// @flow
import React from 'react';
import { get, has } from 'lodash';
import { Table } from 'antd';
import ConvertedUnits from 'components/shared/ConvertedUnits';
import { MeasurementScanType } from 'constants/exportConstants';
import type { DerivedProps } from 'components/measurements/HTMLExport/Measurement';
import styles from 'components/measurements/HTMLExport/HTMLExport.styl';

export const filterReadings = (data: MeasurementFullData, scanType: ScanType) => {
	const isAreaScan = scanType === MeasurementScanType.AreaScan;
	const isFreePath = scanType === MeasurementScanType.FreePath;
	const s = data.settings[0].content;
	let nYLines = 0,
		nXLines = 0;
	let spacing = 0,
		maxScanLength = 0;
	let gridSpacingX = 0,
		gridSpacingY = 0,
		gridSizeX = 0,
		gridSizeY = 0;
	let scanPattern = 'uniform',
		scanFrequency = 1;
	if (has(s, 'settings.preset.scanPattern')) {
		scanPattern = s.settings.preset.scanPattern;
	}
	if (has(s, 'settings.preset.scanFrequency.value')) {
		scanFrequency = s.settings.preset.scanFrequency.value;
	}
	const isUniform = scanPattern.toLowerCase() === 'uniform';

	if (has(s, 'settings.preset.scanModeParameters.areaScanParameters.numberOfVerticalLines')) {
		nYLines = s.settings.preset.scanModeParameters.areaScanParameters.numberOfVerticalLines;
		nXLines = s.settings.preset.scanModeParameters.areaScanParameters.numberOfHorizontalLines;
	} else if (isAreaScan) {
		maxScanLength = s.settings.preset.scanModeParameters.areaScanParameters.maxScanLength;
		spacing = s.settings.preset.scanModeParameters.areaScanParameters.spacing;
		nYLines = Math.round(maxScanLength / spacing) + 1;
		nXLines = nYLines;
	}

	if (has(s, 'settings.preset.scanModeParameters.areaScanParameters.gridSpacingX')) {
		gridSpacingX = s.settings.preset.scanModeParameters.areaScanParameters.gridSpacingX;
		gridSpacingY = s.settings.preset.scanModeParameters.areaScanParameters.gridSpacingY;
		gridSizeX = (nYLines - 1) * gridSpacingX;
		gridSizeY = (nXLines - 1) * gridSpacingY;
	} else {
		gridSpacingX = spacing;
		gridSpacingY = spacing;
		gridSizeX = maxScanLength;
		gridSizeY = maxScanLength;
	}

	const processedReadings = [];
	const r = data.readings.filter(reading => reading.type.toLowerCase() !== 'gpsposition');
	const lineStatusKey = isFreePath
		? 'measurement.content.freePathStatus.lineStatus'
		: 'measurement.content.areaScanStatus.lineStatus';
	const lineStatus = has(data, lineStatusKey) ? get(data, lineStatusKey) : [];
	// put line status to an array.
	const lineStatusMap = {};
	const lineStatusIndexMap = {};
	for (const l of lineStatus) {
		if (isFreePath) {
			const distance = (l.numberOfAScans - 1) / scanFrequency;
			lineStatusMap[`${l.position.dimension}:${l.position.sequence}`] = distance;
			lineStatusIndexMap[`${l.position.dimension}:${l.position.sequence}`] = l.id;
		} else {
			lineStatusMap[`${l.position.dimension}:${l.position.sequence}`] = l.measuredDistanceMeter;
		}
	}
	for (const reading of r) {
		const readingSequence = reading.content.sequence;
		const readingDimension = reading.content.dimension;
		let sequenceNo = 1;
		let startX = 0.0;
		let startY = 0.0;
		if (isAreaScan) {
			if (reading.content.dimension === 'X') {
				sequenceNo = nYLines + reading.content.sequence;
			} else {
				sequenceNo = reading.content.sequence;
			}
		} else if (isFreePath) {
			sequenceNo = lineStatusIndexMap[`${readingDimension}:${readingSequence}`];
		}
		reading.sequenceNo = sequenceNo;
		reading.measuredDistanceMeter = lineStatusMap[`${reading.content.dimension}:${reading.content.sequence}`];
		/*
			For LineScan, there'll be single entry in the lineStatus. 
			So, if it's lineScan reading it's measuredDistanceMeter will give the correct scan distance
		*/
		if (
			scanType === MeasurementScanType.LineScan &&
			reading.measuredDistanceMeter === undefined &&
			lineStatus !== undefined &&
			lineStatus[0] !== undefined
		) {
			reading.measuredDistanceMeter = lineStatus[0].measuredDistanceMeter;
		}
		const readingNew = { ...reading, startx: 0, starty: 0 };
		if (data.productFamily.toLowerCase() === 'gpr_soil') {
			if (sequenceNo > 1) {
				if (readingDimension === 'X') {
					startY = (readingSequence - 1) * gridSpacingY;
					if (isUniform) {
						startX = 0;
					} else if (readingSequence % 2 === 0) {
						startX = gridSizeX;
					} else {
						startX = 0;
					}
				} else {
					startX = (readingSequence - 1) * gridSpacingX;
					if (isUniform) {
						startY = 0;
					} else if (readingSequence % 2 === 0) {
						startY = gridSizeY;
					} else {
						startY = 0;
					}
				}
			}
			readingNew.startx = startX;
			readingNew.starty = startY;
		}

		processedReadings.push(readingNew);
	}
	processedReadings.sort((a, b) => a.sequenceNo - b.sequenceNo);
	return processedReadings;
};

export const title = ({ product, scanType, isMetric }: Object) => (
	<ConvertedUnits
		id="Scan Distance [{unit}]"
		unitId={`${product}.CSV.Scan distance`}
		scanType={scanType}
		isMetric={isMetric}
	/>
);

export const ScanDistance = ({
	data,
	product,
	scanType,
	isMetric,
	convert,
	showTitle,
}: { data: MeasurementFullData } & DerivedProps) => {
	/*
		construct data first
		1. fix sequence number
		2. sort data
	*/
	const filteredLines = filterReadings(data, scanType);
	const productModel = get(data, 'measurement.productModel');
	const isFreePath = scanType === MeasurementScanType.FreePath;
	return (
		<Table
			title={
				showTitle
					? () => <span className={styles.main_header}>{title({ product, scanType, isMetric })}</span>
					: null
			}
			className={[styles.table, 'table-scan-distance'].join(' ')}
			columns={[
				{
					key: 1,
					render: (text, record) => `Line ${record.sequenceNo}`,
					width: 230,
				},
				{
					key: 2,
					dataIndex: 'measuredDistanceMeter',
					render: text => convert(text, `${product}.CSV.Scan distance`),
				},
				{
					key: 3,
					dataIndex: 'startx',
					render: text => convert(text, `${product}.CSV.Scan distance`),
					enable: productModel === 'gpr_soil' && !isFreePath,
				},
				{
					key: 4,
					dataIndex: 'starty',
					render: text => convert(text, `${product}.CSV.Scan distance`),
					enable: productModel === 'gpr_soil' && !isFreePath,
				},
			].filter(row => row.enable === undefined || row.enable === true)}
			dataSource={filteredLines}
			pagination={false}
			showHeader={false}
			size="small"
		/>
	);
};

export default ScanDistance;
