// @flow
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { compose, withState, withProps } from 'recompose';
import { keys, pickBy } from 'lodash';
import { Modal, Form, Checkbox, Button, Alert } from 'antd';
import type { FormProps } from 'antd/lib/form';
import { FormattedMessage, injectIntl } from 'react-intl';
import type { InjectIntlProvidedProps } from 'react-intl';
import URL from 'apis/urls';
import styles from 'components/shared/SmarterTable.styl';
import { hasRoleLevel, normalizeRolesForDisplay, expandRolesForCheckboxes, ROLES } from 'utils/rolesHelper';
import { request } from 'actions/ajax';

export const Roles = ({
	initialRoles = new Set(),
	loadUsers,
	form,
	intl,
	loading,
	setLoading,
	visible,
	error,
	setError,
	isSuperAdmin,
	isAdmin,
	isPO,
	toggleModal,
	closeModal,
	updateRoles,
}: {
	id: string, // eslint-disable-line react/no-unused-prop-types
	initialRoles: Set<UserRole>,
	roles: UserRole[], // eslint-disable-line react/no-unused-prop-types
	loadUsers: Function,
	form: FormProps,
	loading: boolean,
	setLoading: Function,
	visible: boolean,
	error: string,
	setError: Function,
	isSuperAdmin: boolean,
	isAdmin: boolean,
	isPO: boolean,
	toggleModal: Function,
	closeModal: Function,
	updateRoles: Function,
} & InjectIntlProvidedProps) => {
	const afterLoad = () => {
		setLoading(false);
		loadUsers();
	};

	const onSubmit = e => {
		e.preventDefault();

		form.validateFields((err, values) => {
			if (err) {
				return;
			}

			// at least one must be ticked
			if (isAdmin && Object.values(values).filter(Boolean).length < 1) {
				setError(
					intl.formatMessage({
						id: 'App.UsersAtLeastOneRoleValidation',
					})
				);
				return;
			}
			setError('');

			setLoading(true);
			closeModal();

			updateRoles(normalizeRolesForDisplay(keys(pickBy(values)))).then(afterLoad);
		});
	};
	return (
		<Fragment>
			<Modal
				visible={visible}
				title={<FormattedMessage id="App.UsersManageRoles" />}
				footer={null}
				onCancel={closeModal}
			>
				<Form onSubmit={onSubmit}>
					{isSuperAdmin && (
						<Form.Item className={styles.field}>
							{form.getFieldDecorator(ROLES.ADMIN, {
								valuePropName: 'checked',
								initialValue: initialRoles.has(ROLES.ADMIN),
							})(
								<Checkbox
									onChange={({ target }) => {
										const checked = target.checked;
										form.setFieldsValue({
											[ROLES.PO]: checked,
											[ROLES.BU]: checked,
											[ROLES.DEV]: checked,
											[ROLES.SALES_AND_FINANCE]: checked,
										});
									}}
								>
									<FormattedMessage id="App.RoleAdmin" />
								</Checkbox>
							)}
						</Form.Item>
					)}
					{isAdmin && (
						<Form.Item className={styles.field}>
							{form.getFieldDecorator(ROLES.PO, {
								initialValue: initialRoles.has(ROLES.PO),
								valuePropName: 'checked',
							})(
								<Checkbox
									disabled={form.getFieldValue(ROLES.ADMIN)}
									onChange={({ target }) => {
										const checked = target.checked;
										form.setFieldsValue({
											[ROLES.BU]: checked,
										});
									}}
								>
									<FormattedMessage id="App.RolePO" />
								</Checkbox>
							)}
						</Form.Item>
					)}
					{isPO && (
						<Form.Item className={styles.field}>
							{form.getFieldDecorator(ROLES.BU, {
								initialValue: initialRoles.has(ROLES.BU),
								valuePropName: 'checked',
							})(
								<Checkbox disabled={form.getFieldValue(ROLES.ADMIN) || form.getFieldValue(ROLES.PO)}>
									<FormattedMessage id="App.RoleBU" />
								</Checkbox>
							)}
						</Form.Item>
					)}
					{isAdmin && (
						<Form.Item className={styles.field}>
							{form.getFieldDecorator(ROLES.DEV, {
								initialValue: initialRoles.has(ROLES.DEV),
								valuePropName: 'checked',
							})(
								<Checkbox disabled={form.getFieldValue(ROLES.ADMIN)}>
									<FormattedMessage id="App.RoleDev" />
								</Checkbox>
							)}
						</Form.Item>
					)}
					<Form.Item className={styles.field}>
						{form.getFieldDecorator(ROLES.SALES_AND_FINANCE, {
							initialValue: initialRoles.has(ROLES.SALES_AND_FINANCE),
							valuePropName: 'checked',
						})(
							<Checkbox
								disabled={
									form.getFieldValue(ROLES.ADMIN) ||
									form.getFieldValue(ROLES.PO) ||
									form.getFieldValue(ROLES.BU)
								}
							>
								<FormattedMessage id="App.RoleSalesAndFinance" />
							</Checkbox>
						)}
					</Form.Item>
					{isAdmin && (
						<Form.Item className={styles.field}>
							{form.getFieldDecorator(ROLES.ANALYST, {
								initialValue: initialRoles.has(ROLES.ANALYST),
								valuePropName: 'checked',
							})(
								<Checkbox disabled={form.getFieldValue(ROLES.ADMIN)}>
									<FormattedMessage id="App.RoleAnalyst" />
								</Checkbox>
							)}
						</Form.Item>
					)}
					<Form.Item className={styles.field}>
						{form.getFieldDecorator(ROLES.USER, {
							initialValue: true,
							valuePropName: 'checked',
						})(
							<Checkbox disabled>
								<FormattedMessage id="App.RoleUser" />
							</Checkbox>
						)}
					</Form.Item>
					{error && <Alert type="error" description={error} closable onClose={() => setError('')} />}
					<Form.Item className={styles.submit}>
						<Button type="default" onClick={closeModal}>
							<FormattedMessage id="Proceq.LogbookClose" />
						</Button>
						&nbsp;
						<Button type="primary" htmlType="submit" loading={loading}>
							<FormattedMessage id="Proceq.ButtonSubmit" />
						</Button>
					</Form.Item>
				</Form>
			</Modal>
			{/* {isAdmin ? ( */}
			<div onClick={() => toggleModal(true)}>
				<FormattedMessage id="App.UsersManageRoles" />
			</div>
			{/* ) : (
				<Button onClick={() => toggleModal(true)}>
					<FormattedMessage id="App.UsersManageRoles" />
				</Button>
			)} */}
		</Fragment>
	);
};

export default compose(
	Form.create(),
	injectIntl,
	withState('loading', 'setLoading', false),
	withState('visible', 'toggleModal', false),
	withState('error', 'setError', ''),
	connect(
		state => ({
			isSuperAdmin: hasRoleLevel(state.session.scopes, ROLES.SUPER),
			isAdmin: hasRoleLevel(state.session.scopes, ROLES.ADMIN),
			isPO: hasRoleLevel(state.session.scopes, ROLES.PO),
		}),
		(dispatch: Function, props) => ({
			grantRole: role => () =>
				dispatch(
					request({
						method: 'PUT',
						url: URL.GRANT_ROLES,
						params: {
							role,
							userIDs: [props.id],
						},
					})
				),
			revokeRole: role => () =>
				dispatch(
					request({
						method: 'DELETE',
						url: URL.GRANT_ROLES,
						params: {
							role,
							userIDs: [props.id],
						},
					})
				),
		})
	),
	withProps(props => ({
		initialRoles: expandRolesForCheckboxes(props.roles),
		updateRoles: (roles: string[]) => {
			const promises = [];

			roles.forEach(newRole => {
				if (newRole === ROLES.USER) {
					return;
				}

				if (props.roles.indexOf(newRole) < 0) {
					promises.push(props.grantRole(newRole));
				}
			});

			props.roles.forEach(oldRole => {
				if (roles.indexOf(oldRole) < 0 && (props.isAdmin || oldRole !== ROLES.DEV)) {
					promises.push(props.revokeRole(oldRole));
				}
			});

			return promises.reduce((prevPromise, nextPromise) => prevPromise.then(nextPromise), Promise.resolve());
		},
		closeModal: () => {
			props.form.resetFields();
			props.setError('');
			props.toggleModal(false);
		},
	}))
)(Roles);
