import actionTypes from 'actions/actionTypes';
import {
	saveLastProductLogin,
	restoreSessionFromSharedToken,
	restoreSessionFromOneTimeToken,
} from 'utils/sessionHelper';
// import update from 'react-addons-update';
// import update from 'immutability-helper';

const initialState = {
	ajaxParams: {},
	bearerToken: '',
	userName: '',
	userRoles: [],
	userID: '',
	/* allUserInfo: null, */
	groupsRoles: {},
	groupId: '',
	productType: 'GPR',
	productTypes: [],
	regionalDomain: '',
	managementDomain: '',
	sessionType: '' /* Valid values: 'PROCEQ' and 'TDS' */,
	changingProduct: false,
	serverRegion: '',
	products: {},
};

export function session(state = initialState, action) {
	switch (action.type) {
		case actionTypes.INIT_SHARED_PROCEQ_TOKEN_SESSION: {
			const newPartStatus = restoreSessionFromSharedToken(action.payload);
			return {
				...state,
				...newPartStatus,
			};
		}
		case actionTypes.INIT_ONETIME_PROCEQ_TOKEN_SESSION: {
			const newPartStatus = restoreSessionFromOneTimeToken(action.payload);
			return {
				...state,
				...newPartStatus,
			};
		}
		case actionTypes.FINISH_USER_SESSION: {
			return {
				...initialState,
			};
		}
		case actionTypes.CHANGING_CURRENT_PRODUCT: {
			return {
				...state,
				changingProduct: true,
			};
		}
		case actionTypes.CHANGE_CURRENT_GROUP: {
			const newStatus = {
				bearerToken: action.payload.access_token,
				userName: state.userName,
				userID: state.userID,
				userRoles: action.payload.roles,
				groupsRoles: state.groupsRoles,
				groupId: action.payload.group,
				productType: state.productType,
				productTypes: state.productTypes,
				regionalDomain: state.regionalDomain,
				managementDomain: state.managementDomain,
				sessionType: state.sessionType,
				serverRegion: state.serverRegion,
			};
			return {
				...state,
				...newStatus,
			};
		}

		case actionTypes.CHANGE_CURRENT_PRODUCT: {
			saveLastProductLogin(action.payload);
			return {
				...state,
				productType: action.payload,
				changingProduct: false,
			};
		}
		case actionTypes.START_USER_SESSION: {
			const info = action.payload;
			let groupid = '';
			let username = '';
			let userid = '';
			let userroles = [];
			let accesstoken = '';
			let producttypes = [];
			let groupsroles = {};
			let regionaldomain = '';
			let managementdomain = '';
			let sessiontype = '';
			let serverregion = '';
			let scopes = [];

			if ('groupsroles' in info) {
				/* Proceq Login */
				accesstoken = info.access_token;
				username = info.user_info.username;
				userid = info.user_info.user_id;
				groupid = info.group;
				groupsroles = info.groupsroles;
				userroles = groupsroles[groupid];
				producttypes = info.product_types;
				regionaldomain = info.api_url;
				managementdomain = info.api_url;
				sessiontype = 'PROCEQ';
				serverregion = info.server_region;
			} else {
				/* TDS Login */
				accesstoken = info.accessToken;
				username = info.email;
				userid = info.userID;
				groupid = info.username;
				if (info.scopes) {
					if (info.scopes.indexOf('super') >= 0) {
						userroles.push('master_proceq');
						groupid = 'proceq';
					}
					if (info.scopes.indexOf('admin') >= 0) {
						userroles.push('admin_proceq');
						groupid = 'proceq';
					}
					if (info.scopes.indexOf('po') >= 0) {
						userroles.push('po_proceq');
						groupid = 'proceq';
					}
					if (info.scopes.indexOf('bu') >= 0) {
						userroles.push('bu_proceq');
						groupid = 'proceq';
					}
					if (info.scopes.indexOf('user') >= 0) {
						userroles.push('master_client');
					}
					if (info.scopes.indexOf('dev') >= 0) {
						userroles.push('developer_proceq');
						groupid = 'proceq';
					}
				} else {
					userroles.push('master_client');
				}
				scopes = info.scopes;
				groupsroles[groupid] = userroles;
				producttypes = info.producttypes || ['GPR'];
				regionaldomain = info.regionalDomain;
				managementdomain = info.managementDomain;
				sessiontype = 'TDS';
				serverregion = info.region;
			}

			const newStatus = {
				bearerToken: accesstoken,
				userName: username,
				userID: userid,
				userRoles: userroles,
				/* allUserInfo:userInfo, */
				groupsRoles: groupsroles,
				scopes,
				groupId: groupid,
				productType: producttypes[0],
				productTypes: producttypes,
				regionalDomain: regionaldomain,
				managementDomain: managementdomain,
				sessionType: sessiontype,
				serverRegion: serverregion,
			};
			saveLastProductLogin(producttypes[0]);
			// let userInfo = null; //TODO: Launch new request to retrieve the user info
			return {
				...state,
				...newStatus,
			};
		}
		case actionTypes.INIT_USER_SESSION: {
			const info = action.payload;
			return {
				...state,
				...info,
			};
		}
		case actionTypes.SET_SHARED_MEASUREMENTS_SESSION: {
			return {
				...state,
				productType: action.payload.productType,
				regionalDomain: action.payload.regionalDomain,
			};
		}
		case actionTypes.SET_AJAX_PARAMS: {
			return {
				...state,
				ajaxParams: action.payload.ajaxParams,
			};
		}
		case actionTypes.SET_MEASUREMENT_SUMMARY: {
			return {
				...state,
				products: {
					...state.products,
					[action.payload.product]: action.payload.summary,
				},
			};
		}
		default:
			return state;
	}
}
