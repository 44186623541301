// @flow
import { injectIntl } from 'react-intl';
import type { InjectIntlProvidedProps } from 'react-intl';
import handleFormatMessage from 'utils/handleFormatMessage';

export const TrashFile = ({ log, intl }: GPRLogbookEntryProps & InjectIntlProvidedProps) =>
	handleFormatMessage('Proceq.LogbookPropertyMovedSeriesFromToTrash', intl.formatMessage, [log.content.folder]);

export const color = () => 'grey';

export default injectIntl(TrashFile);
