// @flow
import React from 'react';
import { FormattedMessage } from 'react-intl';

export const RenameFile = ({ log }: GPRLogbookEntryProps) => (
	<FormattedMessage
		id="App.LogbookEntryRenamed"
		values={{
			from: log.content.old,
			to: log.content.new,
		}}
	/>
);

export default RenameFile;
