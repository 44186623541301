// @flow
import React, { useState, useEffect, useCallback } from 'react';
import { Table, Icon, Button, Modal } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import NotificationDetailsProvider, { useNotificationDetailsContext } from './NotificationDetailsProvider';
import type { NotificationInfo } from './notificationTypes';
import notificationColumns from './NotificationColumns';
import SyncLoadingButton from '../shared/SyncLoadingButton';
import NotificationForm from './NotificationForm';
import styles from './Notifications.styl';

const Notification = () => {
	const {
		notificationData,
		handleFetchNotifications,
		deleteNotification,
		setFormVisible,
	} = useNotificationDetailsContext();

	const [loading, setLoading] = useState(false);

	const handleInit = useCallback(async () => {
		setLoading(true);
		try {
			await handleFetchNotifications();
		} finally {
			setLoading(false);
		}
	}, [handleFetchNotifications]);

	useEffect(() => {
		handleInit();
	}, [handleInit]);

	const formatMessage = useIntl().formatMessage;

	const handleDeleteNotification = useCallback(
		async (id: string, notificationTitle: string) => {
			Modal.confirm({
				title: formatMessage({ id: 'Notification.Delete.Title' }, { notificationTitle }),
				content: formatMessage({ id: 'Notification.Delete.Description' }),
				okText: formatMessage({ id: 'Proceq.TextYes' }),
				cancelText: formatMessage({ id: 'Proceq.TextNo' }),
				icon: <Icon type="exclamation-circle" />,

				onOk: async () => {
					try {
						await deleteNotification(id);
					} finally {
						await handleFetchNotifications();
					}
				},
			});
		},
		[deleteNotification, handleFetchNotifications]
	);

	const titleRenderer = (title: string, record: NotificationInfo) => {
		const date = new Date(record.deliveryDate);
		return (
			<div className={styles.item_title}>
				<span>{title}</span>
				{date.getTime() > Date.now() && (
					<SyncLoadingButton onSyncClick={() => handleDeleteNotification(record.id, record.title)}>
						<Icon type="delete" />
					</SyncLoadingButton>
				)}
			</div>
		);
	};

	notificationColumns[0].render = titleRenderer;

	const isLoading = loading && !notificationData.length;

	return (
		<div>
			<div className={styles.action_area}>
				<Button type="primary" onClick={() => setFormVisible(true)}>
					<FormattedMessage id="Notification.AddNotification" />
				</Button>
			</div>
			<Table
				className={styles.notification_table}
				rowKey="id"
				size="small"
				dataSource={notificationData}
				columns={notificationColumns}
				pagination={false}
				bordered
				loading={isLoading}
			/>
		</div>
	);
};

const WrappedNotification = () => {
	return (
		<NotificationDetailsProvider>
			<Notification />
			<NotificationForm />
		</NotificationDetailsProvider>
	);
};

export default WrappedNotification;
