// @flow
import React from 'react';
import { get } from 'lodash';
import { Form, Input, Button, Checkbox, Tooltip, Icon, message } from 'antd';
import type { FormProps } from 'antd/lib/form';
import URL from 'apis/urls';
import { connect } from 'react-redux';
import { compose, withState, lifecycle } from 'recompose';
import type { InjectIntlProvidedProps } from 'react-intl';
import { injectIntl, FormattedMessage } from 'react-intl';
import Select from 'components/shared/SmarterFormItems/Select';
import StyledSelect from 'components/shared/SmarterFormItems/StyledSelect';
import tableStyles from 'components/shared/SmarterTable.styl';
import { request } from 'actions/ajax';
import { hasRoleLevel, ROLES } from 'utils/rolesHelper';
import { getTextValue } from 'utils/registryHelper';
import styles from './Tier.styl';
import { ProductConstants, productComparator } from '../contracts/contractConsts';

export const AddEditTier = ({
	loading,
	form,
	tier,
	products,
	changeTier,
	loadTiers,
	addTier,
	editTier,
	prodOptions,
	getFeatures,
	onlineFeatureOptions,
	offlineFeatureOptions,
	loadTierFeatures,
	onlineFeature,
	offlineFeature,
	contractTypeProp,
}: {
	loading: boolean,
	form: FormProps,
	tier?: Tier,
	products: Product[],
	changeTier: Function,
	loadTiers: Function,
	addTier: Function,
	editTier: Function,
	prodOptions: SelectOpts[],
	getFeatures: Function,
	onlineFeatureOptions: SelectOpts[],
	offlineFeatureOptions: SelectOpts[],
	loadTierFeatures: Function,
	onlineFeature: Feature[],
	offlineFeature: Feature[],
	contractTypeProp: Number,
} & InjectIntlProvidedProps) => {
	const onSubmit = e => {
		e.preventDefault();
		const online = form.getFieldValue('onlineFeature');
		const offline = form.getFieldValue('offlineFeature');
		const customerFeatureList = form.getFieldValue('customerFeatureList');
		form.validateFields((err, values) => {
			if (err) {
				return;
			}
			for (let i = 0; i < online.length; i++) {
				if (offline !== undefined && online !== undefined && offline.includes(online[i])) {
					message.error('Same feature can not be in offline and online features');
					return;
				}
			}

			values.customerFeatureList = customerFeatureList ? customerFeatureList.join('\n') : '';
			if (values.tier === undefined || values.tier === '') {
				addTier(values);
			} else {
				editTier(values);
			}
			setTimeout(() => {
				loadTiers();
				loadTierFeatures();
			});
		});
	};
	const onProductChange = val => {
		form.setFieldsValue({ tier: '' });
		form.setFieldsValue({ product: val });
		getFeatures(val);
	};
	const contractTypeOpt = [
		{
			name: 'Device',
			value: '0',
		},
		{
			name: 'Maintenance Services',
			value: '1',
		},
		{
			name: 'Software',
			value: '2',
		},
	];
	const productStr = form.getFieldValue('product');
	const saasProds = getTextValue(['saasProducts']).map(({ value }) => value.toUpperCase());
	const isSaasProducts = typeof productStr === 'string' && saasProds.includes(productStr.toUpperCase());
	const showDeviceIDFlag = productStr === ProductConstants.GPR_INSIGHTS;
	const customerFeatureList = tier && tier.customerFeatureList ? tier.customerFeatureList.split('\n') : [];
	return (
		<Form onSubmit={onSubmit}>
			<div>
				<Form.Item label={<FormattedMessage id="Product" />} className={tableStyles.half_field}>
					{products !== undefined &&
						form.getFieldDecorator('product', {
							rules: [{ required: true }],
						})(
							<Select
								disabled={tier !== undefined && tier.tier !== undefined && tier.tier !== ''}
								selectOpt={prodOptions}
								onChange={onProductChange}
							/>
						)}
				</Form.Item>
				<Form.Item label={<FormattedMessage id="Tier" />} className={styles.hidden}>
					{form.getFieldDecorator('tier', {
						rules: [{ required: false }],
					})(<Input />)}
				</Form.Item>
			</div>
			<div>
				<Form.Item label={<FormattedMessage id="Tier Type" />} className={tableStyles.half_field}>
					{form.getFieldDecorator('contractType', {
						initialValue: tier ? String(tier.contractType) : '0',
						rules: [{ required: true }],
					})(
						<Select
							disabled={tier !== undefined && tier.contractType !== undefined && tier.contractType !== ''}
							selectOpt={contractTypeOpt}
						/>
					)}
				</Form.Item>
			</div>
			<div>
				{tier !== undefined && (
					<Form.Item label={<FormattedMessage id="Name" />} className={tableStyles.half_field}>
						{form.getFieldDecorator('name', {
							initialValue: tier ? tier.name : '',
							rules: [{ required: true }],
						})(<Input />)}
					</Form.Item>
				)}

				{(tier === undefined || tier.code === '') && (
					<Form.Item label={<FormattedMessage id="Name" />} className={tableStyles.half_field}>
						{form.getFieldDecorator('name', {
							initialValue: tier ? tier.name : '',
							rules: [{ required: true }],
						})(<Input />)}
					</Form.Item>
				)}
			</div>
			<div>
				<Form.Item label={<FormattedMessage id="Description" />} className={tableStyles.field}>
					{form.getFieldDecorator('description', {
						initialValue: tier ? tier.description : '',
						rules: [{ required: true }],
					})(<Input />)}
				</Form.Item>
			</div>
			{isSaasProducts && (
				<div>
					<Form.Item label={<FormattedMessage id="Customer Features" />} className={tableStyles.field}>
						<Tooltip title={<FormattedMessage id="Proceq.FormFreeTrial.CustomerFeatureList.Tooltip" />}>
							{products !== undefined &&
								form.getFieldDecorator('customerFeatureList', {
									rules: [],
									initialValue: customerFeatureList,
								})(
									<StyledSelect
										mode="tags"
										tokenSeparators={[',']}
										size={'default'}
										dropdownStyle={{ display: 'none' }}
										style={{ width: '100%' }}
									/>
								)}
						</Tooltip>
					</Form.Item>
				</div>
			)}
			<div>
				<Form.Item label={<FormattedMessage id="Subscription Feature" />} className={tableStyles.field}>
					{products !== undefined &&
						form.getFieldDecorator('onlineFeature', {
							rules: [],
							initialValue: onlineFeature,
						})(<StyledSelect mode="multiple" selectOpt={onlineFeatureOptions} />)}
				</Form.Item>
			</div>
			{String(contractTypeProp) === '0' && (
				<div>
					<Form.Item label={<FormattedMessage id="Lifetime Feature" />} className={tableStyles.field}>
						{products !== undefined &&
							form.getFieldDecorator('offlineFeature', {
								rules: [],
								initialValue: offlineFeature,
							})(<StyledSelect mode="multiple" selectOpt={offlineFeatureOptions} />)}
					</Form.Item>
				</div>
			)}

			<div>
				<Form.Item>
					{form.getFieldDecorator('enable', {
						initialValue: tier ? tier.enable === 1 : false,
						valuePropName: 'checked',
					})(
						<Checkbox>
							<FormattedMessage id="Enable" />
							&nbsp;&nbsp;
							<Tooltip
								title={
									<FormattedMessage id="If disabled tier can not be associated with any new contract." />
								}
							>
								<Icon type="info-circle" />
							</Tooltip>
						</Checkbox>
					)}
					{form.getFieldDecorator('allowExpiration', {
						initialValue: tier ? tier.allowExpiration === 1 : false,
						valuePropName: 'checked',
					})(
						<Checkbox>
							<FormattedMessage id="Allow Expiration" />
							&nbsp;&nbsp;
							<Tooltip
								title={
									<FormattedMessage id="If allowed, expiration date can be configured in contracts management related to this tier." />
								}
							>
								<Icon type="info-circle" />
							</Tooltip>
						</Checkbox>
					)}
					{String(contractTypeProp) === '0' &&
						form.getFieldDecorator('offerEagleCare', {
							initialValue: tier ? tier.offerEagleCare === 1 : false,
							valuePropName: 'checked',
						})(
							<Checkbox>
								<FormattedMessage id="Offer Eagle Care" />
								&nbsp;&nbsp;
								<Tooltip
									title={
										<FormattedMessage id="If enabled, eagle care usage status can be configured from contract management." />
									}
								>
									<Icon type="info-circle" />
								</Tooltip>
							</Checkbox>
						)}
					{isSaasProducts &&
						form.getFieldDecorator('internal', {
							initialValue: tier ? tier.internal === 1 : false,
							valuePropName: 'checked',
						})(
							<Checkbox>
								<FormattedMessage id="Internal Tier" />
								&nbsp;&nbsp;
								<Tooltip
									title={
										<FormattedMessage id="Internal tiers will only be available for Internal Users." />
									}
								>
									<Icon type="info-circle" />
								</Tooltip>
							</Checkbox>
						)}
					{isSaasProducts &&
						form.getFieldDecorator('free', {
							initialValue: tier ? tier.free === 1 : false,
							valuePropName: 'checked',
						})(
							<Checkbox>
								<FormattedMessage id="Free Tier" />
								&nbsp;&nbsp;
								<Tooltip
									title={
										<FormattedMessage id="Free tiers to be used for software products : Inspect and Insights" />
									}
								>
									<Icon type="info-circle" />
								</Tooltip>
							</Checkbox>
						)}
					{showDeviceIDFlag &&
						form.getFieldDecorator('requireDeviceID', {
							initialValue: tier ? tier.requireDeviceID === 1 : false,
							valuePropName: 'checked',
						})(
							<Checkbox>
								<FormattedMessage id="Device ID" />
								&nbsp;&nbsp;
								<Tooltip
									title={
										<FormattedMessage id="When enabled, a device ID is required for tier activation. Conversely, it will block license activation in web Apps where a device ID is not necessary, ensuring that licenses are only activated in the appropriate environment." />
									}
								>
									<Icon type="info-circle" />
								</Tooltip>
							</Checkbox>
						)}
				</Form.Item>
			</div>
			<Form.Item className={tableStyles.submit}>
				<Button type="default" onClick={() => changeTier(null)}>
					<FormattedMessage id="Proceq.LogbookClose" />
				</Button>
				&nbsp;
				<Button type="primary" htmlType="submit" loading={loading}>
					<FormattedMessage id="Proceq.ButtonSubmit" />
				</Button>
			</Form.Item>
		</Form>
	);
};

export default compose(
	Form.create({
		mapPropsToFields: props => {
			const fields = {};
			['product', 'tier', 'code', 'name', 'onlineFeature', 'offlineFeature'].forEach(k => {
				const value = get(props, ['tier', k]);
				fields[k] = Form.createFormField({ value });
			});
			return fields;
		},
	}),
	withState('loading', 'setLoading', false),
	withState('prodOptions', 'setProdOptions', []),
	withState('onlineFeatureOptions', 'setOnlineFeatureOptions', []),
	withState('offlineFeatureOptions', 'setOfflineFeatureOptions', []),
	withState('contractTypeProp', 'setContractTypeProp', '0'),
	connect(
		state => ({
			isAdmin: hasRoleLevel(state.session.scopes, ROLES.ADMIN),
		}),
		(dispatch: Function, props) => ({
			addTier: params => {
				dispatch(
					request({
						method: 'POST',
						url: URL.ADD_EDIT_TIER,
						setLoading: props.setLoading,
						params: {
							...params,
						},
					})
				).then(response => {
					if (response === '') {
						props.changeTier(null);
						props.loadTiers();
						props.loadAllTierFeatures();
					}
				});
			},
			editTier: params => {
				dispatch(
					request({
						method: 'PUT',
						url: URL.ADD_EDIT_TIER,
						setLoading: props.setLoading,
						params: {
							...params,
						},
					})
				).then(response => {
					if (response === '') {
						props.changeTier(null);
						props.loadTiers();
						props.loadAllTierFeatures();
					}
				});
			},
			getFeatures: () => {
				dispatch(
					request({
						method: 'GET',
						url: URL.GET_FEATURE_BY_PROD,
						setLoading: props.setLoading,
						params: {
							product:
								props.tier !== undefined ? props.tier.product : props.form.getFieldValue('product'),
						},
					})
				).then(data => {
					const options = [];
					data.map(function(d) {
						if (String(d.contractType) === props.form.getFieldValue('contractType')) {
							const obj: SelectOpts = {
								name: d.description,
								value: d.code,
								disable: d.enable === 0,
							};
							options.push(obj);
						}
					});
					props.setOnlineFeatureOptions(options);
					props.setOfflineFeatureOptions(options);
				});
			},
			loadTierFeatures: () => {
				if (props.tier === undefined) {
					return;
				}
				dispatch(
					request({
						method: 'GET',
						url: URL.GET_TIER_FEATURES,
						setLoading: props.setLoading,
						params: {
							product: props.tier.product,
							tierId: props.tier.tier,
						},
					})
				).then(data => {
					const onlineOpt = [];
					const offlineOpt = [];
					data.map(function(d) {
						if (d.type === 1) {
							onlineOpt.push(d.code);
						} else {
							offlineOpt.push(d.code);
						}
					});
					props.form.setFieldsValue({ onlineFeature: onlineOpt });
					props.form.setFieldsValue({ offlineFeature: offlineOpt });
				});
			},
		})
	),
	lifecycle({
		componentDidMount() {
			const options = [];
			this.props.products.map(function(prod) {
				const obj = {
					name: prod.name,
					value: prod.code,
				};
				options.push(obj);
			});
			options.sort(productComparator('name'));
			this.props.setProdOptions(options);
			setTimeout(() => {
				if (this.props.form.getFieldValue('contractType') !== undefined) {
					this.props.setContractTypeProp(this.props.form.getFieldValue('contractType'));
				}
				this.props.getFeatures(this.props.form.getFieldValue('product'));
			});
		},
		componentDidUpdate(prevProps) {
			const cType = this.props.form.getFieldValue('contractType');
			if (cType !== undefined && String(prevProps.contractTypeProp) !== cType) {
				this.props.setContractTypeProp(cType);
				this.props.getFeatures(
					this.props.tier !== undefined ? this.props.tier.product : this.props.form.getFieldValue('product')
				);
			}
			if (
				(this.props.form.getFieldValue('onlineFeature') === undefined &&
					prevProps.form.getFieldValue('onlineFeature') === undefined) ||
				(this.props.form.getFieldValue('contractType') === 0 &&
					this.props.form.getFieldValue('offlineFeature') === undefined &&
					prevProps.form.getFieldValue('offlineFeature') === undefined)
			) {
				this.props.form.setFieldsValue({ onlineFeature: [] });
				this.props.form.setFieldsValue({ offlineFeature: [] });
				this.props.loadTierFeatures();
				this.props.getFeatures(
					this.props.tier !== undefined ? this.props.tier.product : this.props.form.getFieldValue('product')
				);
			}
		},
	}),
	injectIntl
)(AddEditTier);
