// @flow
import React, { Fragment } from 'react';
import { compose } from 'recompose';
import type { InjectIntlProvidedProps } from 'react-intl';
import { FormattedMessage, injectIntl } from 'react-intl';

import handleFormatMessage from 'utils/handleFormatMessage';
import {
	getStandardNameOfStandardId,
	getScaleInfoOfScaleId,
	getResolutionForScale,
	getSchmidtScaleIdOfFromFactorAndUnitId,
} from 'utils/conversionsHelper';
import { getLabelFromGlobalDataKey } from 'utils/registryHelper';
import StaticMap from '../StaticMap';

export const Creation = ({
	intl,
	log,
	product,
	isVerificationMode,
	primarySchmidtScaleId,
	primaryVerificationScaleId,
}: EquotipLogbookEntryProps & InjectIntlProvidedProps) => {
	const formatPrimaryLimitValue = (scale, value) =>
		`${getResolutionForScale(
			getScaleInfoOfScaleId(intl.formatMessage, scale, product).scaleUnits,
			value,
			'limit'
		)} ${getScaleInfoOfScaleId(intl.formatMessage, scale, product).scaleUnits}`;

	const formatSecondaryLimitValue = (scale, value) =>
		`${getResolutionForScale(
			getScaleInfoOfScaleId(intl.formatMessage, scale, product).scaleUnits,
			value,
			'limit'
		)} ${getScaleInfoOfScaleId(intl.formatMessage, scale, product).scaleName}`;

	const {
		// btSerialNumber,
		folderName,
		maxLimitValue,
		minLimitValue,
		probeTypeID,
		seriesName,
		standardID,
		triggerLoadId,

		// schmidt
		unitId,
		formFactorId,
		conversionCurveId,
		correctionFactor,
		testBlockTitle,
	} = log.content.measurementInfo;

	const renderMap = () => {
		const { latitude, longitude } = log.content;

		if (!longitude && !latitude) {
			return null;
		}
		return (
			<Fragment>
				<br />
				<StaticMap width={400} height={200} zoom={16} coordinates={{ lat: +latitude, lng: +longitude }} />
				{/* <InteractiveMap height={ 200 } zoom={ 16 } coordinates={ coordinates } /> */}
			</Fragment>
		);
	};

	if (isVerificationMode) {
		let primaryVerificationScaleName = '';
		switch (probeTypeID) {
			case 2: // SCHMIDT L
				primaryVerificationScaleName = 'R';
				break;
			case 3: // SCHMIDT N
				primaryVerificationScaleName = 'R';
				break;
		}

		return (
			<div>
				{handleFormatMessage('LogbookPropertyCreatedVerification', intl.formatMessage, [seriesName])}
				{triggerLoadId && (
					<div>
						<FormattedMessage id="Proceq.LogBookTestLoad" />
						{`: HV${triggerLoadId} (${triggerLoadId}kgf)`}
					</div>
				)}
				<div>
					<FormattedMessage id="Proceq.ExportFieldStandard" />
					{': '}
					{getStandardNameOfStandardId(null, intl.formatMessage, standardID, product)}
				</div>
				{testBlockTitle && (
					<div>
						{product === 'EQUOTIP' ? (
							<div>
								<FormattedMessage id="Proceq.LogBookTestBlock" />
								{': '}
								{testBlockTitle}
							</div>
						) : (
							<div>
								<FormattedMessage id="Proceq.LogBookCalibrationAnvil" />
								{': '}
								{testBlockTitle}
							</div>
						)}
					</div>
				)}
				<div>
					<FormattedMessage id="Proceq.ExportFieldPrimaryScale" />
					{': '}
					{primaryVerificationScaleName}
				</div>
				{minLimitValue && (
					<div>
						<FormattedMessage id="Proceq.ExportFieldLowerLimit" />
						{': '}
						{formatPrimaryLimitValue(primaryVerificationScaleId, minLimitValue.primaryValue)}
					</div>
				)}
				{maxLimitValue && (
					<div>
						<FormattedMessage id="Proceq.ExportFieldUpperLimit" />
						{': '}
						{formatPrimaryLimitValue(primaryVerificationScaleId, maxLimitValue.primaryValue)}
					</div>
				)}
				{renderMap()}
			</div>
		);
	}

	const targetScaleId = getSchmidtScaleIdOfFromFactorAndUnitId(unitId, formFactorId);

	return (
		<div>
			{handleFormatMessage('LogbookPropertyCreatedSeriesInFolder', intl.formatMessage, [seriesName, folderName])}
			{triggerLoadId && (
				<div>
					<FormattedMessage id="Proceq.LogBookTestLoad" />
					{`: HV${triggerLoadId} (${triggerLoadId}kgf)`}
				</div>
			)}
			<div>
				<FormattedMessage id="Proceq.ExportFieldStandard" />
				{': '}
				{getStandardNameOfStandardId(null, intl.formatMessage, standardID, product)}
				{conversionCurveId ? (
					<div>
						<FormattedMessage id="Proceq.ExportFieldConversionCurve" />
						{': '}
						{handleFormatMessage(
							getLabelFromGlobalDataKey('convcurveschmidt', conversionCurveId),
							intl.formatMessage
						)}
					</div>
				) : (
					<br />
				)}
				{product === 'SSL' && (
					<Fragment>
						<FormattedMessage id="App.LogbookPropertyCorrectionFactor" />
						{': '}
						{correctionFactor}
						<br />
					</Fragment>
				)}
				<FormattedMessage id="Proceq.ExportFieldPrimaryScale" />
				{': '}
				{getScaleInfoOfScaleId(intl.formatMessage, primarySchmidtScaleId, product).scaleUnits}
				<br />
				<FormattedMessage id="Proceq.ExportFieldTargetScale" />
				{': '}
				{getScaleInfoOfScaleId(intl.formatMessage, targetScaleId, product).scaleName}
				<br />
				<FormattedMessage id="Proceq.ExportFieldLowerLimit" />
				{': '}
				{formatPrimaryLimitValue(primarySchmidtScaleId, minLimitValue.primaryValue)}
				{' / '}
				{formatSecondaryLimitValue(targetScaleId, minLimitValue.secondaryValue)}
				<br />
				<FormattedMessage id="Proceq.ExportFieldUpperLimit" />
				{': '}
				{formatPrimaryLimitValue(primarySchmidtScaleId, maxLimitValue.primaryValue)}
				{' / '}
				{formatSecondaryLimitValue(targetScaleId, maxLimitValue.secondaryValue)}
				<br />
			</div>
			{renderMap()}
		</div>
	);
};

export const color = () => 'green';

export default compose(injectIntl)(Creation);
