// @flow
import React from 'react';
import { compose, withState } from 'recompose';
import { connect } from 'react-redux';
import { Icon, Popover } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import AvatarInitials from '../../shared/AvatarInitials';
import styles from './Avatar.styl';
import colors from '../../../styles/colors.json';
import routeURLs from '../../../routes/urls';

export const Avatar = ({ profile }: { profile: Object }) => {
	const history = useHistory();

	return (
		<div id="avatar_container" className={styles.container}>
			<Popover
				// $FlowFixMe
				getPopupContainer={() => document.getElementById('avatar_container')}
				placement="bottomRight"
				content={
					<div className="menu_container" style={{ width: '240px', backgroundColor: colors.white }}>
						<div className={styles.menu_item}>
							<Link style={{ all: 'unset', flex: '1' }} to="/settings">
								<Icon className={styles.icon} type="user" />
								<FormattedMessage id="Proceq.NavUpdateProfile" />
							</Link>
						</div>
						<hr className={styles.line_style} />
						<div className={styles.menu_item}>
							<a style={{ all: 'unset', flex: '1' }} onClick={() => history.push(routeURLs.LOGOUT)}>
								<Icon className={styles.icon} type="logout" />
								<FormattedMessage id="Proceq.NavLogout" />
							</a>
						</div>
					</div>
				}
				autoAdjustOverflow={false}
				trigger="click"
			>
				<div className={styles.user_menu}>
					<p className={styles.text}>
						{profile.firstName} {profile.lastName}{' '}
					</p>
					<AvatarInitials profile={profile} />
				</div>
			</Popover>
		</div>
	);
};

export default compose(
	connect(state => ({
		profile: state.user.user.profile || {},
	})),
	withState('expanded', 'expand', false)
)(Avatar);
