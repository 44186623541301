// @flow
import React, { Component } from 'react';
import { get, forEach, isArray, has } from 'lodash';
import type { ColumnProps, PaginationProps } from 'antd';
import { Table, Button, Input, Select } from 'antd';
import { FormattedMessage } from 'react-intl';
import { getLabelFromGlobalDataKey, getTextValue } from 'utils/registryHelper';
import styles from './SmarterTable.styl';

const coerceToArray = globalRegister => {
	if (!globalRegister) {
		return [];
	}

	if (!isArray(globalRegister)) {
		return [globalRegister];
	}

	return globalRegister;
};

export const renderCell = (column: ColumnProps) => (text: string) => {
	const globalRegister = coerceToArray(column.globalRegister);

	let newText;
	forEach(globalRegister, gr => {
		newText = getLabelFromGlobalDataKey(gr, text);

		return !newText;
	});

	newText = newText || text;

	if (!newText && column.renderIfEmpty) {
		return <FormattedMessage id={column.renderIfEmpty} />;
	}

	return newText ? <FormattedMessage id={newText} /> : newText;
};

export const computeOffset = (page: number, pageSize: number) => (page - 1) * pageSize || 0;

export const generateFilterQuery = (filter: string, value: mixed, index: number = 0) => {
	// transform into a flat array if needed
	if (isArray(value) && value.length > 0) {
		const firstValue = value[0];
		if (value.some(v => isArray(v))) {
			// flat array of multiple values
			value = value.flat();
		} else if (typeof firstValue === 'string' && firstValue.includes(',')) {
			// multiple values in a string
			value = firstValue.split(',');
		}
	} else if (typeof value === 'string' && value.includes(',')) {
		value = value.split(',');
	}

	// transform flat array into regex
	let regex = value;
	let hasRegex = false;
	if (isArray(value) && value.length > 0) {
		hasRegex = true;
		if (filter === 'roles') {
			regex = `(${value.map(v => `(^|,)(${v})(,|$)`).join('|')})`;
		} else {
			regex = `(${value.map(v => `^${v}$`).join('|')})`;
		}
	}

	return {
		[`columns[${index}][data]`]: filter,
		[`columns[${index}][search][value]`]: regex,
		[`columns[${index}][search][searchable]`]: true,
		[`columns[${index}][search][regex]`]: hasRegex,
	};
};
export type SmarterTableProps<T> = {
	rowKey: string | Function,
	columns: ColumnProps[],
	dataSource: T[],
	totalRecords?: number,
	loading: boolean,
	loadDataSource: Function,
	setParams: Function,
	defaultFilters?: {
		[column: string]: string[],
	},

	selectedRowKeys?: string[],
	onRow?: Function,
	onRowSelection?: Function,
	maxRowSelection?: number,
	expandedRowRender?: Function,
};

class SmarterTable<T: Object> extends Component<
	SmarterTableProps<T>,
	{
		page: number,
		pageSize: number,
		order: string,
		orderDir: string,
		filters: {
			[column: string]: string[],
		},
		filterDropdownVisible: ?string,
	}
> {
	state = {
		page: 1,
		pageSize: 20,
		order: '',
		orderDir: 'ascend',
		filters: {},
		filterDropdownVisible: null,
	};

	static defaultProps = {
		defaultFilters: {},
	};

	get maxRowSelection(): number {
		return this.props.maxRowSelection || 101;
	}

	search = () => this.setState({ filterDropdownVisible: null }, this.loadDataSource);

	filter = (columnFilter: string, filterOnSearch?: boolean) => (e: any) => {
		this.setState({
			filters: {
				...this.state.filters,
				[columnFilter]: filterOnSearch ? e : e.target.value,
			},
		});
	};

	reset = (columnFilter: string) => () => {
		this.setState(
			{
				filters: {
					...this.state.filters,
					[columnFilter]: '',
				},
				filterDropdownVisible: null,
			},
			this.loadDataSource
		);
	};

	generateFilterProps({ filter, filterSearch, globalRegister, filterOps, multiSelect }: FilterProp) {
		if ((globalRegister && globalRegister.length > 0) || (filterOps && filterOps.length > 0)) {
			let options = filterOps !== undefined && filterOps.length > 0 ? filterOps : [];
			if ('length' in options && options.length === 0 && globalRegister !== undefined) {
				options = getTextValue(globalRegister);
			}
			const filters = options
				// this is to allow multiple labels to be combined and be searched as a regex
				.reduce((array, current) => {
					const index = array.findIndex(previous => previous.text === current.text);
					if (index > -1) {
						// duplicate found
						if (isArray(array[index].value)) {
							// duplicate found before
							array[index].value.push(current.value);
						} else {
							array[index].value = [array[index].value, current.value];
						}
					} else {
						array.push(current);
					}
					return array;
				}, [])
				.map<{ text: any, value: any }>(({ text, value }) => ({
					text: <FormattedMessage id={text} />,
					value,
				}));
			return {
				filters,
				filteredValue: get(this.state.filters, filter),
				filterMultiple: multiSelect !== undefined && multiSelect,
			};
		}

		if (filter) {
			const flag: string = filter; // to trick flowtype checker
			return {
				filteredValue: get(this.state.filters, flag),
				filterDropdown: this.renderTextFilter(flag, filterSearch),
				filterDropdownVisible: this.state.filterDropdownVisible === flag,
				onFilterDropdownVisibleChange: (visible: boolean) =>
					this.setState({
						filterDropdownVisible: visible ? flag : null,
					}),
			};
		}
		return {};
	}

	renderTextFilter = (filter: string, filterSearch?: FilterSearchProp) => (
		<div className={styles.custom_filter_dropdown} data-e2e-table-filter-dropdown>
			{filterSearch ? (
				<Select
					className={styles.select}
					dropdownMatchSelectWidth={false}
					showSearch
					notFoundContent={filterSearch.searchLoading ? 'Searching' : 'No Results'}
					onSearch={filterSearch.onSearch}
					onBlur={filterSearch.onBlur}
					onSelect={this.filter(filter, true)}
					value={this.state.filters[filter]}
					filterOption={false}
				>
					{filterSearch.options.map(option => (
						<Select.Option key={option.id} option={option}>
							{filterSearch.customOption ? filterSearch.customOption(option) : option}
						</Select.Option>
					))}
				</Select>
			) : (
				<Input
					className={styles.input}
					onPressEnter={this.search}
					value={this.state.filters[filter]}
					onChange={this.filter(filter)}
				/>
			)}
			<Button type="primary" onClick={this.search}>
				<FormattedMessage id="Proceq.ButtonSearch" />
			</Button>
			<Button type="primary" style={{ marginLeft: '5px' }} onClick={this.reset(filter)}>
				<FormattedMessage id="Proceq.ButtonReset" />
			</Button>
		</div>
	);

	loadDataSource = () => {
		let query = {};
		let counter = 0;
		Object.entries(this.state.filters).forEach(([column, value]) => {
			if (!value || (isArray(value) && value.length < 1)) {
				return;
			}

			query = {
				...query,
				...generateFilterQuery(column, value, counter),
			};
			counter++;
		});

		Object.entries(this.props.defaultFilters || {}).forEach(([column, value]) => {
			const param = this.state.filters[column];
			if (!param || (isArray(param) && param.length < 1)) {
				query = {
					...query,
					...generateFilterQuery(column, value, counter),
				};
				counter++;
			}
		});

		let order = {};
		order = {
			'order[0][column]': counter,
			'order[0][dir]': this.state.orderDir.slice(0, -3),
			[`columns[${counter}][data]`]: this.state.order,
			[`columns[${counter}][orderable]`]: true,
		};

		this.props.setParams(
			{
				start: computeOffset(this.state.page, this.state.pageSize),
				length: this.state.pageSize,
				...query,
				...order,
			},
			() => this.props.loadDataSource()
		);
	};

	handlePaginationChange: $PropertyType<PaginationProps, 'onChange'> = page => {
		this.setState({ page }, this.loadDataSource);
	};

	handlePaginationSizeChange: $PropertyType<PaginationProps, 'onShowSizeChange'> = (current, size) => {
		this.setState({ pageSize: size, page: current }, this.loadDataSource);
	};

	handleTableChange(pagination: Object, filters: Object, sorter: Object) {
		this.setState(
			{
				page: pagination.current,
				pageSize: pagination.pageSize,
				order: sorter.column?.sorterProp ? sorter.column.sorterProp : sorter.field || '',
				// order: sorter.field || '',
				orderDir: sorter.order || '',
				filters,
			},
			this.loadDataSource
		);
	}

	componentWillReceiveProps(nextProps: SmarterTableProps<T>) {
		if (this.props.totalRecords !== nextProps.totalRecords) {
			this.setState({ page: 1 });
		}
	}

	render() {
		const {
			rowKey,
			columns,
			dataSource,
			totalRecords,
			loading,
			selectedRowKeys,
			onRow,
			onRowSelection,
			expandedRowRender,
		} = this.props;

		return (
			<Table
				rowKey={rowKey}
				className="table_with_top_pagination_and_buttons"
				size="small"
				columns={columns
					.filter(column => {
						if (has(column, 'checkAccess')) {
							return column.checkAccess;
						}
						return true;
					})
					.map(column => {
						return {
							render: renderCell(column),
							...column,
							...(column.filter
								? this.generateFilterProps({
										filter: column.filter === true ? column.dataIndex : column.filter,
										filterSearch: column.filterSearch,
										range: column.range,
										globalRegister: coerceToArray(column.globalRegister),
										filterOps: column.filterOps !== undefined ? column.filterOps : [],
										multiSelect: column.filter === true && column.multiSelect,
								  })
								: {}),
							sortOrder:
								this.state.order === column?.sorterProp || this.state.order === column.dataIndex
									? this.state.orderDir
									: null,
							title: <FormattedMessage id={column.title} />,
						};
					})}
				dataSource={dataSource}
				bordered
				loading={loading}
				pagination={{
					position: 'top',
					showSizeChanger: true,
					onShowSizeChange: this.handlePaginationSizeChange,
					pageSizeOptions: ['10', '20', '100'],
					defaultPageSize: this.state.pageSize,
					total: totalRecords,
					showTotal: (total, range) => (
						<FormattedMessage
							id="Proceq.JQGridTableRecordText"
							values={{
								'0': range[0],
								'1': range[1],
								'2': <span data-e2e-table-total-count>{total}</span>,
							}}
						/>
					),
				}}
				onChange={this.handleTableChange.bind(this)}
				onRow={onRow}
				rowSelection={
					selectedRowKeys && onRowSelection
						? {
								selectedRowKeys,
								onChange: keys => onRowSelection(keys),
								getCheckboxProps: (record: T) => {
									if (record.disabled) {
										return {
											disabled: true,
										};
									}

									if (
										selectedRowKeys.length >= this.maxRowSelection &&
										selectedRowKeys.indexOf(get(record, rowKey)) < 0
									) {
										return {
											disabled: true,
										};
									}
									return {};
								},
								onSelectAll: (selected: boolean, selectedRows: T[], changeRows: T[]) => {
									// ignore if already reached max, or if deselecting
									if (selectedRowKeys.length > this.maxRowSelection || !selected) {
										return;
									}

									const diff = this.maxRowSelection - selectedRowKeys.length;
									onRowSelection([
										...selectedRowKeys,
										...changeRows.slice(0, diff).map(r => get(r, rowKey)),
									]);
								},
						  }
						: null
				}
				expandedRowRender={expandedRowRender}
			/>
		);
	}
}

export default SmarterTable;
