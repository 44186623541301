// @flow
import React from 'react';
import { Button } from 'antd';
import { connect } from 'react-redux';
import { compose, withState } from 'recompose';
import { FormattedMessage } from 'react-intl';
import URL from 'apis/urls';
import { request } from 'actions/ajax';

export const DownloadAttachment = ({ loading, handleDownload }: { loading: boolean, handleDownload: Function }) => {
	console.log(this);
	return (
		<Button loading={loading} disabled={loading} onClick={handleDownload}>
			<FormattedMessage id="Proceq.LogbookDownload" />
		</Button>
	);
};

export default compose(
	withState('loading', 'setLoading', false),
	connect(state => ({
		product: state.session.productType,
	})),
	connect(null, (dispatch: Function, props) => ({
		handleDownload: () => {
			props.setLoading(true);
			if (props.product.toLowerCase() === 'gpr_soil') {
				dispatch(
					request({
						url: URL.GET_SIGNED_S3_URL,
						setLoading: props.setLoading,
						headers: {
							'Content-Type': 'application/zip',
						},
						params: {
							mID: props.mID,
							fID: props.fID,
							aID: props.aID,
							fName: props.id,
						},
					})
				).then(data => {
					window.location.assign(data);
				});
			} else {
				dispatch(
					request({
						url: URL.DOWNLOAD_FILE,
						setLoading: props.setLoading,
						headers: {
							'Content-Type': 'application/zip',
						},
						options: {
							responseType: 'blob',
						},
						params: {
							mID: props.mID,
							fID: props.fID,
							aID: props.aID,
						},
					})
				).then(data => {
					window.fileDownload(data, `${props.id}.zip`, 'application/zip');
					// window.location.assign(window.URL.createObjectURL(data));
				});
			}
		},
	}))
)(DownloadAttachment);
