// @flow
import React from 'react';
import { get } from 'lodash';
import { connect } from 'react-redux';
import { Layout } from 'antd';
import { compose, withState, lifecycle } from 'recompose';
import URL from 'apis/urls';
import { request } from 'actions/ajax';
import { hasRoleLevel, ROLES } from 'utils/rolesHelper';
import columnConfig from './verificationColumnConfig';
import MeasurementTable from './MeasurementTable';
import EquotipReadings from './EquotipReadings';
import Logbook from './Logbook';
import JSONTreeView from './Debug/JSONTreeView';
import Attachments from './Debug/Attachments';

export const VerificationData = ({
	loading,
	product,
	isDev,
	verificationData,
	totalRecords,
	params,
	loadVerificationData,
}: {
	loading: boolean,
	product: ProductCode,
	isDev: boolean,
	verificationData: Measurement[],
	totalRecords: number,
	params: Object,
	loadVerificationData: Function,
}) => (
	<Layout>
		<Layout.Content>
			<MeasurementTable
				fileType="verification"
				loading={loading}
				params={params}
				measurements={verificationData}
				loadMeasurements={loadVerificationData}
				totalRecords={totalRecords}
				columnConfig={[
					{
						title: 'Proceq.TableDataSeriesName',
						dataIndex: 'name',
						sorter: true,
						filter: 'search',
						render: (text, record) => (
							<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
								<span style={{ alignSelf: 'flex-start', flex: 1 }}>{text}</span>
								&nbsp; &nbsp;
								<Logbook
									mID={record.id}
									product={product}
									logbookProps={{ isVerificationMode: true }}
								/>
								{isDev && <JSONTreeView mID={record.id} />}
								{isDev && <Attachments mID={record.id} />}
							</div>
						),
					},
					...get(columnConfig, product, columnConfig.EQUOTIP),
					{
						title: 'Proceq.TableColumnVerificationResult',
						dataIndex: 'content.properties.verificationResult',
						globalRegister: 'verificationstatuses',
						filter: 'measurement.$.properties.verificationResult',
					},
				]}
				expandedRowRender={
					product === 'EQUOTIP' || product === 'SCHMIDT'
						? record => <EquotipReadings mID={record.id} product={product} isVerificationMode />
						: null
				}
			/>
		</Layout.Content>
	</Layout>
);

export default compose(
	withState('loading', 'setLoading', true),
	withState('verificationData', 'setVerificationData', []),
	withState('totalRecords', 'setTotalRecords', 0),
	withState('params', 'setParams', { fileType: 'verification', limit: 20 }),
	connect(state => ({
		product: state.session.productType,
		isDev: hasRoleLevel(state.session.scopes, ROLES.DEV),
	})),
	connect(null, (dispatch: Function, props) => ({
		loadVerificationData: newParams => {
			const params = {
				...props.params,
				fileType: 'verification',
				...newParams,
				productFamily: props.product,
			};
			props.setParams(params);
			dispatch(
				request({
					method: 'POST',
					url: URL.GET_MEASUREMENTS,
					setLoading: props.setLoading,
					params,
				})
			).then(data => {
				props.setVerificationData(data.data);
				props.setTotalRecords(data.totalRecords);
			});
		},
	})),
	lifecycle({
		componentDidMount() {
			this.props.loadVerificationData();
		},
		componentDidUpdate(prevProps) {
			if (this.props.product !== prevProps.product) {
				this.props.loadVerificationData();
			}
		},
	})
)(VerificationData);
