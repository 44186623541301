// @flow
import React, { Fragment } from 'react';
import { get } from 'lodash';
import { Table } from 'antd';
import { FormattedMessage } from 'react-intl';
import type { DerivedProps } from 'components/measurements/HTMLExport/PI8000';
import ConvertedUnits from 'components/shared/ConvertedUnits';
import styles from 'components/measurements/HTMLExport/HTMLExport.styl';
import Markers from './Markers';

const tableTitle = (pileName, positionIndex) => {
	const orderDesc = String.fromCharCode(65 + positionIndex);
	return (
		<Fragment>
			<FormattedMessage id="App.HTML.PI8000.Piles.PositionWithPileName" values={{ pileName, orderDesc }} />
		</Fragment>
	);
};

const positionLengthCalculator = (depth, velocity, samplingRate) => {
	const distance = depth.bottom - depth.top;
	if (!samplingRate || !velocity) {
		return '';
	}

	return ((distance / samplingRate) * velocity) / 2;
};

const aScanIsIncludedMaps = readings => {
	return readings.reduce((accumulator, reading) => {
		if (reading.type === 'AScan') {
			const content = reading.content;
			accumulator[content.aScanID] = content.isIncluded;
		}

		return accumulator;
	}, {});
};

const calculateTotalImpacts = (isIncludedMaps, aScanIDs) => {
	const impacts = aScanIDs.length;
	const excludedCount = aScanIDs.reduce((count, aScanID) => {
		return !isIncludedMaps[aScanID] ? count + 1 : count;
	}, 0);

	if (excludedCount > 0) {
		return (
			<FormattedMessage
				id="App.HTML.PI8000.Piles.Position.ImpactWithExcludedFromAverage"
				values={{ excludedCount, impacts }}
			/>
		);
	}

	return impacts;
};

const PilePositions = ({ data, scanType, isMetric, convert }: { data: MeasurementFullData } & DerivedProps) => {
	const piles = get(data, 'measurement.content.piles');
	const velocity = get(data, 'settings.0.content.processing.velocity');
	const samplingRate = get(data, 'settings.0.content.processing.samplingRate');
	const isIncludedMaps = aScanIsIncludedMaps(data.readings);
	const pilePositions = [];
	const derivedProps: DerivedProps = {
		scanType,
		isMetric,
		convert,
	};

	piles.forEach(pile => {
		pile.positions.forEach((position, index) => {
			const title = tableTitle(pile.name, index);
			const positionID = position.id;
			const overview = [
				{
					field: 'App.HTML.PI8000.Piles.Position.Status',
					value: position.status,
					key: 'status',
				},
				{
					field: 'App.HTML.PI8000.Piles.Position.TotalImpacts',
					value: calculateTotalImpacts(isIncludedMaps, position.aScanIDs),
					key: 'totalImpact',
				},
				{
					field: 'App.HTML.PI8000.Piles.Position.CalculatedLength',
					unitId: 'PIT_IE.CSV.CalculatedLength',
					value: positionLengthCalculator(position.depth, velocity, samplingRate),
					key: 'calculatedLength',
				},
			].map(overviewData =>
				overviewData.unitId
					? {
							...overviewData,
							value: convert(overviewData.value, overviewData.unitId),
							field: (
								<ConvertedUnits
									id={overviewData.field}
									unitId={overviewData.unitId}
									scanType={scanType}
									isMetric={isMetric}
								/>
							),
					  }
					: {
							...overviewData,
							field: <FormattedMessage id={overviewData.field} />,
					  }
			);

			pilePositions.push({ title, overview, positionID });
		});
	});

	return (
		<Fragment>
			{pilePositions.map(({ title, overview, positionID }) => (
				<Fragment key={positionID}>
					<Table
						title={() => <span className={styles.sub_header}>{title}</span>}
						className={styles.table}
						dataSource={overview}
						pagination={false}
						showHeader={false}
						columns={[
							{
								dataIndex: 'field',
								key: 'filed',
								className: styles.column,
							},
							{
								dataIndex: 'value',
								key: 'value',
							},
						]}
						size="small"
					/>
					<Markers data={data} positionID={positionID} {...derivedProps} />
					<br />
				</Fragment>
			))}
		</Fragment>
	);
};

export default PilePositions;
