// @flow
import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withState } from 'recompose';
import { Form, Input, Button } from 'antd';
import { FormattedMessage, injectIntl } from 'react-intl';
import type { InjectIntlProvidedProps } from 'react-intl';
import type { FormProps } from 'antd/lib/form';
import { request } from 'actions/ajax';
import URL from 'apis/urls';

export const GenerateDemoBundle = ({
	generateDemoBundle,
	form,
	intl,
	loading,
}: {
	generateDemoBundle: Function,
	form: FormProps,
	loading: boolean,
} & InjectIntlProvidedProps) => (
	<div>
		<Form onSubmit={generateDemoBundle(form)}>
			<Form.Item
				style={{ marginTop: '8px' }}
				label={intl.formatMessage({
					id: 'App.DevTools.GenerateDemoBundle.Label',
				})}
			>
				{form.getFieldDecorator('url', {
					rules: [
						{
							required: true,
						},
					],
				})(<Input />)}
			</Form.Item>
			<Button type="primary" htmlType="submit" loading={loading}>
				<FormattedMessage id="App.DevTools.GenerateDemoBundle.Button" />
			</Button>
		</Form>
	</div>
);

export default compose(
	withState('loading', 'setLoading', false),
	connect(null, (dispatch: Function, props) => ({
		generateDemoBundle: form => e => {
			e.preventDefault();
			form.validateFields((err, values) => {
				if (err) {
					return;
				}

				dispatch(
					request({
						method: 'POST',
						url: URL.GENERATE_DEMO_FILES,
						params: {
							urls: [values.url],
						},
						setLoading: props.setLoading,
						returnResponseHeaders: true,
						options: {
							responseType: 'arraybuffer',
						},
					})
				).then(response =>
					window.fileDownload(
						response.data,
						(response.headers['content-disposition'] || '').split('"')[1],
						response.headers['content-type']
					)
				);
			});
		},
	})),
	Form.create({}),
	injectIntl
)(GenerateDemoBundle);
