// @flow
import React from 'react';
import { LocaleProvider, Layout } from 'antd';
import { IntlProvider } from 'react-intl';
import { getSetup, getLang } from 'utils/localeHelper';
import Measurement from './Measurement';
import './compiled/index.css';
import Header from './components/Common/Header';

export const App = ({
	measurement,
	shortlang,
	users,
	version,
}: {
	measurement: MeasurementFullData,
	shortlang: LangKey,
	users: Object[],
	version: string,
}) => {
	const { lang, messages, antdLocaleProvider } = getSetup(getLang(shortlang));

	return (
		<LocaleProvider locale={antdLocaleProvider}>
			<IntlProvider locale={lang} messages={messages} key={lang}>
				<Layout>
					<Header productFamily={measurement.productFamily} version={version} />
					<br />
					<Measurement data={measurement} users={users} />
				</Layout>
			</IntlProvider>
		</LocaleProvider>
	);
};

export default App;
