// @flow
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import type { InjectIntlProvidedProps } from 'react-intl';

export const Indication = ({ intl, log }: GPRLogbookEntryProps & InjectIntlProvidedProps) => {
	const { updatedContent, statusType } = log.content;
	const position = log.content.position;

	if (statusType === 'added') {
		return (
			<FormattedMessage
				id="App.Logbook.FDL.Indication.Added"
				values={{
					position,
				}}
			/>
		);
	}
	if (statusType === 'deleted') {
		return (
			<FormattedMessage
				id="App.Logbook.FDL.Indication.Deleted"
				values={{
					position,
				}}
			/>
		);
	}

	const item = intl.formatMessage({
		id: `App.Logbook.FDL.Item.${updatedContent.item}`,
	});

	let oldValue = updatedContent.oldValue;
	let newValue = updatedContent.newValue;
	const enumUnits = ['type', 'measuringMode', 'pulseWidth', 'rf', 'triggerGate1', 'triggerGate2'];
	if (enumUnits.includes(updatedContent.item)) {
		let itemType = updatedContent.item;
		if (updatedContent.item.includes('triggerGate')) {
			itemType = 'triggerGate';
		}

		if (oldValue !== undefined) {
			oldValue = intl.formatMessage({ id: `App.Logbook.FDL.Item.${itemType}.${oldValue}` });
		}
		newValue = intl.formatMessage({ id: `App.Logbook.FDL.Item.${itemType}.${newValue}` });
	}

	return oldValue !== undefined ? (
		<FormattedMessage
			id="App.Logbook.FDL.Indication.Changed"
			values={{
				position,
				item,
				oldValue,
				newValue,
			}}
		/>
	) : (
		<FormattedMessage
			id="App.Logbook.FDL.Indication.Edited"
			values={{
				position,
				item,
				newValue,
			}}
		/>
	);
};

export const color = (log: Log) => (log.content.statusType === 'deleted' ? 'red' : 'green');

export default injectIntl(Indication);
