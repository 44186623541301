// @flow
import React from 'react';
import { Table } from 'antd';
import { FormattedMessage } from 'react-intl';
import { MeasurementScanType, TagObjectType } from 'constants/exportConstants';
import ConvertedUnits from '../../../../../shared/ConvertedUnits';
import type { DerivedProps } from '../../../Measurement';
import styles from '../../../HTMLExport.styl';
import { calculateTransmissionTime } from './Objects';

export const title = <FormattedMessage id="App.HTML.PUNDIT.VelocityObjects" />;

export const VelocityObject = ({
	data,
	scanType,
	product,
	isMetric,
	convert,
	showTitle,
}: { data: MeasurementFullData } & DerivedProps) => {
	// we only want to use the most recent log entry
	const sortedLogs = data.logs.sort((a, b) => b.clientCreated - a.clientCreated);
	const isGridScan = data.measurement.type === MeasurementScanType.GridScan;

	const objects = data.objects
		.filter(object => object.type === 'object' && object.content.type === TagObjectType.Velocity)
		.map(object => {
			const extraKeys = {};
			extraKeys.tagName = 'Pulse Velocity';

			const log = sortedLogs.find(l => +l.content.sequence === object.content.number);
			if (log) {
				extraKeys.logContent = log.content;
			}

			const reading = isGridScan ? data.readings.find(r => r.id === object.rID) : undefined;
			if (reading) {
				extraKeys.gridCell =
					String.fromCharCode('A'.charCodeAt(0) + reading.content.columnIndex) +
					(reading.content.rowIndex + 1);
			}

			const transmissionTime = isGridScan ? calculateTransmissionTime(object, data.settings) : undefined;
			if (transmissionTime !== undefined) {
				extraKeys.transmissionTime = transmissionTime < 0 ? '-' : transmissionTime;
			}

			return {
				...extraKeys,
				...object,
			};
		})
		.sort((a, b) => a.content.number - b.content.number);

	const gridScanColumns = isGridScan
		? [
				{
					title: 'App.HTML.PUNDIT.GridCell',
					dataIndex: 'gridCell',
				},
		  ]
		: [];

	return (
		<div className="table-objects">
			<Table
				title={showTitle ? () => <span className={styles.main_header}>{title}</span> : null}
				className={styles.table}
				columns={[
					{
						title: 'App.HTML.GPR.Objects.TagNumber',
						dataIndex: 'content.number',
					},
					{
						title: 'App.HTML.GPR.Objects.TagType',
						dataIndex: 'tagName',
					},
					...gridScanColumns,
					{
						title: 'App.HTML.GPR.Objects.DistanceX',
						unitId: `${product}.CSV.Tag objects distance X`,
						dataIndex: 'logContent.locationX',
					},
					{
						title: 'App.HTML.GPR.Objects.DistanceY',
						unitId: `${product}.CSV.Tag Object Distance Y`,
					},
					{
						title: 'App.HTML.PUNDIT.TransmissionTime',
						dataIndex: 'transmissionTime',
						unitId: `${product}.GridScan.TransmissionTime`,
					},
					{
						title: 'App.HTML.PUNDIT.Display.LocalVelocity',
						unitId: `${product}.CSV.Tag objects local velocity`,
						dataIndex: ['content', 'extraContent', 'velocityConfig', 'derivedLocalVelocity'],
					},
					{
						title: 'App.HTML.PUNDIT.Display.LocalDepth',
						unitId: `${product}.Logbook Panel.Set Depth`,
						dataIndex: ['content', 'extraContent', 'velocityConfig', 'localDepthMeter'],
					},

					{
						title: 'Proceq.LogbookSettingsComment',
						dataIndex: ['logContent', 'comment'],
					},
					{
						title: 'App.LogbookSettingsName',
						dataIndex: 'uID',
					},
				].map(columnConfig => ({
					// eslint-disable-next-line no-unused-vars
					render: (text, record) => (columnConfig.unitId ? convert(text, columnConfig.unitId) : text),
					...columnConfig,
					key: columnConfig.title,
					title: columnConfig.unitId ? (
						<ConvertedUnits
							id={columnConfig.title}
							unitId={columnConfig.unitId}
							scanType={scanType}
							isMetric={isMetric}
						/>
					) : (
						<FormattedMessage id={columnConfig.title} />
					),
				}))}
				dataSource={objects}
				pagination={false}
				size="small"
				rowKey="id"
			/>
		</div>
	);
};

export default VelocityObject;
