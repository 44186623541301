/* eslint-disable react/jsx-no-literals */
import React, { Component } from 'react';
import { Modal, Button, Timeline, Tooltip, Icon } from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import URL from 'apis/urls';
import { getProceqUrl } from 'utils/sessionHelper';
import SmartTable from '../../shared/SmartTable';
import styles from '../../dashboard/Settings/UserProfile.styl';

export class TrackUserProceq extends Component {
	static propTypes = {
		session: PropTypes.object,
	};

	constructor(props) {
		super(props);

		const extraActions = [
			{
				actionId: 'showtrackedlogs',
				actionType: 'form',
				actionText: 'Show tracked logs',
				apiEndpoint: getProceqUrl(this.props.session, URL.PROCEQ_SHOW_TRACK_USER_SUBMIT),
				apiTypeMethod: 'post',
				apiSendToken: true,
				apiOneRequestForEachId: true,
				titleText: 'Show tracked logs',
				titleIcon: 'search',
				fieldSets: [
					{
						title: null,
						fields: [
							{
								title: 'Username',
								name: 'username',
								type: 'input',
								styleClasses: 'force_full_field',
								validation: {
									type: 'standard',
									required: true,
								},
								onlyread: true,
							},
							{
								title: 'Date (UTC time)',
								name: 'log_date',
								type: 'date',
								styleClasses: 'force_full_field',
								validation: {
									type: 'standard',
									required: true,
								},
							},
						],
					},
				],
				multipleSelectedAllowed: false,
				oneSelectedNeeded: true,
				onSuccessForm: this.showTrackedLogs,
			},
		];

		this.state = {
			visible: false,
			date: null,
			username: '',
			logData: [],
			extraActions,
		};
	}

	showTrackedLogs = (logInfo, formFieldsValues) => {
		const records = [];
		const resultData = logInfo;

		if (resultData.length > 10) {
			const splitted = resultData.split(/[¶]/);
			for (let indexSplitted = 0; indexSplitted < splitted.length; indexSplitted++) {
				if (splitted[indexSplitted].length > 10) {
					records[indexSplitted] = JSON.parse(splitted[indexSplitted]);
				}
			}
		}

		this.setState({
			visible: true,
			date: formFieldsValues.log_date,
			username: formFieldsValues.username,
			logData: records,
		});
		return true;
	};

	handleCancel = () => {
		this.setState({ visible: false });
	};

	render() {
		const columns = [
			{
				title: 'Hidden Id',
				dataIndex: '_id',
				sorter: true,
				typeField: 'hidden',
				filter: true,
			},
			{
				title: 'User ID',
				dataIndex: 'user_id',
				sorter: false,
				filter: false,
			},
			{
				title: 'Username',
				dataIndex: 'username',
				sorter: false,
				filter: false,
			},
		];
		const { visible, date, username, logData } = this.state;
		return (
			<div>
				{visible ? (
					<Modal
						visible={visible}
						title={`${username} / ${date}`}
						onOk={this.handleOk}
						onCancel={this.handleCancel}
						width="80%"
						footer={[
							<Button key="back" size="large" onClick={this.handleCancel}>
								{'Close'}
							</Button>,
						]}
					>
						{logData.length === 0 && <div>No logs available for this day</div>}
						<Timeline>
							{logData.map(
								(logMessage, index) => (
									<Timeline.Item color={logMessage.Code === 'success' ? 'green' : 'red'} key={index}>
										<Tooltip title={logMessage.Date}>{moment(logMessage.Date).fromNow()}</Tooltip>
										<br />
										<Icon type="arrow-right" />
										&nbsp;
										{logMessage.Uri}
										&nbsp;
										<Icon type="arrow-left" />
										&nbsp;
										{logMessage.Code}
										<br />
										<Icon type="user" />
										&nbsp;
										{logMessage.Username} ({logMessage.UserId}
										)&nbsp;/&nbsp;
										<Icon type="team" />
										&nbsp; {logMessage.GroupId}
										<br />
										AccessToken: {logMessage.AccessToken} <br />
										Parameters: {JSON.stringify(logMessage.Parameters)}
									</Timeline.Item>
								),
								this
							)}
						</Timeline>
					</Modal>
				) : (
					''
				)}
				<div className={styles.profile}>
					<div className={styles.profile_container}>
						<SmartTable
							apiEndpoint={getProceqUrl(this.props.session, URL.PROCEQ_GET_TRACK_USER_LOG)}
							apiTypeMethod="post"
							apiSendToken
							columns={columns}
							columnKey="_id"
							typeSortingFiltering="proceq"
							typeRetrieveData="proceq"
							editFormInfo={this.state.editFormInfo}
							addFormInfo={this.state.addFormInfo}
							exportCSVInfo={this.state.exportCSVInfo}
							extraActions={this.state.extraActions}
							fetchAllDataOnStart={false}
						/>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	session: state.session,
	locale: state.locale,
	globalregister: state.globalregister,
});

export default connect(mapStateToProps)(TrackUserProceq);
