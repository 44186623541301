// @flow
import React from 'react';
import moment from 'moment';
import { FormattedMessage, injectIntl } from 'react-intl';
import type { InjectIntlProvidedProps } from 'react-intl';
import handleFormatMessage from 'utils/handleFormatMessage';
import { DATE_LOGBOOK_FORMAT } from 'utils/dateHandler';
import styles from '../GPR/UserText.styl';

export const UserText = ({ log, intl }: GPRLogbookEntryProps & InjectIntlProvidedProps) =>
	log.content.deletedAt ? (
		<div className={styles.deleted}>
			{handleFormatMessage('Proceq.LogbookPropertyTextCommentDeletedOn', intl.formatMessage, [
				moment(log.content.deletedAt).format(DATE_LOGBOOK_FORMAT),
			])}
		</div>
	) : (
		<div>
			<b>
				<FormattedMessage id="Proceq.LogbookPropertyTextCommentAdded" />
			</b>
			<div>{log.content.comment}</div>
		</div>
	);

export const icon = (log: Log) => (log.content.deletedAt ? 'log_deleted' : 'log');

export default injectIntl(UserText);
