// @flow
import React from 'react';
import { get } from 'lodash';
import { Table } from 'antd';
import { FormattedMessage } from 'react-intl';
import { getLabelFromGlobalDataKey } from 'utils/registryHelper';
import {
	Action,
	UserTypeOptions,
	getName,
	getEagleCareText,
	getLicenseStatus,
	AuditHistoryType,
} from './contractConsts';

const getProductLabel = (options: { text: string, value: any }[], opt: any) => {
	for (const entry of options) {
		if (entry.value === opt) {
			return entry.text;
		}
	}
	return '';
};

const isBluetooth = () => {
	const pageUrl = location && location.href ? location.href : '';
	return pageUrl.indexOf('equotip') > 0;
};

export const AuditElementInfo = ({ documentType, isProductSaas, logFirst, logSecond, products }: AuditItemProps) => {
	let historyItems = [
		{ label: 'Email', index: 'contactEmail' },
		{ label: 'Business Unit', index: 'businessUnit' },
		{ label: 'Country Sold To', index: 'countrySoldTo' },
		{ label: 'User Type', index: 'isInternal' },
		{ label: 'Product', index: 'product' },
		{ label: 'Device S/N', index: 'probeSN' },
		{ label: 'MA8000 S/N', index: 'mASN' },
		{ label: 'Wi-Fi MAC address', index: 'hardwareID' },
		{ label: 'Expiration Date', index: 'expirationDate' },
		{ label: 'BP', index: 'bp' },
		{ label: 'Remarks', index: 'remarks' },
		{ label: 'Eagle Care Used', index: 'isCareRedeemed' },
		{ label: 'Language', index: 'language' },
		{ label: 'Invoice No 1', index: 'invoiceNo' },
		{ label: 'Invoice No 2', index: 'invoiceNo2' },
		{ label: 'Invoice No 3', index: 'invoiceNo3' },
		{ label: 'Invoice No 4', index: 'invoiceNo4' },
		{ label: 'Invoice No 5', index: 'invoiceNo5' },
		{ label: 'Invoice No 6', index: 'invoiceNo6' },
	];
	if (!isProductSaas) {
		historyItems.push({ label: 'Type', index: 'tierName' });
	}

	if (AuditHistoryType.License === documentType) {
		historyItems = [
			{ label: 'Duration in Months', index: 'duration' },
			{ label: 'Status', index: 'status' },
			{ label: 'Activation Date', index: 'activationDate' },
			{ label: 'Expiraton Date', index: 'expirationDate' },
			{ label: 'Product Type', index: 'product' },
			{ label: 'Type', index: 'tier' },
		];
	}
	const isCreate = logSecond.action === Action.Created;
	const itemsArr = [
		{
			id: 'Field',
			oldIndex: isCreate ? '' : 'Old Value',
			newIndex: isCreate ? '' : 'New Value',
			enable: true,
		},
	];
	for (const item of historyItems) {
		if (item.index === 'hardwareID' && isBluetooth()) {
			item.label = 'Hardware ID';
		}

		const obj = {
			id: item.label,
			oldIndex: get(logFirst.content, item.index),
			newIndex: get(logSecond.content, item.index),
			enable: isCreate
				? get(logSecond.content, item.index) !== ''
				: get(logFirst.content, item.index) !== get(logSecond.content, item.index),
		};

		if (item.index === 'isInternal') {
			obj.oldIndex = getName(UserTypeOptions, Number(obj.oldIndex));
			obj.newIndex = getName(UserTypeOptions, Number(obj.newIndex));
		}
		if (item.index === 'isCareRedeemed') {
			obj.oldIndex = getEagleCareText(Number(obj.oldIndex));
			obj.newIndex = getEagleCareText(Number(obj.newIndex));
		}
		if (item.index === 'businessUnit') {
			obj.oldIndex = getLabelFromGlobalDataKey('buunits', obj.oldIndex);
			obj.newIndex = getLabelFromGlobalDataKey('buunits', obj.newIndex);
		}
		if (item.index === 'countrySoldTo') {
			obj.oldIndex = getLabelFromGlobalDataKey('countries', obj.oldIndex);
			obj.newIndex = getLabelFromGlobalDataKey('countries', obj.newIndex);
		}
		if (item.index === 'product') {
			obj.oldIndex = getProductLabel(products, obj.oldIndex);
			obj.newIndex = getProductLabel(products, obj.newIndex);
		}
		if (item.index === 'language') {
			obj.oldIndex = <FormattedMessage id={getLabelFromGlobalDataKey('availlanguages', obj.oldIndex)} />;
			obj.newIndex = <FormattedMessage id={getLabelFromGlobalDataKey('availlanguages', obj.newIndex)} />;
		}
		if (AuditHistoryType.License === documentType && item.index === 'status') {
			obj.oldIndex = getLicenseStatus(Number(obj.oldIndex));
			obj.newIndex = getLicenseStatus(Number(obj.newIndex));
		}
		if (item.index === 'tier') {
			obj.oldIndex = logFirst.tierName;
			obj.newIndex = logSecond.tierName;
		}

		itemsArr.push(obj);
	}

	const rows = itemsArr.filter(row => row.enable === undefined || row.enable === true);
	return (
		<Table rowKey="id" dataSource={rows} pagination={false} showHeader={false} size="small" className="to_toggle">
			<Table.Column key="label" render={(text, record) => <FormattedMessage id={record.id} />} width={230} />
			<Table.Column
				key="value"
				render={(text, record) => {
					return isCreate ? '' : record.oldIndex;
				}}
			/>
			<Table.Column
				key="value2"
				render={(text, record) => {
					return record.newIndex;
				}}
			/>
		</Table>
	);
};

export default AuditElementInfo;
