// @flow
import React, { useState, useCallback } from 'react';
import { Button } from 'antd';
import type { ButtonProps } from 'antd';

const SyncLoadingButton = (props: ButtonProps & { onSyncClick: () => Promise<void> } & any) => {
	const { onSyncClick, children } = props;
	const [loading, setLoading] = useState(false);
	const handleOnClick = useCallback(async () => {
		if (onSyncClick) {
			setLoading(true);
			try {
				await onSyncClick();
			} finally {
				setLoading(false);
			}
		}
	}, [onSyncClick]);

	return (
		<Button {...props} onClick={handleOnClick} loading={loading}>
			{children}
		</Button>
	);
};

export default SyncLoadingButton;
