// @flow
import moment from 'moment';
import URL from 'apis/urls';

export const latencyLogs = (state: Object = {}, action: Object = {}) => {
	switch (action.type) {
		case `STORE GET ${URL.EXPORT_LATENCY_LOGS}`: {
			const { params, data } = action.payload;
			const startDate = moment(params.from).format('YYYY-MM-DD');
			const endDate = moment(params.to).format('YYYY-MM-DD');

			window.fileDownload(data, `${params.product}_latency_logs_${startDate}_-_${endDate}.csv`, 'text/csv');
			return state;
		}
		default:
			return state;
	}
};
