// @flow
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { convertAndDisplay } from 'constants/conversionRates';

export const Curve = ({ log }: GPRLogbookEntryProps) => {
	const { curveType, statusType } = log.content;
	const isDGS = curveType === 'DGS';
	const isDeleted = statusType === 'deleted';
	const points = 'curveInfo' in log.content && 'points' in log.content.curveInfo ? log.content.curveInfo.points : '0';

	const deltaVk = isDGS && !isDeleted ? log.content.curveInfo.deltaVk.value : '0';
	const deltaVkUnit = isDGS && !isDeleted ? log.content.curveInfo.deltaVk.unit : 'metric';
	const ers = isDGS && !isDeleted ? log.content.curveInfo.ers.value : '0';
	const referenceType = isDGS && !isDeleted ? log.content.curveInfo.referenceType.value : '0';
	const testBlockAttenuation = isDGS && !isDeleted ? log.content.curveInfo.testBlockAttenuation.value : '0';

	return (
		<div>
			<b>
				<FormattedMessage
					id="{curveType} curve {statusType}"
					values={{
						curveType,
						statusType,
					}}
				/>
			</b>

			<br />
			{!isDGS && !isDeleted && <FormattedMessage id="Points: {value}" values={{ value: points }} />}

			{isDGS && statusType === 'created' && (
				<div>
					<FormattedMessage id="Reference Type: {value}" values={{ value: referenceType }} />
					<br />
					<FormattedMessage
						id="Test Block Attenuation: {value} {unit}"
						values={{
							value: testBlockAttenuation,
							unit: convertAndDisplay(`FDL.Curve.DGA.testBlockAttenuation`, 'Indication', deltaVkUnit)
								.unit,
						}}
					/>
					<br />
					<FormattedMessage
						id="Delta Vk: {value} {unit}"
						values={{
							value: deltaVk,
							unit: convertAndDisplay(`FDL.Curve.DGA.deltaVk`, 'Indication', deltaVkUnit).unit,
						}}
					/>
					<br />
					<FormattedMessage
						id="ERS: {value} {unit}"
						values={{
							value: ers,
							unit: convertAndDisplay(`FDL.Curve.DGA.ers`, 'Indication', deltaVkUnit).unit,
						}}
					/>
				</div>
			)}
		</div>
	);
};

export const color = (log: Log) => (log.content.statusType === 'deleted' ? 'red' : 'green');
export default Curve;
