import React from 'react';
import { FormattedMessage } from 'react-intl';
import styles from '../components/auth/LoginForm.styl';

const passwordValidator = (rule, values) => {
	if (!values) {
		return 0;
	}

	return scorePassword(values);
};

export const passwordPolicyTextArray = [
	'Proceq.PasswordPolicyMinChar',
	'Proceq.PasswordPolicyCombination',
	'Proceq.PasswordPolicyCase',
	'Proceq.PasswordPolicyNameOrEmail',
];

export function passwordPolicyToolTip(items) {
	const listItems = [];
	let ctr = 1;
	items.forEach(item => {
		const dummyKey = `policy-${ctr}`;
		listItems.push(
			<li key={dummyKey}>
				<FormattedMessage id={item} />
			</li>
		);
		ctr++;
	});
	const listAfter = (
		<div className={styles.password_tooltip}>
			<ul>{listItems}</ul>
		</div>
	);
	return listAfter;
}

export function passwordPolicyValidator(firstName, lastName, userName, password) {
	const patternRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})/;
	let isPasswordValid = true;
	if (!password.match(patternRegex)) {
		isPasswordValid = false;
	}
	const nameFields = [firstName, lastName, userName];

	const startFieldRegexStr = '^(';
	const endFieldRegexStr = '.)*$';

	// Do not allow the password to contain any value from email, firstname or lastname
	let fieldRegexStr = nameFields.reduce((regexStr, fieldValue) => {
		if (!fieldValue) {
			return regexStr;
		}
		// Get the first part if the value is an email format
		fieldValue = fieldValue.split('@', 2)[0];
		regexStr += `(?!${fieldValue})`;
		return regexStr;
	}, startFieldRegexStr);
	fieldRegexStr += endFieldRegexStr;
	const fieldRegex = new RegExp(fieldRegexStr, 'i');
	if (!(fieldRegexStr.length <= startFieldRegexStr.length + endFieldRegexStr.length || fieldRegex.test(password))) {
		isPasswordValid = false;
	}
	return isPasswordValid;
}

const scorePassword = password => {
	if (password.length < 8 || password.length > 30) {
		return 0;
	}
	let score = parseInt(password.length / 10, 10) * 10;
	const variations = {
		digits: /\d/.test(password),
		lower: /[a-z]/.test(password),
		upper: /[A-Z]/.test(password),
		nonWords: /\W/.test(password),
	};

	Object.keys(variations).forEach(check => {
		score += variations[check] ? 20 : 0;
	});

	return parseInt(score, 10);
};

export default passwordValidator;
