import React from 'react';
import styles from '../layout/Layout.styl';

export default function() {
	return (
		<svg enableBackground="new 0 0 50 50" viewBox="0 0 50 50" className={styles.icon}>
			<rect fill="none" height="50" width="50" />
			<polyline
				fill="none"
				points="15,14 36,35 25,46 25,4 36,15 15,36"
				stroke="#FFF"
				strokeMiterlimit="10"
				strokeWidth="1.9091"
			/>
		</svg>
	);
}
