// @flow
import React from 'react';
import { Table } from 'antd';
import { FormattedMessage } from 'react-intl';
import styles from 'components/measurements/HTMLExport/HTMLExport.styl';

type GLMStat = {
	name: string,
	val20: number,
	val60: number,
	valH: number,
};

export const title = <FormattedMessage id="LimitsAndStatistics" />;

export const Statistics = ({
	data,
	settings,
	showHaze,
}: {
	data: MeasurementObject,
	settings: GLMSettingsContent,
	showHaze: boolean,
}) => {
	const stats = data.content.statisticsList;
	const binList = settings.angleBinList;
	const enabled20 = binList.includes(1);
	const enabled60 = binList.includes(2);
	const enabledH = binList.includes(3) && showHaze;
	const formattedRows = [
		{
			name: 'Upper Limit',
			val20: enabled20 ? settings.limitsItems[1].high : 0,
			val60: enabled60 ? settings.limitsItems[2].high : 0,
			valH: enabledH ? settings.limitsItems[3].high : 0,
		},
		{
			name: 'Lower Limit',
			val20: enabled20 ? settings.limitsItems[1].low : 0,
			val60: enabled60 ? settings.limitsItems[2].low : 0,
			valH: enabledH ? settings.limitsItems[3].low : 0,
		},
		{
			name: 'Readings',
			val20: enabled20 ? stats[1].count + 1 : 0,
			val60: enabled60 ? stats[2].count + 1 : 0,
			valH: enabledH ? stats[3].count + 1 : 0,
		},
		{
			name: 'Average',
			val20: enabled20 ? stats[1].mean : 0,
			val60: enabled60 ? stats[2].mean : 0,
			valH: enabledH ? stats[3].mean : 0,
		},
		{
			name: 'Maximum',
			val20: enabled20 ? stats[1].maximum : 0,
			val60: enabled60 ? stats[2].maximum : 0,
			valH: enabledH ? stats[3].maximum : 0,
		},
		{
			name: 'Minimum',
			val20: enabled20 ? stats[1].minimum : 0,
			val60: enabled60 ? stats[2].minimum : 0,
			valH: enabledH ? stats[3].minimum : 0,
		},
		{
			name: 'Range',
			val20: enabled20 ? stats[1].range : 0,
			val60: enabled60 ? stats[2].range : 0,
			valH: enabledH ? stats[3].range : 0,
		},
		{
			name: 'Span',
			val20: enabled20 ? stats[1].relSpan : 0,
			val60: enabled60 ? stats[2].relSpan : 0,
			valH: enabledH ? stats[3].relSpan : 0,
		},
		{
			name: 'Standard dev',
			val20: enabled20 ? stats[1].stdDev : 0,
			val60: enabled60 ? stats[2].stdDev : 0,
			valH: enabledH ? stats[3].stdDev : 0,
		},
	];
	return (
		<div className="table-objects">
			<Table
				className={styles.table}
				columns={[
					{
						title: 'Proceq.AppPlaceholder',
						dataIndex: 'name',
						width: 40,
						enabled: true,
					},
					{
						title: '20°',
						dataIndex: 'val20',
						width: 80,
						enabled: enabled20,
					},
					{
						title: '60°',
						dataIndex: 'val60',
						width: 80,
						enabled: enabled60,
					},
					{
						title: 'H',
						dataIndex: 'valH',
						width: 60,
						enabled: enabledH,
					},
				]
					.filter(columnConfig => {
						return columnConfig.enabled;
					})
					.map(columnConfig => ({
						render: (text: number, record: GLMStat) => {
							let val = columnConfig.dataIndex === 'name' ? text : +text.toFixed(1);
							if (columnConfig.dataIndex !== 'name') val = record.name === 'Span' ? `${val}%` : val;
							return val;
						},
						...columnConfig,
						key: columnConfig.title,
						title: <FormattedMessage id={columnConfig.title} />,
					}))}
				dataSource={formattedRows}
				pagination={false}
				size="small"
				bordered
			/>
		</div>
	);
};

export default Statistics;
