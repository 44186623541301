import React from 'react';
import { Link } from 'react-router-dom';

const LinkWithExternal = ({ path, children, ...props }) => {
	const isExternal = typeof path !== 'undefined' && path.toLowerCase().startsWith('http');

	if (isExternal) {
		return (
			<a href={path} {...props} target="_blank" rel="noopener noreferrer">
				{children}
			</a>
		);
	}

	return (
		<Link to={path} {...props}>
			{children}
		</Link>
	);
};

export default LinkWithExternal;
