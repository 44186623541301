// @flow
import React, { Fragment } from 'react';
import { get } from 'lodash';
import File from 'components/measurements/HTMLExport/components/Settings/FDL/File';
import IndicationTable from 'components/measurements/HTMLExport/components/Settings/FDL/IndicationTable';
import CorrosionGrid from 'components/measurements/HTMLExport/components/Settings/FDL/CorrosionGrid';
import KeyFrameSnapshot from 'components/measurements/HTMLExport/components/Settings/FDL/KeyFrameSnapshot';
import SetupFile from 'components/measurements/HTMLExport/components/Settings/FDL/SetupFile';
import type { DerivedProps } from '../../../Measurement';

export type FDLMeasurementPresetsProps = {
	number?: number,
	showTitle?: boolean,
	unit: string,
};

export const MeasurementPresets = (props: { data: MeasurementFullData } & DerivedProps) => {
	const showTitle = !!props.showTitle;
	if (!props.data.readings) {
		return <File {...props} showTitle={showTitle} />;
	}
	const readings = props.data.readings.sort(
		(a, b) => a.content.indicationPosition.indicationPosition - b.content.indicationPosition.indicationPosition
	);

	if (readings.length < 1) {
		return <File {...props} showTitle={showTitle} />;
	}

	const scanMode = props.data.measurement.content.info.scanMode;
	const isCorrosionGrid = scanMode === 'Corrosion Map';

	const currentLine = get(props.data, 'settings.0.content.indicationStatus.currentLine.indicationPosition');
	const lastReading = readings.find(r => r.content.indicationPosition.indicationPosition === currentLine);
	const unit = get(lastReading, 'content.indicationSetupFile.unit');

	const derivedProps = {
		unit,
		showTitle,
	};

	if (isCorrosionGrid) {
		return (
			<Fragment>
				<File {...props} showTitle={showTitle} />
				<br />
				<CorrosionGrid {...props} {...derivedProps} />
				<br />
				{readings.map((reading, index) => (
					<Fragment key={reading.id}>
						<SetupFile
							setupFile={reading.content.indicationSetupFile}
							number={index + 1}
							showTitle={showTitle}
							unit={unit}
						/>
						<br />
					</Fragment>
				))}
			</Fragment>
		);
	}

	return (
		<Fragment>
			<File {...props} showTitle={showTitle} />
			<br />
			<IndicationTable readings={readings} {...derivedProps} />
			<br />
			{readings.map(reading => (
				<KeyFrameSnapshot
					key={reading.id}
					reading={reading}
					log={props.data.logs.find(
						log =>
							log.content.attachmentId &&
							log.content.attachmentId ===
								reading.content.indicationSettings.info.lastSnapshotAttachmentID
					)}
					{...derivedProps}
				/>
			))}
		</Fragment>
	);
};

export default MeasurementPresets;
