// @flow
import React from 'react';

export const Logo = () => (
	<img
		style={{ position: 'absolute', right: 24, maxHeight: 40 }}
		src={require('../../../../../../public/img/proceq/logo.png')}
		alt="logo"
	/>
);

export default Logo;
