// @flow
import React from 'react';
import { timeToLocaleTime } from 'utils/dateHandler';

const renderTime = (text: string) => <span>{timeToLocaleTime(text)}</span>;

export default {
	GPR: [],
	PUNDIT: [],
	UCI: [],
	EQUOTIP: [
		{
			title: 'Proceq.TableBaseMaterial',
			dataIndex: 'content.baseMaterialId',
			globalRegister: 'materials',
		},
		{
			title: 'Proceq.TableScaleUnit',
			dataIndex: 'content.targetScaleId',
			globalRegister: 'units',
		},
		{
			title: 'Proceq.TableCreatedOn',
			dataIndex: 'created',
			sorter: true,
			render: renderTime,
		},
	],
	SCHMIDT: [
		{
			title: 'Proceq.TableScaleUnit',
			dataIndex: 'content.scaleId',
			globalRegister: 'unitsschmidt',
		},
		{
			title: 'Proceq.ExportFieldCurveType',
			dataIndex: 'content.curveTypeId',
			globalRegister: 'schmidtcurvetypesshort',
		},
		{
			title: 'Proceq.TableCreatedOn',
			dataIndex: 'created',
			sorter: true,
			render: renderTime,
		},
	],
};
