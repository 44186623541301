import { GLOBAL_REGISTERED_INFO } from 'constants/global-registered-info';
import actionTypes from 'actions/actionTypes';

const initialState = {
	registeredKeyValues: [],
};

export function globalregister(state = initialState, action) {
	switch (action.type) {
		case actionTypes.SET_KEY_VALUE: {
			const newRegisteredKeyValues = {
				...state.registeredKeyValues,
				[action.value[0]]: action.value[1],
			};
			return {
				...state,
				registeredKeyValues: newRegisteredKeyValues,
			};
		}
		case actionTypes.INIT_GLOBAL_REGISTER: {
			const initRegisteredKeyValues = {};
			GLOBAL_REGISTERED_INFO.forEach(item => {
				initRegisteredKeyValues[item.key] = item.data;
			});
			return {
				...state,
				registeredKeyValues: initRegisteredKeyValues,
			};
		}
		default:
			return state;
	}
}
