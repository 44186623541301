// @flow
import URL from 'apis/urls';
import { parseJwt } from './authUtils';

export function isBearerTokenSet(session: Session) {
	return !!session.bearerToken;
}

/**
 * Get top-down role hierarchy, aka the higher roles a user has
 * Good for display
 */
export const retrieveNormalizedRoles = (roles: string[]) => {
	if (roles.indexOf('super')) {
		return ['super'];
	}

	if (roles.indexOf('admin')) {
		return ['admin'];
	}

	const normalizedRoles = [];
	if (roles.indexOf('po') > -1) {
		normalizedRoles.push('po');
	} else if (roles.indexOf('bu') > -1) {
		normalizedRoles.push('bu');
	}

	['users', 'dev'].forEach(role => {
		if (roles.indexOf(role) > -1) {
			normalizedRoles.push(role);
		}
	});

	return normalizedRoles;
};

export function hasAtLeastOneRole(session: Session, checkRoles: string[]) {
	if (!session.userRoles) {
		return false;
	}

	if (session.userRoles.indexOf('super') >= 0 || session.userRoles.indexOf('master_proceq') >= 0) {
		return true;
	}

	const userRoles = session.userRoles;
	if (userRoles.indexOf('master_proceq') >= 0) {
		userRoles.push('admin_proceq', 'snassigner_proceq', 'developer_proceq', 'client_proceq');
	}
	if (checkRoles.length === 0) {
		return true;
	}

	/* Look for negative roles */
	for (let indexRole = 0; indexRole < checkRoles.length; indexRole++) {
		const role = checkRoles[indexRole];

		/* Skip non-negative roles */
		if (role.substring(0, 1) === '!') {
			const toCheckRole = role.substring(1);
			if (userRoles.indexOf(toCheckRole) >= 0 || userRoles.indexOf(`${toCheckRole}_proceq`) >= 0) {
				return false;
			}
		}
	}

	const findOneRole = checkRoles.some(v => {
		if (v.substring(0, 1) === '!') {
			return false;
		}
		if (v.substring(0, 1) === '*') {
			if (v.length === 1) {
				return true;
			}
			for (let indexUserRole = 0; indexUserRole < userRoles.length; indexUserRole++) {
				const userRole = userRoles[indexUserRole];
				if (userRole.indexOf(v.substring(1)) >= 0) {
					return true;
				}
			}
		}
		return userRoles.indexOf(v) >= 0 || userRoles.indexOf(`${v}_proceq`) >= 0;
	});
	return findOneRole;
}

export function getNonMngtUrl(session: Session, url: string) {
	if (session.regionalDomain) {
		return url.replace('{non_mngt_url}', session.regionalDomain);
	}
	return null;
}
export function getViewSharedToken(session: Session) {
	if (isBearerTokenSet(session) && session.bearerToken.indexOf('SH') === 0) {
		return session.bearerToken.substring(2, 3);
	}
	return false;
}
export function isUsingSharedToken(session: Session) {
	if (
		(isBearerTokenSet(session) && session.bearerToken.indexOf('SH') === 0) ||
		(window.location.href.indexOf('d=') > -1 && window.location.href.indexOf('k=') > -1)
	) {
		return true;
	}
	return false;
}
export function getProceqUrl(session: ?Session, url: string, overrideApiUrl: string | null = null) {
	if (overrideApiUrl !== null) {
		return url.replace('{proceq_api_url}', overrideApiUrl);
	}
	if (session && session.regionalDomain) {
		return url.replace('{proceq_api_url}', session.regionalDomain);
	}
	return url.replace('{proceq_api_url}', URL.DEFAULT_PROCEQ_API_URL);
}

export function deleteCookie(name: string) {
	setCookie(name, '', -1);
}

export function deleteAllCookies() {
	document.cookie.split(';').forEach(cookie => {
		const eqPos = cookie.indexOf('=');
		deleteCookie(eqPos > -1 ? cookie.substr(0, eqPos) : cookie);
	});
}

export function enableCookies() {
	document.cookie = 'cookiesAccepted=1;';
}

export function isProceqToken(token: string) {
	if (!token) {
		return false;
	}
	const platform = token.substring(token.length - 3, token.length - 2);
	if (platform === 'P') {
		return true;
	}
	return false;
}
export function getApiUrlFromSharedToken(sharedToken: string) {
	const region = sharedToken.substring(sharedToken.length - 2);
	if (region in URL.PROCEQ_REGIONS_API_URLS) {
		return URL.PROCEQ_REGIONS_API_URLS[region];
	}
	return URL.PROCEQ_REGIONS_API_URLS.EU;
}
export function restoreSessionFromSharedToken(sharedToken: string) {
	let toReturn = {};
	toReturn = {
		productType: 'EQUOTIP',
		sessionType: 'PROCEQ',
		bearerToken: sharedToken,
		regionalDomain: getApiUrlFromSharedToken(sharedToken), // 'http://192.168.201.8/api/', // Depends on the last 2 chars of the token
		managementDomain: getApiUrlFromSharedToken(sharedToken), // 'http://192.168.201.8/api/' // Depends on the last 2 chars of the token
	};
	return toReturn;
}
export function restoreSessionFromOneTimeToken(oneTimeToken: string) {
	let toReturn = {};
	toReturn = {
		productType: 'EQUOTIP',
		sessionType: 'PROCEQ',
		bearerToken: oneTimeToken,
		regionalDomain: getApiUrlFromSharedToken(oneTimeToken), // 'http://192.168.201.8/api/', // Depends on the last 2 chars of the token
		managementDomain: getApiUrlFromSharedToken(oneTimeToken), // 'http://192.168.201.8/api/' // Depends on the last 2 chars of the token
	};
	return toReturn;
}

export function saveLastProductLogin(productType: ProductCode) {
	setCookie('lastProduct', productType, 100000 * 60 * 60 * 1000);
}
export function saveLastLanguage(language: ShortLangKey) {
	setCookie('lastLanguage', language, 100000 * 60 * 60 * 1000);
}
export function getLastLanguage() {
	const foundCookieValue = getCookie('lastLanguage');
	if (foundCookieValue === '') {
		return null;
	}
	return foundCookieValue;
}
export function getLastProductLogin(): any {
	const foundCookieValue = getCookie('lastProduct');
	if (foundCookieValue === '') {
		return null;
	}
	return foundCookieValue;
}

export function setCookie(name: string, value: string, expireHours: number) {
	const d = new Date();
	d.setTime(d.getTime() + expireHours);
	document.cookie = `${name}=${value}; expires=${d.toUTCString()}`;
}
export function getCookie(name: string) {
	const ca = document.cookie.split(';');
	const caLen = ca.length;
	const cookieName = `${name}=`;
	let c;
	for (let i = 0; i < caLen; i += 1) {
		c = ca[i].replace(/^\s\+/g, '');
		const posFound = c.indexOf(cookieName);
		if (posFound >= 0) {
			return c.substring(cookieName.length + posFound, c.length);
		}
	}
	return '';
}

export function trackUserInSentry(id: number, email: string) {
	if (window.Sentry) {
		window.Sentry.configureScope(scope => {
			scope.setUser({
				id,
				email,
			});
		});
	}
}

interface EagleIDTokenResponse {
	access_token: string;
	expires_in: number;
	id_token: string;
	refresh_token: string;
	scope: string;
	token_type: string;
}

interface IDToken {
	sub: string;
	pqRegion: string;
	pqRegionalDomain: string;
	pqManagementDomain: string;
	email: string;
}

export function convertTokenResponseToSession(token: EagleIDTokenResponse, roleString: string = '') {
	const { userRoles, groupID, scopes } = getRoleInfo(roleString);
	const { pqRegion, pqRegionalDomain, pqManagementDomain, sub, email }: IDToken = parseJwt(token.id_token);
	const productTypes = ['GPR'];

	return {
		bearerToken: token.access_token,
		userID: Number(sub),
		userRoles,
		groupsRoles: {
			[groupID]: userRoles,
		},
		scopes,
		groupId: groupID,
		productType: productTypes[0],
		productTypes,
		regionalDomain: pqRegionalDomain,
		managementDomain: pqManagementDomain,
		sessionType: 'TDS',
		serverRegion: pqRegion,
		email,
	};
}

export function getRoleInfo(roleString: string) {
	const roleArray = roleString.split(',').filter(Boolean);
	const userRoles = [];
	let groupID = '';

	if (roleArray.indexOf('super') >= 0) {
		userRoles.push('master_proceq');
		groupID = 'proceq';
	}
	if (roleArray.indexOf('admin') >= 0) {
		userRoles.push('admin_proceq');
		groupID = 'proceq';
	}
	if (roleArray.indexOf('po') >= 0) {
		userRoles.push('po_proceq');
		groupID = 'proceq';
	}
	if (roleArray.indexOf('bu') >= 0) {
		userRoles.push('bu_proceq');
		groupID = 'proceq';
	}
	if (roleArray.indexOf('user') >= 0) {
		userRoles.push('master_client');
	}
	if (roleArray.indexOf('dev') >= 0) {
		userRoles.push('developer_proceq');
		groupID = 'proceq';
	}

	return {
		userRoles,
		groupID,
		scopes: roleArray,
	};
}
