// @flow
import React from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { compose, withState, withProps, lifecycle } from 'recompose';
import { FormattedMessage } from 'react-intl';
import { Table, Button, Icon, Form, Input } from 'antd';
import type { FormProps } from 'antd/lib/form';
import { hasRoleLevel, ROLES } from 'utils/rolesHelper';
import URL from 'apis/urls';
import { request } from 'actions/ajax';
import { getLabelFromGlobalDataKey } from 'utils/registryHelper';
import { renderCell } from '../shared/SmarterTable';
import tableStyles from '../shared/SmarterTable.styl';

export const Versions = ({
	loading,
	versions,
	editingKey,
	form,
	saveVersion,
	edit,
	cancel,
	isAdmin,
}: {
	loading: boolean,
	versions: {
		key: string,
		version: string,
		productDisplayName: string,
		product: ProductCode,
		client: 'android' | 'ios',
	}[],
	editingKey: string,
	form: FormProps,
	saveVersion: Function,
	edit: Function,
	cancel: Function,
	isAdmin: Boolean,
}) => {
	const isEditing = record => record.key === editingKey;

	return (
		<Form>
			<Table dataSource={versions} pagination={false} loading={loading}>
				<Table.Column dataIndex="productDisplayName" title={<FormattedMessage id="App.ProductApp" />} />
				<Table.Column
					dataIndex="client"
					title={<FormattedMessage id="Proceq.ExportColumnAppPlatform" />}
					render={renderCell({
						globalRegister: 'platforms',
					})}
				/>
				<Table.Column
					dataIndex="version"
					title={<FormattedMessage id="App.MinAppVersion" />}
					render={(_, record) => {
						return isEditing(record) ? (
							<Form.Item name="version">
								{form.getFieldDecorator('version', {
									initialValue: record.version,
									rules: [
										{
											required: true,
										},
									],
								})(<Input />)}
							</Form.Item>
						) : (
							record.version
						);
					}}
				/>
				<Table.Column
					render={(_, record) => {
						if (!isAdmin) {
							return '';
						}
						return isEditing(record) ? (
							<div>
								<Button
									className={classNames(tableStyles.button, tableStyles.inline)}
									onClick={() => saveVersion(record)}
									type="primary"
								>
									<Icon type="save" />
								</Button>
								<Button
									className={classNames(tableStyles.button, tableStyles.inline)}
									onClick={() => cancel()}
								>
									<Icon type="cross" />
								</Button>
							</div>
						) : (
							<Button
								className={classNames(tableStyles.button, tableStyles.inline)}
								onClick={() => edit(record)}
								disabled={editingKey !== null}
							>
								<Icon type="edit" />
							</Button>
						);
					}}
				/>
			</Table>
		</Form>
	);
};

export default compose(
	withState('loading', 'setLoading', true),
	withState('versions', 'setVersions', []),
	withState('editingKey', 'setEditingKey', null),
	Form.create({}),
	connect(
		state => ({
			isAdmin: hasRoleLevel(state.session.scopes, ROLES.ADMIN),
		}),
		(dispatch: Function) => ({
			loadVersion: (product, client) =>
				dispatch(
					request({
						url: URL.GET_VERSIONS,
						params: { product, client },
					})
				).then(version => ({
					key: `${product}${client}`,
					productDisplayName: getLabelFromGlobalDataKey('productversions', product.toUpperCase()),
					product,
					client,
					...version,
				})),
			updateVersion: (product, client, version) => {
				return dispatch(
					request({
						url: URL.UPDATE_VERSION,
						method: 'PUT',
						params: { product, client, version },
					})
				);
			},
		})
	),
	withProps(({ setLoading, setVersions, loadVersion }) => ({
		loadVersions: () => {
			setLoading(true);
			Promise.all(
				[
					['equotip', 'ios'],
					['fdl', 'ios'],
					['glm', 'ios'],
					['schmidt', 'android'],
					['schmidt', 'ios'],
					['paperschmidt', 'android'],
					['paperschmidt', 'ios'],
					['gpr', 'ios'],
					['profometer', 'ios'],
					['pit_ie', 'ios'],
					['pundit', 'ios'],
					['gpr_soil', 'ios'],
					['gpr_mounted', 'ios'],
					['gpr_solo', 'ios'],
				].map(([product, client]) => loadVersion(product, client))
			)
				.then(versions => setVersions(versions))
				.finally(() => setLoading(false));
		},
	})),
	withProps(({ setEditingKey }) => ({
		edit: record => {
			setEditingKey(record.key);
		},
		cancel: () => {
			setEditingKey(null);
		},
	})),
	withProps(({ setLoading, setEditingKey, updateVersion, form, versions, setVersions }) => ({
		saveVersion: record => {
			setLoading(true);
			form.validateFields((err, row) => {
				if (err) {
					return;
				}
				const newRecord = { ...record, ...row };
				const { key, product, client, version: newVersion } = newRecord;
				const index = versions.findIndex(data => key === data.key);
				if (index < -1) {
					return;
				}
				const newVersions = [...versions];
				newVersions[index] = newRecord;
				updateVersion(product, client, newVersion)
					.then(() => {
						setVersions(newVersions);
					})
					.finally(() => {
						setEditingKey(null);
						setLoading(false);
					});
			});
		},
	})),
	lifecycle({
		componentDidMount() {
			this.props.loadVersions();
		},
	})
)(Versions);
