// @flow

export const parseQuery = (queryString: string, decodeURI: boolean = true) => {
	const query: any = {};
	const pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');
	for (let i = 0; i < pairs.length; i++) {
		const pair = pairs[i].split('=');
		const key = decodeURI ? decodeURIComponent(pair[0]) : pair[0];
		query[key] = decodeURI ? decodeURIComponent(pair[1] ?? '') : pair[1] ?? '';
	}
	return query;
};

export const makeQueryPath = (
	path: string,
	query: { [key: string]: string | number },
	encode: boolean = true
): string => {
	const keys = Object.keys(query).filter(key => query[key]);
	if (keys.length === 0) {
		return path;
	}
	return keys.reduce((newPath, key, i) => {
		const value = encode ? window.encodeURIComponent(query[key] ?? '') : query[key];
		return `${newPath}${key}=${value}${i < keys.length - 1 ? '&' : ''}`;
	}, `${path}?`);
};

export const removeQueryKeys = (path: string, queryString: string, keys: string[]): string => {
	const query = parseQuery(queryString, false);
	keys.forEach(key => {
		delete query[key];
	});
	return makeQueryPath(path, query, false);
};
