// @flow
import React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import type { InjectIntlProvidedProps } from 'react-intl';
import { injectIntl } from 'react-intl';
import { Icon } from 'antd';
import type { ContextRouter } from 'react-router';
import { withRouter } from 'react-router';
import { hasAtLeastOneRole, isUsingSharedToken, getViewSharedToken } from 'utils/sessionHelper';
import { legacyRoutes } from '../../../routes/config';
import styles from './SideNav.styl';
import NavItem from './NavItem';
import SettingsIcon from '../../svgIcons/Settings';

const SideNav = ({
	session,
	intl: { formatMessage },
	location,
}: { session: Session } & InjectIntlProvidedProps & ContextRouter) => (
	<div className={styles.container} data-e2e-nav>
		{legacyRoutes
			.filter(({ hideForServerRegions, rolesNeeded, ifGuestOnlyShowIfView }) => {
				// Check if the item should be hide for the server region
				if (hideForServerRegions && hideForServerRegions.indexOf(session.serverRegion) > -1) {
					return false;
				}

				if (rolesNeeded && !hasAtLeastOneRole(session, rolesNeeded)) {
					return false;
				}

				if (isUsingSharedToken(session) && getViewSharedToken(session) !== ifGuestOnlyShowIfView) {
					return false;
				}

				return true;
			})
			.map(({ path, iconString, label }) => (
				<NavItem
					key={label}
					badge={null}
					label={formatMessage({ id: label })}
					selected={path === location.pathname}
					route={{ pathname: path }}
					iconSrc={iconString ? <Icon type={iconString} /> : <SettingsIcon />}
				/>
			))}
	</div>
);

export default compose(
	connect(state => ({
		session: state.session,
	})),
	withRouter,
	injectIntl
)(SideNav);
