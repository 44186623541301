// Async validator that verifies if the passed in value is
// an email address
// Works best with https://ant.design/components/select/

const emailValidator = (rule, values, callback) => {
	if (!values) {
		callback('Please input email addresses');
		return;
	}

	const emailRegex = /^([a-z0-9_.-]+)@([\da-z.-]+)\.([a-z.]{2,6})$/;
	const invalidInputs = values.filter(value => !value.match(emailRegex));
	if (invalidInputs.length === 0) {
		callback();
	} else if (invalidInputs.length === 1) {
		callback(`${invalidInputs.join('')} is not a valid email`);
	} else {
		callback(`${invalidInputs.slice(0, -1).join(', ')}  and ${invalidInputs.slice(-1)} are not valid emails`);
	}
};

export default emailValidator;
