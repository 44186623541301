module.exports = {
	shortlang: 'en',
	lang: 'en',
	messages: {
		'Calibration Date': 'Calibration Date',
		'Proceq.Enabled': 'Enabled',
		'Proceq.NotEnabled': 'Not Enabled',
		'Proceq.ErrorWrongUsername': 'Invalid email provided',
		'Proceq.ErrorCreationNotAllowed': 'Creation of new accounts has been disabled',
		'Proceq.ErrorWrongPassword': 'Invalid password provided',
		'Proceq.ErrorWrongFirstName': 'Invalid first name provided',
		'Proceq.ErrorWrongLastName': 'Invalid last name provided',
		'Proceq.ErrorWrongSerialLicenseNumber': 'Invalid serial/license number provided',
		'Proceq.ErrorUsernameAlreadyExists': 'An account with the same e-mail already exists',
		'Proceq.ErrorDB': 'Error performing a database operation',
		'Proceq.InvalidUsername': 'User account could not be found',
		'Proceq.AlreadyMultipleForgot': 'Already multiple reset passwords requested',
		'Proceq.InvalidNewPassword': 'Invalid new password provided',
		'Proceq.InvalidResetCode': 'Invalid password reset code or already expired',
		'Proceq.InvalidOldPassword': 'Invalid old password provided',
		'Proceq.InvalidAccessRights': 'Session expired or user is not allowed to perform this action',
		'Proceq.ErrorNoAccountType': 'No account type provided',
		'Proceq.ErrorNoUsername': 'No email provided',
		'Proceq.InvalidCountry': 'Invalid country provided',
		'Proceq.ErrorCreatingUser': 'Error creating new user',
		'Proceq.InvalidID': 'Invalid ID provided',
		'Proceq.InvalidTransactionID': 'Invalid transaction ID',
		'Proceq.InvalidFileContent': "Error reading file's content",
		'Proceq.InvalidTypeEntry': 'Invalid entry type',
		'Proceq.InvalidContractID': 'Contract is invalid or has expired',
		'Proceq.InvalidExistingID': 'ID can not be created since it already exists',
		'Proceq.InvalidDataSeriesType': 'Invalid series type',
		'Proceq.InvalidMetaDataContent': 'Invalid meta data content',
		'Proceq.ErrorFileIsLocked': 'One or more files are locked. Try again later',
		'Proceq.MissingAttachmentFiles': 'Attachment files can not be found on the server',
		'Proceq.InvalidFlagType': 'Invalid or missing flag type',
		'Proceq.MissingDateTypeFields': 'Missing required fields for series',
		'Proceq.InvalidFolderID': 'Invalid folder ID',
		'Proceq.InvalidDataSeriesName': 'Invalid series name',
		'Proceq.ErrorDateFormat': 'Invalid date format provided',
		'Proceq.ErrorDataFormat': 'Data from series can not be parsed',
		'Proceq.InvalidDownloadID': 'Invalid download token, or it already expired',
		'Proceq.InvalidVersionNr': 'Invalid version number provided',
		'Proceq.ErrorDataMissing': 'Data can not be recovered from the storage system',
		'Proceq.ErrorSavingFile': 'Server could not save the file',
		'Proceq.ErrorInsertDB': 'Error while inserting on the DB',
		'Proceq.InvalidFolderName': 'Invalid folder name provided',
		'Proceq.InvalidActionID': 'Invalid or expired URL link',
		'Proceq.AlreadyConfirmed': 'Account has been already verified',
		'Proceq.InvalidActionType': 'The requested action can not be processed',
		'Proceq.ErrorGeneratingChart': 'Error generating the charts',
		'Proceq.InvalidSerialID': 'Invalid Serial ID',
		'Proceq.InvalidHardwareID': 'Invalid Hardware ID',
		'Proceq.InvalidEntityID': 'Invalid object ID or it has been deleted',
		'Proceq.InvalidEntityType': 'Invalid object type',
		'Proceq.InvalidEntityVerNr': 'Invalid object version number',
		'Proceq.ErrorTransactionLocked': 'The object is locked and can not be modified',
		'Proceq.InvalidDateValue': 'Date can not be parsed',
		'Proceq.MissingFields': 'One or more required fields are missing',
		'Proceq.ErrorColumnValue': 'Wrong value provided for one or more fields',
		'Proceq.MissingUidDBValue': 'Missing entry ID',
		'Proceq.ErrorFileID': 'Invalid file ID provided',
		'Proceq.ErrorInsertDownload': 'Error creating download',
		'Proceq.InvalidAppVersion': 'Your current app version is no longer supported. Please update it',
		'Proceq.MissingUserInfo': 'Missing user information',
		'Proceq.InvalidAccessToken': 'Invalid or expired session token',
		'Proceq.ErrorGeneratingCSV': 'Error generating CSV export',
		'Proceq.ErrorGeneratingExport': 'Error generating export',
		'Proceq.MissingParams': 'One or more of the required parameters are missing',
		'Proceq.SerialNotFound': 'No contract found for this device',
		'Proceq.StandardEnumJapan': 'Japan',
		'Proceq.StandardEnumDefault': 'Default',
		'Proceq.MaterialEnumIndependent': 'Material independent',
		'Proceq.MaterialEnumSteelAndCastSteel': 'Steel and cast steel',
		'Proceq.MaterialEnumWorkToolSteel': 'Work tool steel',
		'Proceq.MaterialEnumStainlessSteel': 'Stainless steel',
		'Proceq.MaterialEnumGrayCastIron': 'Gray cast iron (GG)',
		'Proceq.MaterialEnumNodularCastIron': 'Nodular cast iron (GGG)',
		'Proceq.MaterialEnumCastAlluminiumAlloys': 'Cast aluminium alloys',
		'Proceq.MaterialEnumBrassCopperZincAlloys': 'Brass - Copper/Zinc alloys',
		'Proceq.MaterialEnumBronze': 'Bronze',
		'Proceq.MaterialEnumWroughtCopperAlloys': 'Wrought copper alloys',
		'Proceq.MaterialEnumTitanium': 'Titanium',
		'Proceq.ScaleEnumCustom': 'Custom',
		'Proceq.ScaleEnumPenetrationDepth': 'Penetration depth',
		'Proceq.CompanySizeMicro': '1-9 Employees',
		'Proceq.CompanySizeSmall': '10-49 Employees',
		'Proceq.CompanySizeMedium': '50-249 Employees',
		'Proceq.CompanySizeLarge': '>249 Employees',
		'Proceq.SalutationMr': 'Mr.',
		'Proceq.SalutationMs': 'Ms.',
		'Proceq.SalutationDr': 'Dr.',
		'Proceq.SalutationProf': 'Prof.',
		'Proceq.CategoryAerospace': 'Aerospace',
		'Proceq.CategoryAutomotive': 'Automotive',
		'Proceq.CategoryChemicalProcessing': 'Chemical Processing',
		'Proceq.CategoryConstructionBuilding': 'Construction/Building',
		'Proceq.CategoryConsultantTestLabsEngineer': 'Consultant/Test Labs/Engineer',
		'Proceq.CategoryEducationInstituteRD': 'Education/Insitute/R&D',
		'Proceq.CategoryFilmProducerConverter': 'Film Producer/Converter',
		'Proceq.CategoryGeologyRocktesters': 'Geology/Rocktesters',
		'Proceq.CategoryMachineryToolingEquipment': 'Machinery/Tooling/Equipment',
		'Proceq.CategoryMassTransportation': 'Mass Transportation',
		'Proceq.CategoryMetalWorks': 'Metal Works',
		'Proceq.CategoryOilAndGas': 'Oil & Gas',
		'Proceq.CategoryOthers': 'Others',
		'Proceq.CategoryPaperProducerConverter': 'Paper Producer/Converter',
		'Proceq.CategorySalesChannel': 'Sales Channel',
		'Proceq.ErrorWrongLoginCredentials': 'The username or password you entered is incorrect',
		'Proceq.ErrorEmailNotVerified': 'Email has not been verified yet',
		'Proceq.ServerError': 'Server Error',
		'Proceq.TooManyActionEmailRequests': 'Too many requests. Please wait for the emails to arrive in your mailbox',
		'Proceq.ErrorServerUnderMaintenance': 'Server is under maintenance',
		'Proceq.TableDataSeriesName': 'Name',
		'Proceq.TableCreatedOn': 'Created On',
		'Proceq.TableModifiedOn': 'Modified On',
		'Proceq.TableCreatedBy': 'Created By',
		'Proceq.TableNumMean': 'Mean',
		'Proceq.TableNumTotal': 'Total',
		'Proceq.TableNumStandardDeviation': 'Std. dev',
		'Proceq.TableImpactDevice': 'Probe',
		'Proceq.TableImpactDeviceHammer': 'Hammer',
		'Proceq.TableMaterial': 'Material',
		'Proceq.TableScaleUnit': 'Scale Unit',
		'Proceq.TableActionRefresh': 'Refresh',
		'Proceq.TableActionExpand': 'Expand',
		'Proceq.TableActionCollapse': 'Collapse',
		'Proceq.TableActionExportAsPDF': 'Export as PDF',
		'Proceq.TableActionExportAsCSV': 'Export as CSV',
		'Proceq.TableActionExportAsJSON': 'Export as JSON',
		'Proceq.TableActionExportKML': 'KML',
		'Proceq.TableActionExportSEGY': 'SEGY',
		'Proceq.TableActionExportHTML': 'HTML',
		'Proceq.TableActionExportDXF': 'DXF',
		'Proceq.TableActionExportSHP': 'SHP',
		'Proceq.TableActionExportJSON': 'JSON',
		'Proceq.TableActionExportRaw': 'Raw Data',
		'Proceq.SearchOperationEquals': 'Equals',
		'Proceq.SearchOperationContains': 'Contains',
		'Proceq.SearchOperationDifferent': 'Different',
		'Proceq.SearchOperationGreaterOrEqual': 'Greater or equal',
		'Proceq.SearchOperationGreater': 'Greater',
		'Proceq.SearchOperationLesserOrEqual': 'Lesser or equal',
		'Proceq.SearchOperationLesser': 'Lesser',
		'Proceq.ExportFieldExportDate': 'Export Date',
		'Proceq.ExportFieldLimits': 'Limits',
		'Proceq.ExportFieldHigh': 'High',
		'Proceq.ExportFieldLow': 'Low',
		'Proceq.ExportFieldOff': 'Off',
		'Proceq.ExportFieldLowerLimit': 'Lower Limit',
		'Proceq.ExportFieldUpperLimit': 'Upper Limit',
		'Proceq.ExportFieldReadings': 'Readings',
		'Proceq.ExportFieldStandardDeviation': 'Standard deviation',
		'Proceq.ExportFieldRange': 'Range',
		'Proceq.ExportFieldRelativeSpan': 'Relative Span',
		'Proceq.ExportFieldMaximum': 'Maximum',
		'Proceq.ExportFieldMinimum': 'Minimum',
		'Proceq.ExportFieldAverage': 'Average',
		'Proceq.ExportFieldUnit': 'Unit',
		'Proceq.ExportFieldName': 'Name',
		'Proceq.ExportFieldMeasurementDate': 'Measurement Date',
		'Proceq.ExportFieldLocationLatitude': 'Location Latitude',
		'Proceq.ExportFieldLocationLongitude': 'Location Longitude',
		'Proceq.ExportFieldProbeType': 'Probe Type',
		'Proceq.ExportFieldProbeSerialNumber': 'Probe Serial Number',
		'Proceq.ExportFieldProbeBTSerialNumber': 'Probe Bluetooth Serial Number',
		'Proceq.ExportFieldProbeLastVerificationDate': 'Probe Last Verification Date',
		'Proceq.ExportFieldPrimaryScale': 'Primary Scale',
		'Proceq.ExportFieldSecondaryScale': 'Secondary Scale',
		'Proceq.ExportFieldMaterial': 'Material',
		'Proceq.ExportFieldStandard': 'Standard',
		'Proceq.ExportFieldNoConversion': 'No Conversion',
		'Proceq.ExportFieldMeasurements': 'Measurements',
		'Proceq.ExportFieldMore': 'More',
		'Proceq.ExportFieldLess': 'Less',
		'Proceq.ExportFieldStatistics': 'Statistics',
		'Proceq.ExportFieldSettings': 'Settings',
		'Proceq.DataSeriesLogbook': 'Logbook',
		'Proceq.DataSeriesAllMeasurements': 'All Measurements',
		'Proceq.DataSeriesFlagged': 'Flagged',
		'Proceq.DataSeriesTrash': 'Trash',
		'Proceq.BrowserNoSupportAudio': 'Your browser does not support audio files.',
		'Proceq.EmailPasswordRecoverySubject': 'Password change request for **?0**',
		'Proceq.EmailPasswordRecoveryBody':
			'Dear **?0** **?1**<br/><br/>You have requested a new password. To reset your password please click on the link below.<br/><br/><a href="**?2**">Reset password</a><br/><br/>If you haven’t requested to change your password, please ignore this message. It will expire in 12 hours.<br/><br/>Your Proceq Team<br/><br/>**?3**',
		'Proceq.EmailRegistrationSubject': 'Registration completion for **?0**',
		'Proceq.EmailRegistrationBody':
			'Dear **?0** **?1**<br/><br/>Thank you for registering on **?5**. Please complete your registration by clicking on the link below:<br/><br/><a href="**?2**">**?2**</a><br/><br/>This e-mail was created automatically as you have filled out the registration. If you did not do this registration, please ignore this message.<br/><br/> On completing the registration you agree to our <a href="**?3**">privacy policy</a>.<br/><br/>Your Proceq Team<br/><br/>**?4**',
		'Proceq.LogbookActionNewMeasurement': 'New measurement',
		'Proceq.LogbookPropertyCreatedSeriesInFolder': 'Created Series "**?0**" in folder "**?1**"',
		'Proceq.LogbookPropertyRenamedSeriesFromTo': 'Series was renamed from "**?0**" to "**?1**"',
		'Proceq.LogbookPropertyMovedSeriesFromToTrash': 'Series was moved from folder "**?0**" to trash',
		'Proceq.LogbookPropertyMovedSeriesFromTrashTo': 'Series was moved from trash to folder "**?0**"',
		'Proceq.LogbookPropertyMovedSeriesFromTo': 'Series was moved from folder "**?0**" to folder "**?1**"',
		'Proceq.LogbookPropertySecondaryScaleChangedFromTo': 'Secondary scale changed from **?0** to **?1**',
		'Proceq.LogbookPropertyMaterialChangedFromTo': 'Material changed from **?0** to **?1**',
		'Proceq.LogbookPropertyMaxLimitChangedFromTo': 'Upper limit changed from **?0** to **?1**',
		'Proceq.LogbookPropertyMinLimitChangedFromTo': 'Lower limit changed from **?0** to **?1**',
		'Proceq.LogbookPropertyStandardChangedFromTo': 'Standard changed from **?0** to **?1**',
		'Proceq.LogbookPropertyTextCommentAdded': 'Text comment added',
		'Proceq.LogbookPropertyTextCommentDeletedOn': 'Deleted on **?0**',
		'Proceq.LogbookPropertyMeasurementAdded': '**?0** added',
		'Proceq.LogbookPropertyMeasurementExcluded': '**?0** excluded',
		'Proceq.LogbookPropertyMeasurementIncluded': '**?0** included',
		'Proceq.LogbookPropertyMeasurementDeleted': '**?0** deleted',
		'Proceq.LogbookPropertyAttachmentDeletedOn': 'Deleted on **?0**',
		'Proceq.LogbookPropertyPhotoAdded': 'Photo added',
		'Proceq.LogbookPropertyAudioAdded': 'Audio comment added',
		'Proceq.LogbookPropertySeriesFlagged': 'Series flagged',
		'Proceq.LogbookPropertySeriesUnflagged': 'Series unflagged',
		'Proceq.LogbookPropertySerialNumberShort': 'S/N',
		'Proceq.LogbookPropertyBluetoothModuleShort': 'BTM S/N',
		'Proceq.LogbookPropertyVerified': 'Verified',
		'Proceq.LogbookPropertyLastVerification': 'Last verification',
		'Proceq.LogbookPropertyLastAdjust': 'Last adjust',
		'Proceq.LogbookPropertyLastPremiumCalibration': 'Last premium calibration',
		'Proceq.LogbookPropertyContractValidUntil': 'Contract valid until',
		'Proceq.LogbookPropertyOperator': 'Operator',
		'Proceq.LogbookTitle': 'Logbook',
		'Proceq.LogbookDownload': 'Download',
		'Proceq.DownloadWithURL': 'Download with URL',
		'Proceq.LogbookLoadingData': 'Loading data....',
		'Proceq.LogbookClose': 'Close',
		'Proceq.FormCompany': 'Company',
		'Proceq.FormScreeningEagleID': 'Screening Eagle ID (Email)',
		'Proceq.PasswordPolicyMinChar': 'Contains at least 8 characters',
		'Proceq.PasswordPolicyCombination': 'Contains at least one number (0-9) and a symbol',
		'Proceq.PasswordPolicyCase': 'Contains both lower (a-z) and upper case letters (A-Z)',
		'Proceq.PasswordPolicyNameOrEmail': 'Does not contain your name or email address',
		'Proceq.FormGroupUID': 'uID',
		'Proceq.FormEmailGroupID': 'Email',
		'Proceq.FormEagleID': 'User’s eagle ID',
		'Proceq.DeviceID': 'Device ID',
		'Proceq.DeviceID.Required.Notes':
			"This tier requires the user's device ID. Direct upgrades aren't available. Please issue a new license, assist the user with activation, and set the old license to expire within 7 days.",
		'Proceq.FormFreeTrialDuration': 'Free trial duration',
		'Proceq.FormFreeTrial.CustomerFeatureList.Tooltip':
			'This will be used to show custom features to the customer instead of the subscription features. Leave this field empty to use subscription features',
		'Proceq.FormCustomerEmail': 'Customer Email',
		'Proceq.FormProbeSN': 'Probe S/N',
		'Proceq.FormBluetoothSN': 'S/N',
		'Proceq.FormBluetoothHWID': 'HW ID',
		'Proceq.FormExpirationDate': 'Expiration Date',
		'Proceq.TitleRegisterProbeSNs': 'Register Rental Contract',
		'Proceq.ButtonSubmit': 'Submit',
		'Proceq.ButtonReset': 'Reset',
		'Proceq.ButtonResetPassword': 'Reset Password',
		'Proceq.Message2FactorAuthenticationSuccessfullyChanged': '2-Factor Authentication Successfully Changed',
		'Proceq.MessageLoginAttemptsSuccessfullyResetted': 'Login Attempts Successfully Resetted',
		'Proceq.MessageSuccesfullyInserted': 'Successfully inserted',
		'Proceq.MessageSuccesfullyEmailConfirmed': 'Your email has been confirmed.',
		'Proceq.MessagePasswordSuccesfullyChanged': 'Password Successfully Changed',
		'Proceq.MessageSuccesfullyUpdated': 'Successfully Updated',
		'Proceq.MessageAccountCreated':
			'A sign up verification email has been sent to you. Please click on the link in the email to complete the registration process',
		'Proceq.MessageEmailForgotPassword': 'An Email has been sent to you with further instructions',
		'Proceq.MessageEmailResendConfirm': 'An Email has been sent to you with further instructions',
		'Proceq.MessageLoggingIn': 'Logging in...',
		'Proceq.LoginNeedAccount': 'Need an account?',
		'Proceq.LoginCreateAccount': 'Create Account',
		'Proceq.TitleForgotPassword': 'Forgot Password',
		'Proceq.TitleResendConfirmation': 'Resend Confirmation Email',
		'Proceq.FormGroup': 'Group',
		'Proceq.FormFirstName': 'First Name',
		'Proceq.FormLastName': 'Last Name',
		'Proceq.FormEmail': 'E-mail Address',
		'Proceq.FormCity': 'City',
		'Proceq.FormPassword': 'Password',
		'Proceq.FormPasswordNew': 'New Password',
		'Proceq.FormPasswordOld': 'Old Password',
		'Proceq.FormConfirmPassword': 'Confirm Password',
		'Proceq.FormConfirmPasswordNew': 'Confirm New Password',
		'Proceq.Confirm': 'Confirm',
		'Proceq.EnterYourNewPassword': 'Enter your new password',
		'Proceq.PasswordChangedTitle': 'Password Changed',
		'Proceq.PasswordChangedBody':
			'Password has been reset successfully. You may use your new password to sign in to your account.',
		'Proceq.ButtonRegister': 'Register',
		'Proceq.ButtonSignIn': 'Sign in',
		'Proceq.TitleSignIn': 'Sign In',
		'Proceq.LoginAlreadyRegistered': 'Already registered?',
		'Proceq.TextOrSignInUsing': 'Or sign in using',
		'Proceq.NavSearchSerials': 'Search Contracts',
		'Proceq.NavRegisterProbeSNs': 'Register Contract',
		'Proceq.NavCreateStaffAccounts': 'Create Staff Accounts',
		'Proceq.NavHistoryLog': 'History Log',
		'Proceq.NavDataReview': 'Data Review (Legacy)',
		'Proceq.NavMeasurementsData': 'Measurements Data',
		'Proceq.NavChangePassword': 'Change Password',
		'Proceq.NavLogout': 'Logout',
		'Proceq.NavInspect': 'Inspect',
		'Proceq.NavWorkspace': 'Workspace',
		'Proceq.NavUpdateProfile': 'Screening Eagle ID',
		'Proceq.TitleUpdateProfile': 'Screening Eagle ID',
		'Proceq.NavCreateCustomersAccounts': 'Create Customer Account',
		'Proceq.ButtonSearch': 'Search',
		'Proceq.ButtonUpdate': 'Update',
		'Proceq.ButtonCreate': 'Create',
		'Proceq.ButtonChange': 'Change',
		'Proceq.FormSearchBy': 'Search by',
		'Proceq.FormValue': 'Value',
		'Proceq.FormAccountType': 'Account Type',
		'Proceq.FormAccountTypeAdmin': 'Administrator',
		'Proceq.FormAccountTypeBU': 'Business Unit',
		'Proceq.FormCompanyName': 'Company Name',
		'Proceq.FormAddress': 'Address',
		'Proceq.FormZipCode': 'Zip Code',
		'Proceq.FormCountry': 'Country',
		'Proceq.FormPhoneNumber': 'Telephone',
		'Proceq.FormCompanyWebsite': 'Company Website',
		'Proceq.FormJobTitle': 'Job Title',
		'Proceq.FormIndustry': 'Industry',
		'Proceq.FormSalutation': 'Salutation',
		'Proceq.FormCompanySize': 'Company Size',
		'Proceq.FormLanguage': 'Language',
		'Proceq.FormContactByProceq': 'Contact by Proceq',
		'Proceq.LanguageEnglish': 'English',
		'Proceq.LanguageJapanese': 'Japanese',
		'Proceq.LanguageChinese': 'Chinese',
		'Proceq.LanguageGerman': 'German',
		'Proceq.LanguageKorean': 'Korean',
		'Proceq.LanguageFrench': 'French',
		'Proceq.LanguageItalian': 'Italian',
		'Proceq.LanguagePortuguese': 'Portuguese',
		'Proceq.LanguageRussian': 'Russian',
		'Proceq.LanguageSpanish': 'Spanish',
		'Proceq.LanguageTurkish': 'Turkish',
		'Proceq.TextYes': 'Yes',
		'Proceq.TextNo': 'No',
		'Proceq.FormNewsletter': 'Newsletter',
		'Proceq.Reset2FactorAuthentication': 'Reset 2-Factor Authentication',
		'Proceq.ResetLoginAttempts': 'Reset Login Attempts',
		'Proceq.TitleSearchSerials': 'Search Contracts',
		'Proceq.TitleChangePassword': 'Change Password',
		'Proceq.TabLoginCustomers': 'Customers',
		'Proceq.TabLoginSingleUser': 'Single User',
		'Proceq.TabLoginGroup': 'Group',
		'Proceq.TextRemainingXDays': 'Still **?0** day(s) remaining',
		'Proceq.TextInfoShort': 'Info',
		'Proceq.TextIAgreeTerms': 'I agree with the <a>Terms and Conditions</a>',
		'Proceq.ErrorDisplayTitleError': 'Error',
		'Proceq.ErrorDisplayTitleWarning': 'Warning',
		'Proceq.LoginBackLogin': 'Back to login',
		'Proceq.LoginForgotPasswordQuestion': 'Forgot Password?',
		'Proceq.LoginResendConfirmationEmail': 'Resend confirmation email',
		'Proceq.LoginRegisterAccountTextPart': "If you don't have an account, please",
		'Proceq.LoginRegisterAccountLinkPart': 'register here',
		'Proceq.LoginProceqUseOnly': 'This is for Proceq internal use only',
		'Proceq.ProfileEnterInfo': 'Please enter your profile information before continuing',
		'Proceq.TextInfoExclamation': 'Info!',
		'Proceq.FooterLegalPrivacy': 'Legal & Privacy',
		'Proceq.JQGridTableRecordText': 'View {0} - {1} of {2}',
		'Proceq.JQGridTableEmptyRecords': 'No records to view',
		'Proceq.JQGridTableLoadText': 'Loading...',
		'Proceq.JQGridTablePaginationText': 'Page {0} of {1}',
		'Proceq.JQGridTableSavingText': 'Saving...',
		'Proceq.JQGridTableFirstPage': 'First Page',
		'Proceq.JQGridTableLastPage': 'Last Page',
		'Proceq.JQGridTableNextPage': 'Next Page',
		'Proceq.JQGridTablePreviousPage': 'Previous Page',
		'Proceq.JQGridTableRecordsPerPage': 'Records per Page',
		'Proceq.JQGridTablePageText': 'Page:',
		'Proceq.JQGridTableNoMoreRecs': 'No more records...',
		'Proceq.ExportColumnCSVNo': 'No.',
		'Proceq.ExportColumnCSVSingleReadings': 'Single Readings',
		'Proceq.ExportColumnCSVMeasurementValue': 'Measurement Value',
		'Proceq.ExportColumnCSVAngleDegrees': 'Angle (°)',
		'Proceq.ExportColumnCSVExcluded': 'Excluded',
		'Proceq.ExportColumnCSVSeriesName': 'Series Name',
		'Proceq.ExportColumnCSVImpactDevice': 'Impact Device',
		'Proceq.ExportColumnCSVImpactDeviceSN': 'Impact Device S/N',
		'Proceq.ExportColumnCSVImpactDeviceVerification': 'Impact Device Verification',
		'Proceq.ExportColumnCSVImpactDeviceHammerVerification': 'Hammer Verification',
		'Proceq.ExportColumnCSVImpactDeviceHammer': 'Hammer',
		'Proceq.ExportColumnCSVImpactDeviceHammerSN': 'Hammer S/N',
		'Proceq.ExportColumnCSVBluetoothModuleSN': 'Bluetooth Module S/N',
		'Proceq.ExportColumnCSVConversionStandard': 'Conversion Std.',
		'Proceq.ExportColumnCSVScale': 'Scale',
		'Proceq.ExportColumnCSVUnit': 'Unit',
		'Proceq.ExportColumnCSVReadings': 'Readings',
		'Proceq.ExportColumnCSVStandardDeviation': 'Std. dev.',
		'Proceq.ExportColumnCSVRelativeSpan': 'Rel. Span(%)',
		'Proceq.ExportColumnCSVDebugDataSeriesName': 'Series Name',
		'Proceq.ExportColumnExportDate': 'Export Date',
		'Proceq.ExportColumnUpperLimit': 'Upper Limit',
		'Proceq.ExportColumnLowerLimit': 'Lower Limit',
		'Proceq.ExportColumnLimits': 'Limits',
		'Proceq.ExportColumnHigh': 'High',
		'Proceq.ExportColumnLow': 'Low',
		'Proceq.ExportColumnValueOff': 'Off',
		'Proceq.ExportColumnValueNoConversion': 'No Conversion',
		'Proceq.ExportColumnValueNoConversionShort': 'NoConv',
		'Proceq.ExportColumnReadings': 'Readings',
		'Proceq.ExportColumnStandardDeviation': 'Standard deviation',
		'Proceq.ExportColumnRange': 'Range',
		'Proceq.ExportColumnRelativeSpan': 'Relative Span',
		'Proceq.ExportColumnMaximum': 'Maximum',
		'Proceq.ExportColumnMinimum': 'Minimum',
		'Proceq.ExportColumnAverage': 'Average',
		'Proceq.ExportColumnUnit': 'Unit',
		'Proceq.ExportColumnName': 'Name',
		'Proceq.ExportColumnDataSeriesID': 'Series ID',
		'Proceq.ExportColumnFolderName': 'Folder Name',
		'Proceq.ExportColumnCreatedDate': 'Created Date',
		'Proceq.ExportColumnCreatedDateOnly': 'Created Date',
		'Proceq.ExportColumnCreatedTime': 'Created Time',
		'Proceq.ExportColumnLastModifiedDate': 'Last Modified Date',
		'Proceq.ExportColumnLastModifiedDateOnly': 'Last Modified Date',
		'Proceq.ExportColumnLastModifiedTime': 'Last Modified Time',
		'Proceq.ExportColumnMeasurementAvgHardness': 'Measurement Average Hardness',
		'Proceq.ExportColumnLocationLatitude': 'Location Latitude',
		'Proceq.ExportColumnLocationLongitude': 'Location Longitude',
		'Proceq.ExportColumnProbeType': 'Type',
		'Proceq.ExportColumnProbeSN': 'Serial Number',
		'Proceq.ExportColumnBTMSN': 'Serial Number',
		'Proceq.ExportColumnProbeLastModifiedDate': 'Last Verification Date',
		'Proceq.ExportColumnProbeHardwareRevision': 'Hardware Revision',
		'Proceq.ExportColumnProbeFirmwareRevision': 'Firmware Revision',
		'Proceq.ExportColumnBTMHardwareRevision': 'Hardware Revision',
		'Proceq.ExportColumnBTMFirmwareRevision': 'Firmware Revision',
		'Proceq.ExportColumnAppPlatform': 'Platform',
		'Proceq.ExportColumnVersion': 'Version',
		'Proceq.ExportColumnOperator': 'Operator',
		'Proceq.ExportColumnPrimaryScale': 'Primary Scale',
		'Proceq.ExportColumnSecondaryScale': 'Secondary Scale',
		'Proceq.ExportColumnMaterial': 'Material',
		'Proceq.ExportColumnConversionStandard': 'Conversion Standard',
		'Proceq.ExportColumnAngleCorrection': 'Angle Correction',
		'Proceq.ExportColumnValueAuto': 'Auto',
		'Proceq.ExportColumnTableAngleGrades': 'Angle (°)',
		'Proceq.ExportColumnTableLegendExcluded': '* Excluded',
		'Proceq.ExportColumnMeasurementBarGraph': 'Measurement Bar Graph',
		'Proceq.ExportColumnTableView': 'Table View',
		'Proceq.ExportColumnStatistics': 'Statistics',
		'Proceq.ExportColumnSettings': 'Settings',
		'Proceq.ExportColumnImpactDeviceInfo': 'Impact Device Info',
		'Proceq.ExportColumnImpactDeviceHammerInfo': 'Hammer Info',
		'Proceq.ExportColumnBluetoothModuleInfo': 'Bluetooth Module Info',
		'Proceq.ExportColumnApplicationInfo': 'Application Info',
		'Proceq.ExportColumnAttachedPhotos': 'Attached Photos',
		'Proceq.ButtonActions': 'Actions',
		'Proceq.SerialNotInServiceFTP': 'S/N not in Service FTP',
		'Proceq.ActionResultsToCSV': 'Export results as CSV',
		'Proceq.ErrorDisplayTitleSuccess': 'Success',
		'Proceq.ErrorNoMultipleTargetsAllowed': 'Action can not be performed on multiple rows',
		'Proceq.MessageActionSuccesfullyPerformed': 'Action has been successfully performed',
		'Proceq.EmailReminderContractUserSubject': 'Reminder of contract expiration',
		'Proceq.EmailReminderContractUserBody':
			'Dear user<br/><br/>Your rental period for **?0** will expire on **?1**.<br/><br/>Your contract is automatically extended by 12 months as per <a href="http://www.proceq.com/fileadmin/user_upload/Proceq_General_Terms___Conditions_of_Rental_10112016.pdf">our ‘General Terms and Conditions of Rental’</a>. You will receive the invoice separately. Please make sure to acquit it on time to ensure the seamless use of your Equotip Live product.<br/><br/>Please contact your <a href="http://www.proceq.com/contact/proceq-network/">local sales representative</a> in case of questions.<br/><br/>Your Proceq Team<br/><br/>**?2**',
		'Proceq.EmailReminderContractAdminSubject': 'Reminder of contract expiration',
		'Proceq.ErrorNoTargetSelected': 'No target selected for the action',
		'Proceq.ErrorDisplayTitleInfo': 'Info',
		'Proceq.TextSharingSeriesUrlLinkCreated':
			'The series have been shared under the following link:<br/>**?0**<br/><a class="link-copy-clipboard" href="javascript:void(0);" onclick="**?1**">Copy to Clipboard</a><br/>',
		'Proceq.TextPleaseWait': 'Please wait...',
		'Proceq.TextPerformingServerRequest': 'Performing server request',
		'Proceq.TextPerformingAction': 'Performing action',
		'Proceq.ButtonCreateAccountChinaBack': 'Discard',
		'Proceq.TitleCreateAccountChina': 'Chinese customer',
		'Proceq.BodyCreateAccountChina':
			'For customers in China please go to <a href="**?0**">**?1**</a> to register or login',
		'Proceq.LoginCompatibiltyInfo': 'Compatibility information <a href="**?0**">Proceq Apps</a>',
		'Proceq.TextRedirectingToRegion': 'Redirection to your region...',
		'Proceq.EmailReminderContractExpiredTodayUserSubject': 'Contract Expiration',
		'Proceq.EmailReminderContractExpiredTodayUserBody':
			'Dear user<br/><br/>Your rental period for **?0** has expired today.<br/><br/>We regret that you are no longer using Proceq Live, but we are always happy to welcome you back again.<br/><br/>To renew the contract please contact your <a href="http://www.proceq.com/contact/proceq-network/">local sales representative</a>.<br/><br/>Your Proceq Team<br/><br/>**?1**',
		'Proceq.EmailRentalPeriodChangedSubject': 'Changed rental period',
		'Proceq.EmailRentalPeriodChangedBody':
			'Dear user<br/><br/>The expiration date of your rental contract for **?0** has been changed. Your rental contract will expire on **?1**.<br/><br/>Please contact your <a href="http://www.proceq.com/contact/proceq-network/">local sales representative</a> in case of questions.<br/><br/>Your Proceq Team<br/><br/>**?2**',
		'Proceq.FormExpiresIn': 'Expires in (days)',
		'Proceq.SelectTimeWeek': 'week',
		'Proceq.SelectTimeDay': 'day',
		'Proceq.SelectTimeMonth': 'month',
		'Proceq.GraphNoDataAvailable': 'no data available',
		'Proceq.GraphMaterial': 'Material Group',
		'Proceq.GraphSecondaryScale': 'Conversion Scale',
		'Proceq.GraphStandard': 'Conversion Standard',
		'Proceq.GraphMeasurementCount': 'Number of Impacts',
		'Proceq.GraphMeasurementSeriesCount': 'Measurement series count',
		'Proceq.StatisticsTitle': 'Statistics',
		'Proceq.StatisticsCreatedSeries': 'created measurement series',
		'Proceq.StatisticsModifiedSeries': 'modified measurement series',
		'Proceq.StatisticsPhotos': 'photos',
		'Proceq.StatisticsAudioFiles': 'audio files',
		'Proceq.StatisticsUsedStorage': 'used storage',
		'Proceq.StatisticsFolder': 'Folder',
		'Proceq.StatisticsModified': 'Modified',
		'Proceq.StatisticsCreated': 'Created',
		'Proceq.StatisticsAll': 'All',
		'Proceq.StatisticsCountry': 'Country',
		'Proceq.StatisticsCompanySize': 'Company Size',
		'Proceq.StatisticsIndustry': 'Industry',
		'Proceq.DateRangeFrom': 'From',
		'Proceq.DateRangeTo': 'To',
		'Proceq.GraphHistogram': 'Hardness Histogram',
		'Proceq.GraphSeriesHistogram': 'Hardness Histogram series',
		'Proceq.StatisticsImpactDevice': 'Impact Device',
		'Proceq.StatisticsImpactDeviceHammer': 'Hammer',
		'Proceq.GraphExcluded': 'Excluded',
		'Proceq.GraphIncluded': 'Included',
		'Proceq.GraphWithinTolerance': 'Within tolerance',
		'Proceq.GraphOutOfTolerance': 'Out of tolerance',
		'Proceq.StatisticsCreatedImpacts': 'Created impacts (included/excluded)',
		'Proceq.StatisticsModifiedImpacts': 'Modified impacts (included/excluded)',
		'Proceq.GraphNoConversion': 'No conversion',
		'Proceq.StatisticsTotalImpacts': 'Total impacts',
		'Proceq.StatisticsTotalSeries': 'Total series',
		'Proceq.StatisticsTotalExcluded': 'Total excluded',
		'Proceq.StatisticsValuesOutOfLimits': 'Values out of limits',
		'Proceq.StatisticsPeriod': 'Period',
		'Proceq.LabelFrom': 'From',
		'Proceq.LabelTo': 'To',
		'Proceq.LabelImpacts': 'Impacts',
		'Proceq.AbbrevCalendarWeek': 'CW',
		'Proceq.EmailWeeklyBuEquotipReportBUSubject': 'Weekly Equotip usage report',
		'Proceq.EmailWeeklyBuEquotipReportBUBody':
			"Dear BU<br/><br/>Attached you can find the Equotip Usage's report during the last 30 days <br/><br/>Your Proceq Team<br/><br/>**?0**",
		'Proceq.eRebNStd_Mean': 'Mean',
		'Proceq.ExportFieldTargetScale': 'Target Scale',
		'Proceq.ExportFieldConversionCurve': 'Conversion curve',
		'Proceq.eCC_None': 'None',
		'Proceq.eCC_Portland_Cement_J_N': 'Portland Cement J',
		'Proceq.eCC_Early_Strength_J_N': 'Early Strength J',
		'Proceq.eCC_Blast_Furnace_J_N': 'Blast Furnace J',
		'Proceq.eCC_Average_Curve_J_N': 'Average Curve J',
		'Proceq.eSC_R1_dry': 'JSCE-G504 R1 = 0 (dry)',
		'Proceq.eSC_R1_black_mark': 'JSCE-G504 R1 = +3 (black mark)',
		'Proceq.eSC_R1_wet_surface': 'JSCE-G504 R1 = +5 (wet surface)',
		'Proceq.eCSC_None': 'None',
		'Proceq.eCSC_Upper': 'Upper',
		'Proceq.eCSC_Under': 'Under',
		'Proceq.eSCC_US_eCSC_None': 'Unified strength',
		'Proceq.eSCC_US_eCSC_Upper': 'Unified strength upper casting surface',
		'Proceq.eSCC_US_eCSC_Under': 'Unified strength under casting surface',
		'Proceq.eSCC_PC_eCSC_None': 'Pumped concrete',
		'Proceq.eSCC_PC_eCSC_Upper': 'Pumped concrete upper casting surface',
		'Proceq.eSCC_PC_eCSC_Under': 'Pumped concrete under casting surface',
		'Proceq.eSCC_US': 'Unified strength',
		'Proceq.eSCC_PC': 'Pumped concrete',
		'Proceq.eNoOutlier': 'No Outlier',
		'Proceq.eOutlierLow': 'Outlier Low',
		'Proceq.eOutlierHigh': 'Outlier High',
		'Proceq.eOutlierRedundant': 'Outlier Redundant',
		'Proceq.LogbookPropertyConversionCurveChangedFromTo': 'Conversion curve changed from **?0** to **?1**',
		'Proceq.LogbookPropertyConversionCurveChangedTo': 'Conversion curve changed to **?0**',
		'Proceq.LogbookPropertyTargetScaleChangedFromTo': 'Target scale changed from **?0** to **?1**',
		'Proceq.LogbookPropertySurfaceCorrectionChangedFromTo': 'Surface correction changed from **?0** to **?1**',
		'Proceq.LogbookPropertySurfaceCorrectionChangedTo': 'Surface correction changed to **?0**',
		'Proceq.LogbookPropertyCarbonationDepthChangedFromTo': 'Carbonation depth changed from **?0** mm to **?1** mm',
		'Proceq.LogbookPropertyCorrectionFactorFromTo': 'Correction factor changed from **?0** to **?1**',
		'Proceq.LogbookPropertySurfaceConditionChangedFromTo': 'Surface condition changed from **?0** to **?1**',
		'Proceq.LogbookPropertySurfaceConditionChangedTo': 'Surface condition changed to **?0**',
		'Proceq.LogbookPropertyConcreteAgeChangedFromTo': 'Concrete age changed from **?0** d to **?1** d',
		'Proceq.LogbookPropertyConcreteAgeChangedTo': 'Concrete age changed to **?0** d',
		'Proceq.ExportColumnCSVOutlierType': 'Outlier',
		'Proceq.ExportColumnCarbonationDepth': 'Carbonation depth',
		'Proceq.ExportColumnConcreteAge': 'Concrete age',
		'Proceq.ExportFieldCarbonationDepth': 'Carbonation depth',
		'Proceq.ExportFieldConcreteAge': 'Concrete age',
		'Proceq.ValueConcreteAgeDays': '**?0** d',
		'Proceq.ValueCarbonationDepthMms': '**?0** mm',
		'Proceq.ExportColumnConversionCurve': 'Conversion curve',
		'Proceq.ExportColumnTableLegendOutlier': '* Outlier',
		'Proceq.ExportColumnTargetScale': 'Target Scale',
		'Proceq.ExportColumnMeasurementAvgCompressiveStrength': 'Measurement Average Compressive Strength',
		'Proceq.ExportColumnEn13791Screening': 'EN 13791 Screening',
		'Proceq.ExportColumnLowestRebound': 'Lowest Rebound Value',
		'Proceq.ExportColumnMedianRebound': 'Median Rebound Value',
		'Proceq.ExportColumnCompressiveStrengthClass': 'EN 206 Compressive Strength Class',
		'Proceq.EmailIntroDearContractOwner': 'Dear contract owner',
		'Proceq.ColumnTableSeriesRegionExport': 'Series',
		'Proceq.ExportColumSeriesRegionExport': 'Series',
		'Proceq.ExportTitleTestRegionExport': 'Test Region Export',
		'Proceq.ErrorSchmidtExportInvalidStandard': 'All series should have the same standard set',
		'Proceq.ErrorSchmidtExportTooFewSeries': 'At least 9 series are needed for the EN 13791 Screening',
		'Proceq.ErrorSchmidtExportTooManySeries': 'A maximum of 99 series can be selected for a single export',
		'Proceq.ErrorSchmidtExportDifferentConfiguration': 'Different configurations found on series',
		'Proceq.ErrorSchmidtExportWrongStandard': 'Wrong standard found for selected export',
		'Proceq.ErrorSchmidtExportInvalidSeries':
			'At least one of the series is invalid according to the selected standard',
		'Proceq.ErrorSchmidtExportSoftwareError': 'Error during the generation of the export',
		'Proceq.ErrorSchmidtExportOutOfRange': 'The calculated strength class is out of range',
		'Proceq.ButtonExportTestRegionEU': 'Export EN 13791 Screening test as PDF',
		'Proceq.ButtonExportTestRegionUniformity': 'Uniformity export as PDF',
		'Proceq.ButtonExportTestRegionChina': 'Export JGJ T23 test region as PDF',
		'Proceq.ErrorNotYetImplemented': 'Not yet implemented',
		'Proceq.ErrorSchmidtExportWrongParam':
			'Wrong pararameter found on series. For example: Wrong unit for selected export',
		'Proceq.ErrorSchmidtExportUnitNotMpa': "For JGJ region it's mandatory that the unit is set to MPa",
		'Proceq.ErrorSchmidtExportWrongFormFactor': 'For JGJ region the form factor must not be 2:1 cylinder',
		'Proceq.NavSelectView': 'Select view',
		'Proceq.TextConfirmationAreYouSure': 'Are you sure?',
		'Proceq.ErrorContractLinkedDifferentImpactDevice':
			'Contract can not be used with current impact device serial number',
		'Proceq.TitleShareByUrl': 'Share via URL',
		'Proceq.ExportTitleVerification': 'Verification Report:',
		'Proceq.ExportVerificationStatusFailed': 'Failed',
		'Proceq.ExportVerificationStatusPassed': 'Passed',
		'Proceq.ExportColumnTestVerificationResult': 'Verification Result',
		'Proceq.ExportColumnTestBlockTolerance': 'Test Block Uncertainty (Ucrm) k=2',
		'Proceq.ExportColumnTestBlockNominalHardness': 'Test Block Nominal Hardness',
		'Proceq.ExportColumnTestBlockSN': 'Test Block Serial Number',
		'Proceq.ExportColumnTestBlockName': 'Test Block Name',
		'Proceq.ExportColumnVerificationStd': 'Verification Standard',
		'Proceq.ExportColumnVerificationCriteria': 'Verification Criteria',
		'Proceq.VerificationCriteriaAverage5Span5Perc': 'average +/- 5%, span +/- 5%',
		'Proceq.VerificationCriteriaReadings6HL': 'readings +/- 6 HL',
		'Proceq.VerificationCriteriaReadings3Perc': 'readings +/- 3%',
		'Proceq.VerificationCriteriaAverage5Perc': 'average +/- 5%',
		'Proceq.VerificationCriteriaAverage2R': 'average +/- 2 R',
		'Proceq.VerificationCriteriaReadings3R': 'readings +/- 3 R',
		'Proceq.StandardEnumCustom': 'Custom',
		'Proceq.MaterialEnumCustom': 'Custom',
		'Proceq.ExportColumnCalibrationAnvilName': 'Calibration Anvil Name',
		'Proceq.ExportColumnCalibrationAnvilSN': 'Calibration Anvil Serial Number',
		'Proceq.ExportColumnCalibrationAnvilReboundValue': 'Calibration Anvil Rebound Value',
		'Proceq.ExportColumnCalibrationAnvilTolerance': 'Calibration Anvil Tolerance',
		'Proceq.ExportColumnTestLoadInfo': 'Test Load Info',
		'Proceq.NavVerificationData': 'Verification Data',
		'Proceq.NavCustomData': 'Custom Data',
		'Proceq.TableColumnVerificationResult': 'Result',
		'Proceq.TitleVerifications': 'Verifications',
		'Proceq.LogBookTestBlock': 'Test Block',
		'Proceq.LogBookCalibrationAnvil': 'Calibration Anvil',
		'Proceq.LogbookPropertyTestBlockChangedFromTo': 'Test block was changed from "**?0**" to "**?1**"',
		'Proceq.LogbookPropertyTestBlockChangedTo': 'Test block was changed to "**?0**"',
		'Proceq.LogbookPropertyCalibrationAnvilChangedFromTo': 'Calibration anvil was changed from **?0** to **?1**',
		'Proceq.LogbookPropertyCalibrationAnvilChangedTo': 'Calibration anvil was changed to **?0**',
		'Proceq.LogbookPropertyCreatedVerification': 'Created Verification "**?0**"',
		'Proceq.LogbookPropertyRenamedVerificationFromTo': 'Verification was renamed from "**?0**" to "**?1**"',
		'Proceq.LogbookResultVerificationFailed': 'Verification failed',
		'Proceq.LogbookResultVerificationPassed': 'Verification passed',
		'Proceq.LogbookPropertyTestLoadChangedFromTo': 'Test load changed from **?0** to **?1**',
		'Proceq.LogbookPropertyTestLoadChangedTo': 'Test load changed to **?0**',
		'Proceq.LogBookTestLoad': 'Test load',
		'Proceq.FieldWebOriginalCurve': 'Original',
		'Proceq.FieldWebCustomCurve': 'Custom',
		'Proceq.ExportFieldValuePair': 'Value pair',
		'Proceq.TableBaseMaterial': 'Base Material',
		'Proceq.TableDescription': 'Description',
		'Proceq.NavCustomMaterial': 'Custom Materials',
		'Proceq.FolderCustomMaterials': 'Custom Materials',
		'Proceq.SchmidtCurveTypePolynomial': 'fck = a + b*R + c*R² (Polynomial)',
		'Proceq.SchmidtCurveTypeExponencial': 'fck = a.e^(b*R) (Exponential)',
		'Proceq.SchmidtCurveTypePolynomialShort': 'Polynomial',
		'Proceq.SchmidtCurveTypeExponencialShort': 'Exponential',
		'Proceq.ExportFieldCurveType': 'Curve Type',
		'Proceq.TableActionEdit': 'Edit',
		'Proceq.TableActionAdd': 'Add',
		'Proceq.WebAccountActivatedTitle': 'Verification Success',
		'Proceq.WebAccountActivatedBodyLine1':
			'Congratulations, you have successfully verified your email address. You may now start your inspection journey with Screening Eagle.',
		'Proceq.WebAccountActivatedProceqTeam': 'Proceq Team',
		'Proceq.TextLifetime': 'Lifetime',
		'Proceq.TextExport': 'Export',
		'Proceq.TextUnknown': 'Unknown',
		'Proceq.LogbookContractType': 'Contract type',
		'Proceq.LogbookNoConversionValue': 'no conversion',
		'Proceq.AppHeading': 'Live Products',
		'Proceq.AppPlaceholder': ' ',
		'Proceq.LoginFormSignInMsg': 'Sign in and start managing your products',
		'Proceq.ForgotPasswordFormSendPassword': 'Send password',
		'Proceq.ForgotPasswordFormEmailMsg': 'Please input your email address!',
		'Proceq.ForgotPasswordFormEmailSentMessage':
			'If your email address is registered, an email with a password reset link will be sent shortly.',
		'Proceq.ResetPasswordFormHeading': 'Reset your password',
		'Proceq.ResetPasswordFormNoToken': 'There is no recovery token available',
		'Proceq.ErrorPageNotFoundHeading': "Sorry! The page you're looking for doesn't exist",
		'Proceq.ErrorPageNotFoundParagraph': 'You may have mistyped the address, or it may have been moved!',
		'Proceq.ErrorPageNotFoundLink': 'Take me to the home page',
		'Proceq.ErrorPermissionDeniedHeading': 'Oops! Access denied',
		'Proceq.ErrorPermissionDeniedParagraph': "Sorry about that, but you don't have permission to access this page",
		'Proceq.ErrorPermissionDeniedLink': 'Go back to previous page',
		'Proceq.LayoutSidebarHeading': 'Screening Eagle Cloud',
		'Proceq.FileUploadHanderAlertLargeFile': 'File must be smaller than 5MB!',
		'Proceq.FileUploadHanderAlertUpsupportedType': 'The type of this file is not supported',
		'Proceq.FileUploadHanderAlertAttachSuccess': 'File attached successfully!',
		'Proceq.NavDashboard': 'Dashboard',
		'App.AnalyticsSearching': 'Searching...',
		'App.AnalyticsNoResults': 'No Results',
		'App.ScreeningEagleWorkspace': 'Screening Eagle Workspace',
		'App.CheckingFileDownload': 'Checking file download...',
		'App.StartingFileDownload': 'Your file download should begin shortly.',
		'App.FileDownloadNotFound': 'The file does not exist.',
		'App.FileDownloadExpired': 'The file download has expired.',
		'App.FileDownloadError': 'Incorrect URL link.',
		'App.ExportFailure':
			'Export failed. Please try to export again after some time. If problem persist please contact administrator.',
		'App.LongRunningJobInProgress': 'Export is in progress.',
		'App.KeepBrowserOpen': 'Keep browser open, download will start in few minutes.',
		'App.AccessPageAfterSomeTime': 'Access below URL after some time',
		'App.ToDownloadReport': 'to Download Report',
		'App.ChangedFromTo': '{changed} changed from {from} to {to}.',
		'App.LogbookNoEntries': 'No logbook entries.',
		'App.LogbookAddedAt': 'Tag {sequence} at line {lineId} added.',
		'App.LogbookEditedAt': 'Tag {sequence} at line {lineId} edited.',
		'App.LogbookDeletedAt': 'Tag {sequence} at line {lineId} deleted.',
		'App.LogbookAdded': 'Tag {sequence} added.',
		'App.LogbookEdited': 'Tag {sequence} edited.',
		'App.LogbookDeleted': 'Tag {sequence} deleted.',
		'App.LogbookType': 'Type: {type}',
		'App.LogbookPositionMoved': 'Position moved',
		'App.LogbookEntryRenamed': 'Measurement file was renamed from "{from}" to "{to}"',
		'App.LogbookEntryMoved': 'Measurement file was moved from "{from}" to "{to}"',
		'App.LogbookEntryTrashed': 'Measurement file was moved from folder "{folder}" to trash',
		'App.LogbookEntryRestored': 'Measurement file was moved from trash to folder "{folder}"',
		'App.LogbookEntryUpdateStatus': '{item}: {status}',
		'App.LogbookImported': 'Data imported on {comment}',
		'App.LogbookSetSize': 'Set Size ({unit}): {0}',
		'App.LogbookSetDepth': 'Set Depth ({unit}): {0}',
		'App.DevTools.LatencyLogs.Header': 'Latency Logs',
		'App.DevTools.LatencyLogs.Label': 'Export latency logs from a date range as CSV',
		'App.DevTools.LatencyLogs.Button': 'Export',
		'App.DevTools.Import.Header': 'Import Shared Measurements',
		'App.DevTools.Import.Label': 'URL to import (Equotip/Schmidt, live/livedev only eg. /mnts-data)',
		'App.DevTools.Import.Button': 'Import',
		'App.DevTools.Import.Failed': 'Failed to import {failed, plural, one {# measurement} other {# measurements} }',
		'App.DevTools.Import.Success':
			'Successfully imported {succeeded, plural, one {# measurement} other {# measurements} } for {product}',
		'App.DevTools.Renew.Header': 'Renew Shared URL',
		'App.DevTools.Renew.Label':
			'URL to renew (will set validity to 14 days, migrated app only eg. /m/<product>?k=&d=)',
		'App.DevTools.Renew.Button': 'Renew',
		'App.DevTools.Renew.Success': 'URL renewed for 14 days',
		'App.DevTools.TouchMeasurement.Header': 'Touch Measurement',
		'App.DevTools.TouchMeasurement.MeasurementIdLabel': 'Measurement ID',
		'App.DevTools.TouchMeasurement.RegionLabel': 'Measurement Region',
		'App.DevTools.TouchMeasurement.Button': 'Touch',
		'App.DevTools.TouchMeasurement.Success': 'Measurement Touched Sccessfully',
		'App.DevTools.GenerateDemoBundle.Header': 'Generate Demo File Bundle from shared measurement URL',
		'App.DevTools.GenerateDemoBundle.Label': 'URL',
		'App.DevTools.GenerateDemoBundle.Button': 'Generate Demo File Bundle',
		'App.DevTools.Events': 'Event logs',
		'App.DevTools.Wipeout.Header': 'Wipe out your account data',
		'App.DevTools.Wipeout.Button': 'Data wipeout',
		'App.DevTools.Wipeout.Confirm':
			'This operation will completely wipe out data associated with your user account. This cannot be reversed.',
		'App.DevTools.Wipeout.Success': 'Data wiped out.',
		'App.DevTools.Managei18n': 'Manage i18n',
		'App.DevTools.ClearAPICache': 'Clear API Cache',
		'App.DevTools.ClearAPICache.Success': 'Cleared API Cache.',
		'App.LogbookPropertySnapshotExported': 'Snapshot exported',
		'App.LogbookPropertySnapshotSaved': 'Snapshot saved',
		'App.LogbookPhotoDeleted': 'Photo deleted on {date}',
		'App.LogbookMarkerAudioAdded': 'Voice marker {marker} added',
		'App.LogbookMarkerAudioDeleted': 'Voice marker {marker} deleted on {date}',
		'App.LogbookMarkerAudioAddedAtLine': 'Voice marker {marker} at line {lineId} added',
		'App.LogbookMarkerAudioDeletedAtLine': 'Voice marker {marker} at line {lineId} deleted on {date}',
		'App.LogbookAudioAdded': 'Audio added',
		'App.LogbookAudioDeleted': 'Audio deleted on {date}',
		'App.LogbookMarkerTextAddedAtLine': 'Text marker {marker} at line {lineId} added',
		'App.LogbookMarkerTextEditedAtLine': 'Text marker {marker} at line {lineId} edited',
		'App.LogbookMarkerTextDeletedAtLine': 'Text marker {marker} at line {lineId} deleted on {date}',
		'App.LogbookMarkerTextAdded': 'Text marker {marker} added',
		'App.LogbookMarkerTextEdited': 'Text marker {marker} edited',
		'App.LogbookMarkerTextDeleted': 'Text marker {marker} deleted on {date}',
		'App.LogbookTextAdded': 'Text added',
		'App.LogbookTextDeleted': 'Text deleted on {date}',
		'App.LogbookEntryCreated': 'Created "{fileName}" in folder "{folderName}"',
		'App.LogbookEntryMode': 'Mode: {value}',
		'App.LogbookEntryMacro': 'Macro: {value}',
		'App.LogbookEntryResolution': 'Resolution: {value}',
		'Proceq.LogbookEntryCoordinateSystem': 'Coordinate System: {value}',
		'Proceq.LogbookEntryGridSize': 'Grid Size ({unit}): {0} * {1}',
		'Proceq.LogbookEntryGridSpacing': 'Grid Spacing ({unit}): {0}',
		'App.LogbookEntryRepetitionRate': 'Repetition Rate ({unit}): {0}',
		'App.LogbookEntryDielectricConstant': 'Dielectric Constant: {0}',
		'App.LogbookEntryChannels': 'Channels: {value}',
		'App.LogbookEntrySignalZeroOffset': 'Signal Zero Offset ({unit}): {0}',
		'App.LogbookEntryRawDataOffset': 'Raw Data Offset ({unit}): {0}',
		'App.LogbookEntryTxFrequency': 'Tx Frequency ({unit}): {0}',
		'App.LogbookEntryTxVoltage': 'Tx Voltage ({unit}): {0}',
		'App.LogbookEntryNoOfSamples': 'Number of Samples: {value}',
		'App.LogbookEntryPulseDelay': 'Pulse Delay ({unit}): {0}',
		'App.LogbookEntryPulseVelocity': 'Pulse Velocity ({unit}): {0}',
		'App.LogbookEntryAnalogGain': 'Analog Gain ({unit}): {0}',
		'App.LogbookEntryTimeGainCompensation': 'Time Gain Compensation ({unit}): {0}',
		'App.LogbookEntryDigitalGain': 'Digital Gain ({unit}): {0}',
		'App.LogbookEntryImageStabilizer': 'Image Stabilizer: {value}',
		'App.LogbookEntryHighDefinitionFocus': 'High Definition Focus: {value}',
		'App.LogbookEntrySurfaceWaveCancellation': 'Surface Wave Cancellation: {value}',
		'App.LogbookEntryBScanStabilizer': 'B Scan Stabilizer: {value}',
		'App.LogbookEntryXSpacing': 'X Spacing ({unit}): {0}',
		'App.LogbookEntryYSpacing': 'Y Spacing ({unit}): {0}',
		'App.LogbookEntryAIMode': 'A.I. Positioning: {value}',
		'App.LogbookEntryMaxTransmissionTime': 'Max Transmission Time ({unit}): {0}',
		'App.LogbookEntryHalfCycle': 'Half Cycle: {value}',
		'App.LogbookPropertyCorrectionFactor': 'Correction Factor',
		'App.LogbookPropertyProbeSerialNumber': 'Probe S/N',
		'App.LogbookPropertyBaseUnitNumber': 'Base Unit S/N',
		'App.LogbookPropertyHardwareVersion': 'Hardware Version',
		'App.LogbookPropertyFirmwareVersion': 'Firmware Version',
		'App.LogbookPropertyMacAddress': 'MAC Address',
		'App.LogbookPropertyAppVersion': 'App Version',
		'App.LogbookPropertyContractType': 'Contract Type',
		'App.LogbookPropertyContractValidity': 'Contract Validity',
		'App.LogbookPropertyAddNewImpact': 'Add New Impact',
		'App.LogbookPropertyRemoveImpact': 'Remove Impact',
		'App.LogbookPropertyAddNewPile': 'Add New Pile',
		'App.LogbookPropertyRemovePile': 'Remove Pile',
		'App.LogbookPropertyRenamePile': 'Rename Pile',
		'App.LogbookPropertyAddNewPosition': 'Add New Position',
		'App.LogbookPropertyRemovePosition': 'Remove Position',
		'App.LogbookPropertyChangePosition': 'Change Position',
		'App.LogbookPropertyChangeExpectedLength': 'Change Expected Length',
		'App.LogbookPropertyCalibrateVelocity': 'Calibrate Velocity',
		'App.LogbookPropertyCalibrateTimeGain': 'Calibrate Time Gain',
		'App.LogbookPropertyCompareModeToggleSelectPile': 'Compare Mode Toggle Select Pile',
		'App.LogbookPropertyCompareModeToggleSelectPosition': 'Compare Mode Toggle Select Position',
		'App.LogbookPropertyTextWithKeyValues': 'Depth Edited',
		'App.LogbookPropertyDataExport': 'Data Export',
		'App.LogbookPropertyMarkerChange': 'Marker Change',
		'App.LogbookPropertyPileStatusChange': 'Pile Status Change',
		'App.LogbookPropertySnapshot': 'Snap shot',
		'App.LogbookPropertyRestoreFile': 'Restore File',
		'App.LogbookPropertyUserPhoto': 'User Photo',
		'App.LogbookPropertyUserAudio': 'User Audio',
		'App.LogbookPropertyUserText': 'User Text',
		'App.LogbookPropertyAddPileComment': 'Add Pile Comment',
		'App.LogbookPropertyImpactInAverage': 'Impact In Average',
		'App.LogbookChangeCorrectionFactor': 'Correction factor changed from {0} to {1}',
		'App.LogbookChangeCropArea': 'Focus area ({unit}) changed from {0}-{1} to {2}-{3}',
		'App.LogbookSettingsType': 'Type',
		'App.LogbookSettingsDistance': 'Distance ({unit})',
		'App.LogbookSettingsDielectricConstant': 'Dielectric Constant ({unit})',
		'App.LogbookSettingsLinearGain': 'Linear Gain ({unit})',
		'App.LogbookSettingsPulseVelocity': 'Pulse Velocity ({unit})',
		'Proceq.LogbookSettingsPulseVelocityPWave': 'Pulse Velocity P-Wave ({unit})',
		'Proceq.LogbookSettingsCompressiveStrength': 'Compressive Strength',
		'Proceq.LogbookSettingsCompressiveStrength.low': 'Low (<30 MPa)',
		'Proceq.LogbookSettingsCompressiveStrength.medium': 'Medium (30 - 45 MPa)',
		'Proceq.LogbookSettingsCompressiveStrength.high': 'High (>45 MPa)',
		'Proceq.LogbookSettingsCompressiveStrength.custom': 'Custom',
		'Proceq.LogbookSettingsCalculatedDepth': 'Calculated Depth ({unit})',
		'Proceq.LogbookSettingsSetDepth': 'Set Depth ({unit})',
		'Proceq.LogbookSettingsSetSize': 'Set Size ({unit})',
		'Proceq.LogbookSettingsComment': 'Comment',
		'Proceq.LogbookSettingsPoissonRatio': 'Poisson Ratio',
		'App.HTML.PUNDIT.Display.LocalVelocity': 'Local Velocity [{unit}]',
		'App.HTML.PUNDIT.Display.LocalDepth': 'Local Depth [{unit}]',
		'App.HTML.PUNDIT.VelocityObjects': 'Velocity Objects',
		'App.HTML.GPR.Objects.Rebar': 'Rebar',
		'App.HTML.GPR.Objects.Live Wire': 'Live Wire',
		'App.HTML.GPR.Objects.Non Metallic': 'Non Metallic',
		'App.HTML.GPR.Objects.Backwall': 'Backwall',
		'App.HTML.GPR.Objects.Void': 'Void',
		'App.HTML.GPR.Objects.User Defined': 'User Defined',
		'App.HTML.GPR.Objects.Velocity': 'Pulse Velocity',
		'Proceq.LogbookSettingsBackwallDepth': 'Backwall Depth ({unit})',
		'App.LogbookSettingsAnalogGain': 'Analog Gain ({unit})',
		'App.LogbookSettingsTimeGainCompensation': 'Time Gain Compensation ({unit})',
		'App.LogbookSettingsCalculatedDepth': 'Calculated Depth ({unit})',
		'App.LogbookSettingsSetDepth': 'Set Depth ({unit})',
		'App.LogbookSettingsSetSize': 'Set Size ({unit})',
		'App.LogbookSettingsComment': 'Comment',
		'App.LogbookSettingsName': 'Name',
		'App.LogbookEntryMeasurementEnd': 'Measurement ended.',
		'App.LogbookEntryMeasurementEndTotalLength': 'Total length ({unit}): {0}',
		'Proceq.LogbookEntryNumberOfScans': 'No. of scans: {value}',
		'App.LogbookEntryMeasurementEndNoOfLines': 'No. of lines: ({count})',
		'App.ManageCookiesLink': 'Manage your cookies here',
		'App.CookieHeader': 'Cookie Management',
		'App.CookieSubheader': 'Here you can choose to allow or disallow cookies.',
		'App.CookiesAllowed': 'You are currently allowing cookies.',
		'App.CookiesDisabled': 'Cookies are currently disabled.',
		'App.AllowCookies': 'Allow cookies',
		'App.AllowCookiesWhy':
			'Your login session will be preserved across multiple visits and browser tabs. Language and product preferences will also be saved.',
		'App.DisableCookies': 'I do not want to enable cookies.',
		'App.DisableCookiesWhy': 'If you close your browser you will have to login again.',
		'App.MeasurementDebugJSONTitle': 'JSON Tree View',
		'App.MeasurementDebugAttachmentsTitle': 'Attachments',
		'App.MeasurementDebugUnsyncedMeasurements': 'Debug: Show unsynced measurements',
		'App.AttachmentId': 'Id',
		'App.AttachmentType': 'Type',
		'App.AttachmentAttachedTo': 'Attached to',
		'App.AttachmentCreated': 'Created',
		'App.AttachmentUpdated': 'Updated',
		'App.CheckingSharedMeasurement': 'Checking shared measurements...',
		'App.SharedMeasurementNotFound': 'The shared measurement could not be found.',
		'App.SharedMeasurementExpired': 'The shared measurement link has expired.',
		'App.SharedMeasurementError': 'Incorrect URL link.',
		'App.ContractsId': 'Contract ID',
		'App.ContractsAdd': 'Add contract',
		'App.ContractsAddSaas': 'Create SAAS Contract',
		'App.ContractsEditSaas': 'Edit SAAS Contract',
		'App.ContractsEdit': 'Edit contract',
		'App.ContractsUnlock': 'Unlock contract(s)',
		'App.ContractsUnlockPrompt': 'Unlock {length, plural, one {# contract} other {# contracts} }?',
		'App.ContractsDelete': 'Disable contract(s)',
		'App.ContractsDeletePrompt': 'Disable {length, plural, one {# contract} other {# contracts} }?',
		'App.ContractsUserTypeRequired': 'userType is required',
		'App.InvoiceNoRequired': 'invoice number is required',
		'App.ContractsExportAll': 'Export all contracts',
		'App.ContractsExportBluetooth': 'Export all bluetooth contracts',
		'App.ContractsExportMaintenanceServices': 'Export Maintenance Services Contracts',
		'App.ContractsExportInspect': 'Export SAAS Contracts',
		'App.ContractsExportSoftware': 'Export Software Contracts',
		'App.ContractsExportWifi': 'Export all Wi-Fi contracts',
		'App.ContractsWifiMAC': 'Wi-Fi MAC address',
		'App.ContractsWifiMACError': 'Invalid Wi-Fi MAC address',
		'App.ContractsSerialLeebError': 'Bluetooth module serial number should be in BT01-XXX-XXXX format.',
		'App.ContractsSerialUCIError': 'Bluetooth module serial number should be in BT05-XXX-XXXX format.',
		'App.ContractsSerialSchmidtError':
			'Bluetooth module serial number should be in BT05-XXX-XXXX or BT42-XXX-XXXX format.',
		'App.ContractsSerialMismatchError':
			'Error: Mismatch of Bluetooth module serial number and probe type. BT01-XXX-XXXX can only be assigned to Equotip Leeb D, BT05-XXX-XXXX for Equotip UCI, and BT05-XXX-XXXX or BT42-XXX-XXXX for Schmidt, SSL, and Glossmeter.',
		'App.GM8000ContractsSerialMismatchError': 'Serial number should be in format GM80-XXX-XXXX',
		'App.GPRContractsSerialMismatchError': 'Serial number should be in format PM08-XXX-XXXX',
		'App.GPRPalmContractsSerialMismatchError': 'Serial number should be in format GP88-XXX-XXXX',
		'App.GP8100ContractsSerialMismatchError': 'Serial number should be in format GP81-XXX-XXXX',
		'App.GPRSoilContractsSerialMismatchError': 'Serial number should be in format GS80-XXX-XXXX',
		'App.GS9000ContractsSerialMismatchError': 'Serial number should be in format GS90-XXX-XXXX',
		'App.PunditContractsSerialMismatchError': 'Serial number should be in format PE05-XXX-XXXX',
		'App.PunditPD8050ContractsSerialMismatchError': 'Serial number should be in format PD80-XXX-XXXX',
		'App.FDLContractsSerialMismatchError': 'Serial number should be in format UT80-XXX-XXXX',
		'App.GLMContractsSerialMismatchError': 'Serial number should be in format ZG80-XXX-XXXX',
		'App.SchmidtContractsSerialMismatchError': 'Serial number should be in format SL01-XXX-XXXX or SL02-XXX-XXXX',
		'App.SSLContractsSerialMismatchError': 'Serial number should be in format OS82-XXX-XXXX or OS83-XXX-XXXX',
		'App.PS8000ContractsSerialMismatchError': 'Serial number should be in format PS80-XXX-XXXX',
		'App.RS8000ContractsSerialMismatchError': 'Serial number should be in format RS80-XXX-XXXX or RS81-XXX-XXXX',
		'App.PunditVisionContractsSerialMismatchError': 'Serial number should be in format PR-XXXX-XXXX-XXXX',
		'App.GPRSliceContractsSerialMismatchError': 'Serial number should only contain number of 4 or 5 digits',
		'App.MA8000ContractsSerialMismatchError': 'Serial number should be in format MA80-XXX-XXXX',
		'App.PI8000ContractsSerialMismatchError': 'Serial number should be in format PI80-XXX-XXXX',
		'App.PM8000ContractsSerialMismatchError': 'Serial number should be in format PM80-XXX-XXXX',
		'App.PM8500ContractsSerialMismatchError': 'Serial number should be in format PM85-XXX-XXXX',
		'App.ContractsLegendSixtyOneToNinetyDays': 'Contracts highlighted in green expire in 61 days to 90 days.',
		'App.ContractsLegendSixtyDaysOrExpired':
			'Contracts highlighted in orange expire in 60 days or less, or have already expired.',
		'App.GPRSliceLicenseLabel': 'GPR Slice License:',
		'App.GPRSIMLicenseLabel': 'GPRSIM License:',
		'App.LicensesSixtyDaysOrExpiredLegend':
			'Licenses highlighted in orange expire in 60 days or less, or have already expired.',
		'App.LicensesSixtyOneOrNinetyDaysLegend': 'Licenses highlighted in green expire in 61 to 90 days.',
		'App.LicensesLegendInsightsFreeTrial':
			'Licenses highlighted in orange expire in 5 days or less, or have already expired.',
		'App.RevokedLegend': 'Licenses highlighted in grey are revoked.',
		'App.Product': 'Product',
		'App.Product.Family': 'Product Family',
		'App.ProductModel': 'Product Model',
		'App.UserType': 'User Type',
		'App.Payment': 'Payment Status',
		'App.Licenses': 'License(s)',
		'App.GenerateKey': 'Generate Key',
		'App.ProductType': 'Type',
		'App.BluetoothSN': 'Bluetooth S/N',
		'App.DeviceSN': 'Device S/N',
		'App.SerialLicenseNo': 'Serial/License Number',
		'App.ExportSaaSLicenses': 'Export SAAS Licenses',
		'App.Contract.GPRSliceLicense': 'GPR Slice License',
		'App.Contract.LicenseKey': 'License Key',
		'App.Contract.LicensePassword': 'License Password',
		'App.Contract.LicenseType': 'Type',
		'App.Contract.LicenseExpirationDate': 'Expiration Date',
		'APP.Contract.InvoiceNo': 'Invoice Number 1',
		'APP.Contract.InvoiceNo2': 'Invoice Number 2',
		'APP.Contract.InvoiceNo3': 'Invoice Number 3',
		'APP.Contract.InvoiceNo4': 'Invoice Number 4',
		'APP.Contract.InvoiceNo5': 'Invoice Number 5',
		'APP.Contract.InvoiceNo6': 'Invoice Number 6',
		'App.MASN': 'MA8000 S/N',
		'App.HardwareId': 'Hardware ID',
		'App.HardwareIdError': 'Hardware ID must be exactly 16 characters in length.',
		'App.ProbePassword': 'Password',
		'App.ProbeExportAll': 'Export all probes',
		'App.DongleCreated': 'Created',
		'App.DongleUpdated': 'Last Updated',
		'App.DongleFtpTimestamp': 'Original Timestamp',
		'App.DongleExportAll': 'Export all dongles',
		'App.Users2FAEnabled': '2FA',
		'App.UsersAdd': 'Add User',
		'App.UsersVerified': 'Verified',
		'App.UsersRoles': 'Roles',
		'App.UsersExportAll': 'Export all users',
		'App.UsersDeletePrompt': 'Delete {length, plural, one {# user} other {# users} }?',
		'App.UsersDelete': 'Delete user(s)',
		'App.UsersVerifyPrompt': 'Verify user account?',
		'App.UsersVerify': 'Verify',
		'App.UsersVerifySuccess': 'Account verified.',
		'App.UsersManageRoles': 'Manage roles',
		'App.UsersUnblockPrompt': 'Unblock {email}?',
		'App.UsersUnblock': 'Unblock user',
		'App.UsersUnblockSuccess': 'Unblocked {email}.',
		'App.UsersAssumePrompt': "This will log you out of your account and into {user}'s account.",
		'App.UsersAssume': 'Login as this user',
		'App.UsersPasswordUpdated': 'Password updated',
		'App.UsersCreated': 'Account created',
		'App.UsersUpdated': 'Account updated',
		'App.RoleSuper': 'Super Admin',
		'App.RoleAdmin': 'Admin',
		'App.RolePO': 'Product Owner',
		'App.RoleBUAdmin': 'BU Admin',
		'App.RoleBU': 'Business Unit',
		'App.RoleDev': 'Developer',
		'App.RoleUser': 'User',
		'App.RoleSalesAndFinance': 'Contract Viewer',
		'App.RoleAnalyst': 'Analyst',
		'App.UsersNewPasswordValidation': 'Password should be within 6 and 25 characters',
		'App.UsersConfirmPasswordValidation': 'Password should match.',
		'App.UsersAtLeastOneRoleValidation': 'User must have at least one role.',
		'App.ShareMeasurementsText': 'Keep link active for...',
		'App.ShareMeasurementsSuccess': 'The series have been shared under the following link',
		'App.ShareMeasurementsCopyAction': 'Copy to Clipboard',
		'App.ShareMeasurementsCopySuccess': 'Copied!',
		'App.ShareMeasurementsCopyFailed': 'Your browser does not support copying. Please copy manually.',
		'App.ExportHTML': 'Export as HTML',
		'App.ExportHTMLSuccess': 'The HTML Export link has been generated',
		'App.FirmwareFilename': 'Filename',
		'App.FirmwareVersion': 'Version',
		'App.FirmwareProductCode': 'Product Code',
		'App.FirmwareHash': 'File Checksum',
		'App.FirmwareSize': 'Size',
		'App.FirmwareType': 'Type',
		'App.TableActionDelete': 'Delete',
		'App.TableActionDeletePrompt': 'Delete { subject }?',
		'App.BUNotificationEmail': 'Notification Email',
		'App.ForgetPasswordLimitReached':
			'You have reached the daily request limit. Please contact proceq to reset the limit or try again tomorrow.',
		'App.DashboardTitle': 'Usage statistics for {product}',
		'App.DashboardFilters': 'Filters',
		'App.DashboardUserFilter': 'User account',
		'App.DashboardUserFilterPlaceholderAdmin': 'Search by name, user ID or email',
		'App.DashboardUserFilterPlaceholder': 'Search by userID',
		'App.DashboardDateRange': 'Date Range',
		'App.DashboardCreatedModified': 'Created/Modified',
		'App.DashboardLineScans': 'Line Scans',
		'App.DashboardAreaScans': 'Area Scans',
		'App.DashboardStripeScans': 'Stripe Scans',
		'App.DashboardConventionalScan': 'Conventional Scan',
		'App.DashboardCorrosionGrid': 'Corrosion Grid',
		'App.DashboardSnapshots': 'Snapshots',
		'App.DashboardSnapshotsTooltip': 'Snapshots exported from the measurement screen of the app',
		'App.DashboardCSVStorage': 'in CSV files',
		'App.DashboardCSVCount': 'Snapshot + CSV files',
		'App.DashboardCSVTooltip':
			'Snapshots exported from the measurement list in the app, includes CSV of the data and the snapshot, zipped together',
		'App.DashboardTotalMeasurements': 'Total measurements',
		'App.DashboardTotalFolders': 'User-created folders',
		'App.DashboardLastSynced': 'Last synced date within time range: ',
		'App.AnalyticsCreatedOnly': '(Map View) Show user-created measurements only',
		'App.AnalyticsExportCancel': 'Cancel',
		'App.AnalyticsFlags': 'Flags',
		'App.AnalyticsFlagAll': 'All',
		'App.AnalyticsFlagAlive': 'Alive',
		'App.AnalyticsFlagTrashed': 'Trashed',
		'App.AnalyticsFlagDeleted': 'Deleted',
		'App.AnalyticsStorage': 'Storage breakdown',
		'App.AnalyticsStorageType': 'Type',
		'App.AnalyticsStorageAlive': 'Active',
		'App.AnalyticsStorageDeleted': 'Deleted',
		'App.AnalyticsStorageTrashed': 'Trashed',
		'App.AnalyticsStorageTypeMeasurement': 'Raw measurement files',
		'App.AnalyticsStorageTypeMeasurementTooltip': 'Includes imported, demo and trashed files',
		'App.AnalyticsStorageTypeImage': 'Image files',
		'App.AnalyticsStorageTypeAudio': 'Audio files',
		'App.AnalyticsStorageTypeImageAudio': 'Image and audio files',
		'App.AnalyticsStorageTypeTotal': 'Total',
		'App.AnalyticsStorageByType': 'Storage breakdown by data type',
		'App.AnalyticsStorageByTypeSubtitle': 'Click on a category to further break down by data type',
		'App.AnalyticsStorageByScan': 'Storage breakdown by scan type',
		'App.AnalyticsStorageByScanSubtitle': 'Click on a category to further break down by scan type',
		'App.AnalyticsRegion': 'Region',
		'App.AnalyticsLocation': 'Location Analytics',
		'App.AnalyticsOperationCancelled': 'Operation cancelled by user',
		'App.AnalyticsRegionExport': 'Export stats as CSV (GPR/Pundit only)',
		'App.AnalyticsRegionExportDateParameter': 'Exporting {duration} of stats from {from} to {to}',
		'App.AnalyticsRegionExportProgress': 'Progress',
		'App.AnalyticsRegionExportOk': 'Ok',
		'App.AnalyticsRegionExportCancel': 'Cancel',
		'App.AnalyticsRegionExportCancelled': 'Export cancelled by user',
		'App.GenerateAnalyticsReport': 'Generate Report',
		'App.AnalyticsReportTypes': 'Report Types',
		'App.AnalyticsLocationNoMeasurements': 'No measurements with location data retrieved.',
		'App.AnalyticsLocationExcludedMeasurements':
			'{count, plural, one {# measurement} other {# measurements} } did not have location data.',
		'App.AnalyticsLocationFoundMeasurements':
			'{count, plural, one {# measurement} other {# measurements} } with location data retrieved.',
		'App.AnalyticsLocationFoundRealMeasurements': '{count} of those measurements were created by users.',
		'App.AnalyticsLocationNoUser': 'Unknown',
		'App.AnalyticsLocationUserColumn': 'Owner',
		'App.AnalyticsLegend': 'Legend',
		'App.ViewMore': 'View More',
		'App.ViewLess': 'View Less',
		'App.EventsTimestamp': 'Date',
		'App.EventsClientTimestamp': 'Client Timestamp',
		'App.EventsUser': 'User',
		'App.EventsType': 'Type',
		'App.EventsContent': 'Content',
		'App.EventsTriggers': 'Triggers',
		'App.SidebarVersion': 'Version',
		'App.SidebarAdmin': 'Admin',
		'App.DateRange.DateRangeRequired': 'Date range is required.',
		'App.DateRange.DateRangeOptional': 'End date is required.',
		'App.DateRange.QuickRanges': 'Preset time ranges',
		'App.NavContracts': 'Contracts',
		'App.NavBusinessUnits': 'Business Units',
		'App.NavProbes': 'Probes',
		'App.NavDongles': 'Dongles',
		'App.NavFirmware': 'Firmware',
		'App.NavDeveloperTools': 'Developer Tools',
		'App.NavNotifications': 'Notifications',
		'App.NavAnalytics': 'Analytics',
		'App.NavAnalyticsRegional': 'Regional',
		'App.NavAnalyticsReports': 'Reports',
		'App.NavAnalyticsUser': 'User',
		'App.NavUsers': 'Users',
		'App.MeasurementType': 'Scan Type',
		'App.SentryReportFeedback': 'Report feedback',
		'App.LanguagesAllProducts': 'All Products',
		'App.LanguagesEQSHProducts': 'Equotip/Schmidt only',
		'App.IsRedeemed': 'Eagle Care Used',
		'App.IsRedeemedToolTip': 'Selecting this option will redeem Eagle Care package',
		'App.ShouldVerify': 'Verify MAC Address',
		'App.ShouldVerifyTooltip':
			'Selecting this option will make sure a dongle with the corresponding ID exists when creating/editing the contract',
		'App.CreateAsAdmin': 'Allow overwrite of Hardware ID',
		'App.CreateAsAdminTooltip1':
			'Selecting this option will skip auto-generation of the Hardware ID, allowing you to manually set the Hardware ID.',
		'App.CreateAsAdminTooltip2':
			'Note that if the bluetooth dongle already exists, the Hardware ID will be taken from the bluetooth dongle instead.',
		'App.EditAsAdmin': 'Edit as Admin',
		'App.EditAsAdminTooltip1':
			'Selecting this option will allow you to manually set the hardware ID and bypass dongle verification.',
		'App.EditAsAdminTooltip2':
			'Note that if the bluetooth dongle already exists, the Hardware ID must match the bluetooth dongle.',
		'App.ProductApp': 'App',
		'App.AnalyticsFilter': 'Filter',
		'App.MinAppVersion': 'Min Version Required',
		'App.BluetoothProducts': 'Bluetooth devices',
		'App.WifiProducts': 'Wi-Fi devices',
		'App.MaintenanceServicesProducts': 'Maintenance Services Kit',
		'App.SoftwareSolution': 'Software Solution',
		'App.SystemFolders': 'System Folders',
		'App.SchmidtFormFactorRAngle': 'R angle corrected',
		'App.SchmidtFormFactorQAngle': 'Q Value',
		'App.Characters': 'characters',
		'Proceq.DataSeriesDemo': 'Demo',
		'Proceq.DataSeriesImported': 'Imported',
		'App.HTML.User': 'User / Probe Info',
		'App.HTML.User.ContractID': 'Contract ID',
		'App.HTML.GPR.Settings.MeasurementPresets': 'Measurement Presets',
		'App.HTML.GPR.MeasurementPresets.FileName': 'File Name',
		'App.HTML.GPR.MeasurementPresets.FileID': 'File ID',
		'App.HTML.GPR.MeasurementPresets.Mode': 'Mode',
		'App.HTML.GPR.MeasurementPresets.ScanDirection': 'Scan Direction',
		'App.HTML.GPR.MeasurementPresets.GridSizeX': 'Grid Size X [{unit}]',
		'App.HTML.GPR.MeasurementPresets.GridSizeY': 'Grid Size Y [{unit}]',
		'App.HTML.GPR.MeasurementPresets.GridSpacingX': 'Grid Spacing X [{unit}]',
		'App.HTML.GPR.MeasurementPresets.GridSpacingY': 'Grid Spacing Y [{unit}]',
		'App.HTML.GPR.MeasurementPresets.Units': 'Units',
		'App.HTML.GPR.MeasurementPresets.Resolution': 'Resolution',
		'App.HTML.GPR.MeasurementPresets.RepetitionRate': 'Repetition Rate [{unit}]',
		'App.HTML.GPR.MeasurementPresets.ScanLength': 'Scan Length [{unit}]',
		'App.HTML.GPR.MeasurementPresets.TimeWindow': 'Time Window [{unit}]',
		'App.HTML.GPR.MeasurementPresets.SamplingRate': 'Sampling Rate [{unit}]',
		'App.HTML.GPR.MeasurementPresets.AntennaSpacing': 'Antenna Spacing [{unit}]',
		'App.HTML.GPR.Settings.ImageProcessing': 'Image Processing',
		'App.HTML.GPR.Settings.ImageProcessing.AutoGain': 'Auto Gain',
		'App.HTML.GPR.Settings.ImageProcessing.LinearGain': 'Linear Gain [{unit}]',
		'App.HTML.GPR.Settings.ImageProcessing.TimeGain': 'Time Gain Compensation [{unit}]',
		'App.HTML.GPR.Settings.ImageProcessing.MigratedView': 'Migrated View',
		'App.HTML.GPR.Settings.ImageProcessing.NonMigratedView': 'Non-migrated View',
		'App.HTML.GPR.Settings.ImageProcessing.TSV/3D': 'TSV/3D',
		'App.HTML.GPR.Objects': 'Objects',
		'App.HTML.GPR.Objects.Line': 'Line',
		'App.HTML.GPR.Objects.TagNumber': 'Tag',
		'App.HTML.GPR.Objects.TagType': 'Tag Type',
		'App.HTML.GPR.Objects.DistanceX': 'Distance X [{unit}]',
		'App.HTML.GPR.Objects.DistanceY': 'Distance Y [{unit}]',
		'App.HTML.GPR.Markers': 'Markers',
		'App.HTML.GPR.Markers.MarkerNumber': 'Marker Number',
		'App.HTML.GPR.ScanDistance': 'Scan Distance [{unit}]',
		'App.HTML.GPR_SOIL.Setting.Background.Removal.Depth': 'Background Removal Depth [{unit}]',
		'App.HTML.GPR_SOIL.Setting.Sepration.Slider.Depth': 'Separation Slider Depth [{unit}]',
		'App.HTML.PUNDIT.Settings.Display': 'Display',
		'App.HTML.PUNDIT.Settings.Grid': 'Grid',
		'App.HTML.PUNDIT.Settings.InternalParameters': 'Internal Parameters',
		'App.HTML.PUNDIT.Settings.TimeSlice': 'Time Slice',
		'App.HTML.PUNDIT.MeasurementPresets.Gap': 'Gap and Overlap [{unit}]',
		'App.HTML.PUNDIT.MeasurementPresets.XSpacing': 'X Spacing [{unit}]',
		'App.HTML.PUNDIT.MeasurementPresets.YSpacing': 'Y Spacing [{unit}]',
		'App.HTML.PUNDIT.MeasurementPresets.AScan': 'AScan',
		'App.HTML.PUNDIT.MeasurementPresets.Channels': 'Channels',
		'App.HTML.PUNDIT.MeasurementPresets.PulseDelay': 'Pulse Delay [{unit}]',
		'App.HTML.PUNDIT.MeasurementPresets.TxFrequency': 'Tx Frequency [{unit}]',
		'App.HTML.PUNDIT.MeasurementPresets.TxVoltage': 'Tx Voltage [{unit}]',
		'App.HTML.PUNDIT.MeasurementPresets.NoOfSamples': 'Number of Samples',
		'App.HTML.PUNDIT.ImageProcessing.HighDefinitionFocus': 'High Definition Focus',
		'App.HTML.PUNDIT.ImageProcessing.FocusDepth': 'Focus Depth [{unit}]',
		'App.HTML.PUNDIT.ImageProcessing.DigitalGain': 'Digital Gain [{unit}]',
		'App.HTML.PUNDIT.ImageProcessing.SurfaceWaveCancellation': 'Surface Wave Cancellation',
		'App.HTML.PUNDIT.ImageProcessing.BScanStabilizer': 'B-scan Stabilizer',
		'App.HTML.PUNDIT.ImageProcessing.SignalZeroOffset': 'Signal Zero Offset [{unit}]',
		'App.HTML.PUNDIT.ImageProcessing.RawDataOffset': 'Raw Data Offset [{unit}]',
		'App.HTML.PUNDIT.ImageProcessing.PulseVelocity': 'Pulse Velocity [{unit}]',
		'App.HTML.PUNDIT.Grid.GridRows': 'Grid Rows',
		'App.HTML.PUNDIT.Grid.GridColumns': 'Grid Columns',
		'App.HTML.PUNDIT.Grid.GridStartingPointX': 'Grid Starting Point X',
		'App.HTML.PUNDIT.Grid.GridStartingPointY': 'Grid Starting Point Y',
		'App.HTML.PUNDIT.Grid.GridSpacingX': 'Grid Spacing X [{unit}]',
		'App.HTML.PUNDIT.Grid.GridSpacingY': 'Grid Spacing Y [{unit}]',
		'App.HTML.PUNDIT.Display.ColorPalette': 'Color Palette',
		'App.HTML.PUNDIT.Display.AspectRatio': 'Aspect Ratio 1:1',
		'App.HTML.PUNDIT.Display.XAxisStart': 'X Axis Start [{unit}]',
		'App.HTML.PUNDIT.Display.XAxisEnd': 'X Axis End [{unit}]',
		'App.HTML.PUNDIT.Display.YAxisStart': 'Y Axis Start [{unit}]',
		'App.HTML.PUNDIT.Display.YAxisEnd': 'Y Axis End [{unit}]',
		'App.HTML.PUNDIT.Display.GridLines': 'Grid Lines',
		'App.HTML.PUNDIT.Display.Units': 'Units',
		'App.HTML.PUNDIT.Display.ImageStabilizer': 'Image Stabilizer',
		'App.HTML.PUNDIT.Display.Macro': 'Macro',
		'App.HTML.PUNDIT.Display.MaxTransmissionTime': 'Max Transmission Time [{unit}]',
		'App.HTML.PUNDIT.Display.LineViewXZoom': 'Line View X axis zoom level',
		'App.HTML.PUNDIT.Display.LineViewYZoom': 'Line View Y axis zoom level',
		'App.HTML.PUNDIT.Display.LineViewXOffset': 'Line View X axis content offset [{unit}]',
		'App.HTML.PUNDIT.Display.LineViewYOffset': 'Line View Y axis content offset [{unit}]',
		'App.HTML.PUNDIT.Display.LineViewFocusAreaTop': 'Line View Focus area top [{unit}]',
		'App.HTML.PUNDIT.Display.LineViewFocusAreaBottom': 'Line View Focus area bottom [{unit}]',
		'App.HTML.PUNDIT.Display.HalfCycle': 'Half Cycle',
		'App.HTML.PUNDIT.InternalParameters.SamplesToShow': 'Samples to Show',
		'App.HTML.PUNDIT.InternalParameters.SampleToDistanceScale': 'Sample To Distance Scale [{unit}]',
		'App.HTML.PUNDIT.TimeSlice.Depth': 'Depth [{unit}]',
		'App.HTML.PUNDIT.TimeSlice.Thickness': 'Thickness [{unit}]',
		'App.HTML.PUNDIT.Snapshot.CenterFrequency': 'Center frequency [{unit}]',
		'App.HTML.PUNDIT.GridCell': 'Grid Cell',
		'App.HTML.PUNDIT.TransmissionTime': 'Transmission Time [{unit}]',
		'App.HTML.PUNDIT.MeasurementPresets.DataInterest': 'Data Interest',
		'App.HTML.PI8000.Settings.FilteringPresets': 'Filtering Presets',
		'App.HTML.PI8000.Settings.TimeGain': 'Time Gain [{unit}]',
		'App.HTML.PI8000.Settings.HighPassFilter': 'High Pass Filter [{unit}]',
		'App.HTML.PI8000.Settings.LowPassFilter': 'Low Pass Filter [{unit}]',
		'App.HTML.PI8000.Settings.Pivot': 'Pivot [{unit}]',
		'App.HTML.PI8000.Markers.MarkerName': 'Name',
		'App.HTML.PI8000.Markers.Position': 'Position [{unit}]',
		'App.HTML.PI8000.Markers.Comments': 'Comments',
		'App.HTML.PI8000..Comments': 'Comments',
		'App.HTML.PI8000.Piles.Summary': 'Summary',
		'App.HTML.PI8000.Piles.Summary.Pile': 'Pile',
		'App.HTML.PI8000.Piles.Summary.Positions': 'Positions',
		'App.HTML.PI8000.Piles.Summary.ExpectedLength': 'Expected Length',
		'App.HTML.PI8000.Piles.PositionWithPileName': '{pileName} / Position {orderDesc}',
		'App.HTML.PI8000.Piles.Position.Status': 'Status',
		'App.HTML.PI8000.Piles.Position.CalculatedLength': 'Calculated Length [{unit}]',
		'App.HTML.PI8000.Piles.Position.TotalImpacts': 'Total Impacts',
		'App.HTML.PI8000.Piles.Position.ImpactWithExcludedFromAverage':
			'{impacts} (+{excludedCount} excluded from average)',
		'App.HTML.FDL.MeasurementPresets.FrameType': 'Frame Type',
		'App.HTML.FDL.MeasurementPresets.Indication': 'Indication Table',
		'App.HTML.FDL.MeasurementPresets.KeyFrameSnapshot': 'Key Frame Snapshot',
		'App.HTML.FDL.MeasurementPresets.KeyFrameSnapshot.IndicationID': 'Indication ID',
		'App.HTML.FDL.MeasurementPresets.KeyFrameSnapshot.GlobalGain': 'Global Gain ({unit})',
		'App.HTML.FDL.MeasurementPresets.SetupFile': 'Setup File',
		'App.HTML.FDL.MeasurementPresets.PRF': 'PRF Mode',
		'App.HTML.FDL.MeasurementPresets.CorrosionGrid.Cells': 'Cell',
		'App.HTML.FDL.MeasurementPresets.gate1SoundPathDistance': 'G1 ↘',
		'App.HTML.FDL.MeasurementPresets.gate1DepthToReflector': 'G1 ↓',
		'App.HTML.FDL.MeasurementPresets.gate1SurfaceDistance': 'G1 →',
		'App.HTML.FDL.MeasurementPresets.gate1CurrentAmplitude': 'G1 %FSH',
		'App.HTML.FDL.MeasurementPresets.gate1MaximumAmplitude': 'G1 %↑',
		'App.HTML.FDL.MeasurementPresets.gate2SoundPathDistance': 'G2 ↘',
		'App.HTML.FDL.MeasurementPresets.gate2DepthToReflector': 'G2 ↓',
		'App.HTML.FDL.MeasurementPresets.gate2SurfaceDistance': 'G2 →',
		'App.HTML.FDL.MeasurementPresets.gate2CurrentAmplitude': 'G2 %FSH',
		'App.HTML.FDL.MeasurementPresets.gate2MaximumAmplitude': 'G2 %↑',
		'App.HTML.FDL.MeasurementPresets.gate1AmplitudeToCurve': 'G1 %C',
		'App.HTML.FDL.MeasurementPresets.gate2AmplitudeToCurve': 'G2 %C',
		'App.HTML.FDL.MeasurementPresets.gate1DBtoCurve': 'G1 dBC',
		'App.HTML.FDL.MeasurementPresets.gate2DBtoCurve': 'G2 dBC',
		'App.HTML.FDL.MeasurementPresets.gate1Ers': 'G1 ERS',
		'App.HTML.FDL.MeasurementPresets.gate2Ers': 'G2 ERS',
		'App.HTML.FDL.MeasurementPresets.gate1DB': 'G1 dB',
		'App.HTML.FDL.MeasurementPresets.gate2DB': 'G2 dB',
		'App.HTML.FDL.MeasurementPresets.awsA': 'AWS A',
		'App.HTML.FDL.MeasurementPresets.awsB': 'AWS B',
		'App.HTML.FDL.MeasurementPresets.awsC': 'AWS C',
		'App.HTML.FDL.MeasurementPresets.awsD': 'AWS D',
		'App.HTML.FDL.MeasurementPresets.echoToEcho': 'G2-G1',
		'App.HTML.FDL.MeasurementPresets.gate1SurfaceDistanceIndexPointCorrection': 'G1 → - X',
		'App.HTML.FDL.MeasurementPresets.gate2SurfaceDistanceIndexPointCorrection': 'G2 → - X',
		'App.HTML.FDL.MeasurementPresets.equivalentReflectorSize': 'ERS',
		'App.HTML.FDL.MeasurementPresets.overshoot': 'OS',
		'App.HTML.FDL.MeasurementPresets.DgsCalibration': 'DGS Calibration',
		'App.HTML.FDL.MeasurementPresets.DgsSnapshot': 'DGS Snapshot',
		'App.HTML.FDL.IndicationInfo.Backwall': 'Backwall',
		'App.HTML.FDL.IndicationInfo.ReferenceType': 'Reference Type',
		'App.HTML.FDL.IndicationInfo.ProbeType': 'Probe Type',
		'App.HTML.FDL.IndicationInfo.calibratedDistance': 'Calibration Distance ({unit})',
		'App.HTML.FDL.IndicationInfo.CalibratedAmplitude': 'Calibration Amplitude ({unit})',
		'App.HTML.FDL.IndicationInfo.Attenuation': 'Attenuation ({unit})',
		'App.HTML.FDL.IndicationInfo.DeltaVk': 'Delta Vk ({unit})',
		'App.HTML.FDL.IndicationInfo.ErsMainCurve': 'ERS of main curve ({unit})',
		'App.HTML.FDL.DgsSnapshot.G1DistanceToDefect': 'G1 Distance to defect ({unit})',
		'App.HTML.FDL.DgsSnapshot.G1AmplitudeOfDefect': 'G1 Amplitude of defect ({unit})',
		'App.HTML.FDL.DgsSnapshot.G1.Ers': 'G1 ERS ({unit})',
		'App.HTML.FDL.DgsSnapshot.G1DbToCurve': 'G1 dB to curve ({unit})',
		'App.HTML.FDL.DgsSnapshot.G2DistanceToDefect': 'G2 Distance to defect ({unit})',
		'App.HTML.FDL.DgsSnapshot.G2AmplitudeOfDefect': 'G2 Amplitude of defect ({unit})',
		'App.HTML.FDL.DgsSnapshot.G2.Ers': 'G2 ERS ({unit})',
		'App.HTML.FDL.DgsSnapshot.G2DbToCurve': 'G2 dB to curve ({unit})',
		'App.HTML.Logbook.ExpandEntries': 'Expand all tag entries',
		'App.HTML.Logbook.CollapseEntries': 'Collapse all tag entries',
		'App.HTML.HTMLView': 'View',
		'App.HTML.TableOfContents': 'List of Measurement Files',
		'App.HTML.Version': 'Export HTML Format Version',
		'App.RememberMe': 'Remember me',
		'App.ContractsNotify': 'Send contract notification emails',
		'App.ContractsNotifyDaysTillExpire': 'Find contracts that are expiring in (days)',
		'App.ContractsNotifyAdditionalReceivers': 'Additional email addresses to send this notification to (optional)',
		'App.SetupFiles': 'Setup Files',
		'App.Logbook.FDL.MeasuringMode': 'Testing Technique: {mode}',
		'App.Logbook.FDL.Indication.Added': 'Indication {position} added',
		'App.Logbook.FDL.Indication.Edited': 'Indication {position}: {item} changed to {newValue}',
		'App.Logbook.FDL.Indication.ChangeToNew': '{item} changed to {newValue}',
		'App.Logbook.FDL.Indication.Changed': 'Indication {position}: {item} changed from {oldValue} to {newValue}',
		'App.Logbook.FDL.Indication.Deleted': 'Indication {position} deleted',
		'App.Logbook.FDL.Keyframe.Changed': 'Indication {position}: Key frame changed',
		'App.Logbook.FDL.Item.type': 'Type',
		'App.Logbook.FDL.Item.type.unknown': 'Unknown',
		'App.Logbook.FDL.Item.type.flaw': 'Flaw',
		'App.Logbook.FDL.Item.type.geometric': 'Geometric',
		'App.Logbook.FDL.Item.type.rejected': 'Rejected',
		'App.Logbook.FDL.Item.type.linked': 'Linked',
		'App.Logbook.FDL.Item.description': 'Description',
		'App.Logbook.FDL.Item.measuringMode': 'Testing Technique',
		'App.Logbook.FDL.Item.measuringMode.pulseEcho': 'Pulse Echo',
		'App.Logbook.FDL.Item.measuringMode.dual': 'Dual',
		'App.Logbook.FDL.Item.measuringMode.thru': 'Thru',
		'App.Logbook.FDL.Item.measuringMode.corrosionGrid': 'Corrosion Grid',
		'App.Logbook.FDL.Item.damping': 'Damping ({unit})',
		'App.Logbook.FDL.Item.gain': 'Gain ({unit})',
		'App.Logbook.FDL.Item.digitalFilter': 'Digital Filter ({unit})',
		'App.Logbook.FDL.Item.analogueFilter': 'Analogue Filter ({unit})',
		'App.Logbook.FDL.Item.range': 'Range ({unit})',
		'App.Logbook.FDL.Item.delay': 'Delay ({unit})',
		'App.Logbook.FDL.Item.digitalGain': 'Digital Gain ({unit})',
		'App.Logbook.FDL.Item.setupFile': 'Setup File ',
		'App.Logbook.FDL.Item.pulseWidth': 'Pulse Width',
		'App.Logbook.FDL.Item.pulseWidth.auto': 'Auto',
		'App.Logbook.FDL.Item.pulseWidth.narrow': 'Narrow',
		'App.Logbook.FDL.Item.filter': 'Filter ({unit})',
		'App.Logbook.FDL.Item.spatialAveraging': 'Averaging',
		'App.Logbook.FDL.Item.unit': 'Unit',
		'App.Logbook.FDL.Item.rf': 'Signal',
		'App.Logbook.FDL.Item.rf.full': 'RF',
		'App.Logbook.FDL.Item.rf.positiveHalf': 'Positive Half',
		'App.Logbook.FDL.Item.rf.negativeHalf': 'Negative Half',
		'App.Logbook.FDL.Item.rf.rectified': 'Full',
		'App.Logbook.FDL.Item.rf.envelope': 'Envelope',
		'App.Logbook.FDL.Item.triggerGate1': 'Trigger Gate 1',
		'App.Logbook.FDL.Item.triggerGate2': 'Trigger Gate 2',
		'App.Logbook.FDL.Item.triggerGate.off': 'Off',
		'App.Logbook.FDL.Item.triggerGate.highestPeak': 'Highest Peak',
		'App.Logbook.FDL.Item.triggerGate.firstPeak': 'First Peak',
		'App.Logbook.FDL.Item.triggerGate.jFlank': 'J Flank',
		'App.Logbook.FDL.Item.probeAngle': 'Angle ({unit})',
		'App.Logbook.FDL.Item.prf': 'PRF ({unit})',
		'App.Logbook.FDL.Item.prf.auto': 'Auto',
		'App.Logbook.FDL.Item.prf.manual': 'Manual',
		'App.Logbook.FDL.Item.reject': 'Reject ({unit})',
		'App.Logbook.FDL.Item.energy': 'Energy ({unit})',
		'App.Logbook.FDL.Item.frequency': 'Frequency ({unit})',
		'App.Logbook.FDL.Item.velocity': 'Velocity ({unit})',
		'App.Logbook.FDL.Item.zero': 'Zero offset ({unit})',
		'App.Logbook.FDL.Item.thickness': 'Thickness ({unit})',
		'App.Logbook.FDL.Item.indexPointCorrection': 'Index Point Correction ({unit})',
		'App.Logbook.FDL.Item.setReferenceGain': 'Set Reference Gain ({unit})',
		'App.Logbook.FDL.Settings': '{item} changed from {oldValue} to {newValue}',
		'App.Logbook.FDL.TextMarker.Added': 'Indication {position}: Text marker {marker} added',
		'App.Logbook.FDL.TextMarker.Edited': 'Indication {position}: Text marker {marker} edited',
		'App.Logbook.FDL.TextMarker.Deleted': 'Indication {position}: Text marker {marker} deleted',
		'App.Logbook.FDL.AudioMarker.Added': 'Indication {position}: Voice marker {marker} added',
		'App.Logbook.FDL.AudioMarker.Deleted': 'Indication {position}: Voice marker {marker} deleted',
		'App.Logbook.PI8000.PileName': 'Pile Name',
		'App.Logbook.PI8000.NewPileName': 'New Pile Name',
		'App.Logbook.PI8000.OldPileName': 'Old Pile Name',
		'App.Logbook.PI8000.Location': 'Location',
		'App.Logbook.PI8000.NewLocation': 'NewLocation',
		'App.Logbook.PI8000.OldLocation': 'OldLocation',
		'App.Logbook.PI8000.PositionName': 'Position Name',
		'App.Logbook.PI8000.ImpactID': 'Impact ID',
		'App.Logbook.PI8000.IsHighPassFilterOn': 'High Pass Filter(On/Off): {value}',
		'App.Logbook.PI8000.IsLowPassFilterOn': 'Low Pass Filter(On/Off): {value}',
		'App.Logbook.PI8000.Unit': 'Unit',
		'App.Logbook.PI8000.NewLength': 'New Length',
		'App.Logbook.PI8000.OldLength': 'Old Length',
		'App.Logbook.PI8000.HighPass': 'High Pass',
		'App.Logbook.PI8000.LowPass': 'Low Pass',
		'App.Logbook.PI8000.Mode': 'Mode',
		'App.Logbook.PI8000.TimeGain': 'Time Gain',
		'App.Logbook.PI8000.Velocity': 'Velocity',
		'App.Logbook.PI8000.IsSelected': 'Is Selected',
		'App.Logbook.PI8000.KeyValueItems': 'Additional information',
		'App.Logbook.PI8000.LogEvent': 'Log Event',
		'App.Logbook.PI8000.ExportType': 'Export Type',
		'App.Logbook.PI8000.MarkerName': 'Marker Name',
		'App.Logbook.PI8000.Event': 'Event',
		'App.Logbook.PI8000.DeletedDate': 'Deleted Date',
		'App.Logbook.PI8000.Status': 'Status',
		'App.Logbook.PI8000.IsExcluded': 'Is Excluded',
		'App.Logbook.PI8000.HighlightedSignal': 'Highlighted Signal',
		'App.Logbook.PI8000.AttachmentId': 'Attachment ID',
		'App.Logbook.PI8000.PitMode': 'Pit Mode',
		'App.Logbook.PI8000.IsExport': 'Is Export',
		'App.Logbook.PI8000.PileAndPositions': 'Pile And Positions',
		'App.Logbook.PI8000.ImpactStrength': 'Impact Strength',
		'App.Logbook.PI8000.Folder': 'Folder',
		'App.Logbook.PI8000.AttachmentName': 'Attachment Name',
		'App.Logbook.PI8000.Comment': 'Comment',
		'App.Logbook.PI8000.CommentType': 'Comment Type',
		'Notification.AddNotification': 'Add Notification',
		'Notification.Field.Title': 'Title',
		'Notification.Field.Status': 'Status',
		'Notification.Field.Status.Complete': 'Complete',
		'Notification.Field.Status.Scheduled': 'Scheduled',
		'Notification.Field.Message': 'Message',
		'Notification.Field.Message.Description':
			'Text exceeds 4 lines /178 characters is not shown in the default view.',
		'Notification.Field.UserSegments': 'User Segments',
		'Notification.Field.DeliveryTime': 'Delivery Time',
		'Notification.DeliveryTimeOption.Immediately': 'Immediately',
		'Notification.DeliveryTimeOption.SendLater': 'Send later',
		'Notification.DeliveryTimeOption.SendLater.Description':
			'The notification will be delivered at the same time across user timezones.',
		'Notification.Field.PushNotification': 'Push Notification',
		'Notification.Delete.Title': 'Are you sure you want to cancel {notificationTitle}?',
		'Notification.Delete.Description':
			'This notification will be cancelled immediately. And users will not receive anything.',
		'Antenna Spacing [{unit}]': 'Antenna Spacing [{unit}]',
		'App Version': 'App Version',
		'Base Unit S/N': 'Base Unit S/N',
		'Calculated Depth': 'Calculated Depth',
		Comment: 'Comment',
		'Contract ID': 'Contract ID',
		'Distance X [{unit}]': 'Distance X [{unit}]',
		'Distance Y [{unit}]': 'Distance Y [{unit}]',
		'Local X [{unit}]': 'Local X [{unit}]',
		'Local Y [{unit}]': 'Local Y [{unit}]',
		Easting: 'Easting',
		Northing: 'Northing',
		'Distance along line [{unit}]': 'Distance along line [{unit}]',
		'Set Size [{unit}]': 'Set Size [{unit}]',
		PointsOfInterest: 'Points of Interest',
		PointNumber: 'Point Number',
		'Export HTML Format Version': 'Export HTML Format Version',
		'File ID': 'File ID',
		'File Name': 'File Name',
		'Firmware Version': 'Firmware Version',
		'Grid Size X [{unit}]': 'Grid Size X [{unit}]',
		'Grid Size Y [{unit}]': 'Grid Size Y [{unit}]',
		'Grid Spacing X [{unit}]': 'Grid Spacing X [{unit}]',
		'Grid Spacing Y [{unit}]': 'Grid Spacing Y [{unit}]',
		'Hardware Version': 'Hardware Version',
		Line: 'Line',
		'MAC Address': 'MAC Address',
		'Marker Number': 'Marker Number',
		'Measurement Presets': 'Measurement Presets',
		Mode: 'Mode',
		Name: 'Name',
		Objects: 'Objects',
		UtilitiesLine: 'Utilities Line',
		'Probe S/N': 'Probe S/N',
		Product: 'Product',
		'Repetition Rate [{unit}]': 'Repetition Rate [{unit}]',
		Resolution: 'Resolution',
		'Sampling Rate [{unit}]': 'Sampling Rate [{unit}]',
		'Scan Distance [{unit}]': 'Scan Distance [{unit}]',
		'Scan Length [{unit}]': 'Scan Length [{unit}]',
		'Set Depth': 'Set Depth',
		'Set Size': 'Set Size',
		Tag: 'Tag',
		'Tag Type': 'Tag Type',
		'Time Window [{unit}]': 'Time Window [{unit}]',
		Units: 'Units',
		'User / Probe Info': 'User / Probe Info',
		Markers: 'Markers',
		'Created {fileName} in folder {folderName}': 'Created {fileName} in folder {folderName}',
		'Mode: {value}': 'Mode: {value}',
		'Geometries: {value}': 'Geometries: {value}',
		'Unit: {value}': 'Unit: {value}',
		'Points: {value}': 'Points: {value}',
		'Reference Type: {value}': 'Reference Type: {value}',
		'Delta Vk: {value} {unit}': 'Delta Vk: {value} {unit}',
		'ERS: {value} {unit}': 'ERS: {value} {unit}',
		'Test Block Attenuation: {value} {unit}': 'Test Block Attenuation: {value} {unit}',
		'{curveType} curve {statusType}': '{curveType} curve {statusType}',
		'Measurement Mode: {value}': 'Measurement Mode: {value}',
		'20° lower limit: {value}': '20° lower limit: {value}',
		'20° upper limit: {value}': '20° upper limit: {value}',
		'60° lower limit: {value}': '60° lower limit: {value}',
		'60° upper limit: {value}': '60° upper limit: {value}',
		'Geometries changed from  {oldValue} to {newValue}': 'Geometries changed from  {oldValue} to {newValue}',
		'{angle} {item} changed from  {oldValue} {unit} to {newValue} {unit}':
			'{angle} {item} changed from  {oldValue} {unit} to {newValue} {unit}',
		'#{index} 20°: {valueFor20} {unit} / 60°: {valueFor60} {unit} / H: {valueForH} HU added':
			'#{index} 20°: {valueFor20} {unit} / 60°: {valueFor60} {unit} / H: {valueForH} HU added',
		'#{index} 20°: {valueFor20} {unit} / 60°: {valueFor60} {unit} added':
			'#{index} 20°: {valueFor20} {unit} / 60°: {valueFor60} {unit} added',
		'#{index} 20°: {valueFor20} {unit} added': '#{index} 20°: {valueFor20} {unit} added',
		'#{index} 60°: {valueFor60} {unit} added': '#{index} 60°: {valueFor60} {unit} added',
		'20°': '20°',
		'60°': '60°',
		Haze: 'Haze',
		LimitsAndStatistics: 'Limits and statistics',
		Geometries: 'Geometries',
		Measurements: 'Measurements',
		singleModeMeasurements: 'Single mode measurements',
		continuousModeMeasurements: 'Continuous mode measurements',
		scanModeMeasurements: 'Scan mode measurements',
		'App.ShowHaze': 'Show Haze',
		'Auth.LoggingIn': 'Signing In...',
		'Auth.LoggingOut': 'Signing Out...',
		'SharedMeasurement.Message1': 'You will be able to open your data directly with {workspace} soon.',
		'SharedMeasurement.Message2':
			'Please use the corresponding App in the meantime to open the data that has been shared with you.',
		'App.Version.Info': 'Version Info',
		'App.System.Filename': 'System / Filename',
		'App.Version': 'Version',
	},
};
