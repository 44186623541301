// @flow
import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { upperFirst, startCase, isObject } from 'lodash';

export const GenericLogEvent = ({ log }: GPRLogbookEntryProps) => {
	const logEventType = upperFirst(log.type);
	const content = log.content;
	return (
		<div>
			<b>
				<FormattedMessage id={`App.LogbookProperty${logEventType}`} defaultMessage={startCase(logEventType)} />
			</b>
			<br />
			{Object.keys(content).map((key, index) => {
				const formattedMessageID = `App.Logbook.PI8000.${upperFirst(key)}`;
				let value = content[key];
				if (isObject(value)) {
					value = JSON.stringify(value);
				}
				return (
					<Fragment key={`${log.id}__${key}`}>
						{index > 0 && <br />}
						<FormattedMessage id={formattedMessageID} defaultMessage={startCase(key)} />
						{`: ${value}`}
					</Fragment>
				);
			})}
		</div>
	);
};

export default GenericLogEvent;
