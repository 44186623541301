// @flow
import { simpleID } from './mathUtils';
import * as storageUtils from './storageUtils';
import * as routeUtils from './routeUtils';
import apiURLs from '../apis/urls';
import { getLastLanguage } from './sessionHelper';
import { lookupLang } from './localeHelper';
import routeURLs from '../routes/urls';
import { WORKSPACE_URL } from '../routes/config';

const EAGLE_ID_CLIENT_IDS = {
	local: 'screeningeagle-dev',
	dev: 'screeningeagle-dev',
	stg: 'screeningeagle-stg-zedsMTb',
	prod: 'screeningeagle-2oxirNlZP0',
	'dev-cn': 'screeningeagle-dev',
	'stg-cn': 'screeningeagle-stg-zedsMTb',
	'prod-cn': 'screeningeagle-2oxirNlZP0',
};

const SERVER_TYPE = process.env.SERVER_TYPE || 'dev';
export const EAGLE_ID_CLIENT_ID = EAGLE_ID_CLIENT_IDS[SERVER_TYPE] || '';

export const defaultEagleIDRedirectUri = (language?: string) => {
	if (language) {
		return `${location.origin}${routeURLs.AUTH}?lang=${language}`;
	}
	return `${location.origin}${routeURLs.AUTH}`;
};

export const checkEagleIDState = (state: string | null): boolean => {
	return storageUtils.getSessionItem('eagleIDState') === state;
};

export const getRedirectURI = (language?: string): string => defaultEagleIDRedirectUri(language);

export const getSharedParams = (includeLanguage: boolean = false) => {
	const randomState = simpleID(6);
	storageUtils.saveSessionItem('eagleIDState', randomState);
	const shortLang: any = getLastLanguage();
	const language = lookupLang(shortLang) || 'en';
	const redirectURI = getRedirectURI(includeLanguage ? language : undefined);

	return {
		redirect_uri: redirectURI,
		client_id: EAGLE_ID_CLIENT_ID,
		state: randomState,
		product: 'proceq',
		client: 'web',
		version: '0.1',
		lang: language,
		includeLang: 'true',
	};
};

export const redirectEagleIDLogin = (withCustomError: boolean = false, extraParams: { [key: string]: string } = {}) => {
	const path = apiURLs.CHECK_EAGLE_ID_AUTH;
	const scope = ['openid', 'email', 'profile', 'offline', 'sensor', 'sensor_common'].join(' ');

	const url = routeUtils.makeQueryPath(path, {
		...getSharedParams(true),
		response_type: 'code',
		scope,
		error: withCustomError ? 'loginFailed' : undefined,
		...extraParams,
	});

	window.open(url, '_self');
};

export const redirectEagleIDLogout = () => {
	const path = apiURLs.EAGLE_ID_LOGOUT;
	const url = routeUtils.makeQueryPath(path, getSharedParams());
	window.open(url, '_self');
};

export const redirectEagleWorkspace = () => {
	window.open(WORKSPACE_URL, '_self');
};

// Reference: https://stackoverflow.com/questions/38552003/how-to-decode-jwt-token-in-javascript-without-using-a-library
export const parseJwt = (token: string) => {
	const base64Str = token.split('.')[1];
	const base64 = base64Str.replace(/-/g, '+').replace(/_/g, '/');
	const jsonPayload = atob(base64);
	const result = JSON.parse(jsonPayload);
	return result;
};

export const saveLandingPath = () => {
	if (location.pathname && !storageUtils.getSessionItem('landingPathname')) {
		storageUtils.saveSessionItem('landingPathname', location.pathname);
	}
	if (location.search && !storageUtils.getSessionItem('landingQuery')) {
		storageUtils.saveSessionItem('landingQuery', location.search);
	}
};

export const restoreLandingPath = () => {
	const landingPathname = storageUtils.getSessionItem('landingPathname') ?? '';
	const landingQuery = storageUtils.getSessionItem('landingQuery') ?? '';
	storageUtils.removeSessionItem('landingPathname');
	storageUtils.removeSessionItem('landingQuery');
	return landingPathname ? landingPathname + landingQuery : '';
};

export const getLandingPath = () => {
	const landingPath = restoreLandingPath();
	if (landingPath && landingPath !== location.pathname + location.search) {
		return landingPath;
	}
	return routeURLs.SETTINGS;
};
