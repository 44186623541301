// @flow
import es_ES from 'antd/lib/locale-provider/es_ES';
import de_DE from 'antd/lib/locale-provider/de_DE';
import fr_FR from 'antd/lib/locale-provider/fr_FR';
import it_IT from 'antd/lib/locale-provider/it_IT';
import ko_KR from 'antd/lib/locale-provider/ko_KR';
import pt_PT from 'antd/lib/locale-provider/pt_BR';
import ru_RU from 'antd/lib/locale-provider/ru_RU';
import tr_TR from 'antd/lib/locale-provider/tr_TR';
import ja_JP from 'antd/lib/locale-provider/ja_JP';
import zh_TW from 'antd/lib/locale-provider/zh_TW';
import en_GB from 'antd/lib/locale-provider/en_GB';

import moment from 'moment';
import 'moment/locale/es';
import 'moment/locale/de';
import 'moment/locale/fr';
import 'moment/locale/it';
import 'moment/locale/ko';
import 'moment/locale/pt';
import 'moment/locale/ru';
import 'moment/locale/tr';
import 'moment/locale/ja';
import 'moment/locale/zh-cn';

import common from '../i18n/common';
import es from '../i18n/es';
import de from '../i18n/de';
import fr from '../i18n/fr';
import it from '../i18n/it';
import ko from '../i18n/ko';
import pt from '../i18n/pt';
import ru from '../i18n/ru';
import tr from '../i18n/tr';
import ja from '../i18n/ja';
import zh from '../i18n/zh';
import en from '../i18n/en';

export const strings = {
	es,
	de,
	fr,
	it,
	ko,
	pt,
	ru,
	tr,
	ja,
	zh,
	en,
};

// addLocaleData([..._en, ..._es, ..._de, ..._fr, ..._it, ..._ko, ..._pt, ..._ru, ..._tr, ..._ja, ..._zh]);

// looking up i18n key
export const lookupLang = (lang: ShortLangKey): LangKey => {
	if (lang === 'cn') {
		return 'zh';
	}

	if (lang === 'jp') {
		return 'ja';
	}

	return lang;
};

// getting i18n key
export const getLang = (lang: LangKey): ShortLangKey => {
	if (lang === 'zh') {
		return 'cn';
	}

	if (lang === 'ja') {
		return 'jp';
	}

	return lang;
};

export const getSetup = (
	shortlang: ShortLangKey
): {
	shortlang: ShortLangKey,
	lang: LangKey,
	messages: Object,
	antdLocaleProvider: Object,
} => {
	let antdLocaleProvider = en_GB;
	let newLocaleInfo = {};
	switch (shortlang) {
		case 'en':
			newLocaleInfo = en;
			antdLocaleProvider = en_GB;
			break;
		case 'es':
			newLocaleInfo = es;
			antdLocaleProvider = es_ES;
			break;
		case 'de':
			newLocaleInfo = de;
			antdLocaleProvider = de_DE;
			break;
		case 'fr':
			newLocaleInfo = fr;
			antdLocaleProvider = fr_FR;
			break;
		case 'it':
			newLocaleInfo = it;
			antdLocaleProvider = it_IT;
			break;
		case 'ko':
			newLocaleInfo = ko;
			antdLocaleProvider = ko_KR;
			break;
		case 'pt':
			newLocaleInfo = pt;
			antdLocaleProvider = pt_PT;
			break;
		case 'ru':
			newLocaleInfo = ru;
			antdLocaleProvider = ru_RU;
			break;
		case 'tr':
			newLocaleInfo = tr;
			antdLocaleProvider = tr_TR;
			break;
		case 'jp':
			newLocaleInfo = ja;
			antdLocaleProvider = ja_JP;
			break;
		case 'cn':
			newLocaleInfo = zh;
			antdLocaleProvider = zh_TW;
			break;
		default:
			newLocaleInfo = en;
			antdLocaleProvider = en_GB;
			break;
	}
	moment.locale(shortlang === 'cn' ? 'zh-cn' : newLocaleInfo.lang);
	return {
		...newLocaleInfo,
		antdLocaleProvider,
		messages: {
			...common,
			...en.messages,
			...newLocaleInfo.messages,
		},
	};
};

export function getSystemLocale() {
	let localeValue = null;
	if (window.navigator !== null) {
		localeValue =
			window.navigator.languages && window.navigator.languages.length
				? window.navigator.languages[0]
				: window.navigator.language;
		if (localeValue === null) {
			localeValue = window.navigator.userLanguage;
		}
	}
	if (localeValue === null) {
		localeValue = 'en_en';
	}
	return localeValue;
}
