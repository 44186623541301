// @flow
import React, { Fragment } from 'react';
import moment from 'moment';
import { debounce } from 'lodash';
import { compose, withState, lifecycle, withHandlers } from 'recompose';
import { connect } from 'react-redux';
import { Form, Button, message, Radio } from 'antd';
import type { FormComponentProps } from 'antd';
import type { FormProps } from 'antd/lib/form';
import axios from 'axios';
import { injectIntl, FormattedMessage } from 'react-intl';
import type { InjectIntlProvidedProps } from 'react-intl';
import { formatDateTimeNoSecsToLocale } from 'utils/dateHandler';
import { getCookie } from 'utils/sessionHelper';
import { getInitialRange } from 'constants/dateRange';
import { request } from 'actions/ajax';
import URL from 'apis/urls';
import ReactJson from 'react-json-view';
import DateRange, { dateRangeRequired } from '../shared/DateRange';
import SmarterTable from '../shared/SmarterTable';
import SmarterSelect from '../shared/SmarterFormItems/Select';
import selectStyles from './UserAnalytics.styl';
import styles from './Events.styl';
import { hasRoleLevel, ROLES } from '../../utils/rolesHelper';

const renderTime = (text: number) => <div>{formatDateTimeNoSecsToLocale(moment.unix(text / 1000).local())}</div>;

const renderUser = (users: User[], isAdmin: boolean) => (uID: number) => {
	const user = users.find(u => u.id === +uID);
	return user && isAdmin ? (
		<span>
			{user.firstName} {user.lastName}
			<br />
			{user.username}
			<br />
			{'uID: '}
			{uID}
		</span>
	) : (
		`uID: ${uID}`
	);
};

const deepParseJson = (text: string) => {
	if (!text) {
		return {};
	}

	const obj = {};
	try {
		const tmpObj = JSON.parse(text);
		Object.keys(tmpObj).forEach(key => {
			if (tmpObj[key] == null) {
				return;
			}
			try {
				obj[key] = JSON.parse(tmpObj[key]);
			} catch (e) {
				obj[key] = tmpObj[key];
			}
		});
	} catch (error) {
		return { content: text };
	}

	return obj;
};

export const Events = ({
	loading,
	form,
	intl,
	users,
	usersSearch,
	setUsersSearch,
	events,
	setParams,
	loadEvents,
	totalRecords,
	triggers,
	eventTypes,
	cancel,
	isAdmin,
	loadUsers,
	usersLoading,
}: {
	loading: boolean,
	form: FormProps,
	intl: Object,
	users: User[],
	setUsersSearch: Function,
	usersSearch: User[],
	events: AppEvent[],
	setParams: Function,
	loadEvents: Function,
	totalRecords: number,
	triggers: Object[],
	eventTypes: Object[],
	cancel: Function,
	isAdmin: boolean,
	loadUsers: Function,
	usersLoading: boolean,
} & InjectIntlProvidedProps &
	FormComponentProps) => {
	const userOption = ({
		id,
		firstName,
		lastName,
		username,
	}: {
		id: string,
		firstName: string,
		lastName: string,
		username: string,
	}) => {
		if (isAdmin) {
			return (
				<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
					<span style={{ alignSelf: 'flex-start', flex: 1 }}>{`(uID: ${id}) ${firstName} ${lastName}`}</span>
					<span>{`(${String(username)})`}</span>
				</div>
			);
		}
		return <span>{`uID: ${id}`}</span>;
	};

	return (
		<Fragment>
			<Form style={{ margin: '12', width: '400' }}>
				<div className={styles.left_filter_section}>
					<Form.Item label={<FormattedMessage id="App.DashboardDateRange" />}>
						{form.getFieldDecorator('dateRange', {
							rules: [
								{
									validator: dateRangeRequired,
									message: intl.formatMessage({
										id: 'App.DateRange.DateRangeRequired',
									}),
								},
							],
							initialValue: getInitialRange(),
						})(<DateRange />)}
					</Form.Item>
					<Form.Item label={<FormattedMessage id="App.AnalyticsFilter" />}>
						{form.getFieldDecorator('filter', {
							initialValue: 'family',
						})(
							<Radio.Group>
								<Radio value={'family'}>{'App'}</Radio>
								<Radio value={'productModel'}>{'Product'}</Radio>
							</Radio.Group>
						)}
					</Form.Item>
					{form.getFieldValue('filter') === 'family' && (
						<Form.Item label={<FormattedMessage id="App.Product.Family" />}>
							{form.getFieldDecorator('appName', {
								initialValue: getCookie('appName') ? getCookie('appName').split(',') : ['All'],
							})(
								<SmarterSelect
									mode="multiple"
									className={styles.select}
									globalRegister="availappnamesfull"
									disabled={loading}
									saveSelects="appName"
								/>
							)}
						</Form.Item>
					)}
					{form.getFieldValue('filter') === 'productModel' && (
						<Form.Item label={<FormattedMessage id="App.ProductModel" />}>
							{form.getFieldDecorator('productModel', {
								initialValue: getCookie('productModel')
									? getCookie('productModel').split(',')
									: ['All'],
							})(
								<SmarterSelect
									mode="multiple"
									className={selectStyles.select}
									globalRegister="productModelsRegional"
									disabled={loading}
									saveSelects="productModel"
								/>
							)}
						</Form.Item>
					)}
					<Button type="primary" htmlType="submit" loading={loading} onClick={() => loadEvents()}>
						<FormattedMessage id="Proceq.TableActionRefresh" />
					</Button>
					{loading && (
						<Button type="default" onClick={cancel} className={styles.cancel_button}>
							<FormattedMessage id="App.AnalyticsRegionExportCancel" />
						</Button>
					)}
				</div>
			</Form>
			<SmarterTable
				rowKey={() => {
					return Math.random()
						.toString(36)
						.substring(7);
				}}
				loading={loading}
				columns={[
					{
						title: 'App.EventsTimestamp',
						dataIndex: 'created',
						render: renderTime,
						sorter: (a, b) => a.created - b.created,
					},
					{
						title: 'App.EventsClientTimestamp',
						dataIndex: 'clientTS',
						render: renderTime,
						sorter: (a, b) => a.clientTS - b.clientTS,
					},
					{
						title: 'App.EventsUser',
						dataIndex: 'uID',
						render: renderUser(users, isAdmin),
						filter: true,
						filterSearch: {
							onSearch: loadUsers,
							onBlur: () => setUsersSearch([]),
							searchLoading: usersLoading,
							options: usersSearch,
							customOption: userOption,
						},
					},
					{
						title: 'App.EventsType',
						dataIndex: 'type',
						filters: eventTypes,
					},
					{
						title: 'App.EventsContent',
						dataIndex: 'content',
						render: content => (
							<ReactJson
								iconStyle="square"
								displayDataTypes={false}
								enableClipboard={false}
								collapseStringsAfterLength={40}
								collapsed={1}
								src={deepParseJson(content)}
							/>
						),
						filter: true,
					},
					{
						title: 'App.EventsTriggers',
						dataIndex: 'trigger',
						filters: triggers,
						render: text => {
							return (
								<div>
									<span>{text}</span>
								</div>
							);
						},
					},
				]}
				dataSource={events}
				loadDataSource={loadEvents}
				setParams={setParams}
				totalRecords={totalRecords}
				pagination={{
					position: 'top',
					showTotal: (total, range) => (
						<FormattedMessage
							id="Proceq.JQGridTableRecordText"
							values={{
								'0': range[0],
								'1': range[1],
								'2': <span data-e2e-table-total-count>{total}</span>,
							}}
						/>
					),
				}}
			/>
		</Fragment>
	);
};

export default compose(
	injectIntl,
	Form.create({}),
	withState('loading', 'setLoading', false),
	withState('users', 'setUsers', []),
	withState('usersSearch', 'setUsersSearch', []),
	withState('usersLoading', 'setUsersLoading', false),
	withState('events', 'setEvents', []),
	withState('params', 'setParams'),
	withState('triggers', 'setTriggers', []),
	withState('triggerKeys', 'setTriggerKeys', []),
	withState('eventTypes', 'setEventTypes', []),
	withState('eventTypesKeys', 'setEventTypesKeys', []),
	withState('totalRecords', 'setTotalRecords', 0),
	withState('cancelSource', 'setCancelSource', axios.CancelToken.source()),
	withHandlers({
		cancel: ({ cancelSource, intl, setCancelSource, setLoading }) => () => {
			cancelSource.cancel();
			message.error(intl.formatMessage({ id: 'App.AnalyticsOperationCancelled' }));
			setCancelSource(axios.CancelToken.source());
			setLoading(false);
		},
	}),
	connect(
		state => ({
			product: state.session.productType,
			isAdmin: hasRoleLevel(state.session.scopes, ROLES.ADMIN),
		}),
		(dispatch: Function, props) => ({
			loadEvents: eventParam => {
				props.setLoading(true);
				const dateRange = props.form.getFieldValue('dateRange');
				const appNames = props.form.getFieldValue('appName');
				const products = props.form.getFieldValue('productModel');
				const params =
					props.params === undefined
						? {
								start: 0,
								length: 500,
								from: dateRange.from.unix(),
								to: dateRange.to.unix(),
								appnames: appNames,
								products,
						  }
						: props.params;
				eventParam = eventParam === undefined ? params : eventParam;
				eventParam.from = dateRange === {} ? undefined : dateRange.from.unix();
				eventParam.to = dateRange === {} ? undefined : dateRange.to.unix();

				if (appNames) {
					if (appNames.includes('All') || appNames === []) {
						eventParam.appnames = undefined;
					} else {
						eventParam.appnames = appNames;
					}
				}

				if (products) {
					if (products.includes('All') || products === []) {
						eventParam.products = undefined;
					} else {
						eventParam.products = products;
					}
				}

				dispatch(
					request({
						url: URL.GET_EVENTS,
						setLoading: props.setLoading,
						params: eventParam,
						injectProduct: false,
						options: {
							cancelToken: props.cancelSource.token,
						},
					})
				)
					.then(data => {
						const uIDs = data.data.map(event => event.uID);
						const uniqueIds = [...new Set(uIDs)];
						dispatch(
							request({
								url: URL.GET_ACCOUNTS,
								params: {
									ids: uniqueIds,
								},
							})
						).then(users => {
							const userList = users.data;
							userList.sort((u1, u2) => {
								return u1.username === u2.username ? 0 : u1.username > u2.username ? 1 : -1;
							});
							props.setUsers(userList);
						});
						props.setTotalRecords(data.recordsFiltered, () => {
							props.setEvents(data.data);
						});
						props.setLoading(false);
					})
					.catch(() => {
						props.setLoading(false);
						props.cancelSource.cancel();
						props.setCancelSource(axios.CancelToken.source());
					});
			},
			loadUsers: debounce((value: string) => {
				if (value.length < 1) {
					return;
				}
				props.setUsersLoading(true);
				dispatch(
					request({
						url: URL.GET_ACCOUNTS,
						params: {
							q: value,
						},
					})
				).then(data => {
					const userList = data.data;
					userList.sort((u1, u2) => {
						return u1.username === u2.username ? 0 : u1.username > u2.username ? 1 : -1;
					});
					props.setUsersSearch(userList);
					props.setUsersLoading(false);
				});
			}, 500),
			loadFilters: () => {
				dispatch(
					request({
						url: URL.GET_EVENT_FILTERS,
						params: {},
					})
				).then(data => {
					if (data && Array.isArray(data.triggers)) {
						data.triggers.sort();
					}
					props.setTriggers(
						data.triggers.map(type => ({
							text: type,
							value: type,
						}))
					);

					if (data && Array.isArray(data.eventTypes)) {
						data.eventTypes.sort();
					}
					props.setEventTypes(
						data.eventTypes.map(type => ({
							text: type,
							value: type,
						}))
					);
				});
			},
		})
	),
	lifecycle({
		componentDidMount() {
			this.props.loadFilters();
		},
	})
)(Events);
