// @flow
import React from 'react';
import { get } from 'lodash';
import { Collapse, Spin } from 'antd';
import { withState, compose, lifecycle } from 'recompose';
import { connect } from 'react-redux';
import { request } from 'actions/ajax';
import URL from 'apis/urls';
import { convertAndDisplay } from 'constants/conversionRates';
import type { DerivedProps } from './HTMLExport/Measurement';
import User, { title as UserTitle } from './HTMLExport/components/Common/User';
import MeasurementPresets, {
	title as MeasurementPresetsTitle,
} from './HTMLExport/components/Settings/GPR/MeasurementPresets';
import ImageProcessing, { title as ImageProcessingTitle } from './HTMLExport/components/Settings/GPR/ImageProcessing';
import Objects, { title as ObjectsTitle } from './HTMLExport/components/Settings/GPR/Objects';
import Markers, { title as MarkersTitle } from './HTMLExport/components/Settings/GPR/Markers';
import ScanDistance, { title as ScanDistanceTitle } from './HTMLExport/components/Settings/GPR/ScanDistance';

export const GPRReadings = ({ loading, data }: { loading: boolean, data: MeasurementFullData }) => {
	if (loading) {
		return <Spin />;
	}

	const scanType = get(data, 'measurement.type');
	const productModel = get(data, 'measurement.productModel');
	const isMetric = get(data, 'settings.0.content.settings.display.unit') === 'Metric';
	const derivedProps: DerivedProps = {
		product: 'GPR',
		productModel,
		scanType,
		isMetric,
		convert: (value, unitId) =>
			isFinite(+value) ? convertAndDisplay(unitId, scanType, isMetric).convertFunction(+value) : '-',
		showTitle: false,
	};

	return (
		<Collapse>
			<Collapse.Panel header={UserTitle}>
				<User data={data} {...derivedProps} />
			</Collapse.Panel>
			<Collapse.Panel header={MeasurementPresetsTitle}>
				<MeasurementPresets data={data} {...derivedProps} />
			</Collapse.Panel>
			<Collapse.Panel header={ImageProcessingTitle}>
				<ImageProcessing data={data} {...derivedProps} />
			</Collapse.Panel>
			{data.objects && (
				<Collapse.Panel header={ObjectsTitle}>
					<Objects data={data} {...derivedProps} />
				</Collapse.Panel>
			)}
			{data.objects && (
				<Collapse.Panel header={MarkersTitle}>
					<Markers data={data} {...derivedProps} />
				</Collapse.Panel>
			)}
			<Collapse.Panel header={ScanDistanceTitle(derivedProps)}>
				<ScanDistance data={data} {...derivedProps} />
			</Collapse.Panel>
		</Collapse>
	);
};

export default compose(
	withState('loading', 'setLoading', true),
	withState('data', 'setData', {}),
	connect(null, (dispatch: Function, props) => ({
		loadMeasurement: () => {
			props.setLoading(true);
			dispatch(
				request({
					url: URL.MEASUREMENTS + props.mID,
				})
			).then(data => {
				props.setData(data);
				props.setLoading(false);
			});
		},
	})),
	lifecycle({
		componentDidMount() {
			this.props.loadMeasurement();
		},
	})
)(GPRReadings);
