// @flow
import React from 'react';
import { FormattedMessage } from 'react-intl';

export const Angles = ({ log }: GPRLogbookEntryProps) => {
	const { newValue, oldValue } = log.content;
	return <FormattedMessage id="Geometries changed from  {oldValue} to {newValue}" values={{ oldValue, newValue }} />;
};

export const color = () => 'blue';

export default Angles;
