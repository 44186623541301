// @flow
import React from 'react';
import { FormattedMessage } from 'react-intl';

export const AddMeasuringMode = ({ log }: GPRLogbookEntryProps) => (
	<FormattedMessage
		id="App.Logbook.FDL.MeasuringMode"
		values={{
			mode: log.content.mode,
		}}
	/>
);

export default AddMeasuringMode;
