import React from 'react';
import styles from '../layout/Layout.styl';

export const EagleHead = () => (
	<svg width="18px" height="18px" viewBox="0 0 18 18" version="1.1" className={styles.icon}>
		<defs>
			<polygon id="path-1" points="0 0.0124375057 17.9655495 0.0124375057 17.9655495 17.3333333 0 17.3333333" />
		</defs>
		<g id="Artboard" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<g id="Eagle_Symbol_WHITE">
				<mask id="mask-2" fill="white">
					<use xlinkHref="#path-1" />
				</mask>
				<g id="Clip-2" />
				<path
					d="M11.3100833,3.292 C10.8854167,3.21558333 10.6165833,2.58016667 10.3569167,2.128 C10.15525,1.77358333 10.2346667,1.59025 10.6776667,1.59025 C10.8548333,1.59025 11.0931667,1.62083333 11.3925833,1.67883333 C12.4435,1.88658333 14.14825,3.5975 14.14825,3.5975 C14.14825,3.5975 11.5911667,3.34391667 11.3100833,3.292 Z M15.9355833,3.54858333 C15.9355833,3.54858333 16.19825,0.126833333 10.363,0.0138333333 C6.27525,-0.0625833333 0.556,2.99558333 0.01525,9.94608333 C0.00916666667,10.0865833 0,10.3950833 0,10.5356667 C0,15.3689167 2.7985,16.2975833 2.7985,16.2975833 C2.7985,16.2975833 1.81783333,14.7180833 2.5205,13.03175 C2.5205,13.03175 2.07141667,17.3363333 6.84658333,17.3333349 C6.84658333,17.3333349 5.55733333,16.46875 5.42283333,15.10925 C5.42283333,15.10925 8.55433333,18.5004167 12.48325,15.7355 C12.48325,15.7355 6.37608333,15.9370833 5.11433333,11.2108333 C5.11433333,11.2108333 5.78333333,12.106 7.02066667,13.0011667 C8.51466667,14.0795833 10.8365833,15.1580833 13.8214167,14.66925 C13.8214167,14.66925 11.2275833,13.6671667 11.3803333,10.7678333 C11.5330833,7.8685 14.5301667,7.22391667 14.5301667,7.22391667 C13.64725,6.19125 12.3488333,5.60466667 10.9893333,5.62916667 C10.9281667,5.62608333 10.864,5.62608333 10.8029167,5.62608333 C10.9954167,5.61383333 11.1878333,5.60775 11.3803333,5.60466667 L11.4903333,5.60466667 C11.4903333,5.60466667 16.9804167,4.96308333 16.3876667,6.99175 C16.3876667,6.99175 17.5364167,6.90308333 17.88775,6.13016667 C18.2421667,5.36025 17.3500833,4.14433333 15.9355833,3.54858333 L15.9355833,3.54858333 Z"
					id="Fill-1"
					fill="#FFFFFE"
					mask="url(#mask-2)"
				/>
			</g>
		</g>
	</svg>
);

export default EagleHead;
