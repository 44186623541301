/* eslint-disable func-names,no-restricted-globals */
import React, { Component } from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { Modal, Form, Button, Icon, Spin } from 'antd';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { httpRequest } from 'apis/httpClient';
import { checkStatusJson } from 'apis/restUtils';
import errorHandler from 'actionUtils/errorHandler';
import { DATE_SERVER_FORMAT, DATETIME_SERVER_FORMAT, DATETIMENOSECS_SERVER_FORMAT } from 'utils/dateHandler';
import handleFormatMessage from 'utils/handleFormatMessage';
import URL from 'apis/urls';
import CustomAlert from './CustomAlert';
import SmartFormItem from './SmartFormItem';
import styles from './SmartForm.styl';
import './SmartForm.css';
import { passwordPolicyValidator } from '../../utils/passwordValidator';

class SmartForm extends Component {
	static propTypes = {
		form: PropTypes.shape({
			getFieldDecorator: PropTypes.func.isRequired,
			getFieldsValue: PropTypes.func.isRequired,
			resetFields: PropTypes.func.isRequired,
		}),
		intl: PropTypes.shape({
			formatMessage: PropTypes.func.isRequired,
		}),
		isFormVisible: PropTypes.bool,
		apiEndpoint: PropTypes.string,
		session: PropTypes.object,
		locale: PropTypes.object,
		apiTypeMethod: PropTypes.string,
		apiSendToken: PropTypes.bool,
		onErrorForm: PropTypes.func,
		messageOnSuccess: PropTypes.string,
		clearFormOnSuccess: PropTypes.bool,
		hideFormOnSuccess: PropTypes.bool,
		onSuccessForm: PropTypes.func,
		isModalForm: PropTypes.bool,
		inputClasses: PropTypes.string,
		buttons: PropTypes.shape({
			submitButton: PropTypes.object,
			resetButton: PropTypes.object,
		}),
		titleText: PropTypes.string,
		titleIcon: PropTypes.string,
		formClasses: PropTypes.string,
		overrideFields: PropTypes.object,
		fieldSets: PropTypes.arrayOf(PropTypes.object),
	};

	static getFormattedValue(fieldType, fieldValue) {
		switch (fieldType) {
			default:
				return fieldValue;
			case 'date':
				return moment(fieldValue, DATE_SERVER_FORMAT);
			case 'dateTime':
				return moment(fieldValue, DATETIME_SERVER_FORMAT);
			case 'dateTimeNoSecs':
				return moment(fieldValue, DATETIMENOSECS_SERVER_FORMAT);
			case 'select':
				if (!isNaN(fieldValue)) {
					return `${fieldValue}`;
				}
				return fieldValue;

			case 'selectmulti':
				if (fieldValue === '' || typeof fieldValue === 'undefined' || fieldValue === null) {
					return [];
				}
				return fieldValue.split(',');
		}
	}

	static getFieldsOfGroup(fieldGroup) {
		if (fieldGroup.constructor === Array) {
			return fieldGroup;
		}
		return [fieldGroup];
	}

	constructor(props) {
		super(props);

		this.state = {
			loadingData: false,
			disableSubmitButton: true,
			currentErrorMessage: '',
			currentSuccessMessage: '',
			showForm: !!props.isFormVisible,
			hideSubmittedForm: false,
		};
		this.onSelectChange = this.onSelectChange.bind(this);
		this.onDatePickerChange = this.onDatePickerChange.bind(this);
		this.handleEmailValidation = this.handleEmailValidation.bind(this);
		this.handleConfirmPasswordValidation = this.handleConfirmPasswordValidation.bind(this);
		this.handleBluetoothSerialValidation = this.handleBluetoothSerialValidation.bind(this);
		this.handleBluetoothHwIDValidation = this.handleBluetoothHwIDValidation.bind(this);
		this.handleWifiMacAddressValidation = this.handleWifiMacAddressValidation.bind(this);
		this.handleProbeSerialValidation = this.handleProbeSerialValidation.bind(this);
		this.handleDateValidation = this.handleDateValidation.bind(this);
		this.handleGroupNameValidation = this.handleGroupNameValidation.bind(this);
		this.handlePasswordValidation = this.handlePasswordValidation.bind(this);
		this.handleFirstNameValidation = this.handleFirstNameValidation.bind(this);
		this.handleLastNameValidation = this.handleLastNameValidation.bind(this);
		this.handlePhoneNumberValidation = this.handlePhoneNumberValidation.bind(this);
		this.handleMustBeCheckedValidation = this.handleMustBeCheckedValidation.bind(this);
	}

	componentDidMount() {
		if (this.state.showForm) {
			this.initFormValues();
		}
	}

	componentWillReceiveProps(nextProps) {
		if ('isFormVisible' in nextProps) {
			if (nextProps.isFormVisible !== this.state.showForm) {
				/* Form visibility just changed */
				this.setState({
					showForm: nextProps.isFormVisible,
				});
				if (nextProps.isFormVisible) {
					this.initFormValues();
				}
			}
		}
	}

	onSubmit(e = null) {
		if (e) {
			e.preventDefault();
		}
		const formFieldsValues = { ...this.props.form.getFieldsValue() };

		const listOfFieldsNames = this.getListOfFields();
		listOfFieldsNames.forEach(function(fieldName) {
			const thisFieldInfo = this.getFieldInfo(fieldName);
			let thisValidationType = null;
			let convertToIntValue = false;
			if (thisFieldInfo.validation && thisFieldInfo.validation.type) {
				thisValidationType = thisFieldInfo.validation.type;
			}
			if (
				thisFieldInfo.validation &&
				'returnAsInt' in thisFieldInfo.validation &&
				thisFieldInfo.validation.returnAsInt
			) {
				convertToIntValue = true;
			}
			switch (thisFieldInfo.type) {
				default:
					break;
				case 'input':
					switch (thisValidationType) {
						default:
							break;
						case 'wifimacaddress':
							formFieldsValues[fieldName] = formFieldsValues[fieldName].toUpperCase();
							break;
					}
					break;
				case 'date':
					if (formFieldsValues[fieldName]) {
						formFieldsValues[fieldName] = formFieldsValues[fieldName].format(DATE_SERVER_FORMAT);
					}
					break;
				case 'password':
					formFieldsValues[fieldName] = btoa(unescape(encodeURIComponent(formFieldsValues[fieldName])));
					break;
				case 'passwordnoencode':
					formFieldsValues[fieldName] = formFieldsValues[fieldName];
					break;
				case 'dateTime':
					formFieldsValues[fieldName] = formFieldsValues[fieldName].format(DATETIME_SERVER_FORMAT);
					break;
				case 'dateTimeNoSecs':
					formFieldsValues[fieldName] = formFieldsValues[fieldName].format(DATETIMENOSECS_SERVER_FORMAT);
					break;
				case 'selectmulti':
					if (typeof formFieldsValues[fieldName] !== 'undefined' && formFieldsValues[fieldName].length >= 1) {
						formFieldsValues[fieldName] = formFieldsValues[fieldName].join(',');
					} else {
						formFieldsValues[fieldName] = '';
					}
					break;
			}
			if (convertToIntValue) {
				if (!isNaN(formFieldsValues[fieldName])) {
					formFieldsValues[fieldName] = parseInt(formFieldsValues[fieldName], 10);
				}
			}
		}, this);

		const replacedFieldsAlready = [];
		listOfFieldsNames.forEach(function(fieldName) {
			const thisFieldInfo = this.getFieldInfo(fieldName);

			if ('showIf' in thisFieldInfo && replacedFieldsAlready.indexOf(fieldName) === -1) {
				let atlLeastOneMatchFound = false;
				for (let indexShowIfName = 0; indexShowIfName < thisFieldInfo.showIf.name.length; indexShowIfName++) {
					const conditionFieldValue = formFieldsValues[thisFieldInfo.showIf.name[indexShowIfName]];
					if (thisFieldInfo.showIf.value.indexOf(conditionFieldValue) >= 0) {
						atlLeastOneMatchFound = true;
					}
				}
				if (!atlLeastOneMatchFound) {
					delete formFieldsValues[fieldName];
				} else if ('replaceField' in thisFieldInfo.showIf) {
					replacedFieldsAlready.push(thisFieldInfo.showIf.replaceField);
					formFieldsValues[thisFieldInfo.showIf.replaceField] = formFieldsValues[fieldName];
					delete formFieldsValues[fieldName];
				}

				/* const conditionFieldValue = formFieldsValues[thisFieldInfo.showIf.name];
				if (thisFieldInfo.showIf.value.indexOf(conditionFieldValue) === -1) {
					delete formFieldsValues[fieldName];
				}
				else if ('replaceField' in thisFieldInfo.showIf) {
					formFieldsValues[thisFieldInfo.showIf.replaceField] = formFieldsValues[fieldName];
					delete formFieldsValues[fieldName];
				} */
			}
		}, this);

		this.setState({
			currentErrorMessage: '',
			currentSuccessMessage: '',
		});

		if (this.props.apiEndpoint) {
			return httpRequest(
				this.props.session,
				this.props.locale,
				this.props.apiEndpoint,
				this.props.apiTypeMethod,
				this.props.apiSendToken,
				formFieldsValues
			)
				.then(checkStatusJson)
				.then(json => {
					if (!isEmpty(json) && typeof fileContent !== 'string' && 'data' in json) {
						this.apiRequestSuccessHandler(json.data, formFieldsValues);
					} else {
						this.apiRequestSuccessHandler(json, formFieldsValues);
					}
				}, this)
				.catch(err => {
					this.apiRequestErrorHandler(err);
				}, this);
		}
		/* if no endpoint then the request always succeds */
		this.onSuccess(null, formFieldsValues);

		return true;
	}

	onError(error) {
		const { intl } = this.props,
			{ formatMessage } = intl;

		let messageToShow = '';
		if ('message' in error) {
			messageToShow = error.message;
		} else if ('error_description' in error) {
			messageToShow = error.error_description;
		} else if ('code' in error) {
			messageToShow = error.code;
			messageToShow = handleFormatMessage(messageToShow, formatMessage);
		} else {
			messageToShow = 'Error sending request';
		}

		this.setState({ currentErrorMessage: messageToShow });
		if (this.props.onErrorForm) {
			this.props.onErrorForm(error);
		}
	}

	onSuccess(json, formFieldsValues) {
		if (this.props.messageOnSuccess) {
			this.setState({
				currentSuccessMessage: this.props.messageOnSuccess,
			});
		}

		if (this.props.clearFormOnSuccess) {
			this.resetForm();
		}

		if (this.props.hideFormOnSuccess) {
			this.setState({ hideSubmittedForm: true });
		}

		if (this.props.onSuccessForm) {
			this.props.onSuccessForm(json, formFieldsValues);
		}

		if (this.props.isModalForm) {
			this.setModalVisible(false);
		}
		/* if( (typeof this.successData !== "undefined")&& (typeof this.successData === 'object')&& (this.successData != null)  && ('file_method' in this.successData) && this.successData.file_data){
			this.apiRequestService.fileDownloadRequest(this.successData.file_data,this.successData.file_method, this.successData.file_mime , this.successData.file_size, this.successData.file_name );
		} */
	}

	onDatePickerChange(fieldName, newValue) {
		/* Some issues with the DatePicker since it does not trigger the onChange on the form */
		const { form } = this.props;
		form.setFieldsValue({ [fieldName]: newValue });
		this.onChange();
	}

	onSelectChange(fieldName, newValue) {
		/* Some issues with the Select since it does not trigger the onChange on the form */
		const { form } = this.props;
		form.setFieldsValue({ [fieldName]: newValue });
		// console.log('onSelectChange');
		// console.log('fieldName:' + fieldName + ":" + newValue);
		this.onChange();
	}

	onChange = () => {
		this.setState({ disableSubmitButton: !this.isValidForm() }, () => {
			// console.log('forceUpdate');
			this.forceUpdate();
		});
	};

	onModalOkClicked() {
		if (this.state.disableSubmitButton) {
			/* TODO: Do nothing or show error */
			const { form } = this.props;
			form.validateFields();
		} else {
			this.onSubmit(null);
		}
	}

	render() {
		const { intl, form } = this.props,
			{ getFieldDecorator } = this.props.form,
			{ disableSubmitButton } = this.state,
			{ formatMessage } = intl;
		const listOfFieldsNames = this.getListOfFields();
		const { inputClasses } = this.props;
		const buttonsContainer = (
			<div className={styles.button}>
				{this.props.buttons.submitButton ? (
					<Form.Item>
						<Button type="primary" htmlType="submit" size="large" disabled={disableSubmitButton}>
							{this.props.buttons.submitButton.icon ? (
								<Icon type={this.props.buttons.submitButton.icon} />
							) : (
								''
							)}{' '}
							{handleFormatMessage(this.props.buttons.submitButton.title, formatMessage)}
						</Button>
					</Form.Item>
				) : (
					''
				)}
				{this.props.buttons.resetButton ? (
					<Form.Item>
						<Button type="primary" onClick={() => this.resetForm()} size="large">
							{this.props.buttons.resetButton.icon ? (
								<Icon type={this.props.buttons.resetButton.icon} />
							) : (
								''
							)}{' '}
							{handleFormatMessage(this.props.buttons.resetButton.title, formatMessage)}
						</Button>
					</Form.Item>
				) : (
					''
				)}
			</div>
		);
		let okButtonTest = 'OK';
		if (this.props.buttons.submitButton && this.props.buttons.submitButton.title)
			okButtonTest = handleFormatMessage(this.props.buttons.submitButton.title, formatMessage);

		/* if (this.state.loadingData) {
		 return (
		 <Spin style={ { marginTop: '50px' } } tip='Loading...' />
		 );
		 }
		 else { */

		const formContainer = (
			<div>
				<div
					className={classNames({
						[styles.profile]: true,
						hidden_form: !this.state.loadingData,
					})}
				>
					<Spin
						style={{ marginTop: '50px' }}
						tip={handleFormatMessage('Proceq.JQGridTableLoadText', formatMessage)}
					/>
				</div>
				<div
					className={classNames({
						[styles.profile]: true,
						hidden_form: this.state.loadingData,
					})}
				>
					{this.props.titleText ? (
						<h1 style={{ marginBottom: '16px' }}>
							{' '}
							{this.props.titleIcon ? <Icon type={this.props.titleIcon} /> : ''}{' '}
							{handleFormatMessage(this.props.titleText, formatMessage)}
						</h1>
					) : (
						''
					)}
					{this.state.currentErrorMessage ? (
						<CustomAlert
							style={this.props.isModalForm ? { width: '100%' } : { width: '50%' }}
							message={handleFormatMessage(this.state.currentErrorMessage, formatMessage)}
							type="error"
						/>
					) : (
						''
					)}
					{this.state.currentSuccessMessage ? (
						<CustomAlert
							style={this.props.isModalForm ? { width: '100%' } : { width: '50%' }}
							message={handleFormatMessage(this.state.currentSuccessMessage, formatMessage)}
							type="success"
						/>
					) : (
						''
					)}

					<div
						className={classNames({
							[styles.modal_form_wrapper]: this.props.isModalForm,
							[styles.nonmodal_form_wrapper]: !this.props.isModalForm,
							[this.props.formClasses]: true,
							hidden_form: this.state.hideSubmittedForm,
						})}
					>
						<Form
							className={styles.form}
							onSubmit={this.onSubmit.bind(this)}
							onChange={this.onChange.bind(this)}
						>
							<div>
								{listOfFieldsNames.map(function(fieldName, indexField) {
									const field = this.getFieldInfo(fieldName);
									const isDisabled = !!field.onlyread;
									let asHTML = null;
									if (field.asHTML) {
										asHTML = field.default;
									}

									const thisFieldRules = this.getFieldRules(fieldName);
									const thisFieldDecorator = getFieldDecorator(fieldName, {
										rules: thisFieldRules,
									});
									let hasToolTip = false;
									let toolTipText = '';
									if ('hasToolTip' in field) {
										hasToolTip = field.hasToolTip;
										toolTipText = field.toolTipText;
									}

									let thisFieldInputClasses = [];
									if ('styleClasses' in field) {
										thisFieldInputClasses = field.styleClasses.split(' ');
									}
									const inputClassesField = inputClasses;
									const inputClassesFieldSplitted = inputClassesField
										.split(' ')
										.concat(thisFieldInputClasses);

									const inputClassesFieldSStyles = [];
									inputClassesFieldSplitted.forEach(thisClass => {
										if (thisClass in styles) {
											inputClassesFieldSStyles.push(styles[thisClass]);
										} else {
											inputClassesFieldSStyles.push(thisClass);
										}
									});

									if (this.props.isModalForm) {
										inputClassesFieldSStyles.push(styles.modal_form_item);
									}

									let displayField = true;
									if ('showIf' in field) {
										displayField = false;
										for (
											let indexShowIfName = 0;
											indexShowIfName < field.showIf.name.length;
											indexShowIfName++
										) {
											if (
												field.showIf.value.indexOf(
													form.getFieldValue(field.showIf.name[indexShowIfName])
												) >= 0
											) {
												displayField = true;
											}
										}
										/* if (field.showIf.value.indexOf(form.getFieldValue(field.showIf.name)) === -1) {
											 displayField = false;
											 } */
									}

									if (displayField) {
										return (
											<div className={inputClassesFieldSStyles.join(' ')} key={indexField}>
												<SmartFormItem
													asHTML={asHTML}
													fieldName={fieldName}
													decorator={thisFieldDecorator}
													formItem={{
														label:
															typeof field.title === 'string'
																? handleFormatMessage(field.title, formatMessage)
																: field.title,
													}}
													tooltip={{
														hasTooltip: hasToolTip,
														text: toolTipText,
													}}
													allowedValues={field.allowedValues ? field.allowedValues : null}
													onChange={this.onChange}
													onSelectChange={this.onSelectChange}
													onDatePickerChange={this.onDatePickerChange}
													input={{
														disabled: isDisabled,
														type: field.type,
														placeholder: handleFormatMessage(
															field.placeholder,
															formatMessage
														),
													}}
												/>
											</div>
										);
									}
									return <div key={indexField} style={{ display: 'none' }} />;
								}, this)}
							</div>
							{!this.props.isModalForm ? buttonsContainer : ''}
						</Form>
					</div>
				</div>
			</div>
		);

		return this.props.isModalForm ? (
			<Modal
				visible={this.state.showForm}
				onOk={() => this.onModalOkClicked(false)}
				okText={okButtonTest}
				onCancel={() => this.setModalVisible(false)}
			>
				{formContainer}
			</Modal>
		) : (
			formContainer
		);
		/* } */
	}

	initFormValues() {
		this.setState({
			currentErrorMessage: '',
			currentSuccessMessage: '',
		});
		this.fillFormWithDefaultValues();
		if (this.props.overrideFields) {
			this.overrideFormValues();
		}
	}

	validateField(typefield, value) {
		const { form } = this.props;
		const currentValueField = value;
		if (typeof currentValueField === 'undefined' || currentValueField === null) return false;
		let isFieldValid = true;
		switch (typefield) {
			default:
				break;
			case 'email':
				if (
					!currentValueField.match(
						/^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i
					)
				)
					isFieldValid = false;
				break;
			case 'bluetoothserial':
				if (!currentValueField.match(/^BT[0-9]{2}-[0-9]{3}-[0-9]{4}$/)) isFieldValid = false;
				break;
			case 'bluetoothhwid':
				if (!currentValueField.match(/^[.0-9A-F]{16,32}$/)) isFieldValid = false;
				break;
			case 'wifimacaddress':
				if (
					!currentValueField.match(
						/^[0-9A-Fa-f]{2}:[0-9A-Fa-f]{2}:[0-9A-Fa-f]{2}:[0-9A-Fa-f]{2}:[0-9A-Fa-f]{2}:[0-9A-Fa-f]{2}$/
					)
				)
					isFieldValid = false;
				break;
			case 'probeserial':
				if (!currentValueField.match(/^[A-Z0-9]{4}-[0-9]{3}-[0-9]{4}$/i)) isFieldValid = false;
				break;
			case 'date':
				/* Done by the DatePicker */ break;
			case 'groupname':
				if (!currentValueField.match(/^[a-zA-Z0-9]+$/i) || currentValueField.length < 1) isFieldValid = false;
				break;
			case 'password': {
				isFieldValid = passwordPolicyValidator(
					document.getElementById('firstName').value,
					document.getElementById('lastName').value,
					document.getElementById('username').value,
					value
				);
				break;
			}
			case 'firstname':
				if (currentValueField.length < 1 || currentValueField.length > 50) isFieldValid = false;
				break;
			case 'lastname':
				if (currentValueField.length < 1 || currentValueField.length > 50) isFieldValid = false;
				break;
			case 'phonenumber':
				if (!currentValueField.match(/^[0-9-#()/ ]{5,20}$/)) isFieldValid = false;
				break;
			case 'required':
				if (currentValueField === null || currentValueField === '') isFieldValid = false;
				break;
			case 'somethingselected':
				if (currentValueField === null || currentValueField === '') isFieldValid = false;
				break;
			case 'confirmpassword':
				if (currentValueField === null) {
					isFieldValid = true;
				}
				if (
					form.getFieldValue('newPassword') !== currentValueField &&
					form.getFieldValue('password_new') !== currentValueField
				) {
					isFieldValid = false;
				}
				break;
			case 'mustbechecked':
				isFieldValid = currentValueField === true;
				break;
		}
		return isFieldValid;
	}

	fetchUserDetailsAndValidate(value, username, callback) {
		httpRequest(
			this.props.session,
			this.props.locale,
			`${URL.GET_SELF}?filterKey=username&filterValue=${username}`,
			'GET',
			true,
			{}
		)
			.then(checkStatusJson)
			.then(json => {
				const isValid = passwordPolicyValidator(json.firstName, json.lastName, json.username, value);
				if (!isValid) {
					callback('Invalid password');
				} else {
					callback();
				}
			}, this)
			.catch(() => {
				callback();
			}, this);
	}

	setModalVisible(visible) {
		this.setState({
			showForm: visible,
		});
	}

	setOverrideValuesWithMapping(overrideValues) {
		if (!overrideValues || overrideValues === null || typeof overrideValues === 'undefined') {
			return;
		}

		const { form } = this.props;

		Object.entries(overrideValues).forEach(([fieldNameBeforeMapping, defaultValue]) => {
			let targetFieldNames = [fieldNameBeforeMapping];
			if ('mapping' in this.props.overrideFields && fieldNameBeforeMapping in this.props.overrideFields.mapping) {
				targetFieldNames = this.props.overrideFields.mapping[fieldNameBeforeMapping];
				if (typeof targetFieldNames === 'string') {
					targetFieldNames = [targetFieldNames];
				}
			}
			targetFieldNames.forEach(function(fieldName) {
				const fieldInfo = this.getFieldInfo(fieldName);
				if (fieldInfo !== null) {
					form.setFieldsValue({
						[fieldName]: this.constructor.getFormattedValue(fieldInfo.type, defaultValue),
					});
				}
			}, this);
		});

		// Check the showIf values
		const listOfFieldsNames = this.getListOfFields();
		listOfFieldsNames.forEach(function(fieldName) {
			const thisFieldInfo = this.getFieldInfo(fieldName);
			if ('showIf' in thisFieldInfo) {
				for (let indexShowIfName = 0; indexShowIfName < thisFieldInfo.showIf.name.length; indexShowIfName++) {
					const conditionFieldValue = form.getFieldValue(thisFieldInfo.showIf.name[indexShowIfName]);
					if (
						thisFieldInfo.showIf.value.indexOf(conditionFieldValue) >= 0 &&
						'replaceField' in thisFieldInfo.showIf
					) {
						const currenFieldValueReplaceField = form.getFieldValue(thisFieldInfo.showIf.replaceField);
						form.setFieldsValue({
							[fieldName]: currenFieldValueReplaceField,
						});
						if (currenFieldValueReplaceField.constructor === Array) {
							form.setFieldsValue({
								[thisFieldInfo.showIf.replaceField]: [],
							});
						} else {
							form.setFieldsValue({
								[thisFieldInfo.showIf.replaceField]: '',
							});
						}
					}
				}
			}
		}, this);
	}

	overrideFormValues() {
		if (this.props.overrideFields.values) {
			this.setOverrideValuesWithMapping(this.props.overrideFields.values);
		}

		if ('apiEndpoint' in this.props.overrideFields) {
			this.setState({
				loadingData: true,
			});
			let apiTypeMethod = 'get';
			if ('apiTypeMethod' in this.props.overrideFields) {
				apiTypeMethod = this.props.overrideFields.apiTypeMethod;
			}

			/* Do the API call and do not show the form until the data has been received */
			/* return */ httpRequest(
				this.props.session,
				this.props.locale,
				this.props.overrideFields.apiEndpoint,
				apiTypeMethod,
				true,
				{}
			)
				.then(checkStatusJson)
				.then(json => {
					// TODO
					if (!isEmpty(json) && typeof fileContent !== 'string' && 'data' in json) {
						this.setOverrideValuesWithMapping(json.data);
					} else {
						this.setOverrideValuesWithMapping(json);
					}
					this.setState({
						loadingData: false,
					});
				}, this)
				.catch(err => {
					errorHandler(err);
					// TODO
					this.setState({
						loadingData: false,
					});
				}, this);
		}
	}

	resetForm() {
		this.fillFormWithDefaultValues();
	}

	fillFormWithDefaultValues() {
		const { form } = this.props;
		const listOfFieldsNames = this.getListOfFields();
		listOfFieldsNames.forEach(function(fieldName) {
			const fieldInfo = this.getFieldInfo(fieldName);
			if (fieldInfo !== null) {
				const defaultValue = this.getDefaultValue(fieldName);
				form.setFieldsValue({ [fieldName]: defaultValue });
			}
		}, this);
	}

	getDefaultValue(fieldName) {
		const fieldInfo = this.getFieldInfo(fieldName);
		if ('default' in fieldInfo) {
			return this.constructor.getFormattedValue(fieldInfo.type, fieldInfo.default);
		}
		switch (fieldInfo.type) {
			default:
				return null;
			case 'selectmulti':
				return [];
		}
	}

	isFieldValid(fieldName) {
		let isValid = true;
		const { form } = this.props;
		const currentFieldValue = form.getFieldValue(fieldName);
		const thisFieldInfo = this.getFieldInfo(fieldName);
		if ('showIf' in thisFieldInfo) {
			let atlLeastOneMatchFound = false;
			for (let indexShowIfName = 0; indexShowIfName < thisFieldInfo.showIf.name.length; indexShowIfName++) {
				const conditionFieldValue = form.getFieldValue(thisFieldInfo.showIf.name[indexShowIfName]);
				if (thisFieldInfo.showIf.value.indexOf(conditionFieldValue) >= 0) {
					atlLeastOneMatchFound = true;
				}
			}
			if (!atlLeastOneMatchFound) {
				return true;
			}
		}
		const validatorsArray = this.getFieldNeededValidations(fieldName);
		validatorsArray.forEach(function(validator) {
			if (!this.validateField(validator, currentFieldValue)) {
				isValid = false;
			}
		}, this);
		return isValid;
	}

	isValidForm() {
		let isFormValid = true;
		const listOfFieldsNames = this.getListOfFields();
		listOfFieldsNames.forEach(function(fieldName) {
			if (!this.isFieldValid(fieldName)) {
				isFormValid = false;
			}
		}, this);
		return isFormValid;
	}

	handleEmailValidation(rule, value, callback) {
		const resultValidation = this.validateField('email', value);
		if (!resultValidation) callback('Invalid email');
		else callback();
	}

	handleConfirmPasswordValidation(rule, value, callback) {
		const resultValidation = this.validateField('confirmpassword', value);
		if (!resultValidation) callback('Different new and confirm passwords');
		else callback();
	}

	handleBluetoothSerialValidation(rule, value, callback) {
		const resultValidation = this.validateField('bluetoothserial', value);
		if (!resultValidation) callback('Invalid Bluetooth Serial');
		else callback();
	}

	handleBluetoothHwIDValidation(rule, value, callback) {
		const resultValidation = this.validateField('bluetoothhwid', value);
		if (!resultValidation) callback('Invalid Bluetooth HW ID');
		else callback();
	}

	handleWifiMacAddressValidation(rule, value, callback) {
		const resultValidation = this.validateField('wifimacaddress', value);
		if (!resultValidation) callback('Invalid Wifi MAC Address');
		else callback();
	}

	handleProbeSerialValidation(rule, value, callback) {
		const resultValidation = this.validateField('probeserial', value);
		if (!resultValidation) callback('Invalid Probe Serial');
		else callback();
	}

	handleDateValidation(rule, value, callback) {
		const resultValidation = this.validateField('date', value);
		if (!resultValidation) callback('Invalid Date');
		else callback();
	}

	handleGroupNameValidation(rule, value, callback) {
		const resultValidation = this.validateField('groupname', value);
		if (!resultValidation) callback('Invalid username');
		else callback();
	}

	handlePasswordValidation(rule, value, callback) {
		const username = document.getElementById('username').value;
		this.fetchUserDetailsAndValidate(value, username, callback);
	}

	handleFirstNameValidation(rule, value, callback) {
		const resultValidation = this.validateField('firstname', value);
		if (!resultValidation) callback('Invalid First Name');
		else callback();
	}

	handleLastNameValidation(rule, value, callback) {
		const resultValidation = this.validateField('lastname', value);
		if (!resultValidation) callback('Invalid Last Name');
		else callback();
	}

	handlePhoneNumberValidation(rule, value, callback) {
		const resultValidation = this.validateField('phonenumber', value);
		if (!resultValidation) callback('Invalid Phone Number');
		else callback();
	}

	handleMustBeCheckedValidation(rule, value, callback) {
		const resultValidation = this.validateField('mustbechecked', value);
		if (!resultValidation) callback('');
		else callback();
	}

	apiRequestErrorHandler(err) {
		errorHandler(err);
		let error = null;
		if ('response' in err) {
			error = err.response.data;
		} else {
			error = err;
		}
		this.onError(error);
	}

	apiRequestSuccessHandler(json, formFieldsValues) {
		this.onSuccess(json, formFieldsValues);
	}

	getListOfFields() {
		const toReturn = [];
		for (let indexFieldSet = 0; indexFieldSet < this.props.fieldSets.length; indexFieldSet++) {
			for (let indexField = 0; indexField < this.props.fieldSets[indexFieldSet].fields.length; indexField++) {
				const thisFieldGroupInfo = this.constructor.getFieldsOfGroup(
					this.props.fieldSets[indexFieldSet].fields[indexField]
				);

				for (let indexFieldFinal = 0; indexFieldFinal < thisFieldGroupInfo.length; indexFieldFinal++) {
					const thisFieldInfo = thisFieldGroupInfo[indexFieldFinal];
					toReturn.push(thisFieldInfo.name);
				}
			}
		}
		return toReturn;
	}

	getFieldInfo(fieldName) {
		const toReturn = null;
		for (let indexFieldSet = 0; indexFieldSet < this.props.fieldSets.length; indexFieldSet++) {
			for (let indexField = 0; indexField < this.props.fieldSets[indexFieldSet].fields.length; indexField++) {
				const thisFieldGroupInfo = this.constructor.getFieldsOfGroup(
					this.props.fieldSets[indexFieldSet].fields[indexField]
				);

				for (let indexFieldFinal = 0; indexFieldFinal < thisFieldGroupInfo.length; indexFieldFinal++) {
					const thisFieldInfo = thisFieldGroupInfo[indexFieldFinal];
					if (thisFieldInfo.name === fieldName) {
						return thisFieldInfo;
					}
				}
			}
		}
		return toReturn;
	}

	getFieldNeededValidations(fieldName) {
		const validationsNeeded = [];
		const thisFieldInfo = this.getFieldInfo(fieldName);
		if ('onlyread' in thisFieldInfo && thisFieldInfo.onlyread) {
			return validationsNeeded;
		}
		if ('validation' in thisFieldInfo && thisFieldInfo.validation.required) {
			validationsNeeded.push('required');
		}
		if (
			'validation' in thisFieldInfo &&
			'required' in thisFieldInfo.validation &&
			!thisFieldInfo.validation.required
		) {
			return validationsNeeded;
		}
		if ('validation' in thisFieldInfo && thisFieldInfo.validation) {
			validationsNeeded.push(thisFieldInfo.validation.type);
			switch (thisFieldInfo.type) {
				default:
					break;
				case 'select':
				case 'selectmulti':
					if (
						!('allowedValues' in thisFieldInfo) ||
						!('addEmptyOptionWithLabel' in thisFieldInfo.allowedValues)
					) {
						validationsNeeded.push('somethingselected');
					}
					break;
			}
		}

		return validationsNeeded;
	}

	getFieldRules(fieldName) {
		const rulesToReturn = [];
		const validatorsArray = this.getFieldNeededValidations(fieldName);

		for (let indexValidator = 0; indexValidator < validatorsArray.length; indexValidator++) {
			const thisValidatorInfo = validatorsArray[indexValidator];
			switch (thisValidatorInfo) {
				default:
					break;
				case 'email':
					rulesToReturn.push({
						validator: this.handleEmailValidation,
					});
					break;
				case 'bluetoothserial':
					rulesToReturn.push({
						validator: this.handleBluetoothSerialValidation,
					});
					break;
				case 'bluetoothhwid':
					rulesToReturn.push({
						validator: this.handleBluetoothHwIDValidation,
					});
					break;
				case 'wifimacaddress':
					rulesToReturn.push({
						validator: this.handleWifiMacAddressValidation,
					});
					break;
				case 'probeserial':
					rulesToReturn.push({
						validator: this.handleProbeSerialValidation,
					});
					break;
				case 'date':
					rulesToReturn.push({
						validator: this.handleDateValidation,
					});
					break;
				case 'groupname':
					rulesToReturn.push({
						validator: this.handleGroupNameValidation,
					});
					break;
				case 'password':
					rulesToReturn.push({
						validator: this.handlePasswordValidation,
					});
					break;
				case 'firstname':
					rulesToReturn.push({
						validator: this.handleFirstNameValidation,
					});
					break;
				case 'lastname':
					rulesToReturn.push({
						validator: this.handleLastNameValidation,
					});
					break;
				case 'phonenumber':
					rulesToReturn.push({
						validator: this.handlePhoneNumberValidation,
					});
					break;
				case 'confirmpassword':
					rulesToReturn.push({
						validator: this.handleConfirmPasswordValidation,
					});
					break;
				case 'required':
					rulesToReturn.push({
						required: true,
						message: 'Field is required',
					});
					break;
				case 'mustbechecked':
					rulesToReturn.push({
						validator: this.handleMustBeCheckedValidation,
					});
					break;
			}
		}
		return rulesToReturn;
	}
}

SmartForm.defaultProps = {
	isFormVisible: true,
	isModalForm: false,
	titleIcon: '',
	titleText: '',
	apiEndpoint: '',
	apiTypeMethod: 'post',
	apiSendToken: true,
	fieldSets: [],
	messageOnSuccess: '',
	clearFormOnSuccess: false,
	hideFormOnSuccess: false,
	buttons: {
		submitButton: {
			title: 'ButtonSubmit',
			icon: '',
		},
	},
	inputClasses: 'half_field',
	formClasses: '',
	onErrorForm: null,
	onSuccessForm: null,
	overrideFields: null,
};

SmartForm = Form.create({})(SmartForm); // eslint-disable-line no-class-assign

const mapStateToProps = state => ({
	session: state.session,
	locale: state.locale,
});

export default connect(mapStateToProps)(injectIntl(SmartForm));
