// @flow
import React from 'react';
import { FormattedMessage } from 'react-intl';

export const CorrectionFactor = ({ log }: GPRLogbookEntryProps) => (
	<FormattedMessage
		id="App.LogbookChangeCorrectionFactor"
		values={[log.content.oldCorrectionFactor, log.content.updatedCorrectionFactor]}
	/>
);

export default CorrectionFactor;
