// @flow
import React from 'react';
import { connect } from 'react-redux';
import { Layout } from 'antd';
import { compose, withState, lifecycle, withProps } from 'recompose';
import URL from 'apis/urls';
import { request } from 'actions/ajax';
import { hasRoleLevel, ROLES } from 'utils/rolesHelper';
import columnConfig from './setupFilesColumnConfig';
import MeasurementTable from './MeasurementTable';
import { JSONTreeView } from './Debug/JSONTreeView';

const SetupFilesJSONTreeView = compose(
	withState('visible', 'setVisible', false),
	withProps(({ setVisible }) => ({
		openModal: () => setVisible(true),
		closeModal: () => setVisible(false),
	}))
)(JSONTreeView);

export const SetupFiles = ({
	loading,
	setupFiles,
	totalRecords,
	params,
	loadSetupFiles,
}: {
	loading: boolean,
	setupFiles: Object[],
	totalRecords: number,
	params: Object,
	loadSetupFiles: Function,
}) => (
	<Layout>
		<Layout.Content>
			<MeasurementTable
				loading={loading}
				params={params}
				measurements={setupFiles}
				loadMeasurements={loadSetupFiles}
				totalRecords={totalRecords}
				columnConfig={[
					{
						title: 'Proceq.TableDescription',
						dataIndex: 'content.name',
						sorter: true,
						render: (text, record) => (
							<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
								<span style={{ alignSelf: 'flex-start', flex: 1 }}>{text}</span>
								&nbsp; &nbsp;
								{<SetupFilesJSONTreeView loading={false} output={record} />}
							</div>
						),
					},
					...columnConfig,
				]}
			/>
		</Layout.Content>
	</Layout>
);

export default compose(
	withState('loading', 'setLoading', true),
	withState('setupFiles', 'setSetupFiles', []),
	withState('totalRecords', 'setTotalRecords', 0),
	withState('params', 'setParams', { type: 'SETUP_FILE', limit: 20 }),
	connect(
		state => ({
			product: state.session.productType,
			isDev: hasRoleLevel(state.session.scopes, ROLES.DEV),
		}),
		(dispatch: Function, props) => ({
			loadSetupFiles: newParams => {
				const params = {
					...props.params,
					...newParams,
				};
				props.setParams(params);
				dispatch(
					request({
						url: URL.GET_GENERIC_MEASUREMENTS,
						setLoading: props.setLoading,
						params,
					})
				).then(data => {
					props.setSetupFiles(data.data);
					props.setTotalRecords(data.totalRecords);
				});
			},
		})
	),
	lifecycle({
		componentDidMount() {
			this.props.loadSetupFiles();
		},
		componentWillReceiveProps(nextProps) {
			if (this.props.product !== nextProps.product) {
				this.props.loadSetupFiles();
			}
		},
	})
)(SetupFiles);
