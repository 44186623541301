// @flow
import React from 'react';
import moment from 'moment';
import { compose, withState } from 'recompose';
import { connect } from 'react-redux';
import URL from 'apis/urls';
import { Button } from 'antd';
import { FormattedMessage } from 'react-intl';
import { request } from 'actions/ajax';
import { getFileNameFromContentDisposition } from 'utils/downloadHelper';
import styles from '../shared/SmarterTable.styl';

export const ExportAsJson = ({ iDs, loading, download }: { iDs: string[], loading: boolean, download: Function }) => (
	<Button type="primary" loading={loading} disabled={iDs.length < 1} onClick={download} className={styles.button}>
		<FormattedMessage id="Proceq.TableActionExportAsJSON" />
	</Button>
);

export default compose(
	withState('loading', 'setLoading', false),
	connect(null, (dispatch: Function, props) => ({
		download: () =>
			dispatch(
				request({
					method: 'POST',
					url: URL.EXPORT_REPORTS,
					params: {
						fileType: props.fileType,
						format: 'json',
						iDs: props.iDs,
						timeZoneOffset: moment().utcOffset(),
					},
					setLoading: props.setLoading,
					returnResponseHeaders: true,
					options: {
						responseType: 'blob',
					},
				})
			).then(response =>
				window.fileDownload(
					response.data,
					getFileNameFromContentDisposition(response.headers['content-disposition'] || ''),
					response.headers['content-type']
				)
			),
	}))
)(ExportAsJson);
