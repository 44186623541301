// @flow
import React from 'react';
import { compose } from 'redux';
import { withGoogleMap, GoogleMap, Marker } from 'react-google-maps';
import { withProps } from 'recompose';
// import { Spin } from 'antd';

const InteractiveMap = compose(
	withProps(props => ({
		// these are only needed for withScriptjs
		// googleMapURL: `https://maps.googleapis.com/maps/api/js?v=3.exp&key=${process.env.GOOGLE_MAPS_API_KEY || ''}`,
		// loadingElement: <Spin />,
		containerElement: <div style={{ height: props.height }} />,
		mapElement: <div style={{ height: props.height }} />,
	})),
	// withScriptjs,
	withGoogleMap
)(props => (
	<GoogleMap defaultZoom={props.zoom} defaultCenter={props.coordinates}>
		<Marker position={props.coordinates} />
	</GoogleMap>
));

export default InteractiveMap;
