// @flow
import React, { Fragment } from 'react';
import { Table } from 'antd';
import { FormattedMessage } from 'react-intl';
import ConvertedUnits from '../../../../../shared/ConvertedUnits';
import type { DerivedProps } from '../../../Measurement';
import styles from '../../../HTMLExport.styl';

export const title = (
	<Fragment>
		<FormattedMessage id="Proceq.ExportFieldSettings" />
		{' ­­» '}
		<FormattedMessage id="App.HTML.PUNDIT.Settings.Grid" />
	</Fragment>
);

export const Grid = ({ data, scanType, isMetric, showTitle }: { data: MeasurementFullData } & DerivedProps) => {
	const gridRaw = [
		{
			label: 'App.HTML.PUNDIT.Grid.GridRows',
			value: data.measurement.content.gridScanData.layoutInfo.rows,
		},
		{
			label: 'App.HTML.PUNDIT.Grid.GridColumns',
			value: data.measurement.content.gridScanData.layoutInfo.columns,
		},
		{
			label: 'App.HTML.PUNDIT.Grid.GridStartingPointX',
			value: String.fromCharCode(
				'A'.charCodeAt(0) + data.measurement.content.gridScanData.layoutInfo.originOffset.xOffset
			),
		},
		{
			label: 'App.HTML.PUNDIT.Grid.GridStartingPointY',
			value: data.measurement.content.gridScanData.layoutInfo.originOffset.yOffset + 1,
		},
		{
			label: 'App.HTML.PUNDIT.Grid.GridSpacingX',
			value: data.settings[0].content.display.gridDimension?.xSpacing,
			unitId: 'PUNDIT.GridScan.XYSpacing',
		},
		{
			label: 'App.HTML.PUNDIT.Grid.GridSpacingY',
			value: data.settings[0].content.display.gridDimension?.ySpacing,
			unitId: 'PUNDIT.GridScan.XYSpacing',
		},
	];

	const grid = gridRaw.map(row => ({
		key: row.label,
		label: row.unitId ? (
			<ConvertedUnits id={row.label} unitId={row.unitId} scanType={scanType} isMetric={isMetric} />
		) : (
			<FormattedMessage id={row.label} />
		),
		value: row.value,
	}));

	return (
		<Table
			title={showTitle ? () => <span className={styles.sub_header}>{title}</span> : null}
			className={styles.table}
			dataSource={grid}
			pagination={false}
			showHeader={false}
			size="small"
		>
			<Table.Column dataIndex="label" className={styles.column} />
			<Table.Column dataIndex="value" />
		</Table>
	);
};

export default Grid;
