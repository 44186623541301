// @flow
import React from 'react';
import { connect } from 'react-redux';
import { compose, withState, lifecycle } from 'recompose';
import { Layout, Button } from 'antd';
import { FormattedMessage } from 'react-intl';
import URL, { isLive } from 'apis/urls';
import { request } from 'actions/ajax';
import { getTextValue } from 'utils/registryHelper';
import styles from '../shared/SmarterTable.styl';
import columnConfig from './columnConfig';
import SmarterTable, { generateFilterQuery } from '../shared/SmarterTable';
import ExportDongles from './ExportDongles';

const GPRFamily = getTextValue([isLive ? 'wifiproductslive' : 'wifiproductsfull']).map(({ value }) => value);
const EquotipFamily = getTextValue([isLive ? 'bluetoothproductslive' : 'bluetoothproductsfull']).map(
	({ value }) => value
);

const generateParams = (productFamily: ProductFamily) => {
	switch (productFamily) {
		case 'GPR':
			return generateFilterQuery('productType', GPRFamily, 0);
		case 'EQUOTIP':
			return generateFilterQuery('productType', EquotipFamily, 0);
		default:
			return {};
	}
};

export const Dongles = ({
	dongles,
	totalRecords,
	loadDongles,
	loading,
	setParams,
	productFamily,
}: {
	dongles: Dongle[],
	totalRecords: number,
	loadDongles: Function,
	loading: boolean,
	setParams: Function,
	productFamily: ProductFamily,
}) => (
	<Layout>
		<Layout.Content>
			<Button className={styles.button} type="primary" loading={loading} onClick={loadDongles}>
				<FormattedMessage id="Proceq.TableActionRefresh" />
			</Button>
			<ExportDongles />
			<SmarterTable
				rowKey="id"
				columns={columnConfig[productFamily]}
				dataSource={dongles}
				loadDataSource={loadDongles}
				loading={loading}
				setParams={setParams}
				totalRecords={totalRecords}
				defaultFilters={{
					productType: productFamily === 'GPR' ? ['GPR', 'PUNDIT'] : ['LEEBD', 'UCI', 'SCHMIDT'],
				}}
			/>
		</Layout.Content>
	</Layout>
);

export default compose(
	withState('loading', 'setLoading', true),
	withState('dongles', 'setDongles', []),
	withState('totalRecords', 'setTotalRecords', 0),
	withState('params', 'setParams'),
	connect(null, (dispatch: Function, props) => ({
		loadDongles: () =>
			dispatch(
				request({
					url: URL.GET_DONGLES,
					setLoading: props.setLoading,
					params: {
						...props.params,
						product: undefined,
					},
				})
			).then(data => {
				props.setDongles(data.data);
				props.setTotalRecords(data.recordsFiltered);
			}),
	})),
	lifecycle({
		componentDidMount() {
			this.props.setParams({
				start: 0,
				length: 20,
				...generateParams(this.props.productFamily),
			});
			setTimeout(() => this.props.loadDongles());
		},
	})
)(Dongles);
