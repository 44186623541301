// @flow
import React from 'react';
import classNames from 'classnames';
import { compose, withState } from 'recompose';
import { connect } from 'react-redux';
import URL from 'apis/urls';
import { Button, Icon } from 'antd';
import { request } from 'actions/ajax';
import styles from '../shared/SmarterTable.styl';

export const Download = ({ loading, download }: { loading: boolean, download: Function }) => (
	<Button
		type="primary"
		className={classNames(styles.button, styles.inline)}
		onClick={download}
		loading={loading}
		data-e2e-firmware-download-button
	>
		{!loading && <Icon type="download" />}
	</Button>
);

export default compose(
	withState('loading', 'setLoading', false),
	connect(null, (dispatch: Function, props) => ({
		download: () =>
			dispatch(
				request({
					url: URL.DOWNLOAD_FIRMWARE,
					params: {
						productCode: props.productCode,
						firmwareVersion: props.firmwareVersion,
					},
					setLoading: props.setLoading,
				})
			).then(data => {
				window.location.assign(data.url);
			}),
	}))
)(Download);
