// Some antd components require polyfills to run
window.matchMedia =
	window.matchMedia ||
	function antDPolyfill() {
		return {
			matches: false,
			addListener: function() {},
			removeListener: function() {},
		};
	};

// fetch() polyfill for making API calls.
require('whatwg-fetch');

if (!global.Intl) {
    // line below allows for code splitting
    require.ensure([
        'intl',
        'intl/locale-data/jsonp/en.js'
    ], function (require) {
        // requires like this will just initialize them, and the modules set the globals
        require('intl');
        require('intl/locale-data/jsonp/en.js');
    });
}