import React from 'react';

export default function() {
	return (
		<svg width="26" height="22" viewBox="0 0 26 22">
			<g fill="none" fillRule="evenodd" transform="translate(0 .77)">
				<path
					stroke="#FFF"
					strokeWidth="1.1"
					d="M9.581 2.099L9.5 2.55H3.008A2.453 2.453 0 0 0 .55 5.003v13.213a2.46 2.46 0 0 0 2.458 2.452h19.984a2.453 2.453 0 0 0 2.458-2.452V5.003a2.46 2.46 0 0 0-2.458-2.453h-5.63l-.082-.452A1.889 1.889 0 0 0 15.416.55h-3.971c-.923 0-1.7.655-1.864 1.549z"
				/>
				<ellipse cx="12.306" cy="12.222" stroke="#FFF" strokeWidth="1.1" rx="4.028" ry="4.074" />
				<rect width="4.028" height="1" x="17.75" y="6.704" fill="#FFF" rx=".5" />
			</g>
		</svg>
	);
}
