// @flow
import { get } from 'lodash';

// calculate file size and append unit
export function getFileSizeFormatted(size: number) {
	const i = Math.floor(Math.log(size) / Math.log(1024));

	return `${(size / 1024 ** i).toFixed(0)} ${['B', 'KB', 'MB', 'GB', 'TB'][i]}`;
}

// get file extension
export function getFileExtension(fileName: string) {
	const re = /(?:\.([^.]+))?$/;
	const ext = re.exec(fileName);
	if (ext && ext[1]) {
		return ext[1].toLowerCase();
	}
	throw new Error('The file type is undefined');
}

export function getFileNameWithExpectedExtensions(fileNames: string[], extensions: string[]) {
	for (const ext of extensions) {
		const fileName = fileNames.find(f => getFileExtension(f) === ext);
		if (fileName) {
			return fileName;
		}
	}

	return null;
}

export const renderFolder = (measurement: MeasurementFullData) => {
	if (get(measurement, 'measurement.isDemoFile') === true) {
		return 'Proceq.DataSeriesDemo';
	}
	if (get(measurement, 'folder.type') === 'default') {
		return 'Proceq.DataSeriesAllMeasurements';
	}
	if (get(measurement, 'folder.name')) {
		return get(measurement, 'folder.name');
	}
	return 'Proceq.DataSeriesAllMeasurements';
};

export const renderDir = (measurement: MeasurementIndexData) => {
	if (get(measurement, 'isDemoFile') === true) {
		return 'Proceq.DataSeriesDemo';
	}
	if (get(measurement, 'folder.type') === 'default') {
		return 'Proceq.DataSeriesAllMeasurements';
	}
	if (get(measurement, 'folder.name')) {
		return get(measurement, 'folder.name');
	}
	return 'Proceq.DataSeriesAllMeasurements';
};
