// @flow
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { Popconfirm, message } from 'antd';
import { FormattedMessage, injectIntl } from 'react-intl';
import URL from 'apis/urls';
import { request } from 'actions/ajax';

export const Verify = ({ verifyUser }: { verifyUser: Function }) => (
	<Popconfirm
		title={<FormattedMessage id="App.UsersVerifyPrompt" />}
		onConfirm={verifyUser}
		okText={<FormattedMessage id="Proceq.TextYes" />}
		cancelText={<FormattedMessage id="Proceq.TextNo" />}
	>
		<div style={{ width: '100%', height: '100%' }}>
			<FormattedMessage id="App.UsersVerify" />
		</div>
	</Popconfirm>
);

export default compose(
	injectIntl,
	connect(null, (dispatch: Function, props) => ({
		verifyUser: () => {
			dispatch(
				request({
					url: URL.ACTIVATE_ACCOUNT.replace('{key}', props.id),
					params: {
						product: null,
					},
				})
			).then(() => {
				props.loadUsers();
				message.success(
					props.intl.formatMessage({
						id: 'App.UsersVerifySuccess',
					})
				);
			});
		},
	}))
)(Verify);
