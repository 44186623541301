// @flow
import { injectIntl } from 'react-intl';
import type { InjectIntlProvidedProps } from 'react-intl';
import handleFormatMessage from 'utils/handleFormatMessage';

export const MoveFile = ({ log, intl }: GPRLogbookEntryProps & InjectIntlProvidedProps) =>
	handleFormatMessage('Proceq.LogbookPropertyMovedSeriesFromTo', intl.formatMessage, [
		log.content.source,
		log.content.destination,
	]);

export default injectIntl(MoveFile);
