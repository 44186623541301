// @flow
import React from 'react';
import { Table } from 'antd';
import { FormattedMessage } from 'react-intl';
import { get } from 'lodash';
import ConvertedUnits from 'components/shared/ConvertedUnits';
import type { DerivedProps } from 'components/measurements/HTMLExport/PI8000';
import styles from 'components/measurements/HTMLExport/HTMLExport.styl';
import type { MeasurementObjectWithInfo } from '../GPR/Objects';

const buildMarkerPositionCalculator = data => {
	const velocity = get(data, 'settings.0.content.processing.velocity');
	const samplingRate = get(data, 'settings.0.content.processing.samplingRate');
	const piles = get(data, 'measurement.content.piles');

	const positionDepths = {};
	piles.forEach(pile => {
		pile.positions.forEach(position => {
			positionDepths[position.id] = position.depth;
		});
	});

	return (location: number, positionId: string) => {
		if (!samplingRate || !velocity) {
			return '';
		}

		const sampleTop = positionDepths[positionId].top;
		const positionInSamples = location - sampleTop;
		return ((positionInSamples / samplingRate) * velocity) / 2;
	};
};

export const title = <FormattedMessage id="App.HTML.GPR.Markers" />;

export const Markers = ({
	data,
	scanType,
	isMetric,
	convert,
	positionID,
}: { data: MeasurementFullData, positionID: string } & DerivedProps) => {
	const calculateMarkerPosition = buildMarkerPositionCalculator(data);
	let markers = data.objects;
	if (positionID !== undefined) {
		markers = markers.filter(object => object.content.positionID === positionID);
	}

	markers = markers
		.filter(object => object.type === 'piTextMarker')
		.map(marker => {
			return {
				...marker,
				markerPosition: calculateMarkerPosition(marker.content.location, marker.content.positionID),
			};
		})
		.sort((a, b) => {
			return a.content.number - b.content.number;
		});

	return (
		<div className="table-markers">
			<Table
				title={() => <span className={styles.main_header}>{title}</span>}
				className={styles.table}
				columns={[
					{
						title: 'App.HTML.PI8000.Markers.MarkerName',
						render: (text: string, record: MeasurementObjectWithInfo) => `M${record.content.number}`,
					},
					{
						title: 'App.HTML.PI8000.Markers.Position',
						dataIndex: 'markerPosition',
						unitId: `PIT_IE.CSV.Marker distance X`,
					},
					{
						title: 'App.HTML.PI8000.Markers.Comments',
						dataIndex: 'content.content',
						render: (text: string, record: MeasurementObjectWithInfo) => {
							return record.content.text ? record.content.text : '-';
						},
					},
				].map(columnConfig => ({
					// eslint-disable-next-line no-unused-vars
					render: (text: string, record: MeasurementObjectWithInfo) =>
						columnConfig.unitId ? convert(text, columnConfig.unitId) : text,
					...columnConfig,
					title: columnConfig.unitId ? (
						<ConvertedUnits
							id={columnConfig.title}
							unitId={columnConfig.unitId}
							scanType={scanType}
							isMetric={isMetric}
						/>
					) : (
						<FormattedMessage id={columnConfig.title} />
					),
					key: columnConfig.title,
				}))}
				dataSource={markers}
				pagination={false}
				size="small"
				rowKey="id"
			/>
		</div>
	);
};

export default Markers;
