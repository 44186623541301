// @flow
import React, { Fragment } from 'react';
import { get } from 'lodash';
import { Table } from 'antd';
import { FormattedMessage } from 'react-intl';
import { renderFolder } from 'utils/fileUtils';
import styles from '../../../HTMLExport.styl';

export const title = (
	<Fragment>
		<FormattedMessage id="Proceq.ExportFieldSettings" />
		{' ­­» '}
		<FormattedMessage id="App.HTML.GPR.Settings.MeasurementPresets" />
	</Fragment>
);

export const File = ({ data, showTitle }: { data: MeasurementFullData, showTitle: boolean }) => {
	const dataSource = [
		{
			label: 'App.HTML.GPR.MeasurementPresets.FileName',
			value: get(data, 'measurement.name'),
		},
		{
			label: 'App.HTML.GPR.MeasurementPresets.FileID',
			value: get(data, 'measurement.id'),
		},
		{
			label: 'Proceq.StatisticsFolder',
			value: <FormattedMessage id={renderFolder(data)} />,
		},
	].map(row => ({
		key: row.label,
		label: <FormattedMessage id={row.label} />,
		value: row.value,
	}));

	return (
		<Table
			title={showTitle ? () => <span className={styles.sub_header}>{title}</span> : null}
			className={styles.table}
			dataSource={dataSource}
			pagination={false}
			showHeader={false}
			size="small"
		>
			<Table.Column dataIndex="label" className={styles.column} />
			<Table.Column dataIndex="value" />
		</Table>
	);
};

export default File;
