// @flow
import React from 'react';
import { connect } from 'react-redux';
import { compose, withState, lifecycle } from 'recompose';
import URL from 'apis/urls';
import type { InjectIntlProvidedProps } from 'react-intl';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Row, Col, Spin } from 'antd';
import { request } from 'actions/ajax';
import Equotip from './Equotip';
import Schmidt from './Schmidt';
import layoutStyles from '../layout/Layout.styl';
import storageStyles from './Storage.styl';

export const DashboardOld = ({
	loading,
	product,
	data,
	intl: { formatMessage },
}: {
	loading: boolean,
	params: Object,
	product: ProductCode,
	data: {
		common: CommonAnalytics,
		storage: StorageAnalytics,
		measurements: EquotipMeasurementAnalytics | SchmidtMeasurementAnalytics,
	},
	loadData: Function,
	isAdmin: boolean,
} & InjectIntlProvidedProps) =>
	loading || !data ? (
		<Spin style={{ marginTop: '50px', width: '100%' }} tip={formatMessage({ id: 'Proceq.JQGridTableLoadText' })} />
	) : (
		<Row>
			<Col xs={24} className={layoutStyles.container}>
				<h2 className="label-statistics">
					<FormattedMessage id="Proceq.StatisticsTitle" />
				</h2>
				{data.measurements.impactsWithTime[0] && (
					<div>
						{data.measurements.impactsWithTime[0].key}
						{' - '}
						{data.measurements.impactsWithTime[data.measurements.impactsWithTime.length - 1].key}
					</div>
				)}
				<Col xs={24} className={storageStyles.stat_container} style={{ marginTop: 40 }}>
					<Col xs={12} md={6}>
						<FormattedMessage id="Proceq.StatisticsTotalImpacts" />
						<span className={storageStyles.number}>{data.measurements.nImpacts}</span>
					</Col>
					<Col xs={12} md={6}>
						<FormattedMessage id="Proceq.StatisticsTotalSeries" />
						<span className={storageStyles.number}>{data.measurements.nMeasurements}</span>
					</Col>
					<Col xs={12} md={6}>
						<FormattedMessage id="Proceq.StatisticsTotalExcluded" />
						<span className={storageStyles.number}>
							{data.measurements.nExcluded}
							{'%'}
						</span>
					</Col>
					<Col xs={12} md={6}>
						<FormattedMessage id="Proceq.StatisticsValuesOutOfLimits" />
						<span className={storageStyles.number}>
							{data.measurements.nOutOfLimit}
							{'%'}
						</span>
					</Col>
				</Col>
			</Col>
			<Col xs={24}>
				{product === 'EQUOTIP' && 'nImpacts' in data.measurements && <Equotip isAdmin={false} {...data} />}
				{product === 'SCHMIDT' && 'nImpacts' in data.measurements && <Schmidt isAdmin={false} {...data} />}
			</Col>
		</Row>
	);

export default compose(
	withState('loading', 'setLoading', true),
	withState('data', 'setData', null),
	connect(
		state => ({
			product: state.session.productType,
		}),
		(dispatch: Function, props) => ({
			loadData: () => {
				dispatch(
					request({
						method: 'POST',
						url: URL.DASHBOARD,
						setLoading: props.setLoading,
						params: {
							metrics: ['common', 'measurements', 'storage'],
						},
					})
				).then(data => {
					props.setData(data);
				});
			},
		})
	),
	lifecycle({
		componentDidMount() {
			this.props.loadData();
		},
		componentWillReceiveProps(nextProps) {
			if (this.props.product !== nextProps.product) {
				this.props.loadData();
			}
		},
	}),
	injectIntl
)(DashboardOld);
