import en_GB from 'antd/lib/locale-provider/en_GB';

import actionTypes from 'actions/actionTypes';
import { getLastLanguage, saveLastLanguage } from 'utils/sessionHelper';
import { getSetup } from 'utils/localeHelper';
import en from '../i18n/en';

export const defaultState = {
	...en,
	antdLocaleProvider: en_GB,
};

export function locale(state = defaultState, action = {}) {
	switch (action.type) {
		case actionTypes.INIT_LANGUAGE:
			action.payload = getLastLanguage(); // eslint-disable line no-fallthrough

		case actionTypes.CHANGE_LANGUAGE: {
			const setup = getSetup(action.payload);
			saveLastLanguage(setup.shortlang);
			return setup;
		}
		default:
			return state;
	}
}
