/* eslint-disable */
import React, { Component } from 'react';
import { Menu, Icon, Select } from 'antd';
/* import PropTypes from 'prop-types'; */
import { connect } from 'react-redux';
import URL from 'apis/urls';
import SmartTable from '../../shared/SmartTable';
import styles from '../../dashboard/Settings/UserProfile.styl';
import { getProceqUrl } from 'utils/sessionHelper';

export class HistoryLogsProceq extends Component {
	static propTypes = {}
	_ismounted = false;
	constructor(props) {
		super(props);
		this.state = {
			hideAll: false,
			selectedTab: 'general',
			exportCSVInfo: {
				apiEndpoint: getProceqUrl(this.props.session, URL.PROCEQ_EDIT_BUS_INFO_CSV),
				apiTypeMethod: 'post',
				apiSendToken: true
			}
		};
		this.handleChange = this.handleChange.bind(this);
	}
	handleChange(value) {
		this.setState({
			hideAll:true
		}, () => {
			setTimeout(() => {
				this.setState({
					hideAll: false,
					selectedTab: value
				});
			}, 10);
		});
	}
	componentDidMount() {
		this._ismounted = true;
	}
	componentWillUnmount() {
		this._ismounted = false;
	}
	componentWillReceiveProps(nextProps){
		this.setState({
			hideAll:true
		}, () => {
			setTimeout(() => {
				if (this._ismounted) {
					this.setState({
						hideAll:false,
					});
				}
			}, 10);
		});
	}
	render() {
		const urlList = getProceqUrl(this.props.session, URL.PROCEQ_GET_HISTORY_LOGS).replace("{type}", this.state.selectedTab);
		const urlCSV = getProceqUrl(this.props.session, URL.PROCEQ_GET_HISTORY_CSV).replace("{type}", this.state.selectedTab);
		const exportCSVInfo = {
			apiEndpoint: urlCSV,
			apiTypeMethod: 'post',
			apiSendToken: true
		};
		const allTablesConfig = {
			common: {
				columns: [
					{ title: 'Log Entry ID', dataIndex: '_id', typeField: 'hidden' },
					{ title: 'Created On', dataIndex: 'createdTs', typeField: 'datetime', sorter: true },
					{ title: 'User ID', dataIndex: 'userId', sorter: true, filter: true },
					{ title: 'Email', dataIndex: 'userName', sorter: true, filter: true },
					{ title: 'Group ID', dataIndex: 'groupId', sorter: true, filter: true },
					{ title: 'Action', dataIndex: 'typeAction', sorter: true, filter: true }
				]
			},
			general: {
				columns: [
					{ title: 'Info', dataIndex: 'info', sorter: true, filter: true }
				]
			},
			contracts: {
				columns: [
					{ title: 'Contract ID', dataIndex: 'contractId', sorter: true, filter: true },
					{ title: 'Serial Number', dataIndex: 'serialNumber', sorter: true, filter: true },
					{ title: 'Hardware ID', dataIndex: 'hardwareId', sorter: true, filter: true },
					{ title: 'Owner', dataIndex: 'ownerId', sorter: true, filter: true },
					{ title: 'Expires On', dataIndex: 'expirationTs', typeField: 'datetime', sorter: true, renderIfEmpty: '' },
					{ title: 'Product', dataIndex: 'productType', sorter: true, filter: true },
					{ title: 'Options', dataIndex: 'contractOptions', sorter: true, filter: true },
				]
			},
			accounts: {
				columns: [
					{ title: 'Account Type', dataIndex: 'accountType', sorter: true, filter: true },
					{ title: 'Created Username', dataIndex: 'createdUsername', sorter: true, filter: true },
					{ title: 'Created UserID', dataIndex: 'createdUserid', sorter: true, filter: true }
				]
			},
			dataseries: {
				columns: [
					{ title: 'Data Series Type', dataIndex: 'dataSeriesType', sorter: true, filter: true },
					{ title: 'Data Series ID', dataIndex: 'dataSeriesId', sorter: true, filter: true },
					{ title: 'Data Series Name', dataIndex: 'dataSeriesName', sorter: true, filter: true },
					{ title: 'Ver Nr', dataIndex: 'dataSeriesVerNr', sorter: true, filter: true }
				]
			},
		};
		// const columns = allColumns[this.state.currentFixedFilters[0]];
		const columns = [
			...allTablesConfig.common.columns,
			...allTablesConfig[this.state.selectedTab].columns
		];
		if ( this.state.hideAll ) {
			return (<div> </div>);
		}
		else {
			return (
				<div className={ styles.profile }>
					<div className={ styles.profile_container }>
						<Select defaultValue={ this.state.selectedTab } style={{width: 250}} onChange={ this.handleChange }>
							<Select.Option value="general">General</Select.Option>
							<Select.Option value="contracts">Contracts</Select.Option>
							<Select.Option value="accounts">Account creation (no sign-up)</Select.Option>
							<Select.Option value="dataseries">Data series</Select.Option>
						</Select>
						<SmartTable
							apiEndpoint={ urlList }
							apiTypeMethod={ 'post' }
							apiSendToken={ true }
							columns={ columns }
							columnKey={ '_id' }
							onRef={ ref => (this.tabledata = ref) }
							typeSortingFiltering={ 'proceq' }
							typeRetrieveData={ 'proceq' }
							exportCSVInfo={ exportCSVInfo }
							fetchAllDataOnStart={ false }
						/>
					</div>
				</div>
			);
		}
	}
}

const mapStateToProps = state => ({
	session: state.session,
	locale: state.locale,
	globalregister: state.globalregister
});

const mapDispatchToProps = dispatch => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(HistoryLogsProceq);
