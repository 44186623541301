module.exports = {
	shortlang: 'es',
	lang: 'es',
	messages: {
		'Proceq.ErrorWrongUsername': 'Se ha proporcionado un correo electrónico inválido',
		'Proceq.ErrorCreationNotAllowed': 'Se ha deshabilitado la creación de nuevas cuentas',
		'Proceq.ErrorWrongPassword': 'Se ha proporcionado una contraseña inválida',
		'Proceq.ErrorWrongFirstName': 'Se ha proporcionado un nombre inválido',
		'Proceq.ErrorWrongLastName': 'Se ha proporcionado un apellido inválido',
		'Proceq.ErrorUsernameAlreadyExists': 'Ya existe una cuenta con este correo electrónico',
		'Proceq.ErrorDB': 'Error al ejecutar una operación de base de datos',
		'Proceq.InvalidUsername': 'No se ha podido encontrar la cuenta de usuario',
		'Proceq.AlreadyMultipleForgot': 'Ya se han solicitado múltiples contraseñas de restablecimiento',
		'Proceq.InvalidNewPassword': 'Se ha proporcionado una nueva contraseña inválida',
		'Proceq.InvalidResetCode': 'Código de restablecimiento de contraseña inválido o expirado',
		'Proceq.InvalidOldPassword': 'Se ha proporcionado una contraseña antigua inválida',
		'Proceq.InvalidAccessRights': 'La sesión ha expirado o el usuario no tiene el derecho de ejecutar esta acción',
		'Proceq.ErrorNoAccountType': 'No se proporcionado ningún tipo de cuenta',
		'Proceq.ErrorNoUsername': 'No se ha proporcionado ningún correo electrónico',
		'Proceq.InvalidCountry': 'Se ha proporcionado un país inválido',
		'Proceq.ErrorCreatingUser': 'Error al crear el nuevo usuario',
		'Proceq.InvalidID': 'Se ha proporcionado una ID inválida',
		'Proceq.InvalidTransactionID': 'ID de transacción inválida',
		'Proceq.InvalidFileContent': 'Error al leer el contenido del archivo',
		'Proceq.InvalidTypeEntry': 'Tipo de entrada inválido',
		'Proceq.InvalidContractID': 'El contrato es inválido o ha expirado',
		'Proceq.InvalidExistingID': 'No se puede crear la ID porque ya existe',
		'Proceq.InvalidDataSeriesType': 'Tipo de serie inválido',
		'Proceq.InvalidMetaDataContent': 'Contenido de metadatos inválido',
		'Proceq.ErrorFileIsLocked': 'Uno o varios archivos están bloqueados. Intentarlo de nuevo más tarde',
		'Proceq.MissingAttachmentFiles': 'No se pueden encontrar los archivos adjuntos en el servidor',
		'Proceq.InvalidFlagType': 'Falta el tipo de indicador o el mismo es inválido',
		'Proceq.MissingDateTypeFields': 'Faltan campos requeridos para la serie',
		'Proceq.InvalidFolderID': 'ID de carpeta inválida',
		'Proceq.InvalidDataSeriesName': 'Nombre de serie inválido',
		'Proceq.ErrorDateFormat': 'Se ha proporcionado un formato de datos inválido',
		'Proceq.ErrorDataFormat': 'No se pueden analizar los datos de la serie',
		'Proceq.InvalidDownloadID': 'Autentificador de descarga inválido, o el mismo ya ha expirado',
		'Proceq.InvalidVersionNr': 'Se ha proporcionado un número de versión inválido',
		'Proceq.ErrorDataMissing': 'No se pueden recuperar los datos del sistema de almacenamiento',
		'Proceq.ErrorSavingFile': 'El servidor no ha podido guardar el archivo',
		'Proceq.ErrorInsertDB': 'Error durante la inserción en la base de datos',
		'Proceq.InvalidFolderName': 'Se ha proporcionado un nombre de carpeta inválido',
		'Proceq.InvalidActionID': 'Enlace URL inválido o expirado',
		'Proceq.AlreadyConfirmed': 'Ya se ha verificado la cuenta',
		'Proceq.InvalidActionType': 'No se puede procesar la acción solicitada',
		'Proceq.ErrorGeneratingChart': 'Error al generar las tablas',
		'Proceq.InvalidSerialID': 'ID serial inválida',
		'Proceq.InvalidHardwareID': 'ID de hardware inválida',
		'Proceq.InvalidEntityID': 'ID de objeto inválida, o la misma ha sido eliminada',
		'Proceq.InvalidEntityType': 'Tipo de objeto inválido',
		'Proceq.InvalidEntityVerNr': 'Número de versión inválido',
		'Proceq.ErrorTransactionLocked': 'El objeto está bloqueado y no se puede modificar',
		'Proceq.InvalidDateValue': 'Los datos no se pueden analizar',
		'Proceq.MissingFields': 'Faltan uno o varios de los campos requeridos',
		'Proceq.ErrorColumnValue': 'Se ha proporcionado un valor incorrecto para uno o varios campos',
		'Proceq.MissingUidDBValue': 'Falta ID de entrada',
		'Proceq.ErrorFileID': 'Se ha proporcionado una ID de archivo inválida',
		'Proceq.ErrorInsertDownload': 'Error al crear la descarga',
		'Proceq.InvalidAppVersion': 'Ya no se soporta la versión de su apliación móvil. Favor de actualizarla',
		'Proceq.MissingUserInfo': 'Falta información del usuario',
		'Proceq.InvalidAccessToken': 'Autentificador de sesión inválido o expirado',
		'Proceq.ErrorGeneratingCSV': 'Error al crear exportación CSV',
		'Proceq.ErrorGeneratingExport': 'Error al crear exportación',
		'Proceq.MissingParams': 'Faltan uno o varios de los parámetros requeridos',
		'Proceq.SerialNotFound': 'No se ha encontrado ningún contrato para este dispositivo',
		'Proceq.StandardEnumJapan': 'Japón',
		'Proceq.StandardEnumDefault': 'Predeterminado',
		'Proceq.MaterialEnumIndependent': 'Independiente del material',
		'Proceq.MaterialEnumSteelAndCastSteel': 'Acero y fundición de acero',
		'Proceq.MaterialEnumWorkToolSteel': 'Acero de herramientas',
		'Proceq.MaterialEnumStainlessSteel': 'Acero inoxidable',
		'Proceq.MaterialEnumGrayCastIron': 'Fundición de hierro gris (GG)',
		'Proceq.MaterialEnumNodularCastIron': 'Fundición de hierro nodular (GGG)',
		'Proceq.MaterialEnumCastAlluminiumAlloys': 'Aleaciones de aluminio de fundición',
		'Proceq.MaterialEnumBrassCopperZincAlloys': 'Latón - Aleaciones de cobre/zinc',
		'Proceq.MaterialEnumBronze': 'Bronce',
		'Proceq.MaterialEnumWroughtCopperAlloys': 'Aleaciones de forja de cobre',
		'Proceq.MaterialEnumTitanium': 'Titanio',
		'Proceq.ScaleEnumCustom': 'Personalizado',
		'Proceq.ScaleEnumPenetrationDepth': 'Profundidad de penetración',
		'Proceq.CompanySizeMicro': '1 a 9 empleados',
		'Proceq.CompanySizeSmall': '10 a 49 empleados',
		'Proceq.CompanySizeMedium': '50 a 249 empleados',
		'Proceq.CompanySizeLarge': '>249 empleados',
		'Proceq.SalutationMr': 'Sr.',
		'Proceq.SalutationMs': 'Sra.',
		'Proceq.SalutationDr': 'Dr.',
		'Proceq.SalutationProf': 'Prof.',
		'Proceq.CategoryAerospace': 'Industria aeroespacial',
		'Proceq.CategoryAutomotive': 'Industria automovilística',
		'Proceq.CategoryChemicalProcessing': 'Procesos químicos',
		'Proceq.CategoryConstructionBuilding': 'Construcción/obras',
		'Proceq.CategoryConsultantTestLabsEngineer': 'Consultor / Laboratorios de ensayos / Ingeniero',
		'Proceq.CategoryEducationInstituteRD': 'Educación / Instituto / Investigación y desarrollo',
		'Proceq.CategoryFilmProducerConverter': 'Productor/trasnformador de films',
		'Proceq.CategoryGeologyRocktesters': 'Geología / Examinador de rocas',
		'Proceq.CategoryMachineryToolingEquipment': 'Maquinaria / Herramientas / Equipos',
		'Proceq.CategoryMassTransportation': 'Transporte público',
		'Proceq.CategoryMetalWorks': 'Trabajo de metales',
		'Proceq.CategoryOilAndGas': 'Aceite y gas',
		'Proceq.CategoryOthers': 'Otros',
		'Proceq.CategoryPaperProducerConverter': 'Fabricante/transformador de papel',
		'Proceq.CategorySalesChannel': 'Canal de ventas',
		'Proceq.ErrorWrongLoginCredentials': 'El nombre de usuario o la contraseña que han entrado son incorrectos',
		'Proceq.ErrorEmailNotVerified': 'Todavía no se ha verificado el correo electrónico',
		'Proceq.ServerError': 'Error de servidor',
		'Proceq.TooManyActionEmailRequests':
			'Demasiadas solicitudes. Favor de esperar que los correos electrónicos lleguen al buzón',
		'Proceq.ErrorServerUnderMaintenance': 'Se está efectuando el mantenimiento del servidor',
		'Proceq.TableDataSeriesName': 'Nombre',
		'Proceq.TableCreatedOn': 'Creado en',
		'Proceq.TableModifiedOn': 'Modificado en',
		'Proceq.TableCreatedBy': 'Creado por',
		'Proceq.TableNumMean': 'Media',
		'Proceq.TableNumTotal': 'Total',
		'Proceq.TableNumStandardDeviation': 'Disp. est.',
		'Proceq.TableImpactDevice': 'Sonda',
		'Proceq.TableImpactDeviceHammer': 'Martillo',
		'Proceq.TableMaterial': 'Material',
		'Proceq.TableScaleUnit': 'Unidad de escala',
		'Proceq.TableActionRefresh': 'Actualizar',
		'Proceq.TableActionExpand': 'Expandir',
		'Proceq.TableActionCollapse': 'Colapsar',
		'Proceq.TableActionExportAsPDF': 'Exportar como PDF',
		'Proceq.TableActionExportAsCSV': 'Exportar como CSV',
		'Proceq.TableActionExportAsJSON': 'Exportar como JSON',
		'Proceq.SearchOperationEquals': 'Es igual',
		'Proceq.SearchOperationContains': 'Contiene',
		'Proceq.SearchOperationDifferent': 'Diferente',
		'Proceq.SearchOperationGreaterOrEqual': 'Más grande o igual',
		'Proceq.SearchOperationGreater': 'Más grande',
		'Proceq.SearchOperationLesserOrEqual': 'Más pequeño o igual',
		'Proceq.SearchOperationLesser': 'Más pequeño',
		'Proceq.ExportFieldExportDate': 'Fecha de exportación',
		'Proceq.ExportFieldLimits': 'Límites',
		'Proceq.ExportFieldHigh': 'Alto',
		'Proceq.ExportFieldLow': 'Bajo',
		'Proceq.ExportFieldOff': 'Apagado',
		'Proceq.ExportFieldLowerLimit': 'Límite inferior',
		'Proceq.ExportFieldUpperLimit': 'Límite superior',
		'Proceq.ExportFieldReadings': 'Lecturas',
		'Proceq.ExportFieldStandardDeviation': 'Desviación estándar',
		'Proceq.ExportFieldRange': 'Rango',
		'Proceq.ExportFieldRelativeSpan': 'Extensión relativa',
		'Proceq.ExportFieldMaximum': 'Máximo',
		'Proceq.ExportFieldMinimum': 'Mínimo',
		'Proceq.ExportFieldAverage': 'Promedio',
		'Proceq.ExportFieldUnit': 'Unidad',
		'Proceq.ExportFieldName': 'Nombre',
		'Proceq.ExportFieldMeasurementDate': 'Fecha de medición',
		'Proceq.ExportFieldLocationLatitude': 'Latitud de la ubicación',
		'Proceq.ExportFieldLocationLongitude': 'Longitud de la ubicación',
		'Proceq.ExportFieldProbeType': 'Tipo de sonda',
		'Proceq.ExportFieldProbeSerialNumber': 'Número de serie de la sonda',
		'Proceq.ExportFieldProbeBTSerialNumber': 'Número de serie de Bluetooth de la sonda',
		'Proceq.ExportFieldProbeLastVerificationDate': 'Fecha de la última verificación de la sonda',
		'Proceq.ExportFieldPrimaryScale': 'Escala primaria',
		'Proceq.ExportFieldSecondaryScale': 'Escala secundaria',
		'Proceq.ExportFieldMaterial': 'Material',
		'Proceq.ExportFieldStandard': 'Norma',
		'Proceq.ExportFieldNoConversion': 'Sin conversión',
		'Proceq.ExportFieldMeasurements': 'Mediciones',
		'Proceq.ExportFieldMore': 'Más',
		'Proceq.ExportFieldLess': 'Menos',
		'Proceq.ExportFieldStatistics': 'Estadística',
		'Proceq.ExportFieldSettings': 'Ajustes',
		'Proceq.DataSeriesLogbook': 'Cuaderno diario',
		'Proceq.DataSeriesAllMeasurements': 'Todas las mediciones',
		'Proceq.DataSeriesFlagged': 'Con indicador',
		'Proceq.DataSeriesTrash': 'Papelera',
		'Proceq.BrowserNoSupportAudio': 'Su explorador no soporta archivos de audio.',
		'Proceq.EmailPasswordRecoverySubject': 'Solicitud de cambio de contraseña para **?0**',
		'Proceq.EmailPasswordRecoveryBody':
			'Estimado **?0** **?1**<br/><br/>Ha solicitado una nueva contraseña. Para restablecer su contraseña por favor haga clic en el enlace a continuación.<br/><br/><a href="**?2**">Restablecer contraseña</a><br/><br/>Si no ha solicitado el restablecimiento de su contraseña, por favor ignore este mensaje. Va a expirar dentro de 12 horas.<br/><br/>Su Equipo Proceq<br/><br/>**?3**',
		'Proceq.EmailRegistrationSubject': 'Finalización del registro para **?0**',
		'Proceq.EmailRegistrationBody':
			'Estimado **?0** **?1**<br/><br/>Muchas gracias por registrarse en **?5**. Por favor, termine su registro haciendo clic en el enlace a continuación:<br/><br/><a href="**?2**">**?2**</a><br/><br/>Este correo electrónico fue generado automáticamente ya que ha rellenado el formulario de registro. Si no ha realizado este registro, por favor ignore este mensaje.<br/><br/> Al terminar el registro, usted acepta nuestra  <a href="**?3**">política de privacidad</a>.<br/><br/>Su Equipo Proceq<br/><br/>**?4**',
		'Proceq.LogbookActionNewMeasurement': 'Nueva medición',
		'Proceq.LogbookPropertyCreatedSeriesInFolder': 'Serie creada "**?0**" en carpeta "**?1**"',
		'Proceq.LogbookPropertyRenamedSeriesFromTo': 'Se ha cambiado el nombre de la serie de "**?0**" a "**?1**"',
		'Proceq.LogbookPropertyMovedSeriesFromToTrash':
			'Se ha arrastrado la serie de la carpeta "**?0**" a la papelera',
		'Proceq.LogbookPropertyMovedSeriesFromTrashTo':
			'Se ha arrastrado la serie de la papelera a la carpeta "**?0**"',
		'Proceq.LogbookPropertyMovedSeriesFromTo':
			'Se ha arrastrado la serie de la carpeta "**?0**" a la carpeta "**?1**"',
		'Proceq.LogbookPropertySecondaryScaleChangedFromTo': 'La escala secundaria ha cambiado de **?0** a **?1**',
		'Proceq.LogbookPropertyMaterialChangedFromTo': 'El material ha cambiado de **?0** a **?1**',
		'Proceq.LogbookPropertyMaxLimitChangedFromTo': 'El límite superior ha cambiado de **?0** a **?1**',
		'Proceq.LogbookPropertyMinLimitChangedFromTo': 'El límite inferior ha cambiado de **?0** a **?1**',
		'Proceq.LogbookPropertyStandardChangedFromTo': 'La norma ha cambiado de **?0** a **?1**',
		'Proceq.LogbookPropertyTextCommentAdded': 'Se ha agregado un comentario de texto',
		'Proceq.LogbookPropertyTextCommentDeletedOn': 'Eliminado en **?0**',
		'Proceq.LogbookPropertyMeasurementAdded': '**?0** agregado',
		'Proceq.LogbookPropertyMeasurementExcluded': '**?0** excluido',
		'Proceq.LogbookPropertyMeasurementIncluded': '**?0** incluido',
		'Proceq.LogbookPropertyMeasurementDeleted': '**?0** eliminado',
		'Proceq.LogbookPropertyAttachmentDeletedOn': 'Eliminado en **?0**',
		'Proceq.LogbookPropertyPhotoAdded': 'Foto agregada',
		'Proceq.LogbookPropertyAudioAdded': 'Comentario audio agregado',
		'Proceq.LogbookPropertySeriesFlagged': 'Serie con indicador',
		'Proceq.LogbookPropertySeriesUnflagged': 'Serie sin indicador',
		'Proceq.LogbookPropertySerialNumberShort': 'N° S',
		'Proceq.LogbookPropertyBluetoothModuleShort': 'BTM S/N',
		'Proceq.LogbookPropertyVerified': 'Verificado',
		'Proceq.LogbookPropertyContractValidUntil': 'Contrato válido hasta',
		'Proceq.LogbookPropertyOperator': 'Operador',
		'Proceq.LogbookTitle': 'Cuaderno diario',
		'Proceq.LogbookDownload': 'Descarga',
		'Proceq.LogbookLoadingData': 'Cargando datos....',
		'Proceq.LogbookClose': 'Cerrar',
		'Proceq.FormCompany': 'Compañía',
		'Proceq.FormScreeningEagleID': 'Screening Eagle ID (Email)',
		'Proceq.FormEmailGroupID': 'Correo electrónico',
		'Proceq.FormCustomerEmail': 'Correo electrónico del cliente',
		'Proceq.FormProbeSN': 'N° S de la sonda',
		'Proceq.FormBluetoothSN': 'N° S',
		'Proceq.FormBluetoothHWID': 'ID del HW',
		'Proceq.FormExpirationDate': 'Fecha de expiración',
		'Proceq.TitleRegisterProbeSNs': 'Registrar contrato de renta',
		'Proceq.ButtonSubmit': 'Enviar',
		'Proceq.ButtonReset': 'Restablecer',
		'Proceq.ButtonResetPassword': 'Restablecer contraseña',
		'Proceq.MessageSuccesfullyInserted': 'Insertado con éxito',
		'Proceq.MessageSuccesfullyEmailConfirmed': 'Se ha confirmado su correo electrónico.',
		'Proceq.MessagePasswordSuccesfullyChanged': 'Contraseña cambiada con éxito',
		'Proceq.MessageSuccesfullyUpdated': 'Actualización exitosa',
		'Proceq.MessageAccountCreated':
			'Le hemos enviado un correo electrónico de verificación de registro. Por favor, haga clic en el enlace en el correo electrónico para terminar el proceso de registro.',
		'Proceq.MessageEmailForgotPassword': 'Le hemos enviado un correo electrónico con más instrucciones',
		'Proceq.MessageEmailResendConfirm': 'Le hemos enviado un correo electrónico con más instrucciones',
		'Proceq.MessageLoggingIn': 'Iniciando la sesión...',
		'Proceq.LoginNeedAccount': '¿Necesita una cuenta?',
		'Proceq.LoginCreateAccount': 'Crear cuenta',
		'Proceq.TitleForgotPassword': 'Se me ha olvidado la contraseña',
		'Proceq.TitleResendConfirmation': 'Volver a enviar correo electrónico de confirmación',
		'Proceq.FormGroup': 'Grupo',
		'Proceq.FormFirstName': 'Nombre',
		'Proceq.FormLastName': 'Apellido',
		'Proceq.FormEmail': 'Dirección de correo electrónico',
		'Proceq.FormCity': 'Ciudad',
		'Proceq.FormPassword': 'Contraseña',
		'Proceq.FormPasswordNew': 'Nueva contraseña',
		'Proceq.FormPasswordOld': 'Contraseña antigua',
		'Proceq.FormConfirmPassword': 'Confirmar contraseña',
		'Proceq.FormConfirmPasswordNew': 'Confirmar nueva contraseña',
		'Proceq.ButtonRegister': 'Registrar',
		'Proceq.ButtonSignIn': 'Iniciar sesión',
		'Proceq.TitleSignIn': 'Iniciar sesión',
		'Proceq.LoginAlreadyRegistered': '¿Ya está registrado?',
		'Proceq.TextOrSignInUsing': 'O iniciar sesión usando',
		'Proceq.NavSearchSerials': 'Búsqueda de contratos',
		'Proceq.NavRegisterProbeSNs': 'Regitsrar contrato',
		'Proceq.NavCreateStaffAccounts': 'Crear cuentas de personal',
		'Proceq.NavHistoryLog': 'Registro del historial',
		'Proceq.NavMeasurementsData': 'Datos de medición',
		'Proceq.NavChangePassword': 'Cambio de contraseña',
		'Proceq.NavLogout': 'Cierre de sesión',
		'Proceq.NavUpdateProfile': 'Actualizar perfil',
		'Proceq.TitleUpdateProfile': 'Actualizar perfil',
		'Proceq.NavCreateCustomersAccounts': 'Crear cuenta de cliente',
		'Proceq.ButtonSearch': 'Buscar',
		'Proceq.ButtonUpdate': 'Actualizar',
		'Proceq.ButtonCreate': 'Crear',
		'Proceq.ButtonChange': 'Cambiar',
		'Proceq.FormSearchBy': 'Buscar por',
		'Proceq.FormValue': 'Valor',
		'Proceq.FormAccountType': 'Tipo de cuenta',
		'Proceq.FormAccountTypeAdmin': 'Administrador',
		'Proceq.FormAccountTypeBU': 'Unidad de negocios',
		'Proceq.FormCompanyName': 'Nombre de compañía',
		'Proceq.FormAddress': 'Dirección',
		'Proceq.FormZipCode': 'Código postal',
		'Proceq.FormCountry': 'País',
		'Proceq.FormPhoneNumber': 'Teléfono',
		'Proceq.FormCompanyWebsite': 'Página web de la compañía',
		'Proceq.FormJobTitle': 'Título de trabajo',
		'Proceq.FormIndustry': 'Industria',
		'Proceq.FormSalutation': 'Saludo',
		'Proceq.FormCompanySize': 'Tamaño de la compañía',
		'Proceq.FormLanguage': 'Idioma',
		'Proceq.FormContactByProceq': 'Contacto por Proceq',
		'Proceq.LanguageEnglish': 'Inglés',
		'Proceq.LanguageJapanese': 'Japonés',
		'Proceq.LanguageChinese': 'Chino',
		'Proceq.LanguageGerman': 'Alemán',
		'Proceq.TextYes': 'Sí',
		'Proceq.TextNo': 'No',
		'Proceq.FormNewsletter': 'Boletín',
		'Proceq.TitleSearchSerials': 'Búsqueda de contratos',
		'Proceq.TitleChangePassword': 'Cambio de contraseña',
		'Proceq.TabLoginCustomers': 'Clientes',
		'Proceq.TabLoginSingleUser': 'Usuario singular',
		'Proceq.TabLoginGroup': 'Grupo',
		'Proceq.TextRemainingXDays': 'Todavía sobran **?0** día(s)',
		'Proceq.TextInfoShort': 'Info',
		'Proceq.TextIAgreeTerms': 'Acepto los <a>Términos y condiciones</a>',
		'Proceq.ErrorDisplayTitleError': 'Error',
		'Proceq.ErrorDisplayTitleWarning': 'Advertencia',
		'Proceq.LoginBackLogin': 'Regresar al inicio de sesión',
		'Proceq.LoginForgotPasswordQuestion': '¿Ha olvidado la contraseña?',
		'Proceq.LoginResendConfirmationEmail': 'Volver a enviar el correo electrónico de confirmación',
		'Proceq.LoginRegisterAccountTextPart': 'Si no tiene ninguna cuenta, por favor',
		'Proceq.LoginRegisterAccountLinkPart': 'regístese aquí',
		'Proceq.LoginProceqUseOnly': 'Esto sólo es para uso interno por Proceq',
		'Proceq.ProfileEnterInfo': 'Por favor, entre sus datos del perfil antes de continuar',
		'Proceq.TextInfoExclamation': '¡Información!',
		'Proceq.FooterLegalPrivacy': 'Leyes y privacidad',
		'Proceq.JQGridTableRecordText': 'Vista {0} - {1} de {2}',
		'Proceq.JQGridTableEmptyRecords': 'No hay registros que ver',
		'Proceq.JQGridTableLoadText': 'Cargando...',
		'Proceq.JQGridTablePaginationText': 'Página {0} de {1}',
		'Proceq.JQGridTableSavingText': 'Guardando...',
		'Proceq.JQGridTableFirstPage': 'Primera página',
		'Proceq.JQGridTableLastPage': 'Última página',
		'Proceq.JQGridTableNextPage': 'Página siguiente',
		'Proceq.JQGridTablePreviousPage': 'Página anterior',
		'Proceq.JQGridTableRecordsPerPage': 'Registros por página',
		'Proceq.JQGridTablePageText': 'Página:',
		'Proceq.JQGridTableNoMoreRecs': 'No hay más registros...',
		'Proceq.ExportColumnCSVNo': 'N°',
		'Proceq.ExportColumnCSVSingleReadings': 'Lecturas singulares',
		'Proceq.ExportColumnCSVMeasurementValue': 'Valor de medición',
		'Proceq.ExportColumnCSVAngleDegrees': 'Ángulo (°)',
		'Proceq.ExportColumnCSVExcluded': 'Excluido',
		'Proceq.ExportColumnCSVSeriesName': 'Nombre de serie',
		'Proceq.ExportColumnCSVImpactDevice': 'Dispositivo de impacto',
		'Proceq.ExportColumnCSVImpactDeviceSN': 'N° S Dispositivo de impacto',
		'Proceq.ExportColumnCSVImpactDeviceVerification': 'Verificación de dispositivo de impacto',
		'Proceq.ExportColumnCSVImpactDeviceHammerVerification': 'Verificación de martillo',
		'Proceq.ExportColumnCSVImpactDeviceHammer': 'Martillo',
		'Proceq.ExportColumnCSVImpactDeviceHammerSN': 'N° S Martillo',
		'Proceq.ExportColumnCSVBluetoothModuleSN': 'N° S Módulo de Bluetooth',
		'Proceq.ExportColumnCSVConversionStandard': 'Est. conversión',
		'Proceq.ExportColumnCSVScale': 'Escala',
		'Proceq.ExportColumnCSVUnit': 'Unidad',
		'Proceq.ExportColumnCSVReadings': 'Lecturas',
		'Proceq.ExportColumnCSVStandardDeviation': 'Desv. est.',
		'Proceq.ExportColumnCSVRelativeSpan': 'Expan. rel(%)',
		'Proceq.ExportColumnCSVDebugDataSeriesName': 'Nombre de serie',
		'Proceq.ExportColumnExportDate': 'Fecha de exportación',
		'Proceq.ExportColumnUpperLimit': 'Límite superior',
		'Proceq.ExportColumnLowerLimit': 'Límite inferior',
		'Proceq.ExportColumnLimits': 'Límites',
		'Proceq.ExportColumnHigh': 'Alto',
		'Proceq.ExportColumnLow': 'Bajo',
		'Proceq.ExportColumnValueOff': 'Apagado',
		'Proceq.ExportColumnValueNoConversion': 'Sin conversión',
		'Proceq.ExportColumnValueNoConversionShort': 'SinConv',
		'Proceq.ExportColumnReadings': 'Lecturas',
		'Proceq.ExportColumnStandardDeviation': 'Desviación estándar',
		'Proceq.ExportColumnRange': 'Rango',
		'Proceq.ExportColumnRelativeSpan': 'Extensión relativa',
		'Proceq.ExportColumnMaximum': 'Máximo',
		'Proceq.ExportColumnMinimum': 'Mínimo',
		'Proceq.ExportColumnAverage': 'Promedio',
		'Proceq.ExportColumnUnit': 'Unidad',
		'Proceq.ExportColumnName': 'Nombre',
		'Proceq.ExportColumnDataSeriesID': 'ID de serie',
		'Proceq.ExportColumnFolderName': 'Nombre de carpeta',
		'Proceq.ExportColumnCreatedDate': 'Creado Fecha',
		'Proceq.ExportColumnCreatedDateOnly': 'Creado Fecha',
		'Proceq.ExportColumnCreatedTime': 'Creado Hora',
		'Proceq.ExportColumnLastModifiedDate': 'Última modificación Fecha',
		'Proceq.ExportColumnLastModifiedDateOnly': 'Última modificación Fecha',
		'Proceq.ExportColumnLastModifiedTime': 'Última modificación Hora',
		'Proceq.ExportColumnMeasurementAvgHardness': 'Medidción Dureza promedio',
		'Proceq.ExportColumnLocationLatitude': 'Latitud de la ubicación',
		'Proceq.ExportColumnLocationLongitude': 'Longitud de la ubicación',
		'Proceq.ExportColumnProbeType': 'Tipo',
		'Proceq.ExportColumnProbeSN': 'Número de serie',
		'Proceq.ExportColumnBTMSN': 'Número de serie',
		'Proceq.ExportColumnProbeLastModifiedDate': 'Última verificación Fecha',
		'Proceq.ExportColumnProbeHardwareRevision': 'Revisión del hardware',
		'Proceq.ExportColumnProbeFirmwareRevision': 'Revisión del firmware',
		'Proceq.ExportColumnBTMHardwareRevision': 'Revisión del hardware',
		'Proceq.ExportColumnBTMFirmwareRevision': 'Revisión del firmware',
		'Proceq.ExportColumnAppPlatform': 'Plataforma',
		'Proceq.ExportColumnVersion': 'Versión',
		'Proceq.ExportColumnOperator': 'Operador',
		'Proceq.ExportColumnPrimaryScale': 'Escala primaria',
		'Proceq.ExportColumnSecondaryScale': 'Escala secundaria',
		'Proceq.ExportColumnMaterial': 'Material',
		'Proceq.ExportColumnConversionStandard': 'Estándar de conversión',
		'Proceq.ExportColumnAngleCorrection': 'Corrección de ángulo',
		'Proceq.ExportColumnValueAuto': 'Auto',
		'Proceq.ExportColumnTableAngleGrades': 'Ángulo (°)',
		'Proceq.ExportColumnTableLegendExcluded': '* Excluido',
		'Proceq.ExportColumnMeasurementBarGraph': 'Código de barras de medición',
		'Proceq.ExportColumnTableView': 'Vista de tabla',
		'Proceq.ExportColumnStatistics': 'Estadística',
		'Proceq.ExportColumnSettings': 'Ajustes',
		'Proceq.ExportColumnImpactDeviceInfo': 'Info Dispositivo de impacto',
		'Proceq.ExportColumnImpactDeviceHammerInfo': 'Info Martillo',
		'Proceq.ExportColumnBluetoothModuleInfo': 'Info Módulo de Bluetooth',
		'Proceq.ExportColumnApplicationInfo': 'Info Aplicación',
		'Proceq.ExportColumnAttachedPhotos': 'Fotos adjuntas',
		'Proceq.ButtonActions': 'Acciones',
		'Proceq.SerialNotInServiceFTP': 'N° S no en FTP de servicio',
		'Proceq.ActionResultsToCSV': 'Exportar resultados como CSV',
		'Proceq.ErrorDisplayTitleSuccess': 'Éxito',
		'Proceq.ErrorNoMultipleTargetsAllowed': 'La acción no se puede ejecutar en hileras múltiples',
		'Proceq.MessageActionSuccesfullyPerformed': 'La acción se ha ejecutado con éxito',
		'Proceq.EmailReminderContractUserSubject': 'Recordatorio de expiración del contrato',
		'Proceq.EmailReminderContractUserBody':
			'Estimado usuario<br/><br/>Su periodo de renta de  **?0** va a expirar en **?1**.<br/><br/>Su contrato se extenderá automáticamente por 12 meses de conformidad con <a href="http://www.proceq.com/fileadmin/user_upload/Proceq_General_Terms___Conditions_of_Rental_10112016.pdf">nuestras ‘Condiciones generales de renta’</a>. Recibirá la factura por separado. Por favor, páguela a tiempo para asegurar el uso ininterrumpido de su producto Equotip Live.<br/><br/>Por favor, póngase en contacto con su <a href="http://www.proceq.com/contact/proceq-network/">representante de ventas local</a> en caso de alguna duda.<br/><br/>Su Equipo Proceq<br/><br/>**?2**',
		'Proceq.EmailReminderContractAdminSubject': 'Recordatorio de expiración del contrato',
		'Proceq.ErrorNoTargetSelected': 'No se ha seleccionado destino para esta acción',
		'Proceq.ErrorDisplayTitleInfo': 'Info',
		'Proceq.TextSharingSeriesUrlLinkCreated':
			'La serie se ha compartido bajo el siguiente enlace:<br/>**?0**<br/><a class="link-copy-clipboard" href="javascript:void(0);" onclick="**?1**">Copiar al portapapeles</a><br/>',
		'App.ShareMeasurementsSuccess': 'La serie se ha compartido bajo el siguiente enlace',
		'App.ShareMeasurementsCopyAction': 'Copiar al portapapeles',
		'Proceq.TextPleaseWait': 'Favor de esperar...',
		'Proceq.TextPerformingServerRequest': 'Ejecución de solicitud fr servidor',
		'Proceq.TextPerformingAction': 'Ejecución de acción',
		'Proceq.ButtonCreateAccountChinaBack': 'Desechar',
		'Proceq.TitleCreateAccountChina': 'Cliente chino',
		'Proceq.BodyCreateAccountChina':
			'Para clientes en China, favor de ir a <a href="**?0**">**?1**</a> para registrarse o iniciar la sesión',
		'Proceq.LoginCompatibiltyInfo': 'Información de compatibilidad <a href="**?0**">Proceq Apps</a>',
		'Proceq.TextRedirectingToRegion': 'Redireccionar a su región...',
		'Proceq.EmailReminderContractExpiredTodayUserSubject': 'Expiración del contrato',
		'Proceq.EmailReminderContractExpiredTodayUserBody':
			'Estimado usuario<br/><br/>Su periodo de renta de **?0** ha expirado hoy.<br/><br/>Lamentamos que ya no esté usando Proceq Live, pero estaremos siemre dispuestos a bienvenirle de nuevo.<br/><br/>Para renovar el contrato, por favor póngase en contacto con su <a href="http://www.proceq.com/contact/proceq-network/">representante de ventas local</a>.<br/><br/>Su Equipo Proceq<br/><br/>**?1**',
		'Proceq.EmailRentalPeriodChangedSubject': 'Periodo de renta cambiado',
		'Proceq.EmailRentalPeriodChangedBody':
			'Estimado usuario<br/><br/>La fecha de expiración de su contrato de renta de **?0** ha sido cambiada. Su contrato de renta va a expirar en **?1**.<br/><br/>Por favor, póngase en contacto con su <a href="http://www.proceq.com/contact/proceq-network/">representante de venta local</a> en caso de duda.<br/><br/>Su Equipo Proceq<br/><br/>**?2**',
		'Proceq.FormExpiresIn': 'Expira en (días)',
		'Proceq.SelectTimeWeek': 'semana',
		'Proceq.SelectTimeDay': 'día',
		'Proceq.SelectTimeMonth': 'mes',
		'Proceq.GraphNoDataAvailable': 'no hay datos disponibles',
		'Proceq.GraphMaterial': 'Grupo de material',
		'Proceq.GraphSecondaryScale': 'Escala de conversión',
		'Proceq.GraphStandard': 'Estándar de conversión',
		'Proceq.GraphMeasurementCount': 'Númerode impactos',
		'Proceq.GraphMeasurementSeriesCount': 'Cuenta de serie de medición',
		'Proceq.StatisticsTitle': 'Estadística',
		'Proceq.StatisticsCreatedSeries': 'series de medición creadas',
		'Proceq.StatisticsModifiedSeries': 'series de medición modificadas',
		'Proceq.StatisticsPhotos': 'fotos',
		'Proceq.StatisticsAudioFiles': 'archivos de audio',
		'Proceq.StatisticsUsedStorage': 'memoria ocupada',
		'Proceq.StatisticsFolder': 'Carpeta',
		'Proceq.StatisticsModified': 'Modificado',
		'Proceq.StatisticsCreated': 'Creado',
		'Proceq.StatisticsAll': 'Todos',
		'Proceq.StatisticsCountry': 'País',
		'Proceq.StatisticsCompanySize': 'Tamaño de la compañía',
		'Proceq.StatisticsIndustry': 'Industria',
		'Proceq.DateRangeFrom': 'Desde',
		'Proceq.DateRangeTo': 'A',
		'Proceq.GraphHistogram': 'Histograma de dureza',
		'Proceq.GraphSeriesHistogram': 'Serie de hsitograma de dureza',
		'Proceq.StatisticsImpactDevice': 'Dispositivo de impacto',
		'Proceq.StatisticsImpactDeviceHammer': 'Martillo',
		'Proceq.GraphExcluded': 'Excluido',
		'Proceq.GraphIncluded': 'Incluido',
		'Proceq.GraphWithinTolerance': 'Dentro de la tolerancia',
		'Proceq.GraphOutOfTolerance': 'Fuera de tolerancia',
		'Proceq.StatisticsCreatedImpacts': 'Impactos creados (incluidos/excluidos)',
		'Proceq.StatisticsModifiedImpacts': 'Impactos modificados (incluidos/excluidos)',
		'Proceq.GraphNoConversion': 'Sin conversión',
		'Proceq.StatisticsTotalImpacts': 'Impactos en total',
		'Proceq.StatisticsTotalSeries': 'Series en total',
		'Proceq.StatisticsTotalExcluded': 'Excluidos en total',
		'Proceq.StatisticsValuesOutOfLimits': 'Valores fuera de los límites',
		'Proceq.StatisticsPeriod': 'Periordo',
		'Proceq.LabelFrom': 'Desde',
		'Proceq.LabelTo': 'A',
		'Proceq.LabelImpacts': 'Impactos',
		'Proceq.AbbrevCalendarWeek': 'SN',
		'Proceq.eRebNStd_Mean': 'Media',
		'Proceq.ExportFieldTargetScale': 'Escala destino',
		'Proceq.ExportFieldConversionCurve': 'Curva de conversión',
		'Proceq.eCC_None': 'Ninguno',
		'Proceq.eCC_Portland_Cement_J_N': 'Portland Cement J',
		'Proceq.eCC_Early_Strength_J_N': 'Early Strength J',
		'Proceq.eCC_Blast_Furnace_J_N': 'Blast Furnace J',
		'Proceq.eCC_Average_Curve_J_N': 'Average Curve J',
		'Proceq.eSC_R1_dry': 'JSCE-G504 R1 = 0 (seco)',
		'Proceq.eSC_R1_black_mark': 'JSCE-G504 R1 = +3 (marca negra)',
		'Proceq.eSC_R1_wet_surface': 'JSCE-G504 R1 = +5 (superficie mojada)',
		'Proceq.eCSC_None': 'Ninguno',
		'Proceq.eCSC_Upper': 'Superior',
		'Proceq.eCSC_Under': 'Inferior',
		'Proceq.eSCC_US_eCSC_None': 'Resistencia unificada',
		'Proceq.eSCC_US_eCSC_Upper': 'Resistencia unificada de superficie de relleno superior',
		'Proceq.eSCC_US_eCSC_Under': 'Resistencia unificada debajo de superficie de relleno',
		'Proceq.eSCC_PC_eCSC_None': 'Hormigón bombeado',
		'Proceq.eSCC_PC_eCSC_Upper': 'Superficie de relleno superior de hormigón bombeado',
		'Proceq.eSCC_PC_eCSC_Under': 'Hormigón bombeado debajo de superficie de relleno',
		'Proceq.eSCC_US': 'Resistencia unificada',
		'Proceq.eSCC_PC': 'Hormigón bombeado',
		'Proceq.eNoOutlier': 'Ningún valor anormal',
		'Proceq.eOutlierLow': 'Valor anormal bajo',
		'Proceq.eOutlierHigh': 'Valor anormal alto',
		'Proceq.eOutlierRedundant': 'Valor anormal redundante',
		'Proceq.LogbookPropertyConversionCurveChangedFromTo': 'Curva de conversión ha cambiado de **?0** a **?1**',
		'Proceq.LogbookPropertyConversionCurveChangedTo': 'Curva de conversión ha cambiado a **?0**',
		'Proceq.LogbookPropertyTargetScaleChangedFromTo': 'Escala destino ha cambiado de **?0** a **?1**',
		'Proceq.LogbookPropertySurfaceCorrectionChangedFromTo':
			'Corrección de superficie ha cambiado de **?0** a **?1**',
		'Proceq.LogbookPropertySurfaceCorrectionChangedTo': 'Corrección de superficie ha cambiado a **?0**',
		'Proceq.LogbookPropertyCarbonationDepthChangedFromTo':
			'Profundidad de carbonatación ha cambiado de **?0** mm a **?1** mm',
		'Proceq.LogbookPropertySurfaceConditionChangedFromTo':
			'Condición de la superficie ha cambiado de **?0** a **?1**',
		'Proceq.LogbookPropertySurfaceConditionChangedTo': 'Condición de la superficie ha cambiado a **?0**',
		'Proceq.LogbookPropertyConcreteAgeChangedFromTo': 'Edad del hormigón ha cambiado de **?0** d a **?1** d',
		'Proceq.LogbookPropertyConcreteAgeChangedTo': 'Edad del hormigón ha cambiado a **?0** d',
		'Proceq.ExportColumnCSVOutlierType': 'Valor anormal',
		'Proceq.ExportColumnCarbonationDepth': 'Profundidad de carbonatación',
		'Proceq.ExportColumnConcreteAge': 'Edad del hormigón',
		'Proceq.ExportFieldCarbonationDepth': 'Profundidad de carbonatación',
		'Proceq.ExportFieldConcreteAge': 'Edad del hormigón',
		'Proceq.ValueConcreteAgeDays': '**?0** d',
		'Proceq.ValueCarbonationDepthMms': '**?0** mm',
		'Proceq.ExportColumnConversionCurve': 'Curva de conversión',
		'Proceq.ExportColumnTableLegendOutlier': '* Valor anormal',
		'Proceq.ExportColumnTargetScale': 'Escala destino',
		'Proceq.ExportColumnMeasurementAvgCompressiveStrength': 'Medidión Resistencia a la compresión promedio',
		'Proceq.ExportColumnEn13791Screening': 'Prueba EN 13791',
		'Proceq.ExportColumnLowestRebound': 'Valor de rebote más bajo',
		'Proceq.ExportColumnMedianRebound': 'Mediana de valor de rebote',
		'Proceq.ExportColumnCompressiveStrengthClass': 'EN 206 Clase de resistencia a la comrpresión',
		'Proceq.EmailIntroDearContractOwner': 'Estimado propietario del contrato',
		'Proceq.ColumnTableSeriesRegionExport': 'Serie',
		'Proceq.ExportColumSeriesRegionExport': 'Serie',
		'Proceq.ExportTitleTestRegionExport': 'Exportación de región del ensayo',
		'Proceq.ErrorSchmidtExportInvalidStandard': 'Todas las series deberían tener el mismo juego estándar',
		'Proceq.ErrorSchmidtExportTooFewSeries': 'Se requiere un mínimo de 9 series para la prueba EN 13791',
		'Proceq.ErrorSchmidtExportTooManySeries':
			'Se puede elegir un máximo de 99 series para una exportación singular',
		'Proceq.ErrorSchmidtExportDifferentConfiguration': 'Se han encontrado configuraciones diferentes en la serie',
		'Proceq.ErrorSchmidtExportWrongStandard': 'Se ha encontrado norma incorrecta para la exportación seleccionada',
		'Proceq.ErrorSchmidtExportInvalidSeries':
			'Por lo menos una de las series es inválida según la norma seleccionada',
		'Proceq.ErrorSchmidtExportSoftwareError': 'Error durante la generación de la exportación',
		'Proceq.ErrorSchmidtExportOutOfRange': 'La clase de resistencia calculada está fuera de rango',
		'Proceq.ButtonExportTestRegionEU': 'Exportar ensayo de Prueba EN 13791 como PDF',
		'Proceq.ButtonExportTestRegionUniformity': 'Exportación de uniformidad como PDF',
		'Proceq.ButtonExportTestRegionChina': 'Exportación de ensayo de región JGJ T23 como PDF',
		'Proceq.ErrorNotYetImplemented': 'Todavía no está implementado',
		'Proceq.ErrorSchmidtExportWrongParam':
			'Se ha encontrado parámetro incorrecto en la serie. Unidad incorrecta para la exportación',
		'Proceq.ErrorSchmidtExportUnitNotMpa': 'Para región JGJ es obligatorio que la unidad esté ajustada en MPa',
		'Proceq.ErrorSchmidtExportWrongFormFactor': 'Para región JGJ el factor de forma no debe ser cilindro 2:1',
		'Proceq.NavSelectView': 'Seleccionar vista',
		'Proceq.TextConfirmationAreYouSure': '¿Está seguro?',
		'Proceq.ErrorContractLinkedDifferentImpactDevice':
			'El contrato no se puede usar con el número de serie de dispositivo de impacto actual',
		'Proceq.TitleShareByUrl': 'Compartir por URL',
		'Proceq.ExportTitleVerification': 'Informe de verificación:',
		'Proceq.ExportVerificationStatusFailed': 'Fracasada',
		'Proceq.ExportVerificationStatusPassed': 'Aprobada',
		'Proceq.ExportColumnTestVerificationResult': 'Resultado de verificación',
		'Proceq.ExportColumnTestBlockTolerance': 'Incertidumbre del bloque de ensayo (Ucrm) k=2',
		'Proceq.ExportColumnTestBlockNominalHardness': 'Dureza nominal del bloque de ensayo',
		'Proceq.ExportColumnTestBlockSN': 'Número de serie del bloque de ensayo',
		'Proceq.ExportColumnTestBlockName': 'Nombre del bloque de ensayo',
		'Proceq.ExportColumnVerificationStd': 'Norma de verificación',
		'Proceq.ExportColumnVerificationCriteria': 'Criterios de verificación',
		'Proceq.VerificationCriteriaAverage5Span5Perc': 'promedio +/- 5%, extensión +/- 5%',
		'Proceq.VerificationCriteriaReadings6HL': 'lecturas +/- 6 HL',
		'Proceq.VerificationCriteriaReadings3Perc': 'lecturas +/- 3%',
		'Proceq.VerificationCriteriaAverage5Perc': 'promedio +/- 5%',
		'Proceq.VerificationCriteriaAverage2R': 'promedio +/- 2 R',
		'Proceq.VerificationCriteriaReadings3R': 'lecturas +/- 3 R',
		'Proceq.StandardEnumCustom': 'Personalizado',
		'Proceq.MaterialEnumCustom': 'Personalizado',
		'Proceq.ExportColumnCalibrationAnvilName': 'Nombre del yunque de calibración',
		'Proceq.ExportColumnCalibrationAnvilSN': 'Número de serie del yunque de calibración',
		'Proceq.ExportColumnCalibrationAnvilReboundValue': 'Valor de rebote del yunque de calibración',
		'Proceq.ExportColumnCalibrationAnvilTolerance': 'Tolerancia del yunque de calibración',
		'Proceq.ExportColumnTestLoadInfo': 'Info de carga de ensayo',
		'Proceq.NavVerificationData': 'Datos de verificación',
		'Proceq.NavCustomData': 'Datos personalizados',
		'Proceq.TableColumnVerificationResult': 'Resultado',
		'Proceq.TitleVerifications': 'Verificaciones',
		'Proceq.LogBookTestBlock': 'Bloque de ensayo',
		'Proceq.LogBookCalibrationAnvil': 'Yunque de calibración',
		'Proceq.LogbookPropertyTestBlockChangedFromTo': 'Bloque de ensayo ha cambiado de "**?0**" a "**?1**"',
		'Proceq.LogbookPropertyTestBlockChangedTo': 'Bloque de ensayo ha cambiado a "**?0**"',
		'Proceq.LogbookPropertyCalibrationAnvilChangedFromTo': 'Yunque de calibración ha cambiado de **?0** a **?1**',
		'Proceq.LogbookPropertyCalibrationAnvilChangedTo': 'Yunque de calibración ha cambiado a **?0**',
		'Proceq.LogbookPropertyCreatedVerification': 'Verificación creada "**?0**"',
		'Proceq.LogbookPropertyRenamedVerificationFromTo':
			'Se ha cambiado el nombre de la verificación de "**?0**" a "**?1**"',
		'Proceq.LogbookResultVerificationFailed': 'Verificación fracasada',
		'Proceq.LogbookResultVerificationPassed': 'Verificación aprobada',
		'Proceq.LogbookPropertyTestLoadChangedFromTo': 'Carga de ensayo ha cambiado de **?0** a **?1**',
		'Proceq.LogbookPropertyTestLoadChangedTo': 'Carga de ensayo ha cambiado a **?0**',
		'Proceq.LogBookTestLoad': 'Carga de ensayo',
		'Proceq.FieldWebOriginalCurve': 'Original',
		'Proceq.FieldWebCustomCurve': 'Personalizado',
		'Proceq.ExportFieldValuePair': 'Pareja de valores',
		'Proceq.TableBaseMaterial': 'Material base',
		'Proceq.TableDescription': 'Descripción',
		'Proceq.NavCustomMaterial': 'Material personalizado',
		'Proceq.FolderCustomMaterials': 'Materiales personalizados',
		'Proceq.SchmidtCurveTypePolynomial': 'fck = a + b*R + c*R² (polinomial)',
		'Proceq.SchmidtCurveTypeExponencial': 'fck = a.e^(b*R) (exponencial)',
		'Proceq.SchmidtCurveTypePolynomialShort': 'Polinomial',
		'Proceq.SchmidtCurveTypeExponencialShort': 'Exponencial',
		'Proceq.ExportFieldCurveType': 'Tipo de curva',
		'Proceq.TableActionEdit': 'Editar',
		'Proceq.TableActionAdd': 'Agregar',
		'Proceq.WebAccountActivatedTitle': 'Bienvenido a  Proceq Live,',
		'Proceq.WebAccountActivatedBodyLine1': 'Su cuenta  se ha activado con éxito. ¡Está lista para usarla!',
		'Proceq.WebAccountActivatedProceqTeam': 'Equipo Proceq',
		'Proceq.NavDashboard': 'Tablero',
		'App.SchmidtFormFactorRAngle': 'Ángulo R corregido',
		'App.SchmidtFormFactorQAngle': 'Valor Q',
		'App.ShareMeasurementsCopySuccess': '¡Copiado!',
		'App.ShareMeasurementsCopyFailed': 'Su explorador no soporta la copia. Favor de copiar manualmente.',
		'App.SystemFolders': 'Carpetas del sistema',
		'Proceq.DataSeriesDemo': 'Demostración',
		'Proceq.DataSeriesImported': 'Importado',
		'Antenna Spacing [{unit}]': 'Espaciado de antena [{unit}]',
		'App Version': 'Version de aplicacion',
		'Base Unit S/N': 'Unidad base S / N',
		'Calculated Depth': 'Profundidad calculada',
		Comment: 'Comentario',
		'Contract ID': 'ID del contrato',
		'Distance X [{unit}]': 'Distancia X [{unit}]',
		'Distance Y [{unit}]': 'Distancia Y [{unit}]',
		'Export HTML Format Version': 'Exportar versión de formato HTML',
		'File ID': 'ID de archivo',
		'File Name': 'Nombre del archivo',
		'Firmware Version': 'Versión de firmware',
		'Grid Size X [{unit}]': 'Tamaño de cuadrícula X [{unit}]',
		'Grid Size Y [{unit}]': 'Tamaño de cuadrícula Y [{unit}]',
		'Grid Spacing X [{unit}]': 'Espaciado de cuadrícula X [{unit}]',
		'Grid Spacing Y [{unit}]': 'Espaciado de cuadrícula Y [{unit}]',
		'Hardware Version': 'versión del hardware',
		Line: 'Línea',
		'MAC Address': 'Dirección MAC',
		'Marker Number': 'Número de marcador',
		'Measurement Presets': 'Presets de medición',
		Mode: 'Modo',
		Name: 'Nombre',
		Objects: 'Objetos',
		'Probe S/N': 'Sonda S / N',
		Product: 'Producto',
		'Repetition Rate [{unit}]': 'Tasa de repetición [{unit}]',
		Resolution: 'Resolución',
		'Sampling Rate [{unit}]': 'Tasa de muestreo [{unit}]',
		'Scan Distance [{unit}]': 'Distancia de escaneo [{unit}]',
		'Scan Length [{unit}]': 'Longitud de escaneo [{unit}]',
		'Set Depth': 'Establecer profundidad',
		'Set Size': 'Establecer tamaño',
		Tag: 'Etiqueta',
		'Tag Type': 'Tipo de etiqueta',
		'Time Window [{unit}]': 'Ventana de tiempo [{unit}]',
		Units: 'Unidades',
		'User / Probe Info': 'Información de usuario / sonda',
		Markers: 'Marcadores',
		'Created {fileName} in folder {folderName}': 'Se creó {fileName} en la carpeta {folderName}',
		'Mode: {value}': 'Modo: {value}',
		'Auth.LoggingIn': 'Iniciando la sesión...',
		'Auth.LoggingOut': 'Cerrando la sesión...',
	},
};
