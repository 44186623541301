// @flow
import React from 'react';
import { get } from 'lodash';
import { Table } from 'antd';
import { FormattedMessage } from 'react-intl';
import { getFileNameWithExpectedExtensions } from 'utils/fileUtils';
import ConvertedUnits from 'components/shared/ConvertedUnits';
import type { DerivedProps } from 'components/measurements/HTMLExport/Measurement';
import styles from 'components/measurements/HTMLExport/HTMLExport.styl';
import Audio from 'components/measurements/LogEvent/Audio';
import type { MeasurementObjectWithInfo } from './Objects';

export const title = <FormattedMessage id="App.HTML.GPR.Markers" />;

export const Markers = ({
	data,
	scanType,
	product,
	isMetric,
	convert,
	showTitle,
}: { data: MeasurementFullData } & DerivedProps) => {
	// we only want to use the most recent log entry
	const sortedLogs = data.logs.sort((a, b) => b.clientCreated - a.clientCreated);

	let markers: MeasurementObjectWithInfo[] = data.objects
		.filter(object => object.type === 'marker')
		.map(object => {
			const reading = data.readings.find(r => r.id === object.rID);

			const log = sortedLogs.find(l => +l.content.sequence === +object.content.number);

			return {
				...object,
				readingContent: reading ? reading.content : {},
				logContent: log ? log.content : {},
			};
		});
	markers = markers.sort((a, b) => {
		// this is actually the last to be sorted, applies only when type is sorted
		if (a.content.type === b.content.type) {
			return a.content.number - b.content.number;
		}

		// then sort by tag type
		return a.content.type === 'Text' ? -1 : 1;
	});

	return (
		<div className="table-markers">
			<Table
				title={showTitle ? () => <span className={styles.main_header}>{title}</span> : null}
				className={styles.table}
				columns={[
					{
						title: 'App.HTML.GPR.Markers.MarkerNumber',
						key: 'markerNumber',
						render: (text: string, record: MeasurementObjectWithInfo) =>
							`${record.content.type === 'Text' ? 'M' : 'V'}${record.content.number}`,
					},
					{
						title: 'App.HTML.GPR.Objects.DistanceX',
						unitId: `${product}.CSV.Marker distance X`,
						dataIndex: 'content.distanceMeter',
						key: 'distanceMeter',
					},
					{
						title: 'App.HTML.GPR.Objects.DistanceY',
						unitId: `${product}.CSV.Marker distance Y`,
						key: 'distanceY',
					},
					{
						title: 'App.LogbookSettingsComment',
						dataIndex: 'content.content',
						key: 'content',
						render: (text: string, record: MeasurementObjectWithInfo) => {
							if (record.content.type === 'Text') {
								return text;
							}

							const attachment = data.attachments.find(a => a.id === text);
							const attachmentName = getFileNameWithExpectedExtensions(get(attachment, 'fileNames', []), [
								'm4a',
								'aac',
							]);

							return (
								<Audio
									mID={record.mID}
									aID={text}
									attachmentName={attachmentName || `${text}.m4a`}
									download={false}
								/>
							);
						},
					},
				].map(columnConfig => ({
					// eslint-disable-next-line no-unused-vars
					render: (text: string, record: MeasurementObjectWithInfo) =>
						columnConfig.unitId ? convert(text, columnConfig.unitId) : text,
					...columnConfig,
					title: columnConfig.unitId ? (
						<ConvertedUnits
							id={columnConfig.title}
							unitId={columnConfig.unitId}
							scanType={scanType}
							isMetric={isMetric}
						/>
					) : (
						<FormattedMessage id={columnConfig.title} />
					),
				}))}
				dataSource={markers}
				pagination={false}
				size="small"
				rowKey="id"
			/>
		</div>
	);
};

export default Markers;
