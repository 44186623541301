// @flow
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { formatDateTimeNoSecsToLocale } from 'utils/dateHandler';

export const MarkerChange = ({ log }: GPRLogbookEntryProps) => (
	<div>
		<b>
			<FormattedMessage id="App.LogbookPropertyMarkerChange" />
		</b>
		<br />
		<FormattedMessage id="App.Logbook.PI8000.PileName" />
		{`: ${log.content.pileName}`}
		<br />
		<FormattedMessage id="App.Logbook.PI8000.PositionName" />
		{`: ${log.content.positionName}`}
		<br />
		<FormattedMessage id="App.Logbook.PI8000.MarkerName" />
		{`: ${log.content.markerName}`}
		<br />
		<FormattedMessage id="App.Logbook.PI8000.Event" />
		{`: ${log.content.event}`}
		<br />
		<FormattedMessage id="App.Logbook.PI8000.DeletedDate" />
		{`: ${log.content.event === 'deleted' ? formatDateTimeNoSecsToLocale(log.content.deletedDate) : '-'}`}
	</div>
);

export default MarkerChange;
