// @flow
export const InfinityValue = '3.402823466385289e+38'; // fixed in app side
export const InfinitySymbol = '∞';

export const IsInfinity = (num: number) => {
	if (num === undefined || typeof num !== 'number') {
		return false;
	}
	return num.toPrecision(8) === parseFloat(InfinityValue).toPrecision(8);
};
