// @flow
import React from 'react';
import moment from 'moment';
import { getLabelFromGlobalDataKey } from 'utils/registryHelper';
import { formatDateTimeNoSecsToLocale } from 'utils/dateHandler';

const renderTime = (text: number) =>
	text === 0 ? '-' : <div>{formatDateTimeNoSecsToLocale(moment.unix(text).local())}</div>;
const renderTimestamp = (text: string) => (
	<div>{formatDateTimeNoSecsToLocale(moment.utc(text, moment.ISO_8601).local())}</div>
);

const columnConfig = [
	{
		title: 'Proceq.FormCountry',
		dataIndex: 'countryCode',
		globalRegister: 'countries',
		filter: true,
		multiSelect: true,
		sorter: true,
	},
	{
		title: 'App.AnalyticsRegion',
		key: 'region',
		dataIndex: 'region',
		globalRegister: 'regionsnames',
		sorter: true,
		filter: true,
		multiSelect: true,
		render: (region: any) => getLabelFromGlobalDataKey('regionsnames', region),
	},
	{
		title: 'Proceq.FormJobTitle',
		dataIndex: 'jobTitle',
		sorter: true,
		filter: 'jobTitle',
	},
	{
		title: 'Proceq.FormCompanyName',
		dataIndex: 'companyName',
		sorter: true,
		filter: 'companyName',
	},
	{
		title: 'Proceq.FormCompanySize',
		dataIndex: 'companySize',
		globalRegister: 'companysize',
		filter: true,
		multiSelect: true,
	},
	{
		title: 'Proceq.FormCompanyWebsite',
		dataIndex: 'companyWebSite',
		sorter: true,
		filter: 'companyWebSite',
	},
	{
		title: 'Proceq.FormIndustry',
		dataIndex: 'industry',
		globalRegister: 'industrytype',
		sorter: true,
		filter: true,
		multiSelect: true,
	},
	{
		title: 'Proceq.FormNewsletter',
		dataIndex: 'newsletter',
		globalRegister: 'yesorno',
		filter: true,
		sorter: true,
	},
	{
		title: 'App.UsersVerified',
		dataIndex: 'emailVerified',
		globalRegister: 'yesorno',
		filter: true,
		sorter: true,
	},
	{
		title: 'App.UsersPasswordUpdated',
		dataIndex: 'passwordUpdatedTime',
		sorter: true,
		sorterProp: 'passwordUpdated',
		render: renderTime,
	},
	{
		title: 'App.UsersCreated',
		dataIndex: 'created',
		sorter: true,
		render: renderTimestamp,
	},
	{
		title: 'App.UsersUpdated',
		dataIndex: 'updated',
		sorter: true,
		render: renderTimestamp,
	},
];

export default columnConfig;
