import moment from 'moment';
import { LicenseStatus } from '../components/contracts/contractConsts';
import contractStyles from '../components/contracts/Contracts.styl';
import licenseStyles from '../components/contracts/Licenses.styl';

// const ONE_HOUR = 3600000;
const ONE_DAY = 86400000;
export const DATE_SERVER_FORMAT = 'YYYY-MM-DD';
export const DATETIME_SERVER_FORMAT = 'YYYY-MM-DD hh:mm:ss';
export const DATETIMENOSECS_SERVER_FORMAT = 'YYYY-MM-DD hh:mm';
export const DATE_LOGBOOK_FORMAT = 'D MMMM YYYY, h:mm A';
export const DATE_WITH_TIMEZONE_FORMAT = 'YYYY-MM-DD HH:mm:ss Z';

// Time in milliseconds to localeDateString convertor
export function timeToLocaleDate(timeInMilliSeconds) {
	return moment(timeInMilliSeconds).format('D MMM YYYY');
}

// Time in milliseconds to localeDateString converter with literal month string
export function timeToLocaleDateFormatted(timeInMilliSeconds) {
	return moment(timeInMilliSeconds).format('MMMM D, YYYY');
}

// Time in milliseconds to localeString convertor
export function timeToLocaleTime(timeInMilliSeconds) {
	return moment(timeInMilliSeconds).format('D MMM YYYY, LT');
}

export function tsSecondsToLocaleTime(timeInSeconds) {
	return timeToLocaleTime(timeInSeconds * 1000);
}

// If less than 1 day, returns the time diff
// else returns the date
export function timeElapsedToLocaleTime(timeInMilliSeconds) {
	const dateNow = Date.now(),
		timeElapsed = timeInMilliSeconds - dateNow;

	if (timeElapsed * -1 < ONE_DAY) {
		return moment.duration(timeElapsed).humanize(true);
	}

	return timeToLocaleDate(timeInMilliSeconds);
}

export function timeToDayOfWeek(timeInMilliSeconds) {
	return moment(timeInMilliSeconds).format('ddd');
}

export function timeToDate(timeInMilliSeconds) {
	return moment(timeInMilliSeconds).format('MMM D');
}

export function timeToMonth(timeInMilliSeconds) {
	return moment(timeInMilliSeconds).format('MMM');
}

export function formatDateToLocale(dateInServerFormat) {
	const formatToConvert = moment.localeData().longDateFormat('ll');
	return moment(dateInServerFormat).format(formatToConvert);
}

export function formatTimeNoSecsToLocale(dateInServerFormat) {
	const formatToConvert = moment.localeData().longDateFormat('LT');
	return moment(dateInServerFormat).format(formatToConvert);
}

export function formatDateTimeToLocale(dateInServerFormat) {
	const formatToConvert = `${moment.localeData().longDateFormat('ll')}oo ${moment
		.localeData()
		.longDateFormat('LTS')}`;
	return moment(dateInServerFormat).format(formatToConvert);
}

export function formatDateTimeNoSecsToLocale(dateInServerFormat) {
	// const formatToConvert = moment.localeData().longDateFormat('LT');
	const formatToConvert = moment.localeData().longDateFormat('lll');
	return moment(dateInServerFormat).format(formatToConvert);
}

export function getDateLocaleFormat() {
	return moment.localeData().longDateFormat('L');
}

export function formatLinuxTsSecsDateToLocale(timeInSeconds) {
	const formatToConvert = moment.localeData().longDateFormat('ll');
	return moment(timeInSeconds * 1000).format(formatToConvert);
}

export function formatLinuxTsSecsTimeToLocale(timeInSeconds) {
	const formatToConvert = moment.localeData().longDateFormat('LTS');
	return moment(timeInSeconds * 1000).format(formatToConvert);
}

export function formatLinuxTsSecsTimeNoSecsToLocale(timeInSeconds) {
	const formatToConvert = moment.localeData().longDateFormat('LT');
	return moment(timeInSeconds * 1000).format(formatToConvert);
}

export function formatLinuxTsSecsDateTimeToLocale(timeInSeconds) {
	const formatToConvert = `${moment.localeData().longDateFormat('ll')} ${moment.localeData().longDateFormat('LTS')}`;
	return moment(timeInSeconds * 1000).format(formatToConvert);
}

export function formatLinuxTsSecsDateTimeNoSecsToLocale(timeInSeconds) {
	const formatToConvert = moment.localeData().longDateFormat('lll');
	return moment(timeInSeconds).format(formatToConvert);
}

export function renderLogbookDate(date) {
	const formatToConvert = moment.localeData().longDateFormat('ll');
	if (process.env.HTML_EXPORT_VERSION) {
		return moment(date)
			.utcOffset(window.data.timeZoneOffset)
			.format(formatToConvert);
	}

	return moment(date).format(formatToConvert);
}

export function renderLogbookTime(date) {
	const formatToConvert = moment.localeData().longDateFormat('LT');
	if (process.env.HTML_EXPORT_VERSION) {
		return moment(date)
			.utcOffset(window.data.timeZoneOffset)
			.format(formatToConvert);
	}

	return moment(date).format(formatToConvert);
}

export const highlightExpiringContractsOrLicenses = (record, expirationDate, isContract, isSaasFreeTrial = false) => {
	if (!isContract && !isSaasFreeTrial && record.status === LicenseStatus.REVOKED) {
		return {
			className: licenseStyles.revoked,
		};
	}
	const currentDate = moment().startOf('day');
	const diffInDays = expirationDate.diff(currentDate, 'days');

	if (isSaasFreeTrial) {
		if (diffInDays <= 5) {
			return {
				className: contractStyles.sixty_days_or_less_highlighted,
			};
		}
		return {};
	}

	if (expirationDate !== '') {
		if (diffInDays <= 60) {
			return {
				className: contractStyles.sixty_days_or_less_highlighted,
			};
		}
		if (diffInDays >= 61 && diffInDays <= 90) {
			return {
				className: contractStyles.sixty_one_to_ninety_days_highlighted,
			};
		}
	}

	return {};
};
