// @flow
import React from 'react';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';
import InfoIcon from '../measurements/LogEvent/icoAboutCopy2.png';
import styles from '../measurements/LogEvent/GPR/ObjectMarker.styl';
import { AuditHistoryType, DocumentType } from './contractConsts';

export const AuditElement = ({
	logSecond,
	documentType,
	highlighted,
	toggleHighlight,
}: {
	highlighted: boolean,
	toggleHighlight: Function,
	documentType: string,
} & AuditItemProps) => {
	let documentHistory = 'Document';
	switch (documentType) {
		case AuditHistoryType.Contract:
			documentHistory = DocumentType.Contract;
			break;
		case AuditHistoryType.License:
			documentHistory = DocumentType.License;
			break;
	}
	let id = documentHistory + (logSecond.action === 1 ? ' created by ' : ' edited by ');
	id += logSecond.email;
	return (
		<div
			className={classnames({
				toggle: true,
				[styles.message_container]: true,
				[styles.highlightable]: true,
				[styles.highlighted]: highlighted,
			})}
			onClick={toggleHighlight}
			role="button"
			tabIndex={0}
		>
			<b>
				<FormattedMessage id={id} />
			</b>
			<br />
			{<img alt="icon" className={styles.info_icon} src={InfoIcon} />}
		</div>
	);
};

export default AuditElement;
