// @flow
import React from 'react';
import classNames from 'classnames';
import moment from 'moment';
import { uniq } from 'lodash';
import { connect } from 'react-redux';
import { compose, withState, withProps, withStateHandlers } from 'recompose';
import { FormattedMessage } from 'react-intl';
import { Modal, Table, Button, Icon } from 'antd';
import URL from 'apis/urls';
import styles from 'components/shared/SmarterTable.styl';
import { request } from 'actions/ajax';
import { DATETIME_SERVER_FORMAT } from 'utils/dateHandler';
import DownloadAttachment from './DownloadAttachment';

const renderTime = text => <span>{moment(text).format(DATETIME_SERVER_FORMAT)}</span>;
const generateFilter = (all, key) => ({
	filters: uniq(all.map(a => a[key])).map(t => ({ text: t, value: t })),
	onFilter: (v, r) => r[key] === v,
});
const generateSorter = key => (a, b) => a[key] - b[key];

export const Attachments = ({
	visible,
	openModal,
	closeModal,
	loading,
	attachments,
}: {
	visible: boolean,
	openModal: Function,
	closeModal: Function,
	loading: boolean,
	attachments: Attachment[],
}) => (
	<div className={classNames(styles.button, styles.inline)}>
		<Modal
			visible={visible}
			onOk={closeModal}
			onCancel={closeModal}
			title={<FormattedMessage id="App.MeasurementDebugAttachmentsTitle" />}
			footer={[
				<Button key="back" size="large" onClick={closeModal}>
					<FormattedMessage id="Proceq.LogbookClose" />
				</Button>,
			]}
			width={1115}
		>
			<Table
				rowKey="id"
				dataSource={attachments || []}
				columns={[
					{ title: 'App.AttachmentId', dataIndex: 'id' },
					{
						title: 'App.AttachmentType',
						dataIndex: 'type',
						...generateFilter(attachments || [], 'type'),
					},
					{
						title: 'App.AttachmentAttachedTo',
						dataIndex: 'rType',
						...generateFilter(attachments || [], 'rType'),
					},
					{
						title: 'App.AttachmentCreated',
						render: renderTime,
						dataIndex: 'created',
						sorter: generateSorter('created'),
					},
					{
						title: 'App.AttachmentUpdated',
						render: renderTime,
						dataIndex: 'updated',
						sorter: generateSorter('updated'),
					},
					{
						title: 'Proceq.LogbookDownload',
						render: (text, record) => <DownloadAttachment {...record} />,
					},
				].map(column => ({
					...column,
					title: <FormattedMessage id={column.title} />,
				}))}
			/>
		</Modal>
		<Button onClick={openModal} loading={loading} data-e2e-attachments-button>
			{!loading && <Icon type="paper-clip" />}
		</Button>
	</div>
);

export const AttachmentsWithState = compose(
	withStateHandlers(
		{
			visible: false,
		},
		{
			openModal: () => () => ({ visible: true }),
			closeModal: () => () => ({ visible: false }),
		}
	)
)(Attachments);

export default compose(
	withState('visible', 'setVisible', false),
	withState('loading', 'setLoading', false),
	withState('attachments', 'setAttachments', null),
	connect(null, (dispatch: Function, props) => ({
		fetchAttachments: () =>
			dispatch(
				request({
					url: URL.ATTACHMENTS,
					setLoading: props.setLoading,
					params: {
						mID: props.mID,
					},
				})
			).then(data => {
				props.setAttachments(data.data || []);
				props.setVisible(true);
			}),
	})),
	withProps(({ setVisible, setLoading, attachments, fetchAttachments }) => ({
		openModal: () => {
			if (attachments === null) {
				fetchAttachments();
			} else {
				setVisible(true);
			}
		},
		closeModal: () => {
			setLoading(false);
			setVisible(false);
		},
	}))
)(Attachments);
