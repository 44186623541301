import React, { useCallback, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { Spin } from 'antd';
import * as routeUtils from '../../utils/routeUtils';
import * as loginService from '../../actions/login';
import {
	getRedirectURI,
	EAGLE_ID_CLIENT_ID,
	checkEagleIDState,
	redirectEagleIDLogin,
	redirectEagleWorkspace,
} from '../../utils/authUtils';
import useIsAuthenticated from '../../hooks/useIsAuthenticated';
import routeURLs from '../../routes/urls';
import AuthBackground from './AuthBackground';
import { INVALID_ROLE_ERROR_MESSAGE } from '../../actions/login';

const AuthReceiver = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const { formatMessage } = useIntl();
	const language = useSelector(state => state.locale.lang) || 'en';

	const isAuthenticated = useIsAuthenticated();

	const { code, state = null, error } = routeUtils.parseQuery(location.search);

	const isFirstTime = useRef(true);

	const logout = useCallback(() => {
		history.push(routeURLs.LOGOUT);
	}, [history]);

	const handleGetOAuthToken = useCallback(
		myCode => {
			const clientID = EAGLE_ID_CLIENT_ID || '';
			const redirectURI = getRedirectURI(language);

			dispatch(
				loginService.fetchToken({
					code: myCode,
					client_id: clientID,
					redirect_uri: redirectURI,
					grant_type: 'authorization_code',
				})
			).catch(tokenError => {
				if (tokenError.message === INVALID_ROLE_ERROR_MESSAGE) {
					redirectEagleWorkspace();
				} else {
					logout();
				}
			});
		},
		[language, history, logout, dispatch]
	);

	useEffect(() => {
		// We only want to run this 1 time
		if (!isFirstTime.current) return;

		if (isAuthenticated) {
			history.replace('/');
			return;
		}

		const stateValid = checkEagleIDState(state);

		if (code && stateValid) {
			handleGetOAuthToken(code);
		} else if (error || (state && !stateValid)) {
			// Perform logout if there's error (from Eagle ID) or state not valid (copy paste from another tab)
			logout();
		} else {
			redirectEagleIDLogin(error !== undefined);
		}
	}, [isAuthenticated, code, state, handleGetOAuthToken, error, history, logout]);

	useEffect(() => {
		isFirstTime.current = false;
	}, []);

	return (
		<AuthBackground>
			<Spin tip={code ? formatMessage({ id: 'Auth.LoggingIn' }) : ''} size="large" />
		</AuthBackground>
	);
};

export default AuthReceiver;
