// @flow
import React, { Fragment } from 'react';
import { get } from 'lodash';
import { compose, withState } from 'recompose';
import { Form, Input, Button } from 'antd';
import { Link } from 'react-router-dom';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import URL from 'apis/urls';
import tableStyles from 'components/shared/SmarterTable.styl';
import { request } from 'actions/ajax';
import styles from './LoginForm.styl';
import type { CommonFormProps } from './LoginPage';
import Alert from './Alert';
import { emailRegex } from '../users/AddEditUser';

export const ForgotPasswordForm = ({
	loading,
	severity,
	message,
	form,
	forgotPassword,
}: {
	forgotPassword: Function,
} & CommonFormProps) => (
	<Fragment>
		{message && <Alert message={message} severity={severity} />}
		{severity !== 'success' && (
			<Form className={styles.form} onSubmit={forgotPassword}>
				<Form.Item label={<FormattedMessage id="Proceq.FormEmailGroupID" />} className={tableStyles.field}>
					{form.getFieldDecorator('username', {
						rules: [
							{
								required: true,
								pattern: emailRegex,
								message: <FormattedMessage id="Proceq.ForgotPasswordFormEmailMsg" />,
							},
						],
					})(<Input type="email" />)}
				</Form.Item>
				<div className={styles.button_container}>
					<Link to="/login">
						<FormattedMessage id="Proceq.LoginBackLogin" />
					</Link>
					<Button type="primary" htmlType="submit" loading={loading} className={styles.login_btn}>
						<FormattedMessage id="Proceq.ForgotPasswordFormSendPassword" />
					</Button>
				</div>
			</Form>
		)}
	</Fragment>
);

export default compose(
	withState('loading', 'setLoading', false),
	withState('severity', 'setSeverity', 'error'),
	withState('message', 'setMessage', ''),
	Form.create({}),
	injectIntl,
	connect(null, (dispatch: Function, props) => ({
		forgotPassword: e => {
			e.preventDefault();

			props.form.validateFields((err, values) => {
				if (err) {
					return;
				}

				props.setMessage('');
				dispatch(
					request({
						url: URL.FORGOT_PASSWORD,
						method: 'POST',
						setLoading: props.setLoading,
						params: values,
						errorHandler: error => {
							props.setSeverity('error');
							if (err) {
								if (get(err, 'response.data.code') === 'RequestLimitExceeded') {
									props.setMessage('App.ForgetPasswordLimitReached');
								} else if (get(err, 'response.status') < 500) {
									props.setMessage('Proceq.ForgotPasswordFormEmailSentMessage');
								}
							} else {
								props.setMessage('Proceq.ServerError');
							}
							props.setMessage(get(error, 'response.data.message'));
							props.setLoading(false);
						},
					})
				).then(() => {
					props.setSeverity('success');
					props.setMessage('Proceq.ForgotPasswordFormEmailSentMessage');
				});
			});
		},
	}))
)(ForgotPasswordForm);
