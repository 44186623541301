// @flow
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { MeasurementScanType } from 'constants/exportConstants';
import { ConvertedUnits } from '../../../shared/ConvertedUnits';

export const CloseFile = ({ product, log, scanType, isMetric }: GPRLogbookEntryProps) => (
	<div>
		<FormattedMessage id="App.LogbookEntryMeasurementEnd" />
		<br />
		{scanType === MeasurementScanType.GridScan ? (
			<FormattedMessage id="Proceq.LogbookEntryNumberOfScans" values={{ value: log.content.numberOfScan }} />
		) : (
			<ConvertedUnits
				id="App.LogbookEntryMeasurementEndTotalLength"
				unitId={`${product}.Logbook Panel.Measurement ended. Total length`}
				scanType={scanType}
				isMetric={isMetric}
				values={[log.content.distance]}
			/>
		)}
		<br />
		{scanType === MeasurementScanType.AreaScan && (
			<FormattedMessage
				id="App.LogbookEntryMeasurementEndNoOfLines"
				values={{
					count: log.content.numberOfScan,
				}}
			/>
		)}
	</div>
);

export default CloseFile;
