// @flow
import React from 'react';
import { connect } from 'react-redux';
import { compose, withState, lifecycle } from 'recompose';
import { Layout, Button } from 'antd';
import { FormattedMessage } from 'react-intl';
import URL from 'apis/urls';
import { request } from 'actions/ajax';
import styles from '../shared/SmarterTable.styl';
import columnConfig from './columnConfig';
import SmarterTable from '../shared/SmarterTable';

export const FirmwareComponent = ({
	firmwares,
	totalRecords,
	loadFirmwares,
	loading,
	setParams,
}: {
	firmwares: Firmware[],
	totalRecords: number,
	loadFirmwares: Function,
	loading: boolean,
	setParams: Function,
}) => (
	<Layout>
		<Layout.Content>
			<Button className={styles.button} type="primary" loading={loading} onClick={loadFirmwares}>
				<FormattedMessage id="Proceq.TableActionRefresh" />
			</Button>
			<SmarterTable
				rowKey="fileName"
				columns={columnConfig}
				dataSource={firmwares}
				loadDataSource={loadFirmwares}
				loading={loading}
				setParams={setParams}
				totalRecords={totalRecords}
			/>
		</Layout.Content>
	</Layout>
);

export default compose(
	withState('loading', 'setLoading', true),
	withState('firmwares', 'setFirmwares', []),
	withState('totalRecords', 'setTotalRecords', 0),
	withState('params', 'setParams', { start: 0, length: 20 }),
	connect(
		state => ({
			product: state.session.productType,
		}),
		(dispatch: Function, props) => ({
			loadFirmwares: () => {
				dispatch(
					request({
						url: URL.GET_FIRMWARES,
						setLoading: props.setLoading,
						params: props.params,
					})
				).then(data => {
					props.setFirmwares(data.data);
					props.setTotalRecords(data.recordsFiltered);
				});
			},
		})
	),
	lifecycle({
		componentDidMount() {
			this.props.loadFirmwares();
		},
		componentWillReceiveProps(nextProps) {
			if (this.props.product !== nextProps.product) {
				this.props.loadFirmwares();
			}
		},
	})
)(FirmwareComponent);
