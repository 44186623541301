// @flow
import React from 'react';
import { Table } from 'antd';
import { FormattedMessage } from 'react-intl';
import { get } from 'lodash';
import { MeasurementScanType, TagObjectType } from 'constants/exportConstants';
import ConvertedUnits from '../../../../../shared/ConvertedUnits';
import type { DerivedProps } from '../../../Measurement';
import styles from '../../../HTMLExport.styl';

export const calculateTransmissionTime = (object: Object, settings: Object) => {
	const contentProcess = settings[0].content.process;
	const totalScanDepthInTime = get(contentProcess, 'sampleParameters.scanDepthInTimeMicrosecond');
	const depthPercentage = get(object, 'content.location.depthPercentage');
	const signalZeroOffset = get(contentProcess, 'signalZeroOffset') || 0;
	if (!depthPercentage || !totalScanDepthInTime) {
		return -1;
	}
	return totalScanDepthInTime * depthPercentage - signalZeroOffset;
};

const calculateTagDepth = (object, settings) => {
	const totalScanDepthInTime = get(settings, '[0].content.process.sampleParameters.scanDepthInTimeMicrosecond');
	const totalScanDepthInDis = get(settings, '[0].content.process.sampleParameters.scanDepthInDistanceMeter');
	const signalZeroOffset = get(settings, '[0].content.process.signalZeroOffset', 0);
	const tagDepth = get(object, 'content.location.depthPercentage');
	if (!totalScanDepthInDis || !totalScanDepthInTime || !tagDepth) {
		return -1;
	}

	const truncatedDepth = (totalScanDepthInDis * signalZeroOffset) / totalScanDepthInTime;
	return totalScanDepthInDis * tagDepth - truncatedDepth;
};

export type MeasurementObjectWithInfo = MeasurementObject & {
	logContent: $PropertyType<Log, 'content'>,
};

export const title = <FormattedMessage id="App.HTML.GPR.Objects" />;

export const Objects = ({
	data,
	scanType,
	product,
	isMetric,
	convert,
	showTitle,
}: { data: MeasurementFullData } & DerivedProps) => {
	// we only want to use the most recent log entry
	const sortedLogs = data.logs.sort((a, b) => b.clientCreated - a.clientCreated);
	const calibratedDepthLog = sortedLogs.find(l => l.content.calibratedDepthString);
	const setDepth = calibratedDepthLog ? +calibratedDepthLog.content.calibratedDepthString : null;
	const isGridScan = data.measurement.type === MeasurementScanType.GridScan;

	const objects: MeasurementObjectWithInfo[] = data.objects
		.filter(object => object.type === 'object' && object.content.type !== TagObjectType.Velocity)
		.map(object => {
			const extraKeys = {};

			const calculatedDepth = calculateTagDepth(object, data.settings);
			extraKeys.calculatedDepth = calculatedDepth < 0 ? '-' : calculatedDepth;

			const log = sortedLogs.find(l => +l.content.sequence === object.content.number);
			if (log) {
				extraKeys.logContent = log.content;
			} else {
				extraKeys.logContent = {};
			}

			const reading = isGridScan ? data.readings.find(r => r.id === object.rID) : undefined;
			if (reading) {
				extraKeys.gridCell =
					String.fromCharCode('A'.charCodeAt(0) + reading.content.columnIndex) +
					(reading.content.rowIndex + 1);
			}

			const transmissionTime = isGridScan ? calculateTransmissionTime(object, data.settings) : undefined;
			if (transmissionTime !== undefined) {
				extraKeys.transmissionTime = transmissionTime < 0 ? '-' : transmissionTime;
			}

			if (!log || !calibratedDepthLog || !setDepth) {
				return {
					...extraKeys,
					...object,
				};
			}

			if (log.id === calibratedDepthLog.id) {
				// calibrated depth
				extraKeys.setDepth = setDepth;
			}

			return {
				...extraKeys,
				...object,
			};
		})
		.sort((a, b) => a.content.number - b.content.number);

	const gridScanColumns = isGridScan
		? [
				{
					title: 'App.HTML.PUNDIT.GridCell',
					dataIndex: 'gridCell',
				},
				{
					title: 'App.HTML.PUNDIT.TransmissionTime',
					dataIndex: 'transmissionTime',
					unitId: `${product}.GridScan.TransmissionTime`,
				},
		  ]
		: [];

	return (
		<div className="table-objects">
			<Table
				title={showTitle ? () => <span className={styles.main_header}>{title}</span> : null}
				className={styles.table}
				columns={[
					{
						title: 'App.HTML.GPR.Objects.TagNumber',
						dataIndex: 'content.number',
					},
					{
						title: 'App.HTML.GPR.Objects.TagType',
						dataIndex: 'content.type',
					},
					...gridScanColumns,
					{
						title: 'App.HTML.GPR.Objects.DistanceX',
						unitId: `${product}.CSV.Tag objects distance X`,
						dataIndex: ['logContent', 'locationX'],
					},
					{
						title: 'App.HTML.GPR.Objects.DistanceY',
						unitId: `${product}.CSV.Tag Object Distance Y`,
					},
					{
						title: 'App.LogbookSettingsCalculatedDepth',
						unitId: `${product}.Logbook Panel.Calculated Depth`,
						dataIndex: 'calculatedDepth',
					},
					{
						title: 'App.LogbookSettingsSetDepth',
						unitId: `${product}.Logbook Panel.Set Depth`,
						dataIndex: 'setDepth',
					},
					{
						title: 'App.LogbookSettingsSetSize',
						unitId: `${product}.Logbook Panel.Set Size`,
						dataIndex: 'logContent.sizeCentimeter',
					},
					{
						title: 'App.LogbookSettingsComment',
						dataIndex: 'logContent.comment',
					},
					{
						title: 'App.LogbookSettingsName',
						dataIndex: 'uID',
					},
				].map(columnConfig => ({
					// eslint-disable-next-line no-unused-vars
					render: (text, record: MeasurementObjectWithInfo) =>
						columnConfig.unitId ? convert(text, columnConfig.unitId) : text,
					...columnConfig,
					key: columnConfig.title,
					title: columnConfig.unitId ? (
						<ConvertedUnits
							id={columnConfig.title}
							unitId={columnConfig.unitId}
							scanType={scanType}
							isMetric={isMetric}
						/>
					) : (
						<FormattedMessage id={columnConfig.title} />
					),
				}))}
				dataSource={objects}
				pagination={false}
				size="small"
				rowKey="id"
			/>
		</div>
	);
};

export default Objects;
