// @flow
import React, { Fragment } from 'react';
import { get } from 'lodash';
import { Table } from 'antd';
import { FormattedMessage } from 'react-intl';
import styles from '../../../HTMLExport.styl';

export const title = (
	<Fragment>
		<FormattedMessage id="App.HTML.PI8000.Piles.Summary" />
	</Fragment>
);

const PilesSummary = ({ data }: { data: MeasurementFullData }) => {
	const piles = get(data, 'measurement.content.piles');
	const summaries = piles.map(pile => {
		return {
			pileName: pile.name,
			expectedLength: pile.expectedLength || '-',
			positions: pile.positions.length,
			key: pile.name,
		};
	});
	return (
		<Table
			title={() => <span className={styles.sub_header}>{title}</span>}
			className={styles.table}
			dataSource={summaries}
			pagination={false}
			columns={[
				{
					title: <FormattedMessage id="App.HTML.PI8000.Piles.Summary.Pile" />,
					dataIndex: 'pileName',
					key: 'pileName',
				},
				{
					title: <FormattedMessage id="App.HTML.PI8000.Piles.Summary.Positions" />,
					dataIndex: 'positions',
					key: 'positions',
				},
				{
					title: <FormattedMessage id="App.HTML.PI8000.Piles.Summary.ExpectedLength" />,
					dataIndex: 'expectedLength',
					key: 'expectedLength',
				},
			]}
			size="small"
		/>
	);
};

export default PilesSummary;
