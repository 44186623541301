// @flow
import React, { Fragment } from 'react';
import { get, has } from 'lodash';
import { Table } from 'antd';
import { FormattedMessage } from 'react-intl';
import ConvertedUnits from 'components/shared/ConvertedUnits';
import { IsEqualOrAfterPD8050Launch } from 'constants/exportConstants';
import type { DerivedProps } from '../../../Measurement';
import styles from '../../../HTMLExport.styl';

export const title = (
	<Fragment>
		<FormattedMessage id="Proceq.ExportFieldSettings" />
		{' ­­» '}
		<FormattedMessage id="App.HTML.GPR.Settings.ImageProcessing" />
	</Fragment>
);

export const ImageProcessing = ({
	data,
	scanType,
	isMetric,
	convert,
	showTitle,
}: { data: MeasurementFullData } & DerivedProps) => {
	const isIQSAFT = get(data, 'settings.0.content.process.isIQSAFT');
	const version = get(data, 'measurement.content.info.version');

	const presets = [
		{
			label: 'App.HTML.PUNDIT.ImageProcessing.HighDefinitionFocus',
			value: isIQSAFT ? 'On' : 'Off',
		},
		{
			label: 'App.HTML.PUNDIT.ImageProcessing.FocusDepth',
			unitId: 'PUNDIT.Settings Panel.Focus Depth',
			value: get(data, 'settings.0.content.process.focusDepth'),
			enable:
				isIQSAFT && has(data, 'settings.0.content.process.focusDepth') && !IsEqualOrAfterPD8050Launch(version),
		},
		{
			label: 'App.HTML.PUNDIT.ImageProcessing.DigitalGain',
			unitId: 'PUNDIT.Settings Panel.Digital Gain',
			value: get(data, 'settings.0.content.process.digitalGain'),
		},
		{
			label: 'App.HTML.PUNDIT.ImageProcessing.SurfaceWaveCancellation',
			value: get(data, 'settings.0.content.process.surfaceWaveCancellation') ? 'On' : 'Off',
		},
		{
			label: 'App.HTML.PUNDIT.ImageProcessing.SignalZeroOffset',
			unitId: 'PUNDIT.Settings Panel.Signal Zero Offset',
			value: get(data, 'settings.0.content.process.signalZeroOffset'),
			enable: !IsEqualOrAfterPD8050Launch(version),
		},
		{
			label: 'App.HTML.PUNDIT.ImageProcessing.RawDataOffset',
			unitId: 'PUNDIT.Settings Panel.Signal Zero Offset',
			value: get(data, 'settings.0.content.process.rawDataOffset'),
			enable: IsEqualOrAfterPD8050Launch(version),
		},
		{
			label: 'App.HTML.PUNDIT.ImageProcessing.PulseVelocity',
			unitId: 'PUNDIT.Settings Panel.Pulse Velocity',
			value: get(data, 'settings.0.content.process.pulseVelocity'),
		},
	]
		.filter(row => row.enable === undefined || row.enable === true)
		.map(row => ({
			label: row.unitId ? (
				<ConvertedUnits id={row.label} unitId={row.unitId} scanType={scanType} isMetric={isMetric} />
			) : (
				<FormattedMessage id={row.label} />
			),
			value: row.unitId ? convert(row.value, row.unitId) : row.value,
			key: row.label,
		}));

	return (
		<Table
			title={showTitle ? () => <span className={styles.sub_header}>{title}</span> : null}
			className={styles.table}
			dataSource={presets}
			pagination={false}
			showHeader={false}
			size="small"
		>
			<Table.Column dataIndex="label" className={styles.column} />
			<Table.Column dataIndex="value" />
		</Table>
	);
};

export default ImageProcessing;
