// @flow
import React from 'react';
import classNames from 'classnames';
import { find } from 'lodash';
import { connect } from 'react-redux';
import { compose, withState, lifecycle } from 'recompose';
import { FormattedMessage } from 'react-intl';
import { Layout, Modal, Button, Popconfirm, Icon, Popover, Alert } from 'antd';
import URL, { isProduction } from 'apis/urls';
import { hasOneOfRoleLevel, hasRoleLevel, ROLES } from 'utils/rolesHelper';
import { getTextValue, getGlobalRegister } from 'utils/registryHelper';
import { request } from 'actions/ajax';
import moment from 'moment/moment';
import styles from './Contracts.styl';
import tableStyles from '../shared/SmarterTable.styl';
import inspectStyles from './InspectContracts.styl';
import columnConfig from './columnConfig';
import { generateFilterQuery } from '../shared/SmarterTable';
import ContractsBaseTable from './ContractsBaseTable';
import AddEditContract from './AddEditContract';
import AddEditSaasContract from './AddEditSaasContract';
import AddEditBluetoothContract from './AddEditBluetoothContract';
import ExportContracts from './ExportContracts';
import Legend from './Legend';
import AuditHistory from './AuditHistory';
import NotifyContracts from './NotifyContracts';
import {
	ContractType,
	UserTypeOptions,
	getName,
	productComparator,
	caseInsensitiveCompare,
	ConnectionTypeSoftware,
	AuditHistoryType,
	GPRFamily,
	EquotipFamily,
	SaasProds,
	SoftwareProds,
	AllProds,
	getProductFamily,
} from './contractConsts';
import colors from '../../styles/colors.json';
import type { ContractTypes } from './contractConsts';
import { DATE_SERVER_FORMAT, highlightExpiringContractsOrLicenses } from '../../utils/dateHandler';

export const getDefaultProductFilters = (contractType: ContractTypes, productFamily: ProductFamily): Array<string> => {
	switch (contractType) {
		case ContractType.Device:
			return productFamily === 'EQUOTIP' ? EquotipFamily : GPRFamily;
		case ContractType.MaintenanceServices:
			return AllProds;
		case ContractType.Software:
			return SaasProds.includes(productFamily.toUpperCase()) ? SaasProds : SoftwareProds;
		default:
			return [];
	}
};

export const generateParams = (productFamily: ProductFamily, contractType: string) => {
	if (contractType === ContractType.MaintenanceServices) {
		return generateFilterQuery('product', AllProds, 0);
	}
	if (contractType === ContractType.Software) {
		return generateFilterQuery(
			'product',
			SaasProds.includes(productFamily.toUpperCase()) ? SaasProds : SoftwareProds,
			0
		);
	}
	switch (productFamily) {
		case 'GPR':
			return generateFilterQuery('product', GPRFamily, 0);
		case 'EQUOTIP':
			return generateFilterQuery('product', EquotipFamily, 0);
		default:
			return {};
	}
};

const dispatchAllProducts = async (dispatch: Function, props) => {
	const data = await dispatch(
		request({
			method: 'GET',
			setLoading: props.setLoading,
			url: URL.GET_PRODUCT,
			params: {
				connectionType: props.connectionType,
			},
		})
	);
	return data;
};

const dispatchProductsAvailable = async (dispatch: Function, props) => {
	const data = await dispatch(
		request({
			method: 'GET',
			setLoading: props.setLoading,
			url: URL.GET_PRODUCT_AVAILABLE,
			params: {
				contractType: parseInt(props.contractType, 10),
			},
		})
	);
	return data;
};

const LICENSE_PAGE = `${window.location.origin}/contracts/inspect/licenses`;

export const Contracts = ({
	isAdmin,
	isBUOrHigher,
	contracts,
	totalRecords,
	loadContracts,
	loading,
	setParams,
	selectedRowKeys,
	onRowSelection,
	editedContract,
	changeContract,
	unlockContracts,
	deleteContracts,
	productFamily,
	products,
	loadProducts,
	tiers,
	loadTiers,
	features,
	tierOps,
	prodOps,
	connectionType,
	contractType,
	showAlert,
	setAlert,
}: {
	isAdmin: boolean,
	isBUOrHigher: boolean,
	contracts: Contract[],
	totalRecords: number,
	loadContracts: Function,
	loading: boolean,
	setParams: Function,
	selectedRowKeys: string[],
	onRowSelection: Function,
	editedContract?: string,
	changeContract: Function,
	unlockContracts: Function,
	deleteContracts: Function,
	productFamily: ProductFamily,
	products: Product[],
	loadProducts: Function,
	tiers: Tier[],
	loadTiers: Function,
	features: Object,
	tierOps: { text: string, value: any }[],
	prodOps: { text: string, value: any }[],
	connectionType: string,
	contractType: ContractTypes,
	showAlert: string,
	setAlert: Function,
}) => {
	const isSoftwareSolution =
		contractType === ContractType.Software && connectionType.toLowerCase() === ConnectionTypeSoftware.toLowerCase();

	const columnConfigType =
		contractType === ContractType.MaintenanceServices
			? 'MAINTENANCE_SERVICES'
			: isSoftwareSolution
			? 'SOFTWARE'
			: contractType === ContractType.Software
			? 'SAAS'
			: productFamily;
	const isProductSaas =
		typeof productFamily === 'string' &&
		(productFamily.toUpperCase() === 'INSPECT' || productFamily.toUpperCase() === 'GPR_INSIGHTS');
	const saasProductOptions = getGlobalRegister('saasProducts').map(product => {
		return { value: product.data, text: product.label };
	});
	const isDisplayHistory =
		contractType === ContractType.Device ||
		contractType === ContractType.MaintenanceServices ||
		isSoftwareSolution ||
		isProductSaas;

	const getExpirationDate = record =>
		moment(isProductSaas ? record.firstExpirationDate : record.expirationDate, DATE_SERVER_FORMAT);

	return (
		<Layout>
			<Layout.Content>
				{showAlert !== '' && (
					<div className={inspectStyles.alert_banner}>
						<Alert
							message={showAlert}
							description={
								<div>
									{'You can view and download the license(s) at '}
									<a href={LICENSE_PAGE}>
										<u>{'License Keys'}</u>
									</a>
									{'.'}
								</div>
							}
							type="success"
							afterClose={() => setAlert('')}
							banner
							closable
							showIcon
						/>
					</div>
				)}
				<Legend />
				<Legend legendColor={colors.light_green} legendTextId={'App.ContractsLegendSixtyOneToNinetyDays'} />
				<Button className={tableStyles.button} type="primary" loading={loading} onClick={loadContracts}>
					<FormattedMessage id="Proceq.TableActionRefresh" />
				</Button>
				{isBUOrHigher && (
					<Button className={tableStyles.button} type="primary" onClick={() => changeContract(0)}>
						<FormattedMessage id="App.ContractsAdd" />
					</Button>
				)}
				{isBUOrHigher && (productFamily === 'EQUOTIP' || contractType === ContractType.MaintenanceServices) && (
					<Popconfirm
						title={
							<FormattedMessage
								id="App.ContractsUnlockPrompt"
								values={{ length: selectedRowKeys.length }}
							/>
						}
						onConfirm={unlockContracts}
						okText={<FormattedMessage id="Proceq.TextYes" />}
						cancelText={<FormattedMessage id="Proceq.TextNo" />}
						disabled={selectedRowKeys.length < 1 || selectedRowKeys.length > 5}
					>
						<Button
							className={tableStyles.button}
							type="primary"
							disabled={selectedRowKeys.length < 1 || selectedRowKeys.length > 5}
						>
							<FormattedMessage id="App.ContractsUnlock" />
						</Button>
					</Popconfirm>
				)}
				{!isProduction && isAdmin && !isProductSaas && (
					<Popconfirm
						title={
							<FormattedMessage
								id="App.ContractsDeletePrompt"
								values={{ length: selectedRowKeys.length }}
							/>
						}
						onConfirm={deleteContracts}
						okText={<FormattedMessage id="Proceq.TextYes" />}
						cancelText={<FormattedMessage id="Proceq.TextNo" />}
						disabled={selectedRowKeys.length < 1 || selectedRowKeys.length > 5}
					>
						<Button
							className={tableStyles.button}
							type="danger"
							disabled={selectedRowKeys.length < 1 || selectedRowKeys.length > 5}
						>
							<FormattedMessage id="App.ContractsDelete" />
						</Button>
					</Popconfirm>
				)}
				{isAdmin && !isProductSaas && <NotifyContracts />}
				<ExportContracts productFamily={productFamily} contractType={contractType} />
				<ContractsBaseTable
					rowKey="id"
					columns={[
						{
							title: 'No',
							dataIndex: 'serialNo',
							render: (text: number) => text,
						},
						{
							title: 'Contract ID',
							dataIndex: 'id',
							filter: true,
							render: (text: string) => text,
						},
						{
							title: isProductSaas ? 'Primary Contact Email' : 'Proceq.FormEmailGroupID',
							dataIndex: 'contactEmail',
							filter: 'contactEmail',
							render: (text, record) => (
								<div className={styles.email_field}>
									<span className={styles.email_field_name}>{text}</span>
									&nbsp; &nbsp;
									{isBUOrHigher && (
										<Button
											className={classNames(tableStyles.button, tableStyles.inline)}
											onClick={() => changeContract(record.id)}
											data-e2e-contracts-edit-button
										>
											<Icon type="edit" />
										</Button>
									)}
									{!isProductSaas && (
										<Popover
											placement="right"
											title={<span>{'Features'}</span>}
											content={
												<div className={styles.popover_content_root}>
													{features[record.product] !== undefined &&
														features[record.product][record.tier] !== undefined &&
														features[record.product][record.tier].map(feature => {
															return (
																<div
																	key={Math.random()
																		.toString(36)
																		.substring(7)}
																	className={styles.popover_content}
																>
																	<div
																		className={
																			feature.enable === 1
																				? styles.popover_description_code
																				: styles.popover_description_code_disable
																		}
																	>
																		{`[${feature.code}]`}
																	</div>
																	<div
																		className={
																			feature.enable === 1
																				? styles.popover_description_content
																				: styles.popover_description_content_disable
																		}
																	>
																		{feature.description}
																	</div>
																</div>
															);
														})}
												</div>
											}
											trigger="click"
										>
											<Button className={classNames(tableStyles.button, tableStyles.inline)}>
												<Icon type="profile" />
											</Button>
										</Popover>
									)}
									&nbsp; &nbsp;
									{isDisplayHistory && (
										<AuditHistory
											id={record.id}
											documentType={AuditHistoryType.Contract}
											isProductSaas={isProductSaas}
										/>
									)}
								</div>
							),
							sorter: true,
						},
						isProductSaas ? columnConfig[columnConfigType][2] : columnConfig[columnConfigType][0],
						isSoftwareSolution
							? null
							: {
									title: 'Country Sold To',
									dataIndex: 'countrySoldTo',
									globalRegister: 'countries',
									filter: true,
									multiSelect: true,
							  },
						{
							title: 'User Type',
							dataIndex: 'isInternal',
							globalRegister: 'usertypeFilter',
							sorter: true,
							filter: true,
							multiSelect: true,
							render: (text: number) => getName(UserTypeOptions, text),
						},
						{
							title: 'App.Product',
							dataIndex: 'product',
							filter: true,
							multiSelect: true,
							render: (text, record) => {
								const selected = products.filter(p => {
									return caseInsensitiveCompare(p.code, record.product);
								});
								text = selected.length > 0 ? selected[0].name : text;
								return (
									<div>
										<span>{text}</span>
									</div>
								);
							},
							sorter: true,
							filterOps: isProductSaas ? saasProductOptions : prodOps,
						},
						isProductSaas
							? null
							: {
									title: 'App.ProductType',
									dataIndex: 'tier',
									filter: true,
									multiSelect: true,
									render: (text, record) => {
										const selected = tiers.filter(
											t => t.product === record.product && t.tier === text
										);
										text = selected.length > 0 ? selected[0].name : text;
										return (
											<div>
												<span>{text}</span>
											</div>
										);
									},
									sorter: true,
									filterOps: tierOps,
							  },
						...columnConfig[columnConfigType].slice(3, columnConfig[columnConfigType].length),
					].filter(a => a !== null)}
					onRow={record => highlightExpiringContractsOrLicenses(record, getExpirationDate(record), true)}
					dataSource={contracts}
					loadDataSource={loadContracts}
					loading={loading}
					setParams={setParams}
					selectedRowKeys={selectedRowKeys}
					onRowSelection={onRowSelection}
					maxRowSelection={5}
					totalRecords={totalRecords}
					loadTiers={loadTiers}
					defaultFilters={{
						product: getDefaultProductFilters(contractType, productFamily),
						type: [contractType],
					}}
				/>
			</Layout.Content>
			{isProductSaas && (
				<Modal
					visible={editedContract !== null}
					className={inspectStyles.modal_inspect}
					footer={null}
					onCancel={() => changeContract(null)}
					destroyOnClose
					title={
						<FormattedMessage
							id={
								find(contracts, { id: editedContract })
									? 'App.ContractsEditSaas'
									: 'App.ContractsAddSaas'
							}
						/>
					}
				>
					<AddEditSaasContract
						contract={find(contracts, { id: editedContract })}
						changeContract={changeContract}
						setAlert={setAlert}
						loadContracts={loadContracts}
						products={products}
						loadProducts={loadProducts}
						prodConnectionType={connectionType}
						contractType={contractType}
					/>
				</Modal>
			)}

			{!isProductSaas && (
				<Modal
					className={inspectStyles.modal_inspect}
					visible={editedContract !== null}
					footer={null}
					onCancel={() => changeContract(null)}
					destroyOnClose
					title={
						<FormattedMessage
							id={find(contracts, { id: editedContract }) ? 'App.ContractsEdit' : 'App.ContractsAdd'}
						/>
					}
				>
					{productFamily === 'EQUOTIP' ? (
						<AddEditBluetoothContract
							contract={find(contracts, { id: editedContract })}
							changeContract={changeContract}
							loadContracts={loadContracts}
							products={products}
							loadProducts={loadProducts}
							prodConnectionType={connectionType}
							contractType={contractType}
						/>
					) : (
						<AddEditContract
							contract={find(contracts, { id: editedContract })}
							changeContract={changeContract}
							loadContracts={loadContracts}
							products={products}
							loadProducts={loadProducts}
							prodConnectionType={connectionType}
							contractType={contractType}
						/>
					)}
				</Modal>
			)}
		</Layout>
	);
};

export default compose(
	withState('selectedRowKeys', 'onRowSelection', []),
	withState('editedContract', 'changeContract', null),
	withState('showAlert', 'setAlert', ''),
	withState('loading', 'setLoading', true),
	withState('contracts', 'setContracts', []),
	withState('totalRecords', 'setTotalRecords', 0),
	withState('params', 'setParams'),
	withState('products', 'setProducts', []),
	withState('tiers', 'setTiers', []),
	withState('features', 'setFeatures', {}),
	withState('tierOps', 'setTierOps', []),
	withState('prodOps', 'setProdOps', []),

	connect(
		state => ({
			isAdmin: hasRoleLevel(state.session.scopes, ROLES.ADMIN),
			isBUOrHigher: hasOneOfRoleLevel(state.session.scopes, [ROLES.BU, ROLES.PO, ROLES.ADMIN, ROLES.SUPER]),
		}),
		(dispatch: Function, props) => ({
			loadContracts: async () => {
				const href = window.location.href;
				let contractId = '';
				if (href.includes('contractId=')) {
					const contractInfo = href.split('=');
					contractId = contractInfo[1];
				}
				const data = await dispatch(
					request({
						url: URL.GET_CONTRACTS,
						setLoading: props.setLoading,
						params: {
							...props.params,
							product: undefined,
							contractId,
						},
					})
				);

				let serialCounter = props.params.start;
				const contractList = data.data.map(entry => {
					serialCounter++;
					return { serialNo: serialCounter, ...entry };
				});
				props.setContracts(contractList);
				props.setTotalRecords(data.recordsFiltered);
				const contractUrl = href.split('?');
				window.history.replaceState({}, document.title, contractUrl[0]);
			},
			loadProducts: async (callback: Function) => {
				const options = [];
				const data =
					props.contractType === ContractType.MaintenanceServices ||
					props.contractType === ContractType.Software
						? await dispatchProductsAvailable(dispatch, props)
						: await dispatchAllProducts(dispatch, props);
				const saasProds = getTextValue(['saasProducts']).map(({ value }) => value.toUpperCase());
				data.forEach(product => {
					const newOption = { value: product.code, text: product.name };
					if (
						props.contractType === ContractType.MaintenanceServices ||
						props.contractType === ContractType.Software ||
						props.connectionType === product.connectionType
					) {
						if (props.contractType === ContractType.MaintenanceServices) {
							options.push(newOption);
						} else if (props.contractType === ContractType.Software) {
							if (
								!saasProds.includes(product.code.toUpperCase()) &&
								props.connectionType === product.connectionType
							) {
								options.push(newOption);
							}
						} else {
							options.push(newOption);
						}
					}
				});

				options.sort(productComparator('text'));
				props.setProdOps(options);
				props.setProducts(data, () => {
					if (typeof callback === 'function') {
						callback();
					}
				});
			},
			loadTiers: () => {
				dispatch(
					request({
						method: 'GET',
						url: URL.GET_TIERS,
						setLoading: props.setLoading,
						params: {
							start: 0,
							length: 200,
						},
					})
				).then(data => {
					let tierOptions = [];
					if (data.data !== undefined && data.data.length > 0) {
						tierOptions = data.data
							.filter(
								tier =>
									(props.contractType === undefined ||
										props.contractType === '' ||
										tier.contractType === parseInt(props.contractType, 10)) &&
									(!props.productFamily ||
										props.productFamily.toLowerCase() ===
											getProductFamily(tier.product, tier.contractType))
							)
							.map(tier => {
								return { value: tier.tier, text: tier.name };
							})
							.reduce((acc, curr) => {
								const found = acc.find(a => a.text === curr.text);
								if (found) {
									found.value = [...found.value, curr.value];
								} else {
									acc.push({ ...curr, value: [curr.value] });
								}
								return acc;
							}, []);
					}
					props.setTierOps(tierOptions);
					props.setTiers(data.data);
				});
			},
			loadAllTierFeatures: () => {
				dispatch(
					request({
						method: 'GET',
						url: URL.GET_ALL_TIER_FEATURES,
						setLoading: props.setLoading,
						params: {
							...props.params,
						},
					})
				).then(data => {
					props.setFeatures(data);
				});
			},
		})
	),
	connect(null, (dispatch: Function, props) => ({
		unlockContracts: () => {
			dispatch(
				request({
					method: 'POST',
					url: URL.UNLOCK_CONTRACTS,
					setLoading: props.setLoading,
					params: {
						ids: props.selectedRowKeys,
					},
				})
			).then(() => {
				props.loadContracts();
				props.onRowSelection([]);
			});
		},
		deleteContracts: () => {
			dispatch(
				request({
					method: 'DELETE',
					url: URL.DELETE_CONTRACTS,
					setLoading: props.setLoading,
					params: {
						ids: props.selectedRowKeys,
					},
				})
			).then(() => {
				props.loadContracts();
				props.onRowSelection([]);
			});
		},
	})),
	lifecycle({
		componentDidMount() {
			this.props.setParams({
				start: 0,
				length: 20,
				...generateParams(this.props.productFamily, this.props.contractType),
				...generateFilterQuery('type', this.props.contractType, 1),
			});
			setTimeout(() => {
				const self = this;
				this.props.loadProducts(() => {
					self.props.loadContracts();
					self.props.loadTiers();
					self.props.loadAllTierFeatures();
				});
			});
		},
	})
)(Contracts);
