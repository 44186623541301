// @flow
import React from 'react';
import { FormattedMessage } from 'react-intl';

export const ImportFile = ({ log }: GPRLogbookEntryProps) => (
	<FormattedMessage
		id="App.LogbookImported"
		values={{
			comment: log.content.comment,
		}}
	/>
);

export default ImportFile;
