import React from 'react';
import moment from 'moment';
import { formatDateTimeNoSecsToLocale } from 'utils/dateHandler';
import styles from '../shared/SmarterTable.styl';
import Download from './Download';
import Delete from './Delete';

const renderTime = text => (
	<span className={styles.date_cell}>{formatDateTimeNoSecsToLocale(moment.utc(text).local())}</span>
);

const renderFilename = (text, record) => (
	<div>
		<span style={{ width: 220, display: 'inline-block' }}>{text}</span>
		<Download productCode={record.productCode} firmwareVersion={record.version} />
		<Delete record={record} />
	</div>
);

const columnConfig = [
	{
		title: 'App.FirmwareProductCode',
		dataIndex: 'productCode',
		filter: 'productCode',
		sorter: true,
	},
	{
		title: 'App.FirmwareFilename',
		dataIndex: 'fileName',
		sorter: true,
		filter: 'fileName',
		render: renderFilename,
	},
	{
		title: 'App.FirmwareVersion',
		dataIndex: 'version',
		sorter: true,
	},
	// {
	// 	title: 'App.FirmwareMajorVersion',
	// 	dataIndex: 'majorVersion',
	// 	sorter: true,
	// },
	// {
	// 	title: 'App.FirmwareMinorVersion',
	// 	dataIndex: 'minorVersion',
	// 	sorter: true,
	// },
	// {
	// 	title: 'App.FirmwarePatchVersion',
	// 	dataIndex: 'patchVersion',
	// 	sorter: true,
	// },
	{
		title: 'App.FirmwareType',
		dataIndex: 'type',
		sorter: true,
		globalRegister: 'firmwaretypes',
		filter: true,
		multiSelect: true,
	},
	{
		title: 'App.FirmwareSize',
		dataIndex: 'size',
		sorter: true,
	},
	{
		title: 'App.FirmwareHash',
		dataIndex: 'sha256',
		filter: 'sha256',
	},
	// {
	// 	title: 'App.FirmwareLink',
	// 	dataIndex: 'url',
	// 	sorter: true,
	// },
	{
		title: 'App.DongleCreated',
		dataIndex: 'created',
		sorter: true,
		render: renderTime,
	},
	// {
	// 	title: 'App.DongleUpdated',
	// 	dataIndex: 'updated',
	// 	sorter: true,
	// 	render: renderTime,
	// },
];

export default columnConfig;
