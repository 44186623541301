// @flow
import React from 'react';
import classNames from 'classnames';
import moment from 'moment';
import { connect } from 'react-redux';
import { compose, withState, withProps, withStateHandlers } from 'recompose';
import { FormattedMessage } from 'react-intl';
import { Modal, Tabs, Input, Button } from 'antd';
import Inspector from 'react-json-inspector';
import 'react-json-inspector/json-inspector.css';
import URL from 'apis/urls';
import styles from 'components/shared/SmarterTable.styl';
import { request } from 'actions/ajax';
import { getFileNameFromContentDisposition } from 'utils/downloadHelper';

export const JSONTreeView = ({
	visible,
	openModal,
	closeModal,
	loading,
	output,
	download,
}: {
	visible: boolean,
	openModal: Function,
	closeModal: Function,
	loading: boolean,
	output: string,
	download: Function,
}) => (
	<div className={classNames(styles.button, styles.inline)}>
		<Modal
			visible={visible}
			onOk={closeModal}
			onCancel={closeModal}
			title={<FormattedMessage id="App.MeasurementDebugJSONTitle" />}
			footer={[
				<Button type="primary" key="download" size="large" onClick={download}>
					<FormattedMessage id="Proceq.LogbookDownload" />
				</Button>,
				<Button key="back" size="large" onClick={closeModal}>
					<FormattedMessage id="Proceq.LogbookClose" />
				</Button>,
			]}
		>
			<Tabs defaultActiveKey="1">
				<Tabs.TabPane tab="JSON Navigator" key="1">
					<Inspector
						data={output}
						id={null}
						filterOptions={{ ignoreCase: true }}
						search={props => (
							<Input onChange={(e: any) => props.onChange(e.target.value)} placeholder="Filter" />
						)}
						validateQuery={() => true}
					/>
				</Tabs.TabPane>
				<Tabs.TabPane tab="Raw JSON Output" key="2">
					<Input.TextArea readOnly value={JSON.stringify(output, null, 4)} autosize />
				</Tabs.TabPane>
			</Tabs>
		</Modal>
		<Button onClick={openModal} loading={loading} data-e2e-json-button>
			{!loading && 'JSON'}
		</Button>
	</div>
);

export const JSONTreeViewWithState = compose(
	withStateHandlers(
		{
			visible: false,
		},
		{
			openModal: () => () => ({ visible: true }),
			closeModal: () => () => ({ visible: false }),
		}
	)
)(JSONTreeView);

export default compose(
	withState('visible', 'setVisible', false),
	withState('loading', 'setLoading', false),
	withState('output', 'setOutput', ''),
	connect(null, (dispatch: Function, props) => ({
		loadMeasurement: () => {
			dispatch(
				request({
					url: URL.MEASUREMENTS + props.mID,
					setLoading: props.setLoading,
					params: {
						withunsynced: props.withunsynced,
					},
				})
			).then(data => {
				props.setOutput(data);
				props.setVisible(true);
			});
		},
		downloadJSON: () => {
			dispatch(
				request({
					method: 'POST',
					url: URL.EXPORT_REPORTS,
					params: {
						product: props.output.productFamily,
						fileType: 'measurement',
						format: 'json',
						iDs: [props.mID],
						timeZoneOffset: moment().utcOffset(),
					},
					setLoading: props.setLoading,
					returnResponseHeaders: true,
					options: {
						responseType: 'blob',
					},
				})
			).then(response =>
				window.fileDownload(
					response.data,
					getFileNameFromContentDisposition(response.headers['content-disposition'] || ''),
					response.headers['content-type']
				)
			);
		},
	})),
	withProps(({ setVisible, setLoading, output, loadMeasurement, downloadJSON }) => ({
		openModal: () => {
			if (output === '') {
				loadMeasurement();
			} else {
				setVisible(true);
			}
		},
		closeModal: () => {
			setLoading(false);
			setVisible(false);
		},
		download: () => {
			downloadJSON();
		},
	}))
)(JSONTreeView);
