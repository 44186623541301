// @flow
import React from 'react';
import { get } from 'lodash';
import moment from 'moment';
import { Form, Input, InputNumber, Button } from 'antd';
import type { FormProps } from 'antd/lib/form';
import URL from 'apis/urls';
import { connect } from 'react-redux';
import { compose, withState, lifecycle, pure } from 'recompose';
import type { InjectIntlProvidedProps } from 'react-intl';
import { FormattedMessage, injectIntl } from 'react-intl';
import Select from 'components/shared/SmarterFormItems/Select';
import DatePicker from 'components/shared/SmarterFormItems/DatePicker';
import styles from 'components/shared/SmarterTable.styl';
import { request } from 'actions/ajax';
import { getTextValue } from 'utils/registryHelper';
import { hasRoleLevel, isBUOrHigher, ROLES } from 'utils/rolesHelper';
import licenseStyles from './InspectContracts.styl';
import { emailRegex } from '../users/AddEditUser';
import { productComparator } from './contractConsts';
import { isProductOrTierDisabled } from '../../utils/productUtils';

export const AddEditFreeTrial = pure(
	({
		loading,
		form,
		contract,
		changeFreeTrialLicense,
		// loadFreeTrialLicenses,
		isAdmin,
		buOrHigher,
		addContract,
		editContract,
		products,
		prodOptions,
		setCurrProd,
		contractModified,
		intl: { formatMessage },
	}: {
		loading: boolean,
		form: FormProps,
		contract?: License,
		changeFreeTrialLicense: Function,
		// loadFreeTrialLicenses: Function,
		isAdmin: boolean,
		buOrHigher: boolean,
		addContract: Function,
		editContract: Function,
		products: Product[],
		prodOptions: SelectOpts[],
		setCurrProd: Function,
		contractModified: boolean,
	} & InjectIntlProvidedProps) => {
		const onSubmit = e => {
			e.preventDefault();
			form.validateFields((err, values) => {
				if (err) {
					return;
				}

				if (values.expirationDate) {
					values.expirationDate = values.expirationDate.format('YYYY-MM-DD');
				}

				if (values.serialNo) {
					values.serialNo = values.serialNo.toUpperCase();
				}

				(contract ? editContract : addContract)(values);
			});
		};

		const onProductChange = params => {
			setCurrProd(params);
			const duration = form.getFieldValue('durationInDays') || 30;
			form.setFieldsValue({ durationInDays: duration });
		};
		const onDurationChange = v => {
			form.setFieldsValue({ durationInDays: v });
		};
		const onDateChange = v => {
			form.setFieldsValue({ expirationDate: v });
		};

		const isCreateMode = contract === undefined || contract === null;
		const nonDisabledProdOptions = prodOptions.filter(prodOption => prodOption.tierEnabled);
		const productDisabled =
			!isCreateMode && !buOrHigher
				? isProductOrTierDisabled(prodOptions, form.getFieldValue('product'), true)
				: false;

		return (
			<Form onSubmit={onSubmit}>
				<div>
					{contract && isAdmin && (
						<Form.Item label={<FormattedMessage id="License Key" />} className={styles.field}>
							<span>{contract.key}</span>
						</Form.Item>
					)}
				</div>
				<div>
					<Form.Item label={<FormattedMessage id="Product" />} className={styles.half_field}>
						{products !== undefined &&
							form.getFieldDecorator('product', {
								rules: [{ required: true }],
							})(
								<Select
									selectOpt={nonDisabledProdOptions}
									onChange={onProductChange}
									disabled={!isCreateMode || productDisabled}
								/>
							)}
					</Form.Item>
				</div>
				<Form.Item label={<FormattedMessage id="Proceq.FormEagleID" />} className={styles.field}>
					{form.getFieldDecorator('email', {
						rules: [
							{
								required: true,
								pattern: emailRegex,
								message: formatMessage({ id: 'Proceq.ErrorWrongUsername' }),
							},
						],
					})(<Input disabled={!isCreateMode || productDisabled} />)}
				</Form.Item>
				{contract && (
					<Form.Item label={<FormattedMessage id="Proceq.DeviceID" />} className={styles.field}>
						{form.getFieldDecorator('deviceId', {})(<Input disabled />)}
					</Form.Item>
				)}
				<div>
					{!contract && (
						<Form.Item
							label={<FormattedMessage id="Proceq.FormFreeTrialDuration" />}
							className={styles.half_field}
						>
							{form.getFieldDecorator('durationInDays', {
								rules: [{ required: !contract }],
							})(
								<div className={licenseStyles.durationWrap}>
									<InputNumber defaultValue={30} onChange={onDurationChange} />
									<div className={licenseStyles.durationYears}>{'days'}</div>
								</div>
							)}
						</Form.Item>
					)}
					{contract && (
						<Form.Item
							label={<FormattedMessage id="Proceq.FormExpirationDate" />}
							className={styles.half_field}
						>
							{form.getFieldDecorator('expirationDate', {
								rules: [{ required: true }],
							})(
								<DatePicker
									onChange={onDateChange}
									showRemainingDays
									showRemainingText="TextRemainingXDays"
									disabled={productDisabled}
								/>
							)}
						</Form.Item>
					)}
					<Form.Item label={<FormattedMessage id="Proceq.FormAccountTypeBU" />} className={styles.half_field}>
						{form.getFieldDecorator('businessUnit', {
							rules: [{ required: true }],
						})(<Select globalRegister="buunits" disabled={productDisabled} />)}
					</Form.Item>
				</div>
				<Form.Item className={styles.submit}>
					<Button type="default" onClick={() => changeFreeTrialLicense(null)}>
						<FormattedMessage id="Proceq.LogbookClose" />
					</Button>
					&nbsp;
					{contractModified && (
						<Button type="primary" htmlType="submit" loading={loading}>
							<FormattedMessage id="Proceq.ButtonSubmit" />
						</Button>
					)}
					{!contractModified && (
						<Button disabled type="primary" htmlType="submit" loading={loading}>
							<FormattedMessage id="Proceq.ButtonSubmit" />
						</Button>
					)}
				</Form.Item>
			</Form>
		);
	}
);

export default compose(
	Form.create({
		mapPropsToFields: props => {
			const fields = {};
			const fieldKeys = ['email', 'product', 'expirationDate', 'durationInDays', 'businessUnit'];
			fieldKeys.forEach(k => {
				let value = get(props, ['contract', k]);
				if (k === 'expirationDate') {
					value = value ? moment(value) : null;
				}
				if (k === 'email') {
					value = get(props, ['contract', 'activeUser']);
				}
				fields[k] = Form.createFormField({ value });
			});
			return fields;
		},
	}),
	withState('loading', 'setLoading', false),
	withState('prodOptions', 'setProdOptions', []),
	withState('currProd', 'setCurrProd', ''),
	withState('prevContract', 'setPrevContract', ''),
	withState('contractModified', 'setContractModified', false),

	connect(
		state => ({
			isAdmin: hasRoleLevel(state.session.scopes, ROLES.ADMIN),
			buOrHigher: isBUOrHigher(state.session.scopes),
		}),
		(dispatch: Function, props) => ({
			addContract: (params: { isCareRedeemed?: any, isInternal?: any, product: string }) => {
				const url = URL.CREATE_FREE_TRIAL_LICENSE.replace('{product}', params.product.toLocaleLowerCase());
				dispatch(
					request({
						method: 'POST',
						url,
						setLoading: props.setLoading,
						params: {
							...params,
						},
					})
				).then(
					() => {
						props.changeFreeTrialLicense(null);
						props.loadFreeTrialLicenses();
					},
					error => {
						// eslint-disable-next-line no-console
						console.log(error);
					}
				);
			},
			editContract: (params: { isCareRedeemed?: any, isInternal?: any }) => {
				dispatch(
					request({
						method: 'PUT',
						url: URL.UPDATE_LICENSE.replace('{key}', props.contract.id),
						setLoading: props.setLoading,
						params: {
							expirationDate: get(params, 'expirationDate'),
							businessUnit: get(params, 'businessUnit'),
						},
					})
				).then(response => {
					if (response === '') {
						props.changeFreeTrialLicense(null);
						props.loadFreeTrialLicenses();
					}
				});
			},
			loadAvailableProducts: () => {
				dispatch(
					request({
						method: 'GET',
						url: URL.GET_PRODUCT_AVAILABLE,
						setLoading: props.setLoading,
						params: {
							contractType: parseInt(props.contractType, 10),
						},
					})
				).then(data => {
					const options = [];
					const saasProds = getTextValue(['freeTrialSaasProducts']).map(({ value }) => value.toUpperCase());
					if (data !== undefined && data !== null) {
						data.map(prod => {
							if (saasProds.includes(prod.code.toUpperCase())) {
								const obj = {
									name: prod.name,
									value: prod.code,
									tierEnabled: prod.tierEnabled,
								};
								options.push(obj);
							}
						});
					}
					options.sort(productComparator('name'));
					props.setProdOptions(options);
				});
			},
		})
	),
	lifecycle({
		componentDidMount() {
			this.props.loadAvailableProducts();
			this.props.setContractModified(false);
			this.props.setPrevContract(this.props.contract);
		},
		componentDidUpdate(prevProps) {
			const compare = (o1, o2) => {
				return o1 === o2 || (o1 === undefined && o2 === '') || (o1 === '' && o2 === undefined);
			};
			const formExpiration =
				this.props === undefined || this.props.form === undefined
					? undefined
					: this.props.form.getFieldValue('expirationDate');
			const prevExpiration =
				this.props === undefined || this.props.prevContract === undefined
					? undefined
					: this.props.prevContract.expirationDate;
			if (
				this.props !== undefined &&
				this.props.prevContract !== undefined &&
				this.props.contract !== undefined &&
				compare(this.props.prevContract.contactEmail, this.props.form.getFieldValue('contactEmail')) &&
				compare(this.props.prevContract.product, this.props.form.getFieldValue('product')) &&
				(((formExpiration === null || formExpiration === undefined) &&
					(prevExpiration === null || prevExpiration === undefined || prevExpiration === '')) ||
					(formExpiration !== null &&
						compare(
							this.props.prevContract.expirationDate,
							this.props.form.getFieldValue('expirationDate').format('YYYY-MM-DD')
						))) &&
				compare(this.props.prevContract.businessUnit, this.props.form.getFieldValue('businessUnit'))
			) {
				if (this.props.contractModified === true) {
					this.props.setContractModified(false);
				}
			} else if (this.props.contractModified === false) {
				this.props.setContractModified(true);
			}
			if (
				prevProps !== undefined &&
				this.props !== undefined &&
				this.props.form.getFieldValue('product') !== undefined
			) {
				if (
					(prevProps.currProd === '' && this.props.form.getFieldValue('product') !== '') ||
					prevProps.currProd !== this.props.form.getFieldValue('product')
				) {
					this.props.setCurrProd(this.props.form.getFieldValue('product'));
				}
			}
		},
	}),
	injectIntl
)(AddEditFreeTrial);
