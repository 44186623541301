// @flow
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { compose, withState } from 'recompose';
import type { InjectIntlProvidedProps } from 'react-intl';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Button, Form, Input, Modal, Select, message } from 'antd';
import type { FormComponentProps } from 'antd';
import URL from 'apis/urls';
import { request } from 'actions/ajax';
import { emailRegex } from '../users/AddEditUser';
import styles from '../shared/SmarterTable.styl';

export const NotifyContracts = ({
	loading,
	visible,
	setVisible,
	notifyContract,
	form,
	intl,
}: {
	loading: boolean,
	visible: boolean,
	setVisible: Function,
	notifyContract: Function,
} & InjectIntlProvidedProps &
	FormComponentProps) => (
	<Fragment>
		<Button className={styles.button} onClick={() => setVisible(true)} data-e2e-contracts-notify-button>
			<FormattedMessage id="App.ContractsNotify" />
		</Button>
		<Modal visible={visible} footer={null} onCancel={() => setVisible(false)}>
			<Form onSubmit={notifyContract}>
				<Form.Item label={<FormattedMessage id="App.ContractsNotifyDaysTillExpire" />}>
					{form.getFieldDecorator('daysTillExpire', {
						initialValue: 60,
						rules: [{ required: true }],
					})(<Input type="number" />)}
				</Form.Item>
				<Form.Item label={<FormattedMessage id="App.ContractsNotifyAdditionalReceivers" />}>
					{form.getFieldDecorator('additionalReceivers', {
						rules: [
							{
								validator: (rule, value, callback) => {
									const result = (value || []).reduce(
										(flag, email) => flag && emailRegex.exec(email),
										true
									);

									if (result) {
										callback();
									} else {
										callback(intl.formatMessage({ id: 'Proceq.ErrorWrongUsername' }));
									}
								},
							},
						],
					})(<Select mode="tags" notFoundContent="" />)}
				</Form.Item>
				<Form.Item className={styles.submit}>
					<Button type="default" onClick={() => setVisible(null)}>
						<FormattedMessage id="Proceq.LogbookClose" />
					</Button>
					&nbsp;
					<Button type="primary" htmlType="submit" loading={loading}>
						<FormattedMessage id="Proceq.ButtonSubmit" />
					</Button>
				</Form.Item>
			</Form>
		</Modal>
	</Fragment>
);

export default compose(
	withState('loading', 'setLoading', false),
	withState('visible', 'setVisible', false),
	Form.create({}),
	injectIntl,
	connect(null, (dispatch: Function, props) => ({
		notifyContract: e => {
			e.preventDefault();

			props.form.validateFields((err, values) => {
				if (err) {
					return;
				}

				dispatch(
					request({
						method: 'POST',
						url: URL.NOTIFY_CONTRACT_EXPIRY,
						setLoading: props.setLoading,
						params: {
							additionalReceivers: values.additionalReceivers,
							daysTillExpire: parseInt(values.daysTillExpire, 10),
						},
					})
				).then(() => {
					message.success(props.intl.formatMessage({ id: 'Proceq.ErrorDisplayTitleSuccess' }));
					props.setVisible(false);
				});
			});
		},
	}))
)(NotifyContracts);
