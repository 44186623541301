// @flow
import qs from 'qs';
import { trim } from 'lodash';
import { useSelector } from 'react-redux';

export default function useIsAuthenticated(myLocation: any = location): boolean {
	const userID = useSelector(state => state.session.userID);
	const { sh, k, d } = qs.parse(trim(myLocation.search, '?'));
	return sh || !!userID || (k && d);
}
