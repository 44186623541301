// @flow
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { Popconfirm, message } from 'antd';
import { FormattedMessage, injectIntl } from 'react-intl';
import URL from 'apis/urls';
import { request } from 'actions/ajax';

export const UnblockUser = ({ email, unblockUser }: { email: string, unblockUser: Function }) => (
	<Popconfirm
		title={<FormattedMessage id="App.UsersUnblockPrompt" values={{ email }} />}
		onConfirm={unblockUser}
		okText={<FormattedMessage id="Proceq.TextYes" />}
		cancelText={<FormattedMessage id="Proceq.TextNo" />}
	>
		<div style={{ width: '100%', height: '100%' }}>
			<FormattedMessage id="App.UsersUnblock" />
		</div>
	</Popconfirm>
);

export default compose(
	injectIntl,
	connect(null, (dispatch: Function, props) => ({
		unblockUser: () => {
			dispatch(
				request({
					method: 'POST',
					url: URL.UNBLOCK_ACCOUNT,
					params: {
						product: null,
						userIDs: [props.id],
					},
				})
			).then(() => {
				props.loadUsers();
				message.success(
					props.intl.formatMessage(
						{
							id: 'App.UsersUnblockSuccess',
						},
						{ email: props.email }
					)
				);
			});
		},
	}))
)(UnblockUser);
