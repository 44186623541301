// @flow
import React from 'react';
import moment from 'moment';
import { compose, withState } from 'recompose';
import { connect } from 'react-redux';
import { Form, Card, Alert, Table, Input, Button } from 'antd';
import { FormattedMessage, injectIntl } from 'react-intl';
import type { InjectIntlProvidedProps } from 'react-intl';
import type { FormProps } from 'antd/lib/form';
import { request } from 'actions/ajax';
import URL from 'apis/urls';
import { getLabelFromGlobalDataKey } from 'utils/registryHelper';
import columnConfig from './columnConfig';

export const Import = ({
	importMeasurements,
	form,
	intl,
	loading,
	failed,
	succeeded,
	product,
}: {
	importMeasurements: Function,
	form: FormProps,
	loading: boolean,
	failed: ImportResponse[],
	succeeded: ImportResponse[],
	product: ProductCode,
} & InjectIntlProvidedProps) => (
	<div>
		<Form onSubmit={importMeasurements(form)}>
			<Form.Item
				style={{ marginTop: '8px' }}
				label={intl.formatMessage({
					id: 'App.DevTools.Import.Label',
				})}
			>
				{form.getFieldDecorator('url', {
					rules: [{ required: true }],
				})(<Input />)}
			</Form.Item>
			<Button type="primary" htmlType="submit" loading={loading}>
				<FormattedMessage id="App.DevTools.Import.Button" />
			</Button>
		</Form>
		&nbsp;
		{failed.length > 0 && (
			<Card
				title={
					<Alert
						message={
							<FormattedMessage id="App.DevTools.Import.Failed" values={{ failed: failed.length }} />
						}
						type="error"
					/>
				}
			>
				<Table rowKey="oldID" columns={columnConfig} dataSource={failed} pagination={false} />
			</Card>
		)}
		&nbsp;
		{succeeded.length > 0 && (
			<Card
				title={
					<Alert
						message={
							<FormattedMessage
								id="App.DevTools.Import.Success"
								values={{
									succeeded: succeeded.length,
									product: getLabelFromGlobalDataKey('availproductsfull', product),
								}}
							/>
						}
						type="success"
					/>
				}
			>
				<Table rowKey="oldID" columns={columnConfig} dataSource={succeeded} pagination={false} />
			</Card>
		)}
	</div>
);

export default compose(
	withState('loading', 'setLoading', false),
	withState('failed', 'setFailedResults', []),
	withState('succeeded', 'setSucceededResults', []),
	withState('product', 'setProduct', null),
	connect(null, (dispatch: Function, props) => ({
		importMeasurements: form => e => {
			e.preventDefault();
			form.validateFields((err, values) => {
				if (err) {
					return;
				}

				props.setSucceededResults([]);
				props.setFailedResults([]);

				dispatch(
					request({
						method: 'POST',
						url: URL.IMPORT_MEASUREMENTS,
						setLoading: props.setLoading,
						params: {
							url: values.url,
							timeZoneOffset: moment().utcOffset(),
						},
					})
				).then(data => {
					props.setFailedResults(data.failed);
					props.setSucceededResults(data.succeeded);
					props.setProduct(data.productFamily);
				});
			});
		},
	})),
	Form.create({}),
	injectIntl
)(Import);
