// @flow
import React from 'react';
import { get } from 'lodash';
import moment from 'moment';
import { Form, Input, Icon, Button, InputNumber, DatePicker } from 'antd';
import type { FormProps } from 'antd/lib/form';
import URL from 'apis/urls';
import { connect } from 'react-redux';
import { compose, withState, lifecycle, pure } from 'recompose';
import type { InjectIntlProvidedProps } from 'react-intl';
import { FormattedMessage, injectIntl } from 'react-intl';
import Select from 'components/shared/SmarterFormItems/Select';
import styles from 'components/shared/SmarterTable.styl';
import { request } from 'actions/ajax';
import classNames from 'classnames';
import { getTextValue } from 'utils/registryHelper';
import { isBUOrHigher } from 'utils/rolesHelper';
import { v4 as uuidv4 } from 'uuid';
import { getFileNameFromContentDisposition } from 'utils/downloadHelper';
import licenseStyles from './InspectContracts.styl';
import { emailRegex } from '../users/AddEditUser';
import { LicenseStatus, UserTypeOptions, UserTypeInternal, getName, ContractType, getValue } from './contractConsts';
import { isProductOrTierDisabled } from '../../utils/productUtils';

let id = 0;
let lids = [];
let licenseStatus = [];
let lts = [];
let lds = [];
let lau = [];
let deviceIDs = [];
let licenseExpirationDate = [];
const resetDeviceIDs = [];
const today = moment().format('YYYY-MM-DD');

const freeTierIds = [];
const editSuccessMessage = 'The contract is updated successfully!';
const addSuccessMessage = 'The contract is created successfully!';

export const downloadLicensesPdf = (dispatch: Function, props: any, params: any, contractId: any) => {
	const url =
		params === null ? URL.DOWNLOAD_LICENSES_BY_CONTRACT_ID.replace('{key}', contractId) : URL.DOWNLOAD_LICENSES;
	dispatch(
		request({
			method: 'POST',
			url,
			setLoading: props.setLoading,
			returnResponseHeaders: true,
			options: {
				responseType: 'blob',
			},
			params,
		})
	).then(response =>
		window.fileDownload(
			response.data,
			getFileNameFromContentDisposition(response.headers['content-disposition'] || ''),
			response.headers['content-type']
		)
	);
};

export const AddEditSaasContract = pure(
	({
		loading,
		form,
		contract,
		changeContract,
		addContract,
		editContract,
		products,
		prodOptions,
		tierOptions,
		setCurrProd,
		contractModified,
		userType,
		buOrHigher,
		containsFreeTier,
		intl: { formatMessage },
	}: {
		loading: boolean,
		form: FormProps,
		contract?: Contract,
		changeContract: Function,
		addContract: Function,
		editContract: Function,
		products: Product[],
		prodOptions: SelectOpts[],
		tierOptions: SelectOpts[],
		setCurrProd: Function,
		contractModified: boolean,
		userType: String,
		buOrHigher: boolean,
		containsFreeTier: boolean,
	} & InjectIntlProvidedProps) => {
		const isCreateMode = contract === undefined;
		const nonDisabledProdOptions = prodOptions.filter(prodOption => prodOption.tierEnabled);
		const tierDisabled =
			!isCreateMode && !buOrHigher
				? isProductOrTierDisabled(prodOptions, form.getFieldValue('product'), true)
				: false;

		const onSubmit = e => {
			e.preventDefault();
			form.validateFields((err, values) => {
				const { licenseIds, licenseTypes, duration } = values;
				values.licenseTypes = keys.map(key => licenseTypes[key]);
				values.licenseIds = keys.map(key => licenseIds[key]);
				values.licenseStatus = keys.map(key => licenseStatus[key]);
				values.resetDeviceIDs = keys.map(key => !!resetDeviceIDs[key]);
				if (isCreateMode) {
					values.duration = keys.map(key => Number(duration[key]));
				} else {
					values.licenseExpirationDate = keys.map(key => {
						const expirationDate = values.licenseExpirationDate[key]
							? values.licenseExpirationDate[key].format('YYYY-MM-DD')
							: null;
						return moment(expirationDate, 'YYYY-MM-DD', true).isValid() ? expirationDate : '';
					});
				}
				if (err) {
					return;
				}
				if (values.dueDate) {
					values.dueDate = values.dueDate.format('YYYY-MM-DD');
				}
				(contract ? editContract : addContract)(values);
			});
		};
		const remove = k => {
			const keys = form.getFieldValue('keys');
			// can use data-binding to set
			form.setFieldsValue({
				keys: keys.filter(key => key !== k),
			});
		};
		const revoke = k => {
			const keys = form.getFieldValue('keys');
			licenseStatus[k] = LicenseStatus.REVOKED;

			form.setFieldsValue({
				keys,
			});
		};

		const unlink = k => {
			const keys = form.getFieldValue('keys');
			licenseStatus[k] = LicenseStatus.ENABLED;
			deviceIDs[k] = null;
			lau[k] = null;

			form.setFieldsValue({
				keys,
			});
		};

		const add = () => {
			const keys = form.getFieldValue('keys');
			const nextKeys = keys.concat(id++);
			lids.push(uuidv4());
			lds.push(1);
			form.setFieldsValue({
				keys: nextKeys,
			});
		};

		const isInternalUser = v => {
			return Number(v) === UserTypeInternal || v === 'Internal User';
		};

		const getTierInfo = (tierID, fieldID) => {
			if (tierOptions.length > 0) {
				const tier = tierOptions.filter(option => option.value === tierID)[0];
				if (tier) {
					if (Object.prototype.hasOwnProperty.call(tier, fieldID)) {
						return tier[fieldID];
					}
				}
			}
			return undefined;
		};

		const { getFieldDecorator } = form;
		getFieldDecorator('keys', { initialValue: [] });
		const keys = form.getFieldValue('keys');
		const licenseFormItems = keys.map(k => {
			const isFreeTier = freeTierIds.includes(lts[k]);
			const isRevoked = licenseStatus[k] === LicenseStatus.REVOKED;
			const allowExpiration = getTierInfo(form.getFieldValue(`licenseTypes[${k}]`), 'allowExpiration');
			const isRequireDeviceIDForCurrentSelection = getTierInfo(lts[k], 'requireDeviceID');
			let tierSelectOptions;
			if (isFreeTier) {
				tierSelectOptions = tierOptions;
			} else {
				tierSelectOptions = tierOptions
					.filter(option => !freeTierIds.includes(option.value))
					.map(option => ({
						...option,
						...(!isRequireDeviceIDForCurrentSelection &&
						getTierInfo(option.value, 'requireDeviceID') &&
						!isCreateMode
							? {
									disable: true,
									disableText: formatMessage({ id: 'Proceq.DeviceID.Required.Notes' }),
							  }
							: {}),
					}));
			}

			const onTierTypeChange = params => {
				if (allowExpiration && !getTierInfo(params, 'allowExpiration')) {
					licenseExpirationDate[k] = null;
					form.setFieldsValue({
						[`licenseExpirationDate[${k}]`]: null,
					});
				}
				if (isRequireDeviceIDForCurrentSelection && !getTierInfo(params, 'requireDeviceID')) {
					resetDeviceIDs[k] = true;
					form.setFieldsValue({
						keys,
					});
				}
			};

			return (
				<div key={k} className={licenseStyles.wrapper}>
					<div className={licenseStyles.licenseRow}>
						<div className={licenseStyles.licenseField}>
							<div className={licenseStyles.licensesHeader}>{'License Key'}</div>
							<Form.Item required={false} key={`license-ids-${k}`}>
								{getFieldDecorator(`licenseIds[${k}]`, {
									initialValue: lids[k],
									rules: [{}],
								})(<Input className={licenseStyles.licenseID} disabled />)}
							</Form.Item>
						</div>
						{!isCreateMode && (
							<div className={licenseStyles.licenseField}>
								<br />
								<Form.Item required={false} key={`unlink-button-${k}`}>
									<Button
										className={classNames(styles.button, styles.inline, licenseStyles.revokeButton)}
										onClick={() => unlink(k)}
										disabled={licenseStatus[k] !== LicenseStatus.ACTIVATED}
									>
										{'Unlink'}
									</Button>
								</Form.Item>
							</div>
						)}
						{!isCreateMode && (
							<div className={licenseStyles.licenseField}>
								<br />
								<Form.Item required={false} key={`revoke-button-${k}`}>
									<Button
										className={classNames(styles.button, styles.inline, licenseStyles.revokeButton)}
										onClick={() => revoke(k)}
										disabled={isRevoked}
									>
										{isRevoked ? 'Revoked' : 'Revoke'}
									</Button>
								</Form.Item>
							</div>
						)}
					</div>
					<div className={licenseStyles.licenseRow}>
						<div className={licenseStyles.licenseField}>
							<div className={licenseStyles.licensesHeader}>{'Active Users'}</div>
							<Form.Item required={false} key={`license-active-users-${k}`}>
								{getFieldDecorator(`licenseActiveUsers[${k}]`, {
									initialValue: lau[k],
									rules: [{}],
								})(<Input className={licenseStyles.activeUsers} disabled />)}
							</Form.Item>
						</div>
						<div className={licenseStyles.hidden}>
							<div className={licenseStyles.licensesHeader}>{'Status'}</div>
							<Form.Item required={false} key={`license-status-${k}`}>
								{getFieldDecorator(`licenseStatus[${k}]`, {
									initialValue: licenseStatus[k],
									rules: [{ required: false }],
								})(
									<div>
										<InputNumber disabled />{' '}
									</div>
								)}
							</Form.Item>
						</div>
						{!isCreateMode && (
							<div className={licenseStyles.licenseField}>
								<div className={licenseStyles.licensesHeader}>{'Device ID'}</div>
								<Form.Item required={false} key={`device-id-${k}`}>
									{getFieldDecorator(`deviceID[${k}]`, {
										initialValue: deviceIDs[k],
										rules: [{}],
									})(<Input className={licenseStyles.keys} disabled />)}
								</Form.Item>
							</div>
						)}
						{isCreateMode && (
							<div className={licenseStyles.licenseField}>
								<div className={licenseStyles.licensesHeader}>{'Duration'}</div>
								<Form.Item required={false} key={`license-duration-${k}`}>
									{getFieldDecorator(`duration[${k}]`, {
										initialValue: lds[k],
										rules: [
											{
												required: true,
												message: 'Please input valid month value',
											},
										],
									})(
										<div className={licenseStyles.durationWrap}>
											<InputNumber min={1} max={20} defaultValue={1} disabled />
											<div className={licenseStyles.durationYears}>{'Year(s)'}</div>
										</div>
									)}
								</Form.Item>
							</div>
						)}
						{!isCreateMode && (
							<div className={licenseStyles.licenseField}>
								<div className={licenseStyles.licensesHeader}>{'Expiration Date'}</div>
								<Form.Item required={false} key={`license-expiration-date-${k}`}>
									{getFieldDecorator(`licenseExpirationDate[${k}]`, {
										initialValue: licenseExpirationDate[k]
											? moment(licenseExpirationDate[k], 'YYYY-MM-DD')
											: null,
									})(
										<DatePicker
											className={licenseStyles.expirationDate}
											allowClear={false}
											disabledDate={d => !d || d.isBefore(today)}
											disabled={tierDisabled || !allowExpiration || isRevoked}
										/>
									)}
								</Form.Item>
							</div>
						)}
						<div className={licenseStyles.licenseField} style={{ marginRight: 10 }}>
							<div className={licenseStyles.licensesHeader}>{'Types'}</div>
							<Form.Item required={false} key={`license-types-${k}`}>
								{getFieldDecorator(`licenseTypes[${k}]`, {
									initialValue: lts[k],
									rules: [{}],
								})(
									<Select
										selectOpt={tierSelectOptions}
										placeholder="Type"
										disabled={isFreeTier || tierDisabled || isRevoked}
										dropdownMatchSelectWidth={false}
										className={licenseStyles.types}
										onChange={onTierTypeChange}
									/>
								)}
							</Form.Item>
						</div>
						{isCreateMode && (
							<div className={licenseStyles.licenseField}>
								<br />
								<Form.Item required={false} key={`dynamic-delete-button-${k}`}>
									<Button
										className={classNames(styles.button, styles.inline)}
										onClick={() => remove(k)}
										disabled={tierDisabled}
									>
										<Icon
											className="dynamic-delete-button"
											type="delete"
											style={{ width: '14px', height: '14px', color: '#ec2e4a' }}
											theme="outlined"
											onClick={() => remove(k)}
										/>
									</Button>
								</Form.Item>
							</div>
						)}
					</div>
				</div>
			);
		});

		const onUserTypeChange = () => {
			const keysTemp = form.getFieldValue('keys');
			form.setFieldsValue({
				keys: keysTemp,
			});
		};

		const onProductChange = params => {
			setCurrProd(params);
			onUserTypeChange();
		};

		return (
			<Form onSubmit={onSubmit}>
				{contract && (
					<Form.Item label="Contract ID" className={styles.field}>
						{form.getFieldDecorator('contractId', {
							rules: [{ required: false }],
						})(<span>{contract.id}&nbsp;</span>)}
					</Form.Item>
				)}
				<Form.Item label={<FormattedMessage id="App.UserType" />} className={styles.half_field}>
					{form.getFieldDecorator('isInternal', {
						rules: [{ required: true, message: formatMessage({ id: 'userType is required' }) }],
					})(<Select selectOpt={UserTypeOptions} onChange={onUserTypeChange} disabled={tierDisabled} />)}
				</Form.Item>
				<Form.Item label="Primary Contact Email" className={styles.half_field}>
					{form.getFieldDecorator('contactEmail', {
						rules: [
							{
								required: true,
								pattern: emailRegex,
								message: formatMessage({ id: 'Proceq.ErrorWrongUsername' }),
							},
						],
					})(<Input disabled={tierDisabled} />)}
				</Form.Item>
				<Form.Item label={<FormattedMessage id="Product" />} className={styles.half_field}>
					{products !== undefined &&
						form.getFieldDecorator('product', {
							rules: [{ required: true, message: formatMessage({ id: 'product is required' }) }],
						})(
							<Select
								selectOpt={nonDisabledProdOptions}
								disabled={!!contract || tierDisabled}
								onChange={onProductChange}
							/>
						)}
				</Form.Item>
				{!isInternalUser(userType) && (isCreateMode || !containsFreeTier) && (
					<Form.Item label={<FormattedMessage id="BP (customer SAP number)" />} className={styles.half_field}>
						{form.getFieldDecorator('bp', {
							rules: [{ required: true }],
						})(<Input disabled={tierDisabled} />)}
					</Form.Item>
				)}
				{!isInternalUser(userType) && (isCreateMode || !containsFreeTier) && (
					<div>
						<div>
							<Form.Item
								label={<FormattedMessage id="APP.Contract.InvoiceNo" />}
								className={styles.one_third_field}
							>
								{form.getFieldDecorator('invoiceNo', {
									rules: [
										{
											required: true,
											message: formatMessage({ id: 'App.InvoiceNoRequired' }),
										},
									],
								})(<Input disabled={tierDisabled} />)}
							</Form.Item>

							<Form.Item
								label={<FormattedMessage id="APP.Contract.InvoiceNo2" />}
								className={styles.one_third_field}
							>
								{form.getFieldDecorator('invoiceNo2', {
									rules: [{ required: false }],
								})(
									<Input
										disabled={
											!(
												contract &&
												(form.getFieldValue('invoiceNo2') !== '' ||
													(contract !== undefined && contract.invoiceNo2 !== '') ||
													form.getFieldValue('invoiceNo') !== '')
											) || tierDisabled
										}
									/>
								)}
							</Form.Item>
							<Form.Item
								label={<FormattedMessage id="APP.Contract.InvoiceNo3" />}
								className={styles.one_third_field}
							>
								{form.getFieldDecorator('invoiceNo3', {
									rules: [{ required: false }],
								})(
									<Input
										disabled={
											!(
												contract &&
												(form.getFieldValue('invoiceNo3') !== '' ||
													(contract !== undefined && contract.invoiceNo3 !== '') ||
													form.getFieldValue('invoiceNo2') !== '')
											) || tierDisabled
										}
									/>
								)}
							</Form.Item>
						</div>

						<div>
							<Form.Item
								label={<FormattedMessage id="APP.Contract.InvoiceNo4" />}
								className={styles.one_third_field}
							>
								{form.getFieldDecorator('invoiceNo4', {
									rules: [{ required: false }],
								})(
									<Input
										disabled={
											!(
												contract &&
												(form.getFieldValue('invoiceNo4') !== '' ||
													(contract !== undefined && contract.invoiceNo4 !== '') ||
													form.getFieldValue('invoiceNo3') !== '')
											) || tierDisabled
										}
									/>
								)}
							</Form.Item>
							<Form.Item
								label={<FormattedMessage id="APP.Contract.InvoiceNo5" />}
								className={styles.one_third_field}
							>
								{form.getFieldDecorator('invoiceNo5', {
									rules: [{ required: false }],
								})(
									<Input
										disabled={
											!(
												contract &&
												(form.getFieldValue('invoiceNo5') !== '' ||
													(contract !== undefined && contract.invoiceNo5 !== '') ||
													form.getFieldValue('invoiceNo4') !== '')
											) || tierDisabled
										}
									/>
								)}
							</Form.Item>
							<Form.Item
								label={<FormattedMessage id="APP.Contract.InvoiceNo6" />}
								className={styles.one_third_field}
							>
								{form.getFieldDecorator('invoiceNo6', {
									rules: [{ required: false }],
								})(
									<Input
										disabled={
											!(
												contract &&
												(form.getFieldValue('invoiceNo6') !== '' ||
													(contract !== undefined && contract.invoiceNo6 !== '') ||
													form.getFieldValue('invoiceNo5') !== '')
											) || tierDisabled
										}
									/>
								)}
							</Form.Item>
						</div>
					</div>
				)}
				<div>
					<Form.Item label={<FormattedMessage id="App.Licenses" />} className={styles.field}>
						{form.getFieldDecorator('license', {
							rules: [
								{
									required: true,
									validator: (rule, value, callback) => {
										const keysTemp = form.getFieldValue('keys');
										let isError = keysTemp.length === 0;
										for (let i = 0; i < keysTemp.length; i++) {
											if (form.getFieldValue(`licenseTypes[${keysTemp[i]}]`) === undefined) {
												isError = true;
											}
										}
										if (isError) {
											callback('Invalid license data');
										} else {
											callback();
										}
									},
								},
							],
						})(<span>{''}</span>)}
					</Form.Item>
				</div>
				{licenseFormItems}
				{isCreateMode && (
					<Form.Item className={licenseStyles.generateButton}>
						<Button type="dashed" onClick={add} className={licenseStyles.button} disabled={tierDisabled}>
							<Icon type="plus" /> {'Generate Key'}
						</Button>
					</Form.Item>
				)}

				<Form.Item label={<FormattedMessage id="Remarks" />} className={styles.field}>
					{form.getFieldDecorator('remarks', {
						rules: [{ required: false }],
					})(<Input disabled={tierDisabled} />)}
				</Form.Item>
				<Form.Item
					label={<FormattedMessage id="Proceq.FormAccountTypeBU" />}
					className={styles.one_third_field}
				>
					{form.getFieldDecorator('businessUnit', {
						rules: [{ required: true }],
					})(<Select globalRegister="buunits" disabled={tierDisabled} />)}
				</Form.Item>
				<Form.Item label={<FormattedMessage id="Proceq.FormLanguage" />} className={styles.one_third_field}>
					{form.getFieldDecorator('language', {
						rules: [{ required: true }],
					})(<Select globalRegister="languagescontacttds" disabled={tierDisabled} />)}
				</Form.Item>
				<Form.Item label={<FormattedMessage id="Country Sold To" />} className={styles.one_third_field}>
					{form.getFieldDecorator('countrySoldTo', {
						rules: [{ required: !contract }],
					})(<Select globalRegister="countries" disabled={tierDisabled} />)}
				</Form.Item>
				<Form.Item className={styles.submit}>
					<Button type="default" onClick={() => changeContract(null)}>
						<FormattedMessage id="Proceq.LogbookClose" />
					</Button>
					&nbsp;
					{contractModified && (
						<Button type="primary" htmlType="submit" loading={loading}>
							<FormattedMessage id="Proceq.ButtonSubmit" />
						</Button>
					)}
					{!contractModified && (
						<Button disabled type="primary" htmlType="submit" loading={loading}>
							<FormattedMessage id="Proceq.ButtonSubmit" />
						</Button>
					)}
				</Form.Item>
			</Form>
		);
	}
);

export default compose(
	Form.create({
		mapPropsToFields: props => {
			const fields = {};
			[
				'isInternal',
				'product',
				'license',
				'contactEmail',
				'businessUnit',
				'dueDate',
				'invoiceNo',
				'invoiceNo2',
				'invoiceNo3',
				'invoiceNo4',
				'invoiceNo5',
				'invoiceNo6',
				'language',
				'countrySoldTo',
				'bp',
				'remarks',
			].forEach(k => {
				let value = get(props, ['contract', k]);
				if (k === 'dueDate') {
					value = value ? moment(value) : null;
				}
				if (k === 'isInternal') {
					value = getName(UserTypeOptions, Number(value));
				}
				fields[k] = Form.createFormField({ value });
			});
			return fields;
		},
	}),
	withState('loading', 'setLoading', false),
	withState('currProd', 'setCurrProd', ''),
	withState('prodOptions', 'setProdOptions', []),
	withState('tierOptions', 'setTierOptions', []),
	withState('userType', 'setUserType', ''),
	withState('licenseArray', 'setLicenseArray', []),
	withState('prevContract', 'setPrevContract', ''),
	withState('contractModified', 'setContractModified', false),
	withState('containsFreeTier', 'setContainsFreeTier', false),

	connect(
		state => ({
			buOrHigher: isBUOrHigher(state.session.scopes),
		}),
		(dispatch: Function, props) => ({
			addContract: (params: { isCareRedeemed?: any, isInternal?: any, product?: any }) => {
				if (Object.prototype.hasOwnProperty.call(params, 'isInternal')) {
					params.isInternal = Number(params.isInternal);
				}

				dispatch(
					request({
						method: 'POST',
						url: URL.CREATE_WIFI_CONTRACT,
						setLoading: props.setLoading,
						params: {
							...params,
							type: props.contractType,
							shouldVerify: false,
						},
					})
				).then(response => {
					if (response.status === 'success') {
						props.changeContract(null);
						props.loadContracts();
						props.setAlert(addSuccessMessage);
						downloadLicensesPdf(dispatch, props, null, response.objectID);
					}
				});
			},
			editContract: (params: { isCareRedeemed?: any, isInternal?: any, product?: any }) => {
				if (Object.prototype.hasOwnProperty.call(params, 'isInternal')) {
					params.isInternal = isNaN(Number(params.isInternal))
						? getValue(UserTypeOptions, params.isInternal ?? '')
						: Number(params.isInternal);
				}
				dispatch(
					request({
						method: 'PUT',
						url: URL.UPDATE_WIFI_CONTRACT.replace('{key}', props.contract.id),
						setLoading: props.setLoading,
						params: {
							...params,
							type: props.contractType,
							shouldVerify: false,
						},
					})
				).then(response => {
					if (response === '') {
						props.changeContract(null);
						props.loadContracts();
						props.setAlert(editSuccessMessage);
					}
				});
			},
			loadAvailableProducts: () => {
				dispatch(
					request({
						method: 'GET',
						url: URL.GET_PRODUCT_AVAILABLE,
						setLoading: props.setLoading,
						params: {
							contractType: parseInt(props.contractType, 10),
						},
					})
				).then(data => {
					const options = [];
					const saasProds = getTextValue(['saasProducts']).map(({ value }) => value.toUpperCase());
					if (data !== undefined && data !== null) {
						data.map(prod => {
							if (
								props.contractType === ContractType.Software &&
								saasProds.includes(prod.code.toUpperCase())
							) {
								const obj = {
									name: prod.name,
									value: prod.code,
									tierEnabled: prod.tierEnabled,
								};
								options.push(obj);
							}
						});
					}
					props.setProdOptions(options);
				});
			},
			async loadActiveUsers(previousContract) {
				lau = [];
				const { data } = await dispatch(
					request({
						url: URL.GET_USERS,
						params: {
							ids: previousContract.licenseActiveUserIds,
						},
					})
				);
				const dataHash = {};
				data.forEach(account => {
					dataHash[account.id] = account;
				});
				previousContract.licenseActiveUserIds.forEach(activeUID => {
					if (activeUID === 0) {
						lau.push('');
					} else {
						lau.push(dataHash[activeUID].email);
					}
				});
				previousContract.licenseActiveUsers = [...lau];
			},
			async loadLicenses(previousContract) {
				if (props.contract === undefined) {
					// add contract
					props.setContainsFreeTier(false);
					return;
				}

				const data = await dispatch(
					request({
						method: 'GET',
						url: URL.GET_LICENSES_BY_CONTRACT_ID.replace('{key}', props.contract.id),
					})
				);

				const keys = props.form.getFieldValue('keys');
				let nextKeys = keys;
				const activeUIDs = [];

				lids = [];
				licenseStatus = [];
				lts = [];
				lds = [];
				deviceIDs = [];
				licenseExpirationDate = [];
				id = 0;
				let containsFreeTierLocal = false;
				data.forEach(function(e) {
					lids.push(e.key);
					lts.push(e.tier);
					activeUIDs.push(e.activeUID);
					lds.push(e.duration);
					licenseStatus.push(e.status);
					deviceIDs.push(e.deviceID);
					licenseExpirationDate.push(e.expirationDate);
					nextKeys = nextKeys.concat(id++);
					if (freeTierIds.includes(e.tier)) {
						containsFreeTierLocal = true;
					}
				});
				props.setContainsFreeTier(containsFreeTierLocal);
				props.form.setFieldsValue({
					keys: nextKeys,
				});
				previousContract.licenseIds = [...lids];
				previousContract.licenseDuration = [...lds];
				previousContract.licenseActiveUserIds = [...activeUIDs];
				previousContract.licenseTypes = [...lts];
				previousContract.licenseStatus = [...licenseStatus];
				previousContract.deviceIDs = [...deviceIDs];
				previousContract.licenseExpirationDate = [...licenseExpirationDate];
			},
			async loadTiers(params) {
				const prevSelectedTier =
					props.contract === undefined ? props.form.getFieldValue('tier') : props.contract.tier;
				await dispatch(
					request({
						method: 'GET',
						url: URL.GET_TIERS,
						params: {
							start: 0,
							length: 200,
							'columns[0][data]': 'product',
							'columns[0][search][value]': `(^${params}$)`,
							'columns[0][search][searchable]': true,
							'columns[0][search][regex]': true,
						},
					})
				).then(data => {
					const options = [];
					data.data.map(function(t) {
						if (
							(t.enable === 1 || prevSelectedTier === t.tier) &&
							String(t.contractType) === props.contractType &&
							(!t.free || props.contract)
						) {
							const obj: SelectOpts = {
								name: t.name,
								value: t.tier,
								disable: t.enable === 0,
								allowExpiration: t.allowExpiration === 1,
								requireDeviceID: t.requireDeviceID === 1,
							};
							options.push(obj);
							if (t.free === 1) {
								freeTierIds.push(t.tier);
							}
						}
					});
					props.setTierOptions(options);
				});
			},
		})
	),
	lifecycle({
		async componentDidMount() {
			this.props.loadAvailableProducts();
			await this.props.loadTiers(this.props.form.getFieldValue('product'));
			const previousContract = this.props.contract;
			if (previousContract !== undefined) {
				await this.props.loadLicenses(previousContract);
				await this.props.loadActiveUsers(previousContract);
				this.props.setPrevContract(previousContract);
			}
			this.props.setContractModified(false);
		},
		componentDidUpdate(prevProps) {
			const compare = function(o1, o2) {
				return o1 === o2 || (o1 === undefined && o2 === '') || (o1 === '' && o2 === undefined);
			};
			const compareLicenseInfo = function(
				prevIds,
				prevActiveUsers,
				prevDuration,
				prevTypes,
				prevStatus,
				prevExpiration,
				currentIds,
				currentActiveUsers,
				currentDuration,
				currentTypes,
				currentStatus,
				currentExpiration
			) {
				if (
					prevIds === undefined ||
					prevActiveUsers === undefined ||
					prevTypes === undefined ||
					currentIds === undefined ||
					currentActiveUsers === undefined ||
					currentTypes === undefined ||
					prevExpiration === undefined ||
					currentExpiration === undefined
				) {
					return true;
				}
				if (prevIds.length !== currentIds.length || prevTypes.length !== currentTypes.length) {
					return false;
				}
				for (let i = 0; i < prevIds.length; i++) {
					if (
						prevIds[i] !== currentIds[i] ||
						prevActiveUsers[i] !== currentActiveUsers[i] ||
						prevTypes[i] !== currentTypes[i] ||
						prevStatus[i] !== currentStatus[i] ||
						(currentExpiration[i] !== null &&
							currentExpiration[i]._isValid &&
							prevExpiration[i] !== currentExpiration[i].format('YYYY-MM-DD'))
					) {
						return false;
					}
				}
				return true;
			};

			const formDueDate =
				this.props === undefined || this.props.form === undefined
					? undefined
					: this.props.form.getFieldValue('dueDate');
			const prevDueDate =
				this.props === undefined || this.props.prevContract === undefined
					? undefined
					: this.props.prevContract.dueDate;

			if (
				this.props !== undefined &&
				this.props.prevContract !== undefined &&
				this.props.contract !== undefined &&
				compare(this.props.prevContract.contactEmail, this.props.form.getFieldValue('contactEmail')) &&
				compare(this.props.prevContract.businessUnit, this.props.form.getFieldValue('businessUnit')) &&
				compare(this.props.prevContract.product, this.props.form.getFieldValue('product')) &&
				compare(this.props.prevContract.language, this.props.form.getFieldValue('language')) &&
				compare(this.props.prevContract.countrySoldTo, this.props.form.getFieldValue('countrySoldTo')) &&
				compare(this.props.prevContract.bp, this.props.form.getFieldValue('bp')) &&
				compare(this.props.prevContract.remarks, this.props.form.getFieldValue('remarks')) &&
				compare(this.props.prevContract.invoiceNo, this.props.form.getFieldValue('invoiceNo')) &&
				compare(this.props.prevContract.invoiceNo2, this.props.form.getFieldValue('invoiceNo2')) &&
				compare(this.props.prevContract.invoiceNo3, this.props.form.getFieldValue('invoiceNo3')) &&
				compare(this.props.prevContract.invoiceNo4, this.props.form.getFieldValue('invoiceNo4')) &&
				compare(this.props.prevContract.invoiceNo5, this.props.form.getFieldValue('invoiceNo5')) &&
				compare(this.props.prevContract.invoiceNo6, this.props.form.getFieldValue('invoiceNo6')) &&
				(((formDueDate === null || formDueDate === undefined) &&
					(prevDueDate === null || prevDueDate === undefined || prevDueDate === '')) ||
					(formDueDate !== null &&
						this.props.form.getFieldValue('dueDate') !== undefined &&
						compare(
							this.props.prevContract.dueDate,
							this.props.form.getFieldValue('dueDate').format('YYYY-MM-DD')
						))) &&
				this.props.form.getFieldValue('isInternal') ===
					getName(UserTypeOptions, this.props.prevContract.isInternal) &&
				compareLicenseInfo(
					this.props.prevContract.licenseIds,
					this.props.prevContract.licenseActiveUsers,
					this.props.prevContract.licenseDuration,
					this.props.prevContract.licenseTypes,
					this.props.prevContract.licenseStatus,
					this.props.prevContract.licenseExpirationDate,
					this.props.form.getFieldValue('licenseIds'),
					this.props.form.getFieldValue('licenseActiveUsers'),
					this.props.form.getFieldValue('duration'),
					this.props.form.getFieldValue('licenseTypes'),
					this.props.form.getFieldValue('licenseStatus'),
					this.props.form.getFieldValue('licenseExpirationDate')
				)
			) {
				if (this.props.contractModified === true) {
					this.props.setContractModified(false);
				}
			} else if (this.props.contractModified === false) {
				this.props.setContractModified(true);
			}
			this.props.setUserType(this.props.form.getFieldValue('isInternal'));

			if (
				prevProps !== undefined &&
				this.props !== undefined &&
				this.props.form.getFieldValue('product') !== undefined
			) {
				if (
					(prevProps.currProd === '' && this.props.form.getFieldValue('product') !== '') ||
					prevProps.currProd !== this.props.form.getFieldValue('product')
				) {
					this.props.setCurrProd(this.props.form.getFieldValue('product'));
					this.props.loadTiers(this.props.form.getFieldValue('product'));
				} else if (
					this.props !== undefined &&
					this.props.tierOptions !== undefined &&
					this.props.contract !== undefined &&
					prevProps.contract !== undefined &&
					this.props.tierOptions.filter(to => to.value === this.props.contract.tier).length === 0 &&
					prevProps.currProd !== this.props.form.getFieldValue('product')
				) {
					this.props.loadTiers(this.props.form.getFieldValue('product'));
				}
			}
		},
	}),
	injectIntl
)(AddEditSaasContract);
