// @flow
import React from 'react';
import { Menu, Icon, Badge, Button } from 'antd';
import { FormattedMessage } from 'react-intl';
import { compose, withStateHandlers } from 'recompose';
import './Folders.css';

import type { MeasurementFolderState } from 'reducers/measurement/folder';
import { sortMeasurementFolder } from 'reducers/measurement/folder';

const specialFolders = ['all', 'default', 'import', 'flagged', 'demo', 'trash'];

const filterNormalFolders = f => specialFolders.indexOf(f.type) < 0;

export const Folders = ({
	folders,
	activeFolder,
	setFolder,
	setSort,
	activeSort,
	sortDirection,
}: MeasurementFolderState & {
	activeFolder: string,
	setFolder: Function,
	setSort: Function,
}) => (
	<div>
		<Button.Group size="smaller">
			<Button type="primary" onClick={() => setSort('name')} className={activeSort === 'name' ? '' : 'inactive'}>
				<FormattedMessage id="Proceq.ExportColumnName" />
				{activeSort === 'name' && <Icon type={sortDirection === 'asc' ? 'caret-up' : 'caret-down'} />}
			</Button>
			<Button
				type="primary"
				onClick={() => setSort('created')}
				className={activeSort === 'created' ? '' : 'inactive'}
			>
				<FormattedMessage id="Proceq.StatisticsCreated" />
				{activeSort === 'created' && <Icon type={sortDirection === 'asc' ? 'caret-up' : 'caret-down'} />}
			</Button>
		</Button.Group>
		<Menu
			mode="inline"
			style={{ height: '100%' }}
			onSelect={({ key }) => setFolder(key)}
			selectedKeys={[activeFolder]}
		>
			{sortMeasurementFolder(folders.filter(filterNormalFolders), activeSort, sortDirection).map(folder => (
				<Menu.Item key={folder.id}>
					<Icon type="folder" />
					<span className="nav-text">{folder.name}</span>
					<Badge count={folder.itemCount} overflowCount={999999} showZero />
				</Menu.Item>
			))}
			<Menu.Divider />
			<Menu.ItemGroup title={<FormattedMessage id="App.SystemFolders" />}>
				{specialFolders
					.filter(f => f !== 'default')
					.map(f => folders.find(folder => folder.type === f))
					.filter(Boolean)
					.map(folder => (
						<Menu.Item key={folder.id}>
							{(() => {
								let icon = '';
								switch (folder.type) {
									case 'all':
										icon = 'file';
										break;
									case 'trash':
										icon = 'delete';
										break;
									case 'flagged':
										icon = 'flag';
										break;
									case 'import':
										icon = 'download';
										break;
									default:
										icon = 'folder';
								}
								return <Icon type={icon} />;
							})()}
							<span className="nav-text">
								{(() => {
									let id;
									switch (folder.type) {
										case 'all':
											id = 'Proceq.DataSeriesAllMeasurements';
											break;
										case 'trash':
											id = 'Proceq.DataSeriesTrash';
											break;
										case 'flagged':
											id = 'Proceq.DataSeriesFlagged';
											break;
										case 'demo':
											id = 'Proceq.DataSeriesDemo';
											break;
										case 'import':
											id = 'Proceq.DataSeriesImported';
											break;
									}
									return id ? <FormattedMessage id={id} /> : folder.name;
								})()}
							</span>
							<Badge count={folder.itemCount} overflowCount={999999} showZero />
						</Menu.Item>
					))}
			</Menu.ItemGroup>
		</Menu>
	</div>
);

export default compose(
	withStateHandlers(
		{
			sortDirection: 'asc',
			activeSort: 'created',
		},
		{
			setSort: ({ sortDirection, activeSort }) => sort => {
				if (sort !== activeSort) {
					activeSort = sort;
					sortDirection = 'asc';
				} else if (sortDirection === 'asc') {
					sortDirection = 'desc';
				} else {
					sortDirection = 'asc';
				}

				return {
					sortDirection,
					activeSort,
				};
			},
		}
	)
)(Folders);
