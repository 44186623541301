// @flow

const columnConfig = {
	COMMON: [
		{
			title: 'Description',
			dataIndex: 'description',
			filter: true,
		},
		{
			title: 'Created',
			dataIndex: 'created',
			filter: true,
		},
		{
			title: 'Updated',
			dataIndex: 'updated',
			filter: true,
		},
	],
};

export default columnConfig;
