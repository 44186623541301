// @flow
import { getLabelFromGlobalDataKey, getTextValue } from 'utils/registryHelper';
import handleFormatMessage from './handleFormatMessage';

export function rgbToHex(r: number, g: number, b: number) {
	return `#${componentToHex(r)}${componentToHex(g)}${componentToHex(b)}`;
}
export function componentToHex(c: number) {
	const hex = c.toString(16);
	return hex.length === 1 ? `0${hex}` : hex;
}
export function roundNumberToNext(value: number, direction: 'down' | 'up') {
	if (value === 0) {
		return 0;
	}
	let coef = 100;
	if (Math.abs(value) < 100) {
		if (Math.abs(value) < 10) {
			coef = 10;
		} else {
			coef = 5;
		}
	}
	if (direction === 'down') {
		return Math.floor(value / coef) * coef;
	}
	return Math.ceil(value / coef) * coef;
}
export function getResolutionForScale(
	scaleUnits: any,
	value: mixed,
	typeValue?: ?string,
	showDashIf0: boolean = false
): string {
	let toReturn = value;
	if (toReturn === null || isNaN(parseInt(toReturn, 10))) {
		// Like -- or null values
		return '';
	}
	toReturn = parseFloat(toReturn);
	if (toReturn === 0 && showDashIf0) {
		return '--';
	}
	switch (typeValue) {
		case 'count':
			toReturn = toReturn.toFixed(0);
			break;
		default:
			if (toReturn < 1) {
				// do nothing with the precision
			} else if (toReturn < 100) {
				toReturn = toReturn.toFixed(1);
			} else {
				toReturn = toReturn.toFixed(0);
			}
			break;
	}
	return String(toReturn);
}
export function getScaleUnitsOfScaleId(product: ProductCode, formatMessage: Function, scaleID: number) {
	// changed
	const scaleInfo = getScaleInfoOfScaleId(formatMessage, scaleID, product);
	return scaleInfo.scaleUnits;
}
export function getScaleNameOfScaleId(product: ProductCode, formatMessage: Function, scaleID: number) {
	// changed
	const scaleInfo = getScaleInfoOfScaleId(formatMessage, scaleID, product);
	return scaleInfo.scaleName;
}
export function getStandardNameOfStandardId(
	globalregister: null,
	formatMessage: Function,
	standardID: number,
	productType: ProductCode
) {
	// changed
	let standardGlobalCategory = 'standards';
	if (productType === 'SCHMIDT') {
		standardGlobalCategory = 'standardsschmidt';
	}
	const standardLabel = getLabelFromGlobalDataKey(standardGlobalCategory, standardID);
	if (standardLabel === null) {
		return standardID;
	}
	return handleFormatMessage(standardLabel, formatMessage);
}
export function getOffsetForProduct(productType: ProductCode) {
	switch (productType) {
		case 'SSL':
		case 'SCHMIDT':
			return 1;
		default:
			return 0;
	}
}
export function getMaterialNameOfMaterialId(globalregister: null, formatMessage: Function, materialID: number) {
	// changed
	const materialLabel = getLabelFromGlobalDataKey('materials', materialID);
	if (materialLabel === null) {
		return materialID;
	}
	return handleFormatMessage(materialLabel, formatMessage);
}
export function getSchmidtCompositeSurfaceId(surfaceStrengthId?: number, surfaceConversionId?: number) {
	if (typeof surfaceStrengthId === 'undefined' || surfaceStrengthId === null) {
		return null;
	}
	if (typeof surfaceConversionId === 'undefined' || surfaceConversionId === null) {
		return null;
	}
	return `${surfaceStrengthId}_${surfaceConversionId}`;
}

export function getSchmidtScaleIdOfFromFactorAndUnitId(unitId?: ?number, formFactorId?: ?number) {
	if (typeof formFactorId === 'undefined' || formFactorId === null) {
		formFactorId = 0;
	}
	if (typeof unitId === 'undefined' || unitId === null) {
		unitId = 0;
	}
	return 1 * 1000000 + formFactorId * 100 + unitId;
}

/**
 *
 * @param {*} formatMessage From intl.formatMessage
 * @param {*} scaleID if Schmidt, make sure to use getSchmidtScaleIdOfFromFactorAndUnitId
 * @param {*} product to check if Silver Schmidt
 */
export function getScaleInfoOfScaleId(formatMessage: Function, scaleID: number, product: ProductCode) {
	// changed
	let scaleUnits = '';
	let scaleName = '';
	if (scaleID >= 1000000) {
		const productNumId = Math.floor(scaleID / 1000000);
		if (productNumId === 1) {
			const formFactorAndUnit = scaleID - productNumId * 1000000;
			const formFactorId = Math.floor(formFactorAndUnit / 100);
			const unitId = formFactorAndUnit % 100;
			scaleUnits = getLabelFromGlobalDataKey('unitsschmidt', unitId);
			if (scaleUnits === null) {
				scaleUnits = getLabelFromGlobalDataKey('unitsschmidt', 0);
			}

			if (unitId === 0) {
				scaleName = formatMessage({ id: 'App.SchmidtFormFactorRAngle' });
				if (product === 'SSL') {
					scaleName = formatMessage({ id: 'App.SchmidtFormFactorQAngle' });
				}
			} else if (unitId === 6) {
				scaleName = formatMessage({ id: 'App.SchmidtFormFactorQAngle' });
			} else {
				const formFactorName = getLabelFromGlobalDataKey('formfactorschmidt', formFactorId);
				scaleName = `${scaleUnits} ${formFactorName}`;
			}
		}
		return {
			scaleUnits,
			scaleName,
		};
	}
	const scaleLabel = getLabelFromGlobalDataKey('scales', scaleID);
	if (scaleLabel === null) {
		scaleName = scaleID;
	} else {
		scaleName = handleFormatMessage(scaleLabel, formatMessage);
	}

	scaleUnits = getLabelFromGlobalDataKey('units', scaleID);
	if (scaleUnits === null) {
		scaleUnits = getLabelFromGlobalDataKey('units', 2);
	}
	scaleUnits = handleFormatMessage(scaleUnits, formatMessage);
	return {
		scaleUnits,
		scaleName,
	};
}

export function isKeyDefinedAndNotNull(object: Object, keyToSearch: string) {
	if (!(keyToSearch in object)) {
		return false;
	}

	if (object[keyToSearch] === null) {
		return false;
	}
	return true;
}

export function morrisToHighchartsReg(
	product: ProductCode,
	formatMessage: Function,
	data: Object[],
	registerKey: string
) {
	const translations = [];
	if (registerKey === 'scales') {
		for (let i = 0, len = data.length; i < len; i++) {
			data[i].label = getScaleInfoOfScaleId(formatMessage, data[i].label, product).scaleName;
		}
	} else if (registerKey === 'scalesschmidt') {
		for (let i = 0, len = data.length; i < len; i++) {
			const scaleId = getSchmidtScaleIdOfFromFactorAndUnitId(data[i].label, data[i].ff);
			data[i].label = getScaleInfoOfScaleId(formatMessage, scaleId, product).scaleName;
		}
	} else {
		getTextValue([registerKey]).forEach(({ text, value }) => {
			translations[value] = text;
		});

		for (let i = 0, len = data.length; i < len; i++) {
			if (typeof translations[data[i].label] !== 'undefined') {
				data[i].label = handleFormatMessage(translations[data[i].label], formatMessage);
			} else {
				data[i].label = handleFormatMessage(data[i].label, formatMessage);
			}
		}
	}
	return morrisToHighcharts(formatMessage, data);
}

export function morrisToHighcharts(formatMessage: Function, data: Object) {
	const series = {
		data: [],
		innerSize: '50%',
		name: '',
	};

	for (const i in data) {
		// eslint-disable-next-line no-prototype-builtins
		if (data.hasOwnProperty(i)) {
			series.data.push([handleFormatMessage(data[i].label, formatMessage), data[i].value]);
		}
	}
	return [series];
}

export function extractLabelsFromSeries(series: Object[]) {
	const labels = [];
	for (const s in series[0].data) {
		// eslint-disable-next-line no-prototype-builtins
		if (series[0].data.hasOwnProperty(s)) {
			labels.push(series[0].data[s][0].toString());
		}
	}
	return labels;
}
