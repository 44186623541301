// @flow
import axios from 'axios';
import qs from 'qs';
import moment from 'moment';
import { getSystemLocale } from 'utils/localeHelper';

const defaultApiConfig = {
	headers: {
		'Content-Type': 'text/plain',
	},

	withCredentials: false,
	transformRequest: [data => JSON.stringify(data)],
};

// Simple wrapper around axios post that returns a promise object
export function httpPost(url: string, postBody: Object, apiConfig?: Object = defaultApiConfig) {
	return axios.post(url, postBody, apiConfig);
}

// Simple wrapper around axios get that returns a promise object
export function httpGet(url: string) {
	return axios.get(url, {});
}

export function httpRequest(
	session: ?Session,
	locale: ?Locale,
	url: string,
	method?: string = 'post',
	apiSendToken?: boolean = true,
	data?: Object = {},
	headers?: Object = {},
	options?: Object = { sendBodyAsItIs: false, sendProductType: false }
) {
	const bodyType = method === 'get' ? 'params' : 'data';
	let sendCredentials = false;
	// headers['Access-Control-Allow-Origin'] = '*';
	if (apiSendToken && session && session.bearerToken) {
		headers.Authorization = `Bearer ${session.bearerToken}`;
		sendCredentials = false;
	}
	data.locale = getSystemLocale();
	data.local_lang = 'en';
	if (locale) {
		data.local_lang = locale.lang;
	}
	if (session && session.sessionType === 'PROCEQ') {
		// Always send product type if Proceq cloud
		options.sendProductType = true;
		data.local_time = moment().format('YYYY-MM-DD HH:mm:ss ZZ');
	}

	if (session && 'sendProductType' in options && options.sendProductType) {
		if (session.productType) {
			if (session.sessionType === 'TDS') {
				data.product = session.productType;
			} else {
				data.app_type = session.productType;
			}
		}
	}

	if (!('sendBodyAsItIs' in options) || !options.sendBodyAsItIs) {
		if (data && method !== 'get') {
			data = JSON.stringify(data);
			headers['Content-Type'] = 'x-www-form-urlencoded';
		}
	}

	axios.defaults.headers.common = {}; // or which ever header you have to remove

	const config: Object = {
		method,
		url,
		headers,
		withCredentials: sendCredentials,
		[bodyType]: data,
	};

	if (data && method === 'get') {
		config.paramsSerializer = params => qs.stringify(params, { arrayFormat: 'brackets' });
	}

	const instanceAxios = axios.create(config);
	return instanceAxios.request(config);
}
