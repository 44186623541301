/* eslint-disable */
import { withHighcharts, HighchartsChart, Chart, XAxis, YAxis, Title, Subtitle, Legend, LineSeries, BarSeries, AreaSeries, PieSeries, ColumnSeries, AreaSplineSeries, PlotLine, Tooltip } from 'react-jsx-highcharts';
import Highcharts from 'highcharts';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { Layout, Menu, Breadcrumb, Icon , Radio, Button, Badge, Col, Row } from 'antd';
import { connect } from 'react-redux';
import handleFormatMessage from 'utils/handleFormatMessage';

export class HighChartsDashboard extends Component {
	constructor(props) {
		super(props);
		/* this.state = {
			chartInfo: this.getChartInfo()
		}; */
	}

	componentWillMount() {

	}
	componentDidMount() {

	}
	componentWillUnmount() {

	}
	componentWillReceiveProps(nextProps){

	}

	render() {
		const
			{ intl } = this.props,
			{ formatMessage } = intl;
		const thisChartId = this.props.baseId + "-highchart";
		const chartInfo = this.getChartInfo();
		return (

					<HighchartsChart key={ thisChartId } plotOptions={chartInfo.plotOptions} >
						{
							this.props.showLegend ?
								<Legend  id={ thisChartId + "-legend" } itemWidth={200} useHTML={true} floating={false} itemStyle={ {
									color: "#333333",
									cursor: "pointer",
									fontFamily: 'Arial',
									fontSize: '12px',
									fontWeight: 'normal',
									textOverflow: "ellipsis",
									overflow: "hidden",
									whiteSpace: "nowrap"
								} }/>
								: ''
						}
						<Chart id={ thisChartId + "-chart" } backgroundColor={null} />



						<Tooltip formatter={chartInfo.formatter} shared />

						<XAxis id={ thisChartId + "-xaxis" } categories={chartInfo.xAxis.categories}>
							<XAxis.Title>{chartInfo.xAxis.title.text}</XAxis.Title>
						</XAxis>

						<YAxis id={ thisChartId + "-yaxis" }>
							<YAxis.Title>{chartInfo.yAxis.title.text}</YAxis.Title>
							{
								chartInfo.series.map(function(infoSeries, indexSeries) {
									const thisGraphId = this.props.baseId + indexSeries;
									switch (chartInfo.chart.type) {
										case 'pie':
											return (<PieSeries key={ thisGraphId } id={ thisGraphId } name={ infoSeries.name } minSize={'100'}  height={'100'} innerSize={'50%'} data={infoSeries.data}  color={chartInfo.colors[indexSeries]} />);
										case 'area':
											return (<AreaSeries key={ thisGraphId } id={ thisGraphId } name={ infoSeries.name } data={infoSeries.data}  color={chartInfo.colors[indexSeries]} />);
										case 'line':
											return (<LineSeries key={ thisGraphId } id={ thisGraphId } name={ infoSeries.name } data={infoSeries.data}  color={chartInfo.colors[indexSeries]} />);
										case 'spline':
											return (<SplineSeries key={ thisGraphId } id={ thisGraphId } name={ infoSeries.name } data={infoSeries.data}  color={chartInfo.colors[indexSeries]} />);
										case 'bar':
											return (<BarSeries key={ thisGraphId } id={ thisGraphId } name={ infoSeries.name } data={infoSeries.data}  color={chartInfo.colors[indexSeries]} />);
										case 'column':
											return (<ColumnSeries key={ thisGraphId } id={ thisGraphId } name={ infoSeries.name } data={infoSeries.data}  color={chartInfo.colors[indexSeries]} />);
										case 'areaspline':
											return (<AreaSplineSeries key={ thisGraphId } id={ thisGraphId } name={ infoSeries.name } data={infoSeries.data}  color={chartInfo.colors[indexSeries]} />);
										default:
											return (<LineSeries key={ thisGraphId } id={ thisGraphId } name={ infoSeries.name } data={infoSeries.data}  color={chartInfo.colors[indexSeries]} />);
									}
								}, this)
							}
						</YAxis>
						{/*<YAxis id="number"  type="linear">
							<YAxis.Title>{dataForGraph['targetunit']}</YAxis.Title>
							{ dataForGraph['dataoriginal'].length ?  <LineSeries id="dataoriginal" name="Original Curve" data={dataForGraph['dataoriginal']}  color={'rgba(0,0,255,.9)'}/> : ''}
							{ dataForGraph['datacustom'].length ?  <LineSeries id="datacustom" name="Custom Curve" data={dataForGraph['datacustom']}  color={'rgba(255,0,0,1)'}/> : ''}
						</YAxis>*/}
					</HighchartsChart>

		);
	}
	getChartInfo(){
		const
			{ intl } = this.props,
			{ formatMessage } = intl;
		const self = this;

		let infoXAxis = {...this.props.xAxis};
		let infoYAxis = {...this.props.yAxis};
		if (this.props.labels !== null) {
			//this.xAxis = {crosshair: true, categories: this.labels};
			infoXAxis.categories= [...this.props.labels];
		}
		if(this.props.yAxisTitle !== null){
			infoYAxis.title.text = this.props.yAxisTitle;
		}
		if(this.props.xAxisTitle !== null){
			infoXAxis.title.text = this.props.xAxisTitle;
		}
		if(this.props.xAxisType != null){
			infoXAxis.type = this.props.xAxisType;
		}
		if(this.props.xAxisCrosshair != null ){
			infoXAxis.crosshair = this.props.xAxisCrosshair;
		}
		if(this.props.xAxisEvents != null ){
			infoXAxis.events = this.props.xAxisEvents;
		}

		let infoType = '';
		switch (this.props.type) {
			case 'pie':
			case 'area':
			case 'line':
			case 'spline':
			case 'bar':
			case 'column':
			case 'areaspline':
				infoType = this.props.type;
				break;
			default:
				infoType = 'line';
				break;
		}
		let infoChart = {
			type: infoType,
			style: {
				fontFamily: 'Arial',
				fontSize: '15px'
			}

		};
		if (this.props.chartOptions != null) {
			Object.assign(infoChart, this.props.chartOptions);
		}
		if (this.props.unit != null){
			infoYAxis.labels.format = '{value} ' +  handleFormatMessage(this.props.unit, formatMessage);
		}

		/* let infoColors = [];
		for(let indexColor = 0; indexColor < this.props.colors.length; indexColor++)
		{
			infoColors.push(rgbToHex(this.props.colors[indexColor][0],this.props.colors[indexColor][1],this.props.colors[indexColor][2]));
		} */
		let infoSeries = [...this.props.series];
		for(let indexSeries = 0; indexSeries < this.props.serieNames.length; indexSeries++)
		{
			infoSeries[indexSeries].name = handleFormatMessage(this.props.serieNames[indexSeries], formatMessage);
		}
		const allChartSettings = {
			chart: infoChart,
			title: {
				text:  handleFormatMessage(this.props.title, formatMessage)
			},
			xAxis: {...infoXAxis},
			yAxis: {...infoYAxis},
			colors: this.props.colors,
			formatter: function() {
				let header = ('x' in this && (typeof this.x !== 'undefined')) ? '<b>'+ this.x + '</b><br/>' : '';
				let content = '';
				let allPoints = [];
				if( 'points' in this) {
					allPoints = this.points;
				}
				else if('point' in this) {
					allPoints = [this.point];
				}
				for(let indexSerie = 0; indexSerie < allPoints.length; indexSerie++) {
					let thisPoint = allPoints[indexSerie];
					if(indexSerie !== 0) {
						content += '<br/>';
					}
					content += '<span style="color:'+ thisPoint.color +'">\u25CF</span> '+ (('name' in thisPoint) ? thisPoint.name : thisPoint.series.name) + ':';
					content +=  thisPoint.y + ((self.props.unitForLabel !== null) ? (' ' + self.props.unitForLabel) : '');
				}
				return (header+ content);
			},
			plotOptions: {
				pie: {
					colors: this.props.colors,
					dataLabels: {
						enabled: true,
						color: '#00A1B1',
						formatter: function() {
							if((typeof this.y == "undefined")|| (this.y=== null) || (this.y == 0) ){
								return "";
							}
							return this.y +" %";
						},
						style: {"color": "contrast", "fontSize": "16px", /*"fontWeight": "bold",*/ "textOutline": "1px contrast" }
					},
					showInLegend: true
				},
				areaspline: {
					dataLabels: {
						enabled: true,
						color: '#00A1B1',
						formatter: function() {
							if((typeof this.y == "undefined")|| (this.y=== null) || (this.y == 0) ){
								return "";
							}
							return this.y;
						},
						style: {"color": "contrast", "fontSize": "22px", /*"fontWeight": "bold",*/ "textOutline": "1px contrast" }
					},

				},
				column: {
					/*borderWidth: 16,*/
					dataLabels: {
						enabled: true,
						color: '#00A1B1',

						formatter: function() {
							if((typeof this.y == "undefined")|| (this.y=== null) || (this.y == 0) ){
								return "";
							}
							return this.y;
						},
						style: {"color": "contrast", "fontSize": "22px", /*"fontWeight": "bold",*/  "textOutline": "1px contrast" }
					},

					groupPadding: 0,
					pointPadding: 0.05
				}
			},

			legend: this.props.legend,
			credits: {enabled: false},
			series: infoSeries,
			showLegend:  this.props.showLegend
		};

		return allChartSettings;
	}
}


HighChartsDashboard.defaultProps = {
	type: "line",
	title: "",
	baseId: "",
	series: [],
	serieNames:[],
	colors: ['#00A1B1', '#55C0CB', '#8DD5DC', '#C6EAED', '#1CABB9', '#38B5C2', '#71CAD3', /*'#deebf7', '#f7fbff',*/ '#AADFE5' ,'E2F4F6'],
	xAxis: {
		title: {
			text: null,
			offset:40
		},
		labels:{
			style: {
				fontFamily: 'Arial',
				fontSize: '15px'
			}
		}
	},
	yAxis: {
		title: {
			text: null,
			offset:60
		},
		labels:{
			style: {
				fontFamily: 'Arial',
				fontSize: '15px'
			}
		}
	},
	formatter: null,
	yAxisTitle: null,
	xAxisTitle: null,
	xAxisType: null,
	xAxisCrosshair: null,
	xAxisEvents: null,
	unit: null,
	labels: null,
	options: null,
	unitForLabel: null,
	legend: {
		itemWidth: 200,
		itemStyle: {
			color: "#333333",
			cursor: "pointer",
			fontFamily: 'Arial',
			fontSize: '13px',
			fontWeight: 'normal',
			width: '170px',
			textOverflow: "ellipsis",
			overflow: "hidden",
			whiteSpace: "nowrap"
		},
		useHTML: true
	},
	showLegend: true
};


const mapStateToProps = state => ({
	session: state.session,
	globalregister: state.globalregister
});

const mapDispatchToProps = dispatch => ({

});

export default withHighcharts(connect(mapStateToProps, mapDispatchToProps)(injectIntl(HighChartsDashboard)), Highcharts);
