/* eslint-disable func-names */
import React, { Component } from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { lifecycle } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Popover } from 'antd';
import { changeCurrentProduct, changingProduct, changeCurrentGroup } from 'actions/session';
import { getLabelFromGlobalDataKey, getTextValue } from 'utils/registryHelper';
import { isUsingSharedToken } from 'utils/sessionHelper';
import { isProduction, isLive } from 'apis/urls';
import { hasRoleLevel, hasOneOfRoleLevel, ROLES } from 'utils/rolesHelper';
import Avatar from '../dashboard/Header/Avatar';
import styles from './Header.styl';
import Languages from './Languages';
import { history } from '../../routes';
import {
	getRouteConfigFromPathName,
	DataReviewPath,
	NewDashBoardpath,
	OldDashBoardPath,
	OldDashBoardProducts,
} from '../../routes/config';

// const Search = Input.Search;

class Header extends Component {
	static propTypes = {
		session: PropTypes.object,
		changeCurrentGroup: PropTypes.func,
		onProductChanged: PropTypes.func,
	};

	constructor(props) {
		super(props);
		this.state = {
			visibleProductPopup: false,
			visibleGroupPopup: false,
		};
	}

	render() {
		const { session, location, onProductChanged } = this.props;
		let getListOfPlatformProducts = [];
		let productsMenu = null;
		if (!isUsingSharedToken(session)) {
			switch (session.sessionType) {
				case 'TDS': {
					let register = 'availproductsfull';
					if (isLive) {
						register = 'availproductslive';
					} else if (isProduction) {
						register = 'availproductslivedev';
					}
					getListOfPlatformProducts = getTextValue([register]).map(p => p.value);
					break;
				}
				case 'PROCEQ':
					// SCHMIDT & EQUOTIP is available only for admin users.
					if (session.groupsRoles != null && Object.keys(session.groupsRoles).length > 1) {
						console.log(session.groupsRoles);
						for (const key of Object.keys(session.groupsRoles)) {
							if (session.groupsRoles[key].includes('admin_proceq')) {
								getListOfPlatformProducts.push('SCHMIDT');
								getListOfPlatformProducts.push('EQUOTIP');
								break;
							}
						}
					}
					break;
				default:
					break;
			}
			productsMenu = (
				<div className="menu_container" style={{ width: '240px', backgroundColor: 'white' }}>
					{getListOfPlatformProducts.map(function(productKey, indexProduct) {
						// if user then do not show option for wifi product data
						if (!hasOneOfRoleLevel(this.props.session.scopes, [ROLES.DEV, ROLES.PO])) {
							if (['PUNDIT', 'GPR', 'FDL'].includes(productKey)) {
								return null;
							}
						}
						// hide products without measurements
						if (
							(session.sessionType === 'TDS' && session.products[productKey] === undefined) ||
							session.products[productKey]?.numberCreated === 0
						) {
							return null;
						}
						return (
							<div className={styles.menu_item} key={indexProduct}>
								<a
									style={{ all: 'unset', flex: '1' }}
									tabIndex={indexProduct}
									onClick={() => {
										this.hidePopuUp();
										onProductChanged(productKey);
										const sessionCopy = { ...session };
										sessionCopy.productType = productKey;

										// Schmidt & Equotip uses old dashboard;
										// Thus, change accordingly when products switching b/w old and new dashboard
										let path = location.pathname;
										if (path === NewDashBoardpath || path === OldDashBoardPath) {
											path = OldDashBoardProducts.includes(productKey)
												? OldDashBoardPath
												: NewDashBoardpath;
										}
										const route = getRouteConfigFromPathName(path);
										if (
											route !== undefined &&
											route.checkAccess !== undefined &&
											route.checkAccess(sessionCopy)
										) {
											history.push(path);
										} else {
											history.push(DataReviewPath);
										}
									}}
								>
									{getLabelFromGlobalDataKey('availproductsfull', productKey)}
									{session.sessionType === 'TDS' &&
										location.pathname === '/data' &&
										` (${get(session, ['products', productKey, 'numberCreated'], 0)})`}
								</a>
							</div>
						);
					}, this)}
				</div>
			);
		}
		let groupsMenu = null;
		if (session.groupsRoles != null && Object.keys(session.groupsRoles).length > 1) {
			groupsMenu = (
				<div className="menu_container" style={{ width: '240px', backgroundColor: 'white' }}>
					{Object.keys(session.groupsRoles).map(function(groupKey, indexGroup) {
						return (
							<div className={styles.menu_item} key={indexGroup}>
								<a
									style={{ all: 'unset', flex: '1' }}
									tabIndex={indexGroup}
									onClick={() => {
										this.hideGroupPopuUp();
										this.props.changeCurrentGroup(groupKey);
									}}
								>
									{groupKey}
								</a>
							</div>
						);
					}, this)}
				</div>
			);
		}
		const isProductContainerVisible = this.props.isProductContainerVisible;
		return (
			<header className={styles.header}>
				<div className={styles.header_left}>
					{(() => {
						if (!isProductContainerVisible) {
							return null;
						}
						if (
							productsMenu !== null &&
							(session.sessionType === 'PROCEQ' ||
								location.pathname === '/dashboard-new' ||
								location.pathname === '/dashboard-old' ||
								location.pathname === '/data' ||
								location.pathname === '/data-verify' ||
								location.pathname === '/data-custom' ||
								location.pathname === '/data-setup')
						) {
							return (
								<div
									id="products_container"
									className={styles.products_container}
									data-e2e-product-switcher
								>
									<Popover
										getPopupContainer={() => document.getElementById('products_container')}
										placement="bottomLeft"
										content={productsMenu}
										autoAdjustOverflow={false}
										trigger="click"
										visible={this.state.visibleProductPopup}
										onVisibleChange={this.handleVisibleChange.bind(this)}
									>
										<div className={styles.product_selector}>
											{getLabelFromGlobalDataKey('availproductsfull', session.productType)}
										</div>
									</Popover>
								</div>
							);
						}
						if (
							location.pathname.match(/^\/m\/.*$/) !== null ||
							(session &&
								session.scopes &&
								session.scopes.length === 1 &&
								session.scopes.includes('user') &&
								session.productType === 'SCHMIDT' &&
								location.pathname !== '/settings')
						) {
							return (
								<div id="products_container" className={styles.products_container}>
									<div className={styles.product_display}>
										{getLabelFromGlobalDataKey('availproductsfull', session.productType)}
									</div>
								</div>
							);
						}
						return null;
					})()}
					{groupsMenu !== null ? (
						<div id="groups_container" className={styles.products_container}>
							<Popover
								getPopupContainer={() => document.getElementById('groups_container')}
								placement="bottomLeft"
								content={groupsMenu}
								autoAdjustOverflow={false}
								trigger="click"
								visible={this.state.visibleGroupPopup}
								onVisibleChange={this.handleGroupsVisibleChange.bind(this)}
							>
								<div className={styles.product_selector}> {session.groupId} </div>
							</Popover>
						</div>
					) : (
						''
					)}
				</div>
				<div className={styles.header_right}>
					{!isUsingSharedToken(session) ? <Avatar /> : null}
					<div className={styles.language_container}>
						<Languages />
					</div>
				</div>
			</header>
		);
	}

	hidePopuUp = () => {
		this.setState({
			visibleProductPopup: false,
		});
	};

	handleVisibleChange = visibleProductPopup => {
		this.setState({ visibleProductPopup });
	};

	hideGroupPopuUp = () => {
		this.setState({
			visibleGroupPopup: false,
		});
	};

	handleGroupsVisibleChange = visibleGroupPopup => {
		this.setState({ visibleGroupPopup });
	};

	/* onGroupChanged(groupKey) {
		console.log('onGroupChanged:' + groupKey);
	} */
}

const mapStateToProps = state => ({
	session: state.session,
	isProductContainerVisible: true,
});
const mapDispatchToProps = dispatch => ({
	onProductChanged: productKey => {
		dispatch(changingProduct());
		dispatch(changeCurrentProduct(productKey));
	},
	changeCurrentGroup: productKey => {
		dispatch(changeCurrentGroup(productKey));
	},
});

export default compose(
	connect(mapStateToProps, mapDispatchToProps),
	lifecycle({
		componentDidMount() {
			let listOfPlatformProducts = [];
			if (this.props.session.sessionType === 'TDS') {
				let register = 'availproductsfull';
				if (isLive) {
					register = 'availproductslive';
				} else if (isProduction) {
					register = 'availproductslivedev';
				}
				listOfPlatformProducts = getTextValue([register]).map(p => p.value);
				// if current product in session don't have data then change product which has data
				if (this.props.session.products[this.props.session.productType]?.numberCreated === 0) {
					for (const index in listOfPlatformProducts) {
						if (
							this.props.session.sessionType === 'TDS' &&
							this.props.session.products[listOfPlatformProducts[index]]?.numberCreated !== undefined &&
							this.props.session.products[listOfPlatformProducts[index]]?.numberCreated !== 0
						) {
							this.props.onProductChanged(listOfPlatformProducts[index]);
							break;
						}
					}
				}
				// if user role and data only present for wifi devices then hide drop down
				if (!hasRoleLevel(this.props.session.scopes, ROLES.BU)) {
					let isBTDataPresent = false;
					for (const index in listOfPlatformProducts) {
						if (
							['EQUOTIP', 'SCHMIDT', 'GLM'].includes(listOfPlatformProducts[index]) &&
							this.props.session.products[listOfPlatformProducts[index]]?.numberCreated !== undefined &&
							this.props.session.products[listOfPlatformProducts[index]]?.numberCreated > 0
						) {
							isBTDataPresent = true;
						}
					}
					if (!isBTDataPresent) {
						this.setState({ isProductContainerVisible: false });
					}
				}
			}
		},
	}),
	withRouter
)(Header);
