// @flow
import React, { Component } from 'react';
import { trim } from 'lodash';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import type { Location } from 'react-router';
import type { InjectIntlProvidedProps } from 'react-intl';
import { injectIntl } from 'react-intl';
import { Spin } from 'antd';
import qs from 'qs';
import { afterLogin, loginOneTimeProceq } from 'actions/login';
import handleFormatMessage from 'utils/handleFormatMessage';
import { history } from '../../routes';
import { finishUserSession } from '../../actions/logout';

type Props = {
	loginOneTimeProceq: Function,
	afterLogin: Function,
	location: Location,
	finishUserSession: Function,
} & InjectIntlProvidedProps;

export class LoginOneTimeToken extends Component<Props> {
	componentDidMount() {
		const queryParams = qs.parse(trim(this.props.location.search, '?'));
		if (queryParams.ot) {
			this.props.loginOneTimeProceq({
				ot: queryParams.ot,
			});
		} else if (queryParams.accessToken) {
			this.props.afterLogin(queryParams);
		} else {
			history.replace('/login');
			this.props.finishUserSession();
		}
	}

	render() {
		const { intl } = this.props,
			{ formatMessage } = intl;
		return (
			<Spin
				style={{ marginTop: '50px', width: '100%' }}
				tip={handleFormatMessage('Proceq.JQGridTableLoadText', formatMessage)}
			/>
		);
	}
}

export default compose(
	connect(null, {
		loginOneTimeProceq,
		afterLogin,
		finishUserSession,
	}),
	injectIntl
)(LoginOneTimeToken);
