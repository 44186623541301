// @flow
import React from 'react';
import type { InjectIntlProvidedProps } from 'react-intl';
import { injectIntl } from 'react-intl';
import handleFormatMessage from 'utils/handleFormatMessage';

export const TestBlock = ({ log, product, intl }: InjectIntlProvidedProps & EquotipLogbookEntryProps) => (
	<span>
		{log.content.oldTestBlockTitle
			? handleFormatMessage(
					product === 'EQUOTIP'
						? 'LogbookPropertyTestBlockChangedFromTo'
						: 'Proceq.LogbookPropertyCalibrationAnvilChangedFromTo',
					intl.formatMessage,
					[log.content.oldTestBlockTitle, log.content.updatedTestBlockTitle]
			  )
			: handleFormatMessage(
					product === 'EQUOTIP'
						? 'LogbookPropertyTestBlockChangedTo'
						: 'Proceq.LogbookPropertyCalibrationAnvilChangedTo',
					intl.formatMessage,
					[log.content.updatedTestBlockTitle]
			  )}
	</span>
);

export default injectIntl(TestBlock);
