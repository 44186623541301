// @flow
import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';

export const ResumeScan = ({ log }: GPRLogbookEntryProps) => (
	<Fragment>
		<FormattedMessage id="Comment" />
		{`: ${log.content.comment}`}
	</Fragment>
);

export default ResumeScan;
