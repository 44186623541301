// @flow
import React from 'react';
import { Popconfirm, Button } from 'antd';
import { FormattedMessage } from 'react-intl';
import { makeQueryPath } from '../../utils/routeUtils';
import { WORKSPACE_MASQUERADE } from '../../routes/config';

export const AssumeUser = ({ email, id, isButton }: { email: string, id: string, isButton?: boolean }) => {
	const assumeUser = () => {
		const url = makeQueryPath(WORKSPACE_MASQUERADE, { userID: id });
		window.open(url, '_self');
	};

	return (
		<Popconfirm
			title={
				<span>
					<FormattedMessage id="App.UsersAssumePrompt" values={{ user: email }} />
					<FormattedMessage id="Proceq.TextConfirmationAreYouSure" />
				</span>
			}
			onConfirm={assumeUser}
			okText={<FormattedMessage id="Proceq.TextYes" />}
			cancelText={<FormattedMessage id="Proceq.TextNo" />}
		>
			{isButton ? (
				<Button>
					<FormattedMessage id="App.UsersAssume" />
				</Button>
			) : (
				<div style={{ width: '100%', height: '100%' }}>
					<FormattedMessage id="App.UsersAssume" />
				</div>
			)}
		</Popconfirm>
	);
};

export default AssumeUser;
