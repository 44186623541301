// @flow
import React from 'react';
import { find } from 'lodash';
import { connect } from 'react-redux';
import { compose, withState, lifecycle } from 'recompose';
import { FormattedMessage } from 'react-intl';
import { Layout, Modal, Button, Popconfirm, Icon } from 'antd';
import URL from 'apis/urls';
import { request } from 'actions/ajax';
import { getTextValue } from 'utils/registryHelper';
import styles from '../shared/SmarterTable.styl';
import columnConfig from './columnConfig';
import SmarterTable, { renderCell } from '../shared/SmarterTable';
import AddEditBU from './AddEditBU';

const globalRegister = 'buunits';

export const BusinessUnits = ({
	BUs,
	totalRecords,
	loadBUs,
	loading,
	setParams,
	editedBU,
	changeBU,
	deleteBU,
}: {
	BUs: BUContact[],
	totalRecords: number,
	loadBUs: Function,
	loading: boolean,
	setParams: Function,
	editedBU?: string,
	changeBU: Function,
	deleteBU: Function,
}) => (
	<Layout>
		<Layout.Content>
			<Button className={styles.button} type="primary" loading={loading} onClick={loadBUs}>
				<FormattedMessage id="Proceq.TableActionRefresh" />
			</Button>
			<SmarterTable
				rowKey="bu"
				columns={[
					{
						title: 'Proceq.FormAccountTypeBU',
						dataIndex: 'bu',
						globalRegister,
						render: (text, record) => (
							<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
								<span style={{ alignSelf: 'flex-start', flex: 1 }}>
									{renderCell({ globalRegister })(text)}
								</span>
								&nbsp; &nbsp;
								<Button type="primary" onClick={() => changeBU(record)}>
									{record.email ? <Icon type="edit" /> : <Icon type="plus" />}
								</Button>
								{record.email && (
									<Popconfirm
										title={
											<FormattedMessage
												id="App.TableActionDeletePrompt"
												values={{ subject: record.bu }}
											/>
										}
										onConfirm={deleteBU(record.id)}
										okText={<FormattedMessage id="Proceq.TextYes" />}
										cancelText={<FormattedMessage id="Proceq.TextNo" />}
									>
										&nbsp; &nbsp;
										<Button type="danger">
											<Icon type="delete" />
										</Button>
									</Popconfirm>
								)}
							</div>
						),
					},
					...columnConfig,
				]}
				dataSource={getTextValue(['buunits']).map(({ value: bu }) => ({
					bu,
					...find(BUs, { bu }),
				}))}
				loadDataSource={loadBUs}
				loading={loading}
				setParams={setParams}
				totalRecords={totalRecords}
			/>
			<Modal visible={editedBU !== null} footer={null} onCancel={() => changeBU(null)}>
				<AddEditBU bu={editedBU} changeBU={changeBU} loadBUs={loadBUs} />
			</Modal>
		</Layout.Content>
	</Layout>
);

export default compose(
	withState('editedBU', 'changeBU', null),
	withState('loading', 'setLoading', true),
	withState('BUs', 'setBUs', []),
	withState('totalRecords', 'setTotalRecords', 0),
	withState('params', 'setParams', { start: 0, length: 20 }),
	connect(null, (dispatch: Function, props) => ({
		loadBUs: () => {
			dispatch(
				request({
					url: URL.GET_BUS,
					setLoading: props.setLoading,
					params: props.params,
				})
			).then(data => {
				props.setBUs(data.data);
				props.setTotalRecords(data.recordsFiltered);
			});
		},
	})),
	connect(null, (dispatch: Function, props) => ({
		deleteBU: id => () => {
			dispatch(
				request({
					method: 'DELETE',
					url: URL.DELETE_BU.replace('{key}', id),
					setLoading: props.setLoading,
				})
			).then(props.loadBUs);
		},
	})),
	lifecycle({
		componentDidMount() {
			this.props.loadBUs();
		},
	})
)(BusinessUnits);
