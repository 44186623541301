// @flow
import React, { Component } from 'react';
import { DatePicker as DatePickerComponent } from 'antd';
import { getDateLocaleFormat } from 'utils/dateHandler';
import styles from './DatePicker.styl';

// eslint-disable-next-line react/prefer-stateless-function
export class DatePicker extends Component<Object> {
	render() {
		return <DatePickerComponent format={getDateLocaleFormat()} className={styles.datepicker} {...this.props} />;
	}
}

export default DatePicker;
