// @flow
import React from 'react';
import classnames from 'classnames';
import { get, noop } from 'lodash';
import { FormattedMessage } from 'react-intl';
import { ProductConstants } from 'components/contracts/contractConsts';
import ConvertedUnits from '../../../shared/ConvertedUnits';
import InfoIcon from '../icoAboutCopy2.png';
import styles from './ObjectMarker.styl';

export const ObjectMarker = ({
	log,
	product,
	isMetric,
	scanType,
	highlighted,
	toggleHighlight,
}: {
	highlighted: boolean,
	toggleHighlight: Function,
} & GPRLogbookEntryProps) => {
	const { status, sequence, lineId, changes } = log.content;
	let { type } = log.content;
	const size = get(changes, 'sizeCentimeter');
	const depth = get(changes, 'calibratedDepthString');
	if (product === ProductConstants.PUNDIT && type === 'Velocity') {
		type = 'Pulse Velocity';
	}

	let id;
	switch (status) {
		case 'added':
			id = lineId ? 'App.LogbookAddedAt' : 'App.LogbookAdded';
			break;
		case 'edited':
			id = lineId ? 'App.LogbookEditedAt' : 'App.LogbookEdited';
			break;
		case 'deleted':
			id = lineId ? 'App.LogbookDeletedAt' : 'App.LogbookDeleted';
			break;
		default:
			return null;
	}

	return (
		<div
			className={classnames({
				toggle: process.env.HTML_EXPORT_VERSION && status !== 'deleted',
				[styles.message_container]: true,
				[styles.highlightable]: status !== 'deleted',
				[styles.highlighted]: highlighted,
			})}
			onClick={status === 'deleted' ? noop : toggleHighlight}
			role="button"
			tabIndex={0}
		>
			<b>
				<FormattedMessage
					id={id}
					values={{
						sequence,
						lineId,
					}}
				/>
			</b>
			<br />
			{status === 'added' && (
				<FormattedMessage
					id="App.LogbookType"
					values={{
						type,
					}}
				/>
			)}
			{status === 'edited' && !changes && <FormattedMessage id="App.LogbookPositionMoved" />}
			{size && (
				<div>
					<ConvertedUnits
						id="App.LogbookSetSize"
						unitId="GPR.Logbook Panel.Set Size"
						scanType={scanType}
						isMetric={isMetric}
						values={[size]}
					/>
				</div>
			)}
			{depth && (
				<div>
					<ConvertedUnits
						id="App.LogbookSetDepth"
						unitId="GPR.Logbook Panel.Set Depth"
						scanType={scanType}
						isMetric={isMetric}
						values={[depth]}
					/>
				</div>
			)}
			{status !== 'deleted' && <img alt="icon" className={styles.info_icon} src={InfoIcon} />}
		</div>
	);
};

export const color = (log: Log) => (log.content.status === 'deleted' ? 'red' : 'green');

export default ObjectMarker;
