// @flow
import React from 'react';
import moment from 'moment';
import { compose, withState, lifecycle } from 'recompose';
import { connect } from 'react-redux';
import URL from 'apis/urls';
import { Button, Dropdown, Menu, Icon } from 'antd';
import { FormattedMessage } from 'react-intl';
import { request } from 'actions/ajax';
import styles from '../shared/SmarterTable.styl';

export const DownloadWithURL = ({
	iDs,
	loading,
	download,
	items,
}: {
	iDs: string[],
	loading: boolean,
	download: Function,
	items: Object,
}) => (
	<Dropdown
		disabled={iDs.length < 1}
		overlay={
			<Menu onClick={({ key }) => download(key)}>
				{(() => {
					return items.map(item => (
						<Menu.Item key={item.key}>
							<FormattedMessage id={item.id} />
						</Menu.Item>
					));
				})()}
			</Menu>
		}
	>
		<Button type="primary" loading={loading} className={styles.button}>
			<FormattedMessage id="Proceq.DownloadWithURL" /> <Icon type="down" />
		</Button>
	</Dropdown>
);

export default compose(
	withState('loading', 'setLoading', false),
	withState('items', 'setItems', []),
	connect(null, (dispatch: Function, props) => ({
		download: key =>
			dispatch(
				request({
					method: 'POST',
					url: URL.EXPORT_REPORT_AS_URL,
					params: {
						product: props.product,
						fileType: 'measurement',
						format: key,
						iDs: props.iDs,
						timeZoneOffset: moment().utcOffset(),
					},
					setLoading: props.setLoading,
					returnResponseHeaders: true,
				})
			).then(response => window.open(response.data.url, '_blank')),
	})),
	lifecycle({
		componentDidUpdate(prevProps) {
			if (prevProps.items === []) {
				this.setItems(this.props.items);
			}
		},
		componentDidMount() {
			if (this.props.items === undefined || this.props.items.length === 0) {
				const itm = [];
				switch (this.props.product) {
					case 'GPR_SOIL':
						itm.push({ key: 'segy', id: 'Proceq.TableActionExportSEGY', responseType: 'text' });
						break;
					case 'GPR':
						itm.push({ key: 'segy', id: 'Proceq.TableActionExportSEGY', responseType: 'text' });
						break;
				}
				this.props.setItems(itm);
			}
		},
	})
)(DownloadWithURL);
