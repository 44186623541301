// @flow
import React from 'react';
import { FormattedMessage } from 'react-intl';

export const Snapshots = () => (
	<div>
		<b>
			<FormattedMessage id="App.LogbookPropertySnapshotExported" />
		</b>
	</div>
);

export default Snapshots;
