import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage/session';
import { user } from './user';
import { locale } from './locale';
import { latencyLogs } from './export/latencylogs';
import { globalregister } from './globalregister';
import { session } from './session';

const sessionPersistConfig = {
	key: 'session',
	storage,
};

const rootReducer = combineReducers({
	user,
	locale,
	globalregister,
	session: persistReducer(sessionPersistConfig, session),
	export: combineReducers({
		latencyLogs,
	}),
});

export default rootReducer;
