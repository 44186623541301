// @flow
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { renderLogbookDate } from 'utils/dateHandler';

export const GLMProbe = ({ log: { content } }: { log: Log }) => {
	return (
		<div className="full-container intro-container">
			<div>
				<div className="initial-content">
					<b>{content.probeModel}</b>
					<br />
					<FormattedMessage id="Proceq.LogbookPropertySerialNumberShort" />
					{': '}
					{content.probeSerialNo}
					<br />
					<FormattedMessage id="Proceq.LogbookPropertyBluetoothModuleShort" />
					{': '}
					{content.btmSN}
					<br />
					{!!content.contractType && (
						<div>
							<FormattedMessage id="Proceq.LogbookContractType" />
							{': '}
							{content.contractType}
						</div>
					)}
					<FormattedMessage id="Proceq.LogbookPropertyContractValidUntil" />
					{': '}
					{content.contractExpirationDate ? (
						content.contractExpirationDate === 'Lifetime' ? (
							<FormattedMessage id="Proceq.TextLifetime" />
						) : (
							renderLogbookDate(content.contractExpirationDate)
						)
					) : (
						<FormattedMessage id="Proceq.TextUnknown" />
					)}
					<br />
					<FormattedMessage id="Proceq.LogbookPropertyLastVerification" />
					{': '}
					{content.verifiedOn ? (
						renderLogbookDate(content.verifiedOn)
					) : (
						<FormattedMessage id="Proceq.AppPlaceholder" />
					)}
					<br />
					<FormattedMessage id="Proceq.LogbookPropertyLastAdjust" />
					{': '}
					{content.adjustedOn ? (
						renderLogbookDate(content.adjustedOn)
					) : (
						<FormattedMessage id="Proceq.AppPlaceholder" />
					)}
					<br />
					<FormattedMessage id="Proceq.LogbookPropertyLastPremiumCalibration" />
					{': '}
					{content.calibrationOn ? (
						renderLogbookDate(content.calibrationOn)
					) : (
						<FormattedMessage id="Proceq.AppPlaceholder" />
					)}
					<br />
				</div>
			</div>
		</div>
	);
};

export default GLMProbe;
