import { isEmpty } from 'lodash';
import URL from 'apis/urls';
import { httpRequest } from 'apis/httpClient';
import { checkStatusJson } from 'apis/restUtils';
import errorHandler from 'actionUtils/errorHandler';
import { getProceqUrl } from './sessionHelper';

export function startDownloadFile(session, file_data, file_method /* , file_mime, file_size, file_name */) {
	const endpoint = getProceqUrl(session, URL.PROCEQ_FILE_DOWNLOADS);
	if (file_method === 'dwl_id') {
		const locationRef = `${endpoint}?dwl_id=${file_data}`;
		const isSafari = !!navigator.userAgent.match(/Version\/[\d.]+.*Safari/);
		if (!isSafari) {
			window.location.href = locationRef;
		} else {
			const isMobileSafari =
				!!navigator.userAgent.match(/.*iPad.*Version\/[\d.]+.*Safari/) ||
				!!navigator.userAgent.match(/.*iPhone.*Version\/[\d.]+.*Safari/) ||
				!!navigator.userAgent.match(/.*iPod.*Version\/[\d.]+.*Safari/);
			if (isMobileSafari) {
				const downloadurl = locationRef;
				const newWin = window.open(downloadurl);
				if (!newWin || newWin.closed || typeof newWin.closed === 'undefined') {
					// POPUP BLOCKED
					window.location.href = downloadurl;
				} else {
					// POPUP WORKED!!
				}
			} else {
				// we no longer use jQuery.fileDownload
				window.location.href = locationRef;
			}
		}
	} else if (file_method === 'url') {
		window.location.href = file_data;
	}
}

export function requestDownloadFile(session, fileId) {
	const endpoint = getProceqUrl(session, URL.PROCEQ_REQUEST_FILE_DOWNLOAD);
	httpRequest(session, null, endpoint, 'post', true, { file_id: fileId })
		.then(checkStatusJson)
		.then(json => {
			if (!isEmpty(json) && 'data' in json && 'file_data' in json.data) {
				const fileInfo = json.data;
				startDownloadFile(
					session,
					fileInfo.file_data,
					fileInfo.file_method,
					fileInfo.file_mime,
					fileInfo.file_size,
					fileInfo.file_name
				);
			}
		}, this)
		.catch(err => {
			errorHandler(err);
		}, this);
}

export function getFileNameFromContentDisposition(contentDisposition) {
	const fileNames = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(contentDisposition) || [''];
	return fileNames.length > 1 ? decodeURIComponent(fileNames[1].substring(7)) : '';
}
