// @flow
import React from 'react';
import type { InjectIntlProvidedProps } from 'react-intl';
import { injectIntl } from 'react-intl';
import handleFormatMessage from 'utils/handleFormatMessage';
import { getScaleInfoOfScaleId } from 'utils/conversionsHelper';

export const Scale = ({
	intl,
	log,
	product,
}: InjectIntlProvidedProps & { log: ScaleLog } & EquotipLogbookEntryProps) => (
	<span>
		{handleFormatMessage('LogbookPropertySecondaryScaleChangedFromTo', intl.formatMessage, [
			getScaleInfoOfScaleId(intl.formatMessage, log.content.oldScaleId, product).scaleUnits,
			getScaleInfoOfScaleId(intl.formatMessage, log.content.updatedScaleId, product).scaleUnits,
		])}
	</span>
);

export default injectIntl(Scale);
