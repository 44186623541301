// @flow
import React, { Fragment } from 'react';
import { get } from 'lodash';
import { Table } from 'antd';
import { FormattedMessage } from 'react-intl';
import { convertAndDisplay } from 'constants/conversionRates';
import type { FDLMeasurementPresetsProps } from './MeasurementPresets';
import styles from '../../../HTMLExport.styl';

export const title = (
	<Fragment>
		<FormattedMessage id="Proceq.ExportFieldSettings" />
		{' ­­» '}
		<FormattedMessage id="App.DashboardCorrosionGrid" />
	</Fragment>
);

export const CorrosionGrid = ({
	data,
	showTitle,
	unit,
}: { data: MeasurementFullData } & FDLMeasurementPresetsProps) => {
	const startingX = get(data, 'settings.0.content.indicationStatus.corrosionGridInfo.startingPosition.xIndex', 0);
	const startingY = get(data, 'settings.0.content.indicationStatus.corrosionGridInfo.startingPosition.yIndex', 0) + 1;

	// the app view defaults to thickness, doesn't track if it was changed to amplitude
	const { convertFunction, unit: displayUnit } = convertAndDisplay('FDL.CorrosionGrid.thickness', 'Indication', unit);

	const columns = [
		{
			dataIndex: 'content.indicationPosition.gridPosition.xIndex',
			title: <FormattedMessage id="App.HTML.FDL.MeasurementPresets.CorrosionGrid.Cells" />,
			render: (text, row) => {
				const X = get(row, 'content.indicationPosition.gridPosition.xIndex', 0) + startingX;
				const Y = get(row, 'content.indicationPosition.gridPosition.yIndex', 0) + startingY;
				let x1 = '';
				let x2 = '';
				if (X >= 26) {
					x1 = String.fromCharCode('A'.charCodeAt(0) + (X / 26 - 1));
				}
				x2 = String.fromCharCode('A'.charCodeAt(0) + (X % 26));
				return x1 + x2 + Y;
			},
			className: styles.column,
		},
		{
			title: displayUnit,
			render: (text, row) => {
				const { thickness, time } = get(row, 'content.indicationSettings.info.corrosionGridIndicationInfo', {});

				return unit === 'µs' ? convertFunction(time) : convertFunction(thickness);
			},
		},
	];

	const dataSource = data.readings.sort((a, b) => {
		const aX = get(a, 'content.indicationPosition.gridPosition.xIndex', 0);
		const aY = get(a, 'content.indicationPosition.gridPosition.yIndex', 0);

		const bX = get(b, 'content.indicationPosition.gridPosition.xIndex', 0);
		const bY = get(b, 'content.indicationPosition.gridPosition.yIndex', 0);

		if (aX === bX) {
			return aY - bY;
		}

		return aX - bX;
	});

	return (
		<Table
			title={showTitle ? () => <span className={styles.sub_header}>{title}</span> : null}
			className={styles.table}
			columns={columns}
			dataSource={dataSource}
			pagination={false}
			size="small"
		/>
	);
};

export default CorrosionGrid;
