// @flow
import React, { Fragment } from 'react';
import { has, get } from 'lodash';
import { Table } from 'antd';
import { FormattedMessage } from 'react-intl';
import ConvertedUnits from 'components/shared/ConvertedUnits';
import { IsEqualOrAfterPD8050Launch, IsProContractType, MeasurementScanType } from 'constants/exportConstants';
import { renderFolder } from 'utils/fileUtils';
import type { DerivedProps } from '../../../Measurement';
import styles from '../../../HTMLExport.styl';

export const title = (
	<Fragment>
		<FormattedMessage id="Proceq.ExportFieldSettings" />
		{' ­­» '}
		<FormattedMessage id="App.HTML.GPR.Settings.MeasurementPresets" />
	</Fragment>
);

const PunditGridScanDataOfInterestType = {
	BackwallDepth: 'Backwall Depth',
	PulseVelocity: 'Pulse Velocity',
};

const getPWave = (sWave, poissonRatio) => {
	if (
		sWave === undefined ||
		poissonRatio === undefined ||
		typeof sWave !== 'number' ||
		typeof poissonRatio !== 'number'
	)
		return '-';
	return Math.sqrt((2 * sWave ** 2 * (poissonRatio - 1)) / (2 * poissonRatio - 1));
};

export const MeasurementPresets = ({
	data,
	scanType,
	isMetric,
	convert,
	showTitle,
}: { data: MeasurementFullData } & DerivedProps) => {
	const isStripeScan = get(data, 'measurement.type') === MeasurementScanType.StripeScan;
	const isGridScan = get(data, 'measurement.type') === MeasurementScanType.GridScan;
	const gridScanDataOfInterest = isGridScan ? data.settings[0].content.preset.dataInterest : undefined;
	const isIQSAFT = get(data, 'settings.0.content.process.isIQSAFT');
	const version = get(data, 'measurement.content.info.version');

	const compressiveStrengthId = has(data, 'settings.0.content.process.compressiveStrength')
		? `Proceq.LogbookSettingsCompressiveStrength.${data.settings[0].content.process.compressiveStrength}`
		: '-';
	const compressiveStrength = <FormattedMessage id={compressiveStrengthId} />;

	// refs: https://jira.screeningeagle.com/browse/PDLIV-503
	// TempFix : whether to enable Macro should actually be checked from the features of contractId.Tier
	const isEnableMacro =
		(isIQSAFT && !IsEqualOrAfterPD8050Launch(version)) ||
		(IsProContractType(data) && IsEqualOrAfterPD8050Launch(version));
	const presets = [
		{
			label: 'App.HTML.GPR.MeasurementPresets.FileName',
			value: get(data, 'measurement.name'),
		},
		{
			label: 'App.HTML.GPR.MeasurementPresets.FileID',
			value: get(data, 'measurement.id'),
		},
		{
			label: 'Proceq.StatisticsFolder',
			value: <FormattedMessage id={renderFolder(data)} />,
		},
		{
			label: 'App.HTML.GPR.MeasurementPresets.Mode',
			value: get(data, 'measurement.type'),
		},
		{
			label: 'App.HTML.PUNDIT.MeasurementPresets.DataInterest',
			value: get(data, 'settings.0.content.preset.dataInterest'),
			enable: isGridScan,
		},
		{
			label: 'App.HTML.PUNDIT.Display.Macro',
			value: get(data, 'settings.0.content.preset.macro'),
			enable: isEnableMacro,
		},
		{
			label: 'App.HTML.PUNDIT.Display.HalfCycle',
			value: get(data, 'settings.0.content.preset.isHalfCycle') ? 'On' : 'Off',
		},
		{
			label: 'App.LogbookSettingsAnalogGain',
			unitId: 'PUNDIT.Settings Panel.Analogue Gain',
			value: get(data, 'settings.0.content.preset.analogueGain'),
		},
		{
			label: 'App.LogbookSettingsTimeGainCompensation',
			unitId: 'PUNDIT.Settings Panel.Time Gain Compensation',
			value: get(data, 'settings.0.content.preset.timeGainCompensation'),
		},
		{
			label: isStripeScan
				? 'App.HTML.PUNDIT.MeasurementPresets.YSpacing'
				: 'App.HTML.PUNDIT.MeasurementPresets.XSpacing',
			unitId: (() => {
				let overlap = get(data, 'settings.0.content.process.overlap');
				overlap = overlap === 0 ? overlap : -1 * overlap;
				return overlap < 0
					? 'PUNDIT.Settings Panel.Gap and Overlap slider Channels'
					: 'PUNDIT.Settings Panel.Gap and Overlap slider';
			})(),
			value: (() => {
				let overlap = get(data, 'settings.0.content.process.overlap');
				overlap = overlap === 0 ? overlap : -1 * overlap;
				return overlap;
			})(),
		},
		{
			label: 'App.HTML.PUNDIT.MeasurementPresets.AScan',
			value: get(data, 'settings.0.content.process.aScanDrawingMode'),
		},
		{
			label: 'App.HTML.PUNDIT.MeasurementPresets.Channels',
			value: get(data, 'settings.0.content.preset.numberOfChannels.value'),
		},
		{
			label: 'App.HTML.PUNDIT.Display.ImageStabilizer',
			value: Math.round(get(data, 'settings.0.content.preset.averaging')),
			enable: isIQSAFT,
		},
		{
			label: 'App.HTML.PUNDIT.MeasurementPresets.PulseDelay',
			unitId: 'PUNDIT.Settings Panel.Pulse Delay',
			value: get(data, 'settings.0.content.preset.pulseDelay'),
		},
		{
			label: 'Proceq.LogbookSettingsBackwallDepth',
			unitId: 'PUNDIT.GridScan.BackwallDepth',
			value: data.settings[0].content.preset.presumedBackwallDepth,
			enable: isGridScan && gridScanDataOfInterest === PunditGridScanDataOfInterestType.PulseVelocity,
		},
		{
			label: 'App.LogbookSettingsPulseVelocity',
			unitId: 'PUNDIT.Settings Panel.Pulse Velocity',
			value: get(data, 'settings[0].content.process.pulseVelocity'),
			enable: gridScanDataOfInterest !== PunditGridScanDataOfInterestType.PulseVelocity,
		},
		{
			label: 'Proceq.LogbookSettingsPulseVelocityPWave',
			unitId: 'PUNDIT.Settings Panel.Pulse Velocity',
			value: getPWave(
				get(data, 'settings[0].content.process.pulseVelocity'),
				get(data, 'settings[0].content.process.poissonRatio')
			),
		},
		{
			label: 'Proceq.LogbookSettingsCompressiveStrength',
			value: compressiveStrength,
		},
		{
			label: 'Proceq.LogbookSettingsPoissonRatio',
			unitId: 'PUNDIT.Settings Panel.Poisson Ratio',
			value: get(data, 'settings[0].content.process.poissonRatio'),
		},
		{
			label: 'App.HTML.PUNDIT.MeasurementPresets.TxFrequency',
			unitId: 'PUNDIT.Snapshot.Center Frequency',
			value: get(data, 'settings.0.content.preset.transmitFrequency') / 1000,
			enable: IsEqualOrAfterPD8050Launch(version),
		},
		{
			label: 'App.HTML.PUNDIT.MeasurementPresets.TxVoltage',
			unitId: 'PUNDIT.MeasurementPresets.Energy',
			value: get(data, 'settings.0.content.preset.transmitVoltage'),
			enable: IsEqualOrAfterPD8050Launch(version),
		},
		{
			label: 'App.HTML.PUNDIT.Display.MaxTransmissionTime',
			unitId: 'PUNDIT.Logbook Panel.Max Transmission Time',
			value: get(data, 'settings.0.content.preset.numberOfSamples'),
			enable: !isEnableMacro || IsEqualOrAfterPD8050Launch(version),
		},
	]
		.filter(row => row.enable === undefined || row.enable === true)
		.map(row => ({
			label: row.unitId ? (
				<ConvertedUnits id={row.label} unitId={row.unitId} scanType={scanType} isMetric={isMetric} />
			) : (
				<FormattedMessage id={row.label} />
			),
			value: row.unitId ? convert(row.value, row.unitId) : row.value,
			key: row.label,
		}));

	return (
		<Table
			title={showTitle ? () => <span className={styles.sub_header}>{title}</span> : null}
			className={styles.table}
			dataSource={presets}
			pagination={false}
			showHeader={false}
			size="small"
		>
			<Table.Column dataIndex="label" className={styles.column} />
			<Table.Column dataIndex="value" />
		</Table>
	);
};

export default MeasurementPresets;
