// @flow
import React from 'react';
import { get, has } from 'lodash';
import { Table } from 'antd';
import { FormattedMessage } from 'react-intl';
import { getLabelFromGlobalDataKey, getTextValue } from 'utils/registryHelper';
import type { DerivedProps } from 'components/measurements/HTMLExport/Measurement';
import styles from 'components/measurements/HTMLExport/HTMLExport.styl';
import { ProceqMA8000 } from 'constants/exportConstants';
import { ProductConstants } from '../../../../contracts/contractConsts';

export const title = <FormattedMessage id="User / Probe Info" />;

export const User = ({ data, productModel, showTitle }: { data: MeasurementFullData } & DerivedProps) => {
	const isPI8000 = productModel.toUpperCase() === ProductConstants.PI8000;
	const probeLog = data.logs && data.logs.find(log => log.type === 'probe');
	const probeSerialNo = get(data, 'measurement.content.info.probeSerialNumber');
	const isSoil = productModel.toUpperCase() === ProductConstants.GPR_SOIL;
	const isMA8000 = isSoil && has(probeLog, 'content.ma8000SerialNumber');

	const user = [
		{
			label: 'Proceq.LogbookPropertyOperator',
			value: get(data, 'measurement.content.info.author'),
		},
		{
			label: 'Proceq.FormEmailGroupID',
			value: get(data, 'measurement.content.info.email'),
		},
		{
			label: 'Product',
			value: (
				<div>
					<b>{getLabelFromGlobalDataKey('productmodel', productModel.toUpperCase())}</b>
					<br />
					{isMA8000 && <b>{ProceqMA8000}</b>}
				</div>
			),
			enable: getTextValue(['productmodel'])
				.map(({ value }) => value)
				.includes(productModel.toUpperCase()),
		},
		{
			label: data.productFamily.toUpperCase() === 'FDL' ? 'Base Unit S/N' : 'Probe S/N',
			value: isPI8000 ? (
				get(data, 'measurement.probeinfo.serialNumber')
			) : isMA8000 ? (
				<div>
					{probeSerialNo} <br /> {get(probeLog, 'content.ma8000SerialNumber')}
				</div>
			) : (
				probeSerialNo
			),
		},
		{
			label: 'Hardware Version',
			value: isPI8000
				? get(data, 'measurement.probeinfo.hardwareRevision')
				: get(data, 'measurement.content.info.hardwareVersion'),
		},
		{
			label: 'Firmware Version',
			value: isPI8000
				? get(data, 'measurement.probeinfo.firmwareVersion')
				: get(data, 'measurement.content.info.firmwareVersion'),
		},
		{
			label: 'App Version',
			value: get(data, 'measurement.content.info.version'),
		},
		{
			label: 'Contract ID',
			value: isPI8000 ? get(data, 'measurement.contractID') : get(data, 'measurement.content.info.contractID'),
			enable: !isSoil,
		},
		{
			label: 'MAC Address',
			value: get(data, 'measurement.content.info.macAddress'),
			enable: !isPI8000 && !isSoil,
		},
		{
			label: 'Calibration Date',
			value: get(data, 'measurement.content.info.calibrationDate'),
			enable: data.productFamily.toUpperCase() === 'FDL',
		},
	]
		.filter(row => row.enable === undefined || row.enable === true)
		.map((row, key) => ({
			key,
			...row,
			label: <FormattedMessage id={row.label} />,
		}));

	return (
		<Table
			title={showTitle ? () => <span className={styles.main_header}>{title}</span> : null}
			className={styles.table}
			dataSource={user}
			pagination={false}
			showHeader={false}
			size="small"
		>
			<Table.Column dataIndex="label" width={230} />
			<Table.Column dataIndex="value" />
		</Table>
	);
};

export default User;
