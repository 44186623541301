// @flow
import React from 'react';
import type { InjectIntlProvidedProps } from 'react-intl';
import { injectIntl } from 'react-intl';
import handleFormatMessage from 'utils/handleFormatMessage';
import { getMaterialNameOfMaterialId } from 'utils/conversionsHelper';

export const Material = ({
	intl,
	log,
}: InjectIntlProvidedProps & /* { log: MaterialLog } & */ EquotipLogbookEntryProps) => (
	<span>
		{handleFormatMessage('LogbookPropertyMaterialChangedFromTo', intl.formatMessage, [
			log.content.oldCustomMaterialName
				? log.content.oldCustomMaterialName
				: getMaterialNameOfMaterialId(null, intl.formatMessage, log.content.oldMaterialId),
			log.content.updatedCustomMaterialName
				? log.content.updatedCustomMaterialName
				: getMaterialNameOfMaterialId(null, intl.formatMessage, log.content.updatedMaterialId),
		])}
	</span>
);

export default injectIntl(Material);
