// @flow
import { get } from 'lodash';
import type { Key, Data, DataLabel } from 'constants/global-registered-info';
import { GLOBAL_REGISTERED_INFO } from 'constants/global-registered-info';

type GlobalRegisterState = {
	registeredKeyValues: {
		[Key]: DataLabel[],
	},
};

export function isKeyDefined(globalRegister: GlobalRegisterState, key: Key) {
	if (key in globalRegister.registeredKeyValues) {
		return true;
	}
	return false;
}

export function getKeyValue(globalRegister: GlobalRegisterState, keyRegister: Key) {
	if (keyRegister in globalRegister.registeredKeyValues) {
		return globalRegister.registeredKeyValues[keyRegister];
	}
	return null;
}

export function getKeyJSONValue(globalRegister: GlobalRegisterState, keyRegister: Key) {
	if (keyRegister in globalRegister.registeredKeyValues) {
		return JSON.stringify(globalRegister.registeredKeyValues[keyRegister]);
	}
	return null;
}

// export function getLabelFromDataKey(globalRegister: GlobalRegisterState, keyRegister: Key, data: Data) {
// 	if (!(keyRegister in globalRegister.registeredKeyValues)) {
// 		return null;
// 	}
// 	data += '';
// 	for (let indexEntry = 0; indexEntry < globalRegister.registeredKeyValues[keyRegister].length; indexEntry++) {
// 		if (globalRegister.registeredKeyValues[keyRegister][indexEntry].data === data) {
// 			return globalRegister.registeredKeyValues[keyRegister][indexEntry].label;
// 		}
// 	}
// 	return null;
// }

export const getGlobalRegister = (key: Key) =>
	get(
		GLOBAL_REGISTERED_INFO.find(v => v.key === key),
		'data',
		[]
	);

const registeredKeyValues = {};
GLOBAL_REGISTERED_INFO.forEach(item => {
	registeredKeyValues[item.key] = item.data;
});

export const globalregister = {
	registeredKeyValues,
};

export const getLabelFromGlobalDataKey = (key: Key, data: Data) =>
	get(
		getGlobalRegister(key).find(v => v.data === String(data)),
		'label',
		null
	);

export const getDataFromGlobalDataLabel = (key: Key, data: Data) =>
	get(
		getGlobalRegister(key).find(v => v.label === String(data)),
		'data',
		null
	);

export const getDataFromGlobalDataIndex = (key: Key, index: number) => get(getGlobalRegister(key)[index], 'data', null);

export const getLabelFromGlobalDataIndex = (key: Key, index: number) =>
	get(getGlobalRegister(key)[index], 'label', null);

export const getTextValue = (keys: string[]): { text: string, value: any }[] =>
	[].concat(...keys.map(getGlobalRegister)).map(({ label, data }) => ({ text: label, value: data }));

export function getOnlyDataFromGlobalDataKey(key: Key) {
	const dataArray = [];
	const dataMap = get(
		GLOBAL_REGISTERED_INFO.find(v => v.key === key),
		'data',
		[]
	);
	dataMap.forEach(element => {
		dataArray.push(get(element, 'data'));
	});
	return dataArray;
}
