// @flow
import React from 'react';
import { get } from 'lodash';
import { Form, Input, Checkbox, Button, Tooltip, Icon } from 'antd';
import type { FormProps } from 'antd/lib/form';
import { connect } from 'react-redux';
import { compose, withState, lifecycle } from 'recompose';
import type { InjectIntlProvidedProps } from 'react-intl';
import { injectIntl, FormattedMessage } from 'react-intl';
import Select from 'components/shared/SmarterFormItems/Select';
import styles from 'components/shared/SmarterTable.styl';
import { request } from 'actions/ajax';
import { hasRoleLevel, ROLES } from 'utils/rolesHelper';
import URL from 'apis/urls';
import featureStyles from './Feature.styl';
import { productComparator } from '../contracts/contractConsts';

export const AddEditFeature = ({
	loading,
	form,
	feature,
	products,
	changeFeature,
	addFeature,
	editFeature,
	prodOptions,
}: {
	loading: boolean,
	form: FormProps,
	feature?: Feature,
	products: Product[],
	changeFeature: Function,
	addFeature: Function,
	editFeature: Function,
	prodOptions: SelectOpts[],
} & InjectIntlProvidedProps) => {
	const onSubmit = e => {
		e.preventDefault();
		form.validateFields((err, values) => {
			if (err) {
				return;
			}
			if (values.code === undefined) {
				addFeature(values);
			} else {
				editFeature(values);
			}
		});
	};
	const contractTypeOpt = [
		{
			name: 'Device',
			value: '0',
		},
		{
			name: 'Maintenance Services',
			value: '1',
		},
		{
			name: 'Software',
			value: '2',
		},
	];

	return (
		<Form onSubmit={onSubmit}>
			<div>
				{feature && (
					<Form.Item label={<FormattedMessage id="Feature Code" />} className={featureStyles.hidden}>
						{form.getFieldDecorator('code', {
							initialValue: feature ? feature.code : '',
							rules: [{ required: true }],
						})(<Input />)}
					</Form.Item>
				)}
			</div>
			<div>
				<Form.Item label={<FormattedMessage id="Product" />} className={styles.half_field}>
					{products !== undefined &&
						form.getFieldDecorator('product', {
							rules: [{ required: true }],
						})(
							<Select
								disabled={feature !== undefined && feature.code !== undefined && feature.code !== ''}
								selectOpt={prodOptions}
							/>
						)}
				</Form.Item>
			</div>
			<div>
				<Form.Item label={<FormattedMessage id="Feature Type" />} className={styles.half_field}>
					{form.getFieldDecorator('contractType', {
						initialValue: feature ? String(feature.contractType) : '0',
						rules: [{ required: true }],
					})(
						<Select
							selectOpt={contractTypeOpt}
							disabled={!!(feature !== undefined && feature.contractType !== undefined)}
						/>
					)}
				</Form.Item>
			</div>
			{form !== undefined && form.getFieldValue('product') === 'COMMON' && (
				<div>
					<span className={featureStyles.common_prod_message}>
						{'A Feature with Common product can be added in all tiers of any product.'}
					</span>
				</div>
			)}
			<div>
				<Form.Item label={<FormattedMessage id="Description" />} className={styles.field}>
					{form.getFieldDecorator('description', {
						initialValue: feature ? feature.description : '',
						rules: [{ required: true }],
					})(<Input />)}
				</Form.Item>
			</div>
			<div>
				<Form.Item>
					{form.getFieldDecorator('enable', {
						initialValue: feature ? feature.enable === 1 : false,
						valuePropName: 'checked',
					})(
						<Checkbox>
							<FormattedMessage id="Enable" />
							&nbsp;&nbsp;&nbsp;
							<Tooltip
								title={
									<FormattedMessage id="If disabled feature can not be associated with any tier." />
								}
							>
								<Icon type="info-circle" />
							</Tooltip>
						</Checkbox>
					)}
				</Form.Item>
			</div>
			<Form.Item className={styles.submit}>
				<Button type="default" onClick={() => changeFeature(null)}>
					<FormattedMessage id="Proceq.LogbookClose" />
				</Button>
				&nbsp;
				<Button type="primary" htmlType="submit" loading={loading}>
					<FormattedMessage id="Proceq.ButtonSubmit" />
				</Button>
			</Form.Item>
		</Form>
	);
};

export default compose(
	Form.create({
		mapPropsToFields: props => {
			const fields = {};
			['product', 'code', 'enable', 'description'].forEach(k => {
				const value = get(props, ['feature', k]);
				fields[k] = Form.createFormField({ value });
			});
			return fields;
		},
	}),
	withState('loading', 'setLoading', false),
	withState('prodOptions', 'setProdOptions', []),
	connect(
		state => ({
			isAdmin: hasRoleLevel(state.session.scopes, ROLES.ADMIN),
		}),
		(dispatch: Function, props) => ({
			addFeature: params => {
				params.enable = params.enable === 1 ? true : params.enable === 0 ? false : params.enable;
				dispatch(
					request({
						method: 'POST',
						url: URL.ADD_EDIT_FEATURE,
						setLoading: props.setLoading,
						params: {
							...params,
						},
					})
				).then(() => {
					props.changeFeature(null);
					props.loadFeatures();
				});
			},
			editFeature: params => {
				params.enable = params.enable === 1 ? true : params.enable === 0 ? false : params.enable;
				dispatch(
					request({
						method: 'PUT',
						url: URL.ADD_EDIT_FEATURE,
						setLoading: props.setLoading,
						params: {
							...params,
						},
					})
				).then(() => {
					props.changeFeature(null);
					props.loadFeatures();
				});
			},
		})
	),
	lifecycle({
		componentDidMount() {
			const options = [];
			options.push({ name: 'Common', value: 'COMMON' });
			this.props.products.map(function(prod) {
				const obj = {
					name: prod.name,
					value: prod.code,
				};
				options.push(obj);
			});
			options.sort(productComparator('name'));
			this.props.setProdOptions(options);
		},
	}),
	injectIntl
)(AddEditFeature);
