// @flow
import React from 'react';
import type { InjectIntlProvidedProps } from 'react-intl';
import { injectIntl } from 'react-intl';
import handleFormatMessage from 'utils/handleFormatMessage';
import { getStandardNameOfStandardId } from 'utils/conversionsHelper';

export const Standard = ({ intl, log, product }: InjectIntlProvidedProps & EquotipLogbookEntryProps) => (
	<span>
		{handleFormatMessage('LogbookPropertyStandardChangedFromTo', intl.formatMessage, [
			getStandardNameOfStandardId(null, intl.formatMessage, log.content.oldStandardId, product),
			getStandardNameOfStandardId(null, intl.formatMessage, log.content.updatedStandardId, product),
		])}
	</span>
);

export default injectIntl(Standard);
