// @flow
import React, { Fragment } from 'react';
import { get, has } from 'lodash';
import { Table } from 'antd';
import { FormattedMessage } from 'react-intl';
import ConvertedUnits from 'components/shared/ConvertedUnits';
import SetupFile from 'components/measurements/HTMLExport/components/Settings/FDL/SetupFile';
import DGSCalibration from 'components/measurements/HTMLExport/components/Settings/FDL/DGSCalibration';
import DGSSnapshot from 'components/measurements/HTMLExport/components/Settings/FDL/DGSSnapshot';
import Photo from 'components/measurements/LogEvent/Photo';
import { convertAndDisplay } from 'constants/conversionRates';
import type { FDLMeasurementPresetsProps } from './MeasurementPresets';
import styles from '../../../HTMLExport.styl';

export const KeyFrameSnapshot = ({
	reading,
	log,
	unit,
	showTitle,
}: { reading: MeasurementReading, log: ?Log } & FDLMeasurementPresetsProps) => {
	const number = +reading.content.indicationPosition.indicationPosition + 1;
	const title = (
		<Fragment>
			<FormattedMessage id="Proceq.ExportFieldSettings" />
			{' ­­» '}
			<FormattedMessage id="App.HTML.FDL.MeasurementPresets.KeyFrameSnapshot" />
			{' ­­» '}
			{number}
		</Fragment>
	);
	const hasIndicationInfo = has(reading, 'content.indicationSettings.info.dgsIndicationInfo');

	const dataSource = [
		{
			label: 'App.HTML.FDL.MeasurementPresets.KeyFrameSnapshot.IndicationID',
			value: number,
		},
		{
			label: 'Proceq.ExportColumnProbeType',
			value: (
				<FormattedMessage
					id={`App.Logbook.FDL.Item.type.${get(reading, 'content.indicationSettings.info.type')}`}
				/>
			),
		},
		{
			label: 'Proceq.TableDescription',
			value: get(reading, 'content.indicationSettings.info.description'),
		},
		{
			label: 'App.HTML.FDL.MeasurementPresets.KeyFrameSnapshot.GlobalGain',
			value: get(reading, 'content.indicationSetupFile.gain'),
			unitId: 'FDL.KeyFrameSnapshot.GlobalGain',
		},
	].map((row, key) => {
		let label = <FormattedMessage id={row.label} />;
		let value = row.value;
		if (row.unitId) {
			const { convertFunction } = convertAndDisplay(row.unitId, 'Indication', unit);
			label = <ConvertedUnits id={row.label} unitId={row.unitId} scanType="Indication" isMetric={unit} />;
			value = convertFunction(row.value);
		}

		return {
			key,
			label,
			value,
		};
	});

	return (
		<div className="key-frame-snapshot">
			<Table
				title={showTitle ? () => <span className={styles.sub_header}>{title}</span> : null}
				className={styles.table}
				dataSource={dataSource}
				pagination={false}
				showHeader={false}
				size="small"
			>
				<Table.Column dataIndex="label" className={styles.column} />
				<Table.Column dataIndex="value" />
			</Table>
			{log && (
				<Photo mID={reading.mID} aID={log.content.attachmentId} attachmentName={log.content.attachmentName} />
			)}
			<SetupFile
				setupFile={reading.content.indicationSetupFile}
				number={number}
				showTitle={showTitle}
				unit={unit}
			/>
			{hasIndicationInfo && (
				<DGSCalibration
					setupFile={reading.content.indicationSetupFile}
					indicationInfo={reading.content.indicationSettings.info.dgsIndicationInfo}
					number={number}
					showTitle={showTitle}
					unit={unit}
				/>
			)}
			{hasIndicationInfo && (
				<DGSSnapshot
					setupFile={reading.content.indicationSetupFile}
					indicationInfo={reading.content.indicationSettings.info.dgsIndicationInfo}
					number={number}
					showTitle={showTitle}
					unit={unit}
				/>
			)}
			<br />
		</div>
	);
};

export default KeyFrameSnapshot;
