// @flow
import React from 'react';
import type { InjectIntlProvidedProps } from 'react-intl';
import { injectIntl } from 'react-intl';
import handleFormatMessage from 'utils/handleFormatMessage';
import { getScaleInfoOfScaleId, getSchmidtScaleIdOfFromFactorAndUnitId } from 'utils/conversionsHelper';

export const TargetScale = ({ intl, log, product }: InjectIntlProvidedProps & EquotipLogbookEntryProps) => (
	<span>
		{handleFormatMessage('Proceq.LogbookPropertyTargetScaleChangedFromTo', intl.formatMessage, [
			getScaleInfoOfScaleId(
				intl.formatMessage,
				getSchmidtScaleIdOfFromFactorAndUnitId(log.content.oldUnitId, log.content.oldFormFactorId),
				product
			).scaleName,
			getScaleInfoOfScaleId(
				intl.formatMessage,
				getSchmidtScaleIdOfFromFactorAndUnitId(log.content.updatedUnitId, log.content.updatedFormFactorId),
				product
			).scaleName,
		])}
	</span>
);

export default injectIntl(TargetScale);
