// @flow
import React from 'react';
import { get } from 'lodash';
import { Form, Input, Button } from 'antd';
import type { FormProps } from 'antd/lib/form';
import URL from 'apis/urls';
import { connect } from 'react-redux';
import { compose, withState } from 'recompose';
import type { InjectIntlProvidedProps } from 'react-intl';
import { FormattedMessage, injectIntl } from 'react-intl';
import Select from 'components/shared/SmarterFormItems/Select';
import styles from 'components/shared/SmarterTable.styl';
import { request } from 'actions/ajax';

export const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line

export const AddEditUser = ({
	loading,
	form,
	user,
	changeUser,
	addUser,
	editUser,
	intl,
}: {
	loading: boolean,
	form: FormProps,
	user?: User,
	changeUser: Function,
	addUser: Function,
	editUser: Function,
} & InjectIntlProvidedProps) => (
	<Form
		onSubmit={e => {
			e.preventDefault();

			form.validateFields((err, values) => {
				if (err) {
					return;
				}

				if (values.expirationDate) {
					values.expirationDate = values.expirationDate.format('YYYY-MM-DD');
				}

				values.newsletter = +values.newsletter;

				if (values.password) {
					values.password = btoa(values.password);
				}

				(user ? editUser : addUser)(values);
			});
		}}
	>
		<Form.Item label={<FormattedMessage id="Proceq.FormFirstName" />} className={styles.half_field}>
			{form.getFieldDecorator('firstName', {
				rules: [{ required: true, max: 50 }],
			})(<Input />)}
		</Form.Item>
		<Form.Item label={<FormattedMessage id="Proceq.FormLastName" />} className={styles.half_field}>
			{form.getFieldDecorator('lastName', {
				rules: [{ required: true, max: 50 }],
			})(<Input />)}
		</Form.Item>
		<Form.Item label={<FormattedMessage id="Proceq.FormIndustry" />} className={styles.field}>
			{form.getFieldDecorator('industry', {
				rules: [{ required: true }],
			})(<Select globalRegister="industrytype" />)}
		</Form.Item>
		<Form.Item label={<FormattedMessage id="Proceq.FormCompanyName" />} className={styles.half_field}>
			{form.getFieldDecorator('companyName', {
				rules: [{ required: true }],
			})(<Input />)}
		</Form.Item>
		<Form.Item label={<FormattedMessage id="Proceq.FormCompanySize" />} className={styles.half_field}>
			{form.getFieldDecorator('companySize', {
				rules: [{ required: true }],
			})(<Select globalRegister="companysize" />)}
		</Form.Item>
		<Form.Item label={<FormattedMessage id="Proceq.FormCompanyWebsite" />} className={styles.field}>
			{form.getFieldDecorator('companyWebSite', {
				rules: [{ required: true }],
			})(<Input />)}
		</Form.Item>
		<Form.Item label={<FormattedMessage id="Proceq.FormJobTitle" />} className={styles.half_field}>
			{form.getFieldDecorator('jobTitle', {
				rules: [{ required: true }],
			})(<Input />)}
		</Form.Item>
		<Form.Item label={<FormattedMessage id="Proceq.FormNewsletter" />} className={styles.half_field}>
			{form.getFieldDecorator('newsletter')(<Select globalRegister="yesorno" />)}
		</Form.Item>
		{!user && (
			<Form.Item label={<FormattedMessage id="Proceq.FormCountry" />} className={styles.half_field}>
				{form.getFieldDecorator('countryCode', {
					rules: [{ required: true }],
				})(<Select globalRegister="countries" />)}
			</Form.Item>
		)}
		{!user && (
			<Form.Item label={<FormattedMessage id="Proceq.FormEmailGroupID" />} className={styles.field}>
				{form.getFieldDecorator('username', {
					rules: [
						{
							required: true,
							pattern: emailRegex,
							message: intl.formatMessage({ id: 'Proceq.ErrorWrongUsername' }),
						},
					],
				})(<Input type="email" />)}
			</Form.Item>
		)}
		{!user && (
			<Form.Item label={<FormattedMessage id="Proceq.FormPassword" />} className={styles.field}>
				{form.getFieldDecorator('password', {
					rules: [{ required: true, min: 6, max: 15 }],
				})(<Input type="password" />)}
			</Form.Item>
		)}
		<Form.Item className={styles.submit}>
			<Button type="default" onClick={() => changeUser(null)}>
				<FormattedMessage id="Proceq.LogbookClose" />
			</Button>
			&nbsp;
			<Button type="primary" htmlType="submit" loading={loading}>
				<FormattedMessage id="Proceq.ButtonSubmit" />
			</Button>
		</Form.Item>
	</Form>
);

export default compose(
	Form.create({
		mapPropsToFields: props => {
			const fields = {};
			[
				'firstName',
				'lastName',
				'industry',
				'companyName',
				'companySize',
				'companyWebSite',
				'jobTitle',
				'newsletter',
			].forEach(k => {
				const value = String(get(props, ['user', k], ''));

				fields[k] = Form.createFormField({ value });
			});
			return fields;
		},
	}),
	injectIntl,
	withState('loading', 'setLoading', false),
	connect(null, (dispatch: Function, props) => ({
		addUser: (params: {}) => {
			dispatch(
				request({
					method: 'POST',
					url: URL.CREATE_ACCOUNT,
					setLoading: props.setLoading,
					params,
				})
			).then(data => {
				if (data.status === 'success') {
					props.changeUser(null);
					props.loadUsers();
				}
			});
		},
		editUser: (params: {}) => {
			dispatch(
				request({
					method: 'PUT',
					url: URL.UPDATE_ACCOUNT.replace('{key}', props.user.id),
					setLoading: props.setLoading,
					params,
				})
			).then(data => {
				if (data === '') {
					props.changeUser(null);
					props.loadUsers();
				}
			});
		},
	}))
)(AddEditUser);
