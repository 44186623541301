// @flow
import React from 'react';
import { has, get, curryRight } from 'lodash';
import { withState, compose, lifecycle } from 'recompose';
import { connect } from 'react-redux';
import { Collapse, Spin, Row, Col, Table } from 'antd';
import { withHighcharts, HighchartsChart, Chart, XAxis, YAxis, ColumnSeries, Tooltip } from 'react-jsx-highcharts';
import Highcharts, { isNumber } from 'highcharts';
import { FormattedMessage, injectIntl } from 'react-intl';
import { request } from 'actions/ajax';
import URL from 'apis/urls';
import type { InjectIntlProvidedProps } from 'react-intl';
import { roundNumberToNext, getResolutionForScale } from 'utils/conversionsHelper';
import Statistics, { title as StatsTitle } from '../HTMLExport/components/Settings/GLM/Statistics';
import styles from '../EquotipReadings.styl';

function formatter(formatMessage, unit) {
	return function unitFormatter() {
		if (isNaN(this.y)) {
			return `<b>${this.x}</b><br/><span style="color:${this.points[0].color}">${formatMessage({
				id: 'Proceq.ExportFieldNoConversion',
			})}</span>`;
		}
		return `<b>${this.x}</b><br/><span style="color:${this.points[0].color}">${getResolutionForScale(
			unit,
			this.y
		)} ${unit}</span>`;
	};
}

export const GLMReadings = ({
	loading,
	readings,
	intl: { formatMessage },
	measurement,
	settings,
	params,
}: {
	loading: boolean,
	readings: GLMMeasurementContent,
	measurement: MeasurementObject,
	settings: GLMSettingsContent,
	params: Object,
} & InjectIntlProvidedProps) => {
	if (loading) {
		return <Spin />;
	}
	if (!measurement || !settings || !readings) {
		return <FormattedMessage id="Proceq.GraphNoDataAvailable" />;
	}
	const showHaze = has(params, 'showHaze') ? params.showHaze : true;

	const getValueColor = (value, hi, lo) => {
		if (value > hi || value < lo) return '#FF7F00';
		return '#00848B';
	};
	// loop through bin list
	const plotOptions = {
		column: {
			colorByPoint: true,
			marker: {
				enabled: false,
			},
		},
	};
	const unitMapping = {
		'0': 'GU',
		'1': '%',
	};
	const getValueParam = (isScanMode: boolean, bin: number) => {
		if (!isScanMode) {
			switch (bin) {
				case 1:
					return { key: 'valueA0', value: '20°' };
				case 2:
					return { key: 'valueA1', value: '60°' };
				case 3:
					return { key: 'valueHaze', value: 'Haze' };
			}
		} else {
			switch (bin) {
				case 1:
					return { key: 'valueActual0', value: '20°' };
				case 2:
					return { key: 'valueActual1', value: '60°' };
				case 3:
					return { key: 'valueHaze', value: 'Haze' };
			}
		}
		return { key: 'valueA0', value: '20°' };
	};
	const graphHTML = [];
	const isScanMode = settings.measureMode === 2;
	const binList = settings.angleBinList;
	binList
		.filter(bin => {
			if (bin === 3) {
				if (!showHaze) return false;
			}
			return true;
		})
		.forEach(bin => {
			const valueParam = getValueParam(isScanMode, bin).key;
			const unit = unitMapping[settings.unit]; // TODO: map id to value
			const thresholdHiValue = settings.limitsItems[bin].high;
			const thresholdLoValue = settings.limitsItems[bin].low;
			const plotBands = [
				{
					from: thresholdLoValue,
					to: thresholdHiValue,
					color: 'rgba(68, 170, 213, 0.1)',
				},
			];
			const plotLines = [
				{
					color: 'rgba(209, 209, 209, 1)', // Color value
					dashStyle: 'shortdash', // Style of the plot line. Default to solid
					value: thresholdLoValue, // Value of where the line will appear
					width: 2, // Width of the line
				},
				{
					color: 'rgba(209, 209, 209, 1)', // Color value
					dashStyle: 'shortdash', // Style of the plot line. Default to solid
					value: thresholdHiValue, // Value of where the line will appear
					width: 2, // Width of the line
				},
			];
			const categories = readings.series.map(readingSeries => readingSeries.index);
			const colors = readings.series.map(readingSeries => {
				const value = readingSeries[valueParam];
				return getValueColor(value, thresholdHiValue, thresholdLoValue);
			});
			const data = readings.series.map(readingSeries => {
				let reading = readingSeries[valueParam];
				if (bin === 3 && reading < 0) {
					reading = 0;
				}
				return reading;
			});
			let maxValueFound = data.reduce((max, current) => (current > max ? current : max), thresholdHiValue);
			let minValueFound = data.reduce((min, current) => (current < min ? current : min), thresholdLoValue);
			if (minValueFound < 0) minValueFound = 0;
			minValueFound = roundNumberToNext(minValueFound, 'down');
			maxValueFound = roundNumberToNext(maxValueFound, 'up');
			if (readings.series.length > 0) {
				graphHTML.push(
					<div key={bin}>
						<Col xs={16} lg={14}>
							<div className={styles.title_property}>
								<FormattedMessage id={getValueParam(isScanMode, bin).value} />
							</div>
							<div style={{ maxWidth: '500px', margin: '0 auto' }}>
								<HighchartsChart plotOptions={plotOptions}>
									<Chart backgroundColor={null} />
									<Tooltip formatter={formatter(formatMessage, unit)} shared />
									<XAxis categories={categories} />
									<YAxis
										id="number"
										plotBands={plotBands}
										plotLines={plotLines}
										min={minValueFound}
										max={maxValueFound}
									>
										<YAxis.Title>{unit}</YAxis.Title>
										<ColumnSeries
											id="installation"
											name="Installation"
											data={data}
											colors={colors}
										/>
									</YAxis>
								</HighchartsChart>
							</div>
						</Col>
					</div>
				);
			}
		});

	// measurements  table
	/*
	[
		{val20: {data: , color: }, val60: , valH: }
	]
	*/
	const enabled20 = binList.includes(1);
	const enabled60 = binList.includes(2);
	const enabledH = binList.includes(3) && showHaze;
	const tableData = readings.series.map(readingSeries => {
		const row = {};
		row.index = readingSeries.index;
		// val20
		if (enabled20) {
			const value20 = readingSeries[getValueParam(isScanMode, 1).key];
			row.value20 = {
				data: value20,
				color: getValueColor(value20, settings.limitsItems[1].high, settings.limitsItems[1].low),
			};
		}

		// val60
		if (enabled60) {
			const value60 = readingSeries[getValueParam(isScanMode, 2).key];
			row.value60 = {
				data: value60,
				color: getValueColor(value60, settings.limitsItems[2].high, settings.limitsItems[2].low),
			};
		}
		// haze
		if (enabledH) {
			let valueHaze = readingSeries[getValueParam(isScanMode, 3).key];
			let color = getValueColor(valueHaze, settings.limitsItems[3].high, settings.limitsItems[3].low);
			if (valueHaze < 0 || valueHaze > 1000) {
				color = '#FF7F00';
				valueHaze = <FormattedMessage id="Proceq.GraphNoConversion" />;
			}
			row.valueHaze = {
				data: valueHaze,
				color,
			};
		}
		return row;
	});
	const tableHTML = (
		<Col xs={24} lg={18}>
			<div className={styles.title_property}>
				<FormattedMessage id="Measurements" />
			</div>
			<Table
				className={styles.table}
				columns={[
					{
						title: 'Proceq.AppPlaceholder',
						dataIndex: 'index',
						enabled: true,
						width: 60,
					},
					{
						title: '20°',
						dataIndex: 'value20',
						enabled: enabled20,
					},
					{
						title: '60°',
						dataIndex: 'value60',
						enabled: enabled60,
					},
					{
						title: 'H',
						dataIndex: 'valueHaze',
						enabled: enabledH,
					},
				]
					.filter(columnConfig => {
						return columnConfig.enabled;
					})
					.map(columnConfig => ({
						render: record => {
							if (columnConfig.dataIndex === 'index') {
								return record;
							}
							if (isNumber(record.data)) {
								return <div style={{ color: record.color }}>{record.data.toFixed(1)}</div>;
							}
							return <div style={{ color: record.color }}>{record.data}</div>;
						},
						...columnConfig,
						key: columnConfig.title,
						title: <FormattedMessage id={columnConfig.title} />,
					}))}
				dataSource={tableData}
				pagination={false}
				scroll={{ y: 1000 }}
				size="small"
				bordered
				rowKey="uid"
			/>
			{/* </div> */}
		</Col>
	);

	return (
		<div>
			<Row align="top">
				<Col xs={16} lg={14}>
					<Row>{graphHTML}</Row>
				</Col>
				<Col xs={8} lg={6}>
					<Row>{tableHTML}</Row>
				</Col>
			</Row>
			<Collapse>
				<Collapse.Panel header={StatsTitle}>
					<Statistics data={measurement} settings={settings} showHaze={showHaze} />
				</Collapse.Panel>
			</Collapse>
		</div>
	);
};

export const GLMReadingsWithState = compose(injectIntl, curryRight(withHighcharts)(Highcharts))(GLMReadings);

export default compose(
	withState('loading', 'setLoading', true),
	withState('readings', 'setReadings'),
	withState('measurement', 'setMeasurement', {}),
	withState('settings', 'setSettings', {}),
	connect(null, (dispatch: Function, props) => ({
		loadMeasurement: () =>
			dispatch(
				request({
					url: URL.MEASUREMENTS + props.mID,
				})
			).then(data => {
				props.setMeasurement(data.measurement);
				props.setReadings(get(data, 'readings.0.content'));
				props.setSettings(get(data, 'settings.0.content'));
				props.setLoading(false);
			}),
	})),
	lifecycle({
		componentDidMount() {
			this.props.loadMeasurement();
		},
	})
)(GLMReadingsWithState);
