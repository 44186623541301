// @flow
import React from 'react';
import { toPlainObject } from 'lodash';
import { FormattedMessage } from 'react-intl';

import { convertAndDisplay } from 'constants/conversionRates';

export const ConvertedUnits = ({
	id,
	unitId,
	scanType,
	isMetric,
	values = [],
}: {
	id: string,
	unitId: string,
	scanType: ScanType,
	isMetric: boolean | string,
	values?: number[],
}) => {
	const { convertFunction, unit } = convertAndDisplay(unitId, scanType, isMetric);

	return (
		<FormattedMessage
			id={id}
			values={{
				...toPlainObject(values.map(Number).map(convertFunction)),
				unit,
			}}
		/>
	);
};

export default ConvertedUnits;
