import 'antd/dist/antd.less';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import './index.styl';

import App from './components/App';
import { router } from './routes';
import store, { storagePersistor } from './store';

ReactDOM.render(
	<Provider store={store}>
		<PersistGate persistor={storagePersistor}>
			<App>{router}</App>
		</PersistGate>
	</Provider>,
	document.getElementById('root')
);
