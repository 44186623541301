// @flow
import React from 'react';
import { FormattedMessage } from 'react-intl';

export const TextWithKeyValues = ({ log }: GPRLogbookEntryProps) => {
	const keyValueItems = [];
	log.content.keyValueItems.forEach(item => {
		Object.keys(item).forEach(key => {
			const value = item[key];
			keyValueItems.push(`${key}: ${value}`);
		});
	});

	return (
		<div>
			<b>
				<FormattedMessage id="App.LogbookPropertyTextWithKeyValues" />
			</b>
			<br />
			<FormattedMessage id="App.Logbook.PI8000.PileName" />
			{`: ${log.content.pileName}`}
			<br />
			<FormattedMessage id="App.Logbook.PI8000.PositionName" />
			{`: ${log.content.positionName}`}
			<br />
			<br />
			<b>
				<FormattedMessage id="App.Logbook.PI8000.KeyValueItems" />
				{': '}
			</b>
			<br />
			{keyValueItems.map(kv => (
				<div key={kv}>{kv}</div>
			))}
		</div>
	);
};

export default TextWithKeyValues;
