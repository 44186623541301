// @flow
import React from 'react';
import Sidebar from './Sidebar';
import Header from './Header';
import MainArea from './MainArea';
import styles from './Layout.styl';
import './Overrides.css';

export const Layout = ({ children }: { children: any }) => (
	<div className={styles.layout}>
		<Sidebar />
		<MainArea>
			<Header />
			{children}
		</MainArea>
	</div>
);

export default Layout;
