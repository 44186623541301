module.exports = {
	shortlang: 'fr',
	lang: 'fr',
	messages: {
		'Proceq.ErrorWrongUsername': 'Adresse mail saisie non valide',
		'Proceq.ErrorCreationNotAllowed': 'La création de nouveaux comptes a été désactivée',
		'Proceq.ErrorWrongPassword': 'Mot de passe saisi non valide',
		'Proceq.ErrorWrongFirstName': 'Prénom saisi non valide',
		'Proceq.ErrorWrongLastName': 'Nom saisi non valide',
		'Proceq.ErrorUsernameAlreadyExists': 'Un compte avec la même adresse mail existe déjà',
		'Proceq.ErrorDB': 'Erreur exécution opération de base de données',
		'Proceq.InvalidUsername': 'Impossible de trouver le compte utilisateur',
		'Proceq.AlreadyMultipleForgot': 'Plusieurs mots de passe de réinitialisation déjà demandés',
		'Proceq.InvalidNewPassword': 'Nouveau mot de passe saisi non valide',
		'Proceq.InvalidResetCode': 'Code réinitialisation de mot de passe non valide ou déjà expiré',
		'Proceq.InvalidOldPassword': 'Ancien mot de passe saisi non valide',
		'Proceq.InvalidAccessRights': 'Session expirée ou utilisateur non autorisé à exécuter cette action',
		'Proceq.ErrorNoAccountType': 'Aucun type de compte saisi',
		'Proceq.ErrorNoUsername': 'Pas d’adresse mail saisie',
		'Proceq.InvalidCountry': 'Pays saisi non valide',
		'Proceq.ErrorCreatingUser': 'Erreur création nouvel utilisateur',
		'Proceq.InvalidID': 'ID saisi non valide',
		'Proceq.InvalidTransactionID': 'ID transaction non valide',
		'Proceq.InvalidFileContent': 'Erreur lecture contenu du fichier',
		'Proceq.InvalidTypeEntry': 'Type entrée non valide',
		'Proceq.InvalidContractID': 'Contrat non valide ou expiré',
		'Proceq.InvalidExistingID': 'Impossible de créer ID car il existe déjà',
		'Proceq.InvalidDataSeriesType': 'Type de série non valide',
		'Proceq.InvalidMetaDataContent': 'Contenu métadonnées non valide',
		'Proceq.ErrorFileIsLocked': 'Un ou plusieurs fichiers sont verrouillés. Réessayez plus tard',
		'Proceq.MissingAttachmentFiles': 'Impossible de trouver les fichiers joints sur le serveur',
		'Proceq.InvalidFlagType': 'Type drapeau non valide ou manquant',
		'Proceq.MissingDateTypeFields': 'Champs requis manquants pour la série',
		'Proceq.InvalidFolderID': 'ID dossier non valide',
		'Proceq.InvalidDataSeriesName': 'Nom de série non valide',
		'Proceq.ErrorDateFormat': 'Format de date saisi non valide',
		'Proceq.ErrorDataFormat': 'Impossible d’analyser les données de la série',
		'Proceq.InvalidDownloadID': 'Jeton de chargement non valide ou déjà expiré',
		'Proceq.InvalidVersionNr': 'Numéro de version saisi non valide',
		'Proceq.ErrorDataMissing': 'Impossible de récupérer les données du système de stockage',
		'Proceq.ErrorSavingFile': 'Le serveur n’a pas pu enregistrer le fichier',
		'Proceq.ErrorInsertDB': 'Erreur insertion sur la BD',
		'Proceq.InvalidFolderName': 'Nom de dossier saisi non valide',
		'Proceq.InvalidActionID': 'Lien URL non valide ou expiré',
		'Proceq.AlreadyConfirmed': 'Le compte a déjà été vérifié',
		'Proceq.InvalidActionType': 'L’action demandée ne peut pas être traitée',
		'Proceq.ErrorGeneratingChart': 'Erreur génération des graphiques',
		'Proceq.InvalidSerialID': 'ID série non valide',
		'Proceq.InvalidHardwareID': 'ID matériel non valide',
		'Proceq.InvalidEntityID': 'ID objet non valide ou supprimé',
		'Proceq.InvalidEntityType': 'Type objet non valide',
		'Proceq.InvalidEntityVerNr': 'Numéro de version de l’objet non valide',
		'Proceq.ErrorTransactionLocked': 'L’objet est verrouillé et ne peut pas être modifié',
		'Proceq.InvalidDateValue': 'La date ne peut pas être analysée',
		'Proceq.MissingFields': 'Un ou plusieurs champs requis sont manquants',
		'Proceq.ErrorColumnValue': 'Valeur saisie pour un ou plusieurs champs incorrecte',
		'Proceq.MissingUidDBValue': 'ID entrée manquant',
		'Proceq.ErrorFileID': 'ID fichier saisi non valide',
		'Proceq.ErrorInsertDownload': 'Erreur création chargement',
		'Proceq.InvalidAppVersion': 'Votre version d’appli actuelle n’est plus prise en charge. Faites la mise à jour',
		'Proceq.MissingUserInfo': 'Données utilisateur manquantes',
		'Proceq.InvalidAccessToken': 'Jeton de session non valide ou expiré',
		'Proceq.ErrorGeneratingCSV': 'Erreur génération exportation CSV',
		'Proceq.ErrorGeneratingExport': 'Erreur génération exportation',
		'Proceq.MissingParams': 'Un ou plusieurs des paramètres requis sont manquants',
		'Proceq.SerialNotFound': 'Aucun contrat trouvé pour cet appareil',
		'Proceq.StandardEnumJapan': 'Japon',
		'Proceq.StandardEnumDefault': 'Par défaut',
		'Proceq.MaterialEnumIndependent': 'Indépendant du matériau',
		'Proceq.MaterialEnumSteelAndCastSteel': 'Acier et acier moulé',
		'Proceq.MaterialEnumWorkToolSteel': 'Acier à outils',
		'Proceq.MaterialEnumStainlessSteel': 'Acier inoxydable',
		'Proceq.MaterialEnumGrayCastIron': 'Fonte grise (GG)',
		'Proceq.MaterialEnumNodularCastIron': 'Fonte nodulaire (GGG)',
		'Proceq.MaterialEnumCastAlluminiumAlloys': 'Alliages d’aluminium coulé',
		'Proceq.MaterialEnumBrassCopperZincAlloys': 'Alliages de zinc/cuivre - laiton',
		'Proceq.MaterialEnumBronze': 'Bronze',
		'Proceq.MaterialEnumWroughtCopperAlloys': 'Alliages de cuivre battu',
		'Proceq.MaterialEnumTitanium': 'Titane',
		'Proceq.ScaleEnumCustom': 'Personnalisé',
		'Proceq.ScaleEnumPenetrationDepth': 'Profondeur de pénétration',
		'Proceq.CompanySizeMicro': '1-9 employés',
		'Proceq.CompanySizeSmall': '10-49 employés',
		'Proceq.CompanySizeMedium': '50-249 employés',
		'Proceq.CompanySizeLarge': '>249 employés',
		'Proceq.SalutationMr': 'M.',
		'Proceq.SalutationMs': 'Mme',
		'Proceq.SalutationDr': 'Dr.',
		'Proceq.SalutationProf': 'Prof.',
		'Proceq.CategoryAerospace': 'Aéronautique',
		'Proceq.CategoryAutomotive': 'Automobile',
		'Proceq.CategoryChemicalProcessing': 'Traitement chimique',
		'Proceq.CategoryConstructionBuilding': 'Construction/bâtiment',
		'Proceq.CategoryConsultantTestLabsEngineer': 'Consultant/laboratoires de test/ingénieur',
		'Proceq.CategoryEducationInstituteRD': 'Enseignement/Institut/R&D',
		'Proceq.CategoryFilmProducerConverter': 'Producteur de films/convertisseur',
		'Proceq.CategoryGeologyRocktesters': 'Géologie/testeurs de roche',
		'Proceq.CategoryMachineryToolingEquipment': 'Machines/outillage/équipement',
		'Proceq.CategoryMassTransportation': 'Transport collectif',
		'Proceq.CategoryMetalWorks': 'Travail du métal',
		'Proceq.CategoryOilAndGas': 'Pétrole et gaz',
		'Proceq.CategoryOthers': 'Autres',
		'Proceq.CategoryPaperProducerConverter': 'Producteur de papier/convertisseur',
		'Proceq.CategorySalesChannel': 'Réseau de vente',
		'Proceq.ErrorWrongLoginCredentials': 'Le nom d’utilisateur ou le mot de passe saisi est incorrect',
		'Proceq.ErrorEmailNotVerified': 'L’adresse mail n’a pas encore été vérifiée',
		'Proceq.ServerError': 'Erreur serveur',
		'Proceq.TooManyActionEmailRequests':
			'Demandes trop nombreuses. Patientez le temps que les e-mails arrivent dans votre messagerie',
		'Proceq.ErrorServerUnderMaintenance': 'Serveur en maintenance',
		'Proceq.TableDataSeriesName': 'Nom',
		'Proceq.TableCreatedOn': 'Créé le',
		'Proceq.TableModifiedOn': 'Modifié le',
		'Proceq.TableCreatedBy': 'Créé par',
		'Proceq.TableNumMean': 'Moyenne',
		'Proceq.TableNumTotal': 'Total',
		'Proceq.TableNumStandardDeviation': 'Écart type',
		'Proceq.TableImpactDevice': 'Sonde',
		'Proceq.TableImpactDeviceHammer': 'Scléromètre',
		'Proceq.TableMaterial': 'Matériau',
		'Proceq.TableScaleUnit': 'Unité d’échelle',
		'Proceq.TableActionRefresh': 'Actualiser',
		'Proceq.TableActionExpand': 'Agrandir',
		'Proceq.TableActionCollapse': 'Réduire',
		'Proceq.TableActionExportAsPDF': 'Exporter au format PDF',
		'Proceq.TableActionExportAsCSV': 'Exporter au format CSV',
		'Proceq.TableActionExportAsJSON': 'Exporter au format JSON',
		'Proceq.SearchOperationEquals': 'Équivaut à',
		'Proceq.SearchOperationContains': 'Contient',
		'Proceq.SearchOperationDifferent': 'Différent',
		'Proceq.SearchOperationGreaterOrEqual': 'Supérieur ou égal',
		'Proceq.SearchOperationGreater': 'Supérieur',
		'Proceq.SearchOperationLesserOrEqual': 'Inférieur ou égal',
		'Proceq.SearchOperationLesser': 'Inférieur',
		'Proceq.ExportFieldExportDate': 'Date exportation',
		'Proceq.ExportFieldLimits': 'Limites',
		'Proceq.ExportFieldHigh': 'Élevé',
		'Proceq.ExportFieldLow': 'Faible',
		'Proceq.ExportFieldOff': 'Éteint',
		'Proceq.ExportFieldLowerLimit': 'Limite inférieure',
		'Proceq.ExportFieldUpperLimit': 'Limite supérieure',
		'Proceq.ExportFieldReadings': 'Relevés',
		'Proceq.ExportFieldStandardDeviation': 'Écart type',
		'Proceq.ExportFieldRange': 'Plage',
		'Proceq.ExportFieldRelativeSpan': 'Ampleur relative',
		'Proceq.ExportFieldMaximum': 'Maximum',
		'Proceq.ExportFieldMinimum': 'Minimum',
		'Proceq.ExportFieldAverage': 'Moyenne',
		'Proceq.ExportFieldUnit': 'Unité',
		'Proceq.ExportFieldName': 'Nom',
		'Proceq.ExportFieldMeasurementDate': 'Date de mesure',
		'Proceq.ExportFieldLocationLatitude': 'Latitude de l’emplacement',
		'Proceq.ExportFieldLocationLongitude': 'Longitude de l’emplacement',
		'Proceq.ExportFieldProbeType': 'Type de sonde',
		'Proceq.ExportFieldProbeSerialNumber': 'Numéro de série de la sonde',
		'Proceq.ExportFieldProbeBTSerialNumber': 'Numéro de série Bluetooth de la sonde',
		'Proceq.ExportFieldProbeLastVerificationDate': 'Dernière date de vérification de la sonde',
		'Proceq.ExportFieldPrimaryScale': 'Échelle principale',
		'Proceq.ExportFieldSecondaryScale': 'Échelle secondaire',
		'Proceq.ExportFieldMaterial': 'Matériau',
		'Proceq.ExportFieldStandard': 'Norme',
		'Proceq.ExportFieldNoConversion': 'Pas de conversion',
		'Proceq.ExportFieldMeasurements': 'Mesures',
		'Proceq.ExportFieldMore': 'Plus',
		'Proceq.ExportFieldLess': 'Moins',
		'Proceq.ExportFieldStatistics': 'Statistiques',
		'Proceq.ExportFieldSettings': 'Paramètres',
		'Proceq.DataSeriesLogbook': 'Journal',
		'Proceq.DataSeriesAllMeasurements': 'Toutes les mesures',
		'Proceq.DataSeriesFlagged': 'Marqué',
		'Proceq.DataSeriesTrash': 'Corbeille',
		'Proceq.BrowserNoSupportAudio': 'Votre navigateur ne prend pas en charge les fichiers audio.',
		'Proceq.EmailPasswordRecoverySubject': 'Demande de changement de mot de passe pour **?0**',
		'Proceq.EmailPasswordRecoveryBody':
			'Cher/chère **?0** **?1**<br/><br/>Vous avez demandé un nouveau mot de passe. Pour réinitialiser votre mot de passe, cliquez sur le lien ci-dessous.<br/><br/><a href="**?2**">Réinitialiser mot de passe</a><br/><br/>Si vous n’avez pas demandé un changement de mot de passe, ignorez ce message. Il expirera dans les 12 heures.<br/><br/>Votre équipe Proceq<br/><br/>**?3**',
		'Proceq.EmailRegistrationSubject': 'Achèvement de l’enregistrement pour **?0**',
		'Proceq.EmailRegistrationBody':
			'Cher/chère **?0** **?1**<br/><br/>Merci pour votre enregistrement sur **?5**. Terminez votre enregistrement en cliquant sur le lien ci-dessous:<br/><br/><a href="**?2**">**?2**</a><br/><br/>Cet e-mail a été créé automatiquement suite à votre enregistrement. Si vous n’avez pas fait cet enregistrement, ignorez ce message.<br/><br/> En vous enregistrant vous acceptez notre <a href="**?3**">politique de confidentialité</a>.<br/><br/>Votre équipe Proceq<br/><br/>**?4**',
		'Proceq.LogbookActionNewMeasurement': 'Nouvelle mesure',
		'Proceq.LogbookPropertyCreatedSeriesInFolder': 'Série "**?0**" créée dans le dossierr "**?1**"',
		'Proceq.LogbookPropertyRenamedSeriesFromTo': 'Série "**?0**" renommée "**?1**"',
		'Proceq.LogbookPropertyMovedSeriesFromToTrash': 'Série déplacée du dossier "**?0**" vers la corbeille',
		'Proceq.LogbookPropertyMovedSeriesFromTrashTo': 'Série déplacée de la corbeille vers le dossier "**?0**"',
		'Proceq.LogbookPropertyMovedSeriesFromTo': 'Série déplacée du dossier "**?0**" vers le dossier "**?1**"',
		'Proceq.LogbookPropertySecondaryScaleChangedFromTo': 'Échelle secondaire changée de **?0** en **?1**',
		'Proceq.LogbookPropertyMaterialChangedFromTo': 'Matériau changé de **?0** en **?1**',
		'Proceq.LogbookPropertyMaxLimitChangedFromTo': 'Limite supérieure changée de **?0** en **?1**',
		'Proceq.LogbookPropertyMinLimitChangedFromTo': 'Limite inférieure changée de **?0** en **?1**',
		'Proceq.LogbookPropertyStandardChangedFromTo': 'Norme changée de **?0** en **?1**',
		'Proceq.LogbookPropertyTextCommentAdded': 'Commentaire texte ajouté',
		'Proceq.LogbookPropertyTextCommentDeletedOn': 'Supprimé le **?0**',
		'Proceq.LogbookPropertyMeasurementAdded': '**?0** ajouté',
		'Proceq.LogbookPropertyMeasurementExcluded': '**?0** exclu',
		'Proceq.LogbookPropertyMeasurementIncluded': '**?0** inclus',
		'Proceq.LogbookPropertyMeasurementDeleted': '**?0** supprimé',
		'Proceq.LogbookPropertyAttachmentDeletedOn': 'Supprimé le **?0**',
		'Proceq.LogbookPropertyPhotoAdded': 'Photo ajoutée',
		'Proceq.LogbookPropertyAudioAdded': 'Commentaire audio ajouté',
		'Proceq.LogbookPropertySeriesFlagged': 'Série marquée',
		'Proceq.LogbookPropertySeriesUnflagged': 'Série non marquée',
		'Proceq.LogbookPropertySerialNumberShort': 'S/N',
		'Proceq.LogbookPropertyBluetoothModuleShort': 'BTM S/N',
		'Proceq.LogbookPropertyVerified': 'Vérifié',
		'Proceq.LogbookPropertyContractValidUntil': 'Contrat valide jusqu’au',
		'Proceq.LogbookPropertyOperator': 'Opérateur',
		'Proceq.LogbookTitle': 'Journal',
		'Proceq.LogbookDownload': 'Charger',
		'Proceq.LogbookLoadingData': 'Chargement de données...',
		'Proceq.LogbookClose': 'Fermer',
		'Proceq.FormCompany': 'Entreprise',
		'Proceq.FormScreeningEagleID': 'Screening Eagle ID (Email)',
		'Proceq.FormEmailGroupID': 'E-mail',
		'Proceq.FormCustomerEmail': 'E-mail client',
		'Proceq.FormProbeSN': 'Sonde S/N',
		'Proceq.FormBluetoothSN': 'S/N',
		'Proceq.FormBluetoothHWID': 'ID matériel',
		'Proceq.FormExpirationDate': 'Date d’expiration',
		'Proceq.TitleRegisterProbeSNs': 'Enregistrer contrat de location',
		'Proceq.ButtonSubmit': 'Envoyer',
		'Proceq.ButtonReset': 'Réinitialiser',
		'Proceq.ButtonResetPassword': 'Réinitialiser mot de passe',
		'Proceq.MessageSuccesfullyInserted': 'Inséré avec succès',
		'Proceq.MessageSuccesfullyEmailConfirmed': 'Votre adresse mail a été confirmée.',
		'Proceq.MessagePasswordSuccesfullyChanged': 'Mot de passe changé avec succès',
		'Proceq.MessageSuccesfullyUpdated': 'Mis à jour avec succès',
		'Proceq.MessageAccountCreated':
			'Un e-mail de vérification d’enregistrement vous a été envoyé. Cliquez sur le lien dans l’e-mail pour terminer l’enregistrement',
		'Proceq.MessageEmailForgotPassword': 'Un e-mail vous a été envoyé avec des instructions supplémentaires',
		'Proceq.MessageEmailResendConfirm': 'Un e-mail vous a été envoyé avec des instructions supplémentaires',
		'Proceq.MessageLoggingIn': 'Connexion...',
		'Proceq.LoginNeedAccount': 'Besoin d’un compte?',
		'Proceq.LoginCreateAccount': 'Créer compte',
		'Proceq.TitleForgotPassword': 'Mot de passe oublié?',
		'Proceq.TitleResendConfirmation': 'Renvoyer e-mail de confirmation',
		'Proceq.FormGroup': 'Groupe',
		'Proceq.FormFirstName': 'Prénom',
		'Proceq.FormLastName': 'Nom',
		'Proceq.FormEmail': 'Adresse mail',
		'Proceq.FormCity': 'Ville',
		'Proceq.FormPassword': 'Mot de passe',
		'Proceq.FormPasswordNew': 'Nouveau mot de passe',
		'Proceq.FormPasswordOld': 'Ancien mot de passe',
		'Proceq.FormConfirmPassword': 'Confirmer mot de passe',
		'Proceq.FormConfirmPasswordNew': 'Confirmer nouveau mot de passe',
		'Proceq.ButtonRegister': 'S’enregistrer',
		'Proceq.ButtonSignIn': 'S’identifier',
		'Proceq.TitleSignIn': 'S’identifier',
		'Proceq.LoginAlreadyRegistered': 'Déjà enregistré?',
		'Proceq.TextOrSignInUsing': 'Ou s’identifier avec',
		'Proceq.NavSearchSerials': 'Rechercher contrats',
		'Proceq.NavRegisterProbeSNs': 'Enregistrer contrat',
		'Proceq.NavCreateStaffAccounts': 'Créer comptes de personnel',
		'Proceq.NavHistoryLog': 'Journal d’historique',
		'Proceq.NavMeasurementsData': 'Données de mesure',
		'Proceq.NavChangePassword': 'Changer mot de passe',
		'Proceq.NavLogout': 'Déconnexion',
		'Proceq.NavUpdateProfile': 'Mettre à jour profil',
		'Proceq.TitleUpdateProfile': 'Mettre à jour profil',
		'Proceq.NavCreateCustomersAccounts': 'Créer compte client',
		'Proceq.ButtonSearch': 'Rechercher',
		'Proceq.ButtonUpdate': 'Mettre à jour',
		'Proceq.ButtonCreate': 'Créer',
		'Proceq.ButtonChange': 'Changer',
		'Proceq.FormSearchBy': 'Rechercher par',
		'Proceq.FormValue': 'Valeur',
		'Proceq.FormAccountType': 'Type de compte',
		'Proceq.FormAccountTypeAdmin': 'Administrateur',
		'Proceq.FormAccountTypeBU': 'Unité opérationnelle',
		'Proceq.FormCompanyName': 'Nom de l’entreprise',
		'Proceq.FormAddress': 'Adresse',
		'Proceq.FormZipCode': 'Code postal',
		'Proceq.FormCountry': 'Pays',
		'Proceq.FormPhoneNumber': 'Téléphone',
		'Proceq.FormCompanyWebsite': 'Site Web de l’entreprise',
		'Proceq.FormJobTitle': 'Intitulé poste',
		'Proceq.FormIndustry': 'Industrie',
		'Proceq.FormSalutation': 'Salutation',
		'Proceq.FormCompanySize': 'Taille de l’entreprise',
		'Proceq.FormLanguage': 'Langue',
		'Proceq.FormContactByProceq': 'Contact par Proceq',
		'Proceq.LanguageEnglish': 'Anglais',
		'Proceq.LanguageJapanese': 'Japonais',
		'Proceq.LanguageChinese': 'Chinois',
		'Proceq.LanguageGerman': 'Allemand',
		'Proceq.TextYes': 'Oui',
		'Proceq.TextNo': 'Non',
		'Proceq.FormNewsletter': 'Lettre d’information',
		'Proceq.TitleSearchSerials': 'Rechercher contrats',
		'Proceq.TitleChangePassword': 'Changer mot de passe',
		'Proceq.TabLoginCustomers': 'Clients',
		'Proceq.TabLoginSingleUser': 'Utilisateur unique',
		'Proceq.TabLoginGroup': 'Groupe',
		'Proceq.TextRemainingXDays': 'Il reste **?0** jour(s)',
		'Proceq.TextInfoShort': 'Info',
		'Proceq.TextIAgreeTerms': 'J’accepte les <a>conditions générales</a>',
		'Proceq.ErrorDisplayTitleError': 'Erreur',
		'Proceq.ErrorDisplayTitleWarning': 'Avertissement',
		'Proceq.LoginBackLogin': 'Retour écran connexion',
		'Proceq.LoginForgotPasswordQuestion': 'Mot de passe oublié?',
		'Proceq.LoginResendConfirmationEmail': 'Renvoyer e-mail de confirmation',
		'Proceq.LoginRegisterAccountTextPart': 'Si vous n’avez pas de compte,',
		'Proceq.LoginRegisterAccountLinkPart': 'enregistrez-vous ici',
		'Proceq.LoginProceqUseOnly': 'Uniquement pour un usage interne de Proceq',
		'Proceq.ProfileEnterInfo': 'Entrez vos informations de profil avant de poursuivre',
		'Proceq.TextInfoExclamation': 'Info!',
		'Proceq.FooterLegalPrivacy': 'Juridique et confidentialité',
		'Proceq.JQGridTableRecordText': 'Afficher {0} - {1} sur {2}',
		'Proceq.JQGridTableEmptyRecords': 'Aucun enregistrement à afficher',
		'Proceq.JQGridTableLoadText': 'Chargement...',
		'Proceq.JQGridTablePaginationText': 'Page {0} sur {1}',
		'Proceq.JQGridTableSavingText': 'Enregistrement...',
		'Proceq.JQGridTableFirstPage': 'Première page',
		'Proceq.JQGridTableLastPage': 'Dernière page',
		'Proceq.JQGridTableNextPage': 'Page suivante',
		'Proceq.JQGridTablePreviousPage': 'Page précédente',
		'Proceq.JQGridTableRecordsPerPage': 'Enregistrements par page',
		'Proceq.JQGridTablePageText': 'Page:',
		'Proceq.JQGridTableNoMoreRecs': 'Pas d’autre enregistrement',
		'Proceq.ExportColumnCSVNo': 'N°',
		'Proceq.ExportColumnCSVSingleReadings': 'Relevés uniques',
		'Proceq.ExportColumnCSVMeasurementValue': 'Valeur de mesure',
		'Proceq.ExportColumnCSVAngleDegrees': 'Angle (°)',
		'Proceq.ExportColumnCSVExcluded': 'Exclu',
		'Proceq.ExportColumnCSVSeriesName': 'Nom de série',
		'Proceq.ExportColumnCSVImpactDevice': 'Instrument de frappe',
		'Proceq.ExportColumnCSVImpactDeviceSN': 'Instrument de frappe S/N',
		'Proceq.ExportColumnCSVImpactDeviceVerification': 'Vérification instrument de frappe',
		'Proceq.ExportColumnCSVImpactDeviceHammerVerification': 'Vérification scléromètre',
		'Proceq.ExportColumnCSVImpactDeviceHammer': 'Scléromètre',
		'Proceq.ExportColumnCSVImpactDeviceHammerSN': 'Scléromètre S/N',
		'Proceq.ExportColumnCSVBluetoothModuleSN': 'Module Bluetooth S/N',
		'Proceq.ExportColumnCSVConversionStandard': 'Norme conversion',
		'Proceq.ExportColumnCSVScale': 'Échelle',
		'Proceq.ExportColumnCSVUnit': 'Unité',
		'Proceq.ExportColumnCSVReadings': 'Relevés',
		'Proceq.ExportColumnCSVStandardDeviation': 'Écart type',
		'Proceq.ExportColumnCSVRelativeSpan': 'Ampleur relative (%)',
		'Proceq.ExportColumnCSVDebugDataSeriesName': 'Nom de série',
		'Proceq.ExportColumnExportDate': 'Date exportation',
		'Proceq.ExportColumnUpperLimit': 'Limite supérieure',
		'Proceq.ExportColumnLowerLimit': 'Limite inférieure',
		'Proceq.ExportColumnLimits': 'Limites',
		'Proceq.ExportColumnHigh': 'Élevé',
		'Proceq.ExportColumnLow': 'Faible',
		'Proceq.ExportColumnValueOff': 'Éteint',
		'Proceq.ExportColumnValueNoConversion': 'Pas de conversion',
		'Proceq.ExportColumnValueNoConversionShort': 'Pas conv.',
		'Proceq.ExportColumnReadings': 'Relevés',
		'Proceq.ExportColumnStandardDeviation': 'Écart type',
		'Proceq.ExportColumnRange': 'Plage',
		'Proceq.ExportColumnRelativeSpan': 'Ampleur relative',
		'Proceq.ExportColumnMaximum': 'Maximum',
		'Proceq.ExportColumnMinimum': 'Minimum',
		'Proceq.ExportColumnAverage': 'Moyenne',
		'Proceq.ExportColumnUnit': 'Unité',
		'Proceq.ExportColumnName': 'Nom',
		'Proceq.ExportColumnDataSeriesID': 'ID série',
		'Proceq.ExportColumnFolderName': 'Nom dossier',
		'Proceq.ExportColumnCreatedDate': 'Date créée',
		'Proceq.ExportColumnCreatedDateOnly': 'Date créée',
		'Proceq.ExportColumnCreatedTime': 'Heure créée',
		'Proceq.ExportColumnLastModifiedDate': 'Dernière date modifiée',
		'Proceq.ExportColumnLastModifiedDateOnly': 'Dernière date modifiée',
		'Proceq.ExportColumnLastModifiedTime': 'Dernière heure modifiée',
		'Proceq.ExportColumnMeasurementAvgHardness': 'Mesure dureté moyenne',
		'Proceq.ExportColumnLocationLatitude': 'Latitude de l’emplacement',
		'Proceq.ExportColumnLocationLongitude': 'Longitude de l’emplacement',
		'Proceq.ExportColumnProbeType': 'Type',
		'Proceq.ExportColumnProbeSN': 'Numéro de série',
		'Proceq.ExportColumnBTMSN': 'Numéro de série',
		'Proceq.ExportColumnProbeLastModifiedDate': 'Dernière date de vérification',
		'Proceq.ExportColumnProbeHardwareRevision': 'Révision du matériel',
		'Proceq.ExportColumnProbeFirmwareRevision': 'Révision du micrologiciel',
		'Proceq.ExportColumnBTMHardwareRevision': 'Révision du matériel',
		'Proceq.ExportColumnBTMFirmwareRevision': 'Révision du micrologiciel',
		'Proceq.ExportColumnAppPlatform': 'Plate-forme',
		'Proceq.ExportColumnVersion': 'Version',
		'Proceq.ExportColumnOperator': 'Opérateur',
		'Proceq.ExportColumnPrimaryScale': 'Échelle principale',
		'Proceq.ExportColumnSecondaryScale': 'Échelle secondaire',
		'Proceq.ExportColumnMaterial': 'Matériau',
		'Proceq.ExportColumnConversionStandard': 'Norme de conversion',
		'Proceq.ExportColumnAngleCorrection': 'Correction d’angle',
		'Proceq.ExportColumnValueAuto': 'Auto',
		'Proceq.ExportColumnTableAngleGrades': 'Angle (°)',
		'Proceq.ExportColumnTableLegendExcluded': '* Exclu',
		'Proceq.ExportColumnMeasurementBarGraph': 'Mesure graphique à barres',
		'Proceq.ExportColumnTableView': 'Vue tableau',
		'Proceq.ExportColumnStatistics': 'Statistiques',
		'Proceq.ExportColumnSettings': 'Paramètres',
		'Proceq.ExportColumnImpactDeviceInfo': 'Info instrument de frappe',
		'Proceq.ExportColumnImpactDeviceHammerInfo': 'Info scléromètre',
		'Proceq.ExportColumnBluetoothModuleInfo': 'Info module Bluetooth',
		'Proceq.ExportColumnApplicationInfo': 'Info application',
		'Proceq.ExportColumnAttachedPhotos': 'Photos jointes',
		'Proceq.ButtonActions': 'Actions',
		'Proceq.SerialNotInServiceFTP': 'S/N pas dans service FTP',
		'Proceq.ActionResultsToCSV': 'Exporter résultats au format CSV',
		'Proceq.ErrorDisplayTitleSuccess': 'Succès',
		'Proceq.ErrorNoMultipleTargetsAllowed': 'L’action n’a pas pu être exécutée sur plusieurs lignes',
		'Proceq.MessageActionSuccesfullyPerformed': 'L’action a été exécutée avec succès',
		'Proceq.EmailReminderContractUserSubject': 'Rappel d’expiration de contrat',
		'Proceq.EmailReminderContractUserBody':
			'Cher utilisateur, chère utilisatrice<br/><br/>Votre période de location pour **?0** expirera le **?1**.<br/><br/>Votre contrat est automatiquement prolongé de 12 mois conformément à <a href="http://www.proceq.com/fileadmin/user_upload/Proceq_General_Terms___Conditions_of_Rental_10112016.pdf">nos conditions générales de location</a>. Vous recevrez la facture séparément. Veillez à l’acquitter dans les délais pour garantir une utilisation sans interruption de votre produit Equotip Live.<br/><br/>Contactez votre <a href="http://www.proceq.com/contact/proceq-network/">représentant commercial local</a> en cas de questions.<br/><br/>Votre équipe Proceq<br/><br/>**?2**',
		'Proceq.EmailReminderContractAdminSubject': 'Rappel d’expiration de contrat',
		'Proceq.ErrorNoTargetSelected': 'Aucune cible sélectionnée pour l’action',
		'Proceq.ErrorDisplayTitleInfo': 'Info',
		'Proceq.TextSharingSeriesUrlLinkCreated':
			'La série a été partagée sous le lien suivant:<br/>**?0**<br/><a class="link-copy-clipboard" href="javascript:void(0);" onclick="**?1**">Copier vers presse-papier</a><br/>',
		'App.ShareMeasurementsSuccess': 'La série a été partagée sous le lien suivant',
		'App.ShareMeasurementsCopyAction': 'Copier vers presse-papier',
		'Proceq.TextPleaseWait': 'Merci de patienter...',
		'Proceq.TextPerformingServerRequest': 'Exécution d’une requête serveur',
		'Proceq.TextPerformingAction': 'Exécution d’une action',
		'Proceq.ButtonCreateAccountChinaBack': 'Jeter',
		'Proceq.TitleCreateAccountChina': 'Client chinois',
		'Proceq.BodyCreateAccountChina':
			'Pour les clients en Chine allez sur <a href="**?0**">**?1**</a> pour vous enregistrer ou vous connecter',
		'Proceq.LoginCompatibiltyInfo': 'Informations de comptabilité <a href="**?0**">Applis Proceq</a>',
		'Proceq.TextRedirectingToRegion': 'Redirection vers votre région...',
		'Proceq.EmailReminderContractExpiredTodayUserSubject': 'Expiration du contrat',
		'Proceq.EmailReminderContractExpiredTodayUserBody':
			'Cher utilisateur, chère utilisatrice<br/><br/>Votre période de location pour **?0** a expiré aujourd’hui.<br/><br/>Nous regrettons que vous n’utilisiez plus Proceq Live, mais nous serons ravis de vous accueillir de nouveau.<br/><br/>Pour renouveler le contrat, contactez votre <a href="http://www.proceq.com/contact/proceq-network/">représentant commercial local</a>.<br/><br/>Votre équipe Proceq<br/><br/>**?1**',
		'Proceq.EmailRentalPeriodChangedSubject': 'Période de location changée',
		'Proceq.EmailRentalPeriodChangedBody':
			'Cher utilisateur, chère utilisatrice<br/><br/>La date d’expiration de votre contrat de location pour **?0** a été changée. Votre contrat de location expirera le **?1**.<br/><br/>Contactez votre <a href="http://www.proceq.com/contact/proceq-network/">représentant commercial local</a> en cas de questions.<br/><br/>Votre équipe Proceq<br/><br/>**?2**',
		'Proceq.FormExpiresIn': 'Expire dans (jours)',
		'Proceq.SelectTimeWeek': 'semaine',
		'Proceq.SelectTimeDay': 'jour',
		'Proceq.SelectTimeMonth': 'mois',
		'Proceq.GraphNoDataAvailable': 'aucune donnée disponible',
		'Proceq.GraphMaterial': 'Groupe de matériaux',
		'Proceq.GraphSecondaryScale': 'Échelle de conversion',
		'Proceq.GraphStandard': 'Norme de conversion',
		'Proceq.GraphMeasurementCount': 'Nombre d’impacts',
		'Proceq.GraphMeasurementSeriesCount': 'Compte de série de mesure',
		'Proceq.StatisticsTitle': 'Statistiques',
		'Proceq.StatisticsCreatedSeries': 'série de mesure créée',
		'Proceq.StatisticsModifiedSeries': 'série de mesure modifiée',
		'Proceq.StatisticsPhotos': 'photos',
		'Proceq.StatisticsAudioFiles': 'fichiers audio',
		'Proceq.StatisticsUsedStorage': 'stockage utilisé',
		'Proceq.StatisticsFolder': 'Dossier',
		'Proceq.StatisticsModified': 'Modifié',
		'Proceq.StatisticsCreated': 'Créé',
		'Proceq.StatisticsAll': 'Tout',
		'Proceq.StatisticsCountry': 'Pays',
		'Proceq.StatisticsCompanySize': 'Taille de l’entreprise',
		'Proceq.StatisticsIndustry': 'Industrie',
		'Proceq.DateRangeFrom': 'De',
		'Proceq.DateRangeTo': 'À',
		'Proceq.GraphHistogram': 'Histogramme de dureté',
		'Proceq.GraphSeriesHistogram': 'Série d’histogramme de dureté',
		'Proceq.StatisticsImpactDevice': 'Instrument de frappe',
		'Proceq.StatisticsImpactDeviceHammer': 'Scléromètre',
		'Proceq.GraphExcluded': 'Exclu',
		'Proceq.GraphIncluded': 'Inclus',
		'Proceq.GraphWithinTolerance': 'Dans limites tolérance',
		'Proceq.GraphOutOfTolerance': 'Hors des limites tolérance',
		'Proceq.StatisticsCreatedImpacts': 'Impacts créés (inclus/exclus)',
		'Proceq.StatisticsModifiedImpacts': 'Impacts modifiés (inclus/exclus)',
		'Proceq.GraphNoConversion': 'Aucune conversion',
		'Proceq.StatisticsTotalImpacts': 'Total impacts',
		'Proceq.StatisticsTotalSeries': 'Total séries',
		'Proceq.StatisticsTotalExcluded': 'Total exclus',
		'Proceq.StatisticsValuesOutOfLimits': 'Valeurs hors limites',
		'Proceq.StatisticsPeriod': 'Période',
		'Proceq.LabelFrom': 'De',
		'Proceq.LabelTo': 'À',
		'Proceq.LabelImpacts': 'Impacts',
		'Proceq.AbbrevCalendarWeek': 'CW',
		'Proceq.eRebNStd_Mean': 'Moyenne',
		'Proceq.ExportFieldTargetScale': 'Échelle cible',
		'Proceq.ExportFieldConversionCurve': 'Courbe de conversion',
		'Proceq.eCC_None': 'Aucun',
		'Proceq.eCC_Portland_Cement_J_N': 'Ciment Portland J',
		'Proceq.eCC_Early_Strength_J_N': 'Résistance initiale J',
		'Proceq.eCC_Blast_Furnace_J_N': 'Haut fourneau J',
		'Proceq.eCC_Average_Curve_J_N': 'Courbe moyenne J',
		'Proceq.eSC_R1_dry': 'JSCE-G504 R1 = 0 (sec)',
		'Proceq.eSC_R1_black_mark': 'JSCE-G504 R1 = +3 (marque noire)',
		'Proceq.eSC_R1_wet_surface': 'JSCE-G504 R1 = +5 (surface humide)',
		'Proceq.eCSC_None': 'Aucun',
		'Proceq.eCSC_Upper': 'Supérieur',
		'Proceq.eCSC_Under': 'Inférieur',
		'Proceq.eSCC_US_eCSC_None': 'Résistance unifiée',
		'Proceq.eSCC_US_eCSC_Upper': 'Résistance unifiée sur la surface de moulage',
		'Proceq.eSCC_US_eCSC_Under': 'Résistance unifiée sous la surface de moulage',
		'Proceq.eSCC_PC_eCSC_None': 'Béton pompé',
		'Proceq.eSCC_PC_eCSC_Upper': 'Béton pompé sur la surface de moulage',
		'Proceq.eSCC_PC_eCSC_Under': 'Béton pompé sous la surface de moulage',
		'Proceq.eSCC_US': 'Résistance unifiée',
		'Proceq.eSCC_PC': 'Béton pompé',
		'Proceq.eNoOutlier': 'Pas d’aberration',
		'Proceq.eOutlierLow': 'Aberration faible',
		'Proceq.eOutlierHigh': 'Aberration élevée',
		'Proceq.eOutlierRedundant': 'Aberration redondante',
		'Proceq.LogbookPropertyConversionCurveChangedFromTo': 'Courbe de conversion changée de **?0** en **?1**',
		'Proceq.LogbookPropertyConversionCurveChangedTo': 'Courbe de conversion changée en **?0**',
		'Proceq.LogbookPropertyTargetScaleChangedFromTo': 'Échelle cible changée de **?0** en **?1**',
		'Proceq.LogbookPropertySurfaceCorrectionChangedFromTo': 'Correction de surface changée de **?0** en **?1**',
		'Proceq.LogbookPropertySurfaceCorrectionChangedTo': 'Correction de surface changée en **?0**',
		'Proceq.LogbookPropertyCarbonationDepthChangedFromTo':
			'Profondeur de carbonatation changée de **?0** mm en **?1** mm',
		'Proceq.LogbookPropertySurfaceConditionChangedFromTo': 'Condition de surface changée de **?0** en **?1**',
		'Proceq.LogbookPropertySurfaceConditionChangedTo': 'Condition de surface changée en **?0**',
		'Proceq.LogbookPropertyConcreteAgeChangedFromTo': 'Âge du béton changé de **?0** j en **?1** j',
		'Proceq.LogbookPropertyConcreteAgeChangedTo': 'Âge du béton changé en **?0** j',
		'Proceq.ExportColumnCSVOutlierType': 'Aberration',
		'Proceq.ExportColumnCarbonationDepth': 'Profondeur de carbonatation',
		'Proceq.ExportColumnConcreteAge': 'Âge du béton',
		'Proceq.ExportFieldCarbonationDepth': 'Profondeur de carbonatation',
		'Proceq.ExportFieldConcreteAge': 'Âge du béton',
		'Proceq.ValueConcreteAgeDays': '**?0** j',
		'Proceq.ValueCarbonationDepthMms': '**?0** mm',
		'Proceq.ExportColumnConversionCurve': 'Courbe de conversion',
		'Proceq.ExportColumnTableLegendOutlier': '* Aberration',
		'Proceq.ExportColumnTargetScale': 'Échelle cible',
		'Proceq.ExportColumnMeasurementAvgCompressiveStrength': 'Mesure résistance moyenne à l’écrasement',
		'Proceq.ExportColumnEn13791Screening': 'Contrôle EN 13791',
		'Proceq.ExportColumnLowestRebound': 'Valeur de rebond la plus faible',
		'Proceq.ExportColumnMedianRebound': 'Valeur de rebond moyenne',
		'Proceq.ExportColumnCompressiveStrengthClass': 'Classe de résistance à l’écrasement EN 206',
		'Proceq.EmailIntroDearContractOwner': 'Cher titulaire de contrat',
		'Proceq.ColumnTableSeriesRegionExport': 'Série',
		'Proceq.ExportColumSeriesRegionExport': 'Série',
		'Proceq.ExportTitleTestRegionExport': 'Export région test',
		'Proceq.ErrorSchmidtExportInvalidStandard': 'Toutes les séries doivent avoir la même norme établie',
		'Proceq.ErrorSchmidtExportTooFewSeries': 'Au moins 9 séries sont nécessaires pour le contrôle EN 13791',
		'Proceq.ErrorSchmidtExportTooManySeries':
			'Un total de 99 séries au maximum peut être sélectionné pour une seule exportation',
		'Proceq.ErrorSchmidtExportDifferentConfiguration': 'Différentes configurations trouvées sur la série',
		'Proceq.ErrorSchmidtExportWrongStandard': 'Norme incorrecte trouvée pour l’exportation sélectionnée',
		'Proceq.ErrorSchmidtExportInvalidSeries':
			'Au moins l’une des séries n’est pas valide selon la norme sélectionnée',
		'Proceq.ErrorSchmidtExportSoftwareError': 'Erreur pendant la génération de l’exportation',
		'Proceq.ErrorSchmidtExportOutOfRange': 'La classe de résistance calculée est hors plage',
		'Proceq.ButtonExportTestRegionEU': 'Exporter test contrôle EN 13791 au format PDF',
		'Proceq.ButtonExportTestRegionUniformity': 'Exporter uniformité au format pdf',
		'Proceq.ButtonExportTestRegionChina': 'Exporter région test JGJ T23 au format PDF',
		'Proceq.ErrorNotYetImplemented': 'Pas encore appliqué',
		'Proceq.ErrorSchmidtExportWrongParam':
			'Paramètre incorrect trouvé sur la série. Par exemple: unité incorrecte pour l’exportation sélectionnée',
		'Proceq.ErrorSchmidtExportUnitNotMpa': 'Pour la région JGJ, il est obligatoire que l’unité soit réglée sur MPa',
		'Proceq.ErrorSchmidtExportWrongFormFactor':
			'Pour la région JGJ, le facteur de forme ne doit pas être 2:1 cylindre',
		'Proceq.NavSelectView': 'Sélectionner vue',
		'Proceq.TextConfirmationAreYouSure': 'Êtes-vous sûr?',
		'Proceq.ErrorContractLinkedDifferentImpactDevice':
			'Le contrat ne peut pas être utilisé avec le numéro de série actuel de l’instrument de frappe',
		'Proceq.TitleShareByUrl': 'Partager par URL',
		'Proceq.ExportTitleVerification': 'Rapport de vérification:',
		'Proceq.ExportVerificationStatusFailed': 'Échec',
		'Proceq.ExportVerificationStatusPassed': 'Succès',
		'Proceq.ExportColumnTestVerificationResult': 'Résultat de vérification',
		'Proceq.ExportColumnTestBlockTolerance': 'Incertitude du boc de test (Ucrm) k=2',
		'Proceq.ExportColumnTestBlockNominalHardness': 'Dureté nominale du bloc de test',
		'Proceq.ExportColumnTestBlockSN': 'Numéro de série du bloc de test',
		'Proceq.ExportColumnTestBlockName': 'Nom du bloc de test',
		'Proceq.ExportColumnVerificationStd': 'Norme de vérification',
		'Proceq.ExportColumnVerificationCriteria': 'Critères de vérification',
		'Proceq.VerificationCriteriaAverage5Span5Perc': 'moyenne +/- 5%, ampleur +/- 5%',
		'Proceq.VerificationCriteriaReadings6HL': 'relevés +/- 6 HL',
		'Proceq.VerificationCriteriaReadings3Perc': 'relevés +/- 3%',
		'Proceq.VerificationCriteriaAverage5Perc': 'moyenne +/- 5%',
		'Proceq.VerificationCriteriaAverage2R': 'moyenne +/- 2 R',
		'Proceq.VerificationCriteriaReadings3R': 'relevés +/- 3 R',
		'Proceq.StandardEnumCustom': 'Personnalisé',
		'Proceq.MaterialEnumCustom': 'Personnalisé',
		'Proceq.ExportColumnCalibrationAnvilName': 'Nom de l’enclume d’étalonnage',
		'Proceq.ExportColumnCalibrationAnvilSN': 'Numéro de série de l’enclume d’étalonnage',
		'Proceq.ExportColumnCalibrationAnvilReboundValue': 'Valeur de rebond de l’enclume d’étalonnage',
		'Proceq.ExportColumnCalibrationAnvilTolerance': 'Tolérance de l’enclume d’étalonnage',
		'Proceq.ExportColumnTestLoadInfo': 'Info charge de test',
		'Proceq.NavVerificationData': 'Données de vérification',
		'Proceq.NavCustomData': 'Données personnalisées',
		'Proceq.TableColumnVerificationResult': 'Résultat',
		'Proceq.TitleVerifications': 'Vérifications',
		'Proceq.LogBookTestBlock': 'Bloc de test',
		'Proceq.LogBookCalibrationAnvil': 'Enclume d’étalonnage',
		'Proceq.LogbookPropertyTestBlockChangedFromTo': 'Le bloc de test a été changé de "**?0**" en "**?1**"',
		'Proceq.LogbookPropertyTestBlockChangedTo': 'Le bloc de test a été changé en "**?0**"',
		'Proceq.LogbookPropertyCalibrationAnvilChangedFromTo':
			'L’enclume d’étalonnage a été changée de **?0** en **?1**',
		'Proceq.LogbookPropertyCalibrationAnvilChangedTo': 'L’enclume d’étalonnage a été changée en **?0**',
		'Proceq.LogbookPropertyCreatedVerification': 'Vérification créée "**?0**"',
		'Proceq.LogbookPropertyRenamedVerificationFromTo': 'La vérification "**?0**" a été renommée en "**?1**"',
		'Proceq.LogbookResultVerificationFailed': 'Échec vérification',
		'Proceq.LogbookResultVerificationPassed': 'Vérification réussie',
		'Proceq.LogbookPropertyTestLoadChangedFromTo': 'Charge de test changé de **?0** en **?1**',
		'Proceq.LogbookPropertyTestLoadChangedTo': 'Charge de test changé en **?0**',
		'Proceq.LogBookTestLoad': 'Charge de test',
		'Proceq.FieldWebOriginalCurve': 'Original',
		'Proceq.FieldWebCustomCurve': 'Personnalisé',
		'Proceq.ExportFieldValuePair': 'Paire de valeurs',
		'Proceq.TableBaseMaterial': 'Matériau de base',
		'Proceq.TableDescription': 'Description',
		'Proceq.NavCustomMaterial': 'Matériau personnalisé',
		'Proceq.FolderCustomMaterials': 'Matériaux personnalisés',
		'Proceq.SchmidtCurveTypePolynomial': 'fck = a + b*R + c*R² (polynomial)',
		'Proceq.SchmidtCurveTypeExponencial': 'fck = a.e^(b*R) (exponentiel)',
		'Proceq.SchmidtCurveTypePolynomialShort': 'Polynomial',
		'Proceq.SchmidtCurveTypeExponencialShort': 'Exponentiel',
		'Proceq.ExportFieldCurveType': 'Type courbe',
		'Proceq.TableActionEdit': 'Éditer',
		'Proceq.TableActionAdd': 'Ajouter',
		'Proceq.WebAccountActivatedTitle': 'Bienvenue dans Proceq Live,',
		'Proceq.WebAccountActivatedBodyLine1':
			'Votre compte a été activé avec succès. Il est désormais prêt à être utilisé!',
		'Proceq.WebAccountActivatedProceqTeam': 'Équipe Proceq',
		'Proceq.NavDashboard': 'Tableau de bord',
		'App.SchmidtFormFactorRAngle': 'Angle R corrigé',
		'App.SchmidtFormFactorQAngle': 'Valeur Q',
		'App.ShareMeasurementsCopySuccess': 'Copié!',
		'App.ShareMeasurementsCopyFailed':
			'Votre navigateur ne prend pas en charge la copie. Veuillez copier manuellement.',
		'App.SystemFolders': 'Dossiers système',
		'Proceq.DataSeriesDemo': 'Démo',
		'Proceq.DataSeriesImported': 'Importé',
		'Antenna Spacing [{unit}]': 'Espacement antenne [{unit}]',
		'App Version': 'Version de l&#39;application',
		'Base Unit S/N': 'Unité de base S / N',
		'Calculated Depth': 'Profondeur calculée',
		Comment: 'Commentaire',
		'Contract ID': 'Identifiant du contrat',
		'Distance X [{unit}]': 'Distance X [{unit}]',
		'Distance Y [{unit}]': 'Distance Y [{unit}]',
		'Export HTML Format Version': 'Exporter la version au format HTML',
		'File ID': 'ID de fichier',
		'File Name': 'Nom de fichier',
		'Firmware Version': 'Version du firmware',
		'Grid Size X [{unit}]': 'Taille de la grille X [{unit}]',
		'Grid Size Y [{unit}]': 'Taille de la grille Y [{unit}]',
		'Grid Spacing X [{unit}]': 'Espacement de la grille X [{unit}]',
		'Grid Spacing Y [{unit}]': 'Espacement de la grille Y [{unit}]',
		'Hardware Version': 'Version matérielle',
		Line: 'Ligne',
		'MAC Address': 'Adresse Mac',
		'Marker Number': 'Numéro de marqueur',
		'Measurement Presets': 'Préréglages de mesure',
		Mode: 'Mode',
		Name: 'Nom',
		Objects: 'Objets',
		'Probe S/N': 'Sonde N / S',
		Product: 'Produit',
		'Repetition Rate [{unit}]': 'Taux de répétition [{unit}]',
		Resolution: 'Résolution',
		'Sampling Rate [{unit}]': 'Taux d&#39;échantillonnage [{unit}]',
		'Scan Distance [{unit}]': 'Distance de numérisation [{unit}]',
		'Scan Length [{unit}]': 'Longueur de balayage [{unit}]',
		'Set Depth': 'Définir la profondeur',
		'Set Size': 'Définir la taille',
		Tag: 'Étiquette',
		'Tag Type': 'Type de tag',
		'Time Window [{unit}]': 'Fenêtre de temps [{unit}]',
		Units: 'Unités',
		'User / Probe Info': 'Informations utilisateur / sonde',
		Markers: 'Marqueurs',
		'Created {fileName} in folder {folderName}': 'Créé {fileName} dans le dossier {folderName}',
		'Mode: {value}': 'Mode: {value}',
		'App.HTML.FDL.MeasurementPresets.DgsCalibration': 'Calibration AVG',
		'App.HTML.FDL.MeasurementPresets.DgsSnapshot': 'Capture AVG',
		'App.HTML.FDL.IndicationInfo.Backwall': 'Fond',
		'App.HTML.FDL.IndicationInfo.ReferenceType': 'Type de référence',
		'App.HTML.FDL.IndicationInfo.ProbeType': 'Type de transducteur',
		'App.HTML.FDL.IndicationInfo.calibratedDistance': 'Distance de calibration ({unit})',
		'App.HTML.FDL.IndicationInfo.CalibratedAmplitude': 'Amplitude de calibration ({unit})',
		'App.HTML.FDL.IndicationInfo.Attenuation': 'Atténuation ({unit})',
		'App.HTML.FDL.IndicationInfo.ErsMainCurve': 'Courbe du réflecteur équivalent ({unit})',
		'App.HTML.FDL.DgsSnapshot.G1DistanceToDefect': 'G1 Distance au défaut ({unit})',
		'App.HTML.FDL.DgsSnapshot.G1AmplitudeOfDefect': 'G1 Amplitude du défaut ({unit})',
		'App.HTML.FDL.DgsSnapshot.G1.Ers': 'G1 Réfl. eq. ({unit})',
		'App.HTML.FDL.DgsSnapshot.G1DbToCurve': 'G1 dB versus courbe ({unit})',
		'App.HTML.FDL.DgsSnapshot.G2DistanceToDefect': 'G2 Distance au défaut ({unit})',
		'App.HTML.FDL.DgsSnapshot.G2AmplitudeOfDefect': 'G2 Amplitude du défaut ({unit})',
		'App.HTML.FDL.DgsSnapshot.G2.Ers': 'G2 Réfl. eq. ({unit})',
		'App.HTML.FDL.DgsSnapshot.G2DbToCurve': 'G2 dB versus courbe ({unit})',
		'Auth.LoggingIn': 'Authentification...',
		'Auth.LoggingOut': 'Déconnexion…',
	},
};
