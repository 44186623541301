module.exports = {
	shortlang: 'ko',
	lang: 'ko',
	messages: {
		'Proceq.ErrorWrongUsername': '잘못된 이메일을 입력했습니다.',
		'Proceq.ErrorCreationNotAllowed': '새 계정의 작성이 비활성화되었습니다.',
		'Proceq.ErrorWrongPassword': '잘못된 암호를 입력했습니다.',
		'Proceq.ErrorWrongFirstName': '잘못된 이름을 입력했습니다.',
		'Proceq.ErrorWrongLastName': '잘못된 성을 입력했습니다.',
		'Proceq.ErrorUsernameAlreadyExists': '동일한 이메일의 계정이 이미 존재합니다.',
		'Proceq.ErrorDB': '데이터베이스 작업을 수행하는 중 오류가 발생했습니다.',
		'Proceq.InvalidUsername': '사용자 계정을 찾을 수 없습니다.',
		'Proceq.AlreadyMultipleForgot': '이미 복수의 재설정 암호를 요청했습니다.',
		'Proceq.InvalidNewPassword': '잘못된 새 암호를 입력했습니다.',
		'Proceq.InvalidResetCode': '암호 재설정 코드가 잘못되었거나 이미 만료되었습니다.',
		'Proceq.InvalidOldPassword': '잘못된 기존 암호를 입력했습니다.',
		'Proceq.InvalidAccessRights': '세션이 만료되었거나 사용자가 이 작업을 수행할 수 없습니다.',
		'Proceq.ErrorNoAccountType': '계정 유형을 입력하지 않았습니다.',
		'Proceq.ErrorNoUsername': '이메일을 입력하지 않았습니다.',
		'Proceq.InvalidCountry': '국가를 잘못 입력했습니다.',
		'Proceq.ErrorCreatingUser': '새 사용자를 작성하는 중 오류가 발생했습니다.',
		'Proceq.InvalidID': '잘못된 ID를 입력했습니다.',
		'Proceq.InvalidTransactionID': '잘못된 트랜잭션 ID',
		'Proceq.InvalidFileContent': '파일 내용을 읽는 중 오류가 발생했습니다.',
		'Proceq.InvalidTypeEntry': '잘못된 항목 유형',
		'Proceq.InvalidContractID': '계약이 잘못되었거나 만료되었습니다.',
		'Proceq.InvalidExistingID': 'ID가 이미 존재하기 때문에 작성할 수 없습니다.',
		'Proceq.InvalidDataSeriesType': '잘못된 시리즈 유형',
		'Proceq.InvalidMetaDataContent': '잘못된 메타 데이터 내용',
		'Proceq.ErrorFileIsLocked': '하나 이상의 파일이 잠겨 있습니다. 나중에 다시 시도해 주십시오.',
		'Proceq.MissingAttachmentFiles': '서버에서 첨부 파일을 찾을 수 없습니다.',
		'Proceq.InvalidFlagType': '잘못되거나 누락된 플래그 유형',
		'Proceq.MissingDateTypeFields': '시리즈에 대해 필수 필드가 누락되었습니다.',
		'Proceq.InvalidFolderID': '잘못된 폴더 ID',
		'Proceq.InvalidDataSeriesName': '잘못된 시리즈 이름',
		'Proceq.ErrorDateFormat': '날짜 형식을 잘못 입력했습니다.',
		'Proceq.ErrorDataFormat': '시리즈의 데이터를 해석할 수 없습니다.',
		'Proceq.InvalidDownloadID': '다운로드 토큰이 잘못되었거나 이미 만료되었습니다.',
		'Proceq.InvalidVersionNr': '잘못된 버전 번호를 입력했습니다.',
		'Proceq.ErrorDataMissing': '저장 시스템에서 데이터를 복원할 수 없습니다.',
		'Proceq.ErrorSavingFile': '서버가 파일을 저장할 수 없습니다.',
		'Proceq.ErrorInsertDB': 'DB에 삽입하는 중 오류 발생',
		'Proceq.InvalidFolderName': '잘못된 폴더 이름을 입력했습니다.',
		'Proceq.InvalidActionID': '잘못되거나 만료된 URL 링크',
		'Proceq.AlreadyConfirmed': '계정이 이미 확인되었습니다.',
		'Proceq.InvalidActionType': '요청된 작업을 처리할 수 없습니다.',
		'Proceq.ErrorGeneratingChart': '차트 생성 중 오류 발생',
		'Proceq.InvalidSerialID': '잘못된 일련 ID',
		'Proceq.InvalidHardwareID': '잘못된 하드웨어 ID',
		'Proceq.InvalidEntityID': '개체 ID가 잘못되었거나 삭제되었습니다.',
		'Proceq.InvalidEntityType': '잘못된 개체 유형',
		'Proceq.InvalidEntityVerNr': '잘못된 개체 버전 번호',
		'Proceq.ErrorTransactionLocked': '개체가 잠겨 있어서 수정할 수 없습니다.',
		'Proceq.InvalidDateValue': '날짜를 해석할 수 없습니다.',
		'Proceq.MissingFields': '한 개 이상의 필수 필드가 누락되어 있습니다.',
		'Proceq.ErrorColumnValue': '한 개 이상의 필드에 잘못된 값을 입력했습니다.',
		'Proceq.MissingUidDBValue': '누락된 항목 ID',
		'Proceq.ErrorFileID': '잘못된 파일 ID를 입력했습니다.',
		'Proceq.ErrorInsertDownload': '다운로드 작성 중 오류 발생',
		'Proceq.InvalidAppVersion': '현재 앱 버전이 더 이상 지원되지 않습니다. 업데이트하십시오.',
		'Proceq.MissingUserInfo': '누락된 사용자 정보',
		'Proceq.InvalidAccessToken': '잘못되었거나 만료된 세션 토큰',
		'Proceq.ErrorGeneratingCSV': 'CSV 내보내기 생성 중 오류 발생',
		'Proceq.ErrorGeneratingExport': '내보내기 작성 중 오류 발생',
		'Proceq.MissingParams': '한 개 이상의 필수 매개변수가 누락되어 있습니다.',
		'Proceq.SerialNotFound': '이 장치에 대한 계약을 찾을 수 없습니다.',
		'Proceq.StandardEnumJapan': '일본',
		'Proceq.StandardEnumDefault': '기본값',
		'Proceq.MaterialEnumIndependent': '재료 독립적',
		'Proceq.MaterialEnumSteelAndCastSteel': '강철 및 주조강',
		'Proceq.MaterialEnumWorkToolSteel': '공구강',
		'Proceq.MaterialEnumStainlessSteel': '스테인리스 강',
		'Proceq.MaterialEnumGrayCastIron': '회색 주철(GG)',
		'Proceq.MaterialEnumNodularCastIron': '구상 흑연 주철(GGG)',
		'Proceq.MaterialEnumCastAlluminiumAlloys': '주조 알루미늄 합금',
		'Proceq.MaterialEnumBrassCopperZincAlloys': '청동 - 구리/아연 합금',
		'Proceq.MaterialEnumBronze': '청동',
		'Proceq.MaterialEnumWroughtCopperAlloys': '가공된 구리합금',
		'Proceq.MaterialEnumTitanium': '티타늄',
		'Proceq.ScaleEnumCustom': '사용자 정의',
		'Proceq.ScaleEnumPenetrationDepth': '침투 깊이',
		'Proceq.CompanySizeMicro': '1-9명의 직원',
		'Proceq.CompanySizeSmall': '10-49명의 직원',
		'Proceq.CompanySizeMedium': '50-249명의 직원',
		'Proceq.CompanySizeLarge': '249명 초과 직원',
		'Proceq.SalutationMr': 'Mr.',
		'Proceq.SalutationMs': 'Ms.',
		'Proceq.SalutationDr': 'Dr.',
		'Proceq.SalutationProf': 'Prof.',
		'Proceq.CategoryAerospace': '우주항공',
		'Proceq.CategoryAutomotive': '자동차',
		'Proceq.CategoryChemicalProcessing': '화학적 처리',
		'Proceq.CategoryConstructionBuilding': '건설/건축',
		'Proceq.CategoryConsultantTestLabsEngineer': '컨설턴트/테스트 실험실/엔지니어',
		'Proceq.CategoryEducationInstituteRD': '교육/기관/R&D',
		'Proceq.CategoryFilmProducerConverter': '영화 제작사/변환자',
		'Proceq.CategoryGeologyRocktesters': '지질학/암석 테스터',
		'Proceq.CategoryMachineryToolingEquipment': '기계/공구/장비',
		'Proceq.CategoryMassTransportation': '대중 교통',
		'Proceq.CategoryMetalWorks': '금속 공사',
		'Proceq.CategoryOilAndGas': '오일 및 가스',
		'Proceq.CategoryOthers': '기타',
		'Proceq.CategoryPaperProducerConverter': '제지 업체/변환자',
		'Proceq.CategorySalesChannel': '영업망',
		'Proceq.ErrorWrongLoginCredentials': '사용자가 입력한 사용자 이름 또는 암호가 올바르지 않습니다.',
		'Proceq.ErrorEmailNotVerified': '이메일이 아직 확인되지 않았습니다.',
		'Proceq.ServerError': '서버 오류',
		'Proceq.TooManyActionEmailRequests': '요청이 너무 많습니다. 우편함에 이메일이 도착할 때까지 기다려 주십시오.',
		'Proceq.ErrorServerUnderMaintenance': '서버가 유지 보수 중입니다.',
		'Proceq.TableDataSeriesName': '이름',
		'Proceq.TableCreatedOn': '작성 날짜',
		'Proceq.TableModifiedOn': '수정 날짜',
		'Proceq.TableCreatedBy': '작성자',
		'Proceq.TableNumMean': '평균',
		'Proceq.TableNumTotal': '합계',
		'Proceq.TableNumStandardDeviation': '표준 편차',
		'Proceq.TableImpactDevice': '프로브',
		'Proceq.TableImpactDeviceHammer': '해머',
		'Proceq.TableMaterial': '재료',
		'Proceq.TableScaleUnit': '스케일 단위',
		'Proceq.TableActionRefresh': '새로 고침',
		'Proceq.TableActionExpand': '확장',
		'Proceq.TableActionCollapse': '축소',
		'Proceq.TableActionExportAsPDF': 'PDF로 내보내기',
		'Proceq.TableActionExportAsCSV': 'CSV로 내보내기',
		'Proceq.TableActionExportAsJSON': 'JSON로 내보내기',
		'Proceq.SearchOperationEquals': '동등',
		'Proceq.SearchOperationContains': '포함',
		'Proceq.SearchOperationDifferent': '차이',
		'Proceq.SearchOperationGreaterOrEqual': '이상',
		'Proceq.SearchOperationGreater': '초과',
		'Proceq.SearchOperationLesserOrEqual': '이하',
		'Proceq.SearchOperationLesser': '미만',
		'Proceq.ExportFieldExportDate': '만료 날짜',
		'Proceq.ExportFieldLimits': '한계',
		'Proceq.ExportFieldHigh': '높음',
		'Proceq.ExportFieldLow': '낮음',
		'Proceq.ExportFieldOff': '꺼짐',
		'Proceq.ExportFieldLowerLimit': '하한값',
		'Proceq.ExportFieldUpperLimit': '상한값',
		'Proceq.ExportFieldReadings': '판독값',
		'Proceq.ExportFieldStandardDeviation': '표준 편차',
		'Proceq.ExportFieldRange': '범위',
		'Proceq.ExportFieldRelativeSpan': '상대 범위',
		'Proceq.ExportFieldMaximum': '최대',
		'Proceq.ExportFieldMinimum': '최소',
		'Proceq.ExportFieldAverage': '평균',
		'Proceq.ExportFieldUnit': '단위',
		'Proceq.ExportFieldName': '이름',
		'Proceq.ExportFieldMeasurementDate': '측정 날짜',
		'Proceq.ExportFieldLocationLatitude': '위치 위도',
		'Proceq.ExportFieldLocationLongitude': '위치 경도',
		'Proceq.ExportFieldProbeType': '프로브 유형',
		'Proceq.ExportFieldProbeSerialNumber': '프로브 일련 번호',
		'Proceq.ExportFieldProbeBTSerialNumber': '프로브 Bluetooth 일련 번호',
		'Proceq.ExportFieldProbeLastVerificationDate': '프로브 최종 확인 날짜',
		'Proceq.ExportFieldPrimaryScale': '2차 스케일',
		'Proceq.ExportFieldSecondaryScale': '1차 스케일',
		'Proceq.ExportFieldMaterial': '재료',
		'Proceq.ExportFieldStandard': '표준',
		'Proceq.ExportFieldNoConversion': '변환 없음',
		'Proceq.ExportFieldMeasurements': '측정',
		'Proceq.ExportFieldMore': '초과',
		'Proceq.ExportFieldLess': '미만',
		'Proceq.ExportFieldStatistics': '통계',
		'Proceq.ExportFieldSettings': '설정',
		'Proceq.DataSeriesLogbook': '로그북',
		'Proceq.DataSeriesAllMeasurements': '모든 측정',
		'Proceq.DataSeriesFlagged': '플래그됨',
		'Proceq.DataSeriesTrash': '휴지통',
		'Proceq.BrowserNoSupportAudio': '사용자의 브라우저는 오디오 파일을 지원하지 않습니다.',
		'Proceq.EmailPasswordRecoverySubject': '**?0**에 대한 암호 변경 요청',
		'Proceq.EmailPasswordRecoveryBody':
			'**?0** **?1** 귀하<br/><br/>귀하께서는 새 암호를 요청하셨습니다. 암호를 재설정하시려면 아래 링크를 클릭해 주십시오.<br/><br/><a href="**?2**">암호 재설정</a><br/><br/>암호 변경을 요청하지 않으신 경우라면, 본 메시지를 무시해 주십시오. 메시지는 12시간 후에 만료됩니다.<br/><br/>Proceq 팀<br/><br/>**?3**',
		'Proceq.EmailRegistrationSubject': '**?0**에 대한 등록 완료',
		'Proceq.EmailRegistrationBody':
			'**?0** **?1** 귀하,<br/><br/>**?5**에 등록해 주셔서 감사합니다. 아래 링크를 클릭하셔서 등록을 완료해 주십시오.<br/><br/><a href="**?2**">**?2**</a><br/><br/>본 이메일은 귀하께서 등록 신청서를 작성하셨기 때문에 자동으로 생성되었습니다. 등록 신청서를 작성하지 않으신 경우라면, 본 메시지를 무시해 주십시오.<br/><br/> 등록을 완료하시면 당사의 <a href="**?3**">개인정보 보호 정책</a>에 동의하시게 됩니다.<br/><br/>Proceq 팀<br/><br/>**?4**',
		'Proceq.LogbookActionNewMeasurement': '새 측정',
		'Proceq.LogbookPropertyCreatedSeriesInFolder': '시리즈 "**?0**"을(를) "**?1**" 폴더에 작성했습니다.',
		'Proceq.LogbookPropertyRenamedSeriesFromTo': '시리즈 이름이 "**?0**"에서 "**?1**"(으)로 변경되었습니다.',
		'Proceq.LogbookPropertyMovedSeriesFromToTrash': '시리즈가 "**?0**" 폴더에서 휴지통으로 이동했습니다.',
		'Proceq.LogbookPropertyMovedSeriesFromTrashTo': '시리즈가 휴지통에서 "**?0**" 폴더로 이동했습니다.',
		'Proceq.LogbookPropertyMovedSeriesFromTo': '시리즈가 "**?0**" 폴더에서 "**?1**" 폴더로 이동했습니다.',
		'Proceq.LogbookPropertySecondaryScaleChangedFromTo': '2차 스케일이 **?0**에서 **?1**(으)로 변경되었습니다.',
		'Proceq.LogbookPropertyMaterialChangedFromTo': '재료가 **?0**에서 **?1**(으)로 변경되었습니다.',
		'Proceq.LogbookPropertyMaxLimitChangedFromTo': '상한값이 **?0**에서 **?1**(으)로 변경되었습니다.',
		'Proceq.LogbookPropertyMinLimitChangedFromTo': '하한값이 **?0**에서 **?1**(으)로 변경되었습니다.',
		'Proceq.LogbookPropertyStandardChangedFromTo': '표준이 **?0**에서 **?1**(으)로 변경되었습니다.',
		'Proceq.LogbookPropertyTextCommentAdded': '텍스트 명령 추가됨',
		'Proceq.LogbookPropertyTextCommentDeletedOn': '**?0**에 삭제됨',
		'Proceq.LogbookPropertyMeasurementAdded': '**?0** 추가됨',
		'Proceq.LogbookPropertyMeasurementExcluded': '**?0** 제외됨',
		'Proceq.LogbookPropertyMeasurementIncluded': '**?0** 포함됨',
		'Proceq.LogbookPropertyMeasurementDeleted': '**?0** 삭제됨',
		'Proceq.LogbookPropertyAttachmentDeletedOn': '**?0**에 삭제됨',
		'Proceq.LogbookPropertyPhotoAdded': '추가된 사진',
		'Proceq.LogbookPropertyAudioAdded': '오디오 의견 추가됨',
		'Proceq.LogbookPropertySeriesFlagged': '플래그된 시리즈',
		'Proceq.LogbookPropertySeriesUnflagged': '플래그 해제된 시리즈',
		'Proceq.LogbookPropertySerialNumberShort': 'S/N',
		'Proceq.LogbookPropertyBluetoothModuleShort': 'BTM S/N',
		'Proceq.LogbookPropertyVerified': '확인됨',
		'Proceq.LogbookPropertyContractValidUntil': '계약 유효 기간',
		'Proceq.LogbookPropertyOperator': '작업자',
		'Proceq.LogbookTitle': '로그북',
		'Proceq.LogbookDownload': '다운로드',
		'Proceq.LogbookLoadingData': '데이터 로드 중...',
		'Proceq.LogbookClose': '닫음',
		'Proceq.FormCompany': '회사',
		'Proceq.FormScreeningEagleID': 'Screening Eagle ID (Email)',
		'Proceq.FormEmailGroupID': '이메일',
		'Proceq.FormCustomerEmail': '고객 이메일',
		'Proceq.FormProbeSN': '프로브 S/N',
		'Proceq.FormBluetoothSN': 'S/N',
		'Proceq.FormBluetoothHWID': '하드웨어 ID',
		'Proceq.FormExpirationDate': '만료 날짜',
		'Proceq.TitleRegisterProbeSNs': '등록 임대 계약',
		'Proceq.ButtonSubmit': '제출',
		'Proceq.ButtonReset': '재설정',
		'Proceq.ButtonResetPassword': '암호 재설정',
		'Proceq.MessageSuccesfullyInserted': '성공적으로 삽입됨',
		'Proceq.MessageSuccesfullyEmailConfirmed': '이메일을 확인했습니다.',
		'Proceq.MessagePasswordSuccesfullyChanged': '암호가 성공적으로 변경됨',
		'Proceq.MessageSuccesfullyUpdated': '성공적으로 업데이트됨',
		'Proceq.MessageAccountCreated':
			'로그인 확인 이메일을 보내드렸습니다. 이메일에 있는 링크를 클릭하셔서 등록 절차를 완료해 주십시오.',
		'Proceq.MessageEmailForgotPassword': '자세한 지시 사항이 있는 이메일을 보내드렸습니다.',
		'Proceq.MessageEmailResendConfirm': '자세한 지시 사항이 있는 이메일을 보내드렸습니다.',
		'Proceq.MessageLoggingIn': '로그인 중...',
		'Proceq.LoginNeedAccount': '계정이 필요합니까?',
		'Proceq.LoginCreateAccount': '계정 작성',
		'Proceq.TitleForgotPassword': '암호 분실',
		'Proceq.TitleResendConfirmation': '확인 이메일 재전송',
		'Proceq.FormGroup': '그룹',
		'Proceq.FormFirstName': '이름',
		'Proceq.FormLastName': '성',
		'Proceq.FormEmail': '이메일 계정',
		'Proceq.FormCity': '시',
		'Proceq.FormPassword': '암호',
		'Proceq.FormPasswordNew': '새 암호',
		'Proceq.FormPasswordOld': '기존 암호',
		'Proceq.FormConfirmPassword': '암호 확인',
		'Proceq.FormConfirmPasswordNew': '새 암호 확인',
		'Proceq.ButtonRegister': '등록',
		'Proceq.ButtonSignIn': '로그인',
		'Proceq.TitleSignIn': '로그인',
		'Proceq.LoginAlreadyRegistered': '이미 등록했습니까?',
		'Proceq.TextOrSignInUsing': '또는 로그인 방법',
		'Proceq.NavSearchSerials': '계약 검색',
		'Proceq.NavRegisterProbeSNs': '등록 계약',
		'Proceq.NavCreateStaffAccounts': '직원 계정 작성',
		'Proceq.NavHistoryLog': '내역 로그',
		'Proceq.NavMeasurementsData': '측정 데이터',
		'Proceq.NavChangePassword': '암호 변경',
		'Proceq.NavLogout': '로그아웃',
		'Proceq.NavUpdateProfile': '프로파일 업데이트',
		'Proceq.TitleUpdateProfile': '프로파일 업데이트',
		'Proceq.NavCreateCustomersAccounts': '고객 계정 작성',
		'Proceq.ButtonSearch': '검색',
		'Proceq.ButtonUpdate': '업데이트',
		'Proceq.ButtonCreate': '작성',
		'Proceq.ButtonChange': '변경',
		'Proceq.FormSearchBy': '검색 단위',
		'Proceq.FormValue': '값',
		'Proceq.FormAccountType': '계정 유형',
		'Proceq.FormAccountTypeAdmin': '관리자',
		'Proceq.FormAccountTypeBU': '사업부',
		'Proceq.FormCompanyName': '회사 이름',
		'Proceq.FormAddress': '주소',
		'Proceq.FormZipCode': '우편 번호',
		'Proceq.FormCountry': '국가',
		'Proceq.FormPhoneNumber': '전화',
		'Proceq.FormCompanyWebsite': '회사 웹사이트',
		'Proceq.FormJobTitle': '직위',
		'Proceq.FormIndustry': '업계',
		'Proceq.FormSalutation': '호칭',
		'Proceq.FormCompanySize': '회사 규모',
		'Proceq.FormLanguage': '언어',
		'Proceq.FormContactByProceq': 'Proceq 연락처',
		'Proceq.LanguageEnglish': '영어',
		'Proceq.LanguageJapanese': '일본어',
		'Proceq.LanguageChinese': '중국어',
		'Proceq.LanguageGerman': '독일어',
		'Proceq.TextYes': '예',
		'Proceq.TextNo': '아니오',
		'Proceq.FormNewsletter': '뉴스레터',
		'Proceq.TitleSearchSerials': '계약 검색',
		'Proceq.TitleChangePassword': '암호 변경',
		'Proceq.TabLoginCustomers': '고객',
		'Proceq.TabLoginSingleUser': '단일 사용자',
		'Proceq.TabLoginGroup': '그룹',
		'Proceq.TextRemainingXDays': '아직 **?0**일이 남아 있습니다.',
		'Proceq.TextInfoShort': '정보',
		'Proceq.TextIAgreeTerms': '<a>약관</a>에 동의합니다.',
		'Proceq.ErrorDisplayTitleError': '오류',
		'Proceq.ErrorDisplayTitleWarning': '경고',
		'Proceq.LoginBackLogin': '로그인으로 돌아가기',
		'Proceq.LoginForgotPasswordQuestion': '암호를 분실했습니까?',
		'Proceq.LoginResendConfirmationEmail': '확인 이메일 재전송',
		'Proceq.LoginRegisterAccountTextPart': '계정이 없는 경우에는,',
		'Proceq.LoginRegisterAccountLinkPart': '여기에서 등록하십시오.',
		'Proceq.LoginProceqUseOnly': 'Proceq 내부 전용입니다.',
		'Proceq.ProfileEnterInfo': '계속 진행하기 전에 프로파일 정보를 입력하십시오.',
		'Proceq.TextInfoExclamation': '정보!',
		'Proceq.FooterLegalPrivacy': '법률 & 개인정보 보호',
		'Proceq.JQGridTableRecordText': '보기 {0} - {1} / {2}',
		'Proceq.JQGridTableEmptyRecords': '표시할 레코드 없음',
		'Proceq.JQGridTableLoadText': '로드 중...',
		'Proceq.JQGridTablePaginationText': '페이지 {0} / {1}',
		'Proceq.JQGridTableSavingText': '저장 중...',
		'Proceq.JQGridTableFirstPage': '첫 페이지',
		'Proceq.JQGridTableLastPage': '최종 페이지',
		'Proceq.JQGridTableNextPage': '다음 페이지',
		'Proceq.JQGridTablePreviousPage': '이전 페이지',
		'Proceq.JQGridTableRecordsPerPage': '페이지 당 레코드',
		'Proceq.JQGridTablePageText': '페이지:',
		'Proceq.JQGridTableNoMoreRecs': '추가 레코드 없음...',
		'Proceq.ExportColumnCSVNo': '번호',
		'Proceq.ExportColumnCSVSingleReadings': '단일 레코딩',
		'Proceq.ExportColumnCSVMeasurementValue': '측정값',
		'Proceq.ExportColumnCSVAngleDegrees': '각도(°)',
		'Proceq.ExportColumnCSVExcluded': '제외됨',
		'Proceq.ExportColumnCSVSeriesName': '시리즈 이름',
		'Proceq.ExportColumnCSVImpactDevice': '임팩트 장치',
		'Proceq.ExportColumnCSVImpactDeviceSN': '임팩트 디바이스 S/N',
		'Proceq.ExportColumnCSVImpactDeviceVerification': '임팩트 디바이스 검증',
		'Proceq.ExportColumnCSVImpactDeviceHammerVerification': '해머 검증',
		'Proceq.ExportColumnCSVImpactDeviceHammer': '해머',
		'Proceq.ExportColumnCSVImpactDeviceHammerSN': '해머 S/N',
		'Proceq.ExportColumnCSVBluetoothModuleSN': 'Bluetooth 모듈 S/N',
		'Proceq.ExportColumnCSVConversionStandard': '변환 표준',
		'Proceq.ExportColumnCSVScale': '스케일',
		'Proceq.ExportColumnCSVUnit': '단위',
		'Proceq.ExportColumnCSVReadings': '판독값',
		'Proceq.ExportColumnCSVStandardDeviation': '표준 편차',
		'Proceq.ExportColumnCSVRelativeSpan': '상대 범위(%)',
		'Proceq.ExportColumnCSVDebugDataSeriesName': '시리즈 이름',
		'Proceq.ExportColumnExportDate': '만료 날짜',
		'Proceq.ExportColumnUpperLimit': '상한값',
		'Proceq.ExportColumnLowerLimit': '하한값',
		'Proceq.ExportColumnLimits': '한계',
		'Proceq.ExportColumnHigh': '높음',
		'Proceq.ExportColumnLow': '낮음',
		'Proceq.ExportColumnValueOff': '꺼짐',
		'Proceq.ExportColumnValueNoConversion': '변환 없음',
		'Proceq.ExportColumnValueNoConversionShort': '변환 없음',
		'Proceq.ExportColumnReadings': '판독값',
		'Proceq.ExportColumnStandardDeviation': '표준 편차',
		'Proceq.ExportColumnRange': '범위',
		'Proceq.ExportColumnRelativeSpan': '상대 범위',
		'Proceq.ExportColumnMaximum': '최대',
		'Proceq.ExportColumnMinimum': '최소',
		'Proceq.ExportColumnAverage': '평균',
		'Proceq.ExportColumnUnit': '단위',
		'Proceq.ExportColumnName': '이름',
		'Proceq.ExportColumnDataSeriesID': '시리즈 ID',
		'Proceq.ExportColumnFolderName': '폴더 이름',
		'Proceq.ExportColumnCreatedDate': '작성 날짜',
		'Proceq.ExportColumnCreatedDateOnly': '작성 날짜',
		'Proceq.ExportColumnCreatedTime': '작성 시간',
		'Proceq.ExportColumnLastModifiedDate': '최종 수정 날짜',
		'Proceq.ExportColumnLastModifiedDateOnly': '최종 수정 날짜',
		'Proceq.ExportColumnLastModifiedTime': '최종 수정 시간',
		'Proceq.ExportColumnMeasurementAvgHardness': '측정 평균 경도',
		'Proceq.ExportColumnLocationLatitude': '위치 위도',
		'Proceq.ExportColumnLocationLongitude': '위치 경도',
		'Proceq.ExportColumnProbeType': '유형',
		'Proceq.ExportColumnProbeSN': '일련 번호',
		'Proceq.ExportColumnBTMSN': '일련 번호',
		'Proceq.ExportColumnProbeLastModifiedDate': '최종 검증 날짜',
		'Proceq.ExportColumnProbeHardwareRevision': '하드웨어 버전',
		'Proceq.ExportColumnProbeFirmwareRevision': '펌웨어 버전',
		'Proceq.ExportColumnBTMHardwareRevision': '하드웨어 버전',
		'Proceq.ExportColumnBTMFirmwareRevision': '펌웨어 버전',
		'Proceq.ExportColumnAppPlatform': '플랫폼',
		'Proceq.ExportColumnVersion': '버전',
		'Proceq.ExportColumnOperator': '작업자',
		'Proceq.ExportColumnPrimaryScale': '2차 스케일',
		'Proceq.ExportColumnSecondaryScale': '1차 스케일',
		'Proceq.ExportColumnMaterial': '재료',
		'Proceq.ExportColumnConversionStandard': '변환 표준',
		'Proceq.ExportColumnAngleCorrection': '각도 보정',
		'Proceq.ExportColumnValueAuto': '자동',
		'Proceq.ExportColumnTableAngleGrades': '각도(°)',
		'Proceq.ExportColumnTableLegendExcluded': '* 제외됨',
		'Proceq.ExportColumnMeasurementBarGraph': '측정 막대 그래프',
		'Proceq.ExportColumnTableView': '표 보기',
		'Proceq.ExportColumnStatistics': '통계',
		'Proceq.ExportColumnSettings': '설정',
		'Proceq.ExportColumnImpactDeviceInfo': '임팩트 디바이스 정보',
		'Proceq.ExportColumnImpactDeviceHammerInfo': '해머 정보',
		'Proceq.ExportColumnBluetoothModuleInfo': 'Bluetooth 모듈 정보',
		'Proceq.ExportColumnApplicationInfo': '응용프로그램 정보',
		'Proceq.ExportColumnAttachedPhotos': '첨부된 사진',
		'Proceq.ButtonActions': '작업',
		'Proceq.SerialNotInServiceFTP': '서비스 FTP에 S/N 없음',
		'Proceq.ActionResultsToCSV': '결과를 CSV로 내보내기',
		'Proceq.ErrorDisplayTitleSuccess': '성공',
		'Proceq.ErrorNoMultipleTargetsAllowed': '복수의 열에서 작업을 수행할 수 없습니다.',
		'Proceq.MessageActionSuccesfullyPerformed': '작업이 성공적으로 수행되었습니다.',
		'Proceq.EmailReminderContractUserSubject': '계약 만료 알림',
		'Proceq.EmailReminderContractUserBody':
			'사용자 귀하,<br/><br/>**?0**의 임대 기간이 **?1**에 만료됩니다.<br/><br/>귀하의 계약은 <a href="http://www.proceq.com/fileadmin/user_upload/Proceq_General_Terms___Conditions_of_Rental_10112016.pdf">당사의 ‘임대에 관한 일반 약관’</a>에 따라 12개월간 자동 연장됩니다. 귀하께서는 송장을 별도로 받으실 것입니다. Equotip Live 제품을 중단 없이 사용할 수 있도록 적절한 시간 내에 확인해 주십시오.<br/><br/>질문이 있는 경우에는 <a href="http://www.proceq.com/contact/proceq-network/">지역 대리점</a>에 문의해 주십시오.<br/><br/>Proceq 팀<br/><br/>**?2**',
		'Proceq.EmailReminderContractAdminSubject': '계약 만료 알림',
		'Proceq.ErrorNoTargetSelected': '작업에 대한 대상을 선택하지 않았습니다.',
		'Proceq.ErrorDisplayTitleInfo': '정보',
		'Proceq.TextSharingSeriesUrlLinkCreated':
			'다음 링크에서 시리즈가 공유되었습니다.<br/>**?0**<br/><a class="link-copy-clipboard" href="javascript:void(0);" onclick="**?1**">클립보드로 복사</a><br/>',
		'App.ShareMeasurementsSuccess': '다음 링크에서 시리즈가 공유되었습니다',
		'App.ShareMeasurementsCopyAction': '클립보드로 복사',
		'Proceq.TextPleaseWait': '잠시 기다려 주십시오.',
		'Proceq.TextPerformingServerRequest': '서버 요청 수행 중',
		'Proceq.TextPerformingAction': '작업 수행 중',
		'Proceq.ButtonCreateAccountChinaBack': '버리기',
		'Proceq.TitleCreateAccountChina': '중국 고객',
		'Proceq.BodyCreateAccountChina':
			'중국 고객의 경우 <a href="**?0**">**?1**</a>으로 이동해서 등록하거나 로그인하십시오.',
		'Proceq.LoginCompatibiltyInfo': '호환성 정보 <a href="**?0**">Proceq 앱</a>',
		'Proceq.TextRedirectingToRegion': '사용자의 지역으로 리디렉션...',
		'Proceq.EmailReminderContractExpiredTodayUserSubject': '계약 만료',
		'Proceq.EmailReminderContractExpiredTodayUserBody':
			'사용자 귀하,<br/><br/>**?0**에 대한 임대 기간이 오늘 만료되었습니다.<br/><br/>귀하께서 Proceq Live를 더 이상 사용하실 수 없는 것을 아쉽게 생각합니다. 언제든지 다시 돌아오시길 기대합니다.<br/><br/>계약을 갱신하시려면 <a href="http://www.proceq.com/contact/proceq-network/">지역 대리점</a>에 문의해 주십시오.<br/><br/>Proceq 팀<br/><br/>**?1**',
		'Proceq.EmailRentalPeriodChangedSubject': '변경됨 임대 기간',
		'Proceq.EmailRentalPeriodChangedBody':
			'사용자 귀하,<br/><br/>**?0**에 대한 임대 계약의 만료 날짜가 변경되었습니다. 귀하의 임대 계약은 **?1**에 만료됩니다.<br/><br/>질문이 있는 경우에는 <a href="http://www.proceq.com/contact/proceq-network/">지역 대리점</a>에 문의해 주십시오.<br/><br/>Proceq 팀<br/><br/>**?2**',
		'Proceq.FormExpiresIn': '만료 기한(일)',
		'Proceq.SelectTimeWeek': '주',
		'Proceq.SelectTimeDay': '일',
		'Proceq.SelectTimeMonth': '월',
		'Proceq.GraphNoDataAvailable': '사용 가능한 데이터 없음',
		'Proceq.GraphMaterial': '재료 그룹',
		'Proceq.GraphSecondaryScale': '변환 스케일',
		'Proceq.GraphStandard': '변환 표준',
		'Proceq.GraphMeasurementCount': '임팩트 수',
		'Proceq.GraphMeasurementSeriesCount': '측정 시리즈 카운트',
		'Proceq.StatisticsTitle': '통계',
		'Proceq.StatisticsCreatedSeries': '작성된 측정 시리즈',
		'Proceq.StatisticsModifiedSeries': '수정된 측정 시리즈',
		'Proceq.StatisticsPhotos': '사진',
		'Proceq.StatisticsAudioFiles': '오디오 파일',
		'Proceq.StatisticsUsedStorage': '사용된 저장 공간',
		'Proceq.StatisticsFolder': '폴더',
		'Proceq.StatisticsModified': '수정됨',
		'Proceq.StatisticsCreated': '작성됨',
		'Proceq.StatisticsAll': '모두',
		'Proceq.StatisticsCountry': '국가',
		'Proceq.StatisticsCompanySize': '회사 규모',
		'Proceq.StatisticsIndustry': '업계',
		'Proceq.DateRangeFrom': '시작',
		'Proceq.DateRangeTo': '끝',
		'Proceq.GraphHistogram': '경도 히스토그램',
		'Proceq.GraphSeriesHistogram': '경도 히스토그램 시리즈',
		'Proceq.StatisticsImpactDevice': '임팩트 장치',
		'Proceq.StatisticsImpactDeviceHammer': '해머',
		'Proceq.GraphExcluded': '제외됨',
		'Proceq.GraphIncluded': '포함됨',
		'Proceq.GraphWithinTolerance': '허용치 이내',
		'Proceq.GraphOutOfTolerance': '허용치 벗어남',
		'Proceq.StatisticsCreatedImpacts': '작성된 임팩트(포함/제외)',
		'Proceq.StatisticsModifiedImpacts': '수정된 임팩트(포함/제외)',
		'Proceq.GraphNoConversion': '변환 없음',
		'Proceq.StatisticsTotalImpacts': '총 충격',
		'Proceq.StatisticsTotalSeries': '총 시리즈',
		'Proceq.StatisticsTotalExcluded': '총 제외 항목',
		'Proceq.StatisticsValuesOutOfLimits': '한계 초과 값',
		'Proceq.StatisticsPeriod': '기간',
		'Proceq.LabelFrom': '시작',
		'Proceq.LabelTo': '끝',
		'Proceq.LabelImpacts': '임팩트',
		'Proceq.AbbrevCalendarWeek': 'CW',
		'Proceq.eRebNStd_Mean': '평균',
		'Proceq.ExportFieldTargetScale': '대상 스케일',
		'Proceq.ExportFieldConversionCurve': '변환 곡선',
		'Proceq.eCC_None': '없음',
		'Proceq.eCC_Portland_Cement_J_N': '포틀랜드 시멘트 J',
		'Proceq.eCC_Early_Strength_J_N': '조기 강도 J',
		'Proceq.eCC_Blast_Furnace_J_N': '고로 J',
		'Proceq.eCC_Average_Curve_J_N': '평균 곡선 J',
		'Proceq.eSC_R1_dry': 'JSCE-G504 R1 = 0 (건조)',
		'Proceq.eSC_R1_black_mark': 'JSCE-G504 R1 = +3 (블랙 마크)',
		'Proceq.eSC_R1_wet_surface': 'JSCE-G504 R1 = +5 (젖은 표면)',
		'Proceq.eCSC_None': '없음',
		'Proceq.eCSC_Upper': '상한값',
		'Proceq.eCSC_Under': '아래',
		'Proceq.eSCC_US_eCSC_None': '통합 강도',
		'Proceq.eSCC_US_eCSC_Upper': '통합 강도 상단 주조 표면',
		'Proceq.eSCC_US_eCSC_Under': '주조 표면 아래의 통합 강도',
		'Proceq.eSCC_PC_eCSC_None': '펌프압송 콘크리트',
		'Proceq.eSCC_PC_eCSC_Upper': '주조 표면 하단의 펌프압송 콘크리트',
		'Proceq.eSCC_PC_eCSC_Under': '주조 표면 상단의 펌프압송 콘크리트',
		'Proceq.eSCC_US': '통합 강도',
		'Proceq.eSCC_PC': '펌프압송 콘크리트',
		'Proceq.eNoOutlier': '아웃라이어 없음',
		'Proceq.eOutlierLow': '아웃라이어 하한값',
		'Proceq.eOutlierHigh': '아웃라이어 상한값',
		'Proceq.eOutlierRedundant': '아웃라이어 중복',
		'Proceq.LogbookPropertyConversionCurveChangedFromTo': '변환 곡선이 **?0**에서 **?1**(으)로 변경되었습니다.',
		'Proceq.LogbookPropertyConversionCurveChangedTo': '변환 곡선이 **?0**(으)로 변경되었습니다.',
		'Proceq.LogbookPropertyTargetScaleChangedFromTo': '대상 스케일이 **?0**에서 **?1**(으)로 변경되었습니다.',
		'Proceq.LogbookPropertySurfaceCorrectionChangedFromTo': '표면 보정이 **?0**에서 **?1**(으)로 변경되었습니다.',
		'Proceq.LogbookPropertySurfaceCorrectionChangedTo': '표면 보정이 **?0**(으)로 변경되었습니다.',
		'Proceq.LogbookPropertyCarbonationDepthChangedFromTo':
			'탄산화 깊이가 **?0** mm에서 **?1** mm로 변경되었습니다.',
		'Proceq.LogbookPropertySurfaceConditionChangedFromTo': '표면 상태가 **?0**에서 **?1**(으)로 변경되었습니다.',
		'Proceq.LogbookPropertySurfaceConditionChangedTo': '표면 상태가 **?0**(으)로 변경되었습니다.',
		'Proceq.LogbookPropertyConcreteAgeChangedFromTo': '콘크리트 재령이 **?0** d에서 **?1** d(으)로 변경되었습니다.',
		'Proceq.LogbookPropertyConcreteAgeChangedTo': '콘크리트 재령이 **?0** d(으)로 변경되었습니다.',
		'Proceq.ExportColumnCSVOutlierType': '아웃라이어',
		'Proceq.ExportColumnCarbonationDepth': '탄산화 깊이',
		'Proceq.ExportColumnConcreteAge': '콘크리트 재령',
		'Proceq.ExportFieldCarbonationDepth': '탄산화 깊이',
		'Proceq.ExportFieldConcreteAge': '콘크리트 재령',
		'Proceq.ValueConcreteAgeDays': '**?0** d',
		'Proceq.ValueCarbonationDepthMms': '**?0** mm',
		'Proceq.ExportColumnConversionCurve': '변환 곡선',
		'Proceq.ExportColumnTableLegendOutlier': '* 아웃라이어',
		'Proceq.ExportColumnTargetScale': '대상 스케일',
		'Proceq.ExportColumnMeasurementAvgCompressiveStrength': '측정 강도 압축 강도',
		'Proceq.ExportColumnEn13791Screening': 'EN 13791 Screening',
		'Proceq.ExportColumnLowestRebound': '최저 반발값',
		'Proceq.ExportColumnMedianRebound': '중간 반발값',
		'Proceq.ExportColumnCompressiveStrengthClass': 'EN 206 압축 강도 등급',
		'Proceq.EmailIntroDearContractOwner': '계약 당사자 귀하',
		'Proceq.ColumnTableSeriesRegionExport': '시리즈',
		'Proceq.ExportColumSeriesRegionExport': '시리즈',
		'Proceq.ExportTitleTestRegionExport': '테스트 영역 내보내기',
		'Proceq.ErrorSchmidtExportInvalidStandard': '모든 시리즈는 동일한 표준 세트를 가져야 합니다.',
		'Proceq.ErrorSchmidtExportTooFewSeries': 'EN 13791 Screening에는 최소 9개의 시리즈가 필요합니다.',
		'Proceq.ErrorSchmidtExportTooManySeries': '단일 내보내기에 대해 최대 99개의 시리즈를 선택할 수 있습니다.',
		'Proceq.ErrorSchmidtExportDifferentConfiguration': '시리즈에서 다른 구성이 발견되었습니다.',
		'Proceq.ErrorSchmidtExportWrongStandard': '선택한 내보내기에 대해 잘못된 표준을 발견했습니다.',
		'Proceq.ErrorSchmidtExportInvalidSeries': '선택한 표준에 따르면 최소한 1개의 시리즈가 유효하지 않습니다.',
		'Proceq.ErrorSchmidtExportSoftwareError': '내보내기를 생성하는 중 오류가 발생했습니다.',
		'Proceq.ErrorSchmidtExportOutOfRange': '계산된 강도 등급이 범위를 벗어났습니다.',
		'Proceq.ButtonExportTestRegionEU': 'EN 13791 Screening 테스트를 PDF로 내보내기',
		'Proceq.ButtonExportTestRegionUniformity': '균질성을 PDF로 내보내기',
		'Proceq.ButtonExportTestRegionChina': 'JGJ T23 테스트 영역을 PDF로 내보내기',
		'Proceq.ErrorNotYetImplemented': '아직 구현되지 않음',
		'Proceq.ErrorSchmidtExportWrongParam':
			'시리즈에 대해 잘못된 매개변수를 발견했습니다. 예를 들어, 선택한 내보내기에 대해 잘못된 단위입니다.',
		'Proceq.ErrorSchmidtExportUnitNotMpa': 'JGJ 영역의 경우 단위를 MPa로 설정해야 합니다.',
		'Proceq.ErrorSchmidtExportWrongFormFactor': 'JGJ 영역의 경우 폼 팩터가 2:1 실린더가 되어서는 안됩니다.',
		'Proceq.NavSelectView': '보기 선택',
		'Proceq.TextConfirmationAreYouSure': '확실합니까?',
		'Proceq.ErrorContractLinkedDifferentImpactDevice': '현재 임팩트 장치 일련 번호로 계약을 사용할 수 없습니다.',
		'Proceq.TitleShareByUrl': 'URL로 공유',
		'Proceq.ExportTitleVerification': '검증 보고서:',
		'Proceq.ExportVerificationStatusFailed': '실패',
		'Proceq.ExportVerificationStatusPassed': '통과',
		'Proceq.ExportColumnTestVerificationResult': '검증 결과',
		'Proceq.ExportColumnTestBlockTolerance': '테스트 블록 불확실성(Ucrm) k=2',
		'Proceq.ExportColumnTestBlockNominalHardness': '테스트 블록 정격 경도',
		'Proceq.ExportColumnTestBlockSN': '테스트 블록 일련 번호',
		'Proceq.ExportColumnTestBlockName': '테스트 블록 이름',
		'Proceq.ExportColumnVerificationStd': '검증 표준',
		'Proceq.ExportColumnVerificationCriteria': '검증 기준',
		'Proceq.VerificationCriteriaAverage5Span5Perc': '평균 +/- 5%, 범위 +/- 5%',
		'Proceq.VerificationCriteriaReadings6HL': '판독값 +/- 6 HL',
		'Proceq.VerificationCriteriaReadings3Perc': '판독값 +/- 3%',
		'Proceq.VerificationCriteriaAverage5Perc': '평균 +/- 5%',
		'Proceq.VerificationCriteriaAverage2R': '평균 +/- 2 R',
		'Proceq.VerificationCriteriaReadings3R': '판독값 +/- 3 R',
		'Proceq.StandardEnumCustom': '사용자 정의',
		'Proceq.MaterialEnumCustom': '사용자 정의',
		'Proceq.ExportColumnCalibrationAnvilName': '보정 모루 이름',
		'Proceq.ExportColumnCalibrationAnvilSN': '보정 모루 일련 번호',
		'Proceq.ExportColumnCalibrationAnvilReboundValue': '검증 모루 반발값',
		'Proceq.ExportColumnCalibrationAnvilTolerance': '보정 모루 허용치',
		'Proceq.ExportColumnTestLoadInfo': '테스트 부하 정보',
		'Proceq.NavVerificationData': '검증 데이터',
		'Proceq.NavCustomData': '사용자 정의 데이터',
		'Proceq.TableColumnVerificationResult': '결과',
		'Proceq.TitleVerifications': '검증',
		'Proceq.LogBookTestBlock': '테스트 블록',
		'Proceq.LogBookCalibrationAnvil': '보정 모루',
		'Proceq.LogbookPropertyTestBlockChangedFromTo': '테스트 블록이 "**?0**"에서 "**?1**"(으)로 변경되었습니다.',
		'Proceq.LogbookPropertyTestBlockChangedTo': '테스트 블록이 "**?0**"(으)로 변경되었습니다.',
		'Proceq.LogbookPropertyCalibrationAnvilChangedFromTo': '보정 모루가 **?0**에서 **?1**(으)로 변경되었습니다.',
		'Proceq.LogbookPropertyCalibrationAnvilChangedTo': '보정 모루가 **?0**(으)로 변경되었습니다.',
		'Proceq.LogbookPropertyCreatedVerification': '검증 "**?0**"이(가) 작성되었습니다.',
		'Proceq.LogbookPropertyRenamedVerificationFromTo': '검증 이름이 "**?0**"에서 "**?1**"(으)로 변경되었습니다.',
		'Proceq.LogbookResultVerificationFailed': '검증 실패',
		'Proceq.LogbookResultVerificationPassed': '검증 통과',
		'Proceq.LogbookPropertyTestLoadChangedFromTo': '테스트 부하가 **?0**에서 **?1**(으)로 변경되었습니다.',
		'Proceq.LogbookPropertyTestLoadChangedTo': '테스트 부하가 **?0**(으)로 변경되었습니다.',
		'Proceq.LogBookTestLoad': '테스트 부하',
		'Proceq.FieldWebOriginalCurve': '원본',
		'Proceq.FieldWebCustomCurve': '사용자 정의',
		'Proceq.ExportFieldValuePair': '값 쌍',
		'Proceq.TableBaseMaterial': '기본 재료',
		'Proceq.TableDescription': '설명',
		'Proceq.NavCustomMaterial': '사용자 정의 재료',
		'Proceq.FolderCustomMaterials': '사용자 정의 재료',
		'Proceq.SchmidtCurveTypePolynomial': 'fck = a + b*R + c*R² (다항 함수)',
		'Proceq.SchmidtCurveTypeExponencial': 'fck = a.e^(b*R) (지수 함수)',
		'Proceq.SchmidtCurveTypePolynomialShort': '다항 함수',
		'Proceq.SchmidtCurveTypeExponencialShort': '지수 함수',
		'Proceq.ExportFieldCurveType': '곡선 유형',
		'Proceq.TableActionEdit': '편집',
		'Proceq.TableActionAdd': '추가',
		'Proceq.WebAccountActivatedTitle': '확인 성공',
		'Proceq.PasswordChangedTitle': '비밀번호 변경됨',
		'Proceq.ResetPasswordFormHeading': '비밀번호를 재설정',
		'Proceq.PasswordChangedBody':
			'비밀번호가 성공적으로 재설정되었습니다. 새 비밀번호를 사용하여 계정에 로그인할 수 있습니다.',
		'Proceq.EnterYourNewPassword': '새 비밀번호를 입력하세요',
		'Proceq.WebAccountActivatedBodyLine1':
			'축하합니다. 이메일 주소를 성공적으로 확인했습니다. 이제 Screening Eagle과 함께 검사 여정을 시작할 수 있습니다.',
		'Proceq.WebAccountActivatedProceqTeam': 'Proceq 팀',
		'Proceq.NavDashboard': '대시보드',
		'Proceq.Confirm': '확인',
		'App.SchmidtFormFactorRAngle': 'R 각도 교정됨',
		'App.SchmidtFormFactorQAngle': 'Q 값',
		'App.ShareMeasurementsCopySuccess': '복사했습니다!',
		'App.ShareMeasurementsCopyFailed': '사용자의 브라우저가 복사를 지원하지 않습니다. 수동으로 복사하십시오.',
		'App.SystemFolders': '시스템 폴더',
		'Proceq.DataSeriesDemo': '데모 ',
		'Proceq.DataSeriesImported': '가져옴',
		'Antenna Spacing [{unit}]': '안테나 간격 [{unit}]',
		'App Version': '앱 버전',
		'Base Unit S/N': '베이스 유닛 S / N',
		'Calculated Depth': '계산 된 깊이',
		Comment: '논평',
		'Contract ID': '계약 ID',
		'Distance X [{unit}]': '거리 X [{unit}]',
		'Distance Y [{unit}]': '거리 Y [{unit}]',
		'Export HTML Format Version': 'HTML 형식 버전 내보내기',
		'File ID': '파일 ID',
		'File Name': '파일 이름',
		'Firmware Version': '펌웨어 버전',
		'Grid Size X [{unit}]': '격자 선 크기 X [{unit}]',
		'Grid Size Y [{unit}]': '격자 선 크기 Y [{unit}]',
		'Grid Spacing X [{unit}]': '격자 간격 X [{unit}]',
		'Grid Spacing Y [{unit}]': '격자 간격 Y [{unit}]',
		'Hardware Version': '하드웨어 버전',
		Line: '선',
		'MAC Address': 'MAC 주소',
		'Marker Number': '마커 번호',
		'Measurement Presets': '측정 사전 설정',
		Mode: '방법',
		Name: '이름',
		Objects: '사물',
		'Probe S/N': '프로브 S / N',
		Product: '생성물',
		'Repetition Rate [{unit}]': '반복률 [{unit}]',
		Resolution: '해결',
		'Sampling Rate [{unit}]': '샘플링 속도 [{unit}]',
		'Scan Distance [{unit}]': '스캔 거리 [{unit}]',
		'Scan Length [{unit}]': '스캔 길이 [{unit}]',
		'Set Depth': '깊이 설정',
		'Set Size': '크기 설정',
		Tag: '꼬리표',
		'Tag Type': '태그 유형',
		'Time Window [{unit}]': '시간 창 [{unit}]',
		Units: '단위',
		'User / Probe Info': '사용자 / 프로브 정보',
		Markers: '마커',
		'Created {fileName} in folder {folderName}': '{folderName} 폴더에 {fileName} 생성',
		'Mode: {value}': '모드 : {value}',
		'Auth.LoggingIn': '로그인 중...',
		'Auth.LoggingOut': '로그아웃 중...',
	},
};
