// @flow
import React from 'react';
import { FormattedMessage } from 'react-intl';

export const AddNewImpact = ({ log }: GPRLogbookEntryProps) => {
	let impactStrengthDesc;
	switch (log.content.impactStrength) {
		case 0:
			impactStrengthDesc = 'optimal';
			break;
		case -1:
			impactStrengthDesc = 'weak';
			break;
		case 1:
			impactStrengthDesc = 'strong';
			break;
		default:
			impactStrengthDesc = 'unknown';
			break;
	}

	return (
		<div>
			<b>
				<FormattedMessage id="App.LogbookPropertyAddNewImpact" />
			</b>
			<br />
			<FormattedMessage id="App.Logbook.PI8000.PileName" />
			{`: ${log.content.pileName}`}
			<br />
			<FormattedMessage id="App.Logbook.PI8000.PositionName" />
			{`: ${log.content.positionName}`}
			<br />
			<FormattedMessage id="App.Logbook.PI8000.ImpactID" />
			{`: ${log.content.impactID}`}
			<br />
			<FormattedMessage id="App.Logbook.PI8000.ImpactStrength" />
			{`: ${impactStrengthDesc}`}
		</div>
	);
};

export default AddNewImpact;
