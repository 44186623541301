module.exports = {
	shortlang: 'pt',
	lang: 'pt',
	messages: {
		'Proceq.ErrorWrongUsername': 'Disponibilizado email inválido',
		'Proceq.ErrorCreationNotAllowed': 'Desabilitada a criação de novas contas',
		'Proceq.ErrorWrongPassword': 'Disponibilizada senha inválida',
		'Proceq.ErrorWrongFirstName': 'Disponibilizada nome inválido',
		'Proceq.ErrorWrongLastName': 'Disponibilizado sobrenome inválido',
		'Proceq.ErrorUsernameAlreadyExists': 'Já existe uma conta com o mesmo e-mail',
		'Proceq.ErrorDB': 'Erro ao executar uma operação banco de dados',
		'Proceq.InvalidUsername': 'Conta do usuário não pode ser econtrada',
		'Proceq.AlreadyMultipleForgot': 'Já solicitados vários resets de senhas',
		'Proceq.InvalidNewPassword': 'Disponibilizada nova senha inválida',
		'Proceq.InvalidResetCode': 'Código reset de senha inválido ou já expirado',
		'Proceq.InvalidOldPassword': 'Disponibilizada senha antiga inválida',
		'Proceq.InvalidAccessRights': 'Sessão expirou ou usuário não autorizado a efetuar essa ação',
		'Proceq.ErrorNoAccountType': 'Não disponibilizado tipo de conta',
		'Proceq.ErrorNoUsername': 'Não disponibilizado email',
		'Proceq.InvalidCountry': 'Disponibilizado país inválido',
		'Proceq.ErrorCreatingUser': 'Erro ao criar usuário novo',
		'Proceq.InvalidID': 'Disponibilizado ID inválido',
		'Proceq.InvalidTransactionID': 'ID transação inválido',
		'Proceq.InvalidFileContent': 'Erro na leitura conteúdo arquivo',
		'Proceq.InvalidTypeEntry': 'Tipo de entrada inválido',
		'Proceq.InvalidContractID': 'Contrato inválido ou expirado',
		'Proceq.InvalidExistingID': 'ID não pode ser criado pois já existe',
		'Proceq.InvalidDataSeriesType': 'Tipo de série inválido',
		'Proceq.InvalidMetaDataContent': 'Conteúdo metadas inválido',
		'Proceq.ErrorFileIsLocked': 'Um ou mais arquivos bloqueados. Tente novamente mais tarde',
		'Proceq.MissingAttachmentFiles': 'Arquivos do anexo não puderam ser encontrados no servidor',
		'Proceq.InvalidFlagType': 'Tipo marcador inválido ou ausente',
		'Proceq.MissingDateTypeFields': 'Ausência campos necessários para séries',
		'Proceq.InvalidFolderID': 'ID de pasta inválido',
		'Proceq.InvalidDataSeriesName': 'Nome série inválido',
		'Proceq.ErrorDateFormat': 'Disponibilizado formato data inválido',
		'Proceq.ErrorDataFormat': 'Dados da série não podem ser analisados',
		'Proceq.InvalidDownloadID': 'Token download inválido, ou já expirou',
		'Proceq.InvalidVersionNr': 'Disponibilizado número inválido versão',
		'Proceq.ErrorDataMissing': 'Dados não podem ser recuperados do sistema armazenamento',
		'Proceq.ErrorSavingFile': 'Servidor não pode salvar o arquivo',
		'Proceq.ErrorInsertDB': 'Erro ao inserir no BD',
		'Proceq.InvalidFolderName': 'Disponibilizado nome inválido de pasta',
		'Proceq.InvalidActionID': 'Link URL inválido ou expirado',
		'Proceq.AlreadyConfirmed': 'Conta já foi verificada',
		'Proceq.InvalidActionType': 'A ação requisitada não pode ser processada',
		'Proceq.ErrorGeneratingChart': 'Erro ao gerar os gráficos',
		'Proceq.InvalidSerialID': 'ID serial inválido',
		'Proceq.InvalidHardwareID': 'ID hardware inválido',
		'Proceq.InvalidEntityID': 'ID objeto inválido ou foi deletado',
		'Proceq.InvalidEntityType': 'Tipo objeto inválido',
		'Proceq.InvalidEntityVerNr': 'Número versão objeto inválido',
		'Proceq.ErrorTransactionLocked': 'O objeto está bloqueado e não pode ser modificado',
		'Proceq.InvalidDateValue': 'Data não pode ser analisada',
		'Proceq.MissingFields': 'Um ou mais campos necessários estão faltando',
		'Proceq.ErrorColumnValue': 'Disponibilizado valor incorreto para um ou mais campos',
		'Proceq.MissingUidDBValue': 'ID entrada ausente',
		'Proceq.ErrorFileID': 'Disponibilizado ID arquivo inválido',
		'Proceq.ErrorInsertDownload': 'Erro criando download',
		'Proceq.InvalidAppVersion': 'A versão do aplicativo atual não é mais suportada. Atualze.',
		'Proceq.MissingUserInfo': 'Falta informação do usuário',
		'Proceq.InvalidAccessToken': 'Token sessão inválido ou expirado',
		'Proceq.ErrorGeneratingCSV': 'Erro ao gerar exportação CSV',
		'Proceq.ErrorGeneratingExport': 'Erro ao gerar exportação',
		'Proceq.MissingParams': 'Um ou mais dos parâmetros necessários ausentes',
		'Proceq.SerialNotFound': 'Não foi encontrado contrato para este dispositivo',
		'Proceq.StandardEnumJapan': 'Japão',
		'Proceq.StandardEnumDefault': 'Padrão',
		'Proceq.MaterialEnumIndependent': 'Independente de material',
		'Proceq.MaterialEnumSteelAndCastSteel': 'Aço e aço fundido',
		'Proceq.MaterialEnumWorkToolSteel': 'Aço ferramentas maquinadas',
		'Proceq.MaterialEnumStainlessSteel': 'Aço inoxidável',
		'Proceq.MaterialEnumGrayCastIron': 'Ferro fundido cinzento (GG)',
		'Proceq.MaterialEnumNodularCastIron': 'Ferro fundido nodular (GGG)',
		'Proceq.MaterialEnumCastAlluminiumAlloys': 'Ligas de alumínio fundido',
		'Proceq.MaterialEnumBrassCopperZincAlloys': 'Latão - Ligas de cobre/zinco',
		'Proceq.MaterialEnumBronze': 'Bronze',
		'Proceq.MaterialEnumWroughtCopperAlloys': 'Ligas de cobre forjado',
		'Proceq.MaterialEnumTitanium': 'Titânio',
		'Proceq.ScaleEnumCustom': 'Personalizado',
		'Proceq.ScaleEnumPenetrationDepth': 'Profundida da penetração',
		'Proceq.CompanySizeMicro': '1-9 funcionários',
		'Proceq.CompanySizeSmall': '10-49 funcionários',
		'Proceq.CompanySizeMedium': '50-249 funcionários',
		'Proceq.CompanySizeLarge': '>249 Employees',
		'Proceq.SalutationMr': 'Sr.',
		'Proceq.SalutationMs': 'Sra.',
		'Proceq.SalutationDr': 'Dr.',
		'Proceq.SalutationProf': 'Prof.',
		'Proceq.CategoryAerospace': 'Aeroespacial',
		'Proceq.CategoryAutomotive': 'Automotivo',
		'Proceq.CategoryChemicalProcessing': 'Processamento químico',
		'Proceq.CategoryConstructionBuilding': 'Construção/Edificação',
		'Proceq.CategoryConsultantTestLabsEngineer': 'Consultor/Laboratórios exames/Engenharia',
		'Proceq.CategoryEducationInstituteRD': 'Educação/Instituto/P&D',
		'Proceq.CategoryFilmProducerConverter': 'Produtor de filmes/Conversor',
		'Proceq.CategoryGeologyRocktesters': 'Geologia/Testador rochas',
		'Proceq.CategoryMachineryToolingEquipment': 'Maquinário/Ferramentas/Equipamento',
		'Proceq.CategoryMassTransportation': 'Transporte em massa',
		'Proceq.CategoryMetalWorks': 'Trabalhos em metal',
		'Proceq.CategoryOilAndGas': 'Petróleo e Gás',
		'Proceq.CategoryOthers': 'Outros',
		'Proceq.CategoryPaperProducerConverter': 'Produtor de papel/Conversor',
		'Proceq.CategorySalesChannel': 'Canal de vendas',
		'Proceq.ErrorWrongLoginCredentials': 'O nome do usuário ou a senha introduzidos estão incorretos',
		'Proceq.ErrorEmailNotVerified': 'O email ainda não foi verificado',
		'Proceq.ServerError': 'Erro servidor',
		'Proceq.TooManyActionEmailRequests': 'Solicitações demais. Aguarde os emails chegarem à sua caixa de entrada',
		'Proceq.ErrorServerUnderMaintenance': 'Servidor está em manutenção',
		'Proceq.TableDataSeriesName': 'Nome',
		'Proceq.TableCreatedOn': 'Criado em',
		'Proceq.TableModifiedOn': 'Modificado em',
		'Proceq.TableCreatedBy': 'Criado por',
		'Proceq.TableNumMean': 'Médio',
		'Proceq.TableNumTotal': 'Total',
		'Proceq.TableNumStandardDeviation': 'Desvio padrão',
		'Proceq.TableImpactDevice': 'Sonda',
		'Proceq.TableImpactDeviceHammer': 'Esclerômetro',
		'Proceq.TableMaterial': 'Material',
		'Proceq.TableScaleUnit': 'Unidade escala',
		'Proceq.TableActionRefresh': 'Atualizar',
		'Proceq.TableActionExpand': 'Expandir',
		'Proceq.TableActionCollapse': 'Colabar',
		'Proceq.TableActionExportAsPDF': 'Exportar como PDF',
		'Proceq.TableActionExportAsCSV': 'Exportar como CSV',
		'Proceq.TableActionExportAsJSON': 'Exportar como JSON',
		'Proceq.SearchOperationEquals': 'Iguais',
		'Proceq.SearchOperationContains': 'Contêm',
		'Proceq.SearchOperationDifferent': 'Diferente',
		'Proceq.SearchOperationGreaterOrEqual': 'Maior ou igual',
		'Proceq.SearchOperationGreater': 'Maior',
		'Proceq.SearchOperationLesserOrEqual': 'Menor ou igual',
		'Proceq.SearchOperationLesser': 'Menor',
		'Proceq.ExportFieldExportDate': 'Exportar data',
		'Proceq.ExportFieldLimits': 'Limites',
		'Proceq.ExportFieldHigh': 'Alto',
		'Proceq.ExportFieldLow': 'Baixo',
		'Proceq.ExportFieldOff': 'Desl',
		'Proceq.ExportFieldLowerLimit': 'Limite inferior',
		'Proceq.ExportFieldUpperLimit': 'Limire superior',
		'Proceq.ExportFieldReadings': 'Medições',
		'Proceq.ExportFieldStandardDeviation': 'Desvio padrão',
		'Proceq.ExportFieldRange': 'Faixa',
		'Proceq.ExportFieldRelativeSpan': 'Intervalo relativo',
		'Proceq.ExportFieldMaximum': 'Máximo',
		'Proceq.ExportFieldMinimum': 'Mínimo',
		'Proceq.ExportFieldAverage': 'Médio',
		'Proceq.ExportFieldUnit': 'Unid',
		'Proceq.ExportFieldName': 'Nome',
		'Proceq.ExportFieldMeasurementDate': 'Data medição',
		'Proceq.ExportFieldLocationLatitude': 'Latitude local',
		'Proceq.ExportFieldLocationLongitude': 'Longitude local',
		'Proceq.ExportFieldProbeType': 'Tipo sonda',
		'Proceq.ExportFieldProbeSerialNumber': 'N° de sério sonda',
		'Proceq.ExportFieldProbeBTSerialNumber': 'N° de série sonda Bluetooth',
		'Proceq.ExportFieldProbeLastVerificationDate': 'Data última verificação sonda',
		'Proceq.ExportFieldPrimaryScale': 'Escala primária',
		'Proceq.ExportFieldSecondaryScale': 'Escala secundária',
		'Proceq.ExportFieldMaterial': 'Material',
		'Proceq.ExportFieldStandard': 'Padrão',
		'Proceq.ExportFieldNoConversion': 'Sem conversão',
		'Proceq.ExportFieldMeasurements': 'Medições',
		'Proceq.ExportFieldMore': 'Mais',
		'Proceq.ExportFieldLess': 'Menos',
		'Proceq.ExportFieldStatistics': 'Estatísticas',
		'Proceq.ExportFieldSettings': 'Configurações',
		'Proceq.DataSeriesLogbook': 'Livro registros',
		'Proceq.DataSeriesAllMeasurements': 'Todas as medições',
		'Proceq.DataSeriesFlagged': 'Marcado',
		'Proceq.DataSeriesTrash': 'Lixeira',
		'Proceq.BrowserNoSupportAudio': 'Seu navegador não suporta arquivos de áudio.',
		'Proceq.EmailPasswordRecoverySubject': 'Solicitação de alteração de senha para **?0**',
		'Proceq.EmailPasswordRecoveryBody':
			'Prezado/a **?0** **?1**<br/><br/>Você solicitou senha nova. Para resetar a sua senha clique no link abaixo.<br/><br/><a href="**?2**">Resetar senha</a><br/><br/>Se você não solicitou a aletração da sua senha, ignore esta mensagem. Ela expira em 12 horas.<br/><br/>Sua Equipe Proceq<br/><br/>**?3**',
		'Proceq.EmailRegistrationSubject': 'Finalização registro para **?0**',
		'Proceq.EmailRegistrationBody':
			'Prezado/a **?0** **?1**<br/><br/>Obrigado por se registrar em **?5**. Complete o seu registro clicando no link abaixo:<br/><br/><a href="**?2**">**?2**</a><br/><br/>Este email foi criado automaticamente quando você preencheu o registro. Se não fez este registro, ignore essa mensagem.<br/><br/> Ao completar o registro você concorda com uma <a href="**?3**">política de privacidade</a>.<br/><br/>Sua Equipe Proceq<br/><br/>**?4**',
		'Proceq.LogbookActionNewMeasurement': 'Nova medição',
		'Proceq.LogbookPropertyCreatedSeriesInFolder': 'Série criada "**?0**" na pasta "**?1**"',
		'Proceq.LogbookPropertyRenamedSeriesFromTo': 'Série foi renomeada de "**?0**" para "**?1**"',
		'Proceq.LogbookPropertyMovedSeriesFromToTrash': 'Série foi kovida da pasta "**?0**" para a lixeira',
		'Proceq.LogbookPropertyMovedSeriesFromTrashTo': 'Série foi movida da lixeira para a pasta "**?0**"',
		'Proceq.LogbookPropertyMovedSeriesFromTo': 'Série foi movida da pasta "**?0**" para a pasta "**?1**"',
		'Proceq.LogbookPropertySecondaryScaleChangedFromTo': 'Escala secundária mudou de **?0** para **?1**',
		'Proceq.LogbookPropertyMaterialChangedFromTo': 'Material mudado de **?0** para **?1**',
		'Proceq.LogbookPropertyMaxLimitChangedFromTo': 'Limite superior mudou de **?0** para **?1**',
		'Proceq.LogbookPropertyMinLimitChangedFromTo': 'Limite inferior mudou de **?0** para **?1**',
		'Proceq.LogbookPropertyStandardChangedFromTo': 'Padrão alterado de  **?0** para **?1**',
		'Proceq.LogbookPropertyTextCommentAdded': 'Adicionado comentário texto',
		'Proceq.LogbookPropertyTextCommentDeletedOn': 'Deletado em **?0**',
		'Proceq.LogbookPropertyMeasurementAdded': '**?0** adicionado',
		'Proceq.LogbookPropertyMeasurementExcluded': '**?0** excluído',
		'Proceq.LogbookPropertyMeasurementIncluded': '**?0** incluído',
		'Proceq.LogbookPropertyMeasurementDeleted': '**?0** deletado',
		'Proceq.LogbookPropertyAttachmentDeletedOn': 'Deletado em **?0**',
		'Proceq.LogbookPropertyPhotoAdded': 'Foto adicionada',
		'Proceq.LogbookPropertyAudioAdded': 'Adicionado comentário áudio',
		'Proceq.LogbookPropertySeriesFlagged': 'Série marcada',
		'Proceq.LogbookPropertySeriesUnflagged': 'Série desmarcada',
		'Proceq.LogbookPropertySerialNumberShort': 'N/S',
		'Proceq.LogbookPropertyBluetoothModuleShort': 'BTM S/N',
		'Proceq.LogbookPropertyVerified': 'Verificado',
		'Proceq.LogbookPropertyContractValidUntil': 'Contrato válido até',
		'Proceq.LogbookPropertyOperator': 'Operador',
		'Proceq.LogbookTitle': 'Livro registros',
		'Proceq.LogbookDownload': 'Download',
		'Proceq.LogbookLoadingData': 'Carragando dados...',
		'Proceq.LogbookClose': 'Fechar',
		'Proceq.FormCompany': 'Empresa',
		'Proceq.FormScreeningEagleID': 'Screening Eagle ID (Email)',
		'Proceq.FormEmailGroupID': 'Email',
		'Proceq.FormCustomerEmail': 'Email cliente',
		'Proceq.FormProbeSN': 'N/S sonda',
		'Proceq.FormBluetoothSN': 'N/S',
		'Proceq.FormBluetoothHWID': 'ID HW',
		'Proceq.FormExpirationDate': 'Data de validade',
		'Proceq.TitleRegisterProbeSNs': 'Registrar contrato de alufuel',
		'Proceq.ButtonSubmit': 'Submeter',
		'Proceq.ButtonReset': 'Resetar',
		'Proceq.ButtonResetPassword': 'Resetar senha',
		'Proceq.MessageSuccesfullyInserted': 'Inserido com sucesso',
		'Proceq.MessageSuccesfullyEmailConfirmed': 'Seu email foi confirmado.',
		'Proceq.MessagePasswordSuccesfullyChanged': 'Senha alterada com sucesso',
		'Proceq.MessageSuccesfullyUpdated': 'Atualizado com sucesso',
		'Proceq.MessageAccountCreated':
			'Um email de verificação do cadastro foi enviado a você. Clique no link no email para completar o processo de registro',
		'Proceq.MessageEmailForgotPassword': 'Foi enviado um email a você com mais instruções',
		'Proceq.MessageEmailResendConfirm': 'Foi enviado um email a você com mais instruções',
		'Proceq.MessageLoggingIn': 'Logando...',
		'Proceq.LoginNeedAccount': 'Precisa de uma conta?',
		'Proceq.LoginCreateAccount': 'Criar conta',
		'Proceq.TitleForgotPassword': 'Esqueci a senha',
		'Proceq.TitleResendConfirmation': 'Reenviar email confirmação',
		'Proceq.FormGroup': 'Grupo',
		'Proceq.FormFirstName': 'Primeiro nome',
		'Proceq.FormLastName': 'Último nome',
		'Proceq.FormEmail': 'Endereço de email',
		'Proceq.FormCity': 'Cidade',
		'Proceq.FormPassword': 'Senha',
		'Proceq.FormPasswordNew': 'Senha nova',
		'Proceq.FormPasswordOld': 'Senha antiga',
		'Proceq.FormConfirmPassword': 'Confirmar senha',
		'Proceq.FormConfirmPasswordNew': 'Confirmar senha nova',
		'Proceq.ButtonRegister': 'Registrar',
		'Proceq.ButtonSignIn': 'Logar',
		'Proceq.TitleSignIn': 'Logar',
		'Proceq.LoginAlreadyRegistered': 'Já se registrou?',
		'Proceq.TextOrSignInUsing': 'Ou faça login usando',
		'Proceq.NavSearchSerials': 'Buscar contatos',
		'Proceq.NavRegisterProbeSNs': 'Registrar contrato',
		'Proceq.NavCreateStaffAccounts': 'Criar contas funcionários',
		'Proceq.NavHistoryLog': 'Registro histórico',
		'Proceq.NavMeasurementsData': 'Dados medição',
		'Proceq.NavChangePassword': 'Alterar senha',
		'Proceq.NavLogout': 'Logout',
		'Proceq.NavUpdateProfile': 'Atualizar perfil',
		'Proceq.TitleUpdateProfile': 'Atualizar perfil',
		'Proceq.NavCreateCustomersAccounts': 'Criar conta de usuário',
		'Proceq.ButtonSearch': 'Busca',
		'Proceq.ButtonUpdate': 'Atualização',
		'Proceq.ButtonCreate': 'Criar',
		'Proceq.ButtonChange': 'Alterar',
		'Proceq.FormSearchBy': 'Buscar por',
		'Proceq.FormValue': 'Valor',
		'Proceq.FormAccountType': 'Tipo de conta',
		'Proceq.FormAccountTypeAdmin': 'Administrador',
		'Proceq.FormAccountTypeBU': 'Unidade negócio',
		'Proceq.FormCompanyName': 'Nome empresa',
		'Proceq.FormAddress': 'Endereço',
		'Proceq.FormZipCode': 'Código Postal',
		'Proceq.FormCountry': 'País',
		'Proceq.FormPhoneNumber': 'Telefone',
		'Proceq.FormCompanyWebsite': 'Site da empresa',
		'Proceq.FormJobTitle': 'Título serviço',
		'Proceq.FormIndustry': 'Indústria',
		'Proceq.FormSalutation': 'Saudação',
		'Proceq.FormCompanySize': 'Tamanho empresa',
		'Proceq.FormLanguage': 'Idioma',
		'Proceq.FormContactByProceq': 'Contato pelo Proceq',
		'Proceq.LanguageEnglish': 'Inglês',
		'Proceq.LanguageJapanese': 'Japonês',
		'Proceq.LanguageChinese': 'Chinês',
		'Proceq.LanguageGerman': 'Alemão',
		'Proceq.TextYes': 'Sim',
		'Proceq.TextNo': 'Não',
		'Proceq.FormNewsletter': 'Newsletter',
		'Proceq.TitleSearchSerials': 'Buscar contatos',
		'Proceq.TitleChangePassword': 'Alterar senha',
		'Proceq.TabLoginCustomers': 'Clientes',
		'Proceq.TabLoginSingleUser': 'Usuário individual',
		'Proceq.TabLoginGroup': 'Grupo',
		'Proceq.TextRemainingXDays': 'Ainda **?0** dia(s) remanescentes',
		'Proceq.TextInfoShort': 'Info',
		'Proceq.TextIAgreeTerms': 'Concordo com os <a>Termos e Condições</a>',
		'Proceq.ErrorDisplayTitleError': 'Erro',
		'Proceq.ErrorDisplayTitleWarning': 'Advertência',
		'Proceq.LoginBackLogin': 'Retornar ao login',
		'Proceq.LoginForgotPasswordQuestion': 'Esqueceu a senha?',
		'Proceq.LoginResendConfirmationEmail': 'Reenviar email de confirmação',
		'Proceq.LoginRegisterAccountTextPart': 'Se você não tem uma conta,',
		'Proceq.LoginRegisterAccountLinkPart': 'registre-se aqui',
		'Proceq.LoginProceqUseOnly': 'Isto é apenas para uso interno da Proceq',
		'Proceq.ProfileEnterInfo': 'Introduza as informações do seu perfil antes de continuar',
		'Proceq.TextInfoExclamation': 'Info!',
		'Proceq.FooterLegalPrivacy': 'Legal e Privacidade',
		'Proceq.JQGridTableRecordText': 'Veja {0} - {1} of {2}',
		'Proceq.JQGridTableEmptyRecords': "N'ao há registros para ver",
		'Proceq.JQGridTableLoadText': 'Carregando...',
		'Proceq.JQGridTablePaginationText': 'Página {0} of {1}',
		'Proceq.JQGridTableSavingText': 'Salvando...',
		'Proceq.JQGridTableFirstPage': 'Primeira página',
		'Proceq.JQGridTableLastPage': 'Última página',
		'Proceq.JQGridTableNextPage': 'Próxima página',
		'Proceq.JQGridTablePreviousPage': 'Página anterior',
		'Proceq.JQGridTableRecordsPerPage': 'Registros por página',
		'Proceq.JQGridTablePageText': 'Página:',
		'Proceq.JQGridTableNoMoreRecs': 'Não há mais registros...',
		'Proceq.ExportColumnCSVNo': 'N°',
		'Proceq.ExportColumnCSVSingleReadings': 'Medições individuais',
		'Proceq.ExportColumnCSVMeasurementValue': 'Valor medição',
		'Proceq.ExportColumnCSVAngleDegrees': 'Ângulo (°)',
		'Proceq.ExportColumnCSVExcluded': 'Excluído',
		'Proceq.ExportColumnCSVSeriesName': 'Nome da série',
		'Proceq.ExportColumnCSVImpactDevice': 'Dispositivo impacto',
		'Proceq.ExportColumnCSVImpactDeviceSN': 'N/S dispositivo impacto',
		'Proceq.ExportColumnCSVImpactDeviceVerification': 'Verificação dispositivo impacto',
		'Proceq.ExportColumnCSVImpactDeviceHammerVerification': 'Verificação martelo',
		'Proceq.ExportColumnCSVImpactDeviceHammer': 'Esclerômetro',
		'Proceq.ExportColumnCSVImpactDeviceHammerSN': 'N/S esclerômetro',
		'Proceq.ExportColumnCSVBluetoothModuleSN': 'N/S módulo Bluetooth',
		'Proceq.ExportColumnCSVConversionStandard': 'Padrão conversão',
		'Proceq.ExportColumnCSVScale': 'Escala',
		'Proceq.ExportColumnCSVUnit': 'Unid',
		'Proceq.ExportColumnCSVReadings': 'Medições',
		'Proceq.ExportColumnCSVStandardDeviation': 'Desvio padrão',
		'Proceq.ExportColumnCSVRelativeSpan': 'Intervalo rel.(%)',
		'Proceq.ExportColumnCSVDebugDataSeriesName': 'Nome da série',
		'Proceq.ExportColumnExportDate': 'Exportar data',
		'Proceq.ExportColumnUpperLimit': 'Limire superior',
		'Proceq.ExportColumnLowerLimit': 'Limite inferior',
		'Proceq.ExportColumnLimits': 'Limites',
		'Proceq.ExportColumnHigh': 'Alto',
		'Proceq.ExportColumnLow': 'Baixo',
		'Proceq.ExportColumnValueOff': 'Desl',
		'Proceq.ExportColumnValueNoConversion': 'Sem conversão',
		'Proceq.ExportColumnValueNoConversionShort': 'SemConv',
		'Proceq.ExportColumnReadings': 'Medições',
		'Proceq.ExportColumnStandardDeviation': 'Desvio padrão',
		'Proceq.ExportColumnRange': 'Faixa',
		'Proceq.ExportColumnRelativeSpan': 'Intervalo relativo',
		'Proceq.ExportColumnMaximum': 'Máximo',
		'Proceq.ExportColumnMinimum': 'Mínimo',
		'Proceq.ExportColumnAverage': 'Médio',
		'Proceq.ExportColumnUnit': 'Unid',
		'Proceq.ExportColumnName': 'Nome',
		'Proceq.ExportColumnDataSeriesID': 'ID série',
		'Proceq.ExportColumnFolderName': 'Nome pasta',
		'Proceq.ExportColumnCreatedDate': 'Criada data',
		'Proceq.ExportColumnCreatedDateOnly': 'Criada data',
		'Proceq.ExportColumnCreatedTime': 'Criado hora',
		'Proceq.ExportColumnLastModifiedDate': 'Última modificação data',
		'Proceq.ExportColumnLastModifiedDateOnly': 'Última modificação data',
		'Proceq.ExportColumnLastModifiedTime': 'Última modificação hora',
		'Proceq.ExportColumnMeasurementAvgHardness': 'Medição dureza média',
		'Proceq.ExportColumnLocationLatitude': 'Latitude local',
		'Proceq.ExportColumnLocationLongitude': 'Longitude local',
		'Proceq.ExportColumnProbeType': 'Tipo',
		'Proceq.ExportColumnProbeSN': 'N° de série',
		'Proceq.ExportColumnBTMSN': 'N° de série',
		'Proceq.ExportColumnProbeLastModifiedDate': 'Data última verificação',
		'Proceq.ExportColumnProbeHardwareRevision': 'Revisão hardware',
		'Proceq.ExportColumnProbeFirmwareRevision': 'Revisão firmware',
		'Proceq.ExportColumnBTMHardwareRevision': 'Revisão hardware',
		'Proceq.ExportColumnBTMFirmwareRevision': 'Revisão firmware',
		'Proceq.ExportColumnAppPlatform': 'Plataforma',
		'Proceq.ExportColumnVersion': 'Versão',
		'Proceq.ExportColumnOperator': 'Operador',
		'Proceq.ExportColumnPrimaryScale': 'Escala primária',
		'Proceq.ExportColumnSecondaryScale': 'Escala secundária',
		'Proceq.ExportColumnMaterial': 'Material',
		'Proceq.ExportColumnConversionStandard': 'Padrão conversão',
		'Proceq.ExportColumnAngleCorrection': 'Correção ângulo',
		'Proceq.ExportColumnValueAuto': 'Auto',
		'Proceq.ExportColumnTableAngleGrades': 'Ângulo (°)',
		'Proceq.ExportColumnTableLegendExcluded': '* Excluído',
		'Proceq.ExportColumnMeasurementBarGraph': 'Barra gráfico medição',
		'Proceq.ExportColumnTableView': 'Vista tabela',
		'Proceq.ExportColumnStatistics': 'Estatísticas',
		'Proceq.ExportColumnSettings': 'Configurações',
		'Proceq.ExportColumnImpactDeviceInfo': 'Info dispositivo impacto',
		'Proceq.ExportColumnImpactDeviceHammerInfo': 'Info esclerômetro',
		'Proceq.ExportColumnBluetoothModuleInfo': 'Info módulo Bluetooth',
		'Proceq.ExportColumnApplicationInfo': 'Info aplicativo',
		'Proceq.ExportColumnAttachedPhotos': 'Fotos anexas',
		'Proceq.ButtonActions': 'Ações',
		'Proceq.SerialNotInServiceFTP': 'N/S não em FTP serviço',
		'Proceq.ActionResultsToCSV': 'Exportar dados como CSV',
		'Proceq.ErrorDisplayTitleSuccess': 'Sucesso',
		'Proceq.ErrorNoMultipleTargetsAllowed': 'Ação não pode ser executada em várias linhas',
		'Proceq.MessageActionSuccesfullyPerformed': 'Ação realizada com sucesso',
		'Proceq.EmailReminderContractUserSubject': 'Lembrete expiração contrato',
		'Proceq.EmailReminderContractUserBody':
			'Prezado usuário<br/><br/>O seu período de locação de  **?0** expira em **?1**.<br/><br/>Seu contrato será automaticamente prolongado por 12 meses conforme <a href="http://www.proceq.com/fileadmin/user_upload/Proceq_General_Terms___Conditions_of_Rental_10112016.pdf">os nossos ‘Termos e Condições Gerais de Locação’</a>. Você receberá a fatura em separado. Assegure-se de quitá-la a tempo para garantir o uso sem interrupção do seu produto Equotip Live.<br/><br/>Contate o seu <a href="http://www.proceq.com/contact/proceq-network/">representante de vendas local</a> se tiver alguma dúvida.<br/><br/>Your Proceq Team<br/><br/>**?2**',
		'Proceq.EmailReminderContractAdminSubject': 'Lembrete expiração contrato',
		'Proceq.ErrorNoTargetSelected': 'Não há alvo selecionado para a ação',
		'Proceq.ErrorDisplayTitleInfo': 'Info',
		'Proceq.TextSharingSeriesUrlLinkCreated':
			'A série foi compartilhada pelo link:<br/>**?0**<br/><a class="link-copy-clipboard" href="javascript:void(0);" onclick="**?1**">Copiar para área transferência</a><br/>',
		'App.ShareMeasurementsSuccess': 'A série foi compartilhada pelo link',
		'App.ShareMeasurementsCopyAction': 'Copiar para área transferência',
		'Proceq.TextPleaseWait': 'Aguarde...',
		'Proceq.TextPerformingServerRequest': 'Executando solicitação do servidor',
		'Proceq.TextPerformingAction': 'Executando ação',
		'Proceq.ButtonCreateAccountChinaBack': 'Descartar',
		'Proceq.TitleCreateAccountChina': 'Cliente chinês',
		'Proceq.BodyCreateAccountChina':
			'Para clientes na China ir para <a href="**?0**">**?1**</a> para registro ou login',
		'Proceq.LoginCompatibiltyInfo': 'Informação de compatibilidade <a href="**?0**">Aplicativos Proceq</a>',
		'Proceq.TextRedirectingToRegion': 'Redirecionando para a sua região...',
		'Proceq.EmailReminderContractExpiredTodayUserSubject': 'Expiração do contrato',
		'Proceq.EmailReminderContractExpiredTodayUserBody':
			'Prezado usuário<br/><br/>Seu período de locação para **?0** expirou hoje.<br/><br/>Sentimos que não esteja mais utilizando o Proceq Live, mas sempre teremos prazer em recebê-lo de volta.<br/><br/>Para renovar o contrato contate o seu <a href="http://www.proceq.com/contact/proceq-network/">representante de vendas local</a>.<br/><br/>Sua Equipe Proceq<br/><br/>**?1**',
		'Proceq.EmailRentalPeriodChangedSubject': 'Período de locação alterado',
		'Proceq.EmailRentalPeriodChangedBody':
			'Prezado usuário<br/><br/>A data expiração do seu contrato de locação **?0** foi alterado. Seu contrato de locação expira em **?1**.<br/><br/>Contate o seu <a href="http://www.proceq.com/contact/proceq-network/">representante de vendas local</a> em caso de dúvidas.<br/><br/>Sua Equipe Proceq<br/><br/>**?2**',
		'Proceq.FormExpiresIn': 'Expira em (dias)',
		'Proceq.SelectTimeWeek': 'semana',
		'Proceq.SelectTimeDay': 'dia',
		'Proceq.SelectTimeMonth': 'mês',
		'Proceq.GraphNoDataAvailable': 'não há dados disponíveis',
		'Proceq.GraphMaterial': 'Grupo material',
		'Proceq.GraphSecondaryScale': 'Escala conversão',
		'Proceq.GraphStandard': 'Padrão conversão',
		'Proceq.GraphMeasurementCount': 'Número de impactos',
		'Proceq.GraphMeasurementSeriesCount': 'Contagem série medição',
		'Proceq.StatisticsTitle': 'Estatísticas',
		'Proceq.StatisticsCreatedSeries': 'criada série medição',
		'Proceq.StatisticsModifiedSeries': 'série medição modificada',
		'Proceq.StatisticsPhotos': 'fotos',
		'Proceq.StatisticsAudioFiles': 'arquivos áudio',
		'Proceq.StatisticsUsedStorage': 'armazenagem usada',
		'Proceq.StatisticsFolder': 'Pasta',
		'Proceq.StatisticsModified': 'Modificado',
		'Proceq.StatisticsCreated': 'Criado',
		'Proceq.StatisticsAll': 'Tudo',
		'Proceq.StatisticsCountry': 'País',
		'Proceq.StatisticsCompanySize': 'Tamanho empresa',
		'Proceq.StatisticsIndustry': 'Indústria',
		'Proceq.DateRangeFrom': 'De',
		'Proceq.DateRangeTo': 'Para',
		'Proceq.GraphHistogram': 'Histograma dureza',
		'Proceq.GraphSeriesHistogram': 'Série histograma dureza',
		'Proceq.StatisticsImpactDevice': 'Dispositivo impacto',
		'Proceq.StatisticsImpactDeviceHammer': 'Esclerômetro',
		'Proceq.GraphExcluded': 'Excluído',
		'Proceq.GraphIncluded': 'Incluído',
		'Proceq.GraphWithinTolerance': 'Dentro da tolerância',
		'Proceq.GraphOutOfTolerance': 'Fora da tolerância',
		'Proceq.StatisticsCreatedImpacts': 'Impactos criados (incluídos/excluidos)',
		'Proceq.StatisticsModifiedImpacts': 'Impactos modificados (incluídos/excluídos)',
		'Proceq.GraphNoConversion': 'Sem conversão',
		'Proceq.StatisticsTotalImpacts': 'Impactos totais',
		'Proceq.StatisticsTotalSeries': 'Séries totais',
		'Proceq.StatisticsTotalExcluded': 'Total excluído',
		'Proceq.StatisticsValuesOutOfLimits': 'Valores fora dos limites',
		'Proceq.StatisticsPeriod': 'Período',
		'Proceq.LabelFrom': 'De',
		'Proceq.LabelTo': 'Para',
		'Proceq.LabelImpacts': 'Impactos',
		'Proceq.AbbrevCalendarWeek': 'CW',
		'Proceq.eRebNStd_Mean': 'Médio',
		'Proceq.ExportFieldTargetScale': 'Escala alvo',
		'Proceq.ExportFieldConversionCurve': 'Curva de conversão',
		'Proceq.eCC_None': 'Nenhum',
		'Proceq.eCC_Portland_Cement_J_N': 'Cimento Portland J',
		'Proceq.eCC_Early_Strength_J_N': 'Resistência precoce J',
		'Proceq.eCC_Blast_Furnace_J_N': 'Alto forno J',
		'Proceq.eCC_Average_Curve_J_N': 'Curva média J',
		'Proceq.eSC_R1_dry': 'JSCE-G504 R1 = 0 (seco)',
		'Proceq.eSC_R1_black_mark': 'JSCE-G504 R1 = +3 (marca preta)',
		'Proceq.eSC_R1_wet_surface': 'JSCE-G504 R1 = +5 (superfície molhada)',
		'Proceq.eCSC_None': 'Nenhum',
		'Proceq.eCSC_Upper': 'Superior',
		'Proceq.eCSC_Under': 'Inferior',
		'Proceq.eSCC_US_eCSC_None': 'Resistência unificada',
		'Proceq.eSCC_US_eCSC_Upper': 'Resisência unificada superfície superior fundição',
		'Proceq.eSCC_US_eCSC_Under': 'Resistência unificada superfície inferior fundição',
		'Proceq.eSCC_PC_eCSC_None': 'Concreto bombeado',
		'Proceq.eSCC_PC_eCSC_Upper': 'Concreto bombeado superfície superior fundição',
		'Proceq.eSCC_PC_eCSC_Under': 'Concreto bombeado superfície inferior fundição',
		'Proceq.eSCC_US': 'Resistência unificada',
		'Proceq.eSCC_PC': 'Concreto bombeado',
		'Proceq.eNoOutlier': 'Sem discrepância',
		'Proceq.eOutlierLow': 'Discrepância baixa',
		'Proceq.eOutlierHigh': 'Discrepância alta',
		'Proceq.eOutlierRedundant': 'Discrepância redundante',
		'Proceq.LogbookPropertyConversionCurveChangedFromTo': 'Curva de conversão mudou de **?0** para **?1**',
		'Proceq.LogbookPropertyConversionCurveChangedTo': 'Curva de conversão mudou para **?0**',
		'Proceq.LogbookPropertyTargetScaleChangedFromTo': 'Escala alvo mudou de **?0** para **?1**',
		'Proceq.LogbookPropertySurfaceCorrectionChangedFromTo': 'Correção superfície mudou de **?0** para **?1**',
		'Proceq.LogbookPropertySurfaceCorrectionChangedTo': 'Correção superfície mudou para **?0**',
		'Proceq.LogbookPropertyCarbonationDepthChangedFromTo': 'Prof carbonatação mudou de **?0** mm para **?1** mm',
		'Proceq.LogbookPropertySurfaceConditionChangedFromTo': 'Condição superfície mudou de **?0** para **?1**',
		'Proceq.LogbookPropertySurfaceConditionChangedTo': 'Condição superfície mudou para **?0**',
		'Proceq.LogbookPropertyConcreteAgeChangedFromTo': 'Idade concreto mudou de **?0** d para **?1** d',
		'Proceq.LogbookPropertyConcreteAgeChangedTo': 'Idade concreto mudou para **?0** d',
		'Proceq.ExportColumnCSVOutlierType': 'Discrepância',
		'Proceq.ExportColumnCarbonationDepth': 'Prof carbonatação',
		'Proceq.ExportColumnConcreteAge': 'Idade concreto',
		'Proceq.ExportFieldCarbonationDepth': 'Prof carbonatação',
		'Proceq.ExportFieldConcreteAge': 'Idade concreto',
		'Proceq.ValueConcreteAgeDays': '**?0** d',
		'Proceq.ValueCarbonationDepthMms': '**?0** mm',
		'Proceq.ExportColumnConversionCurve': 'Curva de conversão',
		'Proceq.ExportColumnTableLegendOutlier': '* Discrepância',
		'Proceq.ExportColumnTargetScale': 'Escala alvo',
		'Proceq.ExportColumnMeasurementAvgCompressiveStrength': 'Média medição resistência compressão',
		'Proceq.ExportColumnEn13791Screening': 'Verificação EN 13791',
		'Proceq.ExportColumnLowestRebound': 'Valor último rebote',
		'Proceq.ExportColumnMedianRebound': 'Valor médio rebote',
		'Proceq.ExportColumnCompressiveStrengthClass': 'EN 206 Classe Resistência Compressão',
		'Proceq.EmailIntroDearContractOwner': 'Prezado dono do contrato',
		'Proceq.ColumnTableSeriesRegionExport': 'Série',
		'Proceq.ExportColumSeriesRegionExport': 'Série',
		'Proceq.ExportTitleTestRegionExport': 'Região teste exportação',
		'Proceq.ErrorSchmidtExportInvalidStandard': 'Todas as séries devem ter o mesmo padrão ajustado',
		'Proceq.ErrorSchmidtExportTooFewSeries': 'São necessárias no mín 9 séries para a verificação EN 13791',
		'Proceq.ErrorSchmidtExportTooManySeries': 'Podem ser selecionadas no máx 99 séries para exportação única',
		'Proceq.ErrorSchmidtExportDifferentConfiguration': 'Diferentes configurações encontradas em séries',
		'Proceq.ErrorSchmidtExportWrongStandard': 'Encontrado padrão incorreto para seleção exportação',
		'Proceq.ErrorSchmidtExportInvalidSeries':
			'No mínimo uma das séries é inválida de acordo com o padrão selecionado',
		'Proceq.ErrorSchmidtExportSoftwareError': 'Erro durante a geração da exportação',
		'Proceq.ErrorSchmidtExportOutOfRange': 'A classe resistência calculada está fora da faixa',
		'Proceq.ButtonExportTestRegionEU': 'Exportar teste verificação EN 13791 como PDF',
		'Proceq.ButtonExportTestRegionUniformity': 'Exportar uniformidade como PDF',
		'Proceq.ButtonExportTestRegionChina': 'Exportar região teste JGJ T23 como PDF',
		'Proceq.ErrorNotYetImplemented': 'Ainda não implementado',
		'Proceq.ErrorSchmidtExportWrongParam':
			'Parâmetro incorreto encontrado série. Ex.: Unidade incorreta para expotar selecionado',
		'Proceq.ErrorSchmidtExportUnitNotMpa': 'Para região JGJ obrigatório que unidade ajustada em MPa',
		'Proceq.ErrorSchmidtExportWrongFormFactor': 'Para região JGJ o fator forma não pode ser cilindro 2:1',
		'Proceq.NavSelectView': 'Selecionar visual',
		'Proceq.TextConfirmationAreYouSure': 'Tem certeza?',
		'Proceq.ErrorContractLinkedDifferentImpactDevice':
			'Contrato não pode ser usado com n° de série dispositivo impacto atual',
		'Proceq.TitleShareByUrl': 'Compartilhar p URL',
		'Proceq.ExportTitleVerification': 'Relatório verificação:',
		'Proceq.ExportVerificationStatusFailed': 'Falha',
		'Proceq.ExportVerificationStatusPassed': 'Aprovado',
		'Proceq.ExportColumnTestVerificationResult': 'Resultado verificação',
		'Proceq.ExportColumnTestBlockTolerance': 'Testar incerteza do bloco (Ucrm) k=2',
		'Proceq.ExportColumnTestBlockNominalHardness': 'Testar dureza nominal do boco',
		'Proceq.ExportColumnTestBlockSN': 'Testar n¨de série do bloco',
		'Proceq.ExportColumnTestBlockName': 'Testar nome do bloco',
		'Proceq.ExportColumnVerificationStd': 'Padrão de verificação',
		'Proceq.ExportColumnVerificationCriteria': 'Critérios de verificação',
		'Proceq.VerificationCriteriaAverage5Span5Perc': 'média +/- 5%, intervalo +/- 5%',
		'Proceq.VerificationCriteriaReadings6HL': 'medições +/- 6 HL',
		'Proceq.VerificationCriteriaReadings3Perc': 'medições +/- 3%',
		'Proceq.VerificationCriteriaAverage5Perc': 'média +/- 5%',
		'Proceq.VerificationCriteriaAverage2R': 'média +/- 2 R',
		'Proceq.VerificationCriteriaReadings3R': 'medições +/- 3 R',
		'Proceq.StandardEnumCustom': 'Personalizado',
		'Proceq.MaterialEnumCustom': 'Personalizado',
		'Proceq.ExportColumnCalibrationAnvilName': 'Calibração nome ponta',
		'Proceq.ExportColumnCalibrationAnvilSN': 'Calibração n° de série ponta',
		'Proceq.ExportColumnCalibrationAnvilReboundValue': 'Calibração valor rebote ponta',
		'Proceq.ExportColumnCalibrationAnvilTolerance': 'Calibração tolerância ponta',
		'Proceq.ExportColumnTestLoadInfo': 'Info carga teste',
		'Proceq.NavVerificationData': 'Dados verificação',
		'Proceq.NavCustomData': 'Dados personalizados',
		'Proceq.TableColumnVerificationResult': 'Resultado',
		'Proceq.TitleVerifications': 'Verificações',
		'Proceq.LogBookTestBlock': 'Bloco de teste',
		'Proceq.LogBookCalibrationAnvil': 'Calibração ponta',
		'Proceq.LogbookPropertyTestBlockChangedFromTo': 'Bloco de teste foi alterado de "**?0**" para "**?1**"',
		'Proceq.LogbookPropertyTestBlockChangedTo': 'Bloco de teste foi alterado para "**?0**"',
		'Proceq.LogbookPropertyCalibrationAnvilChangedFromTo': 'Calibração ponta foi alterada de **?0** para **?1**',
		'Proceq.LogbookPropertyCalibrationAnvilChangedTo': 'Calibração ponta foi alterada para **?0**',
		'Proceq.LogbookPropertyCreatedVerification': 'Criada verificação "**?0**"',
		'Proceq.LogbookPropertyRenamedVerificationFromTo': 'Verificação foi renomeada de "**?0**" para "**?1**"',
		'Proceq.LogbookResultVerificationFailed': 'Verificação falhou',
		'Proceq.LogbookResultVerificationPassed': 'Verificação aprovada',
		'Proceq.LogbookPropertyTestLoadChangedFromTo': 'Carga de teste alterada de **?0** para **?1**',
		'Proceq.LogbookPropertyTestLoadChangedTo': 'Carga de teste alterada para **?0**',
		'Proceq.LogBookTestLoad': 'Carga teste',
		'Proceq.FieldWebOriginalCurve': 'Original',
		'Proceq.FieldWebCustomCurve': 'Personalizado',
		'Proceq.ExportFieldValuePair': 'Valor par',
		'Proceq.TableBaseMaterial': 'Material base',
		'Proceq.TableDescription': 'Descrição',
		'Proceq.NavCustomMaterial': 'Material personalizado',
		'Proceq.FolderCustomMaterials': 'Materiais personalizados',
		'Proceq.SchmidtCurveTypePolynomial': 'fck = a + b*R + c*R² (Polinomial)',
		'Proceq.SchmidtCurveTypeExponencial': 'fck = a.e^(b*R) (Exponencial)',
		'Proceq.SchmidtCurveTypePolynomialShort': 'Polinomial',
		'Proceq.SchmidtCurveTypeExponencialShort': 'Exponencial',
		'Proceq.ExportFieldCurveType': 'Tipo de curva',
		'Proceq.TableActionEdit': 'Editar',
		'Proceq.TableActionAdd': 'Adicionar',
		'Proceq.WebAccountActivatedTitle': 'Bem-vindo ao Proceq Live,',
		'Proceq.WebAccountActivatedBodyLine1': 'Sua conta foi ativada com sucesso. esta pronta para ser usada!',
		'Proceq.WebAccountActivatedProceqTeam': 'Equipe Proceq',
		'Proceq.NavDashboard': 'Painel',
		'App.SchmidtFormFactorRAngle': 'Ângulo R corrigido',
		'App.SchmidtFormFactorQAngle': 'Valor Q',
		'App.ShareMeasurementsCopySuccess': 'Copiado!',
		'App.ShareMeasurementsCopyFailed': 'Seu navegador não suporta cópia. Copie manualmente.',
		'App.SystemFolders': 'Pastas do sistema',
		'Proceq.DataSeries': 'DemoDemonstração',
		'Proceq.DataSeriesImported': 'Importado',
		'Antenna Spacing [{unit}]': 'Espaçamento da antena [{unit}]',
		'App Version': 'Versão da aplicação',
		'Base Unit S/N': 'Unidade Base S / N',
		'Calculated Depth': 'Profundidade calculada',
		Comment: 'Comente',
		'Contract ID': 'ID do contrato',
		'Distance X [{unit}]': 'Distância X [{unit}]',
		'Distance Y [{unit}]': 'Distância Y [{unit}]',
		'Export HTML Format Version': 'Exportar versão em formato HTML',
		'File ID': 'ID do arquivo',
		'File Name': 'Nome do arquivo',
		'Firmware Version': 'Versão do firmware',
		'Grid Size X [{unit}]': 'Tamanho da grade X [{unit}]',
		'Grid Size Y [{unit}]': 'Tamanho da grade Y [{unit}]',
		'Grid Spacing X [{unit}]': 'Espaçamento da grade X [{unit}]',
		'Grid Spacing Y [{unit}]': 'Espaçamento da grade Y [{unit}]',
		'Hardware Version': 'Versão de hardware',
		Line: 'Linha',
		'MAC Address': 'Endereço MAC',
		'Marker Number': 'Número do marcador',
		'Measurement Presets': 'Predefinições de medição',
		Mode: 'Modo',
		Name: 'Nome',
		Objects: 'Objetos',
		'Probe S/N': 'Sonda S / N',
		Product: 'produtos',
		'Repetition Rate [{unit}]': 'Taxa de repetição [{unit}]',
		Resolution: 'Resolução',
		'Sampling Rate [{unit}]': 'Taxa de amostragem [{unit}]',
		'Scan Distance [{unit}]': 'Distância de digitalização [{unit}]',
		'Scan Length [{unit}]': 'Comprimento da digitalização [{unit}]',
		'Set Depth': 'Definir profundidade',
		'Set Size': 'Definir tamanho',
		Tag: 'Etiqueta, rótulo, palavra-chave',
		'Tag Type': 'Tipo de etiqueta',
		'Time Window [{unit}]': 'Janela de tempo [{unit}]',
		Units: 'Unidades',
		'User / Probe Info': 'Informações do usuário / probe',
		Markers: 'Marcadores',
		'Created {fileName} in folder {folderName}': 'Criado {fileName} na pasta {folderName}',
		'Mode: {value}': 'Modo: {value}',
		'Auth.LoggingIn': 'Logando...',
		'Auth.LoggingOut': 'Fazendo logout...',
	},
};
