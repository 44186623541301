// @flow
import React from 'react';
import type { AlertProps } from 'antd/lib/alert';
import type { FormProps } from 'antd/lib/form';
import { isLive } from 'apis/urls';
import Logo from '../img/Logo.png';
import styles from './LoginForm.styl';
import './LoginForm.css';
import Languages from '../layout/Languages';

export type CommonFormProps = {
	loading: boolean,
	severity: $PropertyType<AlertProps, 'type'>,
	message: string,
	form: FormProps,
};

export const LoginPage = ({ children }: { children: any }) => (
	<div className={styles.login}>
		<div className={styles.form_wrapper}>
			<div className={styles.language_container}>
				<Languages />
			</div>
			<div className={styles.heading_wrapper}>
				<img src={Logo} className={styles.logo} alt="logo" />
			</div>
			{!isLive && <div className={styles.dev_banner}>{'DEVELOPMENT'}</div>}
			<div className={styles.form_container}>{children}</div>
		</div>
	</div>
);

export default LoginPage;
