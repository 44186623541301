import React, { Component } from 'react';
import { isEmpty } from 'lodash';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { loadProfile } from 'actions/profile';
import styles from './AvatarInitials.styl';

const defaultColors = [
	'#72CCCA',
	'#1abc9c',
	'#16a085',
	'#409C97',

	'#2ecc71',
	'#27ae60',
	'#42787A',
	'#4F8677',

	'#47D2E9',
	'#54ACD2',
	'#3498db',
	'#2980b9',

	'#1D628B',
	'#043D5D',
	'#11132F',
	'#02135C',

	'#5659C9',
	'#8870FF',
	'#9b59b6',
	'#8e44ad',

	'#f1c40f',
	'#f39c12',
	'#e67e22',
	'#d35400',

	'#F04903',
	'#e74c3c',
	'#c0392b',
	'#D33257',
	'#EB6361',
	'#000000',
];

class AvatarInitials extends Component {
	static propTypes = {
		size: PropTypes.string,
		session: PropTypes.object,
		user: PropTypes.object,
	};

	static defaultProps = {
		size: '40px',
	};

	constructor(props) {
		super(props);

		this.state = {
			bgColor: defaultColors[0],
		};
	}

	componentWillMount() {
		if (this.props.session.userName) {
			// Ensure that each user keeps getting
			// the same avatar background color
			const emailHashNumber = this.props.session.userName.length % defaultColors.length || 1;

			this.setState({
				bgColor: defaultColors[emailHashNumber],
			});
		}
		const userInfo = this.props.user;
		if (isEmpty(userInfo.user) || isEmpty(userInfo.user.profile)) {
			// console.log('Profile is not loaded!!');
			loadProfile();
		}
	}

	render() {
		const { size } = this.props;
		return (
			<div
				style={{
					backgroundColor: this.state.bgColor,
					width: size,
					height: size,
				}}
				className={styles.avatar_circle}
			>
				{this.getAvatarContents()}
			</div>
		);
	}

	getAvatarContents() {
		// return this.getInitials();

		const userInfo = this.props.user;
		if (isEmpty(userInfo.user) || isEmpty(userInfo.user.profile) || isEmpty(userInfo.user.profile.portrait)) {
			return this.getInitials();
		}
		return <img className={styles.image} src={userInfo.user.profile.portrait} alt="img" />;
	}

	getInitials() {
		let initials = '';
		const userInfo = this.props.user;
		if (
			isEmpty(userInfo.user) ||
			isEmpty(userInfo.user.profile) ||
			isEmpty(userInfo.user.profile.firstName) ||
			isEmpty(userInfo.user.profile.lastName)
		) {
			if (!isEmpty(this.props.session.userName)) {
				initials = `${this.props.session.userName.substring(0, 2).toUpperCase()}`;
			}
		} else {
			initials = `${userInfo.user.profile.firstName
				.charAt(0)
				.toUpperCase()}${userInfo.user.profile.lastName.charAt(0).toUpperCase()}`;
		}
		const fontSize = this.getFontSize(this.props.size);
		/* const
		 fontSize = this.getFontSize(this.props.size),
		 { firstName, lastName, email } = this.props.profile; */
		/* if (!firstName || !lastName) {
		 initials = `${ email.substring(0, 2).toUpperCase() }`;
		 }
		 else {
		 initials = `${ firstName.charAt(0).toUpperCase() }${ lastName.charAt(0).toUpperCase() }`;
		 } */

		return (
			<span style={{ fontSize }} className={styles.initials}>
				{initials}
			</span>
		);
	}

	getFontSize(size) {
		const sizeNumeric = Number(size.replace('px', '')),
			fontSizeNumeric = (sizeNumeric / 2) * 0.8;
		return `${Math.max(fontSizeNumeric, 14)}px`;
	}
}
const mapStateToProps = state => ({
	session: state.session,
	user: state.user,
});

const mapDispatchToProps = dispatch => ({
	loadProfile: () => {
		dispatch(loadProfile());
	},
});

export default connect(mapStateToProps, mapDispatchToProps)(AvatarInitials);
