// @flow
import React from 'react';
import { curryRight } from 'lodash';
import { compose } from 'recompose';
import { Row, Col } from 'antd';
import {
	withHighcharts,
	HighchartsChart,
	Chart,
	XAxis,
	YAxis,
	Tooltip,
	LineSeries,
	Legend,
} from 'react-jsx-highcharts';
import Highcharts from 'highcharts';
import { injectIntl } from 'react-intl';
import type { InjectIntlProvidedProps } from 'react-intl';
import {
	getAllParsedDataNeeded,
	getPairValueForProduct,
	getSettingsParametersForProduct,
} from 'utils/legacyCustomMaterialsChartHelper';
import { doExtraInfoColumn } from 'utils/legacyEquotipChartHelper';
import { getResolutionForScale } from 'utils/conversionsHelper';

function formatter(sourceUnit, targetUnit) {
	return function unitFormatter() {
		const header = `<b>${this.x} ${sourceUnit}</b><br/>`;
		let content = '';
		for (let indexSerie = 0; indexSerie < this.points.length; indexSerie++) {
			const thisPoint = this.points[indexSerie];
			if (indexSerie !== 0) {
				content += '<br/>';
			}
			content += `<span style="color:${thisPoint.color}">\u25CF</span> ${thisPoint.series.name}:`;
			content += `${thisPoint.y} ${targetUnit}`;
		}
		return header + content;
	};
}

export const CustomMaterialChart = ({
	record,
	product,
	intl: { formatMessage },
}: { record: Object, product: ProductCode } & InjectIntlProvidedProps) => {
	const allParsedDataNeeded = getAllParsedDataNeeded(record, product, formatMessage);
	const sourceUnit = allParsedDataNeeded.SETTINGS.SOURCEUNIT.VALUE;
	const targetUnit = allParsedDataNeeded.SETTINGS.TARGETUNIT.VALUE;

	const pairValueHTML = doExtraInfoColumn(
		getPairValueForProduct(product),
		'Proceq.ExportFieldValuePair',
		allParsedDataNeeded
	);
	const settingsHTML = doExtraInfoColumn(
		getSettingsParametersForProduct(product),
		'Proceq.ExportFieldSettings',
		allParsedDataNeeded,
		pairValueHTML === null ? 16 : 12
	);

	const curves = record.content.curves;

	return (
		<Row>
			{curves && curves.original && (
				<Col xs={24} lg={24}>
					<div style={{ maxWidth: '800px', margin: '0 auto' }}>
						<HighchartsChart>
							<Chart backgroundColor={null} />

							<Legend layout="vertical" align="right" verticalAlign="middle" />

							<Tooltip formatter={formatter(sourceUnit, targetUnit)} shared />

							<XAxis>
								<XAxis.Title>{sourceUnit}</XAxis.Title>
							</XAxis>

							<YAxis id="number" type="linear">
								<YAxis.Title>{targetUnit}</YAxis.Title>
								{curves.original.length > 0 && (
									<LineSeries
										id="dataoriginal"
										name={formatMessage({
											id:
												product === 'SCHMIDT'
													? 'Proceq.FieldWebCustomCurve'
													: 'Proceq.FieldWebOriginalCurve',
										})}
										data={curves.original.map(([source, target]) => [
											parseFloat(getResolutionForScale(sourceUnit, source)),
											parseFloat(getResolutionForScale(targetUnit, target)),
										])}
										color={product === 'SCHMIDT' ? 'rgba(255,0,0,1)' : 'rgba(0,0,255,.9)'}
									/>
								)}
								{curves.transformed.length > 0 && (
									<LineSeries
										id="datatransformed"
										name={formatMessage({
											id: 'Proceq.FieldWebCustomCurve',
										})}
										data={curves.transformed.map(([source, target]) => [
											parseFloat(getResolutionForScale(sourceUnit, source)),
											parseFloat(getResolutionForScale(targetUnit, target)),
										])}
										color="rgba(255,0,0,1)"
									/>
								)}
							</YAxis>
						</HighchartsChart>
					</div>
				</Col>
			)}
			{pairValueHTML}
			{settingsHTML}
		</Row>
	);
};

export default compose(curryRight(withHighcharts)(Highcharts), injectIntl)(CustomMaterialChart);
