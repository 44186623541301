// @flow
import { assign, has, get, noop } from 'lodash';
import { checkStatusJson } from 'apis/restUtils';
import { httpPost, httpRequest } from 'apis/httpClient';
import URL from 'apis/urls';
import { loginAsyncProceq, loginAsyncOneTimeProceq } from 'actionUtils/loginAsync';
import { convertTokenResponseToSession, getProceqUrl } from 'utils/sessionHelper';
import { getTextValue } from 'utils/registryHelper';
import { getLandingPath } from 'utils/authUtils';
import { startUserSession, initUserSession } from './session';
import { request } from './ajax';
import actionTypes from './actionTypes';
import { history } from '../routes';
import { fetchProfile } from './profile';

// Action creator states
export function loginRequest(userData: Object) {
	return {
		type: actionTypes.LOGIN_REQUEST,
		payload: userData,
	};
}
export function loginRequestProceq(userData: Object) {
	return {
		type: actionTypes.PROCEQ_LOGIN_REQUEST,
		payload: userData,
	};
}

export function loginFailed(err: any) {
	return {
		type: actionTypes.LOGIN_FAILED,
		payload: err,
	};
}

export function loginSuccess(loginInfo: any) {
	return {
		type: actionTypes.LOGIN_SUCCESS,
		payload: loginInfo,
	};
}

export function resettingPassword() {
	return {
		type: actionTypes.RESET_PASSWORD_SENDING,
	};
}

export function resetPasswordEmailSent() {
	return {
		type: actionTypes.RESET_PASSWORD_EMAIL_SENT,
	};
}

export function resetPasswordSuccess() {
	return {
		type: actionTypes.RESET_PASSWORD_SUCCESS,
	};
}

export function getMeasurementSummaries() {
	return (dispatch: Function, getState: Function) => {
		const { session } = getState();
		if (!session.regionalDomain) {
			return Promise.resolve();
		}

		const products = getTextValue(['availproductsfull']).map(p => p.value);
		return Promise.all(
			products.map(product =>
				dispatch(
					request({
						url: URL.GET_SUMMARY,
						params: {
							product,
						},
					})
				).then(summary => {
					dispatch(setMeasurementSummary(product, summary));
					return Promise.resolve({
						product,
						summary,
					});
				})
			)
		);
	};
}

function setMeasurementSummary(product, summary) {
	return {
		type: actionTypes.SET_MEASUREMENT_SUMMARY,
		payload: {
			product,
			summary,
		},
	};
}

export function login({
	email,
	password,
	errorHandler = noop,
}: {
	email: string,
	password: string,
	errorHandler: Function,
}) {
	return (dispatch: Function) => {
		dispatch(
			request({
				url: URL.SIGN_IN,
				method: 'POST',
				params: {
					username: email,
					grantType: 'password',
					clientID: 'email',
					password: btoa(unescape(encodeURIComponent(password))),
				},
				errorHandler,
			})
		);
	};
}

export function afterLogin(payload: Session) {
	return (dispatch: Function) => {
		dispatch(initUserSession(payload));
		/* dispatch(getMeasurementSummaries()).then(summaries => {
			const products = zipObject(
				summaries.map(s => s.product),
				summaries.map(s => s.summary)
			);
			dispatch(loginSuccess(payload));
			if (hasOneOfRoleLevel(payload.scopes, [ROLES.DEV, ROLES.PO])) {
				history.replace('/settings');
			} else if (hasProduct(products, ['EQUOTIP'])) {
				dispatch(changeCurrentProduct('EQUOTIP'));
				history.replace('/dashboard-old');
			} else if (hasProduct(products, ['SCHMIDT'])) {
				dispatch(changeCurrentProduct('SCHMIDT'));
				history.replace('/dashboard-old');
			} else {
				history.replace('/settings');
			}
		}); */
		dispatch(loginSuccess(payload));
		history.replace(getLandingPath());
	};
}

export function loginOneTimeTDS(accessToken: string) {
	return (dispatch: Function) => {
		dispatch(
			startUserSession({
				sessionType: 'TDS',
				accessToken,
			})
		);

		setTimeout(() =>
			dispatch(
				request({
					url: URL.REFRESH_TOKEN,
					method: 'POST',
					params: {
						grantType: 'refresh_token',
						refreshToken: accessToken,
					},
				})
			).then(results => {
				dispatch(startUserSession(results));
			})
		);
	};
}

export function loginProceq(userData: Object) {
	return (dispatch: Function) => {
		dispatch(loginRequestProceq(userData));
		return loginAsyncProceq(userData)
			.then(data => Promise.all([data]))
			.then(results => {
				const loginInfo = assign(...results);
				dispatch(startUserSession(loginInfo));
				loginInfo.email = loginInfo.username;
				loginInfo.profile = {};
				dispatch(loginSuccess(loginInfo));
				history.push(getLandingPath());
			});
		// .catch(err => {
		// 	const errorMsg = (((err || {}).response || {}).data || {}).error_description || 'Proceq.ServerError';
		// 	dispatch(loginFailed(errorMsg));
		// });
	};
}

export function loginOneTimeProceq(userData: Object) {
	return (dispatch: Function) => {
		dispatch(loginRequestProceq(userData));
		return loginAsyncOneTimeProceq(userData)
			.then(data => Promise.all([data]))
			.then(results => {
				const loginInfo = assign(...results);
				dispatch(startUserSession(loginInfo));
				loginInfo.email = loginInfo.username;
				loginInfo.profile = {};
				dispatch(loginSuccess(loginInfo));
				history.push(getLandingPath());
			})
			.catch(err => {
				// TODO: Localize, Introduce a REST error handler
				let errorMsg = 'Proceq.ServerError';
				if (err && has(err, 'response.data.message')) {
					errorMsg = err.response.data.message;
				}
				dispatch(loginFailed(errorMsg));
			});
	};
}

export function forgotPassword(username: string, isTdsCloud: boolean) {
	return (dispatch: Function) => {
		const jsonBody = {
			username,
		};

		dispatch(resettingPassword());

		const urlForgot = isTdsCloud ? URL.FORGOT_PASSWORD : getProceqUrl(null, URL.PROCEQ_FORGOT_PASSWORD);
		return httpPost(urlForgot, jsonBody)
			.then(checkStatusJson)
			.then(() => {
				dispatch(resetPasswordEmailSent());
			})
			.catch(err => {
				if (err) {
					if (get(err, 'response.data.code') === 'RequestLimitExceeded') {
						dispatch(loginFailed('App.ForgetPasswordLimitReached'));
					} else if (get(err, 'response.status') < 500) {
						dispatch(loginFailed('Proceq.ForgotPasswordFormEmailSentMessage'));
					}
				} else {
					dispatch(loginFailed('Proceq.ServerError'));
				}
			});
	};
}

export const INVALID_ROLE_ERROR_MESSAGE = 'Invalid Role';

type FetchTokenParams = {
	client_id: string,
	code: string,
	redirect_uri: string,
	grant_type: 'authorization_code',
};
export const fetchToken = (params: FetchTokenParams) => async (dispatch: Function, getState: Function) => {
	const response: any = await dispatch(request({ url: URL.AUTH_TOKEN, method: 'POST', params }));
	if (response.error) {
		throw new Error(response.error);
	}

	// Get the half session for API verification, China region checking and fetching profile
	const session: any = convertTokenResponseToSession(response);

	// Setup the http only cookie
	await httpRequest(session, undefined, URL.SET_EAGLE_ID_AUTH, 'post', true, {});
	const profile = await dispatch(fetchProfile(session.userID.toString(), session, getState().locale));

	// If only has user access, we should redirect to Eagle Workspace
	if (profile.roles === 'user') {
		throw new Error(INVALID_ROLE_ERROR_MESSAGE);
	}

	// Save the full session together with role information
	const fullSession: any = convertTokenResponseToSession(response, profile.roles);
	await dispatch(afterLogin(fullSession));
};
