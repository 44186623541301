// @flow
import React, { Component } from 'react';
import type { ContextRouter } from 'react-router';
import { get, trim } from 'lodash';
import qs from 'qs';
import axios from 'axios';
import PageNotFound from 'components/error/PageNotFound';
import DownloadStatus from 'components/error/DownloadStatus';

export class DownloadRedirectRoute extends Component<
	{
		noDelay?: boolean,
	} & ContextRouter,
	{
		checking: boolean,
		notFound: boolean,
		authError: string,
	}
> {
	static defaultProps = {
		noDelay: false,
	};

	state = {
		checking: true,
		notFound: false,
		authError: '',
	};

	get domain(): ?string {
		const { d } = qs.parse(trim(this.props.location.search, '?'));
		return d;
	}

	get key(): ?string {
		const { k } = qs.parse(trim(this.props.location.search, '?'));
		return k;
	}

	componentWillMount() {
		switch (get(this.props.match.params, 'downloadType')) {
			case 's':
				if (!this.domain || !this.key) {
					this.setState({
						checking: false,
						authError: 'App.FileDownloadError',
					});
				}
				break;
			default:
				this.setState({ notFound: true });
		}
	}

	componentDidMount() {
		if (this.state.authError || this.state.notFound) {
			return;
		}

		if (this.props.noDelay) {
			this.redirect();
		} else {
			setTimeout(this.redirect, 1000);
		}
	}

	redirect = () => {
		if (!this.domain || !this.key) {
			return;
		}
		const protocol = this.domain.includes('localhost') ? 'http' : 'https';
		const domain = this.domain ? this.domain : '';
		const key = this.key ? this.key : '';
		const url = `${protocol}://${domain}/api/files/info?key=${key}`;
		axios
			.get(url)
			.then(resp => {
				this.setState({ checking: false }, () => {
					window.location.assign(resp.data.url);
				});
			})
			.catch(err => {
				switch (get(err, 'response.status')) {
					case 404:
						if (get(err, 'response.data.code') === 'ResourceNotFound') {
							this.setState({
								checking: false,
								authError: 'App.FileDownloadNotFound',
							});
						} else {
							this.setState({
								checking: false,
								authError: 'App.FileDownloadError',
							});
						}
						break;
					case 406:
						this.setState({
							checking: false,
							authError: 'App.FileDownloadExpired',
						});
						break;
					case 422:
						this.setState({
							checking: false,
							authError: 'App.LongRunningJobInProgress',
						});
						break;
					default:
						if (
							get(err, 'response.status') === 500 &&
							get(err, 'response.data.message') &&
							get(err, 'response.data.message').includes('[Export Failed]')
						) {
							this.setState({
								checking: false,
								authError: 'App.ExportFailure',
							});
						} else {
							this.setState({
								checking: false,
								authError: 'App.FileDownloadError',
							});
						}
				}
			});
	};

	render() {
		if (this.state.notFound) {
			return <PageNotFound />;
		}

		if (this.state.authError) {
			return <DownloadStatus id={this.state.authError} />;
		}

		if (this.state.checking) {
			return <DownloadStatus id="App.CheckingFileDownload" />;
		}
		return <DownloadStatus id="App.StartingFileDownload" />;
	}
}

export default DownloadRedirectRoute;
