// @flow
import React from 'react';
import { FormattedMessage } from 'react-intl';

export const TrashFile = ({ log }: GPRLogbookEntryProps) => (
	<FormattedMessage
		id="App.LogbookEntryTrashed"
		values={{
			folder: log.content.folder,
		}}
	/>
);

export const color = () => 'grey';

export default TrashFile;
