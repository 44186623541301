// @flow
import React from 'react';
import { Alert as AntAlert } from 'antd';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import styles from './LoginForm.styl';

export const Alert = ({
	message,
	description,
	severity = 'error',
	showIcon = true,
	showReturn = true,
}: {
	message: string,
	description?: any,
	severity: 'success' | 'info' | 'warning' | 'error',
	showIcon?: boolean,
	showReturn?: boolean,
}) => (
	<div className={styles.alert}>
		<AntAlert
			message={<FormattedMessage id={message} />}
			description={description}
			type={severity}
			showIcon={showIcon}
		/>
		{severity === 'success' && showReturn && (
			<div className={styles.button_container}>
				<Link to="/login">
					<FormattedMessage id="Proceq.LoginBackLogin" />
				</Link>
			</div>
		)}
	</div>
);

export default Alert;
