// @flow
import React from 'react';
import classNames from 'classnames';
import { get } from 'lodash';
import { connect } from 'react-redux';
import { compose, withState, withProps, withStateHandlers } from 'recompose';
import { FormattedMessage } from 'react-intl';
import { Modal, Button } from 'antd';
import URL from 'apis/urls';
import styles from 'components/shared/SmarterTable.styl';
import { request } from 'actions/ajax';
import LogbookIcon from '../img/icoLogbook@2x.png';
import GPRLogbook from './GPRLogbook';
import EquotipLogbook from './EquotipLogbook';

export const Logbook = ({
	visible,
	openModal,
	closeModal,
	loading,
	logs,
	logbookProps,
	product,
	version,
}: {
	visible: boolean,
	openModal: Function,
	closeModal: Function,
	loading: boolean,
	logs: Log[],
	logbookProps: Object,
	product: ProductCode,
	version: string,
}) => (
	<div className={classNames(styles.button, styles.inline)}>
		<Modal
			visible={visible}
			onOk={closeModal}
			onCancel={closeModal}
			title={<FormattedMessage id="Proceq.LogbookTitle" />}
			footer={[
				<Button key="back" size="large" onClick={closeModal}>
					<FormattedMessage id="Proceq.LogbookClose" />
				</Button>,
			]}
		>
			{['GPR', 'PUNDIT', 'FDL', 'GPR_SOIL', 'PIT_IE'].includes(product) && (
				<GPRLogbook logs={logs} product={product} version={version} {...logbookProps} />
			)}
			{['EQUOTIP', 'SCHMIDT', 'GLM'].includes(product) && (
				<EquotipLogbook logs={logs} product={product} {...logbookProps} />
			)}
		</Modal>
		<Button
			type="primary"
			onClick={openModal}
			loading={loading}
			className={styles.icon_container}
			data-e2e-logbook-button
		>
			{!loading && <img src={LogbookIcon} className={styles.icon} alt="Logbook" />}
		</Button>
	</div>
);

export const LogbookWithState = compose(
	withStateHandlers(
		{
			visible: false,
		},
		{
			openModal: () => () => ({ visible: true }),
			closeModal: () => () => ({ visible: false }),
		}
	)
)(Logbook);

export default compose(
	withState('visible', 'setVisible', false),
	withState('loading', 'setLoading', false),
	withState('logs', 'setLogs', null),
	withState('logbookProps', 'setLogbookProps', props => props.logbookProps || {}),
	connect(null, (dispatch: Function, props) => ({
		fetchSettings: () =>
			dispatch(
				request({
					url: URL.MEASUREMENTS + props.mID,
					params: {
						withunsynced: props.withunsynced,
					},
				})
			).then(data => {
				props.setLogbookProps({
					...props.logbookProps,
					productModel: get(data, 'measurement.productModel'),
					scanType: get(data, 'measurement.type'),
					isMetric:
						get(
							data,
							props.product === 'GPR'
								? 'settings.0.content.settings.display.unit'
								: 'settings.0.content.display.unit'
						) === 'Metric',
				});
			}),
	})),
	connect(null, (dispatch: Function, props) => ({
		fetchLogs: () =>
			dispatch(
				request({
					url: URL.LOGBOOK,
					params: {
						mID: props.mID,
					},
				})
			).then(data => {
				props.setLogs(data.data);
			}),
	})),
	withProps(({ logs, fetchLogs, fetchSettings, product, setVisible, setLoading }) => ({
		openModal: () => {
			if (logs === null) {
				setLoading(true);

				const networkRequests = [fetchLogs()];
				switch (product) {
					case 'GPR':
					case 'GPR_SOIL':
					case 'PUNDIT':
					case 'FDL':
					case 'PIT_IE':
						networkRequests.push(fetchSettings());
						break;
					case 'EQUOTIP':
					case 'SSL':
					case 'SCHMIDT':
					// get isVerificationMode
				}

				Promise.all(networkRequests).then(() => {
					setLoading(false);
					setVisible(true);
				});
			} else {
				setVisible(true);
			}
		},
		closeModal: () => {
			setLoading(false);
			setVisible(false);
		},
	}))
)(Logbook);
