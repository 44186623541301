// @flow
import React, { useEffect } from 'react';
import { Route, useLocation, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import * as routeUtils from '../utils/routeUtils';
import { getTextValue } from '../utils/registryHelper';
import { changeLanguage } from '../actions/locale';
import { getLang } from '../utils/localeHelper';

const languageKeys = getTextValue(['availlanguages']).map(({ value }) => value);

const LanguageRoute = (props: any) => {
	const location = useLocation();
	const history = useHistory();
	const dispatch = useDispatch();

	const { search, pathname } = location;
	const { lang } = routeUtils.parseQuery(search);

	// Change language based on URL params
	useEffect(() => {
		if (lang && languageKeys.includes(lang)) {
			const shortLang = getLang(lang);
			dispatch(changeLanguage(shortLang));
		}
		if (lang !== undefined) {
			const newPath = routeUtils.removeQueryKeys(pathname, search, ['lang']);
			history.replace(newPath);
		}
	}, [lang, history, search, pathname]);

	return lang === undefined ? <Route {...props} /> : null;
};

export default LanguageRoute;
