const RestUtils = {
	checkStatusJson,
	checkStatusBuffer,
};

export function checkStatusJson(response) {
	return new Promise((resolve, reject) => {
		if (response && (response.status === 200 || response.status === 201 || response.status === 204)) {
			resolve(response.data);
		} else {
			reject(response ? response.data : new Error('No response'));
		}
	});
}

export function checkStatusBuffer(response) {
	return new Promise((resolve, reject) => {
		if (response && response.status === 200) {
			resolve(response.data);
		} else {
			reject(response ? response.data : new Error('No response'));
		}
	});
}

export default RestUtils;
