// @flow
import React, { Fragment } from 'react';
import { has, get, round } from 'lodash';
import { compose } from 'recompose';
import { FormattedMessage, injectIntl } from 'react-intl';
import type { InjectIntlProvidedProps } from 'react-intl';
import { Row, Col } from 'antd';
import { extractLabelsFromSeries, morrisToHighcharts, morrisToHighchartsReg } from 'utils/conversionsHelper';
import Charts from '../shared/Charts';
import layoutStyles from '../layout/Layout.styl';

export const Equotip = ({
	isAdmin,
	measurements,
	intl: { formatMessage },
}: {
	isAdmin: boolean,
	measurements: EquotipMeasurementAnalytics,
} & InjectIntlProvidedProps) => {
	const barChart0Data = morrisToHighcharts(
		formatMessage,
		measurements.impactsWithTime.map(({ key, value }) => ({ label: key, value }))
	);
	// const barChart1Data = morrisToHighcharts(formatMessage, data.histogram);
	// const barChart2Data = morrisToHighcharts(formatMessage, data.seriesCount);
	const labelsBarChart0 = extractLabelsFromSeries(barChart0Data);
	// const labelsBarChart1 = extractLabelsFromSeries(barChart1Data);
	// const labelsBarChart2 = extractLabelsFromSeries(barChart2Data);
	barChart0Data[0].name = formatMessage({ id: 'Proceq.LabelImpacts' });
	// barChart1Data[0].name = formatMessage({ id: 'Proceq.ColumnTableSeriesRegionExport' });
	// barChart2Data[0].name = formatMessage({ id: 'Proceq.LabelImpacts' });
	return (
		<Fragment>
			{[
				{
					name: 'Proceq.GraphMeasurementCount',
					type: 'areaspline',
					series: barChart0Data,
					seriesNames: ['LabelImpacts'],
					xAxisTitle: '',
					yAxisTitle: formatMessage({ id: 'Proceq.LabelImpacts' }),
					ykeys: ['value'],
					labels: labelsBarChart0,
				},
				// ...(isAdmin
				// 	? [
				// 			{
				// 				name: 'Proceq.GraphMeasurementSeriesCount',
				// 				type: 'column',
				// 				series: barChart2Data,
				// 				seriesNames: ['ColumnTableSeriesRegionExport'],
				// 				xAxisTitle: '',
				// 				yAxisTitle: formatMessage({ id: 'Proceq.ColumnTableSeriesRegionExport' }),
				// 				labels: labelsBarChart2,
				// 			},
				// 	  ]
				// 	: []),
				// {
				// 	name: 'Proceq.GraphHistogram',
				// 	type: 'column',
				// 	series: barChart1Data,
				// 	seriesNames: ['LabelImpacts'],
				// 	xAxisTitle: product === 'EQUOTIP' ? 'HLD' : 'R',
				// 	yAxisTitle: formatMessage({ id: 'Proceq.LabelImpacts' }),
				// 	labels: labelsBarChart1,
				// },
			].map(({ name, type, series, seriesNames, xAxisTitle, yAxisTitle, labels }) => (
				<div key={name} className={layoutStyles.container}>
					<h2 className="label-diagramm">
						<FormattedMessage id={name} />
					</h2>
					{has(series, '0.data.0') ? (
						<Charts
							key="equotip-dashboard-0-"
							baseId="equotip-dashboard-0-"
							type={type}
							series={series}
							serieNames={seriesNames}
							showLegend={false}
							xAxisTitle={xAxisTitle}
							yAxisTitle={yAxisTitle}
							labels={labels}
						/>
					) : (
						<FormattedMessage id="Proceq.GraphNoDataAvailable" />
					)}
				</div>
			))}

			<Row gutter={1}>
				{(isAdmin
					? [
							{
								dataIndex: 'conversionStandards',
								globalRegister: 'standards',
								title: 'Proceq.GraphStandard',
							},
							{
								dataIndex: 'materialGroups',
								globalRegister: 'materials',
								title: 'Proceq.GraphMaterial',
							},
							{
								dataIndex: 'conversionScales',
								globalRegister: 'scales',
								title: 'Proceq.GraphSecondaryScale',
							},
							// {
							// 	dataIndex: 'toleranceDonut',
							// 	title: 'Proceq.GraphWithinTolerance',
							// 	globalRegister: '',
							// },
					  ]
					: [
							{
								dataIndex: 'materialGroups',
								globalRegister: 'materials',
								title: 'Proceq.GraphMaterial',
							},
							{
								dataIndex: 'conversionScales',
								globalRegister: 'scales',
								title: 'Proceq.GraphSecondaryScale',
							},
					  ]
				).map(({ title, dataIndex, globalRegister }) => {
					const datum = get(measurements, dataIndex, []);
					const total = datum.reduce((sum, current) => sum + current.value, 0);
					const series = globalRegister
						? morrisToHighchartsReg(
								'EQUOTIP',
								formatMessage,
								datum
									.filter(({ value }) => value > 0)
									.map(({ id, value }) => ({ label: id, value: round((value / total) * 100, 1) })),
								globalRegister
						  )
						: morrisToHighcharts(formatMessage, datum);
					return (
						<Col xs={24} md={12} key={dataIndex} className={layoutStyles.container}>
							<div className="white">
								<h2 className="label-diagramm">
									<FormattedMessage id={title} />
								</h2>
								{has(series, '0.data.0') ? (
									<Charts
										type="pie"
										series={series}
										unitForLabel="%"
										labels={extractLabelsFromSeries(series)}
									/>
								) : (
									<FormattedMessage id="Proceq.GraphNoDataAvailable" />
								)}
							</div>
						</Col>
					);
				})}
			</Row>
		</Fragment>
	);
};

export default compose(injectIntl)(Equotip);
