// @flow
import React from 'react';
import { compose } from 'redux';
import { withStateHandlers } from 'recompose';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import '../measurements/Logbook.css';
import AuditEvent from './AuditEvent';
import { Action } from './contractConsts';

export const HistoryLog = ({
	logs,
	products,
	documentType,
	isProductSaas,
	highlighted,
	toggleHighlight,
}: {
	logs: AuditItem[],
	products: { text: string, value: any }[],
	documentType: string,
	isProductSaas: boolean,
	highlighted: string[],
	toggleHighlight: Function,
}) => {
	const sortedLogs = logs
		? logs.sort((a, b) => {
				return b.id - a.id;
		  })
		: [];
	const logsPair = [];
	const dateMap = new Map();
	for (let i = 0; i < sortedLogs.length; i++) {
		let first: AuditItem = {};
		let second = null;
		if (i < sortedLogs.length - 1) {
			first = sortedLogs[i + 1];
			second = sortedLogs[i];
		} else if (sortedLogs[i].action === Action.Created) {
			second = sortedLogs[i];
		}
		if (second !== null) {
			const obj = {
				first,
				second,
				showDate: false,
				id: '',
			};
			obj.id = obj.second.content.id + i + 1;
			const date = moment(second.updateTime).format('L');
			if (dateMap.get(date) === undefined) {
				obj.showDate = true;
				dateMap.set(date, true);
			}
			logsPair.push(obj);
		}
	}

	return (
		<div id="logbookModal">
			<div id="chat-body" className="chat-body-custom-scroll">
				{sortedLogs.length === 0 && <FormattedMessage id="History not found." />}
				<ul>
					{logsPair &&
						logsPair.map(log => {
							return (
								<AuditEvent
									key={log.second.id}
									documentType={documentType}
									isProductSaas={isProductSaas}
									products={products}
									logFirst={log.first}
									logSecond={log.second}
									showDate={log.showDate}
									highlighted={highlighted.includes(log.second.id)}
									toggleHighlight={() => toggleHighlight(log.second.id)}
								/>
							);
						})}
				</ul>
			</div>
		</div>
	);
};

export default compose(
	withStateHandlers(
		{
			highlighted: [],
		},
		{
			toggleHighlight: ({ highlighted }) => id => ({
				highlighted: highlighted.includes(id)
					? highlighted.filter(existingId => existingId !== id)
					: [...highlighted, id],
			}),
		}
	)
)(HistoryLog);
