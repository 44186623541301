// @flow
import React from 'react';
import type { InjectIntlProvidedProps } from 'react-intl';
import { injectIntl } from 'react-intl';
import handleFormatMessage from 'utils/handleFormatMessage';
import { getLabelFromGlobalDataKey } from 'utils/registryHelper';
import { getSchmidtCompositeSurfaceId } from 'utils/conversionsHelper';

export const SurfaceCorrection = ({ intl, log }: InjectIntlProvidedProps & EquotipLogbookEntryProps) => {
	const oldCompositeSurfaceId = getSchmidtCompositeSurfaceId(
		log.content.oldStrengthId,
		log.content.oldSurfaceCorrectionId
	);
	const updatedCompositeSurfaceId = getSchmidtCompositeSurfaceId(
		log.content.updatedStrengthId,
		log.content.updatedSurfaceCorrectionId
	);

	const updatedCompositeSurfaceName = handleFormatMessage(
		getLabelFromGlobalDataKey('surfacestrengthandcorrschmidt', updatedCompositeSurfaceId),
		intl.formatMessage
	);

	if (oldCompositeSurfaceId) {
		return (
			<span>
				{handleFormatMessage('Proceq.LogbookPropertySurfaceCorrectionChangedFromTo', intl.formatMessage, [
					handleFormatMessage(
						getLabelFromGlobalDataKey('surfacestrengthandcorrschmidt', oldCompositeSurfaceId),
						intl.formatMessage
					),
					updatedCompositeSurfaceName,
				])}
			</span>
		);
	}

	return (
		<span>
			{handleFormatMessage('Proceq.LogbookPropertySurfaceCorrectionChangedTo', intl.formatMessage, [
				updatedCompositeSurfaceName,
			])}
		</span>
	);
};

export default injectIntl(SurfaceCorrection);
