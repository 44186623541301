// @flow
import React from 'react';
import type { InjectIntlProvidedProps } from 'react-intl';
import { injectIntl } from 'react-intl';
import {
	getScaleInfoOfScaleId,
	getResolutionForScale,
	getSchmidtScaleIdOfFromFactorAndUnitId,
} from 'utils/conversionsHelper';
import handleFormatMessage from 'utils/handleFormatMessage';

export const Measurement = ({
	intl,
	log,
	product,
	isVerificationMode,
	primaryEquotipScaleId,
	primarySchmidtScaleId,
	primaryVerificationScaleId,
}: InjectIntlProvidedProps & EquotipLogbookEntryProps) => {
	const getScaleInfo = scaleId => getScaleInfoOfScaleId(intl.formatMessage, scaleId, product);
	const primaryEquotipId = getScaleInfo(primaryEquotipScaleId).scaleUnits;
	const primarySchmidtId = getScaleInfo(primarySchmidtScaleId).scaleUnits;
	const primaryVerificationId = getScaleInfo(primaryVerificationScaleId).scaleUnits;
	const secondaryScaleId = getScaleInfo(log.content.measurementValue.secondaryScaleId).scaleUnits;

	let actionText = '';
	switch (log.content.eventType) {
		case 0:
			actionText = 'Proceq.LogbookPropertyMeasurementAdded';
			break;
		case 1:
			actionText = 'Proceq.LogbookPropertyMeasurementExcluded';
			break;
		case 2:
			actionText = 'Proceq.LogbookPropertyMeasurementIncluded';
			break;
		case 3:
			actionText = 'Proceq.LogbookPropertyMeasurementDeleted';
			break;
		default:
			break;
	}

	let bodyContent = `#${log.content.measurementIndex} `;
	if (isVerificationMode) {
		bodyContent += `${getResolutionForScale(
			primaryVerificationId,
			log.content.measurementValue.primaryValue,
			'point'
		)} ${primaryVerificationId}`;
	} else {
		switch (product) {
			case 'EQUOTIP':
				bodyContent += `${getResolutionForScale(
					primaryEquotipId,
					log.content.measurementValue.primaryValue,
					'point'
				)} ${primaryEquotipId} / ${getResolutionForScale(
					secondaryScaleId,
					log.content.measurementValue.secondaryValue,
					'point'
				)} ${secondaryScaleId}`;

				break;
			case 'SSL':
			case 'SCHMIDT':
				{
					const targetScaleId = getSchmidtScaleIdOfFromFactorAndUnitId(
						log.content.measurementValue.unitId,
						log.content.measurementValue.formFactorId
					);
					bodyContent += `${getResolutionForScale(
						primarySchmidtId,
						log.content.measurementValue.primaryValue,
						'point'
					)} ${primarySchmidtId} / ${getResolutionForScale(
						getScaleInfo(targetScaleId).scaleUnits,
						log.content.measurementValue.secondaryValue,
						'point'
					)} ${getScaleInfo(targetScaleId).scaleName}`;
				}
				break;
			default:
				break;
		}
	}

	return <span>{handleFormatMessage(actionText, intl.formatMessage, [bodyContent])}</span>;
};

export const color = (log: Log) => {
	switch (log.content.eventType) {
		case 0:
			return 'green';
		case 1:
			return 'grey';
		case 2:
			return 'green';
		case 3:
			return 'red';
		default:
			return 'blue';
	}
};

export default injectIntl(Measurement);
