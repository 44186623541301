// @flow
import React from 'react';
import styles from '../layout/Layout.styl';

export const Firmware = () => (
	<svg viewBox="0 0 32 32" className={styles.icon}>
		<g id="surface1">
			<path d="M 9 3 L 9 6 L 11 6 L 11 3 Z M 13 3 L 13 6 L 15 6 L 15 3 Z M 17 3 L 17 6 L 19 6 L 19 3 Z M 21 3 L 21 6 L 23 6 L 23 3 Z M 7 7 L 7 25 L 25 25 L 25 7 Z M 3 9 L 3 11 L 6 11 L 6 9 Z M 9 9 L 23 9 L 23 23 L 9 23 Z M 26 9 L 26 11 L 29 11 L 29 9 Z M 3 13 L 3 15 L 6 15 L 6 13 Z M 26 13 L 26 15 L 29 15 L 29 13 Z M 3 17 L 3 19 L 6 19 L 6 17 Z M 26 17 L 26 19 L 29 19 L 29 17 Z M 3 21 L 3 23 L 6 23 L 6 21 Z M 26 21 L 26 23 L 29 23 L 29 21 Z M 9 26 L 9 29 L 11 29 L 11 26 Z M 13 26 L 13 29 L 15 29 L 15 26 Z M 17 26 L 17 29 L 19 29 L 19 26 Z M 21 26 L 21 29 L 23 29 L 23 26 Z " />
		</g>
	</svg>
);

export default Firmware;
