// @flow
import React from 'react';
import classNames from 'classnames';
import moment from 'moment';
import { find } from 'lodash';
import { connect } from 'react-redux';
import { compose, withState, lifecycle } from 'recompose';
import { FormattedMessage } from 'react-intl';
import { Layout, Modal, Button, Popconfirm, Icon, Tag } from 'antd';
import URL from 'apis/urls';
import { DATE_SERVER_FORMAT } from 'utils/dateHandler';
import { hasRoleLevel, ROLES } from 'utils/rolesHelper';
import { request } from 'actions/ajax';
import styles from './Feature.styl';
import tableStyles from '../shared/SmarterTable.styl';
import columnConfig from './columnConfig';
import FeaturesBaseTable from './FeatureBaseTable';
import AddEditFeature from './AddEditFeature';
import ExportFeatures from './ExportFeatures';
import { productComparator } from '../contracts/contractConsts';

export const Features = ({
	isSuper,
	feature,
	totalRecords,
	loadFeatures,
	loading,
	setParams,
	selectedRowKeys,
	products,
	onRowSelection,
	editedFeature,
	changeFeature,
	deleteFeatures,
	loadProducts,
	prodOps,
}: {
	isSuper: boolean,
	feature: Feature[],
	totalRecords: number,
	loadFeatures: Function,
	loading: boolean,
	setParams: Function,
	selectedRowKeys: string[],
	products: Product[],
	onRowSelection: Function,
	editedFeature?: string,
	changeFeature: Function,
	deleteFeatures: Function,
	loadProducts: Function,
	prodOps: { text: string, value: any }[],
}) => {
	const contractTypes = {
		'0': 'Device',
		'1': 'Maintenance Services',
		'2': 'Software',
	};
	return (
		<Layout>
			<Layout.Content>
				{/* <Legend /> */}
				<Button className={tableStyles.button} type="primary" loading={loading} onClick={loadFeatures}>
					<FormattedMessage id="Refresh" />
				</Button>
				<Button className={tableStyles.button} type="primary" onClick={() => changeFeature(0)}>
					<FormattedMessage id="Add Feature" />
				</Button>
				{isSuper && (
					<Popconfirm
						title={
							<FormattedMessage id="Do you want to delete?" values={{ length: selectedRowKeys.length }} />
						}
						onConfirm={deleteFeatures}
						okText={<FormattedMessage id="Yes" />}
						cancelText={<FormattedMessage id="No" />}
					>
						<Button
							className={classNames(tableStyles.button)}
							type="danger"
							disabled={selectedRowKeys.length < 1 || selectedRowKeys.length > 5}
						>
							<FormattedMessage id="Delete" />
						</Button>
					</Popconfirm>
				)}
				<ExportFeatures />
				<FeaturesBaseTable
					rowKey="id"
					columns={[
						{
							title: 'Code',
							dataIndex: 'code',
							filter: true,
							render: (text, record) => (
								<div>
									&nbsp; &nbsp;
									<span>{text}</span>
									&nbsp; &nbsp;
									<Button
										className={classNames(styles.edit_button)}
										onClick={() => changeFeature(record.id)}
									>
										<Icon type="edit" />
									</Button>
								</div>
							),
						},
						{
							title: 'Active',
							dataIndex: 'enable',
							render: text => {
								if (text === 1) {
									return (
										<div>
											<Tag color="green">
												<FormattedMessage id="Active" />
											</Tag>
										</div>
									);
								}
								return (
									<div>
										<Tag color="volcano">
											<FormattedMessage id="Inactive" />
										</Tag>
									</div>
								);
							},
							sorter: true,
						},
						{
							title: 'Product',
							dataIndex: 'product',
							render: text => {
								let display;
								products.map(function(prod) {
									if (text === prod.code) {
										display = (
											<div>
												<span>{prod.name}</span>
											</div>
										);
									}
								});
								return display;
							},
							sorter: true,
							filter: true,
							filterOps: prodOps,
							multiSelect: true,
						},
						{
							title: 'Type',
							dataIndex: 'contract_type',
							render: (text, record) => {
								return (
									<div>
										<span>{contractTypes[record.contractType]}</span>
									</div>
								);
							},
							sorter: true,
							globalRegister: 'contracttypes',
							filter: true,
							multiSelect: true,
						},
						...columnConfig.COMMON,
					]}
					onRow={record => {
						if (
							moment(record.expirationDate, DATE_SERVER_FORMAT)
								.subtract(60, 'days')
								.isBefore(moment())
						) {
							return {
								className: styles.highlighted,
							};
						}
						return {};
					}}
					dataSource={feature}
					loadDataSource={loadFeatures}
					loading={loading}
					setParams={setParams}
					selectedRowKeys={selectedRowKeys}
					onRowSelection={onRowSelection}
					maxRowSelection={5}
					totalRecords={totalRecords}
				/>
			</Layout.Content>
			<Modal
				visible={editedFeature !== null}
				footer={null}
				onCancel={() => changeFeature(null)}
				title={<FormattedMessage id={find(feature, { id: editedFeature }) ? 'Edit Feature' : 'Add Feature'} />}
			>
				<AddEditFeature
					feature={find(feature, { id: editedFeature })}
					changeFeature={changeFeature}
					loadFeatures={loadFeatures}
					products={products}
					loadProducts={loadProducts}
				/>
			</Modal>
		</Layout>
	);
};

export default compose(
	withState('selectedRowKeys', 'onRowSelection', []),
	withState('editedFeature', 'changeFeature', null),
	withState('loading', 'setLoading', true),
	withState('feature', 'setFeatures', []),
	withState('totalRecords', 'setTotalRecords', 0),
	withState('params', 'setParams'),
	withState('products', 'setProducts', []),
	withState('prodOps', 'setProdOps', []),
	connect(
		state => ({
			isSuper: hasRoleLevel(state.session.scopes, ROLES.SUPER),
		}),
		(dispatch: Function, props) => ({
			loadFeatures: () => {
				dispatch(
					request({
						method: 'GET',
						url: URL.GET_FEATURES,
						setLoading: props.setLoading,
						params: {
							...props.params,
						},
					})
				).then(data => {
					props.setFeatures(data.data);
					props.setTotalRecords(data.recordsFiltered);
				});
			},
		})
	),
	connect(null, (dispatch: Function, props) => ({
		deleteFeatures: () => {
			dispatch(
				request({
					method: 'DELETE',
					url: URL.DELETE_FEATURE,
					setLoading: props.setLoading,
					params: {
						ids: props.selectedRowKeys,
					},
				})
			).then(() => {
				props.loadFeatures();
				props.onRowSelection([]);
			});
		},
	})),
	connect(null, (dispatch: Function, props) => ({
		loadProducts: () => {
			dispatch(
				request({
					method: 'GET',
					url: URL.GET_PRODUCT,
					setLoading: props.setLoading,
					params: {},
				})
			).then(data => {
				const options = data
					.map(p => {
						return { value: p.code, text: p.name };
					})
					.filter(p => p.value !== undefined && p.value !== '');
				options.sort(productComparator('text'));
				props.setProdOps(options);
				props.setProducts(data);
			});
		},
	})),
	lifecycle({
		componentDidMount() {
			this.props.setParams({
				start: 0,
				length: 20,
			});
			setTimeout(() => {
				this.props.loadFeatures();
				this.props.loadProducts();
			});
		},
	})
)(Features);
