// @flow
import type { InjectIntlProvidedProps } from 'react-intl';
import { injectIntl } from 'react-intl';
import handleFormatMessage from 'utils/handleFormatMessage';

export const CarbonationDepth = ({ log, intl }: EquotipLogbookEntryProps & InjectIntlProvidedProps) =>
	handleFormatMessage('Proceq.LogbookPropertyCarbonationDepthChangedFromTo', intl.formatMessage, [
		log.content.oldCarbonationDepth,
		log.content.updatedCarbonationDepth,
	]);

export default injectIntl(CarbonationDepth);
