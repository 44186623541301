// @flow
import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { getLabelFromGlobalDataKey } from 'utils/registryHelper';
import Logo from './Logo';

export const Header = ({ version, productFamily }: { version: string, productFamily: ProductFamily }) => (
	<Fragment>
		<Logo />
		<h1>{getLabelFromGlobalDataKey('availproductsfull', productFamily.toUpperCase())}</h1>
		<br />
		<h1>
			<FormattedMessage id="Export HTML Format Version" />
			{`: ${version}`}
		</h1>
	</Fragment>
);

export default Header;
