import React from 'react';
import moment from 'moment';
import { formatDateTimeNoSecsToLocale } from 'utils/dateHandler';
import { isLive } from 'apis/urls';
import styles from '../shared/SmarterTable.styl';

// const renderTime = text => (
// 	<span className={styles.date_cell}>{formatDateTimeNoSecsToLocale(moment.utc(text).local())}</span>
// );
const renderTimestamp = text => (
	<span className={styles.date_cell}>
		{formatDateTimeNoSecsToLocale(moment.utc(text, ['YYYYMMDD__HHmmss', 'YYYY-MM-DD']).local())}
	</span>
);

const commonColumns = [
	// {
	// 	title: 'App.DongleUpdated',
	// 	dataIndex: 'updated',
	// 	sorter: true,
	// 	render: renderTime,
	// },
	{
		title: 'App.DongleFtpTimestamp',
		dataIndex: 'ftpTimeStamp',
		sorter: true,
		render: renderTimestamp,
	},
];

const columnConfig = {
	GPR: [
		{
			title: 'App.ContractsWifiMAC',
			dataIndex: 'hardwareID',
			sorter: true,
			filter: 'hardwareID',
		},
		{
			title: 'App.Product',
			dataIndex: 'productType',
			globalRegister: isLive ? 'wifiproductslive' : 'wifiproductsfull',
			filter: true,
			multiSelect: true,
		},
		...commonColumns,
	],
	EQUOTIP: [
		{
			title: 'App.HardwareId',
			dataIndex: 'hardwareID',
			sorter: true,
			filter: 'hardwareID',
		},
		{
			title: 'Proceq.ExportColumnProbeSN',
			dataIndex: 'serialNo',
			sorter: true,
			filter: 'serialNo',
		},
		{
			title: 'App.Product',
			dataIndex: 'productType',
			globalRegister: isLive ? 'bluetoothproductslive' : 'bluetoothproductsfull',
			filter: true,
			multiSelect: true,
		},
		...commonColumns,
	],
};

export default columnConfig;
