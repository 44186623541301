// @flow
import React, { Component } from 'react';
import { compose, withState } from 'recompose';
import { Layout as AntLayout, Icon, Alert } from 'antd';
import { FormattedMessage } from 'react-intl';
import { isLive, serverType, serverTypeLabel } from 'apis/urls';
import Header from './Header';
import Navigation from './Navigation';
import styles from './Layout.styl';
import './Overrides.css';
import Footer from './Footer';
import { history } from '../../routes';

const onLogoClick = () => history.push('/');

type Props = {
	path: string,
	collapsed: boolean,
	setCollapse: Function,
	children: any,
};

export class NewLayout extends Component<
	Props,
	{
		error: boolean,
	}
> {
	constructor() {
		super();

		this.state = {
			error: false,
		};
	}

	componentDidCatch(error: Error, errorInfo: Object) {
		this.setState({
			error: true,
		});
		if (!window.Sentry) {
			return;
		}

		window.Sentry.withScope(scope => {
			scope.setExtras(errorInfo);
			window.Sentry.captureException(error);
		});
	}

	componentWillReceiveProps(nextProps: Props) {
		if (this.props.path !== nextProps.path) {
			this.setState({ error: false });
		}
	}

	render() {
		const { collapsed, setCollapse, children } = this.props;

		return (
			<AntLayout>
				<AntLayout.Sider
					className={styles.sidebar}
					breakpoint="lg"
					collapsible
					collapsed={collapsed}
					onCollapse={collapsedState => setCollapse(collapsedState)}
					trigger={null}
					width={220}
				>
					<div role="button" className={styles.top_container}>
						{!collapsed && (
							<h1 className={styles.brand} onClick={onLogoClick}>
								<FormattedMessage id="Proceq.LayoutSidebarHeading" />
							</h1>
						)}
						<Icon
							className={styles.trigger}
							type={collapsed ? 'menu-unfold' : 'menu-fold'}
							onClick={() => setCollapse(!collapsed)}
						/>
					</div>
					{!isLive && <div className={styles.dev_banner}>{collapsed ? serverType : serverTypeLabel}</div>}
					<Navigation collapsed={collapsed} />
					<Footer collapsed={collapsed} />
				</AntLayout.Sider>
				<AntLayout>
					<Header />
					<div className={styles.body}>
						{this.state.error ? (
							<React.Fragment>
								<Alert
									message="The application encountered a javascript error on this page."
									type="error"
								/>
								{window.Sentry && (
									<a onClick={() => window.Sentry.showReportDialog()}>
										<FormattedMessage id="App.SentryReportFeedback" />
									</a>
								)}
							</React.Fragment>
						) : (
							children
						)}
					</div>
				</AntLayout>
			</AntLayout>
		);
	}
}

export default compose(withState('collapsed', 'setCollapse', window.matchMedia('(max-width: 992px)').matches))(
	NewLayout
);
