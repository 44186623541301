// @flow
import { WORKSPACE_URL } from '../../routes/config';

const RedirectToEagleWorkspace = () => {
	const { pathname = '', search = '' } = location;

	const url = `${WORKSPACE_URL}${pathname}${search}`;
	window.location.replace(url);

	return null;
};

export default RedirectToEagleWorkspace;
