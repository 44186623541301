// @flow
import { get } from 'lodash';

export const ROLES = Object.freeze({
	SUPER: 'super',
	ADMIN: 'admin',
	PO: 'po',
	BU: 'bu',
	DEV: 'dev',
	SALES_AND_FINANCE: 'sales_and_finance',
	USER: 'user',
	ANALYST: 'analyst',
});

// Temporary function for BUs. Will be removed for https://jira.screeningeagle.com/browse/LC-1236
export const isBUOrHigher = (roles: UserRole[]) => {
	return hasOneOfRoleLevel(roles, [ROLES.SUPER, ROLES.ADMIN, ROLES.PO, ROLES.BU]);
};

/**
 * given a user's roles, see if he meets the required role
 */
export const hasRoleLevel = (roles: UserRole[], role: UserRole): boolean => {
	return expandRolesForCheckboxes(roles).has(role);
};

export const hasOneOfRoleLevel = (roleScope: UserRole[], checkedRoles: UserRole[]): boolean => {
	const expandRoles = expandRolesForCheckboxes(roleScope);
	for (const role of checkedRoles) {
		if (expandRoles.has(role)) {
			return true;
		}
	}

	return false;
};

export const canUserAccessThisPage = (userRoles: UserRole[], pageRoles: UserRole[]): boolean => {
	let canAccess = false;

	if (userRoles.includes('super')) {
		return true;
	}

	if (pageRoles.includes('user')) {
		userRoles.forEach(userRole => {
			if (userRole !== 'user') {
				canAccess = true;
			}
		});
		return canAccess;
	}

	pageRoles.forEach(pageRole => {
		if (userRoles.includes(pageRole)) {
			canAccess = true;
		}
	});

	return canAccess;
};

export const hasProduct: (userProducts: UserProducts, products?: ProductCode[]) => boolean = (
	userProducts,
	products
) => {
	if (!products) {
		return true;
	}

	let flag = false;
	products.forEach(product => {
		if (get(userProducts, [product, 'numberCreated']) > 0) {
			flag = true;
		}
	});

	return flag;
};

/**
 * shorten a user's roles into the minimum required.
 * useful for display
 */
export const normalizeRolesForDisplay = (roles: UserRole[]): UserRole[] => {
	const roleSet = expandRolesForCheckboxes(roles);
	if (roleSet.has(ROLES.SUPER)) {
		return [ROLES.SUPER, ROLES.USER];
	}

	if (roleSet.has(ROLES.ADMIN)) {
		return [ROLES.ADMIN, ROLES.USER];
	}

	const normalizedRoles = [];
	if (roleSet.has(ROLES.PO)) {
		normalizedRoles.push(ROLES.PO);
	} else if (roleSet.has(ROLES.BU)) {
		normalizedRoles.push(ROLES.BU);
	} else if (roleSet.has(ROLES.SALES_AND_FINANCE)) {
		normalizedRoles.push(ROLES.SALES_AND_FINANCE);
	}

	if (roleSet.has(ROLES.DEV)) {
		normalizedRoles.push(ROLES.DEV);
	}

	if (roleSet.has(ROLES.ANALYST)) {
		normalizedRoles.push(ROLES.ANALYST);
	}

	normalizedRoles.push(ROLES.USER);
	return normalizedRoles;
};

export const expandRolesForCheckboxes = (roles: UserRole[]): Set<UserRole> => {
	roles = roles || [];
	const expandRolesConfig = {
		[ROLES.SUPER]: [ROLES.ADMIN, ROLES.PO, ROLES.BU, ROLES.DEV, ROLES.SALES_AND_FINANCE, ROLES.ANALYST],
		[ROLES.ADMIN]: [ROLES.PO, ROLES.BU, ROLES.DEV, ROLES.SALES_AND_FINANCE, ROLES.ANALYST],
		[ROLES.PO]: [ROLES.BU],
	};

	const expandRoles = new Set(roles);

	roles.forEach(role => {
		if (!expandRolesConfig[role]) {
			return;
		}
		expandRolesConfig[role].forEach(r => {
			expandRoles.add(r);
		});
	});

	expandRoles.add(ROLES.USER);
	return expandRoles;
};
