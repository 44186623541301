// @flow
import React from 'react';
import ConvertedUnits from '../../../shared/ConvertedUnits';

export const ObjectMarker = ({ log, product, isMetric, scanType }: GPRLogbookEntryProps) => {
	const { oldStart, oldEnd, newStart, newEnd } = log.content;

	return (
		<ConvertedUnits
			id="App.LogbookChangeCropArea"
			unitId={`${product}.Logbook Panel.Focus area`}
			scanType={scanType}
			isMetric={isMetric}
			values={[oldStart, oldEnd, newStart, newEnd]}
		/>
	);
};

export default ObjectMarker;
