// @flow
/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import moment from 'moment';
import { DatePicker, Button, Icon } from 'antd';
import type { ValidationRule } from 'antd';
import { FormattedMessage } from 'react-intl';
import { DATE_SERVER_FORMAT } from 'utils/dateHandler';
import { quickRanges, saveDateRange } from 'constants/dateRange';

export const dateRangeOptional: $PropertyType<ValidationRule<{ from: string, to: string }>, 'validator'> = (
	rule,
	value,
	callback
) => {
	if ((!value.from && value.to) || (value.from && !value.to)) {
		callback(rule.message);
	} else {
		callback();
	}
};

export const dateRangeRequired: $PropertyType<ValidationRule<{ from: string, to: string }>, 'validator'> = (
	rule,
	value,
	callback
) => {
	if (value.from && value.to) {
		callback();
	} else {
		callback(rule.message);
	}
};

export class DateRange extends Component<{
	value?: Object,
	onChange?: Function,
	saveDate?: boolean,
}> {
	render() {
		const { value, onChange, saveDate = true, ...props } = this.props;
		const changeHandler =
			saveDate && onChange
				? (...args) => {
						onChange(...args);
						saveDateRange(...args);
				  }
				: onChange;

		if (!value || !changeHandler) {
			return null;
		}

		const renderRangeButton = (range, label) => {
			const [from, to] = range();
			return (
				<React.Fragment key={label}>
					<Button
						size="small"
						type="ghost"
						disabled={from.isSame(value.from, 'day') && to.isSame(value.to, 'day')}
						onClick={() => {
							changeHandler({
								from,
								to,
							});
						}}
					>
						{label}
					</Button>
					&nbsp;
				</React.Fragment>
			);
		};

		return (
			<React.Fragment>
				<div>
					<DatePicker
						value={value.from}
						format={DATE_SERVER_FORMAT}
						disabledDate={start => {
							if (!start || !value.to) {
								return false;
							}
							return start.isAfter(value.to, 'day');
						}}
						onChange={date =>
							changeHandler({
								from: date && date.startOf('day'),
								to: value.to,
							})
						}
						{...props}
					/>
					&nbsp; &nbsp;
					<Icon type="arrow-right" />
					&nbsp; &nbsp;
					<DatePicker
						value={value.to}
						format={DATE_SERVER_FORMAT}
						disabledDate={end => {
							if (!end || !value.from) {
								return false;
							}
							return end.isBefore(value.from, 'day');
						}}
						onChange={date =>
							changeHandler({
								from: value.from,
								to: date && date.endOf('day'),
							})
						}
						{...props}
					/>
				</div>
				<FormattedMessage id="App.DateRange.QuickRanges" />
				<div>
					{quickRanges.map(({ length, unit, range }) =>
						renderRangeButton(range, moment.duration(length, unit).humanize())
					)}
				</div>
			</React.Fragment>
		);
	}
}

export default DateRange;
