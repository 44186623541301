// @flow
import React from 'react';
import { Button, message, Spin, Form, Row } from 'antd';
import { FormattedMessage, injectIntl } from 'react-intl';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { request } from 'actions/ajax';
import URL from 'apis/urls';
import classNames from 'classnames';
import { getInitialRange } from 'constants/dateRange';
import { getFileNameFromContentDisposition } from 'utils/downloadHelper';
import layoutStyles from '../layout/Layout.styl';
import styles from './Usage.styl';
import DateRange from '../shared/DateRange';
import SmarterSelect from '../shared/SmarterFormItems/Select';

type Props = {
	intl: {
		formatMessage: (params: { id: string }) => string,
	},
	dispatch: Function,
	form: any,
};

const Usage = ({ intl, dispatch, form }: Props) => {
	const [loading, setLoading] = React.useState(false);

	const generateReport = (e: SyntheticEvent<HTMLFormElement>) => {
		e.preventDefault();
		form.validateFields((err, values) => {
			if (err) {
				return;
			}

			setLoading(true);
			dispatch(
				request({
					method: 'POST',
					url: URL.USAGE_EXPORT,
					params: {
						from: values.dates.from.unix(),
						to: values.dates.to.unix(),
						product: values.product,
					},
					returnResponseHeaders: true,
					options: {
						responseType: 'blob',
					},
				})
			)
				.then(response => {
					window.fileDownload(
						response.data,
						getFileNameFromContentDisposition(response.headers['content-disposition']),
						response.headers['content-type']
					);
					message.success(intl.formatMessage({ id: 'App.ReportGeneratedSuccess' }));
				})
				.catch(() => {
					message.error(intl.formatMessage({ id: 'App.ReportGenerationFailed' }));
				})
				.finally(() => {
					setLoading(false);
				});
		});
	};

	return (
		<div className={classNames(layoutStyles.container, styles.usage_container)}>
			<h2 className="label-statistics">
				<FormattedMessage id="App.NavAnalyticsUsage" />
			</h2>
			<Spin spinning={loading}>
				<Form onSubmit={generateReport}>
					<Row gutter={[16, 16]}>
						<Form.Item label={<FormattedMessage id="App.Product.Family" />}>
							{form.getFieldDecorator('product', {
								initialValue: undefined,
							})(
								<SmarterSelect
									className={styles.select}
									globalRegister="availproductsappfullregional"
									disabled={loading}
								/>
							)}
						</Form.Item>
					</Row>
					<Row gutter={[16, 16]}>
						<Form.Item label={<FormattedMessage id="App.DashboardDateRange" />}>
							{form.getFieldDecorator('dates', {
								initialValue: getInitialRange(),
								rules: [{ required: true, message: intl.formatMessage({ id: 'App.SelectDateRange' }) }],
							})(<DateRange />)}
						</Form.Item>
					</Row>
					<Row>
						<Button type="primary" htmlType="submit" loading={loading}>
							<FormattedMessage id="App.GenerateReport" />
						</Button>
					</Row>
				</Form>
			</Spin>
		</div>
	);
};

const EnhancedUsage = compose(
	injectIntl,
	connect(null, dispatch => ({ dispatch })),
	Form.create({})
)(Usage);

export default EnhancedUsage;
