// @flow
import React, { Component } from 'react';
import classNames from 'classnames';
import moment from 'moment';

import { renderLogbookTime } from 'utils/dateHandler';
import styles from '../measurements/LogEvent/LogEvent.styl';
import logIcon from '../measurements/LogEvent/icoSelfLog.png';
import AuditElementInfo from './AuditElementInfo';
import AuditElement from './AuditElement';

export class AuditEvent extends Component<
	{
		highlighted: boolean,
		showDate: boolean,
	} & AuditItemProps,
	{
		icon: ?string,
		color: ?string,
	}
> {
	state = {
		icon: null,
		color: 'blue',
	};

	render() {
		const {
			documentType,
			isProductSaas,
			logFirst,
			logSecond,
			products,
			showDate,
			highlighted,
			...props
		} = this.props;
		const date = logSecond.updateTime;
		return (
			<li
				className={classNames({
					[styles.message]: true,
					message: true,
				})}
			>
				{showDate && <div className={styles.date_container}>{moment(date).format('D MMM YYYY')}</div>}
				<div className={styles.content_container}>
					<div>
						<div>
							<time className={styles.time} dateTime={date}>
								{renderLogbookTime(date)}
							</time>
						</div>
						{<AuditElement logSecond={logSecond} documentType={documentType} {...props} />}
					</div>
					{this.state.color && <div className={classNames(styles.icon, styles[this.state.color])} />}
					{this.state.icon && (
						<div className={classNames(styles.icon, styles.icon_with_logo)}>
							<img alt="icon" className={styles.logo} src={logIcon} />
						</div>
					)}
				</div>
				{
					<div
						className={classNames({
							log_event: true,
							[styles.log_event]: true,
							[styles.highlighted]: highlighted,
						})}
					>
						<AuditElementInfo
							logFirst={logFirst}
							logSecond={logSecond}
							products={products}
							documentType={documentType}
							isProductSaas={isProductSaas}
						/>
					</div>
				}
			</li>
		);
	}
}

export default AuditEvent;
