const proceqDomainsConfig = {
	local: {
		protocol: 'http',
		baseDomain: 'localhost:8088',
		mgtSubdomain: '',
		regionSubdomains: { SG: '', EU: '', JP: '', US: '' },
	},
	dev: {
		protocol: 'https',
		baseDomain: 'tectusdreamlab.com',
		mgtSubdomain: 'pq-dev',
		regionSubdomains: { SG: 'pq-sg', EU: 'pq-eu', JP: 'pq-jp', US: 'pq-us', CN: 'pq-cn' },
	},
	stg: {
		protocol: 'https',
		baseDomain: 'livedev2.proceq.com',
		mgtSubdomain: 'mgt',
		regionSubdomains: { SG: 'sg', EU: 'eu', JP: 'jp', US: 'us', CN: { domain: 'mgt.livedev2.proceq.cn.com' } },
	},
	prod: {
		protocol: 'https',
		baseDomain: 'live2.proceq.com',
		mgtSubdomain: 'mgt',
		regionSubdomains: { SG: 'sg', EU: 'eu', JP: 'jp', US: 'us', CN: { domain: 'mgt.live2.proceq.cn.com' } },
	},
};

const generateHosts = serverType => {
	if (!proceqDomainsConfig[serverType]) {
		throw new Error(`serverType: ${serverType} is not configured in src/apis/proceq.js`);
	}
	const { protocol, baseDomain, mgtSubdomain, regionSubdomains } = proceqDomainsConfig[serverType];
	const hosts = {};
	hosts.MGT = `${protocol}://${mgtSubdomain}.${baseDomain}`;
	Object.keys(regionSubdomains).forEach(regionCode => {
		const region = regionSubdomains[regionCode];
		if (typeof region === 'object' && region.domain) {
			hosts[regionCode.toUpperCase()] = `${protocol}://${region.domain}`;
		} else {
			hosts[regionCode.toUpperCase()] = `${protocol}://${region}.${baseDomain}`;
		}
	});

	return hosts;
};

export const HOSTS = generateHosts(process.env.SERVER_TYPE || 'local');
export const APIS = {
	REGIONAL_TOUCH_MEASUREMENT: '/api/regional/touchmeasurement',
};
