module.exports = {
	shortlang: 'tr',
	lang: 'tr',
	messages: {
		'Proceq.ErrorWrongUsername': 'Geçersiz e-posta girildi',
		'Proceq.ErrorCreationNotAllowed': 'Yeni hesapların oluşturulması devre dışı bırakıldı',
		'Proceq.ErrorWrongPassword': 'Geçersiz şifre girildi',
		'Proceq.ErrorWrongFirstName': 'Geçersiz ad girildi',
		'Proceq.ErrorWrongLastName': 'Geçersiz soyadı girildi',
		'Proceq.ErrorUsernameAlreadyExists': 'Aynı e-postaya sahip bir hesap zaten var',
		'Proceq.ErrorDB': 'Bir veritabanı işlemi yapılırken hata',
		'Proceq.InvalidUsername': 'Kullanıcı hesabı bulunamadı',
		'Proceq.AlreadyMultipleForgot': 'Birden fazla şifre sıfırlaması zaten istenmiş',
		'Proceq.InvalidNewPassword': 'Geçersiz yeni şifre girildi',
		'Proceq.InvalidResetCode': 'Geçersiz şifre sıfırlama kodu veya süresi dolmuş',
		'Proceq.InvalidOldPassword': 'Geçersiz eski şifre girildi',
		'Proceq.InvalidAccessRights': 'Oturumun süresi doldu veya kullanıcının bu işlemi yapmasına izin verilmez',
		'Proceq.ErrorNoAccountType': 'Hesap türü girilmedi',
		'Proceq.ErrorNoUsername': 'E-posta girilmedi',
		'Proceq.InvalidCountry': 'Geçersiz ülke girildi',
		'Proceq.ErrorCreatingUser': 'Yeni kullanıcı oluşturmada hata',
		'Proceq.InvalidID': 'Geçersiz ID girildi',
		'Proceq.InvalidTransactionID': "Geçersiz işlem ID'si",
		'Proceq.InvalidFileContent': 'Dosya içeriğini okumada hata',
		'Proceq.InvalidTypeEntry': 'Geçersiz giriş türü',
		'Proceq.InvalidContractID': 'Sözleşme geçersiz veya süresi dolmuş',
		'Proceq.InvalidExistingID': 'ID zaten var olduğu için oluşturulamıyor',
		'Proceq.InvalidDataSeriesType': 'Geçersiz seri türü',
		'Proceq.InvalidMetaDataContent': 'Geçersiz üst veri içeriği',
		'Proceq.ErrorFileIsLocked': 'Bir veya daha fazla dosya kilitli. Daha sonra tekrar deneyin',
		'Proceq.MissingAttachmentFiles': 'Ek dosyaları sunucuda bulunamadı',
		'Proceq.InvalidFlagType': 'Geçersiz veya eksik işaret türü',
		'Proceq.MissingDateTypeFields': 'Seri için istenen alanlar eksik',
		'Proceq.InvalidFolderID': "Geçersiz klasör ID'si",
		'Proceq.InvalidDataSeriesName': 'Geçersiz seri adı',
		'Proceq.ErrorDateFormat': 'Geçersiz tarih formatı girildi',
		'Proceq.ErrorDataFormat': 'Seriden veri ayrıştırılamıyor',
		'Proceq.InvalidDownloadID': 'Geçersiz indirme belirteci veya zaten var',
		'Proceq.InvalidVersionNr': 'Geçersiz sürüm numarası girildi',
		'Proceq.ErrorDataMissing': 'Veriler arşiv sisteminden geri yüklenemiyor',
		'Proceq.ErrorSavingFile': 'Sunucu dosyayı kaydedemedi',
		'Proceq.ErrorInsertDB': "DB'ye eklemede hata",
		'Proceq.InvalidFolderName': 'Geçersiz klasör adı girildi',
		'Proceq.InvalidActionID': 'URL bağlantısı geçersiz veya bitmiş',
		'Proceq.AlreadyConfirmed': 'Hesap zaten doğrulanmış',
		'Proceq.InvalidActionType': 'İstenen işlem yapılamıyor',
		'Proceq.ErrorGeneratingChart': 'Grafikler oluşturmada hata',
		'Proceq.InvalidSerialID': "Geçersiz Seri ID'si",
		'Proceq.InvalidHardwareID': "Geçersiz Donanım ID'si",
		'Proceq.InvalidEntityID': "Geçersiz nesne ID'si veya silinmiş",
		'Proceq.InvalidEntityType': 'Geçersiz nesne türü',
		'Proceq.InvalidEntityVerNr': 'Geçersiz nesne sürüm numarası',
		'Proceq.ErrorTransactionLocked': 'Nesne kilitli ve değiştirilemiyor',
		'Proceq.InvalidDateValue': 'Tarih ayrıştırılamıyor',
		'Proceq.MissingFields': 'İstenen bir veya daha fazla alan eksik',
		'Proceq.ErrorColumnValue': 'Bir veya daha fazla alan için girilen değer yanlış',
		'Proceq.MissingUidDBValue': "Eksik giriş ID'si",
		'Proceq.ErrorFileID': "Geçersiz dosya ID'si girildi",
		'Proceq.ErrorInsertDownload': 'İndirme oluşturmada hata',
		'Proceq.InvalidAppVersion': 'Geçerli uygulama sürümünüz artık desteklenmiyor. Lütfen güncelleyin',
		'Proceq.MissingUserInfo': 'Eksik kullanıcı bilgisi',
		'Proceq.InvalidAccessToken': 'Geçersiz veya süresi dolmuş oturum belirteci',
		'Proceq.ErrorGeneratingCSV': 'CSV aktarma dosyası oluşturmada hata',
		'Proceq.ErrorGeneratingExport': 'Aktarma dosyası oluşturmada hata',
		'Proceq.MissingParams': 'İstenen parametrelerden biri veya daha fazlası eksik',
		'Proceq.SerialNotFound': 'Bu cihaz için sözleşme bulunamadı',
		'Proceq.StandardEnumJapan': 'Japonya',
		'Proceq.StandardEnumDefault': 'Varsayln',
		'Proceq.MaterialEnumIndependent': 'Malzemeden bağımsız',
		'Proceq.MaterialEnumSteelAndCastSteel': 'Çelik ve döküm çeliği',
		'Proceq.MaterialEnumWorkToolSteel': 'İş takım çeliği',
		'Proceq.MaterialEnumStainlessSteel': 'Paslanmaz çelik',
		'Proceq.MaterialEnumGrayCastIron': 'Gri dökme demir (GG)',
		'Proceq.MaterialEnumNodularCastIron': 'Sfero dökme demir (GGG)',
		'Proceq.MaterialEnumCastAlluminiumAlloys': 'Döküm alüminyum alaşımlar',
		'Proceq.MaterialEnumBrassCopperZincAlloys': 'Pirinç - Bakır/Çinko alaşımları',
		'Proceq.MaterialEnumBronze': 'Bronz',
		'Proceq.MaterialEnumWroughtCopperAlloys': 'Dövme bakır alaşımlar',
		'Proceq.MaterialEnumTitanium': 'Titanyum',
		'Proceq.ScaleEnumCustom': 'Özel',
		'Proceq.ScaleEnumPenetrationDepth': 'Penetrasyon derinliği',
		'Proceq.CompanySizeMicro': '1-9 Çalışan',
		'Proceq.CompanySizeSmall': '10-49 Çalışan',
		'Proceq.CompanySizeMedium': '50-249 Çalışan',
		'Proceq.CompanySizeLarge': '>249 Çalışan',
		'Proceq.SalutationMr': 'Bay',
		'Proceq.SalutationMs': 'Byn.',
		'Proceq.SalutationDr': 'Dr.',
		'Proceq.SalutationProf': 'Prof.',
		'Proceq.CategoryAerospace': 'Havacılık',
		'Proceq.CategoryAutomotive': 'Otomotiv',
		'Proceq.CategoryChemicalProcessing': 'Kimyasal İşleme',
		'Proceq.CategoryConstructionBuilding': 'İnşaat/Yapı',
		'Proceq.CategoryConsultantTestLabsEngineer': 'Müşavir/Test Lab./Mühendis',
		'Proceq.CategoryEducationInstituteRD': 'Eğitim/Kurum/Ar-Ge',
		'Proceq.CategoryFilmProducerConverter': 'Film Üreticisi/Dönüştürücüsü',
		'Proceq.CategoryGeologyRocktesters': 'Jeoloji/Kayaç Testleri',
		'Proceq.CategoryMachineryToolingEquipment': 'Makine/Takım/Ekipman',
		'Proceq.CategoryMassTransportation': 'Toplu Taşıma',
		'Proceq.CategoryMetalWorks': 'Metal İşleri',
		'Proceq.CategoryOilAndGas': 'Petrol & Gaz',
		'Proceq.CategoryOthers': 'Diğer',
		'Proceq.CategoryPaperProducerConverter': 'Kağıt Üreticisi/Dönüştürücüsü',
		'Proceq.CategorySalesChannel': 'Satış Kanalı',
		'Proceq.ErrorWrongLoginCredentials': 'Girdiğiniz kullanıcı adı veya şifre hatalı',
		'Proceq.ErrorEmailNotVerified': 'E-posta henüz doğrulanmadı',
		'Proceq.ServerError': 'Sunucu Hatası',
		'Proceq.TooManyActionEmailRequests': 'Çok fazla istek. Lütfen e-postaların posta kutunuza ulaşmasını bekleyin',
		'Proceq.ErrorServerUnderMaintenance': 'Sunucu bakımda',
		'Proceq.TableDataSeriesName': 'Ad',
		'Proceq.TableCreatedOn': 'Oluşturma:',
		'Proceq.TableModifiedOn': 'Değişiklik:',
		'Proceq.TableCreatedBy': 'Oluşturan:',
		'Proceq.TableNumMean': 'Orta.',
		'Proceq.TableNumTotal': 'Toplam',
		'Proceq.TableNumStandardDeviation': 'Stan.sap',
		'Proceq.TableImpactDevice': 'Prob',
		'Proceq.TableImpactDeviceHammer': 'Çekiç',
		'Proceq.TableMaterial': 'Malzeme',
		'Proceq.TableScaleUnit': 'Ölçek Birimi',
		'Proceq.TableActionRefresh': 'Yenile',
		'Proceq.TableActionExpand': 'Genişlet',
		'Proceq.TableActionCollapse': 'Daralt',
		'Proceq.TableActionExportAsPDF': 'PDF olarak aktar',
		'Proceq.TableActionExportAsCSV': 'CSV olarak aktar',
		'Proceq.TableActionExportAsJSON': 'JSON olarak aktar',
		'Proceq.SearchOperationEquals': 'Eşittir',
		'Proceq.SearchOperationContains': 'Kapsar',
		'Proceq.SearchOperationDifferent': 'Farklı',
		'Proceq.SearchOperationGreaterOrEqual': 'Büyük eşittir',
		'Proceq.SearchOperationGreater': 'Büyüktür',
		'Proceq.SearchOperationLesserOrEqual': 'Küçük eşittir',
		'Proceq.SearchOperationLesser': 'Küçüktür',
		'Proceq.ExportFieldExportDate': 'Aktarma Tarihi',
		'Proceq.ExportFieldLimits': 'Sınırlar',
		'Proceq.ExportFieldHigh': 'Yüksk',
		'Proceq.ExportFieldLow': 'Düşk',
		'Proceq.ExportFieldOff': 'Kapat',
		'Proceq.ExportFieldLowerLimit': 'Alt Sınır',
		'Proceq.ExportFieldUpperLimit': 'Üst Sınır',
		'Proceq.ExportFieldReadings': 'Okumalar',
		'Proceq.ExportFieldStandardDeviation': 'Standart sapma',
		'Proceq.ExportFieldRange': 'Menzil',
		'Proceq.ExportFieldRelativeSpan': 'Göreli Açıklık',
		'Proceq.ExportFieldMaximum': 'Maksimum',
		'Proceq.ExportFieldMinimum': 'Minimum',
		'Proceq.ExportFieldAverage': 'Ortalama',
		'Proceq.ExportFieldUnit': 'Birim',
		'Proceq.ExportFieldName': 'Ad',
		'Proceq.ExportFieldMeasurementDate': 'Ölçüm Tarihi',
		'Proceq.ExportFieldLocationLatitude': 'Konum Enlemi',
		'Proceq.ExportFieldLocationLongitude': 'Konum Boylamı',
		'Proceq.ExportFieldProbeType': 'Prob Türü',
		'Proceq.ExportFieldProbeSerialNumber': 'Prob Seri Numarası',
		'Proceq.ExportFieldProbeBTSerialNumber': 'Prob Bluetooth Seri Numarası',
		'Proceq.ExportFieldProbeLastVerificationDate': 'Prob Son Doğrulama Tarihi',
		'Proceq.ExportFieldPrimaryScale': 'Birincil Ölçek',
		'Proceq.ExportFieldSecondaryScale': 'İkincil Ölçek',
		'Proceq.ExportFieldMaterial': 'Malzeme',
		'Proceq.ExportFieldStandard': 'Standart',
		'Proceq.ExportFieldNoConversion': 'Çevirme Yok',
		'Proceq.ExportFieldMeasurements': 'Ölçümler',
		'Proceq.ExportFieldMore': 'Çok',
		'Proceq.ExportFieldLess': 'Az',
		'Proceq.ExportFieldStatistics': 'İstatistik',
		'Proceq.ExportFieldSettings': 'Ayarlar',
		'Proceq.DataSeriesLogbook': 'Kayıt defteri',
		'Proceq.DataSeriesAllMeasurements': 'Tüm Ölçümler',
		'Proceq.DataSeriesFlagged': 'İşaretli',
		'Proceq.DataSeriesTrash': 'Çöp',
		'Proceq.BrowserNoSupportAudio': 'Tarayıcınız ses dosyalarını desteklemiyor.',
		'Proceq.EmailPasswordRecoverySubject': '**?0** için şifre değiştirme isteği',
		'Proceq.EmailPasswordRecoveryBody':
			'Sayın **?0** **?1**<br/><br/>Yeni bir şifre istediniz. Şifrenizi sıfırlamak için lütfen aşağıdaki bağlantıyı tıklayın.<br/><br/><a href="**?2**">Şifre sıfırla</a><br/><br/>Şifrenizi değiştirme isteğinde bulunmadıysanız lütfen bu mesajı yok sayın. 12 saat içinde süresi dolacaktır.<br/><br/>Proceq Ekibiniz<br/><br/>**?3**',
		'Proceq.EmailRegistrationSubject': '**?0** için kayıt tamamlama',
		'Proceq.EmailRegistrationBody':
			'Sayın **?0** **?1**<br/><br/>**?5** kaydınız için teşekkürler. Lütfen aşağıdaki bağlantıyı tıklayarak kaydınızı tamamlayın:<br/><br/><a href="**?2**">**?2**</a><br/><br/>Bu e-posta siz kayıt formunu doldurunca otomatik olarak oluşturulmuştur. Bu kaydı siz gerçekleştirmediyseniz lütfen bu mesajı yok sayın.<br/><br/> Kaydı tamamlayarak <a href="**?3**">gizlilik politikamızı</a> kabul etmiş olursunuz.<br/><br/>Proceq Ekibiniz<br/><br/>**?4**',
		'Proceq.LogbookActionNewMeasurement': 'Yeni ölçüm',
		'Proceq.LogbookPropertyCreatedSeriesInFolder': '"**?0**" serisi "**?1**" klasöründe oluşturuldu',
		'Proceq.LogbookPropertyRenamedSeriesFromTo': '"**?0**" olan seri adı "**?1**" olarak değiştirildi',
		'Proceq.LogbookPropertyMovedSeriesFromToTrash': 'Seri, "**?0**" klasöründen çöpe taşındı',
		'Proceq.LogbookPropertyMovedSeriesFromTrashTo': 'Seri, çöpten "**?0**" klasörüne taşındı',
		'Proceq.LogbookPropertyMovedSeriesFromTo': 'Seri, "**?0**" klasöründen "**?1**" klasörüne taşındı',
		'Proceq.LogbookPropertySecondaryScaleChangedFromTo': '**?0** olan ikincil ölçek **?1** olarak değiştirildi',
		'Proceq.LogbookPropertyMaterialChangedFromTo': '**?0** olan malzeme **?1** olarak değiştirildi',
		'Proceq.LogbookPropertyMaxLimitChangedFromTo': '**?0** olan üst sınır **?1** olarak değiştirildi',
		'Proceq.LogbookPropertyMinLimitChangedFromTo': '**?0** olan alt sınır **?1** olarak değiştirildi',
		'Proceq.LogbookPropertyStandardChangedFromTo': '**?0** olan standart **?1** olarak değiştirildi',
		'Proceq.LogbookPropertyTextCommentAdded': 'Metin yorumu eklendi',
		'Proceq.LogbookPropertyTextCommentDeletedOn': "**?0**'de silindi",
		'Proceq.LogbookPropertyMeasurementAdded': '**?0** eklendi',
		'Proceq.LogbookPropertyMeasurementExcluded': '**?0** hariç tutuldu',
		'Proceq.LogbookPropertyMeasurementIncluded': '**?0** dahil edildi',
		'Proceq.LogbookPropertyMeasurementDeleted': '**?0** silindi',
		'Proceq.LogbookPropertyAttachmentDeletedOn': "**?0**'de silindi",
		'Proceq.LogbookPropertyPhotoAdded': 'Fotoğraf eklendi',
		'Proceq.LogbookPropertyAudioAdded': 'Sesli yorum eklendi',
		'Proceq.LogbookPropertySeriesFlagged': 'Seri işaretlendi',
		'Proceq.LogbookPropertySeriesUnflagged': 'Seri işareti kaldırıldı',
		'Proceq.LogbookPropertySerialNumberShort': 'S/N',
		'Proceq.LogbookPropertyBluetoothModuleShort': 'BTM S/N',
		'Proceq.LogbookPropertyVerified': 'Doğrulandı',
		'Proceq.LogbookPropertyContractValidUntil': 'Sözleşme geçerliliği:',
		'Proceq.LogbookPropertyOperator': 'Operatör',
		'Proceq.LogbookTitle': 'Kayıt defteri',
		'Proceq.LogbookDownload': 'İndir',
		'Proceq.LogbookLoadingData': 'Veri yükleniyor....',
		'Proceq.LogbookClose': 'Kapat',
		'Proceq.FormCompany': 'Şirket',
		'Proceq.FormScreeningEagleID': 'Screening Eagle ID (Email)',
		'Proceq.FormEmailGroupID': 'Eposta',
		'Proceq.FormCustomerEmail': 'Müşteri E-posta',
		'Proceq.FormProbeSN': 'Prob S/N',
		'Proceq.FormBluetoothSN': 'S/N',
		'Proceq.FormBluetoothHWID': 'HW ID',
		'Proceq.FormExpirationDate': 'Bitiş Tarihi',
		'Proceq.TitleRegisterProbeSNs': 'Kira Sözleşmesi Kaydet',
		'Proceq.ButtonSubmit': 'Gönder',
		'Proceq.ButtonReset': 'Sıfırla',
		'Proceq.ButtonResetPassword': 'Şifre Sıfırla',
		'Proceq.MessageSuccesfullyInserted': 'Başarıyla eklendi',
		'Proceq.MessageSuccesfullyEmailConfirmed': 'E-postanız onaylandı.',
		'Proceq.MessagePasswordSuccesfullyChanged': 'Şifre Başarıyla Değiştirildi',
		'Proceq.MessageSuccesfullyUpdated': 'Başarıyla Güncellendi',
		'Proceq.MessageAccountCreated':
			'Size bir kayıt doğrulama e-postası gönderildi. Kayıt işlemini tamamlamak için lütfen e-postadaki bağlantıyı tıklayın',
		'Proceq.MessageEmailForgotPassword': 'Ek talimatları içeren bir e-posta size gönderildi',
		'Proceq.MessageEmailResendConfirm': 'Ek talimatları içeren bir e-posta size gönderildi',
		'Proceq.MessageLoggingIn': 'Oturun açılıyor...',
		'Proceq.LoginNeedAccount': 'Bir hesap mı gerekli?',
		'Proceq.LoginCreateAccount': 'Hesap Oluştur',
		'Proceq.TitleForgotPassword': 'Şifreyi Unuttum',
		'Proceq.TitleResendConfirmation': 'Onay E-postasını Tekrar Gönder',
		'Proceq.FormGroup': 'Grup',
		'Proceq.FormFirstName': 'Adı',
		'Proceq.FormLastName': 'Soyadı',
		'Proceq.FormEmail': 'E-posta Adresi',
		'Proceq.FormCity': 'İl',
		'Proceq.FormPassword': 'Şifre',
		'Proceq.FormPasswordNew': 'Yeni Şifre',
		'Proceq.FormPasswordOld': 'Eski Şifre',
		'Proceq.FormConfirmPassword': 'Şifre Onayla',
		'Proceq.FormConfirmPasswordNew': 'Yeni Şifreyi Onayla',
		'Proceq.ButtonRegister': 'Kaydol',
		'Proceq.ButtonSignIn': 'Giriş',
		'Proceq.TitleSignIn': 'Giriş',
		'Proceq.LoginAlreadyRegistered': 'Zaten kayıtlı mısınız?',
		'Proceq.TextOrSignInUsing': 'Diğer giriş yolu:',
		'Proceq.NavSearchSerials': 'Sözleşmelerde Ara',
		'Proceq.NavRegisterProbeSNs': 'Sözleşme Kaydet',
		'Proceq.NavCreateStaffAccounts': 'Personel Hesapları Oluştur',
		'Proceq.NavHistoryLog': 'Geçmiş Kaydı',
		'Proceq.NavMeasurementsData': 'Ölçüm Verileri',
		'Proceq.NavChangePassword': 'Şifre Değiştir',
		'Proceq.NavLogout': 'Çıkış',
		'Proceq.NavUpdateProfile': 'Profil Güncelle',
		'Proceq.TitleUpdateProfile': 'Profil Güncelle',
		'Proceq.NavCreateCustomersAccounts': 'Müşteri Hesabı Oluştur',
		'Proceq.ButtonSearch': 'Ara',
		'Proceq.ButtonUpdate': 'Güncelle',
		'Proceq.ButtonCreate': 'Oluştur',
		'Proceq.ButtonChange': 'Değiştir',
		'Proceq.FormSearchBy': 'Arama ölçütü',
		'Proceq.FormValue': 'Değer',
		'Proceq.FormAccountType': 'Hesap Türü',
		'Proceq.FormAccountTypeAdmin': 'Yönetici',
		'Proceq.FormAccountTypeBU': 'İş Birimi',
		'Proceq.FormCompanyName': 'Şirket Adı',
		'Proceq.FormAddress': 'Adres',
		'Proceq.FormZipCode': 'Posta Kodu',
		'Proceq.FormCountry': 'Ülke',
		'Proceq.FormPhoneNumber': 'Telefon',
		'Proceq.FormCompanyWebsite': 'Şirket Web Sitesi',
		'Proceq.FormJobTitle': 'İş Unvanı',
		'Proceq.FormIndustry': 'Endüstri',
		'Proceq.FormSalutation': 'Hitap',
		'Proceq.FormCompanySize': 'Şirket Büyüklüğü',
		'Proceq.FormLanguage': 'Dil',
		'Proceq.FormContactByProceq': 'Proceq İletişimi',
		'Proceq.LanguageEnglish': 'İngilizce',
		'Proceq.LanguageJapanese': 'Japonca',
		'Proceq.LanguageChinese': 'Çince',
		'Proceq.LanguageGerman': 'Almanca',
		'Proceq.TextYes': 'Evet',
		'Proceq.TextNo': 'Hyr',
		'Proceq.FormNewsletter': 'Bülten',
		'Proceq.TitleSearchSerials': 'Sözleşmelerde Ara',
		'Proceq.TitleChangePassword': 'Şifre Değiştir',
		'Proceq.TabLoginCustomers': 'Müşteriler',
		'Proceq.TabLoginSingleUser': 'Tek Kullanıcı',
		'Proceq.TabLoginGroup': 'Grup',
		'Proceq.TextRemainingXDays': '**?0** gün kaldı',
		'Proceq.TextInfoShort': 'Bilgi',
		'Proceq.TextIAgreeTerms': '<a>Koşulları</a> kabul ediyorum',
		'Proceq.ErrorDisplayTitleError': 'Hata',
		'Proceq.ErrorDisplayTitleWarning': 'Uyarı',
		'Proceq.LoginBackLogin': 'Girişe dön',
		'Proceq.LoginForgotPasswordQuestion': 'Şifrenizi mi Unuttunuz?',
		'Proceq.LoginResendConfirmationEmail': 'Onay e-postasını tekrar gönder',
		'Proceq.LoginRegisterAccountTextPart': 'Bir hesabınız yoksa lütfen',
		'Proceq.LoginRegisterAccountLinkPart': 'buradan kaydolun',
		'Proceq.LoginProceqUseOnly': "Sadece Proceq'in kurumiçi kullanımı içindir",
		'Proceq.ProfileEnterInfo': 'Devam etmeden önce lütfen profil bilgilerinizi girin',
		'Proceq.TextInfoExclamation': 'Bilgi!',
		'Proceq.FooterLegalPrivacy': 'Hukuk ve Gizlilik',
		'Proceq.JQGridTableRecordText': 'Görünüm: {0} - {1} / {2}',
		'Proceq.JQGridTableEmptyRecords': 'Görüntülenecek kayıt yok',
		'Proceq.JQGridTableLoadText': 'Yükleniyor...',
		'Proceq.JQGridTablePaginationText': 'Sayfa {0} / {1}',
		'Proceq.JQGridTableSavingText': 'Kaydediliyor',
		'Proceq.JQGridTableFirstPage': 'İlk Sayfa',
		'Proceq.JQGridTableLastPage': 'Son Sayfa',
		'Proceq.JQGridTableNextPage': 'Sonraki Sayfa',
		'Proceq.JQGridTablePreviousPage': 'Önceki Sayfa',
		'Proceq.JQGridTableRecordsPerPage': 'Kayıt/Sayfa',
		'Proceq.JQGridTablePageText': 'Sayfa:',
		'Proceq.JQGridTableNoMoreRecs': 'Başka kayıt yok...',
		'Proceq.ExportColumnCSVNo': 'No.',
		'Proceq.ExportColumnCSVSingleReadings': 'Tek Okumalar',
		'Proceq.ExportColumnCSVMeasurementValue': 'Ölçüm Değeri',
		'Proceq.ExportColumnCSVAngleDegrees': 'Açı (°)',
		'Proceq.ExportColumnCSVExcluded': 'Hariç tutuldu',
		'Proceq.ExportColumnCSVSeriesName': 'Seri Adı',
		'Proceq.ExportColumnCSVImpactDevice': 'Darbe Cihazı',
		'Proceq.ExportColumnCSVImpactDeviceSN': 'Darbe Cihazı S/N',
		'Proceq.ExportColumnCSVImpactDeviceVerification': 'Darbe Cihazı Doğrulaması',
		'Proceq.ExportColumnCSVImpactDeviceHammerVerification': 'Çekiç Doğrulaması',
		'Proceq.ExportColumnCSVImpactDeviceHammer': 'Çekiç',
		'Proceq.ExportColumnCSVImpactDeviceHammerSN': 'Çekiç S/N',
		'Proceq.ExportColumnCSVBluetoothModuleSN': 'Bluetooth Modülü S/N',
		'Proceq.ExportColumnCSVConversionStandard': 'Çevire Stand.',
		'Proceq.ExportColumnCSVScale': 'Ölçek',
		'Proceq.ExportColumnCSVUnit': 'Birim',
		'Proceq.ExportColumnCSVReadings': 'Okumalar',
		'Proceq.ExportColumnCSVStandardDeviation': 'Stan.sap.',
		'Proceq.ExportColumnCSVRelativeSpan': 'Gör.Açıklık(%)',
		'Proceq.ExportColumnCSVDebugDataSeriesName': 'Seri Adı',
		'Proceq.ExportColumnExportDate': 'Aktarma Tarihi',
		'Proceq.ExportColumnUpperLimit': 'Üst Sınır',
		'Proceq.ExportColumnLowerLimit': 'Alt Sınır',
		'Proceq.ExportColumnLimits': 'Sınırlar',
		'Proceq.ExportColumnHigh': 'Yüksk',
		'Proceq.ExportColumnLow': 'Düşk',
		'Proceq.ExportColumnValueOff': 'Kapat',
		'Proceq.ExportColumnValueNoConversion': 'Çevirme Yok',
		'Proceq.ExportColumnValueNoConversionShort': 'ÇevYok',
		'Proceq.ExportColumnReadings': 'Okumalar',
		'Proceq.ExportColumnStandardDeviation': 'Standart sapma',
		'Proceq.ExportColumnRange': 'Menzil',
		'Proceq.ExportColumnRelativeSpan': 'Göreli Açıklık',
		'Proceq.ExportColumnMaximum': 'Maksimum',
		'Proceq.ExportColumnMinimum': 'Minimum',
		'Proceq.ExportColumnAverage': 'Ortalama',
		'Proceq.ExportColumnUnit': 'Birim',
		'Proceq.ExportColumnName': 'Ad',
		'Proceq.ExportColumnDataSeriesID': "Seri ID'si",
		'Proceq.ExportColumnFolderName': 'Klasör Adı',
		'Proceq.ExportColumnCreatedDate': 'Oluşturma Tarihi',
		'Proceq.ExportColumnCreatedDateOnly': 'Oluşturma Tarihi',
		'Proceq.ExportColumnCreatedTime': 'Oluşturma Saati',
		'Proceq.ExportColumnLastModifiedDate': 'Son Değişiklik Tarihi',
		'Proceq.ExportColumnLastModifiedDateOnly': 'Son Değişiklik Tarihi',
		'Proceq.ExportColumnLastModifiedTime': 'Son Değişiklik Saati',
		'Proceq.ExportColumnMeasurementAvgHardness': 'Ölçüm Ortalama Sertliği',
		'Proceq.ExportColumnLocationLatitude': 'Konum Enlemi',
		'Proceq.ExportColumnLocationLongitude': 'Konum Boylamı',
		'Proceq.ExportColumnProbeType': 'Tür',
		'Proceq.ExportColumnProbeSN': 'Seri Numarası',
		'Proceq.ExportColumnBTMSN': 'Seri Numarası',
		'Proceq.ExportColumnProbeLastModifiedDate': 'Son Doğrulama Tarihi',
		'Proceq.ExportColumnProbeHardwareRevision': 'Donanım Revizyonu',
		'Proceq.ExportColumnProbeFirmwareRevision': 'Yerleşik Yazılım Revizyonu',
		'Proceq.ExportColumnBTMHardwareRevision': 'Donanım Revizyonu',
		'Proceq.ExportColumnBTMFirmwareRevision': 'Yerleşik Yazılım Revizyonu',
		'Proceq.ExportColumnAppPlatform': 'Platform',
		'Proceq.ExportColumnVersion': 'Sürüm',
		'Proceq.ExportColumnOperator': 'Operatör',
		'Proceq.ExportColumnPrimaryScale': 'Birincil Ölçek',
		'Proceq.ExportColumnSecondaryScale': 'İkincil Ölçek',
		'Proceq.ExportColumnMaterial': 'Malzeme',
		'Proceq.ExportColumnConversionStandard': 'Çevirme Standardı',
		'Proceq.ExportColumnAngleCorrection': 'Açı Düzeltmesi',
		'Proceq.ExportColumnValueAuto': 'Otom.',
		'Proceq.ExportColumnTableAngleGrades': 'Açı (°)',
		'Proceq.ExportColumnTableLegendExcluded': '* Hariç tutuldu',
		'Proceq.ExportColumnMeasurementBarGraph': 'Ölçüm Sütun Grafiği',
		'Proceq.ExportColumnTableView': 'Tablo Görünüm',
		'Proceq.ExportColumnStatistics': 'İstatistik',
		'Proceq.ExportColumnSettings': 'Ayarlar',
		'Proceq.ExportColumnImpactDeviceInfo': 'Darbe Cihazı Bilgileri',
		'Proceq.ExportColumnImpactDeviceHammerInfo': 'Çekiç Bilgileri',
		'Proceq.ExportColumnBluetoothModuleInfo': 'Bluetooth Modülü Bilgileri',
		'Proceq.ExportColumnApplicationInfo': 'Uygulama Bilgileri',
		'Proceq.ExportColumnAttachedPhotos': 'Eklenen Fotoğraflar',
		'Proceq.ButtonActions': 'İşlemler',
		'Proceq.SerialNotInServiceFTP': "S/N Hizmet FTP'sinde yok",
		'Proceq.ActionResultsToCSV': 'Sonuçları CSV olarak aktar',
		'Proceq.ErrorDisplayTitleSuccess': 'Başarı',
		'Proceq.ErrorNoMultipleTargetsAllowed': 'İşlem birden çok satırda yapılamaz',
		'Proceq.MessageActionSuccesfullyPerformed': 'İşlem başarıyla gerçekleştirildi',
		'Proceq.EmailReminderContractUserSubject': 'Sözleşme bitiş süresi anımsatması',
		'Proceq.EmailReminderContractUserBody':
			'Sayın kullanıcı<br/><br/>**?0** için kiralama süreniz **?1** tarihinde dolacaktır.<br/><br/><a href="http://www.proceq.com/fileadmin/user_upload/Proceq_General_Terms___Conditions_of_Rental_10112016.pdf">‘Genel Kiralama Koşulları’mız</a> uyarınca sözleşmeniz otomatik olarak 12 ay uzatılır. Faturanız size ayrıca ulaştırılacaktır. Equotip Live ürününüzün kesintisiz kullanımını sağlamak için lütfen ödemeyi zamanında yaptığınızdan emin olun.<br/><br/>Sorularınız için lütfen <a href="http://www.proceq.com/contact/proceq-network/">yerel satış temsilcinize</a> başvurun.<br/><br/>Proceq Ekibiniz<br/><br/>**?2**',
		'Proceq.EmailReminderContractAdminSubject': 'Sözleşme bitiş süresi anımsatması',
		'Proceq.ErrorNoTargetSelected': 'İşlem için hedef seçilmedi',
		'Proceq.ErrorDisplayTitleInfo': 'Bilgi',
		'Proceq.TextSharingSeriesUrlLinkCreated':
			'Seri şu bağlantı altında paylaşılmıştır:<br/>**?0**<br/><a class="link-copy-clipboard" href="javascript:void(0);" onclick="**?1**">Copy to Clipboard</a><br/>',
		'App.ShareMeasurementsCopyAction': 'Panoya Kopyala',
		'App.ShareMeasurementsSuccess': 'Seri şu bağlantı altında paylaşılmıştır',
		'Proceq.TextPleaseWait': 'Lütfen bekleyin...',
		'Proceq.TextPerformingServerRequest': 'Sunucu isteği gerçekleştiriliyor',
		'Proceq.TextPerformingAction': 'İşlem gerçekleştiriliyor',
		'Proceq.ButtonCreateAccountChinaBack': 'At',
		'Proceq.TitleCreateAccountChina': 'Çinli müşteri',
		'Proceq.BodyCreateAccountChina':
			'Çin\'deki müşteriler için lütfen <a href="**?0**">**?1**</a> seçeneğinden kaydolun veya giriş yapın',
		'Proceq.LoginCompatibiltyInfo': 'Uyumluluk bilgisi <a href="**?0**">Proceq Uygulamaları</a>',
		'Proceq.TextRedirectingToRegion': 'Bölgenize yeniden yönlendirme...',
		'Proceq.EmailReminderContractExpiredTodayUserSubject': 'Sözleşme Bitiş Tarihi',
		'Proceq.EmailReminderContractExpiredTodayUserBody':
			'Sayın kullanıcı<br/><br/>**?0** için kiralama süreniz bugün dolmuştur.<br/><br/>Artık Proceq Live kullanmadığınız için üzgünüz, ancak sizinle tekrar buluşmaktan her zaman mutlu olacağız.<br/><br/>Sözleşmeyi yenilemek için lütfen <a href="http://www.proceq.com/contact/proceq-network/">yerel satış temsilcinize</a> başvurun.<br/><br/>Proceq Ekibiniz<br/><br/>**?1**',
		'Proceq.EmailRentalPeriodChangedSubject': 'Kira süresi değiştirildi',
		'Proceq.EmailRentalPeriodChangedBody':
			'Sayın kullanıcı<br/><br/>**?0** için kira sözleşmenizin bitiş tarihi değiştirilmiştir. Kira sözleşmeniz **?1** tarihinde son bulacaktır.<br/><br/>Sorularınız için lütfen <a href="http://www.proceq.com/contact/proceq-network/">yerel satış temsilcinize</a> başvurun.<br/><br/>Proceq Ekibiniz<br/><br/>**?2**',
		'Proceq.FormExpiresIn': '(gün) içinde bitecektir',
		'Proceq.SelectTimeWeek': 'hafta',
		'Proceq.SelectTimeDay': 'gün',
		'Proceq.SelectTimeMonth': 'ay',
		'Proceq.GraphNoDataAvailable': 'veri yok',
		'Proceq.GraphMaterial': 'Malzeme Grubu',
		'Proceq.GraphSecondaryScale': 'Çevirme Ölçeği',
		'Proceq.GraphStandard': 'Çevirme Standardı',
		'Proceq.GraphMeasurementCount': 'Darbe Sayısı',
		'Proceq.GraphMeasurementSeriesCount': 'Ölçüm serisi sayısı',
		'Proceq.StatisticsTitle': 'İstatistik',
		'Proceq.StatisticsCreatedSeries': 'oluşturulan ölçüm serisi',
		'Proceq.StatisticsModifiedSeries': 'değiştirilen ölçüm serisi',
		'Proceq.StatisticsPhotos': 'fotolar',
		'Proceq.StatisticsAudioFiles': 'ses dosyaları',
		'Proceq.StatisticsUsedStorage': 'kullanılan bellek',
		'Proceq.StatisticsFolder': 'Klasör',
		'Proceq.StatisticsModified': 'Değişiklik',
		'Proceq.StatisticsCreated': 'Oluşturma',
		'Proceq.StatisticsAll': 'Tüm',
		'Proceq.StatisticsCountry': 'Ülke',
		'Proceq.StatisticsCompanySize': 'Şirket Büyüklüğü',
		'Proceq.StatisticsIndustry': 'Endüstri',
		'Proceq.DateRangeFrom': 'Başla.:',
		'Proceq.DateRangeTo': 'Bitş:',
		'Proceq.GraphHistogram': 'Sertlik Histogramı',
		'Proceq.GraphSeriesHistogram': 'Sertlik Histogramı serisi',
		'Proceq.StatisticsImpactDevice': 'Darbe Cihazı',
		'Proceq.StatisticsImpactDeviceHammer': 'Çekiç',
		'Proceq.GraphExcluded': 'Hariç tutuldu',
		'Proceq.GraphIncluded': 'Dahil edildi',
		'Proceq.GraphWithinTolerance': 'Tolerans içi',
		'Proceq.GraphOutOfTolerance': 'Tolerans dışı',
		'Proceq.StatisticsCreatedImpacts': 'Oluşturulan darbeler (dahil/hariç)',
		'Proceq.StatisticsModifiedImpacts': 'Değiştirilen darbeler (dahil/hariç)',
		'Proceq.GraphNoConversion': 'Çevirme yok',
		'Proceq.StatisticsTotalImpacts': 'Toplam darbe',
		'Proceq.StatisticsTotalSeries': 'Toplam seri',
		'Proceq.StatisticsTotalExcluded': 'Toplam hariç tutulan',
		'Proceq.StatisticsValuesOutOfLimits': 'Sınır dışı değerler',
		'Proceq.StatisticsPeriod': 'Süre',
		'Proceq.LabelFrom': 'Başla.:',
		'Proceq.LabelTo': 'Bitş:',
		'Proceq.LabelImpacts': 'Darbeler',
		'Proceq.AbbrevCalendarWeek': 'CW',
		'Proceq.eRebNStd_Mean': 'Orta.',
		'Proceq.ExportFieldTargetScale': 'Hedef Ölçek',
		'Proceq.ExportFieldConversionCurve': 'Çevirme eğrisi',
		'Proceq.eCC_None': 'Hiçbiri',
		'Proceq.eCC_Portland_Cement_J_N': 'Portland Çimento J',
		'Proceq.eCC_Early_Strength_J_N': 'Erken Dayanım J',
		'Proceq.eCC_Blast_Furnace_J_N': 'Yüksek Fırın J',
		'Proceq.eCC_Average_Curve_J_N': 'Ortalama Eğrisi J',
		'Proceq.eSC_R1_dry': 'JSCE-G504 R1 = 0 (kuru)',
		'Proceq.eSC_R1_black_mark': 'JSCE-G504 R1 = +3 (siyah leke)',
		'Proceq.eSC_R1_wet_surface': 'JSCE-G504 R1 = +5 (ıslak yüzey)',
		'Proceq.eCSC_None': 'Hiçbiri',
		'Proceq.eCSC_Upper': 'Üst',
		'Proceq.eCSC_Under': 'Alt',
		'Proceq.eSCC_US_eCSC_None': 'Birleşik dayanım',
		'Proceq.eSCC_US_eCSC_Upper': 'Birleşik dayanım üst döküm yüzeyi',
		'Proceq.eSCC_US_eCSC_Under': 'Birleşik dayanım alt döküm yüzeyi',
		'Proceq.eSCC_PC_eCSC_None': 'Pompaj betonu',
		'Proceq.eSCC_PC_eCSC_Upper': 'Pompaj betonu üst döküm yüzeyi',
		'Proceq.eSCC_PC_eCSC_Under': 'Pompaj betonu alt döküm yüzeyi',
		'Proceq.eSCC_US': 'Birleşik dayanım',
		'Proceq.eSCC_PC': 'Pompaj betonu',
		'Proceq.eNoOutlier': 'Aykırı Yok',
		'Proceq.eOutlierLow': 'Aykırı Düşük',
		'Proceq.eOutlierHigh': 'Aykırı Yüksek',
		'Proceq.eOutlierRedundant': 'Aykırı Artık',
		'Proceq.LogbookPropertyConversionCurveChangedFromTo': '**?0** olan çevirme eğrisi **?1**olarak değiştirildi',
		'Proceq.LogbookPropertyConversionCurveChangedTo': 'Çevirme eğrisi **?0** olarak değiştirildi',
		'Proceq.LogbookPropertyTargetScaleChangedFromTo': '**?0** olan hedef ölçek **?1** olarak değiştirildi',
		'Proceq.LogbookPropertySurfaceCorrectionChangedFromTo':
			'**?0** olan yüzey düzeltmesi **?1** olarak değiştirildi',
		'Proceq.LogbookPropertySurfaceCorrectionChangedTo': 'Yüzey düzeltmesi **?0** olarak değiştirildi',
		'Proceq.LogbookPropertyCarbonationDepthChangedFromTo':
			'**?0** mm olan karbonatlaşma derinliği **?1** mm olarak değiştirildi',
		'Proceq.LogbookPropertySurfaceConditionChangedFromTo': '**?0** olan yüzey koşulu **?1** olarak değiştirildi',
		'Proceq.LogbookPropertySurfaceConditionChangedTo': 'Yüzey koşulu **?0** olarak değiştirildi',
		'Proceq.LogbookPropertyConcreteAgeChangedFromTo': '**?0** g olan beton yaşı **?1** g olarak değiştirildi',
		'Proceq.LogbookPropertyConcreteAgeChangedTo': 'Beton yaşı **?0** g olarak değiştirildi',
		'Proceq.ExportColumnCSVOutlierType': 'Aykırı',
		'Proceq.ExportColumnCarbonationDepth': 'Karbonatlaşma derinliği',
		'Proceq.ExportColumnConcreteAge': 'Beton yaşı',
		'Proceq.ExportFieldCarbonationDepth': 'Karbonatlaşma derinliği',
		'Proceq.ExportFieldConcreteAge': 'Beton yaşı',
		'Proceq.ValueConcreteAgeDays': '**?0** g',
		'Proceq.ValueCarbonationDepthMms': '**?0** mm',
		'Proceq.ExportColumnConversionCurve': 'Çevirme eğrisi',
		'Proceq.ExportColumnTableLegendOutlier': '* Aykırı',
		'Proceq.ExportColumnTargetScale': 'Hedef Ölçek',
		'Proceq.ExportColumnMeasurementAvgCompressiveStrength': 'Ölçüm Ortalama Basınç Dayanımı',
		'Proceq.ExportColumnEn13791Screening': 'EN 13791 Tarama',
		'Proceq.ExportColumnLowestRebound': 'En Düşük Tepme Değeri',
		'Proceq.ExportColumnMedianRebound': 'Medyan Tepme Değeri',
		'Proceq.ExportColumnCompressiveStrengthClass': 'EN 206 Basınç Dayanımı Sınıfı',
		'Proceq.EmailIntroDearContractOwner': 'Sayın sözleşme sahibi',
		'Proceq.ColumnTableSeriesRegionExport': 'Seri',
		'Proceq.ExportColumSeriesRegionExport': 'Seri',
		'Proceq.ExportTitleTestRegionExport': 'Test Bölgesi Aktarma',
		'Proceq.ErrorSchmidtExportInvalidStandard': 'Tüm serilerde aynı standart kümesi olmalıdır',
		'Proceq.ErrorSchmidtExportTooFewSeries': 'EN 13791 Taraması için en az 9 seri gereklidir',
		'Proceq.ErrorSchmidtExportTooManySeries': 'Tek bir aktarma için maksimum 99 seri seçilebilir',
		'Proceq.ErrorSchmidtExportDifferentConfiguration': 'Seride farklı yapılandırmalar bulundu',
		'Proceq.ErrorSchmidtExportWrongStandard': 'Seçilen aktarma için yanlış standart bulundu',
		'Proceq.ErrorSchmidtExportInvalidSeries': 'Seçilen standarda göre, serilerden en az biri geçersiz',
		'Proceq.ErrorSchmidtExportSoftwareError': 'Aktarma dosyası oluşturulurken hata',
		'Proceq.ErrorSchmidtExportOutOfRange': 'Hesaplanan dayanım sınıfı menzil dışı',
		'Proceq.ButtonExportTestRegionEU': 'EN 13791 Tarama testini PDF olarak aktar',
		'Proceq.ButtonExportTestRegionUniformity': 'Tektipliği pdf olarak aktar',
		'Proceq.ButtonExportTestRegionChina': 'JGJ T23 test bölgesini PDF olarak aktar',
		'Proceq.ErrorNotYetImplemented': 'Henüz uygulanmadı',
		'Proceq.ErrorSchmidtExportWrongParam':
			'Seride yanlış parametre bulundu: Örneğin: Seçilen aktarma için yanlış birim',
		'Proceq.ErrorSchmidtExportUnitNotMpa': 'JGJ bölgesi için birimin MPa olarak ayarlanması zorunludur',
		'Proceq.ErrorSchmidtExportWrongFormFactor': 'JGJ bölgesi için şekil faktörü 2:1 silindir olamaz',
		'Proceq.NavSelectView': 'Görünüm seç',
		'Proceq.TextConfirmationAreYouSure': 'Emin misiniz?',
		'Proceq.ErrorContractLinkedDifferentImpactDevice':
			'Sözleşme, geçerli darbe cihazı seri numarası ile kullanılamaz',
		'Proceq.TitleShareByUrl': 'URL ile paylaş',
		'Proceq.ExportTitleVerification': 'Doğrulama Raporu:',
		'Proceq.ExportVerificationStatusFailed': 'Başarısız',
		'Proceq.ExportVerificationStatusPassed': 'Başarılı',
		'Proceq.ExportColumnTestVerificationResult': 'Doğrulama Sonucu',
		'Proceq.ExportColumnTestBlockTolerance': 'Test Bloğu Belirsizliği (Ucrm) k=2',
		'Proceq.ExportColumnTestBlockNominalHardness': 'Test Bloğu Nominal Sertliği',
		'Proceq.ExportColumnTestBlockSN': 'Test Bloğu Seri Numarası',
		'Proceq.ExportColumnTestBlockName': 'Test Bloğu Adı',
		'Proceq.ExportColumnVerificationStd': 'Doğrulama Standardı',
		'Proceq.ExportColumnVerificationCriteria': 'Doğrulama Kriteri',
		'Proceq.VerificationCriteriaAverage5Span5Perc': 'ortalama +/- %5, açıklık +/- %5',
		'Proceq.VerificationCriteriaReadings6HL': 'okumalar +/- 6 HL',
		'Proceq.VerificationCriteriaReadings3Perc': 'okumalar +/- %3',
		'Proceq.VerificationCriteriaAverage5Perc': 'ortalama +/- %5',
		'Proceq.VerificationCriteriaAverage2R': 'ortalama +/- 2 R',
		'Proceq.VerificationCriteriaReadings3R': 'okumalar +/- 3 R',
		'Proceq.StandardEnumCustom': 'Özel',
		'Proceq.MaterialEnumCustom': 'Özel',
		'Proceq.ExportColumnCalibrationAnvilName': 'Kalibrasyon Örsü Adı',
		'Proceq.ExportColumnCalibrationAnvilSN': 'Kalibrasyon Örsü Seri Numarası',
		'Proceq.ExportColumnCalibrationAnvilReboundValue': 'Kalibrasyon Örsü Tepme Değeri',
		'Proceq.ExportColumnCalibrationAnvilTolerance': 'Kalibrasyon Örsü Toleransı',
		'Proceq.ExportColumnTestLoadInfo': 'Test Yükü Bilgisi',
		'Proceq.NavVerificationData': 'Doğrulama Verileri',
		'Proceq.NavCustomData': 'Özel Veriler',
		'Proceq.TableColumnVerificationResult': 'Sonuç',
		'Proceq.TitleVerifications': 'Doğrulamalar',
		'Proceq.LogBookTestBlock': 'Test Bloğu',
		'Proceq.LogBookCalibrationAnvil': 'Kalibrasyon Örsü',
		'Proceq.LogbookPropertyTestBlockChangedFromTo': '"**?0**" olan test bloğu "**?1**" olarak değiştirildi',
		'Proceq.LogbookPropertyTestBlockChangedTo': 'Test bloğu "**?0**" olarak değiştirildi',
		'Proceq.LogbookPropertyCalibrationAnvilChangedFromTo':
			'**?0** olan kalibrasyon örsü **?1** olarak değiştirildi',
		'Proceq.LogbookPropertyCalibrationAnvilChangedTo': 'Kalibrasyon örsü **?0** olarak değiştirildi',
		'Proceq.LogbookPropertyCreatedVerification': '"**?0**" Doğrulaması Oluşturuldu',
		'Proceq.LogbookPropertyRenamedVerificationFromTo': '"**?0**" olan doğrulama adı "**?1**" olarak değiştirildi',
		'Proceq.LogbookResultVerificationFailed': 'Doğrulama başarısız',
		'Proceq.LogbookResultVerificationPassed': 'Doğrulama başarılı',
		'Proceq.LogbookPropertyTestLoadChangedFromTo': '**?0** olan test yükü **?1** olarak değiştirildi',
		'Proceq.LogbookPropertyTestLoadChangedTo': 'Test yükü**?0** olarak değiştirildi',
		'Proceq.LogBookTestLoad': 'Test yükü',
		'Proceq.FieldWebOriginalCurve': 'Orijinal',
		'Proceq.FieldWebCustomCurve': 'Özel',
		'Proceq.ExportFieldValuePair': 'Değer çifti',
		'Proceq.TableBaseMaterial': 'Temel Malzeme',
		'Proceq.TableDescription': 'Açıklama',
		'Proceq.NavCustomMaterial': 'Özel Malzeme',
		'Proceq.FolderCustomMaterials': 'Özel Malzemeler',
		'Proceq.SchmidtCurveTypePolynomial': 'fck = a + b*R + c*R² (Polinomal)',
		'Proceq.SchmidtCurveTypeExponencial': 'fck = a.e^(b*R) (Üstel)',
		'Proceq.SchmidtCurveTypePolynomialShort': 'Polinomal',
		'Proceq.SchmidtCurveTypeExponencialShort': 'Üstel',
		'Proceq.ExportFieldCurveType': 'Eğri Türü',
		'Proceq.TableActionEdit': 'Düzen',
		'Proceq.TableActionAdd': 'Ekle',
		'Proceq.WebAccountActivatedTitle': "Proceq Live'a hoş geldiniz,",
		'Proceq.WebAccountActivatedBodyLine1': 'Hesabınız başarıyla oluşturuldu. Şimdi kullanıma hazır!',
		'Proceq.WebAccountActivatedProceqTeam': 'Proceq Ekibi',
		'Proceq.NavDashboard': 'Gösterge paneli',
		'App.SchmidtFormFactorRAngle': 'R açısı düzeltildi',
		'App.SchmidtFormFactorQAngle': 'Q değeri',
		'App.ShareMeasurementsCopySuccess': 'Kopyalandı',
		'App.ShareMeasurementsCopyFailed': 'Tarayıcınız kopyalamayı desteklemiyor. Lütfen manuel olarak kopyalayın.',
		'App.SystemFolders': 'Sistem klasörleri',
		'Proceq.DataSeriesDemo': 'Demo',
		'Proceq.DataSeriesImported': 'Dışarıdan alındı',
		'Antenna Spacing [{unit}]': 'Anten Aralığı [{unit}]',
		'App Version': 'Uygulama sürümü',
		'Base Unit S/N': 'Ana Ünite S / N',
		'Calculated Depth': 'Hesaplanan Derinlik',
		Comment: 'Yorum Yap',
		'Contract ID': 'Sözleşme kimliği',
		'Distance X [{unit}]': 'Mesafe X [{unit}]',
		'Distance Y [{unit}]': 'Mesafe Y [{unit}]',
		'Export HTML Format Version': 'HTML Formatı Sürümünü Dışarı Aktar',
		'File ID': 'Dosya kimliği',
		'File Name': 'Dosya adı',
		'Firmware Version': 'Firmware Sürümü',
		'Grid Size X [{unit}]': 'Izgara Boyutu X [{unit}]',
		'Grid Size Y [{unit}]': 'Izgara Boyutu Y [{unit}]',
		'Grid Spacing X [{unit}]': 'Izgara Aralığı X [{unit}]',
		'Grid Spacing Y [{unit}]': 'Izgara Aralığı Y [{unit}]',
		'Hardware Version': 'Donanım Sürümü',
		Line: 'Hat',
		'MAC Address': 'Mac Adresi',
		'Marker Number': 'İşaretçi Numarası',
		'Measurement Presets': 'Ölçüm Ön Ayarları',
		Mode: 'kip',
		Name: 'isim',
		Objects: 'Nesneler',
		'Probe S/N': 'Prob S / N',
		Product: 'Ürün',
		'Repetition Rate [{unit}]': 'Tekrarlama Oranı [{unit}]',
		Resolution: 'çözüm',
		'Sampling Rate [{unit}]': 'Örnekleme Hızı [{unit}]',
		'Scan Distance [{unit}]': 'Tarama Mesafesi [{unit}]',
		'Scan Length [{unit}]': 'Tarama Uzunluğu [{unit}]',
		'Set Depth': 'Derinlik Ayarla',
		'Set Size': 'Boyut Ayarla',
		Tag: 'Etiket',
		'Tag Type': 'Etiket türü',
		'Time Window [{unit}]': 'Zaman Penceresi [{unit}]',
		Units: 'Birimler',
		'User / Probe Info': 'Kullanıcı / Prob Bilgisi',
		Markers: 'İşaretleyiciler',
		'Created {fileName} in folder {folderName}': '{folderName} klasöründe {fileName} oluşturuldu',
		'Mode: {value}': 'Mod: {value}',
	},
};
