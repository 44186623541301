// @flow
import React from 'react';
import classNames from 'classnames';
import { compose, withState } from 'recompose';
import { connect } from 'react-redux';
import { Popconfirm, Button, Icon } from 'antd';
import { FormattedMessage } from 'react-intl';
import URL from 'apis/urls';
import { request } from 'actions/ajax';
import styles from '../shared/SmarterTable.styl';

export const Delete = ({
	record,
	loading,
	deleteFirmware,
}: {
	record: Object,
	loading: boolean,
	deleteFirmware: Function,
}) => (
	<Popconfirm
		title={<FormattedMessage id="App.TableActionDeletePrompt" values={{ subject: record.fileName }} />}
		onConfirm={deleteFirmware}
		okText={<FormattedMessage id="Proceq.TextYes" />}
		cancelText={<FormattedMessage id="Proceq.TextNo" />}
	>
		<Button
			type="danger"
			className={classNames(styles.button, styles.inline)}
			loading={loading}
			data-e2e-firmware-delete-button
		>
			<Icon type="delete" />
		</Button>
	</Popconfirm>
);

export default compose(
	withState('loading', 'setLoading', false),
	connect(null, (dispatch: Function, props) => ({
		deleteFirmware: () =>
			dispatch(
				request({
					method: 'DELETE',
					url: URL.DELETE_FIRMWARE,
					params: {
						product: undefined,
						keys: [props.record],
					},
					setLoading: props.setLoading,
				})
			),
	}))
)(Delete);
