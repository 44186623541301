// @flow
import React from 'react';
import { FormattedMessage } from 'react-intl';

export const Limits = ({ log }: GPRLogbookEntryProps) => {
	const { newValue, oldValue, unit, angle, item } = log.content;
	return (
		<FormattedMessage
			id="{angle} {item} changed from  {oldValue} {unit} to {newValue} {unit}"
			values={{ oldValue, newValue, unit, angle, item }}
		/>
	);
};

export const color = () => 'blue';

export default Limits;
