// @flow
import React from 'react';
import { FormattedMessage } from 'react-intl';

export const MoveFile = ({ log }: GPRLogbookEntryProps) => (
	<FormattedMessage
		id="App.LogbookEntryMoved"
		values={{
			from: log.content.source,
			to: log.content.destination,
		}}
	/>
);

export default MoveFile;
