// @flow
import React, { Fragment } from 'react';
import { get } from 'lodash';
import { convertAndDisplay } from 'constants/conversionRates';
import SavedSnapshots from 'components/measurements/HTMLExport/components/Common/SavedSnapshots';
import UserComponent from './components/Common/User';
import MeasurementPresets from './components/Settings/GPR/MeasurementPresets';
// import ImageProcessing from './components/Settings/GPR/ImageProcessing';
import Objects from './components/Settings/GPR/Objects';
import Markers from './components/Settings/GPR/Markers';
import ScanDistance from './components/Settings/GPR/ScanDistance';
import Logbook from './components/Common/Logbook';
import styles from './HTMLExport.styl';

export type DerivedProps = {
	scanType: ScanType,
	product: ProductCode,
	productModel: string,
	isMetric: boolean,
	convert: (string | number, string) => string | number,
	showTitle?: boolean,
};

const GPR = ({ data, users }: { data: MeasurementFullData, users: User[] }) => {
	const productModel = get(data, 'measurement.productModel');
	const scanType = get(data, 'measurement.type');
	const isMetric = get(data, 'settings.0.content.settings.display.unit') === 'Metric';
	const derivedProps: DerivedProps = {
		// $FlowFixMe
		product: data.productFamily.toUpperCase(),
		productModel,
		scanType,
		isMetric,
		convert: (value, unitId) =>
			isFinite(+value) ? convertAndDisplay(unitId, scanType, isMetric).convertFunction(+value) : '-',
		showTitle: true,
	};

	const snapshotLogs = data.logs.filter(log => log.type === 'saveSnapshot');

	return (
		<div className="html-body">
			<h1 id={data.measurement.id} className={styles.file_name}>
				{data.uniqueName}
			</h1>
			<br />
			<UserComponent data={data} {...derivedProps} />
			<br />
			<MeasurementPresets data={data} {...derivedProps} />
			<br />
			{/* <ImageProcessing data={data} {...derivedProps} />
				<br /> */}
			{snapshotLogs.length > 0 && (
				<Fragment>
					<SavedSnapshots data={data} logs={snapshotLogs} {...derivedProps} />
					<br />
				</Fragment>
			)}
			{data.objects && (
				<Fragment>
					<Objects data={data} {...derivedProps} />
					<br />
					<Markers data={data} {...derivedProps} />
					<br />
				</Fragment>
			)}
			<ScanDistance data={data} {...derivedProps} />
			<br />
			<Logbook data={data} users={users} {...derivedProps} />
		</div>
	);
};

export default GPR;
