// @flow
export function getSessionItem(key: string): string | null | void {
	return sessionStorage.getItem(key);
}

export function saveSessionItem(key: string, value: string) {
	sessionStorage.setItem(key, value);
}

export function removeSessionItem(key: string) {
	sessionStorage.removeItem(key);
}

export function getLocalItem(key: string): string | null | void {
	return localStorage.getItem(key);
}

export function saveLocalItem(key: string, value: string) {
	localStorage.setItem(key, value);
}

export function removeLocalItem(key: string) {
	localStorage.removeItem(key);
}
