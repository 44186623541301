// @flow
import React, { Component } from 'react';
import qs from 'qs';
import { get, trim } from 'lodash';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Form } from 'antd';
import type { ContextRouter } from 'react-router';
import type { InjectIntlProvidedProps } from 'react-intl';
import { injectIntl } from 'react-intl';
import handleFormatMessage from 'utils/handleFormatMessage';
import { checkStatusJson } from 'apis/restUtils';
import { httpRequest } from 'apis/httpClient';
import { getProceqUrl } from 'utils/sessionHelper';
import URL from 'apis/urls';
import stylesActivation from './ActivationForm.styl';
import { history } from '../../routes';
import { finishUserSession } from '../../actions/logout';

export class ActivationForm extends Component<
	InjectIntlProvidedProps & ContextRouter,
	{
		displaySuccessMessage: boolean,
		errorMessage: string,
	}
> {
	state = {
		displaySuccessMessage: false,
		errorMessage: '',
	};

	componentWillMount() {
		const { id, token, eaid } = qs.parse(trim(this.props.location.search, '?'));
		if (id && token) {
			// TDS
			this.activateAccountTDS(id, token);
		} else if (eaid) {
			// Proceq
			this.activateAccountProceq(eaid);
		}
	}

	render() {
		const { intl } = this.props,
			{ formatMessage } = intl;
		return (
			<div className={stylesActivation.page_background}>
				<div className={stylesActivation.container_wrapper}>
					<div>
						<div className={stylesActivation.logo} />
						<div className={stylesActivation.message_container}>
							{this.state.displaySuccessMessage && (
								<div>
									<p className={stylesActivation.message_title}>
										{handleFormatMessage('Proceq.WebAccountActivatedTitle', formatMessage)}
									</p>
									<p className={stylesActivation.message_paragraph}>
										{handleFormatMessage('Proceq.WebAccountActivatedBodyLine1', formatMessage)}
									</p>
								</div>
							)}
							{this.state.errorMessage && (
								<div>
									<p className={stylesActivation.message_title}>
										{handleFormatMessage('Proceq.ErrorDisplayTitleError', formatMessage)}
									</p>
									<p className={stylesActivation.message_paragraph}>{this.state.errorMessage}</p>
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
		);
	}

	backToLogin() {
		history.push('/login');
		this.props.finishUserSession();
	}

	activateAccountTDS(accountID: number, token: string) {
		let apiEndpoint = URL.ACTIVATE_ACCOUNT;
		const ln = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).ln;
		apiEndpoint = apiEndpoint.replace('{key}', accountID);
		return httpRequest(
			null,
			null,
			apiEndpoint,
			'get',
			false,
			{
				token,
			},
			{ 'X-Accept-Language': ln }
		)
			.then(checkStatusJson)
			.then(() => {
				this.setState({
					displaySuccessMessage: true,
				});
			})
			.catch(err => {
				switch (get(err, 'response.data.code')) {
					case 'AlreadyVerifiedAccount':
						/* it is not really an error, better to show success */
						this.setState({
							displaySuccessMessage: true,
						});

						break;
					default:
						this.setState({
							displaySuccessMessage: false,
							errorMessage: err.response.data.message,
						});
						break;
				}
			});
	}

	activateAccountProceq(token: string) {
		const apiEndpoint = getProceqUrl(null, URL.PROCEQ_MAIL_ACTIONS);
		return httpRequest(null, null, apiEndpoint, 'post', false, {
			action_id: token,
		})
			.then(checkStatusJson)
			.then((/* json */) => {
				this.setState({
					displaySuccessMessage: true,
				});
			})
			.catch((/* err */) => {
				this.setState({
					displaySuccessMessage: false,
				});
			});
	}
}

export default compose(
	connect(
		state => ({
			locale: state.locale,
			globalregister: state.globalregister,
			account: state.account,
		}),
		{ finishUserSession }
	),
	injectIntl,
	Form.create({})
)(ActivationForm);
