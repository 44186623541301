import { v4 as uuid } from 'uuid';
import moment from 'moment';

// Copied this code from common-fe, should use it from there once node version is updated
const COOKIE_DEVICE_ID_NAME = 'X-SE-Client-Id';

export const getCookie = name => {
	const deviceIdCookie = document.cookie.split(';').find(cookie => cookie.split('=')[0].trim() === name);
	return deviceIdCookie ? deviceIdCookie.split('=')[1] : undefined;
};

export const setDeviceIDCookie = deviceID => {
	const expires = moment()
		.add(1, 'year')
		.toDate()
		.toUTCString();
	if (location.hostname === 'localhost') {
		// not possible to set cookie for another domain, this will default to localhost
		document.cookie = `${COOKIE_DEVICE_ID_NAME}=${deviceID};expires=${expires};path=/`;
	} else {
		document.cookie = `${COOKIE_DEVICE_ID_NAME}=${deviceID};expires=${expires};domain=screeningeagle.com;path=/`;
	}
};

export const getDeviceID = () => {
	let deviceID = getCookie(COOKIE_DEVICE_ID_NAME);
	if (!deviceID) {
		deviceID = uuid();
		setDeviceIDCookie(deviceID);
	}
	return deviceID;
};
