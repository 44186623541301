// @flow
import React, { Fragment } from 'react';
import classNames from 'classnames';
import { compose, withProps } from 'recompose';
import { connect } from 'react-redux';
import { Menu, Icon, Divider, Popover } from 'antd';
import { FormattedMessage, injectIntl } from 'react-intl';
import type { InjectIntlProvidedProps } from 'react-intl';
import type { ContextRouter } from 'react-router';
import { withRouter } from 'react-router';
import { isUsingSharedToken } from 'utils/sessionHelper';
import LinkWithExternal from 'components/shared/LinkWithExternal';
import { routes, subPageMapping } from '../../routes/config';
import styles from './Layout.styl';

export const getRoutesFromSession = (session: Session) => {
	// $FlowFixMe
	const adminRoutes = routes.filter(route => route.adminPanel && route.checkAccess(session));
	// $FlowFixMe
	const userRoutes = routes.filter(route => !route.adminPanel && route.checkAccess(session));

	return {
		userRoutes,
		adminRoutes,
	};
};

export const Navigation = ({
	collapsed,
	userRoutes,
	adminRoutes,
	session,
	location,
	intl,
}: {
	collapsed: boolean,
	userRoutes: Object[],
	adminRoutes: Object[],
	session: Session,
} & InjectIntlProvidedProps &
	ContextRouter) => {
	const current = location.pathname;

	const renderIcon = ({ icon, iconString, iconElement, label }) => {
		if (iconString) {
			return <Icon className={styles.icon} type={iconString} />;
		}

		if (icon) {
			return <img src={icon} className={styles.icon} alt={intl.formatMessage({ id: label })} />;
		}

		if (iconElement) {
			return iconElement;
		}

		return null;
	};

	// must use content.props.children, as <Fragment /> won't allow the tooltip to render
	const conditionallyRenderTooltip = (content, subPage) =>
		subPage.tooltip ? (
			<Popover trigger="hover" placement="right" title={subPage.tooltip_title} content={subPage.tooltip}>
				{content.props.children}
			</Popover>
		) : (
			content.props.children
		);

	const renderItem = ({ path, icon, iconString, iconElement, label, subPages }) =>
		subPages ? (
			<Menu.SubMenu
				key={path}
				className={classNames({
					[styles.sub_menu]: true,
					[styles.selected]: subPageMapping[current] === path,
				})}
				title={
					<span>
						{renderIcon({ icon, iconString, iconElement, label })}
						<span>
							<FormattedMessage id={label} />
						</span>
						{collapsed && (
							<Icon
								type="right"
								theme="outlined"
								className={classNames(styles.icon, styles.sub_menu_icon)}
							/>
						)}
					</span>
				}
			>
				{collapsed && (
					<Menu.Item
						key={path}
						className={classNames(styles.menu_item, styles.selected, styles.sub_menu_header)}
						disabled
					>
						<LinkWithExternal path={path}>
							<FormattedMessage id={label} />
						</LinkWithExternal>
					</Menu.Item>
				)}
				{subPages
					.filter(subPage => (subPage.checkAccess ? subPage.checkAccess(session) : true))
					.map(subPage => (
						<Menu.Item
							key={subPage.path}
							id={subPage.path}
							className={classNames({
								[styles.menu_item]: true,
								[styles.selected]: subPage.path === current,
							})}
						>
							<LinkWithExternal path={subPage.path}>
								{conditionallyRenderTooltip(
									<Fragment>
										{!collapsed && <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>}
										{renderIcon(subPage)}
										<span>
											<FormattedMessage id={subPage.label} />
										</span>
									</Fragment>,
									subPage
								)}
							</LinkWithExternal>
						</Menu.Item>
					))}
			</Menu.SubMenu>
		) : (
			<Menu.Item
				key={path}
				className={classNames({
					[styles.menu_item]: true,
					[styles.selected]: path === current,
					[styles.hidden]: path.includes('cn.'),
				})}
			>
				<LinkWithExternal path={path}>
					{renderIcon({ icon, iconString, iconElement, label })}
					<span>
						<FormattedMessage id={label} />
					</span>
				</LinkWithExternal>
			</Menu.Item>
		);

	return (
		<Fragment>
			<Menu mode="inline" selectedKeys={[current]} className={styles.menu}>
				{userRoutes.map(renderItem)}
			</Menu>
			{adminRoutes.length > 0 && (
				<Divider className={styles.sidebar_footer}>
					<FormattedMessage id="App.SidebarAdmin" />
				</Divider>
			)}
			{adminRoutes.length > 0 && (
				<Menu
					mode="inline"
					selectedKeys={[current]}
					defaultOpenKeys={[subPageMapping[current]]}
					className={styles.menu}
				>
					{adminRoutes.map(renderItem)}
				</Menu>
			)}
		</Fragment>
	);
};

export default compose(
	connect(state => ({
		session: state.session,
	})),
	withProps(({ session }) => {
		const computedRoutes = getRoutesFromSession(session);

		// Manual route handling for shared routes
		if (isUsingSharedToken(session)) {
			computedRoutes.userRoutes = [];
		}

		return computedRoutes;
	}),
	withRouter,
	injectIntl
)(Navigation);
