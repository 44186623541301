import actionTypes from 'actions/actionTypes';

export const defaultState = {
	user: {},
	authError: {
		authFailed: false,
		message: '',
	},
	isAuthenticated: false,
	isAuthenticating: false,
	resetPasswordEmailSent: false,
	resetPasswordSuccess: false,
};

export function user(state = defaultState, action = {}) {
	switch (action.type) {
		case actionTypes.PROCEQ_LOGIN_REQUEST:
		case actionTypes.LOGIN_REQUEST:
			return {
				...state,
				user: action.payload,
				isAuthenticating: true,
				authError: {
					authFailed: false,
					message: '',
				},
			};

		case actionTypes.LOGIN_FAILED:
			return {
				...state,
				user: {},
				isAuthenticating: false,
				authError: {
					authFailed: true,
					message: action.payload,
				},
			};

		case actionTypes.LOGIN_SUCCESS: {
			return {
				...state,
				isAuthenticating: false,
				isAuthenticated: true,
				authError: {
					authFailed: false,
					message: '',
				},
			};
		}
		case actionTypes.PROFILE_LOADED: {
			return {
				...state,
				user: {
					...state.user,
					profile: {
						...action.payload,
						email: action.payload.username,
						portrait: action.payload.profilePicture,
					},
				},
			};
		}

		case actionTypes.PROCEQ_PROFILE_LOADED: {
			return {
				...state,
				user: {
					...state.user,
					profile: {
						email: action.payload.username,
						firstName: action.payload.first_name,
						lastName: action.payload.last_name,
						userID: action.payload.user_id,
						portrait: '',
					},
				},
			};
		}

		case actionTypes.PORTRAIT_LOADED: {
			return {
				...state,
				user: {
					...state.user,
					profile: {
						...state.user.profile,
						portrait: action.payload,
					},
				},
			};
		}

		case actionTypes.LOGOUT_SUCCESS:
		case actionTypes.LOGOUT_FAILED:
			return {
				...state,
				user: {},
				isAuthenticated: false,
			};

		case actionTypes.PASSWORD_UPDATE_SUCCESS:
			return {
				...state,
				user: {
					...state.user,
					passwordUpdateStatus: true,
				},
			};

		case actionTypes.PASSWORD_UPDATE_FAILED:
			return {
				...state,
				user: {
					...state.user,
					passwordUpdateStatus: false,
				},
			};

		case actionTypes.RESET_PASSWORD_SENDING:
			return {
				...state,
				authError: { authFailed: false, message: '' },
			};

		case actionTypes.RESET_PASSWORD_EMAIL_SENT:
			return {
				...state,
				resetPasswordEmailSent: true,
			};

		case actionTypes.RESET_PASSWORD_SUCCESS: {
			return {
				...defaultState,
				resetPasswordSuccess: true,
			};
		}
		default:
			return state;
	}
}
