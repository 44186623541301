module.exports = {
	shortlang: 'ru',
	lang: 'ru',
	messages: {
		'Proceq.ErrorWrongUsername': 'Предоставлен неверный адрес электронной почты',
		'Proceq.ErrorCreationNotAllowed': 'Отключено создание новых учетных записей',
		'Proceq.ErrorWrongPassword': 'Предоставлен неверный пароль',
		'Proceq.ErrorWrongFirstName': 'Предоставлено неверное имя',
		'Proceq.ErrorWrongLastName': 'Предоставлена неверная фамилия',
		'Proceq.ErrorUsernameAlreadyExists': 'Учетная запись с таким адресом электронной почты уже существует',
		'Proceq.ErrorDB': 'Ошибка при выполнении операции базы данных',
		'Proceq.InvalidUsername': 'Учетная запись пользователя не найдена',
		'Proceq.AlreadyMultipleForgot': 'Сделано слишком много запросов на сброс пароля',
		'Proceq.InvalidNewPassword': 'Неверный новый пароль',
		'Proceq.InvalidResetCode': 'Код сброса пароля неверен или уже истек',
		'Proceq.InvalidOldPassword': 'Предоставлен неверный старый пароль',
		'Proceq.InvalidAccessRights':
			'Истек срок ожидания сеанса, либо пользователю запрещено выполнять данное действие',
		'Proceq.ErrorNoAccountType': 'Не предоставлен тип учетной записи',
		'Proceq.ErrorNoUsername': 'Не предоставлен адрес электронной почты',
		'Proceq.InvalidCountry': 'Предоставлена неверная страна',
		'Proceq.ErrorCreatingUser': 'Ошибка создания нового пользователя',
		'Proceq.InvalidID': 'Предоставлен неверный ИН',
		'Proceq.InvalidTransactionID': 'Неверный ИН транзакции',
		'Proceq.InvalidFileContent': 'Ошибка чтения содержимого файла',
		'Proceq.InvalidTypeEntry': 'Неверный тип записи',
		'Proceq.InvalidContractID': 'Договор недействителен или истек',
		'Proceq.InvalidExistingID': 'Невозможно создать ИН, так как он уже существует',
		'Proceq.InvalidDataSeriesType': 'Неверный тип серии',
		'Proceq.InvalidMetaDataContent': 'Неверное содержание метаданных',
		'Proceq.ErrorFileIsLocked': 'Один или несколько файлов заблокированы. Попытайтесь позже',
		'Proceq.MissingAttachmentFiles': 'Невозможно найти приложенные файлы на сервере',
		'Proceq.InvalidFlagType': 'Тип флажка неверен или отсутствует',
		'Proceq.MissingDateTypeFields': 'Отсутствуют требуемые поля для серии',
		'Proceq.InvalidFolderID': 'Неверный ИН папки',
		'Proceq.InvalidDataSeriesName': 'Неверное имя серии',
		'Proceq.ErrorDateFormat': 'Предоставлен неверный формат данных',
		'Proceq.ErrorDataFormat': 'Невозможно выполнить синтаксический анализ данных из серии',
		'Proceq.InvalidDownloadID': 'Лексема загрузки неверна, или для нее истек срок',
		'Proceq.InvalidVersionNr': 'Предоставлен неверный номер версии',
		'Proceq.ErrorDataMissing': 'Невозможно получить данные из системы хранилища',
		'Proceq.ErrorSavingFile': 'Сервер не может сохранить файл',
		'Proceq.ErrorInsertDB': 'Ошибка при включении в базу данных',
		'Proceq.InvalidFolderName': 'Предоставлено неверное имя папки',
		'Proceq.InvalidActionID': 'Неверная URL-ссылка, или для нее истек срок',
		'Proceq.AlreadyConfirmed': 'Учетная запись уже была проверена',
		'Proceq.InvalidActionType': 'Невозможно обработать запрошенное действие',
		'Proceq.ErrorGeneratingChart': 'Ошибка генерации списков',
		'Proceq.InvalidSerialID': 'Неверный ИН серийного кода',
		'Proceq.InvalidHardwareID': 'Неверный ИН аппаратного обеспечение',
		'Proceq.InvalidEntityID': 'Неверный ИН объекта, или он был удален',
		'Proceq.InvalidEntityType': 'Неверный тип объекта',
		'Proceq.InvalidEntityVerNr': 'Неверный номер версии объекта',
		'Proceq.ErrorTransactionLocked': 'Невозможно модифицировать объект, так как она заблокирован',
		'Proceq.InvalidDateValue': 'Невозможно выполнить синтаксический анализ данных',
		'Proceq.MissingFields': 'Отсутствует одно или несколько необходимых полей',
		'Proceq.ErrorColumnValue': 'Для одного или нескольких полей предоставлено неверное значение',
		'Proceq.MissingUidDBValue': 'Отсутствует ИН записи',
		'Proceq.ErrorFileID': 'Предоставлен неверный ИН файла',
		'Proceq.ErrorInsertDownload': 'Ошибка создании загрузки',
		'Proceq.InvalidAppVersion': 'Ваша текущая версия приложения больше не поддерживается. Обновите приложение',
		'Proceq.MissingUserInfo': 'Отсутствует информация о пользователе',
		'Proceq.InvalidAccessToken': 'Лексема сеанса неверна, либо для нее истек срок',
		'Proceq.ErrorGeneratingCSV': 'Ошибка генерации экспорта CSV',
		'Proceq.ErrorGeneratingExport': 'Ошибка генерации экспорта',
		'Proceq.MissingParams': 'Отсутствует один или несколько требуемых параметров',
		'Proceq.SerialNotFound': 'Для этого устройства не найден договор',
		'Proceq.StandardEnumJapan': 'Япония',
		'Proceq.StandardEnumDefault': 'По умолчанию',
		'Proceq.MaterialEnumIndependent': 'Независимый материал',
		'Proceq.MaterialEnumSteelAndCastSteel': 'Сталь и литая сталь',
		'Proceq.MaterialEnumWorkToolSteel': 'Рабочий инструмент для стали',
		'Proceq.MaterialEnumStainlessSteel': 'Нержавеющая сталь',
		'Proceq.MaterialEnumGrayCastIron': 'Серый чугун (GG)',
		'Proceq.MaterialEnumNodularCastIron': 'Высокопрочный чугун (GGG)',
		'Proceq.MaterialEnumCastAlluminiumAlloys': 'Литые алюминиевые сплавы',
		'Proceq.MaterialEnumBrassCopperZincAlloys': 'Латунь - Медные/Цинковые сплавы',
		'Proceq.MaterialEnumBronze': 'Бронза',
		'Proceq.MaterialEnumWroughtCopperAlloys': 'Кованые медные сплавы',
		'Proceq.MaterialEnumTitanium': 'Титан',
		'Proceq.ScaleEnumCustom': 'Пользовательский',
		'Proceq.ScaleEnumPenetrationDepth': 'Глубина проникновения',
		'Proceq.CompanySizeMicro': '1-9 работников',
		'Proceq.CompanySizeSmall': '10-49 работников',
		'Proceq.CompanySizeMedium': '50-249 работников',
		'Proceq.CompanySizeLarge': '>249 работников',
		'Proceq.SalutationMr': 'Г-н',
		'Proceq.SalutationMs': 'Г-жа',
		'Proceq.SalutationDr': 'Д-р',
		'Proceq.SalutationProf': 'Проф.',
		'Proceq.CategoryAerospace': 'Воздушно-космическое пространство',
		'Proceq.CategoryAutomotive': 'Автотранспорт',
		'Proceq.CategoryChemicalProcessing': 'Химическая обработка',
		'Proceq.CategoryConstructionBuilding': 'Строительство/Здание',
		'Proceq.CategoryConsultantTestLabsEngineer': 'Консультант/Испытательные лаборатории/Инженер',
		'Proceq.CategoryEducationInstituteRD': 'Образование/Институт/Научно-исследовательские работы',
		'Proceq.CategoryFilmProducerConverter': 'Производитель пленки/Преобразователь',
		'Proceq.CategoryGeologyRocktesters': 'Геология/Испытатели горных пород',
		'Proceq.CategoryMachineryToolingEquipment': 'Машиностроение/Инструментарий/Оборудование',
		'Proceq.CategoryMassTransportation': 'Общественный транспорт',
		'Proceq.CategoryMetalWorks': 'Металлоконструкции',
		'Proceq.CategoryOilAndGas': 'Нефть и газ',
		'Proceq.CategoryOthers': 'Другое',
		'Proceq.CategoryPaperProducerConverter': 'Производитель бумаги/Преобразователь',
		'Proceq.CategorySalesChannel': 'Канал сбыта',
		'Proceq.ErrorWrongLoginCredentials': 'Введено неверное имя пользователя или пароль',
		'Proceq.ErrorEmailNotVerified': 'Адрес электронной почты еще не был проверен',
		'Proceq.ServerError': 'Ошибка сервера',
		'Proceq.TooManyActionEmailRequests': 'Слишком много запросов. Подождите, пока сообщения придут на вашу почту',
		'Proceq.ErrorServerUnderMaintenance': 'На сервере идут технические работы',
		'Proceq.TableDataSeriesName': 'Имя',
		'Proceq.TableCreatedOn': 'Создано',
		'Proceq.TableModifiedOn': 'Изменено',
		'Proceq.TableCreatedBy': 'Создано',
		'Proceq.TableNumMean': 'Ср. значение',
		'Proceq.TableNumTotal': 'Всего',
		'Proceq.TableNumStandardDeviation': 'Станд. откл-е',
		'Proceq.TableImpactDevice': 'Датчик',
		'Proceq.TableImpactDeviceHammer': 'Молоток',
		'Proceq.TableMaterial': 'Материал',
		'Proceq.TableScaleUnit': 'Единица шкалы',
		'Proceq.TableActionRefresh': 'Обновить',
		'Proceq.TableActionExpand': 'Расширить',
		'Proceq.TableActionCollapse': 'Свернуть',
		'Proceq.TableActionExportAsPDF': 'Экспортировать как PDF',
		'Proceq.TableActionExportAsCSV': 'Экспортировать как CSV',
		'Proceq.TableActionExportAsJSON': 'Экспортировать как JSON',
		'Proceq.SearchOperationEquals': 'Равные',
		'Proceq.SearchOperationContains': 'Содержит',
		'Proceq.SearchOperationDifferent': 'Отличается',
		'Proceq.SearchOperationGreaterOrEqual': 'Превышает или равен',
		'Proceq.SearchOperationGreater': 'Превышает',
		'Proceq.SearchOperationLesserOrEqual': 'Меньше или равен',
		'Proceq.SearchOperationLesser': 'Меньше',
		'Proceq.ExportFieldExportDate': 'Дата экспорта',
		'Proceq.ExportFieldLimits': 'Пределы',
		'Proceq.ExportFieldHigh': 'Высоко',
		'Proceq.ExportFieldLow': 'Низко',
		'Proceq.ExportFieldOff': 'Откл.',
		'Proceq.ExportFieldLowerLimit': 'Нижний предел',
		'Proceq.ExportFieldUpperLimit': 'Верхний предел',
		'Proceq.ExportFieldReadings': 'Показатели',
		'Proceq.ExportFieldStandardDeviation': 'Стандартное отклонение',
		'Proceq.ExportFieldRange': 'Диапазон',
		'Proceq.ExportFieldRelativeSpan': 'Относительный интервал',
		'Proceq.ExportFieldMaximum': 'Максимум',
		'Proceq.ExportFieldMinimum': 'Минимум',
		'Proceq.ExportFieldAverage': 'Средний',
		'Proceq.ExportFieldUnit': 'Единица измерения',
		'Proceq.ExportFieldName': 'Имя',
		'Proceq.ExportFieldMeasurementDate': 'Дата измерения',
		'Proceq.ExportFieldLocationLatitude': 'Широта местоположения',
		'Proceq.ExportFieldLocationLongitude': 'Долгота местоположения',
		'Proceq.ExportFieldProbeType': 'Тип датчика',
		'Proceq.ExportFieldProbeSerialNumber': 'Серийный номер датчика',
		'Proceq.ExportFieldProbeBTSerialNumber': 'Серийный номер Bluetooth датчика',
		'Proceq.ExportFieldProbeLastVerificationDate': 'Дата последней проверки датчика',
		'Proceq.ExportFieldPrimaryScale': 'Основная шкала',
		'Proceq.ExportFieldSecondaryScale': 'Дополнительная шкала',
		'Proceq.ExportFieldMaterial': 'Материал',
		'Proceq.ExportFieldStandard': 'Стандартный',
		'Proceq.ExportFieldNoConversion': 'Без конверсии',
		'Proceq.ExportFieldMeasurements': 'Измерения',
		'Proceq.ExportFieldMore': 'Еще',
		'Proceq.ExportFieldLess': 'Меньше',
		'Proceq.ExportFieldStatistics': 'Статистика',
		'Proceq.ExportFieldSettings': 'Настройка',
		'Proceq.DataSeriesLogbook': 'Журнал',
		'Proceq.DataSeriesAllMeasurements': 'Все измерения',
		'Proceq.DataSeriesFlagged': 'Помечено',
		'Proceq.DataSeriesTrash': 'Корзина',
		'Proceq.BrowserNoSupportAudio': 'Ваш браузер не поддерживает звуковые файлы.',
		'Proceq.EmailPasswordRecoverySubject': 'Запрос смены пароля на **?0**',
		'Proceq.EmailPasswordRecoveryBody':
			'Уважаемый(ая) **?0** **?1**<br/><br/>Вы запросили новый пароль. Чтобы сбросить ваш пароль, щелкните по ссылке ниже.<br/><br/><a href="**?2**">Сбросить пароль</a><br/><br/>Если вы не запрашивали смену пароля, проигнорируйте это сообщение. Его срок истечет через 12 часов.<br/><br/>Ваша команда Proceq Team<br/><br/>**?3**',
		'Proceq.EmailRegistrationSubject': 'Завершение регистрации для **?0**',
		'Proceq.EmailRegistrationBody':
			'Уважаемый(ая) **?0** **?1**<br/><br/>Благодарим вас за регистрацию на **?5**. Завершите регистрацию, щелкнув по ссылке ниже:<br/><br/><a href="**?2**">**?2**</a><br/><br/>Данное сообщение было автоматически создано после ввода регистрационных данных. Если вы не выполняли эту регистрацию, проигнорируйте это сообщение.<br/><br/> По завершении регистрации вы соглашаетесь с нашей <a href="**?3**">политикой конфиденциальности</a>.<br/><br/>Ваша команда Proceq Team<br/><br/>**?4**',
		'Proceq.LogbookActionNewMeasurement': 'Новое измерение',
		'Proceq.LogbookPropertyCreatedSeriesInFolder': 'Созданная серия "**?0**" в папке "**?1**"',
		'Proceq.LogbookPropertyRenamedSeriesFromTo': 'Серия была переименована с "**?0**" на "**?1**"',
		'Proceq.LogbookPropertyMovedSeriesFromToTrash': 'Серия была перемещена из папки "**?0**" в корзину',
		'Proceq.LogbookPropertyMovedSeriesFromTrashTo': 'Серия была перемещена из папки "**?0**"',
		'Proceq.LogbookPropertyMovedSeriesFromTo': 'Серия была перемещена из папки "**?0**" в папку "**?1**"',
		'Proceq.LogbookPropertySecondaryScaleChangedFromTo': 'Дополнительная шкала изменена с **?0** на **?1**',
		'Proceq.LogbookPropertyMaterialChangedFromTo': 'Материал изменен с **?0** на **?1**',
		'Proceq.LogbookPropertyMaxLimitChangedFromTo': 'Верхний предел изменен с **?0** на **?1**',
		'Proceq.LogbookPropertyMinLimitChangedFromTo': 'Нижний предел изменен с **?0** на **?1**',
		'Proceq.LogbookPropertyStandardChangedFromTo': 'Стандарт изменен с **?0** на **?1**',
		'Proceq.LogbookPropertyTextCommentAdded': 'Текстовый комментарий добавлен',
		'Proceq.LogbookPropertyTextCommentDeletedOn': 'Удалено **?0**',
		'Proceq.LogbookPropertyMeasurementAdded': '**?0** добавлено',
		'Proceq.LogbookPropertyMeasurementExcluded': '**?0** исключено',
		'Proceq.LogbookPropertyMeasurementIncluded': '**?0** включено',
		'Proceq.LogbookPropertyMeasurementDeleted': '**?0** удалено',
		'Proceq.LogbookPropertyAttachmentDeletedOn': 'Удалено **?0**',
		'Proceq.LogbookPropertyPhotoAdded': 'Фотография добавлена',
		'Proceq.LogbookPropertyAudioAdded': 'Аудиокомментарий добавлен',
		'Proceq.LogbookPropertySeriesFlagged': 'Серии помечены',
		'Proceq.LogbookPropertySeriesUnflagged': 'С серий снят выбор',
		'Proceq.LogbookPropertySerialNumberShort': 'С/Н',
		'Proceq.LogbookPropertyBluetoothModuleShort': 'BTM S/N',
		'Proceq.LogbookPropertyVerified': 'Проверено',
		'Proceq.LogbookPropertyContractValidUntil': 'Договор действителен до',
		'Proceq.LogbookPropertyOperator': 'Оператор',
		'Proceq.LogbookTitle': 'Журнал',
		'Proceq.LogbookDownload': 'Загрузка',
		'Proceq.LogbookLoadingData': 'Данные загружаются....',
		'Proceq.LogbookClose': 'Закрыть',
		'Proceq.FormCompany': 'Компания',
		'Proceq.FormScreeningEagleID': 'Screening Eagle ID (Email)',
		'Proceq.FormEmailGroupID': 'Адрес электронной почты',
		'Proceq.FormCustomerEmail': 'Адрес электронной почты пользователя',
		'Proceq.FormProbeSN': 'С/Н датчика',
		'Proceq.FormBluetoothSN': 'С/Н',
		'Proceq.FormBluetoothHWID': 'ИН апп. обесп.',
		'Proceq.FormExpirationDate': 'Дата истечения срока',
		'Proceq.TitleRegisterProbeSNs': 'Зарегистрировать договор аренды',
		'Proceq.ButtonSubmit': 'Подтвердить',
		'Proceq.ButtonReset': 'Сбросить',
		'Proceq.ButtonResetPassword': 'Сбросить пароль',
		'Proceq.MessageSuccesfullyInserted': 'Успешно вставлено',
		'Proceq.MessageSuccesfullyEmailConfirmed': 'Ваш адрес электронной почты подтвержден',
		'Proceq.MessagePasswordSuccesfullyChanged': 'Пароль успешно изменен',
		'Proceq.MessageSuccesfullyUpdated': 'Успешно обновлено',
		'Proceq.MessageAccountCreated':
			'Сообщение с подтверждением регистрации было отправлено вам. Щелкните по ссылке в сообщении электронной почты для завершения процесса регистрации',
		'Proceq.MessageEmailForgotPassword':
			'Вам было отправлено сообщение на электронную почту с дальнейшими указаниями',
		'Proceq.MessageEmailResendConfirm':
			'Вам было отправлено сообщение на электронную почту с дальнейшими указаниями',
		'Proceq.MessageLoggingIn': 'Выполняется вход...',
		'Proceq.LoginNeedAccount': 'Требуется учетная запись?',
		'Proceq.LoginCreateAccount': 'Создать учетную запись',
		'Proceq.TitleForgotPassword': 'Забыли пароль?',
		'Proceq.TitleResendConfirmation': 'Сбросить сообщение подтверждения',
		'Proceq.FormGroup': 'Группа',
		'Proceq.FormFirstName': 'Имя',
		'Proceq.FormLastName': 'Фамилия',
		'Proceq.FormEmail': 'Адрес электронной почты',
		'Proceq.FormCity': 'Город',
		'Proceq.FormPassword': 'Пароль',
		'Proceq.FormPasswordNew': 'Новый пароль',
		'Proceq.FormPasswordOld': 'Старый пароль',
		'Proceq.FormConfirmPassword': 'Подтвердить пароль',
		'Proceq.FormConfirmPasswordNew': 'Подтвердить новый пароль',
		'Proceq.ButtonRegister': 'Зарегистрироваться',
		'Proceq.ButtonSignIn': 'Войти',
		'Proceq.TitleSignIn': 'Войти',
		'Proceq.LoginAlreadyRegistered': 'Уже зарегистрированы?',
		'Proceq.TextOrSignInUsing': 'Либо войдите с помощью',
		'Proceq.NavSearchSerials': 'Найти договоры',
		'Proceq.NavRegisterProbeSNs': 'Договор регистрации',
		'Proceq.NavCreateStaffAccounts': 'Создать учетные записи сотрудников',
		'Proceq.NavHistoryLog': 'Журнал событий',
		'Proceq.NavMeasurementsData': 'Данные измерений',
		'Proceq.NavChangePassword': 'Изменить пароль',
		'Proceq.NavLogout': 'Выйти',
		'Proceq.NavUpdateProfile': 'Обновить профиль',
		'Proceq.TitleUpdateProfile': 'Обновить профиль',
		'Proceq.NavCreateCustomersAccounts': 'Создать учетную запись клиента',
		'Proceq.ButtonSearch': 'Поиск',
		'Proceq.ButtonUpdate': 'Обновить',
		'Proceq.ButtonCreate': 'Создать',
		'Proceq.ButtonChange': 'Изменить',
		'Proceq.FormSearchBy': 'Поиск по',
		'Proceq.FormValue': 'Значение',
		'Proceq.FormAccountType': 'Тип учетной записи',
		'Proceq.FormAccountTypeAdmin': 'Администратор',
		'Proceq.FormAccountTypeBU': 'Подразделение',
		'Proceq.FormCompanyName': 'Название компании',
		'Proceq.FormAddress': 'Адрес',
		'Proceq.FormZipCode': 'Почтовый индекс',
		'Proceq.FormCountry': 'Страна',
		'Proceq.FormPhoneNumber': 'Телефон',
		'Proceq.FormCompanyWebsite': 'Веб-сайт компании',
		'Proceq.FormJobTitle': 'Название работы',
		'Proceq.FormIndustry': 'Индустрия',
		'Proceq.FormSalutation': 'Приветствие',
		'Proceq.FormCompanySize': 'Размер компании',
		'Proceq.FormLanguage': 'Язык',
		'Proceq.FormContactByProceq': 'Связь с Proceq',
		'Proceq.LanguageEnglish': 'Английский',
		'Proceq.LanguageJapanese': 'Японский',
		'Proceq.LanguageChinese': 'Китайский',
		'Proceq.LanguageGerman': 'Немецкий',
		'Proceq.TextYes': 'Да',
		'Proceq.TextNo': 'Нет',
		'Proceq.FormNewsletter': 'Новостная рассылка',
		'Proceq.TitleSearchSerials': 'Найти договоры',
		'Proceq.TitleChangePassword': 'Изменить пароль',
		'Proceq.TabLoginCustomers': 'Клиенты',
		'Proceq.TabLoginSingleUser': 'Один пользователь',
		'Proceq.TabLoginGroup': 'Группа',
		'Proceq.TextRemainingXDays': 'Остается **?0** дней',
		'Proceq.TextInfoShort': 'Инф.',
		'Proceq.TextIAgreeTerms': 'Я согласен с <a>условиями</a>',
		'Proceq.ErrorDisplayTitleError': 'Ошибка',
		'Proceq.ErrorDisplayTitleWarning': 'Предупреждение',
		'Proceq.LoginBackLogin': 'Назад к имени учетной записи',
		'Proceq.LoginForgotPasswordQuestion': 'Забыли пароль?',
		'Proceq.LoginResendConfirmationEmail': 'Снова отправить сообщение подтверждения',
		'Proceq.LoginRegisterAccountTextPart': 'Если у вас нет учетной записи, то',
		'Proceq.LoginRegisterAccountLinkPart': 'зарегистрируйтесь здесь',
		'Proceq.LoginProceqUseOnly': 'Предназначено только для внутреннего использования Proceq',
		'Proceq.ProfileEnterInfo': 'Перед тем как продолжить, введите информацию о вашем профиле',
		'Proceq.TextInfoExclamation': 'Информация!',
		'Proceq.FooterLegalPrivacy': 'Юридическая информация и конфиденциальность',
		'Proceq.JQGridTableRecordText': 'Вид {0} - {1} из {2}',
		'Proceq.JQGridTableEmptyRecords': 'Отсутствуют записи для просмотра',
		'Proceq.JQGridTableLoadText': 'Загрузка...',
		'Proceq.JQGridTablePaginationText': 'Страница {0} из {1}',
		'Proceq.JQGridTableSavingText': 'Сохранение...',
		'Proceq.JQGridTableFirstPage': 'Первая страница',
		'Proceq.JQGridTableLastPage': 'Последняя страница',
		'Proceq.JQGridTableNextPage': 'Следующая страница',
		'Proceq.JQGridTablePreviousPage': 'Предыдущая страница',
		'Proceq.JQGridTableRecordsPerPage': 'Записей на каждой странице',
		'Proceq.JQGridTablePageText': 'Страница:',
		'Proceq.JQGridTableNoMoreRecs': 'Записей больше нет...',
		'Proceq.ExportColumnCSVNo': 'Нет.',
		'Proceq.ExportColumnCSVSingleReadings': 'Одиночные показатели',
		'Proceq.ExportColumnCSVMeasurementValue': 'Значение измерения',
		'Proceq.ExportColumnCSVAngleDegrees': 'Угол (°)',
		'Proceq.ExportColumnCSVExcluded': 'Исключено',
		'Proceq.ExportColumnCSVSeriesName': 'Имя серии',
		'Proceq.ExportColumnCSVImpactDevice': 'Твердомер',
		'Proceq.ExportColumnCSVImpactDeviceSN': 'С/Н твердомера',
		'Proceq.ExportColumnCSVImpactDeviceVerification': 'Проверка твердомера',
		'Proceq.ExportColumnCSVImpactDeviceHammerVerification': 'Проверка молотка',
		'Proceq.ExportColumnCSVImpactDeviceHammer': 'Молоток',
		'Proceq.ExportColumnCSVImpactDeviceHammerSN': 'С/Н молотка',
		'Proceq.ExportColumnCSVBluetoothModuleSN': 'С/Н модуля Bluetooth',
		'Proceq.ExportColumnCSVConversionStandard': 'Стандарт конверсии',
		'Proceq.ExportColumnCSVScale': 'Масштаб',
		'Proceq.ExportColumnCSVUnit': 'Единица измерения',
		'Proceq.ExportColumnCSVReadings': 'Показатели',
		'Proceq.ExportColumnCSVStandardDeviation': 'Станд. откл.',
		'Proceq.ExportColumnCSVRelativeSpan': 'Отн. пролет (%)',
		'Proceq.ExportColumnCSVDebugDataSeriesName': 'Имя серии',
		'Proceq.ExportColumnExportDate': 'Дата экспорта',
		'Proceq.ExportColumnUpperLimit': 'Верхний предел',
		'Proceq.ExportColumnLowerLimit': 'Нижний предел',
		'Proceq.ExportColumnLimits': 'Пределы',
		'Proceq.ExportColumnHigh': 'Высоко',
		'Proceq.ExportColumnLow': 'Низко',
		'Proceq.ExportColumnValueOff': 'Откл.',
		'Proceq.ExportColumnValueNoConversion': 'Без конверсии',
		'Proceq.ExportColumnValueNoConversionShort': 'Отс.Конв.',
		'Proceq.ExportColumnReadings': 'Показатели',
		'Proceq.ExportColumnStandardDeviation': 'Стандартное отклонение',
		'Proceq.ExportColumnRange': 'Диапазон',
		'Proceq.ExportColumnRelativeSpan': 'Относительный интервал',
		'Proceq.ExportColumnMaximum': 'Максимум',
		'Proceq.ExportColumnMinimum': 'Минимум',
		'Proceq.ExportColumnAverage': 'Средний',
		'Proceq.ExportColumnUnit': 'Единица измерения',
		'Proceq.ExportColumnName': 'Имя',
		'Proceq.ExportColumnDataSeriesID': 'ИН серии',
		'Proceq.ExportColumnFolderName': 'Имя папки',
		'Proceq.ExportColumnCreatedDate': 'Дата создания',
		'Proceq.ExportColumnCreatedDateOnly': 'Дата создания',
		'Proceq.ExportColumnCreatedTime': 'Время создания',
		'Proceq.ExportColumnLastModifiedDate': 'Дата последнего изменения',
		'Proceq.ExportColumnLastModifiedDateOnly': 'Дата последнего изменения',
		'Proceq.ExportColumnLastModifiedTime': 'Время последнего изменения',
		'Proceq.ExportColumnMeasurementAvgHardness': 'Стандартная прочность измерения',
		'Proceq.ExportColumnLocationLatitude': 'Широта местоположения',
		'Proceq.ExportColumnLocationLongitude': 'Долгота местоположения',
		'Proceq.ExportColumnProbeType': 'Тип',
		'Proceq.ExportColumnProbeSN': 'Серийный номер',
		'Proceq.ExportColumnBTMSN': 'Серийный номер',
		'Proceq.ExportColumnProbeLastModifiedDate': 'Дата последней проверки',
		'Proceq.ExportColumnProbeHardwareRevision': 'Ревизия аппаратного обеспечения',
		'Proceq.ExportColumnProbeFirmwareRevision': 'Ревизия микропрограммного обеспечения',
		'Proceq.ExportColumnBTMHardwareRevision': 'Ревизия аппаратного обеспечения',
		'Proceq.ExportColumnBTMFirmwareRevision': 'Ревизия микропрограммного обеспечения',
		'Proceq.ExportColumnAppPlatform': 'Платформа',
		'Proceq.ExportColumnVersion': 'Версия',
		'Proceq.ExportColumnOperator': 'Оператор',
		'Proceq.ExportColumnPrimaryScale': 'Основная шкала',
		'Proceq.ExportColumnSecondaryScale': 'Дополнительная шкала',
		'Proceq.ExportColumnMaterial': 'Материал',
		'Proceq.ExportColumnConversionStandard': 'Стандарт конверсии',
		'Proceq.ExportColumnAngleCorrection': 'Корректировка угла',
		'Proceq.ExportColumnValueAuto': 'Автоматически',
		'Proceq.ExportColumnTableAngleGrades': 'Угол (°)',
		'Proceq.ExportColumnTableLegendExcluded': '* Исключено',
		'Proceq.ExportColumnMeasurementBarGraph': 'Столбчатый график измерений',
		'Proceq.ExportColumnTableView': 'Табличное представление',
		'Proceq.ExportColumnStatistics': 'Статистика',
		'Proceq.ExportColumnSettings': 'Настройка',
		'Proceq.ExportColumnImpactDeviceInfo': 'Информация о твердомере',
		'Proceq.ExportColumnImpactDeviceHammerInfo': 'Информация о молотке',
		'Proceq.ExportColumnBluetoothModuleInfo': 'Информация о модуле Bluetooth',
		'Proceq.ExportColumnApplicationInfo': 'Информация о приложении',
		'Proceq.ExportColumnAttachedPhotos': 'Прикрепленные фотографии',
		'Proceq.ButtonActions': 'Действия',
		'Proceq.SerialNotInServiceFTP': 'С/Н отсутствует в Службе FTP',
		'Proceq.ActionResultsToCSV': 'Результаты экспорта в виде CSV',
		'Proceq.ErrorDisplayTitleSuccess': 'Успешно',
		'Proceq.ErrorNoMultipleTargetsAllowed': 'Невозможно выполнить действие для нескольких рядов',
		'Proceq.MessageActionSuccesfullyPerformed': 'Действие успешно выполнено',
		'Proceq.EmailReminderContractUserSubject': 'Уведомление об истечении срока договора',
		'Proceq.EmailReminderContractUserBody':
			'Уважаемый пользователь<br/><br/>Ваш срок аренды для  **?0** истечет **?1**.<br/><br/>Ваш договор будет автоматически продлен на 12 месяцев согласно <a href="http://www.proceq.com/fileadmin/user_upload/Proceq_General_Terms___Conditions_of_Rental_10112016.pdf">нашим "Общим условиям аренды"</a>. Вы получите счет отдельно. Оплатите его вовремя, чтобы продолжить пользоваться вашим продуктом Equotip Live.<br/><br/>Свяжитесь с вашим <a href="http://www.proceq.com/contact/proceq-network/">местным торговым представителем</a> при возникновении вопросов.<br/><br/>Ваша команда Proceq Team<br/><br/>**?2**',
		'Proceq.EmailReminderContractAdminSubject': 'Уведомление об истечении срока договора',
		'Proceq.ErrorNoTargetSelected': 'Для действия не выбрана цель',
		'Proceq.ErrorDisplayTitleInfo': 'Инф.',
		'Proceq.TextSharingSeriesUrlLinkCreated':
			'К серии предоставлен общий доступ по ссылке:<br/>**?0**<br/><a class="link-copy-clipboard" href="javascript:void(0);" onclick="**?1**">Копировать в буфер обмена</a><br/>',
		'App.ShareMeasurementsSuccess': 'К серии предоставлен общий доступ по ссылке',
		'App.ShareMeasurementsCopyAction': 'Копировать в буфер обмена',
		'Proceq.TextPleaseWait': 'Подождите...',
		'Proceq.TextPerformingServerRequest': 'Выполняется серверный запрос',
		'Proceq.TextPerformingAction': 'Выполняется действие',
		'Proceq.ButtonCreateAccountChinaBack': 'Сброс',
		'Proceq.TitleCreateAccountChina': 'Клиент из Китая',
		'Proceq.BodyCreateAccountChina':
			'Если вы клиент из Китая, перейдите по по <a href="**?0**">**?1**</a>, чтобы зарегистрироваться или выполнить вход',
		'Proceq.LoginCompatibiltyInfo': 'Информация о совместимости <a href="**?0**">Proceq Apps</a>',
		'Proceq.TextRedirectingToRegion': 'Перенаправление в ваш регион...',
		'Proceq.EmailReminderContractExpiredTodayUserSubject': 'Истечение срока договора',
		'Proceq.EmailReminderContractExpiredTodayUserBody':
			'Уважаемый пользователь<br/><br/>Ваш срок аренды для **?0** истек.<br/><br/>Мы сожалеем, что вы больше не пользуетесь Proceq Live, но мы будем всегда рады вашему возвращению.<br/><br/>Чтобы возобновить договор, свяжитесь с вашим <a href="http://www.proceq.com/contact/proceq-network/">местным торговым представителем</a>.<br/><br/>Ваша команда Proceq Team<br/><br/>**?1**',
		'Proceq.EmailRentalPeriodChangedSubject': 'Измененный срок аренды',
		'Proceq.EmailRentalPeriodChangedBody':
			'Уважаемый пользователь<br/><br/>Срок истечения вашего договора аренды для **?0** был изменен. Срок вашего договора аренды истечет **?1**.<br/><br/>Свяжитесь с вашим <a href="http://www.proceq.com/contact/proceq-network/">местным торговым представителем</a> при возникновении вопросов.<br/><br/>Искренне ваши Proceq Team<br/><br/>**?2**',
		'Proceq.FormExpiresIn': 'Истечет через (дней)',
		'Proceq.SelectTimeWeek': 'неделя',
		'Proceq.SelectTimeDay': 'день',
		'Proceq.SelectTimeMonth': 'месяц',
		'Proceq.GraphNoDataAvailable': 'отсутствуют данные',
		'Proceq.GraphMaterial': 'Группа материалов',
		'Proceq.GraphSecondaryScale': 'Шкала конверсии',
		'Proceq.GraphStandard': 'Стандарт конверсии',
		'Proceq.GraphMeasurementCount': 'Число ударов',
		'Proceq.GraphMeasurementSeriesCount': 'Число серий измерений',
		'Proceq.StatisticsTitle': 'Статистика',
		'Proceq.StatisticsCreatedSeries': 'созданная серия измерений',
		'Proceq.StatisticsModifiedSeries': 'измененная серия измерений',
		'Proceq.StatisticsPhotos': 'фотографии',
		'Proceq.StatisticsAudioFiles': 'звуковые файлы',
		'Proceq.StatisticsUsedStorage': 'использованное место в хранилище',
		'Proceq.StatisticsFolder': 'Папка',
		'Proceq.StatisticsModified': 'Изменено',
		'Proceq.StatisticsCreated': 'Создано',
		'Proceq.StatisticsAll': 'Все',
		'Proceq.StatisticsCountry': 'Страна',
		'Proceq.StatisticsCompanySize': 'Размер компании',
		'Proceq.StatisticsIndustry': 'Индустрия',
		'Proceq.DateRangeFrom': 'Из',
		'Proceq.DateRangeTo': 'В',
		'Proceq.GraphHistogram': 'Гистограмма прочности',
		'Proceq.GraphSeriesHistogram': 'Серия гистограмм прочности',
		'Proceq.StatisticsImpactDevice': 'Твердомер',
		'Proceq.StatisticsImpactDeviceHammer': 'Молоток',
		'Proceq.GraphExcluded': 'Исключено',
		'Proceq.GraphIncluded': 'Включено',
		'Proceq.GraphWithinTolerance': 'В пределах допуска',
		'Proceq.GraphOutOfTolerance': 'За пределами допуска',
		'Proceq.StatisticsCreatedImpacts': 'Созданные удары (включено/исключено)',
		'Proceq.StatisticsModifiedImpacts': 'Измененные удары (включено/исключено)',
		'Proceq.GraphNoConversion': 'Конверсия отсутствует',
		'Proceq.StatisticsTotalImpacts': 'Общее число ударов',
		'Proceq.StatisticsTotalSeries': 'Общее число серий',
		'Proceq.StatisticsTotalExcluded': 'Общее число исключений',
		'Proceq.StatisticsValuesOutOfLimits': 'Значения вне пределов',
		'Proceq.StatisticsPeriod': 'Срок',
		'Proceq.LabelFrom': 'Из',
		'Proceq.LabelTo': 'В',
		'Proceq.LabelImpacts': 'Удары',
		'Proceq.AbbrevCalendarWeek': 'Календарная неделя',
		'Proceq.eRebNStd_Mean': 'Ср. значение',
		'Proceq.ExportFieldTargetScale': 'Целевой масштаб',
		'Proceq.ExportFieldConversionCurve': 'Кривая конверсии',
		'Proceq.eCC_None': 'Отсутствует',
		'Proceq.eCC_Portland_Cement_J_N': 'Портландцемент J',
		'Proceq.eCC_Early_Strength_J_N': 'Начальная прочность J',
		'Proceq.eCC_Blast_Furnace_J_N': 'Ватержакетная печь J',
		'Proceq.eCC_Average_Curve_J_N': 'Средняя кривая J',
		'Proceq.eSC_R1_dry': 'JSCE-G504 R1 = 0 (сухой)',
		'Proceq.eSC_R1_black_mark': 'JSCE-G504 R1 = +3 (пометка о неблагонадежности)',
		'Proceq.eSC_R1_wet_surface': 'JSCE-G504 R1 = +5 (мокрая поверхность)',
		'Proceq.eCSC_None': 'Отсутствует',
		'Proceq.eCSC_Upper': 'Верхнее',
		'Proceq.eCSC_Under': 'Нижнее',
		'Proceq.eSCC_US_eCSC_None': 'Унифицированная структура',
		'Proceq.eSCC_US_eCSC_Upper': 'Унифицированная структура верхней литой поверхности',
		'Proceq.eSCC_US_eCSC_Under': 'Унифицированная структура нижней литой поверхности',
		'Proceq.eSCC_PC_eCSC_None': 'Подаваемая насосом бетонная смесь',
		'Proceq.eSCC_PC_eCSC_Upper': 'Верхняя литая поверхность подаваемой насосом бетонной смеси',
		'Proceq.eSCC_PC_eCSC_Under': 'Нижняя литая поверхность подаваемой насосом бетонной смеси',
		'Proceq.eSCC_US': 'Унифицированная структура',
		'Proceq.eSCC_PC': 'Подаваемая насосом бетонная смесь',
		'Proceq.eNoOutlier': 'Выброс отсутствует',
		'Proceq.eOutlierLow': 'Низкий выброс',
		'Proceq.eOutlierHigh': 'Высокий выброс',
		'Proceq.eOutlierRedundant': 'Незначительный выброс',
		'Proceq.LogbookPropertyConversionCurveChangedFromTo': 'Кривая конверсии изменена с **?0** на **?1**',
		'Proceq.LogbookPropertyConversionCurveChangedTo': 'Кривая конверсии изменена на **?0**',
		'Proceq.LogbookPropertyTargetScaleChangedFromTo': 'Целевой масштаб изменен с **?0** на **?1**',
		'Proceq.LogbookPropertySurfaceCorrectionChangedFromTo': 'Корректировка поверхности изменена с **?0** на **?1**',
		'Proceq.LogbookPropertySurfaceCorrectionChangedTo': 'Корректировка поверхности изменена на **?0**',
		'Proceq.LogbookPropertyCarbonationDepthChangedFromTo':
			'Насыщение углекислотой изменено с **?0** мм на **?1** мм',
		'Proceq.LogbookPropertySurfaceConditionChangedFromTo': 'Состояние поверхности изменено с **?0** на **?1**',
		'Proceq.LogbookPropertySurfaceConditionChangedTo': 'Состояние поверхности изменено на **?0**',
		'Proceq.LogbookPropertyConcreteAgeChangedFromTo': 'Возраст бетона изменен с **?0** д до **?1** д',
		'Proceq.LogbookPropertyConcreteAgeChangedTo': 'Возраст бетона изменен на **?0** д',
		'Proceq.ExportColumnCSVOutlierType': 'Выброс',
		'Proceq.ExportColumnCarbonationDepth': 'Глубина насыщения углекислотой',
		'Proceq.ExportColumnConcreteAge': 'Возраст бетона',
		'Proceq.ExportFieldCarbonationDepth': 'Глубина насыщения углекислотой',
		'Proceq.ExportFieldConcreteAge': 'Возраст бетона',
		'Proceq.ValueConcreteAgeDays': '**?0** д',
		'Proceq.ValueCarbonationDepthMms': '**?0** мм',
		'Proceq.ExportColumnConversionCurve': 'Кривая конверсии',
		'Proceq.ExportColumnTableLegendOutlier': '* Выброс',
		'Proceq.ExportColumnTargetScale': 'Целевой масштаб',
		'Proceq.ExportColumnMeasurementAvgCompressiveStrength': 'Средняя прочность на сжатие при измерении',
		'Proceq.ExportColumnEn13791Screening': 'EN 13791 Проверка',
		'Proceq.ExportColumnLowestRebound': 'Нижнее значение отскока',
		'Proceq.ExportColumnMedianRebound': 'Среднее значение отскока',
		'Proceq.ExportColumnCompressiveStrengthClass': 'EN 206 Класс прочности на сжатие',
		'Proceq.EmailIntroDearContractOwner': 'Уважаемый владелец договора',
		'Proceq.ColumnTableSeriesRegionExport': 'Серия',
		'Proceq.ExportColumSeriesRegionExport': 'Серия',
		'Proceq.ExportTitleTestRegionExport': 'Тест регионального экспорта',
		'Proceq.ErrorSchmidtExportInvalidStandard': 'У всех серий должен быть одинаковый стандартный набор',
		'Proceq.ErrorSchmidtExportTooFewSeries': 'Для проверки EN 13791 необходимо не менее 9 серий',
		'Proceq.ErrorSchmidtExportTooManySeries': 'Для одного экспорта можно выбрать максимум 99 серий',
		'Proceq.ErrorSchmidtExportDifferentConfiguration': 'Для серий обнаружены разные конфигурации',
		'Proceq.ErrorSchmidtExportWrongStandard': 'Для экспорта обнаружен неверный стандарт',
		'Proceq.ErrorSchmidtExportInvalidSeries':
			'Согласно выбранному стандарту по крайней мере одна серия является неверной',
		'Proceq.ErrorSchmidtExportSoftwareError': 'Ошибка при генерации экспорта',
		'Proceq.ErrorSchmidtExportOutOfRange': 'Рассчитанный класс прочности находится вне диапазона',
		'Proceq.ButtonExportTestRegionEU': 'Экспорт проверки EN 13791 в виде PDF',
		'Proceq.ButtonExportTestRegionUniformity': 'Экспорт равномерности в виде pdf',
		'Proceq.ButtonExportTestRegionChina': 'Экспорт испытательного региона JGJ T23 в виде PDF',
		'Proceq.ErrorNotYetImplemented': 'Еще не внедрено',
		'Proceq.ErrorSchmidtExportWrongParam':
			'Для серии обнаружен неверный параметр. Например: Неверный пункт для выбранного экспорта',
		'Proceq.ErrorSchmidtExportUnitNotMpa': 'Для региона JGJ пункт обязательно должен быть задан на MPa',
		'Proceq.ErrorSchmidtExportWrongFormFactor':
			'Для региона JGJ конструктивной характеристикой не должен быть 2:1 цилиндр',
		'Proceq.NavSelectView': 'Выбрать вид',
		'Proceq.TextConfirmationAreYouSure': 'Вы уверены?',
		'Proceq.ErrorContractLinkedDifferentImpactDevice':
			'Договор невозможно использовать вместе с текущим серийным номером твердомера',
		'Proceq.TitleShareByUrl': 'Общий доступ посредством URL',
		'Proceq.ExportTitleVerification': 'Отчет о проверке:',
		'Proceq.ExportVerificationStatusFailed': 'Сбой',
		'Proceq.ExportVerificationStatusPassed': 'Успешно',
		'Proceq.ExportColumnTestVerificationResult': 'Результат проверки',
		'Proceq.ExportColumnTestBlockTolerance': 'Неопределенность испытуемого образца (Ucrm) k=2',
		'Proceq.ExportColumnTestBlockNominalHardness': 'Номинальная прочность испытуемого образца',
		'Proceq.ExportColumnTestBlockSN': 'Серийный номер испытуемого образца',
		'Proceq.ExportColumnTestBlockName': 'Название испытуемого образца',
		'Proceq.ExportColumnVerificationStd': 'Стандарт проверки',
		'Proceq.ExportColumnVerificationCriteria': 'Критерий проверки',
		'Proceq.VerificationCriteriaAverage5Span5Perc': 'средний +/- 5%, диапазон +/- 5%',
		'Proceq.VerificationCriteriaReadings6HL': 'показатели +/- 6 HL',
		'Proceq.VerificationCriteriaReadings3Perc': 'показатели +/- 3%',
		'Proceq.VerificationCriteriaAverage5Perc': 'средний +/- 5%',
		'Proceq.VerificationCriteriaAverage2R': 'средний +/- 2 R',
		'Proceq.VerificationCriteriaReadings3R': 'показатели +/- 3 R',
		'Proceq.StandardEnumCustom': 'Пользовательский',
		'Proceq.MaterialEnumCustom': 'Пользовательский',
		'Proceq.ExportColumnCalibrationAnvilName': 'Имя калибровочного блока',
		'Proceq.ExportColumnCalibrationAnvilSN': 'Серийный номер калибровочного блока',
		'Proceq.ExportColumnCalibrationAnvilReboundValue': 'Значение отскока калибровочного блока',
		'Proceq.ExportColumnCalibrationAnvilTolerance': 'Погрешность калибровочного блока',
		'Proceq.ExportColumnTestLoadInfo': 'Инф. о тестовой нагрузке',
		'Proceq.NavVerificationData': 'Данные проверки',
		'Proceq.NavCustomData': 'Пользовательские данные',
		'Proceq.TableColumnVerificationResult': 'Результат',
		'Proceq.TitleVerifications': 'Проверки',
		'Proceq.LogBookTestBlock': 'Испытуемый образец',
		'Proceq.LogBookCalibrationAnvil': 'Калибровочный блок',
		'Proceq.LogbookPropertyTestBlockChangedFromTo': 'Испытуемый образец был изменен с "**?0**" на "**?1**"',
		'Proceq.LogbookPropertyTestBlockChangedTo': 'Испытуемый образец был изменен на "**?0**"',
		'Proceq.LogbookPropertyCalibrationAnvilChangedFromTo': 'Калибровочный блок был изменен с **?0** на **?1**',
		'Proceq.LogbookPropertyCalibrationAnvilChangedTo': 'Калибровочный блок был изменен на **?0**',
		'Proceq.LogbookPropertyCreatedVerification': 'Созданная проверка "**?0**"',
		'Proceq.LogbookPropertyRenamedVerificationFromTo': 'Проверка была переименована с "**?0**" на "**?1**"',
		'Proceq.LogbookResultVerificationFailed': 'Сбой проверки',
		'Proceq.LogbookResultVerificationPassed': 'Проверка пройдена',
		'Proceq.LogbookPropertyTestLoadChangedFromTo': 'Тестовая нагрузка изменена с **?0** на **?1**',
		'Proceq.LogbookPropertyTestLoadChangedTo': 'Тестовая нагрузка изменена на **?0**',
		'Proceq.LogBookTestLoad': 'Тестовая нагрузка',
		'Proceq.FieldWebOriginalCurve': 'Исходный',
		'Proceq.FieldWebCustomCurve': 'Пользовательский',
		'Proceq.ExportFieldValuePair': 'Пара значений',
		'Proceq.TableBaseMaterial': 'Основной материал',
		'Proceq.TableDescription': 'Описание',
		'Proceq.NavCustomMaterial': 'Пользовательский материал',
		'Proceq.FolderCustomMaterials': 'Пользовательские материалы',
		'Proceq.SchmidtCurveTypePolynomial': 'fck = a + b*R + c*R² (Многочлен)',
		'Proceq.SchmidtCurveTypeExponencial': 'fck = a.e^(b*R) (Экспонента)',
		'Proceq.SchmidtCurveTypePolynomialShort': 'Многочлен',
		'Proceq.SchmidtCurveTypeExponencialShort': 'Экспонента',
		'Proceq.ExportFieldCurveType': 'Тип кривой',
		'Proceq.TableActionEdit': 'Редактировать',
		'Proceq.TableActionAdd': 'Добавить',
		'Proceq.WebAccountActivatedTitle': 'Добро пожаловать в Proceq Live,',
		'Proceq.WebAccountActivatedBodyLine1':
			'Ваша учетная запись была успешно активирована. Теперь она готовка к использованию!',
		'Proceq.WebAccountActivatedProceqTeam': 'Proceq Team',
		'Proceq.NavDashboard': 'Панель управления',
		'App.SchmidtFormFactorRAngle': 'Угол R скорректирован',
		'App.SchmidtFormFactorQAngle': 'Значение Q',
		'App.ShareMeasurementsCopySuccess': 'Скопировано!',
		'App.ShareMeasurementsCopyFailed': 'Ваш браузер не поддерживает копирование. Пожалуйста, скопируйте вручную.',
		'App.SystemFolders': 'Системные папки',
		'Proceq.DataSeriesDemo': 'Демонстрация',
		'Proceq.DataSeriesImported': 'Импортировано',
		'Antenna Spacing [{unit}]': 'Интервал между антеннами [{unit}]',
		'App Version': 'Версия приложения',
		'Base Unit S/N': 'Базовый блок S / N',
		'Calculated Depth': 'Расчетная глубина',
		Comment: 'Комментарий',
		'Contract ID': 'ID контракта',
		'Distance X [{unit}]': 'Расстояние X [{unit}]',
		'Distance Y [{unit}]': 'Расстояние Y [{unit}]',
		'Export HTML Format Version': 'Экспортировать версию в формате HTML',
		'File ID': 'Идентификатор файла',
		'File Name': 'Имя файла',
		'Firmware Version': 'Версия прошивки',
		'Grid Size X [{unit}]': 'Размер сетки X [{unit}]',
		'Grid Size Y [{unit}]': 'Размер сетки Y [{unit}]',
		'Grid Spacing X [{unit}]': 'Шаг сетки X [{unit}]',
		'Grid Spacing Y [{unit}]': 'Интервал сетки Y [{unit}]',
		'Hardware Version': 'Версия аппаратного обеспечения',
		Line: 'Линия',
		'MAC Address': 'MAC-адрес',
		'Marker Number': 'Номер маркера',
		'Measurement Presets': 'Пресеты измерений',
		Mode: 'Режим',
		Name: 'имя',
		Objects: 'Объекты',
		'Probe S/N': 'Зонд S / N',
		Product: 'Продукт',
		'Repetition Rate [{unit}]': 'Частота повторения [{unit}]',
		Resolution: 'разрешение',
		'Sampling Rate [{unit}]': 'Частота выборки [{unit}]',
		'Scan Distance [{unit}]': 'Расстояние сканирования [{unit}]',
		'Scan Length [{unit}]': 'Длина сканирования [{unit}]',
		'Set Depth': 'Установить глубину',
		'Set Size': 'Установить размер',
		Tag: 'Тег',
		'Tag Type': 'Тип тега',
		'Time Window [{unit}]': 'Временное окно [{unit}]',
		Units: 'Единицы',
		'User / Probe Info': 'Информация о пользователе / датчике',
		Markers: 'Маркеры',
		'Created {fileName} in folder {folderName}': 'Создано {fileName} в папке {folderName}',
		'Mode: {value}': 'Режим: {value}',
		'Auth.LoggingIn': 'Идет авторизация…',
		'Auth.LoggingOut': 'Выход…',
	},
};
