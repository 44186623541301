// @flow
import React from 'react';
import GPR from 'components/measurements/HTMLExport/GPR';
import GprSoil from 'components/measurements/HTMLExport/GPR_SOIL';
import PUNDIT from 'components/measurements/HTMLExport/PUNDIT';
import PI8000 from 'components/measurements/HTMLExport/PI8000';
import FDL from 'components/measurements/HTMLExport/FDL';
import { getFileNameWithExpectedExtensions } from 'utils/fileUtils';
import { ProductConstants } from '../../contracts/contractConsts';

export type DerivedProps = {
	scanType: ScanType,
	product: ProductCode,
	productModel: string,
	isMetric: boolean,
	convert: (string | number, string) => string | number,
	showTitle?: boolean,
};

const Measurement = ({ data, users }: { data: MeasurementFullData, users: User[] }) => {
	const product = data.productFamily.toUpperCase();

	const logsWithAttachmentFilenames = data.logs.map(log => {
		const idToCheck = log.content.content || log.content.attachmentId || log.content.markerContent;
		const attachment = data.attachments.find(a => a.id === idToCheck);

		let attachmentName;
		if (attachment && attachment.fileNames) {
			attachmentName = getFileNameWithExpectedExtensions(attachment.fileNames, [
				'png',
				'jpg',
				'jpeg',
				'm4a',
				'aac',
			]);

			if (attachmentName) {
				log.content.attachmentName = attachmentName;
			}
		}

		return log;
	});

	data.logs = logsWithAttachmentFilenames;

	switch (product) {
		case 'GPR':
			return <GPR data={data} users={users} />;
		case 'GPR_SOIL':
			return <GprSoil data={data} users={users} />;
		case 'PUNDIT':
			return <PUNDIT data={data} users={users} />;
		case ProductConstants.PIT_IE:
			return <PI8000 data={data} users={users} />;
		case 'FDL':
			return <FDL data={data} users={users} />;
		default:
			return null;
	}
};

export default Measurement;
