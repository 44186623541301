// @flow
import React from 'react';
import { FormattedMessage } from 'react-intl';

export const Favourite = ({ log }: EquotipLogbookEntryProps) =>
	log.content.status ? (
		<FormattedMessage id="Proceq.LogbookPropertySeriesFlagged" />
	) : (
		<FormattedMessage id="Proceq.LogbookPropertySeriesUnflagged" />
	);

export default Favourite;
