// @flow
import { has } from 'lodash';
import { getLabelFromGlobalDataKey } from 'utils/registryHelper';
import {
	getResolutionForScale,
	getSchmidtScaleIdOfFromFactorAndUnitId,
	getStandardNameOfStandardId,
	getMaterialNameOfMaterialId,
	getScaleInfoOfScaleId,
} from './conversionsHelper';
import handleFormatMessage from './handleFormatMessage';

export const getDataForGraph = (infoForDataMeasuremets: Object) => {
	const dataForGraph = {
		dataoriginal: [],
		datacustom: [],
		sourceunit: infoForDataMeasuremets.SETTINGS.SOURCEUNIT.VALUE,
		targetunit: infoForDataMeasuremets.SETTINGS.TARGETUNIT.VALUE,
	};
	if (has(infoForDataMeasuremets, 'content.meta.curves')) {
		for (const typeCurve in infoForDataMeasuremets.content.meta.curves) {
			let saveProperty = 'dataoriginal';
			if (typeCurve === 'target') {
				saveProperty = 'datacustom';
			}
			for (
				let indexPoint = 0;
				indexPoint < infoForDataMeasuremets.content.meta.curves[typeCurve].length;
				indexPoint++
			) {
				const thisPoint = infoForDataMeasuremets.content.meta.curves[typeCurve][indexPoint];
				dataForGraph[saveProperty][indexPoint] = [
					parseFloat(getResolutionForScale(dataForGraph.sourceunit, thisPoint[0])),
					parseFloat(getResolutionForScale(dataForGraph.targetunit, thisPoint[1])),
				];
			}
		}
	}
	return dataForGraph;
};

export const getAllParsedDataNeeded = (data: Object, productType: ProductCode, formatMessage: Function) => {
	const toReturn = data;
	toReturn.json = data.content;
	toReturn.SETTINGS = {};
	toReturn.MEASUREMENTS = {};
	let sourceScaleUnit = null;
	let sourceScaleName = null;
	switch (productType) {
		case 'EQUOTIP':
			sourceScaleUnit = 'HLD';
			sourceScaleName = 'Leeb D';
			break;
		case 'SSL':
		case 'SCHMIDT':
			sourceScaleUnit = 'R';
			sourceScaleName = formatMessage({ id: 'App.SchmidtFormFactorRAngle' });
			break;
	}

	let targetScaleUnit = sourceScaleUnit;
	let targetScaleName = sourceScaleName;
	if ('sourceScaleId' in toReturn.json) {
		sourceScaleUnit = getScaleInfoOfScaleId(formatMessage, toReturn.json.sourceScaleId, productType).scaleUnits;
		sourceScaleName = getScaleInfoOfScaleId(formatMessage, toReturn.json.sourceScaleId, productType).scaleName;
	}

	if ('targetScaleId' in toReturn.json) {
		targetScaleUnit = getScaleInfoOfScaleId(formatMessage, toReturn.json.targetScaleId, productType).scaleUnits;
		targetScaleName = getScaleInfoOfScaleId(formatMessage, toReturn.json.targetScaleId, productType).scaleName;
	} else if ('formFactorId' in toReturn.json && 'scaleId' in toReturn.json) {
		const targetScaleId = getSchmidtScaleIdOfFromFactorAndUnitId(toReturn.json.scaleId, toReturn.json.formFactorId);
		targetScaleUnit = getScaleInfoOfScaleId(formatMessage, targetScaleId, productType).scaleUnits;
		targetScaleName = getScaleInfoOfScaleId(formatMessage, targetScaleId, productType).scaleName;
	} else {
		targetScaleUnit = 'R';
		targetScaleName = formatMessage({ id: 'App.SchmidtFormFactorRAngle' });
	}

	if (sourceScaleUnit !== null)
		toReturn.SETTINGS.SOURCEUNIT = { TITLE: 'Proceq.ExportFieldUnit', VALUE: sourceScaleUnit };
	if (sourceScaleName !== null)
		toReturn.SETTINGS.SOURCENAME = { TITLE: 'Proceq.ExportFieldName', VALUE: sourceScaleName };
	if (targetScaleUnit !== null)
		toReturn.SETTINGS.TARGETUNIT = { TITLE: 'Proceq.ExportFieldUnit', VALUE: targetScaleUnit };
	if (targetScaleName !== null)
		toReturn.SETTINGS.TARGETNAME = { TITLE: 'Proceq.ExportFieldName', VALUE: targetScaleName };

	if ('sourceValue' in toReturn.json) {
		toReturn.MEASUREMENTS.SOURCEVALUE = {
			TITLE: ' ',
			VALUE: `${getResolutionForScale(null, toReturn.json.sourceValue, null)} ${
				toReturn.SETTINGS.SOURCEUNIT.VALUE
			}`,
		};
	}
	if ('targetValue' in toReturn.json) {
		toReturn.MEASUREMENTS.TARGETVALUE = {
			TITLE: '  ',
			VALUE: `${getResolutionForScale(null, toReturn.json.targetValue, null)} ${
				toReturn.SETTINGS.TARGETUNIT.VALUE
			}`,
		};
	}

	if ('targetLimitMax' in toReturn.json) {
		const upperLimValue = toReturn.json.targetLimitMax;
		const lowerLimValue = toReturn.json.targetLimitMin;

		if (targetScaleUnit) {
			toReturn.SETTINGS.UPPERLIMIT = {
				TITLE: 'Proceq.ExportFieldUpperLimit',
				VALUE: getResolutionForScale(toReturn.SETTINGS.TARGETUNIT.VALUE, upperLimValue),
			};
			toReturn.SETTINGS.LOWERLIMIT = {
				TITLE: 'Proceq.ExportFieldLowerLimit',
				VALUE: getResolutionForScale(toReturn.SETTINGS.TARGETUNIT.VALUE, lowerLimValue),
			};
			toReturn.SETTINGS.UPPERLIMITWITHUNITS = {
				TITLE: 'Proceq.ExportFieldUpperLimit',
				VALUE: `${toReturn.SETTINGS.UPPERLIMIT.VALUE} ${toReturn.SETTINGS.TARGETUNIT.VALUE}`,
			};
			toReturn.SETTINGS.LOWERLIMITWITHUNITS = {
				TITLE: 'Proceq.ExportFieldLowerLimit',
				VALUE: `${toReturn.SETTINGS.LOWERLIMIT.VALUE} ${toReturn.SETTINGS.TARGETUNIT.VALUE}`,
			};
		}
	}

	if ('curveTypeId' in toReturn.json) {
		const curveTypeStr = handleFormatMessage(
			getLabelFromGlobalDataKey('schmidtcurvetypes', toReturn.json.curveTypeId),
			formatMessage
		);
		toReturn.SETTINGS.CURVETYPE = { TITLE: 'Proceq.ExportFieldCurveType', VALUE: curveTypeStr };
		switch (toReturn.json.curveTypeId) {
			case 0: // polynomial
				toReturn.SETTINGS.CURVEPARAMA = { TITLE: 'a', VALUE: toReturn.json.coefficients[0] };
				toReturn.SETTINGS.CURVEPARAMB = { TITLE: 'b', VALUE: toReturn.json.coefficients[1] };
				toReturn.SETTINGS.CURVEPARAMC = { TITLE: 'c', VALUE: toReturn.json.coefficients[2] };
				break;
			case 1: // exponencial
				toReturn.SETTINGS.CURVEPARAMA = { TITLE: 'a', VALUE: toReturn.json.coefficients[0] };
				toReturn.SETTINGS.CURVEPARAMB = { TITLE: 'b', VALUE: toReturn.json.coefficients[1] };
				break;
		}
	}

	if ('baseMaterialId' in toReturn.json) {
		toReturn.SETTINGS.BASEMATERIAL = {
			TITLE: 'Proceq.ExportFieldMaterial',
			VALUE: getMaterialNameOfMaterialId(null, formatMessage, toReturn.json.baseMaterialId),
		};
	}
	if ('baseStandardId' in toReturn.json) {
		toReturn.SETTINGS.BASESTANDARD = {
			TITLE: 'Proceq.ExportFieldStandard',
			VALUE: getStandardNameOfStandardId(null, formatMessage, toReturn.json.baseStandardId, productType),
		};
	}
	return toReturn;
};

export const getSettingsParametersForProduct = (productType: ProductCode) => {
	let parameters = [];
	switch (productType) {
		case 'EQUOTIP':
			parameters = [
				'SETTINGS.UPPERLIMITWITHUNITS',
				'SETTINGS.LOWERLIMITWITHUNITS',
				'SETTINGS.BASEMATERIAL',
				'SETTINGS.BASESTANDARD',
				'SETTINGS.TARGETNAME',
				'SETTINGS.TARGETUNIT',
			];
			break;
		case 'SSL':
		case 'SCHMIDT':
			parameters = [
				'SETTINGS.UPPERLIMITWITHUNITS',
				'SETTINGS.LOWERLIMITWITHUNITS',
				'SETTINGS.CURVETYPE',
				'SETTINGS.CURVEPARAMA',
				'SETTINGS.CURVEPARAMB',
				'SETTINGS.CURVEPARAMC',
				'SETTINGS.TARGETNAME',
				'SETTINGS.TARGETUNIT',
			];
			break;
	}
	return parameters;
};
export const getPairValueForProduct = (productType: ProductCode) => {
	let parameters = [];
	switch (productType) {
		case 'EQUOTIP':
			parameters = ['MEASUREMENTS.SOURCEVALUE', 'MEASUREMENTS.TARGETVALUE'];
			break;
	}
	return parameters;
};
