// @flow
import React from 'react';
import { FormattedMessage } from 'react-intl';
import Photo from '../Photo';

export const SaveSnapshot = ({ productModel, log }: GPRLogbookEntryProps) => (
	<div>
		<b>
			{productModel === 'fdl' && <FormattedMessage id={`Indication ${log.content.position} : `} />}{' '}
			<FormattedMessage id="App.LogbookPropertySnapshotSaved" />
		</b>
		<Photo mID={log.mID} aID={log.content.attachmentId} attachmentName={log.content.attachmentName} />
	</div>
);

export default SaveSnapshot;
