// @flow
import React, { useEffect } from 'react';
import type { ContextRouter } from 'react-router';
import { Route, Redirect } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { loadProfile } from 'actions/profile';
import Layout from 'components/layout/Layout';
import NewLayout from 'components/layout/NewLayout';
import useIsAuthenticated from '../hooks/useIsAuthenticated';
import routeURLs from './urls';
import { redirectEagleWorkspace, saveLandingPath } from '../utils/authUtils';

export const AuthRoute = ({
	path,
	component,
	session,
	location,
}: {
	path: string,
	component: any,
	session?: 'PROCEQ' | 'TDS', // no session means not limited to either
} & ContextRouter) => {
	const dispatch = useDispatch();
	const {
		sessionType,
		profile,
		scopes,
	}: { sessionType: 'PROCEQ' | 'TDS', profile: any, scopes: string[] } = useSelector(state => ({
		sessionType: state.session.sessionType,
		profile: state.user.user.profile,
		scopes: state.session.scopes,
	}));

	const isAuthenticated = useIsAuthenticated(location);
	const shouldRedirect = (!sessionType && !isAuthenticated) || (session && session !== sessionType);

	useEffect(() => {
		if (!shouldRedirect && !profile) {
			dispatch(loadProfile());
		}
	}, [dispatch, profile]);

	// If only has user access, we should redirect to Eagle Workspace
	if (scopes && scopes.length === 1 && scopes[0] === 'user') {
		redirectEagleWorkspace();
		return null;
	}

	if (shouldRedirect) {
		saveLandingPath();
		return <Redirect to={routeURLs.AUTH} />;
	}

	const Parent = sessionType === 'TDS' ? NewLayout : Layout;

	return (
		<Parent path={path}>
			<Route path={path} component={component} />
		</Parent>
	);
};

export default AuthRoute;
