// @flow
import React, { Fragment } from 'react';
import { sumBy, has, curryRight } from 'lodash';
import { compose } from 'recompose';
import { injectIntl } from 'react-intl';
import type { InjectIntlProvidedProps } from 'react-intl';
import { Col } from 'antd';
import {
	withHighcharts,
	HighchartsChart,
	Title,
	Subtitle,
	PieSeries,
	Legend,
	Tooltip,
	Chart,
	XAxis,
	YAxis,
} from 'react-jsx-highcharts';
import Highcharts from 'highcharts';
import applyDrilldown from 'highcharts/modules/drilldown';
import { rates } from 'constants/conversionRates';

// https://stackoverflow.com/a/42091241/1097483
if (!Highcharts.Chart.prototype.addSeriesAsDrilldown) {
	applyDrilldown(Highcharts);
}

const colors = [
	'#00A1B1',
	'#55C0CB',
	'#8DD5DC',
	'#C6EAED',
	'#1CABB9',
	'#38B5C2',
	'#71CAD3',
	/* '#deebf7', '#f7fbff', */ '#AADFE5',
	'E2F4F6',
];
const pieProps = {
	type: 'pie',
	colors,
	center: ['50%', 130],
	size: 200,
	innerSize: 100,
	showInLegend: true,
	dataLabels: {
		formatter,
		style: {
			fontWeight: 'normal',
		},
	},
};
const legendProps = {
	layout: 'vertical',
	itemMarginTop: 20,
	itemStyle: {
		fontWeight: 'normal',
		textOverflow: undefined,
	},
	labelFormatter: legendFormatter,
	floating: true,
	verticalAlign: 'top',
	y: 350,
	navigation: {
		enabled: false,
	},
};
function formatter() {
	return `
		<strong>${this.point.x} ${this.point.name}</strong>
		<br />
		${rates.bytes(this.y)}
	`;
}
function legendFormatter() {
	return `
		<strong>${this.name}</strong>
		<br />
		${this.legend ? `${this.legend}` : ''}
	`;
}

export const Charts = ({
	primaryTitle,
	primarySubtitle,
	primaryPath,
	secondaryTitle,
	secondarySubtitle,
	secondaryPath,
	total,
	measurements,
	intl,
}: {
	primaryTitle?: string,
	primarySubtitle?: string,
	primaryPath?: {
		label: string,
		key: string,
		totalX: number,
		totalY: number,
	}[],
	secondaryTitle: string,
	secondarySubtitle?: string,
	secondaryPath: {
		label: string,
		legend?: string,
		x: string,
		y: string,
		totalX?: number,
		totalY?: number,
	}[],
	total: number,
	measurements: MeasurementAnalytics,
} & InjectIntlProvidedProps) =>
	primaryTitle && primarySubtitle && primaryPath && secondarySubtitle ? (
		<Fragment>
			<Col lg={24} xl={12}>
				<HighchartsChart
					plotOptions={{
						pie: {
							point: {
								events: {
									// legendItemClick: e => e.preventDefault(),
									legendItemClick: e => {
										if (has(e, 'target.hcEvents.click')) {
											e.target.hcEvents.click[0]();
											return true;
										}
										return false;
									},
								},
							},
						},
					}}
					drilldown={{
						series: secondaryPath.map(({ label, x, y }) => ({
							id: label,
							name: intl.formatMessage({ id: label }),
							data: primaryPath
								.map(({ label: primaryLabel, key }) => ({
									name: intl.formatMessage({ id: primaryLabel }),
									x: measurements[key][x],
									y: measurements[key][y],
								}))
								.filter(dataset => dataset.y > 0)
								.map(dataset => ({ ...dataset, x: dataset.x > 0 ? dataset.x : '' })),
							...pieProps,
						})),
					}}
				>
					<Title>{intl.formatMessage({ id: primaryTitle })}</Title>
					<Subtitle>{intl.formatMessage({ id: primarySubtitle })}</Subtitle>
					<Chart height={620} />
					<Legend
						title={{
							text: intl.formatMessage({ id: 'App.AnalyticsLegend' }),
							style: {
								textDecoration: 'underline',
							},
						}}
						{...legendProps}
					/>
					<XAxis />
					<YAxis />
					<Tooltip pointFormat="{point.y} bytes" />
					<PieSeries
						data={[
							{
								name: intl.formatMessage({ id: 'Proceq.TableNumTotal' }),
								x: '',
								y: total,
							},
						]}
						{...pieProps}
						dataLabels={{
							...pieProps.dataLabels,
							distance: -60,
						}}
						colors={['white']}
						size={100}
						showInLegend={false}
					/>
					<PieSeries
						name={intl.formatMessage({ id: 'Proceq.TableNumTotal' })}
						data={secondaryPath
							.map(({ label, legend, x, y, totalX, totalY }) => ({
								name: intl.formatMessage({ id: label }),
								id: label,
								drilldown: label,
								legend: legend === undefined ? legend : intl.formatMessage({ id: legend }),
								x: totalX === undefined ? sumBy(Object.values(measurements), x) : totalX,
								y: totalY === undefined ? sumBy(Object.values(measurements), y) : totalY,
							}))

							.filter(dataset => dataset.y > 0)
							.map(dataset => ({ ...dataset, x: dataset.x > 0 ? dataset.x : '' }))}
						label={{
							boxesToAvoid: ['bottom', 'right'],
						}}
						{...pieProps}
					/>
				</HighchartsChart>
			</Col>
			<Col lg={24} xl={12}>
				<HighchartsChart
					plotOptions={{
						pie: {
							point: {
								events: {
									legendItemClick: e => {
										if (has(e, 'target.hcEvents.click')) {
											e.target.hcEvents.click[0]();
											return true;
										}
										return false;
									},
								},
							},
						},
					}}
					drilldown={{
						series: primaryPath.map(({ label, key }) => ({
							id: label,
							name: intl.formatMessage({ id: label }),
							data: secondaryPath
								.map(({ label: secondaryLabel, x, y, legend }) => ({
									name: intl.formatMessage({ id: secondaryLabel }),
									x: measurements[key][x],
									y: measurements[key][y],
									legend: legend === undefined ? legend : intl.formatMessage({ id: legend }),
								}))
								.filter(dataset => dataset.y > 0)
								.map(dataset => ({ ...dataset, x: dataset.x > 0 ? dataset.x : '' })),
							...pieProps,
						})),
					}}
				>
					<Title>{intl.formatMessage({ id: secondaryTitle })}</Title>
					<Subtitle>{intl.formatMessage({ id: secondarySubtitle })}</Subtitle>
					<Chart height={620} />
					<Legend
						title={{
							text: intl.formatMessage({ id: 'App.AnalyticsLegend' }),
							style: {
								textDecoration: 'underline',
							},
						}}
						{...legendProps}
					/>
					<XAxis />
					<YAxis />
					<Tooltip pointFormat="{point.y} bytes" />
					<PieSeries
						data={[
							{
								name: intl.formatMessage({ id: 'Proceq.TableNumTotal' }),
								x: '',
								y: total,
							},
						]}
						{...pieProps}
						dataLabels={{
							...pieProps.dataLabels,
							distance: -60,
						}}
						colors={['white']}
						size={100}
						showInLegend={false}
					/>
					<PieSeries
						name={intl.formatMessage({ id: 'Proceq.TableNumTotal' })}
						data={primaryPath
							.map(({ label, totalX, totalY }) => ({
								name: intl.formatMessage({ id: label }),
								drilldown: label,
								x: totalX,
								y: totalY,
							}))
							.filter(dataset => dataset.y > 0)
							.map(dataset => ({ ...dataset, x: dataset.x > 0 ? dataset.x : '' }))}
						{...pieProps}
					/>
				</HighchartsChart>
			</Col>
		</Fragment>
	) : (
		<Col lg={24} xl={12}>
			<HighchartsChart
				plotOptions={{
					pie: {
						point: {
							events: {
								// legendItemClick: e => e.preventDefault(),
								legendItemClick: e => {
									if (has(e, 'target.hcEvents.click')) {
										e.target.hcEvents.click[0]();
										return true;
									}
									return false;
								},
							},
						},
					},
				}}
			>
				<Title>{intl.formatMessage({ id: secondaryTitle })}</Title>
				<Chart height={620} />
				<Legend
					title={{
						text: intl.formatMessage({ id: 'App.AnalyticsLegend' }),
						style: {
							textDecoration: 'underline',
						},
					}}
					{...legendProps}
				/>
				<XAxis />
				<YAxis />
				<Tooltip pointFormat="{point.y} bytes" />
				<PieSeries
					data={[
						{
							name: intl.formatMessage({ id: 'Proceq.TableNumTotal' }),
							x: '',
							y: total,
						},
					]}
					{...pieProps}
					dataLabels={{
						...pieProps.dataLabels,
						distance: -60,
					}}
					colors={['white']}
					size={100}
					showInLegend={false}
				/>
				<PieSeries
					name={intl.formatMessage({ id: 'Proceq.TableNumTotal' })}
					data={secondaryPath
						.map(({ label, legend, x, y, totalX, totalY }) => ({
							name: intl.formatMessage({ id: label }),
							legend: legend === undefined ? legend : intl.formatMessage({ id: legend }),
							x: totalX === undefined ? sumBy(Object.values(measurements), x) : totalX,
							y: totalY === undefined ? sumBy(Object.values(measurements), y) : totalY,
						}))

						.filter(dataset => dataset.y > 0)
						.map(dataset => ({ ...dataset, x: dataset.x > 0 ? dataset.x : '' }))}
					label={{
						boxesToAvoid: ['bottom', 'right'],
					}}
					{...pieProps}
				/>
			</HighchartsChart>
		</Col>
	);

export default compose(injectIntl, curryRight(withHighcharts)(Highcharts))(Charts);
