// @flow
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Icon } from 'antd';
import styles from './errorStyles.styl';

export const DownloadStatus = ({ id }: { id: string }) => {
	let type = 'loading';
	switch (id) {
		case 'App.StartingFileDownload':
			type = 'download';
			break;
		case 'App.FileDownloadNotFound':
		case 'App.FileDownloadError':
		case 'App.SharedMeasurementNotFound':
		case 'App.SharedMeasurementError':
		case 'App.ExportFailure':
			type = 'warning';
			break;
		case 'App.FileDownloadExpired':
		case 'App.SharedMeasurementExpired':
			type = 'clock-circle-o';
			break;
		case 'App.LongRunningJobInProgress':
			type = 'info-circle';
			break;
		default:
			type = 'loading';
			break;
	}

	if (id === 'App.LongRunningJobInProgress') {
		setTimeout(function() {
			location.reload();
		}, 60000);
	}

	return (
		<div>
			{id === 'App.LongRunningJobInProgress' ? (
				<div className={styles.container}>
					<Icon type={type} style={{ fontSize: 50 }} />
					<h1 className={styles.inprogress}>
						<FormattedMessage id={id} />
						<br />
						<FormattedMessage id="App.KeepBrowserOpen" />
						<br />
						<FormattedMessage id="OR" />
						<br />
						<FormattedMessage id="App.AccessPageAfterSomeTime" />
						<br />
						<a href={window.location.href}> {window.location.href} </a>
						<br />
						<FormattedMessage id="App.ToDownloadReport" />
					</h1>
				</div>
			) : (
				<div className={styles.container}>
					<Icon type={type} style={{ fontSize: 64 }} />
					<h1 className={styles.text}>
						<FormattedMessage id={id} />
					</h1>
				</div>
			)}
		</div>
	);
};

export default DownloadStatus;
