// @flow
import React from 'react';
import { connect } from 'react-redux';
import { compose, withState } from 'recompose';
import { FormattedMessage } from 'react-intl';
import { Button } from 'antd';
import URL from 'apis/urls';
import { request } from 'actions/ajax';
import styles from '../shared/SmarterTable.styl';

export const ExportDongles = ({ loading, exportDongles }: { loading: boolean, exportDongles: Function }) => (
	<Button className={styles.button} type="primary" onClick={exportDongles} loading={loading}>
		<FormattedMessage id="App.DongleExportAll" />
	</Button>
);

export default compose(
	withState('loading', 'setLoading', false),
	connect(null, (dispatch: Function, props) => ({
		exportDongles: () => {
			dispatch(
				request({
					url: URL.EXPORT_DONGLES,
					setLoading: props.setLoading,
				})
			).then(data => {
				window.fileDownload(data, 'dongles.csv');
			});
		},
	}))
)(ExportDongles);
