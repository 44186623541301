/* eslint-disable no-underscore-dangle */
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import multi from 'redux-multi';
import { persistStore } from 'redux-persist';

import rootReducer from './reducers/rootReducer';

const middleware = [thunk, multi];
const store = createStore(
	rootReducer,
	compose(
		applyMiddleware(...middleware),
		process.env.NODE_ENV !== 'production' && window.__REDUX_DEVTOOLS_EXTENSION__
			? window.__REDUX_DEVTOOLS_EXTENSION__()
			: f => f
	)
);

export const storagePersistor = persistStore(store);

export const storeDispatch = store.dispatch;

// The redux store is a massive global object
// we should always return the same instance of the store object
export default store;
