// @flow
import React, { Fragment } from 'react';
import moment from 'moment';
import { get, has } from 'lodash';
import { Row, Col, Icon, Tooltip as AntTooltip } from 'antd';
import { FormattedMessage } from 'react-intl';
import { rates } from 'constants/conversionRates';
import { formatDateTimeNoSecsToLocale } from 'utils/dateHandler';
import StorageCharts from './StorageCharts';
import styles from './Storage.styl';
import layoutStyles from '../layout/Layout.styl';

export const Storage = ({
	common,
	storage,
	measurements,
}: {
	common: CommonAnalytics,
	storage: StorageAnalytics,
	measurements: MeasurementAnalytics,
}) => (
	<Fragment>
		<Row className={layoutStyles.container}>
			<Col xs={24} className={styles.stat_container}>
				<Col xs={12} md={6}>
					<FormattedMessage id="App.DashboardTotalFolders" />
					<span className={styles.number}>{common.nFolders}</span>
				</Col>
				<Col xs={12} md={6}>
					<div>
						<FormattedMessage id="App.DashboardTotalMeasurements" />
						&nbsp;
						<AntTooltip title={<FormattedMessage id="App.AnalyticsStorageTypeMeasurementTooltip" />}>
							<Icon type="info-circle" />
						</AntTooltip>
					</div>
					<span className={styles.number}>{get(measurements, 'nMeasurements', common.nTotalFiles)}</span>
					<span className={styles.small}>{rates.bytes(storage.alive.totalStorage)}</span>
				</Col>
				{has(measurements, 'lineScan') && (
					<Col xs={12} md={6}>
						<FormattedMessage id="App.DashboardLineScans" />
						<span className={styles.number}>{measurements.lineScan.measurementCount}</span>
						{measurements.lineScan.measurementCount > 0 && (
							<span className={styles.small}>{rates.bytes(measurements.lineScan.totalStorage)}</span>
						)}
					</Col>
				)}
				{has(measurements, 'areaScan') && (
					<Col xs={12} md={6}>
						<FormattedMessage id="App.DashboardAreaScans" />
						<span className={styles.number}>{measurements.areaScan.measurementCount}</span>
						{measurements.areaScan.measurementCount > 0 && (
							<span className={styles.small}>{rates.bytes(measurements.areaScan.totalStorage)}</span>
						)}
					</Col>
				)}
				{has(measurements, 'stripeScan') && (
					<Col xs={12} md={6}>
						<FormattedMessage id="App.DashboardStripeScans" />
						<span className={styles.number}>{measurements.stripeScan.measurementCount}</span>
						{measurements.stripeScan.measurementCount > 0 && (
							<span className={styles.small}>{rates.bytes(measurements.stripeScan.totalStorage)}</span>
						)}
					</Col>
				)}
				{has(measurements, 'nImpacts') && (
					<Col xs={8} md={4}>
						<FormattedMessage id="Proceq.StatisticsTotalImpacts" />
						<span className={styles.number}>{measurements.nImpacts}</span>
					</Col>
				)}
				{has(measurements, 'nExcluded') && (
					<Col xs={8} md={4}>
						<FormattedMessage id="Proceq.StatisticsTotalExcluded" />
						<span className={styles.number}>{measurements.nExcluded}</span>
					</Col>
				)}
				{has(measurements, 'nOutOfLimit') && (
					<Col xs={8} md={4}>
						<FormattedMessage id="Proceq.StatisticsValuesOutOfLimits" />
						<span className={styles.number}>{measurements.nOutOfLimit}</span>
					</Col>
				)}
			</Col>

			<Col xs={24} className={styles.date_container}>
				<FormattedMessage id="App.DashboardLastSynced" />
				<span className={styles.date}>
					{common.mLastUpdated > 0
						? formatDateTimeNoSecsToLocale(moment.utc(common.mLastUpdated).local())
						: '-'}
				</span>
			</Col>
		</Row>

		<div className={layoutStyles.container}>
			<Row>
				{get(measurements, 'nMeasurements', common.nTotalFiles) > 0 && (
					<StorageCharts common={common} storage={storage} measurements={measurements} />
				)}
			</Row>
		</div>
	</Fragment>
);

export default Storage;
