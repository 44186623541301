// @flow
import React from 'react';
import { Col, Row } from 'antd';
import {
	withHighcharts,
	HighchartsChart,
	Chart,
	XAxis,
	YAxis,
	Legend,
	LineSeries,
	Tooltip,
} from 'react-jsx-highcharts';
import Highcharts from 'highcharts';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import URL from 'apis/urls';
import { getProceqUrl } from 'utils/sessionHelper';
import { getLabelFromGlobalDataKey } from 'utils/registryHelper';
import {
	getResolutionForScale,
	getSchmidtScaleIdOfFromFactorAndUnitId,
	getScaleUnitsOfScaleId,
	getScaleNameOfScaleId,
	getStandardNameOfStandardId,
	getMaterialNameOfMaterialId,
} from 'utils/conversionsHelper';
import handleFormatMessage from 'utils/handleFormatMessage';
import { MainData } from '../MeasurementsData/MainData';

export class CustomMaterialData extends MainData {
	isVerification = false;

	isCustomMaterialData = true;

	customDataType = 'CUSTOM_MATERIAL';

	disableFolders = true;

	tableFetchApiEndpoint = getProceqUrl(
		this.props.session,
		URL.PROCEQ_GET_CUSTOM_DATA.replace('{type_data}', 'CUSTOM_MATERIAL')
	);

	colModel = {
		EQUOTIP: [
			{ title: 'Id', dataIndex: '_id', sorter: false, width: '20%', typeField: 'hidden' },
			{ title: 'TableDescription', dataIndex: 'description' /* , sorter: true, filter: false */ },
			{
				title: 'TableCreatedOn',
				dataIndex: 'createDate',
				typeField: 'dateTimeNoSecs' /* , sorter: true, typeField: 'dateTimeNoSecs' */,
			},
			{
				title: 'TableBaseMaterial',
				dataIndex: 'baseMaterialId',
				globalRegister: 'materials',
				sendValueInsteadOfID: true /* , sorter: true, filter: true */,
			},
			{
				title: 'TableScaleUnit',
				dataIndex: 'targetScaleId',
				globalRegister: 'units',
				sendValueInsteadOfID: true /* , sorter: true, filter: true */,
			},
			{ title: 'json', dataIndex: 'json', sorter: false, typeField: 'hidden' },
		],
		SCHMIDT: [
			{ title: 'Id', dataIndex: '_id', sorter: false, width: '20%', typeField: 'hidden' },
			{ title: 'TableDescription', dataIndex: 'description' /* , sorter: true, filter: false */ },
			{
				title: 'TableCreatedOn',
				dataIndex: 'createDate',
				typeField: 'dateTimeNoSecs' /* , sorter: true, typeField: 'dateTimeNoSecs' */,
			},
			{
				title: 'TableScaleUnit',
				dataIndex: 'unit',
				globalRegister: 'unitsschmidt',
				sendValueInsteadOfID: true /* , sorter: true, filter: true */,
			},
			{
				title: 'ExportFieldCurveType',
				dataIndex: 'curveType',
				globalRegister: 'schmidtcurvetypesshort',
				sendValueInsteadOfID: true /* , sorter: true, filter: true */,
			},
			{ title: 'json', dataIndex: 'json', sorter: false, typeField: 'hidden' },
		],
	};

	specificActions = {
		EQUOTIP: [],
		SCHMIDT: [],
	};

	basicActions = [
		{
			actionId: 'expand',
			actionType: 'expand',
			actionText: 'TableActionExpand',
			multipleSelectedAllowed: true,
			oneSelectedNeeded: true,
		},
		{
			actionId: 'collapse',
			actionType: 'collapse',
			actionText: 'TableActionCollapse',
			multipleSelectedAllowed: true,
			oneSelectedNeeded: true,
		},
	];

	renderExpandedRow = (record: Object) => {
		const allRowUncompressedData = this.getSeriesDataFromRow(record);
		const allParsedDataNeeded = this.getAllParsedDataNeeded(
			allRowUncompressedData,
			'',
			null,
			this.props.session.productType
		);
		const dataForGraph = this.getDataForGraph(allParsedDataNeeded);

		function formatter() {
			const header = `<b>${this.x} ${dataForGraph.sourceunit}</b><br/>`;
			let content = '';
			for (let indexSerie = 0; indexSerie < this.points.length; indexSerie++) {
				const thisPoint = this.points[indexSerie];
				if (indexSerie !== 0) {
					content += '<br/>';
				}
				content += `<span style="color:${thisPoint.color}">\u25CF</span> ${thisPoint.series.name}:`;
				content += `${thisPoint.y} ${dataForGraph.targetunit}`;
			}
			return header + content;
		}

		let graphHTML = null;
		if (dataForGraph.dataoriginal.length > 0 || dataForGraph.datacustom.length > 0) {
			graphHTML = (
				<Col xs={24} lg={24}>
					<div style={{ maxWidth: '800px', margin: '0 auto' }}>
						<HighchartsChart>
							<Chart backgroundColor={null} />

							<Legend layout="vertical" align="right" verticalAlign="middle" />

							<Tooltip formatter={formatter} shared />

							<XAxis>
								<XAxis.Title>{dataForGraph.sourceunit}</XAxis.Title>
							</XAxis>

							<YAxis id="number" type="linear">
								<YAxis.Title>{dataForGraph.targetunit}</YAxis.Title>
								{dataForGraph.dataoriginal.length ? (
									<LineSeries
										id="dataoriginal"
										name="Original Curve"
										data={dataForGraph.dataoriginal}
										color={'rgba(0,0,255,.9)'}
									/>
								) : (
									''
								)}
								{dataForGraph.datacustom.length ? (
									<LineSeries
										id="datacustom"
										name="Custom Curve"
										data={dataForGraph.datacustom}
										color={'rgba(255,0,0,1)'}
									/>
								) : (
									''
								)}
							</YAxis>
						</HighchartsChart>
					</div>
				</Col>
			);
		}
		const pairValueHTML = this.doExtraInfoColumn(
			this.getPairValueForProduct(this.props.session.productType),
			'ExportFieldValuePair',
			allParsedDataNeeded
		);
		const settingsHTML = this.doExtraInfoColumn(
			this.getSettingsParametersForProduct('', this.props.session.productType),
			'ExportFieldSettings',
			allParsedDataNeeded,
			pairValueHTML === null ? 16 : 12
		);

		if (graphHTML) {
			return (
				<div>
					<Row>
						{graphHTML}
						{pairValueHTML}
						{settingsHTML}
					</Row>
				</div>
			);
		}

		return (
			<div>
				<Row>
					{pairValueHTML}
					{settingsHTML}
				</Row>
			</div>
		);
	};

	getDataForGraph(infoForDataMeasuremets: Object) {
		const dataForGraph = {
			dataoriginal: [],
			datacustom: [],
			sourceunit: infoForDataMeasuremets.SETTINGS.SOURCEUNIT.VALUE,
			targetunit: infoForDataMeasuremets.SETTINGS.TARGETUNIT.VALUE,
		};
		if ('curves' in infoForDataMeasuremets.json) {
			for (const typeCurve in infoForDataMeasuremets.json.curves) {
				let saveProperty = 'dataoriginal';
				if (typeCurve === 'target') {
					saveProperty = 'datacustom';
				}
				for (
					let indexPoint = 0;
					indexPoint < infoForDataMeasuremets.json.curves[typeCurve].length;
					indexPoint++
				) {
					const thisPoint = infoForDataMeasuremets.json.curves[typeCurve][indexPoint];
					dataForGraph[saveProperty][indexPoint] = [
						parseFloat(getResolutionForScale(dataForGraph.sourceunit, thisPoint[0])),
						parseFloat(getResolutionForScale(dataForGraph.targetunit, thisPoint[1])),
					];
				}
			}
		}
		return dataForGraph;
	}

	getAllParsedDataNeeded(data: Object, metadata: string, currentData: null, productType: ProductCode) {
		const { intl } = this.props,
			{ formatMessage } = intl;
		const toReturn = data;
		toReturn.json = JSON.parse(data.json);
		toReturn.SETTINGS = {};
		toReturn.MEASUREMENTS = {};
		let sourceScaleUnit = null;
		let sourceScaleName = null;
		switch (productType) {
			case 'EQUOTIP':
				sourceScaleUnit = 'HLD';
				sourceScaleName = 'Leeb D';
				break;
			case 'SCHMIDT':
				sourceScaleUnit = 'R';
				sourceScaleName = formatMessage({ id: 'App.SchmidtFormFactorRAngle' });
				break;
			case 'SSL':
				sourceScaleUnit = 'Q';
				sourceScaleName = formatMessage({ id: 'App.SchmidtFormFactorQAngle' });
				break;
		}

		let targetScaleUnit = sourceScaleUnit;
		let targetScaleName = sourceScaleName;
		if ('sourceScaleId' in toReturn.json) {
			sourceScaleUnit = getScaleUnitsOfScaleId(productType, formatMessage, toReturn.json.sourceScaleId);
			sourceScaleName = getScaleNameOfScaleId(productType, formatMessage, toReturn.json.sourceScaleId);
		}

		if ('targetScaleId' in toReturn.json) {
			targetScaleUnit = getScaleUnitsOfScaleId(productType, formatMessage, toReturn.json.targetScaleId);
			targetScaleName = getScaleNameOfScaleId(productType, formatMessage, toReturn.json.targetScaleId);
		} else if ('formFactorId' in toReturn.json && 'unitId' in toReturn.json) {
			const targetScaleId = getSchmidtScaleIdOfFromFactorAndUnitId(
				toReturn.json.unitId,
				toReturn.json.formFactorId
			);
			targetScaleUnit = getScaleUnitsOfScaleId(productType, formatMessage, targetScaleId);
			targetScaleName = getScaleNameOfScaleId(productType, formatMessage, targetScaleId);
		} else {
			targetScaleUnit = 'Q';
			targetScaleName = formatMessage({ id: 'App.SchmidtFormFactorQAngle' });
		}

		if (sourceScaleUnit !== null)
			toReturn.SETTINGS.SOURCEUNIT = { TITLE: 'ExportFieldUnit', VALUE: sourceScaleUnit };
		if (sourceScaleName !== null)
			toReturn.SETTINGS.SOURCENAME = { TITLE: 'ExportFieldName', VALUE: sourceScaleName };
		if (targetScaleUnit !== null)
			toReturn.SETTINGS.TARGETUNIT = { TITLE: 'ExportFieldUnit', VALUE: targetScaleUnit };
		if (targetScaleName !== null)
			toReturn.SETTINGS.TARGETNAME = { TITLE: 'ExportFieldName', VALUE: targetScaleName };

		if ('sourceValue' in toReturn.json) {
			toReturn.MEASUREMENTS.SOURCEVALUE = {
				TITLE: ' ',
				VALUE: `${getResolutionForScale(null, toReturn.json.sourceValue, null)} ${
					toReturn.SETTINGS.SOURCEUNIT.VALUE
				}`,
			};
		}
		if ('targetValue' in toReturn.json) {
			toReturn.MEASUREMENTS.TARGETVALUE = {
				TITLE: ' ',
				VALUE: `${getResolutionForScale(null, toReturn.json.targetValue, null)} ${
					toReturn.SETTINGS.TARGETUNIT.VALUE
				}`,
			};
		}

		if ('limitMax' in toReturn.json) {
			const upperLimValue = toReturn.json.limitMax;
			const lowerLimValue = toReturn.json.limitMin;

			if (targetScaleUnit) {
				toReturn.SETTINGS.UPPERLIMIT = {
					TITLE: 'ExportFieldUpperLimit',
					VALUE: getResolutionForScale(toReturn.SETTINGS.TARGETUNIT.VALUE, upperLimValue),
				};
				toReturn.SETTINGS.LOWERLIMIT = {
					TITLE: 'ExportFieldLowerLimit',
					VALUE: getResolutionForScale(toReturn.SETTINGS.TARGETUNIT.VALUE, lowerLimValue),
				};
				toReturn.SETTINGS.UPPERLIMITWITHUNITS = {
					TITLE: 'ExportFieldUpperLimit',
					VALUE: `${toReturn.SETTINGS.UPPERLIMIT.VALUE} ${toReturn.SETTINGS.TARGETUNIT.VALUE}`,
				};
				toReturn.SETTINGS.LOWERLIMITWITHUNITS = {
					TITLE: 'ExportFieldLowerLimit',
					VALUE: `${toReturn.SETTINGS.LOWERLIMIT.VALUE} ${toReturn.SETTINGS.TARGETUNIT.VALUE}`,
				};
			}
		}

		if ('curveTypeId' in toReturn.json) {
			const curveTypeStr = handleFormatMessage(
				getLabelFromGlobalDataKey('schmidtcurvetypes', toReturn.json.curveTypeId),
				formatMessage
			);
			toReturn.SETTINGS.CURVETYPE = { TITLE: 'ExportFieldCurveType', VALUE: curveTypeStr };
			switch (toReturn.json.curveTypeId) {
				case 0: // polynomial
					toReturn.SETTINGS.CURVEPARAMA = { TITLE: 'a', VALUE: toReturn.json.coefficients[0] };
					toReturn.SETTINGS.CURVEPARAMB = { TITLE: 'b', VALUE: toReturn.json.coefficients[1] };
					toReturn.SETTINGS.CURVEPARAMC = { TITLE: 'c', VALUE: toReturn.json.coefficients[2] };
					break;
				case 1: // exponencial
					toReturn.SETTINGS.CURVEPARAMA = { TITLE: 'a', VALUE: toReturn.json.coefficients[0] };
					toReturn.SETTINGS.CURVEPARAMB = { TITLE: 'b', VALUE: toReturn.json.coefficients[1] };
					break;
			}
		}

		if ('baseMaterialId' in toReturn.json) {
			toReturn.SETTINGS.BASEMATERIAL = {
				TITLE: 'ExportFieldMaterial',
				VALUE: getMaterialNameOfMaterialId(null, formatMessage, toReturn.json.baseMaterialId),
			};
		}
		if ('baseStandardId' in toReturn.json) {
			toReturn.SETTINGS.BASESTANDARD = {
				TITLE: 'ExportFieldStandard',
				VALUE: getStandardNameOfStandardId(null, formatMessage, toReturn.json.baseStandardId, productType),
			};
		}
		return toReturn;
	}

	getSettingsParametersForProduct(mainScale: string, productType: ProductCode) {
		let parameters: string[] = [];
		switch (this.customDataType) {
			case 'CUSTOM_MATERIAL':
				switch (productType) {
					case 'EQUOTIP':
						parameters = [
							'SETTINGS.UPPERLIMITWITHUNITS',
							'SETTINGS.LOWERLIMITWITHUNITS',
							'SETTINGS.BASEMATERIAL',
							'SETTINGS.BASESTANDARD',
							'SETTINGS.TARGETNAME',
							'SETTINGS.TARGETUNIT',
						];
						break;
					case 'SSL':
					case 'SCHMIDT':
						parameters = [
							'SETTINGS.UPPERLIMITWITHUNITS',
							'SETTINGS.LOWERLIMITWITHUNITS',
							'SETTINGS.CURVETYPE',
							'SETTINGS.CURVEPARAMA',
							'SETTINGS.CURVEPARAMB',
							'SETTINGS.CURVEPARAMC',
							'SETTINGS.TARGETNAME',
							'SETTINGS.TARGETUNIT',
						];
						break;
				}

				break;
		}
		return parameters;
	}

	getPairValueForProduct = (productType: ProductCode) => {
		let parameters = [];
		switch (this.customDataType) {
			case 'CUSTOM_MATERIAL':
				switch (productType) {
					case 'EQUOTIP':
						parameters = ['MEASUREMENTS.SOURCEVALUE', 'MEASUREMENTS.TARGETVALUE'];
						break;
				}
				break;
		}
		return parameters;
	};

	getSeriesDataFromRow = (row: Object) => {
		return { ...row };
	};
}

const mapStateToProps = state => ({
	session: state.session,
	locale: state.locale,
	globalregister: state.globalregister,
});

export default withHighcharts(connect(mapStateToProps)(injectIntl(CustomMaterialData)), Highcharts);
