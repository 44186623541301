// @flow
import React from 'react';

const StaticMap = ({
	width,
	height,
	zoom,
	coordinates,
}: {
	width: number,
	height: number,
	zoom: number,
	coordinates: {
		lat: number,
		lng: number,
	},
}) =>
	coordinates.lat === 0 && coordinates.lng === 0 ? null : (
		<a
			target="_blank"
			rel="noopener noreferrer"
			href={`https://www.google.com/maps/search/?api=1&query=${coordinates.lat},${coordinates.lng}`}
		>
			<img
				alt="map"
				src={`https://maps.googleapis.com/maps/api/staticmap?markers=${coordinates.lat},${
					coordinates.lng
				}&size=${width}x${height}&zoom=${zoom}&key=${process.env.GOOGLE_MAPS_API_KEY || ''}`}
			/>
		</a>
	);

export default StaticMap;
