// @flow
import React, { Fragment } from 'react';
import { has, get, intersection } from 'lodash';
import { Table } from 'antd';
import { FormattedMessage } from 'react-intl';
import { convertAndDisplay } from 'constants/conversionRates';
import ConvertedUnits from 'components/shared/ConvertedUnits';
import type { FDLMeasurementPresetsProps } from './MeasurementPresets';
import { IsInfinity, InfinitySymbol } from './FdlConstants';
import styles from '../../../HTMLExport.styl';

export const title = (
	<Fragment>
		<FormattedMessage id="Proceq.ExportFieldSettings" />
		{' ­­» '}
		<FormattedMessage id="App.HTML.FDL.MeasurementPresets.Indication" />
	</Fragment>
);

const parameters = [
	'gate1SoundPathDistance',
	'gate1DepthToReflector',
	'gate1SurfaceDistance',
	'gate1CurrentAmplitude',
	'gate1MaximumAmplitude',
	'gate2SoundPathDistance',
	'gate2DepthToReflector',
	'gate2SurfaceDistance',
	'gate2CurrentAmplitude',
	'gate2MaximumAmplitude',
	'gate1AmplitudeToCurve',
	'gate2AmplitudeToCurve',
	'gate1DBtoCurve',
	'gate2DBtoCurve',
	'gate1Ers',
	'gate2Ers',
	'gate1DB',
	'gate2DB',
	'awsA',
	'awsB',
	'awsC',
	'awsD',
	'echoToEcho',
	'gate1SurfaceDistanceIndexPointCorrection',
	'gate2SurfaceDistanceIndexPointCorrection',
	'equivalentReflectorSize',
	'overshoot',
];

export const IndicationTable = ({
	readings,
	showTitle,
	unit,
}: { readings: MeasurementReading[] } & FDLMeasurementPresetsProps) => {
	const parametersToShow = [].concat(...readings.map(reading => reading.content.indicationSetupFile.toolBarOptions));

	const columns = [
		{
			dataIndex: 'content.indicationPosition.indicationPosition',
			title: 'ID',
			render: text => text + 1,
		},
		{
			dataIndex: 'content.indicationSettings.info.type',
			title: <FormattedMessage id="App.Logbook.FDL.Item.type" />,
			render: text => <FormattedMessage id={`App.Logbook.FDL.Item.type.${text}`} />,
		},
		{
			dataIndex: 'content.indicationSettings.info.description',
			title: <FormattedMessage id="App.Logbook.FDL.Item.description" />,
		},
		{
			dataIndex: 'content.indicationSetupFile.gain',
			title: (
				<ConvertedUnits
					id="App.HTML.FDL.MeasurementPresets.KeyFrameSnapshot.GlobalGain"
					unitId="FDL.KeyFrameSnapshot.GlobalGain"
					scanType="Indication"
					isMetric={unit}
				/>
			),
			render: text => {
				const { convertFunction } = convertAndDisplay('FDL.IndicationTable.GlobalGain', 'Indication', unit);
				return convertFunction(text);
			},
		},
	].concat(
		intersection(parameters, parametersToShow).map(dataIndex => ({
			// dataIndex is gate1SoundPathDistance, gate2CurrentAmplitude, etc.
			title: <FormattedMessage id={`App.HTML.FDL.MeasurementPresets.${dataIndex}`} />,
			render: (text, row) => {
				const unitToUse = row.content.indicationSetupFile.unit;
				const { convertFunction, unit: displayUnit } = convertAndDisplay(
					`FDL.IndicationTable.${dataIndex}`,
					'Indication',
					unitToUse
				);
				let fullDataIndex = `content.indicationSettings.toolBarOptions.${dataIndex}`;
				if (unitToUse === 'µs') {
					const timeIndex = `${fullDataIndex}InTimeUnit`;
					if (has(row, timeIndex)) {
						fullDataIndex = timeIndex;
					}
				}
				const value = get(row, fullDataIndex);
				return value
					? IsInfinity(value)
						? InfinitySymbol
						: `${convertFunction(value)} ${displayUnit}`
					: 'N/A';
			},
		}))
	);

	return (
		<Table
			rowKey="id"
			title={showTitle ? () => <span className={styles.sub_header}>{title}</span> : null}
			className={styles.table}
			columns={columns}
			dataSource={readings}
			pagination={false}
			size="small"
		/>
	);
};

export default IndicationTable;
