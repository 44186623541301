module.exports = {
	shortlang: 'cn',
	lang: 'zh',
	messages: {
		'Proceq.ErrorWrongUsername': '提供的电子邮件无效',
		'Proceq.ErrorCreationNotAllowed': '新帐户创建已被禁用',
		'Proceq.ErrorWrongPassword': '提供的密码无效',
		'Proceq.ErrorWrongFirstName': '提供的名字无效',
		'Proceq.ErrorWrongLastName': '提供的姓氏无效',
		'Proceq.ErrorUsernameAlreadyExists': '已存在具有相同电子邮件的帐户',
		'Proceq.ErrorDB': '执行数据库操作时出错',
		'Proceq.InvalidUsername': '未找到用户帐户',
		'Proceq.AlreadyMultipleForgot': '已请求多次重置密码',
		'Proceq.InvalidNewPassword': '提供的新密码无效',
		'Proceq.InvalidResetCode': '无效的密码重置代码或已过期',
		'Proceq.InvalidOldPassword': '提供的旧密码无效',
		'Proceq.InvalidAccessRights': '会话已过期或用户不被允许执行此操作',
		'Proceq.ErrorNoAccountType': '未提供帐户类型',
		'Proceq.ErrorNoUsername': '未提供电子邮件',
		'Proceq.InvalidCountry': '提供的国家/地区无效',
		'Proceq.ErrorCreatingUser': '创建新用户时出错',
		'Proceq.InvalidID': '提供的 ID 无效',
		'Proceq.InvalidTransactionID': '无效交易 ID',
		'Proceq.InvalidFileContent': '读取文件内容时出错',
		'Proceq.InvalidTypeEntry': '无效的条目类型',
		'Proceq.InvalidContractID': '合同无效或已过期',
		'Proceq.InvalidExistingID': '由于 ID 已存在，无法创建',
		'Proceq.InvalidDataSeriesType': '无效序列类型',
		'Proceq.InvalidMetaDataContent': '无效元数据内容',
		'Proceq.ErrorFileIsLocked': '一个或更多文件已锁定。请稍后再试',
		'Proceq.MissingAttachmentFiles': '无法在服务器上找到附件文件',
		'Proceq.InvalidFlagType': '标记类型无效或丢失',
		'Proceq.MissingDateTypeFields': '序列缺少必需的字段',
		'Proceq.InvalidFolderID': '无效的文件夹 ID',
		'Proceq.InvalidDataSeriesName': '无效的序列名称',
		'Proceq.ErrorDateFormat': '提供的日期格式无效',
		'Proceq.ErrorDataFormat': '无法解析来自序列的数据',
		'Proceq.InvalidDownloadID': '无效的下载令牌，或它已过期',
		'Proceq.InvalidVersionNr': '提供的版本号无效',
		'Proceq.ErrorDataMissing': '数据不能从存储系统恢复',
		'Proceq.ErrorSavingFile': '服务器未能保存文件',
		'Proceq.ErrorInsertDB': '插入数据库时出错',
		'Proceq.InvalidFolderName': '提供的文件夹名称无效',
		'Proceq.InvalidActionID': 'URL 链接无效或已过期',
		'Proceq.AlreadyConfirmed': '帐户已验证',
		'Proceq.InvalidActionType': '请求的操作无法处理',
		'Proceq.ErrorGeneratingChart': '生成图表时出错',
		'Proceq.InvalidSerialID': '无效的序列 ID',
		'Proceq.InvalidHardwareID': '无效的硬件 ID',
		'Proceq.InvalidEntityID': '无效的对象 ID 或它已被删除',
		'Proceq.InvalidEntityType': '无效的对象类型',
		'Proceq.InvalidEntityVerNr': '无效的对象版本号',
		'Proceq.ErrorTransactionLocked': '对象已锁定，不能修改',
		'Proceq.InvalidDateValue': '无法解析数据',
		'Proceq.MissingFields': '缺少一个或多个必需字段',
		'Proceq.ErrorColumnValue': '为一个或多个字段提供了错误值',
		'Proceq.MissingUidDBValue': '缺少条目 ID',
		'Proceq.ErrorFileID': '提供的文件 ID 无效',
		'Proceq.ErrorInsertDownload': '创建下载时出错',
		'Proceq.InvalidAppVersion': '您当前的应用版本不再受支持。请更新',
		'Proceq.MissingUserInfo': '缺少用户信息',
		'Proceq.InvalidAccessToken': '会话令牌无效或已过期',
		'Proceq.ErrorGeneratingCSV': '生成 CSV 报告时出错',
		'Proceq.ErrorGeneratingExport': '生成报告时出错',
		'Proceq.MissingParams': '缺少一个或多个必需的参数',
		'Proceq.SerialNotFound': '未找到此设备的合同',
		'Proceq.StandardEnumJapan': '日本',
		'Proceq.StandardEnumDefault': '默认',
		'Proceq.MaterialEnumIndependent': '物质无关',
		'Proceq.MaterialEnumSteelAndCastSteel': '钢和铸钢',
		'Proceq.MaterialEnumWorkToolSteel': '工具钢',
		'Proceq.MaterialEnumStainlessSteel': '不锈钢',
		'Proceq.MaterialEnumGrayCastIron': '灰铸铁 (GG)',
		'Proceq.MaterialEnumNodularCastIron': '球墨铸铁 (GGG)',
		'Proceq.MaterialEnumCastAlluminiumAlloys': '铸造铝合金',
		'Proceq.MaterialEnumBrassCopperZincAlloys': '黄铜 - 铜/锌合金',
		'Proceq.MaterialEnumBronze': '青铜',
		'Proceq.MaterialEnumWroughtCopperAlloys': '锻铜合金',
		'Proceq.MaterialEnumTitanium': '钛',
		'Proceq.ScaleEnumCustom': '自定义',
		'Proceq.ScaleEnumPenetrationDepth': '穿透深度',
		'Proceq.CompanySizeMicro': '1-9 位员工',
		'Proceq.CompanySizeSmall': '10-49 位员工',
		'Proceq.CompanySizeMedium': '50-249 位员工',
		'Proceq.CompanySizeLarge': '>249 位员工',
		'Proceq.SalutationMr': '先生',
		'Proceq.SalutationMs': '女士',
		'Proceq.SalutationDr': '博士',
		'Proceq.SalutationProf': '教授',
		'Proceq.CategoryAerospace': '航空航天',
		'Proceq.CategoryAutomotive': '汽车',
		'Proceq.CategoryChemicalProcessing': '化学加工',
		'Proceq.CategoryConstructionBuilding': '施工/建筑',
		'Proceq.CategoryConsultantTestLabsEngineer': '咨询/测试实验室/设计',
		'Proceq.CategoryEducationInstituteRD': '教育/研究/研发',
		'Proceq.CategoryFilmProducerConverter': '胶片生产商/加工机',
		'Proceq.CategoryGeologyRocktesters': '地质学/岩石试验机',
		'Proceq.CategoryMachineryToolingEquipment': '机械/工具/设备',
		'Proceq.CategoryMassTransportation': '公共交通运输',
		'Proceq.CategoryMetalWorks': '金属加工',
		'Proceq.CategoryOilAndGas': '石油与天然气',
		'Proceq.CategoryOthers': '其他',
		'Proceq.CategoryPaperProducerConverter': '纸制品生产商/加工机',
		'Proceq.CategorySalesChannel': '销售渠道',
		'Proceq.ErrorWrongLoginCredentials': '您输入的用户或密码不正确',
		'Proceq.ErrorEmailNotVerified': '电子邮件还未验证',
		'Proceq.ServerError': '服务器错误',
		'Proceq.TooManyActionEmailRequests': '太多请求。请等待电子邮件抵达您的邮箱',
		'Proceq.ErrorServerUnderMaintenance': '服务器正在维护中',
		'Proceq.TableDataSeriesName': '名称',
		'Proceq.TableCreatedOn': '创建日期',
		'Proceq.TableModifiedOn': '修改日期',
		'Proceq.TableCreatedBy': '创建人',
		'Proceq.TableNumMean': '平均值',
		'Proceq.TableNumTotal': '总计',
		'Proceq.TableNumStandardDeviation': '标准偏差',
		'Proceq.TableImpactDevice': '冲击装置',
		'Proceq.TableImpactDeviceHammer': '回弹仪',
		'Proceq.TableMaterial': '物质',
		'Proceq.TableScaleUnit': '标度单位',
		'Proceq.TableActionRefresh': '刷新',
		'Proceq.TableActionExpand': '展开',
		'Proceq.TableActionCollapse': '折叠',
		'Proceq.TableActionExportAsPDF': '导出为 PDF',
		'Proceq.TableActionExportAsCSV': '导出为 CSV',
		'Proceq.TableActionExportAsJSON': '导出为 JSON',
		'Proceq.SearchOperationEquals': '等于',
		'Proceq.SearchOperationContains': '包含',
		'Proceq.SearchOperationDifferent': '不同于',
		'Proceq.SearchOperationGreaterOrEqual': '大于或等于',
		'Proceq.SearchOperationGreater': '大于',
		'Proceq.SearchOperationLesserOrEqual': '小于或等于',
		'Proceq.SearchOperationLesser': '小于',
		'Proceq.ExportFieldExportDate': '导出日期',
		'Proceq.ExportFieldLimits': '限制',
		'Proceq.ExportFieldHigh': '高',
		'Proceq.ExportFieldLow': '低',
		'Proceq.ExportFieldOff': '关',
		'Proceq.ExportFieldLowerLimit': '下限',
		'Proceq.ExportFieldUpperLimit': '上限',
		'Proceq.ExportFieldReadings': '读数',
		'Proceq.ExportFieldStandardDeviation': '标准偏差',
		'Proceq.ExportFieldRange': '范围',
		'Proceq.ExportFieldRelativeSpan': '相对跨距',
		'Proceq.ExportFieldMaximum': '最大',
		'Proceq.ExportFieldMinimum': '最小',
		'Proceq.ExportFieldAverage': '平均',
		'Proceq.ExportFieldUnit': '单位',
		'Proceq.ExportFieldName': '名称',
		'Proceq.ExportFieldMeasurementDate': '测量日期',
		'Proceq.ExportFieldLocationLatitude': '位置纬度',
		'Proceq.ExportFieldLocationLongitude': '位置经度',
		'Proceq.ExportFieldProbeType': '探头类型',
		'Proceq.ExportFieldProbeSerialNumber': '探头序列号',
		'Proceq.ExportFieldProbeBTSerialNumber': '探头蓝牙序列号',
		'Proceq.ExportFieldProbeLastVerificationDate': '探头上次验证日期',
		'Proceq.ExportFieldPrimaryScale': '主要单位',
		'Proceq.ExportFieldSecondaryScale': '次要单位',
		'Proceq.ExportFieldMaterial': '物质',
		'Proceq.ExportFieldStandard': '标准',
		'Proceq.ExportFieldNoConversion': '无转换',
		'Proceq.ExportFieldMeasurements': '测量值',
		'Proceq.ExportFieldMore': '更多',
		'Proceq.ExportFieldLess': '更少',
		'Proceq.ExportFieldStatistics': '统计信息',
		'Proceq.ExportFieldSettings': '设置',
		'Proceq.DataSeriesLogbook': '记录表',
		'Proceq.DataSeriesAllMeasurements': '所有测量值',
		'Proceq.DataSeriesFlagged': '已标记',
		'Proceq.DataSeriesTrash': '垃圾桶',
		'Proceq.BrowserNoSupportAudio': '您的浏览器不支持音频文件。',
		'Proceq.EmailPasswordRecoverySubject': '密码恢复',
		'Proceq.EmailPasswordRecoveryBody':
			'亲爱的姓名<br/><br/>您已请求新密码。如需重置密码，请点击下面的链接。<br/><br/><a href="**?2**">重置密码</a><br/><br/>如果您未请求更改密码，请忽略此邮件。它将在 12 小时后过期。<br/><br/>Proceq 团队<br/><br/>**?3**',
		'Proceq.EmailRegistrationSubject': '注册完成',
		'Proceq.EmailRegistrationBody':
			'亲爱的姓名<br/><br/>感谢您在 **?5** 注册。请点击下面的链接完成注册：<br/><br/><a href="**?2**">**?2**</a><br/><br/>因为您填写了注册表，系统自动创建此电子邮件。如果您未进行此项注册，请忽略此邮件。如填写注册表，<a href="**?3**">即表示您同意我们的条款和条件</a>。<br/><br/>Proceq 团队<br/><br/>**?4**',
		'Proceq.LogbookActionNewMeasurement': '新测量',
		'Proceq.LogbookPropertyCreatedSeriesInFolder': '已在文件夹 "**?1**" 中创建序列 "**?0**"',
		'Proceq.LogbookPropertyRenamedSeriesFromTo': '序列已从 "**?0**" 重命名为 "**?1**"',
		'Proceq.LogbookPropertyMovedSeriesFromToTrash': '序列已从文件夹 "**?0**" 移至垃圾桶',
		'Proceq.LogbookPropertyMovedSeriesFromTrashTo': '序列已从垃圾桶移至文件夹 "**?0**"',
		'Proceq.LogbookPropertyMovedSeriesFromTo': '序列已从文件夹 "**?0**" 移至文件夹 "**?1**"',
		'Proceq.LogbookPropertySecondaryScaleChangedFromTo': '次要单位从 **?0** 更改为 **?1**',
		'Proceq.LogbookPropertyMaterialChangedFromTo': '物质已从 **?0** 更改为 **?1**',
		'Proceq.LogbookPropertyMaxLimitChangedFromTo': '上限已从 **?0** 更改为 **?1**',
		'Proceq.LogbookPropertyMinLimitChangedFromTo': '下限已从 **?0** 更改为 **?1**',
		'Proceq.LogbookPropertyStandardChangedFromTo': '标准已从 **?0** 更改为 **?1**',
		'Proceq.LogbookPropertyTextCommentAdded': '文本注释已添加',
		'Proceq.LogbookPropertyTextCommentDeletedOn': '删除时间 **?0**',
		'Proceq.LogbookPropertyMeasurementAdded': '**?0** 已添加',
		'Proceq.LogbookPropertyMeasurementExcluded': '**?0** 已排除',
		'Proceq.LogbookPropertyMeasurementIncluded': '**?0** 已包括',
		'Proceq.LogbookPropertyMeasurementDeleted': '**?0** 已删除',
		'Proceq.LogbookPropertyAttachmentDeletedOn': '删除时间 **?0**',
		'Proceq.LogbookPropertyPhotoAdded': '照片已添加',
		'Proceq.LogbookPropertyAudioAdded': '音频注释已添加',
		'Proceq.LogbookPropertySeriesFlagged': '序列已标记',
		'Proceq.LogbookPropertySeriesUnflagged': '序列未标记',
		'Proceq.LogbookPropertySerialNumberShort': '序列号',
		'Proceq.LogbookPropertyBluetoothModuleShort': 'BTM',
		'Proceq.LogbookPropertyVerified': '已验证',
		'Proceq.LogbookPropertyContractValidUntil': '合同有效期至',
		'Proceq.LogbookPropertyOperator': '操作员',
		'Proceq.LogbookTitle': '记录表',
		'Proceq.LogbookDownload': '下载',
		'Proceq.LogbookLoadingData': '正在加载数据...',
		'Proceq.LogbookClose': '关闭',
		'Proceq.FormCompany': '公司',
		'Proceq.FormScreeningEagleID': 'Screening Eagle ID (Email)',
		'Proceq.FormEmailGroupID': '电子邮件',
		'Proceq.FormCustomerEmail': '客户电子邮件',
		'Proceq.FormProbeSN': '探头序列号',
		'Proceq.FormBluetoothSN': '序列号',
		'Proceq.FormBluetoothHWID': '硬件 ID',
		'Proceq.FormExpirationDate': '有效期限',
		'Proceq.TitleRegisterProbeSNs': '注册租赁合同',
		'Proceq.ButtonSubmit': '提交',
		'Proceq.ButtonReset': '重置',
		'Proceq.ButtonResetPassword': '重置密码',
		'Proceq.MessageSuccesfullyInserted': '成功插入',
		'Proceq.MessageSuccesfullyEmailConfirmed': '您的电子邮件已确认。',
		'Proceq.MessagePasswordSuccesfullyChanged': '密码成功更改',
		'Proceq.MessageSuccesfullyUpdated': '成功更新',
		'Proceq.MessageAccountCreated': '已向您发送注册验证电子邮件。请点击电子邮件中的链接完成注册过程',
		'Proceq.MessageEmailForgotPassword': '已向您发送含有进一步说明的电子邮件',
		'Proceq.MessageEmailResendConfirm': '已向您发送含有进一步说明的电子邮件',
		'Proceq.MessageLoggingIn': '正在登录...',
		'Proceq.LoginNeedAccount': '需要帐户？',
		'Proceq.LoginCreateAccount': '创建帐户',
		'Proceq.TitleForgotPassword': '忘记密码',
		'Proceq.TitleResendConfirmation': '重新发送确认电子邮件',
		'Proceq.FormGroup': '组',
		'Proceq.FormFirstName': '名字',
		'Proceq.FormLastName': '姓氏',
		'Proceq.FormEmail': '电子邮件地址',
		'Proceq.FormCity': '城市',
		'Proceq.FormPassword': '密码',
		'Proceq.FormPasswordNew': '新密码',
		'Proceq.FormPasswordOld': '旧密码',
		'Proceq.FormConfirmPassword': '确认密码',
		'Proceq.FormConfirmPasswordNew': '确认新密码',
		'Proceq.ButtonRegister': '注册',
		'Proceq.ButtonSignIn': '登录',
		'Proceq.TitleSignIn': '登录',
		'Proceq.LoginAlreadyRegistered': '已经注册？',
		'Proceq.TextOrSignInUsing': '或使用以下帐户登录',
		'Proceq.NavSearchSerials': '搜索合同',
		'Proceq.NavRegisterProbeSNs': '注册合同',
		'Proceq.NavCreateStaffAccounts': '创建员工帐户',
		'Proceq.NavHistoryLog': '历史记录',
		'Proceq.NavMeasurementsData': '测量数据',
		'Proceq.NavChangePassword': '更改密码',
		'Proceq.NavLogout': '注销',
		'Proceq.NavUpdateProfile': '更新分析数据',
		'Proceq.TitleUpdateProfile': '更新分析数据',
		'Proceq.NavCreateCustomersAccounts': '创建客户帐户',
		'Proceq.ButtonSearch': '搜索',
		'Proceq.ButtonUpdate': '更新',
		'Proceq.ButtonCreate': '创建',
		'Proceq.ButtonChange': '更改',
		'Proceq.FormSearchBy': '搜索条件',
		'Proceq.FormValue': '值',
		'Proceq.FormAccountType': '帐户类型',
		'Proceq.FormAccountTypeAdmin': '管理员',
		'Proceq.FormAccountTypeBU': '业务部门',
		'Proceq.FormCompanyName': '公司名称',
		'Proceq.FormAddress': '地址',
		'Proceq.FormZipCode': '邮政编码',
		'Proceq.FormCountry': '国家/地区',
		'Proceq.FormPhoneNumber': '电话',
		'Proceq.FormCompanyWebsite': '公司网站',
		'Proceq.FormJobTitle': '职称',
		'Proceq.FormIndustry': '行业',
		'Proceq.FormSalutation': '称呼',
		'Proceq.FormCompanySize': '公司规模',
		'Proceq.FormLanguage': '语言',
		'Proceq.FormContactByProceq': '由 Proceq 联系',
		'Proceq.LanguageEnglish': '英语',
		'Proceq.LanguageJapanese': '日语',
		'Proceq.LanguageChinese': '中文',
		'Proceq.LanguageGerman': '德语',
		'Proceq.TextYes': '是',
		'Proceq.TextNo': '否',
		'Proceq.FormNewsletter': '时事通讯',
		'Proceq.TitleSearchSerials': '搜索合同',
		'Proceq.TitleChangePassword': '更改密码',
		'Proceq.TabLoginCustomers': '客户',
		'Proceq.TabLoginSingleUser': '单一用户',
		'Proceq.TabLoginGroup': '组',
		'Proceq.TextRemainingXDays': '还剩 **?0** 天',
		'Proceq.TextInfoShort': '信息',
		'Proceq.TextIAgreeTerms': '我同意<a>条款和条件</a>',
		'Proceq.ErrorDisplayTitleError': '错误',
		'Proceq.ErrorDisplayTitleWarning': '警告',
		'Proceq.LoginBackLogin': '返回登录',
		'Proceq.LoginForgotPasswordQuestion': '忘记密码？',
		'Proceq.LoginResendConfirmationEmail': '重新发送确认电子邮件',
		'Proceq.LoginRegisterAccountTextPart': '如果您没有帐户，请',
		'Proceq.LoginRegisterAccountLinkPart': '在此处注册',
		'Proceq.LoginProceqUseOnly': '这仅供 Proceq 内部使用',
		'Proceq.ProfileEnterInfo': '在继续之前，请输入您的分析数据信息',
		'Proceq.TextInfoExclamation': '信息！',
		'Proceq.FooterLegalPrivacy': '法律和隐私',
		'Proceq.JQGridTableRecordText': '查看 {0} - {1} / {2}',
		'Proceq.JQGridTableEmptyRecords': '没有可查看的记录',
		'Proceq.JQGridTableLoadText': '正在加载...',
		'Proceq.JQGridTablePaginationText': '第 {0} 页/共 {1} 页',
		'Proceq.JQGridTableSavingText': '保存...',
		'Proceq.JQGridTableFirstPage': '第一页',
		'Proceq.JQGridTableLastPage': '最后一页',
		'Proceq.JQGridTableNextPage': '下一页',
		'Proceq.JQGridTablePreviousPage': '上一页',
		'Proceq.JQGridTableRecordsPerPage': '每页记录数',
		'Proceq.JQGridTablePageText': '页面：',
		'Proceq.JQGridTableNoMoreRecs': '没有更多记录...',
		'Proceq.ExportColumnCSVNo': '编号',
		'Proceq.ExportColumnCSVSingleReadings': '单一读数',
		'Proceq.ExportColumnCSVMeasurementValue': '测量值',
		'Proceq.ExportColumnCSVAngleDegrees': '角度 (°)',
		'Proceq.ExportColumnCSVExcluded': '已排除',
		'Proceq.ExportColumnCSVSeriesName': '序列名称',
		'Proceq.ExportColumnCSVImpactDevice': '冲击装置',
		'Proceq.ExportColumnCSVImpactDeviceSN': '冲击装置序列号',
		'Proceq.ExportColumnCSVImpactDeviceVerification': '冲击装置验证',
		'Proceq.ExportColumnCSVImpactDeviceHammerVerification': '回弹仪验证',
		'Proceq.ExportColumnCSVImpactDeviceHammer': '回弹仪',
		'Proceq.ExportColumnCSVImpactDeviceHammerSN': '回弹仪序列号',
		'Proceq.ExportColumnCSVBluetoothModuleSN': '蓝牙模块序列号',
		'Proceq.ExportColumnCSVConversionStandard': '转换标准',
		'Proceq.ExportColumnCSVScale': '标度',
		'Proceq.ExportColumnCSVUnit': '单位',
		'Proceq.ExportColumnCSVReadings': '读数',
		'Proceq.ExportColumnCSVStandardDeviation': '标准偏差',
		'Proceq.ExportColumnCSVRelativeSpan': '相对跨距 (%)',
		'Proceq.ExportColumnCSVDebugDataSeriesName': '序列名称',
		'Proceq.ExportColumnExportDate': '导出日期',
		'Proceq.ExportColumnUpperLimit': '上限',
		'Proceq.ExportColumnLowerLimit': '下限',
		'Proceq.ExportColumnLimits': '限制',
		'Proceq.ExportColumnHigh': '高',
		'Proceq.ExportColumnLow': '低',
		'Proceq.ExportColumnValueOff': '关',
		'Proceq.ExportColumnValueNoConversion': '无转换',
		'Proceq.ExportColumnValueNoConversionShort': '无转换',
		'Proceq.ExportColumnReadings': '读数',
		'Proceq.ExportColumnStandardDeviation': '标准偏差',
		'Proceq.ExportColumnRange': '范围',
		'Proceq.ExportColumnRelativeSpan': '相对跨距',
		'Proceq.ExportColumnMaximum': '最大',
		'Proceq.ExportColumnMinimum': '最小',
		'Proceq.ExportColumnAverage': '平均',
		'Proceq.ExportColumnUnit': '单位',
		'Proceq.ExportColumnName': '名称',
		'Proceq.ExportColumnDataSeriesID': '序列 ID',
		'Proceq.ExportColumnFolderName': '文件夹名称',
		'Proceq.ExportColumnCreatedDate': '创建日期',
		'Proceq.ExportColumnCreatedDateOnly': '创建日期',
		'Proceq.ExportColumnCreatedTime': '创建时间',
		'Proceq.ExportColumnLastModifiedDate': '最后修改日期',
		'Proceq.ExportColumnLastModifiedDateOnly': '最后修改日期',
		'Proceq.ExportColumnLastModifiedTime': '最后修改时间',
		'Proceq.ExportColumnMeasurementAvgHardness': '测量值平均硬度',
		'Proceq.ExportColumnLocationLatitude': '位置纬度',
		'Proceq.ExportColumnLocationLongitude': '位置经度',
		'Proceq.ExportColumnProbeType': '类型',
		'Proceq.ExportColumnProbeSN': '序列号',
		'Proceq.ExportColumnBTMSN': '序列号',
		'Proceq.ExportColumnProbeLastModifiedDate': '最后验证日期',
		'Proceq.ExportColumnProbeHardwareRevision': '硬件修订版',
		'Proceq.ExportColumnProbeFirmwareRevision': '固件修订版',
		'Proceq.ExportColumnBTMHardwareRevision': '硬件修订版',
		'Proceq.ExportColumnBTMFirmwareRevision': '固件修订版',
		'Proceq.ExportColumnAppPlatform': '平台',
		'Proceq.ExportColumnVersion': '版本',
		'Proceq.ExportColumnOperator': '操作员',
		'Proceq.ExportColumnPrimaryScale': '主要单位',
		'Proceq.ExportColumnSecondaryScale': '次要单位',
		'Proceq.ExportColumnMaterial': '物质',
		'Proceq.ExportColumnConversionStandard': '转换标准',
		'Proceq.ExportColumnAngleCorrection': '角度修正',
		'Proceq.ExportColumnValueAuto': '自动',
		'Proceq.ExportColumnTableAngleGrades': '角度 (°)',
		'Proceq.ExportColumnTableLegendExcluded': '* 已排除',
		'Proceq.ExportColumnMeasurementBarGraph': '测量值条形图',
		'Proceq.ExportColumnTableView': '表视图',
		'Proceq.ExportColumnStatistics': '统计信息',
		'Proceq.ExportColumnSettings': '设置',
		'Proceq.ExportColumnImpactDeviceInfo': '冲击装置信息',
		'Proceq.ExportColumnImpactDeviceHammerInfo': '回弹仪信息',
		'Proceq.ExportColumnBluetoothModuleInfo': '蓝牙模块信息',
		'Proceq.ExportColumnApplicationInfo': '应用信息',
		'Proceq.ExportColumnAttachedPhotos': '附加照片',
		'Proceq.ButtonActions': '行动',
		'Proceq.SerialNotInServiceFTP': 'S/N not in Service FTP',
		'Proceq.ActionResultsToCSV': '导出结果CSV',
		'Proceq.ErrorDisplayTitleSuccess': '成功',
		'Proceq.ErrorNoMultipleTargetsAllowed': '多行不能显示数据',
		'Proceq.MessageActionSuccesfullyPerformed': '已成功完成',
		'Proceq.EmailReminderContractUserSubject': '合同期满前 2 个月提醒',
		'Proceq.EmailReminderContractUserBody':
			'亲爱的用户<br/><br/>您的租赁期 **?0** 将于 **?1** 到期。<br/><br/>依照我们的 <a href="http://www.proceq.com/fileadmin/user_upload/Proceq_General_Terms___Conditions_of_Rental_10112016.pdf">一般租赁条款和条件</a>，您的合同会自动延长 12 个月。您将单独收到发票。请确保按时结算，保证您可无停顿地使用 Equotip Live 产品。. <br/><br/>如有疑问，请联系 <a href="http://www.proceq.com/contact/proceq-network/">当地销售代表</a>。<br/><br/>Proceq 团队<br/><br/>**?2**',
		'Proceq.EmailReminderContractAdminSubject': '合同期满前 2 个月提醒',
		'Proceq.ErrorNoTargetSelected': '无选定目标',
		'Proceq.ErrorDisplayTitleInfo': '信息',
		'Proceq.TextSharingSeriesUrlLinkCreated':
			'序列号已分享给下列链接<br/>**?0**<br/><a class="link-copy-clipboard" href="javascript:void(0);" onclick="**?1**">复制到粘贴板</a><br/>',
		'App.ShareMeasurementsSuccess': '序列号已分享给下列链接',
		'App.ShareMeasurementsCopyAction': '复制到粘贴板',
		'Proceq.TextPleaseWait': '请稍候',
		'Proceq.TextPerformingServerRequest': '分析指令',
		'Proceq.TextPerformingAction': '分析中',
		'Proceq.ButtonCreateAccountChinaBack': '放弃操作',
		'Proceq.TitleCreateAccountChina': '中国用户',
		'Proceq.BodyCreateAccountChina': '中国地区的客户请前往 <a href="**?0**">**?1**</a>注册并登入',
		'Proceq.LoginCompatibiltyInfo': '兼容信息可查询<a href="**?0**">Proceq Apps</a>',
		'Proceq.TextRedirectingToRegion': '重新设置您的范围',
		'Proceq.EmailReminderContractExpiredTodayUserSubject': '合同期满',
		'Proceq.EmailReminderContractExpiredTodayUserBody':
			'亲爱的用户<br/><br/>您的租赁期 **?0** 已于今天到期。<br/><br/>很遗憾您不再使用 Proceq Live，但我们一直很乐意欢迎您再次回来。<br/><br/>如需续订合同，请联系 <a href="http://www.proceq.com/contact/proceq-network/">当地销售代表</a>。<br/><br/>Proceq 团队<br/><br/>**?1**',
		'Proceq.EmailRentalPeriodChangedSubject': '合同变更通知',
		'Proceq.EmailRentalPeriodChangedBody':
			'亲爱的用户<br/><br/>您的 **?0** 租赁合同的到期日已更改。您的租赁合同将于 **?1** 到期。<br/><br/>如有疑问，请联系 <a href="http://www.proceq.com/contact/proceq-network/">当地销售代表</a>。<br/><br/>Proceq 团队<br/><br/>**?2**',
		'Proceq.FormExpiresIn': '(days天）到期',
		'Proceq.SelectTimeWeek': '周',
		'Proceq.SelectTimeDay': '天',
		'Proceq.SelectTimeMonth': '月',
		'Proceq.GraphNoDataAvailable': '无有效数据',
		'Proceq.GraphMaterial': '材料组',
		'Proceq.GraphSecondaryScale': '转变等级',
		'Proceq.GraphStandard': '转换标准',
		'Proceq.GraphMeasurementCount': '冲击数字',
		'Proceq.GraphMeasurementSeriesCount': '连续测量计数',
		'Proceq.StatisticsTitle': '统计信息',
		'Proceq.StatisticsCreatedSeries': '创建测量系列',
		'Proceq.StatisticsModifiedSeries': '更改测量系列',
		'Proceq.StatisticsPhotos': '照片',
		'Proceq.StatisticsAudioFiles': '音频文件',
		'Proceq.StatisticsUsedStorage': '存储',
		'Proceq.StatisticsFolder': '文件夹',
		'Proceq.StatisticsModified': '修改',
		'Proceq.StatisticsCreated': '创建',
		'Proceq.StatisticsAll': '全部',
		'Proceq.StatisticsCountry': '国家/地区',
		'Proceq.StatisticsCompanySize': '公司规模',
		'Proceq.StatisticsIndustry': '行业',
		'Proceq.DateRangeFrom': '来自',
		'Proceq.DateRangeTo': '发送',
		'Proceq.GraphHistogram': '硬度柱状图',
		'Proceq.GraphSeriesHistogram': '硬度柱状图系列',
		'Proceq.StatisticsImpactDevice': '冲击装置',
		'Proceq.StatisticsImpactDeviceHammer': '回弹仪',
		'Proceq.GraphExcluded': '已排除',
		'Proceq.GraphIncluded': '包含',
		'Proceq.GraphWithinTolerance': '在公差范围内',
		'Proceq.GraphOutOfTolerance': '在公差范围外',
		'Proceq.StatisticsCreatedImpacts': '创建冲击（包括/不包括）',
		'Proceq.StatisticsModifiedImpacts': '修改冲击（包括/不包括）',
		'Proceq.GraphNoConversion': '无转换',
		'Proceq.StatisticsTotalImpacts': '冲击总数',
		'Proceq.StatisticsTotalSeries': '全系列',
		'Proceq.StatisticsTotalExcluded': '全排除',
		'Proceq.StatisticsValuesOutOfLimits': '极限值',
		'Proceq.StatisticsPeriod': '时段',
		'Proceq.LabelFrom': '来自',
		'Proceq.LabelTo': '发送',
		'Proceq.LabelImpacts': '冲击',
		'Proceq.AbbrevCalendarWeek': '日历周',
		'Proceq.eRebNStd_Mean': '平均值',
		'Proceq.ExportFieldTargetScale': '目标标度',
		'Proceq.ExportFieldConversionCurve': '转换曲线',
		'Proceq.eCC_None': '无',
		'Proceq.eCC_Portland_Cement_J_N': '硅酸盐水泥 J',
		'Proceq.eCC_Early_Strength_J_N': '早期强度 J',
		'Proceq.eCC_Blast_Furnace_J_N': '高炉 J',
		'Proceq.eCC_Average_Curve_J_N': '平均曲线 J',
		'Proceq.eSC_R1_dry': 'JSCE-G504 R1 = 0（干）',
		'Proceq.eSC_R1_black_mark': 'JSCE-G504 R1 = +3（污点）',
		'Proceq.eSC_R1_wet_surface': 'JSCE-G504 R1 = +5（湿表面）',
		'Proceq.eCSC_None': '无',
		'Proceq.eCSC_Upper': '上方',
		'Proceq.eCSC_Under': '下方',
		'Proceq.eSCC_US_eCSC_None': '统一强度',
		'Proceq.eSCC_US_eCSC_Upper': '浇铸表面上方的统一强度',
		'Proceq.eSCC_US_eCSC_Under': '浇铸表面下方的统一强度',
		'Proceq.eSCC_PC_eCSC_None': '泵送混凝土',
		'Proceq.eSCC_PC_eCSC_Upper': '浇铸表面上方的泵送混凝土',
		'Proceq.eSCC_PC_eCSC_Under': '浇铸表面下方的泵送混凝土',
		'Proceq.eSCC_US': '统一强度',
		'Proceq.eSCC_PC': '泵送混凝土',
		'Proceq.eNoOutlier': '无异常值',
		'Proceq.eOutlierLow': '异常值低',
		'Proceq.eOutlierHigh': '异常值高',
		'Proceq.eOutlierRedundant': '异常值冗余',
		'Proceq.LogbookPropertyConversionCurveChangedFromTo': '转换曲线从 **?0** 更改为 **?1**',
		'Proceq.LogbookPropertyConversionCurveChangedTo': '转换曲线更改为 **?0**',
		'Proceq.LogbookPropertyTargetScaleChangedFromTo': '目标标度从 **?0** 更改为 **?1**',
		'Proceq.LogbookPropertySurfaceCorrectionChangedFromTo': '表面校正从 **?0** 更改为 **?1**',
		'Proceq.LogbookPropertySurfaceCorrectionChangedTo': '表面校正更改为 **?0**',
		'Proceq.LogbookPropertyCarbonationDepthChangedFromTo': '碳化深度从 **?0** mm 更改为 **?1** mm',
		'Proceq.LogbookPropertySurfaceConditionChangedFromTo': '表面状况从 **?0** 更改为 **?1**',
		'Proceq.LogbookPropertySurfaceConditionChangedTo': '表面状况更改为 **?0**',
		'Proceq.LogbookPropertyConcreteAgeChangedFromTo': '混凝土龄期从 **?0** d 更改为 **?1** d',
		'Proceq.LogbookPropertyConcreteAgeChangedTo': '混凝土龄期更改为 **?0** d',
		'Proceq.ExportColumnCSVOutlierType': '异常值',
		'Proceq.ExportColumnCarbonationDepth': '碳化深度',
		'Proceq.ExportColumnConcreteAge': '混凝土龄期',
		'Proceq.ExportFieldCarbonationDepth': '碳化深度',
		'Proceq.ExportFieldConcreteAge': '混凝土龄期',
		'Proceq.ValueConcreteAgeDays': '**?0** d',
		'Proceq.ValueCarbonationDepthMms': '**?0** mm',
		'Proceq.ExportColumnConversionCurve': '转换曲线',
		'Proceq.ExportColumnTableLegendOutlier': '* 异常值',
		'Proceq.ExportColumnTargetScale': '目标标度',
		'Proceq.ExportColumnMeasurementAvgCompressiveStrength': '抗压强度测量平均值',
		'Proceq.ExportColumnEn13791Screening': 'EN 13791 筛选',
		'Proceq.ExportColumnLowestRebound': '最低回弹值',
		'Proceq.ExportColumnMedianRebound': '平均回弹值',
		'Proceq.ExportColumnCompressiveStrengthClass': 'EN 206 抗压强度等级',
		'Proceq.EmailIntroDearContractOwner': '亲爱的合同所有人',
		'Proceq.ColumnTableSeriesRegionExport': '序列',
		'Proceq.ExportColumSeriesRegionExport': '序列',
		'Proceq.ExportTitleTestRegionExport': '测试区域导出',
		'Proceq.ErrorSchmidtExportInvalidStandard': '所有序列应有相同的标准集',
		'Proceq.ErrorSchmidtExportTooFewSeries': 'EN 13791 筛选需要至少 9 个序列',
		'Proceq.ErrorSchmidtExportTooManySeries': '单次导出最多可选择 99 个序列',
		'Proceq.ErrorSchmidtExportDifferentConfiguration': '在序列中找到不同的配置',
		'Proceq.ErrorSchmidtExportWrongStandard': '所选导出中发现错误标准',
		'Proceq.ErrorSchmidtExportInvalidSeries': '根据所选的标准，至少一个系列无效',
		'Proceq.ErrorSchmidtExportSoftwareError': '导出生成时出错',
		'Proceq.ErrorSchmidtExportOutOfRange': '已计算的强度等级超出范围',
		'Proceq.ButtonExportTestRegionEU': '将 EN 13791 筛选测试导出为 PDF',
		'Proceq.ButtonExportTestRegionUniformity': '均匀性导出为 pdf',
		'Proceq.ButtonExportTestRegionChina': '将 JGJ T23 测试区域导出为 PDF',
		'Proceq.ErrorNotYetImplemented': '还未实施',
		'Proceq.ErrorSchmidtExportWrongParam': '序列中发现错误参数。例如：所选导出单位错误',
		'Proceq.ErrorSchmidtExportUnitNotMpa': '对于 JGJ 区域，单位必须设置为 MPa',
		'Proceq.ErrorSchmidtExportWrongFormFactor': '对于 JGJ 区域，形状因数不能是 2:1 圆柱',
		'Proceq.NavSelectView': '选择视图',
		'Proceq.TextConfirmationAreYouSure': '是否确定？',
		'Proceq.ErrorContractLinkedDifferentImpactDevice': '合同不能与当前冲击装置序列号一起使用',
		'Proceq.TitleShareByUrl': '通过 URL 分享',
		'Proceq.ExportTitleVerification': '验证报告：',
		'Proceq.ExportVerificationStatusFailed': '失败',
		'Proceq.ExportVerificationStatusPassed': '通过',
		'Proceq.ExportColumnTestVerificationResult': '验证结果',
		'Proceq.ExportColumnTestBlockTolerance': '测试块不确定度 (Ucrm) k=2',
		'Proceq.ExportColumnTestBlockNominalHardness': '测试块标称硬度',
		'Proceq.ExportColumnTestBlockSN': '测试块序列号',
		'Proceq.ExportColumnTestBlockName': '测试块名称',
		'Proceq.ExportColumnVerificationStd': '验证标准',
		'Proceq.ExportColumnVerificationCriteria': '验证条件',
		'Proceq.VerificationCriteriaAverage5Span5Perc': '平均 +/- 5%，跨距 +/- 5%',
		'Proceq.VerificationCriteriaReadings6HL': '读数 +/- 6 HL',
		'Proceq.VerificationCriteriaReadings3Perc': '读数 +/- 3%',
		'Proceq.VerificationCriteriaAverage5Perc': '平均 +/- 5%',
		'Proceq.VerificationCriteriaAverage2R': '平均 +/- 2 R',
		'Proceq.VerificationCriteriaReadings3R': '读数 +/- 3 R',
		'Proceq.StandardEnumCustom': '自定义',
		'Proceq.MaterialEnumCustom': '自定义',
		'Proceq.ExportColumnCalibrationAnvilName': '校准钢砧名称',
		'Proceq.ExportColumnCalibrationAnvilSN': '校准钢砧序列号',
		'Proceq.ExportColumnCalibrationAnvilReboundValue': '校准钢砧回弹值',
		'Proceq.ExportColumnCalibrationAnvilTolerance': '校准钢砧容差',
		'Proceq.ExportColumnTestLoadInfo': '检测负载信息',
		'Proceq.NavVerificationData': '验证数据',
		'Proceq.NavCustomData': '自定义数据',
		'Proceq.TableColumnVerificationResult': '结果',
		'Proceq.TitleVerifications': '验证',
		'Proceq.LogBookTestBlock': '测试块',
		'Proceq.LogBookCalibrationAnvil': '校准钢砧',
		'Proceq.LogbookPropertyTestBlockChangedFromTo': '测试块已从 "**?0**" 更改为 "**?1**"',
		'Proceq.LogbookPropertyTestBlockChangedTo': '测试块更改为 "**?0**"',
		'Proceq.LogbookPropertyCalibrationAnvilChangedFromTo': '校准钢砧从 **?0** 更改为 **?1**',
		'Proceq.LogbookPropertyCalibrationAnvilChangedTo': '校准钢砧更改为 **?0**',
		'Proceq.LogbookPropertyCreatedVerification': '已创建的验证 "**?0**"',
		'Proceq.LogbookPropertyRenamedVerificationFromTo': '验证已从 "**?0**" 重命名为 "**?1**"',
		'Proceq.LogbookResultVerificationFailed': '验证失败',
		'Proceq.LogbookResultVerificationPassed': '验证通过',
		'Proceq.LogbookPropertyTestLoadChangedFromTo': '检测负载从 **?0** 更改为 **?1**',
		'Proceq.LogbookPropertyTestLoadChangedTo': '检测负载更改为 **?0**',
		'Proceq.LogBookTestLoad': '检测负载',
		'Proceq.FieldWebOriginalCurve': '原始',
		'Proceq.FieldWebCustomCurve': '自定义',
		'Proceq.ExportFieldValuePair': '值对',
		'Proceq.TableBaseMaterial': '基础材料',
		'Proceq.TableDescription': '说明',
		'Proceq.NavCustomMaterial': '自定义材料',
		'Proceq.FolderCustomMaterials': '自定义材料',
		'Proceq.SchmidtCurveTypePolynomial': 'fck = a + b*R + c*R²（多项式）',
		'Proceq.SchmidtCurveTypeExponencial': 'fck = a.e^(b*R)（指数）',
		'Proceq.SchmidtCurveTypePolynomialShort': '多项式',
		'Proceq.SchmidtCurveTypeExponencialShort': '指数',
		'Proceq.ExportFieldCurveType': '曲线类型',
		'Proceq.TableActionEdit': '编辑',
		'Proceq.TableActionAdd': '添加',
		'Proceq.WebAccountActivatedTitle': '验证成功',
		'Proceq.PasswordChangedTitle': '密码已更改',
		'Proceq.ResetPasswordFormHeading': '重置您的密码',
		'Proceq.PasswordChangedBody': '密码已被成功重置。您可以使用您的新密码来登录您的账户。',
		'Proceq.EnterYourNewPassword': '输入您的新密码',
		'Proceq.WebAccountActivatedBodyLine1':
			'恭喜您，您已经成功验证了您的电子邮件地址。您现在可以开始您的检测之旅了。',
		'Proceq.WebAccountActivatedProceqTeam': 'Proceq 团队',
		'Proceq.NavDashboard': '控制面板',
		'Proceq.Confirm': '确认',
		'App.SchmidtFormFactorRAngle': 'R 角已修正',
		'App.SchmidtFormFactorQAngle': 'Q值',
		'App.ShareMeasurementsCopySuccess': '已复制！',
		'App.ShareMeasurementsCopyFailed': '您的浏览器不支持复制。请手动复制。',
		'App.SystemFolders': '系统文件夹',
		'Proceq.DataSeriesDemo': '演示',
		'Proceq.DataSeriesImported': '已导入',
		'Antenna Spacing [{unit}]': '天线间距[{unit}]',
		'App Version': '应用程式版本',
		'Base Unit S/N': '基本单位S / N',
		'Calculated Depth': '计算深度',
		Comment: '评论',
		'Contract ID': '合约编号',
		'Distance X [{unit}]': '距离X [{unit}]',
		'Distance Y [{unit}]': '距离Y [{unit}]',
		'Export HTML Format Version': '导出HTML格式版本',
		'File ID': '档案编号',
		'File Name': '文档名称',
		'Firmware Version': '固件版本',
		'Grid Size X [{unit}]': '网格尺寸X [{unit}]',
		'Grid Size Y [{unit}]': '网格尺寸Y [{unit}]',
		'Grid Spacing X [{unit}]': '网格间距X [{unit}]',
		'Grid Spacing Y [{unit}]': '网格间距Y [{unit}]',
		'Hardware Version': '硬件版本',
		Line: '线',
		'MAC Address': 'MAC地址',
		'Marker Number': '标记号',
		'Measurement Presets': '测量预设',
		Mode: '模式',
		Name: '名称',
		Objects: '对象',
		'Probe S/N': '探头序列号',
		Product: '产品',
		'Repetition Rate [{unit}]': '重复率[{unit}]',
		Resolution: '解析度',
		'Sampling Rate [{unit}]': '采样率[{unit}]',
		'Scan Distance [{unit}]': '扫描距离[{unit}]',
		'Scan Length [{unit}]': '扫描长度[{unit}]',
		'Set Depth': '设定深度',
		'Set Size': '设定尺寸',
		Tag: '标签',
		'Tag Type': '标签类型',
		'Time Window [{unit}]': '时间窗[{unit}]',
		Units: '单位',
		'User / Probe Info': '用户/探针信息',
		Markers: '标记物',
		'Created {fileName} in folder {folderName}': '在文件夹{folderName}中创建了{fileName}',
		'Mode: {value}': '模式：{value}',
		'Auth.LoggingIn': '正在登录……',
		'Auth.LoggingOut': '正在退出……',
	},
};
