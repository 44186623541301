import React, { useEffect } from 'react';
import { Spin } from 'antd';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { finishUserSession, logout, logoutSuccess } from '../../actions/logout';
import useIsAuthenticated from '../../hooks/useIsAuthenticated';
import { redirectEagleIDLogout } from '../../utils/authUtils';
import AuthBackground from './AuthBackground';

const Logout = () => {
	const dispatch = useDispatch();
	const { formatMessage } = useIntl();

	const isAuthenticated = useIsAuthenticated();

	useEffect(() => {
		const action = async () => {
			try {
				if (isAuthenticated) {
					await dispatch(logout());
				}
			} finally {
				await dispatch(logoutSuccess());
				await dispatch(finishUserSession());
				redirectEagleIDLogout();
			}
		};
		action();
	}, [isAuthenticated, dispatch]);

	return (
		<AuthBackground>
			<Spin tip={formatMessage({ id: 'Auth.LoggingOut' })} size="large" />
		</AuthBackground>
	);
};

export default Logout;
