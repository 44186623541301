// @flow
import React from 'react';
import { FormattedMessage } from 'react-intl';

export const RestoreFile = ({ log }: GPRLogbookEntryProps) => (
	<FormattedMessage
		id="App.LogbookEntryRestored"
		values={{
			folder: log.content.folder,
		}}
	/>
);

export const color = () => 'grey';

export default RestoreFile;
