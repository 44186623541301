// @flow
import React from 'react';
import moment from 'moment';
import { compose, withState, lifecycle } from 'recompose';
import { connect } from 'react-redux';
import URL from 'apis/urls';
import { Button, Dropdown, Menu, Icon } from 'antd';
import { FormattedMessage } from 'react-intl';
import { request } from 'actions/ajax';
import { getFileNameFromContentDisposition } from 'utils/downloadHelper';
import styles from '../shared/SmarterTable.styl';

export const Download = ({
	iDs,
	loading,
	download,
	items,
}: {
	iDs: string[],
	loading: boolean,
	download: Function,
	items: Object,
}) => (
	<Dropdown
		disabled={iDs.length < 1}
		overlay={
			<Menu onClick={({ key }) => download(key)}>
				{(() => {
					return items.map(item => (
						<Menu.Item key={item.key}>
							<FormattedMessage id={item.id} />
						</Menu.Item>
					));
				})()}
			</Menu>
		}
	>
		<Button type="primary" loading={loading} className={styles.button}>
			<FormattedMessage id="Proceq.LogbookDownload" /> <Icon type="down" />
		</Button>
	</Dropdown>
);

const longRunningExcludedFormat = ['json'];

export default compose(
	withState('loading', 'setLoading', false),
	withState('items', 'setItems', []),
	withState('isLongRunningJobEnable', 'setLongRunningJobEnable', false),
	connect(null, (dispatch: Function, props) => ({
		download: key =>
			dispatch(
				request({
					method: 'POST',
					url:
						props.isLongRunningJobEnable && !longRunningExcludedFormat.includes(key)
							? URL.EXPORT_REPORT_AS_URL
							: URL.EXPORT_REPORTS,
					params: {
						product: props.product,
						fileType: 'measurement',
						format: key,
						iDs: props.iDs,
						timeZoneOffset: moment().utcOffset(),
					},
					setLoading: props.setLoading,
					returnResponseHeaders: true,
					options: {
						responseType:
							props.isLongRunningJobEnable && !longRunningExcludedFormat.includes(key)
								? 'text'
								: props.items.filter(obj => obj.key === key)[0].responseType,
					},
				})
			).then(response => {
				if (props.isLongRunningJobEnable && !longRunningExcludedFormat.includes(key)) {
					window.open(response.data.url, '_blank');
				} else {
					window.fileDownload(
						response.data,
						getFileNameFromContentDisposition(response.headers['content-disposition'] || ''),
						response.headers['content-type']
					);
				}
			}),
		setLongRunningJobVisibility: () => {
			dispatch(
				request({
					url: URL.APP_CONFIG,
					method: 'GET',

					setLoading: props.setLoading,
					params: {
						product: 'gpr',
					},
				})
			).then(resp => {
				let enable = false;

				if (resp.IS_LONG_RUNNING_JOB_ENABLE_COMMON === 'YES' && props.product === 'GPR_SOIL') {
					enable = true;
				}

				props.setLongRunningJobEnable(enable);
			});
		},
	})),
	lifecycle({
		componentDidUpdate(prevProps) {
			if (prevProps.items === []) {
				this.setItems(this.props.items);
			}
		},
		componentDidMount() {
			this.props.setLongRunningJobVisibility();
			if (this.props.items === undefined || this.props.items.length === 0) {
				const itm = [];
				switch (this.props.product) {
					case 'GPR':
						itm.push({ key: 'html', id: 'Proceq.TableActionExportHTML', responseType: 'blob' });
						itm.push({ key: 'segy', id: 'Proceq.TableActionExportSEGY', responseType: 'blob' });
						break;
					case 'GPR_SOIL':
						itm.push({ key: 'shp', id: 'Proceq.TableActionExportSHP', responseType: 'blob' });
						itm.push({ key: 'dxf', id: 'Proceq.TableActionExportDXF', responseType: 'blob' });
						itm.push({ key: 'kml', id: 'Proceq.TableActionExportKML', responseType: 'blob' });
						itm.push({ key: 'html', id: 'Proceq.TableActionExportHTML', responseType: 'blob' });
						itm.push({ key: 'segy', id: 'Proceq.TableActionExportSEGY', responseType: 'blob' });
						break;
					case 'PUNDIT':
						itm.push({ key: 'html', id: 'Proceq.TableActionExportHTML', responseType: 'blob' });
						itm.push({ key: 'raw', id: 'Proceq.TableActionExportRaw', responseType: 'blob' });
						break;
					case 'FDL':
						itm.push({ key: 'html', id: 'Proceq.TableActionExportHTML', responseType: 'blob' });
						itm.push({ key: 'csv', id: 'Proceq.TableActionExportRaw', responseType: 'blob' });
						break;
					case 'GLM':
						itm.push({ key: 'pdf', id: 'Proceq.TableActionExportAsPDF', responseType: 'blob' });
						itm.push({ key: 'csv', id: 'Proceq.TableActionExportAsCSV', responseType: 'csv' });
						break;
					case 'PIT_IE':
						itm.push({ key: 'raw', id: 'Proceq.TableActionExportRaw', responseType: 'blob' });
						itm.push({ key: 'html', id: 'Proceq.TableActionExportHTML', responseType: 'blob' });
						break;
				}
				if (this.props.enableJsonDownload) {
					itm.push({ key: 'json', id: 'Proceq.TableActionExportJSON', responseType: 'blob' });
				}
				itm.sort((a, b) => (a.key < b.key ? -1 : 1));
				this.props.setItems(itm);
			}
		},
	})
)(Download);
