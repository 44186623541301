// @flow
import { withHighcharts } from 'react-jsx-highcharts';
import Highcharts from 'highcharts';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { MainData } from '../MeasurementsData/MainData';

export class MainDataVerifications extends MainData {
	isVerification = true;

	disableFolders = true;

	colModel = {
		EQUOTIP: [
			{
				title: 'Id',
				dataIndex: '_id',
				sorter: false,
				width: '20%',
				typeField: 'hidden',
			},
			{
				title: 'TableDataSeriesName',
				dataIndex: 'dataSeriesName',
				sorter: true,
				filter: true,
			},
			{
				title: 'TableCreatedOn',
				dataIndex: 'createdTs',
				sorter: true,
				typeField: 'dateTimeNoSecs',
			},
			{
				title: 'TableColumnVerificationResult',
				dataIndex: 'status_verification',
				globalRegister: 'verificationstatuses',
				sendValueInsteadOfID: true,
				sorter: true,
				filter: true,
			},
			{
				title: 'ExportFieldStandard',
				dataIndex: 'standard',
				globalRegister: 'standards',
				sorter: true,
				filter: true,
			},
			{
				title: 'TableNumMean',
				dataIndex: 'mean',
				typeField: 'statistics-no0-avg',
				sorter: true,
			},
			{
				title: 'TableScaleUnit',
				dataIndex: 'scaleUnits',
				globalRegister: 'units',
				sendValueInsteadOfID: true,
				sorter: true,
				filter: true,
			},
			{
				title: 'metadata',
				dataIndex: 'metadata',
				sorter: false,
				typeField: 'hidden',
			},
			{
				title: 'datainfo',
				dataIndex: 'datainfo',
				sorter: true,
				typeField: 'hidden',
			},
		],
		SCHMIDT: [
			{
				title: 'Id',
				dataIndex: '_id',
				sorter: false,
				width: '20%',
				typeField: 'hidden',
			},
			{
				title: 'TableDataSeriesName',
				dataIndex: 'dataSeriesName',
				sorter: true,
				filter: true,
			},
			{
				title: 'TableCreatedOn',
				dataIndex: 'createdTs',
				sorter: true,
				typeField: 'dateTimeNoSecs',
			},
			{
				title: 'TableColumnVerificationResult',
				dataIndex: 'status_verification',
				globalRegister: 'verificationstatuses',
				sendValueInsteadOfID: true,
				sorter: true,
				filter: true,
			},
			{
				title: 'ExportFieldStandard',
				dataIndex: 'standard',
				globalRegister: 'standardsschmidt',
				sorter: true,
				filter: true,
			},
			{
				title: 'TableNumMean',
				dataIndex: 'mean',
				typeField: 'statistics-no0-avg',
				sorter: true,
			},
			{
				title: 'TableScaleUnit',
				dataIndex: 'scaleUnits',
				globalRegister: 'unitsschmidt',
				sendValueInsteadOfID: true,
				sorter: true,
				filter: true,
			},
			{
				title: 'metadata',
				dataIndex: 'metadata',
				sorter: false,
				typeField: 'hidden',
			},
			{
				title: 'datainfo',
				dataIndex: 'datainfo',
				sorter: true,
				typeField: 'hidden',
			},
		],
	};

	specificActions = {
		EQUOTIP: [],
		SCHMIDT: [],
	};
}

const mapStateToProps = state => ({
	session: state.session,
	locale: state.locale,
	globalregister: state.globalregister,
});

export default withHighcharts(connect(mapStateToProps)(injectIntl(MainDataVerifications)), Highcharts);
