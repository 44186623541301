// @flow
import SmarterTable from '../shared/SmarterTable';

export class ContractsBaseTable extends SmarterTable<Contract> {
	handleTableChange: Function = (pagination, filters, sorter) => {
		// Reset tier filter if product filter changes
		if (filters.product && (!this.state.filters.product || filters.product[0] !== this.state.filters.product[0])) {
			filters.tier = [];
		}

		return super.handleTableChange(pagination, filters, sorter);
	};
}
export default ContractsBaseTable;
