const columnConfig = [
	{
		title: 'Proceq.FormFirstName',
		dataIndex: 'firstName',
	},
	{
		title: 'Proceq.FormLastName',
		dataIndex: 'lastName',
	},
	{
		title: 'Proceq.FormEmail',
		dataIndex: 'email',
	},
	{
		title: 'Proceq.FormPhoneNumber',
		dataIndex: 'phoneNumber',
	},
	{
		title: 'Proceq.FormAddress',
		dataIndex: 'address',
	},
	{
		title: 'App.BUNotificationEmail',
		dataIndex: 'notificationEmail',
	},
];

export default columnConfig;
