// @flow
import React from 'react';
import { get } from 'lodash';
import { convertAndDisplay } from 'constants/conversionRates';
import UserComponent from './components/Common/User';
import MeasurementPresets from './components/Settings/PI8000/MeasurementPresets';
import FilteringPresets from './components/Settings/PI8000/FilteringPresets';
import PilesSummary from './components/Settings/PI8000/PilesSummary';
import PilePositions from './components/Settings/PI8000/PilePositions';
import Logbook from './components/Common/Logbook';
import styles from './HTMLExport.styl';

export type DerivedProps = {
	scanType: ScanType,
	product?: ProductCode,
	productModel?: string,
	isMetric: boolean,
	convert: (string | number, string) => string | number,
};

const PI8000 = ({ data, users }: { data: MeasurementFullData, users: User[] }) => {
	const productModel = get(data, 'measurement.productModel');
	const scanType = get(data, 'measurement.type');
	const isMetric = get(data, 'settings.0.content.display.unit') !== 'Imperial';
	const derivedProps: DerivedProps = {
		// $FlowFixMe
		product: data.productFamily.toUpperCase(),
		productModel,
		scanType,
		isMetric,
		convert: (value, unitId) =>
			isFinite(+value) ? convertAndDisplay(unitId, scanType, isMetric).convertFunction(+value) : '-',
	};

	return (
		<div className="html-body">
			<h1 id={data.measurement.id} className={styles.file_name}>
				{data.measurement.name}
			</h1>
			<br />
			<UserComponent data={data} {...derivedProps} />
			<br />
			<MeasurementPresets data={data} {...derivedProps} />
			<br />
			<FilteringPresets data={data} {...derivedProps} />
			<br />
			<h1 className={styles.main_header}> {'Piles'} </h1>
			<br />
			<PilesSummary data={data} {...derivedProps} />
			<br />
			<PilePositions data={data} {...derivedProps} />
			<br />
			<Logbook data={data} users={users} {...derivedProps} />
		</div>
	);
};

export default PI8000;
