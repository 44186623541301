// @flow
import React, { Component } from 'react';
import classNames from 'classnames';
import { get, upperFirst, isFunction } from 'lodash';
import moment from 'moment';

import { renderLogbookTime } from 'utils/dateHandler';
import { ProductConstants } from 'components/contracts/contractConsts';
import styles from './LogEvent.styl';
import logIcon from './icoSelfLog.png';
import logIconDeleted from './icoSelfLogDeleted.png';
import GenericLogEvent from './GenericLogEvent';
import LogEventInfo from './LogEventInfo';

export const renderUserInitials = (users: User[] = [], uID: number) => {
	const user = users.find(u => u.id === +uID);
	if (user) {
		const initials = [user.firstName, user.lastName]
			.map(name => name.slice(0, 1).toUpperCase())
			.filter(Boolean)
			.join('');

		if (initials.length > 0) {
			return initials;
		}
	}

	return '';
};

export class LogEvent extends Component<
	{
		highlighted: boolean,
		probeLog?: Log,
		showDate: boolean,
		date: number,
		user: string,
	} & (GPRLogbookEntryProps | EquotipLogbookEntryProps),
	{
		element: any,
		icon: ?string,
		color: ?string,
	}
> {
	state = {
		element: null,
		icon: null,
		color: 'blue',
	};

	async componentWillMount() {
		const { log } = this.props;
		let { product } = this.props;
		if (product === 'PUNDIT' && upperFirst(log.type) !== 'Creation') {
			product = 'GPR';
		}

		// Map all the modules of SOIL to ..LogEvent/GPR/*
		if (product === 'GPR_SOIL') {
			product = 'GPR';
		}

		let logType = upperFirst(log.type);
		if (product === 'GLM') {
			switch (logType) {
				case 'Probe':
				case 'Creation':
				case 'Limits':
				case 'Angles':
				case 'Measurements':
					product = 'GLM';
					break;
			}
		}
		if (product === 'EQUOTIP' || product === 'SCHMIDT' || product === 'GLM') {
			switch (logType) {
				case 'ImportFile':
				case 'ChangeFile':
				case 'ChangeFileUnit':
					product = 'GPR';
					break;
				case 'UserPhoto':
				case 'UserAudio':
				case 'UserText':
				case 'RenameFile':
				case 'TrashFile':
				case 'MoveFile':
				case 'Probe':
				case 'Favourite':
				case 'Limit':
				case 'Material':
				case 'Measurement':
				case 'Scale':
				case 'Result':
				case 'Standard':
				case 'TestBlock':
				case 'TestLoad':
					product = 'EQUOTIP';
					break;
			}
		}

		if (product === 'FDL') {
			switch (logType) {
				case 'Probe':
				case 'Snapshot':
				case 'SaveSnapshot':
				case 'RenameFile':
				case 'ImportFile':
				case 'UserPhoto':
				case 'UserAudio':
				case 'UserText':
					product = 'GPR';
					break;
			}
		}

		if (product === 'PIT_IE') {
			switch (logType) {
				case 'AddNewImpact':
				case 'Creation':
				case 'MarkerChange':
				case 'Snapshot':
				case 'TextWithKeyValues':
					break;
				case 'RenameFile':
				case 'ImportFile':
				case 'ChangeFile':
				case 'TrashFile':
				case 'MoveFile':
					product = 'GPR';
					break;
				default:
					logType = 'GenericLogEvent';
			}
		}
		let element = {};
		element = await require(`./${product}/${logType}`);

		this.setState({
			element: get(element, 'default', GenericLogEvent),
		});

		if (isFunction(element.icon)) {
			this.setState({ icon: element.icon(log) || 'info', color: null });
		}

		if (isFunction(element.color)) {
			this.setState({ color: element.color(log) || 'blue' });
		}
	}

	componentDidCatch(err: Object) {
		if (window.Sentry) {
			window.Sentry.withScope(scope => {
				scope.setLevel('info');
				window.Sentry.captureException(err);
			});
		}
	}

	static getDerivedStateFromError() {
		return { element: GenericLogEvent };
	}

	render() {
		const {
			log,
			user,
			product,
			productModel,
			showDate,
			date,
			highlighted,
			version,
			probeLog,
			...props
		} = this.props;
		const Element = this.state.element;
		const hasExtraInfo =
			log.type === 'textWithKeyValues' || (log.type === 'objectMarker' && log.content.status !== 'deleted');
		const hasMA8000 =
			(product.toUpperCase() === ProductConstants.GPR_SOIL && probeLog?.content.ma8000SerialNumber) ?? undefined;
		return (
			<li
				className={classNames({
					[styles.message]: true,
					message: true,
					messageWithAttachment: log.type === 'fileLogEvent',
				})}
			>
				{showDate && <div className={styles.date_container}>{moment(date).format('D MMM YYYY')}</div>}
				<div className={styles.content_container}>
					<div>
						<div>
							<time className={styles.time} dateTime={date}>
								{renderLogbookTime(date)}
							</time>
							<span className={styles.user}>{user}</span>
						</div>
						{Element && (
							<Element
								product={product}
								productModel={productModel}
								version={version}
								log={log}
								hasMA8000={hasMA8000}
								{...props}
							/>
						)}
					</div>
					{this.state.color && <div className={classNames(styles.icon, styles[this.state.color])} />}
					{this.state.icon && (
						<div className={classNames(styles.icon, styles.icon_with_logo)}>
							<img
								alt="icon"
								className={styles.logo}
								src={this.state.icon === 'log' ? logIcon : logIconDeleted}
							/>
						</div>
					)}
					{/* <div className={ styles.line } /> */}
				</div>
				{hasExtraInfo && (
					<div
						className={classNames({
							log_event: true, // for printing
							[styles.log_event]: true,
							[styles.highlighted]: highlighted,
						})}
					>
						<LogEventInfo
							product={product}
							productModel={productModel}
							log={log}
							isMetric={get(props, 'isMetric')}
							scanType={get(props, 'scanType')}
							version={version}
						/>
					</div>
				)}
			</li>
		);
	}
}

export default LogEvent;
