// @flow
import React from 'react';
import moment from 'moment';
import { compose, withState } from 'recompose';
import { connect } from 'react-redux';
import URL from 'apis/urls';
import { Button, Dropdown, Menu, Icon } from 'antd';
import { FormattedMessage } from 'react-intl';
import { request } from 'actions/ajax';
import { getFileNameFromContentDisposition } from 'utils/downloadHelper';
import styles from '../shared/SmarterTable.styl';

export const ExportPDFReports = ({
	iDs,
	fileType,
	product,
	loading,
	download,
}: {
	iDs: string[],
	fileType: MeasurementFileType,
	product: ProductCode,
	loading: boolean,
	download: Function,
}) =>
	fileType === 'measurement' && product === 'SCHMIDT' ? (
		<Dropdown
			disabled={iDs.length < 1}
			overlay={
				<Menu onClick={({ key }) => download(key)}>
					<Menu.Item key={fileType}>
						<FormattedMessage id="Proceq.TableActionExportAsPDF" />
					</Menu.Item>
					<Menu.Item key="uniformity">
						<FormattedMessage id="Proceq.ButtonExportTestRegionUniformity" />
					</Menu.Item>
					<Menu.Item key="china">
						<FormattedMessage id="Proceq.ButtonExportTestRegionChina" />
					</Menu.Item>
					<Menu.Item key="eu">
						<FormattedMessage id="Proceq.ButtonExportTestRegionEU" />
					</Menu.Item>
				</Menu>
			}
		>
			<Button type="primary" loading={loading} className={styles.button}>
				<FormattedMessage id="Proceq.TableActionExportAsPDF" /> <Icon type="down" />
			</Button>
		</Dropdown>
	) : (
		<Button
			type="primary"
			loading={loading}
			disabled={iDs.length < 1}
			onClick={() => download(fileType)}
			className={styles.button}
		>
			<FormattedMessage id="Proceq.TableActionExportAsPDF" />
		</Button>
	);

export default compose(
	withState('loading', 'setLoading', false),
	connect(null, (dispatch: Function, props) => ({
		download: fileType =>
			dispatch(
				request({
					method: 'POST',
					url: URL.EXPORT_REPORTS,
					params: {
						fileType,
						format: 'pdf',
						iDs: props.iDs,
						timeZoneOffset: moment().utcOffset(),
					},
					setLoading: props.setLoading,
					returnResponseHeaders: true,
					options: {
						responseType: 'blob',
					},
				})
			).then(response =>
				window.fileDownload(
					response.data,
					getFileNameFromContentDisposition(response.headers['content-disposition']),
					response.headers['content-type']
				)
			),
	}))
)(ExportPDFReports);
