// @flow
import React from 'react';
import { connect } from 'react-redux';
import { compose, withState } from 'recompose';
import { escapeRegExp } from 'lodash';
import { Modal, Form, Input, Button, message } from 'antd';
import type { FormProps } from 'antd/lib/form';
import type { InjectIntlProvidedProps } from 'react-intl';
import { FormattedMessage, injectIntl } from 'react-intl';
import URL from 'apis/urls';
import { request } from 'actions/ajax';
import styles from '../shared/SmarterTable.styl';

export const ChangePassword = ({
	intl,
	form,
	loading,
	visible,
	toggleModal,
	changePassword,
}: {
	form: FormProps,
	loading: boolean,
	visible: boolean,
	toggleModal: Function,
	changePassword: Function,
} & InjectIntlProvidedProps) => {
	const onSubmit = e => {
		e.preventDefault();

		form.validateFields((err, values) => {
			if (err) {
				return;
			}

			changePassword(values.newPassword);
		});
	};

	return (
		<div>
			<Modal
				visible={visible}
				title={<FormattedMessage id="Proceq.TitleChangePassword" />}
				footer={null}
				onCancel={() => toggleModal(false)}
			>
				<Form onSubmit={onSubmit}>
					<Form.Item label={<FormattedMessage id="Proceq.FormPasswordNew" />} className={styles.field}>
						{form.getFieldDecorator('newPassword', {
							rules: [
								{
									required: true,
									min: 6,
									max: 25,
									message: intl.formatMessage({
										id: 'App.UsersNewPasswordValidation',
									}),
								},
							],
						})(<Input type="password" />)}
					</Form.Item>
					<Form.Item label={<FormattedMessage id="Proceq.FormConfirmPasswordNew" />} className={styles.field}>
						{form.getFieldDecorator('newPasswordConfirm', {
							rules: [
								{
									required: true,
									pattern: `^${escapeRegExp(form.getFieldValue('newPassword'))}$`,
									message: intl.formatMessage({
										id: 'App.UsersConfirmPasswordValidation',
									}),
								},
							],
						})(<Input type="password" />)}
					</Form.Item>
					<Form.Item className={styles.submit}>
						<Button type="default" onClick={() => toggleModal(false)}>
							<FormattedMessage id="Proceq.LogbookClose" />
						</Button>
						&nbsp;
						<Button type="primary" htmlType="submit" loading={loading}>
							<FormattedMessage id="Proceq.ButtonSubmit" />
						</Button>
					</Form.Item>
				</Form>
			</Modal>
			<div onClick={() => toggleModal(true)}>
				<FormattedMessage id="Proceq.TitleChangePassword" />
			</div>
		</div>
	);
};

export default compose(
	withState('loading', 'setLoading', false),
	withState('visible', 'toggleModal', false),
	injectIntl,
	connect(null, (dispatch: Function, props) => ({
		changePassword: newPassword => {
			dispatch(
				request({
					method: 'PATCH',
					url: URL.CHANGE_ACCOUNT_PASSWORD.replace('{key}', props.id),
					setLoading: props.setLoading,
					params: {
						newPassword: btoa(newPassword),
					},
				})
			).then(() => {
				props.toggleModal(false);
				message.success(
					props.intl.formatMessage({
						id: 'Proceq.MessagePasswordSuccesfullyChanged',
					})
				);
			});
		},
	})),
	Form.create()
)(ChangePassword);
