// @flow
import React, { Fragment } from 'react';
import moment from 'moment';
import { get } from 'lodash';
import { Row, Col, Icon, Tooltip as AntTooltip } from 'antd';
import { FormattedMessage } from 'react-intl';
import { rates } from 'constants/conversionRates';
import { formatDateTimeNoSecsToLocale } from 'utils/dateHandler';
import Charts from './Charts';
import styles from '../Storage.styl';
import layoutStyles from '../../layout/Layout.styl';

declare type GLMeasurementAnalytics = {
	measurements: ProductMeasurementAnalytics,
	singleMode: ProductMeasurementAnalytics,
	continuousMode: ProductMeasurementAnalytics,
	scanMode: ProductMeasurementAnalytics,
};

export const GLM = ({
	common,
	storage,
	measurements,
}: {
	common: CommonAnalytics,
	storage: StorageAnalytics,
	measurements: GLMeasurementAnalytics,
}) => (
	<Fragment>
		<Row className={layoutStyles.container}>
			<Col xs={24} className={styles.stat_container}>
				<Col xs={12} md={4}>
					<FormattedMessage id="App.DashboardTotalFolders" />
					<span className={styles.number}>{common.nFolders}</span>
				</Col>
				<Col xs={12} md={5}>
					<div>
						<FormattedMessage id="App.DashboardTotalMeasurements" />
						&nbsp;
						<AntTooltip title={<FormattedMessage id="App.AnalyticsStorageTypeMeasurementTooltip" />}>
							<Icon type="info-circle" />
						</AntTooltip>
					</div>
					<span className={styles.number}>{get(measurements, 'nMeasurements', common.nTotalFiles)}</span>
					<span className={styles.small}>{rates.bytes(storage.alive.totalStorage)}</span>
				</Col>
				<Col xs={12} md={5}>
					<FormattedMessage id="singleModeMeasurements" />
					<span className={styles.number}>{measurements.singleMode.measurementCount}</span>
				</Col>
				<Col xs={12} md={5}>
					<FormattedMessage id="continuousModeMeasurements" />
					<span className={styles.number}>{measurements.continuousMode.measurementCount}</span>
				</Col>
				<Col xs={12} md={5}>
					<FormattedMessage id="scanModeMeasurements" />
					<span className={styles.number}>{measurements.scanMode.measurementCount}</span>
				</Col>
			</Col>

			<Col xs={24} className={styles.date_container}>
				<FormattedMessage id="App.DashboardLastSynced" />
				<span className={styles.date}>
					{common.mLastUpdated > 0
						? formatDateTimeNoSecsToLocale(moment.utc(common.mLastUpdated).local())
						: '-'}
				</span>
			</Col>
		</Row>

		<div className={layoutStyles.container}>
			<Row>
				{get(measurements, 'nMeasurements', common.nTotalFiles) > 0 && (
					<Charts
						primaryTitle="App.AnalyticsStorageByType"
						primarySubtitle="App.AnalyticsStorageByScanSubtitle"
						primaryPath={[
							{
								label: 'App.DashboardConventionalScan',
								key: 'measurements',
								totalX: measurements.measurements.measurementCount,
								totalY: measurements.measurements.totalStorage,
							},
						]}
						secondaryTitle="App.AnalyticsStorageByScan"
						secondarySubtitle="App.AnalyticsStorageByTypeSubtitle"
						secondaryPath={[
							{
								label: 'App.AnalyticsStorageTypeMeasurement',
								legend: 'App.AnalyticsStorageTypeMeasurementTooltip',
								x: 'measurementCount',
								y: 'rawDataStorage',
								totalX: common.nTotalFiles,
								totalY: storage.alive.measurementRawDataStorage,
							},
							{
								label: 'App.AnalyticsStorageTypeImage',
								x: 'photoCount',
								y: 'photoStorage',
								totalX: common.nPhotos,
								totalY: storage.alive.imageStorage,
							},
							{
								label: 'App.AnalyticsStorageTypeAudio',
								x: 'audioCount',
								y: 'audioStorage',
								totalX: common.nAudios,
								totalY: storage.alive.audioStorage,
							},
							{
								label: 'App.DashboardSnapshots',
								legend: 'App.DashboardSnapshotsTooltip',
								x: 'snapshotCount',
								y: 'snapshotStorage',
							},
							{
								label: 'App.DashboardCSVCount',
								legend: 'App.DashboardCSVTooltip',
								x: 'snapshotCSVCount',
								y: 'snapshotAndCSVStorage',
							},
						]}
						total={storage.alive.totalStorage}
						measurements={measurements}
					/>
				)}
			</Row>
		</div>
	</Fragment>
);

export default GLM;
