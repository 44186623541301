import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import URL from 'apis/urls';
import { getProceqUrl } from 'utils/sessionHelper';
import SmartTable from '../../shared/SmartTable';
import styles from '../../dashboard/Settings/UserProfile.styl';

export class ContractsManagementProceq extends Component {
	static propTypes = {
		session: PropTypes.object,
	};

	tableColumns = [
		{ title: 'Id', dataIndex: '_id', sorter: false, typeField: 'hidden' },
		{
			title: 'Business Unit',
			dataIndex: 'bu_unit',
			globalRegister: 'buunits',
			filter: true,
		},
		{ title: 'Email', dataIndex: 'group_id', sorter: true, filter: true },
		{
			title: 'Product',
			dataIndex: 'product_type',
			globalRegister: 'proceqregisterproducts',
			filter: true,
		},
		// { title: 'Options', dataIndex: 'contract_options', globalRegister: ['contractoptionsschmidt'], multipleAllowed: true, filter: true },
		{
			title: 'Bluetooth s/n',
			dataIndex: 'serial_key',
			filter: true,
			sorter: true,
		},
		{
			title: 'Hardware ID',
			dataIndex: 'hardware_id',
			filter: true,
			sorter: true,
		},
		{
			title: 'Expiration date',
			dataIndex: 'expiration',
			sorter: true,
			typeField: 'date',
		},
		{
			title: 'Language',
			dataIndex: 'contact_lang',
			globalRegister: 'languagescombined',
			filter: true,
		},
	];

	createContractApiEndpoint = getProceqUrl(this.props.session, URL.PROCEQ_CREATE_CONTRACT);

	contractsFormInfo = {
		titleText: 'Edit contract',
		titleIcon: 'edit',
		apiEndpoint: getProceqUrl(this.props.session, URL.PROCEQ_UPDATE_CONTRACT),
		apiTypeMethod: 'post',
		apiSendToken: true,
		messageOnSuccess: 'MessageActionSuccesfullyPerformed',
		clearFormOnSuccess: false,
		fieldSets: [
			{
				title: null,
				fields: [
					{
						title: 'ID',
						type: 'input',
						name: '_id',
						default: '',
						styleClasses: 'hide_field',
						validation: {
							type: 'standard',
							required: false,
						},
					},
					{
						title: 'FormCustomerEmail',
						type: 'input',
						name: 'group_id',
						styleClasses: 'force_full_field',
						validation: {
							type: 'standard',
							required: true,
						},
					},
					{
						title: 'Product',
						type: 'select',
						name: 'product_type',
						default: 'EQUOTIP',
						styleClasses: 'force_half_and_inline',
						allowedValues: {
							type: 'globalRegister',
							data: 'proceqregisterproducts',
						},
						validation: {
							type: 'standard',
							required: true,
						},
					},
					/* {
						title: 'Options',
						type: 'selectmulti',
						name: 'contract_options',
						styleClasses: 'force_half_and_inline',
						showIf: {
							name: ['product_type'],
							value: ['SCHMIDT']
						},
						allowedValues: {
							type: 'globalRegister',
							data: 'contractoptionsschmidt'
						},
						validation: {
							type: 'standard',
							required: false
						}
					}, */
					{
						title: 'Bluetooth s/n',
						name: 'serial_key',
						placeholder: 'BT12-345-6789',
						type: 'input',
						styleClasses: 'force_half_and_inline',
						validation: {
							type: 'bluetoothserial',
							required: true,
						},
					},
					/* {
					 title: 'Hardware ID',
					 name: 'hardware_id',
					 placeholder: 'AB12345678901234',
					 type: 'input',
					 styleClasses: 'force_half_and_inline',
					 validation: {
					 type: 'bluetoothhwid',
					 required: true
					 }
					 }, */
					{
						title: 'FormExpirationDate',
						name: 'expiration',
						type: 'date',
						styleClasses: 'force_half_and_inline',
						validation: {
							type: 'date',
							required: true,
						},
						extras: {
							showRemainingDays: true,
							showRemainingText: 'TextRemainingXDays',
						},
					},
					/* {
					 title: 'Features',
					 type: 'selectmulti',
					 name: 'features2',
					 styleClasses: 'force_full_field',
					 showIf: {
					 name: ['product'],
					 value: ['PUNDIT'],
					 replaceField: 'features'
					 },
					 allowedValues: {
					 type: 'globalRegister',
					 data: 'featuresoptionspundit'
					 },
					 validation: {
					 type: 'standard',
					 required: false
					 }
					 }, */
					{
						title: 'Business Unit',
						type: 'select',
						styleClasses: 'force_half_and_inline',
						allowedValues: {
							type: 'globalRegister',
							data: 'buunits',
						},
						name: 'bu_unit',
						validation: {
							type: 'standard',
							required: true,
						},
					},
					{
						title: 'Customer Language',
						styleClasses: 'force_half_and_inline',
						type: 'select',
						default: 'en',
						allowedValues: {
							type: 'globalRegister',
							data: 'languagescontact',
						},
						name: 'contact_lang',
						validation: {
							type: 'standard',
							required: true,
						},
					},
				],
			},
		],
	};

	constructor(props) {
		super(props);
		this.state = {
			hideAll: true,
		};
	}

	componentWillMount() {
		this.setState({
			hideAll: false,
			exportCSVInfo: {
				apiEndpoint: getProceqUrl(this.props.session, URL.PROCEQ_EXPORT_CONTRACTS_CSV),
				apiTypeMethod: 'post',
				apiSendToken: true,
			},
			tableColumns: this.tableColumns,
		});
	}

	render() {
		if (this.state.hideAll) {
			return <div />;
		}
		return (
			<div className={styles.profile}>
				<div className={styles.profile_container}>
					<SmartTable
						apiEndpoint={getProceqUrl(this.props.session, URL.PROCEQ_GET_CONTRACTS)}
						apiTypeMethod="post"
						apiSendToken
						columns={this.state.tableColumns}
						columnKey="_id"
						typeSortingFiltering="proceq"
						typeRetrieveData="proceq"
						editFormInfo={{}}
						addFormInfo={{}}
						exportCSVInfo={this.state.exportCSVInfo}
					/>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	session: state.session,
	locale: state.locale,
	globalregister: state.globalregister,
});

export default connect(mapStateToProps)(ContractsManagementProceq);
