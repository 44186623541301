import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import URL from 'apis/urls';
import { getProceqUrl } from 'utils/sessionHelper';
import { startDownloadFile } from 'utils/downloadHelper';
import { getKeyValue } from 'utils/registryHelper';
import styles from '../../dashboard/Settings/UserProfile.styl';
import SmartForm from '../../shared/SmartForm';

export class ReportsAdminProceq extends Component {
	static propTypes = {
		session: PropTypes.object,
	};

	reportsFormInfo = {
		titleText: 'Reports',
		titleIcon: 'book',
		apiEndpoint: getProceqUrl(this.props.session, URL.PROCEQ_ADMIN_REPORTS),
		apiTypeMethod: 'post',
		apiSendToken: true,
		messageOnSuccess: 'MessageActionSuccesfullyPerformed',
		clearFormOnSuccess: false,
		fieldSets: [
			{
				title: null,
				fields: [
					{
						title: 'Report',
						type: 'select',
						name: 'report_id',
						default: '',
						allowedValues: {
							type: 'localDefined',
							data: getKeyValue(this.props.globalregister, 'reportids'),
						},
						validation: {
							type: 'standard',
							required: true,
						},
					},
					{
						title: 'Output',
						type: 'select',
						name: 'output_type',
						default: '',
						allowedValues: {
							type: 'localDefined',
							data: [
								{
									data: 'csv',
									label: 'CSV',
								},
								{
									data: 'pdf',
									label: 'PDF',
								},
							],
						},
						validation: {
							type: 'standard',
							required: true,
						},
					},
				],
			},
		],
	};

	constructor(props) {
		super(props);
		this.state = {};
		this.onReportSuccess = this.onReportSuccess.bind(this);
	}

	onReportSuccess(fileInfo) {
		startDownloadFile(
			this.props.session,
			fileInfo.file_data,
			fileInfo.file_method,
			fileInfo.file_mime,
			fileInfo.file_size,
			fileInfo.file_name
		);
	}

	render() {
		return (
			<div className={styles.profile}>
				<div className={styles.profile_container}>
					<SmartForm {...this.reportsFormInfo} onSuccessForm={this.onReportSuccess} />
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	session: state.session,
	locale: state.locale,
	globalregister: state.globalregister,
});

export default connect(mapStateToProps)(ReportsAdminProceq);
