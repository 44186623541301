// @flow
import React from 'react';
import { compose, withHandlers, withState } from 'recompose';
import { connect } from 'react-redux';
import { Form, Button, message } from 'antd';
import axios from 'axios';
import { FormattedMessage, injectIntl } from 'react-intl';
import type { InjectIntlProvidedProps } from 'react-intl';
import type { FormProps } from 'antd/lib/form';
import { request } from 'actions/ajax';
import URL from 'apis/urls';
import DateRange, { dateRangeRequired } from '../shared/DateRange';
import { getInitialRange } from '../../constants/dateRange';
import SmarterSelect from '../shared/SmarterFormItems/Select';
import styles from './LatencyLogs.styl';

export const LatencyLogs = ({
	exportLogs,
	form,
	intl,
	loading,
	cancel,
}: {
	exportLogs: Function,
	form: FormProps,
	intl: Object,
	loading: boolean,
	cancel: Function,
} & InjectIntlProvidedProps) => (
	<Form onSubmit={exportLogs(form)}>
		<Form.Item label={<FormattedMessage id="App.Product" />}>
			{form.getFieldDecorator('product', {
				initialValue: 'GPR',
			})(<SmarterSelect className={styles.select} globalRegister="latencylogsproducts" />)}
		</Form.Item>
		<Form.Item
			style={{ marginTop: '8px' }}
			label={intl.formatMessage({
				id: 'App.DevTools.LatencyLogs.Label',
			})}
		>
			{form.getFieldDecorator('dateRange', {
				rules: [
					{
						validator: dateRangeRequired,
						message: intl.formatMessage({
							id: 'App.DateRange.DateRangeRequired',
						}),
					},
				],
				initialValue: getInitialRange(),
			})(<DateRange saveDate={false} />)}
		</Form.Item>
		<Button type="primary" htmlType="submit" loading={loading}>
			<FormattedMessage id="App.DevTools.LatencyLogs.Button" />
		</Button>
		{loading && (
			<Button type="default" onClick={cancel} className={styles.cancel_button}>
				<FormattedMessage id="App.AnalyticsRegionExportCancel" />
			</Button>
		)}
	</Form>
);

export default compose(
	injectIntl,
	Form.create({}),
	withState('loading', 'setLoading', false),
	withState('cancelSource', 'setCancelSource', axios.CancelToken.source()),
	withHandlers({
		cancel: ({ cancelSource, intl, setCancelSource, setLoading }) => () => {
			cancelSource.cancel();
			message.error(intl.formatMessage({ id: 'App.AnalyticsOperationCancelled' }));
			setCancelSource(axios.CancelToken.source());
			setLoading(false);
		},
	}),
	connect(null, (dispatch: Function, props) => ({
		exportLogs: form => e => {
			e.preventDefault();
			props.setLoading(true);
			form.validateFields((err, values) => {
				const params = {
					from: values.dateRange.from.format('YYYY-MM-DDTHH:mm:ssZ'),
					to: values.dateRange.to.format('YYYY-MM-DDTHH:mm:ssZ'),
					product: values.product,
				};
				if (err) {
					props.setLoading(false);
					return;
				}

				dispatch(
					request({
						url: URL.EXPORT_LATENCY_LOGS,
						setLoading: props.setLoading,
						headers: {
							'Content-Type': 'text/csv',
						},
						params,
						options: {
							cancelToken: props.cancelSource.token,
						},
					})
				)
					.then(() => {
						props.setLoading(false);
					})
					.catch(() => {
						props.setLoading(false);
						props.cancelSource.cancel();
						props.setCancelSource(axios.CancelToken.source());
					});
			});
		},
	})),
	Form.create({}),
	injectIntl
)(LatencyLogs);
