// @flow
import moment from 'moment';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { AppsTray } from './AppIcon';
import { DATE_WITH_TIMEZONE_FORMAT } from '../../utils/dateHandler';
import type { NotificationInfo } from './notificationTypes';

const notificationColumns = [
	{
		title: <FormattedMessage id="Notification.Field.Title" />,
		dataIndex: 'title',
		// eslint-disable-next-line no-unused-vars
		render: (value: string, record: NotificationInfo): null | React$Element<'div'> => null,
	},
	{
		title: <FormattedMessage id="Notification.Field.Status" />,
		render: (value: NotificationInfo) => {
			const date = new Date(value.deliveryDate);
			return (
				<FormattedMessage
					id={
						date.getTime() < Date.now()
							? 'Notification.Field.Status.Complete'
							: 'Notification.Field.Status.Scheduled'
					}
				/>
			);
		},
	},
	{
		title: <FormattedMessage id="Notification.Field.DeliveryTime" />,
		dataIndex: 'deliveryDate',
		render: (value: string) => {
			if (value) {
				const date = new Date(value);
				return moment(date).format(DATE_WITH_TIMEZONE_FORMAT);
			}
			return '';
		},
	},
	{
		title: <FormattedMessage id="Notification.Field.UserSegments" />,
		dataIndex: 'products',
		render: (value: Array<string>) => {
			return <AppsTray products={value} size={24} />;
		},
	},
	{
		title: <FormattedMessage id="Notification.Field.PushNotification" />,
		dataIndex: 'push',
		render: (value: boolean) => {
			return <FormattedMessage id={value ? 'Proceq.TextYes' : 'Proceq.TextNo'} />;
		},
	},
];

export default notificationColumns;
