// @flow
import React, { Fragment } from 'react';
import { get } from 'lodash';
import { Table } from 'antd';
import { FormattedMessage } from 'react-intl';
import ConvertedUnits from 'components/shared/ConvertedUnits';
import type { DerivedProps } from '../../../Measurement';
import styles from '../../../HTMLExport.styl';

export const title = (
	<Fragment>
		<FormattedMessage id="Proceq.ExportFieldSettings" />
		{' ­­» '}
		<FormattedMessage id="App.HTML.PUNDIT.Settings.Display" />
	</Fragment>
);

export const Display = ({
	data,
	scanType,
	isMetric,
	convert,
	showTitle,
}: { data: MeasurementFullData } & DerivedProps) => {
	const presets = [
		{
			label: 'App.HTML.PUNDIT.Display.ColorPalette',
			value: get(data, 'settings.0.content.display.colorScheme'),
		},
		{
			label: 'App.HTML.PUNDIT.Display.AspectRatio',
			value: get(data, 'settings.0.content.display.isLockingAspectRatio') ? 'On' : 'Off',
		},
		{
			label: 'App.HTML.PUNDIT.Display.GridLines',
			value: get(data, 'settings.0.content.display.isShowingGridLines') ? 'On' : 'Off',
		},
		{
			label: 'App.HTML.PUNDIT.Display.Units',
			value: get(data, 'settings.0.content.display.unit'),
		},
		{
			label: 'App.HTML.PUNDIT.Display.LineViewXZoom',
			value: get(data, 'settings.0.content.lineViewLayoutSettings.zoomLevelXAxis'),
		},
		{
			label: 'App.HTML.PUNDIT.Display.LineViewYZoom',
			value: get(data, 'settings.0.content.lineViewLayoutSettings.zoomLevelYAxis'),
		},
		{
			label: 'App.HTML.PUNDIT.Display.LineViewXOffset',
			unitId: 'PUNDIT.CSV.X axis content offset',
			value: get(data, 'settings.0.content.lineViewLayoutSettings.contentOffsetX'),
		},
		{
			label: 'App.HTML.PUNDIT.Display.LineViewYOffset',
			unitId: 'PUNDIT.CSV.Y axis content offset',
			value: get(data, 'settings.0.content.lineViewLayoutSettings.contentOffsetY'),
		},
	].map(row => ({
		label: row.unitId ? (
			<ConvertedUnits id={row.label} unitId={row.unitId} scanType={scanType} isMetric={isMetric} />
		) : (
			<FormattedMessage id={row.label} />
		),
		value: row.unitId ? convert(row.value, row.unitId) : row.value,
		key: row.label,
	}));

	return (
		<Table
			title={showTitle ? () => <span className={styles.sub_header}>{title}</span> : null}
			className={styles.table}
			dataSource={presets}
			pagination={false}
			showHeader={false}
			size="small"
		>
			<Table.Column dataIndex="label" className={styles.column} />
			<Table.Column dataIndex="value" />
		</Table>
	);
};

export default Display;
