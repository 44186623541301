import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { Button, Upload, Popover } from 'antd';

import URL from 'apis/urls';
import { /* updatePassword, */ setPortrait } from 'actions/profile';
import handleFormatMessage from 'utils/handleFormatMessage';
import { getProceqUrl, isUsingSharedToken } from 'utils/sessionHelper';
import { passwordPolicyTextArray } from 'utils/passwordValidator';
import styles from './UserProfile.styl';
import SmartForm from '../../shared/SmartForm';
import AvatarIcon from '../../svgIcons/AvatarThumbnail';
import AvatarInitials from '../../shared/AvatarInitials';

export class UserProfile extends Component {
	static propTypes = {
		userProfile: PropTypes.object,
		session: PropTypes.object,
		intl: PropTypes.shape({
			formatMessage: PropTypes.func.isRequired,
		}),
		setPortrait: PropTypes.func,
		sessionData: PropTypes.object,
		updatePassword: PropTypes.func,
		passwordUpdateStatus: PropTypes.bool,
	};

	constructor(props) {
		super(props);
		this.state = {
			hideAll: isUsingSharedToken(this.props.session),
		};
	}

	render() {
		const { userProfile, intl } = this.props;
		const { formatMessage } = intl;

		if (this.state.hideAll) {
			return <div />;
		}

		let overrideFieldsApiEndpoint = '';
		let updateApiEndpoint = '';
		let changePasswordApiEndpoint = '';
		let editFormInfo = {};
		let passwordFormInfo = {};

		if (this.props.session.sessionType === 'TDS') {
			overrideFieldsApiEndpoint = URL.GET_ACCOUNT.replace('{key}', this.props.session.userID);
			updateApiEndpoint = URL.UPDATE_ACCOUNT.replace('{key}', this.props.session.userID);
			changePasswordApiEndpoint = URL.UPDATE_PASSWORD_ACCOUNT.replace('{key}', this.props.session.userID);
			editFormInfo = {
				titleText: 'Proceq.TitleUpdateProfile',
				titleIcon: 'user',
				messageOnSuccess: 'Proceq.MessageActionSuccesfullyPerformed',
				apiEndpoint: updateApiEndpoint,
				overrideFields: {
					apiEndpoint: overrideFieldsApiEndpoint,
					apiTypeMethod: 'get',
				},
				apiTypeMethod: 'put',
				apiSendToken: true,
				fieldSets: [
					{
						title: null,
						fields: [
							{
								title: 'FormFirstName',
								name: 'firstName',
								type: 'input',
								styleClasses: 'force_half_and_inline',
								validation: {
									type: 'firstname',
									required: true,
								},
							},
							{
								title: 'FormLastName',
								name: 'lastName',
								type: 'input',
								styleClasses: 'force_half_and_inline',
								validation: {
									type: 'lastname',
									required: true,
								},
							},
							{
								title: 'FormEmailGroupID',
								type: 'input',
								name: 'username',
								styleClasses: 'force_half_and_inline',
								validation: {},
								onlyread: true,
							},
							{
								title: 'FormCountry',
								type: 'select',
								name: 'countryCode',
								styleClasses: 'force_half_and_inline',
								allowedValues: {
									type: 'globalRegister',
									data: 'countries',
								},
								validation: {},
								onlyread: true,
							},
							{
								title: 'FormIndustry',
								type: 'select',
								name: 'industry',
								styleClasses: 'force_half_and_inline',
								allowedValues: {
									type: 'globalRegister',
									data: 'industrytype',
								},
								validation: {
									required: false,
								},
							},
							{
								title: 'FormCompanyName',
								type: 'input',
								name: 'companyName',
								styleClasses: 'force_half_and_inline',
								validation: {
									type: 'standard',
									required: true,
								},
							},
							{
								title: 'FormCompanySize',
								type: 'select',
								name: 'companySize',
								styleClasses: 'force_half_and_inline',
								allowedValues: {
									type: 'globalRegister',
									data: 'companysize',
								},
								validation: {
									type: 'standard',
									required: false,
								},
							},
							{
								title: 'FormCompanyWebsite',
								type: 'input',
								name: 'companyWebSite',
								styleClasses: 'force_half_and_inline',
								validation: {
									type: 'standard',
									required: false,
								},
							},
							{
								title: 'FormJobTitle',
								type: 'input',
								name: 'jobTitle',
								styleClasses: 'force_half_and_inline',
								validation: {
									type: 'standard',
									required: false,
								},
							},
							{
								title: 'FormNewsletter',
								type: 'select',
								name: 'newsletter',
								styleClasses: 'force_half_and_inline',
								default: 0,
								allowedValues: {
									type: 'globalRegister',
									data: 'yesorno',
								},
								validation: {
									type: 'standard',
									returnAsInt: true,
								},
							},
						],
					},
				],
			};

			passwordFormInfo = {
				titleText: 'Proceq.TitleChangePassword',
				titleIcon: 'ellipsis',
				messageOnSuccess: 'Proceq.MessageActionSuccesfullyPerformed',
				apiEndpoint: changePasswordApiEndpoint,
				apiTypeMethod: 'patch',
				apiSendToken: true,
				fieldSets: [
					{
						title: null,
						fields: [
							{
								title: 'FormPasswordOld',
								name: 'oldPassword',
								type: 'password',
								validation: {
									type: 'input',
									required: true,
								},
							},
							{
								title: 'FormPasswordNew',
								name: 'newPassword',
								type: 'password',
								styleClasses: 'force_half_and_inline',
								hasToolTip: true,
								toolTipText: passwordPolicyTextArray,
								validation: {
									type: 'password',
									required: true,
								},
							},
							{
								title: 'FormConfirmPassword',
								name: 'confirmPassword',
								type: 'password',
								styleClasses: 'force_half_and_inline',
								validation: {
									type: 'confirmpassword',
									required: true,
								},
							},
						],
					},
				],
			};
		} else {
			overrideFieldsApiEndpoint = getProceqUrl(this.props.session, URL.PROCEQ_GET_ACCOUNT);
			updateApiEndpoint = getProceqUrl(this.props.session, URL.PROCEQ_UPDATE_ACCOUNT);
			changePasswordApiEndpoint = getProceqUrl(this.props.session, URL.PROCEQ_UPDATE_PASSWORD_ACCOUNT);
			editFormInfo = {
				titleText: 'Proceq.TitleUpdateProfile',
				titleIcon: 'user',
				messageOnSuccess: 'Proceq.MessageActionSuccesfullyPerformed',
				apiEndpoint: updateApiEndpoint,
				overrideFields: {
					apiEndpoint: overrideFieldsApiEndpoint,
					apiTypeMethod: 'post',
				},
				apiTypeMethod: 'post',
				apiSendToken: true,
				fieldSets: [
					{
						title: null,
						fields: [
							{
								title: 'FormFirstName',
								name: 'first_name',
								type: 'input',
								styleClasses: 'force_half_and_inline',
								validation: {
									type: 'firstname',
									required: true,
								},
							},
							{
								title: 'FormLastName',
								name: 'last_name',
								type: 'input',
								styleClasses: 'force_half_and_inline',
								validation: {
									type: 'lastname',
									required: true,
								},
							},
							{
								title: 'FormIndustry',
								type: 'select',
								name: 'industry_code',
								styleClasses: 'force_full_field',
								allowedValues: {
									type: 'globalRegister',
									data: 'industrytype',
								},
								validation: {
									type: 'standard',
									required: true,
								},
							},
							{
								title: 'FormCompanyName',
								type: 'input',
								name: 'company_name',
								styleClasses: 'force_half_and_inline',
								validation: {
									type: 'standard',
									required: true,
								},
							},
							{
								title: 'FormCompanySize',
								type: 'select',
								name: 'company_size',
								styleClasses: 'force_half_and_inline',
								allowedValues: {
									type: 'globalRegister',
									data: 'companysize',
								},
								validation: {
									type: 'standard',
									required: true,
								},
							},
							{
								title: 'FormCompanyWebsite',
								type: 'input',
								name: 'company_web',
								styleClasses: 'force_full_field',
								validation: {
									type: 'standard',
									required: true,
								},
							},
							{
								title: 'FormJobTitle',
								type: 'input',
								name: 'job_title',
								styleClasses: 'force_half_and_inline',
								validation: {
									type: 'standard',
									required: true,
								},
							},
							{
								title: 'FormNewsletter',
								type: 'select',
								name: 'allow_newsletter',
								styleClasses: 'force_half_and_inline',
								default: 0,
								allowedValues: {
									type: 'globalRegister',
									data: 'yesorno',
								},
								validation: {
									type: 'standard',
									returnAsInt: true,
								},
							},
						],
					},
				],
			};

			passwordFormInfo = {
				titleText: 'Proceq.TitleChangePassword',
				titleIcon: 'ellipsis',
				messageOnSuccess: 'Proceq.MessageActionSuccesfullyPerformed',
				apiEndpoint: changePasswordApiEndpoint,
				apiTypeMethod: 'post',
				apiSendToken: true,
				fieldSets: [
					{
						title: null,
						fields: [
							{
								title: 'FormPasswordOld',
								name: 'password_old',
								type: 'passwordnoencode',
								validation: {
									type: 'input',
									required: true,
								},
							},
							{
								title: 'FormPasswordNew',
								name: 'password_new',
								type: 'passwordnoencode',
								styleClasses: 'force_half_and_inline',
								validation: {
									type: 'password',
									required: true,
								},
							},
							{
								title: 'FormConfirmPassword',
								name: 'confirmPassword',
								type: 'passwordnoencode',
								styleClasses: 'force_half_and_inline',
								validation: {
									type: 'confirmpassword',
									required: true,
								},
							},
						],
					},
				],
			};
		}

		return (
			<div className={styles.profile} style={{ position: 'relative', maxWidth: '900px' }}>
				<div className={styles.profile_container}>
					{this.props.session.sessionType === 'TDS' ? (
						<div id="avatar" className={styles.image_wrapper}>
							<Popover
								getPopupContainer={() => document.getElementById('avatar')}
								placement="right"
								content={
									false && (
										<Button type="danger" onClick={() => this.handleSetPortrait('')}>
											{handleFormatMessage('Settings.ProfileForm.AvatarRemove', formatMessage)}
										</Button>
									)
								}
							>
								<a>
									<Upload
										customRequest={this.handleCustomUploadRequest.bind(this)}
										accept="image/*"
										showUploadList={false}
									>
										<AvatarInitials profile={userProfile} size="100px" />
										<div className={styles.overlay}>
											<div className={styles.avatar}>
												<AvatarIcon />
											</div>
										</div>
									</Upload>
								</a>
							</Popover>
						</div>
					) : (
						''
					)}

					<SmartForm {...editFormInfo} />
					<SmartForm {...passwordFormInfo} />
				</div>
			</div>
		);
	}

	/* handlePasswordUpdate(oldPass, userPass) {
		if (typeof this.props.updatePassword === 'function') {
			this.props.updatePassword(oldPass, userPass, this.props.sessionData);
			this.setState({
				passwordUpdated: true
			});
		}
	} */
	handleCustomUploadRequest(request) {
		this.props.setPortrait(request.file);
	}

	handleSetPortrait(imgBlob) {
		this.props.setPortrait(imgBlob);
	}
}

const mapStateToProps = state => ({
	session: state.session,
});

const mapDispatchToProps = dispatch => ({
	setPortrait: imgBlob => {
		dispatch(setPortrait(imgBlob));
	},
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(UserProfile));
