// @flow
import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withState } from 'recompose';
import { Form, Button } from 'antd';
import Select from 'components/shared/SmarterFormItems/Select';

import { FormattedMessage, injectIntl } from 'react-intl';
import type { InjectIntlProvidedProps } from 'react-intl';
import type { FormProps } from 'antd/lib/form';
import { request } from 'actions/ajax';
import URL from 'apis/urls';
import { get } from 'lodash';

export const Managei18n = ({
	generateDemoBundle,
	form,
	intl,
	loading,
}: {
	generateDemoBundle: Function,
	form: FormProps,
	loading: boolean,
} & InjectIntlProvidedProps) => {
	return (
		<div>
			<Form onSubmit={generateDemoBundle(form)}>
				<Form.Item
					style={{ marginTop: '8px' }}
					label={intl.formatMessage({
						id: 'App.DevTools.Managei18n',
					})}
				>
					{form.getFieldDecorator('languages', { rules: [] })(
						<Select
							mode="multiple"
							style={{ width: '100%' }}
							placeholder="Please select the languages"
							globalRegister="availlanguages"
						/>
					)}
				</Form.Item>

				<Button type="primary" htmlType="submit" loading={loading}>
					<FormattedMessage id="App.DevTools.LatencyLogs.Button" />
				</Button>
			</Form>
		</div>
	);
};

export default compose(
	Form.create({
		mapPropsToFields: props => {
			const fields = {};
			['languages'].forEach(k => {
				const value = get(props, ['tier', k]);
				fields[k] = Form.createFormField({ value });
			});
			return fields;
		},
	}),
	withState('languages', 'setLanguages'),
	withState('loading', 'setLoading', false),
	connect(null, (dispatch: Function, props) => ({
		generateDemoBundle: form => e => {
			e.preventDefault();
			form.validateFields((err, values) => {
				if (err) {
					return;
				}

				dispatch(
					request({
						method: 'GET',
						url: URL.EXPORT_TRANSLATIONS,
						params: {
							languages: values.languages.join(),
						},
						setLoading: props.setLoading,
						returnResponseHeaders: true,
						options: {
							responseType: 'arraybuffer',
						},
					})
				).then(response =>
					window.fileDownload(
						response.data,
						(response.headers['content-disposition'] || '').split('"')[1],
						response.headers['content-type']
					)
				);
			});
		},
	})),
	injectIntl
)(Managei18n);
