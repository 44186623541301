// @flow
// $FlowFixMe
import React, { Fragment, memo } from 'react';
import { compose } from 'recompose';
import classNames from 'classnames';
import { Spin, Alert } from 'antd';
import { FormattedMessage } from 'react-intl';
import GoogleMaps from './GoogleMaps';
import styles from './RegionalAnalytics.styl';

type Props = {
	loading: boolean,
	data: RegionalAnalytics[],
	excludedCount: number,
	users?: User[],
};

export const MapView = ({ loading, users, data, excludedCount }: Props) => (
	<Fragment>
		{!loading && (
			<Fragment>
				{data.length <= 0 && (
					<Alert
						className={styles.alert}
						message={<FormattedMessage id="App.AnalyticsLocationNoMeasurements" />}
						type="error"
					/>
				)}
				{data.length > 0 && (
					<Alert
						className={styles.alert}
						message={
							<Fragment>
								<FormattedMessage
									id="App.AnalyticsLocationFoundMeasurements"
									values={{ count: data.length }}
								/>
							</Fragment>
						}
						type="success"
					/>
				)}
				{excludedCount > 0 && (
					<Alert
						className={styles.alert}
						message={
							<FormattedMessage
								id="App.AnalyticsLocationExcludedMeasurements"
								values={{ count: excludedCount }}
							/>
						}
						type="error"
					/>
				)}
			</Fragment>
		)}
		&nbsp;
		<Spin spinning={loading} wrapperClassName={classNames(styles.loading_container, styles.region)}>
			<GoogleMaps data={data} users={users} />
		</Spin>
	</Fragment>
);

export default compose(memo)(MapView);
