import actionTypes from './actionTypes';

export function changeLanguage(value) {
	return {
		type: actionTypes.CHANGE_LANGUAGE,
		payload: value,
	};
}
export function initLanguage() {
	return {
		type: actionTypes.INIT_LANGUAGE,
	};
}
