// @flow
import React from 'react';
import type { ContextRouter } from 'react-router';
import { Redirect } from 'react-router';
import { getLandingPath } from 'utils/authUtils';
import useIsAuthenticated from '../hooks/useIsAuthenticated';
import routeURLs from './urls';

export const IndexRedirectRoute = ({ location }: ContextRouter) => {
	const isAuthenticated = useIsAuthenticated(location);
	const redirectTo = getLandingPath();
	return isAuthenticated ? <Redirect to={redirectTo} /> : <Redirect to={routeURLs.AUTH} />;
};

export default IndexRedirectRoute;
