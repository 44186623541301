module.exports = {
	shortlang: 'de',
	lang: 'de',
	messages: {
		'Proceq.ErrorWrongUsername': 'Ungültige E-Mail angegeben',
		'Proceq.ErrorCreationNotAllowed': 'Erzeugung von neuen Konten wurde deaktiviert',
		'Proceq.ErrorWrongPassword': 'Ungültiges Passwort angegeben',
		'Proceq.ErrorWrongFirstName': 'Ungültiger Vorname angegeben',
		'Proceq.ErrorWrongLastName': 'Ungültiger Nachname angegeben',
		'Proceq.ErrorUsernameAlreadyExists': 'Es besteht bereits ein Konto mit der gleichen E-Mail-Adresse',
		'Proceq.ErrorDB': 'Fehler beim Ausführen eines Datenbankvorgangs',
		'Proceq.InvalidUsername': 'Benutzerkonto konnte nicht gefunden werden',
		'Proceq.AlreadyMultipleForgot': 'Es wurden bereits mehrere Reset-Passwörter angefordert',
		'Proceq.InvalidNewPassword': 'Ungültiges neues Passwort angegeben',
		'Proceq.InvalidResetCode': 'Passwort-Reset-Code ist ungültig oder bereits abgelaufen',
		'Proceq.InvalidOldPassword': 'Ungültiges altes Passwort angegeben',
		'Proceq.InvalidAccessRights': 'Sitzung abgelaufen oder Benutzer ist nicht zu dieser Aktion berechtigt',
		'Proceq.ErrorNoAccountType': 'Kein Kontotyp angegeben',
		'Proceq.ErrorNoUsername': 'Keine E-Mail angegeben',
		'Proceq.InvalidCountry': 'Ungültiges Land angegeben',
		'Proceq.ErrorCreatingUser': 'Fehler beim Erzeugen eines neuen Benutzers',
		'Proceq.InvalidID': 'Ungültige ID angegeben',
		'Proceq.InvalidTransactionID': 'Ungültige Transaktions-ID',
		'Proceq.InvalidFileContent': 'Fehler beim Lesen des Dateiinhaltes',
		'Proceq.InvalidTypeEntry': 'Ungültiger Eintragstyp',
		'Proceq.InvalidContractID': 'Vertrag ist ungültig oder abgelaufen',
		'Proceq.InvalidExistingID': 'ID kann nicht erzeugt werden, da sie bereits existiert',
		'Proceq.InvalidDataSeriesType': 'Ungültiger Serientyp',
		'Proceq.InvalidMetaDataContent': 'Ungültiger Metadateninhalt',
		'Proceq.ErrorFileIsLocked': 'Eine oder mehrere Dateien sind gesperrt. Versuchen Sie es später erneut.',
		'Proceq.MissingAttachmentFiles': 'Anlagedateien konnten nicht auf dem Server gefunden werden',
		'Proceq.InvalidFlagType': 'Ungültiger oder fehlender Flag-Typ',
		'Proceq.MissingDateTypeFields': 'Pflichtfelder für Serie leer',
		'Proceq.InvalidFolderID': 'Ungültige Ordner-ID',
		'Proceq.InvalidDataSeriesName': 'Ungültiger Serienname',
		'Proceq.ErrorDateFormat': 'Ungültiges Datumsformat angegeben',
		'Proceq.ErrorDataFormat': 'Daten der Serie konnten nicht geparst werden',
		'Proceq.InvalidDownloadID': 'Download-Token ungültig oder bereits abgelaufen',
		'Proceq.InvalidVersionNr': 'Ungültige Versionsnummer angegeben',
		'Proceq.ErrorDataMissing': 'Daten konnten nicht aus dem Speichersystem wiederhergestellt werden',
		'Proceq.ErrorSavingFile': 'Server konnte Datei nicht speichern',
		'Proceq.ErrorInsertDB': 'Fehler beim Einfügen in DB',
		'Proceq.InvalidFolderName': 'Ungültiger Ordnername angegeben',
		'Proceq.InvalidActionID': 'Ungültiger oder abgelaufener URL-Link',
		'Proceq.AlreadyConfirmed': 'Konto wurde bereits verifiziert',
		'Proceq.InvalidActionType': 'Die angeforderte Aktion kann nicht verarbeitet werden',
		'Proceq.ErrorGeneratingChart': 'Fehler beim Erzeugen der Grafiken',
		'Proceq.InvalidSerialID': 'Ungültige Serien-ID',
		'Proceq.InvalidHardwareID': 'Ungültige Hardware-ID',
		'Proceq.InvalidEntityID': 'Ungültige Objekt-ID oder Objekt gelöscht',
		'Proceq.InvalidEntityType': 'Ungültiger Objekttyp',
		'Proceq.InvalidEntityVerNr': 'Ungültige Objektversionsnummer',
		'Proceq.ErrorTransactionLocked': 'Das Objekt ist gesperrt und kann nicht bearbeitet werden',
		'Proceq.InvalidDateValue': 'Datum konnte nicht geparst werden',
		'Proceq.MissingFields': 'Ein oder mehrere Pflichtfelder leer',
		'Proceq.ErrorColumnValue': 'Falscher Wert in einem oder mehreren Feldern',
		'Proceq.MissingUidDBValue': 'Fehlende Eintrags-ID',
		'Proceq.ErrorFileID': 'Ungültige Datei-ID angegeben',
		'Proceq.ErrorInsertDownload': 'Fehler beim Erzeugen des Downloads',
		'Proceq.InvalidAppVersion':
			'Ihre derzeitige App-Version wird nicht länger unterstützt. Bitte aktualisieren Sie sie.',
		'Proceq.MissingUserInfo': 'Fehlende Benutzerinfo',
		'Proceq.InvalidAccessToken': 'Sitzungs-Token ungültig oder abgelaufen',
		'Proceq.ErrorGeneratingCSV': 'Fehler beim Erzeugen des CSV-Exports',
		'Proceq.ErrorGeneratingExport': 'Fehler beim Erzeugen des Exports',
		'Proceq.MissingParams': 'Ein oder mehrere erforderliche Parameter fehlen',
		'Proceq.SerialNotFound': 'Kein Vertrag für dieses Gerät gefunden',
		'Proceq.StandardEnumJapan': 'Japan',
		'Proceq.StandardEnumDefault': 'Standard',
		'Proceq.MaterialEnumIndependent': 'Materialunabhängig',
		'Proceq.MaterialEnumSteelAndCastSteel': 'Stahl und Stahlguss',
		'Proceq.MaterialEnumWorkToolSteel': 'Werkzeugstahl',
		'Proceq.MaterialEnumStainlessSteel': 'Rostfreier Stahl',
		'Proceq.MaterialEnumGrayCastIron': 'Grauguss (GG)',
		'Proceq.MaterialEnumNodularCastIron': 'Gusseisen mit Kugelgraphit (GGG)',
		'Proceq.MaterialEnumCastAlluminiumAlloys': 'Aluminiumgusslegierungen',
		'Proceq.MaterialEnumBrassCopperZincAlloys': 'Messing - Kupfer-Zink-Legierungen',
		'Proceq.MaterialEnumBronze': 'Bronze',
		'Proceq.MaterialEnumWroughtCopperAlloys': 'Kupferknetlegierungen',
		'Proceq.MaterialEnumTitanium': 'Titan',
		'Proceq.ScaleEnumCustom': 'Kundenspezifisch',
		'Proceq.ScaleEnumPenetrationDepth': 'Eindringtiefe',
		'Proceq.CompanySizeMicro': '1-9 Mitarbeiter',
		'Proceq.CompanySizeSmall': '10-49 Mitarbeiter',
		'Proceq.CompanySizeMedium': '50-249 Mitarbeiter',
		'Proceq.CompanySizeLarge': '>249 Mitarbeiter',
		'Proceq.SalutationMr': 'Hr.',
		'Proceq.SalutationMs': 'Frau',
		'Proceq.SalutationDr': 'Dr.',
		'Proceq.SalutationProf': 'Prof.',
		'Proceq.CategoryAerospace': 'Luftfahrt',
		'Proceq.CategoryAutomotive': 'Automobilbau',
		'Proceq.CategoryChemicalProcessing': 'Chemische Verarbeitung',
		'Proceq.CategoryConstructionBuilding': 'Hoch-/Tiefbau',
		'Proceq.CategoryConsultantTestLabsEngineer': 'Berater/Prüflabor/Ingenieur',
		'Proceq.CategoryEducationInstituteRD': 'Ausbildung/Institutionen/F&E',
		'Proceq.CategoryFilmProducerConverter': 'Folienherstellung/-verarbeitung',
		'Proceq.CategoryGeologyRocktesters': 'Geologie/Gesteinsprüfung',
		'Proceq.CategoryMachineryToolingEquipment': 'Maschinen/Werkzeuge/Geräte',
		'Proceq.CategoryMassTransportation': 'Massentransport',
		'Proceq.CategoryMetalWorks': 'Metallindustrie',
		'Proceq.CategoryOilAndGas': 'Öl & Gas',
		'Proceq.CategoryOthers': 'Andere',
		'Proceq.CategoryPaperProducerConverter': 'Papierherstellung/-verarbeitung',
		'Proceq.CategorySalesChannel': 'Vertriebskanal',
		'Proceq.ErrorWrongLoginCredentials': 'Der eingegebene Benutzername oder das Passwort ist falsch',
		'Proceq.ErrorEmailNotVerified': 'E-Mail wurde noch nicht verifiziert',
		'Proceq.ServerError': 'Server-Fehler',
		'Proceq.TooManyActionEmailRequests':
			'Zu viele Anfragen. Bitte warten Sie, bis die E-Mails in Ihrer Mailbox eingegangen sind.',
		'Proceq.ErrorServerUnderMaintenance': 'Server wird gewartet',
		'Proceq.TableDataSeriesName': 'Name',
		'Proceq.TableCreatedOn': 'Erstellt am',
		'Proceq.TableModifiedOn': 'Bearbeitet am',
		'Proceq.TableCreatedBy': 'Erstellt von',
		'Proceq.TableNumMean': 'Mittelwert',
		'Proceq.TableNumTotal': 'Gesamt',
		'Proceq.TableNumStandardDeviation': 'Std.abw.',
		'Proceq.TableImpactDevice': 'Schlaggerät',
		'Proceq.TableImpactDeviceHammer': 'Hammer',
		'Proceq.TableMaterial': 'Material',
		'Proceq.TableScaleUnit': 'Skala',
		'Proceq.TableActionRefresh': 'Aktualisieren',
		'Proceq.TableActionExpand': 'Ausklappen',
		'Proceq.TableActionCollapse': 'Einklappen',
		'Proceq.TableActionExportAsPDF': 'Als PDF exportieren',
		'Proceq.TableActionExportAsCSV': 'Als CSV exportieren',
		'Proceq.TableActionExportAsJSON': 'Als JSON exportieren',
		'Proceq.SearchOperationEquals': 'Ist gleich',
		'Proceq.SearchOperationContains': 'Enthält',
		'Proceq.SearchOperationDifferent': 'Unterschiedlich',
		'Proceq.SearchOperationGreaterOrEqual': 'Grösser oder gleich',
		'Proceq.SearchOperationGreater': 'Grösser als',
		'Proceq.SearchOperationLesserOrEqual': 'Kleiner oder gleich',
		'Proceq.SearchOperationLesser': 'Kleiner als',
		'Proceq.ExportFieldExportDate': 'Exportdatum',
		'Proceq.ExportFieldLimits': 'Grenzwerte',
		'Proceq.ExportFieldHigh': 'Hoch',
		'Proceq.ExportFieldLow': 'Niedrig',
		'Proceq.ExportFieldOff': 'Aus',
		'Proceq.ExportFieldLowerLimit': 'Unterer Grenzwert',
		'Proceq.ExportFieldUpperLimit': 'Oberer Grenzwert',
		'Proceq.ExportFieldReadings': 'Messwerte',
		'Proceq.ExportFieldStandardDeviation': 'Standardabweichung',
		'Proceq.ExportFieldRange': 'Bereich',
		'Proceq.ExportFieldRelativeSpan': 'Relativer Messbereich',
		'Proceq.ExportFieldMaximum': 'Maximum',
		'Proceq.ExportFieldMinimum': 'Minimum',
		'Proceq.ExportFieldAverage': 'Durchschnitt',
		'Proceq.ExportFieldUnit': 'Einheit',
		'Proceq.ExportFieldName': 'Name',
		'Proceq.ExportFieldMeasurementDate': 'Messdatum',
		'Proceq.ExportFieldLocationLatitude': 'Standort (Breitengrad)',
		'Proceq.ExportFieldLocationLongitude': 'Standort (Längengrad)',
		'Proceq.ExportFieldProbeType': 'Prüfkopftyp',
		'Proceq.ExportFieldProbeSerialNumber': 'Seriennummer des Prüfkopfs',
		'Proceq.ExportFieldProbeBTSerialNumber': 'Bluetooth-Seriennummer des Prüfkopfs',
		'Proceq.ExportFieldProbeLastVerificationDate': 'Datum der letzten Prüfkopfverifizierung',
		'Proceq.ExportFieldPrimaryScale': 'Primäre Skala',
		'Proceq.ExportFieldSecondaryScale': 'Sekundäre Skala',
		'Proceq.ExportFieldMaterial': 'Material',
		'Proceq.ExportFieldStandard': 'Standard',
		'Proceq.ExportFieldNoConversion': 'Keine Umwertung',
		'Proceq.ExportFieldMeasurements': 'Messungen',
		'Proceq.ExportFieldMore': 'Mehr',
		'Proceq.ExportFieldLess': 'Weniger',
		'Proceq.ExportFieldStatistics': 'Statistik',
		'Proceq.ExportFieldSettings': 'Einstellungen',
		'Proceq.DataSeriesLogbook': 'Logbuch',
		'Proceq.DataSeriesAllMeasurements': 'Alle Messungen',
		'Proceq.DataSeriesFlagged': 'Markiert',
		'Proceq.DataSeriesTrash': 'Papierkorb',
		'Proceq.BrowserNoSupportAudio': 'Ihr Browser unterstützt keine Audio-Dateien.',
		'Proceq.EmailPasswordRecoverySubject': 'Passwortwiederherstellung',
		'Proceq.EmailPasswordRecoveryBody':
			'Sehr geehrte/r **?0** **?1**,<br/><br/>Sie haben ein neues Passwort angefordert. Bitte klicken Sie auf den Link unten, um Ihr Passwort zurückzusetzen.<br/><br/><a href="**?2**">Passwort zurücksetzen</a>.<br/><br/>Bitte ignorieren Sie diese Nachricht, falls Sie keine Passwortänderung angefordert haben. Diese Email verliert in 12 Stunden seine Gültigkeit.<br/><br/>Ihr Proceq Team<br/><br/>**?3**',
		'Proceq.EmailRegistrationSubject': 'Registrierung abschliessen',
		'Proceq.EmailRegistrationBody':
			'Sehr geehrte/r **?0** **?1**,<br/><br/>vielen Dank für Ihre Registrierung auf live.proceq.com. Bitte schliessen Sie Ihre Registrierung ab, indem Sie auf den Link unten klicken:<br/><br/><a href="**?2**">**?2**</a><br/><br/>Diese E-Mail wurde automatisch erzeugt, da Sie das Registrierungsformular ausgefüllt haben. Bitte ignorieren Sie diese Nachricht, falls Sie keine Registrierung vorgenommen haben sollten.<br/><br/> Durch Abschluss der Registrierung stimmen Sie <a href="**?3**">unseren Allgemeinen Geschäftsbedingungen </a> zu.<br/><br/>Ihr Proceq Team<br/><br/>**?4**',
		'Proceq.LogbookActionNewMeasurement': 'Neue Messung',
		'Proceq.LogbookPropertyCreatedSeriesInFolder': 'Erstellte Serie "**?0**" in Ordner "**?1**"',
		'Proceq.LogbookPropertyRenamedSeriesFromTo': 'Serie wurde von "**?0**" in "**?1**" umbenannt',
		'Proceq.LogbookPropertyMovedSeriesFromToTrash':
			'Serie wurde aus dem Ordner "**?0**" in den Papierkorb verschoben',
		'Proceq.LogbookPropertyMovedSeriesFromTrashTo': 'Serie wurde vom Papierkorb in den Ordner "**?0**" verschoben',
		'Proceq.LogbookPropertyMovedSeriesFromTo':
			'Serie wurde aus dem Ordner "**?0**" in den Ordner "**?1**" verschoben',
		'Proceq.LogbookPropertySecondaryScaleChangedFromTo': 'Sekundäre Skala wurde von **?0** in **?1** abgeändert',
		'Proceq.LogbookPropertyMaterialChangedFromTo': 'Material wurde von **?0** in **?1** abgeändert',
		'Proceq.LogbookPropertyMaxLimitChangedFromTo': 'Oberer Grenzwert wurde von **?0** in **?1** abgeändert',
		'Proceq.LogbookPropertyMinLimitChangedFromTo': 'Unterer Grenzwert wurde von **?0** in **?1** abgeändert',
		'Proceq.LogbookPropertyStandardChangedFromTo': 'Standard wurde von **?0** in **?1** abgeändert',
		'Proceq.LogbookPropertyTextCommentAdded': 'Textkommentar hinzugefügt',
		'Proceq.LogbookPropertyTextCommentDeletedOn': 'Gelöscht am **?0**',
		'Proceq.LogbookPropertyMeasurementAdded': '**?0** hinzugefügt',
		'Proceq.LogbookPropertyMeasurementExcluded': '**?0** ausgeschlossen',
		'Proceq.LogbookPropertyMeasurementIncluded': '**?0** eingeschlossen',
		'Proceq.LogbookPropertyMeasurementDeleted': '**?0** gelöscht',
		'Proceq.LogbookPropertyAttachmentDeletedOn': 'Gelöscht am **?0**',
		'Proceq.LogbookPropertyPhotoAdded': 'Foto hinzugefügt',
		'Proceq.LogbookPropertyAudioAdded': 'Audiokommentar hinzugefügt',
		'Proceq.LogbookPropertySeriesFlagged': 'Serie markiert',
		'Proceq.LogbookPropertySeriesUnflagged': 'Serie unmarkiert',
		'Proceq.LogbookPropertySerialNumberShort': 'Seriennr.',
		'Proceq.LogbookPropertyBluetoothModuleShort': 'BTM',
		'Proceq.LogbookPropertyVerified': 'Verifiziert',
		'Proceq.LogbookPropertyContractValidUntil': 'Vertrag gültig bis',
		'Proceq.LogbookPropertyOperator': 'Bediener',
		'Proceq.LogbookTitle': 'Logbuch',
		'Proceq.LogbookDownload': 'Download',
		'Proceq.LogbookLoadingData': 'Daten werden geladen...',
		'Proceq.LogbookClose': 'Schliessen',
		'Proceq.FormCompany': 'Firma',
		'Proceq.FormScreeningEagleID': 'Screening Eagle ID (Email)',
		'Proceq.FormEmailGroupID': 'E-Mail',
		'Proceq.FormCustomerEmail': 'E-Mail des Kunden',
		'Proceq.FormProbeSN': 'Seriennr. Prüfkopf',
		'Proceq.FormBluetoothSN': 'Seriennr.',
		'Proceq.FormBluetoothHWID': 'HW-ID',
		'Proceq.FormExpirationDate': 'Ablaufdatum',
		'Proceq.TitleRegisterProbeSNs': 'Mietvertrag registrieren',
		'Proceq.ButtonSubmit': 'Absenden',
		'Proceq.ButtonReset': 'Zurücksetzen',
		'Proceq.ButtonResetPassword': 'Passwort zurücksetzen',
		'Proceq.MessageSuccesfullyInserted': 'Erfolgreich eingefügt',
		'Proceq.MessageSuccesfullyEmailConfirmed': 'Ihre E-Mail wurde bestätigt.',
		'Proceq.MessagePasswordSuccesfullyChanged': 'Passwort erfolgreich geändert',
		'Proceq.MessageSuccesfullyUpdated': 'Erfolgreich aktualisiert',
		'Proceq.MessageAccountCreated':
			'Zur Verifizierung Ihrer Registrierung wurde Ihnen eine E-Mail gesendet. Bitte klicken Sie auf den Link in der E-Mail, um den Registrierungsvorgang abzuschliessen.',
		'Proceq.MessageEmailForgotPassword': 'Es wurde Ihnen eine E-Mail mit weiteren Anweisungen gesendet.',
		'Proceq.MessageEmailResendConfirm': 'Es wurde Ihnen eine E-Mail mit weiteren Anweisungen gesendet.',
		'Proceq.MessageLoggingIn': 'Anmelden...',
		'Proceq.LoginNeedAccount': 'Benötigen Sie ein Konto?',
		'Proceq.LoginCreateAccount': 'Konto erstellen',
		'Proceq.TitleForgotPassword': 'Passwort vergessen',
		'Proceq.TitleResendConfirmation': 'Bestätigungs-E-Mail erneut senden',
		'Proceq.FormGroup': 'Gruppe',
		'Proceq.FormFirstName': 'Vorname',
		'Proceq.FormLastName': 'Nachname',
		'Proceq.FormEmail': 'E-Mail-Adresse',
		'Proceq.FormCity': 'Stadt',
		'Proceq.FormPassword': 'Passwort',
		'Proceq.FormPasswordNew': 'Neues Passwort',
		'Proceq.FormPasswordOld': 'Altes Passwort',
		'Proceq.FormConfirmPassword': 'Passwort bestätigen',
		'Proceq.FormConfirmPasswordNew': 'Neues Passwort bestätigen',
		'Proceq.ButtonRegister': 'Registrieren',
		'Proceq.ButtonSignIn': 'Anmelden',
		'Proceq.TitleSignIn': 'Anmelden',
		'Proceq.LoginAlreadyRegistered': 'Bereits registriert?',
		'Proceq.TextOrSignInUsing': 'Oder Anmeldung mit',
		'Proceq.NavSearchSerials': 'Verträge suchen',
		'Proceq.NavRegisterProbeSNs': 'Vertrag registrieren',
		'Proceq.NavCreateStaffAccounts': 'Mitarbeiterkonten erstellen',
		'Proceq.NavHistoryLog': 'Verlaufsprotokoll',
		'Proceq.NavMeasurementsData': 'Messdaten',
		'Proceq.NavChangePassword': 'Passwort ändern',
		'Proceq.NavLogout': 'Logout',
		'Proceq.NavUpdateProfile': 'Profil aktualisieren',
		'Proceq.TitleUpdateProfile': 'Profil aktualisieren',
		'Proceq.NavCreateCustomersAccounts': 'Kundenkonto erstellen',
		'Proceq.ButtonSearch': 'Suchen',
		'Proceq.ButtonUpdate': 'Update',
		'Proceq.ButtonCreate': 'Erstellen',
		'Proceq.ButtonChange': 'Ändern',
		'Proceq.FormSearchBy': 'Suchen nach',
		'Proceq.FormValue': 'Wert',
		'Proceq.FormAccountType': 'Kontotyp',
		'Proceq.FormAccountTypeAdmin': 'Administrator',
		'Proceq.FormAccountTypeBU': 'Geschäftseinheit',
		'Proceq.FormCompanyName': 'Firmenname',
		'Proceq.FormAddress': 'Adresse',
		'Proceq.FormZipCode': 'PLZ',
		'Proceq.FormCountry': 'Land',
		'Proceq.FormPhoneNumber': 'Telefon',
		'Proceq.FormCompanyWebsite': 'Firmen-Website',
		'Proceq.FormJobTitle': 'Position',
		'Proceq.FormIndustry': 'Branche',
		'Proceq.FormSalutation': 'Begrüssung',
		'Proceq.FormCompanySize': 'Firmengrösse',
		'Proceq.FormLanguage': 'Sprache',
		'Proceq.FormContactByProceq': 'Kontakt bei Proceq',
		'Proceq.LanguageEnglish': 'Englisch',
		'Proceq.LanguageJapanese': 'Japanisch',
		'Proceq.LanguageChinese': 'Chinesisch',
		'Proceq.LanguageGerman': 'Deutsch',
		'Proceq.TextYes': 'Ja',
		'Proceq.TextNo': 'Nein',
		'Proceq.FormNewsletter': 'Newsletter',
		'Proceq.TitleSearchSerials': 'Verträge suchen',
		'Proceq.TitleChangePassword': 'Passwort ändern',
		'Proceq.TabLoginCustomers': 'Kunden',
		'Proceq.TabLoginSingleUser': 'Einzelbenutzer',
		'Proceq.TabLoginGroup': 'Gruppe',
		'Proceq.TextRemainingXDays': 'Noch **?0** Tag(e)',
		'Proceq.TextInfoShort': 'Info',
		'Proceq.TextIAgreeTerms': 'Ich stimme den <a>Allgemeinen Geschäftsbedingungen</a> zu',
		'Proceq.ErrorDisplayTitleError': 'Fehler',
		'Proceq.ErrorDisplayTitleWarning': 'Warnung',
		'Proceq.LoginBackLogin': 'Zurück zur Anmeldung',
		'Proceq.LoginForgotPasswordQuestion': 'Passwort vergessen?',
		'Proceq.LoginResendConfirmationEmail': 'Bestätigungs-E-Mail erneut senden',
		'Proceq.LoginRegisterAccountTextPart': 'Wenn Sie noch kein Konto haben, dann',
		'Proceq.LoginRegisterAccountLinkPart': 'registrieren Sie sich bitte hier',
		'Proceq.LoginProceqUseOnly': 'Nur zur internen Verwendung durch Proceq',
		'Proceq.ProfileEnterInfo': 'Bitte geben Sie die Daten Ihres Profils ein, bevor Sie fortfahren',
		'Proceq.TextInfoExclamation': 'Info!',
		'Proceq.FooterLegalPrivacy': 'Rechtliches & Datenschutz',
		'Proceq.JQGridTableRecordText': '{0} - {1} von {2} anzeigen',
		'Proceq.JQGridTableEmptyRecords': 'Keine Datensätze zur Ansicht',
		'Proceq.JQGridTableLoadText': 'Laden...',
		'Proceq.JQGridTablePaginationText': 'Seite {0} von {1}',
		'Proceq.JQGridTableSavingText': 'Speichern...',
		'Proceq.JQGridTableFirstPage': 'Erste Seite',
		'Proceq.JQGridTableLastPage': 'Letzte Seite',
		'Proceq.JQGridTableNextPage': 'Nächste Seite',
		'Proceq.JQGridTablePreviousPage': 'Vorherige Seite',
		'Proceq.JQGridTableRecordsPerPage': 'Datensätze pro Seite',
		'Proceq.JQGridTablePageText': 'Seite:',
		'Proceq.JQGridTableNoMoreRecs': 'Keine weiteren Datensätze...',
		'Proceq.ExportColumnCSVNo': 'Nr.',
		'Proceq.ExportColumnCSVSingleReadings': 'Einzelne Messwerte',
		'Proceq.ExportColumnCSVMeasurementValue': 'Messwert',
		'Proceq.ExportColumnCSVAngleDegrees': 'Winkel (°)',
		'Proceq.ExportColumnCSVExcluded': 'Ausgeschlossen',
		'Proceq.ExportColumnCSVSeriesName': 'Serienname',
		'Proceq.ExportColumnCSVImpactDevice': 'Schlaggerät',
		'Proceq.ExportColumnCSVImpactDeviceSN': 'Seriennr. Schlaggerät',
		'Proceq.ExportColumnCSVImpactDeviceVerification': 'Verifizierung Schlaggerät',
		'Proceq.ExportColumnCSVImpactDeviceHammerVerification': 'Verifizierung Hammer',
		'Proceq.ExportColumnCSVImpactDeviceHammer': 'Hammer',
		'Proceq.ExportColumnCSVImpactDeviceHammerSN': 'Seriennr. Hammer',
		'Proceq.ExportColumnCSVBluetoothModuleSN': 'Seriennr. Bluetooth-Modul',
		'Proceq.ExportColumnCSVConversionStandard': 'Umwertungsstd.',
		'Proceq.ExportColumnCSVScale': 'Skala',
		'Proceq.ExportColumnCSVUnit': 'Einheit',
		'Proceq.ExportColumnCSVReadings': 'Messwerte',
		'Proceq.ExportColumnCSVStandardDeviation': 'Std.abw.',
		'Proceq.ExportColumnCSVRelativeSpan': 'Rel. Messbereich (%)',
		'Proceq.ExportColumnCSVDebugDataSeriesName': 'Serienname',
		'Proceq.ExportColumnExportDate': 'Exportdatum',
		'Proceq.ExportColumnUpperLimit': 'Oberer Grenzwert',
		'Proceq.ExportColumnLowerLimit': 'Unterer Grenzwert',
		'Proceq.ExportColumnLimits': 'Grenzwerte',
		'Proceq.ExportColumnHigh': 'Hoch',
		'Proceq.ExportColumnLow': 'Niedrig',
		'Proceq.ExportColumnValueOff': 'Aus',
		'Proceq.ExportColumnValueNoConversion': 'Keine Umwertung',
		'Proceq.ExportColumnValueNoConversionShort': 'KeineUmw',
		'Proceq.ExportColumnReadings': 'Messwerte',
		'Proceq.ExportColumnStandardDeviation': 'Standardabweichung',
		'Proceq.ExportColumnRange': 'Bereich',
		'Proceq.ExportColumnRelativeSpan': 'Relativer Messbereich',
		'Proceq.ExportColumnMaximum': 'Maximum',
		'Proceq.ExportColumnMinimum': 'Minimum',
		'Proceq.ExportColumnAverage': 'Durchschnitt',
		'Proceq.ExportColumnUnit': 'Einheit',
		'Proceq.ExportColumnName': 'Name',
		'Proceq.ExportColumnDataSeriesID': 'Serien-ID',
		'Proceq.ExportColumnFolderName': 'Ordnername',
		'Proceq.ExportColumnCreatedDate': 'Datum der Erstellung',
		'Proceq.ExportColumnCreatedDateOnly': 'Datum der Erstellung',
		'Proceq.ExportColumnCreatedTime': 'Uhrzeit der Erstellung',
		'Proceq.ExportColumnLastModifiedDate': 'Datum der letzten Bearbeitung',
		'Proceq.ExportColumnLastModifiedDateOnly': 'Datum der letzten Bearbeitung',
		'Proceq.ExportColumnLastModifiedTime': 'Uhrzeit der letzten Bearbeitung',
		'Proceq.ExportColumnMeasurementAvgHardness': 'Gemessener Mittlewert',
		'Proceq.ExportColumnLocationLatitude': 'Standort (Breitengrad)',
		'Proceq.ExportColumnLocationLongitude': 'Standort (Längengrad)',
		'Proceq.ExportColumnProbeType': 'Typ',
		'Proceq.ExportColumnProbeSN': 'Seriennummer',
		'Proceq.ExportColumnBTMSN': 'Seriennummer',
		'Proceq.ExportColumnProbeLastModifiedDate': 'Datum der letzten Verifizierung',
		'Proceq.ExportColumnProbeHardwareRevision': 'Hardware Version',
		'Proceq.ExportColumnProbeFirmwareRevision': 'Firmware Version',
		'Proceq.ExportColumnBTMHardwareRevision': 'Hardware Version',
		'Proceq.ExportColumnBTMFirmwareRevision': 'Firmware Version',
		'Proceq.ExportColumnAppPlatform': 'Plattform',
		'Proceq.ExportColumnVersion': 'Version',
		'Proceq.ExportColumnOperator': 'Bediener',
		'Proceq.ExportColumnPrimaryScale': 'Primäre Skala',
		'Proceq.ExportColumnSecondaryScale': 'Sekundäre Skala',
		'Proceq.ExportColumnMaterial': 'Material',
		'Proceq.ExportColumnConversionStandard': 'Umwertungsstandard',
		'Proceq.ExportColumnAngleCorrection': 'Winkelkorrektur',
		'Proceq.ExportColumnValueAuto': 'Auto',
		'Proceq.ExportColumnTableAngleGrades': 'Winkel (°)',
		'Proceq.ExportColumnTableLegendExcluded': '* Ausgeschlossen',
		'Proceq.ExportColumnMeasurementBarGraph': 'Balkendiagramm',
		'Proceq.ExportColumnTableView': 'Tabellenansicht',
		'Proceq.ExportColumnStatistics': 'Statistik',
		'Proceq.ExportColumnSettings': 'Einstellungen',
		'Proceq.ExportColumnImpactDeviceInfo': 'Info Schlaggerät',
		'Proceq.ExportColumnImpactDeviceHammerInfo': 'Info Hammer',
		'Proceq.ExportColumnBluetoothModuleInfo': 'Info Bluetooth-Modul',
		'Proceq.ExportColumnApplicationInfo': 'Applikationsinfo',
		'Proceq.ExportColumnAttachedPhotos': 'Angefügte Fotos',
		'Proceq.ButtonActions': 'Aktionen',
		'Proceq.SerialNotInServiceFTP': 'S/N not in Service FTP',
		'Proceq.ActionResultsToCSV': 'CSV Export',
		'Proceq.ErrorDisplayTitleSuccess': 'Erfolgreich',
		'Proceq.ErrorNoMultipleTargetsAllowed': 'Aktion kann nicht auf mehrere Spalten angewendet werden',
		'Proceq.MessageActionSuccesfullyPerformed': 'Aktion wurde erfolgreich durchgeführt',
		'Proceq.EmailReminderContractUserSubject': 'Erinnerung: Noch 2 Monate bis Vertragsablauf',
		'Proceq.EmailReminderContractUserBody':
			'Sehr geehrte/r Benutzer/in,<br/><br/>der Mietzeitraum für **?0** läuft am **?1** ab.<br/><br/>Ihr Vertrag wird gemäss unseren <a href="http://www.proceq.com/fileadmin/user_upload/Proceq_General_Terms___Conditions_of_Rental_10112016.pdf">Allgemeinen Mietbedingungen</a> automatisch um 12 Monate verlängert. Sie erhalten die Rechnung separat. Bitte stellen Sie sicher, dass die Rechnung rechtzeitig beglichen wird, um die nahtlose Nutzung Ihres Equotip Live-Produktes sicherzustellen.<br/><br/>Bitte wenden Sie sich bei Fragen an Ihren <a href="http://www.proceq.com/contact/proceq-network/">örtlichen Vertriebsvertreter</a>.<br/><br/>Ihr Proceq Team<br/><br/>**?2**',
		'Proceq.EmailReminderContractAdminSubject': 'Erinnerung: Noch 2 Monate bis Vertragsablauf',
		'Proceq.ErrorNoTargetSelected': 'Kein Objekt ausgewählt für diese Aktion',
		'Proceq.ErrorDisplayTitleInfo': 'Info',
		'Proceq.TextSharingSeriesUrlLinkCreated':
			'Die Messreihen sind unter folgendem Link erreichbar:<br/>**?0**<br/><a class="link-copy-clipboard" href="javascript:void(0);" onclick="**?1**">In die Zwischenablage kopieren</a><br/>',
		'App.ShareMeasurementsSuccess': 'Die Messreihen sind unter folgendem Link erreichbar',
		'App.ShareMeasurementsCopyAction': 'In die Zwischenablage kopieren',
		'Proceq.TextPleaseWait': 'Bitte warten…',
		'Proceq.TextPerformingServerRequest': 'Führe Serveranfrage aus',
		'Proceq.TextPerformingAction': 'Führe Aktion aus',
		'Proceq.ButtonCreateAccountChinaBack': 'Verwerfen',
		'Proceq.TitleCreateAccountChina': 'Chinesischer Kunde',
		'Proceq.BodyCreateAccountChina':
			'Für Kunden in China zum Registrieren oder Einloggen bitte <a href="**?0**">**?1**</a> aufrufen',
		'Proceq.LoginCompatibiltyInfo': 'Informationen zur Kompatibilität <a href="**?0**">Proceq Apps</a>',
		'Proceq.TextRedirectingToRegion': 'Weiterleiten für passende Region',
		'Proceq.EmailReminderContractExpiredTodayUserSubject': 'Vertragsablauf',
		'Proceq.EmailReminderContractExpiredTodayUserBody':
			'Sehr geehrte/r Benutzer/in,<br/><br/>der Mietzeitraum für **?0** ist heute abgelaufen.<br/><br/>Wir bedauern es, dass Sie Proceq Live nicht länger verwenden. Wir würden uns jederzeit sehr freuen, Sie wieder bei uns begrüssen zu dürfen.<br/><br/>Wenn Sie den Vertrag verlängern möchten, wenden Sie sich bitte an Ihren <a href="http://www.proceq.com/contact/proceq-network/">örtlichen Vertriebsvertreter</a>.<br/><br/>Ihr Proceq Team<br/><br/>**?1**',
		'Proceq.EmailRentalPeriodChangedSubject': 'Benachrichtigung über Vertragsänderung',
		'Proceq.EmailRentalPeriodChangedBody':
			'Sehr geehrte/r Benutzer/in,<br/><br/>Das Ablaufdatum Ihres Mietvertrags für **?0** wurde geändert. Ihr Mietvertrag endet nun am **?1**.<br/><br/>Bitte wenden Sie sich bei Fragen an Ihren <a href="http://www.proceq.com/contact/proceq-network/">örtlichen Vertriebsvertreter</a>.<br/><br/>Ihr Proceq Team<br/><br/>**?2**',
		'Proceq.FormExpiresIn': 'Läuft in (Tagen) ab',
		'Proceq.SelectTimeWeek': 'Woche',
		'Proceq.SelectTimeDay': 'Tag',
		'Proceq.SelectTimeMonth': 'Monat',
		'Proceq.GraphNoDataAvailable': 'Keine Daten verfügbar',
		'Proceq.GraphMaterial': 'Materialgruppe',
		'Proceq.GraphSecondaryScale': 'Umwerteskala',
		'Proceq.GraphStandard': 'Umwertungsstandard',
		'Proceq.GraphMeasurementCount': 'Total Prüfschläge',
		'Proceq.GraphMeasurementSeriesCount': 'Anzahl Prüfschläge',
		'Proceq.StatisticsTitle': 'Statistik',
		'Proceq.StatisticsCreatedSeries': 'Erstellte Messreihen',
		'Proceq.StatisticsModifiedSeries': 'Modifizierte Messreihen',
		'Proceq.StatisticsPhotos': 'Bilder',
		'Proceq.StatisticsAudioFiles': 'Audio-Dateien',
		'Proceq.StatisticsUsedStorage': 'Verwendeter Speicher',
		'Proceq.StatisticsFolder': 'Ordner',
		'Proceq.StatisticsModified': 'Modifiziert',
		'Proceq.StatisticsCreated': 'Erstellt',
		'Proceq.StatisticsAll': 'Alle',
		'Proceq.StatisticsCountry': 'Land',
		'Proceq.StatisticsCompanySize': 'Firmengrösse',
		'Proceq.StatisticsIndustry': 'Branche',
		'Proceq.DateRangeFrom': 'Von',
		'Proceq.DateRangeTo': 'Bis',
		'Proceq.GraphHistogram': 'Härte-Histogramm',
		'Proceq.GraphSeriesHistogram': 'Härte-Histogramm Messreihen',
		'Proceq.StatisticsImpactDevice': 'Schlaggerät',
		'Proceq.StatisticsImpactDeviceHammer': 'Hammer',
		'Proceq.GraphExcluded': 'Ausgeschlossen',
		'Proceq.GraphIncluded': 'Eingeschlossen',
		'Proceq.GraphWithinTolerance': 'Innerhalb Toleranz',
		'Proceq.GraphOutOfTolerance': 'Ausserhalb Toleranz',
		'Proceq.StatisticsCreatedImpacts': 'Erstellte Prüfschläge (eingeschlossen/ausgeschlossen)',
		'Proceq.StatisticsModifiedImpacts': 'Modifizierte Prüfschläge (eingeschlossen/ausgeschlossen)',
		'Proceq.GraphNoConversion': 'Keine Umwertung',
		'Proceq.StatisticsTotalImpacts': 'Total Prüfschläge',
		'Proceq.StatisticsTotalSeries': 'Total Messreihen',
		'Proceq.StatisticsTotalExcluded': 'Total ausgeschlossene Prüfschläge',
		'Proceq.StatisticsValuesOutOfLimits': 'Werte ausserhalb der Toleranz',
		'Proceq.StatisticsPeriod': 'Periode',
		'Proceq.LabelFrom': 'Von',
		'Proceq.LabelTo': 'Bis',
		'Proceq.LabelImpacts': 'Prüfschläge',
		'Proceq.AbbrevCalendarWeek': 'KW',
		'Proceq.eRebNStd_Mean': 'Mittelwert',
		'Proceq.ExportFieldTargetScale': 'Zielskala',
		'Proceq.ExportFieldConversionCurve': 'Umwertungskurve',
		'Proceq.eCC_None': 'Kein/e',
		'Proceq.eCC_Portland_Cement_J_N': 'Portlandzement J',
		'Proceq.eCC_Early_Strength_J_N': 'Frühfestigkeit J',
		'Proceq.eCC_Blast_Furnace_J_N': 'Hochofen J',
		'Proceq.eCC_Average_Curve_J_N': 'Durchschnittskurve J',
		'Proceq.eSC_R1_dry': 'JSCE-G504 R1 = 0 (trocken)',
		'Proceq.eSC_R1_black_mark': 'JSCE-G504 R1 = +3 (schwarze Markierung)',
		'Proceq.eSC_R1_wet_surface': 'JSCE-G504 R1 = +5 (nasse Oberfläche)',
		'Proceq.eCSC_None': 'Kein/e',
		'Proceq.eCSC_Upper': 'Obere/r',
		'Proceq.eCSC_Under': 'Untere/r',
		'Proceq.eSCC_US_eCSC_None': 'Einheitliche Stärke',
		'Proceq.eSCC_US_eCSC_Upper': 'Einheitliche Stärke obere Gussoberfläche',
		'Proceq.eSCC_US_eCSC_Under': 'Einheitliche Stärke untere Gussoberfläche',
		'Proceq.eSCC_PC_eCSC_None': 'Pumpbeton',
		'Proceq.eSCC_PC_eCSC_Upper': 'Pumpbeton obere Gussoberfläche',
		'Proceq.eSCC_PC_eCSC_Under': 'Pumpbeton untere Gussoberfläche',
		'Proceq.eSCC_US': 'Einheitliche Stärke',
		'Proceq.eSCC_PC': 'Pumpbeton',
		'Proceq.eNoOutlier': 'Keine Sonderfälle',
		'Proceq.eOutlierLow': 'Sonderfälle gering',
		'Proceq.eOutlierHigh': 'Sonderfälle hoch',
		'Proceq.eOutlierRedundant': 'Sonderfälle redundant',
		'Proceq.LogbookPropertyConversionCurveChangedFromTo': 'Umwertungskurve von **?0** in **?1** geändert',
		'Proceq.LogbookPropertyConversionCurveChangedTo': 'Umwertungskurve in **?0** geändert',
		'Proceq.LogbookPropertyTargetScaleChangedFromTo': 'Zielskala von **?0** in **?1** geändert',
		'Proceq.LogbookPropertySurfaceCorrectionChangedFromTo': 'Oberflächenkorrektur von **?0** in **?1** geändert',
		'Proceq.LogbookPropertySurfaceCorrectionChangedTo': 'Oberflächenkorrektur in **?0** geändert',
		'Proceq.LogbookPropertyCarbonationDepthChangedFromTo':
			'Karbonatisierungstiefe von **?0** mm in **?1** mm geändert',
		'Proceq.LogbookPropertySurfaceConditionChangedFromTo': 'Oberflächenbedingung von **?0** in **?1** geändert',
		'Proceq.LogbookPropertySurfaceConditionChangedTo': 'Oberflächenbedingung geändert in **?0**',
		'Proceq.LogbookPropertyConcreteAgeChangedFromTo': 'Betonalter von **?0** Tagen in **?1** Tage geändert',
		'Proceq.LogbookPropertyConcreteAgeChangedTo': 'Betonalter in **?0** Tage geändert',
		'Proceq.ExportColumnCSVOutlierType': 'Sonderfall',
		'Proceq.ExportColumnCarbonationDepth': 'Karbonatisierungstiefe',
		'Proceq.ExportColumnConcreteAge': 'Betonalter',
		'Proceq.ExportFieldCarbonationDepth': 'Karbonatisierungstiefe',
		'Proceq.ExportFieldConcreteAge': 'Betonalter',
		'Proceq.ValueConcreteAgeDays': '**?0** d',
		'Proceq.ValueCarbonationDepthMms': '**?0** mm',
		'Proceq.ExportColumnConversionCurve': 'Umwertungskurve',
		'Proceq.ExportColumnTableLegendOutlier': '* Sonderfall',
		'Proceq.ExportColumnTargetScale': 'Zielskala',
		'Proceq.ExportColumnMeasurementAvgCompressiveStrength': 'Messung durchschnittliche Druckfestigkeit',
		'Proceq.ExportColumnEn13791Screening': 'EN 13791 Screening',
		'Proceq.ExportColumnLowestRebound': 'Niedrigster Rückprallwert',
		'Proceq.ExportColumnMedianRebound': 'Mittlerer Rückprallwert',
		'Proceq.ExportColumnCompressiveStrengthClass': 'EN 206 Druckfestigkeitsklasse',
		'Proceq.EmailIntroDearContractOwner': 'Sehr geehrter Vertragsinhaber',
		'Proceq.ColumnTableSeriesRegionExport': 'Reihe',
		'Proceq.ExportColumSeriesRegionExport': 'Reihe',
		'Proceq.ExportTitleTestRegionExport': 'Testregion Export',
		'Proceq.ErrorSchmidtExportInvalidStandard': 'Alle Reihen müssen über den gleichen Satz an Standards verfügen',
		'Proceq.ErrorSchmidtExportTooFewSeries': 'Für das EN 13791 Screening sind mindestens 9 Reihen erforderlich',
		'Proceq.ErrorSchmidtExportTooManySeries': 'Für einen Export können max. 99 Reihen ausgewählt werden',
		'Proceq.ErrorSchmidtExportDifferentConfiguration': 'Unterschiedliche Konfigurationen in der Reihe gefunden',
		'Proceq.ErrorSchmidtExportWrongStandard': 'Falscher Standard für ausgewählten Export erkannt',
		'Proceq.ErrorSchmidtExportInvalidSeries': 'Mindestens eine Reihe ist gemäß des ausgewählten Standards ungültig',
		'Proceq.ErrorSchmidtExportSoftwareError': 'Fehler bei Generierung des Exports',
		'Proceq.ErrorSchmidtExportOutOfRange': 'Die berechnete Stärkeklasse liegt außerhalb des zulässigen Bereichs',
		'Proceq.ButtonExportTestRegionEU': 'EN 13791 Screening-Test als PDF exportieren',
		'Proceq.ButtonExportTestRegionUniformity': 'Homogenität als PDF exportieren',
		'Proceq.ButtonExportTestRegionChina': 'JGJ T23 Testregion als PDF exportieren',
		'Proceq.ErrorNotYetImplemented': 'Noch nicht implementiert',
		'Proceq.ErrorSchmidtExportWrongParam':
			'Falscher Parameter in Reihe gefunden. Beispiel: Falsche Einheit für Export ausgewählt',
		'Proceq.ErrorSchmidtExportUnitNotMpa': 'Für die JGJ-Region muss als Einheit zwingend MPa eingestellt sein',
		'Proceq.ErrorSchmidtExportWrongFormFactor': 'Für die JGJ-Region darf der Formfaktor nicht 2:1 Zylinder sein',
		'Proceq.NavSelectView': 'Ansicht auswählen',
		'Proceq.TextConfirmationAreYouSure': 'Sind Sie sicher?',
		'Proceq.ErrorContractLinkedDifferentImpactDevice':
			'Vertrag kann nicht mit der Seriennummer des aktuellen Schlaggeräts verwendet werden',
		'Proceq.TitleShareByUrl': 'Per URL freigeben',
		'Proceq.ExportTitleVerification': 'Verifizierungsbericht:',
		'Proceq.ExportVerificationStatusFailed': 'Fehlgeschlagen',
		'Proceq.ExportVerificationStatusPassed': 'Erfolgreich',
		'Proceq.ExportColumnTestVerificationResult': 'Verifizierungsergebnis',
		'Proceq.ExportColumnTestBlockTolerance': 'Unsicherheit Härtevergleichsplatte (Ucrm) k=2',
		'Proceq.ExportColumnTestBlockNominalHardness': 'Nominale Härte Härtevergleichsplatte',
		'Proceq.ExportColumnTestBlockSN': 'Seriennummer Härtevergleichsplatte',
		'Proceq.ExportColumnTestBlockName': 'Name Härtevergleichsplatte',
		'Proceq.ExportColumnVerificationStd': 'Verifizierungsstandard',
		'Proceq.ExportColumnVerificationCriteria': 'Verifizierungskriterien',
		'Proceq.VerificationCriteriaAverage5Span5Perc': 'Durchschnitt +/- 5 %, Spanne +/- 5 %',
		'Proceq.VerificationCriteriaReadings6HL': 'Werte +/- 6 HL',
		'Proceq.VerificationCriteriaReadings3Perc': 'Werte +/- 3 %',
		'Proceq.VerificationCriteriaAverage5Perc': 'Durchschnitt +/- 5 %',
		'Proceq.VerificationCriteriaAverage2R': 'Durchschnitt +/- 2 R',
		'Proceq.VerificationCriteriaReadings3R': 'Werte +/- 3 R',
		'Proceq.StandardEnumCustom': 'Kundenspezifisch',
		'Proceq.MaterialEnumCustom': 'Kundenspezifisch',
		'Proceq.ExportColumnCalibrationAnvilName': 'Kalibrieramboss Name',
		'Proceq.ExportColumnCalibrationAnvilSN': 'Kalibrieramboss Seriennr.',
		'Proceq.ExportColumnCalibrationAnvilReboundValue': 'Kalibrieramboss Rückprallwert',
		'Proceq.ExportColumnCalibrationAnvilTolerance': 'Kalibrieramboss Toleranz',
		'Proceq.ExportColumnTestLoadInfo': 'Prüflast Info',
		'Proceq.NavVerificationData': 'Verifizierungsdaten',
		'Proceq.NavCustomData': 'Kundenspezifische Daten',
		'Proceq.TableColumnVerificationResult': 'Ergebnis',
		'Proceq.TitleVerifications': 'Verifizierungen',
		'Proceq.LogBookTestBlock': 'Härtevergleichsplatte',
		'Proceq.LogBookCalibrationAnvil': 'Kalibrieramboss',
		'Proceq.LogbookPropertyTestBlockChangedFromTo': 'Härtevergleichsplatte wurde von "**?0**" in "**?1**" geändert',
		'Proceq.LogbookPropertyTestBlockChangedTo': 'Härtevergleichsplatte wurde in "**?0**" geändert',
		'Proceq.LogbookPropertyCalibrationAnvilChangedFromTo': 'Kalibrieramboss wurde von **?0** in **?1** geändert',
		'Proceq.LogbookPropertyCalibrationAnvilChangedTo': 'Kalibrieramboss wurde in **?0** geändert',
		'Proceq.LogbookPropertyCreatedVerification': 'Erzeugte Verifizierung "**?0**"',
		'Proceq.LogbookPropertyRenamedVerificationFromTo': 'Verifizierung wurde von "**?0**" in "**?1**" geändert',
		'Proceq.LogbookResultVerificationFailed': 'Verifizierung fehlgeschlagen',
		'Proceq.LogbookResultVerificationPassed': 'Verifizierung erfolgreich',
		'Proceq.LogbookPropertyTestLoadChangedFromTo': 'Prüflast wurde von **?0** in **?1** geändert',
		'Proceq.LogbookPropertyTestLoadChangedTo': 'Prüflast wurde in **?0** geändert',
		'Proceq.LogBookTestLoad': 'Prüflast',
		'Proceq.FieldWebOriginalCurve': 'Original',
		'Proceq.FieldWebCustomCurve': 'Kundenspezifisch',
		'Proceq.ExportFieldValuePair': 'Wertepaar',
		'Proceq.TableBaseMaterial': 'Grundwerkstoff',
		'Proceq.TableDescription': 'Beschreibung',
		'Proceq.NavCustomMaterial': 'Kundenspezifisches Material',
		'Proceq.FolderCustomMaterials': 'Kundenspezifische Materialien',
		'Proceq.SchmidtCurveTypePolynomial': 'fck = a + b*R + c*R² (polynomial)',
		'Proceq.SchmidtCurveTypeExponencial': 'fck = a.e^(b*R) (exponential)',
		'Proceq.SchmidtCurveTypePolynomialShort': 'Polynomial',
		'Proceq.SchmidtCurveTypeExponencialShort': 'Exponential',
		'Proceq.ExportFieldCurveType': 'Kurventyp',
		'Proceq.TableActionEdit': 'Bearbeiten',
		'Proceq.TableActionAdd': 'Hinzufügen',
		'Proceq.WebAccountActivatedTitle': 'Erfolgreiche Bestätigung',
		'Proceq.PasswordChangedTitle': 'Geändertes Passwort',
		'Proceq.ResetPasswordFormHeading': 'Ihr Passwort zurücksetzen',
		'Proceq.PasswordChangedBody':
			'Das Passwort wurde erfolgreich zurückgesetzt. Sie können Ihr neues Passwort verwenden, um sich bei Ihrem Konto anzumelden.',
		'Proceq.EnterYourNewPassword': 'Geben Sie Ihr neues Passwort ein',
		'Proceq.WebAccountActivatedBodyLine1':
			'Herzlichen Glückwunsch, Sie haben Ihre E-Mail-Adresse erfolgreich bestätigt. Sie können nun Ihre Inspektionsreise mit Screening Eagle beginnen.',
		'Proceq.Confirm': 'Bestätigen',
		'Proceq.NavDashboard': 'Dashboard',
		'App.SchmidtFormFactorRAngle': 'R-Winkel korrigiert',
		'App.SchmidtFormFactorQAngle': 'Q-Wert',
		'App.ShareMeasurementsCopySuccess': 'Kopiert!',
		'App.ShareMeasurementsCopyFailed': 'Ihr Browser unterstützt kein Kopieren. Bitte kopieren Sie manuell.',
		'App.SystemFolders': 'System ordner',
		'Proceq.DataSeriesDemo': 'Demo',
		'Proceq.DataSeriesImported': 'Importiert',
		'Antenna Spacing [{unit}]': 'Antennenabstand [{unit}]',
		'App Version': 'App Version',
		'Base Unit S/N': 'Basiseinheit S / N',
		'Calculated Depth': 'Berechnete Tiefe',
		Comment: 'Kommentar',
		'Contract ID': 'Vertrags-ID',
		'Distance X [{unit}]': 'Abstand X [{unit}]',
		'Distance Y [{unit}]': 'Abstand Y [{unit}]',
		'Export HTML Format Version': 'HTML-Formatversion exportieren',
		'File ID': 'Datei-ID',
		'File Name': 'Dateiname',
		'Firmware Version': 'Firmware Version',
		'Grid Size X [{unit}]': 'Rastergröße X [{unit}]',
		'Grid Size Y [{unit}]': 'Rastergröße Y [{unit}]',
		'Grid Spacing X [{unit}]': 'Rasterabstand X [{unit}]',
		'Grid Spacing Y [{unit}]': 'Rasterabstand Y [{unit}]',
		'Hardware Version': 'Hardware Version',
		Line: 'Linie',
		'MAC Address': 'MAC-Adresse',
		'Marker Number': 'Markierungsnummer',
		'Measurement Presets': 'Messvoreinstellungen',
		Mode: 'Modus',
		Name: 'Name',
		Objects: 'Objekte',
		'Probe S/N': 'Sonde S / N',
		Product: 'Produkt',
		'Repetition Rate [{unit}]': 'Wiederholungsrate [{unit}]',
		Resolution: 'Auflösung',
		'Sampling Rate [{unit}]': 'Abtastrate [{unit}]',
		'Scan Distance [{unit}]': 'Scan-Abstand [{unit}]',
		'Scan Length [{unit}]': 'Scan-Länge [{unit}]',
		'Set Depth': 'Tiefe einstellen',
		'Set Size': 'Größe einstellen',
		Tag: 'Etikett',
		'Tag Type': 'Tag-Typ',
		'Time Window [{unit}]': 'Zeitfenster [{unit}]',
		Units: 'Einheiten',
		'User / Probe Info': 'Benutzer- / Sondeninfo',
		Markers: 'Marker',
		'Created {fileName} in folder {folderName}': '{fileName} im Ordner {folderName} erstellt',
		'Mode: {value}': 'Modus: {value}',
		'App.HTML.FDL.IndicationInfo.Backwall': 'Rückwand',
		'App.HTML.FDL.MeasurementPresets.DgsCalibration': 'AVG Kalibrierung',
		'App.HTML.FDL.MeasurementPresets.DgsSnapshot': 'AVG Schnappschuss',
		'App.HTML.FDL.IndicationInfo.ReferenceType': 'Referenftyp',
		'App.HTML.FDL.IndicationInfo.ProbeType': 'Prüfkopftyp',
		'App.HTML.FDL.IndicationInfo.calibratedDistance': 'Kalibrierabstand ({unit})',
		'App.HTML.FDL.IndicationInfo.CalibratedAmplitude': 'Kalibrieramplitude ({unit})',
		'App.HTML.FDL.IndicationInfo.Attenuation': 'Schallschwächung ({unit})',
		'App.HTML.FDL.IndicationInfo.DeltaVk': 'Delta Vk ({unit})',
		'App.HTML.FDL.IndicationInfo.ErsMainCurve': 'ERG der Hauptkurve ({unit})',
		'App.HTML.FDL.DgsSnapshot.G1DistanceToDefect': 'G1 Abstand zum Fehler ({unit})',
		'App.HTML.FDL.DgsSnapshot.G1AmplitudeOfDefect': 'G1 Amplitude des Fehlers ({unit})',
		'App.HTML.FDL.DgsSnapshot.G1.Ers': 'G1 ERG ({unit})',
		'App.HTML.FDL.DgsSnapshot.G1DbToCurve': 'G1 dB zu Kurve ({unit})',
		'App.HTML.FDL.DgsSnapshot.G2DistanceToDefect': 'G2 Abstand zum Fehler ({unit})',
		'App.HTML.FDL.DgsSnapshot.G2AmplitudeOfDefect': 'G2 Amplitude des Fehlers ({unit})',
		'App.HTML.FDL.DgsSnapshot.G2.Ers': 'G2 ERG ({unit})',
		'App.HTML.FDL.DgsSnapshot.G2DbToCurve': 'G2 dB zu Kurve ({unit})',
		'Auth.LoggingIn': 'Anmeldung...',
		'Auth.LoggingOut': 'Abmeldung...',
	},
};
