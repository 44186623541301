// @flow
import React from 'react';
import type { InjectIntlProvidedProps } from 'react-intl';
import { injectIntl, FormattedMessage } from 'react-intl';
import { has } from 'lodash';

export const Measurement = ({ log }: InjectIntlProvidedProps & EquotipLogbookEntryProps) => {
	return (
		<div>
			{log.content.measurements.map(value => {
				const logIds = [];
				let valueFor20 = 0,
					valueFor60 = 0,
					valueForH = 0;
				if (has(value, 'valueFor20')) {
					valueFor20 = value.valueFor20;
					logIds.push('20°: {valueFor20} {unit}');
				}
				if (has(value, 'valueFor60')) {
					valueFor60 = value.valueFor60;
					logIds.push('60°: {valueFor60} {unit}');
				}
				if (has(value, 'valueForH')) {
					valueForH = value.valueForH;
					if (valueForH >= 0 && log.content.unit === 'GU') {
						logIds.push('H: {valueForH} HU');
					}
				}
				const logId = `#{index} ${logIds.join(' / ')} added`;
				return (
					<div key={value.index}>
						<FormattedMessage
							id={logId}
							values={{
								index: value.index + 1,
								unit: log.content.unit,
								valueFor20,
								valueFor60,
								valueForH,
							}}
						/>
						<br />
					</div>
				);
			})}
		</div>
	);
};

export const color = 'blue';
export default injectIntl(Measurement);
