// @flow
import React from 'react';
import { get, noop } from 'lodash';
import UserComponent from './components/Common/User';
import MeasurementPresets from './components/Settings/FDL/MeasurementPresets';
import Logbook from './components/Common/Logbook';
import styles from './HTMLExport.styl';

export type DerivedProps = {
	scanType: ScanType,
	product: ProductCode,
	productModel: string,
	isMetric: false,
	convert: Function,
	showTitle?: boolean,
};

const FDL = ({ data, users }: { data: MeasurementFullData, users: User[] }) => {
	const productModel = get(data, 'measurement.productModel');
	const scanType = 'Indication';
	const derivedProps: DerivedProps = {
		// $FlowFixMe
		product: data.productFamily.toUpperCase(),
		productModel,
		scanType,
		isMetric: false,
		convert: noop,
		showTitle: true,
	};

	return (
		<div className="html-body">
			<h1 id={data.measurement.id} className={styles.file_name}>
				{data.uniqueName}
			</h1>
			<br />
			<UserComponent data={data} {...derivedProps} />
			<br />
			<MeasurementPresets data={data} {...derivedProps} />
			<br />
			<Logbook data={data} users={users} {...derivedProps} />
		</div>
	);
};

export default FDL;
