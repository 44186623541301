import React from 'react';

const AuthBackground = props => {
	return (
		<div style={{ height: '100%', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
			{props.children}
		</div>
	);
};

export default AuthBackground;
