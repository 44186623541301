// @flow

type Timestamp = number;
type Date = string;
type NotificationID = string;
type ProductType = string;
type UserID = number;

export const EnumDeliveryType = {
	DeliveryTypeSendLater: 1,
	DeliveryTypeImmediately: 2,
};

export type NotificationInfo = {
	created: Timestamp,
	createdBy: Timestamp,
	delivered: boolean,
	deliveredDate: Date,
	deliveryDate: Date,
	deliveryType: $Values<typeof EnumDeliveryType>,
	id: NotificationID,
	message: string,
	products: Array<ProductType>,
	push: boolean,
	title: string,
	updated: Timestamp,
	updatedBy: UserID,
};

export type NotificationScheduleResponse = {
	data: NotificationInfo[],
	meta: {
		pagination: {
			count: number,
			page: number,
			pages: number,
			size: number,
			sort: number,
			total: number,
		},
	},
};

export type PostNotificationParams = {
	title: string,
	message: string,
	products: Array<string>,
	deliveryType: $Values<typeof EnumDeliveryType>,
	deliveryDate: Date,
	push: true,
};
