// @flow
import React from 'react';
import { groupBy, map } from 'lodash';
import { withState, compose } from 'recompose';
import { connect } from 'react-redux';
import { InfoWindow, Marker } from 'react-google-maps';
import { injectIntl } from 'react-intl';
import { Table } from 'antd';
import { internalToOfficial } from 'utils/productHelper';
import styles from './RegionalAnalytics.styl';
import ViewMore from './ViewMore';
import { hasRoleLevel, ROLES } from '../../utils/rolesHelper';

export const MarkerGroup = ({
	points,
	visible,
	show,
	hide,
	users,
	intl,
	isAdmin,
}: {
	points: (RegionalAnalytics & { product: ProductCode, region: Region })[],
	visible: boolean,
	show: void => void,
	hide: void => void,
	users: User[],
	intl: Object,
	isAdmin: boolean,
}) => {
	const { formatMessage } = intl;

	const renderOwner = (owner: User) => {
		if (isAdmin && !owner.firstName) {
			return formatMessage({ id: 'App.AnalyticsLocationNoUser' });
		}

		return owner && isAdmin ? (
			<span>
				{owner.firstName} {owner.lastName}
				<br />
				{owner.username}
			</span>
		) : (
			`uID: ${owner.id}`
		);
	};

	return (
		<Marker
			position={{ lat: points[0].lat, lng: points[0].lon }}
			onClick={show}
			label={String(points.length)}
			noRedraw
		>
			{visible && (
				<InfoWindow onCloseClick={hide}>
					<Table
						classname={styles.infowindow}
						rowKey={(point, index) => index}
						dataSource={map(
							groupBy(points, point => [point.ownerId, point.region, point.product].join('.')),
							(groupedPoints, group) => {
								const [ownerId, region, product] = group.split('.');
								const owner = users ? users.find(u => u.id === +ownerId) : {};
								return {
									...owner,
									region,
									product,
									groupedPoints,
								};
							}
						)}
						pagination={false}
					>
						{users && (
							<Table.Column
								key="ownerId"
								title={formatMessage({ id: 'App.AnalyticsLocationUserColumn' })}
								render={owner => renderOwner(owner)}
							/>
						)}
						{points[0].region && (
							<Table.Column title={formatMessage({ id: 'App.AnalyticsRegion' })} dataIndex="region" />
						)}
						{points[0].product && (
							<Table.Column
								title={formatMessage({ id: 'App.Product' })}
								dataIndex="product"
								render={internalToOfficial}
							/>
						)}
						{points[0].product && (
							<Table.Column
								title={formatMessage({ id: 'App.AnalyticsStorageTypeTotal' })}
								key="groupedPoints.0.mid"
								dataIndex="groupedPoints"
								render={text => text.length}
							/>
						)}
						<Table.Column
							title={formatMessage({ id: 'Proceq.ExportColumnName' })}
							key="groupedPoints.0.uid"
							dataIndex="groupedPoints"
							render={groupedPoints => (
								<ViewMore list={groupedPoints} max={3} formatMessage={formatMessage} />
							)}
						/>
					</Table>
				</InfoWindow>
			)}
		</Marker>
	);
};

export default compose(
	injectIntl,
	withState('mID', 'selectMeasurement', props => props.points[0].mid),
	connect(state => ({
		isAdmin: hasRoleLevel(state.session.scopes, ROLES.ADMIN),
	}))
)(MarkerGroup);
