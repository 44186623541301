// @flow
import React from 'react';
import { FormattedMessage } from 'react-intl';
import Photo from 'components/measurements/LogEvent/Photo';

export const ChangeKeyFrame = ({ log }: GPRLogbookEntryProps) => {
	const position = +log.content.position;

	return (
		<div>
			<b>
				<FormattedMessage id="App.Logbook.FDL.Keyframe.Changed" values={{ position }} />
			</b>
			<br />
			<Photo mID={log.mID} aID={log.content.attachmentId} attachmentName={log.content.attachmentName} />
		</div>
	);
};

export const color = () => 'green';

export default ChangeKeyFrame;
