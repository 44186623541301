// @flow
import React, { Fragment } from 'react';
import type { Element } from 'react';
import { FormattedMessage } from 'react-intl';
import { isProduction, isLive } from 'apis/urls';
import Firmware from 'components/svgIcons/Firmware';
import Bluetooth from 'components/svgIcons/Bluetooth';
import EagleHead from 'components/svgIcons/EagleHead';
import { getTextValue } from 'utils/registryHelper';
import { hasRoleLevel, hasOneOfRoleLevel, ROLES } from 'utils/rolesHelper';

const SERVER_TYPE: string = process.env.SERVER_TYPE || 'dev';

const INSPECT_URLS = {
	local: 'https://web-dev.screeningeagle.com',
	dev: 'https://web-dev.screeningeagle.com',
	stg: 'https://web-staging.screeningeagle.com',
	prod: 'https://web.screeningeagle.com',
	'dev-cn': 'https://web-dev.screeningeagle.com',
	'stg-cn': 'https://web-staging.screeningeagle.com',
	'prod-cn': 'https://web.screeningeagle.com',
};

export const INSPECT_URL = INSPECT_URLS[SERVER_TYPE];

const WORKSPACE_URLS = {
	local: 'https://workspace-dev.screeningeagle.com',
	dev: 'https://workspace-dev.screeningeagle.com',
	stg: 'https://workspace-stg.screeningeagle.com',
	prod: 'https://workspace.screeningeagle.com',
	'dev-cn': 'https://workspace-dev.screeningeagle.com',
	'stg-cn': 'https://workspace-stg.screeningeagle.com',
	'prod-cn': 'https://workspace.screeningeagle.com',
};

export const WORKSPACE_URL = WORKSPACE_URLS[SERVER_TYPE];
export const WORKSPACE_MASQUERADE = `${WORKSPACE_URL}/masquerade`;

export type LegacyRouteConfig = {
	label: string,
	path: string,
	component: string,
	rolesNeeded?: string[],
	sessionTypeNeeded?: 'PROCEQ',
	ifGuestOnlyShowIfView?: string,
	iconString: string,
	hideForServerRegions?: string[],
};

export const legacyRoutes: LegacyRouteConfig[] = [
	{
		label: 'Dashboard (PQ)',
		path: '/dashboard-admin-pq',
		component: 'components/dashboardproceq/DashboardAdmin/DashboardAdminData',
		rolesNeeded: ['snassigner_proceq', 'admin_proceq'],
		sessionTypeNeeded: 'PROCEQ',
		ifGuestOnlyShowIfView: 'donotshow',
		iconString: 'area-chart',
	},
	{
		label: 'Proceq.NavDashboard',
		path: '/dashboard',
		component: 'components/dashboardproceq/DashboardCustomerData/DashboardCustomerData',
		rolesNeeded: ['master_client'],
		sessionTypeNeeded: 'PROCEQ',
		ifGuestOnlyShowIfView: 'donotshow',
		iconString: 'area-chart',
	},

	{
		label: 'Proceq.NavMeasurementsData',
		path: '/mnts-data',
		component: 'components/dashboardproceq/MeasurementsData/MainData',
		rolesNeeded: ['master_client'],
		sessionTypeNeeded: 'PROCEQ',
		ifGuestOnlyShowIfView: 'M',
		iconString: 'book',
	},
	{
		label: 'Proceq.NavVerificationData',
		path: '/mnts-verif',
		component: 'components/dashboardproceq/VerificationsData/MainData',
		rolesNeeded: ['master_client'],
		sessionTypeNeeded: 'PROCEQ',
		ifGuestOnlyShowIfView: 'V',
		iconString: 'exception',
	},
	{
		label: 'Proceq.NavCustomMaterial',
		path: '/custom-material',
		component: 'components/dashboardproceq/CustomMaterialData/MainData',
		rolesNeeded: ['master_client'],
		sessionTypeNeeded: 'PROCEQ',
		ifGuestOnlyShowIfView: 'donotshow',
		iconString: 'file-unknown',
	},
	{
		label: 'Contract Mngt (PQ)',
		path: '/contracts-pq',
		component: 'components/dashboardproceq/Contracts/ContractsManagementProceq',
		rolesNeeded: ['admin_proceq'],
		sessionTypeNeeded: 'PROCEQ',
		ifGuestOnlyShowIfView: 'donotshow',
		iconString: 'idcard',
		hideForServerRegions: ['CN'],
	},
	{
		label: 'Contract Mngt (PQ-Admin)',
		path: '/contracts-admin-pq',
		component: 'components/dashboardproceq/ContractsAdmin/ContractsManagementProceq',
		rolesNeeded: ['admin_proceq'],
		sessionTypeNeeded: 'PROCEQ',
		ifGuestOnlyShowIfView: 'donotshow',
		iconString: 'idcard',
		hideForServerRegions: ['CN'],
	},
	{
		label: 'Users Mngt (PQ)',
		path: '/proceq-usersmngmt',
		component: 'components/dashboardproceq/UsersMngmt/UsersManagementProceq',
		rolesNeeded: ['snassigner_proceq', 'admin_proceq'],
		sessionTypeNeeded: 'PROCEQ',
		ifGuestOnlyShowIfView: 'donotshow',
		iconString: 'team',
	},
	{
		label: 'BUs Info Mngt(PQ)',
		path: '/businfo-pq',
		component: 'components/dashboardproceq/BusInfo/BusInfoManagementProceq',
		rolesNeeded: ['admin_proceq'],
		sessionTypeNeeded: 'PROCEQ',
		ifGuestOnlyShowIfView: 'donotshow',
		iconString: 'global',
	},
	{
		label: 'History Logs(PQ)',
		path: '/history-logs-pq',
		component: 'components/dashboardproceq/HistoryLogs/HistoryLogsProceq',
		rolesNeeded: ['admin_proceq'],
		sessionTypeNeeded: 'PROCEQ',
		ifGuestOnlyShowIfView: 'donotshow',
		iconString: 'calendar',
	},
	{
		label: 'Reports (PQ)',
		path: '/reports-admin-pq',
		component: 'components/dashboardproceq/ReportsAdmin/ReportsAdminProceq',
		rolesNeeded: ['admin_proceq'],
		sessionTypeNeeded: 'PROCEQ',
		ifGuestOnlyShowIfView: 'donotshow',
		iconString: 'copy',
	},
	{
		label: 'Track user (PQ)',
		path: '/trackuser-pq',
		component: 'components/dashboardproceq/TrackUser/TrackUserProceq',
		rolesNeeded: ['developer_proceq'],
		sessionTypeNeeded: 'PROCEQ',
		ifGuestOnlyShowIfView: 'donotshow',
		iconString: 'search',
	},
	{
		label: 'Proceq.NavUpdateProfile',
		path: '/settings',
		component: 'components/dashboard/Settings/UserProfile',
		ifGuestOnlyShowIfView: 'donotshow',
		iconString: 'user',
	},
];

type RouteCommonConfig = {
	label: string,
	path: string,
	icon?: string,
	iconString?: string,
	iconElement?: Element<*>,
	tooltip?: Element<*>,
	tooltip_title?: Element<*>,
	checkAccess?: Session => boolean,
};

export type RouteConfig = {
	component: string,
	subPages?: RouteCommonConfig[],
	checkAccess: Session => boolean,
	adminPanel?: boolean,
} & RouteCommonConfig;

const renderProductTooltip = products => (
	<Fragment>
		{getTextValue([products]).map(c => (
			<div key={c.value}>{c.text}</div>
		))}
	</Fragment>
);

export const DataReviewPath = '/data';
export const NewDashBoardpath = '/dashboard-new';
export const OldDashBoardPath = '/dashboard-old';
export const OldDashBoardProducts = ['EQUOTIP', 'SCHMIDT'];

export const routes: RouteConfig[] = [
	// {
	// 	label: 'Proceq.NavDashboard',
	// 	path: NewDashBoardpath,
	// 	component: 'components/dashboardnew/Dashboard',
	// 	checkAccess: ({ scopes, productType }) => {
	// 		if (!['GPR', 'PUNDIT', 'FDL', 'GLM'].includes(productType)) {
	// 			return false;
	// 		}
	// 		if (hasOneOfRoleLevel(scopes, [ROLES.DEV, ROLES.PO, ROLES.ADMIN, ROLES.SUPER])) {
	// 			return true;
	// 		}
	// 		if (hasRoleLevel(scopes, ROLES.USER) && productType === 'GLM') return true;
	// 		return false;
	// 	},
	// 	iconString: 'area-chart',
	// },
	{
		label: 'Proceq.NavDashboard',
		path: OldDashBoardPath,
		component: 'components/dashboardnew/DashboardOld',
		checkAccess: ({ productType }) => OldDashBoardProducts.includes(productType),
		iconString: 'area-chart',
	},
	// {
	// 	label: 'Proceq.NavDataReview',
	// 	path: DataReviewPath,
	// 	component: 'components/measurements/Measurements',
	// 	checkAccess: param => {
	// 		const { scopes, productType } = param;
	// 		if (hasOneOfRoleLevel(scopes, [ROLES.DEV, ROLES.PO, ROLES.ADMIN, ROLES.SUPER])){
	// 			return true;
	// 		}
	// 		if (hasRoleLevel(scopes, ROLES.USER) && ['EQUOTIP', 'SCHMIDT', 'GLM'].includes(productType)) {
	// 			return true;
	// 		}
	// 		return false;
	// 	},
	// 	icon: require('components/img/icoMeasurement@2x.png'),
	// },
	{
		label: 'Proceq.NavVerificationData',
		path: '/data-verify',
		component: 'components/measurements/VerificationData',
		checkAccess: ({ productType }) => ['EQUOTIP', 'SCHMIDT'].includes(productType),
		icon: require('components/img/icoVerify@2x.png'),
	},
	{
		label: 'Proceq.NavCustomMaterial',
		path: '/data-custom',
		component: 'components/measurements/CustomMaterials',
		checkAccess: ({ productType }) => ['EQUOTIP', 'SCHMIDT'].includes(productType),
		icon: require('components/img/icoCustom@2x.png'),
	},
	{
		label: 'App.SetupFiles',
		path: '/data-setup',
		component: 'components/measurements/SetupFiles',
		checkAccess: ({ scopes, productType }) => {
			if (
				hasOneOfRoleLevel(scopes, [ROLES.DEV, ROLES.PO, ROLES.ADMIN, ROLES.SUPER]) &&
				['FDL'].includes(productType)
			) {
				return true;
			}
			return false;
		},
		icon: require('components/img/icoCustom@2x.png'),
	},
	{
		label: 'Proceq.NavUpdateProfile',
		path: '/settings',
		component: 'components/dashboard/Settings/UserProfile',
		checkAccess: () => true,
		iconString: 'user',
	},
	{
		label: 'Proceq.NavInspect',
		path: INSPECT_URL,
		component: '',
		checkAccess: () => true,
		iconElement: <EagleHead />,
	},
	{
		label: 'Proceq.NavWorkspace',
		path: WORKSPACE_URL,
		component: '',
		checkAccess: () => true,
		iconElement: <EagleHead />,
	},
	{
		label: 'App.NavContracts',
		path: '/contracts',
		component: 'components/contracts/Contracts',
		subPages: [
			{
				path: '/contracts/equotip',
				label: 'App.BluetoothProducts',
				iconElement: <Bluetooth />,
				tooltip: renderProductTooltip(isLive ? 'bluetoothproductslive' : 'bluetoothproductsfull'),
				tooltip_title: <FormattedMessage id="App.Product" />,
			},
			{
				path: '/contracts/gpr',
				label: 'App.WifiProducts',
				iconString: 'wifi',
				tooltip: renderProductTooltip(isLive ? 'wifiproductslive' : 'wifiproductsfull'),
				tooltip_title: <FormattedMessage id="App.Product" />,
			},
			{
				path: '/contracts/maintenanceservices',
				label: 'App.MaintenanceServicesProducts',
				iconString: 'tool',
				tooltip_title: <FormattedMessage id="App.Product" />,
			},
			{
				path: '/contracts/software',
				label: 'App.SoftwareSolution',
				iconString: 'appstore',
			},
			{
				path: '/contracts/inspect/contracts',
				label: 'SaaS Contracts',
				iconElement: <EagleHead />,
			},
			{
				path: '/contracts/inspect/licenses',
				label: 'SaaS Licenses',
				iconElement: <EagleHead />,
			},
			{
				path: '/contracts/saas/freetrial',
				label: 'SaaS Free Trial',
				iconElement: <EagleHead />,
			},
			{
				path: '/tier/Tiers',
				label: 'Tier Management',
				tooltip_title: <FormattedMessage id="App.Product" />,
				checkAccess: ({ scopes }) => hasRoleLevel(scopes, ROLES.ADMIN),
				iconString: 'profile',
			},
			{
				path: '/feature/Features',
				label: 'Feature Management',
				tooltip_title: <FormattedMessage id="App.Product" />,
				checkAccess: ({ scopes }) => hasRoleLevel(scopes, ROLES.ADMIN),
				iconString: 'profile',
			},
		],
		checkAccess: ({ scopes }) => hasOneOfRoleLevel(scopes, [ROLES.BU, ROLES.SALES_AND_FINANCE]),
		adminPanel: true,
		iconString: 'profile',
	},
	{
		label: 'App.NavBusinessUnits',
		path: '/businfo',
		component: 'components/bu/BusinessUnits',
		checkAccess: ({ scopes }) => !isProduction && hasRoleLevel(scopes, ROLES.PO),
		adminPanel: true,
		iconString: 'customer-service',
	},
	// {
	// 	label: 'App.NavProbes',
	// 	path: '/probes',
	// 	component: 'components/probes/Probes',
	// 	checkAccess: ({ scopes }) => hasRoleLevel(scopes, ROLES.PO),
	// 	adminPanel: true,
	// 	icon: require('components/img/icoProbeActive@2x.png'),
	// },
	{
		label: 'App.NavDongles',
		path: '/dongles',
		component: 'components/dongles/Dongles',
		subPages: [
			{
				path: '/dongles/equotip',
				label: 'App.BluetoothProducts',
				iconElement: <Bluetooth />,
				tooltip: renderProductTooltip(isLive ? 'bluetoothproductslive' : 'bluetoothproductsfull'),
				tooltip_title: <FormattedMessage id="App.Product" />,
			},
			// {
			// 	path: '/dongles/gpr',
			// 	label: 'App.WifiProducts',
			// 	iconString: 'wifi',
			// 	tooltip: renderProductTooltip(isLive ? 'wifiproductslive' : 'wifiproductsfull'),
			// 	tooltip_title: <FormattedMessage id="App.Product" />,
			// },
		],
		checkAccess: ({ scopes }) => hasRoleLevel(scopes, ROLES.PO),
		adminPanel: true,
		iconString: 'usb',
	},
	{
		label: 'App.NavFirmware',
		path: '/firmware',
		component: 'components/firmware/Firmware',
		checkAccess: ({ scopes }) => hasRoleLevel(scopes, ROLES.PO),
		adminPanel: true,
		iconElement: <Firmware />,
	},
	{
		label: 'App.NavDeveloperTools',
		path: '/dev-tools',
		component: 'components/devtools/DevTools',
		checkAccess: ({ scopes }) => hasOneOfRoleLevel(scopes, [ROLES.DEV, ROLES.PO]),
		adminPanel: true,
		iconString: 'tool',
	},
	{
		label: 'App.NavNotifications',
		path: '/notifications',
		component: 'components/notifications/Notifications',
		checkAccess: ({ scopes }) => hasRoleLevel(scopes, ROLES.PO),
		adminPanel: true,
		iconString: 'notification',
	},
	{
		label: 'Migration',
		path: '/migration',
		component: 'components/devtools/Migration',
		checkAccess: ({ scopes }) => !isLive && hasRoleLevel(scopes, ROLES.PO),
		adminPanel: true,
		iconString: 'cloud',
	},
	{
		label: 'App.NavAnalytics',
		path: '/analytics',
		component: 'components/analytics/Analytics',
		subPages: [
			{
				path: '/analytics/reports',
				label: 'App.NavAnalyticsReports',
				checkAccess: ({ scopes }) => hasRoleLevel(scopes, ROLES.PO) || hasRoleLevel(scopes, ROLES.ANALYST),
				iconString: 'file',
			},
			{
				path: '/analytics/regional',
				label: 'App.NavAnalyticsRegional',
				checkAccess: ({ scopes }) => hasRoleLevel(scopes, ROLES.PO) || hasRoleLevel(scopes, ROLES.ANALYST),
				iconString: 'global',
			},
			{
				path: '/analytics/user',
				label: 'App.NavAnalyticsUser',
				checkAccess: ({ scopes }) => hasRoleLevel(scopes, ROLES.PO) || hasRoleLevel(scopes, ROLES.ANALYST),
				iconString: 'team',
			},
			{
				path: '/analytics/latency',
				label: 'App.DevTools.LatencyLogs.Header',
				checkAccess: ({ scopes }) => hasRoleLevel(scopes, ROLES.PO),
				iconString: 'api',
			},
			{
				path: '/analytics/events',
				label: 'App.DevTools.Events',
				checkAccess: ({ scopes }) => hasRoleLevel(scopes, ROLES.PO),
				iconString: 'book',
			},
		],
		checkAccess: ({ scopes }) => hasRoleLevel(scopes, ROLES.PO) || hasRoleLevel(scopes, ROLES.ANALYST),
		adminPanel: true,
		iconString: 'pie-chart',
	},
	{
		label: 'App.LogbookPropertyAppVersion',
		path: '/versions',
		component: 'components/devtools/Versions',
		checkAccess: ({ scopes }) => hasOneOfRoleLevel(scopes, [ROLES.PO, ROLES.DEV]),
		adminPanel: true,
		iconString: 'mobile',
	},
	{
		label: 'App.NavUsers',
		path: '/usersmngmt',
		component: 'components/users/Users',
		checkAccess: ({ scopes }) => hasRoleLevel(scopes, ROLES.PO),
		adminPanel: true,
		iconString: 'team',
	},
];

export const getRouteConfigFromPathName = (pathName: String) => {
	for (let i = 0; i < routes.length; i++) {
		if (routes[i].path === pathName) {
			return routes[i];
		}
		if (routes[i].subPages) {
			const subRoute = routes[i].subPages.find(r => r.path === pathName);
			if (subRoute !== undefined) {
				// assign checkAccess of parent if not present in sub-route
				if (subRoute.checkAccess === undefined) {
					subRoute.checkAccess = routes[i].checkAccess;
				}
				return subRoute;
			}
		}
	}
	return undefined;
};

const subPageMapping = {};
routes.forEach(route => {
	(route.subPages || []).forEach(subPageRoute => {
		subPageMapping[subPageRoute.path] = route.path;
	});
});
export { subPageMapping };
