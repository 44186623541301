// @flow
import React from 'react';
import type { InjectIntlProvidedProps } from 'react-intl';
import { injectIntl } from 'react-intl';
import handleFormatMessage from 'utils/handleFormatMessage';
import {
	getScaleInfoOfScaleId,
	getResolutionForScale,
	getSchmidtScaleIdOfFromFactorAndUnitId,
} from 'utils/conversionsHelper';

export const Limit = ({
	intl,
	log,
	product,
	isVerificationMode,
	primaryEquotipScaleId,
	primarySchmidtScaleId,
	primaryVerificationScaleId,
}: InjectIntlProvidedProps & EquotipLogbookEntryProps) => {
	let primaryUnits = getScaleInfoOfScaleId(intl.formatMessage, primaryEquotipScaleId, product).scaleUnits;
	const secondaryUnits = getScaleInfoOfScaleId(
		intl.formatMessage,
		log.content.oldLimitValue.secondaryScaleId,
		product
	).scaleUnits;
	const newSecondaryUnits = getScaleInfoOfScaleId(
		intl.formatMessage,
		log.content.updatedLimitValue.secondaryScaleId,
		product
	).scaleUnits;
	let fromValue = '';
	let toValue = '';
	if (isVerificationMode) {
		primaryUnits = getScaleInfoOfScaleId(intl.formatMessage, primaryVerificationScaleId, product).scaleUnits;
		fromValue = `${getResolutionForScale(
			primaryUnits,
			log.content.oldLimitValue.primaryValue,
			'limit'
		)} ${primaryUnits}`;
		toValue = `${getResolutionForScale(
			primaryUnits,
			log.content.updatedLimitValue.primaryValue,
			'limit'
		)} ${primaryUnits}`;
	} else {
		switch (product) {
			case 'EQUOTIP':
				fromValue = `${getResolutionForScale(
					primaryUnits,
					log.content.oldLimitValue.primaryValue,
					'limit'
				)} ${primaryUnits} / ${getResolutionForScale(
					secondaryUnits,
					log.content.oldLimitValue.secondaryValue,
					'limit'
				)} ${secondaryUnits}`;
				toValue = `${getResolutionForScale(
					primaryUnits,
					log.content.updatedLimitValue.primaryValue,
					'limit'
				)} ${primaryUnits} / ${getResolutionForScale(
					newSecondaryUnits,
					log.content.updatedLimitValue.secondaryValue,
					'limit'
				)} ${newSecondaryUnits}`;

				break;
			case 'SSL':
			case 'SCHMIDT':
				{
					const oldScaleId = getSchmidtScaleIdOfFromFactorAndUnitId(
						log.content.oldLimitValue.unitId,
						log.content.oldLimitValue.formFactorId
					);
					const updatedScaleId = getSchmidtScaleIdOfFromFactorAndUnitId(
						log.content.updatedLimitValue.unitId,
						log.content.updatedLimitValue.formFactorId
					);

					fromValue = `${getResolutionForScale(
						getScaleInfoOfScaleId(intl.formatMessage, primarySchmidtScaleId, product).scaleUnits,
						log.content.oldLimitValue.primaryValue,
						'limit'
					)} ${
						getScaleInfoOfScaleId(intl.formatMessage, primarySchmidtScaleId, product).scaleUnits
					} / ${getResolutionForScale(
						getScaleInfoOfScaleId(intl.formatMessage, oldScaleId, product).scaleUnits,
						log.content.oldLimitValue.secondaryValue,
						'limit'
					)} ${getScaleInfoOfScaleId(intl.formatMessage, oldScaleId, product).scaleName}`;

					toValue = `${getResolutionForScale(
						getScaleInfoOfScaleId(intl.formatMessage, primarySchmidtScaleId, product).scaleUnits,
						log.content.updatedLimitValue.primaryValue,
						'limit'
					)} ${
						getScaleInfoOfScaleId(intl.formatMessage, primarySchmidtScaleId, product).scaleUnits
					} / ${getResolutionForScale(
						getScaleInfoOfScaleId(intl.formatMessage, updatedScaleId, product).scaleUnits,
						log.content.updatedLimitValue.secondaryValue,
						'limit'
					)} ${getScaleInfoOfScaleId(intl.formatMessage, updatedScaleId, product).scaleName}`;
				}
				break;
			default:
				break;
		}
	}

	if (log.content.isLimitMax) {
		return (
			<span>
				{handleFormatMessage('LogbookPropertyMaxLimitChangedFromTo', intl.formatMessage, [fromValue, toValue])}
			</span>
		);
	}
	return (
		<span>
			{handleFormatMessage('LogbookPropertyMinLimitChangedFromTo', intl.formatMessage, [fromValue, toValue])}
		</span>
	);
};

export default injectIntl(Limit);
