import React from 'react';

export default function() {
	return (
		<svg width="18px" height="18px" viewBox="0 0 18 18">
			<g id="Global_Project" stroke="none" strokeWidth="1">
				<g id="05-SecProj-FloorPlan-FirstLv" transform="translate(-23.000000, -286.000000)">
					<g id="secondLevelNavFull-NoFPNoti">
						<g id="LeftNav-ProjectsExpanded">
							<g id="LeftNav" transform="translate(21.000000, 91.000000)">
								<g id="Settings" transform="translate(2.000000, 195.000000)">
									<path
										d="M8.59090909,5.14285714 C10.7259648,5.14285714 12.4568182,6.86978571 12.4568182,9 C12.4568182,11.1302143 10.7259648,12.8571429 8.59090909,12.8571429 C6.45585341,12.8571429 4.725,11.1302143 4.725,9 C4.725,6.86978571 6.45585341,5.14285714 8.59090909,5.14285714 L8.59090909,5.14285714 Z M6.87272727,0 L6.46337045,2.25 C5.51708182,2.547 4.66164205,3.03964286 3.93313295,3.67628571 L1.79206364,2.85278571 L-3.19744231e-14,5.77907143 L1.71818182,7.29921429 C1.58330455,7.84414286 1.51006705,8.4135 1.51006705,9 C1.51006705,9.5865 1.58330455,10.1560714 1.71818182,10.701 L-3.19744231e-14,12.2209286 L1.79206364,15.1474286 L3.93313295,14.3237143 C4.66164205,14.9603571 5.51708182,15.453 6.46337045,15.75 L6.87272727,18 L10.3090909,18 L10.7184477,15.75 C11.6647364,15.453 12.5201761,14.9603571 13.2486852,14.3237143 L15.3897545,15.1474286 L17.1818182,12.2209286 L15.4702943,10.6941429 C15.603883,10.1517857 15.6717511,9.58371429 15.6717511,9 C15.6717511,8.41628571 15.603883,7.84842857 15.4702943,7.30585714 L17.1818182,5.77907143 L15.3897545,2.85278571 L13.2486852,3.67628571 C12.5201761,3.03964286 11.6647364,2.547 10.7184477,2.25 L10.3090909,0 L6.87272727,0 Z"
										id="icFirstLevelNavSettings"
									/>
								</g>
							</g>
						</g>
					</g>
				</g>
			</g>
		</svg>
	);
}
