import { checkStatusJson } from 'apis/restUtils';
import { httpRequest } from 'apis/httpClient';
import errorHandler from 'actionUtils/errorHandler';
import { getProceqUrl } from 'utils/sessionHelper';
import URL from 'apis/urls';
import actionTypes from './actionTypes';

export function setKeyValue(value) {
	return dispatch => {
		dispatch({
			type: actionTypes.SET_KEY_VALUE,
			value,
		});
	};
}

export function startUserSession(value) {
	return {
		type: actionTypes.START_USER_SESSION,
		payload: value,
	};
}
export function changeCurrentProduct(value) {
	return {
		type: actionTypes.CHANGE_CURRENT_PRODUCT,
		payload: value,
	};
}
export function initUserSession(value) {
	return {
		type: actionTypes.INIT_USER_SESSION,
		payload: value,
	};
}

export function changeCurrentGroup(groupId) {
	return (dispatch, getState) => {
		const apiUrlFull = getProceqUrl(getState().session, URL.PROCEQ_CHANGE_ACTIVE_GROUP);
		return httpRequest(
			getState().session,
			getState().locale,
			apiUrlFull,
			'post',
			true,
			{ group_id: groupId },
			{},
			{}
		)
			.then(checkStatusJson)
			.then(json => {
				dispatch({
					type: actionTypes.CHANGE_CURRENT_GROUP,
					payload: json.data,
				});
			})
			.catch(err => {
				errorHandler(err);
			});
	};
}
// initMetadataRegister

export function changingProduct() {
	return {
		type: actionTypes.CHANGING_CURRENT_PRODUCT,
	};
}
export function initRecoverLastSesssion() {
	return {
		type: actionTypes.INIT_RECOVER_LAST_SESSION,
	};
}

export function initSharedTokenSession(sharedToken) {
	return {
		type: actionTypes.INIT_SHARED_PROCEQ_TOKEN_SESSION,
		payload: sharedToken,
	};
}
export function initOneTimeTokenSession(oneTimeToken) {
	return {
		type: actionTypes.INIT_ONETIME_PROCEQ_TOKEN_SESSION,
		payload: oneTimeToken,
	};
}
export function setSharedMeasurementsSession(productType, regionalDomain) {
	return {
		type: actionTypes.SET_SHARED_MEASUREMENTS_SESSION,
		payload: {
			productType,
			regionalDomain,
		},
	};
}
export function setAjaxParams(ajaxParams) {
	return {
		type: actionTypes.SET_AJAX_PARAMS,
		payload: {
			ajaxParams,
		},
	};
}
