// @flow
import React from 'react';
import classNames from 'classnames';
import { get } from 'lodash';
import { compose, withState } from 'recompose';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Popover, Icon } from 'antd';
import { FormattedMessage } from 'react-intl';
import { strings, lookupLang, getLang } from 'utils/localeHelper';
import { changeLanguage } from 'actions/locale';
import { getTextValue, getLabelFromGlobalDataKey } from 'utils/registryHelper';
import styles from './Header.styl';

// allow fallback to english
const lookupString = (lang: LangKey, string) => strings[lang].messages[string] || strings.en.messages[string];

export const LanguagesDropdown = ({
	currentLang,
	setVisible,
	product,
	changeLanguage: cl,
}: {
	currentLang: ShortLangKey,
	setVisible: Function,
	product: ProductCode,
	changeLanguage: Function,
}) => {
	const onClick = value => () => {
		setVisible(false);
		cl(getLang(value));
	};
	return (
		<div className="menu_container">
			<FormattedMessage id="App.LanguagesAllProducts" />
			{getTextValue(['languagescontacttds']).map(({ text, value }) => (
				<div
					className={classNames({
						[styles.menu_item]: true,
						[styles.active]: currentLang === getLang(value),
					})}
					key={value}
				>
					<a onClick={onClick(value)}>{lookupString(value, text)}</a>
				</div>
			))}
			<FormattedMessage id="App.LanguagesEQSHProducts" />
			{getTextValue(['availlanguages'])
				.filter(
					({ value }) =>
						!getTextValue(['languagescontacttds'])
							.map(l => l.value)
							.includes(value)
				)
				.map(({ text, value }) => (
					<div
						className={classNames({
							[styles.menu_item]: true,
							[styles.active]: currentLang === getLang(value),
						})}
						key={value}
					>
						<a
							style={{
								opacity: ['EQUOTIP', 'SCHMIDT'].includes(product) ? 1 : 0.5,
							}}
							onClick={onClick(value)}
						>
							{lookupString(value, text)}
						</a>
					</div>
				))}
		</div>
	);
};

const WrappedLangaugesDropdown = compose(
	connect(
		state => ({
			product: get(state, 'session.productType'),
		}),
		(dispatch: Function) => bindActionCreators({ changeLanguage }, dispatch)
	)
)(LanguagesDropdown);

export const Languages = ({
	lang,
	visible,
	setVisible,
}: {
	lang: ShortLangKey,
	visible: boolean,
	setVisible: Function,
}) => (
	<Popover
		placement="bottomRight"
		content={<WrappedLangaugesDropdown currentLang={lang} setVisible={setVisible} />}
		autoAdjustOverflow={false}
		trigger="click"
		visible={visible}
		onVisibleChange={setVisible}
	>
		<div>
			<Icon type="global" />{' '}
			{lookupString(lookupLang(lang), getLabelFromGlobalDataKey('availlanguages', lookupLang(lang)))}
		</div>
	</Popover>
);

export default compose(
	connect(state => ({
		lang: get(state, 'locale.shortlang', 'en'),
	})),
	withState('visible', 'setVisible', false)
)(Languages);
