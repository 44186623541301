// @flow
import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withState } from 'recompose';
import { Button, message, Modal, Form } from 'antd';
import { FormattedMessage, injectIntl } from 'react-intl';
import URL from 'apis/urls';
import { request } from 'actions/ajax';

import Select from 'components/shared/SmarterFormItems/Select';

export const Wipeout = ({
	wipeout,
	loading,
	onProductChange,
}: {
	wipeout: Function,
	loading: boolean,
	onProductChange: Function,
}) => {
	return (
		<div>
			<Form>
				<Form.Item label={<FormattedMessage id="App.Product" />}>
					<Select globalRegister={'latestavailproductsfull'} onChange={onProductChange} />
				</Form.Item>
			</Form>
			<Button type="primary" onClick={wipeout} loading={loading}>
				<FormattedMessage id="App.DevTools.Wipeout.Button" />
			</Button>
		</div>
	);
};

export default compose(
	withState('currProd', 'setCurrProd', ''),
	withState('loading', 'setLoading', false),
	injectIntl,
	connect(null, (dispatch: Function, props) => ({
		wipeout: () =>
			Modal.confirm({
				title: props.intl.formatMessage({ id: 'App.DevTools.Wipeout.Confirm' }),
				onOk: () =>
					dispatch(
						request({
							method: 'DELETE',
							url: URL.WIPEOUT_DATA,
							setLoading: props.setLoading,
							params: {
								product: props.currProd,
							},
						})
					).then(() =>
						message.success(
							props.intl.formatMessage({
								id: 'App.DevTools.Wipeout.Success',
							})
						)
					),
			}),
		onProductChange: val => {
			props.setCurrProd(val);
		},
	}))
)(Wipeout);
