// @flow
import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withState } from 'recompose';
import { Form, Input, Button, message } from 'antd';
import { FormattedMessage, injectIntl } from 'react-intl';
import type { InjectIntlProvidedProps } from 'react-intl';
import type { FormProps } from 'antd/lib/form';
import url from 'url';
import qs from 'qs';
import URL, { WORKSPACE_DOMAIN_TO_SUBDOMAIN_MAP } from 'apis/urls';
import { request } from 'actions/ajax';

const validateSharedUrl = sharedUrl => {
	const { host } = url.parse(sharedUrl);
	if (!host) {
		return false;
	}
	let subdomain = host.split('.')[0];

	const currentHost = window.location.host;
	const correctSubdomain = currentHost.split('.')[0];
	if (subdomain.includes('workspace')) {
		subdomain = WORKSPACE_DOMAIN_TO_SUBDOMAIN_MAP[subdomain];
	}

	return subdomain === correctSubdomain;
};
export const Renew = ({
	renewMeasurement,
	form,
	intl,
	loading,
}: {
	renewMeasurement: Function,
	form: FormProps,
	loading: boolean,
} & InjectIntlProvidedProps) => (
	<div>
		<Form onSubmit={renewMeasurement(form)}>
			<Form.Item
				style={{ marginTop: '8px' }}
				label={intl.formatMessage({
					id: 'App.DevTools.Renew.Label',
				})}
			>
				{form.getFieldDecorator('url', {
					rules: [
						{
							required: true,
						},
					],
				})(<Input />)}
			</Form.Item>
			<Button type="primary" htmlType="submit" loading={loading}>
				<FormattedMessage id="App.DevTools.Renew.Button" />
			</Button>
		</Form>
	</div>
);

export default compose(
	withState('loading', 'setLoading', false),
	injectIntl,
	connect(null, (dispatch: Function, props) => ({
		renewMeasurement: form => e => {
			e.preventDefault();
			form.validateFields((err, values) => {
				if (err) {
					return;
				}

				const { query } = url.parse(values.url);
				const { k, d } = qs.parse(query || '');

				if (!k || !d) {
					form.setFields({
						url: {
							value: values.url,
							errors: [new Error('Unsupported URL')],
						},
					});
					return;
				}

				if (!validateSharedUrl(values.url)) {
					form.setFields({
						url: {
							value: values.url,
							errors: [
								new Error(
									'Invalid url domain. Please confirm the input url is consistent with current env (dev/livedev/pro)'
								),
							],
						},
					});
					return;
				}

				dispatch(
					request({
						method: 'PUT',
						url: `https://${d}${URL.RENEW_SHARED_MEASUREMENT}?url=${encodeURIComponent(values.url)}`,
						setLoading: props.setLoading,
						errorHandler: error => {
							const msg = error.response.data.message;
							message.error(`Renew Failed. ${msg}`);
						},
					})
				).then(() => {
					message.success(
						props.intl.formatMessage({
							id: 'App.DevTools.Renew.Success',
						})
					);
				});
			});
		},
	})),
	Form.create({})
)(Renew);
