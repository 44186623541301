// @flow
import React, { Fragment } from 'react';
import { get } from 'lodash';
import { Table } from 'antd';
import { FormattedMessage } from 'react-intl';
import ConvertedUnits from 'components/shared/ConvertedUnits';
import { renderFolder } from 'utils/fileUtils';
import type { DerivedProps } from '../../../Measurement';
import styles from '../../../HTMLExport.styl';

export const title = (
	<Fragment>
		<FormattedMessage id="Proceq.ExportFieldSettings" />
		{' ­­» '}
		<FormattedMessage id="App.HTML.GPR.Settings.MeasurementPresets" />
	</Fragment>
);

export const MeasurementPresets = ({
	data,
	scanType,
	isMetric,
	convert,
}: { data: MeasurementFullData } & DerivedProps) => {
	const presets = [
		{
			label: 'App.HTML.GPR.MeasurementPresets.FileName',
			value: get(data, 'measurement.name'),
			key: 'fileName',
		},
		{
			label: 'App.HTML.GPR.MeasurementPresets.FileID',
			value: get(data, 'measurement.id'),
			key: 'fileId',
		},
		{
			label: 'Proceq.StatisticsFolder',
			value: <FormattedMessage id={renderFolder(data)} />,
			key: 'statisticsFolder',
		},
		{
			label: 'App.HTML.GPR.MeasurementPresets.Mode',
			value: get(data, 'measurement.type'),
			key: 'mode',
		},
		{
			label: 'App.Logbook.FDL.Item.velocity',
			unitId: 'PIT_IE.Settings Panel.Pulse Velocity',
			value: get(data, 'settings.0.content.processing.velocity'),
			key: 'velocity',
		},
	]
		.filter(row => row.enable === undefined || row.enable === true)
		.map(row => ({
			label: row.unitId ? (
				<ConvertedUnits id={row.label} unitId={row.unitId} scanType={scanType} isMetric={isMetric} />
			) : (
				<FormattedMessage id={row.label} />
			),
			value: row.unitId ? convert(row.value, row.unitId) : row.value,
			key: row.key,
		}));

	return (
		<Table
			title={() => <span className={styles.sub_header}>{title}</span>}
			className={styles.table}
			dataSource={presets}
			pagination={false}
			showHeader={false}
			size="small"
		>
			<Table.Column key="lable" dataIndex="label" className={styles.column} />
			<Table.Column key="value" dataIndex="value" />
		</Table>
	);
};

export default MeasurementPresets;
