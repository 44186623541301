// @flow
import React from 'react';
import { Table } from 'antd';
import { FormattedMessage } from 'react-intl';
import ConvertedUnits from 'components/shared/ConvertedUnits';
import type { DerivedProps } from 'components/measurements/HTMLExport/Measurement';
import styles from 'components/measurements/HTMLExport/HTMLExport.styl';
import { MeasurementScanType } from 'constants/exportConstants';
import { filterReadings } from '../GPR/ScanDistance';
import { renderComment } from '../GPR/Markers';
import { getLocationCoordinates, sortObjectData } from '../GPR/Objects';
import type { MeasurementObjectWithInfo } from '../GPR/Objects';

export const title = <FormattedMessage id="PointsOfInterest" />;

export const PointsOfInterest = ({
	data,
	scanType,
	isMetric,
	convert,
	showTitle,
}: { data: MeasurementFullData } & DerivedProps) => {
	if (scanType !== MeasurementScanType.FreePath) {
		return null;
	}

	const filteredReadings = filterReadings(data, scanType);
	const dataSource: MeasurementObjectWithInfo[] = data.objects
		.filter(object => object.type === 'marker' && object.content.textType === 'P')
		.map(object => {
			const reading = filteredReadings.find(r => r.id === object.rID);
			return {
				...object,
				...getLocationCoordinates(object),
				readingContent: reading ? reading.content : {},
				sequenceNo: reading ? reading.sequenceNo : 0,
			};
		})
		.sort(sortObjectData);

	return (
		<div className="table-markers">
			<Table
				title={showTitle ? () => <span className={styles.main_header}>{title}</span> : null}
				className={styles.table}
				columns={[
					{
						title: 'Line',
						render: (text, record: MeasurementObjectWithInfo) => {
							return record.sequenceNo;
						},
						width: 40,
					},
					{
						title: 'Point Number',
						render: (text: string, record: MeasurementObjectWithInfo) => {
							if (record.content.type === 'Audio') {
								return `V${record.content.number}`;
							}
							return `P${record.content.number}`;
						},
						width: 120,
					},
					{
						title: 'Easting',
						unitId: 'GPR_SOIL.CSV.LocationCoordinates',
						dataIndex: 'convertedCoordinateX',
						width: 120,
					},
					{
						title: 'Northing',
						unitId: 'GPR_SOIL.CSV.LocationCoordinates',
						dataIndex: 'convertedCoordinateY',
						width: 120,
					},
					{
						title: 'Comment',
						dataIndex: 'content.content',
						render: (text: string, record: MeasurementObjectWithInfo) => {
							return renderComment(text, record, data);
						},
					},
				].map(columnConfig => ({
					// eslint-disable-next-line no-unused-vars
					render: (text: string, record: MeasurementObjectWithInfo) =>
						columnConfig.unitId ? convert(text, columnConfig.unitId) : text,
					...columnConfig,
					title: columnConfig.unitId ? (
						<ConvertedUnits
							id={columnConfig.title}
							unitId={columnConfig.unitId}
							scanType={scanType}
							isMetric={isMetric}
						/>
					) : (
						<FormattedMessage id={columnConfig.title} />
					),
				}))}
				dataSource={dataSource}
				pagination={false}
				size="small"
				bordered
			/>
		</div>
	);
};

export default PointsOfInterest;
