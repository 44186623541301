// @flow
import React from 'react';
import { FormattedMessage } from 'react-intl';

export const Legend = ({ legendColor, legendTextId }: { legendColor?: string, legendTextId?: string }) => {
	return (
		<div
			style={{
				marginBottom: 10,
			}}
		>
			<div
				style={{
					display: 'inline-block',
					width: 40,
					height: 20,
					backgroundColor: legendColor === undefined ? 'orange' : legendColor,
				}}
			/>
			&nbsp;
			<span
				style={{
					verticalAlign: 'top',
				}}
			>
				<FormattedMessage
					id={legendTextId === undefined ? 'App.ContractsLegendSixtyDaysOrExpired' : legendTextId}
				/>
			</span>
		</div>
	);
};

export default Legend;
