// @flow
import React from 'react';
import type { InjectIntlProvidedProps } from 'react-intl';
import { injectIntl } from 'react-intl';
import handleFormatMessage from 'utils/handleFormatMessage';

export const TestLoad = ({ intl, log }: InjectIntlProvidedProps & EquotipLogbookEntryProps) => (
	<span>
		{log.content.oldTriggerLoadId
			? handleFormatMessage('Proceq.LogbookPropertyTestLoadChangedFromTo', intl.formatMessage, [
					`HV${log.content.oldTriggerLoadId}`,
					`HV${log.content.updatedTriggerLoadId}`,
			  ])
			: handleFormatMessage('Proceq.LogbookPropertyTestLoadChangedTo', intl.formatMessage, [
					`HV${log.content.updatedTriggerLoadId}`,
			  ])}
	</span>
);

export default injectIntl(TestLoad);
