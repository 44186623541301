// @flow
import React from 'react';
import type { InjectIntlProvidedProps } from 'react-intl';
import { injectIntl } from 'react-intl';
import { get } from 'lodash';

import handleFormatMessage from 'utils/handleFormatMessage';
import { getLabelFromGlobalDataKey } from 'utils/registryHelper';

export const ConversionCurve = ({ log, intl }: InjectIntlProvidedProps & EquotipLogbookEntryProps) => {
	const updatedConversionCustom = get(log, 'content.updatedCustomMaterialName');
	const updatedConversionId = getLabelFromGlobalDataKey(
		'convcurveschmidt',
		get(log, 'content.updatedConversionCurveId')
	);
	const updatedConversionName = updatedConversionId
		? intl.formatMessage({
				id: updatedConversionId,
		  })
		: updatedConversionCustom;

	const oldConversionCustom = get(log, 'content.oldCustomMaterialName');
	const oldConversionID = getLabelFromGlobalDataKey('convcurveschmidt', get(log, 'content.oldConversionCurveId'));
	const oldConversionName = oldConversionID
		? intl.formatMessage({
				id: oldConversionID,
		  })
		: oldConversionCustom;

	if (oldConversionName) {
		return (
			<span>
				{handleFormatMessage('LogbookPropertyConversionCurveChangedFromTo', intl.formatMessage, [
					oldConversionName,
					updatedConversionName,
				])}
			</span>
		);
	}

	return (
		<span>
			{handleFormatMessage('LogbookPropertyConversionCurveChangedTo', intl.formatMessage, [
				updatedConversionName,
			])}
		</span>
	);
};

export default injectIntl(ConversionCurve);
