// @flow
import React from 'react';
import classNames from 'classnames';
import { compose, withStateHandlers } from 'recompose';
import { FormattedMessage } from 'react-intl';
import { Radio } from 'antd';
import { Link } from 'react-router-dom';
import { enableCookies, deleteAllCookies } from 'utils/sessionHelper';
import LoginStyles from './LoginForm.styl';
import styles from './Cookies.styl';
import resetStyles from './ResetPasswordForm.styl';
import Alert from './Alert';

export const Cookies = ({ cookieState, changeCookieState }: { cookieState: boolean, changeCookieState: Function }) => (
	<div className={LoginStyles.form_wrapper}>
		<div className={classNames(resetStyles.heading_wrapper, styles.heading_wrapper)}>
			<h1 className={LoginStyles.heading}>
				<p>
					<FormattedMessage id="App.CookieHeader" />
				</p>
			</h1>
			<p className={styles.wordwrap}>
				<FormattedMessage id="App.CookieSubheader" />
			</p>
		</div>
		<div className={styles.body_wrapper}>
			<Alert severity="info" message={cookieState ? 'App.CookiesAllowed' : 'App.CookiesDisabled'} />
			<br />
			<Radio.Group onChange={changeCookieState} value={cookieState}>
				<Radio value={false} style={{ display: 'inline-block' }}>
					<span className={styles.bold}>
						<FormattedMessage id="App.DisableCookies" />
					</span>
					<div className={styles.wordwrap}>
						<FormattedMessage id="App.DisableCookiesWhy" />
					</div>
				</Radio>
				<Radio value>
					<span className={styles.bold}>
						<FormattedMessage id="App.AllowCookies" />
					</span>
					<div className={styles.wordwrap}>
						<FormattedMessage id="App.AllowCookiesWhy" />
					</div>
				</Radio>
			</Radio.Group>
			<Link to="/login">
				<FormattedMessage id="Proceq.LoginBackLogin" />
			</Link>
		</div>
	</div>
);

export default compose(
	withStateHandlers(
		{
			cookieState: document.cookie.length > 0,
		},
		{
			changeCookieState: () => e => {
				if (e.target.value) {
					enableCookies();
					return {
						cookieState: true,
					};
				}
				deleteAllCookies();
				return {
					cookieState: false,
				};
			},
		}
	)
)(Cookies);
