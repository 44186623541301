// @flow
import { withHighcharts, HighchartsChart, Chart, XAxis, YAxis, ColumnSeries, Tooltip } from 'react-jsx-highcharts';
import Highcharts from 'highcharts';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import type { InjectIntlProvidedProps } from 'react-intl';
import { Layout, Menu, Icon, Button, Badge, Col, Row, Modal, Select, notification } from 'antd';
// import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import './MainData.css';
import { checkStatusJson } from 'apis/restUtils';
import { httpRequest } from 'apis/httpClient';
import errorHandler from 'actionUtils/errorHandler';
import URL from 'apis/urls';
import { getProceqUrl, isUsingSharedToken } from 'utils/sessionHelper';

import { getLabelFromGlobalDataKey } from 'utils/registryHelper';
import {
	rgbToHex,
	roundNumberToNext,
	getResolutionForScale,
	getSchmidtScaleIdOfFromFactorAndUnitId,
	getScaleUnitsOfScaleId,
	getScaleNameOfScaleId,
	getStandardNameOfStandardId,
	getMaterialNameOfMaterialId,
	getSchmidtCompositeSurfaceId,
} from 'utils/conversionsHelper';
import handleFormatMessage from 'utils/handleFormatMessage';
import Logbook from './Logbook';
import SmartTable from '../../shared/SmartTable';
import colors from '../../../styles/colors.json';

type Props = {
	session: Session,
	locale: Locale,
} & InjectIntlProvidedProps;

type State = *;

type DataRow = {
	folder_name: string,
	client_create_date: number,
	datainfo: string, // JSON
	metadata: string,
	attachments: string, // JSON
};

export class MainData extends Component<Props, State> {
	static propTypes = {};

	_ismounted = false;

	isVerification = false;

	isCustomMaterialData = false;

	disableFolders = false;

	tabledata: any;

	tableFetchApiEndpoint = getProceqUrl(this.props.session, URL.PROCEQ_GET_MEASUREMENTS);

	styleColors = {
		NORMAL: [0, 0, 0],
		PROCEQ: [0, 132, 139],
		EXCLUDED: [184, 184, 184],
		NOCONVERSION: [170, 170, 170],
		OUTOFRANGE: [255, 127, 0],
		AREALIMITS: [0, 154, 225, 8],
		LINELIMITS: [209, 209, 209],
	};

	colModel: {
		[x: ProductCode]: Object[],
	} = {
		EQUOTIP: [
			{ title: 'Id', dataIndex: '_id', sorter: false, width: '20%', typeField: 'hidden' },
			{ title: 'TableDataSeriesName', dataIndex: 'dataSeriesName', sorter: true, filter: true },
			{ title: 'TableCreatedOn', dataIndex: 'createdTs', sorter: true, typeField: 'dateTimeNoSecs' },
			{ title: 'TableNumMean', dataIndex: 'mean', typeField: 'statistics-no0-avg', sorter: true },
			{ title: 'TableMaterial', dataIndex: 'material', globalRegister: 'materials', sorter: true, filter: true },
			{
				title: 'TableScaleUnit',
				dataIndex: 'scaleUnits',
				globalRegister: 'units',
				sendValueInsteadOfID: true,
				sorter: true,
				filter: true,
			},
			{ title: 'metadata', dataIndex: 'metadata', sorter: false, typeField: 'hidden' },
			{ title: 'datainfo', dataIndex: 'datainfo', sorter: true, typeField: 'hidden' },
		],
		SCHMIDT: [
			{ title: 'Id', dataIndex: '_id', sorter: false, width: '20%', typeField: 'hidden' },
			{ title: 'TableDataSeriesName', dataIndex: 'dataSeriesName', sorter: true, filter: true },
			{ title: 'TableCreatedOn', dataIndex: 'createdTs', sorter: true, typeField: 'dateTimeNoSecs' },
			{
				title: 'ExportFieldStandard',
				dataIndex: 'standard',
				globalRegister: 'standardsschmidt',
				sorter: true,
				filter: true,
			},
			{
				title: 'TableScaleUnit',
				dataIndex: 'scaleUnits',
				globalRegister: 'unitsschmidt',
				sendValueInsteadOfID: true,
				sorter: true,
				filter: true,
			},
			{ title: 'metadata', dataIndex: 'metadata', sorter: false, typeField: 'hidden' },
			{ title: 'datainfo', dataIndex: 'datainfo', sorter: true, typeField: 'hidden' },
		],
	};

	shareByUrlRows = (rowIds: number[]) => {
		this.setState({
			rowIdsShareDaysModal: [...rowIds],
			visibleShareDaysModal: true,
			loadingShareDaysModalRequest: false,
		});
	};

	basicActions: Object[] = [
		{
			actionId: 'sharebyurl',
			actionType: 'function',
			actionText: 'TitleShareByUrl',
			actionFunction: this.shareByUrlRows,
			multipleSelectedAllowed: true,
			oneSelectedNeeded: true,
			rolesNeeded: ['!guest_client', '*'],
		},
		{
			actionId: 'expand',
			actionType: 'expand',
			actionText: 'TableActionExpand',
			multipleSelectedAllowed: true,
			oneSelectedNeeded: true,
		},
		{
			actionId: 'collapse',
			actionType: 'collapse',
			actionText: 'TableActionCollapse',
			multipleSelectedAllowed: true,
			oneSelectedNeeded: true,
		},
		{
			actionGroupText: 'TextExport',
			actionGroupId: 'exportas',
			actionId: 'series_exportcsv',
			actionType: 'apicall',
			actionText: 'TableActionExportAsCSV',
			apiEndpoint: getProceqUrl(this.props.session, URL.PROCEQ_EXPORT_CSV_MEASUREMENTS),
			apiTypeMethod: 'post',
			apiSendToken: true,
			apiOneRequestForEachId: false,
			multipleSelectedAllowed: true,
			oneSelectedNeeded: true,
			apiSendFieldValues: {
				fieldSource: '_id',
				fieldTarget: 'data_series_ids',
				separateValue: 'comma',
			},
		},
		{
			actionGroupText: 'TextExport',
			actionGroupId: 'exportas',
			actionId: 'series_exportpdf',
			actionType: 'apicall',
			actionText: 'TableActionExportAsPDF',
			apiEndpoint: getProceqUrl(this.props.session, URL.PROCEQ_EXPORT_PDF_MEASUREMENTS),
			apiTypeMethod: 'post',
			apiSendToken: true,
			apiOneRequestForEachId: false,
			multipleSelectedAllowed: true,
			oneSelectedNeeded: true,
			apiSendFieldValues: {
				fieldSource: '_id',
				fieldTarget: 'data_series_ids',
				separateValue: 'comma',
			},
		},
		{
			actionGroupText: 'TextExport',
			actionGroupId: 'exportas',
			actionId: 'series_exportcsvdebug1',
			actionType: 'apicall',
			actionText: 'Export as CSV Debug',
			apiEndpoint: getProceqUrl(this.props.session, URL.PROCEQ_EXPORT_CSV_DEBUG_1_MEASUREMENTS),
			apiTypeMethod: 'post',
			apiSendToken: true,
			apiOneRequestForEachId: false,
			multipleSelectedAllowed: true,
			oneSelectedNeeded: true,
			apiSendFieldValues: {
				fieldSource: '_id',
				fieldTarget: 'data_series_ids',
				separateValue: 'comma',
			},
			rolesNeeded: ['admin', 'dev'],
		},
		{
			actionGroupText: 'TextExport',
			actionGroupId: 'exportas',
			actionId: 'series_exportcsvdebug2',
			actionType: 'apicall',
			actionText: 'Export as CSV Debug 2',
			apiEndpoint: getProceqUrl(this.props.session, URL.PROCEQ_EXPORT_CSV_DEBUG_2_MEASUREMENTS),
			apiTypeMethod: 'post',
			apiSendToken: true,
			apiOneRequestForEachId: false,
			multipleSelectedAllowed: true,
			oneSelectedNeeded: true,
			apiSendFieldValues: {
				fieldSource: '_id',
				fieldTarget: 'data_series_ids',
				separateValue: 'comma',
			},
			rolesNeeded: ['admin', 'dev'],
		},
	];

	specificActions: {
		[x: ProductCode]: Object[],
	} = {
		EQUOTIP: [],
		SCHMIDT: [
			{
				actionGroupText: 'TextExport',
				actionGroupId: 'exportas',
				actionId: 'series_exportpdf_regioneu',
				actionType: 'apicall',
				actionText: 'ButtonExportTestRegionEU',
				apiEndpoint: getProceqUrl(this.props.session, URL.PROCEQ_EXPORT_PDF_SCHMIDT_REGIONS_EU),
				apiTypeMethod: 'post',
				apiSendToken: true,
				apiOneRequestForEachId: false,
				multipleSelectedAllowed: true,
				oneSelectedNeeded: true,
				apiSendFieldValues: {
					fieldSource: '_id',
					fieldTarget: 'data_series_ids',
					separateValue: 'comma',
				},
			},
			{
				actionGroupText: 'TextExport',
				actionGroupId: 'exportas',
				actionId: 'series_exportpdf_regionchina',
				actionType: 'apicall',
				actionText: 'ButtonExportTestRegionChina',
				apiEndpoint: getProceqUrl(this.props.session, URL.PROCEQ_EXPORT_PDF_SCHMIDT_REGIONS_CHINA),
				apiTypeMethod: 'post',
				apiSendToken: true,
				apiOneRequestForEachId: false,
				multipleSelectedAllowed: true,
				oneSelectedNeeded: true,
				apiSendFieldValues: {
					fieldSource: '_id',
					fieldTarget: 'data_series_ids',
					separateValue: 'comma',
				},
			},
			{
				actionGroupText: 'TextExport',
				actionGroupId: 'exportas',
				actionId: 'series_exportpdf_regionuniformity',
				actionType: 'apicall',
				actionText: 'ButtonExportTestRegionUniformity',
				apiEndpoint: getProceqUrl(this.props.session, URL.PROCEQ_EXPORT_PDF_SCHMIDT_REGIONS_UNIFORMITY),
				apiTypeMethod: 'post',
				apiSendToken: true,
				apiOneRequestForEachId: false,
				multipleSelectedAllowed: true,
				oneSelectedNeeded: true,
				apiSendFieldValues: {
					fieldSource: '_id',
					fieldTarget: 'data_series_ids',
					separateValue: 'comma',
				},
			},
		],
	};

	state = {
		rowIdsShareDaysModal: [],
		daysUrlShare: 2,
		visibleShareDaysModal: false,
		loadingShareDaysModalRequest: false,
		currentFixedFilters: [],
		sortFoldersBy: 'name',
		sortFoldersAsc: true,
		sortNameAsc: true,
		sortCreatedAsc: true,
		selectedKeys: ['spec_allmeasurements'],
		showingFoldersInfo: [],
		apiSendBody: {},
		hideAll: false,
		expandedRowKeys: [],
	};

	componentWillMount() {
		this.loadListFolders();
		this.setState({
			currentFixedFilters: this.getFixedFiltersForFolder(this.state.selectedKeys[0]),
		});
	}

	componentDidMount() {
		this._ismounted = true;
	}

	componentWillUnmount() {
		this._ismounted = false;
	}

	componentWillReceiveProps() {
		this.setState(
			{
				hideAll: true,
			},
			() => {
				setTimeout(() => {
					if (this._ismounted) {
						this.setState(
							{
								hideAll: false,
								selectedKeys: ['spec_allmeasurements'],
								showingFoldersInfo: [],
								apiSendBody: {},
								currentFixedFilters: this.getFixedFiltersForFolder('spec_allmeasurements'),
							},
							() => {
								this.loadListFolders();
							}
						);
					}
				}, 10);
			}
		);
	}

	onSelectedMenu = (selectedInfo: Object) => {
		this.setState(
			{
				selectedKeys: selectedInfo.selectedKeys,
				currentFixedFilters: this.getFixedFiltersForFolder(selectedInfo.selectedKeys[0]),
			},
			() => {
				this.tabledata.refresh(true);
			}
		);
	};

	getFixedFiltersForFolder(folderId: string) {
		switch (folderId) {
			case 'spec_trash':
				return [
					{ field: 'flagTrash', op: 'eq', data: 1 },
					{ field: 'is_verification', op: 'eq', data: this.isVerification ? 1 : 0 },
				];
			case 'spec_favourite':
				return [
					{ field: 'flagFavourite', op: 'eq', data: 1 },
					{ field: 'flagTrash', op: 'eq', data: 0 },
					{ field: 'is_verification', op: 'eq', data: this.isVerification ? 1 : 0 },
				];
			case 'spec_allmeasurements':
				return [
					{ field: 'flagTrash', op: 'eq', data: 0 },
					{ field: 'is_verification', op: 'eq', data: this.isVerification ? 1 : 0 },
				];
			default:
				return [
					{ field: 'folderID', op: 'eq', data: folderId },
					{ field: 'flagTrash', op: 'eq', data: 0 },
					{ field: 'is_verification', op: 'eq', data: this.isVerification ? 1 : 0 },
				];
		}
	}

	loadListFolders() {
		if (this.disableFolders || isUsingSharedToken(this.props.session)) {
			return;
		}
		httpRequest(
			this.props.session,
			this.props.locale,
			getProceqUrl(this.props.session, URL.PROCEQ_GET_FOLDERS),
			'post',
			true,
			{},
			{},
			{}
		)
			.then(checkStatusJson)
			.then(json => {
				const allFolders = json.data.folders;
				const userFolders = allFolders.filter(
					folder =>
						folder.type !== 'spec_trash' &&
						folder.type !== 'spec_allmeasurements' &&
						folder.type !== 'spec_favourite'
				);
				if (this.state.sortFoldersBy === 'name') {
					if (this.state.sortFoldersAsc) {
						userFolders.sort(this.orderByNameAsc);
					} else {
						userFolders.sort(this.orderByNameDesc);
					}
				} else if (this.state.sortFoldersBy === 'created') {
					if (this.state.sortFoldersAsc) {
						userFolders.sort(this.orderByCreatedAsc);
					} else {
						userFolders.sort(this.orderByCreatedDesc);
					}
				}
				const typeFolderToAdd = ['spec_allmeasurements', 'spec_favourite', 'spec_trash'];
				for (let indexFolderType = 0; indexFolderType < typeFolderToAdd.length; indexFolderType++) {
					const thisTypeFolder = typeFolderToAdd[indexFolderType];
					for (let indexFolder = 0; indexFolder < allFolders.length; indexFolder++) {
						if (allFolders[indexFolder].type === thisTypeFolder) {
							userFolders.push(allFolders[indexFolder]);
						}
					}
				}
				// Add the All Measurements, Flagged and Trash folder always at the end
				this.setState({
					showingFoldersInfo: userFolders,
				});
			})
			.catch(err => {
				errorHandler(err);
			});
	}

	orderByNameAsc(a: DataRow, b: DataRow) {
		if (a.folder_name.toLowerCase() < b.folder_name.toLowerCase()) return -1;
		if (a.folder_name.toLowerCase() > b.folder_name.toLowerCase()) return 1;
		return 0;
	}

	orderByNameDesc(a: DataRow, b: DataRow) {
		if (a.folder_name.toLowerCase() > b.folder_name.toLowerCase()) return -1;
		if (a.folder_name.toLowerCase() < b.folder_name.toLowerCase()) return 1;
		return 0;
	}

	orderByCreatedAsc(a: DataRow, b: DataRow) {
		if (a.client_create_date < b.client_create_date) return -1;
		if (a.client_create_date > b.client_create_date) return 1;
		return 0;
	}

	orderByCreatedDesc(a: DataRow, b: DataRow) {
		if (a.client_create_date > b.client_create_date) return -1;
		if (a.client_create_date < b.client_create_date) return 1;
		return 0;
	}

	handleModeChange = (e: any) => {
		const sortFoldersBy = e.target.value;
		this.setState({ sortFoldersBy }, () => {
			this.loadListFolders();
		});
	};

	handleTabClick(tabClicked: string) {
		let { sortFoldersBy, sortFoldersAsc, sortNameAsc, sortCreatedAsc } = this.state;
		if (sortFoldersBy === tabClicked) {
			sortFoldersAsc = !sortFoldersAsc;
			if (sortFoldersBy === 'created') sortCreatedAsc = !sortCreatedAsc;
			else sortNameAsc = !sortNameAsc;
		} else {
			sortFoldersBy = tabClicked;
			if (sortFoldersBy === 'created') sortFoldersAsc = sortCreatedAsc;
			else sortFoldersAsc = sortNameAsc;
		}

		this.setState({ sortFoldersBy, sortFoldersAsc, sortNameAsc, sortCreatedAsc }, () => {
			this.loadListFolders();
		});
	}

	handleOkShareDaysModal = () => {
		const { intl } = this.props,
			{ formatMessage } = intl;
		this.setState(
			{
				loadingShareDaysModalRequest: true,
			},
			() => {
				return httpRequest(
					this.props.session,
					this.props.locale,
					getProceqUrl(this.props.session, URL.PROCEQ_SHARE_BY_URL),
					'post',
					true,
					{ data_ids: this.state.rowIdsShareDaysModal.join(','), expiration: this.state.daysUrlShare },
					{},
					{}
				)
					.then(checkStatusJson)
					.then(json => {
						notification.open({
							className: 'url-share-notification',
							message: handleFormatMessage('ErrorDisplayTitleInfo', formatMessage),
							description: (
								<div
									style={{ width: 400 }}
									// eslint-disable-next-line react/no-danger
									dangerouslySetInnerHTML={{
										__html: handleFormatMessage('TextSharingSeriesUrlLinkCreated', formatMessage, [
											json.data.url,
											`window.copyTextToClipboard('${json.data.url}')`,
										]),
									}}
								/>
							), // 'This is the content of the notification. This is the content of the notification. This is the content of the notification.',
							icon: <Icon type="cloud-download" style={{ color: '#108ee9' }} />,
							placement: 'bottomRight',
							duration: 60,
						});

						this.setState({
							loadingShareDaysModalRequest: false,
							visibleShareDaysModal: false,
						});
						// this.utilsService.translateString('TextSharingSeriesUrlLinkCreated', [resultData.url, "window.copyTextToClipboard('" + resultData.url + "')"]),
					})
					.catch(err => {
						this.setState({
							loadingShareDaysModalRequest: false,
						});
						errorHandler(err);
					});
			}
		);
	};

	handleCancelShareDaysModal = () => {
		this.setState({
			visibleShareDaysModal: false,
			loadingShareDaysModalRequest: false,
		});
	};

	handleChangeShareDaysModal = (newValue: number) => {
		this.setState({
			daysUrlShare: newValue,
		});
	};

	render() {
		const { intl } = this.props,
			{ formatMessage } = intl;
		const columns = this.colModel[this.props.session.productType];
		let allExtraActions = [...this.basicActions];
		if (this.props.session.productType in this.specificActions) {
			allExtraActions = allExtraActions.concat(this.specificActions[this.props.session.productType]);
		}

		if (this.state.hideAll) {
			return <div> </div>;
		}

		return (
			<div>
				<Layout style={{ padding: '0px 0', background: colors.pale_gray }}>
					{!this.disableFolders && !isUsingSharedToken(this.props.session) ? (
						<Layout.Sider style={{ background: colors.pale_gray }} className="folders_div">
							<Button.Group size="smaller">
								<Button
									type="primary"
									onClick={() => {
										this.handleTabClick('created');
									}}
									className={this.state.sortFoldersBy === 'created' ? '' : 'inactive'}
								>
									{' '}
									{handleFormatMessage('StatisticsCreated', formatMessage)}{' '}
									<Icon type={this.state.sortCreatedAsc ? 'caret-up' : 'caret-down'} />
								</Button>
								<Button
									type="primary"
									onClick={() => {
										this.handleTabClick('name');
									}}
									className={this.state.sortFoldersBy === 'name' ? '' : 'inactive'}
								>
									{' '}
									{handleFormatMessage('ExportColumnName', formatMessage)}{' '}
									<Icon type={this.state.sortNameAsc ? 'caret-up' : 'caret-down'} />
								</Button>
							</Button.Group>

							<Menu
								mode="inline"
								style={{ height: '100%' }}
								onSelect={this.onSelectedMenu}
								defaultSelectedKeys={this.state.selectedKeys}
							>
								{this.state.showingFoldersInfo.map(folderInfo => {
									let iconForfolder = 'folder';
									switch (folderInfo.type) {
										case 'spec_allmeasurements':
											iconForfolder = 'file';
											break;
										case 'spec_trash':
											iconForfolder = 'delete';
											break;
										case 'spec_favourite':
											iconForfolder = 'flag';
											break;
									}
									return (
										<Menu.Item key={folderInfo.folder_id}>
											<Icon type={iconForfolder} />
											<span className="nav-text">{folderInfo.folder_name}</span>
											<Badge count={folderInfo.count} overflowCount={999999} showZero />
										</Menu.Item>
									);
								}, this)}
							</Menu>
						</Layout.Sider>
					) : (
						''
					)}

					<Layout.Content style={{ padding: '0 24px', minHeight: 280 }}>
						<div>
							<SmartTable
								apiEndpoint={this.tableFetchApiEndpoint}
								apiTypeMethod={'post'}
								apiSendToken
								apiSendBody={this.state.apiSendBody}
								columns={columns}
								columnKey={'_id'}
								onRef={ref => {
									this.tabledata = ref;
								}}
								typeSortingFiltering={'proceq'}
								typeRetrieveData={'proceq'}
								fixedFilters={this.state.currentFixedFilters}
								extraActions={allExtraActions}
								expandedRowRender={this.renderExpandedRow}
								expandedRowKeys={this.state.expandedRowKeys}
								onRefreshCall={this.onTableRefresh}
							/>
						</div>
					</Layout.Content>
				</Layout>
				<Modal
					visible={this.state.visibleShareDaysModal}
					title={handleFormatMessage('TitleShareByUrl', formatMessage)}
					onOk={this.handleOkShareDaysModal}
					onCancel={this.handleCancelShareDaysModal}
					footer={[
						<Button
							key="submit"
							type="primary"
							size="large"
							loading={this.state.loadingShareDaysModalRequest}
							onClick={this.handleOkShareDaysModal}
						>
							{handleFormatMessage('ButtonSubmit', formatMessage)}
						</Button>,
					]}
				>
					<div className="share-url-days-modal">
						<div className="share-url-days-label">
							{handleFormatMessage('FormExpiresIn', formatMessage)}
						</div>
						<Select
							defaultValue={this.state.daysUrlShare}
							onChange={this.handleChangeShareDaysModal}
							showSearch
							id="daysUrlShare"
							style={{ width: 200 }}
						>
							<Select.Option key={'1'}>{1}</Select.Option>
							<Select.Option key={'2'}>{2}</Select.Option>
							<Select.Option key={'3'}>{3}</Select.Option>
						</Select>
					</div>
				</Modal>
			</div>
		);
	}

	onTableRefresh = () => {
		this.loadListFolders();
	};

	renderExpandedRow = (record: Object) => {
		const { intl } = this.props,
			{ formatMessage } = intl;
		const thisId = record._id;
		const allRowUncompressedData = this.getSeriesDataFromRow(record);
		if (allRowUncompressedData === null) {
			return (
				<div>
					<Row>
						<i>{'No data available'}</i>
					</Row>
				</div>
			);
		}

		const currentData = allRowUncompressedData.datainfoData;
		const metadata = allRowUncompressedData.metadata;
		const datainfo = allRowUncompressedData.datainfo;
		const allParsedDataNeeded = this.getAllParsedDataNeeded(
			datainfo,
			metadata,
			currentData,
			this.props.session.productType
		);

		if (allParsedDataNeeded === null || currentData === null) {
			return (
				<div>
					<Row>
						<i>{'No data available'}</i>
					</Row>
				</div>
			);
		}

		const infoIndexes = allParsedDataNeeded.infoIndexes;
		const infoForDataMeasuremets = allParsedDataNeeded.infoForDataMeasuremets;
		const thresholdLoValue = allParsedDataNeeded.thresholdLoValue;
		const thresholdHiValue = allParsedDataNeeded.thresholdHiValue;
		const units = allParsedDataNeeded.units;
		const mainScale = allParsedDataNeeded.mainScale;
		const isVerificationMode = this.isVerification;

		const dataForGraph = this.getDataForGraph(
			currentData,
			infoForDataMeasuremets,
			infoIndexes,
			thresholdLoValue,
			thresholdHiValue
		);
		let maxValueFound = dataForGraph.maxValueFound;
		let minValueFound = dataForGraph.minValueFound;
		/* maxValueFound = maxValueFound * 1.05;
		minValueFound = minValueFound - (maxValueFound * 0.05); */
		if (minValueFound < 0) minValueFound = 0;
		minValueFound = roundNumberToNext(minValueFound, 'down');
		maxValueFound = roundNumberToNext(maxValueFound, 'up');

		const plotOptions = {
			column: {
				colorByPoint: true,
				marker: {
					enabled: false,
				},
			},
		};
		function formatter() {
			if (isNaN(this.y)) {
				return `<b>${this.x}</b><br/><span style="color:${this.points[0].color}">${handleFormatMessage(
					'ExportFieldNoConversion',
					formatMessage
				)}</span>`;
			}

			return `<b>${this.x}</b><br/><span style="color:${this.points[0].color}">${getResolutionForScale(
				units,
				this.y
			)} ${units}</span>`;
		}
		let plotBands = [];
		let plotLines = [];

		if (thresholdLoValue !== null && thresholdHiValue !== null) {
			plotBands = [
				{
					// Light air
					from: thresholdLoValue,
					to: thresholdHiValue,
					color: 'rgba(68, 170, 213, 0.1)',
				},
			];

			plotLines = [
				{
					color: 'rgba(209, 209, 209, 1)', // Color value
					dashStyle: 'shortdash', // Style of the plot line. Default to solid
					value: thresholdLoValue, // Value of where the line will appear
					width: 2, // Width of the line
				},
				{
					color: 'rgba(209, 209, 209, 1)', // Color value
					dashStyle: 'shortdash', // Style of the plot line. Default to solid
					value: thresholdHiValue, // Value of where the line will appear
					width: 2, // Width of the line
				},
			];
		}

		let graphHTML = null;
		let measurementsHTML = null;
		if (dataForGraph.data.length > 0) {
			graphHTML = (
				<Col xs={16} lg={18}>
					<div style={{ maxWidth: '500px', margin: '0 auto' }}>
						<HighchartsChart plotOptions={plotOptions}>
							<Chart backgroundColor={null} />

							{/* <Title>Solar Employment Growth by Sector, 2010-2016</Title> */}
							{/* <Subtitle>Source: thesolarfoundation.com</Subtitle> */}
							{/* <Legend layout="vertical" align="right" verticalAlign="middle" /> */}

							<Tooltip formatter={formatter} shared />

							<XAxis categories={dataForGraph.xAxis} />

							<YAxis
								id="number"
								plotBands={plotBands}
								plotLines={plotLines}
								min={minValueFound}
								max={maxValueFound}
							>
								<YAxis.Title>{units}</YAxis.Title>
								<ColumnSeries
									id="installation"
									name="Installation"
									data={dataForGraph.data}
									colors={dataForGraph.colors}
								/>
							</YAxis>
						</HighchartsChart>
					</div>
				</Col>
			);
			measurementsHTML = this.doMeasurementsDataColumn(dataForGraph, units);
		}
		const statisticsHTML = this.doExtraInfoColumn(
			this.getStatisticsParametersForProduct(mainScale, this.props.session.productType),
			'ExportFieldStatistics',
			infoForDataMeasuremets
		);
		const settingsHTML = this.doExtraInfoColumn(
			this.getSettingsParametersForProduct(mainScale, this.props.session.productType, isVerificationMode),
			'ExportFieldSettings',
			infoForDataMeasuremets
		);
		const logBookHTML = (
			<Col span={24}>
				<div className="container-logbook-button">
					<Logbook
						dataSeriesId={thisId}
						isVerificationMode={isVerificationMode}
						productType={this.props.session.productType}
					/>
				</div>
			</Col>
		);

		if (graphHTML && measurementsHTML) {
			return (
				<div>
					<Row>
						{graphHTML}
						{measurementsHTML}
						{statisticsHTML}
						{settingsHTML}
						{logBookHTML}
					</Row>
				</div>
			);
		}

		return (
			<div>
				<Row>
					{statisticsHTML}
					{settingsHTML}
					{logBookHTML}
				</Row>
			</div>
		);
	};

	getSeriesDataFromRow = (row: DataRow) => {
		if (row.datainfo === '' || row.metadata === '') {
			return null;
		}

		const metadata = row.metadata;
		const attachments = JSON.parse(row.attachments);
		const datainfo = JSON.parse(row.datainfo);

		let currentData = datainfo != null && 'data' in datainfo ? datainfo.data : null;
		const compressionMethods = datainfo != null && 'compression' in datainfo ? datainfo.compression : null;
		let alreadyUngzip = false;

		if (currentData && compressionMethods) {
			for (let compressionIndex = compressionMethods.length - 1; compressionIndex >= 0; compressionIndex--) {
				const thisCompressionMethod = compressionMethods[compressionIndex];
				switch (thisCompressionMethod) {
					case 'json':
						currentData = JSON.parse(currentData);

						break;
					case 'gzip':
						if (!alreadyUngzip) {
							currentData = window.JXG.decompress(currentData);
							alreadyUngzip = true;
						}

						break;
					case 'base64':
						if (compressionMethods.indexOf('gzip') >= 0 && !alreadyUngzip) {
							currentData = window.JXG.decompress(currentData);
							alreadyUngzip = true;
						} else {
							atob(currentData);
						}
						break;
				}
			}
		}

		return {
			datainfoData: currentData,
			metadata,
			attachments,
			datainfo,
		};
	};

	getAllParsedDataNeeded(datainfo: ?Object, metadata: string, currentData: Object, productType: ProductCode) {
		const { intl } = this.props,
			{ formatMessage } = intl;

		if (!datainfo) {
			return null;
		}

		const pointLabelIndex = datainfo.description.indexOf('pointLabel');
		const primaryValueIndex = datainfo.description.indexOf('primaryValue');
		const secondaryValueIndex = datainfo.description.indexOf('secondaryValue');
		const removedValueIndex = datainfo.description.indexOf('removed');
		const outlierTypeValueIndex = datainfo.description.indexOf('outlierType'); /* CHANGED FOR SCHMIDT */

		const primarySchmidtScaleId = getSchmidtScaleIdOfFromFactorAndUnitId(null, null);
		const primaryEquotipScaleId = 2;

		// Show Settings and Statistics
		const thisMeasurementObject = JSON.parse(metadata);
		if (!thisMeasurementObject) return null;

		thisMeasurementObject.dataInfo = datainfo;
		thisMeasurementObject.dataInfo.dataUncompressed = currentData;

		const infoForDataMeasuremets: Object = {};

		infoForDataMeasuremets.MISCELLANEOUS = {
			EXPORTDATE: {
				TITLE: handleFormatMessage('ExportFieldExportDate', formatMessage),
			},
		};

		/* Init scales data */
		infoForDataMeasuremets.SCALES = {
			PRIMARY: {},
			SECONDARY: {},
		};

		let isVerificationSerie = false;
		if (
			'properties' in thisMeasurementObject &&
			'seriesType' in thisMeasurementObject.properties &&
			thisMeasurementObject.properties.seriesType === 'verificationSeries'
		) {
			isVerificationSerie = true;
		}

		// Get the table data
		let units = '';
		// secondary not on the first position, so it will replace some values taken from the primary like the limits, etc..
		let tablesIndexToCheck = ['primary', 'secondary'];
		if (isVerificationSerie) {
			tablesIndexToCheck = ['primary'];
		}
		for (let indexTableCheck = 0; indexTableCheck < tablesIndexToCheck.length; indexTableCheck++) {
			const thisTableIndex = tablesIndexToCheck[indexTableCheck];

			/* CHANGED FOR SCHMIDT */
			switch (productType) {
				case 'EQUOTIP':
					units = thisMeasurementObject.settings[thisTableIndex].unit;
					break;
				case 'SSL':
				case 'SCHMIDT': {
					const thisScaleId = getSchmidtScaleIdOfFromFactorAndUnitId(
						thisMeasurementObject.settings[thisTableIndex].unitId,
						thisMeasurementObject.settings[thisTableIndex].formFactorId
					);
					units = getScaleUnitsOfScaleId(productType, formatMessage, thisScaleId);
					break;
				}
			}

			if (
				'settings' in thisMeasurementObject &&
				thisTableIndex in thisMeasurementObject.settings &&
				thisMeasurementObject.settings[thisTableIndex] !== null
			) {
				const thisTableInfo = {
					TYPE:
						'scaleId' in thisMeasurementObject.settings[thisTableIndex]
							? getScaleNameOfScaleId(
									productType,
									formatMessage,
									thisMeasurementObject.settings[thisTableIndex].scaleId
							  )
							: getScaleNameOfScaleId(productType, formatMessage, 2),
					TITLE: thisMeasurementObject.settings[thisTableIndex].unit,
					THRESHOLDS: [],
					POINTS: [],
				};
				if (
					'limits' in thisMeasurementObject.settings[thisTableIndex] &&
					'low' in thisMeasurementObject.settings[thisTableIndex].limits &&
					thisMeasurementObject.settings[thisTableIndex].limits.low !== null
				) {
					thisTableInfo.THRESHOLDS.push({
						TITLE: 'LOWERLIMIT',
						VALUE: getResolutionForScale(
							units,
							thisMeasurementObject.settings[thisTableIndex].limits.low,
							'low'
						),
					});
				}
				if (
					'limits' in thisMeasurementObject.settings[thisTableIndex] &&
					'high' in thisMeasurementObject.settings[thisTableIndex].limits &&
					thisMeasurementObject.settings[thisTableIndex].limits.high !== null
				) {
					thisTableInfo.THRESHOLDS.push({
						TITLE: 'UPPERLIMIT',
						VALUE: getResolutionForScale(
							units,
							thisMeasurementObject.settings[thisTableIndex].limits.high,
							'high'
						),
					});
				}

				infoForDataMeasuremets.MISCELLANEOUS.LIMITS = {
					TITLE: handleFormatMessage('ExportFieldLimits', formatMessage),
					VALUE: `${handleFormatMessage('ExportFieldHigh', formatMessage)}: ${
						'limits' in thisMeasurementObject.settings[thisTableIndex] &&
						'high' in thisMeasurementObject.settings[thisTableIndex].limits &&
						thisMeasurementObject.settings[thisTableIndex].limits.high !== null
							? `${getResolutionForScale(
									units,
									thisMeasurementObject.settings[thisTableIndex].limits.high,
									'high'
							  )} ${units}`
							: handleFormatMessage('ExportFieldOff', formatMessage)
					} , ${handleFormatMessage('ExportFieldLow', formatMessage)}: ${
						'limits' in thisMeasurementObject.settings[thisTableIndex] &&
						'low' in thisMeasurementObject.settings[thisTableIndex].limits &&
						thisMeasurementObject.settings[thisTableIndex].limits.low !== null
							? `${getResolutionForScale(
									units,
									thisMeasurementObject.settings[thisTableIndex].limits.low,
									'low'
							  )} ${units}`
							: handleFormatMessage('ExportFieldOff', formatMessage)
					}`,
				};

				infoForDataMeasuremets.MISCELLANEOUS.LOWERLIMIT = {
					TITLE: handleFormatMessage('ExportFieldLowerLimit', formatMessage),
					VALUE:
						'limits' in thisMeasurementObject.settings[thisTableIndex] &&
						'low' in thisMeasurementObject.settings[thisTableIndex].limits &&
						thisMeasurementObject.settings[thisTableIndex].limits.low !== null
							? getResolutionForScale(
									units,
									thisMeasurementObject.settings[thisTableIndex].limits.low,
									'low'
							  )
							: null,
				};
				infoForDataMeasuremets.MISCELLANEOUS.LOWERLIMITWITHUNITS = {
					TITLE: handleFormatMessage('ExportFieldLowerLimit', formatMessage),
					VALUE:
						infoForDataMeasuremets.MISCELLANEOUS.LOWERLIMIT.VALUE !== null
							? `${infoForDataMeasuremets.MISCELLANEOUS.LOWERLIMIT.VALUE} ${units}`
							: handleFormatMessage('ExportFieldOff', formatMessage),
				};

				infoForDataMeasuremets.MISCELLANEOUS.UPPERLIMIT = {
					TITLE: handleFormatMessage('ExportFieldUpperLimit', formatMessage),
					VALUE:
						'limits' in thisMeasurementObject.settings[thisTableIndex] &&
						'high' in thisMeasurementObject.settings[thisTableIndex].limits &&
						thisMeasurementObject.settings[thisTableIndex].limits.high !== null
							? getResolutionForScale(
									units,
									thisMeasurementObject.settings[thisTableIndex].limits.high,
									'high'
							  )
							: null,
				};
				infoForDataMeasuremets.MISCELLANEOUS.UPPERLIMITWITHUNITS = {
					TITLE: handleFormatMessage('ExportFieldUpperLimit', formatMessage),
					VALUE:
						infoForDataMeasuremets.MISCELLANEOUS.UPPERLIMIT.VALUE !== null
							? `${infoForDataMeasuremets.MISCELLANEOUS.UPPERLIMIT.VALUE} ${units}`
							: handleFormatMessage('ExportFieldOff', formatMessage),
				};

				/* Search for Columsn Index: pointLabel and primaryVal or secondaryVal */
				let indexDataColumnForPointLabel = null;
				let indexDataColumnForPointVal = null;
				thisMeasurementObject.dataInfo.description.forEach((valueColumn, indexColumn) => {
					if (valueColumn === 'pointLabel') {
						indexDataColumnForPointLabel = indexColumn;
					}
					if (valueColumn === `${thisTableIndex}Value`) {
						indexDataColumnForPointVal = indexColumn;
					}
				});

				if (indexDataColumnForPointLabel === null || indexDataColumnForPointVal === null) {
					// throw new Exception('ErrorDataFormat');
				}

				/* Loop trhough the whole date and take the label and point values */

				thisMeasurementObject.dataInfo.dataUncompressed.forEach(thisDataRow => {
					thisTableInfo.POINTS.push({
						TITLE: thisDataRow[indexDataColumnForPointLabel],
						VALUE: thisDataRow[indexDataColumnForPointVal], // number_format($thisDataRow[$indexDataColumnForPointVal], 1)
					});
				});

				/* Add the statistics */
				const statisitcsToDisplay = ['count', 'stdev', 'range', 'relSpan', 'max', 'min', 'avg'];
				const thisScaleStatistics = {};

				statisitcsToDisplay.forEach((keyStat /* indexStat, valStat */) => {
					let valStat = '--';
					let thisNotDefined = true;
					if (
						keyStat in thisMeasurementObject.statistics[thisTableIndex] &&
						thisMeasurementObject.statistics[thisTableIndex][keyStat] !== null
					) {
						valStat = thisMeasurementObject.statistics[thisTableIndex][keyStat];
						thisNotDefined = false;
					}
					let thisLabelStatistics = keyStat;
					let thisValueStatistics = `${valStat}`; // number_format($valStat, 1);
					const indexForArray = keyStat.toUpperCase();
					const unitToUseThis = units;
					valStat = getResolutionForScale(units, valStat, keyStat);
					switch (keyStat) {
						case 'count':
							thisLabelStatistics = 'ExportFieldReadings';
							thisValueStatistics = `${valStat}`; // intval($valStat);

							break;
						case 'stdev':
							if (!thisNotDefined) {
								thisValueStatistics = `${valStat} ${unitToUseThis}`;
							}
							thisLabelStatistics = 'ExportFieldStandardDeviation'; // 'σ';

							break;
						case 'range':
							if (!thisNotDefined) {
								thisValueStatistics = `${valStat} ${unitToUseThis}`;
							}

							thisLabelStatistics = 'ExportFieldRange';

							break;
						case 'relSpan':
							if (!thisNotDefined) {
								thisValueStatistics = `${valStat}%`;
							}

							thisLabelStatistics = 'ExportFieldRelativeSpan';

							break;
						case 'max':
							if (!thisNotDefined) {
								thisValueStatistics = `${valStat} ${unitToUseThis}`;
							}

							thisLabelStatistics = 'ExportFieldMaximum';

							break;
						case 'min':
							if (!thisNotDefined) {
								thisValueStatistics = `${valStat} ${unitToUseThis}`;
							}

							thisLabelStatistics = 'ExportFieldMinimum';

							break;
						case 'avg':
							if (!thisNotDefined) {
								thisValueStatistics = `${valStat} ${unitToUseThis}`;
							}

							thisLabelStatistics = 'ExportFieldAverage';
							break;
					}
					thisScaleStatistics[indexForArray] = {
						TITLE: thisLabelStatistics,
						VALUE: thisValueStatistics,
					};
				});

				/* CHANGED FOR SCHMIDT */
				switch (productType) {
					case 'EQUOTIP':
						infoForDataMeasuremets.SCALES[thisTableIndex.toUpperCase()] = {
							TABLE: thisTableInfo,
							STATISTICS: thisScaleStatistics,
							UNIT: {
								TITLE: 'ExportFieldUnit',
								VALUE: thisMeasurementObject.settings[thisTableIndex].unit,
							},
							NAME: {
								TITLE: 'ExportFieldName',
								VALUE:
									'scaleId' in thisMeasurementObject.settings[thisTableIndex]
										? getScaleNameOfScaleId(
												productType,
												formatMessage,
												thisMeasurementObject.settings[thisTableIndex].scaleId
										  )
										: getScaleNameOfScaleId(productType, formatMessage, primaryEquotipScaleId),
							},
						};

						break;
					case 'SSL':
					case 'SCHMIDT': {
						const scaleIdUsed =
							'unitId' in thisMeasurementObject.settings[thisTableIndex]
								? getSchmidtScaleIdOfFromFactorAndUnitId(
										thisMeasurementObject.settings[thisTableIndex].unitId,
										thisMeasurementObject.settings[thisTableIndex].formFactorId
								  )
								: primarySchmidtScaleId;

						infoForDataMeasuremets.SCALES[thisTableIndex.toUpperCase()] = {
							TABLE: thisTableInfo,
							STATISTICS: thisScaleStatistics,
							UNIT: {
								TITLE: 'ExportFieldUnit',
								VALUE: getScaleUnitsOfScaleId(productType, formatMessage, scaleIdUsed),
							},
							NAME: {
								TITLE: 'ExportFieldName',
								VALUE: getScaleNameOfScaleId(productType, formatMessage, scaleIdUsed),
							},
						};
						break;
					}
				}
			}
		}

		/* Add the properties data */
		if ('properties' in thisMeasurementObject) {
			infoForDataMeasuremets.PROPERTIES = {
				SERIESNAME: {
					TITLE: '',
					VALUE:
						'seriesName' in thisMeasurementObject.properties
							? thisMeasurementObject.properties.seriesName
							: null,
				},
				/* 'FOLDERNAME' : {
				 'TITLE':"Folder name",
				 'VALUE':thisMeasurementObject["properties"]["folderName"]
				 }, */
				MEASUREMENTDATE: {
					TITLE: 'ExportFieldMeasurementDate',
					VALUE:
						'created' in thisMeasurementObject.properties ? thisMeasurementObject.properties.created : null,
				},
				LOCATIONLAT: {
					TITLE: 'ExportFieldLocationLatitude',
					VALUE:
						'lat' in thisMeasurementObject.properties
							? thisMeasurementObject.properties.location.lat
							: null,
				},
				LOCATIONLONG: {
					TITLE: 'ExportFieldLocationLongitude',
					VALUE:
						'long' in thisMeasurementObject.properties
							? thisMeasurementObject.properties.location.long
							: null,
				},
				PROBENAME: {
					TITLE: 'ExportFieldProbeType',
					VALUE:
						'name' in thisMeasurementObject.properties ? thisMeasurementObject.properties.probe.name : null,
				},
				PROBESERIALNUMBER: {
					TITLE: 'ExportFieldProbeSerialNumber',
					VALUE:
						'serialNumber' in thisMeasurementObject.properties
							? thisMeasurementObject.properties.probe.serialNumber
							: null,
				},
				PROBEBTSERIALNUMBER: {
					TITLE: 'ExportFieldProbeBTSerialNumber',
					VALUE:
						'btSerialNumber' in thisMeasurementObject.properties
							? thisMeasurementObject.properties.probe.btSerialNumber
							: null,
				},
				PROBELASTVERIFICATIONDATE: {
					TITLE: 'ExportFieldProbeLastVerificationDate',
					VALUE:
						'lastVerificationDate' in thisMeasurementObject.properties
							? thisMeasurementObject.properties.probe.lastVerificationDate
							: null,
				},
			};
		}

		if ('settings' in thisMeasurementObject) {
			/* CHANGED FOR SCHMIDT */
			const settings: Object = {
				STANDARD: {
					TITLE: 'ExportFieldStandard',
					VALUE:
						'standardId' in thisMeasurementObject.settings
							? getStandardNameOfStandardId(
									null,
									formatMessage,
									thisMeasurementObject.settings.standardId,
									productType
							  )
							: getStandardNameOfStandardId(null, formatMessage, 1, productType),
				},
			};

			switch (productType) {
				case 'EQUOTIP':
					settings.MATERIAL = {
						TITLE: 'ExportFieldMaterial',
						VALUE:
							'materialId' in thisMeasurementObject.settings
								? getMaterialNameOfMaterialId(
										null,
										formatMessage,
										thisMeasurementObject.settings.materialId
								  )
								: getMaterialNameOfMaterialId(null, formatMessage, 2),
					};

					if (
						thisMeasurementObject.settings.customMaterial != null &&
						thisMeasurementObject.settings.customMaterial.customMaterialName != null
					)
						settings.MATERIAL.VALUE = thisMeasurementObject.settings.customMaterial.customMaterialName;

					break;
				case 'SSL':
				case 'SCHMIDT': {
					const surfaceStrenAndCorrEnum = getSchmidtCompositeSurfaceId(
						thisMeasurementObject.settings.secondary.strengthId,
						thisMeasurementObject.settings.secondary.surfaceCorrectionId
					);

					settings.CONVERSIONCURVE = {
						TITLE: 'ExportFieldConversionCurve',
						VALUE: null,
					};
					if (
						thisMeasurementObject.settings.customMaterial != null &&
						thisMeasurementObject.settings.customMaterial.customMaterialName != null
					) {
						settings.CONVERSIONCURVE.VALUE =
							thisMeasurementObject.settings.customMaterial.customMaterialName;
					} else if (typeof thisMeasurementObject.settings.secondary.conversionCurveId !== 'undefined') {
						//  handleFormatMessage('ExportFieldLowerLimit', formatMessage),
						settings.CONVERSIONCURVE.VALUE = handleFormatMessage(
							getLabelFromGlobalDataKey(
								'convcurveschmidt',
								thisMeasurementObject.settings.secondary.conversionCurveId
							),
							formatMessage
						);
					} else if (surfaceStrenAndCorrEnum != null) {
						settings.CONVERSIONCURVE.VALUE = handleFormatMessage(
							getLabelFromGlobalDataKey('surfacestrengthandcorrschmidt', surfaceStrenAndCorrEnum),
							formatMessage
						);
					} else if (thisMeasurementObject.settings.secondary.surfaceConditionId != null) {
						settings.CONVERSIONCURVE.VALUE = handleFormatMessage(
							getLabelFromGlobalDataKey(
								'surfaceconditionschmidt',
								thisMeasurementObject.settings.secondary.surfaceConditionId
							),
							formatMessage
						);
					}

					settings.CARBODEPTH = {
						TITLE: 'ExportFieldCarbonationDepth',
						VALUE:
							thisMeasurementObject.settings.secondary.carbonationDepth != null
								? handleFormatMessage('ValueCarbonationDepthMms', formatMessage, [
										thisMeasurementObject.settings.secondary.carbonationDepth,
								  ])
								: null,
					};
					settings.CONCRETEAGE = {
						TITLE: 'ExportFieldConcreteAge',
						VALUE:
							thisMeasurementObject.settings.secondary.age != null
								? handleFormatMessage('ValueConcreteAgeDays', formatMessage, [
										thisMeasurementObject.settings.secondary.age,
								  ])
								: null,
					};
					break;
				}
			}

			if (typeof thisMeasurementObject.settings.triggerLoadId !== 'undefined') {
				settings.TESTLOADINFO = {
					TITLE: 'ExportColumnTestLoadInfo',
					VALUE: `UCI HV${thisMeasurementObject.settings.triggerLoadId}`,
				};
			}

			if (
				typeof thisMeasurementObject.properties.seriesType !== 'undefined' &&
				thisMeasurementObject.properties.seriesType === 'verificationSeries'
			) {
				/* Verification serie */
				let enumToCheckVerification = null;
				switch (productType) {
					case 'EQUOTIP':
						enumToCheckVerification = 'verificationcriteriaequotip';
						break;
					case 'SSL':
					case 'SCHMIDT':
						enumToCheckVerification = 'verificationcriteriaschmidt';
						break;
				}
				if (enumToCheckVerification) {
					const valueEnumStandardCriteria = getLabelFromGlobalDataKey(
						enumToCheckVerification,
						thisMeasurementObject.settings.standardId
					);
					if (valueEnumStandardCriteria !== null) {
						infoForDataMeasuremets.PROPERTIES.VERIFICATIONCRITERIA = {
							TITLE: 'ExportColumnVerificationCriteria',
							VALUE: handleFormatMessage(valueEnumStandardCriteria, formatMessage),
						};
					}
				}

				settings.VERIFICATIONSTD = {
					TITLE: 'ExportColumnVerificationStd',
					VALUE: thisMeasurementObject.settings.standard,
				};
				if (typeof thisMeasurementObject.settings.testBlock !== 'undefined') {
					settings.TESTBLOCKNAME = {
						TITLE:
							productType !== 'SCHMIDT'
								? 'ExportColumnTestBlockName'
								: 'ExportColumnCalibrationAnvilName',
						VALUE:
							thisMeasurementObject.settings.testBlock.testBlockName != null
								? thisMeasurementObject.settings.testBlock.testBlockName
								: null,
					};

					settings.TESTBLOCKSN = {
						TITLE: productType !== 'SCHMIDT' ? 'ExportColumnTestBlockSN' : 'ExportColumnCalibrationAnvilSN',
						VALUE:
							thisMeasurementObject.settings.testBlock.testBlockSerialNumber != null
								? thisMeasurementObject.settings.testBlock.testBlockSerialNumber
								: null,
					};

					settings.TESTBLOCKNOMINALHARDNESS = {
						TITLE:
							productType !== 'SCHMIDT'
								? 'ExportColumnTestBlockNominalHardness'
								: 'ExportColumnCalibrationAnvilReboundValue',
						VALUE: `${thisMeasurementObject.settings.testBlock.testBlockValue} ${infoForDataMeasuremets.SCALES.PRIMARY.UNIT.VALUE}`,
					};
					settings.TESTBLOCKTOLERANCE = {
						TITLE:
							productType !== 'SCHMIDT'
								? 'ExportColumnTestBlockTolerance'
								: 'ExportColumnCalibrationAnvilTolerance',
						VALUE: `${thisMeasurementObject.settings.testBlock.testBlockMargin} ${infoForDataMeasuremets.SCALES.PRIMARY.UNIT.VALUE}`,
					};
				}

				let valueToDisplay = null;
				if (typeof thisMeasurementObject.properties.verificationResult !== 'undefined') {
					valueToDisplay = thisMeasurementObject.properties.verificationResult;
					if (getLabelFromGlobalDataKey('verificationstatuses', valueToDisplay) !== null) {
						valueToDisplay = handleFormatMessage(
							getLabelFromGlobalDataKey('verificationstatuses', valueToDisplay),
							formatMessage
						);
					}
				}

				infoForDataMeasuremets.PROPERTIES.VERIFICATIONRESULT = {
					TITLE: 'ExportColumnTestVerificationResult',
					VALUE: valueToDisplay,
				};
			}

			infoForDataMeasuremets.SETTINGS = settings;
		}
		let mainScale = 'PRIMARY';
		if ('SECONDARY' in infoForDataMeasuremets.SCALES && !isVerificationSerie) {
			mainScale = 'SECONDARY';
		}

		let mainValueIndex = primaryValueIndex;
		let mainTableIndex = 'primary';
		if (secondaryValueIndex >= 0 && !isVerificationSerie) {
			mainValueIndex = secondaryValueIndex;
			mainTableIndex = 'secondary';
		}

		let thresholdLoValue = null;
		let thresholdHiValue = null;
		if (infoForDataMeasuremets.SCALES[mainScale.toUpperCase()].TABLE.THRESHOLDS.length === 2) {
			thresholdLoValue = +infoForDataMeasuremets.SCALES[mainScale.toUpperCase()].TABLE.THRESHOLDS[0].VALUE;
			thresholdHiValue = +infoForDataMeasuremets.SCALES[mainScale.toUpperCase()].TABLE.THRESHOLDS[1].VALUE;
		}
		const infoIndexes = {
			mainValueIndex,
			mainTableIndex,
			removedValueIndex,
			pointLabelIndex,
			outlierTypeValueIndex /* CHANGED FOR SCHMIDT */,
		};

		const toReturn = {
			infoIndexes,
			infoForDataMeasuremets,
			thisMeasurementObject,
			thresholdLoValue,
			thresholdHiValue,
			units,
			mainScale,
		};

		return toReturn;
	}

	getDataForGraph(
		currentData: Object[],
		infoForDataMeasuremets: Object,
		infoIndexes: Object,
		thresholdLoValue: ?number,
		thresholdHiValue: ?number
	): Object {
		const { intl } = this.props,
			{ formatMessage } = intl;

		const mainValueIndex = infoIndexes.mainValueIndex;
		const removedValueIndex = infoIndexes.removedValueIndex;
		const pointLabelIndex = infoIndexes.pointLabelIndex;
		const outlierTypeValueIndex = infoIndexes.outlierTypeValueIndex; /* CHANGED FOR SCHMIDT */

		let minValueFound = thresholdLoValue;
		let maxValueFound = thresholdHiValue;
		const colorsToUse = [];
		const xAxis = [];
		const data = [];
		for (let rowDataIndex = 0; rowDataIndex < currentData.length; rowDataIndex++) {
			const thisRowData = currentData[rowDataIndex];
			let colorStyle = 'PROCEQ';
			let valueOfMeasurement = thisRowData[mainValueIndex];
			if (valueOfMeasurement === null) {
				colorStyle = 'NOCONVERSION';
				valueOfMeasurement = handleFormatMessage('ExportFieldNoConversion', formatMessage);
			} else {
				if (valueOfMeasurement < minValueFound || typeof minValueFound === 'undefined') {
					/* CHANGED FOR SCHMIDT */
					minValueFound = valueOfMeasurement;
				}
				if (valueOfMeasurement > maxValueFound || typeof maxValueFound === 'undefined') {
					/* CHANGED FOR SCHMIDT */
					maxValueFound = valueOfMeasurement;
				}

				if (
					thisRowData[removedValueIndex] === 1 ||
					(outlierTypeValueIndex >= 0 && thisRowData[outlierTypeValueIndex] > 0)
				) {
					/* CHANGED FOR SCHMIDT */
					colorStyle = 'EXCLUDED';
				} else {
					// Check Above/Below limit
					if (infoForDataMeasuremets.MISCELLANEOUS.UPPERLIMIT.VALUE !== null) {
						if (valueOfMeasurement > parseFloat(infoForDataMeasuremets.MISCELLANEOUS.UPPERLIMIT.VALUE)) {
							colorStyle = 'OUTOFRANGE';
						}
					}

					if (infoForDataMeasuremets.MISCELLANEOUS.LOWERLIMIT.VALUE !== null) {
						if (valueOfMeasurement < parseFloat(infoForDataMeasuremets.MISCELLANEOUS.LOWERLIMIT.VALUE)) {
							colorStyle = 'OUTOFRANGE';
						}
					}
				}
			}
			data.push(thisRowData[mainValueIndex]);
			xAxis.push(thisRowData[pointLabelIndex]);
			colorsToUse.push(
				rgbToHex(
					this.styleColors[colorStyle][0],
					this.styleColors[colorStyle][1],
					this.styleColors[colorStyle][2]
				)
			);
		}
		/* CHANGED FOR SCHMIDT */
		if (typeof minValueFound !== 'number') {
			minValueFound = 0;
		}
		if (typeof maxValueFound !== 'number') {
			maxValueFound = 1;
		}
		return {
			data,
			xAxis,
			colors: colorsToUse,
			minValueFound,
			maxValueFound,
		};
	}

	doMeasurementsDataColumn(dataForGraph: Object, units: string) {
		const { intl } = this.props,
			{ formatMessage } = intl;
		return (
			<Col xs={8} lg={6}>
				<div className="extrainfo-container container-measurements-info">
					<div className="category-properties">
						{handleFormatMessage('ExportFieldMeasurements', formatMessage)}
					</div>
					<div className="container-allmeasurements">
						{dataForGraph.data.map((thisDataEntry, indexData) => {
							return (
								<div key={indexData}>
									<div className="container-measurement">
										<div className="index-measurement"> {dataForGraph.xAxis[indexData]} </div>
										{
											<div
												className="value-measurement"
												style={{ color: dataForGraph.colors[indexData] }}
											>
												{' '}
												{thisDataEntry === null
													? handleFormatMessage('ExportFieldNoConversion', formatMessage)
													: `${getResolutionForScale(units, thisDataEntry)} ${units}`}
											</div>
										}
									</div>
								</div>
							);
						}, this)}
					</div>
				</div>
			</Col>
		);
	}

	getStatisticsParametersForProduct(mainScale: string, productType: ProductCode) {
		let parameters = [];
		switch (productType) {
			case 'EQUOTIP':
				parameters = [
					`SCALES.${mainScale}.STATISTICS.AVG`,
					`SCALES.${mainScale}.STATISTICS.COUNT`,
					`SCALES.${mainScale}.STATISTICS.STDEV`,
					`SCALES.${mainScale}.STATISTICS.MIN`,
					`SCALES.${mainScale}.STATISTICS.MAX`,
					`SCALES.${mainScale}.STATISTICS.RANGE`,
					`SCALES.${mainScale}.STATISTICS.RELSPAN`,
				];
				break;

			case 'SSL':
			case 'SCHMIDT':
				parameters = [
					`SCALES.${mainScale}.STATISTICS.AVG`,
					`SCALES.${mainScale}.STATISTICS.COUNT`,
					`SCALES.${mainScale}.STATISTICS.MIN`,
					`SCALES.${mainScale}.STATISTICS.MAX`,
				];
				break;
		}
		return parameters;
	}

	getSettingsParametersForProduct(mainScale: string, productType: ProductCode, isVerificationMode: boolean) {
		let parameters = [];
		if (isVerificationMode) {
			parameters = [
				'MISCELLANEOUS.UPPERLIMITWITHUNITS',
				'MISCELLANEOUS.LOWERLIMITWITHUNITS',
				'PROPERTIES.PROBENAME',
				'PROPERTIES.VERIFICATIONCRITERIA',
				'SETTINGS.VERIFICATIONSTD',
				'SETTINGS.TESTBLOCKNAME',
				'SETTINGS.TESTBLOCKSN',
				'SETTINGS.TESTLOADINFO',
				'SETTINGS.TESTBLOCKNOMINALHARDNESS',
				'SETTINGS.TESTBLOCKTOLERANCE',
				'PROPERTIES.VERIFICATIONRESULT',
			];
		} else {
			switch (productType) {
				case 'EQUOTIP':
					parameters = [
						'MISCELLANEOUS.UPPERLIMITWITHUNITS',
						'MISCELLANEOUS.LOWERLIMITWITHUNITS',
						'SETTINGS.MATERIAL',
						`SCALES.${mainScale}.NAME`,
						`SCALES.${mainScale}.UNIT`,
						'PROPERTIES.PROBENAME',
						'PROPERTIES.PROBESERIALNUMBER',
						'PROPERTIES.PROBEBTSERIALNUMBER',
						'PROPERTIES.PROBELASTVERIFICATIONDATE',
						'SETTINGS.TESTLOADINFO',
					];
					break;
				case 'SSL':
				case 'SCHMIDT':
					parameters = [
						'MISCELLANEOUS.UPPERLIMITWITHUNITS',
						'MISCELLANEOUS.LOWERLIMITWITHUNITS',
						'SETTINGS.STANDARD',
						'SETTINGS.CONVERSIONCURVE',
						'SETTINGS.CARBODEPTH',
						'SETTINGS.CONCRETEAGE',
						`SCALES.${mainScale}.NAME`,
						`SCALES.${mainScale}.UNIT`,
						'PROPERTIES.PROBENAME',
					];
					break;
			}
		}

		return parameters;
	}

	doExtraInfoColumn(
		toShowKeyValues: Object[],
		title: string,
		infoForDataMeasuremets: Object,
		sizeColumn?: number = 12
	) {
		const { intl } = this.props,
			{ formatMessage } = intl;
		if (toShowKeyValues.length === 0) return null;
		return (
			<Col span={sizeColumn}>
				<div className="extrainfo-container">
					<div className="category-properties">{handleFormatMessage(title, formatMessage)}</div>
					{toShowKeyValues.map((thisToShowKeyValue, indexKey) => {
						let valueFound = infoForDataMeasuremets;
						const strKeyExploded = thisToShowKeyValue.split('.');
						for (let indexExploded = 0; indexExploded < strKeyExploded.length; indexExploded++) {
							const thisSubKey = strKeyExploded[indexExploded];
							if (thisSubKey in valueFound && valueFound[thisSubKey] != null) {
								valueFound = valueFound[thisSubKey];
							} else {
								valueFound = null;
								break;
							}
						}
						if (valueFound != null && 'VALUE' in valueFound && valueFound.VALUE != null) {
							return (
								<div key={indexKey}>
									<div className="title-property">
										{handleFormatMessage(valueFound.TITLE, formatMessage)}
									</div>
									<div className="value-property">{valueFound.VALUE}</div>
								</div>
							);
						}
						return null;
					})}
				</div>
			</Col>
		);
	}
}

const mapStateToProps = state => ({
	session: state.session,
	locale: state.locale,
});

export default withHighcharts(connect(mapStateToProps)(injectIntl(MainData)), Highcharts);
