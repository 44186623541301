// @flow
import type { ThunkAction } from 'redux-thunk';
import axios from 'axios';
import { get, noop } from 'lodash';

import { checkStatusJson } from 'apis/restUtils';
import realErrorHandler from 'actionUtils/errorHandler';
import { getDeviceID } from '../utils/browserUtils';

type AjaxAction = {
	url: string,
	method?: string,
	params?: Object,
	headers?: Object,
	options?: Object,
	errorHandler?: Function,
	setLoading?: Function,
	uninterruptable?: boolean,
	returnResponseHeaders?: boolean,
	injectAuthorization?: boolean,
	injectProduct?: boolean,
};

let cancelToken;

export const request = ({
	url,
	method = 'GET',
	params = {},
	headers = {},
	options = {},
	errorHandler = realErrorHandler,
	setLoading = noop,
	uninterruptable = false,
	returnResponseHeaders = false,
	injectAuthorization = true,
	injectProduct = true,
}: AjaxAction): ThunkAction => (dispatch, getState) => {
	const { session, locale }: { session: Session, locale: Locale } = getState();

	if (url.indexOf('{non_mngt_url}') > -1) {
		url = url.replace('{non_mngt_url}', session.regionalDomain);
	}

	params = {
		product: injectProduct ? session.productType : undefined,
		...params,
	};

	const token = get(session, 'ajaxParams.token');
	if (token) {
		session.bearerToken = token;
	}

	dispatch({
		type: `${method} ${url}`,
		payload: {
			method,
			params,
		},
	});

	// No cancellation logic was present
	if (!options.cancelToken) {
		if (
			!cancelToken || // no existing cancellable request
			cancelToken.token.reason // previous request was already cancelled
		) {
			// generate a cancel token.
			cancelToken = axios.CancelToken.source();
		}

		// This will be the newly generated cancel token or one from a previous unfinished request.
		options.cancelToken = cancelToken.token;
	}

	// we don't want to cancel
	if (uninterruptable) {
		options.cancelToken = undefined;
	}

	setLoading(true);

	let promise = axios
		.request({
			url,
			method,
			params: method === 'GET' ? params : {},
			data: params,
			withCredentials: !injectAuthorization,
			headers: Object.entries({
				'X-Accept-Language': locale.lang,
				'X-SE-Client-Id': getDeviceID(),
				...headers,
				...(injectAuthorization ? { Authorization: `Bearer ${session.bearerToken}` } : {}),
			})
				.filter(header => header[1])
				.reduce((obj, header) => {
					obj[header[0]] = header[1];
					return obj;
				}, {}),
			...options,
		})
		.catch(e => {
			return options.uninterruptable ? errorHandler(e) : errorHandler(e, cancelToken);
		})
		.finally(() => {
			setLoading(false);
		});

	if (!returnResponseHeaders) {
		promise = promise.then(checkStatusJson);
	}

	return promise.then(data => {
		dispatch({
			type: `STORE ${method} ${url}`,
			payload: {
				params,
				data,
			},
		});
		return data;
	});
};
