// @flow
import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import Audio from 'components/measurements/LogEvent/Audio';
import styles from 'components/measurements/LogEvent/GPR/UserText.styl';

export const Marker = ({ log }: GPRLogbookEntryProps) => {
	const { markerType, markerLabel: marker, markerContent, statusType, attachmentName } = log.content;
	const position = +log.content.position;

	let id = `App.Logbook.FDL.${markerType}Marker.`;
	switch (statusType) {
		case 'added':
			id += 'Added';
			break;
		case 'edited':
			id += 'Edited';
			break;
		case 'deleted':
			id += 'Deleted';
			break;
	}

	return (
		<div>
			{statusType === 'deleted' ? (
				<div className={styles.deleted}>
					<FormattedMessage
						id={id}
						values={{
							position,
							marker,
						}}
					/>
				</div>
			) : (
				<b>
					<FormattedMessage
						id={id}
						values={{
							position,
							marker,
						}}
					/>
				</b>
			)}

			{statusType !== 'deleted' && (
				<Fragment>
					<br />
					{markerType === 'Text' && markerContent}
					{markerType === 'Audio' && (
						<Audio mID={log.mID} aID={markerContent} attachmentName={attachmentName} />
					)}
				</Fragment>
			)}
		</div>
	);
};

export const color = (log: Log) => (log.content.statusType === 'deleted' ? 'red' : 'green');

export default Marker;
