// @flow
import React from 'react';
import { connect } from 'react-redux';
import { compose, withState } from 'recompose';
import { FormattedMessage } from 'react-intl';
import { Button } from 'antd';
import URL from 'apis/urls';
import { request } from 'actions/ajax';
import styles from '../shared/SmarterTable.styl';

export const ExportUsers = ({ loading, exportUsers }: { loading: boolean, exportUsers: Function }) => (
	<Button className={styles.button} type="primary" onClick={exportUsers} loading={loading}>
		<FormattedMessage id="App.UsersExportAll" />
	</Button>
);

export default compose(
	withState('loading', 'setLoading', false),
	connect(null, (dispatch: Function, props) => ({
		exportUsers: () => {
			dispatch(
				request({
					url: URL.EXPORT_ACCOUNTS_CSV,
					setLoading: props.setLoading,
					returnResponseHeaders: true,
				})
			).then(response => {
				window.fileDownload(
					response.data,
					(response.headers['content-disposition'] || '').split('"')[1],
					response.headers['content-type']
				);
			});
		},
	}))
)(ExportUsers);
