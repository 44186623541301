// @flow
import React from 'react';
import { connect } from 'react-redux';
import { compose, withState } from 'recompose';
import { FormattedMessage } from 'react-intl';
import { Button } from 'antd';
import URL from 'apis/urls';
import { request } from 'actions/ajax';
import styles from '../shared/SmarterTable.styl';
import { ContractType } from './contractConsts';
import type { ContractTypes } from './contractConsts';

const getButtonTitleID = (contractType: ContractTypes, productFamily: ProductFamily) => {
	switch (contractType) {
		case ContractType.Device:
			return productFamily === 'EQUOTIP' ? 'App.ContractsExportBluetooth' : 'App.ContractsExportWifi';
		case ContractType.MaintenanceServices:
			return 'App.ContractsExportMaintenanceServices';
		case ContractType.Software:
			return productFamily === 'INSPECT' ? 'App.ContractsExportInspect' : 'App.ContractsExportSoftware';
		default:
			return '';
	}
};

const getCategoryParam = (contractType: ContractTypes, productFamily: ProductFamily) => {
	switch (contractType) {
		case ContractType.Device:
			return productFamily === 'EQUOTIP' ? 'bluetooth' : 'general';
		case ContractType.MaintenanceServices:
			return 'aspo';
		case ContractType.Software:
			return productFamily === 'INSPECT' ? 'saas' : 'software';
		default:
			return undefined;
	}
};

export const ExportContracts = ({
	loading,
	exportContracts,
	productFamily,
	contractType,
}: {
	loading: boolean,
	exportContracts: Function,
	productFamily: ProductFamily,
	contractType: ContractTypes,
}) => (
	<Button className={styles.button} type="primary" onClick={exportContracts} loading={loading}>
		<FormattedMessage id={getButtonTitleID(contractType, productFamily)} />
	</Button>
);

export default compose(
	withState('loading', 'setLoading', false),
	connect(
		null,
		(
			dispatch: Function,
			props: {
				setLoading: boolean,
				contractType: ContractTypes,
				productFamily: ProductFamily,
			}
		) => ({
			exportContracts: () => {
				dispatch(
					request({
						url: URL.EXPORT_CONTRACTS,
						setLoading: props.setLoading,
						params: {
							category: getCategoryParam(props.contractType, props.productFamily),
							product: undefined,
							contractType: props.contractType,
						},
						returnResponseHeaders: true,
					})
				).then(response =>
					window.fileDownload(
						response.data,
						(response.headers['content-disposition'] || '').split('"')[1],
						response.headers['content-type']
					)
				);
			},
		})
	)
)(ExportContracts);
