// @flow
import React, { Fragment } from 'react';
import { compose, withState } from 'recompose';

export const ViewMore = ({
	list,
	max,
	collapsed,
	setCollapse,
	formatMessage,
}: {
	list: RegionalAnalytics[],
	max: number,
	collapsed: boolean,
	setCollapse: Function,
	formatMessage: Function,
}) => {
	const msgId = collapsed ? 'App.ViewMore' : 'App.ViewLess';
	return (
		<Fragment>
			{list.slice(0, collapsed ? max - 1 : list.length).map(point => (
				<p key={point.mid}>{point.name}</p>
			))}
			{list.length > max && <a onClick={() => setCollapse(!collapsed)}>{formatMessage({ id: msgId })}</a>}
		</Fragment>
	);
};

export default compose(withState('collapsed', 'setCollapse', ({ list, max }) => list.length > max))(ViewMore);
