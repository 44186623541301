// @flow
import React from 'react';
import { connect } from 'react-redux';
import { compose, withState } from 'recompose';
import { FormattedMessage } from 'react-intl';
import { Button } from 'antd';
import URL from 'apis/urls';
import { request } from 'actions/ajax';
import { getOnlyDataFromGlobalDataKey } from 'utils/registryHelper';
import styles from '../shared/SmarterTable.styl';

const getButtonTitleID = () => {
	return 'App.ExportSaaSLicenses';
};

const getSaaSProducts = () => {
	return getOnlyDataFromGlobalDataKey('saasProducts');
};

export const ExportSaaSLicenses = ({ loading, exportLicenses }: { loading: boolean, exportLicenses: Function }) => (
	<Button className={styles.button} type="primary" onClick={exportLicenses} loading={loading}>
		<FormattedMessage id={getButtonTitleID()} />
	</Button>
);

export default compose(
	withState('loading', 'setLoading', false),
	connect(null, (dispatch: Function, props: { setLoading: boolean }) => ({
		exportLicenses: () => {
			const params = {
				product: undefined,
				products: getSaaSProducts(),
			};
			dispatch(
				request({
					method: 'POST',
					url: URL.EXPORT_LICENSES,
					setLoading: props.setLoading,
					params,
					returnResponseHeaders: true,
				})
			).then(response =>
				window.fileDownload(
					response.data,
					(response.headers['content-disposition'] || '').split('"')[1],
					response.headers['content-type']
				)
			);
		},
	}))
)(ExportSaaSLicenses);
