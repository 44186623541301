// @flow
import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withState, lifecycle, withProps } from 'recompose';
import { FormattedMessage } from 'react-intl';
import { Modal, Button, Spin } from 'antd';
import JSZip from 'jszip';
import { find, noop } from 'lodash';

import { request } from 'actions/ajax';
import URL from 'apis/urls';
import styles from './Attachment.styl';

export const Photo = ({
	loading,
	image,
	imageWidth,
	filename,
	visible,
	toggleModal,
}: {
	loading: boolean,
	image: ?string,
	imageWidth: ?string,
	filename: ?string,
	visible: boolean,
	toggleModal: Function,
}) =>
	loading ? (
		<div className={[styles.attachment_container, 'image_container'].join(' ')}>
			<Spin className={styles.loading} />
		</div>
	) : process.env.HTML_EXPORT_VERSION ? (
		<div className={[styles.attachment_container, 'image_container'].join(' ')}>
			<a className={styles.image_container} href={image} download={filename}>
				<img className={styles.image} src={image} alt="thumbnail" onClick={() => toggleModal(true)} />
			</a>
		</div>
	) : (
		<div className={[styles.attachment_container, 'image_container'].join(' ')}>
			<div className={styles.image_container}>
				<img className={styles.image} src={image} alt="thumbnail" onClick={() => toggleModal(true)} />
				<Modal
					visible={visible}
					closable={false}
					footer={null}
					onOk={() => toggleModal(false)}
					onCancel={() => toggleModal(false)}
					width={imageWidth}
				>
					<img className={styles.full_image} src={image} alt="full size" onClick={() => toggleModal(false)} />
				</Modal>
			</div>
			<div>
				{/* <span className={ styles.filename } title={ filename }>{ filename }</span> */}
				<a href={image} download={filename}>
					<Button type="primary">
						<FormattedMessage id="Proceq.LogbookDownload" />
					</Button>
				</a>
			</div>
		</div>
	);

export default compose(
	withState('loading', 'setLoading', false),
	withState('image', 'setImage', null),
	withState('imageWidth', 'setImageWidth', null),
	withState('filename', 'setFilename', null),
	withState('visible', 'toggleModal', false),
	connect(null, (dispatch: Function, props) => ({
		fetchImage: () =>
			dispatch(
				request({
					url: URL.DOWNLOAD_FILE,
					headers: {
						'Content-Type': 'application/zip',
					},
					options: {
						responseType: 'arraybuffer',
					},
					setLoading: props.setLoading,
					params: {
						mID: props.mID,
						fID: props.fID,
						aID: props.aID,
					},
					uninterruptable: true,
				})
			)
				.then(data => JSZip.loadAsync(data))
				.then(zip => {
					const image = find(zip.files, (file, fileName: string) => fileName.match(/\.(jpg|png)$/));
					if (image) {
						return image.async('blob').then(blob => ({
							filename: image.name,
							imageURL: window.URL.createObjectURL(blob),
						}));
					}
					throw new Error('No jpg/png file in archive.');
				})
				.then(({ filename, imageURL }) => {
					const img = new Image();
					img.onload = function onload() {
						// include padding from modal body
						props.setImageWidth(this.width + 48);
					};
					img.src = imageURL;
					props.setImage(imageURL);
					props.setFilename(filename);
				}),
	})),
	withProps(({ mID, attachmentName }) =>
		process.env.HTML_EXPORT_VERSION
			? {
					image: `measurement_assets/${mID}/${attachmentName}`,
					filename: attachmentName,
					fetchImage: noop,
					imageWidth: 'auto',
			  }
			: {}
	),
	lifecycle({
		componentDidMount() {
			this.props.fetchImage();
		},
	})
)(Photo);
