// @flow
import React, { Fragment } from 'react';
import { upperFirst } from 'lodash';
import { FormattedMessage } from 'react-intl';
import { IsEqualOrAfterPD8050Launch } from 'constants/exportConstants';
import StaticMap from '../StaticMap';
import ConvertedUnits from '../../../shared/ConvertedUnits';

export const Creation = ({ log, isMetric, scanType, version }: GPRLogbookEntryProps) => {
	const { latitide, longitude, measurementInfo } = log.content;
	const coordinates = { lat: +latitide, lng: +longitude };

	return (
		<div>
			<b>
				<FormattedMessage
					id="Created {fileName} in folder {folderName}"
					values={{
						fileName: measurementInfo.fileFolder.fileName,
						folderName: measurementInfo.fileFolder.folderName,
					}}
				/>
			</b>
			<br />
			{[
				{
					id: 'Mode: {value}',
					value: measurementInfo.mode,
				},
				{
					id: 'App.LogbookEntryMacro',
					value: measurementInfo.macro,
					enable: measurementInfo.isIQSAFT,
				},
				{
					id: 'App.LogbookEntryTxFrequency',
					value: measurementInfo.transmitFrequency / 1000,
					unitId: 'PUNDIT.Snapshot.Center Frequency',
					enable: IsEqualOrAfterPD8050Launch(version),
				},
				{
					id: 'App.LogbookEntryTxVoltage',
					unitId: 'PUNDIT.MeasurementPresets.Energy',
					value: measurementInfo.transmitVoltage,
					enable: IsEqualOrAfterPD8050Launch(version),
				},
				{
					id: 'App.LogbookEntryHalfCycle',
					value: measurementInfo.halfCycle ? 'On' : 'Off',
					enable: IsEqualOrAfterPD8050Launch(version),
				},
				{
					id: 'App.LogbookEntryMaxTransmissionTime',
					value: measurementInfo.transmissionTime,
					unitId: 'PUNDIT.Logbook Panel.Max Transmission Time',
					enable: !measurementInfo.isIQSAFT || IsEqualOrAfterPD8050Launch(version),
				},
				{
					id: 'App.LogbookEntryChannels',
					value: measurementInfo.channels,
				},
				{
					id: 'App.LogbookEntrySignalZeroOffset',
					value: measurementInfo.signalZeroOffset,
					unitId: 'PUNDIT.Settings Panel.Signal Zero Offset',
					enable: !IsEqualOrAfterPD8050Launch(version),
				},
				{
					id: 'App.LogbookEntryRawDataOffset',
					unitId: 'PUNDIT.Settings Panel.Signal Zero Offset',
					value: measurementInfo.rawDataOffset,
					enable: IsEqualOrAfterPD8050Launch(version),
				},
				{
					id: 'App.LogbookEntryPulseDelay',
					value: measurementInfo.pulseDelay,
					unitId: 'PUNDIT.Settings Panel.Pulse Delay',
				},
				{
					id: 'App.LogbookEntryPulseVelocity',
					value: measurementInfo.pulseVelocity,
					unitId: 'PUNDIT.Settings Panel.Pulse Velocity',
				},
				{
					id: 'App.LogbookEntryAnalogGain',
					value: measurementInfo.probeGain,
					unitId: 'PUNDIT.Settings Panel.Analogue Gain',
				},
				{
					id: 'App.LogbookEntryTimeGainCompensation',
					value: measurementInfo.timeGain,
					unitId: 'PUNDIT.Settings Panel.Time Gain Compensation',
				},
				{
					id: 'App.LogbookEntryDigitalGain',
					value: measurementInfo.digitalGain,
					unitId: 'PUNDIT.Settings Panel.Digital Gain',
				},
				{
					id: 'App.LogbookEntryImageStabilizer',
					value: measurementInfo.averaging,
				},
				{
					id: 'App.LogbookEntryHighDefinitionFocus',
					value: measurementInfo.isIQSAFT ? 'On' : 'Off',
				},
				{
					id: 'App.LogbookEntrySurfaceWaveCancellation',
					value: measurementInfo.surfaceWaveCancellation,
				},
				{
					id: 'App.LogbookEntryYSpacing',
					value: measurementInfo.overlapX,
					unitId: 'PUNDIT.Logbook Panel.Y Spacing',
					type: 'Stripe Scan',
					enable: !measurementInfo.aiMode,
				},
				{
					id: 'App.LogbookEntryXSpacing',
					value: measurementInfo.overlapX,
					unitId: 'PUNDIT.Logbook Panel.X Spacing',
					type: 'Line Scan',
					enable: !measurementInfo.aiMode && measurementInfo.overlapX >= 0,
				},
				{
					id: 'App.LogbookEntryXSpacing',
					value: measurementInfo.overlapX,
					unitId: 'PUNDIT.Logbook Panel.X Spacing Channel',
					type: 'Line Scan',
					enable: !measurementInfo.aiMode && measurementInfo.overlapX < 0,
				},
				{
					id: 'App.LogbookEntryAIMode',
					value: measurementInfo.aiMode,
					enable: measurementInfo.aiMode,
				},
			]
				.filter(row => row.enable === undefined || row.enable === true)
				.map(config => {
					let ele;
					if (config.unitId) {
						if (config.type && config.type !== scanType) {
							return null;
						}

						ele = (
							<ConvertedUnits
								id={config.id}
								unitId={config.unitId}
								scanType={scanType}
								isMetric={isMetric}
								values={[config.value]}
							/>
						);
					} else if (config.value === null) {
						return null;
					} else {
						ele = <FormattedMessage id={config.id} values={{ value: upperFirst(String(config.value)) }} />;
					}
					return (
						<Fragment key={config.id}>
							{ele}
							<br />
						</Fragment>
					);
				})}
			<StaticMap width={400} height={200} zoom={16} coordinates={coordinates} />
			{/* <InteractiveMap height={ 200 } zoom={ 16 } coordinates={ coordinates } /> */}
		</div>
	);
};

export const color = () => 'green';

export default Creation;
