// @flow
import compareVersions from 'compare-versions';
import { has, get } from 'lodash';

const PD8050InitialVersion = '5.0.0';

export const IsEqualOrAfterPD8050Launch = (version: string) => {
	return compareVersions.compare(PD8050InitialVersion, version, '<=');
};

export const IsProContractType = (data: Object) => {
	const logs = data.logs;
	if (logs === undefined) {
		return false;
	}
	const probeLog = logs && logs.find(log => log.type === 'probe');
	if (has(probeLog, 'content.contractType')) {
		return get(probeLog, 'content.contractType')
			.toLowerCase()
			.includes('pro');
	}
	return false;
};

export const MeasurementScanType = {
	LineScan: 'Line Scan',
	AreaScan: 'Area Scan',
	FreePath: 'Free Path',
	StripeScan: 'Stripe Scan',
	GridScan: 'Grid Scan',
};

export const TagObjectType = {
	Backwall: 'Backwall',
	NonMetallic: 'Non Metallic',
	Rebar: 'Rebar',
	Void: 'Void',
	LiveWire: 'Live Wire',
	UserDefined: 'User Defined',
	Velocity: 'Velocity',
	PulseVelocity: 'Pulse Velocity',
};

export const LineStatusDirection = {
	up: 0,
	right: 1,
	down: 2,
	left: 3,
};
export const ProceqMA8000 = 'Proceq MA8000';
