// @flow
import React from 'react';
import { Router, Switch, Route } from 'react-router-dom';
import { createBrowserHistory } from 'history';

// import DashboardAdminData from 'components/dashboardproceq/DashboardAdmin/DashboardAdminData';
import DashboardCustomerData from 'components/dashboardproceq/DashboardCustomerData/DashboardCustomerData';
import Dashboard from 'components/dashboardnew/Dashboard';
import DashboardOld from 'components/dashboardnew/DashboardOld';
import Measurements from 'components/measurements/Measurements';
import VerificationData from 'components/measurements/VerificationData';
import CustomMaterials from 'components/measurements/CustomMaterials';
import SetupFiles from 'components/measurements/SetupFiles';
import UserProfile from 'components/dashboard/Settings/UserProfile';
import Contracts from 'components/contracts/Contracts';
import Licenses from 'components/contracts/Licenses';
import Features from 'components/feature/Features';
import FreeTrial from 'components/contracts/FreeTrial';
import Tiers from 'components/tier/Tiers';
import BusinessUnits from 'components/bu/BusinessUnits';
// import Probes from 'components/probes/Probes';
import Dongles from 'components/dongles/Dongles';
import Firmware from 'components/firmware/Firmware';
import DevTools from 'components/devtools/DevTools';
import Notifications from 'components/notifications/Notifications';
import Migration from 'components/devtools/Migration';
import Analytics from 'components/analytics/Analytics';
import Versions from 'components/devtools/Versions';
import Users from 'components/users/Users';
import MainData from 'components/dashboardproceq/MeasurementsData/MainData';
import VerificationDataProceq from 'components/dashboardproceq/VerificationsData/MainData';
import CustomMaterialProceq from 'components/dashboardproceq/CustomMaterialData/MainData';
import ContractsManagementProceq from 'components/dashboardproceq/Contracts/ContractsManagementProceq';
import ContractsManagementProceqAdmin from 'components/dashboardproceq/ContractsAdmin/ContractsManagementProceq';
import UsersManagementProceq from 'components/dashboardproceq/UsersMngmt/UsersManagementProceq';
import BusInfoManagementProceq from 'components/dashboardproceq/BusInfo/BusInfoManagementProceq';
import HistoryLogsProceq from 'components/dashboardproceq/HistoryLogs/HistoryLogsProceq';
import ReportsAdminProceq from 'components/dashboardproceq/ReportsAdmin/ReportsAdminProceq';
import TrackUserProceq from 'components/dashboardproceq/TrackUser/TrackUserProceq';

import ViewRoute from 'components/measurements/HTMLExport/ViewRoute';
import RedirectToEagleWorkspace from 'components/redirect/RedirectToEagleWorkspace';

import OldLogin from 'components/auth/OldLoginForm';
import LoginPage from 'components/auth/LoginPage';
// import SignupForm from 'components/auth/SignupForm';
import ForgotPasswordForm from 'components/auth/ForgotPasswordForm';
import ActivationForm from 'components/activation/ActivationForm';
import LoginOneTimeToken from 'components/auth/LoginOneTimeToken';
import Cookies from 'components/auth/Cookies';
import PageNotFound from 'components/error/PageNotFound';
import { ConnectionTypeSoftware, ContractType, ProductConstants } from 'components/contracts/contractConsts';
import Logout from 'components/auth/Logout';
import AuthReceiver from 'components/auth/AuthReceiver';
import ContractsRedirectRoute from 'components/redirect/ContractsRedirectRoute';
import DownloadRedirect from './DownloadRedirectRoute';
import IndexRedirectRoute from './IndexRedirectRoute';
import AuthRoute from './AuthRoute';
import routeURLs from './urls';
import LanguageRoute from './LanguageRoute';
import { EAGLE_ID_RESET } from '../apis/urls';

export const history = createBrowserHistory();

// hardcoded routes to rely on es6 static imports in order to minimize bundle size/build time
export const router = (
	<Router history={history}>
		<LanguageRoute path="*">
			<Switch>
				<Route path={['/', '/login']} exact component={IndexRedirectRoute} />
				<AuthRoute path="/settings" component={UserProfile} />
				{/* <AuthRoute session="PROCEQ" path="/dashboard-admin-pq" component={DashboardAdminData} /> */}
				<AuthRoute session="PROCEQ" path="/dashboard" component={DashboardCustomerData} />
				<AuthRoute session="PROCEQ" path="/mnts-data" component={MainData} />
				<AuthRoute session="PROCEQ" path="/mnts-verif" component={VerificationDataProceq} />
				<AuthRoute session="PROCEQ" path="/custom-material" component={CustomMaterialProceq} />
				<AuthRoute session="PROCEQ" path="/contracts-pq" component={ContractsManagementProceq} />
				<AuthRoute session="PROCEQ" path="/contracts-admin-pq" component={ContractsManagementProceqAdmin} />
				<AuthRoute session="PROCEQ" path="/proceq-usersmngmt" component={UsersManagementProceq} />
				<AuthRoute session="PROCEQ" path="/businfo-pq" component={BusInfoManagementProceq} />
				<AuthRoute session="PROCEQ" path="/history-logs-pq" component={HistoryLogsProceq} />
				<AuthRoute session="PROCEQ" path="/reports-admin-pq" component={ReportsAdminProceq} />
				<AuthRoute session="PROCEQ" path="/trackuser-pq" component={TrackUserProceq} />

				<AuthRoute session="TDS" path="/dashboard-new" component={Dashboard} />
				<AuthRoute session="TDS" path="/dashboard-old" component={DashboardOld} />
				<AuthRoute session="TDS" path="/data/:id" component={ViewRoute} />
				<AuthRoute session="TDS" path="/data" component={Measurements} />
				<AuthRoute session="TDS" path="/data-verify" component={VerificationData} />
				<AuthRoute session="TDS" path="/data-custom" component={CustomMaterials} />
				<AuthRoute session="TDS" path="/data-setup" component={SetupFiles} />
				<AuthRoute
					session="TDS"
					path="/contracts/gpr"
					component={() => (
						<Contracts productFamily="GPR" contractType={ContractType.Device} connectionType="wifi" />
					)}
				/>
				<AuthRoute
					session="TDS"
					path="/contracts/equotip"
					component={() => (
						<Contracts
							productFamily="EQUOTIP"
							contractType={ContractType.Device}
							connectionType="bluetooth"
						/>
					)}
				/>
				<AuthRoute
					session="TDS"
					path="/contracts/maintenanceservices"
					component={() => (
						<Contracts productFamily="" contractType={ContractType.MaintenanceServices} connectionType="" />
					)}
				/>
				<AuthRoute
					session="TDS"
					path="/contracts/software"
					component={() => (
						<Contracts
							productFamily="SOFTWARE"
							contractType={ContractType.Software}
							connectionType={ConnectionTypeSoftware}
						/>
					)}
				/>
				<AuthRoute
					session="TDS"
					path="/contracts/inspect/contracts"
					component={() => (
						<Contracts productFamily="INSPECT" contractType={ContractType.Software} connectionType="" />
					)}
				/>
				<AuthRoute
					session="TDS"
					path="/contracts/inspect/licenses"
					component={() => (
						<Licenses productFamily="INSPECT" contractType={ContractType.Software} connectionType="" />
					)}
				/>
				<AuthRoute
					session="TDS"
					path="/contracts/saas/freetrial"
					component={() => (
						<FreeTrial
							productFamily={ProductConstants.GPR_INSIGHTS}
							contractType={ContractType.Software}
							connectionType=""
						/>
					)}
				/>
				<AuthRoute session="TDS" path="/contracts" exact component={() => <ContractsRedirectRoute />} />
				<AuthRoute session="TDS" path="/feature/Features" component={() => <Features productFamily="GPR" />} />
				<AuthRoute session="TDS" path="/tier/Tiers" component={() => <Tiers productFamily="GPR" />} />
				<AuthRoute session="TDS" path="/businfo" component={BusinessUnits} />
				{/* <AuthRoute session="TDS" path="/probes" component={Probes} /> */}
				{/* <AuthRoute session="TDS" path="/dongles/gpr" component={() => <Dongles productFamily="GPR" />} /> */}
				<AuthRoute
					session="TDS"
					path="/dongles/equotip"
					component={() => <Dongles productFamily="EQUOTIP" />}
				/>
				<AuthRoute session="TDS" path="/firmware" component={Firmware} />
				<AuthRoute session="TDS" path="/dev-tools" component={DevTools} />
				<AuthRoute session="TDS" path="/notifications" component={Notifications} />
				<AuthRoute session="TDS" path="/migration" component={Migration} />
				<AuthRoute session="TDS" path="/analytics/:page" component={Analytics} />
				<AuthRoute session="TDS" path="/versions" component={Versions} />
				<AuthRoute session="TDS" path="/usersmngmt" component={Users} />
				<Route path="/m/:product" component={RedirectToEagleWorkspace} />
				<Route path="/h/:product" component={RedirectToEagleWorkspace} />
				{/* <Route
					path="/signup"
					render={() => (
						<LoginPage>
							<SignupForm />
						</LoginPage>
					)}
				/> */}
				<Route
					path="/forgotpassword"
					render={() => (
						<LoginPage>
							<ForgotPasswordForm />
						</LoginPage>
					)}
				/>
				<Route path={routeURLs.LOGOUT} component={Logout} />
				<Route path={routeURLs.AUTH} component={AuthReceiver} />
				<Route path="/launch/:product" component={OldLogin} />
				<Route path="/activate/:product" component={ActivationForm} />
				<Route path="/logintoken" component={LoginOneTimeToken} />
				<Route
					path="/resetpassword"
					component={() => {
						window.location.href = `${EAGLE_ID_RESET}/${location.search}`;
					}}
				/>
				<Route path="/cookies" component={Cookies} />
				<Route path="/:downloadType/:product" component={DownloadRedirect} />
				<Route path="*" component={PageNotFound} />
			</Switch>
		</LanguageRoute>
	</Router>
);
