// @flow
import React, { Fragment } from 'react';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import { DATE_LOGBOOK_FORMAT } from 'utils/dateHandler';
import styles from './UserText.styl';

export const MarkerText = ({ log, scanType }: GPRLogbookEntryProps) => {
	let id = 'App.LogbookMarkerText';
	switch (log.content.status) {
		case 'added':
			id += 'Added';
			break;
		case 'edited':
			id += 'Edited';
			break;
		case 'deleted':
			id += 'Deleted';
			break;
	}

	if (scanType === 'Area Scan') {
		id += 'AtLine';
	}

	return (
		<div>
			{log.content.status === 'deleted' ? (
				<div className={styles.deleted}>
					<FormattedMessage
						id={id}
						values={{
							marker: log.content.sequence,
							lineId: log.content.lineId,
							date: moment(log.clientCreated).format(DATE_LOGBOOK_FORMAT),
						}}
					/>
				</div>
			) : (
				<b>
					<FormattedMessage
						id={id}
						values={{
							marker: log.content.sequence,
							lineId: log.content.lineId,
						}}
					/>
				</b>
			)}

			{log.content.status === 'edited' && (
				<Fragment>
					<br />
					{log.content.content}
				</Fragment>
			)}
		</div>
	);
};

export const color = (log: Log) => (log.content.status === 'deleted' ? 'red' : 'green');

export default MarkerText;
