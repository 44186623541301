// @flow
import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import StaticMap from '../StaticMap';

export const Creation = ({ log }: GPRLogbookEntryProps) => {
	const { latitude, longitude, measurementInfo } = log.content;
	const coordinates = { lat: +latitude, lng: +longitude };
	const {
		folderName,
		fileName,
		measurementMode,
		geometries,
		unit,
		upperLimitFor20,
		upperLimitFor60,
		lowerLimitFor20,
		lowerLimitFor60,
	} = measurementInfo;
	return (
		<div>
			<b>
				<FormattedMessage
					id="Created {fileName} in folder {folderName}"
					values={{
						fileName,
						folderName,
					}}
				/>
			</b>
			<br />
			<FormattedMessage id="Geometries: {value}" values={{ value: geometries }} />
			<br />
			<FormattedMessage id="Unit: {value}" values={{ value: unit }} />
			<br />
			<FormattedMessage id="Measurement Mode: {value}" values={{ value: measurementMode }} />
			<br />
			<FormattedMessage id="20° lower limit: {value}" values={{ value: lowerLimitFor20 }} />
			<br />
			<Fragment>
				<FormattedMessage id="20° upper limit: {value}" values={{ value: upperLimitFor20 }} />
				<br />
			</Fragment>

			<FormattedMessage id="60° lower limit: {value}" values={{ value: lowerLimitFor60 }} />
			<br />
			<FormattedMessage id="60° upper limit: {value}" values={{ value: upperLimitFor60 }} />
			<br />
			<StaticMap width={400} height={200} zoom={16} coordinates={coordinates} />
		</div>
	);
};

export const color = () => 'green';

export default Creation;
