// @flow
import React, { Component } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import type { InjectIntlProvidedProps } from 'react-intl';
import { Modal, Form, Button } from 'antd';
import { checkStatusJson } from 'apis/restUtils';
import { httpRequest } from 'apis/httpClient';
import errorHandler from 'actionUtils/errorHandler';
import { getProceqUrl } from 'utils/sessionHelper';
import { requestDownloadFile } from 'utils/downloadHelper';
import { formatDateToLocale, formatTimeNoSecsToLocale, formatDateTimeNoSecsToLocale } from 'utils/dateHandler';
import URL from 'apis/urls';
// import { DATE_SERVER_FORMAT, DATETIME_SERVER_FORMAT, DATETIMENOSECS_SERVER_FORMAT } from 'utils/dateHandler';
import { getLabelFromGlobalDataKey } from 'utils/registryHelper';
import {
	getResolutionForScale,
	getSchmidtScaleIdOfFromFactorAndUnitId,
	getScaleUnitsOfScaleId,
	getScaleNameOfScaleId,
	getStandardNameOfStandardId,
	getMaterialNameOfMaterialId,
	getSchmidtCompositeSurfaceId,
	isKeyDefinedAndNotNull,
} from 'utils/conversionsHelper';
import handleFormatMessage from 'utils/handleFormatMessage';
import './Logbook.css';

type Props = {
	dataSeriesId: string,
	isVerificationMode?: boolean,
	productType?: ?ProductCode,
	session: Session,
	locale: Locale,
} & InjectIntlProvidedProps;

export class Logbook extends Component<Props, any> {
	googleMaps = {};

	static defaultProps = {
		isVerificationMode: false,
		productType: null,
	};

	state = {
		loading: false,
		visible: false,
		entriesToDisplay: [],

		googleMapsObjectsDisplay: [],
	};

	showGoogleMaps() {
		this.state.googleMapsObjectsDisplay.forEach(entryInfo => {
			if (typeof this.googleMaps !== 'undefined') {
				const thisLocation = { lat: entryInfo.lat, lng: entryInfo.long };
				this.googleMaps[entryInfo.id] = new window.google.maps.Map(document.getElementById(entryInfo.id), {
					center: thisLocation,
					zoom: 16,
				});
			}
		});
	}

	showLogbook = () => {
		this.setState({
			loading: true,
		});

		return httpRequest(
			this.props.session,
			this.props.locale,
			getProceqUrl(this.props.session, URL.PROCEQ_GET_LOGBOOK),
			'post',
			true,
			{ data_series_id: this.props.dataSeriesId }
		)
			.then(checkStatusJson)
			.then(json => {
				this.parseLogbookData(json.data);
			})
			.catch(err => {
				this.setState({
					loading: false,
				});
				errorHandler(err);
			});
	};

	handleOk = () => {
		this.setState({ loading: true });
		setTimeout(() => {
			this.setState({ loading: false, visible: false });
		}, 3000);
	};

	handleCancel = () => {
		this.setState({ visible: false, googleMapsObjectsDisplay: [] });
	};

	render() {
		const { intl } = this.props,
			{ formatMessage } = intl;
		const { visible, loading } = this.state;
		return (
			<div>
				<Button type="primary" onClick={() => this.showLogbook()} size="large" loading={loading}>
					{handleFormatMessage('DataSeriesLogbook', formatMessage)}
				</Button>
				{visible ? (
					<Modal
						visible={visible}
						title={handleFormatMessage('LogbookTitle', formatMessage)}
						onOk={this.handleOk}
						onCancel={this.handleCancel}
						footer={[
							<Button key="back" size="large" onClick={this.handleCancel}>
								{handleFormatMessage('LogbookClose', formatMessage)}
							</Button>,
						]}
						className="logbookModal"
					>
						<div id="chat-body" className="chat-body custom-scroll">
							<div id="map" />
							{this.state.entriesToDisplay.map((entryToDisplay, indexEntry) => {
								return (
									<ul key={indexEntry}>
										<li
											className={`message ${
												entryToDisplay.typeAttachment ? ' messageWithAttachment ' : ''
											}${indexEntry === 0 ? ' firstEntry ' : ''}`}
										>
											{entryToDisplay.precontent ? (
												entryToDisplay.type === 'initialInfoLogEvent' ? (
													<div className="full-container intro-container">
														<div>
															<div className="initial-image">
																<img
																	alt=""
																	height="100"
																	width="100"
																	src={this.getProbeImage(
																		entryToDisplay.primaryScaleId
																	)}
																/>
															</div>
															<div className="initial-content">
																<b>{entryToDisplay.precontent.header}</b>
																<br />
																<pre>{entryToDisplay.precontent.text}</pre>
															</div>
														</div>
													</div>
												) : (
													<div className="full-container">
														<div>{entryToDisplay.precontent.text}</div>
													</div>
												)
											) : (
												''
											)}
											{entryToDisplay.newdate ? (
												<div className="full-container date-container">
													<div>
														<b>{entryToDisplay.newdate}</b>
													</div>
												</div>
											) : (
												''
											)}

											<div style={{ position: 'relative' }} className="content-container">
												<div className="message-text">
													<time>
														<span className="message-title">{entryToDisplay.title}</span>
														{entryToDisplay.time}
														<span className="username">{entryToDisplay.usershort}</span>
													</time>
													<pre>{entryToDisplay.body}</pre>
													{entryToDisplay.location ? (
														<div
															className="google-map"
															id={`google-map-${this.props.dataSeriesId}`}
															data-lat={entryToDisplay.location.lat}
															data-long={entryToDisplay.location.long}
														/>
													) : (
														''
													)}

													{entryToDisplay.typeAttachment && entryToDisplay.fileId ? (
														entryToDisplay.typeAttachment === 'photo' ? (
															<div className="chat-file row">
																<div
																	onClick={() => {
																		this.downloadAttachmentFile(
																			entryToDisplay.fileId
																		);
																	}}
																	className="attachment_img_thumbnail_div"
																>
																	{entryToDisplay.thumbnail ? (
																		<img
																			src={`data:${entryToDisplay.thumbnail}`}
																			alt="thumbnail"
																		/>
																	) : (
																		''
																	)}
																</div>
																<span className="pull-right message-buttons">
																	<a
																		onClick={e => {
																			e.preventDefault();
																			this.downloadAttachmentFile(
																				entryToDisplay.fileId
																			);
																		}}
																		className="btn-download"
																	>
																		{handleFormatMessage(
																			'LogbookDownload',
																			formatMessage
																		)}
																	</a>
																</span>
															</div>
														) : entryToDisplay.typeAttachment === 'audio' ? (
															<div className="chat-file row">
																<div>
																	<div className="attachment_img_thumbnail_div">
																		<audio controls controlsList="nodownload">
																			<source
																				src={`data:${entryToDisplay.thumbnail}`}
																				type="audio/mp3"
																			/>
																			{handleFormatMessage(
																				'BrowserNoSupportAudio',
																				formatMessage
																			)}
																		</audio>
																	</div>
																</div>
																<span className="pull-right message-buttons">
																	<a
																		onClick={e => {
																			e.preventDefault();
																			this.downloadAttachmentFile(
																				entryToDisplay.fileId
																			);
																		}}
																		className="btn-download"
																	>
																		{handleFormatMessage(
																			'LogbookDownload',
																			formatMessage
																		)}
																	</a>
																</span>
															</div>
														) : (
															''
														)
													) : (
														''
													)}
												</div>
												<div
													className={`message-color message-color-${entryToDisplay.color}`}
												/>
												<div className="bckg-message-circle" />
												<div className="message-line" />
											</div>
										</li>
									</ul>
								);
							}, this)}
						</div>
					</Modal>
				) : (
					''
				)}
			</div>
		);
	}

	componentDidUpdate() {
		setTimeout(() => {
			this.showGoogleMaps();
		}, 1000);
	}

	parseLogbookData(alldata: Object) {
		const { intl } = this.props,
			{ formatMessage } = intl;

		/* this.entriesToDisplay = [];
		this.usersInfo = {};
		this.thumbnailsInfo = {}; */
		const productType = this.props.session.productType;
		const isVerificationMode = this.props.isVerificationMode;

		const newEntriesToDisplay = [];
		const newUsersInfo = {};
		let newThumbnailsInfo = {};
		const newGoogleMapsObjectsDisplay = [];

		alldata.users_info.forEach(entryInfo => {
			newUsersInfo[entryInfo.user_id] = entryInfo;
		});
		newThumbnailsInfo = alldata.thumbnails_info;

		if (!isKeyDefinedAndNotNull(alldata, 'logbook')) {
			return;
		}

		const parsedLogbook = JSON.parse(alldata.logbook);

		let lastEntryDate = '';
		let primarySchmidtScaleId = getSchmidtScaleIdOfFromFactorAndUnitId(null, null);
		let primaryEquotipScaleId = 2;
		let primaryVerificationScaleId = 2;
		let primaryVerificationScaleName = '';

		parsedLogbook.forEach(entryInfo => {
			let newEntryDisplay: Object = {
				title: null,
				action: null,
				body: '',
			};
			let infoElement;
			let colorEntry = 'green';

			switch (
				entryInfo.logEventType // eventType => logEventType
			) {
				case 'initialInfoLogEvent': {
					infoElement = entryInfo.initialInfoDetails;
					let locationObject = null;
					if (
						'location' in infoElement &&
						'lat' in infoElement.location &&
						infoElement.location.lat !== null &&
						'long' in infoElement.location &&
						infoElement.location.long !== null
					) {
						locationObject = infoElement.location;
						newGoogleMapsObjectsDisplay.push({
							lat: infoElement.location.lat,
							long: infoElement.location.long,
							id: `google-map-${this.props.dataSeriesId}`,
						});
					}

					let foundPrimaryScaleId = null;
					if ('primaryScaleId' in infoElement) {
						primaryEquotipScaleId = infoElement.primaryScaleId;
						primarySchmidtScaleId = infoElement.primaryScaleId;
						foundPrimaryScaleId = infoElement.primaryScaleId;
						primaryVerificationScaleId = infoElement.primaryScaleId;
					}

					if (isVerificationMode) {
						switch (infoElement.probeTypeID) {
							case 0: // EQUOTIP D
							default:
								primaryVerificationScaleId = 2;
								primaryVerificationScaleName = 'Leeb D';
								break;
							case 1: // EQUOTIP UCI
								primaryVerificationScaleId = 25;
								primaryVerificationScaleName = 'UCI Vickers';
								break;
							case 2: // SCHMIDT L
								primaryVerificationScaleId = getSchmidtScaleIdOfFromFactorAndUnitId(null, null);
								primaryVerificationScaleName = 'R';
								break;
							case 3: // SCHMIDT N
								primaryVerificationScaleId = getSchmidtScaleIdOfFromFactorAndUnitId(null, null);
								primaryVerificationScaleName = 'R';
								break;
							case 4: // SILVER SCHMIDT L
								primaryVerificationScaleId = 1000006;
								primaryVerificationScaleName = 'Q';
								break;
							case 5: // SILVER SCHMIDT N
								primaryVerificationScaleId = 1000006;
								primaryVerificationScaleName = 'Q';
								break;
						}
					}
					const creationTextString = isVerificationMode
						? 'LogbookPropertyCreatedVerification'
						: 'LogbookPropertyCreatedSeriesInFolder';

					let bodyContent = `${handleFormatMessage(creationTextString, formatMessage, [
						infoElement.seriesName,
						infoElement.folderName,
					])}\r\n`;
					if ('triggerLoadId' in infoElement) {
						bodyContent +=
							`${handleFormatMessage('LogBookTestLoad', formatMessage)}: ` +
							`HV${infoElement.triggerLoadId} (${infoElement.triggerLoadId}kgf)` +
							'\r\n';
					}
					if (isVerificationMode) {
						bodyContent += `${handleFormatMessage(
							'ExportFieldStandard',
							formatMessage
						)}: ${getStandardNameOfStandardId(
							null,
							formatMessage,
							infoElement.standardID,
							productType
						)}\r\n`;
						if ('testBlockTitle' in infoElement) {
							if (productType === 'EQUOTIP')
								bodyContent += `${handleFormatMessage('LogBookTestBlock', formatMessage)}: ${
									infoElement.testBlockTitle
								}\r\n`;
							else
								bodyContent += `${handleFormatMessage('LogBookCalibrationAnvil', formatMessage)}: ${
									infoElement.testBlockTitle
								}\r\n`;
						}

						bodyContent += `${handleFormatMessage(
							'ExportFieldPrimaryScale',
							formatMessage
						)}: ${primaryVerificationScaleName}\r\n`;
						if ('minLimitValue' in infoElement) {
							bodyContent += `${handleFormatMessage(
								'ExportFieldLowerLimit',
								formatMessage
							)}: ${getResolutionForScale(
								getScaleUnitsOfScaleId(productType, formatMessage, primaryVerificationScaleId),
								infoElement.minLimitValue.primaryValue,
								'limit'
							)} ${getScaleUnitsOfScaleId(productType, formatMessage, primaryVerificationScaleId)}\r\n`;
						}
						if ('maxLimitValue' in infoElement) {
							bodyContent += `${handleFormatMessage(
								'ExportFieldUpperLimit',
								formatMessage
							)}: ${getResolutionForScale(
								getScaleUnitsOfScaleId(productType, formatMessage, primaryVerificationScaleId),
								infoElement.maxLimitValue.primaryValue,
								'limit'
							)} ${getScaleUnitsOfScaleId(productType, formatMessage, primaryVerificationScaleId)}\r\n`;
						}
					} else {
						switch (productType) {
							case 'EQUOTIP':
								bodyContent += `${handleFormatMessage(
									'ExportFieldMaterial',
									formatMessage
								)}: ${getMaterialNameOfMaterialId(
									null,
									formatMessage,
									infoElement.materialID
								)}\r\n${handleFormatMessage(
									'ExportFieldStandard',
									formatMessage
								)}: ${getStandardNameOfStandardId(
									null,
									formatMessage,
									infoElement.standardID,
									productType
								)}\r\n${handleFormatMessage(
									'ExportFieldPrimaryScale',
									formatMessage
								)}: ${getScaleNameOfScaleId(productType, formatMessage, primaryEquotipScaleId)}\r\n`;

								bodyContent += `${handleFormatMessage(
									'ExportFieldSecondaryScale',
									formatMessage
								)}: ${getScaleNameOfScaleId(
									productType,
									formatMessage,
									infoElement.secondaryScaleId
								)}\r\n`;

								if ('minLimitValue' in infoElement) {
									bodyContent += `${handleFormatMessage(
										'ExportFieldLowerLimit',
										formatMessage
									)}: ${getResolutionForScale(
										getScaleUnitsOfScaleId(productType, formatMessage, primaryEquotipScaleId),
										infoElement.minLimitValue.primaryValue,
										'limit'
									)} ${getScaleUnitsOfScaleId(productType, formatMessage, primaryEquotipScaleId)} / `;
									if ('secondaryValue' in infoElement.minLimitValue) {
										bodyContent += `${getResolutionForScale(
											getScaleUnitsOfScaleId(
												productType,
												formatMessage,
												infoElement.minLimitValue.secondaryScaleId
											),
											infoElement.minLimitValue.secondaryValue,
											'limit'
										)} ${getScaleUnitsOfScaleId(
											productType,
											formatMessage,
											infoElement.minLimitValue.secondaryScaleId
										)}\r\n`;
									} else {
										bodyContent += `${handleFormatMessage(
											'LogbookNoConversionValue',
											formatMessage
										)} ${getScaleUnitsOfScaleId(
											productType,
											formatMessage,
											infoElement.minLimitValue.secondaryScaleId
										)}\r\n`;
									}
								}

								if ('maxLimitValue' in infoElement) {
									bodyContent += `${handleFormatMessage(
										'ExportFieldUpperLimit',
										formatMessage
									)}: ${getResolutionForScale(
										getScaleUnitsOfScaleId(productType, formatMessage, primaryEquotipScaleId),
										infoElement.maxLimitValue.primaryValue,
										'limit'
									)} ${getScaleUnitsOfScaleId(productType, formatMessage, primaryEquotipScaleId)} / `;
									if ('secondaryValue' in infoElement.maxLimitValue) {
										bodyContent += `${getResolutionForScale(
											getScaleUnitsOfScaleId(
												productType,
												formatMessage,
												infoElement.maxLimitValue.secondaryScaleId
											),
											infoElement.maxLimitValue.secondaryValue,
											'limit'
										)} ${getScaleUnitsOfScaleId(
											productType,
											formatMessage,
											infoElement.maxLimitValue.secondaryScaleId
										)}\r\n`;
									} else {
										bodyContent += `${handleFormatMessage(
											'LogbookNoConversionValue',
											formatMessage
										)} ${getScaleUnitsOfScaleId(
											productType,
											formatMessage,
											infoElement.maxLimitValue.secondaryScaleId
										)}\r\n`;
									}
								}
								break;

							case 'SSL':
							case 'SCHMIDT': {
								const targetScaleId = getSchmidtScaleIdOfFromFactorAndUnitId(
									infoElement.unitId,
									infoElement.formFactorId
								);

								bodyContent += `${handleFormatMessage(
									'ExportFieldStandard',
									formatMessage
								)}: ${getStandardNameOfStandardId(
									null,
									formatMessage,
									infoElement.standardID,
									productType
								)}\r\n`;

								if (typeof infoElement.conversionCurveId !== 'undefined') {
									bodyContent += `${handleFormatMessage(
										'ExportFieldConversionCurve',
										formatMessage
									)}: ${handleFormatMessage(
										getLabelFromGlobalDataKey('convcurveschmidt', infoElement.conversionCurveId),
										formatMessage
									)}\r\n`;
								}

								if (typeof infoElement.correctionFactor !== 'undefined') {
									bodyContent += `${formatMessage({
										id: 'App.LogbookPropertyCorrectionFactor',
									})}: ${infoElement.correctionFactor.toFixed(1)}\r\n`;
								}

								bodyContent += `${handleFormatMessage(
									'ExportFieldPrimaryScale',
									formatMessage
								)}: ${getLabelFromGlobalDataKey(
									'unitsschmidt',
									infoElement.unitId
								)}\r\n${handleFormatMessage(
									'ExportFieldTargetScale',
									formatMessage
								)}: ${getScaleNameOfScaleId(
									productType,
									formatMessage,
									targetScaleId
								)}\r\n${handleFormatMessage(
									'ExportFieldLowerLimit',
									formatMessage
								)}: ${getResolutionForScale(
									getScaleUnitsOfScaleId(productType, formatMessage, primarySchmidtScaleId),
									infoElement.minLimitValue.primaryValue,
									'limit'
								)} ${getScaleUnitsOfScaleId(
									productType,
									formatMessage,
									primarySchmidtScaleId
								)} / ${getResolutionForScale(
									getScaleUnitsOfScaleId(productType, formatMessage, targetScaleId),
									infoElement.minLimitValue.secondaryValue,
									'limit'
								)} ${getScaleNameOfScaleId(
									productType,
									formatMessage,
									targetScaleId
								)}\r\n${handleFormatMessage(
									'ExportFieldUpperLimit',
									formatMessage
								)}: ${getResolutionForScale(
									getScaleUnitsOfScaleId(productType, formatMessage, primarySchmidtScaleId),
									infoElement.maxLimitValue.primaryValue,
									'limit'
								)} ${getScaleUnitsOfScaleId(
									productType,
									formatMessage,
									primarySchmidtScaleId
								)} / ${getResolutionForScale(
									getScaleUnitsOfScaleId(productType, formatMessage, targetScaleId),
									infoElement.maxLimitValue.secondaryValue,
									'limit'
								)} ${getScaleNameOfScaleId(productType, formatMessage, targetScaleId)}\r\n`;
								break;
							}
						}
					}

					newEntryDisplay = {
						title: null, // "#"+ entryInfo['measurementIndex']+ " "+ entryInfo['text'],
						action: handleFormatMessage('LogbookActionNewMeasurement', formatMessage),
						body: bodyContent,
						typeAttachment: null,
						location: locationObject,
						primaryScaleId: foundPrimaryScaleId,
					};

					let contentText = `${handleFormatMessage('LogbookPropertySerialNumberShort', formatMessage)}: ${
						infoElement.probeSerialNumber
					}\r\n${handleFormatMessage('LogbookPropertyBluetoothModuleShort', formatMessage)}: ${
						infoElement.btSerialNumber
					}\r\n`;

					if (
						'probeVerificationDate' in infoElement &&
						typeof infoElement.probeVerificationDate !== 'undefined'
					) {
						contentText += `${handleFormatMessage(
							'LogbookPropertyVerified',
							formatMessage
						)}: ${formatDateToLocale(infoElement.probeVerificationDate.split(' ')[0])}\r\n`;
					} else {
						contentText += `${handleFormatMessage(
							'LogbookPropertyVerified',
							formatMessage
						)}: ${handleFormatMessage('Unknown', formatMessage)}\r\n`;
					}

					if ('contractProductTypeID' in infoElement) {
						contentText += `${handleFormatMessage(
							'LogbookContractType',
							formatMessage
						)}: ${handleFormatMessage(
							getLabelFromGlobalDataKey('proceqregisterproducts', infoElement.contractProductTypeID),
							formatMessage
						)}\r\n`;
					}

					contentText += `${handleFormatMessage('LogbookPropertyContractValidUntil', formatMessage)}: ${
						'contractExpirationDate' in infoElement &&
						typeof infoElement.contractExpirationDate !== 'undefined'
							? formatDateToLocale(infoElement.contractExpirationDate.split(' ')[0])
							: ''
					}\r\n${handleFormatMessage('LogbookPropertyOperator', formatMessage)}: ${
						isKeyDefinedAndNotNull(newUsersInfo, entryInfo.userId)
							? `${newUsersInfo[entryInfo.userId].first_name} ${newUsersInfo[entryInfo.userId].last_name}`
							: ''
					}`;

					// $FlowFixMe
					newEntryDisplay.precontent = {
						header: infoElement.probeType,
						text: contentText,
					};

					break;
				}

				case 'renameLogEvent': {
					colorEntry = 'blue';
					infoElement = entryInfo.renameDetails;
					const eventTextString = isVerificationMode
						? 'LogbookPropertyRenamedVerificationFromTo'
						: 'LogbookPropertyRenamedSeriesFromTo';
					newEntryDisplay.body = `${handleFormatMessage(eventTextString, formatMessage, [
						infoElement.source,
						infoElement.destination,
					])} `;

					break;
				}

				case 'trashLogEvent':
					colorEntry = 'gray';
					infoElement = entryInfo.trashDetails;

					if (infoElement.status) {
						newEntryDisplay.body = `${handleFormatMessage(
							'LogbookPropertyMovedSeriesFromToTrash',
							formatMessage,
							[infoElement.folder]
						)} `;
					} else {
						newEntryDisplay.body = `${handleFormatMessage(
							'LogbookPropertyMovedSeriesFromTrashTo',
							formatMessage,
							[infoElement.folder]
						)} `;
					}
					break;

				case 'moveLogEvent':
					colorEntry = 'blue';
					infoElement = entryInfo.moveDetails;
					newEntryDisplay.body = `${handleFormatMessage('LogbookPropertyMovedSeriesFromTo', formatMessage, [
						infoElement.source,
						infoElement.destination,
					])} `;
					break;

				case 'scaleLogEvent':
					colorEntry = 'blue';
					infoElement = entryInfo.scaleDetails;

					newEntryDisplay.body = `${handleFormatMessage(
						'LogbookPropertySecondaryScaleChangedFromTo',
						formatMessage,
						[
							getScaleUnitsOfScaleId(productType, formatMessage, infoElement.oldScaleId),
							getScaleUnitsOfScaleId(productType, formatMessage, infoElement.updatedScaleId),
						]
					)} `;
					break;

				case 'conversionCurveLogEvent': {
					colorEntry = 'blue';
					infoElement = entryInfo.conversionCurveDetails;
					const enumToUse = 'convcurveschmidt';
					if (
						typeof infoElement.oldConversionCurveId !== 'undefined' ||
						typeof infoElement.oldCustomMaterialName !== 'undefined'
					) {
						const oldConversionName =
							typeof infoElement.oldCustomMaterialName !== 'undefined'
								? infoElement.oldCustomMaterialName
								: handleFormatMessage(
										getLabelFromGlobalDataKey(enumToUse, infoElement.oldConversionCurveId),
										formatMessage
								  );
						const updatedConversionName =
							typeof infoElement.updatedCustomMaterialName !== 'undefined'
								? infoElement.updatedCustomMaterialName
								: handleFormatMessage(
										getLabelFromGlobalDataKey(enumToUse, infoElement.updatedConversionCurveId),
										formatMessage
								  );

						newEntryDisplay.body = `${handleFormatMessage(
							'LogbookPropertyConversionCurveChangedFromTo',
							formatMessage,
							[oldConversionName, updatedConversionName]
						)} `;
					} else {
						const updatedConversionName =
							typeof infoElement.updatedCustomMaterialName !== 'undefined'
								? infoElement.updatedCustomMaterialName
								: handleFormatMessage(
										getLabelFromGlobalDataKey(enumToUse, infoElement.updatedConversionCurveId),
										formatMessage
								  );

						newEntryDisplay.body = `${handleFormatMessage(
							'LogbookPropertyConversionCurveChangedTo',
							formatMessage,
							[updatedConversionName]
						)} `;
					}
					break;
				}

				case 'targetScaleLogEvent': {
					colorEntry = 'blue';
					infoElement = entryInfo.targetScaleDetails;

					const oldScaleId = getSchmidtScaleIdOfFromFactorAndUnitId(
						infoElement.oldUnitId,
						infoElement.oldFormFactorId
					);
					const updatedScaleId = getSchmidtScaleIdOfFromFactorAndUnitId(
						infoElement.updatedUnitId,
						infoElement.updatedFormFactorId
					);
					newEntryDisplay.body = `${handleFormatMessage(
						'LogbookPropertyTargetScaleChangedFromTo',
						formatMessage,
						[
							getScaleNameOfScaleId(productType, formatMessage, oldScaleId),
							getScaleNameOfScaleId(productType, formatMessage, updatedScaleId),
						]
					)} `;
					break;
				}

				case 'carbonationDepthLogEvent':
					colorEntry = 'blue';
					infoElement = entryInfo.carbonationDepthDetails;

					newEntryDisplay.body = `${handleFormatMessage(
						'LogbookPropertyCarbonationDepthChangedFromTo',
						formatMessage,
						[infoElement.oldCarbonationDepth, infoElement.updatedCarbonationDepth]
					)} `;
					break;

				case 'concreteAgeLogEvent':
					colorEntry = 'blue';
					infoElement = entryInfo.concreteAgeDetails;

					if (typeof infoElement.oldAge !== 'undefined') {
						newEntryDisplay.body = `${handleFormatMessage(
							'LogbookPropertyConcreteAgeChangedFromTo',
							formatMessage,
							[infoElement.oldAge, infoElement.updatedAge]
						)} `;
					} else {
						newEntryDisplay.body = `${handleFormatMessage(
							'LogbookPropertyConcreteAgeChangedTo',
							formatMessage,
							[infoElement.updatedAge]
						)} `;
					}

					break;
				case 'surfaceConditionLogEvent':
					colorEntry = 'blue';
					infoElement = entryInfo.surfaceConditionDetails;

					if (typeof infoElement.oldSurfaceConditionId !== 'undefined') {
						newEntryDisplay.body = `${handleFormatMessage(
							'LogbookPropertySurfaceConditionChangedFromTo',
							formatMessage,
							[
								handleFormatMessage(
									getLabelFromGlobalDataKey(
										'surfaceconditionschmidt',
										infoElement.oldSurfaceConditionId
									),
									formatMessage
								),
								handleFormatMessage(
									getLabelFromGlobalDataKey(
										'surfaceconditionschmidt',
										infoElement.updatedSurfaceConditionId
									),
									formatMessage
								),
							]
						)} `;
					} else {
						newEntryDisplay.body = `${handleFormatMessage(
							'LogbookPropertySurfaceConditionChangedTo',
							formatMessage,
							[
								handleFormatMessage(
									getLabelFromGlobalDataKey(
										'surfaceconditionschmidt',
										infoElement.updatedSurfaceConditionId
									),
									formatMessage
								),
							]
						)} `;
					}
					break;

				case 'surfaceCorrectionLogEvent': {
					colorEntry = 'blue';
					infoElement = entryInfo.surfaceCorrectionDetails;

					const oldCompositeSurfaceId = getSchmidtCompositeSurfaceId(
						infoElement.oldStrengthId,
						infoElement.oldSurfaceCorrectionId
					);
					const updatedCompositeSurfaceId = getSchmidtCompositeSurfaceId(
						infoElement.updatedStrengthId,
						infoElement.updatedSurfaceCorrectionId
					);

					const updatedCompositeSurfaceName = handleFormatMessage(
						getLabelFromGlobalDataKey('surfacestrengthandcorrschmidt', updatedCompositeSurfaceId),
						formatMessage
					);
					if (oldCompositeSurfaceId !== null) {
						const oldCompositeSurfaceName = handleFormatMessage(
							getLabelFromGlobalDataKey('surfacestrengthandcorrschmidt', oldCompositeSurfaceId),
							formatMessage
						);

						newEntryDisplay.body = `${handleFormatMessage(
							'LogbookPropertySurfaceCorrectionChangedFromTo',
							formatMessage,
							[oldCompositeSurfaceName, updatedCompositeSurfaceName]
						)} `;
					} else {
						newEntryDisplay.body = `${handleFormatMessage(
							'LogbookPropertySurfaceCorrectionChangedTo',
							formatMessage,
							[updatedCompositeSurfaceName]
						)} `;
					}

					break;
				}

				case 'materialLogEvent': {
					colorEntry = 'blue';
					infoElement = entryInfo.materialDetails;
					const oldMaterialName =
						typeof infoElement.oldCustomMaterialName !== 'undefined'
							? infoElement.oldCustomMaterialName
							: getMaterialNameOfMaterialId(null, formatMessage, infoElement.oldMaterialId);
					const updatedMaterialName =
						typeof infoElement.updatedCustomMaterialName !== 'undefined'
							? infoElement.updatedCustomMaterialName
							: getMaterialNameOfMaterialId(null, formatMessage, infoElement.updatedMaterialId);

					newEntryDisplay.body = `${handleFormatMessage(
						'LogbookPropertyMaterialChangedFromTo',
						formatMessage,
						[oldMaterialName, updatedMaterialName]
					)} `;

					break;
				}

				case 'favouriteLogEvent':
					colorEntry = 'blue';
					infoElement = entryInfo.favouriteDetails;
					newEntryDisplay.body = handleFormatMessage('LogbookPropertySeriesFlagged', formatMessage);
					if (!entryInfo.favouriteDetails.status) {
						newEntryDisplay.body = handleFormatMessage('LogbookPropertySeriesUnflagged', formatMessage);
					}
					break;

				case 'limitLogEvent': {
					colorEntry = 'blue';
					infoElement = entryInfo.limitDetails;
					let primaryUnits = getScaleUnitsOfScaleId(productType, formatMessage, primaryEquotipScaleId);
					const secondaryUnits = getScaleUnitsOfScaleId(
						productType,
						formatMessage,
						infoElement.oldLimitValue.secondaryScaleId
					);
					const newSecondaryUnits = getScaleUnitsOfScaleId(
						productType,
						formatMessage,
						infoElement.updatedLimitValue.secondaryScaleId
					);
					let fromValue = '';
					let toValue = '';
					if (isVerificationMode) {
						primaryUnits = getScaleUnitsOfScaleId(productType, formatMessage, primaryVerificationScaleId);
						fromValue = `${getResolutionForScale(
							primaryUnits,
							infoElement.oldLimitValue.primaryValue,
							'limit'
						)} ${primaryUnits}`;
						toValue = `${getResolutionForScale(
							primaryUnits,
							infoElement.updatedLimitValue.primaryValue,
							'limit'
						)} ${primaryUnits}`;
					} else {
						switch (productType) {
							case 'EQUOTIP':
								fromValue = `${getResolutionForScale(
									primaryUnits,
									infoElement.oldLimitValue.primaryValue,
									'limit'
								)} ${primaryUnits} / ${getResolutionForScale(
									secondaryUnits,
									infoElement.oldLimitValue.secondaryValue,
									'limit'
								)} ${secondaryUnits}`;
								toValue = `${getResolutionForScale(
									primaryUnits,
									infoElement.updatedLimitValue.primaryValue,
									'limit'
								)} ${primaryUnits} / ${getResolutionForScale(
									newSecondaryUnits,
									infoElement.updatedLimitValue.secondaryValue,
									'limit'
								)} ${newSecondaryUnits}`;

								break;
							case 'SSL':
							case 'SCHMIDT': {
								const oldScaleId = getSchmidtScaleIdOfFromFactorAndUnitId(
									infoElement.oldLimitValue.unitId,
									infoElement.oldLimitValue.formFactorId
								);
								const updatedScaleId = getSchmidtScaleIdOfFromFactorAndUnitId(
									infoElement.updatedLimitValue.unitId,
									infoElement.updatedLimitValue.formFactorId
								);

								fromValue = `${getResolutionForScale(
									getScaleUnitsOfScaleId(productType, formatMessage, primarySchmidtScaleId),
									infoElement.oldLimitValue.primaryValue,
									'limit'
								)} ${getScaleUnitsOfScaleId(
									productType,
									formatMessage,
									primarySchmidtScaleId
								)} / ${getResolutionForScale(
									getScaleUnitsOfScaleId(productType, formatMessage, oldScaleId),
									infoElement.oldLimitValue.secondaryValue,
									'limit'
								)} ${getScaleNameOfScaleId(productType, formatMessage, oldScaleId)}`;

								toValue = `${getResolutionForScale(
									getScaleUnitsOfScaleId(productType, formatMessage, primarySchmidtScaleId),
									infoElement.updatedLimitValue.primaryValue,
									'limit'
								)} ${getScaleUnitsOfScaleId(
									productType,
									formatMessage,
									primarySchmidtScaleId
								)} / ${getResolutionForScale(
									getScaleUnitsOfScaleId(productType, formatMessage, updatedScaleId),
									infoElement.updatedLimitValue.secondaryValue,
									'limit'
								)} ${getScaleNameOfScaleId(productType, formatMessage, updatedScaleId)}`;

								break;
							}
						}
					}

					if (infoElement.isLimitMax) {
						newEntryDisplay.body = handleFormatMessage(
							'LogbookPropertyMaxLimitChangedFromTo',
							formatMessage,
							[fromValue, toValue]
						);
					} else {
						newEntryDisplay.body = handleFormatMessage(
							'LogbookPropertyMinLimitChangedFromTo',
							formatMessage,
							[fromValue, toValue]
						);
					}

					break;
				}
				case 'resultLogEvent':
					colorEntry = 'red';
					infoElement = entryInfo.resultDetails;
					newEntryDisplay.body = handleFormatMessage('LogbookResultVerificationFailed', formatMessage);
					if (typeof infoElement.result !== 'undefined' && infoElement.result === 'success') {
						colorEntry = 'green';
						newEntryDisplay.body = handleFormatMessage('LogbookResultVerificationPassed', formatMessage);
					}
					break;

				case 'testBlockLogEvent':
					colorEntry = 'blue';
					infoElement = entryInfo.testBlockDetails;
					if (typeof infoElement.oldTestBlockTitle !== 'undefined') {
						newEntryDisplay.body = `${handleFormatMessage(
							'LogbookPropertyTestBlockChangedFromTo',
							formatMessage,
							[infoElement.oldTestBlockTitle, infoElement.updatedTestBlockTitle]
						)} `;
					} else {
						newEntryDisplay.body = `${handleFormatMessage(
							'LogbookPropertyTestBlockChangedTo',
							formatMessage,
							[infoElement.updatedTestBlockTitle]
						)} `;
					}

					break;
				case 'testLoadLogEvent':
					colorEntry = 'blue';
					infoElement = entryInfo.testLoadDetails;
					if (typeof infoElement.oldTriggerLoadId !== 'undefined') {
						newEntryDisplay.body = `${handleFormatMessage(
							'LogbookPropertyTestLoadChangedFromTo',
							formatMessage,
							[`HV${infoElement.oldTriggerLoadId}`, `HV${infoElement.updatedTriggerLoadId}`]
						)} `;
					} else {
						newEntryDisplay.body = `${handleFormatMessage(
							'LogbookPropertyTestLoadChangedTo',
							formatMessage,
							[`HV${infoElement.updatedTriggerLoadId}`]
						)} `;
					}
					break;

				case 'standardLogEvent':
					colorEntry = 'blue';
					infoElement = entryInfo.standardDetails;

					newEntryDisplay.body = handleFormatMessage('LogbookPropertyStandardChangedFromTo', formatMessage, [
						getStandardNameOfStandardId(null, formatMessage, infoElement.oldStandardId, productType),
						getStandardNameOfStandardId(null, formatMessage, infoElement.updatedStandardId, productType),
					]);
					break;

				case 'textLogEvent':
					colorEntry = 'green';
					newEntryDisplay.body = `${handleFormatMessage(
						'LogbookPropertyTextCommentAdded',
						formatMessage
					)}\r\n${entryInfo.text}`;

					if ('deleteDate' in entryInfo) {
						colorEntry = 'red';
						newEntryDisplay.body = `${handleFormatMessage(
							'LogbookPropertyTextCommentAdded',
							formatMessage
						)}\r\n${handleFormatMessage('LogbookPropertyTextCommentDeletedOn', formatMessage, [
							formatDateTimeNoSecsToLocale(entryInfo.deleteDate),
						])}`;
					}
					break;
				case 'measurementLogEvent': {
					let actionText = null;
					switch (entryInfo.measurementDetails.eventType) {
						case 0:
							actionText = 'LogbookPropertyMeasurementAdded'; // "added";
							colorEntry = 'green';
							break;
						case 1:
							actionText = 'LogbookPropertyMeasurementExcluded'; // "excluded";
							colorEntry = 'gray';
							break;
						case 2:
							actionText = 'LogbookPropertyMeasurementIncluded'; // "included";
							colorEntry = 'green';
							break;
						case 3:
							actionText = 'LogbookPropertyMeasurementDeleted'; // "deleted";
							colorEntry = 'red';
							break;
					}

					let bodyContent = `#${entryInfo.measurementDetails.measurementIndex} `;
					if (isVerificationMode) {
						bodyContent += `${getResolutionForScale(
							getScaleUnitsOfScaleId(productType, formatMessage, primaryVerificationScaleId),
							entryInfo.measurementDetails.measurementValue.primaryValue,
							'point'
						)} ${getScaleUnitsOfScaleId(productType, formatMessage, primaryVerificationScaleId)}`;
					} else {
						switch (productType) {
							case 'EQUOTIP':
								bodyContent += `${getResolutionForScale(
									getScaleUnitsOfScaleId(productType, formatMessage, primaryEquotipScaleId),
									entryInfo.measurementDetails.measurementValue.primaryValue,
									'point'
								)} ${getScaleUnitsOfScaleId(
									productType,
									formatMessage,
									primaryEquotipScaleId
								)} / ${getResolutionForScale(
									getScaleUnitsOfScaleId(
										productType,
										formatMessage,
										entryInfo.measurementDetails.measurementValue.secondaryScaleId
									),
									entryInfo.measurementDetails.measurementValue.secondaryValue,
									'point'
								)} ${getScaleUnitsOfScaleId(
									productType,
									formatMessage,
									entryInfo.measurementDetails.measurementValue.secondaryScaleId
								)}`;

								break;
							case 'SSL':
							case 'SCHMIDT': {
								const targetScaleId = getSchmidtScaleIdOfFromFactorAndUnitId(
									entryInfo.measurementDetails.measurementValue.unitId,
									entryInfo.measurementDetails.measurementValue.formFactorId
								);
								bodyContent += `${getResolutionForScale(
									getScaleUnitsOfScaleId(productType, formatMessage, primarySchmidtScaleId),
									entryInfo.measurementDetails.measurementValue.primaryValue,
									'point'
								)} ${getScaleUnitsOfScaleId(
									productType,
									formatMessage,
									primarySchmidtScaleId
								)} / ${getResolutionForScale(
									getScaleUnitsOfScaleId(productType, formatMessage, targetScaleId),
									entryInfo.measurementDetails.measurementValue.secondaryValue,
									'point'
								)} ${getScaleNameOfScaleId(productType, formatMessage, targetScaleId)}`;

								break;
							}
						}
					}
					newEntryDisplay.body = handleFormatMessage(actionText, formatMessage, [bodyContent]);

					break;
				}
				case 'fileLogEvent':
					newEntryDisplay.typeAttachment = entryInfo.fileDetails.fileType;
					switch (newEntryDisplay.typeAttachment) {
						case 'photo':
						default:
							newEntryDisplay.body = handleFormatMessage('LogbookPropertyPhotoAdded', formatMessage);
							break;
						case 'audio':
							newEntryDisplay.body = handleFormatMessage('LogbookPropertyAudioAdded', formatMessage);
							break;
					}
					if ('deleteDate' in entryInfo) {
						colorEntry = 'red';
						newEntryDisplay.body += `\r\n${handleFormatMessage(
							'LogbookPropertyAttachmentDeletedOn',
							formatMessage,
							[formatDateTimeNoSecsToLocale(entryInfo.deleteDate)]
						)}`;
					} else if (entryInfo.fileDetails.fileID in newThumbnailsInfo) {
						newEntryDisplay.fileId = entryInfo.fileDetails.fileID;

						if ('thumbnail' in newThumbnailsInfo[entryInfo.fileDetails.fileID]) {
							newEntryDisplay.thumbnail = newThumbnailsInfo[entryInfo.fileDetails.fileID].thumbnail;
						}
					}

					break;

				case 'correctionFactorLogEvent':
					newEntryDisplay.body = formatMessage(
						{
							id: 'App.LogbookChangeCorrectionFactor',
						},
						[
							entryInfo.correctionFactorDetails.oldCorrectionFactor.toFixed(1),
							entryInfo.correctionFactorDetails.updatedCorrectionFactor.toFixed(1),
						]
					);
					break;
			}
			if (newEntryDisplay != null) {
				const createDateConverted = formatDateTimeNoSecsToLocale(entryInfo.createDate);
				// let thisEntryDate=  (entryInfo['createDate'].split(" "))[0];
				const thisEntryDate = formatDateToLocale(entryInfo.createDate); // (createDateConverted.split(" "))[0];

				if (thisEntryDate !== lastEntryDate) {
					newEntryDisplay.newdate = thisEntryDate;
					lastEntryDate = thisEntryDate;
				}

				newEntryDisplay.id = entryInfo.logIndex;
				newEntryDisplay.type = entryInfo.logEventType;
				newEntryDisplay.color = colorEntry;
				newEntryDisplay.date = createDateConverted;
				newEntryDisplay.time = formatTimeNoSecsToLocale(entryInfo.createDate); // (createDateConverted.split(" "))[1];

				newEntryDisplay.user = isKeyDefinedAndNotNull(newUsersInfo, entryInfo.userId)
					? `${newUsersInfo[entryInfo.userId].first_name} ${newUsersInfo[entryInfo.userId].last_name}`
					: '';
				newEntryDisplay.usershort = '';
				if (isKeyDefinedAndNotNull(newUsersInfo, entryInfo.userId)) {
					if (
						'first_name' in newUsersInfo[entryInfo.userId] &&
						newUsersInfo[entryInfo.userId].first_name.length >= 1
					) {
						newEntryDisplay.usershort += newUsersInfo[entryInfo.userId].first_name[0].toUpperCase();
					}
					if (
						'last_name' in newUsersInfo[entryInfo.userId] &&
						newUsersInfo[entryInfo.userId].last_name.length >= 1
					) {
						newEntryDisplay.usershort += newUsersInfo[entryInfo.userId].last_name[0].toUpperCase();
					}
				}

				newEntriesToDisplay.push(newEntryDisplay);
			}
		}, this);
		this.setState(
			{
				entriesToDisplay: newEntriesToDisplay,
				googleMapsObjectsDisplay: newGoogleMapsObjectsDisplay,
				loading: false,
				visible: true,
			} /* ,() => {this.showGoogleMaps()} */
		);
	}

	downloadAttachmentFile(fileId: string) {
		requestDownloadFile(this.props.session, fileId);

		/* this.apiRequestService.standardRequest('file/todownload/',true,{file_id: fileId })
		.subscribe(
			(successInfo) => {
				let successData =successInfo.data;
				if( (typeof successData !== "undefined")&& (successData != null)  && ('file_method' in successData) && successData.file_data){
					//setTimeout(()=>
					this.apiRequestService.fileDownloadRequest(successData.file_data,successData.file_method,successData.file_mime ,successData.file_size, successData.file_name );

				}

			},
			error => console.log(error)
		); */
	}

	getProbeImage = (primaryScaleId: number) => {
		let probeType = this.props.productType;
		switch (primaryScaleId) {
			case 25:
				probeType = 'uci';
				break;
		}

		if (!process.env.PUBLIC_URL || !probeType) {
			return '';
		}

		return `${process.env.PUBLIC_URL}/img/proceq/probe_${probeType.toLowerCase()}.png`;
	};
}

const mapStateToProps = state => ({
	session: state.session,
	locale: state.locale,
	globalregister: state.globalregister,
});

export default compose(connect(mapStateToProps), injectIntl, Form.create({}))(Logbook);
