// @flow
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { compose, withState, lifecycle } from 'recompose';
import URL from 'apis/urls';
import type { InjectIntlProvidedProps } from 'react-intl';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Row, Col, Spin, Form } from 'antd';
import { hasRoleLevel, ROLES } from 'utils/rolesHelper';
import { request } from 'actions/ajax';
import { getInitialRange } from 'constants/dateRange';
import { getLabelFromGlobalDataKey } from 'utils/registryHelper';
import Equotip from './Equotip';
import Schmidt from './Schmidt';
import layoutStyles from '../layout/Layout.styl';
import GPR from './Storage/GPR';
import PUNDIT from './Storage/PUNDIT';
import FDL from './Storage/FDL';
import GLM from './Storage/GLM';
import EQUOTIP from './Storage/EQUOTIP';
import DateRange from '../shared/DateRange';

export const Dashboard = ({
	loading,
	params,
	product,
	data,
	loadData,
	isAdmin,
	intl: { formatMessage },
}: {
	loading: boolean,
	params: Object,
	product: ProductCode,
	data: {
		common: CommonAnalytics,
		storage: StorageAnalytics,
		measurements: Object,
	},
	loadData: Function,
	isAdmin: boolean,
} & InjectIntlProvidedProps) => (
	<Fragment>
		<Row className={layoutStyles.container}>
			<h2 className="label-statistics">
				<FormattedMessage
					id="App.DashboardTitle"
					values={{ product: getLabelFromGlobalDataKey('availproductsfull', product) }}
				/>
			</h2>
			<Col xs={24}>
				<Form.Item>
					<DateRange value={params} onChange={loadData} />
				</Form.Item>
			</Col>
		</Row>
		{!loading && data ? (
			<Fragment>
				{product === 'GPR' && <GPR {...data} />}
				{product === 'GLM' && <GLM {...data} />}
				{product === 'PUNDIT' && <PUNDIT {...data} />}
				{product === 'FDL' && <FDL {...data} />}
				{product === 'EQUOTIP' && <EQUOTIP {...data} />}
				{product === 'SCHMIDT' && <EQUOTIP {...data} />}
				{product === 'EQUOTIP' && <Equotip isAdmin={isAdmin} {...data} />}
				{product === 'SCHMIDT' && <Schmidt isAdmin={isAdmin} {...data} />}
			</Fragment>
		) : (
			<Spin
				style={{ marginTop: '50px', width: '100%' }}
				tip={formatMessage({ id: 'Proceq.JQGridTableLoadText' })}
			/>
		)}
	</Fragment>
);

export default compose(
	withState('loading', 'setLoading', true),
	withState('data', 'setData', null),
	withState('totalRecords', 'setTotalRecords', 0),
	withState('params', 'setParams', () => ({
		...getInitialRange(),
		metrics: ['common', 'measurements', 'storage'],
	})),
	connect(state => ({
		product: state.session.productType,
		isAdmin: hasRoleLevel(state.session.scopes, ROLES.ADMIN),
	})),
	connect(null, (dispatch: Function, props) => ({
		loadData: newParams => {
			const params = {
				...props.params,
				...newParams,
			};

			props.setParams(params);

			dispatch(
				request({
					method: 'POST',
					url: URL.DASHBOARD,
					params: {
						...params,
						from: params.from ? params.from.unix() : undefined,
						to: params.to ? params.to.unix() : undefined,
					},
				})
			).then(data => {
				props.setData(data);
				props.setLoading(false);
			});
		},
	})),
	lifecycle({
		componentDidMount() {
			this.props.loadData();
		},
		shouldComponentUpdate(nextProps) {
			if (this.props.product !== nextProps.product) {
				// we don't update the product
				// instead, load the new product which should trigger the required change instead
				this.props.setLoading(true);
				this.props.loadData();
				return false;
			}

			return true;
		},
	}),
	injectIntl
)(Dashboard);
