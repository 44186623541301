// @flow
import React, { Fragment } from 'react';
import { Table } from 'antd';
import { FormattedMessage } from 'react-intl';
import { convertAndDisplay } from 'constants/conversionRates';
import ConvertedUnits from 'components/shared/ConvertedUnits';
import type { FDLMeasurementPresetsProps } from './MeasurementPresets';
import styles from '../../../HTMLExport.styl';

export const SetupFile = ({
	setupFile,
	number,
	showTitle,
	unit,
}: { setupFile: FDLSetupFile } & FDLMeasurementPresetsProps) => {
	const title = (
		<Fragment>
			<FormattedMessage id="Proceq.ExportFieldSettings" />
			{' ­­» '}
			<FormattedMessage id="App.HTML.FDL.MeasurementPresets.SetupFile" />
			{' ­­» '}
			{number}
		</Fragment>
	);

	const getDigitalFilterValue = function(val) {
		switch (val) {
			case '-1':
			case -1:
				return 'Auto';
			case '0':
			case 0:
				return 'No filter';
			case '1':
			case 1:
				return '0.2 - 1.2';
			case '2':
			case 2:
				return '0.2 - 4';
			case '3':
			case 3:
				return '0.2 - 10';
			case '4':
			case 4:
				return '1.5 - 8.5';
			case '5':
			case 5:
				return '2 - 20';
			case '6':
			case 6:
				return '5 - 15';
			case '7':
			case 7:
				return '8 - 20';
		}
		return 'No filter';
	};

	const getAnalogFilterValue = function(val) {
		switch (val) {
			case '-1':
			case -1:
				return 'Auto';
			case '0':
			case 0:
				return '0.2 - 16.1';
			case '1':
			case 1:
				return '0.94 - 17.3';
			case '2':
			case 2:
				return '1.98 - 20';
			case '3':
			case 3:
				return 'No filter';
		}
		return 'No filter';
	};

	const dataSource = [
		{
			label: 'App.HTML.FDL.MeasurementPresets.SetupFile',
			value: setupFile.name,
		},
		{
			label: 'App.Logbook.FDL.Item.measuringMode',
			value: <FormattedMessage id={`App.Logbook.FDL.Item.measuringMode.${setupFile.measuringMode}`} />,
		},
		{
			label: 'App.Logbook.FDL.Item.probeAngle',
			value: setupFile.probeAngle,
			unitId: 'FDL.probeAngle',
		},
		{
			label: 'App.Logbook.FDL.Item.frequency',
			value: setupFile.frequency,
			unitId: 'FDL.frequency',
		},
		{
			label: 'App.Logbook.FDL.Item.energy',
			value: setupFile.voltage,
			unitId: 'FDL.energy',
		},
		{
			label: 'App.Logbook.FDL.Item.damping',
			value: setupFile.damping,
			unitId: 'FDL.damping',
		},
		{
			label: 'App.Logbook.FDL.Item.pulseWidth',
			value: <FormattedMessage id={`App.Logbook.FDL.Item.pulseWidth.${setupFile.pulseWidth.mode}`} />,
		},
		{
			label: 'App.HTML.FDL.MeasurementPresets.PRF',
			value: <FormattedMessage id={`App.Logbook.FDL.Item.prf.${setupFile.prf.mode}`} />,
		},
		{
			label: 'App.Logbook.FDL.Item.prf',
			value: setupFile.prf.value,
			unitId: 'FDL.prf',
		},
		{
			label: 'App.Logbook.FDL.Item.digitalFilter',
			value:
				setupFile.filter.option === undefined
					? getDigitalFilterValue('0')
					: getDigitalFilterValue(setupFile.filter.option),
			unitId: 'FDL.filter',
			escape: true,
		},
		{
			label: 'App.Logbook.FDL.Item.analogueFilter',
			value:
				setupFile.analogueFilter === undefined
					? getAnalogFilterValue('-1')
					: getAnalogFilterValue(setupFile.analogueFilter),
			unitId: 'FDL.filter',
			escape: true,
		},
		{
			label: 'App.Logbook.FDL.Item.spatialAveraging',
			value:
				Number(setupFile.spatialAveraging).toString() === '1'
					? 'None'
					: Number(setupFile.spatialAveraging).toString(),
		},
	].map((row, key) => {
		let label = <FormattedMessage id={row.label} />;
		let value = row.value;
		if (row.unitId) {
			const { convertFunction } = convertAndDisplay(row.unitId, 'Indication', unit);
			label = <ConvertedUnits id={row.label} unitId={row.unitId} scanType="Indication" isMetric={unit} />;
			if (row.unitId === 'FDL.frequency') {
				value = Math.round(row.value * 100) / 100;
			} else if (!row.escape) {
				value = convertFunction(row.value);
			}
		}

		return {
			key,
			label,
			value,
		};
	});

	return (
		<Table
			title={showTitle ? () => <span className={styles.sub_header}>{title}</span> : null}
			className={styles.table}
			dataSource={dataSource}
			pagination={false}
			showHeader={false}
			size="small"
		>
			<Table.Column dataIndex="label" className={styles.column} />
			<Table.Column dataIndex="value" />
		</Table>
	);
};

export default SetupFile;
