// @flow
import React from 'react';
import { compose, withState } from 'recompose';
import { connect } from 'react-redux';
import URL from 'apis/urls';
import { Button, message } from 'antd';
import { FormattedMessage } from 'react-intl';
import { request } from 'actions/ajax';
import styles from '../shared/SmarterTable.styl';

export const SendEagleIdNotification = ({
	loading,
	sendNotification,
}: {
	loading: boolean,
	sendNotification: Function,
}) => (
	<Button type="primary" loading={loading} onClick={sendNotification} className={styles.button}>
		<FormattedMessage id="Send EagleId Notification" />
	</Button>
);

export default compose(
	withState('loading', 'setLoading', false),
	connect(null, (dispatch: Function, props) => ({
		sendNotification: () =>
			dispatch(
				request({
					method: 'POST',
					url: URL.SEND_EAGLE_ID_NOTIFICATION,
					setLoading: props.setLoading,
				})
			).then(() => {
				message.success('Success');
			}),
	}))
)(SendEagleIdNotification);
