// @flow
import React from 'react';
import classNames from 'classnames';
import { get, curryRight, has } from 'lodash';
import { withState, compose, lifecycle } from 'recompose';
import { connect } from 'react-redux';
import { Spin, Row, Col } from 'antd';
import { withHighcharts, HighchartsChart, Chart, XAxis, YAxis, ColumnSeries, Tooltip } from 'react-jsx-highcharts';
import Highcharts from 'highcharts';
import { FormattedMessage, injectIntl } from 'react-intl';
import type { InjectIntlProvidedProps } from 'react-intl';
import { request } from 'actions/ajax';
import URL from 'apis/urls';
import {
	roundNumberToNext,
	getResolutionForScale,
	getScaleInfoOfScaleId,
	getSchmidtCompositeSurfaceId,
	getSchmidtScaleIdOfFromFactorAndUnitId,
} from 'utils/conversionsHelper';
import { getLabelFromGlobalDataKey } from 'utils/registryHelper';
import handleFormatMessage from 'utils/handleFormatMessage';
import styles from './EquotipReadings.styl';

function formatter(formatMessage, unit) {
	return function unitFormatter() {
		if (isNaN(this.y)) {
			return `<b>${this.x}</b><br/><span style="color:${this.points[0].color}">${formatMessage({
				id: 'Proceq.ExportFieldNoConversion',
			})}</span>`;
		}
		return `<b>${this.x}</b><br/><span style="color:${this.points[0].color}">${getResolutionForScale(
			unit,
			this.y
		)} ${unit}</span>`;
	};
}

export const EquotipReadings = ({
	product,
	loading,
	readings,
	intl: { formatMessage },
	measurement,
	settings,
	isVerificationMode,
}: {
	product: ProductCode,
	loading: boolean,
	readings: EquotipMeasurementContent,
	measurement: {
		probeinfo: EquotipProbeInfo,
		content: EQUOTIP_CONTENT,
	},
	settings: EquotipMeasurementSettingsContent,
	isVerificationMode: boolean,
} & InjectIntlProvidedProps) => {
	if (loading) {
		return <Spin />;
	}

	if (!measurement || !settings || !readings) {
		return <FormattedMessage id="Proceq.GraphNoDataAvailable" />;
	}

	const plotOptions = {
		column: {
			colorByPoint: true,
			marker: {
				enabled: false,
			},
		},
	};

	const unit =
		product === 'EQUOTIP'
			? getScaleInfoOfScaleId(formatMessage, settings.secondary.scaleId, product).scaleUnits
			: getScaleInfoOfScaleId(
					formatMessage,
					getSchmidtScaleIdOfFromFactorAndUnitId(settings.secondary.scaleId, settings.secondary.formFactorId),
					product
			  ).scaleUnits;
	let plotBands = [];
	let plotLines = [];

	const thresholdLoValue = +getResolutionForScale(null, settings.secondary.limits.low);
	const thresholdHiValue = +getResolutionForScale(null, settings.secondary.limits.high);
	if (thresholdLoValue !== null && thresholdHiValue !== null) {
		plotBands = [
			{
				// Light air
				from: thresholdLoValue,
				to: thresholdHiValue,
				color: 'rgba(68, 170, 213, 0.1)',
			},
		];

		plotLines = [
			{
				color: 'rgba(209, 209, 209, 1)', // Color value
				dashStyle: 'shortdash', // Style of the plot line. Default to solid
				value: thresholdLoValue, // Value of where the line will appear
				width: 2, // Width of the line
			},
			{
				color: 'rgba(209, 209, 209, 1)', // Color value
				dashStyle: 'shortdash', // Style of the plot line. Default to solid
				value: thresholdHiValue, // Value of where the line will appear
				width: 2, // Width of the line
			},
		];
	}

	const data = readings.series.map(readingSeries =>
		readingSeries.isNoConversion ? 0 : readingSeries.secondaryValue
	);
	let maxValueFound = data.reduce((max, current) => (current > max ? current : max), thresholdHiValue);
	let minValueFound = data.reduce((min, current) => (current < min ? current : min), thresholdLoValue);
	if (minValueFound < 0) minValueFound = 0;
	minValueFound = roundNumberToNext(minValueFound, 'down');
	maxValueFound = roundNumberToNext(maxValueFound, 'up');

	const categories = readings.series.map(readingSeries => readingSeries.index);
	const colors = readings.series.map(readingSeries => {
		const value = readingSeries.secondaryValue;
		if (readingSeries.isNoConversion) {
			return '#AAAAAA';
		}

		if (readingSeries.excluded || readingSeries.isOutlier) {
			return '#B8B8B8';
		}

		if (value > thresholdHiValue || value < thresholdLoValue) {
			return '#FF7F00';
		}

		return '#00848B';
	});

	let graphHTML = null;
	const measurementsHTML = (
		<Col xs={8} lg={6}>
			<div className={classNames(styles.extrainfo_container, styles.container_measurements_info)}>
				<div className={styles.category_properties}>
					<FormattedMessage id="Proceq.ExportFieldMeasurements" />
				</div>
				<div className={styles.container_allmeasurements}>
					{readings.series.map((reading, indexData) => (
						<div key={indexData}>
							<div className={styles.container_measurement}>
								<div className={styles.index_measurement}>{categories[indexData]} </div>
								{
									<div className={styles.value_measurement} style={{ color: colors[indexData] }}>
										{reading.isNoConversion ? (
											<FormattedMessage id="Proceq.ExportFieldNoConversion" />
										) : (
											`${getResolutionForScale(unit, reading.secondaryValue)} ${unit}`
										)}
									</div>
								}
							</div>
						</div>
					))}
				</div>
			</div>
		</Col>
	);
	if (readings.series.length > 0) {
		graphHTML = (
			<Col xs={16} lg={18}>
				<div style={{ maxWidth: '500px', margin: '0 auto' }}>
					<HighchartsChart plotOptions={plotOptions}>
						<Chart backgroundColor={null} />

						<Tooltip formatter={formatter(formatMessage, unit)} shared />

						<XAxis categories={categories} />

						<YAxis
							id="number"
							plotBands={plotBands}
							plotLines={plotLines}
							min={minValueFound}
							max={maxValueFound}
						>
							<YAxis.Title>{unit}</YAxis.Title>
							<ColumnSeries id="installation" name="Installation" data={data} colors={colors} />
						</YAxis>
					</HighchartsChart>
				</div>
			</Col>
		);
	}

	const statisticsHTML = (
		<Col span={12}>
			<div className={styles.extrainfo_container}>
				<div className={styles.category_properties}>
					<FormattedMessage id="Proceq.ExportFieldStatistics" />
				</div>
				{(product === 'EQUOTIP'
					? ['avg', 'count', 'stdev', 'min', 'max', 'range', 'relSpan']
					: ['avg', 'count', 'min', 'max']
				).map(label => (
					<div key={label} className={styles.property_container}>
						<div className={styles.title_property}>
							{(() => {
								let id = label;
								switch (label) {
									case 'count':
										id = 'Proceq.ExportFieldReadings';
										break;
									case 'stdev':
										id = 'Proceq.ExportFieldStandardDeviation';
										break;
									case 'range':
										id = 'Proceq.ExportFieldRange';
										break;
									case 'relSpan':
										id = 'Proceq.ExportFieldRelativeSpan';
										break;
									case 'max':
										id = 'Proceq.ExportFieldMaximum';
										break;
									case 'min':
										id = 'Proceq.ExportFieldMinimum';
										break;
									case 'avg':
										id = 'Proceq.ExportFieldAverage';
										break;
								}
								return <FormattedMessage id={id} />;
							})()}
						</div>

						{(() => {
							let reading = (isVerificationMode
								? measurement.content.statistics.primary
								: measurement.content.statistics.secondary)[label];
							switch (label) {
								case 'count':
									break;
								case 'relSpan':
									reading = reading === 0 ? '0 %' : `${getResolutionForScale(null, reading)}%`;
									break;
								default:
									reading =
										reading === 0 ? `0 ${unit}` : `${getResolutionForScale(null, reading)} ${unit}`;
									break;
							}
							return <div className={styles.value_property}>{String(reading)}</div>;
						})()}
					</div>
				))}
			</div>
		</Col>
	);

	const settingsFields = [
		{
			label: 'Proceq.ExportFieldUpperLimit',
			value:
				thresholdHiValue === null ? (
					<FormattedMessage id="Proceq.ExportFieldOff" />
				) : (
					`${thresholdHiValue} ${unit}`
				),
		},
		{
			label: 'Proceq.ExportFieldLowerLimit',
			value:
				thresholdLoValue === null ? (
					<FormattedMessage id="Proceq.ExportFieldOff" />
				) : (
					`${thresholdLoValue} ${unit}`
				),
		},
	];

	if (isVerificationMode) {
		settingsFields.push(
			...[
				// {
				// 	label: 'Proceq.ExportFieldProbeType',
				// 	value: measurement.probeinfo.name,
				// },
				// {
				// 	label: 'Proceq.ExportColumnVerificationCriteria',
				// 	value: (
				// 		<FormattedMessage
				// 			id={getLabelFromGlobalDataKey(
				// 				product === 'EQUOTIP' ? 'verificationcriteriaequotip' : 'verificationcriteriaschmidt',
				// 				String(settings.standardId)
				// 			)}
				// 		/>
				// 	),
				// },
				{
					label: 'Proceq.ExportColumnVerificationStd',
					value: (
						<FormattedMessage
							id={getLabelFromGlobalDataKey(
								product === 'EQUOTIP' ? 'standards' : 'standardsschmidt',
								String(settings.standardId)
							)}
						/>
					),
				},
				...(settings.testBlock.name
					? [
							{
								label:
									product === 'EQUOTIP'
										? 'Proceq.ExportColumnTestBlockName'
										: 'Proceq.ExportColumnCalibrationAnvilName',
								value: settings.testBlock.name,
							},
					  ]
					: []),
				{
					label:
						product === 'EQUOTIP'
							? 'Proceq.ExportColumnTestBlockSN'
							: 'Proceq.ExportColumnCalibrationAnvilSN',
					value: settings.testBlock.serialNumber,
				},
				...(settings.triggerLoadId
					? [
							{
								label: 'Proceq.ExportColumnTestLoadInfo',
								value: `UCI HV${settings.triggerLoadId}`,
							},
					  ]
					: []),
				{
					label:
						product === 'EQUOTIP'
							? 'Proceq.ExportColumnTestBlockNominalHardness'
							: 'Proceq.ExportColumnCalibrationAnvilReboundValue',
					value: `${settings.testBlock.value} ${
						getScaleInfoOfScaleId(
							formatMessage,
							product === 'EQUOTIP'
								? settings.primary.scaleId
								: getSchmidtScaleIdOfFromFactorAndUnitId(
										settings.primary.scaleId,
										settings.primary.formFactorId
								  ),
							product
						).scaleUnits
					}`,
				},
				{
					label:
						product === 'EQUOTIP'
							? 'Proceq.ExportColumnTestBlockTolerance'
							: 'Proceq.ExportColumnCalibrationAnvilTolerance',
					value: `${settings.testBlock.margin} ${
						getScaleInfoOfScaleId(
							formatMessage,
							product === 'EQUOTIP'
								? settings.primary.scaleId
								: getSchmidtScaleIdOfFromFactorAndUnitId(
										settings.primary.scaleId,
										settings.primary.formFactorId
								  ),
							product
						).scaleUnits
					}`,
				},
				{
					label: 'Proceq.ExportColumnTestVerificationResult',
					value: measurement.content.properties.verificationResult ? (
						<FormattedMessage
							id={getLabelFromGlobalDataKey(
								'verificationstatuses',
								measurement.content.properties.verificationResult
							)}
						/>
					) : null,
				},
			]
		);
	} else {
		switch (product) {
			case 'EQUOTIP':
				settingsFields.push(
					...[
						{
							label: 'Proceq.ExportFieldMaterial',
							value: has(settings, 'customMaterial.name') ? (
								settings.customMaterial.name
							) : (
								<FormattedMessage
									id={getLabelFromGlobalDataKey('materials', String(settings.materialId || 2))}
								/>
							),
						},
						{
							label: 'Proceq.ExportFieldName',
							value: getScaleInfoOfScaleId(formatMessage, settings.secondary.scaleId, product).scaleName,
						},
						{
							label: 'Proceq.ExportFieldUnit',
							value: unit,
						},
						// {
						// 	label: 'Proceq.ExportFieldProbeType',
						// 	value: measurement.probeinfo.name,
						// },
						// {
						// 	label: 'Proceq.ExportFieldProbeSerialNumber',
						// 	value: measurement.probeinfo.serialNumber,
						// },
						// {
						// 	label: 'Proceq.ExportFieldProbeBTSerialNumber',
						// 	value: measurement.probeinfo.btSerialNumber,
						// },
						// {
						// 	label: 'Proceq.ExportFieldProbeLastVerificationDate',
						// 	value: measurement.probeinfo.lastVerificationDate,
						// },
						...(settings.triggerLoadId
							? [
									{
										label: 'Proceq.ExportColumnTestLoadInfo',
										value: `UCI HV${settings.triggerLoadId}`,
									},
							  ]
							: []),
					]
				);
				break;
			case 'SSL':
			case 'SCHMIDT':
				settingsFields.push(
					...[
						{
							label: 'Proceq.ExportFieldStandard',
							// value: settings.standard,
							value: (
								<FormattedMessage
									id={getLabelFromGlobalDataKey('standardsschmidt', String(settings.standardId))}
								/>
							),
						},
						{
							label: 'Proceq.ExportFieldConversionCurve',
							value: (() => {
								const compositeSurfaceId = getSchmidtCompositeSurfaceId(
									settings.secondary.strengthId,
									settings.secondary.surfaceCorrectionId
								);

								if (has(settings, 'customMaterial.name')) {
									return settings.customMaterial.name;
								}
								if ('conversionCurveId' in settings.secondary) {
									return (
										<FormattedMessage
											id={getLabelFromGlobalDataKey(
												'convcurveschmidt',
												String(settings.secondary.conversionCurveId)
											)}
										/>
									);
								}
								if (compositeSurfaceId) {
									return (
										<FormattedMessage
											id={getLabelFromGlobalDataKey(
												'surfacestrengthandcorrschmidt',
												compositeSurfaceId
											)}
										/>
									);
								}
								if ('surfaceConditionId' in settings.secondary) {
									return (
										<FormattedMessage
											id={getLabelFromGlobalDataKey(
												'surfaceconditionschmidt',
												settings.secondary.surfaceConditionId
											)}
										/>
									);
								}
								return null;
							})(),
						},
						...(settings.secondary.carbonationDepth
							? [
									{
										label: 'Proceq.ExportFieldCarbonationDepth',
										value: handleFormatMessage('ValueCarbonationDepthMms', formatMessage, [
											settings.secondary.carbonationDepth,
										]),
									},
							  ]
							: []),
						...(settings.secondary.age
							? [
									{
										label: 'Proceq.ExportFieldConcreteAge',
										value: handleFormatMessage('ValueConcreteAgeDays', formatMessage, [
											settings.secondary.age,
										]),
									},
							  ]
							: []),
						{
							label: 'Proceq.ExportFieldName',
							// value: settings.secondary.formFactorName,
							value: getScaleInfoOfScaleId(
								formatMessage,
								getSchmidtScaleIdOfFromFactorAndUnitId(
									settings.secondary.scaleId,
									settings.secondary.formFactorId
								),
								product
							).scaleName,
						},
						{
							label: 'Proceq.ExportFieldUnit',
							value: unit,
						},
						// {
						// 	label: 'Proceq.ExportFieldProbeType',
						// 	value: measurement.probeinfo.name,
						// },
					]
				);
				break;
		}
	}

	const settingsHTML = (
		<Col span={12}>
			<div className={styles.extrainfo_container}>
				<div className={styles.category_properties}>
					<FormattedMessage id="Proceq.ExportFieldSettings" />
				</div>

				{settingsFields.map(({ value, label }) => (
					<div key={label} className={styles.property_container}>
						<div className={styles.title_property}>
							<FormattedMessage id={label} />
						</div>
						<div className={styles.value_property}>{value}</div>
					</div>
				))}
			</div>
		</Col>
	);

	return (
		<Row>
			{graphHTML}
			{graphHTML && measurementsHTML}
			{statisticsHTML}
			{settingsHTML}
		</Row>
	);
};

export const EquotipReadingsWithState = compose(injectIntl, curryRight(withHighcharts)(Highcharts))(EquotipReadings);

export default compose(
	withState('loading', 'setLoading', true),
	withState('readings', 'setReadings'),
	withState('measurement', 'setMeasurement', {}),
	withState('settings', 'setSettings', {}),
	connect(null, (dispatch: Function, props) => ({
		loadMeasurement: () =>
			dispatch(
				request({
					url: URL.MEASUREMENTS + props.mID,
				})
			).then(data => {
				props.setMeasurement(data.measurement);
				props.setReadings(get(data, 'readings.0.content'));
				props.setSettings(get(data, 'settings.0.content'));
				props.setLoading(false);
			}),
	})),
	lifecycle({
		componentDidMount() {
			this.props.loadMeasurement();
		},
	})
)(EquotipReadingsWithState);
