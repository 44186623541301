// @flow
import React from 'react';
import { timeToLocaleTime } from 'utils/dateHandler';

const renderTime = (text: string) => <span>{timeToLocaleTime(text)}</span>;

export default [
	{
		title: 'Proceq.TableCreatedOn',
		dataIndex: 'created',
		sorter: true,
		render: renderTime,
	},
];
