// @flow
import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withState } from 'recompose';
import { Button, message } from 'antd';
import { FormattedMessage, injectIntl } from 'react-intl';
import URL from 'apis/urls';
import { request } from 'actions/ajax';

export const ClearCache = ({ clearCache, loading }: { clearCache: Function, loading: boolean }) => {
	return (
		<Button type="primary" onClick={clearCache} loading={loading}>
			<FormattedMessage id="App.DevTools.ClearAPICache" />
		</Button>
	);
};

export default compose(
	withState('loading', 'setLoading', false),
	injectIntl,
	connect(null, (dispatch: Function, props) => ({
		clearCache: () =>
			dispatch(
				request({
					method: 'DELETE',
					url: URL.CLEAR_CACHE,
					setLoading: props.setLoading,
				})
			).then(() =>
				message.success(
					props.intl.formatMessage({
						id: 'App.DevTools.ClearAPICache.Success',
					})
				)
			),
	}))
)(ClearCache);
