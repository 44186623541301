// @flow
import React, { Fragment } from 'react';
import { get, has, curryRight } from 'lodash';
import { compose } from 'recompose';
import { injectIntl } from 'react-intl';
import type { InjectIntlProvidedProps } from 'react-intl';
import { Col } from 'antd';
import {
	withHighcharts,
	HighchartsChart,
	Title,
	Subtitle,
	PieSeries,
	Legend,
	Tooltip,
	Chart,
	XAxis,
	YAxis,
} from 'react-jsx-highcharts';
import Highcharts from 'highcharts';
import applyDrilldown from 'highcharts/modules/drilldown';
import { rates } from 'constants/conversionRates';

applyDrilldown(Highcharts);

const colors = [
	'#00A1B1',
	'#55C0CB',
	'#8DD5DC',
	'#C6EAED',
	'#1CABB9',
	'#38B5C2',
	'#71CAD3',
	/* '#deebf7', '#f7fbff', */ '#AADFE5',
	'E2F4F6',
];
const pieProps = {
	type: 'pie',
	colors,
	center: ['50%', 130],
	size: 200,
	innerSize: 100,
	showInLegend: true,
	dataLabels: {
		formatter,
		style: {
			fontWeight: 'normal',
		},
	},
};
const legendProps = {
	layout: 'vertical',
	itemMarginTop: 20,
	itemStyle: {
		fontWeight: 'normal',
		textOverflow: undefined,
	},
	labelFormatter: legendFormatter,
	floating: true,
	verticalAlign: 'top',
	y: 350,
	navigation: {
		enabled: false,
	},
};
function formatter() {
	return `
		<strong>${this.point.x} ${this.point.name}</strong>
		<br />
		${rates.bytes(this.y)}
	`;
}
function legendFormatter() {
	return `
		<strong>${this.name}</strong>
		<br />
		${this.legend ? `${this.legend}` : ''}
	`;
}

export const StorageCharts = ({
	common,
	storage,
	measurements,
	intl,
}: {
	common: CommonAnalytics,
	storage: StorageAnalytics,
	measurements: MeasurementAnalytics,
} & InjectIntlProvidedProps) => (
	<Fragment>
		<Col lg={24} xl={12}>
			<HighchartsChart
				plotOptions={{
					pie: {
						point: {
							events: {
								// legendItemClick: e => e.preventDefault(),
								legendItemClick: e => {
									if (has(e, 'target.hcEvents.click')) {
										e.target.hcEvents.click[0]();
										return true;
									}
									return false;
								},
							},
						},
					},
				}}
				drilldown={{
					series: [
						{
							id: 'measurements',
							name: intl.formatMessage({ id: 'App.AnalyticsStorageTypeMeasurement' }),
							data: [
								{
									name: intl.formatMessage({ id: 'App.DashboardLineScans' }),
									x: get(measurements, 'lineScan.measurementCount', 0),
									y: get(measurements, 'lineScan.rawDataStorage', 0),
								},
								{
									name: intl.formatMessage({ id: 'App.DashboardAreaScans' }),
									x: get(measurements, 'areaScan.measurementCount', 0),
									y: get(measurements, 'areaScan.rawDataStorage', 0),
								},
								{
									name: intl.formatMessage({ id: 'App.DashboardStripeScans' }),
									x: get(measurements, 'stripeScan.measurementCount', 0),
									y: get(measurements, 'stripeScan.rawDataStorage', 0),
								},
							],
						},
						{
							id: 'images',
							name: intl.formatMessage({ id: 'App.AnalyticsStorageTypeImage' }),
							data: [
								{
									name: intl.formatMessage({ id: 'App.DashboardLineScans' }),
									x: get(measurements, 'lineScan.photoCount', 0),
									y: get(measurements, 'lineScan.photoStorage', 0),
								},
								{
									name: intl.formatMessage({ id: 'App.DashboardAreaScans' }),
									x: get(measurements, 'areaScan.photoCount', 0),
									y: get(measurements, 'areaScan.photoStorage', 0),
								},
								{
									name: intl.formatMessage({ id: 'App.DashboardStripeScans' }),
									x: get(measurements, 'stripeScan.photoCount', 0),
									y: get(measurements, 'stripeScan.photoStorage', 0),
								},
							],
						},
						{
							id: 'audio',
							name: intl.formatMessage({ id: 'App.AnalyticsStorageTypeAudio' }),
							data: [
								{
									name: intl.formatMessage({ id: 'App.DashboardLineScans' }),
									x: get(measurements, 'lineScan.audioCount', 0),
									y: get(measurements, 'lineScan.audioStorage', 0),
								},
								{
									name: intl.formatMessage({ id: 'App.DashboardAreaScans' }),
									x: get(measurements, 'areaScan.audioCount', 0),
									y: get(measurements, 'areaScan.audioStorage', 0),
								},
								{
									name: intl.formatMessage({ id: 'App.DashboardStripeScans' }),
									x: get(measurements, 'stripeScan.audioCount', 0),
									y: get(measurements, 'stripeScan.audioStorage', 0),
								},
							],
						},
						{
							id: 'snapshots',
							name: intl.formatMessage({ id: 'App.DashboardSnapshots' }),
							data: [
								{
									name: intl.formatMessage({ id: 'App.DashboardLineScans' }),
									x: get(measurements, 'lineScan.snapshotCount', 0),
									y: get(measurements, 'lineScan.snapshotStorage', 0),
								},
								{
									name: intl.formatMessage({ id: 'App.DashboardAreaScans' }),
									x: get(measurements, 'areaScan.snapshotCount', 0),
									y: get(measurements, 'areaScan.snapshotStorage', 0),
								},
								{
									name: intl.formatMessage({ id: 'App.DashboardStripeScans' }),
									x: get(measurements, 'stripeScan.snapshotCount', 0),
									y: get(measurements, 'stripeScan.snapshotStorage', 0),
								},
							],
						},
						{
							id: 'snapshots_csv',
							name: intl.formatMessage({ id: 'App.DashboardCSVCount' }),
							data: [
								{
									name: intl.formatMessage({ id: 'App.DashboardLineScans' }),
									x: get(measurements, 'lineScan.snapshotCSVCount', 0),
									y: get(measurements, 'lineScan.snapshotAndCSVStorage', 0),
								},
								{
									name: intl.formatMessage({ id: 'App.DashboardAreaScans' }),
									x: get(measurements, 'areaScan.snapshotCSVCount', 0),
									y: get(measurements, 'areaScan.snapshotAndCSVStorage', 0),
								},
								{
									name: intl.formatMessage({ id: 'App.DashboardStripeScans' }),
									x: get(measurements, 'stripeScan.snapshotCSVCount', 0),
									y: get(measurements, 'stripeScan.snapshotAndCSVStorage', 0),
								},
							],
						},
					].map(data => ({
						...data,
						data: data.data
							.filter(dataset => dataset.y > 0)
							.map(dataset => ({ ...dataset, x: dataset.x > 0 ? dataset.x : '' })),
						...pieProps,
					})),
				}}
			>
				<Title>{intl.formatMessage({ id: 'App.AnalyticsStorageByType' })}</Title>
				{has(measurements, 'lineScan') && (
					<Subtitle>{intl.formatMessage({ id: 'App.AnalyticsStorageByScanSubtitle' })}</Subtitle>
				)}
				<Chart height={620} />
				<Legend
					title={{
						text: intl.formatMessage({ id: 'App.AnalyticsLegend' }),
						style: {
							textDecoration: 'underline',
						},
					}}
					{...legendProps}
				/>
				<XAxis />
				<YAxis />
				<Tooltip pointFormat="{point.y} bytes" />
				<PieSeries
					data={[
						{
							name: intl.formatMessage({ id: 'Proceq.TableNumTotal' }),
							x: '',
							y: storage.alive.totalStorage,
						},
					]}
					{...pieProps}
					dataLabels={{
						...pieProps.dataLabels,
						distance: -60,
					}}
					colors={['white']}
					size={100}
					showInLegend={false}
				/>
				<PieSeries
					name={intl.formatMessage({ id: 'Proceq.TableNumTotal' })}
					data={[
						{
							name: intl.formatMessage({ id: 'App.AnalyticsStorageTypeMeasurement' }),
							id: 'measurements',
							drilldown: 'measurements',
							legend: intl.formatMessage({ id: 'App.AnalyticsStorageTypeMeasurementTooltip' }),
							x: get(measurements, 'nMeasurements', common.nTotalFiles),
							y: get(storage, 'alive.measurementRawDataStorage'),
						},
						{
							name: intl.formatMessage({ id: 'App.AnalyticsStorageTypeImage' }),
							id: 'images',
							drilldown: 'images',
							x: common.nPhotos,
							y: get(storage, 'alive.imageStorage'),
						},
						{
							name: intl.formatMessage({ id: 'App.AnalyticsStorageTypeAudio' }),
							id: 'audio',
							drilldown: 'audio',
							x: common.nAudios,
							y: get(storage, 'alive.audioStorage'),
						},
						{
							name: intl.formatMessage({ id: 'App.DashboardSnapshots' }),
							id: 'snapshots',
							drilldown: 'snapshots',
							legend: intl.formatMessage({ id: 'App.DashboardSnapshotsTooltip' }),
							x:
								get(measurements, 'lineScan.snapshotCount', 0) +
								get(measurements, 'areaScan.snapshotCount', 0) +
								get(measurements, 'stripeScan.snapshotCount', 0),
							y:
								get(measurements, 'lineScan.snapshotStorage', 0) +
								get(measurements, 'areaScan.snapshotStorage', 0) +
								get(measurements, 'stripeScan.snapshotStorage', 0),
						},
						{
							name: intl.formatMessage({ id: 'App.DashboardCSVCount' }),
							id: 'snapshots_csv',
							drilldown: 'snapshots_csv',
							legend: intl.formatMessage({ id: 'App.DashboardCSVTooltip' }),
							x:
								get(measurements, 'lineScan.snapshotCSVCount', 0) +
								get(measurements, 'areaScan.snapshotCSVCount', 0) +
								get(measurements, 'stripeScan.snapshotCSVCount', 0),
							y:
								get(measurements, 'lineScan.snapshotAndCSVStorage', 0) +
								get(measurements, 'areaScan.snapshotAndCSVStorage', 0) +
								get(measurements, 'stripeScan.snapshotAndCSVStorage', 0),
						},
					]
						.filter(dataset => dataset.y > 0)
						.map(dataset => ({ ...dataset, x: dataset.x > 0 ? dataset.x : '' }))
						.map(dataset =>
							has(measurements, 'lineScan')
								? dataset
								: {
										...dataset,
										drilldown: null,
								  }
						)}
					// onClick={e => getChart().setTitle(e.point.name)}
					label={{
						boxesToAvoid: ['bottom', 'right'],
					}}
					{...pieProps}
				/>
			</HighchartsChart>
		</Col>
		{has(measurements, 'lineScan') && (
			<Col lg={24} xl={12}>
				<HighchartsChart
					plotOptions={{
						pie: {
							point: {
								events: {
									legendItemClick: e => {
										if (has(e, 'target.hcEvents.click')) {
											e.target.hcEvents.click[0]();
											return true;
										}
										return false;
									},
								},
							},
						},
					}}
					drilldown={{
						series: [
							{
								id: 'line_scans',
								name: 'App.DashboardLineScans',
								key: 'lineScan',
							},
							{
								id: 'area_scans',
								name: 'App.DashboardAreaScans',
								key: 'areaScan',
							},
							{
								id: 'stripe_scans',
								name: 'App.DashboardStripeScans',
								key: 'stripeScan',
							},
						].map(({ id, name, key }) => ({
							id,
							name: intl.formatMessage({ id: name }),
							data: [
								{
									name: intl.formatMessage({ id: 'App.AnalyticsStorageTypeMeasurement' }),
									x: get(measurements, `${key}.measurementCount`, 0),
									y: get(measurements, `${key}.rawDataStorage`, 0),
									legend: intl.formatMessage({ id: 'App.AnalyticsStorageTypeMeasurementTooltip' }),
								},
								{
									name: intl.formatMessage({ id: 'App.AnalyticsStorageTypeImage' }),
									x: get(measurements, `${key}.photoCount`, 0),
									y: get(measurements, `${key}.photoStorage`, 0),
								},
								{
									name: intl.formatMessage({ id: 'App.AnalyticsStorageTypeAudio' }),
									x: get(measurements, `${key}.audioCount`, 0),
									y: get(measurements, `${key}.audioStorage`, 0),
								},
								{
									name: intl.formatMessage({ id: 'App.DashboardSnapshots' }),
									x: get(measurements, `${key}.snapshotCount`, 0),
									y: get(measurements, `${key}.snapshotStorage`, 0),
									legend: intl.formatMessage({ id: 'App.DashboardSnapshotsTooltip' }),
								},
								{
									name: intl.formatMessage({ id: 'App.DashboardCSVCount' }),
									x: get(measurements, `${key}.snapshotCSVCount`, 0),
									y: get(measurements, `${key}.snapshotAndCSVStorage`, 0),
									legend: intl.formatMessage({ id: 'App.DashboardCSVTooltip' }),
								},
							]
								.filter(dataset => dataset.y > 0)
								.map(dataset => ({ ...dataset, x: dataset.x > 0 ? dataset.x : '' })),
							...pieProps,
						})),
					}}
				>
					<Title>{intl.formatMessage({ id: 'App.AnalyticsStorageByScan' })}</Title>
					{has(measurements, 'lineScan') && (
						<Subtitle>{intl.formatMessage({ id: 'App.AnalyticsStorageByTypeSubtitle' })}</Subtitle>
					)}
					<Chart height={620} />
					<Legend
						title={{
							text: intl.formatMessage({ id: 'App.AnalyticsLegend' }),
							style: {
								textDecoration: 'underline',
							},
						}}
						{...legendProps}
					/>
					<XAxis />
					<YAxis />
					<Tooltip pointFormat="{point.y} bytes" />
					<PieSeries
						data={[
							{
								name: intl.formatMessage({ id: 'Proceq.TableNumTotal' }),
								x: '',
								y: storage.alive.totalStorage,
							},
						]}
						{...pieProps}
						dataLabels={{
							...pieProps.dataLabels,
							distance: -60,
						}}
						colors={['white']}
						size={100}
						showInLegend={false}
					/>
					<PieSeries
						name={intl.formatMessage({ id: 'Proceq.TableNumTotal' })}
						data={[
							{
								name: intl.formatMessage({ id: 'App.DashboardLineScans' }),
								drilldown: 'line_scans',
								x: get(measurements, 'lineScan.measurementCount', 0),
								y: get(measurements, 'lineScan.totalStorage', 0),
							},
							{
								name: intl.formatMessage({ id: 'App.DashboardAreaScans' }),
								drilldown: 'area_scans',
								x: get(measurements, 'areaScan.measurementCount', 0),
								y: get(measurements, 'areaScan.totalStorage', 0),
							},
							{
								name: intl.formatMessage({ id: 'App.DashboardStripeScans' }),
								drilldown: 'stripe_scans',
								x: get(measurements, 'stripeScan.measurementCount', 0),
								y: get(measurements, 'stripeScan.totalStorage', 0),
							},
						]
							.filter(dataset => dataset.y > 0)
							.map(dataset => ({ ...dataset, x: dataset.x > 0 ? dataset.x : '' }))}
						{...pieProps}
					/>
				</HighchartsChart>
			</Col>
		)}
	</Fragment>
);

export default compose(injectIntl, curryRight(withHighcharts)(Highcharts))(StorageCharts);
