// @flow
import type { InjectIntlProvidedProps } from 'react-intl';
import { injectIntl } from 'react-intl';
import handleFormatMessage from 'utils/handleFormatMessage';

export const CorrectionFactor = ({ log, intl }: EquotipLogbookEntryProps & InjectIntlProvidedProps) =>
	handleFormatMessage('Proceq.LogbookPropertyCorrectionFactorFromTo', intl.formatMessage, [
		log.content.oldCorrectionFactor.toFixed(1),
		log.content.updatedCorrectionFactor.toFixed(1),
	]);

export default injectIntl(CorrectionFactor);
