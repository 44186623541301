// @flow
import React from 'react';
import { get } from 'lodash';
import { compose } from 'redux';
import { withStateHandlers } from 'recompose';
import { FormattedMessage } from 'react-intl';
import { renderLogbookDate } from 'utils/dateHandler';
import './Logbook.css';
import LogEvent, { renderUserInitials } from './LogEvent/LogEvent';
import Probe from './LogEvent/GPR/Probe';

export const GPRLogbook = ({
	productModel,
	scanType,
	product,
	logs,
	users,
	isMetric,
	version,
	highlighted,
	toggleHighlight,
}: {
	productModel: string,
	scanType: ScanType,
	product: ProductCode,
	logs: Log[],
	users: User[],
	isMetric: boolean,
	version: string,
	highlighted: string[],
	toggleHighlight: Function,
}) => {
	const probeLog: ?Log = logs && logs.find(log => log.type === 'probe');
	const filterFDLKeyFrame = item => {
		if (
			product === 'FDL' &&
			item !== undefined &&
			item.content !== undefined &&
			item.type === 'changeKeyFrame' &&
			item.content.oldKeyFrameIndex === undefined
		) {
			return false;
		}
		return true;
	};
	const filterGprSoilUtilityLine = item => {
		if (
			product === 'GPR_SOIL' &&
			item !== undefined &&
			item.content !== undefined &&
			(item.type === 'utilityLine' || item.type === 'resumeScan')
		) {
			return false;
		}
		return true;
	};
	return (
		<div id="logbookModal">
			<div id="chat-body" className="chat-body-custom-scroll">
				{logs === null && <FormattedMessage id="App.LogbookNoEntries" />}
				{!!probeLog && <Probe log={probeLog} productModel={productModel} />}
				<ul>
					{logs &&
						logs
							.sort((a, b) => {
								// Force creation log to be the very first
								if (a.type === 'creation') {
									return -1;
								}
								if (b.type === 'creation') {
									return 1;
								}
								return a.clientCreated - b.clientCreated;
							})
							.filter(log => ['probe'].indexOf(log.type) < 0)
							.filter(filterFDLKeyFrame)
							.filter(filterGprSoilUtilityLine)
							.map((log, index, sortedLogs) => {
								return (
									<LogEvent
										key={log.id}
										log={log}
										product={product}
										showDate={
											index === 0 ||
											renderLogbookDate(log.clientCreated) !==
												renderLogbookDate(get(sortedLogs, [String(index - 1), 'clientCreated']))
										}
										date={log.clientCreated}
										productModel={productModel}
										isMetric={isMetric}
										scanType={scanType}
										version={version}
										user={renderUserInitials(users, log.uID)}
										highlighted={highlighted.includes(log.id)}
										toggleHighlight={() => toggleHighlight(log.id)}
										probeLog={probeLog ?? undefined}
									/>
								);
							})}
				</ul>
			</div>
		</div>
	);
};

export default compose(
	withStateHandlers(
		{
			highlighted: [],
		},
		{
			toggleHighlight: ({ highlighted }) => id => ({
				highlighted: highlighted.includes(id)
					? highlighted.filter(existingId => existingId !== id)
					: [...highlighted, id],
			}),
		}
	)
)(GPRLogbook);
