// @flow
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { Collapse } from 'antd';
import { FormattedMessage } from 'react-intl';
import { hasRoleLevel, ROLES } from 'utils/rolesHelper';
import { isProduction } from 'apis/urls';
import Import from './Import';
import GenerateDemoBundle from './GenerateDemoBundle';
import Renew from './Renew';
import Wipeout from './Wipeout';
import ClearCache from './ClearCache';
import Managei18n from './Managei18n';
import TouchMeasurement from './TouchMeasurement';

export const DevTools = ({ isAdmin }: { isAdmin: boolean }) => (
	<Collapse defaultActiveKey={[isProduction ? '1' : '0']}>
		<Collapse.Panel
			header={
				isProduction ? (
					'Import feature is only available on dev'
				) : (
					<FormattedMessage id="App.DevTools.Import.Header" />
				)
			}
			disabled={isProduction}
		>
			{!isProduction && <Import />}
		</Collapse.Panel>
		{isAdmin && (
			<Collapse.Panel header={<FormattedMessage id="App.DevTools.GenerateDemoBundle.Header" />}>
				<GenerateDemoBundle />
			</Collapse.Panel>
		)}
		<Collapse.Panel header={<FormattedMessage id="App.DevTools.TouchMeasurement.Header" />}>
			<TouchMeasurement />
		</Collapse.Panel>
		<Collapse.Panel header={<FormattedMessage id="App.DevTools.Renew.Header" />}>
			<Renew />
		</Collapse.Panel>
		<Collapse.Panel
			header={
				isProduction ? (
					'Data wipeout feature is only available on dev'
				) : (
					<FormattedMessage id="App.DevTools.Wipeout.Header" />
				)
			}
			disabled={isProduction}
		>
			{!isProduction && <Wipeout />}
		</Collapse.Panel>
		{isAdmin && (
			<Collapse.Panel header={<FormattedMessage id="App.DevTools.ClearAPICache" />}>
				<ClearCache />
			</Collapse.Panel>
		)}
		{isAdmin && (
			<Collapse.Panel header={<FormattedMessage id="App.DevTools.Managei18n" />}>
				<Managei18n />
			</Collapse.Panel>
		)}
	</Collapse>
);

export default compose(
	connect(state => ({
		isAdmin: hasRoleLevel(state.session.scopes, ROLES.ADMIN),
	}))
)(DevTools);
