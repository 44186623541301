export default [
	{
		title: 'Name',
		dataIndex: 'name',
	},
	{
		title: 'oldID',
		dataIndex: 'oldID',
	},
	{
		title: 'newID',
		dataIndex: 'newID',
	},
	{
		title: 'type',
		dataIndex: 'type',
	},
];
