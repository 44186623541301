// @flow
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { renderLogbookDate } from 'utils/dateHandler';
import { getLabelFromGlobalDataKey } from 'utils/registryHelper';

export const InitialInfo = ({
	log: { content },
	product,
	author,
}: {
	log: InitialInfoLog,
	product: ProductCode,
	author: string,
}) => (
	<div className="full-container intro-container">
		<div>
			<div className="initial-image">
				<img
					alt=""
					height="100"
					width="100"
					src={
						content.primaryScaleId === 25
							? `${process.env.PUBLIC_URL || ''}/img/proceq/probe_uci.png`
							: `${process.env.PUBLIC_URL || ''}/img/proceq/probe_${product.toLowerCase()}.png`
					}
				/>
			</div>
			<div className="initial-content">
				<b>{content.probeType}</b>
				<br />
				<FormattedMessage id="Proceq.LogbookPropertySerialNumberShort" />
				{': '}
				{content.probeSerialNumber}
				<br />
				<FormattedMessage id="Proceq.LogbookPropertyBluetoothModuleShort" />
				{': '}
				{content.btSerialNumber}
				<br />
				<FormattedMessage id="Proceq.LogbookPropertyVerified" />
				{': '}
				{content.probeVerificationDate ? (
					renderLogbookDate(content.probeVerificationDate.split(' ')[0])
				) : (
					<FormattedMessage id="Proceq.TextUnknown" />
				)}
				<br />
				{content.contractProductTypeID && (
					<div>
						<FormattedMessage id="Proceq.LogbookContractType" />
						<FormattedMessage
							id={`Proceq.${getLabelFromGlobalDataKey(
								'registerproducts',
								content.contractProductTypeID
							)}`}
						/>
					</div>
				)}
				<FormattedMessage id="Proceq.LogbookPropertyContractValidUntil" />
				{': '}
				{content.contractExpirationDate && renderLogbookDate(content.contractExpirationDate.split(' ')[0])}
				<br />
				<FormattedMessage id="Proceq.LogbookPropertyOperator" />
				{': '}
				{author}
			</div>
		</div>
	</div>
);

export default InitialInfo;
