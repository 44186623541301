// @flow
import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';

const measureMode = log => (
	<Fragment>
		<FormattedMessage id="App.Logbook.PI8000.PileName" />
		{`: ${log.content.pileName}`}
		<br />
		<FormattedMessage id="App.Logbook.PI8000.PositionName" />
		{`: ${log.content.positionName}`}
		<br />
		<FormattedMessage id="App.Logbook.PI8000.HighlightedSignal" />
		{`: ${log.content.highlightedSignal}`}
		<br />
		<FormattedMessage id="App.Logbook.PI8000.PositionName" />
		{`: ${log.content.positionName}`}
		<br />
		<FormattedMessage id="App.Logbook.PI8000.AttachmentId" />
		{`: ${log.content.attachmentId}`}
		<br />
		<FormattedMessage id="App.Logbook.PI8000.PitMode" />
		{`: ${log.content.pitMode}`}
		<br />
		<FormattedMessage id="App.Logbook.PI8000.IsExport" />
		{`: ${log.content.isExport}`}
	</Fragment>
);

const compareMode = log => (
	<Fragment>
		<FormattedMessage id="App.Logbook.PI8000.PileAndPositions" />
		{log.content.pileAndPositions
			.map(row => {
				const positions = [];
				for (const p in row) {
					positions.push(`${p}: ${row[p]}`);
				}
				return positions.join('\n');
			})
			.join('\n')}
		<br />
		<FormattedMessage id="App.Logbook.PI8000.AttachmentId" />
		{`: ${log.content.attachmentId}`}
		<br />
		<FormattedMessage id="App.Logbook.PI8000.PitMode" />
		{`: ${log.content.pitMode}`}
		<br />
		<FormattedMessage id="App.Logbook.PI8000.IsExport" />
		{`: ${log.content.isExport}`}
	</Fragment>
);

export const Snapshot = ({ log }: GPRLogbookEntryProps) => (
	<div>
		<b>
			<FormattedMessage id="App.LogbookPropertySnapshot" />
		</b>
		{log.content.pitMode === 'measure' && measureMode(log)}
		{log.content.pitMode === 'compare' && compareMode(log)}
	</div>
);

export default Snapshot;
