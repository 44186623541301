// @flow

import { isLive } from 'apis/urls';
import { getTextValue } from 'utils/registryHelper';

const ContractTypeDevice: '0' = '0';
const ContractTypeMaintenanceServices: '1' = '1';
const ContractTypeSoftware: '2' = '2';

export const ContractType = {
	Device: ContractTypeDevice,
	MaintenanceServices: ContractTypeMaintenanceServices,
	Software: ContractTypeSoftware,
};

export type ContractTypes = $Values<typeof ContractType>;

export type SerialLicenseNumberSuggestion = {
	[product: string]: {
		probeSN: string,
	},
};

export const ConnectionTypeSoftware = 'software';
export const ProductConstants = {
	INSPECT: 'INSPECT',
	PI8000: 'PI8000',
	PIT_IE: 'PIT_IE',
	GPR_INSIGHTS: 'GPR_INSIGHTS',
	GPR_SOIL: 'GPR_SOIL',
	PUNDIT: 'PUNDIT',
	PROFOMETER: 'PROFOMETER',
	PM8000: 'PM8000',
	PM8500: 'PM8500',
	GLM: 'GLM',
	SSL: 'SSL',
	SCHMIDT: 'SCHMIDT',
	PS8000: 'PS8000',
	RS8000: 'RS8000',
};

export const LicenseStatus = {
	ENABLED: 1, // default
	ACTIVATED: 2,
	REVOKED: 3,
};
export const getLicenseStatus = (value: number) => {
	if (value === LicenseStatus.ACTIVATED) {
		return 'Activated';
	}
	if (value === LicenseStatus.REVOKED) {
		return 'Revoked';
	}
	return 'Enabled';
};

export const SaasTier = {
	EXTERNAL: 0, // default
	INTERNAL: 1,
};

export const Action = {
	Created: 1,
	Updated: 2,
	Deleted: 3,
	InitialState: 4,
};

export const AuditHistoryType = {
	Contract: 'contracts',
	License: 'licenses',
};

export const DocumentType = {
	Contract: 'Contract',
	License: 'License',
};

export const UserTypeInternal = 1;
export const UserTypeEndUser = 0;
export const UserTypeReseller = 2;

type Option = {
	name: string,
	value: number,
};

export const UserTypeOptions: Option[] = [
	{ name: 'End User', value: UserTypeEndUser },
	{ name: 'Internal User', value: UserTypeInternal },
	{ name: 'Reseller', value: UserTypeReseller },
];

export const getEagleCareText = (value: number) => {
	return value === 1 ? 'Yes' : value === 0 ? 'No' : 'NA';
};

export const getName = (options: Option[], type: number) => {
	for (const entry of options) {
		if (entry.value === type) {
			return entry.name;
		}
	}
	return '';
};

export const getValue = (options: Option[], name: string) => {
	for (const entry of options) {
		if (entry.name === name) {
			return entry.value;
		}
	}
	return '';
};

export const validateSN = (contract: Object, isAdmin: boolean) => {
	// Skip SN validation check when updating contract for admin users
	return contract === undefined || !isAdmin;
};

export const productComparator = (key: string) => {
	return function(a: Object, b: Object) {
		if (a === undefined || b === undefined || typeof a[key] !== 'string' || typeof b[key] !== 'string') {
			return 1;
		}
		return a[key].toUpperCase() > b[key].toUpperCase() ? 1 : b[key].toUpperCase() > a[key].toUpperCase() ? -1 : 0;
	};
};

export const caseInsensitiveCompare = (a: any, b: any) => {
	return typeof a === 'string' && typeof b === 'string'
		? a.localeCompare(b, undefined, { sensitivity: 'accent' }) === 0
		: a === b;
};

export const GPRFamily: any[] = getTextValue([isLive ? 'wifiproductslive' : 'wifiproductsfull']).map(
	({ value }) => value
);
export const EquotipFamily: any[] = getTextValue([isLive ? 'bluetoothproductslive' : 'bluetoothproductsfull']).map(
	({ value }) => value
);
export const AllProds: any[] = getTextValue(['latestavailproductsfull']).map(({ value }) => value);
export const SaasProds: any[] = getTextValue(['saasProducts']).map(({ value }) => value.toUpperCase());
export const SoftwareProds: any[] = getTextValue(['softwareproducts']).map(({ value }) => value);

export const getProductFamily = (product: string = '', type: string): string | null => {
	const contractType = `${type}`;
	const productType = product.toUpperCase();
	if (contractType === ContractType.Device) {
		if (GPRFamily.includes(productType)) {
			return 'gpr';
		}
		if (EquotipFamily.includes(productType)) {
			return 'equotip';
		}
	}
	if (contractType === ContractType.Software) {
		if (SaasProds.includes(productType)) {
			return 'inspect';
		}
		if (SoftwareProds.includes(productType)) {
			return 'software';
		}
	}
	if (contractType === ContractType.MaintenanceServices) {
		if (AllProds.includes(productType)) {
			return 'maintenanceservices';
		}
	}
	// eslint-disable-next-line no-console
	console.error(`Product ${productType} not found in any product family`);
	return null;
};
