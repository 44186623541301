/**
 * Components using the react-intl module require access to the intl context.
 * This is not available when mounting single components in Enzyme.
 * These helper functions aim to address that and wrap a valid,
 * English-locale intl context around them.
 */

import React from 'react';
import { IntlProvider } from 'react-intl';
import { mount, shallow } from 'enzyme';

// You can pass your messages to the IntlProvider. Optional: remove if unneeded.
import common from '../i18n/common';
import en from '../i18n/en';

// Create the IntlProvider to retrieve context for wrapping around.
const props = { locale: en.lang, messages: { ...common, ...en.messages } };
const intlProvider = new IntlProvider(props, {});
const { intl } = intlProvider.state;

/**
 * When using React-Intl `injectIntl` on components, props.intl is required.
 */
function nodeWithIntlProp(node) {
	return React.cloneElement(node, { intl });
}

/**
 * Export these methods.
 */
export function shallowWithIntl(node) {
	return shallow(nodeWithIntlProp(node), { context: { intl } });
}

export function mountWithIntl(node) {
	return mount(node, {
		wrappingComponent: IntlProvider,
		wrappingComponentProps: props,
	});
}
