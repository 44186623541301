// @flow
import { httpRequest } from 'apis/httpClient';
import updatePasswordAsync from 'actionUtils/updatePasswordAsync';
import { checkStatusJson } from 'apis/restUtils';
import URL from 'apis/urls';
import errorHandler from 'actionUtils/errorHandler';
import { getProceqUrl, isUsingSharedToken } from 'utils/sessionHelper';
import actionTypes from './actionTypes';

export function profileLoaded(userProfile: any) {
	return {
		type: actionTypes.PROFILE_LOADED,
		payload: userProfile,
	};
}

export function profileLoadedProceq(userProfile: any) {
	return {
		type: actionTypes.PROCEQ_PROFILE_LOADED,
		payload: userProfile,
	};
}

export function portraitLoaded(urlPortrait: any) {
	return {
		type: actionTypes.PORTRAIT_LOADED,
		payload: urlPortrait,
	};
}

export function passwordUpdateFail() {
	return {
		type: actionTypes.PASSWORD_UPDATE_FAILED,
	};
}

export function passwordUpdateSuccess() {
	return {
		type: actionTypes.PASSWORD_UPDATE_SUCCESS,
	};
}

export function fetchProfile(userID: string, session: any, locale: any) {
	return (dispatch: Function) =>
		httpRequest(session, locale, URL.GET_ACCOUNT.replace('{key}', userID), 'get', true, {})
			.then(checkStatusJson)
			.then(json => {
				dispatch(profileLoaded(json));
				return json;
			})
			.catch(err => {
				errorHandler(err);
			});
}

export function loadProfile() {
	return (dispatch: Function, getState: Function) => {
		if (getState().session.sessionType === 'TDS') {
			return dispatch(fetchProfile(getState().session.userID, getState().session, getState().locale));
		}
		if (isUsingSharedToken(getState().session)) {
			return dispatch(
				profileLoadedProceq({
					email: 'guest@guest',
					firstName: 'guest',
					lastName: 'guest',
					userID: '99999999',
					portrait: '',
				})
			);
		}
		return httpRequest(
			getState().session,
			getState().locale,
			getProceqUrl(getState().session, URL.PROCEQ_GET_ACCOUNT),
			'post',
			true,
			{}
		)
			.then(checkStatusJson)
			.then(json => {
				dispatch(profileLoadedProceq(json.data));
			})
			.catch(err => {
				errorHandler(err);
			});

		/* else {
			return httpRequest(getState().session, URL.GET_SELF, 'get', true, {
				filterValue: getState().session.userID,
				filterKey: 'id'
			})
			.then(checkStatusJson)
			.then((json) => {
				dispatch(profileLoaded(json));
			})
			.catch((err) => {
				errorHandler(err);
			});
		} */
	};
}

export function updatePassword(oldPass: string, userPass: string, sessionData: any) {
	return (dispatch: Function, getState: Function) =>
		updatePasswordAsync(oldPass, userPass, sessionData, getState().session, getState().locale)
			.then(() => {
				dispatch(passwordUpdateSuccess());
			})
			.catch(err => {
				errorHandler(err);
				dispatch(passwordUpdateFail());
			});
}

export function setPortrait(dataBlob: any) {
	return (dispatch: Function, getState: Function) => {
		const data = new FormData();
		data.append('file', dataBlob);
		const request = new XMLHttpRequest();
		request.open('PUT', URL.SET_PORTRAIT, true);
		request.setRequestHeader('Authorization', `Bearer ${getState().session.bearerToken}`);
		request.onreadystatechange = () => {
			if (request.readyState !== 4) {
				return;
			}
			if (request.status === 200) {
				const dataJson = JSON.parse(request.responseText);
				dispatch(portraitLoaded(dataJson.url));
			} else {
				errorHandler(request);
			}
		};
		request.onerror = err => {
			errorHandler(err);
		};
		request.send(data);
	};
}
