// @flow
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { message, Popconfirm } from 'antd';
import type { InjectIntlProvidedProps } from 'react-intl';
import { FormattedMessage, injectIntl } from 'react-intl';
import URL from 'apis/urls';
import { request } from 'actions/ajax';

export const TwoFAReset = ({
	reset2FA,
}: {
	reset2FA: Function,
} & InjectIntlProvidedProps) => {
	return (
		<Popconfirm
			title={<FormattedMessage id="Proceq.Reset2FactorAuthentication" />}
			onConfirm={reset2FA}
			okText={<FormattedMessage id="Proceq.TextYes" />}
			cancelText={<FormattedMessage id="Proceq.TextNo" />}
		>
			<div style={{ width: '100%', height: '100%' }}>
				<FormattedMessage id="Proceq.Reset2FactorAuthentication" />
			</div>
		</Popconfirm>
	);
};

export default compose(
	injectIntl,
	connect(null, (dispatch: Function, props) => ({
		reset2FA: () => {
			dispatch(
				request({
					method: 'POST',
					url: URL.RESET_2_FACTOR_AUTHENTICATION.replace('{id}', props.id),
				})
			).then(() => {
				props.loadUsers();
				message.success(
					props.intl.formatMessage({
						id: 'Proceq.Message2FactorAuthenticationSuccessfullyChanged',
					})
				);
			});
		},
	}))
)(TwoFAReset);
