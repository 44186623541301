// @flow
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { renderLogbookDate } from 'utils/dateHandler';

export const Probe = ({ log: { content }, product }: EquotipLogbookEntryProps) => (
	<div className="full-container intro-container">
		<div>
			<div className="initial-image">
				<img
					alt=""
					height="100"
					width="100"
					src={
						content.primaryScaleId === 25
							? `${process.env.PUBLIC_URL || ''}/img/proceq/probe_uci.png`
							: `${process.env.PUBLIC_URL || ''}/img/proceq/probe_${product.toLowerCase()}.png`
					}
				/>
			</div>
			<div className="initial-content">
				<b>{content.probeModel}</b>
				<br />
				<FormattedMessage id="Proceq.LogbookPropertySerialNumberShort" />
				{': '}
				{content.probeSerialNo}
				<br />
				<FormattedMessage id="Proceq.LogbookPropertyBluetoothModuleShort" />
				{': '}
				{content.btmSN}
				<br />
				<FormattedMessage id="Proceq.LogbookPropertyVerified" />
				{': '}
				{content.verifiedOn ? (
					renderLogbookDate(content.verifiedOn)
				) : (
					<FormattedMessage id="Proceq.TextUnknown" />
				)}
				<br />
				{!!content.contractType && (
					<div>
						<FormattedMessage id="Proceq.LogbookContractType" />
						{': '}
						<FormattedMessage id={content.contractType} />
					</div>
				)}
				<FormattedMessage id="Proceq.LogbookPropertyContractValidUntil" />
				{': '}
				{content.contractExpirationDate ? (
					content.contractExpirationDate === 'Lifetime' ? (
						<FormattedMessage id="Proceq.TextLifetime" />
					) : (
						renderLogbookDate(content.contractExpirationDate)
					)
				) : (
					<FormattedMessage id="Proceq.TextUnknown" />
				)}
				<br />
				{/* <FormattedMessage id='Proceq.LogbookPropertyOperator' />: {author} */}
			</div>
		</div>
	</div>
);

export default Probe;
