// @flow
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { getLabelFromGlobalDataKey } from 'utils/registryHelper';
import { ProceqMA8000 } from 'constants/exportConstants';

export const Probe = ({ log, productModel }: { log: Log, productModel: string }) => (
	<div className="full-container intro-container">
		<div>
			<div className="initial-content">
				<b>{getLabelFromGlobalDataKey('wifiproductsfull', productModel.toUpperCase())}</b>
				<br />
				<FormattedMessage
					id={
						productModel === 'fdl'
							? 'App.LogbookPropertyBaseUnitNumber'
							: 'App.LogbookPropertyProbeSerialNumber'
					}
				/>
				{': '}
				{log.content.probeSerialNo}
				<br />
				<FormattedMessage id="App.LogbookPropertyHardwareVersion" />
				{': '}
				{log.content.hardwareVersion}
				<br />
				<FormattedMessage id="App.LogbookPropertyFirmwareVersion" />
				{': '}
				{log.content.firmwareVersion}
				<br />
				<FormattedMessage id="App.LogbookPropertyMacAddress" />
				{': '}
				{log.content.macAddress}
				<br />
				<FormattedMessage id="App.LogbookPropertyContractType" />
				{': '}
				{log.content.contractType}
				<br />
				<FormattedMessage id="App.LogbookPropertyContractValidity" />
				{': '}
				{log.content.contractExpirationDate}
				<br />
				<FormattedMessage id="App.LogbookPropertyAppVersion" />
				{': '}
				{log.content.version}
				<br />
				{log.content.ma8000SerialNumber && (
					<div>
						<b>{ProceqMA8000}</b>
						<br />
						<FormattedMessage id="App.LogbookPropertyProbeSerialNumber" />
						{': '}
						{log.content.ma8000SerialNumber}
					</div>
				)}
			</div>
		</div>
	</div>
);

export default Probe;
