// @flow
import React, { Fragment } from 'react';
import { compose } from 'recompose';
import type { InjectIntlProvidedProps } from 'react-intl';
import { FormattedMessage, injectIntl } from 'react-intl';

import handleFormatMessage from 'utils/handleFormatMessage';
import {
	getMaterialNameOfMaterialId,
	getStandardNameOfStandardId,
	getScaleInfoOfScaleId,
	getResolutionForScale,
	getSchmidtScaleIdOfFromFactorAndUnitId,
} from 'utils/conversionsHelper';
import StaticMap from '../StaticMap';

export const Creation = ({
	intl,
	log,
	product,
	isVerificationMode,
	primaryEquotipScaleId,
	primaryVerificationScaleId,
}: EquotipLogbookEntryProps & InjectIntlProvidedProps) => {
	const formatLimitValue = (scale, value) =>
		`${getResolutionForScale(
			getScaleInfoOfScaleId(intl.formatMessage, scale, product).scaleUnits,
			value,
			'limit'
		)} ${getScaleInfoOfScaleId(intl.formatMessage, scale, product).scaleUnits}`;

	const {
		// btSerialNumber,
		folderName,
		materialID,
		maxLimitValue,
		minLimitValue,
		probeTypeID,
		secondaryScaleId,
		seriesName,
		standardID,
		triggerLoadId,
		testBlockTitle,
	} = log.content.measurementInfo;

	const renderMap = () => {
		const { latitude, longitude } = log.content;

		if (!longitude && !latitude) {
			return null;
		}
		return (
			<Fragment>
				<br />
				<StaticMap width={400} height={200} zoom={16} coordinates={{ lat: +latitude, lng: +longitude }} />
				{/* <InteractiveMap height={ 200 } zoom={ 16 } coordinates={ coordinates } /> */}
			</Fragment>
		);
	};

	if (isVerificationMode) {
		let primaryVerificationScaleName = '';
		switch (probeTypeID) {
			case 0: // EQUOTIP D
			default:
				primaryVerificationScaleId = 2;
				primaryVerificationScaleName = 'Leeb D';
				break;
			case 1: // EQUOTIP UCI
				primaryVerificationScaleId = 25;
				primaryVerificationScaleName = 'UCI Vickers';
				break;
			case 2: // SCHMIDT L
				primaryVerificationScaleId = getSchmidtScaleIdOfFromFactorAndUnitId(null, null);
				primaryVerificationScaleName = 'R';
				break;
			case 3: // SCHMIDT N
				primaryVerificationScaleId = getSchmidtScaleIdOfFromFactorAndUnitId(null, null);
				primaryVerificationScaleName = 'R';
				break;
			case 4: // SILVER SCHMIDT L
				primaryVerificationScaleId = getSchmidtScaleIdOfFromFactorAndUnitId(null, null);
				primaryVerificationScaleName = 'Q';
				break;
			case 5: // SILVER SCHMIDT N
				primaryVerificationScaleId = getSchmidtScaleIdOfFromFactorAndUnitId(null, null);
				primaryVerificationScaleName = 'Q';
				break;
		}

		return (
			<div>
				{handleFormatMessage('LogbookPropertyCreatedVerification', intl.formatMessage, [seriesName])}
				{triggerLoadId && (
					<div>
						<FormattedMessage id="Proceq.LogBookTestLoad" />
						{`: HV${triggerLoadId} (${triggerLoadId}kgf)`}
					</div>
				)}
				<div>
					<FormattedMessage id="Proceq.ExportFieldStandard" />
					{': '}
					{getStandardNameOfStandardId(null, intl.formatMessage, standardID, product)}
				</div>
				{testBlockTitle && (
					<div>
						{product === 'EQUOTIP' ? (
							<div>
								<FormattedMessage id="Proceq.LogBookTestBlock" />
								{': '}
								{testBlockTitle}
							</div>
						) : (
							<div>
								<FormattedMessage id="Proceq.LogBookCalibrationAnvil" />
								{': '}
								{testBlockTitle}
							</div>
						)}
					</div>
				)}
				<div>
					<FormattedMessage id="Proceq.ExportFieldPrimaryScale" />
					{': '}
					{primaryVerificationScaleName}
				</div>
				{minLimitValue && (
					<div>
						<FormattedMessage id="Proceq.ExportFieldLowerLimit" />
						{': '}
						{formatLimitValue(primaryVerificationScaleId, minLimitValue.primaryValue)}
					</div>
				)}
				{maxLimitValue && (
					<div>
						<FormattedMessage id="Proceq.ExportFieldUpperLimit" />
						{': '}
						{formatLimitValue(primaryVerificationScaleId, maxLimitValue.primaryValue)}
					</div>
				)}
				{renderMap()}
			</div>
		);
	}

	return (
		<div>
			{handleFormatMessage('LogbookPropertyCreatedSeriesInFolder', intl.formatMessage, [seriesName, folderName])}
			{triggerLoadId && (
				<div>
					<FormattedMessage id="Proceq.LogBookTestLoad" />
					{`: HV${triggerLoadId} (${triggerLoadId}kgf)`}
				</div>
			)}
			<div>
				<FormattedMessage id="Proceq.ExportFieldMaterial" />
				{': '}
				{getMaterialNameOfMaterialId(null, intl.formatMessage, materialID)}
				<br />
				<FormattedMessage id="Proceq.ExportFieldStandard" />
				{': '}
				{getStandardNameOfStandardId(null, intl.formatMessage, standardID, product)}
				<br />
				<FormattedMessage id="Proceq.ExportFieldPrimaryScale" />
				{': '}
				{getScaleInfoOfScaleId(intl.formatMessage, primaryEquotipScaleId, product).scaleName}
				<br />
				<FormattedMessage id="Proceq.ExportFieldSecondaryScale" />
				{': '}
				{getScaleInfoOfScaleId(intl.formatMessage, secondaryScaleId, product).scaleName}
				<br />
				{minLimitValue && (
					<div>
						<FormattedMessage id="Proceq.ExportFieldLowerLimit" />
						{': '}
						{formatLimitValue(primaryEquotipScaleId, minLimitValue.primaryValue)}
						{' / '}
						{minLimitValue.secondaryValue ? (
							formatLimitValue(minLimitValue.secondaryScaleId, minLimitValue.secondaryValue)
						) : (
							<span>
								<FormattedMessage id="Proceq.LogbookNoConversionValue" />{' '}
								{
									getScaleInfoOfScaleId(intl.formatMessage, minLimitValue.secondaryScaleId, product)
										.scaleUnits
								}
							</span>
						)}
					</div>
				)}
				{maxLimitValue && (
					<div>
						<FormattedMessage id="Proceq.ExportFieldUpperLimit" />
						{': '}
						{formatLimitValue(primaryEquotipScaleId, maxLimitValue.primaryValue)}
						{' / '}
						{maxLimitValue.secondaryValue ? (
							formatLimitValue(maxLimitValue.secondaryScaleId, maxLimitValue.secondaryValue)
						) : (
							<span>
								<FormattedMessage id="Proceq.LogbookNoConversionValue" />{' '}
								{
									getScaleInfoOfScaleId(intl.formatMessage, maxLimitValue.secondaryScaleId, product)
										.scaleUnits
								}
							</span>
						)}
					</div>
				)}
			</div>
			{renderMap()}
		</div>
	);
};

export const color = () => 'green';

export default compose(injectIntl)(Creation);
