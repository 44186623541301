// @flow
import React from 'react';
import { get } from 'lodash';
import { FormattedMessage } from 'react-intl';
import './Logbook.css';
import { renderLogbookDate } from 'utils/dateHandler';
import { getSchmidtScaleIdOfFromFactorAndUnitId } from 'utils/conversionsHelper';
import LogEvent, { renderUserInitials } from './LogEvent/LogEvent';
import ProbeLogEvent from './LogEvent/EQUOTIP/Probe';
import GLMProbe from './LogEvent/GLM/Probe';

export const EquotipLogbook = ({
	isVerificationMode,
	product,
	productModel,
	logs,
	users,
	version,
}: {
	isVerificationMode: boolean,
	product: ProductCode,
	productModel: string,
	logs: Log[],
	users: User[],
	version: string,
}) => {
	const probeLog: ?Log = logs && logs.find(log => log.type === 'probe');
	const primaryScaleId = get(
		logs && logs.find(log => log.type === 'creation'),
		'content.measurementInfo.primaryScaleId'
	);
	const equotipScale = primaryScaleId || 2;
	const schmidtScale = primaryScaleId || getSchmidtScaleIdOfFromFactorAndUnitId(null, null);
	return (
		<div id="logbookModal">
			<div id="chat-body" className="chat-body-custom-scroll">
				{logs === null && <FormattedMessage id="App.LogbookNoEntries" />}
				{!!probeLog &&
					(() => {
						switch (product) {
							case 'GLM':
								return <GLMProbe log={probeLog} />;
							default:
								return (
									<ProbeLogEvent
										log={probeLog}
										product={product}
										productModel={productModel}
										author=""
										isVerificationMode={isVerificationMode}
										primaryEquotipScaleId={equotipScale}
										primarySchmidtScaleId={schmidtScale}
										primaryVerificationScaleId={product === 'EQUOTIP' ? equotipScale : schmidtScale}
										version={version}
									/>
								);
						}
					})()}
				<ul>
					{logs &&
						logs
							.filter(log => ['probe'].indexOf(log.type) < 0)
							.sort(
								(a, b) =>
									a.clientCreated - b.clientCreated ||
									a.content.measurementIndex - b.content.measurementIndex
							)
							.map((log, index) => (
								<LogEvent
									key={log.id}
									log={log}
									product={product}
									productModel={productModel}
									user={renderUserInitials(users, log.uID)}
									highlighted={false}
									showDate={
										index === 0 ||
										renderLogbookDate(log.clientCreated) !==
											renderLogbookDate(get(logs, [String(index - 1), 'clientCreated']))
									}
									date={log.clientCreated}
									isVerificationMode={isVerificationMode}
									primaryEquotipScaleId={equotipScale}
									primarySchmidtScaleId={schmidtScale}
									primaryVerificationScaleId={product === 'EQUOTIP' ? equotipScale : schmidtScale}
									version={version}
								/>
							))}
				</ul>
			</div>
		</div>
	);
};

export default EquotipLogbook;
