// @flow
import React from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { compose, withState, withProps } from 'recompose';
import { FormattedMessage } from 'react-intl';
import { Modal, Button } from 'antd';
import URL from 'apis/urls';
import styles from 'components/shared/SmarterTable.styl';
import { request } from 'actions/ajax';
import HistoryIcon from '../img/orderHistory.png';
import HistoryLog from './HistoryLog';

export const AuditHistory = ({
	visible,
	openModal,
	closeModal,
	loading,
	logs,
	logbookProps,
	products,
	documentType,
	isProductSaas,
	exportAsCSV,
}: {
	visible: boolean,
	openModal: Function,
	closeModal: Function,
	loading: boolean,
	logs: Log[],
	logbookProps: Object,
	products: { text: string, value: any }[],
	documentType: string,
	isProductSaas: boolean,
	exportAsCSV: Function,
}) => {
	return (
		<div className={classNames(styles.button, styles.inline)}>
			<Modal
				visible={visible}
				onOk={closeModal}
				onCancel={closeModal}
				title={<FormattedMessage id="Contract History" />}
				width={800}
				footer={[
					<Button key="export" size="large" onClick={exportAsCSV} loading={loading}>
						<FormattedMessage id="Proceq.TableActionExportAsCSV" />
					</Button>,
					<Button key="back" size="large" onClick={closeModal}>
						<FormattedMessage id="Proceq.LogbookClose" />
					</Button>,
				]}
			>
				<HistoryLog
					logs={logs}
					products={products}
					documentType={documentType}
					isProductSaas={isProductSaas}
					{...logbookProps}
				/>
			</Modal>
			<Button
				type="primary"
				onClick={openModal}
				loading={loading}
				className={styles.icon_container}
				data-e2e-logbook-button
			>
				{!loading && <img src={HistoryIcon} className={styles.icon} alt="History" />}
			</Button>
		</div>
	);
};

export default compose(
	withState('visible', 'setVisible', false),
	withState('loading', 'setLoading', false),
	withState('logs', 'setLogs', null),
	withState('products', 'setProducts', []),
	withState('logbookProps', 'setLogbookProps', props => props.logbookProps || {}),
	connect(null, (dispatch: Function, props) => ({
		fetchHistory: () =>
			dispatch(
				request({
					url: URL.DOCUMENT_HISTORY.replace('{type}', props.documentType).replace('{key}', props.id),
				})
			).then(data => {
				props.setLogs(data.data);
			}),

		loadProducts: () => {
			dispatch(
				request({
					method: 'GET',
					url: URL.GET_PRODUCT,
					setLoading: props.setLoading,
					params: {
						connectionType: props.connectionType,
					},
				})
			).then(data => {
				const options = data
					.map(product => {
						return { value: product.code, text: product.name };
					})
					.filter(Boolean);
				props.setProducts(options);
			});
		},
		exportAsCSV: () => {
			dispatch(
				request({
					method: 'GET',
					url: URL.EXPORT_DOCUMENT_HISTORY.replace('{type}', props.documentType).replace('{key}', props.id),
					setLoading: props.setLoading,
					returnResponseHeaders: true,
				})
			).then(res => {
				window.fileDownload(
					res.data,
					(res.headers['content-disposition'] || '').split('"')[1],
					res.headers['content-type']
				);
			});
		},
	})),
	withProps(({ fetchHistory, loadProducts, setVisible, setLoading }) => ({
		openModal: () => {
			setLoading(true);
			const networkRequests = [fetchHistory(), loadProducts()];
			Promise.all(networkRequests).then(() => {
				setLoading(false);
				setVisible(true);
			});
		},
		closeModal: () => {
			setLoading(false);
			setVisible(false);
		},
	}))
)(AuditHistory);
