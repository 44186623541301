module.exports = {
	'Proceq.StandardEnumProceq': 'Proceq',
	'Proceq.StandardEnumISO18265': 'ISO 18265',
	'Proceq.StandardEnumASTME140': 'ASTM E140',
	'Proceq.WebRegistrationPrivacyPolicy':
		'I agree to the <a href="**?0**" target="_blank">Privacy Policy</a> and <a href="**?1**" target="_blank">Terms of Use</a>',
	'Proceq.TabLoginProceq': 'Proceq',
	'Proceq.EmailReminderContractAdminBody':
		'Dear BU<br/><br/>Following contract is expiring within the next few months:<br/><br/>Registered to: **?0** <br/> Contact Email: **?1** <br/> Expiration Date: **?2** <br/> Impact Device Bluetooth Modul Info: **?3** <br/> Impact Device Hardware ID: **?4** <br/><br/>Best regards<br/><br/>Your Proceq Team<br/><br/>**?5**',
	'Proceq.EmailNewsletterSubscridedBUSubject': "New Proceq Live's newsletter suscriptions",
	'Proceq.EmailNewsletterSubscridedBUBody':
		'Dear BU<br/><br/>Following users have recently opted-in for the newsletter:<br/><br/>**?0** <br/><br/>Your Proceq Team<br/><br/>**?1**',
	'Proceq.EmailHealthyChecksErrorSubject': 'Proceq Live: Health checks alerts!',
	'Proceq.EmailHealthyChecksErrorBody':
		'Dear Team<br/><br/>Following health alerts have been triggered:<br/><br/>**?0** <br/><br/>Your Proceq Team<br/><br/>**?1**',
	'Proceq.eVerNStd_ISO_16859': 'ISO 16859',
	'Proceq.eVerNStd_ASTM_A956': 'ASTM A956',
	'Proceq.eVerNStd_ASTM_A1038': 'ASTM A1038',
	'Proceq.eVerNStd_DIM_50195': 'DIM 50195',
	'Proceq.eVerNStd_Proceq': 'Proceq',
	'Proceq.ScaleEnumLeebD': 'Leeb D',
	'Proceq.ScaleEnumLeebS': 'Leeb S',
	'Proceq.ScaleEnumLeebE': 'Leeb E',
	'Proceq.ScaleEnumLeebDL': 'Leeb DL',
	'Proceq.ScaleEnumLeebC': 'Leeb C',
	'Proceq.ScaleEnumLeebG': 'Leeb G',
	'Proceq.ScaleEnumLeebSVP': 'Leeb SVP',
	'Proceq.ScaleEnumLeeb': 'Leeb',
	'Proceq.ScaleEnumBrinell': 'Brinell',
	'Proceq.ScaleEnumVickers': 'Vickers',
	'Proceq.ScaleEnumVickersUCI': 'UCI Vickers',
	'Proceq.ScaleEnumRockwellA': 'Rockwell A',
	'Proceq.ScaleEnumRockwellB': 'Rockwell B',
	'Proceq.ScaleEnumRockwellBN': 'Rockwell BN',
	'Proceq.ScaleEnumRockwellC': 'Rockwell C',
	'Proceq.ScaleEnumRockwellE': 'Rockwell E',
	'Proceq.ScaleEnumRockwellH': 'Rockwell H',
	'Proceq.ScaleEnumRockwell15N': 'Rockwell 15N',
	'Proceq.ScaleEnumRockwell15T': 'Rockwell 15T',
	'Proceq.ScaleEnumRockwell': 'Rockwell',
	'Proceq.ScaleEnumShore': 'Shore',
	'Proceq.ScaleEnumMPa': 'MPa',
	'Proceq.ScaleEnumLeebU': 'Leeb U',
	'Proceq.eRebNStd_EN_12540_2': 'EN 12540-2',
	'Proceq.eRebNStd_ASTM_C805': 'ASTM C805',
	'Proceq.eRebNStd_JGJ_T23': 'JGJ T23',
	'Proceq.eRebNStd_JSCE_G504': 'JSCE-G504',
	'Proceq.eRebNStd_JIS': 'JIS',
	'Proceq.eRebNStd_NUM_std': 'NUM_std',
	'Proceq.eCC_PROCEQ_B_N': 'Proceq B (N)',
	'Proceq.eCC_PROCEQ_A_N': 'Proceq A (N)',
	'Proceq.eCC_M_JAP_N': 'M-JAP',
	'Proceq.eCC_A_JAP_N': 'A-JAP',
	'Proceq.eCC_T_JAP_N': 'T-JAP',
	'Proceq.eCC_P_JAP_N': 'P-JAP',
	'Proceq.eCC_PROCEQ_B_L': 'Proceq B (L)',
	'Proceq.eCC_PROCEQ_A_L': 'Proceq A (L)',
	'Proceq.eCC_SS_Low10_N': '<10 %',
	'Proceq.eCC_SS_EU_N': 'Ref EU',
	'Proceq.eCC_SS_CN_N': 'Ref CN',
	'Proceq.eCC_SS_RUS_N': 'Ref RUS',
	'Proceq.eCC_SS_XX_L': 'Ref L',
};
