// @flow
import React from 'react';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import { DATE_LOGBOOK_FORMAT } from 'utils/dateHandler';
import Audio from '../Audio';
import styles from './UserText.styl';

export const UserAudio = ({ log }: GPRLogbookEntryProps) =>
	log.content.deletedAt ? (
		<div className={styles.deleted}>
			<FormattedMessage
				id="App.LogbookAudioDeleted"
				values={{
					date: moment(log.content.deletedAt).format(DATE_LOGBOOK_FORMAT),
				}}
			/>
		</div>
	) : (
		<div>
			<b>
				<FormattedMessage id="App.LogbookAudioAdded" />
			</b>
			<Audio mID={log.mID} aID={log.content.attachmentId} attachmentName={log.content.attachmentName} />
		</div>
	);

export const icon = (log: Log) => (log.content.deletedAt ? 'log_deleted' : 'log');

export default UserAudio;
