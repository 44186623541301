// @flow
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { convertAndDisplay } from 'constants/conversionRates';
import StaticMap from '../StaticMap';

export const Creation = ({ log, scanType, isMetric }: GPRLogbookEntryProps) => {
	const { latitude, longitude, measurementInfo } = log.content;
	const coordinates = { lat: +latitude, lng: +longitude };
	const {
		folderName,
		fileName,
		isHighPassFilterOn,
		isLowPassFilterOn,
		highPass,
		lowPass,
		mode,
		timeGain,
		velocity,
	} = measurementInfo;

	const convert = (value, unitId) => {
		return isFinite(+value) ? convertAndDisplay(unitId, scanType, isMetric).convertFunction(+value) : '-';
	};

	return (
		<div>
			<b>
				<FormattedMessage
					id="Created {fileName} in folder {folderName}"
					values={{
						fileName,
						folderName,
					}}
				/>
			</b>
			<br />
			<FormattedMessage
				id="App.Logbook.PI8000.IsHighPassFilterOn"
				values={{ value: isHighPassFilterOn ? 'On' : 'Off' }}
			/>
			<br />
			<FormattedMessage id="App.Logbook.PI8000.HighPass" />
			{': '}
			{convert(highPass, 'PIT_IE.Settings Panel.High Pass Filter')}
			<br />
			<FormattedMessage
				id="App.Logbook.PI8000.IsLowPassFilterOn"
				values={{ value: isLowPassFilterOn ? 'On' : 'Off' }}
			/>
			<br />
			<FormattedMessage id="App.Logbook.PI8000.LowPass" />
			{': '}
			{convert(lowPass, 'PIT_IE.Settings Panel.Low Pass Filter')}
			<br />
			<FormattedMessage id="App.Logbook.PI8000.Mode" />
			{`: ${mode}`}
			<br />
			<FormattedMessage id="App.Logbook.PI8000.TimeGain" />
			{`: ${timeGain}`}
			<br />
			<FormattedMessage id="App.Logbook.PI8000.Velocity" />
			{`: ${velocity}`}
			<br />
			<StaticMap width={400} height={200} zoom={16} coordinates={coordinates} />
		</div>
	);
};

export const color = () => 'green';

export default Creation;
