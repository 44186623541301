// @flow
import React from 'react';
import { has, get } from 'lodash';
import { Table } from 'antd';
import { FormattedMessage } from 'react-intl';
import { getFileNameWithExpectedExtensions } from 'utils/fileUtils';
import ConvertedUnits from 'components/shared/ConvertedUnits';
import type { DerivedProps } from 'components/measurements/HTMLExport/Measurement';
import styles from 'components/measurements/HTMLExport/HTMLExport.styl';
import Audio from 'components/measurements/LogEvent/Audio';
import { ProductConstants } from 'components/contracts/contractConsts';
import { MeasurementScanType } from 'constants/exportConstants';
import { filterReadings } from './ScanDistance';

import type { MeasurementObjectWithInfo } from './Objects';
import { getLocationCoordinates, getLocalXY } from './Objects';

export const title = <FormattedMessage id="Markers" />;

export const renderComment = (text: string, record: MeasurementObjectWithInfo, data: MeasurementFullData) => {
	if (record.content.type === 'Text') {
		return text;
	}

	const attachment = data.attachments.find(a => a.id === text);
	const attachmentName = getFileNameWithExpectedExtensions(get(attachment, 'fileNames', []), ['m4a', 'aac']);

	return <Audio mID={record.mID} aID={text} attachmentName={attachmentName || `${text}.m4a`} download={false} />;
};

export const Markers = ({
	data,
	scanType,
	product,
	isMetric,
	convert,
	showTitle,
}: { data: MeasurementFullData } & DerivedProps) => {
	// we only want to use the most recent log entry
	const sortedLogs = data.logs.sort((a, b) => b.clientCreated - a.clientCreated);
	const filteredReadings = filterReadings(data, scanType);
	const isSoil = product.toUpperCase() === ProductConstants.GPR_SOIL;
	let markers: MeasurementObjectWithInfo[] = data.objects
		.filter(object => object.type === 'marker' && object.content.textType !== 'P')
		.map(object => {
			const reading = filteredReadings.find(r => r.id === object.rID);
			const log = sortedLogs.find(l => +l.content.sequence === +object.content.number);
			return {
				...object,
				...(isSoil && scanType === MeasurementScanType.AreaScan ? getLocalXY(data, object) : {}),
				...(isSoil ? getLocationCoordinates(object) : {}),
				readingContent: reading ? reading.content : {},
				logContent: log ? log.content : {},
				sequenceNo: reading ? reading.sequenceNo : 0,
			};
		});
	markers = markers.sort((a, b) => {
		// sort by line number first
		if (a.readingContent.sequence !== b.readingContent.sequence) {
			return a.readingContent.sequence - b.readingContent.sequence;
		}

		// this is actually the last to be sorted, applies only when type is sorted
		if (a.content.type === b.content.type) {
			return a.content.number - b.content.number;
		}

		// then sort by tag type
		return a.content.type === 'Text' ? -1 : 1;
	});

	const getDistanceLocationColumns = () => {
		const distanceColumns = [];
		if (isSoil) {
			distanceColumns.push(
				{
					title: 'Easting',
					unitId: `${product}.CSV.LocationCoordinates`,
					dataIndex: ['convertedCoordinateX'],
					width: 80,
				},
				{
					title: 'Northing',
					unitId: `${product}.CSV.LocationCoordinates`,
					dataIndex: ['convertedCoordinateY'],
					width: 80,
				}
			);
			if (scanType === MeasurementScanType.AreaScan) {
				distanceColumns.push(
					{
						title: 'Local X [{unit}]',
						unitId: `${product}.CSV.Tag objects Local XY`,
						dataIndex: ['localX'],
						width: 120,
					},
					{
						title: 'Local Y [{unit}]',
						unitId: `${product}.CSV.Tag objects Local XY`,
						dataIndex: ['localY'],
						width: 120,
					}
				);
			} else {
				distanceColumns.push({
					title: 'Distance along line [{unit}]',
					unitId: `${product}.CSV.Tag objects distance X`,
					dataIndex: ['content', 'distanceMeter'],
					width: 120,
				});
			}
		} else {
			distanceColumns.push(
				{
					title: 'Distance X [{unit}]',
					unitId: `${product}.CSV.Marker distance X`,
					dataIndex: ['content', 'distanceMeter'],
					width: 120,
				},
				{
					title: 'Distance Y [{unit}]',
					unitId: `${product}.CSV.Marker distance Y`,
					width: 120,
				}
			);
		}
		return distanceColumns;
	};

	return (
		<div className="table-markers">
			<Table
				title={showTitle ? () => <span className={styles.main_header}>{title}</span> : null}
				className={styles.table}
				columns={[
					{
						title: 'Line',
						render: (text, record: MeasurementObjectWithInfo) => {
							return record.sequenceNo;
						},
						width: 40,
					},
					{
						title: 'Marker Number',
						render: (text: string, record: MeasurementObjectWithInfo) =>
							`${record.content.type === 'Text' ? 'M' : 'V'}${record.content.number}`,
						width: 120,
					},
					...getDistanceLocationColumns(),
					{
						title: 'Comment',
						dataIndex: 'content.content',
						render: (text: string, record: MeasurementObjectWithInfo) => {
							return renderComment(text, record, data);
						},
					},
				].map(columnConfig => ({
					// eslint-disable-next-line no-unused-vars
					render: (text: string, record: MeasurementObjectWithInfo) =>
						has(columnConfig, 'unitId') ? convert(text, get(columnConfig, 'unitId')) : text,
					...columnConfig,
					title: has(columnConfig, 'unitId') ? (
						<ConvertedUnits
							id={columnConfig.title}
							unitId={columnConfig.unitId}
							scanType={scanType}
							isMetric={isMetric}
						/>
					) : (
						<FormattedMessage id={columnConfig.title} />
					),
				}))}
				dataSource={markers}
				pagination={false}
				size="small"
				bordered
			/>
		</div>
	);
};

export default Markers;
