// @flow
import React from 'react';

import { curryRight } from 'lodash';
import { compose } from 'recompose';
import {
	withHighcharts,
	HighchartsChart,
	Chart,
	XAxis,
	YAxis,
	Legend,
	LineSeries,
	SplineSeries,
	BarSeries,
	AreaSeries,
	PieSeries,
	ColumnSeries,
	AreaSplineSeries,
	Tooltip,
} from 'react-jsx-highcharts';
import Highcharts from 'highcharts';

function formatter(unitForLabel) {
	return function elaborateFormatter() {
		const header = 'x' in this && typeof this.x !== 'undefined' ? `<b>${this.x}</b><br/>` : '';
		let content = '';
		let allPoints = [];
		if ('points' in this) {
			allPoints = this.points;
		} else if ('point' in this) {
			allPoints = [this.point];
		}
		for (let indexSerie = 0; indexSerie < allPoints.length; indexSerie++) {
			const thisPoint = allPoints[indexSerie];
			if (indexSerie !== 0) {
				content += '<br/>';
			}
			content += `<span style="color:${thisPoint.color}">\u25CF</span> ${
				'name' in thisPoint ? thisPoint.name : thisPoint.series.name
			}:`;
			content += thisPoint.y + (unitForLabel ? ` ${unitForLabel}` : '');
		}
		return header + content;
	};
}

function simpleFormatter(percentage = false) {
	return function dasSimpleFormatter() {
		if (typeof this.y === 'undefined' || this.y === null || this.y === 0) {
			return '';
		}
		return percentage ? `${this.y} %` : this.y;
	};
}

export const Charts = ({
	type = 'line',
	baseId = '',
	series = [],
	colors = [
		'#00A1B1',
		'#55C0CB',
		'#8DD5DC',
		'#C6EAED',
		'#1CABB9',
		'#38B5C2',
		'#71CAD3',
		/* '#deebf7', '#f7fbff', */ '#AADFE5',
		'E2F4F6',
	],
	xAxis = {
		title: {
			text: '',
			offset: 40,
		},
		labels: {
			style: {
				fontFamily: 'Arial',
				fontSize: '15px',
			},
		},
	},
	yAxis = {
		title: {
			text: '',
			offset: 60,
		},
		labels: {
			style: {
				fontFamily: 'Arial',
				fontSize: '15px',
			},
			format: '',
		},
	},
	yAxisTitle,
	xAxisTitle,
	labels,
	unitForLabel = '',
	showLegend = true,
}: {
	type: 'pie' | 'area' | 'line' | 'spline' | 'bar' | 'column' | 'areaspline',
	baseId: string,
	series: Object[],
	colors: string[],
	xAxis: {
		title: Object,
		labels: Object,
	},
	yAxis: {
		title: Object,
		labels: Object,
	},
	yAxisTitle: string,
	xAxisTitle: string,
	labels: string[],
	unitForLabel: string,
	showLegend: boolean,
}) => {
	const thisChartId = `${baseId}-highchart`;

	if (yAxisTitle) {
		yAxis.title.text = yAxisTitle;
	}
	if (xAxisTitle) {
		xAxis.title.text = xAxisTitle;
	}

	let infoType = '';
	switch (type) {
		case 'pie':
		case 'area':
		case 'line':
		case 'spline':
		case 'bar':
		case 'column':
		case 'areaspline':
			infoType = type;
			break;
		default:
			infoType = 'line';
			break;
	}
	const infoChart = {
		type: infoType,
		style: {
			fontFamily: 'Arial',
			fontSize: '15px',
		},
	};

	/* let infoColors = [];
		for(let indexColor = 0; indexColor < colors.length; indexColor++)
		{
			infoColors.push(rgbToHex(colors[indexColor][0],colors[indexColor][1],colors[indexColor][2]));
		} */

	const plotOptions = {
		pie: {
			colors,
			dataLabels: {
				enabled: true,
				color: '#00A1B1',
				formatter: simpleFormatter(true),
				style: {
					color: 'contrast',
					fontSize: '16px',
					/* "fontWeight": "bold", */ textOutline: '1px contrast',
				},
			},
			showInLegend: true,
		},
		areaspline: {
			dataLabels: {
				enabled: true,
				color: '#00A1B1',
				formatter: simpleFormatter(),
				style: {
					color: 'contrast',
					fontSize: '22px',
					/* "fontWeight": "bold", */ textOutline: '1px contrast',
				},
			},
		},
		column: {
			/* borderWidth: 16, */
			dataLabels: {
				enabled: true,
				color: '#00A1B1',
				formatter: simpleFormatter(),
				style: {
					color: 'contrast',
					fontSize: '22px',
					/* "fontWeight": "bold", */ textOutline: '1px contrast',
				},
			},

			groupPadding: 0,
			pointPadding: 0.05,
		},
	};
	return (
		<HighchartsChart key={thisChartId} plotOptions={plotOptions}>
			{showLegend && (
				<Legend
					itemWidth={200}
					useHTML
					floating={false}
					itemStyle={{
						color: '#333333',
						cursor: 'pointer',
						fontFamily: 'Arial',
						fontSize: '12px',
						fontWeight: 'normal',
						textOverflow: 'ellipsis',
						overflow: 'hidden',
						whiteSpace: 'nowrap',
					}}
				/>
			)}
			<Chart backgroundColor={null} />

			<Tooltip formatter={formatter(unitForLabel)} shared />

			<XAxis categories={labels}>
				<XAxis.Title>{xAxis.title.text}</XAxis.Title>
			</XAxis>

			<YAxis>
				<YAxis.Title>{yAxis.title.text}</YAxis.Title>
				{series.map(({ name, data }, indexSeries) => {
					const thisGraphId = baseId + indexSeries;
					switch (infoChart.type) {
						case 'pie':
							return (
								<PieSeries
									key={thisGraphId}
									id={thisGraphId}
									name={name}
									minSize="100"
									height="100"
									innerSize="50%"
									data={data}
									color={colors[indexSeries]}
								/>
							);
						case 'area':
							return (
								<AreaSeries
									key={thisGraphId}
									id={thisGraphId}
									name={name}
									data={data}
									color={colors[indexSeries]}
								/>
							);
						case 'line':
							return (
								<LineSeries
									key={thisGraphId}
									id={thisGraphId}
									name={name}
									data={data}
									color={colors[indexSeries]}
								/>
							);
						case 'spline':
							return (
								<SplineSeries
									key={thisGraphId}
									id={thisGraphId}
									name={name}
									data={data}
									color={colors[indexSeries]}
								/>
							);
						case 'bar':
							return (
								<BarSeries
									key={thisGraphId}
									id={thisGraphId}
									name={name}
									data={data}
									color={colors[indexSeries]}
								/>
							);
						case 'column':
							return (
								<ColumnSeries
									key={thisGraphId}
									id={thisGraphId}
									name={name}
									data={data}
									color={colors[indexSeries]}
								/>
							);
						case 'areaspline':
							return (
								<AreaSplineSeries
									key={thisGraphId}
									id={thisGraphId}
									name={name}
									data={data}
									color={colors[indexSeries]}
								/>
							);
						default:
							return (
								<LineSeries
									key={thisGraphId}
									id={thisGraphId}
									name={name}
									data={data}
									color={colors[indexSeries]}
								/>
							);
					}
				}, this)}
			</YAxis>
			{/* <YAxis id="number"  type="linear">
							<YAxis.Title>{dataForGraph['targetunit']}</YAxis.Title>
							{ dataForGraph['dataoriginal'].length ?  <LineSeries id="dataoriginal" name="Original Curve" data={dataForGraph['dataoriginal']}  color={'rgba(0,0,255,.9)'}/> : ''}
							{ dataForGraph['datacustom'].length ?  <LineSeries id="datacustom" name="Custom Curve" data={dataForGraph['datacustom']}  color={'rgba(255,0,0,1)'}/> : ''}
						</YAxis> */}
		</HighchartsChart>
	);
};

export default compose(curryRight(withHighcharts)(Highcharts))(Charts);
