import React from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'antd';
import './CustomAlert.less';

// Custom Alert Component
const CustomAlert = props => {
	const { message, type } = props;
	return (
		<Alert
			style={{ marginTop: '24px', width: '100%', padding: '12px' }}
			message={message}
			type={type}
			showIcon
			className="custom_alert"
			{...props}
		/>
	);
};

CustomAlert.propTypes = {
	message: PropTypes.string.isRequired,
	type: PropTypes.string.isRequired,
};

export default CustomAlert;
