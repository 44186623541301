import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { isLive } from 'apis/urls';
import styles from './Sidebar.styl';
import SideNav from './SideNav/SideNav';
import { history } from '../../routes';

class Sidebar extends Component {
	onLogoClick() {
		history.push('/');
	}

	render() {
		return (
			<div className={styles.sidebar}>
				<div className={styles.brand} onClick={this.onLogoClick}>
					<h1 style={{ marginLeft: '30px' }}>
						<FormattedMessage id="Proceq.LayoutSidebarHeading" /> {!isLive && 'Dev'}
					</h1>
				</div>
				{!isLive && <div className={styles.dev_banner}>{'DEVELOPMENT'}</div>}
				<SideNav />
			</div>
		);
	}
}

export default Sidebar;
