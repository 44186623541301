import moment from 'moment';
import { getCookie, setCookie } from 'utils/sessionHelper';

export const quickRanges = [
	{
		length: 2,
		unit: 'week',
		range: () => [
			moment()
				.subtract(2, 'week')
				.startOf('day'),
			moment().endOf('day'),
		],
	},
	{
		length: 1,
		unit: 'month',
		range: () => [
			moment()
				.subtract(1, 'month')
				.startOf('day'),
			moment().endOf('day'),
		],
	},
	{
		length: 3,
		unit: 'month',
		range: () => [
			moment()
				.subtract(3, 'month')
				.startOf('day'),
			moment().endOf('day'),
		],
	},
	{
		length: 1,
		unit: 'year',
		range: () => [
			moment()
				.subtract(1, 'year')
				.startOf('day'),
			moment().endOf('day'),
		],
	},
];
const initialRange = {
	from: quickRanges[2].range()[0],
	to: quickRanges[2].range()[1],
};

export const getInitialRange = () => {
	const lastDateRange = getCookie('analyticsDateRange');
	if (lastDateRange) {
		const [from, to] = lastDateRange.split(',');
		initialRange.from = moment.unix(from);
		initialRange.to = moment.unix(to);
	}

	return initialRange;
};

export const saveDateRange = ({ from, to }) => {
	if (!from || !to) {
		return;
	}
	setCookie('analyticsDateRange', `${from.unix()},${to.unix()}`, 100000 * 60 * 60 * 1000);
};
