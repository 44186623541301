/* eslint-disable react/no-danger */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Form, Input, Select, DatePicker, Checkbox, Tooltip, Icon } from 'antd';
import { injectIntl } from 'react-intl';

import { isKeyDefined, getKeyValue } from 'utils/registryHelper';
import handleFormatMessage from 'utils/handleFormatMessage';
import { getDateLocaleFormat } from 'utils/dateHandler';
import { passwordPolicyToolTip } from 'utils/passwordValidator';
import './SmartFormItem.less'; /* overriding antd styles */
import styles from './SmartFormItem.styl';
import './SmartFormItem.css';

const Option = Select.Option;
const formItemLayout = {
	colon: false,
};

class SmartFormItem extends Component {
	static propTypes = {
		formItem: PropTypes.object.isRequired,
		input: PropTypes.object.isRequired,
		fieldName: PropTypes.string.isRequired,
		decorator: PropTypes.func,
		tooltip: PropTypes.shape({
			hasTooltip: PropTypes.bool,
			strength: PropTypes.number,
		}),
		allowedValues: PropTypes.object,
		intl: PropTypes.shape({
			formatMessage: PropTypes.func.isRequired,
		}),
		globalregister: PropTypes.object,
		asHTML: PropTypes.string,
		onChange: PropTypes.func,
		onSelectChange: PropTypes.func,
		onDatePickerChange: PropTypes.func,
	};

	static defaultProps = {
		tooltip: {
			hasTooltip: false,
			strength: 0,
		},
	};

	constructor(props) {
		super(props);
		this.handleChange = this.handleChange.bind(this);
		this.handleSelectChange = this.handleSelectChange.bind(this);
		this.handleDatePickerChange = this.handleDatePickerChange.bind(this);
	}

	render() {
		const { formItem, input, asHTML, decorator, allowedValues, tooltip } = this.props;
		const { intl } = this.props,
			{ formatMessage } = intl;

		let itemClassName = styles.form_item;
		let fieldDecorator = {};
		if (asHTML !== null) {
			fieldDecorator = decorator(<div className={styles.input} dangerouslySetInnerHTML={this.createMarkup()} />);
		} else {
			switch (input.type) {
				case 'input':
					input.type = 'text'; // eslint-disable-line no-fallthrough
				default:
					fieldDecorator = decorator(<Input className={styles.input} type="text" {...input} />);
					break;
				case 'checkbox':
					fieldDecorator = decorator(
						<Checkbox className={styles.input} {...input}>
							{formItem.label}
						</Checkbox>
					);
					break;
				case 'password':
				case 'passwordnoencode':
					fieldDecorator = decorator(
						tooltip.hasTooltip ? (
							<Input
								className={`${styles.input} ${styles.inputgroup}`}
								{...input}
								type="password"
								addonAfter={
									<Tooltip placement="right" title={passwordPolicyToolTip(tooltip.text)}>
										<Icon type="info-circle" />
									</Tooltip>
								}
							/>
						) : (
							<Input className={styles.input} {...input} type="password" />
						)
					);
					break;
				case 'date':
					fieldDecorator = decorator(
						<DatePicker
							format={getDateLocaleFormat()}
							className={styles.datepicker}
							{...input}
							onChange={this.handleDatePickerChange}
						/>
					);
					break;
				case 'select':
				case 'selectmulti':
					{
						itemClassName += ' form_item_select';
						const optionsMarkups = [];
						const selectMode = input.type === 'select' ? 'single' : 'multiple';

						let allowedValuesOptions = [];
						if (allowedValues !== null) {
							if (allowedValues.type === 'localDefined') {
								allowedValuesOptions = allowedValues.data;
							} else if (
								allowedValues.type === 'globalRegister' &&
								isKeyDefined(this.props.globalregister, allowedValues.data)
							) {
								allowedValuesOptions = getKeyValue(this.props.globalregister, allowedValues.data);
							}
							if ('addEmptyOptionWithLabel' in allowedValues) {
								optionsMarkups.push(
									<Option key="**EmptyOption**" value="">
										{handleFormatMessage(allowedValues.addEmptyOptionWithLabel, formatMessage)}
									</Option>
								);
							}
							for (
								let indexAllowedValue = 0;
								indexAllowedValue < allowedValuesOptions.length;
								indexAllowedValue++
							) {
								const thisEntryInfo = allowedValuesOptions[indexAllowedValue];
								/* let thisValue = thisEntryInfo.data;
								 if (thisValue === false) { thisValue = "0"; };
								 if (thisValue === true) { thisValue = "1"; }; */

								optionsMarkups.push(
									<Option key={thisEntryInfo.data} value={thisEntryInfo.data}>
										{handleFormatMessage(thisEntryInfo.label, formatMessage)}
									</Option>
								);
							}
						}
						fieldDecorator = decorator(
							<Select
								showSearch
								mode={selectMode}
								{...input}
								{...formItem}
								optionFilterProp="children"
								filterOption={(filteredInput, option) =>
									option.props.children.toLowerCase().indexOf(filteredInput.toLowerCase()) >= 0
								}
								onChange={newValue => this.handleSelectChange(newValue)}
							>
								{optionsMarkups}
							</Select>
						);
					}
					break;
			}
		}
		return (
			<Form.Item
				className={itemClassName}
				{...formItemLayout}
				{...formItem}
				label={input.type === 'checkbox' ? null : formItem.label}
				onChange={this.handleChange}
			>
				{fieldDecorator}
			</Form.Item>
		);
	}

	createMarkup() {
		return { __html: this.props.asHTML };
	}

	handleChange() {
		if (typeof this.props.onChange === 'function') {
			this.props.onChange();
		}
	}

	handleSelectChange(newValue) {
		if (typeof this.props.onSelectChange === 'function') {
			this.props.onSelectChange(this.props.fieldName, newValue);
		}
	}

	handleDatePickerChange(newValue) {
		if (typeof this.props.onDatePickerChange === 'function') {
			this.props.onDatePickerChange(this.props.fieldName, newValue);
		}
	}
}
const mapStateToProps = state => ({
	globalregister: state.globalregister,
});

export default connect(mapStateToProps)(injectIntl(SmartFormItem));
