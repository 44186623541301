// @flow
import React from 'react';
import { FormattedMessage } from 'react-intl';
import StaticMap from '../StaticMap';

export const Creation = ({ log }: GPRLogbookEntryProps) => {
	const { latitude, longitude, measurementInfo, locationAuthorization } = log.content;
	const coordinates = { lat: +latitude, lng: +longitude };
	const { fileName, folderName, mode } = measurementInfo;
	const newMode = mode === 'Conventional' ? 'A-scan' : mode === 'Corrosion Map' ? 'Grid' : mode;
	return (
		<div>
			<b>
				<FormattedMessage
					id="Created {fileName} in folder {folderName}"
					values={{
						fileName,
						folderName,
					}}
				/>
			</b>
			<br />
			<FormattedMessage id="Mode: {value}" values={{ value: newMode }} />
			<br />
			{locationAuthorization !== 'denied' && (
				<StaticMap width={400} height={200} zoom={16} coordinates={coordinates} />
			)}
		</div>
	);
};

export const color = () => 'green';

export default Creation;
