// @flow
import React from 'react';
import { Tag } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { timeToLocaleTime } from 'utils/dateHandler';
import { getResolutionForScale } from 'utils/conversionsHelper';
import { getLabelFromGlobalDataKey } from 'utils/registryHelper';
import { isUndefined } from 'lodash';
import { FormattedMessage } from 'react-intl';

export const renderTime = (text: string) => <span>{timeToLocaleTime(text)}</span>;
export const renderMean = (text: string) => <span>{getResolutionForScale(null, text || 0, 'avg', true)}</span>;
export const getBinProps = (bin: number) => {
	switch (bin) {
		case 1:
			return { color: 'volcano', text: '20°' };
		case 2:
			return { color: 'green', text: '60°' };
		default:
			return { color: 'blue', text: '' };
	}
};

const EQUOTIP = [
	{
		title: 'Proceq.TableNumMean',
		dataIndex: 'content.statistics.secondary.avg',
		render: renderMean,
		filter: 'measurement.$.statistics.secondary.avg',
		sorter: true,
		range: true,
	},
	{
		title: 'Proceq.TableMaterial',
		dataIndex: 'settings.0.content.materialId',
		filter: 'settings.$.materialId',
		render: (index, record) => {
			if (record.settings[0].content.customMaterial !== undefined) {
				return <FormattedMessage id="Proceq.MaterialEnumCustom" />;
			}
			return (
				<FormattedMessage
					id={getLabelFromGlobalDataKey('materials', String(record.settings[0].content.materialId || 2))}
				/>
			);
		},
	},
	{
		title: 'Proceq.TableScaleUnit',
		dataIndex: 'settings.0.content.secondary.scaleId',
		globalRegister: 'units',
		filter: 'settings.$.secondary.scaleId',
	},
	{
		title: 'Proceq.TableCreatedOn',
		dataIndex: 'clientCreated',
		sorter: true,
		render: renderTime,
	},
];
const SCHMIDT = [
	{
		title: 'Proceq.TableNumMean',
		dataIndex: 'content.statistics.secondary.avg',
		render: renderMean,
		filter: 'measurement.$.statistics.secondary.avg',
		sorter: true,
		range: true,
	},
	{
		title: 'Proceq.ExportFieldStandard',
		dataIndex: 'settings.0.content.standardId',
		globalRegister: 'standardsschmidt',
		filter: 'settings.$.standardId',
	},
	{
		title: 'Proceq.TableScaleUnit',
		dataIndex: 'settings.0.content.secondary.scaleId',
		globalRegister: 'unitsschmidt',
		filter: 'settings.$.secondary.scaleId',
	},
	{
		title: 'Proceq.TableCreatedOn',
		dataIndex: 'clientCreated',
		sorter: true,
		render: renderTime,
	},
];
const GPR = [
	{ title: 'App.MeasurementType', dataIndex: 'type', globalRegister: 'gpr_scan_type', filter: 'type' },
	{ title: 'Proceq.TableCreatedOn', dataIndex: 'clientCreated', sorter: true, render: renderTime },
	{ title: 'Proceq.TableModifiedOn', dataIndex: 'clientUpdated', sorter: true, render: renderTime },
];
const PUNDIT = [
	{ title: 'App.MeasurementType', dataIndex: 'type', globalRegister: 'pundit_scan_type', filter: 'type' },
	{ title: 'Proceq.TableCreatedOn', dataIndex: 'clientCreated', sorter: true, render: renderTime },
	{ title: 'Proceq.TableModifiedOn', dataIndex: 'clientUpdated', sorter: true, render: renderTime },
];

const GLM = [
	{
		title: 'Geometries',
		dataIndex: 'settings.0.content.angleBinList',
		render: (binList, record) => {
			if (isUndefined(binList)) {
				return null;
			}
			return (
				<span>
					{binList
						.filter(bin => bin !== 3)
						.map(bin => {
							const { color, text } = getBinProps(bin);
							return (
								<Tag color={color} key={record.id + bin}>
									{text}
								</Tag>
							);
						})}
				</span>
			);
		},
	},
	{
		title: 'Proceq.TableScaleUnit',
		dataIndex: 'settings.0.content.unit',
		globalRegister: 'unitsGLM',
		filter: 'settings.$.content.unit',
	},
	{
		title: 'App.Logbook.FDL.Item.measuringMode',
		dataIndex: 'settings.0.content.measureMode',
		globalRegister: 'modesGLM',
		render: (measureMode, record) => {
			let mode = getLabelFromGlobalDataKey('modesGLM', measureMode);
			mode = measureMode === 3 ? `${mode} ${record.settings[0].content.interval / 1000}s` : mode;
			return mode;
		},
		filter: 'settings.$.measureMode',
	},
	{ title: 'Proceq.TableCreatedOn', dataIndex: 'clientCreated', sorter: true, render: renderTime },
	{ title: 'Proceq.TableModifiedOn', dataIndex: 'clientUpdated', sorter: true, render: renderTime },
];

export const defaultColumnConfig = [
	{ title: 'Proceq.TableCreatedOn', dataIndex: 'clientCreated', sorter: true, render: renderTime },
	{ title: 'Proceq.TableModifiedOn', dataIndex: 'clientUpdated', sorter: true, render: renderTime },
];

const columnConfig: {
	[x: ProductCode]: ColumnProps,
} = {
	EQUOTIP,
	SCHMIDT,
	GPR,
	GPR_SOIL: GPR,
	PUNDIT,
	GLM,
};

export default columnConfig;
