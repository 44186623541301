import 'clientjs';
import { httpPost } from 'apis/httpClient';
import URL from 'apis/urls';
import { checkStatusJson } from 'apis/restUtils';
import { getProceqUrl, getApiUrlFromSharedToken } from 'utils/sessionHelper';

export function loginAsyncProceq(userData) {
	return new Promise((resolve, reject) => {
		const url = getProceqUrl(null, URL.PROCEQ_AUTH);

		const email = userData.email;
		const password = userData.password;

		const jsonBody = {
			username: email,
			grant_type: 'password',
			client_id: email,
			password,
			only_onetime: userData.only_onetime,
			product_login: userData.product_login,
		};

		httpPost(url, jsonBody)
			.then(checkStatusJson)
			.then(json => {
				// when userData.only_onetime is set to 0, onetime_token is only returned when necessary.
				// we also do an additional layer of check to see if a redirection is necessary.
				if ('onetime_token' in json) {
					if (
						json.web_url !== window.location.hostname &&
						!json.access_token &&
						json.web_url.indexOf('.cn') > -1
					) {
						window.location.href = `${json.web_url}logintoken?ot=${json.onetime_token}`;
					} else {
						const jsonBodyOneTimeRequest = {
							ot: json.onetime_token,
							product_login: userData.product_login,
						};
						const urlOneTime = getProceqUrl(null, URL.PROCEQ_AUTH, json.api_url);
						httpPost(urlOneTime, jsonBodyOneTimeRequest)
							.then(checkStatusJson)
							.then(data => {
								resolve(data);
							})
							.catch(err => {
								reject(err);
							});
					}
				} else {
					resolve(json);
				}
			})
			.catch(err => {
				reject(err);
			});
	});
}

export function loginAsyncOneTimeProceq(userData) {
	return new Promise((resolve, reject) => {
		const otToken = userData.ot;
		const url = getProceqUrl(null, URL.PROCEQ_AUTH, getApiUrlFromSharedToken(otToken));
		const jsonBody = {
			ot: otToken,
		};

		httpPost(url, jsonBody)
			.then(checkStatusJson)
			.then(json => {
				resolve(json);
			})
			.catch(err => {
				reject(err);
			});
	});
}

const loginAsyncs = {
	loginAsyncProceq,
	loginAsyncOneTimeProceq,
};

export default loginAsyncs;
