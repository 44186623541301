// @flow
import React, { Fragment } from 'react';
import { get } from 'lodash';
import { Table } from 'antd';
import { FormattedMessage } from 'react-intl';
import ConvertedUnits from 'components/shared/ConvertedUnits';
import type { DerivedProps } from '../../../Measurement';
import styles from '../../../HTMLExport.styl';

export const title = (
	<Fragment>
		<FormattedMessage id="Proceq.ExportFieldSettings" />
		{' ­­» '}
		<FormattedMessage id="App.HTML.PI8000.Settings.FilteringPresets" />
	</Fragment>
);

export const MeasurementPresets = ({
	data,
	scanType,
	isMetric,
	convert,
}: { data: MeasurementFullData } & DerivedProps) => {
	const isHighPassFilterOn = get(data, 'settings.0.content.processing.isHighPassFilterOn');
	const isLowPassFilterOn = get(data, 'settings.0.content.processing.isLowPassFilterOn');
	const isPivotFilterOn = get(data, 'settings.0.content.processing.isPivotFilterOn');

	const presets = [
		{
			label: 'App.HTML.PI8000.Settings.TimeGain',
			unitId: 'PIT_IE.Settings Panel.Time Gain',
			value: get(data, 'settings.0.content.processing.timeGainSlope'),
			filterOn: true,
			key: 'timeGain',
		},
		{
			label: 'App.HTML.PI8000.Settings.HighPassFilter',
			unitId: 'PIT_IE.Settings Panel.High Pass Filter',
			value: get(data, 'settings.0.content.processing.highPassFilter'),
			filterOn: isHighPassFilterOn,
			key: 'highPassFilter',
		},
		{
			label: 'App.HTML.PI8000.Settings.LowPassFilter',
			unitId: 'PIT_IE.Settings Panel.Low Pass Filter',
			value: get(data, 'settings.0.content.processing.lowPassFilter'),
			filterOn: isLowPassFilterOn,
			key: 'lowPassFilter',
		},
		{
			label: 'App.HTML.PI8000.Settings.Pivot',
			unitId: 'PIT_IE.Settings Panel.Pivot Filter',
			value: get(data, 'settings.0.content.processing.pivot'),
			filterOn: isPivotFilterOn,
			key: 'pivot',
		},
	]
		.filter(row => row.enable === undefined || row.enable === true)
		.map(row => ({
			label: row.unitId ? (
				<ConvertedUnits id={row.label} unitId={row.unitId} scanType={scanType} isMetric={isMetric} />
			) : (
				<FormattedMessage id={row.label} />
			),
			value: row.filterOn ? convert(row.value, row.unitId) : 'Off',
			key: row.key,
		}));

	return (
		<Table
			title={() => <span className={styles.sub_header}>{title}</span>}
			className={styles.table}
			dataSource={presets}
			pagination={false}
			showHeader={false}
			size="small"
		>
			<Table.Column key="label" dataIndex="label" className={styles.column} />
			<Table.Column key="value" dataIndex="value" />
		</Table>
	);
};

export default MeasurementPresets;
