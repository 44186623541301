// @flow
import React from 'react';
import type { InjectIntlProvidedProps } from 'react-intl';
import { injectIntl } from 'react-intl';
import handleFormatMessage from 'utils/handleFormatMessage';
import { getLabelFromGlobalDataKey } from 'utils/registryHelper';

export const SurfaceCondition = ({ intl, log }: InjectIntlProvidedProps & EquotipLogbookEntryProps) => (
	<span>
		{log.content.oldSurfaceConditionId
			? handleFormatMessage('Proceq.LogbookPropertySurfaceConditionChangedFromTo', intl.formatMessage, [
					handleFormatMessage(
						getLabelFromGlobalDataKey('surfaceconditionschmidt', log.content.oldSurfaceConditionId),
						intl.formatMessage
					),
					handleFormatMessage(
						getLabelFromGlobalDataKey('surfaceconditionschmidt', log.content.updatedSurfaceConditionId),
						intl.formatMessage
					),
			  ])
			: handleFormatMessage('Proceq.LogbookPropertySurfaceConditionChangedTo', intl.formatMessage, [
					handleFormatMessage(
						getLabelFromGlobalDataKey('surfaceconditionschmidt', log.content.updatedSurfaceConditionId),
						intl.formatMessage
					),
			  ])}
	</span>
);

export default injectIntl(SurfaceCondition);
