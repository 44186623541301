// @flow
import URL from 'apis/urls';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { request } from 'actions/ajax';
import type { NotificationScheduleResponse, PostNotificationParams } from './notificationTypes';

export type NotificationServices = {
	getNotificationSchedule: (newParams?: {
		size?: number,
		page?: number,
		sort?: number,
	}) => Promise<NotificationScheduleResponse>,
	deleteNotification: (id: string) => Promise<any>,
	createNotification: (params: PostNotificationParams) => Promise<any>,
};

const useNotificationServices = () => {
	const dispatch = useDispatch();

	const getNotificationSchedule: $PropertyType<NotificationServices, 'getNotificationSchedule'> = useCallback(
		newParams => {
			const params = {
				size: 100,
				page: 1,
				sort: -1,
				...newParams,
			};

			return dispatch(
				request({
					method: 'GET',
					url: URL.NOTIFICATION_SCHEDULED,
					params,
					errorHandler: () => {},
					injectProduct: false,
				})
			);
		},
		[dispatch]
	);

	const deleteNotification: $PropertyType<NotificationServices, 'deleteNotification'> = useCallback(
		id => {
			const url = URL.NOTIFICATION_SCHEDULED_ITEM.replace('{id}', id);
			return dispatch(
				request({
					method: 'DELETE',
					url,
					returnResponseHeaders: true,
					injectProduct: false,
				})
			);
		},
		[dispatch]
	);

	const createNotification: $PropertyType<NotificationServices, 'createNotification'> = useCallback(
		params => {
			const url = URL.NOTIFICATION_SCHEDULED;
			return dispatch(
				request({
					method: 'POST',
					url,
					params,
					injectProduct: false,
				})
			);
		},
		[dispatch]
	);

	return { getNotificationSchedule, deleteNotification, createNotification };
};
export default useNotificationServices;
