import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Icon } from 'antd';
import { Link } from 'react-router-dom';
import styles from './NavItem.styl';

class NavItem extends Component {
	static propTypes = {
		label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
		route: PropTypes.shape({
			pathname: PropTypes.string.isRequired,
			query: PropTypes.object,
		}),
		parentSelected: PropTypes.bool,
		onClicked: PropTypes.func,
		expanded: PropTypes.bool,
		selected: PropTypes.bool,
		iconSrc: PropTypes.element,
		parentId: PropTypes.string,
		hasChildren: PropTypes.bool,
	};

	constructor(props) {
		super(props);
		this.onClicked = this.onClicked.bind(this);
	}

	onClicked = () => {
		if (typeof this.props.onClicked === 'function' && this.props.selected) {
			this.props.onClicked(this.props);
		}
	};

	render() {
		const { parentId } = this.props;

		if (parentId) return this.renderChildItem();

		return this.renderParentItem();
	}

	renderParentItem() {
		const { route, label, selected, miniMode, hasChildren, expanded, iconSrc } = this.props;
		const container = selected ? styles.container_selected : styles.container;

		return (
			<Link style={{ all: 'unset' }} to={route}>
				<div className={container} onClick={this.onClicked}>
					<div
						style={{ marginLeft: miniMode ? '0px' : '22px' }}
						className={selected ? styles.icon_selected : styles.icon_root}
					>
						{iconSrc}
					</div>
					<div style={{ display: miniMode ? 'none' : '' }} className={styles.text}>
						{label}
					</div>
					{hasChildren && expanded !== undefined && !miniMode && (
						<Icon type={this.getCorrectArrow(expanded)} className={styles.arrow_up_down_icon} />
					)}
				</div>
			</Link>
		);
	}

	renderChildItem() {
		const { route, label, miniMode, parentSelected } = this.props;
		const container = parentSelected ? styles.child_container_selected : styles.child_container;

		return (
			<div className={container}>
				<div
					style={{
						visibility: 'hidden',
						marginLeft: miniMode ? '0px' : '20px',
					}}
					className={styles.icon_root}
				/>
				<div style={{ display: miniMode ? 'none' : '' }} className={styles.child_text}>
					<Link style={{ all: 'unset' }} to={route}>
						<span style={{ cursor: 'pointer' }}>{label}</span>
					</Link>
				</div>
			</div>
		);
	}

	getCorrectArrow(expanded) {
		return expanded ? 'up' : 'down';
	}
}

export default NavItem;
/* eslint-enable key-spacing */
