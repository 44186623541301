// @flow
import React, { Fragment } from 'react';
import { compose, withProps, withStateHandlers } from 'recompose';
import { Card, Button } from 'antd';
import type { InjectIntlProvidedProps } from 'react-intl';
import { FormattedMessage, injectIntl } from 'react-intl';
import { GPRLogbook } from '../../../GPRLogbook';
import type { DerivedProps } from '../../Measurement';
import styles from '../../HTMLExport.styl';

export const Logbook = ({
	data,
	users,
	highlighted,
	allEntries,
	toggleText,
	toggleHighlight,
	toggleAll,
	intl,
	productModel,
	scanType,
	product,
	isMetric,
	version,
}: {
	data: MeasurementFullData,
	users: User[],
	allEntries: string[],
	highlighted: string[],
	toggleText: string,
	toggleHighlight: Function,
	toggleAll: Function,
	version: string,
} & InjectIntlProvidedProps &
	DerivedProps) => {
	return (
		<Card
			className="logbook_module"
			title={
				<Fragment>
					<span className={styles.main_header}>
						<FormattedMessage id="Proceq.LogbookTitle" />
					</span>
					{allEntries.length > 0 && (
						<Button
							onClick={toggleAll}
							className="logbook_toggle"
							data-alt-text={intl.formatMessage({ id: 'App.HTML.Logbook.CollapseEntries' })}
						>
							<FormattedMessage id={toggleText} />
						</Button>
					)}
				</Fragment>
			}
		>
			<GPRLogbook
				highlighted={process.env.HTML_EXPORT_VERSION ? allEntries : highlighted}
				toggleHighlight={toggleHighlight}
				logs={data.logs}
				users={users}
				productModel={productModel}
				product={product}
				scanType={scanType}
				isMetric={isMetric}
				version={version}
			/>
		</Card>
	);
};

export default compose(
	injectIntl,
	withProps(props => ({
		allEntries: props.data.logs.filter(log => log.type === 'objectMarker').map(log => log.id),
	})),
	withStateHandlers(
		{
			highlighted: [],
			toggleText: 'App.HTML.Logbook.ExpandEntries',
		},
		{
			toggleHighlight: ({ highlighted }, { allEntries }) => id => {
				const newHighlighted = highlighted.includes(id)
					? highlighted.filter(existingId => existingId !== id)
					: [...highlighted, id];
				return {
					highlighted: newHighlighted,
					toggleText:
						newHighlighted.length < allEntries.length
							? 'App.HTML.Logbook.ExpandEntries'
							: 'App.HTML.Logbook.CollapseEntries',
				};
			},
			toggleAll: ({ highlighted }, { allEntries }) => () => {
				if (highlighted.length < allEntries.length) {
					return {
						highlighted: allEntries,
						toggleText: 'App.HTML.Logbook.CollapseEntries',
					};
				}

				return {
					highlighted: [],
					toggleText: 'App.HTML.Logbook.ExpandEntries',
				};
			},
		}
	)
)(Logbook);
