import React from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import Image from '../img/404.png';
import styles from './errorStyles.styl';

const PageNotFound = () => (
	<div className={styles.container}>
		<img src={Image} className={styles.logo} alt="logo" />
		<h1 className={styles.text}>
			<FormattedMessage id="Proceq.ErrorPageNotFoundHeading" />
		</h1>
		<p className={styles.text}>
			<FormattedMessage id="Proceq.ErrorPageNotFoundParagraph" />
		</p>
		<Link className={styles.text} to="/">
			<FormattedMessage id="Proceq.ErrorPageNotFoundLink" />
		</Link>
	</div>
);

export default PageNotFound;
