// @flow
/* eslint-disable react/no-this-in-sfc */
import React from 'react';
import { get } from 'lodash';
import { Button, Input } from 'antd';
import { FormattedMessage, injectIntl } from 'react-intl';
import { compose } from 'recompose';
import SmarterTable, { computeOffset } from '../shared/SmarterTable';
import styles from '../shared/SmarterTable.styl';

export class MeasurementBaseTable extends SmarterTable<Measurement> {
	generateFilterProps = (filterProp: FilterProp): Object => {
		if (filterProp.range && filterProp.filter) {
			return {
				...super.generateFilterProps(filterProp),
				filterDropdown: this.renderRangeFilter(filterProp.filter),
			};
		}

		return super.generateFilterProps(filterProp);
	};

	filterRange = (columnFilter: string, index: number) => (e: any) => {
		const existingFilter = this.state.filters[columnFilter] || ['', ''];
		existingFilter[index] = e.target.value;
		this.setState({
			filters: {
				...this.state.filters,
				[columnFilter]: existingFilter,
			},
		});
	};

	resetRange = (columnFilter: string) => () => {
		this.setState(
			{
				filters: {
					...this.state.filters,
					[columnFilter]: '', // this conflicts with the expected type, but will remove the active filter icon in antd
				},
				filterDropdownVisible: null,
			},
			this.loadDataSource
		);
	};

	renderRangeFilter = (filter: string) => (
		<div className={styles.custom_filter_dropdown} data-e2e-table-filter-dropdown>
			<Input
				className={styles.number}
				type="number"
				value={get(this.state.filters, [filter, 0], '')}
				onChange={this.filterRange(filter, 0)}
				onPressEnter={this.search}
				// $FlowFixMe
				placeholder={this.props.intl.formatMessage({ id: 'Proceq.ExportFieldMinimum' })}
			/>
			{' - '}
			<Input
				className={styles.number}
				type="number"
				value={get(this.state.filters, [filter, 1], '')}
				onChange={this.filterRange(filter, 1)}
				onPressEnter={this.search}
				// $FlowFixMe
				placeholder={this.props.intl.formatMessage({ id: 'Proceq.ExportFieldMaximum' })}
			/>
			<Button type="primary" onClick={this.search}>
				<FormattedMessage id="Proceq.ButtonSearch" />
			</Button>
			<Button type="primary" style={{ marginLeft: '5px' }} onClick={this.resetRange(filter)}>
				<FormattedMessage id="Proceq.ButtonReset" />
			</Button>
		</div>
	);

	loadDataSource = (): void => {
		const rules = {
			measurement: [],
			settings: [],
		};

		const getDetailsFromColumnJsonPath = column => {
			const index = column.indexOf('.');
			let field = column
				.slice(index + 1)
				.replace('content', '$')
				.replace('0.', ''); // JSON filtering doesnt work with arrays
			let parent = column.slice(0, index);

			if (parent === 'content') {
				parent = 'measurement';
				field = `$.${field}`;
			}

			return { parent, field };
		};

		Object.entries(this.state.filters).forEach(([column, value]) => {
			const { parent, field } = getDetailsFromColumnJsonPath(column);

			const pushFieldIfNotEmpty = (fieldValue, op = '=') => {
				if (!fieldValue) {
					return;
				}

				rules[parent].push({
					field,
					op,
					value: fieldValue,
				});
			};

			if (['measurement', 'settings'].includes(parent)) {
				if (Array.isArray(value)) {
					if (value.length === 2) {
						// range
						pushFieldIfNotEmpty(value[0], '>=');
						pushFieldIfNotEmpty(value[1], '<=');
					} else if (value.length === 1) {
						// globalRegister
						pushFieldIfNotEmpty(value[0]);
					}
				} else {
					pushFieldIfNotEmpty(value);
				}
			}
		});

		let orderBy;
		const orderDir = this.state.orderDir.slice(0, -3);

		const filter = {};
		if (rules.measurement.length > 0) {
			filter.measurement = {
				operator: 'AND',
				orderBy: rules.measurement[0].field,
				orderDir,
				rules: rules.measurement,
			};
		}
		if (rules.settings.length > 0) {
			filter.settings = {
				operator: 'AND',
				orderBy: rules.settings[0].field,
				orderDir,
				rules: rules.settings,
			};
		}

		if (this.state.order && this.state.orderDir) {
			if (this.state.order.includes('.')) {
				orderBy = undefined;
				// ordering without filtering
				const { parent, field } = getDetailsFromColumnJsonPath(this.state.order);
				if (filter[parent]) {
					filter[parent].orderBy = field;
				} else {
					filter[parent] = {
						operator: 'AND',
						orderBy: field,
						orderDir,
					};
				}
			} else {
				orderBy = this.state.order;
			}
		}

		const filterByTopLevelSearch = key => {
			const search = this.state.filters[key];

			if (Array.isArray(search)) {
				if (search.length > 0) {
					return search[0];
				}
			} else if (search && search.trim().length > 0) {
				return search.trim();
			}

			return '';
		};

		this.props.loadDataSource({
			offset: computeOffset(this.state.page, this.state.pageSize),
			limit: this.state.pageSize,
			filter,
			// $FlowFixMe
			fileType: filterByTopLevelSearch('type') || this.props.fileType,
			search: filterByTopLevelSearch('name'),
			orderBy,
			orderDir,
		});
	};
}

export default compose(injectIntl)(MeasurementBaseTable);
