// @flow
import React, { Fragment } from 'react';
import { get } from 'lodash';
import { Table } from 'antd';
import { FormattedMessage } from 'react-intl';
import ConvertedUnits from 'components/shared/ConvertedUnits';
import type { DerivedProps } from '../../../Measurement';
import styles from '../../../HTMLExport.styl';

export const title = (
	<Fragment>
		<FormattedMessage id="Proceq.ExportFieldSettings" />
		{' ­­» '}
		<FormattedMessage id="App.HTML.GPR.Settings.ImageProcessing" />
	</Fragment>
);

export const ImageProcessing = ({
	data,
	scanType,
	product,
	isMetric,
	convert,
	showTitle,
}: { data: MeasurementFullData } & DerivedProps) => {
	const matrix = [
		{
			title: 'App.LogbookSettingsDielectricConstant',
			unitId: 'GPR.CSV.Dielectric Constant',
			migratedView: get(data, 'settings.0.content.settings.process.dielectricConstant'),
			nonMigratedView: <span>&nbsp;</span>,
			timeSliceView: <span>&nbsp;</span>,
			dontConvert: true,
		},
		{
			title: ' ',
			migratedView: (
				<span className={styles.table_header}>
					<FormattedMessage id="App.HTML.GPR.Settings.ImageProcessing.MigratedView" />
				</span>
			),
			nonMigratedView: (
				<span className={styles.table_header}>
					<FormattedMessage id="App.HTML.GPR.Settings.ImageProcessing.NonMigratedView" />
				</span>
			),
			timeSliceView: (
				<span className={styles.table_header}>
					<FormattedMessage id="App.HTML.GPR.Settings.ImageProcessing.TSV/3D" />
				</span>
			),
		},
		{
			title: 'App.HTML.GPR.Settings.ImageProcessing.AutoGain',
			migratedView: get(data, 'settings.0.content.settings.migratedLineViewSettings.isAutoGainOn') ? 'On' : 'Off',
			nonMigratedView: get(data, 'settings.0.content.settings.nonMigratedLineViewSettings.isAutoGainOn')
				? 'On'
				: 'Off',
			timeSliceView: get(data, 'settings.0.content.settings.timeSliceViewSettings.isAutoGainOn') ? 'On' : 'Off',
		},
		{
			title: 'App.HTML.GPR.Settings.ImageProcessing.LinearGain',
			unitId: `${product}.CSV.Linear Gain`,
			migratedView: get(data, 'settings.0.content.settings.migratedLineViewSettings.gain'),
			nonMigratedView: get(data, 'settings.0.content.settings.nonMigratedLineViewSettings.gain'),
			timeSliceView: get(data, 'settings.0.content.settings.timeSliceViewSettings.gain'),
		},
		{
			title: 'App.HTML.GPR.Settings.ImageProcessing.TimeGain',
			unitId: `${product}.CSV.Time Gain Compensation`,
			migratedView: get(data, 'settings.0.content.settings.migratedLineViewSettings.timeGainCompensation'),
			nonMigratedView: get(data, 'settings.0.content.settings.nonMigratedLineViewSettings.timeGainCompensation'),
			timeSliceView: get(data, 'settings.0.content.settings.timeSliceViewSettings.timeGainCompensation'),
		},
	].map(row => {
		const rows = {
			title: row.unitId ? (
				<ConvertedUnits id={row.title} unitId={row.unitId} scanType={scanType} isMetric={isMetric} />
			) : (
				<FormattedMessage id={row.title} />
			),
			migratedView: row.unitId ? convert(row.migratedView, row.unitId) : row.migratedView,
			nonMigratedView:
				!row.dontConvert && row.unitId ? convert(row.nonMigratedView, row.unitId) : row.nonMigratedView,
			timeSliceView: undefined,
		};

		if (scanType === 'Area Scan') {
			rows.timeSliceView =
				!row.dontConvert && row.unitId ? convert(row.timeSliceView, row.unitId) : row.timeSliceView;
		}

		return rows;
	});

	return (
		<Table
			title={showTitle ? () => <span className={styles.sub_header}>{title}</span> : null}
			className={styles.table}
			components={{
				header: {
					row: () => null, // hide header row
				},
			}}
			columns={[
				{
					dataIndex: 'title',
				},
				{
					dataIndex: 'migratedView',
				},
				{
					dataIndex: 'nonMigratedView',
				},
				{
					dataIndex: 'timeSliceView',
				},
			]}
			dataSource={matrix}
			pagination={false}
			size="small"
		/>
	);
};

export default ImageProcessing;
