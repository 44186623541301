// @flow
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { convertAndDisplay } from 'constants/conversionRates';

export const ChangeFileUnit = ({ log, product, scanType, isMetric }: GPRLogbookEntryProps) => (
	<FormattedMessage
		id="App.ChangedFromTo"
		values={{
			changed: `${log.content.settingName} (${
				convertAndDisplay(`${product}.Settings Panel.${log.content.settingName}`, scanType, isMetric).unit
			})`,
			from: log.content.old,
			to: log.content.new,
		}}
	/>
);

export default ChangeFileUnit;
