// @flow
import React from 'react';
import { Table } from 'antd';
import { get } from 'lodash';
import { FormattedMessage } from 'react-intl';
import ConvertedUnits from 'components/shared/ConvertedUnits';
import type { DerivedProps } from 'components/measurements/HTMLExport/Measurement';
import styles from 'components/measurements/HTMLExport/HTMLExport.styl';
import { MeasurementScanType, LineStatusDirection } from 'constants/exportConstants';
import { ProductConstants } from 'components/contracts/contractConsts';
import { filterReadings } from './ScanDistance';

export type MeasurementObjectWithInfo = MeasurementObject & {
	readingContent: $PropertyType<MeasurementReading, 'content'>,
	logContent: $PropertyType<Log, 'content'>,
	sequenceNo: number,
	originCoordinateX?: number | string,
	originCoordinateY?: number | string,
	localX?: number | string,
	localY?: number | string,
};

export const title = <FormattedMessage id="Objects" />;

export const getLocationCoordinates = (object: MeasurementObject) => {
	const extraKeys: { [key: string]: any } = {};
	extraKeys.convertedCoordinateX = get(object.content, 'convertedCoordinate.x', '-');
	extraKeys.convertedCoordinateY = get(object.content, 'convertedCoordinate.y', '-');
	return extraKeys;
};

export const sortObjectData = (a: MeasurementObjectWithInfo, b: MeasurementObjectWithInfo) => {
	if (a.readingContent !== undefined && b.readingContent !== undefined) {
		const aLine = a.readingContent.sequenceNo;
		const bLine = b.readingContent.sequenceNo;

		// first sort by line number
		if (aLine !== bLine) {
			return aLine - bLine;
		}
	}
	// then tag number
	return a.content.number - b.content.number;
};

export const getLocalXY = (data: MeasurementFullData, object: MeasurementObject) => {
	const extraKeys: { [key: string]: any } = {};
	const lineStatus: LineStatus = data.measurement.content.areaScanStatus.lineStatus.find(
		(r: LineStatus) => r.scanId === object.rID
	);
	if (lineStatus) {
		const distanceMeter = object.content.location.distanceMeter;
		const gridSpacingY =
			data.settings[0].content.settings.preset.scanModeParameters.areaScanParameters.gridSpacingY;
		const gridSpacingX =
			data.settings[0].content.settings.preset.scanModeParameters.areaScanParameters.gridSpacingX;
		switch (lineStatus.direction) {
			case LineStatusDirection.right:
				extraKeys.localX = distanceMeter;
				extraKeys.localY = (lineStatus.position.sequence - 1) * gridSpacingY;
				break;
			case LineStatusDirection.up:
				extraKeys.localY = distanceMeter;
				extraKeys.localX = (lineStatus.position.sequence - 1) * gridSpacingX;
				break;
			case LineStatusDirection.left:
				extraKeys.localX =
					gridSpacingX *
						(data.settings[0].content.settings.preset.scanModeParameters.areaScanParameters
							.numberOfHorizontalLines -
							1) -
					distanceMeter;
				extraKeys.localY = (lineStatus.position.sequence - 1) * gridSpacingY;
				break;
			case LineStatusDirection.down:
				extraKeys.localY =
					gridSpacingY *
						(data.settings[0].content.settings.preset.scanModeParameters.areaScanParameters
							.numberOfVerticalLines -
							1) -
					distanceMeter;
				extraKeys.localX = (lineStatus.position.sequence - 1) * gridSpacingX;
				break;
		}
	} else {
		extraKeys.localX = '-';
		extraKeys.localY = '-';
	}
	return extraKeys;
};

export const Objects = ({
	data,
	scanType,
	product,
	isMetric,
	convert,
	showTitle,
}: { data: MeasurementFullData } & DerivedProps) => {
	// we only want to use the most recent log entry
	const sortedLogs = data.logs.sort((a, b) => b.clientCreated - a.clientCreated);
	const calibratedDepthLog = sortedLogs.find(
		l =>
			l.content.calibratedDepthString ||
			(l.content.changes !== undefined && l.content.changes.calibratedDepthString) ||
			l.content.locationY
	);
	const isSoil = product.toUpperCase() === ProductConstants.GPR_SOIL;
	const calibratedObjectID = get(data.measurement.content, 'tagMetaData.calibratedObjectId');
	const calibratedDepthObject = calibratedDepthLog
		? data.objects
				.filter(object => object.type === 'object')
				.find(o => {
					if (isSoil) {
						return o.id === calibratedObjectID;
					}
					const log = sortedLogs.find(l => +l.content.sequence === o.content.number);
					return log && log.id === calibratedDepthLog.id;
				})
		: null;
	const setDepth = calibratedDepthLog
		? calibratedDepthLog.content.calibratedDepthString
			? +calibratedDepthLog.content.calibratedDepthString
			: calibratedDepthLog.content.changes?.calibratedDepthString
			? +calibratedDepthLog.content.changes.calibratedDepthString
			: calibratedDepthLog.content.locationY
			? +calibratedDepthLog.content.locationY
			: null
		: null;
	const filteredReadings = filterReadings(data, scanType);

	const objects: MeasurementObjectWithInfo[] = data.objects
		.filter(object => object.type === 'object')
		.map(object => {
			const extraKeys = {};

			const reading = filteredReadings.find(r => r.id === object.rID);
			if (reading) {
				extraKeys.readingContent = reading.content;
				extraKeys.readingContent.sequenceNo = reading.sequenceNo;
			}
			const log = sortedLogs.find(l => +l.content.sequence === object.content.number);
			if (log) {
				extraKeys.logContent = log.content;
			}

			extraKeys.calculatedDepth = object.content.location.depthMeter ?? '-';
			if (isSoil) {
				if (object.id === calibratedObjectID) {
					extraKeys.setDepth = setDepth;
				}
				extraKeys.setSize = object.content.sizeMeter ?? '-';
			} else if (
				setDepth !== null &&
				setDepth !== undefined &&
				log !== null &&
				log !== undefined &&
				calibratedDepthLog !== null &&
				calibratedDepthLog !== undefined &&
				calibratedDepthObject !== null &&
				calibratedDepthObject !== undefined
			) {
				if (log.id === calibratedDepthLog.id) {
					// calibrated depth
					extraKeys.setDepth = setDepth;
				}
			}

			if (!log || !calibratedDepthLog || !calibratedDepthObject || !setDepth) {
				return {
					...(isSoil && scanType === MeasurementScanType.AreaScan ? getLocalXY(data, object) : {}),
					...(isSoil ? getLocationCoordinates(object) : {}),
					...extraKeys,
					...object,
				};
			}

			return {
				...(isSoil && scanType === MeasurementScanType.AreaScan ? getLocalXY(data, object) : {}),
				...(isSoil ? getLocationCoordinates(object) : {}),
				...extraKeys,
				...object,
			};
		})
		.sort(sortObjectData);

	const getDistanceLocationColumns = () => {
		const distanceColumns = [];
		if (isSoil) {
			distanceColumns.push(
				{
					title: 'Easting',
					unitId: `${product}.CSV.LocationCoordinates`,
					dataIndex: ['convertedCoordinateX'],
					width: 80,
				},
				{
					title: 'Northing',
					unitId: `${product}.CSV.LocationCoordinates`,
					dataIndex: ['convertedCoordinateY'],
					width: 80,
				}
			);
			if (scanType === MeasurementScanType.AreaScan) {
				distanceColumns.push(
					{
						title: 'Local X [{unit}]',
						unitId: `${product}.CSV.Tag objects Local XY`,
						dataIndex: ['localX'],
						width: 120,
					},
					{
						title: 'Local Y [{unit}]',
						unitId: `${product}.CSV.Tag objects Local XY`,
						dataIndex: ['localY'],
						width: 120,
					}
				);
			} else {
				distanceColumns.push({
					title: 'Distance along line [{unit}]',
					unitId: `${product}.CSV.Tag objects distance X`,
					dataIndex: ['content', 'location', 'distanceMeter'],
					width: 120,
				});
			}
		} else {
			distanceColumns.push(
				{
					title: 'Distance X [{unit}]',
					unitId: `${product}.CSV.Tag objects distance X`,
					dataIndex: ['logContent', 'locationX'],
					width: 120,
				},
				{
					title: 'Distance Y [{unit}]',
					unitId: `${product}.CSV.Tag Object Distance Y`,
					dataIndex: ['logContent', 'locationY'],
					width: 120,
				}
			);
		}
		return distanceColumns;
	};

	return (
		<div className="table-objects">
			<Table
				title={showTitle ? () => <span className={styles.main_header}>{title}</span> : null}
				className={styles.table}
				columns={[
					{
						title: 'Line',
						render: (text, record: MeasurementObjectWithInfo) => {
							return record.readingContent !== undefined ? record.readingContent.sequenceNo : '';
						},
						width: 40,
					},
					{
						title: 'Tag',
						dataIndex: 'content.number',
						width: 40,
					},
					{
						title: 'Tag Type',
						dataIndex: `${product.toUpperCase()}` === 'GPR_SOIL' ? 'content.category.name' : 'content.type',
						width: 80,
					},
					...getDistanceLocationColumns(),
					{
						title: 'Calculated Depth',
						unitId: `${product}.Logbook Panel.Calculated Depth`,
						dataIndex: 'calculatedDepth',
						width: 120,
					},
					{
						title: 'Set Depth',
						unitId: `${product}.Logbook Panel.Set Depth`,
						dataIndex: 'setDepth',
						width: 80,
					},
					{
						title: 'Set Size',
						unitId: `${product}.Logbook Panel.Set Size`,
						dataIndex: isSoil ? 'setSize' : 'logContent.sizeCentimeter',
						width: 60,
					},
					{
						title: 'Comment',
						dataIndex: 'content.comment',
						width: 240,
					},
					{
						title: 'Name',
						dataIndex: 'content.name',
					},
				].map(columnConfig => ({
					// eslint-disable-next-line no-unused-vars
					render: (text, record: MeasurementObjectWithInfo) =>
						columnConfig.unitId ? convert(text, columnConfig.unitId) : text,
					...columnConfig,
					key: columnConfig.title,
					title: columnConfig.unitId ? (
						<ConvertedUnits
							id={columnConfig.title}
							unitId={columnConfig.unitId}
							scanType={scanType}
							isMetric={isMetric}
						/>
					) : (
						<FormattedMessage id={columnConfig.title} />
					),
				}))}
				dataSource={objects}
				pagination={false}
				size="small"
				bordered
			/>
		</div>
	);
};

export default Objects;
