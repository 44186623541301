// @flow
import React from 'react';
import { get } from 'lodash';
import { Form, Input, Button } from 'antd';
import type { FormProps } from 'antd/lib/form';
import URL from 'apis/urls';
import { connect } from 'react-redux';
import { compose, withState } from 'recompose';
import { FormattedMessage } from 'react-intl';
import styles from 'components/shared/SmarterTable.styl';
import { request } from 'actions/ajax';

export const AddEditBU = ({
	loading,
	form,
	BU,
	changeBU,
	addBU,
	editBU,
}: {
	loading: boolean,
	form: FormProps,
	BU: BUContact,
	changeBU: Function,
	addBU: Function,
	editBU: Function,
}) => (
	<Form
		onSubmit={e => {
			e.preventDefault();

			form.validateFields((err, values) => {
				if (err) {
					return;
				}

				(BU.email ? editBU : addBU)(values);
			});
		}}
	>
		<Form.Item label={<FormattedMessage id="Proceq.FormFirstName" />} className={styles.half_field}>
			{form.getFieldDecorator('firstName', {
				rules: [
					{
						required: true,
					},
				],
			})(<Input />)}
		</Form.Item>
		<Form.Item label={<FormattedMessage id="Proceq.FormLastName" />} className={styles.half_field}>
			{form.getFieldDecorator('lastName', {
				rules: [
					{
						required: true,
					},
				],
			})(<Input />)}
		</Form.Item>
		<Form.Item label={<FormattedMessage id="Proceq.FormEmail" />} className={styles.half_field}>
			{form.getFieldDecorator('email', {
				rules: [
					{
						required: true,
					},
				],
			})(<Input />)}
		</Form.Item>
		<Form.Item label={<FormattedMessage id="Proceq.FormPhoneNumber" />} className={styles.half_field}>
			{form.getFieldDecorator('phoneNumber', {
				rules: [
					{
						required: true,
					},
				],
			})(<Input />)}
		</Form.Item>
		<Form.Item label={<FormattedMessage id="Proceq.FormAddress" />} className={styles.field}>
			{form.getFieldDecorator('address', {
				rules: [
					{
						required: true,
					},
				],
			})(<Input />)}
		</Form.Item>
		<Form.Item label={<FormattedMessage id="App.BUNotificationEmail" />} className={styles.half_field}>
			{form.getFieldDecorator('notificationEmail', {
				rules: [
					{
						required: true,
					},
				],
			})(<Input />)}
		</Form.Item>
		<Form.Item className={styles.submit}>
			<Button type="default" onClick={() => changeBU(null)}>
				<FormattedMessage id="Proceq.LogbookClose" />
			</Button>
			&nbsp;
			<Button type="primary" htmlType="submit" loading={loading}>
				<FormattedMessage id="Proceq.ButtonSubmit" />
			</Button>
		</Form.Item>
	</Form>
);

export default compose(
	Form.create({
		mapPropsToFields: props => {
			const fields = {};
			['firstName', 'lastName', 'email', 'phoneNumber', 'address', 'notificationEmail'].forEach(k => {
				const value = get(props, ['bu', k]);
				fields[k] = Form.createFormField({ value });
			});
			return fields;
		},
	}),
	withState('loading', 'setLoading', false),
	connect(null, (dispatch: Function, props) => ({
		addBU: (params: {}) => {
			dispatch(
				request({
					method: 'POST',
					url: URL.CREATE_BU,
					setLoading: props.setLoading,
					params,
				})
			).then(data => {
				if (data.status === 'success') {
					props.changeBU(null);
					props.loadBUs();
				}
			});
		},
		editBU: (params: {}) => {
			dispatch(
				request({
					method: 'PUT',
					url: URL.UPDATE_BU,
					setLoading: props.setLoading,
					params,
				})
			).then(data => {
				if (data === '') {
					props.changeBU(null);
					props.loadBUs();
				}
			});
		},
	}))
)(AddEditBU);
