// @flow
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import type { InjectIntlProvidedProps } from 'react-intl';
import { convertAndDisplay } from 'constants/conversionRates';

export const Setting = ({ intl, log }: GPRLogbookEntryProps & InjectIntlProvidedProps) => {
	const { updatedContent } = log.content;
	const position = +log.content.position;

	const noUnits = [
		'type',
		'description',
		'measuringMode',
		'pulseWidth',
		'spatialAveraging',
		'unit',
		'rf',
		'triggerGate1',
		'triggerGate2',
		'setupFile',
	];
	let unitToUse;
	if (!noUnits.includes(updatedContent.item) && updatedContent.unit) {
		const { unit } = convertAndDisplay(`FDL.${updatedContent.item}`, 'Indication', updatedContent.unit);
		unitToUse = unit;
	}

	const item = intl.formatMessage(
		{
			id: `App.Logbook.FDL.Item.${updatedContent.item}`,
		},
		{
			unit: unitToUse,
		}
	);

	let oldValue = updatedContent.oldValue;
	let newValue = updatedContent.newValue;
	const enumUnits = ['type', 'measuringMode', 'pulseWidth', 'rf', 'triggerGate1', 'triggerGate2'];
	if (enumUnits.includes(updatedContent.item)) {
		let itemType = updatedContent.item;
		if (updatedContent.item.includes('triggerGate')) {
			itemType = 'triggerGate';
		}

		if (oldValue !== undefined) {
			oldValue = intl.formatMessage({ id: `App.Logbook.FDL.Item.${itemType}.${oldValue}` });
		}
		newValue = intl.formatMessage({ id: `App.Logbook.FDL.Item.${itemType}.${newValue}` });
	}

	if (log.content.position === undefined) {
		return oldValue !== undefined ? (
			<FormattedMessage
				id="App.Logbook.FDL.Settings"
				values={{
					item,
					oldValue,
					newValue,
				}}
			/>
		) : (
			<FormattedMessage
				id="App.Logbook.FDL.Indication.ChangeToNew"
				values={{
					item,
					newValue,
				}}
			/>
		);
	}

	return oldValue !== undefined ? (
		<FormattedMessage
			id="App.Logbook.FDL.Indication.Changed"
			values={{
				position,
				item,
				oldValue,
				newValue,
			}}
		/>
	) : (
		<FormattedMessage
			id="App.Logbook.FDL.Indication.Edited"
			values={{
				position,
				item,
				newValue,
			}}
		/>
	);
};

export const color = (log: Log) => (log.content.statusType === 'deleted' ? 'red' : 'green');

export default injectIntl(Setting);
