import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import URL from 'apis/urls';
import { getProceqUrl } from 'utils/sessionHelper';
import SmartTable from '../../shared/SmartTable';
import styles from '../../dashboard/Settings/UserProfile.styl';

export class UsersManagementProceq extends Component {
	static propTypes = {
		session: PropTypes.object,
	};

	constructor(props) {
		super(props);
		const extraActions = [
			{
				actionId: 'verifyaccount',
				actionType: 'apicall',
				actionText: 'Verify Account',
				apiEndpoint: getProceqUrl(this.props.session, URL.PROCEQ_ACTIVATE_ACCOUNT),
				apiTypeMethod: 'post',
				apiSendToken: true,
				apiOneRequestForEachId: false,
				apiSendFieldValues: {
					fieldSource: '_id',
					fieldTarget: 'users_ids',
					separateValue: 'comma',
				},
				askConfirmation: {
					title: 'Verify Account',
					body: 'Are you sure?',
				},
				multipleSelectedAllowed: true,
				oneSelectedNeeded: true,
				rolesNeeded: ['admin_proceq', 'snassigner_proceq'],
			},
			{
				actionId: 'loginasuser',
				actionType: 'apicall',
				actionText: 'Login as a user',
				apiEndpoint: getProceqUrl(this.props.session, URL.PROCEQ_LOGIN_AS_USER),
				apiTypeMethod: 'post',
				apiSendToken: true,
				apiOneRequestForEachId: false,
				apiSendFieldValues: {
					fieldSource: '_id',
					fieldTarget: 'user_id',
					separateValue: 'comma',
				},
				askConfirmation: {
					title: 'Login as user?',
					body: 'Are you sure?',
				},
				onSuccessApiCall: json => {
					const redirectToUrl = `${json.data.web_url}logintoken/?ot=${json.data.onetime_token}`;
					const win = window.open(redirectToUrl, '_blank');
					if (win) {
						// Browser has allowed it to be opened
						win.focus();
					} else {
						// Browser has blocked it
						alert('Please allow popups for this website'); // eslint-disable-line no-alert
						// this.errorDisplayService.showMessage("error", 'Please allow popups for this website');
					}
				},
				multipleSelectedAllowed: false,
				oneSelectedNeeded: true,
				rolesNeeded: ['admin_proceq'],
			},
			{
				actionId: 'changepassword',
				actionType: 'form',
				actionText: 'Change password',
				apiEndpoint: getProceqUrl(this.props.session, URL.PROCEQ_ADMIN_UPDATE_PASSWORD_ACCOUNT),
				apiTypeMethod: 'post',
				apiSendToken: true,
				apiOneRequestForEachId: true,
				messageOnSuccess: 'MessageActionSuccesfullyPerformed',
				titleText: 'Change password',
				titleIcon: 'edit',
				overrideFields: {
					mapping: { _id: 'user_id' },
				},
				fieldSets: [
					{
						title: null,
						fields: [
							{
								title: 'ID',
								type: 'input',
								name: 'user_id',
								default: '',
								styleClasses: 'hide_field',
								validation: {
									type: 'standard',
									required: false,
								},
							},
							{
								title: 'FormPasswordNew',
								name: 'password_new',
								type: 'passwordnoencode',
								styleClasses: 'force_full_field',
								validation: {
									type: 'password',
									required: true,
								},
							},
							{
								title: 'FormConfirmPassword',
								name: 'password_confirm',
								type: 'passwordnoencode',
								styleClasses: 'force_full_field',
								validation: {
									type: 'password',
									required: true,
								},
							},
						],
					},
				],
				multipleSelectedAllowed: false,
				oneSelectedNeeded: true,
				rolesNeeded: ['admin_proceq'],
			},
			{
				actionGroupText: 'Manage roles',
				actionGroupId: 'grantroles',
				actionId: 'grantburole',
				actionType: 'apicall',
				actionText: 'Grant BU',
				apiEndpoint: getProceqUrl(this.props.session, URL.PROCEQ_GRANT_BU_ROLE),
				apiTypeMethod: 'post',
				apiSendToken: true,
				apiOneRequestForEachId: false,
				apiSendBody: {},
				apiSendFieldValues: {
					fieldSource: '_id',
					fieldTarget: 'users_ids',
					separateValue: 'comma',
				},
				askConfirmation: {
					title: 'Grant BU roles?',
					body: 'Are you sure?',
				},
				multipleSelectedAllowed: true,
				oneSelectedNeeded: true,
				rolesNeeded: ['admin_proceq', 'snassigner_proceq'],
			},
			{
				actionGroupText: 'Manage roles',
				actionGroupId: 'grantroles',
				actionId: 'revokeburole',
				actionType: 'apicall',
				actionText: 'Revoke BU',
				apiEndpoint: getProceqUrl(this.props.session, URL.PROCEQ_REVOKE_BU_ROLE),
				apiTypeMethod: 'post',
				apiSendToken: true,
				apiOneRequestForEachId: false,
				apiSendBody: {},
				apiSendFieldValues: {
					fieldSource: '_id',
					fieldTarget: 'users_ids',
					separateValue: 'comma',
				},
				askConfirmation: {
					title: 'Revoke BU roles?',
					body: 'Are you sure?',
				},
				multipleSelectedAllowed: true,
				oneSelectedNeeded: true,
				rolesNeeded: ['admin_proceq', 'snassigner_proceq'],
			},
			{
				actionGroupText: 'Manage roles',
				actionGroupId: 'grantroles',
				actionId: 'grantadminrole',
				actionType: 'apicall',
				actionText: 'Grant Admin',
				apiEndpoint: getProceqUrl(this.props.session, URL.PROCEQ_GRANT_ADMIN_ROLE),
				apiTypeMethod: 'post',
				apiSendToken: true,
				apiOneRequestForEachId: false,
				apiSendBody: {},
				apiSendFieldValues: {
					fieldSource: '_id',
					fieldTarget: 'users_ids',
					separateValue: 'comma',
				},
				askConfirmation: {
					title: 'Grant Admin roles?',
					body: 'Are you sure?',
				},
				multipleSelectedAllowed: true,
				oneSelectedNeeded: true,
				rolesNeeded: ['admin_proceq'],
			},
			{
				actionGroupText: 'Manage roles',
				actionGroupId: 'grantroles',
				actionId: 'revokeadminrole',
				actionType: 'apicall',
				actionText: 'Revoke Admin',
				apiEndpoint: getProceqUrl(this.props.session, URL.PROCEQ_REVOKE_ADMIN_ROLE),
				apiTypeMethod: 'post',
				apiSendToken: true,
				apiOneRequestForEachId: false,
				apiSendBody: {},
				apiSendFieldValues: {
					fieldSource: '_id',
					fieldTarget: 'users_ids',
					separateValue: 'comma',
				},
				askConfirmation: {
					title: 'Revoke Admin roles?',
					body: 'Are you sure?',
				},
				multipleSelectedAllowed: true,
				oneSelectedNeeded: true,
				rolesNeeded: ['admin_proceq'],
			},
			{
				actionGroupText: 'Manage roles',
				actionGroupId: 'grantroles',
				actionId: 'grantdevrole',
				actionType: 'apicall',
				actionText: 'Grant Developer',
				apiEndpoint: getProceqUrl(this.props.session, URL.PROCEQ_GRANT_DEVELOPER_ROLE),
				apiTypeMethod: 'post',
				apiSendToken: true,
				apiOneRequestForEachId: false,
				apiSendBody: {},
				apiSendFieldValues: {
					fieldSource: '_id',
					fieldTarget: 'users_ids',
					separateValue: 'comma',
				},
				askConfirmation: {
					title: 'Grant Developer roles?',
					body: 'Are you sure?',
				},
				multipleSelectedAllowed: true,
				oneSelectedNeeded: true,
				rolesNeeded: ['admin_proceq'],
			},
			{
				actionGroupText: 'Manage roles',
				actionGroupId: 'grantroles',
				actionId: 'revokedevrole',
				actionType: 'apicall',
				actionText: 'Revoke Developer',
				apiEndpoint: getProceqUrl(this.props.session, URL.PROCEQ_REVOKE_DEVELOPER_ROLE),
				apiTypeMethod: 'post',
				apiSendToken: true,
				apiOneRequestForEachId: false,
				apiSendBody: {},
				apiSendFieldValues: {
					fieldSource: '_id',
					fieldTarget: 'users_ids',
					separateValue: 'comma',
				},
				askConfirmation: {
					title: 'Revoke Developer roles?',
					body: 'Are you sure?',
				},
				multipleSelectedAllowed: true,
				oneSelectedNeeded: true,
				rolesNeeded: ['admin_proceq'],
			},
			{
				actionId: 'deleteaccountdata',
				actionType: 'apicall',
				actionText: "Delete Account's Data",
				apiEndpoint: getProceqUrl(this.props.session, URL.PROCEQ_DELETE_ACCOUNT_DATA),
				apiTypeMethod: 'post',
				apiSendToken: true,
				apiOneRequestForEachId: false,
				apiSendBody: {},
				apiSendFieldValues: {
					fieldSource: '_id',
					fieldTarget: 'user_id',
					separateValue: 'comma',
				},
				askConfirmation: {
					title: "Delete Account's Data",
					body: 'Are you sure?',
				},
				multipleSelectedAllowed: false,
				oneSelectedNeeded: true,
				rolesNeeded: ['admin_proceq'],
			},
			{
				actionId: 'deleteaccounts',
				actionType: 'apicall',
				actionText: 'Delete Account',
				apiEndpoint: getProceqUrl(this.props.session, URL.PROCEQ_DELETE_ACCOUNT),
				apiTypeMethod: 'post',
				apiSendToken: true,
				apiOneRequestForEachId: false,
				apiSendBody: {},
				apiSendFieldValues: {
					fieldSource: '_id',
					fieldTarget: 'user_id',
					separateValue: 'comma',
				},
				askConfirmation: {
					title: 'Delete Account',
					body: 'Are you sure?',
				},
				multipleSelectedAllowed: false,
				oneSelectedNeeded: true,
				rolesNeeded: ['admin_proceq'],
			},
			{
				actionId: 'historyexport',
				actionType: 'form',
				actionText: 'Export User Activity as CSV',
				apiEndpoint: getProceqUrl(this.props.session, URL.PROCEQ_EXPORT_USERACTIVITY_CSV),
				apiTypeMethod: 'post',
				apiSendToken: true,
				apiOneRequestForEachId: false,
				messageOnSuccess: 'MessageActionSuccesfullyPerformed',
				titleText: 'Users History Activity CSV',
				titleIcon: 'edit',
				overrideFields: {
					mapping: { _id: 'user_id' },
				},
				fieldSets: [
					{
						title: null,
						fields: [
							{
								title: 'ID',
								type: 'input',
								name: 'user_id',
								default: '',
								styleClasses: 'hide_field',
								validation: {
									type: 'standard',
									required: false,
								},
							},
							{
								title: 'From (UTC)',
								name: 'min_date',
								type: 'date',
								styleClasses: 'force_full_field',
								validation: {
									type: 'data',
									required: true,
								},
							},
							{
								title: 'To (UTC)',
								name: 'max_date',
								type: 'date',
								styleClasses: 'force_full_field',
								validation: {
									type: 'data',
									required: true,
								},
							},
							{
								title: 'Detailed report',
								name: 'detailed',
								type: 'select',
								styleClasses: 'force_full_field',
								default: 1,
								allowedValues: {
									type: 'globalRegister',
									data: 'yesorno',
								},
								validation: {
									type: 'standard',
									required: true,
									returnAsInt: true,
								},
							},
						],
					},
				],
				multipleSelectedAllowed: false,
				oneSelectedNeeded: true,
				rolesNeeded: ['admin_proceq'],
			},
		];

		this.state = {
			extraActions,
			exportCSVInfo: {
				apiEndpoint: getProceqUrl(this.props.session, URL.PROCEQ_EXPORT_ACCOUNTS_CSV),
				apiTypeMethod: 'post',
				apiSendToken: true,
			},
		};
	}

	render() {
		const columns = [
			{
				title: 'Id',
				dataIndex: '_id',
				sorter: false,
				width: '20%',
				typeField: 'hidden',
			},
			{
				title: 'Email',
				dataIndex: 'username',
				sorter: true,
				filter: true,
			},
			{
				title: 'Country',
				dataIndex: 'country',
				sorter: true,
				globalRegister: 'countries',
				filter: true,
			},
			{ title: 'First Name', dataIndex: 'firstname', sorter: true },
			{ title: 'Last Name', dataIndex: 'lastname', sorter: true },
			{
				title: 'Industry',
				dataIndex: 'industry',
				sorter: true,
				globalRegister: 'industrytype',
				filter: true,
			},
			{ title: 'Company Name', dataIndex: 'companyname', sorter: true },
			// { title: 'Company Size', dataIndex: 'companysize', sorter: true, globalRegister: 'companysize', filter: true },
			// { title: 'Company Website', dataIndex: 'companywebsite', sorter: true },
			// { title: 'Job Title', dataIndex: 'jobtitle', sorter: true },
			{
				title: 'Newsletter',
				dataIndex: 'newsletter',
				sorter: true,
				globalRegister: 'yesorno',
				filter: true,
			},
			{ title: 'Region', dataIndex: 'region', filter: true },
			{
				title: 'BU Unit',
				dataIndex: 'buunit',
				globalRegister: 'buunits',
				filter: true,
			},
			{ title: '@Email', dataIndex: 'emaildomain', filter: true },
			{
				title: 'Verified',
				dataIndex: 'email_confirmed',
				sorter: true,
				globalRegister: 'yesorno',
				filter: true,
			},
			{
				title: 'Roles',
				dataIndex: 'roles',
				sorter: true,
				globalRegister: 'roles',
			},
		];
		return (
			<div className={styles.profile}>
				<div className={styles.profile_container}>
					<SmartTable
						apiEndpoint={getProceqUrl(this.props.session, URL.PROCEQ_GET_ACCOUNTS)}
						apiTypeMethod="post"
						apiSendToken
						columns={columns}
						columnKey="_id"
						typeSortingFiltering="proceq"
						typeRetrieveData="proceq"
						editFormInfo={this.state.editFormInfo}
						addFormInfo={this.state.addFormInfo}
						exportCSVInfo={this.state.exportCSVInfo}
						extraActions={this.state.extraActions}
						fetchAllDataOnStart={false}
					/>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	session: state.session,
	locale: state.locale,
	globalregister: state.globalregister,
});

export default connect(mapStateToProps)(UsersManagementProceq);
