// @flow
import React, { useState, useMemo } from 'react';
import { compose, withState } from 'recompose';
import { connect } from 'react-redux';
import { request } from 'actions/ajax';
import URL from 'apis/urls';
import { Modal, Table } from 'antd';
import { injectIntl } from 'react-intl';
import handleFormatMessage from 'utils/handleFormatMessage';
import Info from 'components/svgIcons/Info';

export const Versions = ({
	loadVersions,
	versions,
	loading,
	error,
	intl,
}: {
	loadVersions: () => void,
	versions: {
		name: string,
		version: string,
	}[],
	loading: boolean,
	error: string,
	intl: any,
}) => {
	const { formatMessage } = intl;
	const [visible, setVisible] = useState(false);
	const columnConfig = useMemo(
		() => [
			{
				title: handleFormatMessage('App.System.Filename', formatMessage),
				dataIndex: 'name',
				sorter: (a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
				key: 'name',
			},
			{
				title: handleFormatMessage('App.Version', formatMessage),
				dataIndex: 'version',
				key: 'version',
			},
		],
		[]
	);

	return (
		<>
			<a
				onClick={() => {
					setVisible(true);
					loadVersions();
				}}
				style={{ color: 'white', paddingLeft: 10 }}
			>
				<Info color="white" />
			</a>
			<Modal
				visible={visible}
				title={handleFormatMessage('App.Version.Info', formatMessage)}
				footer={null}
				onCancel={() => setVisible(false)}
				className="versionsModal"
			>
				{!error ? (
					<Table columns={columnConfig} dataSource={versions} pagination={false} loading={loading} />
				) : (
					<div style={{ color: 'red' }}>{error}</div>
				)}
			</Modal>
		</>
	);
};

export default compose(
	injectIntl,
	withState('error', 'setError', null),
	withState('loading', 'setLoading', true),
	withState('versions', 'setVersions', []),
	connect(null, (dispatch: Function, props) => ({
		loadVersions: () => {
			props.setLoading(true);
			props.setError(null);
			dispatch(
				request({
					url: URL.GET_DEPENDENCY_VERSIONS,
					injectProduct: false,
				})
			)
				.then(data => {
					const versionWithKeys = data.map(version => ({ ...version, key: version.name }));
					props.setVersions(versionWithKeys);
					props.setLoading(false);
				})
				.catch(() => {
					props.setLoading(false);
					props.setError('Failed to load');
				});
		},
	}))
)(Versions);
