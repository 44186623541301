import styled from 'styled-components';
import Select from 'components/shared/SmarterFormItems/Select';

const StyledSelect = styled(Select)`
	.ant-select-selection li.ant-select-selection__choice[title='disable'] {
		background-color: #ffb270;
	}
	.ant-select-selection-selected-value[title='disable'] {
		background-color: #ffb270;
		width: 100%;
	}
`;
export default StyledSelect;
