// @flow
import { message } from 'antd';
import { get } from 'lodash';
import axios from 'axios';
import type { CancelTokenSource } from 'axios';
import { history } from '../routes';
import { storeDispatch } from '../store';
import { finishUserSession } from '../actions/logout';
import routeURLs from '../routes/urls';

// return a flag as to whether to continue
const cancel = (cancelToken: CancelTokenSource): boolean => {
	// We can cancel this - let's do it
	if (cancelToken) {
		// Oh wait, we already cancelled it.
		if (cancelToken.token.reason) {
			return true;
		}

		cancelToken.cancel();
	}

	return false;
};

const handleRedirect = (msg: string, cancelToken: CancelTokenSource) => {
	if (cancel(cancelToken)) {
		return;
	}

	message.error(msg, 5);
	storeDispatch(finishUserSession());
	history.push(routeURLs.AUTH);
};

export default function errorHandler(err: Object, cancelToken: CancelTokenSource) {
	if (err.config && err.config.responseType === 'blob') {
		return new Promise<*>((resolve, reject) => {
			const reader = new FileReader();
			reader.addEventListener('abort', reject);
			reader.addEventListener('error', reject);
			reader.addEventListener('loadend', () => {
				err.response.data = JSON.parse(String(reader.result));
				err.config.responseType = 'json';
				resolve(errorHandler(err, cancelToken));
			});
			reader.readAsText(err.response.data);
		});
	}

	const errorMessage = get(err, 'response.data.message');
	const status = get(err, 'response.status');

	if (window.Sentry) {
		window.Sentry.withScope(scope => {
			scope.setLevel('info');
			window.Sentry.captureException(err);
		});
	}

	switch (status) {
		case 401:
			handleRedirect('Your session has expired. Please log in again.', cancelToken);
			break;
		case 403:
			handleRedirect('You do not have the permissions to view this page.', cancelToken);
			break;
		case 500:
			if (get(err, 'response.data.ErrorMsg') === 'Permission Denied') {
				handleRedirect('You do not have the permissions to view this page.', cancelToken);
				break;
			}
		// eslint-disable-next-line no-fallthrough
		default:
			if (cancel(cancelToken)) {
				// managed to cancel, don't do anything
			} else if (errorMessage) {
				message.error(errorMessage, 5);
			} else if (err instanceof axios.Cancel) {
				// message.error(err.message);
			} else if (status) {
				message.error(`Server error: response code ${status}.`, 5);
			}
			break;
	}

	// return a promise that will never resolve, so that we don't progress further but also so we don't trigger an error caused by uncaught rejected promise 🤷‍
	return Promise.reject();
}
