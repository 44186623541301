import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import URL from 'apis/urls';
import { getProceqUrl } from 'utils/sessionHelper';
import SmartTable from '../../shared/SmartTable';
import styles from '../../dashboard/Settings/UserProfile.styl';

export class BusInfoManagementProceq extends Component {
	static propTypes = {
		session: PropTypes.object,
	};

	constructor(props) {
		super(props);
		const editFormInfo = {
			titleText: 'Edit Bu',
			titleIcon: 'edit',
			apiEndpoint: getProceqUrl(this.props.session, URL.PROCEQ_EDIT_BU_INFO),
			apiTypeMethod: 'post',
			apiSendToken: true,
			fieldSets: [
				{
					title: null,
					fields: [
						{
							title: 'BU Name',
							type: 'select',
							name: '_id',
							styleClasses: 'force_full_field',
							allowedValues: {
								type: 'globalRegister',
								data: 'buunits',
							},
							validation: {
								type: 'standard',
								required: true,
							},
						},
						{
							title: 'First Name',
							name: 'contact_firstname',
							type: 'input',
							styleClasses: 'force_half_and_inline',
							validation: {
								type: 'firstname',
								required: true,
							},
						},
						{
							title: 'Last Name',
							name: 'contact_lastname',
							type: 'input',
							styleClasses: 'force_half_and_inline',
							validation: {
								type: 'lastname',
								required: true,
							},
						},
						{
							title: 'Contact Email',
							name: 'contact_email',
							type: 'input',
							styleClasses: 'force_half_and_inline',
							validation: {
								type: 'email',
								required: true,
							},
						},
						{
							title: 'Contact Phone',
							name: 'contact_phone',
							type: 'input',
							styleClasses: 'force_half_and_inline',
							validation: {
								type: 'standard',
								required: true,
							},
						},
						{
							title: 'Address',
							name: 'contact_address',
							type: 'input',
							styleClasses: 'force_half_and_inline',
							validation: {
								type: 'standard',
								required: true,
							},
						},
						{
							title: 'Notifications Email',
							name: 'notifications_email',
							type: 'input',
							styleClasses: 'force_half_and_inline',
							validation: {
								type: 'email',
								required: true,
							},
						},
					],
				},
			],
		};

		const extraActions = [];
		const addFormInfo = {
			...editFormInfo,
			titleText: 'Add BU',
			apiEndpoint: getProceqUrl(this.props.session, URL.PROCEQ_ADD_BU_INFO),
		};
		this.state = {
			editFormInfo,
			addFormInfo,
			extraActions,
			exportCSVInfo: {
				apiEndpoint: getProceqUrl(this.props.session, URL.PROCEQ_EDIT_BUS_INFO_CSV),
				apiTypeMethod: 'post',
				apiSendToken: true,
			},
		};
	}

	render() {
		const columns = [
			{
				title: 'BU Name',
				dataIndex: '_id',
				sorter: true,
				filter: true,
				globalRegister: 'buunits',
			},
			{
				title: 'First Name',
				dataIndex: 'contact_firstname',
				sorter: true,
				filter: true,
			},
			{
				title: 'Last Name',
				dataIndex: 'contact_lastname',
				sorter: true,
				filter: true,
			},
			{
				title: 'Contact Email',
				dataIndex: 'contact_email',
				sorter: true,
				filter: true,
			},
			{
				title: 'Contact Phone',
				dataIndex: 'contact_phone',
				sorter: true,
				filter: true,
			},
			{
				title: 'Address',
				dataIndex: 'contact_address',
				sorter: true,
				filter: true,
			},
			{
				title: 'Notifications Email',
				dataIndex: 'notifications_email',
				sorter: true,
				filter: true,
			},
		];
		return (
			<div className={styles.profile}>
				<div className={styles.profile_container}>
					<SmartTable
						apiEndpoint={getProceqUrl(this.props.session, URL.PROCEQ_GET_BUS_INFO)}
						apiTypeMethod="post"
						apiSendToken
						columns={columns}
						columnKey="_id"
						typeSortingFiltering="proceq"
						typeRetrieveData="proceq"
						editFormInfo={this.state.editFormInfo}
						addFormInfo={this.state.addFormInfo}
						exportCSVInfo={this.state.exportCSVInfo}
						extraActions={this.state.extraActions}
						fetchAllDataOnStart={false}
					/>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	session: state.session,
	locale: state.locale,
	globalregister: state.globalregister,
});

export default connect(mapStateToProps)(BusInfoManagementProceq);
