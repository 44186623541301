// @flow
import React, { useState, useCallback, useContext } from 'react';
import useNotificationServices from './useNotificationServices';
import type { NotificationServices } from './useNotificationServices';
import type { NotificationInfo } from './notificationTypes';

const NotificationDetailsContext = React.createContext<
	NotificationServices & {
		handleFetchNotifications: () => Promise<void>,
		notificationData: Array<NotificationInfo>,
		formVisible: void | boolean,
		setFormVisible: (((void | boolean) => void | boolean) | void | boolean) => void,
	}
>({});

export const useNotificationDetailsContext = () => {
	return useContext(NotificationDetailsContext);
};

const NotificationDetailsProvider = (props: { children: React$Node }) => {
	const { children } = props;
	const [notificationData, setNotificationData] = useState<Array<NotificationInfo>>([]);
	const [formVisible, setFormVisible] = useState(false);

	const { getNotificationSchedule, deleteNotification, createNotification } = useNotificationServices();

	const handleFetchNotifications = useCallback(async () => {
		const response = await getNotificationSchedule();
		if (Array.isArray(response.data)) {
			setNotificationData(response.data);
		}
	}, [getNotificationSchedule]);

	return (
		<NotificationDetailsContext.Provider
			value={{
				getNotificationSchedule,
				deleteNotification,
				createNotification,
				handleFetchNotifications,
				notificationData,
				formVisible,
				setFormVisible,
			}}
		>
			{children}
		</NotificationDetailsContext.Provider>
	);
};

export default NotificationDetailsProvider;
