// @flow
import React, { Fragment } from 'react';
import { get } from 'lodash';
import moment from 'moment';
import { Button, Checkbox, Form, Icon, Input, Tooltip } from 'antd';
import type { FormProps } from 'antd/lib/form';
import URL from 'apis/urls';
import { connect } from 'react-redux';
import { compose, lifecycle, withState } from 'recompose';
import type { InjectIntlProvidedProps } from 'react-intl';
import { FormattedMessage, injectIntl } from 'react-intl';
import Select from 'components/shared/SmarterFormItems/Select';
import DatePicker from 'components/shared/SmarterFormItems/DatePicker';
import styles from 'components/shared/SmarterTable.styl';
import { request } from 'actions/ajax';
import { hasRoleLevel, isBUOrHigher, ROLES } from 'utils/rolesHelper';
import StyledSelect from 'components/shared/SmarterFormItems/StyledSelect';
import { emailRegex } from '../users/AddEditUser';
import {
	ContractType,
	getName,
	getValue,
	productComparator,
	ProductConstants,
	UserTypeOptions,
	validateSN,
} from './contractConsts';

export const AddEditBluetoothContract = ({
	loading,
	form,
	contract,
	changeContract,
	isAdmin,
	permissionToChangeProduct,
	addContract,
	editContract,
	products,
	prodOptions,
	tierOptions,
	setCurrProd,
	tiers,
	contractModified,
	expirationModified,
	contractType,
	setUserType,
	intl: { formatMessage },
}: {
	loading: boolean,
	form: FormProps,
	contract?: Contract,
	changeContract: Function,
	isAdmin: boolean,
	permissionToChangeProduct: boolean,
	addContract: Function,
	editContract: Function,
	products: Product[],
	prodOptions: SelectOpts[],
	tierOptions: SelectOpts[],
	setCurrProd: Function,
	tiers: Object[],
	contractModified: boolean,
	expirationModified: boolean,
	contractType: string,
	setUserType: Function,
} & InjectIntlProvidedProps) => {
	const onProductChange = params => {
		setCurrProd(params);
		form.setFieldsValue({ tier: '' });
	};

	const onDateChange = v => {
		form.setFieldsValue({ expirationDate: v });
	};

	const onUserTypeChange = params => {
		setUserType(params);
	};

	const onSubmit = e => {
		e.preventDefault();
		form.validateFields((err, values) => {
			if (err) {
				return;
			}

			if (contractType === undefined || contractType === ContractType.Device) {
				const LeebPattern = /^BT01-.{3}-.{4}$/;
				const UCIPattern = /^BT05-.{3}-.{4}$/;
				const SchmidtDongleSNPattern = /^BT(05|42)-.{3}-.{4}$/;
				const SchmidtPattern = [/^SL01-.{3}-.{4}$/, /^SL02-.{3}-.{4}$/];
				const SSLPattern = [/^OS82-.{3}-.{4}$/, /^OS83-.{3}-.{4}$/];
				const PaperSchmidtPattern = /^PS80-.{3}-.{4}$/;
				const RockSchmidtPattern = [/^RS80-.{3}-.{4}$/, /^RS81-.{3}-.{4}$/];
				const GLMPattern = /^ZG80-.{3}-.{4}$/;
				const PI8000Pattern = /^PI80-.{3}-.{4}$/;
				const PM8000Pattern = /^PM80-.{3}-.{4}$/;
				const PM8500Pattern = /^PM85-.{3}-.{4}$/;

				let dongleError = '';
				let probeError = '';
				switch (values.product) {
					case 'LEEBD':
						if (values.dongleSN.match(UCIPattern) !== null) {
							dongleError = 'App.ContractsSerialMismatchError';
						} else if (values.dongleSN.match(LeebPattern) === null) {
							dongleError = 'App.ContractsSerialLeebError';
						}
						break;
					case 'SCHMIDT':
					case 'SSL':
					case 'GLM':
						if (values.dongleSN.match(LeebPattern) !== null) {
							dongleError = 'App.ContractsSerialMismatchError';
						} else if (values.dongleSN.match(SchmidtDongleSNPattern) === null) {
							dongleError = 'App.ContractsSerialSchmidtError';
						}
						break;
					case 'UCI':
						if (values.dongleSN.match(LeebPattern) !== null) {
							dongleError = 'App.ContractsSerialMismatchError';
						} else if (values.dongleSN.match(UCIPattern) === null) {
							dongleError = 'App.ContractsSerialUCIError';
						}
						break;
				}
				switch (values.product) {
					case 'SCHMIDT':
						if (
							values.probeSN.match(SchmidtPattern[0]) === null &&
							values.probeSN.match(SchmidtPattern[1]) === null
						) {
							probeError = getErrorMsgForProbeSN(values.product);
						}
						break;
					case 'SSL':
						if (
							values.probeSN.match(SSLPattern[0]) === null &&
							values.probeSN.match(SSLPattern[1]) === null
						) {
							probeError = getErrorMsgForProbeSN(values.product);
						}
						break;
					case 'PS8000':
						if (values.probeSN.match(PaperSchmidtPattern) === null) {
							probeError = getErrorMsgForProbeSN(values.product);
						}
						break;
					case 'RS8000':
						if (
							values.probeSN.match(RockSchmidtPattern[0]) === null &&
							values.probeSN.match(RockSchmidtPattern[1]) === null
						) {
							probeError = getErrorMsgForProbeSN(values.product);
						}
						break;
					case 'GLM':
						if (values.probeSN.match(GLMPattern) === null) {
							probeError = getErrorMsgForProbeSN(values.product);
						}
						break;
					case ProductConstants.PI8000:
						if (values.probeSN.match(PI8000Pattern) === null) {
							probeError = getErrorMsgForProbeSN(values.product);
						}
						break;
					case ProductConstants.PM8000:
						if (values.probeSN.match(PM8000Pattern) === null) {
							probeError = getErrorMsgForProbeSN(values.product);
						}
						break;
					case ProductConstants.PM8500:
						if (values.probeSN.match(PM8500Pattern) === null) {
							probeError = getErrorMsgForProbeSN(values.product);
						}
						break;
				}
				const validateSerialNo = validateSN(contract, isAdmin);
				if (dongleError && validateSerialNo) {
					form.setFields({
						dongleSN: {
							value: values.dongleSN,
							errors: [new Error(formatMessage({ id: dongleError }))],
						},
					});
					return;
				}
				let showProbeError = false;
				if (isAdmin) {
					if (values.probeSN !== undefined && values.probeSN.length > 0) {
						showProbeError = validateSerialNo;
					}
				} else {
					showProbeError = true;
				}
				if (showProbeError && probeError) {
					form.setFields({
						probeSN: {
							value: values.probeSN,
							errors: [new Error(formatMessage({ id: probeError }))],
						},
					});
					return;
				}
			}
			if (values.expirationDate) {
				values.expirationDate = values.expirationDate.format('YYYY-MM-DD');
			}

			if (values.hardwareID) {
				values.hardwareID = values.hardwareID.toUpperCase();
			}

			if (contract) {
				editContract(values, isAdmin);
			} else {
				addContract(values);
			}
		});
	};

	const selectedProd = form.getFieldValue('product');
	// const isProductPI8000 = selectedProd === ProductConstants.PI8000;
	// const isProductPM8000 = selectedProd === ProductConstants.PM8000;

	const deviceSnProducts = [
		ProductConstants.GLM,
		ProductConstants.SCHMIDT,
		ProductConstants.SSL,
		ProductConstants.PI8000,
		ProductConstants.PM8000,
		ProductConstants.PM8500,
		ProductConstants.PS8000,
		ProductConstants.RS8000,
	];
	const hasDeviceSN = deviceSnProducts.includes(selectedProd);

	const hardwareIdHiddenProducts = [
		ProductConstants.PI8000,
		ProductConstants.PM8000,
		ProductConstants.PM8500,
		ProductConstants.PS8000,
		ProductConstants.RS8000,
	];
	const displayHardwareIDEditForm =
		isAdmin &&
		[ContractType.Device, undefined].includes(contractType) &&
		!hardwareIdHiddenProducts.includes(selectedProd);

	const displayBluetoothSN = !hardwareIdHiddenProducts.includes(selectedProd);

	const showExpirationDate =
		tiers !== undefined &&
		tiers.filter(t => t.tier === form.getFieldValue('tier'))[0] !== undefined &&
		tiers.filter(t => t.tier === form.getFieldValue('tier'))[0].allowExpiration === 1;

	return (
		<Form onSubmit={onSubmit}>
			<Form.Item label={<FormattedMessage id="App.UserType" />} className={styles.half_field}>
				{form.getFieldDecorator('isInternal', {
					rules: [{ required: true, message: formatMessage({ id: 'App.ContractsUserTypeRequired' }) }],
				})(<Select selectOpt={UserTypeOptions} onChange={onUserTypeChange} />)}
			</Form.Item>
			<Form.Item label={<FormattedMessage id="Proceq.FormEmailGroupID" />} className={styles.half_field}>
				{form.getFieldDecorator('contactEmail', {
					rules: [
						{
							required: true,
							pattern: emailRegex,
							message: formatMessage({ id: 'Proceq.ErrorWrongUsername' }),
						},
					],
				})(<Input />)}
			</Form.Item>
			<Form.Item
				label={<FormattedMessage id="App.Product" />}
				className={showExpirationDate ? styles.one_third_field : styles.half_field}
			>
				{products !== undefined &&
					form.getFieldDecorator('product', {
						rules: [{ required: true }],
					})(
						<Select
							selectOpt={prodOptions}
							onChange={onProductChange}
							disabled={!(permissionToChangeProduct || contract === undefined || contract.probeSN === '')}
						/>
					)}
			</Form.Item>
			<Form.Item
				label={<FormattedMessage id="App.ProductType" />}
				className={showExpirationDate ? styles.one_third_field : styles.half_field}
			>
				{form.getFieldDecorator('tier', {
					rules: [{ required: true }],
				})(
					<StyledSelect
						selectOpt={tierOptions}
						disabled={!(permissionToChangeProduct || contract === undefined || contract.probeSN === '')}
					/>
				)}
			</Form.Item>
			{showExpirationDate && (
				<Form.Item
					label={<FormattedMessage id="Proceq.FormExpirationDate" />}
					className={styles.one_third_field}
				>
					{form.getFieldDecorator('expirationDate', {
						rules: [{ required: true }],
					})(<DatePicker onChange={onDateChange} showRemainingDays showRemainingText="TextRemainingXDays" />)}
				</Form.Item>
			)}
			<div>
				{tiers !== undefined &&
				(contractType === undefined || contractType === ContractType.Device) /* contract is of type device */ &&
					tiers.filter(t => t.tier === form.getFieldValue('tier'))[0] !== undefined &&
					tiers.filter(t => t.tier === form.getFieldValue('tier'))[0].offerEagleCare === 1 && (
						<Form.Item className={styles.field}>
							{form.getFieldDecorator('isCareRedeemed', {
								valuePropName: 'checked',
								initialValue: false,
							})(
								<Checkbox>
									<FormattedMessage id="App.IsRedeemed" />
									&nbsp;
									<Tooltip title={<FormattedMessage id="App.IsRedeemedToolTip" />}>
										<Icon type="info-circle" />
									</Tooltip>
								</Checkbox>
							)}
						</Form.Item>
					)}
			</div>
			<div>
				{(contractType === undefined ||
					contractType === ContractType.Device) /* contract is of type device */ &&
					displayBluetoothSN && (
						<Form.Item
							label={<FormattedMessage id="App.BluetoothSN" />}
							className={hasDeviceSN ? styles.half_field : styles.field}
						>
							{form.getFieldDecorator('dongleSN', {
								validateTrigger: '',
								rules: [
									{
										required: true,
										message: formatMessage({
											id:
												form.getFieldValue('product') === 'LEEBD'
													? 'App.ContractsSerialLeebError'
													: 'App.ContractsSerialUCIError',
										}),
									},
								],
							})(
								<Input
									placeholder={
										form.getFieldValue('product') === 'LEEBD'
											? 'BT01-123-456'
											: form.getFieldValue('product') === 'UCI'
											? 'BT05-123-4567'
											: 'BT05-123-4567 or BT42-123-4567'
									}
								/>
							)}
						</Form.Item>
					)}
				{hasDeviceSN && (
					<Form.Item label={<FormattedMessage id="App.DeviceSN" />} className={styles.half_field}>
						{form.getFieldDecorator('probeSN', {
							validateTrigger: '',
							onlyread: true,
							rules: [
								{
									required: contract === undefined,
									message: formatMessage({
										id: getErrorMsgForProbeSN(form.getFieldValue('product')),
									}),
								},
							],
						})(<Input placeholder={getPlaceholderForProbeSN(form.getFieldValue('product'))} />)}
					</Form.Item>
				)}
			</div>

			{displayHardwareIDEditForm && (
				<div>
					<Form.Item className={styles.field}>
						{form.getFieldDecorator('createAsAdmin', {
							valuePropName: 'checked',
						})(
							<Checkbox>
								<FormattedMessage id="App.CreateAsAdmin" />
								&nbsp;
								<Tooltip
									title={
										contract ? (
											<Fragment>
												<FormattedMessage id={'App.EditAsAdminTooltip1'} />
												<br />
												<br />
												<FormattedMessage id={'App.EditAsAdminTooltip2'} />
											</Fragment>
										) : (
											<Fragment>
												<FormattedMessage id={'App.CreateAsAdminTooltip1'} />
												<br />
												<br />
												<FormattedMessage id={'App.CreateAsAdminTooltip2'} />
											</Fragment>
										)
									}
								>
									<Icon type="info-circle" />
								</Tooltip>
							</Checkbox>
						)}
					</Form.Item>

					<Form.Item label={<FormattedMessage id="App.HardwareId" />} className={styles.half_field}>
						{form.getFieldDecorator('hardwareID', {
							initialValue: contract ? contract.hardwareID : '',
							rules: [
								{
									required: form.getFieldValue('createAsAdmin'),
									message: formatMessage({ id: 'App.HardwareIdError' }),
								},
							],
						})(<Input disabled={!form.getFieldValue('createAsAdmin')} />)}
					</Form.Item>
					{isAdmin && contract && (
						<Form.Item label={<FormattedMessage id="App.ContractsId" />} className={styles.half_field}>
							<span>{contract.id}</span>
						</Form.Item>
					)}
				</div>
			)}
			{
				<div>
					<div>
						<Form.Item
							label={<FormattedMessage id="APP.Contract.InvoiceNo" />}
							className={styles.one_third_field}
						>
							{form.getFieldDecorator('invoiceNo', {
								rules: [{ required: true, message: formatMessage({ id: 'App.InvoiceNoRequired' }) }],
							})(<Input />)}
						</Form.Item>

						<Form.Item
							label={<FormattedMessage id="APP.Contract.InvoiceNo2" />}
							className={styles.one_third_field}
						>
							{form.getFieldDecorator('invoiceNo2', {
								rules: [{ required: false }],
							})(
								<Input
									disabled={
										!(
											contract &&
											(form.getFieldValue('invoiceNo2') !== '' ||
												(contract !== undefined && contract.invoiceNo2 !== '') ||
												(expirationModified && form.getFieldValue('invoiceNo') !== ''))
										)
									}
								/>
							)}
						</Form.Item>
						<Form.Item
							label={<FormattedMessage id="APP.Contract.InvoiceNo3" />}
							className={styles.one_third_field}
						>
							{form.getFieldDecorator('invoiceNo3', {
								rules: [{ required: false }],
							})(
								<Input
									disabled={
										!(
											contract &&
											(form.getFieldValue('invoiceNo3') !== '' ||
												(contract !== undefined && contract.invoiceNo3 !== '') ||
												(expirationModified && form.getFieldValue('invoiceNo2') !== ''))
										)
									}
								/>
							)}
						</Form.Item>
					</div>

					<div>
						<Form.Item
							label={<FormattedMessage id="APP.Contract.InvoiceNo4" />}
							className={styles.one_third_field}
						>
							{form.getFieldDecorator('invoiceNo4', {
								rules: [{ required: false }],
							})(
								<Input
									disabled={
										!(
											contract &&
											(form.getFieldValue('invoiceNo4') !== '' ||
												(contract !== undefined && contract.invoiceNo4 !== '') ||
												(expirationModified && form.getFieldValue('invoiceNo3') !== ''))
										)
									}
								/>
							)}
						</Form.Item>
						<Form.Item
							label={<FormattedMessage id="APP.Contract.InvoiceNo5" />}
							className={styles.one_third_field}
						>
							{form.getFieldDecorator('invoiceNo5', {
								rules: [{ required: false }],
							})(
								<Input
									disabled={
										!(
											contract &&
											(form.getFieldValue('invoiceNo5') !== '' ||
												(contract !== undefined && contract.invoiceNo5 !== '') ||
												(expirationModified && form.getFieldValue('invoiceNo4') !== ''))
										)
									}
								/>
							)}
						</Form.Item>
						<Form.Item
							label={<FormattedMessage id="APP.Contract.InvoiceNo6" />}
							className={styles.one_third_field}
						>
							{form.getFieldDecorator('invoiceNo6', {
								rules: [{ required: false }],
							})(
								<Input
									disabled={
										!(
											contract &&
											(form.getFieldValue('invoiceNo6') !== '' ||
												(contract !== undefined && contract.invoiceNo6 !== '') ||
												(expirationModified && form.getFieldValue('invoiceNo5') !== ''))
										)
									}
								/>
							)}
						</Form.Item>
					</div>
				</div>
			}
			<div>
				<Form.Item label={<FormattedMessage id="BP (customer SAP number)" />} className={styles.half_field}>
					{form.getFieldDecorator('bp', {
						rules: [
							{
								required: false,
							},
						],
					})(<Input />)}
				</Form.Item>
				<Form.Item label={<FormattedMessage id="Remarks" />} className={styles.half_field}>
					{form.getFieldDecorator('remarks', {
						rules: [
							{
								required: false,
							},
						],
					})(<Input />)}
				</Form.Item>
			</div>
			<div>
				<Form.Item
					label={<FormattedMessage id="Proceq.FormAccountTypeBU" />}
					className={styles.one_third_field}
				>
					{form.getFieldDecorator('businessUnit', {
						rules: [{ required: true }],
					})(<Select globalRegister="buunits" />)}
				</Form.Item>
				<Form.Item label={<FormattedMessage id="Proceq.FormLanguage" />} className={styles.one_third_field}>
					{form.getFieldDecorator('language', {
						rules: [{ required: true }],
					})(<Select globalRegister="languagescontacttds" />)}
				</Form.Item>
				<Form.Item label={<FormattedMessage id="Country Sold To" />} className={styles.one_third_field}>
					{form.getFieldDecorator('countrySoldTo', {
						rules: [{ required: !contract }],
					})(<Select globalRegister="countries" />)}
				</Form.Item>
			</div>
			<Form.Item className={styles.submit}>
				<Button type="default" onClick={() => changeContract(null)}>
					<FormattedMessage id="Proceq.LogbookClose" />
				</Button>
				&nbsp;
				{contractModified && (
					<Button type="primary" htmlType="submit" loading={loading}>
						<FormattedMessage id="Proceq.ButtonSubmit" />
					</Button>
				)}
				{!contractModified && (
					<Button disabled type="primary" htmlType="submit" loading={loading}>
						<FormattedMessage id="Proceq.ButtonSubmit" />
					</Button>
				)}
			</Form.Item>
		</Form>
	);
};

export default compose(
	Form.create({
		mapPropsToFields: props => {
			const fields = {};
			[
				'isInternal',
				'contactEmail',
				'product',
				'tier',
				'dongleSN',
				'probeSN',
				'hardwareID',
				'expirationDate',
				'isCareRedeemed',
				'businessUnit',
				'language',
				'countrySoldTo',
				'bp',
				'remarks',
				'invoiceNo',
				'invoiceNo2',
				'invoiceNo3',
				'invoiceNo4',
				'invoiceNo5',
				'invoiceNo6',
			].forEach(k => {
				let value = get(props, ['contract', k]);
				if (k === 'expirationDate') {
					value = value ? moment(value) : null;
				}
				if (k === 'isCareRedeemed') {
					value = value === 1;
				} else if (k === 'isInternal') {
					value = getName(UserTypeOptions, Number(value));
				}
				fields[k] = Form.createFormField({ value });
			});
			return fields;
		},
	}),
	withState('loading', 'setLoading', false),
	withState('prodOptions', 'setProdOptions', []),
	withState('tiers', 'setTiers', []),
	withState('tierOptions', 'setTierOptions', []),
	withState('currProd', 'setCurrProd', ''),
	withState('userType', 'setUserType', ''),
	withState('prevContract', 'setPrevContract', ''),
	withState('contractModified', 'setContractModified', false),
	withState('expirationModified', 'setExpirationModified', false),
	connect(
		state => ({
			isAdmin: hasRoleLevel(state.session.scopes, ROLES.ADMIN),
			permissionToChangeProduct: isBUOrHigher(state.session.scopes),
		}),
		(dispatch: Function, props) => ({
			addContract: params => {
				if (Object.prototype.hasOwnProperty.call(params, 'isCareRedeemed')) {
					params.isCareRedeemed = params.isCareRedeemed === true ? 1 : 0;
				} else {
					params.isCareRedeemed = 2;
				}
				if (Object.prototype.hasOwnProperty.call(params, 'isInternal')) {
					params.isInternal = Number(params.isInternal);
				}
				dispatch(
					request({
						method: 'POST',
						url: params.createAsAdmin ? URL.CREATE_BLUETOOTH_CONTRACT_ADMIN : URL.CREATE_BLUETOOTH_CONTRACT,
						setLoading: props.setLoading,
						params: {
							...params,
							type: props.contractType,
						},
					})
				).then(response => {
					if (response.status === 'success') {
						props.changeContract(null);
						props.loadContracts();
					}
				});
			},
			editContract: (params, isAdmin) => {
				if (Object.prototype.hasOwnProperty.call(params, 'isCareRedeemed')) {
					params.isCareRedeemed = params.isCareRedeemed === true ? 1 : 0;
				} else {
					params.isCareRedeemed = 2;
				}
				if (Object.prototype.hasOwnProperty.call(params, 'isInternal')) {
					if (isNaN(Number(params.isInternal))) {
						params.isInternal = getValue(UserTypeOptions, params.isInternal);
					} else {
						params.isInternal = Number(params.isInternal);
					}
				}
				// case : change product from Schmidt/ZG to other; probeSN should be reset, if user is admin
				if (
					isAdmin &&
					params.probeSN === undefined &&
					(props.prevContract.product === 'SCHMIDT' ||
						props.prevContract.product === 'SSL' ||
						props.prevContract.product === 'GLM')
				) {
					params.probeSN = '';
				}

				if (isAdmin && params.hardwareID === undefined && props.prevContract.hardwareID !== undefined) {
					params.hardwareID = props.prevContract.hardwareID;
				}

				// Not send hardwareID if admin role doesn't want to create as admin
				if (isAdmin && !params.createAsAdmin) {
					params.hardwareID = '';
				}

				dispatch(
					request({
						method: 'PUT',
						url: (params.createAsAdmin || isAdmin
							? URL.UPDATE_BLUETOOTH_CONTRACT_ADMIN
							: URL.UPDATE_BLUETOOTH_CONTRACT
						).replace('{key}', props.contract.id),
						setLoading: props.setLoading,
						params: {
							...params,
							type: props.contractType,
						},
					})
				).then(response => {
					if (response === '') {
						props.changeContract(null);
						props.loadContracts();
					}
				});
			},
			loadTiers: params => {
				const prevSelectedTier =
					props.contract === undefined ? props.form.getFieldValue('tier') : props.contract.tier;
				dispatch(
					request({
						method: 'GET',
						url: URL.GET_TIERS,
						params: {
							start: 0,
							length: 200,
							'columns[0][data]': 'product',
							'columns[0][search][value]': `(^${params}$)`,
							'columns[0][search][searchable]': true,
							'columns[0][search][regex]': true,
						},
					})
				).then(data => {
					const options = [];
					data.data.map(t => {
						if (
							(t.enable === 1 || prevSelectedTier === t.tier) &&
							String(t.contractType) === props.contractType
						) {
							const obj: SelectOpts = {
								name: t.name,
								value: t.tier,
								disable: t.enable === 0,
							};
							options.push(obj);
						}
					});
					props.setTierOptions(options);
					props.setTiers(data.data);
				});
			},
		})
	),
	lifecycle({
		componentDidMount() {
			const options = [];
			const prodConnectionType = this.props.prodConnectionType;
			this.props.products.map(prod => {
				if (prod.connectionType === prodConnectionType) {
					const obj = {
						name: prod.name,
						value: prod.code,
					};
					options.push(obj);
				}
			});
			options.sort(productComparator('name'));
			this.props.setProdOptions(options);
			this.props.loadTiers(this.props.form.getFieldValue('product'));
			this.props.setContractModified(false);
			this.props.setExpirationModified(false);
			this.props.setPrevContract(this.props.contract);
		},
		componentDidUpdate(prevProps) {
			const compare = (o1, o2) => {
				return o1 === o2 || (o1 === undefined && o2 === '') || (o1 === '' && o2 === undefined);
			};
			const formExpiration =
				this.props === undefined || this.props.form === undefined || this.props.form == null
					? undefined
					: this.props.form.getFieldValue('expirationDate');
			const prevExpiration =
				this.props === undefined || this.props.prevContract === undefined
					? undefined
					: this.props.prevContract.expirationDate;

			if (
				prevExpiration !== undefined &&
				formExpiration !== undefined &&
				formExpiration !== null &&
				!compare(prevExpiration, formExpiration.format('YYYY-MM-DD'))
			) {
				this.props.setExpirationModified(true);
			} else {
				this.props.setExpirationModified(false);
			}

			if (
				this.props !== undefined &&
				this.props.prevContract !== undefined &&
				this.props.contract !== undefined &&
				compare(this.props.prevContract.invoiceNo, this.props.form.getFieldValue('invoiceNo')) &&
				compare(this.props.prevContract.invoiceNo2, this.props.form.getFieldValue('invoiceNo2')) &&
				compare(this.props.prevContract.invoiceNo3, this.props.form.getFieldValue('invoiceNo3')) &&
				compare(this.props.prevContract.invoiceNo4, this.props.form.getFieldValue('invoiceNo4')) &&
				compare(this.props.prevContract.invoiceNo5, this.props.form.getFieldValue('invoiceNo5')) &&
				compare(this.props.prevContract.invoiceNo6, this.props.form.getFieldValue('invoiceNo6')) &&
				compare(this.props.prevContract.contactEmail, this.props.form.getFieldValue('contactEmail')) &&
				compare(this.props.prevContract.businessUnit, this.props.form.getFieldValue('businessUnit')) &&
				compare(this.props.prevContract.product, this.props.form.getFieldValue('product')) &&
				compare(this.props.prevContract.tier, this.props.form.getFieldValue('tier')) &&
				compare(this.props.prevContract.dongleSN, this.props.form.getFieldValue('dongleSN')) &&
				compare(this.props.prevContract.probeSN, this.props.form.getFieldValue('probeSN')) &&
				compare(this.props.prevContract.language, this.props.form.getFieldValue('language')) &&
				compare(this.props.prevContract.countrySoldTo, this.props.form.getFieldValue('countrySoldTo')) &&
				compare(this.props.prevContract.bp, this.props.form.getFieldValue('bp')) && // Previous and current expiration dates are both empty / undefined
				compare(this.props.prevContract.remarks, this.props.form.getFieldValue('remarks')) &&
				compare(this.props.prevContract.hardwareID, this.props.form.getFieldValue('hardwareID')) &&
				(((formExpiration === null || formExpiration === undefined) &&
					(prevExpiration === null || prevExpiration === undefined || prevExpiration === '')) ||
					(formExpiration !== null &&
						this.props.form.getFieldValue('expirationDate') !== undefined &&
						compare(
							this.props.prevContract.expirationDate,
							this.props.form.getFieldValue('expirationDate').format('YYYY-MM-DD')
						))) &&
				(this.props.form.getFieldValue('isCareRedeemed')
					? this.props.prevContract.isCareRedeemed === 1
					: this.props.prevContract.isCareRedeemed !== 1) &&
				this.props.form.getFieldValue('isInternal') ===
					getName(UserTypeOptions, this.props.prevContract.isInternal)
			) {
				if (this.props.contractModified === true) {
					this.props.setContractModified(false);
				}
			} else if (this.props.contractModified === false) {
				this.props.setContractModified(true);
			}
			this.props.setUserType(this.props.form.getFieldValue('isInternal'));
			if (
				prevProps !== undefined &&
				this.props !== undefined &&
				this.props.form.getFieldValue('product') !== undefined
			) {
				if (
					(prevProps.currProd === '' && this.props.form.getFieldValue('product') !== '') ||
					prevProps.currProd !== this.props.form.getFieldValue('product')
				) {
					this.props.setCurrProd(this.props.form.getFieldValue('product'));
					this.props.loadTiers(this.props.form.getFieldValue('product'));
				} else if (
					this.props !== undefined &&
					this.props.tierOptions !== undefined &&
					this.props.contract !== undefined &&
					prevProps.contract !== undefined &&
					this.props.tierOptions.filter(to => to.value === this.props.contract.tier).length === 0 &&
					prevProps.currProd !== this.props.form.getFieldValue('product')
				) {
					this.props.loadTiers(this.props.form.getFieldValue('product'));
				}
			}
		},
	}),
	injectIntl
)(AddEditBluetoothContract);

function getPlaceholderForProbeSN(product) {
	if (product === 'GLM') {
		return 'ZG80-XXX-XXXX';
	}
	if (product === 'SCHMIDT') {
		return 'SL01-XXX-XXXX';
	}
	if (product === 'SSL') {
		return 'OS82-XXX-XXXX';
	}
	if (product === 'PS8000') {
		return 'PS80-XXX-XXXX';
	}
	if (product === 'RS8000') {
		return 'RS80-XXX-XXXX or RS81-XXX-XXXX';
	}
	if (product === ProductConstants.PI8000) {
		return 'PI80-XXX-XXXX';
	}
	if (product === ProductConstants.PM8000) {
		return 'PM80-XXX-XXXX';
	}
	if (product === ProductConstants.PM8500) {
		return 'PM85-XXX-XXXX';
	}
	return '';
}

function getErrorMsgForProbeSN(product) {
	if (product === 'GLM') {
		return 'App.GLMContractsSerialMismatchError';
	}
	if (product === 'SCHMIDT') {
		return 'App.SchmidtContractsSerialMismatchError';
	}
	if (product === 'SSL') {
		return 'App.SSLContractsSerialMismatchError';
	}
	if (product === 'PS8000') {
		return 'App.PS8000ContractsSerialMismatchError';
	}
	if (product === 'RS8000') {
		return 'App.RS8000ContractsSerialMismatchError';
	}
	if (product === ProductConstants.PI8000) {
		return 'App.PI8000ContractsSerialMismatchError';
	}
	if (product === ProductConstants.PM8000) {
		return 'App.PM8000ContractsSerialMismatchError';
	}
	if (product === ProductConstants.PM8500) {
		return 'App.PM8500ContractsSerialMismatchError';
	}
	return '';
}
