// @flow
import React from 'react';
import classNames from 'classnames';
import moment from 'moment';
import { find, debounce } from 'lodash';
import { connect } from 'react-redux';
import { compose, withState, lifecycle } from 'recompose';
import { FormattedMessage } from 'react-intl';
import { Layout, Modal, Button, Icon } from 'antd';
import URL from 'apis/urls';
import { hasRoleLevel, ROLES } from 'utils/rolesHelper';
import { getTextValue } from 'utils/registryHelper';
import { request } from 'actions/ajax';
import tableStyles from '../shared/SmarterTable.styl';
import { generateFilterQuery } from '../shared/SmarterTable';
import ContractsBaseTable from './ContractsBaseTable';
import AddEditFreeTrial from './AddEditFreeTrial';
import Legend from './Legend';
import { ContractType, productComparator, caseInsensitiveCompare, ProductConstants } from './contractConsts';
import { DATE_SERVER_FORMAT, highlightExpiringContractsOrLicenses } from '../../utils/dateHandler';
import type { ContractTypes } from './contractConsts';

const FreeTrialSaasProds = getTextValue(['freeTrialSaasProducts']).map(({ value }) => value.toUpperCase());

export const getDefaultProductFilters = (): Array<string> => {
	return FreeTrialSaasProds;
};

export const generateParams = (productFamily: ProductFamily, contractType: string) => {
	if (contractType === ContractType.Software) {
		return generateFilterQuery('product', FreeTrialSaasProds);
	}
	return {};
};

const gprInsightFreeTiers = [];
const emptyOptionsFilter = item => item.value !== undefined && item.value !== '';

const getExpirationDate = record => moment(record.expirationDate, DATE_SERVER_FORMAT);

export const FreeTrial = ({
	contracts,
	totalRecords,
	loadFreeTrialLicenses,
	loading,
	setParams,
	selectedRowKeys,
	onRowSelection,
	editedFreeTrialLicense,
	changeFreeTrialLicense,
	usersMap,
	products,
	loadProducts,
	connectionType,
	contractType,
	isAdmin,
	isBU,
	usersSearch,
	setUsersSearch,
	loadUsers,
	usersLoading,
}: {
	contracts: Contract[],
	totalRecords: number,
	loadFreeTrialLicenses: Function,
	loading: boolean,
	setParams: Function,
	selectedRowKeys: string[],
	onRowSelection: Function,
	editedFreeTrialLicense?: string,
	changeFreeTrialLicense: Function,
	usersMap: Object,
	products: Product[],
	loadProducts: Function,
	connectionType: string,
	contractType: ContractTypes,
	isAdmin: boolean,
	isBU: boolean,
	setUsersSearch: Function,
	usersSearch: User[],
	loadUsers: Function,
	usersLoading: boolean,
}) => {
	const userOption = ({ id, firstName, lastName, email }) => {
		return (
			<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
				<span style={{ alignSelf: 'flex-start', flex: 1 }}>
					{`(uID: ${id}) ${firstName || ''} ${lastName || ''}`}
				</span>
				<span>{`(${String(email)})`}</span>
			</div>
		);
	};
	const getProductName = productCode => {
		const selected = products.filter(p => {
			return caseInsensitiveCompare(p.code, productCode);
		});
		return selected.length > 0 ? selected[0].name : productCode;
	};
	return (
		<Layout>
			<Layout.Content>
				<Legend legendTextId={'App.LicensesLegendInsightsFreeTrial'} />
				<Button className={tableStyles.button} type="primary" loading={loading} onClick={loadFreeTrialLicenses}>
					<FormattedMessage id="Proceq.TableActionRefresh" />
				</Button>
				{(isAdmin || isBU) && (
					<Button className={tableStyles.button} type="primary" onClick={() => changeFreeTrialLicense(0)}>
						<FormattedMessage id="Add Free Trial" />
					</Button>
				)}

				<ContractsBaseTable
					rowKey="id"
					columns={[
						{
							title: 'Activation Date',
							dataIndex: 'activationDate',
							filter: 'activationDate',
							sorter: true,
						},
						{
							title: 'Expiration Date',
							dataIndex: 'expirationDate',
							filter: true,
							sorter: true,
						},
						{
							title: 'App.RoleBU',
							dataIndex: 'businessUnit',
							globalRegister: 'buunits',
							filter: true,
							multiSelect: true,
						},
						{
							title: 'Active User',
							dataIndex: 'activeUID',
							filter: true,
							filterSearch: {
								onSearch: loadUsers,
								onBlur: () => setUsersSearch([]),
								searchLoading: usersLoading,
								options: usersSearch,
								customOption: userOption,
							},
							render: (_, record) => {
								return (
									<div>
										<span>{record.activeUser}</span>
										&nbsp; &nbsp;
										{(isAdmin || isBU) && (
											<Button
												className={classNames(tableStyles.button, tableStyles.inline)}
												onClick={() => changeFreeTrialLicense(record.id)}
												data-e2e-contracts-edit-button
											>
												<Icon type="edit" />
											</Button>
										)}
									</div>
								);
							},
						},
						{
							title: 'App.Product',
							dataIndex: 'product',
							render: (text, record) => {
								text = getProductName(record.product);
								return (
									<div>
										<span>{text}</span>
									</div>
								);
							},
							filter: true,
							multiSelect: true,
							sorter: true,
							filterOps: getDefaultProductFilters().map(product => ({
								value: product,
								text: getProductName(product),
							})),
						},
						{
							title: 'Device ID',
							dataIndex: 'deviceID',
							filter: true,
							sorter: true,
						},
						{
							title: 'Generated By',
							dataIndex: 'uID',
							render: (_, record) => {
								return (
									<div>
										<span>{record.generatedBy}</span>
									</div>
								);
							},
							filter: true,
							filterSearch: {
								onSearch: loadUsers,
								onBlur: () => setUsersSearch([]),
								searchLoading: usersLoading,
								options: usersSearch,
								customOption: userOption,
							},
						},
					].filter(a => a !== null)}
					onRow={record =>
						highlightExpiringContractsOrLicenses(record, getExpirationDate(record), false, true)
					}
					dataSource={contracts}
					loadDataSource={loadFreeTrialLicenses}
					loading={loading}
					setParams={setParams}
					selectedRowKeys={selectedRowKeys}
					onRowSelection={onRowSelection}
					maxRowSelection={5}
					totalRecords={totalRecords}
					defaultFilters={{
						product: getDefaultProductFilters(),
						type: [contractType],
					}}
				/>
			</Layout.Content>

			{
				<Modal
					visible={editedFreeTrialLicense !== null}
					footer={null}
					onCancel={() => changeFreeTrialLicense(null)}
					destroyOnClose
					title={
						<FormattedMessage
							id={find(contracts, { id: editedFreeTrialLicense }) ? 'Edit Free Trial' : 'Add Free Trial'}
						/>
					}
				>
					<AddEditFreeTrial
						contract={find(contracts, { id: editedFreeTrialLicense })}
						changeFreeTrialLicense={changeFreeTrialLicense}
						loadFreeTrialLicenses={loadFreeTrialLicenses}
						products={products}
						loadProducts={loadProducts}
						prodConnectionType={connectionType}
						contractType={contractType}
						usersMap={usersMap}
					/>
				</Modal>
			}
		</Layout>
	);
};

export default compose(
	withState('selectedRowKeys', 'onRowSelection', []),
	withState('editedFreeTrialLicense', 'changeFreeTrialLicense', null),
	withState('showAlert', 'setAlert', ''),
	withState('loading', 'setLoading', true),
	withState('contracts', 'setContracts', []),
	withState('totalRecords', 'setTotalRecords', 0),
	withState('params', 'setParams'),
	withState('products', 'setProducts', []),
	withState('tiers', 'setTiers', []),
	withState('features', 'setFeatures', {}),
	withState('tierOps', 'setTierOps', []),
	withState('prodOps', 'setProdOps', []),
	withState('usersMap', 'setUsersMap', {}),
	withState('usersSearch', 'setUsersSearch', []),
	withState('usersLoading', 'setUsersLoading', false),

	connect(
		state => ({
			isAdmin: hasRoleLevel(state.session.scopes, ROLES.ADMIN),
			isBU: hasRoleLevel(state.session.scopes, ROLES.BU),
		}),
		(dispatch: Function, props) => ({
			loadFreeTrialLicenses: () => {
				dispatch(
					request({
						url: URL.GET_LICENSES,
						setLoading: props.setLoading,
						params: {
							free: true,
							...props.params,
						},
					})
				).then(data => {
					let serialCounter = props.params.start;
					const licenseList = data.data.map(entry => {
						serialCounter++;
						return { serialNo: serialCounter, ...entry };
					});
					props.setContracts(licenseList);
					props.setTotalRecords(data.recordsFiltered);

					// fetch users map
					const uIDs = [];
					data.data.forEach(freeTrail => {
						if (freeTrail.uID) uIDs.push(freeTrail.uID);
						if (freeTrail.activeUID) uIDs.push(freeTrail.activeUID);
					});
					const uniqueIds = [...new Set(uIDs)];
					if (uniqueIds.length > 0) {
						dispatch(
							request({
								url: URL.GET_USERS,
								params: {
									ids: uniqueIds,
								},
							})
						).then(user => {
							const result = {};
							for (const key in user.data) {
								result[user.data[key].id] = user.data[key].email;
							}
							props.setUsersMap(result);
						});
					}
				});
			},
			loadProducts: (callback: Function) => {
				dispatch(
					request({
						method: 'GET',
						url: URL.GET_PRODUCT,
						setLoading: props.setLoading,
						params: {
							connectionType: props.connectionType,
						},
					})
				).then(data => {
					const options = data
						.map(product => {
							if (
								props.contractType === ContractType.MaintenanceServices ||
								props.contractType === ContractType.Software ||
								props.connectionType === product.connectionType
							) {
								return { value: product.code, text: product.name };
							}
							return null;
						})
						.filter(Boolean);
					options.sort(productComparator('text'));
					props.setProdOps(options);
					props.setProducts(data, () => {
						if (typeof callback === 'function') {
							callback();
						}
					});
				});
			},
			loadTiers: () => {
				dispatch(
					request({
						method: 'GET',
						url: URL.GET_TIERS,
						setLoading: props.setLoading,
						params: {
							start: 0,
							length: 200,
						},
					})
				).then(data => {
					let tierOptions = [];
					if (data.data !== undefined && data.data.length > 0) {
						tierOptions = data.data
							.map(tier => {
								if (
									tier.free === 1 &&
									tier.product === ProductConstants.GPR_INSIGHTS &&
									tier.contractType === parseInt(props.contractType, 10)
								) {
									gprInsightFreeTiers.push(tier.tier);
									return { value: tier.tier, text: tier.name };
								}
								return { value: '', text: '' };
							})
							.filter(emptyOptionsFilter);
					}
					props.setTierOps(tierOptions);
					props.setTiers(data.data);
				});
			},
			loadUsers: debounce((value: string) => {
				if (value.trim().length < 1) {
					return;
				}
				props.setUsersLoading(true);
				dispatch(
					request({
						url: URL.GET_USERS,
						params: {
							q: value.trim(),
						},
					})
				).then(data => {
					const userList = data.data;
					userList.sort((u1, u2) => {
						return u1.email === u2.email ? 0 : u1.email > u2.email ? 1 : -1;
					});
					props.setUsersSearch(userList);
					props.setUsersLoading(false);
				});
			}, 500),
		})
	),
	connect(
		null
		// (dispatch: Function, props) => ({

		// })
	),
	lifecycle({
		componentDidMount() {
			this.props.setParams({
				start: 0,
				length: 20,
				...generateParams(this.props.productFamily, this.props.contractType),
				...generateFilterQuery('type', this.props.contractType, 1),
			});

			setTimeout(() => {
				const self = this;
				this.props.loadProducts(() => {
					self.props.loadFreeTrialLicenses();
					self.props.loadTiers();
				});
			});
		},
	})
)(FreeTrial);
