// @flow
import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { compose, withState, lifecycle } from 'recompose';
import { Select, Button, Alert } from 'antd';
import URL from 'apis/urls';
import { request } from 'actions/ajax';
import { FormattedMessage } from 'react-intl';

export const Share = ({
	type,
	handleChange,
	loading,
	link,
	buttonText,
	buttonDisabled,
	tokenValidityMinutes,
	share,
	copy,
}: {
	type?: 'html',
	tokenValidityMinutes: number,
	handleChange: Function,
	loading: boolean,
	link: string,
	buttonText: string,
	buttonDisabled: boolean,
	share: Function,
	copy: Function,
}) => (
	<div>
		<FormattedMessage id="Proceq.FormExpiresIn" />
		&nbsp;
		<Select defaultValue={tokenValidityMinutes} onChange={v => handleChange(v)} id="daysUrlShare">
			<Select.Option value={1 * 24 * 60}>{moment.duration(1, 'days').humanize()}</Select.Option>
			<Select.Option value={3 * 24 * 60}>{moment.duration(3, 'days').humanize()}</Select.Option>
			<Select.Option value={7 * 24 * 60}>{moment.duration(1, 'weeks').humanize()}</Select.Option>
			<Select.Option value={14 * 24 * 60}>{moment.duration(2, 'weeks').humanize()}</Select.Option>
			<Select.Option value={1} data-e2e-days-url-share-1 style={{ display: 'none' }} />
		</Select>
		&nbsp;
		<Button loading={loading} onClick={share} type="primary">
			<FormattedMessage id={type === 'html' ? 'App.ExportHTML' : 'Proceq.TitleShareByUrl'} />
		</Button>
		{link && !loading && (
			<div>
				<br />
				<FormattedMessage id={type === 'html' ? 'App.ExportHTMLSuccess' : 'App.ShareMeasurementsSuccess'} />
				<br />
				<Alert message={link} />
				<br />
				<Button type={buttonDisabled ? 'default' : 'primary'} disabled={buttonDisabled} onClick={copy}>
					<FormattedMessage id={buttonText} />
				</Button>
			</div>
		)}
	</div>
);

export default compose(
	withState('loading', 'setLoading', false),
	withState('link', 'setLink', ''),
	withState('buttonText', 'setButtonText', 'App.ShareMeasurementsCopyAction'),
	withState('buttonDisabled', 'setButtonDisabled', false),
	withState('tokenValidityMinutes', 'handleChange', 14 * 24 * 60),
	connect(null, (dispatch: Function, props) => ({
		share: () => {
			dispatch(
				request({
					method: 'POST',
					url: URL.SHARE_MEASUREMENTS,
					setLoading: props.setLoading,
					params: {
						mIDs: props.selected,
						type: props.type,
						scope: {
							groups: [],
							tokenValidityMinutes: props.tokenValidityMinutes,
							type: 2,
							users: [],
						},
					},
				})
			).then(data => {
				props.setLink(data.url);
			});
		},
		copy: () => {
			if (window.copyTextToClipboard(props.link)) {
				props.setButtonText('App.ShareMeasurementsCopySuccess');
				props.setButtonDisabled(true);
				setTimeout(() => {
					props.setButtonText('App.ShareMeasurementsCopyAction');
					props.setButtonDisabled(false);
				}, 3000);
			} else {
				props.setButtonText('App.ShareMeasurementsCopyFailed');
				props.setButtonDisabled(true);
			}
		},
	})),
	lifecycle({
		componentWillReceiveProps(nextProps) {
			if (this.props.selected !== nextProps.selected) {
				this.props.setLink('');
			}
		},
	})
)(Share);
