// @flow
import React from 'react';
import { find, get } from 'lodash';
import { connect } from 'react-redux';
import { compose, withState, lifecycle } from 'recompose';
import { Layout, Modal, Button, Menu, Dropdown, Icon } from 'antd';
import { FormattedMessage } from 'react-intl';
import URL from 'apis/urls';
import { getLabelFromGlobalDataKey } from 'utils/registryHelper';
import { hasRoleLevel, normalizeRolesForDisplay, ROLES } from 'utils/rolesHelper';
import { request } from 'actions/ajax';
import styles from '../shared/SmarterTable.styl';
import columnConfig from './columnConfig';
import SmarterTable from '../shared/SmarterTable';
import AddEditUser from './AddEditUser';
import Roles from './Roles';
import Verify from './Verify';
import AssumeUser from './AssumeUser';
import UnblockUser from './UnblockUser';
import TwoFAReset from './2FAReset';
import LoginAttemptsReset from './LoginAttemptsReset';
import ChangePassword from './ChangePassword';
import ExportUsers from './ExportUsers';
import SendEagleIdNotification from './SendEagleIdNotification';
import { history } from '../../routes';

export const Users = ({
	scopes,
	users,
	totalRecords,
	loading,
	setParams,
	selectedRowKeys,
	onRowSelection,
	editedUser,
	changeUser,
	loadUsers,
}: {
	scopes: UserRole[],
	users: User[],
	totalRecords: number,
	loading: boolean,
	setParams: Function,
	selectedRowKeys: string[],
	onRowSelection: Function,
	editedUser: boolean,
	changeUser: Function,
	loadUsers: Function,
	// deleteUsers: Function,
}) => {
	const isSuperAdmin = hasRoleLevel(scopes, ROLES.SUPER);
	const isAdmin = hasRoleLevel(scopes, ROLES.ADMIN);
	const isPO = hasRoleLevel(scopes, ROLES.PO);

	const render = (text, record) => {
		if (
			// only super can do anything to super
			(hasRoleLevel(record.rolesArray, ROLES.SUPER) && !isSuperAdmin) ||
			// to edit po, must be admin
			(hasRoleLevel(record.rolesArray, ROLES.PO) && !isAdmin) ||
			// to edit bu, must be PO
			(hasRoleLevel(record.rolesArray, ROLES.BU) && !isPO)
		) {
			return null;
		}

		// an admin can only login as another admin
		if (hasRoleLevel(record.rolesArray, ROLES.ADMIN) && !isSuperAdmin) {
			return <AssumeUser email={record.username} id={record.id} isButton />;
		}

		return (
			<Dropdown
				overlay={
					<Menu>
						<Menu.Item key="1">
							<Roles id={record.id} roles={record.rolesArray} loadUsers={loadUsers} />
						</Menu.Item>
						<Menu.Item key="2" onClick={() => changeUser(record.id)}>
							<FormattedMessage id="Proceq.TableActionEdit" />
						</Menu.Item>
						{isAdmin && record['2fa'] && (
							<Menu.Item key="3">
								<TwoFAReset id={record.id} loadUsers={loadUsers} />
							</Menu.Item>
						)}
						{isAdmin && (
							<Menu.Item key="3">
								<LoginAttemptsReset id={record.id} loadUsers={loadUsers} />
							</Menu.Item>
						)}
						{isAdmin && (
							<Menu.Item key="4">
								<ChangePassword id={record.id} />
							</Menu.Item>
						)}
						{record.emailVerified === 0 && (
							<Menu.Item key="5">
								<Verify id={record.id} loadUsers={loadUsers} />
							</Menu.Item>
						)}
						{isAdmin && (
							<Menu.Item key="6">
								<UnblockUser id={record.id} email={record.username} loadUsers={loadUsers} />
							</Menu.Item>
						)}
						{isAdmin && (
							<Menu.Item key="7">
								<AssumeUser email={record.username} id={record.id} />
							</Menu.Item>
						)}
					</Menu>
				}
			>
				<Button>
					<FormattedMessage id="Proceq.ButtonActions" /> <Icon type="down" />
				</Button>
			</Dropdown>
		);
	};

	const columns = [
		{
			title: 'Proceq.FormGroupUID',
			dataIndex: 'id',
			sorter: true,
			filter: 'id',
		},
		{
			title: 'Proceq.FormEmailGroupID',
			dataIndex: 'username',
			sorter: true,
			filter: 'username',
			checkAccess: isAdmin,
		},
		{
			title: 'Proceq.FormFirstName',
			dataIndex: 'firstName',
			sorter: true,
			checkAccess: isAdmin,
			filter: 'firstName',
		},
		{
			title: 'Proceq.FormLastName',
			dataIndex: 'lastName',
			sorter: true,
			checkAccess: isAdmin,
			filter: 'lastName',
		},
		{
			title: 'App.Users2FAEnabled',
			dataIndex: '2fa',
			sorter: true,
			globalRegister: 'enabled',
			filter: true,
			checkAccess: isAdmin,
			render: (twoFA: boolean) =>
				twoFA ? <FormattedMessage id="Proceq.Enabled" /> : <FormattedMessage id="Proceq.NotEnabled" />,
		},
		{
			title: 'App.UsersRoles',
			dataIndex: 'roles',
			globalRegister: 'rolestds',
			filter: true,
			multiSelect: true,
			sorter: true,
			render: (text, record) =>
				normalizeRolesForDisplay(record.rolesArray)
					.map(role => getLabelFromGlobalDataKey('rolestdsshort', role))
					.join(', '),
		},
		...(isAdmin
			? [
					{
						title: '\u00a0',
						// fixed: 'right',
						render,
					},
			  ]
			: []),
		...columnConfig,
	];

	return (
		<Layout>
			<Layout.Content>
				<Button className={styles.button} type="primary" loading={loading} onClick={() => loadUsers()}>
					<FormattedMessage id="Proceq.TableActionRefresh" />
				</Button>
				{isAdmin && (
					<Button className={styles.button} type="primary" onClick={() => changeUser(0)}>
						{<FormattedMessage id="App.UsersAdd" />}
					</Button>
				)}
				{/* {isAdmin && (
					<Popconfirm
						title={
							<FormattedMessage id="App.UsersDeletePrompt" values={{ length: selectedRowKeys.length }} />
						}
						onConfirm={deleteUsers}
						okText={<FormattedMessage id="Proceq.TextYes" />}
						cancelText={<FormattedMessage id="Proceq.TextNo" />}
					>
						<Button
							className={styles.button}
							type="danger"
							disabled={selectedRowKeys.length < 1 || selectedRowKeys.length > 5}
						>
							{<FormattedMessage id="App.UsersDelete" />}
						</Button>
					</Popconfirm>
				)} */}
				<ExportUsers />
				{isSuperAdmin && <SendEagleIdNotification />}
				<SmarterTable
					rowKey="id"
					columns={columns}
					dataSource={users.map(user => ({
						...user,
						disabled: hasRoleLevel(get(user, 'rolesArray'), ROLES.ADMIN) && !isSuperAdmin,
					}))}
					loadDataSource={loadUsers}
					loading={loading}
					selectedRowKeys={isAdmin ? selectedRowKeys : undefined}
					onRowSelection={onRowSelection}
					setParams={setParams}
					maxRowSelection={5}
					totalRecords={totalRecords}
				/>
			</Layout.Content>
			<Modal visible={editedUser !== null} footer={null} onCancel={() => changeUser(null)}>
				<AddEditUser user={find(users, { id: editedUser })} changeUser={changeUser} loadUsers={loadUsers} />
			</Modal>
		</Layout>
	);
};

export default compose(
	withState('selectedRowKeys', 'onRowSelection', []),
	withState('editedUser', 'changeUser', null),
	withState('loading', 'setLoading', true),
	withState('users', 'setUsers', []),
	withState('totalRecords', 'setTotalRecords', 0),
	withState('params', 'setParams', { start: 0, length: 20 }),
	connect(
		state => ({
			scopes: state.session.scopes,
		}),
		(dispatch: Function, props) => ({
			loadUsers: () => {
				dispatch(
					request({
						url: URL.GET_ACCOUNTS,
						setLoading: props.setLoading,
						params: props.params,
					})
				).then(data => {
					props.setUsers(
						data.data.map(user => {
							user.rolesArray = user.roles.split(',').filter(Boolean);
							return user;
						})
					);
					props.setTotalRecords(data.recordsFiltered);
				});
			},
		})
	),
	connect(null, (dispatch: Function, props) => ({
		deleteUsers: () => {
			dispatch(
				request({
					method: 'DELETE',
					url: URL.DELETE_ACCOUNT,
					setLoading: props.setLoading,
					params: {
						ids: props.selectedRowKeys,
					},
				})
			).then(() => {
				props.loadUsers();
				props.onRowSelection([]);
			});
		},
	})),
	lifecycle({
		componentDidMount() {
			this.props.loadUsers();
			if (!hasRoleLevel(this.props.scopes, ROLES.PO)) {
				history.goBack();
			}
		},
	})
)(Users);
