export const isProductOrTierDisabled = (productOrTierOptions, productOrTier, isProduct) => {
	let productOrTierDisabled = false;
	productOrTierOptions.forEach(productOrTierOption => {
		if (productOrTier.toLowerCase() === productOrTierOption.value.toLowerCase()) {
			if (isProduct) {
				if (productOrTierOption.tierEnabled) {
					productOrTierDisabled = false;
				} else {
					productOrTierDisabled = true;
				}
			} else if (productOrTierOption.disable) {
				productOrTierDisabled = true;
			} else {
				productOrTierDisabled = false;
			}
		}
	});
	return productOrTierDisabled;
};
