// @flow
/* eslint-disable react/no-danger */
import React, { Component } from 'react';
import { get, trim } from 'lodash';
import { compose, withState } from 'recompose';
import qs from 'qs';
import { connect } from 'react-redux';
import type { Location } from 'react-router';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Form, Input, Button, Icon, Tabs, Spin } from 'antd';
import type { FormProps } from 'antd/lib/form';
import type { InjectIntlProvidedProps } from 'react-intl';
import URL, { isLive } from 'apis/urls';
import { login, loginProceq, forgotPassword } from 'actions/login';
import handleFormatMessage from 'utils/handleFormatMessage';
import emailValidator from 'utils/emailValidator';
import { getProceqUrl, getLastProductLogin, saveLastProductLogin } from 'utils/sessionHelper';
import BorderlessButton from '../shared/BorderlessButton';
import Logo from '../img/LogoLive.png';
import LogoDev from '../img/LogoDev.png';
import SmartForm from '../shared/SmartForm';
import styles from './LoginForm.styl';
import './LoginForm.css';
import Languages from '../layout/Languages';

const TabPane = Tabs.TabPane;
const FormItem = Form.Item;

type Props = {
	login: Function,
	forgotPassword: Function,
	user: {
		isAuthenticating: boolean,
		authError: {
			authFailed: boolean,
			message: string,
		},
		resetPasswordEmailSent: boolean,
	},
	loginProceq: Function,
	form: FormProps,
	location: Location,
	loading: boolean,
	setLoading: Function,
	error: string,
	setError: Function,
} & InjectIntlProvidedProps;

export class LoginForm extends Component<
	Props,
	{
		productTab: ProductCode,
		activeTab: string,
		hideForgot: boolean,
	}
> {
	constructor(props: Props) {
		super(props);
		const { product } = qs.parse(trim(this.props.location.search, '?'));
		const lastProductSelected = getLastProductLogin() || product;
		this.state = {
			productTab: lastProductSelected || 'GPR',
			activeTab: 'login',
			hideForgot: false,
		};
	}

	onLoginSubmit = (e: any) => {
		e.preventDefault();
		saveLastProductLogin(this.state.productTab);

		const { email, password } = this.props.form.getFieldsValue(['email', 'password']);

		if (['EQUOTIP', 'SCHMIDT'].includes(this.state.productTab)) {
			/* set only_onetime to 0 if do not want to get redirected to the Equotip webseite */
			this.props.loginProceq({
				email,
				password,
				only_onetime: 0,
				product_login: this.state.productTab,
			});

			return;
		}
		this.props.setLoading(true);
		this.props.login({
			email,
			password,
			errorHandler: err => {
				this.props.setError(get(err, 'response.data.message'));
				this.props.setLoading(false);
			},
		});
	};

	onResetPasswordSubmit = (e: any) => {
		e.preventDefault();
		const { resetEmail } = this.props.form.getFieldsValue(['resetEmail']);
		this.props.forgotPassword(resetEmail, this.isTDSProduct());
		this.setState({
			hideForgot: true,
		});
	};

	onProductTabChanged = (key: ProductCode) =>
		this.setState({
			productTab: key,
		});

	render() {
		const { formatMessage } = this.props.intl;
		const { getFieldDecorator } = this.props.form;

		const registerFormInfo = this.isTDSProduct()
			? {
					titleText: '',
					titleIcon: '',
					apiEndpoint: URL.CREATE_ACCOUNT,
					apiTypeMethod: 'post',
					apiSendToken: false,
					hideFormOnSuccess: true,
					messageOnSuccess: 'Proceq.MessageAccountCreated',
					formClasses: 'register-account-form',
					fieldSets: [
						{
							title: null,
							fields: [
								{
									title: 'FormFirstName',
									name: 'firstName',
									type: 'input',
									styleClasses: 'force_half_and_inline',
									validation: {
										type: 'firstname',
										required: true,
									},
								},
								{
									title: 'FormLastName',
									name: 'lastName',
									type: 'input',
									styleClasses: 'force_half_and_inline',
									validation: {
										type: 'lastname',
										required: true,
									},
								},
								{
									title: 'FormEmail',
									name: 'username',
									type: 'input',
									styleClasses: 'force_half_and_inline',
									validation: {
										type: 'email',
										required: true,
									},
								},
								{
									title: 'FormPassword',
									name: 'password',
									type: 'password',
									styleClasses: 'force_half_and_inline',
									validation: {
										type: 'password',
										required: true,
									},
								},
								{
									title: 'FormCountry',
									type: 'select',
									name: 'countryCode',
									styleClasses: 'force_half_and_inline',
									allowedValues: {
										type: 'globalRegister',
										data: 'countries',
									},
									validation: {
										type: 'standard',
										required: true,
									},
								},
								{
									title: 'FormJobTitle',
									type: 'input',
									name: 'jobTitle',
									styleClasses: 'force_half_and_inline',
									validation: {
										type: 'standard',
										required: true,
									},
								},
								{
									title: 'FormCompanyName',
									type: 'input',
									name: 'companyName',
									styleClasses: 'force_half_and_inline',
									validation: {
										type: 'standard',
										required: true,
									},
								},
								{
									title: 'FormCompanySize',
									type: 'select',
									name: 'companySize',
									styleClasses: 'force_half_and_inline',
									allowedValues: {
										type: 'globalRegister',
										data: 'companysize',
									},
									validation: {
										type: 'standard',
										required: true,
									},
								},
								{
									title: 'FormIndustry',
									type: 'select',
									name: 'industry',
									styleClasses: 'force_half_and_inline',
									allowedValues: {
										type: 'globalRegister',
										data: 'industrytype',
									},
									validation: {
										type: 'standard',
										required: true,
									},
								},
								{
									title: 'FormCompanyWebsite',
									type: 'input',
									name: 'companyWebSite',
									styleClasses: 'force_half_and_inline',
									validation: {
										type: 'standard',
										required: true,
									},
								},
								{
									title: 'FormNewsletter',
									type: 'select',
									name: 'newsletter',
									styleClasses: 'force_half_and_inline',
									default: 0,
									allowedValues: {
										type: 'globalRegister',
										data: 'yesorno',
									},
									validation: {
										type: 'standard',
										returnAsInt: true,
									},
								},
								{
									title: (
										<span
											dangerouslySetInnerHTML={{
												__html: handleFormatMessage(
													'WebRegistrationPrivacyPolicy',
													formatMessage,
													['https://www.proceq.com/legal-privacy/']
												),
											}}
										/>
									),
									type: 'checkbox',
									name: 'privacy',
									styleClasses: 'force_full_field',
									validation: {
										type: 'mustbechecked',
										required: true,
									},
								},
							],
						},
					],
			  }
			: {
					titleText: '',
					titleIcon: '',
					apiEndpoint: getProceqUrl(null, URL.PROCEQ_CREATE_ACCOUNT),
					apiTypeMethod: 'post',
					apiSendToken: false,
					hideFormOnSuccess: true,
					messageOnSuccess: 'Proceq.MessageAccountCreated',
					formClasses: 'register-account-form',
					fieldSets: [
						{
							title: null,
							fields: [
								{
									title: 'FormFirstName',
									name: 'firstname',
									type: 'input',
									styleClasses: 'force_half_and_inline',
									validation: {
										type: 'firstname',
										required: true,
									},
								},
								{
									title: 'FormLastName',
									name: 'lastname',
									type: 'input',
									styleClasses: 'force_half_and_inline',
									validation: {
										type: 'lastname',
										required: true,
									},
								},
								{
									title: 'FormEmail',
									name: 'username',
									type: 'input',
									styleClasses: 'force_half_and_inline',
									validation: {
										type: 'email',
										required: true,
									},
								},
								{
									title: 'FormPassword',
									name: 'password',
									type: 'passwordnoencode',
									styleClasses: 'force_half_and_inline',
									validation: {
										type: 'password',
										required: true,
									},
								},
								{
									title: 'FormCountry',
									type: 'select',
									name: 'country',
									styleClasses: 'force_half_and_inline',
									allowedValues: {
										type: 'globalRegister',
										data: 'countries',
									},
									validation: {
										type: 'standard',
										required: true,
									},
								},
								{
									title: 'FormJobTitle',
									type: 'input',
									name: 'jobtitle',
									styleClasses: 'force_half_and_inline',
									validation: {
										type: 'standard',
										required: true,
									},
								},
								{
									title: 'FormCompanyName',
									type: 'input',
									name: 'companyname',
									styleClasses: 'force_half_and_inline',
									validation: {
										type: 'standard',
										required: true,
									},
								},
								{
									title: 'FormCompanySize',
									type: 'select',
									name: 'company_size',
									styleClasses: 'force_half_and_inline',
									allowedValues: {
										type: 'globalRegister',
										data: 'companysize',
									},
									validation: {
										type: 'standard',
										required: true,
									},
								},
								{
									title: 'FormIndustry',
									type: 'select',
									name: 'industry',
									styleClasses: 'force_half_and_inline',
									allowedValues: {
										type: 'globalRegister',
										data: 'industrytype',
									},
									validation: {
										type: 'standard',
										required: true,
									},
								},
								{
									title: 'FormCompanyWebsite',
									type: 'input',
									name: 'companywebsite',
									styleClasses: 'force_half_and_inline',
									validation: {
										type: 'standard',
										required: true,
									},
								},
								{
									title: 'FormNewsletter',
									type: 'select',
									name: 'newsletter',
									styleClasses: 'force_half_and_inline',
									default: 0,
									allowedValues: {
										type: 'globalRegister',
										data: 'yesorno',
									},
									defaultValue: '',
									validation: {
										type: 'standard',
										returnAsInt: true,
									},
								},
								{
									title: (
										<span
											dangerouslySetInnerHTML={{
												__html: handleFormatMessage(
													'WebRegistrationPrivacyPolicy',
													formatMessage,
													['https://www.proceq.com/legal-privacy/']
												),
											}}
										/>
									),
									type: 'checkbox',
									name: 'privacy',
									styleClasses: 'force_full_field',
									validation: {
										type: 'mustbechecked',
										required: true,
									},
								},
							],
						},
					],
			  };

		let formToShow = '';
		switch (this.state.activeTab) {
			case 'password':
				if (!this.state.hideForgot) {
					formToShow = (
						<Form className={styles.form} hideRequiredMark onSubmit={this.onResetPasswordSubmit}>
							<div className={styles.fields}>
								<p className={styles.form_label}>
									<FormattedMessage id="Proceq.FormEmailGroupID" />
								</p>
								<FormItem>
									{getFieldDecorator('resetEmail', {
										rules: [
											{
												required: true,
												message: formatMessage({
													id: 'Proceq.ForgotPasswordFormEmailMsg',
												}),
											},
											{
												validator: this.handleEmailValidation,
											},
										],
									})(
										<Input
											className={styles.input}
											type="email"
											placeholder={formatMessage({
												id: 'Proceq.FormEmailGroupID',
											})}
										/>
									)}
								</FormItem>
							</div>
							<div className={styles.button_container}>
								<BorderlessButton onClick={this.handleGoBack}>
									<FormattedMessage id="Proceq.LoginBackLogin" />
								</BorderlessButton>
								<Button className={styles.reset_btn} type="primary" htmlType="submit" size="large">
									<FormattedMessage id="Proceq.ForgotPasswordFormSendPassword" />
								</Button>
							</div>
						</Form>
					);
				} else {
					formToShow = (
						<div className={styles.goback_btn_container}>
							{(() => {
								this.renderError();
								if (this.props.user.resetPasswordEmailSent) {
									return (
										<div className={styles.alert}>
											<Icon type="check-circle" className={styles.alert_icon} />
											<h3>
												<FormattedMessage id="Proceq.ForgotPasswordFormEmailSentMessage" />
											</h3>
										</div>
									);
								}
								return <Spin />;
							})()}

							<BorderlessButton onClick={this.handleGoBack}>
								<FormattedMessage id="Proceq.LoginBackLogin" />
							</BorderlessButton>
						</div>
					);
				}
				break;
			case 'register':
				formToShow = (
					<div>
						<SmartForm ref="registerForm" {...registerFormInfo} />
						<div className={styles.productTabs}>
							<BorderlessButton onClick={this.handleGoBack}>
								<FormattedMessage id="Proceq.LoginBackLogin" />
							</BorderlessButton>
							<br />
						</div>
					</div>
				);
				break;
			default:
			case 'login':
				formToShow = (
					<Form className={styles.form} hideRequiredMark onSubmit={this.onLoginSubmit}>
						{this.renderError()}
						<div className={styles.fields}>
							<div className={styles.forgot_password}>
								<p className={styles.input_label}>
									<FormattedMessage id="Proceq.FormEmailGroupID" />
								</p>
								{
									<BorderlessButton onClick={this.handleSignUpMode}>
										<FormattedMessage id="Proceq.LoginNeedAccount" />
									</BorderlessButton>
								}
							</div>
							<FormItem>
								{getFieldDecorator('email', {
									rules: [
										{
											required: true,
											message: ' ',
										},
										{
											validator: this.handleEmailValidation,
										},
									],
								})(
									<Input
										className={styles.input}
										type="email"
										placeholder={formatMessage({
											id: 'Proceq.FormEmailGroupID',
										})}
										disabled={this.isLoading()}
									/>
								)}
							</FormItem>
						</div>
						<div className={styles.fields}>
							<div className={styles.forgot_password}>
								<p className={styles.input_label}>
									<FormattedMessage id="Proceq.FormPassword" />
								</p>
								{
									<BorderlessButton onClick={this.handlePasswordMode}>
										<FormattedMessage id="Proceq.TitleForgotPassword" />
									</BorderlessButton>
								}
							</div>
							<FormItem>
								{getFieldDecorator('password', {
									rules: [
										{
											required: true,
											message: ' ',
										},
									],
								})(
									<Input
										className={styles.input}
										type="password"
										placeholder={formatMessage({
											id: 'Proceq.FormPassword',
										})}
										disabled={this.isLoading()}
									/>
								)}
							</FormItem>
						</div>
						<div className={styles.options}>
							<div className={styles.action_field}>
								<div className={styles.button_container}>
									{this.isLoading() || this.props.loading
										? this.renderLoadingButton()
										: this.renderSubmitButton()}
								</div>
							</div>
						</div>
						<a href="/cookies">
							<FormattedMessage id="App.ManageCookiesLink" />
						</a>
					</Form>
				);
				break;
		}

		return (
			<div className={styles.login}>
				<div className={styles.form_wrapper}>
					<div className={styles.language_container}>
						<Languages />
					</div>
					<div className={styles.heading_wrapper}>
						<img src={isLive ? Logo : LogoDev} className={styles.logo} alt="logo" />
						<h1 className={styles.heading}>
							<p>
								<FormattedMessage id="Proceq.AppHeading" />
							</p>
						</h1>
						<p>
							<FormattedMessage id="Proceq.LoginFormSignInMsg" />
						</p>
					</div>
					{!isLive && <div className={styles.dev_banner}>{'DEVELOPMENT'}</div>}

					<Tabs
						className={styles.productTabs}
						defaultActiveKey={this.state.productTab}
						onChange={this.onProductTabChanged}
					>
						{/* <TabPane
							tab={
								<span>
									{'Equotip Live Leeb D'}
									<br />
									{'Equotip Live UCI'}
									<br />
								</span>
							}
							key="EQUOTIP"
						/> */}
						<TabPane
							tab={
								<span>
									{'Proceq GPR Live'}
									<br />
									{'Pundit Live Array'}
									<br />
									{isLive ? '\u00A0' : 'Original Schmidt Live'}
									<br />
									{isLive ? '\u00A0' : 'Equotip Live Leeb D'}
									<br />
									{isLive ? '\u00A0' : 'Equotip Live UCI'}
									<br />
								</span>
							}
							key="GPR"
						/>
					</Tabs>
					{formToShow}
				</div>
			</div>
		);
	}

	renderError = () => {
		// PQ
		if (this.props.user.authError.authFailed) {
			return (
				<div className={styles.error}>
					<FormattedMessage id={this.props.user.authError.message} />
				</div>
			);
		}

		// TDS
		if (this.props.error) {
			return (
				<div className={styles.error}>
					<FormattedMessage id={this.props.error} />
				</div>
			);
		}

		return null;
	};

	renderLoadingButton = () => (
		<Button className={styles.login_btn_loading} loading>
			<FormattedMessage id="Proceq.ButtonSignIn" />
		</Button>
	);

	renderSubmitButton = () => (
		<button className={styles.login_btn} type="submit">
			<FormattedMessage id="Proceq.ButtonSignIn" />
		</button>
	);

	isLoading = () => this.props.user.isAuthenticating;

	handlePasswordMode = () => {
		this.setState({ activeTab: 'password' });
	};

	handleSignUpMode = () => {
		this.setState({ activeTab: 'register' });
	};

	handleGoBack = () => {
		this.setState({ activeTab: 'login', hideForgot: false });
	};

	handleEmailValidation = (rule: string, value: mixed, callback: Function) => {
		const values = [value];
		if (value) emailValidator(rule, values, callback);
		else callback();
	};

	isTDSProduct = () => {
		if (this.state.productTab !== 'EQUOTIP' && this.state.productTab !== 'SCHMIDT') {
			return true;
		}
		return false;
	};
}

export default compose(
	connect(state => ({ user: state.user }), {
		login,
		loginProceq,
		forgotPassword,
	}),
	injectIntl,
	withState('loading', 'setLoading', false),
	withState('error', 'setError', ''),
	Form.create({})
)(LoginForm);
