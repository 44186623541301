// @flow
import React from 'react';
import { get } from 'lodash';
import { connect } from 'react-redux';
import { Layout } from 'antd';
import { compose, withState, lifecycle, withProps } from 'recompose';
import URL from 'apis/urls';
import { request } from 'actions/ajax';
import { hasRoleLevel, ROLES } from 'utils/rolesHelper';
import columnConfig from './customMaterialColumnConfig';
import MeasurementTable from './MeasurementTable';
import CustomMaterialChart from './CustomMaterialChart';
import { JSONTreeView } from './Debug/JSONTreeView';

const CustomMaterialsJSONTreeView = compose(
	withState('visible', 'setVisible', false),
	withProps(({ setVisible }) => ({
		openModal: () => setVisible(true),
		closeModal: () => setVisible(false),
	}))
)(JSONTreeView);

export const CustomMaterials = ({
	loading,
	product,
	isDev,
	customMaterials,
	totalRecords,
	params,
	loadCustomMaterials,
}: {
	loading: boolean,
	product: ProductCode,
	isDev: boolean,
	customMaterials: Object[],
	totalRecords: number,
	params: Object,
	loadCustomMaterials: Function,
}) => (
	<Layout>
		<Layout.Content>
			<MeasurementTable
				loading={loading}
				params={params}
				measurements={customMaterials}
				loadMeasurements={loadCustomMaterials}
				totalRecords={totalRecords}
				columnConfig={[
					{
						title: 'Proceq.TableDescription',
						dataIndex: 'content.name',
						sorter: true,
						render: (text, record) => (
							<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
								<span style={{ alignSelf: 'flex-start', flex: 1 }}>{text}</span>
								&nbsp; &nbsp;
								{isDev && <CustomMaterialsJSONTreeView loading={false} output={record} />}
							</div>
						),
					},
					...get(columnConfig, product, columnConfig.EQUOTIP),
				]}
				expandedRowRender={record => <CustomMaterialChart record={record} product={product} />}
			/>
		</Layout.Content>
	</Layout>
);

export default compose(
	withState('loading', 'setLoading', true),
	withState('customMaterials', 'setCustomMaterials', []),
	withState('totalRecords', 'setTotalRecords', 0),
	withState('params', 'setParams', { type: 'CUSTOM_MATERIAL', limit: 20 }),
	connect(
		state => ({
			product: state.session.productType,
			isDev: hasRoleLevel(state.session.scopes, ROLES.DEV),
		}),
		(dispatch: Function, props) => ({
			loadCustomMaterials: newParams => {
				const params = {
					...props.params,
					...newParams,
				};
				props.setParams(params);
				dispatch(
					request({
						url: URL.GET_GENERIC_MEASUREMENTS,
						setLoading: props.setLoading,
						params,
					})
				).then(data => {
					props.setCustomMaterials(data.data);
					props.setTotalRecords(data.totalRecords);
				});
			},
		})
	),
	lifecycle({
		componentDidMount() {
			this.props.loadCustomMaterials();
		},
		componentWillReceiveProps(nextProps) {
			if (this.props.product !== nextProps.product) {
				this.props.loadCustomMaterials();
			}
		},
	})
)(CustomMaterials);
