// @flow
import moment from 'moment';
import React, { useState, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Modal, Form, Input, Switch, Radio, Checkbox, DatePicker } from 'antd';
import { useNotificationDetailsContext } from './NotificationDetailsProvider';
import AppIcon from './AppIcon';
import { EnumDeliveryType } from './notificationTypes';
import type { PostNotificationParams } from './notificationTypes';
import { DATE_WITH_TIMEZONE_FORMAT } from '../../utils/dateHandler';
import styles from './NotificationForm.styl';

const allUserSegments = [
	'gpr',
	'gpr_insights',
	'gpr_soil',
	'schmidt',
	'pit_ie',
	'pundit',
	'profometer',
	'paperschmidt',
	'fdl',
	'inspect',
];

const userSegmentOptions = allUserSegments.map(product => ({
	label: <AppIcon product={product} size={24} />,
	value: product,
}));

function disabledDate(current: number) {
	return current && current < moment().startOf('day');
}

const NotificationForm = props => {
	const { form } = props;
	const {
		formVisible,
		setFormVisible,
		handleFetchNotifications,
		createNotification,
	} = useNotificationDetailsContext();
	const [sending, setSending] = useState(false);
	const title = <FormattedMessage id="Notification.AddNotification" />;

	const handleCreateNotification = useCallback(
		async (params: PostNotificationParams) => {
			await createNotification(params);
			handleFetchNotifications();
		},
		[createNotification, handleFetchNotifications]
	);

	const handleSubmit = event => {
		event.preventDefault();
		if (form) {
			form.validateFields(async (err, values) => {
				if (err) {
					return;
				}

				const params = {
					...values,
					push: !!values.push,
					deliveryDate: values.deliveryDate
						? values.deliveryDate
								.clone()
								.utc()
								.format()
						: undefined,
				};
				try {
					setSending(true);
					await handleCreateNotification(params);
					handleCloseModal();
				} finally {
					setSending(false);
				}
			});
		}
	};

	const handleCloseModal = useCallback(() => {
		setFormVisible(false);
	}, []);

	const currentMessage = form.getFieldValue('message') || '';
	return (
		<Modal title={title} visible={formVisible} onCancel={handleCloseModal} footer={null} destroyOnClose>
			<Form onSubmit={handleSubmit} disabled={sending}>
				<Form.Item label={<FormattedMessage id="Notification.Field.Title" />}>
					{form.getFieldDecorator('title', {
						rules: [{ required: true }],
					})(<Input />)}
				</Form.Item>
				<Form.Item label={<FormattedMessage id="Notification.Field.Message" />}>
					<p className={styles.description}>
						<FormattedMessage id="Notification.Field.Message.Description" />
					</p>
					{form.getFieldDecorator('message', {
						rules: [{ required: true }],
					})(<Input.TextArea rows={4} />)}
					<p className={styles.description} style={{ textAlign: 'right' }}>
						{currentMessage.length} <FormattedMessage id="App.Characters" />
					</p>
				</Form.Item>
				<Form.Item label={<FormattedMessage id="Notification.Field.UserSegments" />}>
					{form.getFieldDecorator('products', {
						rules: [{ required: true }],
					})(<Checkbox.Group options={userSegmentOptions} className={styles.checkbox_group} />)}
				</Form.Item>
				<Form.Item label={<FormattedMessage id="Notification.Field.DeliveryTime" />}>
					{form.getFieldDecorator('deliveryType', {
						rules: [{ required: true }],
					})(
						<Radio.Group className={styles.radio_group}>
							<Radio value={EnumDeliveryType.DeliveryTypeImmediately}>
								<FormattedMessage id="Notification.DeliveryTimeOption.Immediately" />
							</Radio>
							<Radio value={EnumDeliveryType.DeliveryTypeSendLater}>
								<FormattedMessage id="Notification.DeliveryTimeOption.SendLater" />
							</Radio>
						</Radio.Group>
					)}
				</Form.Item>
				{form.getFieldValue('deliveryType') === EnumDeliveryType.DeliveryTypeSendLater && (
					<Form.Item>
						{form.getFieldDecorator('deliveryDate', {
							rules: [{ required: true }],
						})(
							<DatePicker
								format={DATE_WITH_TIMEZONE_FORMAT}
								disabledDate={disabledDate}
								showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss Z') }}
								style={{ width: '100%' }}
							/>
						)}
						<p className={styles.description}>
							<FormattedMessage id="Notification.DeliveryTimeOption.SendLater.Description" />
						</p>
					</Form.Item>
				)}
				<Form.Item
					label={<FormattedMessage id="Notification.Field.PushNotification" />}
					className={styles.form_item_same_line}
				>
					{form.getFieldDecorator('push')(<Switch />)}
				</Form.Item>

				<div className={styles.form_footer}>
					<Button onClick={handleCloseModal}>
						<FormattedMessage id="App.AnalyticsRegionExportCancel" />
					</Button>

					<Button type="primary" htmlType="submit" disabled={sending} loading={sending}>
						<FormattedMessage id="Proceq.ButtonSubmit" />
					</Button>
				</div>
			</Form>
		</Modal>
	);
};

export default Form.create({})(NotificationForm);
