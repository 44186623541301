// @flow
import React from 'react';
import { FormattedMessage } from 'react-intl';

export const Result = ({ log }: EquotipLogbookEntryProps) => (
	<FormattedMessage
		id={
			log.content.result === 'success'
				? 'Proceq.LogbookResultVerificationPassed'
				: 'Proceq.LogbookResultVerificationFailed'
		}
	/>
);

export const color = (log: Log) => (log.content.result === 'success' ? 'green' : 'red');

export default Result;
