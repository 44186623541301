// @flow
import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withState, lifecycle, withProps } from 'recompose';
import { FormattedMessage } from 'react-intl';
import { Button, Spin } from 'antd';
import JSZip from 'jszip';
import { find, noop } from 'lodash';

import { request } from 'actions/ajax';
import URL from 'apis/urls';
import styles from './Attachment.styl';

export const Audio = ({
	loading,
	audio,
	filename,
	download = true,
}: {
	loading: boolean,
	audio?: string,
	filename: string,
	download?: boolean,
}) =>
	loading ? (
		<div className={styles.attachment_container}>
			<Spin className={styles.loading} />
		</div>
	) : (
		<div className={styles.attachment_container}>
			<audio controls controlsList="nodownload">
				<source src={audio} type="audio/mp4" />
				<FormattedMessage id="Proceq.BrowserNoSupportAudio" />
			</audio>
			{/* <span className={ styles.filename } title={ filename }>{ filename }</span> */}
			{filename && download && (
				<Button className={styles.audio_download} href={audio} download={filename} type="primary">
					<FormattedMessage id="Proceq.LogbookDownload" />
				</Button>
			)}
		</div>
	);

export default compose(
	withState('loading', 'setLoading', true),
	withState('audio', 'setAudio', null),
	withState('filename', 'setFilename', null),
	connect(null, (dispatch: Function, props) => ({
		fetchAudio: () => {
			dispatch(
				request({
					url: URL.DOWNLOAD_FILE,
					headers: {
						'Content-Type': 'application/zip',
					},
					options: {
						responseType: 'arraybuffer',
					},
					params: {
						mID: props.mID,
						fID: props.fID,
						aID: props.aID,
					},
					uninterruptable: true,
					errorHandler: () => {
						props.setLoading(false);
					},
				})
			)
				.then(data => JSZip.loadAsync(data))
				.then(zip => {
					const audio = find(zip.files, (file, fileName: string) => fileName.search(/\.(m4a|aac)/) > -1);
					if (audio) {
						return audio.async('blob').then(blob => ({
							filename: audio.name,
							audioURL: window.URL.createObjectURL(blob),
						}));
					}

					throw new Error('No .m4a or .aac file in archive.');
				})
				.then(({ filename, audioURL }) => {
					props.setAudio(audioURL);
					props.setFilename(filename);
					props.setLoading(false);
				})
				.catch(error => {
					console.log(error);
				});
		},
	})),
	withProps(({ mID, attachmentName }) =>
		process.env.HTML_EXPORT_VERSION
			? {
					loading: false,
					audio: `measurement_assets/${mID}/${attachmentName}`,
					filename: attachmentName,
					fetchAudio: noop,
			  }
			: {}
	),
	lifecycle({
		componentDidMount() {
			this.props.fetchAudio();
		},
	})
)(Audio);
