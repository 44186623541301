// @flow
import React from 'react';
import { connect } from 'react-redux';
import { compose, withState, lifecycle } from 'recompose';
import { flatten, noop } from 'lodash';
import { Layout, Button, Icon } from 'antd';
import { FormattedMessage } from 'react-intl';
import URL from 'apis/urls';
import { request } from 'actions/ajax';
import { getFileSizeFormatted } from 'utils/fileUtils';
import tableStyles from '../shared/SmarterTable.styl';
import styles from './Migration.styl';
import SmarterTable from '../shared/SmarterTable';
import { renderTime } from '../measurements/columnConfig';

const getRegionalURLs = (env = process.env.SERVER_TYPE) => {
	switch (env) {
		default:
		case 'dev':
			return {
				EU: 'pq-eu.tectusdreamlab.com',
				SG: 'pq-sg.tectusdreamlab.com',
				US: 'pq-us.tectusdreamlab.com',
				JP: 'pq-jp.tectusdreamlab.com',
				CN: 'pq-cn.tectusdreamlab.com',
			};

		case 'stg':
			return {
				EU: 'eu.livedev2.proceq.com',
				SG: 'sg.livedev2.proceq.com',
				JP: 'jp.livedev2.proceq.com',
				US: 'us.livedev2.proceq.com',
				CN: 'mgt.livedev2.proceq.cn.com',
			};

		case 'prod':
			return {
				EU: 'eu.live2.proceq.com',
				SG: 'sg.live2.proceq.com',
				JP: 'jp.live2.proceq.com',
				US: 'us.live2.proceq.com',
				CN: 'mgt.live2.proceq.cn.com',
			};
	}
};

const numberSorter = dataIndex => (a, b) => a[dataIndex] - b[dataIndex];
const stringSorter = dataIndex => (a, b) => a[dataIndex].localeCompare(b[dataIndex]);

const columnConfig = [
	{
		title: 'region',
		dataIndex: 'region',
		sortBy: 'string',
	},
	{
		title: 'productFamily',
		dataIndex: 'productFamily',
		globalRegister: 'availproductsfull',
		sortBy: 'string',
	},
	{
		title: 'Download',
		dataIndex: 'url',
		render: (url, record) => (
			<a href={url} download={record.fileName}>
				<Button type="primary">
					<Icon type="download" />
				</Button>
			</a>
		),
	},
	{
		title: 'packageStatus',
		dataIndex: 'packageStatus',
		sortBy: 'string',
	},
	{
		title: 'userStatus',
		dataIndex: 'userStatus',
		sortBy: 'string',
	},
	{
		title: 'size',
		dataIndex: 'size',
		sortBy: 'number',
		render: size => <span title={size}>{getFileSizeFormatted(size)}</span>,
	},
	{
		title: 'username',
		dataIndex: 'username',
		sortBy: 'string',
	},
	{
		title: 'id',
		dataIndex: 'id',
		sortBy: 'string',
	},
	{
		title: 'error',
		dataIndex: 'error',
		sortBy: 'string',
	},
	{
		title: 'created',
		dataIndex: 'created',
		render: renderTime,
		sortBy: 'string',
	},
	{
		title: 'updated',
		dataIndex: 'updated',
		render: renderTime,
		sortBy: 'string',
	},
	{
		title: 'deviceID',
		dataIndex: 'deviceID',
		sortBy: 'string',
	},
	{
		title: 'deviceModel',
		dataIndex: 'deviceModel',
		sortBy: 'string',
	},
	{
		title: 'osVersion',
		dataIndex: 'osVersion',
		sortBy: 'string',
	},
	{
		title: 'previousAppVersion',
		dataIndex: 'previousAppVersion',
		sortBy: 'string',
	},
	{
		title: 'fileName',
		dataIndex: 'fileName',
		sortBy: 'string',
	},
	{
		title: 'uploaderID',
		dataIndex: 'uploaderID',
		sortBy: 'number',
	},
	{
		title: 'newUserID',
		dataIndex: 'newUserID',
		sortBy: 'number',
	},
	{
		title: 'sha256',
		dataIndex: 'sha256',
		sortBy: 'string',
	},
];

export const Migration = ({
	loading,
	data,
	loadPackages,
}: {
	loading: boolean,
	data: Object[],
	loadPackages: Function,
}) => (
	<Layout>
		<Layout.Content>
			<Button className={tableStyles.button} type="primary" loading={loading} onClick={loadPackages}>
				<FormattedMessage id="Proceq.TableActionRefresh" />
			</Button>
			<SmarterTable
				rowKey="id"
				columns={columnConfig.map(config => {
					let sorter;
					if (config.sortBy === 'string') {
						sorter = stringSorter(config.dataIndex);
					} else if (config.sortBy === 'number') {
						sorter = numberSorter(config.dataIndex);
					}
					return {
						...config,
						sorter,
					};
				})}
				dataSource={data}
				loadDataSource={loadPackages}
				setParams={noop}
				loading={loading}
				totalRecords={data.length}
				onRow={record =>
					record.packageStatus === 'failed'
						? {
								className: styles.highlighted,
						  }
						: {}
				}
			/>
		</Layout.Content>
	</Layout>
);

export default compose(
	withState('loading', 'setLoading', true),
	withState('data', 'setData', []),
	connect(null, (dispatch: Function, props) => ({
		loadPackages: () => {
			props.setLoading(true);

			const promises = Object.values(getRegionalURLs()).map(url =>
				dispatch(
					request({
						url: URL.GET_PACKAGES.replace('{non_mngt_url}', url),
						params: {
							product: undefined,
						},
					})
				)
			);

			Promise.all(promises).then(data => {
				props.setData(
					flatten(
						data.map((rows, i) =>
							rows.map(row => ({
								...row,
								productFamily: row.productFamily.toUpperCase(),
								region: Object.keys(getRegionalURLs())[i],
							}))
						)
					)
				);
				props.setLoading(false);
			});
		},
	})),
	lifecycle({
		componentDidMount() {
			this.props.loadPackages();
		},
	})
)(Migration);
