// @flow
import React, { Fragment } from 'react';
import { Table } from 'antd';
import { has, get } from 'lodash';
import { FormattedMessage } from 'react-intl';
import { convertAndDisplay } from 'constants/conversionRates';
import ConvertedUnits from 'components/shared/ConvertedUnits';
import type { FDLMeasurementPresetsProps } from './MeasurementPresets';
import { IsInfinity, InfinitySymbol } from './FdlConstants';
import styles from '../../../HTMLExport.styl';

export const DGSSnapshot = ({
	setupFile,
	indicationInfo,
	number,
	showTitle,
	unit,
}: { setupFile: FDLSetupFile, indicationInfo: Object } & FDLMeasurementPresetsProps) => {
	const title = (
		<Fragment>
			<FormattedMessage id="Proceq.ExportFieldSettings" />
			{' ­­» '}
			<FormattedMessage id="App.HTML.FDL.MeasurementPresets.DgsSnapshot" />
			{' ­­» '}
			{number}
		</Fragment>
	);

	// For this section, the values are not saved in SI units in the measurement json.
	// However, the conversionRates expect it to be in SI. Thus, convert accordingly.
	// To check the unit of the saved parameter, refer : https://jira.screeningeagle.com/browse/FDL-958
	const dataSource = [
		{
			label: 'App.HTML.FDL.IndicationInfo.Attenuation',
			value: unit === 'metric' ? setupFile.dgsAttenuationCoeffCalib : setupFile.dgsAttenuationCoeffCalib * 25.4,
			unitId: 'FDL.setupFile.Attenuation',
			skipConversion: true,
		},
		{
			label: 'App.HTML.FDL.DgsSnapshot.G1DistanceToDefect',
			value: indicationInfo.defectDistance / 1000,
			unitId: 'FDL.indicationInfo.CalibratedDistance',
		},
		{
			label: 'App.HTML.FDL.DgsSnapshot.G1AmplitudeOfDefect',
			value: indicationInfo.defectAmplitude,
			unitId: 'FDL.indicationInfo.CalibratedAmplitude',
		},
		{
			label: 'App.HTML.FDL.DgsSnapshot.G1.Ers',
			value: IsInfinity(indicationInfo.defectErs) ? InfinitySymbol : indicationInfo.defectErs / 1000,
			unitId: 'FDL.indicationInfo.CalibratedDistance',
			skipConversion: IsInfinity(indicationInfo.defectErs),
		},
		{
			label: 'App.HTML.FDL.DgsSnapshot.G1DbToCurve',
			value: IsInfinity(indicationInfo.defectDbToCurve) ? InfinitySymbol : indicationInfo.defectDbToCurve,
			unitId: 'FDL.indicationInfo.CalibratedAmplitude',
			skipConversion: IsInfinity(indicationInfo.defectDbToCurve),
		},
		{
			label: 'App.HTML.FDL.DgsSnapshot.G2DistanceToDefect',
			value: indicationInfo.defect2Distance / 1000,
			unitId: 'FDL.indicationInfo.CalibratedDistance',
		},
		{
			label: 'App.HTML.FDL.DgsSnapshot.G2AmplitudeOfDefect',
			value: indicationInfo.defect2Amplitude,
			unitId: 'FDL.indicationInfo.CalibratedAmplitude',
		},
		{
			label: 'App.HTML.FDL.DgsSnapshot.G2.Ers',
			value: IsInfinity(indicationInfo.defect2Ers) ? InfinitySymbol : indicationInfo.defect2Ers / 1000,
			unitId: 'FDL.indicationInfo.CalibratedDistance',
			skipConversion: IsInfinity(indicationInfo.defect2Ers),
		},
		{
			label: 'App.HTML.FDL.DgsSnapshot.G2DbToCurve',
			value: IsInfinity(indicationInfo.defect2DbToCurve) ? InfinitySymbol : indicationInfo.defect2DbToCurve,
			unitId: 'FDL.indicationInfo.CalibratedAmplitude',
			skipConversion: IsInfinity(indicationInfo.defect2DbToCurve),
		},
	].map((row, key) => {
		let label = <FormattedMessage id={row.label} />;
		let value = row.value;
		const unitToUse = has(setupFile, 'unit') ? get(setupFile, 'unit') : unit;

		if (row.unitId) {
			const { convertFunction } = convertAndDisplay(row.unitId, 'Indication', unitToUse);
			label = <ConvertedUnits id={row.label} unitId={row.unitId} scanType="Indication" isMetric={unitToUse} />;
			if (!row.skipConversion && typeof row.value !== 'string') {
				value = convertFunction(row.value);
			}
		}

		return {
			key,
			label,
			value,
		};
	});

	return (
		<Table
			title={showTitle ? () => <span className={styles.sub_header}>{title}</span> : null}
			className={styles.table}
			dataSource={dataSource}
			pagination={false}
			showHeader={false}
			size="small"
		>
			<Table.Column dataIndex="label" className={styles.column} />
			<Table.Column dataIndex="value" />
		</Table>
	);
};

export default DGSSnapshot;
