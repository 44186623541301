// @flow
import { httpRequest } from 'apis/httpClient';
import { checkStatusJson } from 'apis/restUtils';
import URL from 'apis/urls';
import { getProceqUrl } from 'utils/sessionHelper';
import actionTypes from './actionTypes';

export function logoutSuccess() {
	return { type: actionTypes.LOGOUT_SUCCESS };
}

export function finishUserSession() {
	return { type: actionTypes.FINISH_USER_SESSION };
}

export function logout() {
	return (dispatch: Function, getState: Function) =>
		httpRequest(
			getState().session,
			getState().locale,
			getState().session.sessionType !== 'PROCEQ'
				? URL.SIGN_OUT
				: getProceqUrl(getState().session, URL.PROCEQ_SIGN_OUT),
			getState().session.sessionType !== 'PROCEQ' ? 'get' : 'post',
			true
		).then(checkStatusJson);
}
