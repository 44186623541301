// @flow
import React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { Divider } from 'antd';
import { FormattedMessage } from 'react-intl';
import { hasRoleLevel, ROLES } from 'utils/rolesHelper';
import Versions from './Versions';
import styles from './Layout.styl';

export const Footer = ({ collapsed, session }: { collapsed: boolean, session: Session }) =>
	!collapsed && hasRoleLevel(session.scopes, ROLES.DEV) ? (
		<div className={styles.sidebar_footer}>
			<Divider className={styles.sidebar_footer}>
				<FormattedMessage id="App.SidebarVersion" />
			</Divider>
			<div style={{ padding: 5 }}>
				{process.env.GIT_TAG_NAME ? `${process.env.GIT_TAG_NAME}` : 'Local Development'}
				<Versions />
			</div>
			{process.env.GIT_COMMIT && <div>{`Hash: ${process.env.GIT_COMMIT.slice(0, 7)}`}</div>}
		</div>
	) : null;

export default compose(
	connect(state => ({
		session: state.session,
	}))
)(Footer);
