// @flow
import React from 'react';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import { DATE_LOGBOOK_FORMAT } from 'utils/dateHandler';
import styles from './UserText.styl';

export const UserText = ({ log }: GPRLogbookEntryProps) =>
	log.content.deletedAt ? (
		<div className={styles.deleted}>
			<FormattedMessage
				id="App.LogbookTextDeleted"
				values={{
					date: moment(log.content.deletedAt).format(DATE_LOGBOOK_FORMAT),
				}}
			/>
		</div>
	) : (
		<div>
			<b>
				<FormattedMessage id="App.LogbookTextAdded" />
			</b>
			<div>{log.content.comment}</div>
		</div>
	);

export const icon = (log: Log) => (log.content.deletedAt ? 'log_deleted' : 'log');

export default UserText;
