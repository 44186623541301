// @flow
import type { InjectIntlProvidedProps } from 'react-intl';
import { injectIntl } from 'react-intl';
import handleFormatMessage from 'utils/handleFormatMessage';

export const ConcreteAge = ({ log, intl }: EquotipLogbookEntryProps & InjectIntlProvidedProps) =>
	handleFormatMessage('Proceq.LogbookPropertyConcreteAgeChangedFromTo', intl.formatMessage, [
		log.content.oldAge,
		log.content.updatedAge,
	]);

export default injectIntl(ConcreteAge);
