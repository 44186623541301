import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'antd';
import styles from './BorderlessButton.styl';

class BorderlessButton extends Component {
	static propTypes = {
		children: PropTypes.element.isRequired,
		icon: PropTypes.string,
		type: PropTypes.string,
		onClick: PropTypes.func,
		disabled: PropTypes.bool,
	};

	render() {
		const { icon, disabled, type } = this.props;
		let buttonStyle = styles.default;

		if (type === 'danger') buttonStyle = styles.danger;
		if (disabled) buttonStyle = styles.disabled;
		return (
			<p icon="reload" role="button" className={buttonStyle} onClick={this.handleClick.bind(this)}>
				{icon && <Icon style={{ marginRight: '4px' }} type={icon} />}
				{this.props.children}
			</p>
		);
	}

	handleClick(...args) {
		if (!this.props.disabled) this.props.onClick(...args);
	}
}

export default BorderlessButton;
