// @flow
import React from 'react';
import { uniq } from 'lodash';
import { compose, withState, lifecycle } from 'recompose';
import { connect } from 'react-redux';
import { Spin } from 'antd';
import { request } from 'actions/ajax';
import URL from 'apis/urls';
import App from './App';

export const ViewRoute = ({
	loading,
	lang,
	measurement,
	users,
}: {
	loading: boolean,
	lang: LangKey,
	measurement: MeasurementFullData,
	users: User[],
}) =>
	loading ? (
		<Spin />
	) : (
		<App measurement={measurement} users={users} version={process.env.GIT_TAG_NAME || ''} shortlang={lang} />
	);

export default compose(
	withState('loading', 'setLoading', true),
	withState('users', 'setUsers', []),
	withState('measurement', 'setMeasurement'),
	connect(
		state => ({
			lang: state.locale.lang,
		}),
		(dispatch: Function, props) => ({
			loadUsers: ids =>
				dispatch(
					request({
						method: 'POST',
						url: URL.GET_SHARED_PROFILES,
						params: {
							ids,
						},
					})
				).then(data => {
					props.setUsers(data);
					props.setLoading(false);
				}),
		})
	),
	connect(null, (dispatch: Function, props) => ({
		loadMeasurement: () =>
			dispatch(
				request({
					url: URL.MEASUREMENTS + props.match.params.id,
				})
			).then(data => {
				props.setMeasurement(data);
				props.loadUsers(uniq(data.logs.map(log => log.uID)));
			}),
	})),
	lifecycle({
		componentDidMount() {
			this.props.loadMeasurement();
		},
	})
)(ViewRoute);
