// @flow
export type MeasurementFolderState = {
	activeSort: string,
	sortDirection: string,
	folders: Folder[],
};

export const sortMeasurementFolder = (folders: Folder[], sort: string, direction: string): Folder[] => {
	let sorted = [];
	switch (sort) {
		case 'name':
			sorted = folders.sort((a, b) => a.name.localeCompare(b.name));
			if (direction === 'desc') {
				sorted = sorted.reverse();
			}
			return sorted;
		case 'created':
			sorted = folders.sort((a, b) => a.clientCreated - b.clientCreated);
			if (direction === 'desc') {
				sorted = sorted.reverse();
			}
			return sorted;
		default:
			return sorted;
	}
};
