// @flow
import React, { Fragment } from 'react';
import { findIndex } from 'lodash';
import moment from 'moment';
import axios from 'axios';
import { connect } from 'react-redux';
import { compose, withStateHandlers } from 'recompose';
import { Modal, Button, Icon, Table, message } from 'antd';
import { FormattedMessage, injectIntl } from 'react-intl';
import URL from 'apis/urls';
import { request } from 'actions/ajax';
import { DATE_SERVER_FORMAT } from 'utils/dateHandler';

export const ExportStats = ({
	progress,
	cancel,
	params,
}: {
	progress: {
		product: ProductCode,
		region: Region,
		status: 'loading' | 'finished' | 'error' | 'done',
	}[],
	cancel: Function,
	params: {
		from: UnixMicroseconds,
		to: UnixMicroseconds,
		product: ProductCode[],
		region: Region[],
	},
	/* disabled: boolean,
	exportCSV: Function, */
}) => (
	<Fragment>
		{/* LC-1166 Remove "Export stats as CSV (GPR/Pundit only)" button
		<Button
			className={styles.export}
			type="primary"
			disabled={difference(params.product, ['GPR', 'PUNDIT']).length > 0 || disabled}
			onClick={exportCSV}
		>
			<FormattedMessage id="App.AnalyticsRegionExport" />
		</Button> */}
		<Modal
			centered
			visible={progress.length > 0}
			footer={
				progress.reduce((result, current) => result && current.status === 'done', true) ? (
					<Button onClick={cancel} type="primary">
						<FormattedMessage id="App.AnalyticsRegionExportOk" />
					</Button>
				) : (
					<Button onClick={cancel}>
						<FormattedMessage id="App.AnalyticsRegionExportCancel" />
					</Button>
				)
			}
			onCancel={cancel}
		>
			<div>
				<FormattedMessage
					id="App.AnalyticsRegionExportDateParameter"
					values={{
						from: moment.unix(params.from).format(DATE_SERVER_FORMAT),
						to: moment.unix(params.to).format(DATE_SERVER_FORMAT),
						duration: moment.duration(moment.unix(params.from).diff(moment.unix(params.to))).humanize(),
					}}
				/>
				<Table
					dataSource={progress.map(data => ({
						key: `${data.region}.${data.region}`,
						...data,
					}))}
					pagination={false}
				>
					<Table.Column dataIndex="product" title={<FormattedMessage id="App.Product" />} />
					<Table.Column dataIndex="region" title={<FormattedMessage id="App.AnalyticsRegion" />} />
					<Table.Column
						dataIndex="status"
						title={<FormattedMessage id="App.AnalyticsRegionExportProgress" />}
						align="right"
						render={status => {
							switch (status) {
								case 'done':
									return <Icon type="check" />;
								case 'error':
									return <Icon type="close" />;
								case 'loading':
								default:
									return <Icon type="loading" />;
							}
						}}
					/>
				</Table>
			</div>
		</Modal>
	</Fragment>
);

export default compose(
	injectIntl,
	withStateHandlers(
		{
			progress: [],
			cancelSource: axios.CancelToken.source(),
		},
		{
			start: ({ progress }) => (product, region) => {
				const updatedProgress = progress.slice();
				const index = findIndex(updatedProgress, { product, region });
				if (index !== -1) {
					updatedProgress[index].status = 'loading';
				} else {
					updatedProgress.push({
						region,
						product,
						status: 'loading',
					});
				}
				return {
					progress: updatedProgress,
				};
			},
			end: ({ progress }) => (product, region) => {
				const updatedProgress = progress.slice();
				const index = findIndex(updatedProgress, { product, region });
				if (index !== -1) {
					updatedProgress[index].status = 'done';
				} else {
					updatedProgress.push({
						region,
						product,
						status: 'done',
					});
				}
				return {
					progress: updatedProgress,
				};
			},
			error: ({ progress }) => (product, region) => {
				const updatedProgress = progress.slice();
				const index = findIndex(updatedProgress, { product, region });
				if (index !== -1) {
					updatedProgress[index].status = 'error';
				} else {
					updatedProgress.push({
						region,
						product,
						status: 'error',
					});
				}
				return {
					progress: updatedProgress,
				};
			},
			cancel: ({ cancelSource }, { intl }) => () => {
				cancelSource.cancel();
				message.error(intl.formatMessage({ id: 'App.AnalyticsRegionExportCancelled' }));
				return { progress: [], cancelSource: axios.CancelToken.source() };
			},
		}
	),
	connect(null, (dispatch: Function, props) => ({
		exportCSV: () => {
			const params = props.params;
			const promises = [];
			params.product.forEach(product => {
				params.region.forEach(region => {
					props.start(product, region);
					const promise = dispatch(
						request({
							method: 'POST',
							url: URL.REGIONAL_ANALYTICS,
							params: {
								...params,
								product,
								region,
							},
							options: {
								cancelToken: props.cancelSource.token,
							},
							returnResponseHeaders: true,
						})
					).then(response => {
						window.fileDownload(
							response.data,
							(response.headers['content-disposition'] || '').split('"')[1],
							response.headers['content-type']
						);
						props.end(product, region);
						return Promise.resolve();
					});
					promises.push(promise);
				});
			});
			Promise.all(promises).then(props.cancel);
		},
	}))
)(ExportStats);
