// @flow
import React, { Component } from 'react';
import { Modal, Button, Switch } from 'antd';
import type { ColumnProps } from 'antd';
import { connect } from 'react-redux';
import { compose, withStateHandlers, withState } from 'recompose';
import { FormattedMessage } from 'react-intl';
import { hasRoleLevel, ROLES } from 'utils/rolesHelper';
import Share from './Share';
import styles from './MeasurementTable.styl';
import tableStyles from '../shared/SmarterTable.styl';
import Download from './Download';
import DownloadWithURL from './DownloadWithURL';
import ExportCSVReports from './ExportCSVReports';
import ExportPDFReports from './ExportPDFReports';
import ExportAsJson from './ExportAsJson';
import MeasurementBaseTable from './MeasurementBaseTable';

export class MeasurementTable extends Component<{
	params: Object,
	fileType: MeasurementFileType,
	loading: boolean,
	setParams: Function,
	measurements: Measurement[],
	loadMeasurements: Function,
	totalRecords: number,
	columnConfig: ColumnProps,
	expandedRowRender?: Function,
	product: ProductCode,
	isDev: boolean,
	isPo: boolean,
	isAdmin: boolean,
	isSuper: boolean,
	component: string,
	setComponent: Function,
	selectedRowKeys: string[],
	onRowSelection: Function,
	selectedMeasurement: Measurement,
	setMeasurement: Function,
	closeModal: Function,
}> {
	share = (component: string) => () => {
		this.props.setMeasurement(true);
		this.props.setComponent(component);
	};

	refresh = () => this.props.loadMeasurements();

	debug = (checked: boolean) => {
		this.props.loadMeasurements({ withUnSynced: checked });
	};

	showHaze = (checked: boolean) => {
		this.props.loadMeasurements({ showHaze: checked });
	};

	render() {
		const {
			fileType,
			loading,
			setParams,
			measurements,
			loadMeasurements,
			totalRecords,
			columnConfig,
			expandedRowRender,
			product,
			isDev,
			isAdmin,
			isSuper,
			isPo,
			component,
			selectedRowKeys,
			onRowSelection,
			selectedMeasurement,
			closeModal,
		} = this.props;

		const isBluetoothFamily = ['SCHMIDT', 'EQUOTIP', 'GLM'].includes(product);
		const displayDownload = ['GPR', 'GPR_SOIL', 'PUNDIT', 'FDL', 'GLM', 'PIT_IE'].includes(product);
		const enableJsonDownload = isSuper || isAdmin || isDev || isPo;
		let title = '';
		let width;
		switch (component) {
			case 'Share':
				title = 'Proceq.TitleShareByUrl';
				break;
			case 'HTML':
				title = 'App.ExportHTML';
				break;
			default:
				break;
		}

		return (
			<div>
				<div className={styles.buttons_container}>
					<Button type="primary" onClick={this.refresh} loading={loading} className={tableStyles.button}>
						<FormattedMessage id="Proceq.TableActionRefresh" />
					</Button>
					{fileType && (isPo || isDev) && (
						<Button
							type="primary"
							disabled={selectedRowKeys.length < 1}
							onClick={this.share('Share')}
							className={tableStyles.button}
						>
							<FormattedMessage id="Proceq.TitleShareByUrl" />
						</Button>
					)}
					{fileType && isAdmin && (
						<Button
							type="primary"
							disabled={selectedRowKeys.length < 1}
							onClick={this.share('HTML')}
							className={tableStyles.button}
						>
							<FormattedMessage id="App.ExportHTML" />
						</Button>
					)}

					{displayDownload && (
						<Download product={product} iDs={selectedRowKeys} enableJsonDownload={enableJsonDownload} />
					)}
					{!displayDownload && enableJsonDownload && (
						<ExportAsJson iDs={selectedRowKeys} fileType={fileType} />
					)}

					{['GPR_SOIL', 'GPR'].includes(product) && isSuper && (
						<DownloadWithURL product={product} iDs={selectedRowKeys} />
					)}
					{isBluetoothFamily && this.props.params.type !== 'CUSTOM_MATERIAL' && (
						<ExportPDFReports iDs={selectedRowKeys} fileType={fileType} product={product} />
					)}
					{isBluetoothFamily && this.props.params.type !== 'CUSTOM_MATERIAL' && (
						<ExportCSVReports iDs={selectedRowKeys} fileType={fileType} />
					)}
					{product === 'GLM' && loadMeasurements && (
						<div className={tableStyles.button} data-e2e-debug-unsynced-switch>
							<Switch defaultChecked onChange={this.showHaze} /> <FormattedMessage id="App.ShowHaze" />
						</div>
					)}
					{isDev && loadMeasurements && (
						<div className={tableStyles.button} data-e2e-debug-unsynced-switch>
							<Switch onChange={this.debug} />{' '}
							<FormattedMessage id="App.MeasurementDebugUnsyncedMeasurements" />
						</div>
					)}
				</div>
				<MeasurementBaseTable
					rowKey="id"
					columns={columnConfig}
					dataSource={measurements}
					loadDataSource={loadMeasurements}
					loading={loading}
					selectedRowKeys={selectedRowKeys}
					onRowSelection={onRowSelection}
					expandedRowRender={expandedRowRender}
					totalRecords={totalRecords}
					setParams={setParams}
					fileType={fileType}
				/>
				<Modal
					visible={!!selectedMeasurement}
					onOk={closeModal}
					onCancel={closeModal}
					title={<FormattedMessage id={title} />}
					footer={[
						<Button key="back" size="large" onClick={closeModal}>
							<FormattedMessage id="Proceq.LogbookClose" />
						</Button>,
					]}
					width={width}
				>
					{component === 'Share' && !!selectedRowKeys && <Share selected={selectedRowKeys} />}
					{component === 'HTML' && !!selectedRowKeys && <Share type="html" selected={selectedRowKeys} />}
				</Modal>
			</div>
		);
	}
}

export default compose(
	connect(state => ({
		product: state.session.productType,
		isDev: hasRoleLevel(state.session.scopes, ROLES.DEV),
		isAdmin: hasRoleLevel(state.session.scopes, ROLES.ADMIN),
		isSuper: hasRoleLevel(state.session.scopes, ROLES.SUPER),
		isPo: hasRoleLevel(state.session.scopes, ROLES.PO),
	})),
	withState('component', 'setComponent', null),
	withState('selectedRowKeys', 'onRowSelection', []),
	withStateHandlers(
		{
			selectedMeasurement: null,
		},
		{
			setMeasurement: () => selectedMeasurement => ({
				selectedMeasurement,
			}),
			closeModal: () => () => ({ selectedMeasurement: null }),
		}
	)
)(MeasurementTable);
