module.exports = {
	shortlang: 'jp',
	lang: 'ja',
	messages: {
		'Proceq.ErrorWrongUsername': '入力された電子メールが無効です',
		'Proceq.ErrorCreationNotAllowed': '新規アカウントの作成が無効化されました',
		'Proceq.ErrorWrongPassword': '入力されたパスワードが無効です',
		'Proceq.ErrorWrongFirstName': '入力された名が無効です',
		'Proceq.ErrorWrongLastName': '入力された姓が無効です',
		'Proceq.ErrorUsernameAlreadyExists': '同じ電子メールのアカウントが既に存在します',
		'Proceq.ErrorDB': 'データベース操作中にエラーが発生しました',
		'Proceq.InvalidUsername': 'ユーザーアカウントが見つかりませんでした',
		'Proceq.AlreadyMultipleForgot': 'パスワードのリセットが既に複数回リクエストされています',
		'Proceq.InvalidNewPassword': '入力された新しいパスワードが無効です',
		'Proceq.InvalidResetCode': 'パスワードリセットコードが無効または期限切れです',
		'Proceq.InvalidOldPassword': '入力された古いパスワードが無効です',
		'Proceq.InvalidAccessRights': 'セッションが期限切れ、またはユーザーがこのアクションの実行を許可されていません',
		'Proceq.ErrorNoAccountType': 'アカウントタイプが入力されていません',
		'Proceq.ErrorNoUsername': '電子メールが入力されていません',
		'Proceq.InvalidCountry': '入力された国が無効です',
		'Proceq.ErrorCreatingUser': '新規ユーザーの作成中にエラー',
		'Proceq.InvalidID': '入力されたIDが無効です',
		'Proceq.InvalidTransactionID': '無効なトランザクションID',
		'Proceq.InvalidFileContent': 'ファイルコンテンツの読み込み中にエラー',
		'Proceq.InvalidTypeEntry': '無効なエントリタイプ',
		'Proceq.InvalidContractID': '契約が無効または期限切れです',
		'Proceq.InvalidExistingID': '既に存在するためIDを作成できません',
		'Proceq.InvalidDataSeriesType': '無効なシリーズタイプ',
		'Proceq.InvalidMetaDataContent': '無効なメタデータコンテンツ',
		'Proceq.ErrorFileIsLocked': '1つまたは複数のファイルがロックされています。後で再試行してください',
		'Proceq.MissingAttachmentFiles': '添付ファイルがサーバーで見つかりません',
		'Proceq.InvalidFlagType': 'フラグタイプが無効またはありません',
		'Proceq.MissingDateTypeFields': 'シリーズの必須フィールドがありません',
		'Proceq.InvalidFolderID': '無効なフォルダID',
		'Proceq.InvalidDataSeriesName': '無効なシリーズ名',
		'Proceq.ErrorDateFormat': '入力された日付形式が無効です',
		'Proceq.ErrorDataFormat': 'シリーズからのデータを解析できません',
		'Proceq.InvalidDownloadID': 'ダウンロードしたトークンが無効または期限切れです',
		'Proceq.InvalidVersionNr': '入力されたバージョン番号が無効です',
		'Proceq.ErrorDataMissing': 'ストレージシステムからデータを復元できません',
		'Proceq.ErrorSavingFile': 'サーバーにファイルを保存できません',
		'Proceq.ErrorInsertDB': 'DBへの追加中にエラー',
		'Proceq.InvalidFolderName': '入力されたフォルダ名が無効です',
		'Proceq.InvalidActionID': 'URLリンクが無効または期限切れです',
		'Proceq.AlreadyConfirmed': 'アカウントは既に検証済みです',
		'Proceq.InvalidActionType': '要求されたアクションは処理できません',
		'Proceq.ErrorGeneratingChart': 'チャートの生成中にエラー',
		'Proceq.InvalidSerialID': '無効なシリアルID',
		'Proceq.InvalidHardwareID': '無効なハードウェアID',
		'Proceq.InvalidEntityID': '無効または削除済みのオブジェクトID',
		'Proceq.InvalidEntityType': '無効なオブジェクトタイプ',
		'Proceq.InvalidEntityVerNr': '無効なオブジェクトバージョン番号',
		'Proceq.ErrorTransactionLocked': 'オブジェクトはロックされており、変更できません',
		'Proceq.InvalidDateValue': 'データを解析できません',
		'Proceq.MissingFields': '1つまたは複数の必須フィールドがありません',
		'Proceq.ErrorColumnValue': '1つまたは複数のフィールドへの入力に誤りがあります',
		'Proceq.MissingUidDBValue': 'エントリIDがありません',
		'Proceq.ErrorFileID': '入力されたファイルIDが無効です',
		'Proceq.ErrorInsertDownload': 'ダウンロードの作成中にエラー',
		'Proceq.InvalidAppVersion': '現在のアプリバージョンはサポートされていません。更新してください',
		'Proceq.MissingUserInfo': 'ユーザー情報がありません',
		'Proceq.InvalidAccessToken': 'セッショントークンが無効または期限切れです',
		'Proceq.ErrorGeneratingCSV': 'CSVエクスポートの生成中にエラー',
		'Proceq.ErrorGeneratingExport': 'エクスポートの生成中にエラー',
		'Proceq.MissingParams': '1つまたは複数の必須パラメータがありません',
		'Proceq.SerialNotFound': 'このデバイス用の契約が見つかりません',
		'Proceq.StandardEnumJapan': '日本',
		'Proceq.StandardEnumDefault': 'デフォルト',
		'Proceq.MaterialEnumIndependent': '素材に依存しない',
		'Proceq.MaterialEnumSteelAndCastSteel': '鋼と鋳鋼',
		'Proceq.MaterialEnumWorkToolSteel': '工具鋼',
		'Proceq.MaterialEnumStainlessSteel': 'ステンレス鋼',
		'Proceq.MaterialEnumGrayCastIron': 'ねずみ鋳鉄(GG)',
		'Proceq.MaterialEnumNodularCastIron': '球状鋳鉄(GGG)',
		'Proceq.MaterialEnumCastAlluminiumAlloys': '鋳造アルミニウム合金',
		'Proceq.MaterialEnumBrassCopperZincAlloys': '真鍮 - 銅/亜鉛合金',
		'Proceq.MaterialEnumBronze': '青銅',
		'Proceq.MaterialEnumWroughtCopperAlloys': '製錬銅合金',
		'Proceq.MaterialEnumTitanium': 'チタン',
		'Proceq.ScaleEnumCustom': 'カスタム',
		'Proceq.ScaleEnumPenetrationDepth': '浸入深さ',
		'Proceq.CompanySizeMicro': '従業員1～9名',
		'Proceq.CompanySizeSmall': '従業員10～49名',
		'Proceq.CompanySizeMedium': '従業員50～249名',
		'Proceq.CompanySizeLarge': '従業員250名以上',
		'Proceq.SalutationMr': 'Mr.',
		'Proceq.SalutationMs': 'Ms.',
		'Proceq.SalutationDr': 'Dr.',
		'Proceq.SalutationProf': 'Prof.',
		'Proceq.CategoryAerospace': '航空宇宙',
		'Proceq.CategoryAutomotive': '自動車',
		'Proceq.CategoryChemicalProcessing': '化学',
		'Proceq.CategoryConstructionBuilding': '建設/建築',
		'Proceq.CategoryConsultantTestLabsEngineer': 'コンサルタント/試験機関/エンジニア',
		'Proceq.CategoryEducationInstituteRD': '教育/研究機関/R&D',
		'Proceq.CategoryFilmProducerConverter': 'フィルム製造/コンバーター',
		'Proceq.CategoryGeologyRocktesters': '地質/岩石試験',
		'Proceq.CategoryMachineryToolingEquipment': '機械/工具/装置',
		'Proceq.CategoryMassTransportation': '公共輸送機関',
		'Proceq.CategoryMetalWorks': '金属加工',
		'Proceq.CategoryOilAndGas': '石油/ガス',
		'Proceq.CategoryOthers': 'その他',
		'Proceq.CategoryPaperProducerConverter': '製紙/コンバーター',
		'Proceq.CategorySalesChannel': '販売チャネル',
		'Proceq.ErrorWrongLoginCredentials': '入力されたユーザー名またはパスワードが正しくありません',
		'Proceq.ErrorEmailNotVerified': '電子メールが検証されていません',
		'Proceq.ServerError': 'サーバーエラー',
		'Proceq.TooManyActionEmailRequests': '要求が多すぎます。メールボックスに電子メールが届くまでお待ちください',
		'Proceq.ErrorServerUnderMaintenance': 'サーバーがメンテナンス中です',
		'Proceq.TableDataSeriesName': '名前',
		'Proceq.TableCreatedOn': '作成日時',
		'Proceq.TableModifiedOn': '変更日時',
		'Proceq.TableCreatedBy': '作成者',
		'Proceq.TableNumMean': '平均',
		'Proceq.TableNumTotal': '合計',
		'Proceq.TableNumStandardDeviation': '標準偏差',
		'Proceq.TableImpactDevice': '衝撃デバイス',
		'Proceq.TableImpactDeviceHammer': 'ハンマー',
		'Proceq.TableMaterial': '素材',
		'Proceq.TableScaleUnit': 'スケール単位',
		'Proceq.TableActionRefresh': 'リフレッシュ',
		'Proceq.TableActionExpand': '展開',
		'Proceq.TableActionCollapse': '折りたたみ',
		'Proceq.TableActionExportAsPDF': 'PDFとしてエクスポート',
		'Proceq.TableActionExportAsCSV': 'CSVとしてエクスポート',
		'Proceq.TableActionExportAsJSON': 'JSONとしてエクスポート',
		'Proceq.SearchOperationEquals': '等しい',
		'Proceq.SearchOperationContains': '含む',
		'Proceq.SearchOperationDifferent': '異なる',
		'Proceq.SearchOperationGreaterOrEqual': '以上',
		'Proceq.SearchOperationGreater': 'より大きい',
		'Proceq.SearchOperationLesserOrEqual': '以下',
		'Proceq.SearchOperationLesser': '未満',
		'Proceq.ExportFieldExportDate': 'エクスポート日時',
		'Proceq.ExportFieldLimits': '制限',
		'Proceq.ExportFieldHigh': '高',
		'Proceq.ExportFieldLow': '低',
		'Proceq.ExportFieldOff': 'オフ',
		'Proceq.ExportFieldLowerLimit': '下限',
		'Proceq.ExportFieldUpperLimit': '上限',
		'Proceq.ExportFieldReadings': '読み取り値',
		'Proceq.ExportFieldStandardDeviation': '標準偏差',
		'Proceq.ExportFieldRange': '範囲',
		'Proceq.ExportFieldRelativeSpan': '相対スパン',
		'Proceq.ExportFieldMaximum': '最大',
		'Proceq.ExportFieldMinimum': '最小',
		'Proceq.ExportFieldAverage': '平均',
		'Proceq.ExportFieldUnit': '単位',
		'Proceq.ExportFieldName': '名前',
		'Proceq.ExportFieldMeasurementDate': '測定日時',
		'Proceq.ExportFieldLocationLatitude': '測定位置緯度',
		'Proceq.ExportFieldLocationLongitude': '測定位置経度',
		'Proceq.ExportFieldProbeType': 'プローブタイプ',
		'Proceq.ExportFieldProbeSerialNumber': 'プローブシリアル番号',
		'Proceq.ExportFieldProbeBTSerialNumber': 'プローブBluetoothシリアル番号',
		'Proceq.ExportFieldProbeLastVerificationDate': 'プローブ最終変更日時',
		'Proceq.ExportFieldPrimaryScale': '一次スケール',
		'Proceq.ExportFieldSecondaryScale': '二次スケール',
		'Proceq.ExportFieldMaterial': '素材',
		'Proceq.ExportFieldStandard': '標準',
		'Proceq.ExportFieldNoConversion': '変換なし',
		'Proceq.ExportFieldMeasurements': '測定',
		'Proceq.ExportFieldMore': '増やす',
		'Proceq.ExportFieldLess': '減らす',
		'Proceq.ExportFieldStatistics': '統計',
		'Proceq.ExportFieldSettings': '設定',
		'Proceq.DataSeriesLogbook': 'ログブック',
		'Proceq.DataSeriesAllMeasurements': 'すべての測定',
		'Proceq.DataSeriesFlagged': 'フラグ付き',
		'Proceq.DataSeriesTrash': 'ごみ箱',
		'Proceq.BrowserNoSupportAudio': 'お使いのブラウザはオーディオファイルをサポートしていません。',
		'Proceq.EmailPasswordRecoverySubject': 'パスワード復旧',
		'Proceq.EmailPasswordRecoveryBody':
			'**?1** **?0** 様<br/><br/>新しいパスワードのリクエストを受け付けました。パスワードをリセットするには、下記のリンクをクリックしてください。<br/><br/><a href="**?2**">パスワードのリセット</a><br/><br/>パスワード変更のリクエストについてお心当たりがない場合は、このメッセージを無視してください。リクエストは 12 時間で期限切れとなります。<br/><br/>Proceq チーム<br/><br/>**?3**',
		'Proceq.EmailRegistrationSubject': '登録完了',
		'Proceq.EmailRegistrationBody':
			'**?1** **?0** 様<br/><br/>**?5** へのご登録ありがとうございます。下記のリンクをクリックして、登録を完了してください。<br/><br/><a href="**?2**">**?2**</a><br/><br/>この電子メールは、登録を行われた際に自動的に生成されたものです。この登録にお心当たりがない場合は、このメッセージを無視してください。<br/><br/>登録を完了することにより、<a href="**?3**">当社の諸条件に同意することになります</a>。<br/><br/>Proceq チーム<br/><br/>**?4**',
		'Proceq.LogbookActionNewMeasurement': '新規測定',
		'Proceq.LogbookPropertyCreatedSeriesInFolder': '作成済みシリーズ "**?0**" 保存先フォルダ "**?1**"',
		'Proceq.LogbookPropertyRenamedSeriesFromTo': 'シリーズ名が "**?0**" から "**?1**" に変更されました',
		'Proceq.LogbookPropertyMovedSeriesFromToTrash': 'シリーズがフォルダ "**?0**" からごみ箱に移動されました',
		'Proceq.LogbookPropertyMovedSeriesFromTrashTo': 'シリーズがごみ箱からフォルダ "**?0**" に移動されました',
		'Proceq.LogbookPropertyMovedSeriesFromTo': 'シリーズがフォルダ "**?0**" からフォルダ "**?1**" に移動されました',
		'Proceq.LogbookPropertySecondaryScaleChangedFromTo': '二次スケールが **?0** から **?1** に変更されました',
		'Proceq.LogbookPropertyMaterialChangedFromTo': '素材が **?0** から **?1** に変更されました',
		'Proceq.LogbookPropertyMaxLimitChangedFromTo': '上限が **?0** から **?1** に変更されました',
		'Proceq.LogbookPropertyMinLimitChangedFromTo': '下限が **?0** から **?1** に変更されました',
		'Proceq.LogbookPropertyStandardChangedFromTo': '標準が **?0** から **?1** に変更されました',
		'Proceq.LogbookPropertyTextCommentAdded': 'テキストコメント追加済み',
		'Proceq.LogbookPropertyTextCommentDeletedOn': '**?0** に削除',
		'Proceq.LogbookPropertyMeasurementAdded': '**?0** 追加しました',
		'Proceq.LogbookPropertyMeasurementExcluded': '**?0** 除外しました',
		'Proceq.LogbookPropertyMeasurementIncluded': '**?0** 含めました',
		'Proceq.LogbookPropertyMeasurementDeleted': '**?0** 削除しました',
		'Proceq.LogbookPropertyAttachmentDeletedOn': '**?0** に削除',
		'Proceq.LogbookPropertyPhotoAdded': '写真追加済み',
		'Proceq.LogbookPropertyAudioAdded': 'オーディオコメント追加済み',
		'Proceq.LogbookPropertySeriesFlagged': 'フラグ付きシリーズ',
		'Proceq.LogbookPropertySeriesUnflagged': 'フラグなしシリーズ',
		'Proceq.LogbookPropertySerialNumberShort': 'S/N',
		'Proceq.LogbookPropertyBluetoothModuleShort': 'BTM',
		'Proceq.LogbookPropertyVerified': '検証日時',
		'Proceq.LogbookPropertyContractValidUntil': '契約有効期限',
		'Proceq.LogbookPropertyOperator': 'オペレーター',
		'Proceq.LogbookTitle': 'ログブック',
		'Proceq.LogbookDownload': 'ダウンロード',
		'Proceq.LogbookLoadingData': 'データのロード中...',
		'Proceq.LogbookClose': '閉じる',
		'Proceq.FormCompany': '会社',
		'Proceq.FormScreeningEagleID': 'Screening Eagle ID (Email)',
		'Proceq.FormEmailGroupID': '電子メール',
		'Proceq.FormCustomerEmail': '顧客電子メール',
		'Proceq.FormProbeSN': 'プローブS/N',
		'Proceq.FormBluetoothSN': 'S/N',
		'Proceq.FormBluetoothHWID': 'HW ID',
		'Proceq.FormExpirationDate': '有効期限',
		'Proceq.TitleRegisterProbeSNs': 'レンタル契約登録',
		'Proceq.ButtonSubmit': '送信',
		'Proceq.ButtonReset': 'リセット',
		'Proceq.ButtonResetPassword': 'パスワードのリセット',
		'Proceq.MessageSuccesfullyInserted': '正常に追加されました',
		'Proceq.MessageSuccesfullyEmailConfirmed': '電子メールが確認されました。',
		'Proceq.MessagePasswordSuccesfullyChanged': 'パスワードは正常に変更されました',
		'Proceq.MessageSuccesfullyUpdated': '正常に更新されました',
		'Proceq.MessageAccountCreated':
			'サインアップ確認電子メールをお送りしました。電子メールに記載のリンクをクリックして、登録プロセスを完了してください',
		'Proceq.MessageEmailForgotPassword': '電子メールで詳しい説明をお送りしました',
		'Proceq.MessageEmailResendConfirm': '電子メールで詳しい説明をお送りしました',
		'Proceq.MessageLoggingIn': 'ログイン中...',
		'Proceq.LoginNeedAccount': 'アカウントが必要ですか?',
		'Proceq.LoginCreateAccount': 'アカウントの作成',
		'Proceq.TitleForgotPassword': 'パスワードを忘れた',
		'Proceq.TitleResendConfirmation': '確認電子メールを再送信',
		'Proceq.FormGroup': 'グループ',
		'Proceq.FormFirstName': '名',
		'Proceq.FormLastName': '姓',
		'Proceq.FormEmail': '電子メールアドレス',
		'Proceq.FormCity': '市',
		'Proceq.FormPassword': 'パスワード',
		'Proceq.FormPasswordNew': '新しいパスワード',
		'Proceq.FormPasswordOld': '古いパスワード',
		'Proceq.FormConfirmPassword': 'パスワードの確認',
		'Proceq.FormConfirmPasswordNew': '新しいパスワードの確認',
		'Proceq.ButtonRegister': '登録',
		'Proceq.ButtonSignIn': 'サインイン',
		'Proceq.TitleSignIn': 'サインイン',
		'Proceq.LoginAlreadyRegistered': '登録済みですか?',
		'Proceq.TextOrSignInUsing': 'または次の方法でサインイン',
		'Proceq.NavSearchSerials': '契約の検索',
		'Proceq.NavRegisterProbeSNs': '契約の登録',
		'Proceq.NavCreateStaffAccounts': 'スタッフアカウントの作成',
		'Proceq.NavHistoryLog': '履歴ログ',
		'Proceq.NavMeasurementsData': '測定データ',
		'Proceq.NavChangePassword': 'パスワードの変更',
		'Proceq.NavLogout': 'ログアウト',
		'Proceq.NavUpdateProfile': 'プロファイルの更新',
		'Proceq.TitleUpdateProfile': 'プロファイルの更新',
		'Proceq.NavCreateCustomersAccounts': '顧客アカウントの作成',
		'Proceq.ButtonSearch': '検索',
		'Proceq.ButtonUpdate': '更新',
		'Proceq.ButtonCreate': '作成',
		'Proceq.ButtonChange': '変更',
		'Proceq.FormSearchBy': '検索条件',
		'Proceq.FormValue': '値',
		'Proceq.FormAccountType': 'アカウントタイプ',
		'Proceq.FormAccountTypeAdmin': '管理者',
		'Proceq.FormAccountTypeBU': '事業部門',
		'Proceq.FormCompanyName': '会社名',
		'Proceq.FormAddress': '住所',
		'Proceq.FormZipCode': '郵便番号',
		'Proceq.FormCountry': '国',
		'Proceq.FormPhoneNumber': '電話',
		'Proceq.FormCompanyWebsite': '会社Webサイト',
		'Proceq.FormJobTitle': '肩書',
		'Proceq.FormIndustry': '業種',
		'Proceq.FormSalutation': '敬称',
		'Proceq.FormCompanySize': '会社規模',
		'Proceq.FormLanguage': '言語',
		'Proceq.FormContactByProceq': 'Proceqからのご連絡',
		'Proceq.LanguageEnglish': '英語',
		'Proceq.LanguageJapanese': '日本語',
		'Proceq.LanguageChinese': '中国語',
		'Proceq.LanguageGerman': 'ドイツ語',
		'Proceq.TextYes': 'はい',
		'Proceq.TextNo': 'いいえ',
		'Proceq.FormNewsletter': 'ニュースレター',
		'Proceq.TitleSearchSerials': '契約の検索',
		'Proceq.TitleChangePassword': 'パスワードの変更',
		'Proceq.TabLoginCustomers': '顧客',
		'Proceq.TabLoginSingleUser': '単一ユーザー',
		'Proceq.TabLoginGroup': 'グループ',
		'Proceq.TextRemainingXDays': '残り **?0** 日',
		'Proceq.TextInfoShort': '情報',
		'Proceq.TextIAgreeTerms': '<a>利用条件</a>に同意します',
		'Proceq.ErrorDisplayTitleError': 'エラー',
		'Proceq.ErrorDisplayTitleWarning': '警告',
		'Proceq.LoginBackLogin': 'ログインに戻る',
		'Proceq.LoginForgotPasswordQuestion': 'パスワードをお忘れですか?',
		'Proceq.LoginResendConfirmationEmail': '確認電子メールを再送信',
		'Proceq.LoginRegisterAccountTextPart': 'アカウントをお持ちでない場合、',
		'Proceq.LoginRegisterAccountLinkPart': 'ここで登録してください',
		'Proceq.LoginProceqUseOnly': 'これはProceq社内専用です',
		'Proceq.ProfileEnterInfo': '続行する前にプロファイル情報を入力してください',
		'Proceq.TextInfoExclamation': '情報!',
		'Proceq.FooterLegalPrivacy': '法律&プライバシー',
		'Proceq.JQGridTableRecordText': '{0} - {1} / {2} の表示',
		'Proceq.JQGridTableEmptyRecords': '表示するレコードがありません',
		'Proceq.JQGridTableLoadText': 'ロード中...',
		'Proceq.JQGridTablePaginationText': '{0} / {1} ページ',
		'Proceq.JQGridTableSavingText': '保存中...',
		'Proceq.JQGridTableFirstPage': '最初のページ',
		'Proceq.JQGridTableLastPage': '最後のページ',
		'Proceq.JQGridTableNextPage': '次のページ',
		'Proceq.JQGridTablePreviousPage': '前のページ',
		'Proceq.JQGridTableRecordsPerPage': 'ページあたりのレコード数',
		'Proceq.JQGridTablePageText': 'ページ:',
		'Proceq.JQGridTableNoMoreRecs': 'これ以上レコードはありません...',
		'Proceq.ExportColumnCSVNo': 'No.',
		'Proceq.ExportColumnCSVSingleReadings': '単一読み取り値',
		'Proceq.ExportColumnCSVMeasurementValue': '測定値',
		'Proceq.ExportColumnCSVAngleDegrees': '角度(°)',
		'Proceq.ExportColumnCSVExcluded': '除外済み',
		'Proceq.ExportColumnCSVSeriesName': 'シリーズ名',
		'Proceq.ExportColumnCSVImpactDevice': '衝撃デバイス',
		'Proceq.ExportColumnCSVImpactDeviceSN': '衝撃デバイスS/N',
		'Proceq.ExportColumnCSVImpactDeviceVerification': '衝撃デバイス検証',
		'Proceq.ExportColumnCSVImpactDeviceHammerVerification': 'ハンマー検証',
		'Proceq.ExportColumnCSVImpactDeviceHammer': 'ハンマー',
		'Proceq.ExportColumnCSVImpactDeviceHammerSN': 'ハンマーS/N',
		'Proceq.ExportColumnCSVBluetoothModuleSN': 'BluetoothモジュールS/N',
		'Proceq.ExportColumnCSVConversionStandard': '変換標準',
		'Proceq.ExportColumnCSVScale': 'スケール',
		'Proceq.ExportColumnCSVUnit': '単位',
		'Proceq.ExportColumnCSVReadings': '読み取り値',
		'Proceq.ExportColumnCSVStandardDeviation': '標準偏差',
		'Proceq.ExportColumnCSVRelativeSpan': '相対スパン(%)',
		'Proceq.ExportColumnCSVDebugDataSeriesName': 'シリーズ名',
		'Proceq.ExportColumnExportDate': 'エクスポート日時',
		'Proceq.ExportColumnUpperLimit': '上限',
		'Proceq.ExportColumnLowerLimit': '下限',
		'Proceq.ExportColumnLimits': '制限',
		'Proceq.ExportColumnHigh': '高',
		'Proceq.ExportColumnLow': '低',
		'Proceq.ExportColumnValueOff': 'オフ',
		'Proceq.ExportColumnValueNoConversion': '変換なし',
		'Proceq.ExportColumnValueNoConversionShort': 'NoConv',
		'Proceq.ExportColumnReadings': '読み取り値',
		'Proceq.ExportColumnStandardDeviation': '標準偏差',
		'Proceq.ExportColumnRange': '範囲',
		'Proceq.ExportColumnRelativeSpan': '相対スパン',
		'Proceq.ExportColumnMaximum': '最大',
		'Proceq.ExportColumnMinimum': '最小',
		'Proceq.ExportColumnAverage': '平均',
		'Proceq.ExportColumnUnit': '単位',
		'Proceq.ExportColumnName': '名前',
		'Proceq.ExportColumnDataSeriesID': 'シリーズID',
		'Proceq.ExportColumnFolderName': 'フォルダ名',
		'Proceq.ExportColumnCreatedDate': '作成日',
		'Proceq.ExportColumnCreatedDateOnly': '作成日',
		'Proceq.ExportColumnCreatedTime': '作成時刻',
		'Proceq.ExportColumnLastModifiedDate': '最終変更日',
		'Proceq.ExportColumnLastModifiedDateOnly': '最終変更日',
		'Proceq.ExportColumnLastModifiedTime': '最終変更時刻',
		'Proceq.ExportColumnMeasurementAvgHardness': '測定平均硬さ',
		'Proceq.ExportColumnLocationLatitude': '測定位置緯度',
		'Proceq.ExportColumnLocationLongitude': '測定位置経度',
		'Proceq.ExportColumnProbeType': 'タイプ',
		'Proceq.ExportColumnProbeSN': 'シリアル番号',
		'Proceq.ExportColumnBTMSN': 'シリアル番号',
		'Proceq.ExportColumnProbeLastModifiedDate': '最終検証日',
		'Proceq.ExportColumnProbeHardwareRevision': '硬さリビジョン',
		'Proceq.ExportColumnProbeFirmwareRevision': 'ファームウェアリビジョン',
		'Proceq.ExportColumnBTMHardwareRevision': '硬さリビジョン',
		'Proceq.ExportColumnBTMFirmwareRevision': 'ファームウェアリビジョン',
		'Proceq.ExportColumnAppPlatform': 'プラットフォーム',
		'Proceq.ExportColumnVersion': 'バージョン',
		'Proceq.ExportColumnOperator': 'オペレーター',
		'Proceq.ExportColumnPrimaryScale': '一次スケール',
		'Proceq.ExportColumnSecondaryScale': '二次スケール',
		'Proceq.ExportColumnMaterial': '素材',
		'Proceq.ExportColumnConversionStandard': '変換標準',
		'Proceq.ExportColumnAngleCorrection': '角度補正',
		'Proceq.ExportColumnValueAuto': 'オート',
		'Proceq.ExportColumnTableAngleGrades': '角度(°)',
		'Proceq.ExportColumnTableLegendExcluded': '* 除外済み',
		'Proceq.ExportColumnMeasurementBarGraph': '測定棒グラフ',
		'Proceq.ExportColumnTableView': '表ビュー',
		'Proceq.ExportColumnStatistics': '統計',
		'Proceq.ExportColumnSettings': '設定',
		'Proceq.ExportColumnImpactDeviceInfo': '衝撃デバイス情報',
		'Proceq.ExportColumnImpactDeviceHammerInfo': 'ハンマー情報',
		'Proceq.ExportColumnBluetoothModuleInfo': 'Bluetoothモジュール情報',
		'Proceq.ExportColumnApplicationInfo': 'アプリケーション情報',
		'Proceq.ExportColumnAttachedPhotos': '添付写真',
		'Proceq.ButtonActions': 'アクション',
		'Proceq.SerialNotInServiceFTP': 'S/NはサービスFTPにありません',
		'Proceq.ActionResultsToCSV': '結果をCSVとしてエクスポート',
		'Proceq.ErrorDisplayTitleSuccess': '成功',
		'Proceq.ErrorNoMultipleTargetsAllowed': '複数行でアクションを実行することはできません',
		'Proceq.MessageActionSuccesfullyPerformed': 'アクションは正常に実行されました',
		'Proceq.EmailReminderContractUserSubject': '契約期限切れ 2 か月前のお知らせ',
		'Proceq.EmailReminderContractUserBody':
			'ユーザー各位<br/><br/>ご利用中の **?0** の レンタル期間が **?1** に期限切れとなります。<br/><br/>ご契約は、当社の <a href="http://www.proceq.com/fileadmin/user_upload/Proceq_General_Terms___Conditions_of_Rental_10112016.pdf">「レンタル一般条件」</a>に従って、自動的に 12 か月延長されます。請求書は別途お届けします。Equotip Live 製品を途切れなくご利用いただくために、期限内に決済を行っていただきますようお願いいたします。 <br/><br/>ご不明な点については、 <a href="http://www.proceq.com/contact/proceq-network/">最寄りのProceq代理店</a>にお問い合わせください。<br/><br/>Proceq チーム<br/><br/>**?2**',
		'Proceq.EmailReminderContractAdminSubject': '契約期限切れのお知らせ',
		'Proceq.EmailReminderContractAdminBody':
			'BU様<br/><br/>次の契約は近く期限切れとなります:<br/><br/>登録先: **?0** <br/> 連絡先電子メール: **?1** <br/> 有効期限: **?2** <br/> 衝撃デバイスBluetoothモジュール情報: **?3** <br/> 衝撃デバイスハードウェアID: **?4** <br/><br/>敬具<br/><br/>Proceqチーム<br/><br/>**?5**',
		'Proceq.ErrorNoTargetSelected': 'アクションのターゲットが選択されていません',
		'Proceq.ErrorDisplayTitleInfo': '情報',
		'Proceq.TextSharingSeriesUrlLinkCreated':
			'シリーズは次のリンクで共有されています:<br/>**?0**<br/><a class="link-copy-clipboard" href="javascript:void(0);" onclick="**?1**">クリップボードにコピー</a><br/>',
		'App.ShareMeasurementsSuccess': 'シリーズは次のリンクで共有されています',
		'App.ShareMeasurementsCopyAction': 'クリップボードにコピー',
		'Proceq.TextPleaseWait': 'お待ちください...',
		'Proceq.TextPerformingServerRequest': 'サーバー要求の実行中',
		'Proceq.TextPerformingAction': 'アクションの実行中',
		'Proceq.ButtonCreateAccountChinaBack': '破棄',
		'Proceq.TitleCreateAccountChina': '中国のお客様',
		'Proceq.BodyCreateAccountChina':
			'中国のお客様はこちらで<a href="**?0**">**?1**</a>登録またはログインしてください',
		'Proceq.LoginCompatibiltyInfo': '互換性情報<a href="**?0**">Proceqアプリ</a>',
		'Proceq.TextRedirectingToRegion': 'お住まいの地域にリダイレクト中...',
		'Proceq.EmailReminderContractExpiredTodayUserSubject': '契約期限切れ',
		'Proceq.EmailReminderContractExpiredTodayUserBody':
			'ユーザー各位<br/><br/>ご利用中の **?0**の レンタル期間が本日期限切れとなりました。<br/><br/>これまで Proceq Live をご利用いただきありがとうございました。またのご利用をお待ちしております。<br/><br/>契約の更新については、<a href="http://www.proceq.com/contact/proceq-network/">最寄りのProceq代理店</a>にお問い合わせください。<br/><br/>Proceq チーム<br/><br/>**?1**',
		'Proceq.EmailRentalPeriodChangedSubject': '契約変更通知',
		'Proceq.EmailRentalPeriodChangedBody':
			'ユーザー各位<br/><br/>ご利用中の **?0** のレンタル契約の有効期限が変更されました。レンタル契約は **?1** に期限切れとなります。<br/><br/>ご不明な点については、 <a href="http://www.proceq.com/contact/proceq-network/">最寄りのProceq代理店</a>にお問い合わせください。<br/><br/>Proceq チーム<br/><br/>**?2**',
		'Proceq.FormExpiresIn': '期限切れまでの日数',
		'Proceq.SelectTimeWeek': '週',
		'Proceq.SelectTimeDay': '日',
		'Proceq.SelectTimeMonth': '月',
		'Proceq.GraphNoDataAvailable': '利用可能なデータなし',
		'Proceq.GraphMaterial': '素材グループ',
		'Proceq.GraphSecondaryScale': '変換スケール',
		'Proceq.GraphStandard': '変換標準',
		'Proceq.GraphMeasurementCount': '衝撃回数',
		'Proceq.GraphMeasurementSeriesCount': '測定シリーズ数',
		'Proceq.StatisticsTitle': '統計',
		'Proceq.StatisticsCreatedSeries': '作成済み測定シリーズ',
		'Proceq.StatisticsModifiedSeries': '変更済み測定シリーズ',
		'Proceq.StatisticsPhotos': '写真',
		'Proceq.StatisticsAudioFiles': 'オーディオファイル',
		'Proceq.StatisticsUsedStorage': '使用済みストレージ',
		'Proceq.StatisticsFolder': 'フォルダ',
		'Proceq.StatisticsModified': '変更済み',
		'Proceq.StatisticsCreated': '作成済み',
		'Proceq.StatisticsAll': 'すべて',
		'Proceq.StatisticsCountry': '国',
		'Proceq.StatisticsCompanySize': '会社規模',
		'Proceq.StatisticsIndustry': '業種',
		'Proceq.DateRangeFrom': '開始',
		'Proceq.DateRangeTo': '終了',
		'Proceq.GraphHistogram': '硬さヒストグラム',
		'Proceq.GraphSeriesHistogram': '硬さヒストグラムシリーズ',
		'Proceq.StatisticsImpactDevice': '衝撃デバイス',
		'Proceq.StatisticsImpactDeviceHammer': 'ハンマー',
		'Proceq.GraphExcluded': '除外済み',
		'Proceq.GraphIncluded': '包含済み',
		'Proceq.GraphWithinTolerance': '許容範囲内',
		'Proceq.GraphOutOfTolerance': '許容範囲外',
		'Proceq.StatisticsCreatedImpacts': '作成済み衝撃(包含済み/除外済み)',
		'Proceq.StatisticsModifiedImpacts': '変更済み衝撃(包含済み/除外済み)',
		'Proceq.GraphNoConversion': '変換なし',
		'Proceq.StatisticsTotalImpacts': '合計衝撃',
		'Proceq.StatisticsTotalSeries': '合計シリーズ',
		'Proceq.StatisticsTotalExcluded': '合計除外済み',
		'Proceq.StatisticsValuesOutOfLimits': '限度外の値',
		'Proceq.StatisticsPeriod': '期間',
		'Proceq.LabelFrom': '開始',
		'Proceq.LabelTo': '終了',
		'Proceq.LabelImpacts': '衝撃',
		'Proceq.AbbrevCalendarWeek': 'CW',
		'Proceq.eRebNStd_Mean': '平均',
		'Proceq.ExportFieldTargetScale': 'ターゲットスケール',
		'Proceq.ExportFieldConversionCurve': '変換曲線',
		'Proceq.eCC_None': 'なし',
		'Proceq.eCC_Portland_Cement_J_N': 'ポルトランドセメントJ',
		'Proceq.eCC_Early_Strength_J_N': '早強セメントJ',
		'Proceq.eCC_Blast_Furnace_J_N': '高炉セメントJ',
		'Proceq.eCC_Average_Curve_J_N': '平均曲線J',
		'Proceq.eSC_R1_dry': 'JSCE-G504 R1 = 0 (乾燥)',
		'Proceq.eSC_R1_black_mark': 'JSCE-G504 R1 = +3 (黒点)',
		'Proceq.eSC_R1_wet_surface': 'JSCE-G504 R1 = +5 (湿った表面)',
		'Proceq.eCSC_None': 'なし',
		'Proceq.eCSC_Upper': '上側',
		'Proceq.eCSC_Under': '下側',
		'Proceq.eSCC_US_eCSC_None': '統一強度',
		'Proceq.eSCC_US_eCSC_Upper': '統一強度上側打設面',
		'Proceq.eSCC_US_eCSC_Under': '統一強度下側打設面',
		'Proceq.eSCC_PC_eCSC_None': 'ポンプ圧送コンクリート',
		'Proceq.eSCC_PC_eCSC_Upper': 'ポンプ圧送コンクリート上側打設面',
		'Proceq.eSCC_PC_eCSC_Under': 'ポンプ圧送コンクリート下側打設面',
		'Proceq.eSCC_US': '統一強度',
		'Proceq.eSCC_PC': 'ポンプ圧送コンクリート',
		'Proceq.eNoOutlier': '外れ値なし',
		'Proceq.eOutlierLow': '外れ値低',
		'Proceq.eOutlierHigh': '外れ値高',
		'Proceq.eOutlierRedundant': '外れ値重複',
		'Proceq.LogbookPropertyConversionCurveChangedFromTo': '変換曲線は **?0** から **?1** に変更されました',
		'Proceq.LogbookPropertyConversionCurveChangedTo': '変換曲線は **?0** に変更されました',
		'Proceq.LogbookPropertyTargetScaleChangedFromTo': 'ターゲットスケールは **?0** から **?1** に変更されました',
		'Proceq.LogbookPropertySurfaceCorrectionChangedFromTo': '表面補正は **?0** から **?1** に変更されました',
		'Proceq.LogbookPropertySurfaceCorrectionChangedTo': '表面補正は **?0** に変更されました',
		'Proceq.LogbookPropertyCarbonationDepthChangedFromTo': '中性化深さは **?0** mm から **?1** mm に変更されました',
		'Proceq.LogbookPropertySurfaceConditionChangedFromTo': '表面の状態は **?0** から **?1** に変更されました',
		'Proceq.LogbookPropertySurfaceConditionChangedTo': '表面の状態は **?0** に変更されました',
		'Proceq.LogbookPropertyConcreteAgeChangedFromTo': 'コンクリート材齢は **?0** 日から **?1** 日に変更されました',
		'Proceq.LogbookPropertyConcreteAgeChangedTo': 'コンクリート材齢は **?0** 日に変更されました',
		'Proceq.ExportColumnCSVOutlierType': '外れ値',
		'Proceq.ExportColumnCarbonationDepth': '中性化深さ',
		'Proceq.ExportColumnConcreteAge': 'コンクリート材齢',
		'Proceq.ExportFieldCarbonationDepth': '中性化深さ',
		'Proceq.ExportFieldConcreteAge': 'コンクリート材齢',
		'Proceq.ValueConcreteAgeDays': '**?0** 日',
		'Proceq.ValueCarbonationDepthMms': '**?0** mm',
		'Proceq.ExportColumnConversionCurve': '変換曲線',
		'Proceq.ExportColumnTableLegendOutlier': '* 外れ値',
		'Proceq.ExportColumnTargetScale': 'ターゲットスケール',
		'Proceq.ExportColumnMeasurementAvgCompressiveStrength': '測定平均圧縮強度',
		'Proceq.ExportColumnEn13791Screening': 'EN 13791 スクリーニング',
		'Proceq.ExportColumnLowestRebound': 'リバウンド値最低値',
		'Proceq.ExportColumnMedianRebound': 'リバウンド値中央値',
		'Proceq.ExportColumnCompressiveStrengthClass': 'EN 206 圧縮強度クラス',
		'Proceq.EmailIntroDearContractOwner': 'ご契約中のお客様各位',
		'Proceq.ColumnTableSeriesRegionExport': 'シリーズ',
		'Proceq.ExportColumSeriesRegionExport': 'シリーズ',
		'Proceq.ExportTitleTestRegionExport': '試験領域エクスポート',
		'Proceq.ErrorSchmidtExportInvalidStandard': 'すべてのシリーズに同じ標準セットが必要です',
		'Proceq.ErrorSchmidtExportTooFewSeries': 'EN 13791 スクリーニングには 9 以上のシリーズが必要です',
		'Proceq.ErrorSchmidtExportTooManySeries': '1 回のエクスポートで最大 99 シリーズを選択できます',
		'Proceq.ErrorSchmidtExportDifferentConfiguration': 'シリーズで異なる構成が見つかりました',
		'Proceq.ErrorSchmidtExportWrongStandard': '選択したエクスポートについて誤った標準が見つかりました',
		'Proceq.ErrorSchmidtExportInvalidSeries': '選択した標準によると少なくとも 1 つのシリーズが無効です',
		'Proceq.ErrorSchmidtExportSoftwareError': 'エクスポートの生成中にエラーが発生しました',
		'Proceq.ErrorSchmidtExportOutOfRange': '計算された強度クラスは範囲外です',
		'Proceq.ButtonExportTestRegionEU': 'EN 13791 スクリーニング試験を PDF としてエクスポート',
		'Proceq.ButtonExportTestRegionUniformity': '均一性を PDF としてエクスポート',
		'Proceq.ButtonExportTestRegionChina': 'JGJ T23 試験領域を PDF としてエクスポート',
		'Proceq.ErrorNotYetImplemented': '未実装',
		'Proceq.ErrorSchmidtExportWrongParam':
			'シリーズで誤ったパラメータが見つかりました。例: 選択したエクスポートについて誤った単位',
		'Proceq.ErrorSchmidtExportUnitNotMpa': 'JGJ 領域については単位を MPa に設定しなければなりません',
		'Proceq.ErrorSchmidtExportWrongFormFactor': 'JGJ 領域については形状因子は 2:1 円筒であってはなりません',
		'Proceq.NavSelectView': 'ビューの表示',
		'Proceq.TextConfirmationAreYouSure': 'よろしいですか?',
		'Proceq.ErrorContractLinkedDifferentImpactDevice': '現在の衝撃デバイスシリアル番号では契約を利用できません',
		'Proceq.TitleShareByUrl': 'URL による共有',
		'Proceq.ExportTitleVerification': '検証レポート:',
		'Proceq.ExportVerificationStatusFailed': '不合格',
		'Proceq.ExportVerificationStatusPassed': '合格',
		'Proceq.ExportColumnTestVerificationResult': '検証結果',
		'Proceq.ExportColumnTestBlockTolerance': '試験ブロック不確実性 (Ucrm) k = 2',
		'Proceq.ExportColumnTestBlockNominalHardness': '試験ブロック公称硬さ',
		'Proceq.ExportColumnTestBlockSN': '試験ブロックシリアル番号',
		'Proceq.ExportColumnTestBlockName': '試験ブロック名',
		'Proceq.ExportColumnVerificationStd': '検証標準',
		'Proceq.ExportColumnVerificationCriteria': '検証条件',
		'Proceq.VerificationCriteriaAverage5Span5Perc': '平均 +/- 5%、スパン +/- 5%',
		'Proceq.VerificationCriteriaReadings6HL': '読み取り値 +/- 6 HL',
		'Proceq.VerificationCriteriaReadings3Perc': '読み取り値 +/- 3%',
		'Proceq.VerificationCriteriaAverage5Perc': '平均 +/- 5%',
		'Proceq.VerificationCriteriaAverage2R': '平均 +/- 2 R',
		'Proceq.VerificationCriteriaReadings3R': '読み取り値 +/- 3 R',
		'Proceq.StandardEnumCustom': 'カスタム',
		'Proceq.MaterialEnumCustom': 'カスタム',
		'Proceq.ExportColumnCalibrationAnvilName': '校正アンビル名',
		'Proceq.ExportColumnCalibrationAnvilSN': '校正アンビルシリアル番号',
		'Proceq.ExportColumnCalibrationAnvilReboundValue': '校正アンビルリバウンド値',
		'Proceq.ExportColumnCalibrationAnvilTolerance': '校正アンビル許容範囲',
		'Proceq.ExportColumnTestLoadInfo': '試験負荷情報',
		'Proceq.NavVerificationData': '検証データ',
		'Proceq.NavCustomData': 'カスタムデータ',
		'Proceq.TableColumnVerificationResult': '結果',
		'Proceq.TitleVerifications': '検証',
		'Proceq.LogBookTestBlock': '試験ブロック',
		'Proceq.LogBookCalibrationAnvil': '校正アンビル',
		'Proceq.LogbookPropertyTestBlockChangedFromTo': '試験ブロックは "**?0**" から "**?1**" に変更されました',
		'Proceq.LogbookPropertyTestBlockChangedTo': '試験ブロックは "**?0**" に変更されました',
		'Proceq.LogbookPropertyCalibrationAnvilChangedFromTo': '校正アンビルは **?0** から **?1** に変更されました',
		'Proceq.LogbookPropertyCalibrationAnvilChangedTo': '校正アンビルは **?0** に変更されました',
		'Proceq.LogbookPropertyCreatedVerification': '作成済み検証 "**?0**"',
		'Proceq.LogbookPropertyRenamedVerificationFromTo': '検証の名前は "**?0**" から "**?1**" に変更されました',
		'Proceq.LogbookResultVerificationFailed': '検証不合格',
		'Proceq.LogbookResultVerificationPassed': '検証合格',
		'Proceq.LogbookPropertyTestLoadChangedFromTo': '試験負荷は **?0** から **?1** に変更されました',
		'Proceq.LogbookPropertyTestLoadChangedTo': '試験負荷は **?0** に変更されました',
		'Proceq.LogBookTestLoad': '試験負荷',
		'Proceq.FieldWebOriginalCurve': 'オリジナル',
		'Proceq.FieldWebCustomCurve': 'カスタム',
		'Proceq.ExportFieldValuePair': '値ペア',
		'Proceq.TableBaseMaterial': '基材',
		'Proceq.TableDescription': '説明',
		'Proceq.NavCustomMaterial': 'カスタム素材',
		'Proceq.FolderCustomMaterials': 'カスタム素材',
		'Proceq.SchmidtCurveTypePolynomial': 'fck = a + b*R + c*R² (多項式)',
		'Proceq.SchmidtCurveTypeExponencial': 'fck = a.e^(b*R) (指数)',
		'Proceq.SchmidtCurveTypePolynomialShort': '多項式',
		'Proceq.SchmidtCurveTypeExponencialShort': '指数',
		'Proceq.ExportFieldCurveType': '曲線タイプ',
		'Proceq.TableActionEdit': '編集',
		'Proceq.TableActionAdd': '追加',
		'Proceq.WebAccountActivatedTitle': '検証成功',
		'Proceq.PasswordChangedTitle': 'パスワードの変更',
		'Proceq.ResetPasswordFormHeading': 'パスワードの再設定',
		'Proceq.PasswordChangedBody':
			'パスワードの再設定が完了しました。新しいパスワードを使って、アカウントにサインインしてください。',
		'Proceq.EnterYourNewPassword': '新しいパスワードを入力する',

		'Proceq.WebAccountActivatedBodyLine1':
			'おめでとうございます。あなたのメールアドレスの認証が完了しました。これで、Screening Eagleでの検査を開始することができます。',
		'Proceq.WebAccountActivatedProceqTeam': 'Proceq チーム',
		'Proceq.NavDashboard': 'ダッシュボード',
		'Proceq.Confirm': '確認',
		'App.SchmidtFormFactorRAngle': 'R角度補正済み',
		'App.SchmidtFormFactorQAngle': 'Q値',
		'App.ShareMeasurementsCopySuccess': 'コピーしました!',
		'App.ShareMeasurementsCopyFailed': 'お使いのブラウザはコピーをサポートしていません。手動でコピーしてください。',
		'App.SystemFolders': 'システムフォルダ',
		'Proceq.DataSeriesDemo': 'デモ',
		'Proceq.DataSeriesImported': 'インポート済み',
		'Antenna Spacing [{unit}]': 'アンテナ間隔[{unit}]',
		'App Version': 'アプリのバージョン',
		'Base Unit S/N': 'ベースユニットS / N',
		'Calculated Depth': '計算された深さ',
		Comment: 'コメント',
		'Contract ID': '契約ID',
		'Distance X [{unit}]': '距離X [{unit}]',
		'Distance Y [{unit}]': '距離Y [{unit}]',
		'Export HTML Format Version': 'HTML形式のバージョンをエクスポート',
		'File ID': 'ファイルID',
		'File Name': 'ファイル名',
		'Firmware Version': 'ファームウェアバージョン',
		'Grid Size X [{unit}]': 'グリッドサイズX [{unit}]',
		'Grid Size Y [{unit}]': 'グリッドサイズY [{unit}]',
		'Grid Spacing X [{unit}]': 'グリッド間隔X [{unit}]',
		'Grid Spacing Y [{unit}]': 'グリッド間隔Y [{unit}]',
		'Hardware Version': 'ハードウェアバージョン',
		Line: 'ライン',
		'MAC Address': 'Macアドレス',
		'Marker Number': 'マーカー番号',
		'Measurement Presets': '測定プリセット',
		Mode: 'モード',
		Name: '名前',
		Objects: 'オブジェクト',
		'Probe S/N': 'プローブS / N',
		Product: '製品',
		'Repetition Rate [{unit}]': '繰り返し率 [{unit}]',
		Resolution: '解決',
		'Sampling Rate [{unit}]': 'サンプリングレート [{unit}]',
		'Scan Distance [{unit}]': 'スキャン距離 [{unit}]',
		'Scan Length [{unit}]': 'スキャン長 [{unit}]',
		'Set Depth': '深さを設定',
		'Set Size': 'セットサイズ',
		Tag: '鬼ごっこ',
		'Tag Type': 'タグタイプ',
		'Time Window [{unit}]': '時間枠[{unit}]',
		Units: '単位',
		'User / Probe Info': 'ユーザー/プローブ情報',
		Markers: 'マーカー',
		'Created {fileName} in folder {folderName}': 'フォルダ{folderName}に{fileName}を作成しました',
		'Mode: {value}': 'モード：{value}',
		'Auth.LoggingIn': 'サインイン中...',
		'Auth.LoggingOut': 'サインアウト中...',
	},
};
