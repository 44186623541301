// @flow
import { ColumnProps } from 'antd/lib/table';
import { renderMean, renderTime } from './columnConfig';

const EQUOTIP = [
	{
		title: 'Proceq.TableNumMean',
		dataIndex: 'content.statistics.primary.avg',
		render: renderMean,
		// filter: 'measurement.$.statistics.primary.avg',
		// sorter: true,
		// range: true,
	},
	{
		title: 'Proceq.TableCreatedOn',
		dataIndex: 'clientCreated',
		sorter: true,
		render: renderTime,
	},
];
const SCHMIDT = [
	{
		title: 'Proceq.TableNumMean',
		dataIndex: 'content.statistics.primary.avg',
		render: renderMean,
		// filter: 'measurement.$.statistics.primary.avg',
		// sorter: true,
		// range: true,
	},
	{
		title: 'Proceq.TableCreatedOn',
		dataIndex: 'clientCreated',
		sorter: true,
		render: renderTime,
	},
];

const columnConfig: {
	[x: ProductCode]: ColumnProps,
} = {
	EQUOTIP,
	SCHMIDT,
};

export default columnConfig;
