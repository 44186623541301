/* eslint-disable func-names,no-restricted-globals */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Table, Button, Modal, Icon, Input, Menu, Dropdown } from 'antd';
import { isEmpty } from 'lodash';
// import update from 'immutability-helper';
import './SmartTable.css';
import { httpRequest } from 'apis/httpClient';
import { checkStatusJson } from 'apis/restUtils';
import handleFormatMessage from 'utils/handleFormatMessage';
import { getKeyValue, getLabelFromGlobalDataKey } from 'utils/registryHelper';
import {
	formatDateToLocale,
	formatDateTimeToLocale,
	formatDateTimeNoSecsToLocale,
	formatLinuxTsSecsDateTimeNoSecsToLocale,
} from 'utils/dateHandler';
import { getResolutionForScale } from 'utils/conversionsHelper';
import { hasAtLeastOneRole } from 'utils/sessionHelper';
import { startDownloadFile } from 'utils/downloadHelper';
import errorHandler from 'actionUtils/errorHandler';
import styles from './SmartTable.styl';
import SmartForm from './SmartForm';

class SmartTable extends Component {
	constructor(props) {
		super(props);
		// this.onShowSizeChange = this.onShowSizeChange.bind(this);
		this.state = {
			selectedRowKeys: [],
			data: [],
			loading: false,
			isModalFormVisible: false,
			modalFormInfo: {},
			fetchParams: {},
			draw: 1,
			pagination: {
				onChange: this.onPaginationChange.bind(this),
				onShowSizeChange: this.onShowSizeChange.bind(this),
				showSizeChanger: this.props.allowSelectPageLength,
				pageSizeOptions: this.props.pageSizeOptions,
				current: 1,
				defaultPageSize: this.props.startPageLength,
				pageSize: this.props.startPageLength,
			},
			expandedRowKeys: [],
			searchText: '',
			filteringColumn: false,
			filters: null,
		};
		this.pageJustChanged = true;
	}

	componentWillMount() {
		this.initColumnsInfo();
	}

	componentDidMount() {
		// this.initColumnsInfo();
		if ('onRef' in this.props) {
			this.props.onRef(this);
		}
		this.fetch();
	}

	componentWillUnmount() {
		if ('onRef' in this.props) {
			this.props.onRef(undefined);
		}
	}

	onPaginationChange() {
		this.pageJustChanged = true;
	}

	onSuccessFormAction(jsondata /* , formFieldsValues */) {
		if (!!jsondata === false && jsondata instanceof Object && 'data' in jsondata) {
			const fileInfo = jsondata.data;
			startDownloadFile(
				this.props.session,
				fileInfo.file_data,
				fileInfo.file_method,
				fileInfo.file_mime,
				fileInfo.file_size,
				fileInfo.file_name
			);
		} else {
			this.fetch();
		}
	}

	onSuccessApiCallAction(jsondata) {
		if (!isEmpty(jsondata) && jsondata !== null && 'data' in jsondata) {
			const fileInfo = jsondata.data;
			startDownloadFile(
				this.props.session,
				fileInfo.file_data,
				fileInfo.file_method,
				fileInfo.file_mime,
				fileInfo.file_size,
				fileInfo.file_name
			);
		} else {
			this.fetch();
		}
	}

	onSelectChange = selectedRowKeys => {
		this.setState({ selectedRowKeys });
	};

	onSearchInputChange(e) {
		this.setState({ searchText: e.target.value });
	}

	onClearFilters() {
		const filteringColumn = this.state.filteringColumn;
		const newPagination = { ...this.state.pagination, current: 1 };
		const newFetchParams = { ...this.state.fetchParams, start: 0, page: 1 };

		this.setState(
			{
				pagination: newPagination,
				fetchParams: newFetchParams,
				searchText: '',
				filters: null,
			},
			() => {
				this.onFilterClicked(filteringColumn, false);
				this.fetch();
			}
		);
	}

	onSearch() {
		const { searchText } = this.state;
		const filteringColumn = this.state.filteringColumn;
		const newPagination = { ...this.state.pagination, current: 1 };
		const newFetchParams = { ...this.state.fetchParams, start: 0, page: 1 };

		this.setState(
			{
				pagination: newPagination,
				fetchParams: newFetchParams,
				filters: {
					[filteringColumn]: [searchText],
				},
			},
			() => {
				this.onFilterClicked(filteringColumn, false);
				this.fetch();
			}
		);
	}

	onFilterClicked(fieldId, visible) {
		const currentFilteredField = this.getCurrentFilteredField();
		if (visible && fieldId === currentFilteredField) {
			/* remove the filter */
			this.setState(
				{
					filters: null,
					filteringColumn: null,
				},
				() => {
					this.fetch();
				}
			);
		} else {
			this.setState({
				filteringColumn: visible ? fieldId : null,
				searchText: '',
			});
		}
	}

	onShowSizeChange(current, pageSize) {
		const newPagination = { ...this.state.pagination, pageSize, defaultPageSize: pageSize };
		this.setState({ pagination: newPagination });
	}

	render() {
		const { selectedRowKeys } = this.state;

		const rowSelection = {
			selectedRowKeys,
			onChange: this.onSelectChange,
		};
		const { intl } = this.props,
			{ formatMessage } = intl;

		const actionsToShow = [];
		if (this.props.editFormInfo && Object.keys(this.props.editFormInfo).length > 0) actionsToShow.push('edit');
		if (this.props.addFormInfo && Object.keys(this.props.addFormInfo).length > 0) actionsToShow.push('add');
		if (this.props.exportCSVInfo && Object.keys(this.props.exportCSVInfo).length > 0)
			actionsToShow.push('exportcsv');
		if (this.props.showRefreshAction) actionsToShow.push('refresh');
		if (this.props.extraActions) {
			const alreadyProcessedGroupIds = [];
			const eligible = extraActionInfo =>
				!('rolesNeeded' in extraActionInfo) ||
				hasAtLeastOneRole(this.props.session, extraActionInfo.rolesNeeded);
			for (
				let currentIndexExtraAction = 0;
				currentIndexExtraAction < this.props.extraActions.length;
				currentIndexExtraAction++
			) {
				const extraActionInfo = this.props.extraActions[currentIndexExtraAction];
				if (eligible(extraActionInfo)) {
					if (!extraActionInfo.actionGroupId) {
						actionsToShow.push(extraActionInfo.actionId);
					} else if (alreadyProcessedGroupIds.indexOf(extraActionInfo.actionGroupId) === -1) {
						const thisGroupActions = [extraActionInfo.actionId];
						alreadyProcessedGroupIds.push(extraActionInfo.actionGroupId);
						for (
							let currentIndexGroupExtraAction = currentIndexExtraAction + 1;
							currentIndexGroupExtraAction < this.props.extraActions.length;
							currentIndexGroupExtraAction++
						) {
							const checkingExtraActionInfo = this.props.extraActions[currentIndexGroupExtraAction];
							if (
								eligible(checkingExtraActionInfo) &&
								checkingExtraActionInfo.actionGroupId === extraActionInfo.actionGroupId
							) {
								thisGroupActions.push(checkingExtraActionInfo.actionId);
							}
						}
						actionsToShow.push(thisGroupActions);
					}
				}
			}
		}

		const columnsRender = this.getCurrentColumns();
		return (
			<div>
				<div className={styles.action_buttons_container}>
					{actionsToShow.length > 0
						? actionsToShow.map(function(actionIdsInfo) {
								const actionsArray = !Array.isArray(actionIdsInfo) ? [actionIdsInfo] : actionIdsInfo;
								const actionToShowInfo = [];
								actionsArray.forEach(function(actionId) {
									let buttonIsDisabled = false;
									let actionText = actionId;
									let isLoading = false;
									let groupText = '';
									let groupId = '';
									switch (actionId) {
										default:
											{
												/* Extra action */
												const extraActionInfo = this.getExtraActionInfo(actionId);
												if (extraActionInfo !== null) {
													actionText = extraActionInfo.actionText;
													const numRowsSelected = selectedRowKeys.length;
													if (
														(extraActionInfo.oneSelectedNeeded && numRowsSelected < 1) ||
														(!extraActionInfo.multipleSelectedAllowed &&
															numRowsSelected > 1)
													) {
														buttonIsDisabled = true;
													}
													if (extraActionInfo.actionGroupText) {
														groupText = handleFormatMessage(
															extraActionInfo.actionGroupText,
															formatMessage
														);
														groupId = extraActionInfo.actionGroupId;
													}
													if (extraActionInfo.actionGroupId) {
														groupId = extraActionInfo.actionGroupId;
													}
												}
											}
											break;
										case 'edit':
											if (selectedRowKeys.length !== 1) {
												buttonIsDisabled = true;
											}
											actionText = 'Proceq.TableActionEdit';
											break;
										case 'add':
											actionText = 'Proceq.TableActionAdd';
											break;
										case 'exportcsv':
											actionText = 'Proceq.TableActionExportAsCSV';
											break;
										case 'refresh':
											actionText = 'Proceq.TableActionRefresh';
											isLoading = this.state.loading;
											break;
									}

									actionText = handleFormatMessage(actionText, formatMessage);
									actionToShowInfo.push({
										buttonIsDisabled,
										actionId,
										isLoading,
										actionText,
										groupText,
										groupId,
									});
								}, this);
								if (actionToShowInfo.length === 1) {
									const actionInfo = actionToShowInfo[0];
									return (
										<div key={actionInfo.actionId} className={styles.action_button_container}>
											<Button
												type="primary"
												onClick={() => {
													this.actionClicked(actionInfo.actionId);
												}}
												disabled={actionInfo.buttonIsDisabled}
												loading={actionInfo.isLoading}
											>
												{actionInfo.actionText}
											</Button>
										</div>
									);
								}

								const groupText = actionToShowInfo[0].groupText;
								const actionGroupId = actionToShowInfo[0].groupId;
								const actionsMarkups = [];
								actionToShowInfo.forEach(actionInfo => {
									actionsMarkups.push(
										<Menu.Item disabled={actionInfo.buttonIsDisabled} key={actionInfo.actionId}>
											{actionInfo.actionText}
										</Menu.Item>
									);
								}, this);
								const actionsMenuMarkup = (
									<Menu
										selectable={false}
										onClick={item => {
											this.actionClicked(item.key);
										}}
									>
										{actionsMarkups}
									</Menu>
								);
								return (
									<div key={actionGroupId} className={styles.action_button_container}>
										<Dropdown overlay={actionsMenuMarkup}>
											<Button>
												{groupText} <Icon type="down" />
											</Button>
										</Dropdown>
									</div>
								);
						  }, this)
						: ''}
				</div>
				<Table
					rowSelection={rowSelection}
					bordered={this.props.bordered}
					loading={this.state.loading}
					size={this.props.size}
					expandedRowRender={this.props.expandedRowRender}
					onExpand={this.onExpand.bind(this)}
					defaultExpandedRowKeys={[]}
					expandedRowKeys={this.state.expandedRowKeys}
					title={this.props.title}
					showHeader={this.props.showHeader}
					footer={this.props.footer}
					scroll={this.props.scroll}
					pagination={this.state.pagination}
					columns={columnsRender}
					rowKey={record => record[this.props.columnKey]}
					dataSource={this.state.data}
					onChange={this.handleTableChange}
				/>
				{this.state.isModalFormVisible ? <SmartForm {...this.state.modalFormInfo} /> : ''}
			</div>
		);
	}

	onExpand(expanded, record) {
		if (expanded) {
			this.expandSelectedRows([record[this.props.columnKey]]);
		} else {
			this.collapseSelectedRows([record[this.props.columnKey]]);
		}
	}

	expandSelectedRows(rowIds) {
		const newExpandedRows = this.state.expandedRowKeys;
		rowIds.forEach(rowId => {
			if (newExpandedRows.indexOf(rowId) < 0) {
				newExpandedRows.push(rowId);
			}
		});

		this.setState({
			expandedRowKeys: newExpandedRows,
		});
	}

	collapseSelectedRows(rowIds) {
		const newExpandedRows = this.state.expandedRowKeys;
		rowIds.forEach(rowId => {
			if (newExpandedRows.indexOf(rowId) >= 0) {
				newExpandedRows.splice(newExpandedRows.indexOf(rowId), 1);
			}
		});

		this.setState({
			expandedRowKeys: newExpandedRows,
		});
	}

	getCurrentFilteredField() {
		const self = this;
		const filters = this.state.filters;
		let currentField = null;
		if (filters) {
			const filterFields = Object.keys(filters);
			if (!filters || filterFields.length <= 0) {
				return null;
			}

			Object.keys(filters).forEach(filterField => {
				if (self.state.filters[filterField] !== null && self.state.filters[filterField].length >= 1) {
					currentField = filterField;
				}
			});
		}
		return currentField;
	}

	getCurrentFilteredValue() {
		const currentFilteredField = this.getCurrentFilteredField();
		if (currentFilteredField === null) {
			return null;
		}

		return this.state.filters[currentFilteredField][0];
	}

	getCurrentColumns() {
		const self = this;
		const currentFilteredField = this.getCurrentFilteredField();
		const newColumns = [...this.state.columns];
		newColumns.forEach(function(columnInfo) {
			const thisColumnFieldId = columnInfo.dataIndex;
			if (columnInfo.filter) {
				if (!columnInfo.globalRegister) {
					columnInfo.filterDropdown = (
						<div className="custom-filter-dropdown">
							<Input
								key="search-input-field"
								onPressEnter={self.onSearch.bind(self)}
								onChange={self.onSearchInputChange.bind(self)}
							/>
							<Button type="primary" onClick={self.onSearch.bind(self)}>
								<FormattedMessage id="Proceq.ButtonSearch" />
							</Button>
							<Button
								type="primary"
								style={{ marginLeft: '5px' }}
								onClick={self.onClearFilters.bind(self)}
							>
								<FormattedMessage id="Proceq.ButtonReset" />
							</Button>
						</div>
					);
				} else {
					columnInfo.filterMultiple = false;
					columnInfo.filters = this.getSelectFilters(columnInfo.globalRegister);
				}

				if (thisColumnFieldId === currentFilteredField) {
					columnInfo.filteredValue = this.getCurrentFilteredValue();
					columnInfo.filterIcon = <Icon type="filter" style={{ color: '#108ee9' }} />;
				} else {
					columnInfo.filteredValue = null;
					columnInfo.filterIcon = <Icon type="filter" style={{ color: '#aaa' }} />;
				}
				if (this.state.filteringColumn === thisColumnFieldId) {
					columnInfo.filterDropdownVisible = true;
				} else {
					columnInfo.filteredValue = null;
					columnInfo.filterDropdownVisible = false;
					if (!columnInfo.globalRegister) {
						columnInfo.filterDropdown = <div className="custom-filter-dropdown" />;
					}
				}
			}
		}, this);
		return newColumns;
	}

	getSelectFilters(registerKey) {
		const filters = [];
		const allowedValuesOptions = [];
		let globalRegisterArray = registerKey;
		if (globalRegisterArray.constructor !== Array) {
			globalRegisterArray = [globalRegisterArray];
		}
		for (let indexGlobalRegister = 0; indexGlobalRegister < globalRegisterArray.length; indexGlobalRegister++) {
			const thisOptionsFound = getKeyValue(this.props.globalregister, globalRegisterArray[indexGlobalRegister]);
			for (let indexOptionValue = 0; indexOptionValue < thisOptionsFound.length; indexOptionValue++) {
				allowedValuesOptions.push(thisOptionsFound[indexOptionValue]);
			}
		}

		getKeyValue(this.props.globalregister, registerKey);
		const { intl } = this.props,
			{ formatMessage } = intl;

		for (let indexAllowedValue = 0; indexAllowedValue < allowedValuesOptions.length; indexAllowedValue++) {
			const thisEntryInfo = allowedValuesOptions[indexAllowedValue];
			filters.push({
				text: handleFormatMessage(thisEntryInfo.label, formatMessage),
				value: thisEntryInfo.data,
			});
		}
		return filters;
	}

	fetch = (/* params = false */) => {
		this.setState({
			loading: true,
			selectedRowKeys: [],
			draw: this.state.draw + 1,
		});

		const preQueryValue = this.state.fetchParams;
		const finalQueryValue = { ...this.props.apiSendBody };
		if (!this.props.fetchAllDataOnStart) {
			finalQueryValue.columns = [];
			let currentColumnIndex = -1;
			let sortFieldId = null;
			if (this.props.typeSortingFiltering === 'simplified') {
				finalQueryValue.offset =
					preQueryValue.page >= 1 ? (preQueryValue.page - 1) * this.state.pagination.pageSize : 0;
				finalQueryValue.limit = this.state.pagination.pageSize;
				if (preQueryValue.sortField) {
					currentColumnIndex += 1;
					finalQueryValue.orderDir = preQueryValue.sortOrder === 'descend' ? 'desc' : 'asc';
					finalQueryValue.order = preQueryValue.sortField;
					sortFieldId = preQueryValue.sortField;
				}
				const currentFilteredField = this.getCurrentFilteredField();
				if (currentFilteredField) {
					finalQueryValue.search = this.getCurrentFilteredValue();
				}
			} else if (this.props.typeSortingFiltering === 'proceq') {
				finalQueryValue.page = preQueryValue.page;
				finalQueryValue.rows = this.state.pagination.pageSize;
				if (preQueryValue.sortField) {
					currentColumnIndex += 1;
					finalQueryValue.sord = preQueryValue.sortOrder === 'descend' ? 'desc' : 'asc';
					finalQueryValue.sidx = preQueryValue.sortField;
					sortFieldId = preQueryValue.sortField;
				}
				let allFilterRules = [];
				if (this.props.fixedFilters.length > 0) {
					allFilterRules = [...this.props.fixedFilters];
				}
				const currentFilteredField = this.getCurrentFilteredField();
				if (currentFilteredField) {
					finalQueryValue.search = this.getCurrentFilteredValue();
					const filteredColumnInfo = this.getColumnInfoOfProps(currentFilteredField);
					if ('globalRegister' in filteredColumnInfo) {
						if (filteredColumnInfo.sendValueInsteadOfID) {
							allFilterRules.push({
								field: currentFilteredField,
								op: 'like',
								data: getLabelFromGlobalDataKey(
									filteredColumnInfo.globalRegister,
									this.getCurrentFilteredValue()
								),
							});
						} else if ('multipleAllowed' in filteredColumnInfo && filteredColumnInfo.multipleAllowed) {
							allFilterRules.push({
								field: currentFilteredField,
								op: 'like',
								data: `%${this.getCurrentFilteredValue()}%`,
								matchId: true,
							});
						} else {
							allFilterRules.push({
								field: currentFilteredField,
								op: 'eq',
								data: this.getCurrentFilteredValue(),
								matchId: true,
							});
						}
					} else {
						allFilterRules.push({
							field: currentFilteredField,
							op: 'like',
							data: `%${this.getCurrentFilteredValue()}%`,
						});
					}
				}
				if (allFilterRules.length > 0) {
					finalQueryValue.filters = JSON.stringify({ groupOp: 'AND', rules: allFilterRules });
				}
			} else {
				finalQueryValue.start =
					preQueryValue.page >= 1 ? (preQueryValue.page - 1) * this.state.pagination.pageSize : 0;
				finalQueryValue.length = this.state.pagination.pageSize;
				if (preQueryValue.sortField) {
					currentColumnIndex += 1;
					finalQueryValue.order = [
						{
							column: currentColumnIndex,
							dir: preQueryValue.sortOrder === 'descend' ? 'desc' : 'asc',
						},
					];
					finalQueryValue.columns.push({
						data: preQueryValue.sortField,
						orderable: true,
					});
				}
				const currentFilteredField = this.getCurrentFilteredField();
				if (currentFilteredField) {
					if (sortFieldId !== currentFilteredField) {
						currentColumnIndex += 1;
						finalQueryValue.columns.push({
							data: currentFilteredField,
						});
					}

					finalQueryValue.columns[currentColumnIndex].search = {
						value: this.getCurrentFilteredValue(),
						searchable: true,
						regex: false,
					};
				}
			}

			finalQueryValue.draw = this.state.draw;
		}
		return httpRequest(
			this.props.session,
			this.props.locale,
			this.props.apiEndpoint,
			this.props.apiTypeMethod,
			this.props.apiSendToken,
			finalQueryValue
		)
			.then(checkStatusJson)
			.then(json => {
				let pagination = { ...this.state.pagination };
				let rowsData = [];
				if (this.props.typeRetrieveData === 'tds') {
					/* tds way */
					rowsData = json.data;
					if (this.props.fetchAllDataOnStart) {
						pagination = false;
					} else {
						// TODO:
						pagination.total = json.totalRecords;
					}
				} else {
					/* proceq way */
					const data = json.data;
					const buildData = [];
					const columnsNames = data.columns;
					for (let indexRow = 0; indexRow < data.rows.length; indexRow++) {
						const thisRecordEntry = {};
						for (let indexColumn = 0; indexColumn < columnsNames.length; indexColumn++) {
							thisRecordEntry[columnsNames[indexColumn]] = data.rows[indexRow][indexColumn];
						}
						buildData.push(thisRecordEntry);
					}

					if (this.props.fetchAllDataOnStart) {
						pagination = false;
					} else {
						// TODO:
						pagination.total = data.records;
					}
					rowsData = buildData;
				}
				this.setState({
					loading: false,
					data: rowsData,
					pagination,
				});
			}, this)
			.catch(err => {
				errorHandler(err);
			}, this);
	};

	mergeFilters(filters1, filters2) {
		if (filters1 === null) return filters2;
		if (filters2 === null) return filters1;

		const toReturn = { ...filters1 };
		Object.keys(filters2).forEach(filterField => {
			if (filters2[filterField] !== null) {
				toReturn[filterField] = filters2[filterField];
			}
		});
		return toReturn;
	}

	getExtraActionInfo(actionId) {
		for (let indexAction = 0; indexAction < this.props.extraActions.length; indexAction++) {
			const extraActionInfo = this.props.extraActions[indexAction];
			if (extraActionInfo.actionId === actionId) {
				return { ...extraActionInfo };
			}
		}
		return null;
	}

	getRowWithKey(rowKey) {
		for (let index = 0; index < this.state.data.length; index++) {
			const thisRowData = this.state.data[index];
			if (this.props.columnKey in thisRowData && thisRowData[this.props.columnKey] === rowKey) {
				return thisRowData;
			}
		}
		return null;
	}

	handleTableChange = (pagination, filters, sorter) => {
		this.pageJustChanged = false;
		const mergeFilters = this.mergeFilters(filters, this.state.filters);
		const pager = { ...this.state.pagination };
		pager.current = pagination.current;
		pager.pageSize = pagination.pageSize;
		const fetchParams = {
			results: pagination.pageSize,
			page: pagination.current,
			sortField: sorter.field,
			sortOrder: sorter.order,
		};

		if (!this.props.fetchAllDataOnStart) {
			// Retrieve data from the server
			this.setState(
				{
					pagination: pager,
					fetchParams,
					filters: mergeFilters,
				},
				() => {
					this.fetch();
				}
			);
		} else {
			// All data is already locally, filter and sort it
			const newColumns = [...this.state.columns];
			newColumns.forEach(columnInfo => {
				if (columnInfo.sorter && sorter.field === columnInfo.dataIndex && sorter.order) {
					columnInfo.sortOrder = sorter.order;
					columnInfo.sorter = function(a, b) {
						// TODO: Create more sorter functions if needed for different types of values
						return a[columnInfo.dataIndex].localeCompare(b[columnInfo.dataIndex]);
					};
				} else {
					columnInfo.sortOrder = false;
				}
			}, this);

			this.setState({
				fetchParams,
				filters: mergeFilters,
				columns: newColumns,
			});
		}
	};

	initColumnsInfo() {
		const { intl } = this.props,
			{ formatMessage } = intl;
		const self = this;

		const columnsFinal = this.props.columns.map(a => Object.assign({}, a));
		columnsFinal.forEach(function(columnInfo) {
			const dataIndex = columnInfo.dataIndex;
			if (columnInfo.globalRegister) {
				columnInfo.render = value => {
					if (typeof value === 'undefined' || value === null) {
						return '';
					}
					let splittedValue;
					if (value === true || value === false) {
						splittedValue = [value];
					} else if (!isNaN(value)) {
						splittedValue = [value];
					} else {
						splittedValue = value.split(',');
					}

					splittedValue.forEach((thisValue, thisIndex) => {
						let globalRegisterArray = columnInfo.globalRegister;
						if (globalRegisterArray.constructor !== Array) {
							globalRegisterArray = [globalRegisterArray];
						}
						for (
							let indexGlobalRegister = 0;
							indexGlobalRegister < globalRegisterArray.length;
							indexGlobalRegister++
						) {
							const valueLabelFound = getLabelFromGlobalDataKey(
								globalRegisterArray[indexGlobalRegister],
								thisValue
							);
							if (valueLabelFound !== null) {
								splittedValue[thisIndex] = handleFormatMessage(valueLabelFound, formatMessage);
								break;
							}
						}
					});
					return splittedValue.join(', ');
				};
			} else if ('typeField' in columnInfo) {
				let valueRenderIfEmpty = null;
				if ('renderIfEmpty' in columnInfo) {
					valueRenderIfEmpty = handleFormatMessage(columnInfo.renderIfEmpty, formatMessage);
				}

				switch (columnInfo.typeField) {
					default:
						columnInfo.render = value => `${this.renderValueOrEmpty(value, valueRenderIfEmpty, value)}`;
						break;
					case 'hidden':
						columnInfo.render = function(/* text, row, index */) {
							return { props: { colSpan: 0 } };
						};
						columnInfo.colSpan = 0;
						break;
					case 'selectmulti':
						columnInfo.render = value => `${this.renderValueOrEmpty(value, valueRenderIfEmpty, value)}`;
						break;
					case 'date':
						// columnInfo.render = value => `${ formatDateToLocale(value) }`;
						columnInfo.render = value =>
							`${this.renderValueOrEmpty(value, valueRenderIfEmpty, formatDateToLocale(value))}`;
						break;
					case 'dateTime':
						columnInfo.render = value =>
							`${this.renderValueOrEmpty(value, valueRenderIfEmpty, formatDateTimeToLocale(value))}`;
						break;
					case 'linuxTsToDateTimeNoSecs':
						// columnInfo.render = value => `${ this.renderValueOrEmpty(value, valueRenderIfEmpty, timeToLocaleTime(value)) }`;
						columnInfo.render = value =>
							`${this.renderValueOrEmpty(
								value,
								valueRenderIfEmpty,
								formatLinuxTsSecsDateTimeNoSecsToLocale(value)
							)}`;
						break;
					case 'dateTimeNoSecs':
						columnInfo.render = value =>
							`${this.renderValueOrEmpty(
								value,
								valueRenderIfEmpty,
								formatDateTimeNoSecsToLocale(value)
							)}`;
						break;
					case 'statistics-no0-avg':
						columnInfo.render = value =>
							`${this.renderValueOrEmpty(
								value,
								valueRenderIfEmpty,
								getResolutionForScale(null, value, 'avg', true)
							)}`;
						break;
				}
			}

			if ('title' in columnInfo) {
				columnInfo.title = handleFormatMessage(columnInfo.title, formatMessage);
			}

			if (columnInfo.filter) {
				columnInfo.filterDropdownVisible = false;
				columnInfo.onFilterDropdownVisibleChange = visible => {
					self.onFilterClicked(dataIndex, visible);
				};
			}
		}, this);
		this.setState({ columns: columnsFinal });
	}

	renderValueOrEmpty(rawValue, valueIfEmpty, value) {
		if (valueIfEmpty === null) {
			return value;
		}
		if (rawValue === null || rawValue === '') {
			return valueIfEmpty;
		}

		return value;
	}

	refresh(resetPaginationAndFilters = false, callOnRefreshProp = false) {
		if (callOnRefreshProp && this.props.onRefreshCall !== null) {
			this.props.onRefreshCall();
		}
		if (!resetPaginationAndFilters) {
			this.fetch();
		} else {
			const newPagination = {
				...this.state.pagination,
				current: 1,
			};
			this.setState(
				{
					pagination: newPagination,
					fetchParams: {},
					searchText: '',
					filters: null,
					filteringColumn: null,
				},
				() => {
					this.fetch();
				}
			);
		}
	}

	actionClicked(actionId, dialogConfirmed = false) {
		const { intl } = this.props,
			{ formatMessage } = intl;
		const self = this;
		/* Ge the action info */
		let actionInfo = {};
		switch (actionId) {
			case 'add':
				actionInfo = {
					...this.props.addFormInfo,
					actionType: 'form',
					oneSelectedNeeded: false,
					multipleSelectedAllowed: true,
				};
				break;
			case 'edit':
				actionInfo = {
					...this.props.editFormInfo,
					actionType: 'form',
					oneSelectedNeeded: true,
					multipleSelectedAllowed: false,
				};
				break;
			case 'exportcsv_1':
			case 'exportcsv_2':
			case 'exportcsv_3':
			case 'exportcsv_4':
			case 'exportcsv_5': {
				const filename = 'Export';
				actionInfo = this.getExtraActionInfo(actionId);
				httpRequest(
					this.props.session,
					this.props.locale,
					actionInfo.apiEndpoint,
					actionInfo.apiTypeMethod,
					actionInfo.apiSendToken,
					{}
				)
					.then(checkStatusJson)
					.then(fileContent => {
						if (typeof fileContent !== 'string' && 'data' in fileContent) {
							fileContent = fileContent.data;
						}
						if (!isEmpty(fileContent) && typeof fileContent !== 'string' && 'file_data' in fileContent) {
							const fileInfo = fileContent;
							startDownloadFile(
								this.props.session,
								fileInfo.file_data,
								fileInfo.file_method,
								fileInfo.file_mime,
								fileInfo.file_size,
								fileInfo.file_name
							);
						} else {
							window.fileDownload(fileContent, `${filename}.csv`, 'application/csv'); // "application/csv;charset=utf-8"
						}
					}, this)
					.catch(err => {
						errorHandler(err);
					}, this);
				return;
			}
			case 'exportcsv': {
				const filename = 'Export';
				httpRequest(
					this.props.session,
					this.props.locale,
					this.props.exportCSVInfo.apiEndpoint,
					this.props.exportCSVInfo.apiTypeMethod,
					this.props.exportCSVInfo.apiSendToken,
					{}
				)
					.then(checkStatusJson)
					.then(fileContent => {
						if (typeof fileContent !== 'string' && 'data' in fileContent) {
							fileContent = fileContent.data;
						}
						if (!isEmpty(fileContent) && typeof fileContent !== 'string' && 'file_data' in fileContent) {
							const fileInfo = fileContent;
							startDownloadFile(
								this.props.session,
								fileInfo.file_data,
								fileInfo.file_method,
								fileInfo.file_mime,
								fileInfo.file_size,
								fileInfo.file_name
							);
						} else {
							window.fileDownload(fileContent, `${filename}.csv`, 'application/csv'); // "application/csv;charset=utf-8"
						}
					}, this)
					.catch(err => {
						errorHandler(err);
					}, this);
				return;
			}
			case 'refresh':
				this.refresh(false, true);
				return;
			default:
				actionInfo = this.getExtraActionInfo(actionId);
				break;
		}

		/* Check if multiple selected and not allowed */
		if (
			(!actionInfo.multipleSelectedAllowed && this.state.selectedRowKeys.length > 1) ||
			(actionInfo.oneSelectedNeeded && this.state.selectedRowKeys.length < 1)
		) {
			return;
		}

		/* Get all current selected rows info */
		const currentSelectedRowsInfo = [];
		if (actionInfo.oneSelectedNeeded) {
			this.state.selectedRowKeys.forEach(function(rowKey, rowIndex) {
				if (
					rowIndex === 0 ||
					(rowIndex >= 1 && actionInfo.multipleSelectedAllowed && actionInfo.actionType !== 'form')
				) {
					const thisCurrentRow = this.getRowWithKey(rowKey);
					currentSelectedRowsInfo.push(thisCurrentRow);
					if (actionInfo.overrideFields) {
						actionInfo.overrideFields.values = thisCurrentRow;
					} else {
						actionInfo.overrideFields = { values: thisCurrentRow };
					}
				}
			}, this);
		}

		if (!dialogConfirmed && actionInfo.askConfirmation) {
			/* Action needs to be confirmed first. Show confimration dialog */
			const confirm = Modal.confirm;
			const confirmationTitle = handleFormatMessage(actionInfo.askConfirmation.title, formatMessage);
			const confirmationBody = handleFormatMessage(actionInfo.askConfirmation.body, formatMessage);
			confirm({
				title: confirmationTitle,
				content: confirmationBody,
				onOk() {
					self.actionClicked(actionId, true);
				},
				onCancel() {},
			});
			return;
		}

		switch (actionInfo.actionType) {
			case 'expand':
				this.expandSelectedRows(this.state.selectedRowKeys);
				break;
			case 'collapse':
				this.collapseSelectedRows(this.state.selectedRowKeys);
				break;
			case 'function':
				actionInfo.actionFunction(this.state.selectedRowKeys);
				break;
			case 'form':
				{
					if (actionInfo.apiEndpoint && this.state.selectedRowKeys.length >= 1) {
						actionInfo.apiEndpoint = actionInfo.apiEndpoint.replace('{key}', this.state.selectedRowKeys[0]);
					}
					const onSuccessFormProps = actionInfo.onSuccessForm;
					actionInfo.isModalForm = true;
					actionInfo.isFormVisible = true;
					if (!onSuccessFormProps) {
						actionInfo.onSuccessForm = function(json, formFieldsValues) {
							self.onSuccessFormAction(json, formFieldsValues);
						};
					} else {
						actionInfo.onSuccessForm = function(json, formFieldsValues) {
							self.onSuccessFormAction(json, formFieldsValues);
							onSuccessFormProps(json, formFieldsValues);
						};
					}

					this.setState({
						isModalFormVisible: false,
						modalFormInfo: actionInfo,
					});
					setTimeout(() => {
						self.setState({
							isModalFormVisible: true,
						});
					}, 10);
				}
				break;
			case 'apicall':
				{
					/* check if confirmation, etc.. */
					let formFieldsValues = {};
					if (actionInfo.apiSendBody) {
						formFieldsValues = { ...actionInfo.apiSendBody };
					}

					if (actionInfo.apiSendFieldValues) {
						const valuesToSend = [];
						let fieldSource = this.props.columnKey;
						if ('fieldSource' in actionInfo.apiSendFieldValues) {
							fieldSource = actionInfo.apiSendFieldValues.fieldSource;
						}
						let fieldTarget = fieldSource;
						if ('fieldTarget' in actionInfo.apiSendFieldValues) {
							fieldTarget = actionInfo.apiSendFieldValues.fieldTarget;
						}
						let separateValue = 'comma';
						if ('separateValue' in actionInfo.apiSendFieldValues) {
							separateValue = actionInfo.apiSendFieldValues.separateValue;
						}
						currentSelectedRowsInfo.forEach((rowContent /* , rowIndex */) => {
							valuesToSend.push(rowContent[fieldSource]);
						}, this);

						let valuesToSendForm = '';
						switch (separateValue) {
							default:
							case 'comma':
								valuesToSendForm = valuesToSend.join(',');
								break;
							case 'array':
								valuesToSendForm = valuesToSend;
								break;
						}
						formFieldsValues[fieldTarget] = valuesToSendForm;
					}
					for (let indexCurrentRow = 0; indexCurrentRow < currentSelectedRowsInfo.length; indexCurrentRow++) {
						const currentRowKey = currentSelectedRowsInfo[indexCurrentRow][this.props.columnKey];
						const apiEndPointToUse = actionInfo.apiEndpoint.replace('{key}', currentRowKey);

						httpRequest(
							this.props.session,
							this.props.locale,
							apiEndPointToUse,
							actionInfo.apiTypeMethod,
							actionInfo.apiSendToken,
							formFieldsValues
						)
							.then(checkStatusJson)
							.then(json => {
								const onSuccessApiCallProps = actionInfo.onSuccessApiCall;
								if (!onSuccessApiCallProps) {
									self.onSuccessApiCallAction(json);
								} else {
									self.onSuccessApiCallAction(json);
									onSuccessApiCallProps(json);
								}
								/* if (!isEmpty(json) && ('data' in json) && (json.data !== null) && ('file_data' in json.data)) {
							const fileInfo = json.data;
							startDownloadFile(this.props.session, fileInfo.file_data, fileInfo.file_method, fileInfo.file_mime, fileInfo.file_size, fileInfo.file_name);
						}
						else {
							self.fetch();
						} */
							}, this)
							.catch(err => {
								errorHandler(err);
							}, this);
						if (!actionInfo.apiOneRequestForEachId) {
							break;
						}
					}
				}
				break;
			default:
				break;
		}
	}

	getColumnInfoOfProps(columnDataIndex) {
		let toReturnColumnInfo = null;
		this.props.columns.forEach(columnInfo => {
			if (columnInfo.dataIndex === columnDataIndex) {
				toReturnColumnInfo = columnInfo;
			}
		}, this);
		return toReturnColumnInfo;
	}
}

SmartTable.defaultProps = {
	apiEndpoint: '',
	apiTypeMethod: 'get',
	apiSendToken: true,
	apiSendBody: {},
	columns: [],
	columnKey: undefined,

	editFormInfo: {},
	addFormInfo: {},
	exportCSVInfo: {},
	showRefreshAction: true,

	onRefreshCall: null,
	isTableVisible: true,
	bordered: true,
	size: 'default',
	expandedRowRender: false,
	title: undefined, // () => 'Here is title',
	footer: undefined, // () => 'Here is footer',
	showHeader: true,
	scroll: undefined,
	fetchAllDataOnStart: false,

	fixedFilters: [], // [{"field":"flagTrash","op":"eq","data":0}...]
	typeRetrieveData: 'tds', // 'tds' or 'proceq'
	typeSortingFiltering: 'dataset', // 'dataset', 'simplified' or 'proceq'
	startPageLength: 20,
	pageSizeOptions: ['10', '20', '30', '40', '50', '100'],
	allowSelectPageLength: true,
};

const mapStateToProps = state => ({
	session: state.session,
	locale: state.locale,
	globalregister: state.globalregister,
});

export default connect(mapStateToProps)(injectIntl(SmartTable));
