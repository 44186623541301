// @flow
import React from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { Col } from 'antd';
import { getLabelFromGlobalDataKey } from 'utils/registryHelper';
import styles from 'components/measurements/EquotipReadings.styl';
import {
	rgbToHex,
	getResolutionForScale,
	getSchmidtScaleIdOfFromFactorAndUnitId,
	getStandardNameOfStandardId,
	getMaterialNameOfMaterialId,
	getSchmidtCompositeSurfaceId,
	getScaleInfoOfScaleId,
} from './conversionsHelper';
import handleFormatMessage from './handleFormatMessage';

const styleColors = {
	NORMAL: [0, 0, 0],
	PROCEQ: [0, 132, 139],
	EXCLUDED: [184, 184, 184],
	NOCONVERSION: [170, 170, 170],
	OUTOFRANGE: [255, 127, 0],
	AREALIMITS: [0, 154, 225, 8],
	LINELIMITS: [209, 209, 209],
};

export const getDataForGraph = (
	currentData: Object[],
	infoForDataMeasurements: Object,
	infoIndexes: Object,
	thresholdLoValue: number,
	thresholdHiValue: number
) => {
	const mainValueIndex = infoIndexes.mainValueIndex;
	const removedValueIndex = infoIndexes.removedValueIndex;
	const pointLabelIndex = infoIndexes.pointLabelIndex;
	const outlierTypeValueIndex = infoIndexes.outlierTypeValueIndex; /* CHANGED FOR SCHMIDT */
	const misc = infoForDataMeasurements.MISCELLANEOUS;

	let minValueFound = thresholdLoValue;
	let maxValueFound = thresholdHiValue;
	const colors = [];
	const xAxis = [];
	const data = [];
	for (let rowDataIndex = 0; rowDataIndex < currentData.length; rowDataIndex++) {
		const thisRowData = currentData[rowDataIndex];
		let colorStyle = 'PROCEQ';
		let valueOfMeasurement = thisRowData[mainValueIndex];
		if (valueOfMeasurement === null) {
			colorStyle = 'NOCONVERSION';
			valueOfMeasurement = <FormattedMessage id="Proceq.ExportFieldNoConversion" />;
		} else {
			if (valueOfMeasurement < minValueFound || typeof minValueFound === 'undefined') {
				/* CHANGED FOR SCHMIDT */
				minValueFound = valueOfMeasurement;
			}
			if (valueOfMeasurement > maxValueFound || typeof maxValueFound === 'undefined') {
				/* CHANGED FOR SCHMIDT */
				maxValueFound = valueOfMeasurement;
			}

			if (
				thisRowData[removedValueIndex] === 1 ||
				(outlierTypeValueIndex >= 0 && thisRowData[outlierTypeValueIndex] > 0)
			) {
				/* CHANGED FOR SCHMIDT */
				colorStyle = 'EXCLUDED';
			} else {
				// Check Above/Below limit
				if (misc.UPPERLIMIT.VALUE !== null) {
					if (valueOfMeasurement > parseFloat(misc.UPPERLIMIT.VALUE)) {
						colorStyle = 'OUTOFRANGE';
					}
				}

				if (misc.LOWERLIMIT.VALUE !== null) {
					if (valueOfMeasurement < parseFloat(misc.LOWERLIMIT.VALUE)) {
						colorStyle = 'OUTOFRANGE';
					}
				}
			}
		}
		data.push(thisRowData[mainValueIndex]);
		xAxis.push(thisRowData[pointLabelIndex]);
		colors.push(rgbToHex(styleColors[colorStyle][0], styleColors[colorStyle][1], styleColors[colorStyle][2]));
	}
	/* CHANGED FOR SCHMIDT */
	if (typeof minValueFound === 'undefined') {
		minValueFound = 0;
	}
	if (typeof maxValueFound === 'undefined') {
		maxValueFound = 1;
	}
	return {
		data,
		xAxis,
		colors,
		minValueFound,
		maxValueFound,
	};
};

export const doExtraInfoColumn = (
	toShowKeyValues: string[],
	title: string,
	infoForDataMeasurements: Object,
	sizeColumn?: number = 12
) => {
	if (toShowKeyValues.length === 0) return null;
	return (
		<Col span={sizeColumn}>
			<div className={styles.extrainfo_container}>
				<div className={styles.category_properties}>
					<FormattedMessage id={title} />
				</div>
				{toShowKeyValues.map(thisToShowKeyValue => {
					let valueFound = infoForDataMeasurements;
					const strKeyExploded = thisToShowKeyValue.split('.');
					for (let indexExploded = 0; indexExploded < strKeyExploded.length; indexExploded++) {
						const thisSubKey = strKeyExploded[indexExploded];
						if (thisSubKey in valueFound && valueFound[thisSubKey] != null) {
							valueFound = valueFound[thisSubKey];
						} else {
							valueFound = null;
							break;
						}
					}
					if (valueFound != null && 'VALUE' in valueFound && valueFound.VALUE != null) {
						return (
							<div key={valueFound.TITLE} className={styles.property_container}>
								<div className={styles.title_property}>
									{<FormattedMessage id={valueFound.TITLE} />}
								</div>
								<div className={styles.value_property}>{valueFound.VALUE}</div>
							</div>
						);
					}

					return null;
				}, this)}
			</div>
		</Col>
	);
};

export const getAllParsedDataNeeded = (
	datainfo: ?Object,
	metadata: ?Object,
	productType: ProductCode,
	formatMessage: Function
) => {
	if (datainfo == null) {
		return null;
	}

	const pointLabelIndex = datainfo.description.indexOf('pointLabel');
	const primaryValueIndex = datainfo.description.indexOf('primaryValue');
	const secondaryValueIndex = datainfo.description.indexOf('secondaryValue');
	const removedValueIndex = datainfo.description.indexOf('removed');
	const outlierTypeValueIndex = datainfo.description.indexOf('outlierType'); /* CHANGED FOR SCHMIDT */

	const primarySchmidtScaleId = getSchmidtScaleIdOfFromFactorAndUnitId(null, null);
	const primaryEquotipScaleId = 2;

	// Show Settings and Statistics
	const thisMeasurementObject = metadata;
	if (!thisMeasurementObject) return null;

	thisMeasurementObject.dataInfo = datainfo;

	const infoForDataMeasurements = {};

	const MISCELLANEOUS: Object = {
		EXPORTDATE: {
			TITLE: handleFormatMessage('Proceq.ExportFieldExportDate', formatMessage),
		},
	};

	/* Init scales data */
	infoForDataMeasurements.SCALES = {
		PRIMARY: {},
		SECONDARY: {},
	};

	let isVerificationSerie = false;
	if (
		'properties' in thisMeasurementObject &&
		'seriesType' in thisMeasurementObject.properties &&
		thisMeasurementObject.properties.seriesType === 'verificationSeries'
	) {
		isVerificationSerie = true;
	}

	// Get the table data
	let units = '';
	// secondary not on the first position, so it will replace some values taken from the primary like the limits, etc..
	let tablesIndexToCheck = ['primary', 'secondary'];
	if (isVerificationSerie) {
		tablesIndexToCheck = ['primary'];
	}
	for (let indexTableCheck = 0; indexTableCheck < tablesIndexToCheck.length; indexTableCheck++) {
		const thisTableIndex = tablesIndexToCheck[indexTableCheck];

		const scaleId = getSchmidtScaleIdOfFromFactorAndUnitId(
			thisMeasurementObject.settings[thisTableIndex].scaleId ||
				thisMeasurementObject.settings[thisTableIndex].unitId,
			thisMeasurementObject.settings[thisTableIndex].formFactorId
		);

		/* CHANGED FOR SCHMIDT */
		switch (productType) {
			case 'EQUOTIP':
				units = thisMeasurementObject.settings[thisTableIndex].unit;
				break;
			case 'SSL':
			case 'SCHMIDT': {
				units = getScaleInfoOfScaleId(formatMessage, scaleId, productType).scaleUnits;
				break;
			}
		}

		if (
			'settings' in thisMeasurementObject &&
			thisTableIndex in thisMeasurementObject.settings &&
			thisMeasurementObject.settings[thisTableIndex] !== null
		) {
			const thisTableInfo = {
				TYPE:
					'scaleId' in thisMeasurementObject.settings[thisTableIndex]
						? getScaleInfoOfScaleId(
								formatMessage,
								thisMeasurementObject.settings[thisTableIndex].scaleId,
								productType
						  ).scaleName
						: getScaleInfoOfScaleId(formatMessage, 2, productType).scaleName,
				TITLE: thisMeasurementObject.settings[thisTableIndex].unit,
				THRESHOLDS: [],
				POINTS: [],
			};
			if (
				'limits' in thisMeasurementObject.settings[thisTableIndex] &&
				'low' in thisMeasurementObject.settings[thisTableIndex].limits &&
				thisMeasurementObject.settings[thisTableIndex].limits.low !== null
			) {
				thisTableInfo.THRESHOLDS.push({
					TITLE: 'LOWERLIMIT',
					VALUE: getResolutionForScale(
						units,
						thisMeasurementObject.settings[thisTableIndex].limits.low,
						'low'
					),
				});
			}
			if (
				'limits' in thisMeasurementObject.settings[thisTableIndex] &&
				'high' in thisMeasurementObject.settings[thisTableIndex].limits &&
				thisMeasurementObject.settings[thisTableIndex].limits.high !== null
			) {
				thisTableInfo.THRESHOLDS.push({
					TITLE: 'UPPERLIMIT',
					VALUE: getResolutionForScale(
						units,
						thisMeasurementObject.settings[thisTableIndex].limits.high,
						'high'
					),
				});
			}

			MISCELLANEOUS.LIMITS = {
				TITLE: handleFormatMessage('Proceq.ExportFieldLimits', formatMessage),
				VALUE: `${formatMessage({ id: 'Proceq.ExportFieldHigh' })}: ${
					'limits' in thisMeasurementObject.settings[thisTableIndex] &&
					'high' in thisMeasurementObject.settings[thisTableIndex].limits &&
					thisMeasurementObject.settings[thisTableIndex].limits.high !== null
						? `${getResolutionForScale(
								units,
								thisMeasurementObject.settings[thisTableIndex].limits.high,
								'high'
						  )} ${units}`
						: formatMessage({ id: 'Proceq.ExportFieldOff' })
				} , ${formatMessage({ id: 'Proceq.ExportFieldLow' })}: ${
					'limits' in thisMeasurementObject.settings[thisTableIndex] &&
					'low' in thisMeasurementObject.settings[thisTableIndex].limits &&
					thisMeasurementObject.settings[thisTableIndex].limits.low !== null
						? `${getResolutionForScale(
								units,
								thisMeasurementObject.settings[thisTableIndex].limits.low,
								'low'
						  )} ${units}`
						: formatMessage({ id: 'Proceq.ExportFieldOff' })
				}`,
			};

			MISCELLANEOUS.LOWERLIMIT = {
				TITLE: handleFormatMessage('Proceq.ExportFieldLowerLimit', formatMessage),
				VALUE:
					'limits' in thisMeasurementObject.settings[thisTableIndex] &&
					'low' in thisMeasurementObject.settings[thisTableIndex].limits &&
					thisMeasurementObject.settings[thisTableIndex].limits.low !== null
						? getResolutionForScale(units, thisMeasurementObject.settings[thisTableIndex].limits.low, 'low')
						: null,
			};
			MISCELLANEOUS.LOWERLIMITWITHUNITS = {
				TITLE: handleFormatMessage('Proceq.ExportFieldLowerLimit', formatMessage),
				VALUE:
					MISCELLANEOUS.LOWERLIMIT.VALUE !== null ? (
						`${MISCELLANEOUS.LOWERLIMIT.VALUE} ${units}`
					) : (
						<FormattedMessage id="Proceq.ExportFieldOff" />
					),
			};

			MISCELLANEOUS.UPPERLIMIT = {
				TITLE: handleFormatMessage('Proceq.ExportFieldUpperLimit', formatMessage),
				VALUE:
					'limits' in thisMeasurementObject.settings[thisTableIndex] &&
					'high' in thisMeasurementObject.settings[thisTableIndex].limits &&
					thisMeasurementObject.settings[thisTableIndex].limits.high !== null
						? getResolutionForScale(
								units,
								thisMeasurementObject.settings[thisTableIndex].limits.high,
								'high'
						  )
						: null,
			};
			MISCELLANEOUS.UPPERLIMITWITHUNITS = {
				TITLE: handleFormatMessage('Proceq.ExportFieldUpperLimit', formatMessage),
				VALUE:
					MISCELLANEOUS.UPPERLIMIT.VALUE !== null ? (
						`${MISCELLANEOUS.UPPERLIMIT.VALUE} ${units}`
					) : (
						<FormattedMessage id="Proceq.ExportFieldOff" />
					),
			};

			/* Search for Columsn Index: pointLabel and primaryVal or secondaryVal */
			let indexDataColumnForPointLabel = null;
			let indexDataColumnForPointVal = null;
			thisMeasurementObject.dataInfo.description.forEach((valueColumn, indexColumn) => {
				if (valueColumn === 'pointLabel') {
					indexDataColumnForPointLabel = indexColumn;
				}
				if (valueColumn === `${thisTableIndex}Value`) {
					indexDataColumnForPointVal = indexColumn;
				}
			});

			if (indexDataColumnForPointLabel === null || indexDataColumnForPointVal === null) {
				// throw new Exception('ErrorDataFormat');
			}

			/* Loop trhough the whole date and take the label and point values */

			thisMeasurementObject.dataInfo.dataUncompressed.forEach(thisDataRow => {
				thisTableInfo.POINTS.push({
					TITLE: thisDataRow[indexDataColumnForPointLabel],
					VALUE: thisDataRow[indexDataColumnForPointVal], // number_format($thisDataRow[$indexDataColumnForPointVal], 1)
				});
			});

			/* Add the statistics */
			const statisitcsToDisplay = ['count', 'stdev', 'range', 'relSpan', 'max', 'min', 'avg'];
			const thisScaleStatistics = {};

			// eslint-disable-next-line no-loop-func
			statisitcsToDisplay.forEach(keyStat => {
				let valStat = '--';
				let thisNotDefined = true;
				if (
					keyStat in thisMeasurementObject.statistics[thisTableIndex] &&
					thisMeasurementObject.statistics[thisTableIndex][keyStat] !== null
				) {
					valStat = thisMeasurementObject.statistics[thisTableIndex][keyStat];
					thisNotDefined = false;
				}
				let thisLabelStatistics = keyStat;
				let thisValueStatistics = `${valStat}`; // number_format($valStat, 1);
				const indexForArray = keyStat.toUpperCase();
				const unitToUseThis = units;
				valStat = getResolutionForScale(units, valStat, keyStat);
				switch (keyStat) {
					case 'count':
						thisLabelStatistics = 'Proceq.ExportFieldReadings';
						thisValueStatistics = `${valStat}`; // intval($valStat);

						break;
					case 'stdev':
						if (!thisNotDefined) {
							thisValueStatistics = `${valStat} ${unitToUseThis}`;
						}
						thisLabelStatistics = 'Proceq.ExportFieldStandardDeviation'; // 'σ';

						break;
					case 'range':
						if (!thisNotDefined) {
							thisValueStatistics = `${valStat} ${unitToUseThis}`;
						}

						thisLabelStatistics = 'Proceq.ExportFieldRange';

						break;
					case 'relSpan':
						if (!thisNotDefined) {
							thisValueStatistics = `${valStat}%`;
						}

						thisLabelStatistics = 'Proceq.ExportFieldRelativeSpan';

						break;
					case 'max':
						if (!thisNotDefined) {
							thisValueStatistics = `${valStat} ${unitToUseThis}`;
						}

						thisLabelStatistics = 'Proceq.ExportFieldMaximum';

						break;
					case 'min':
						if (!thisNotDefined) {
							thisValueStatistics = `${valStat} ${unitToUseThis}`;
						}

						thisLabelStatistics = 'Proceq.ExportFieldMinimum';

						break;
					case 'avg':
						if (!thisNotDefined) {
							thisValueStatistics = `${valStat} ${unitToUseThis}`;
						}

						thisLabelStatistics = 'Proceq.ExportFieldAverage';
						break;
				}
				thisScaleStatistics[indexForArray] = {
					TITLE: thisLabelStatistics,
					VALUE: thisValueStatistics,
				};
			});

			/* CHANGED FOR SCHMIDT */
			switch (productType) {
				case 'EQUOTIP':
					infoForDataMeasurements.SCALES[thisTableIndex.toUpperCase()] = {
						TABLE: thisTableInfo,
						STATISTICS: thisScaleStatistics,
						UNIT: {
							TITLE: 'Proceq.ExportFieldUnit',
							VALUE: thisMeasurementObject.settings[thisTableIndex].unit,
						},
						NAME: {
							TITLE: 'Proceq.ExportFieldName',
							VALUE:
								'scaleId' in thisMeasurementObject.settings[thisTableIndex]
									? getScaleInfoOfScaleId(
											formatMessage,
											thisMeasurementObject.settings[thisTableIndex].scaleId,
											productType
									  ).scaleName
									: getScaleInfoOfScaleId(formatMessage, primaryEquotipScaleId, productType)
											.scaleName,
						},
					};

					break;
				case 'SSL':
				case 'SCHMIDT': {
					const scaleIdUsed = scaleId || primarySchmidtScaleId;

					infoForDataMeasurements.SCALES[thisTableIndex.toUpperCase()] = {
						TABLE: thisTableInfo,
						STATISTICS: thisScaleStatistics,
						UNIT: {
							TITLE: 'Proceq.ExportFieldUnit',
							VALUE: getScaleInfoOfScaleId(formatMessage, scaleIdUsed, productType).scaleUnits,
						},
						NAME: {
							TITLE: 'Proceq.ExportFieldName',
							VALUE: getScaleInfoOfScaleId(formatMessage, scaleIdUsed, productType).scaleName,
						},
					};
					break;
				}
			}
		}
	}

	/* Add the properties data */
	let properties;
	if ('properties' in thisMeasurementObject) {
		properties = {
			SERIESNAME: {
				TITLE: '',
				VALUE:
					'seriesName' in thisMeasurementObject.properties
						? thisMeasurementObject.properties.seriesName
						: null,
			},
			/* 'FOLDERNAME' : {
			 'TITLE':"Folder name",
			 'VALUE':thisMeasurementObject["properties"]["folderName"]
			 }, */
			MEASUREMENTDATE: {
				TITLE: 'Proceq.ExportFieldMeasurementDate',
				VALUE: 'created' in thisMeasurementObject.properties ? thisMeasurementObject.properties.created : null,
			},
			LOCATIONLAT: {
				TITLE: 'Proceq.ExportFieldLocationLatitude',
				VALUE: 'lat' in thisMeasurementObject.properties ? thisMeasurementObject.properties.location.lat : null,
			},
			LOCATIONLONG: {
				TITLE: 'Proceq.ExportFieldLocationLongitude',
				VALUE:
					'long' in thisMeasurementObject.properties ? thisMeasurementObject.properties.location.long : null,
			},
			PROBENAME: {
				TITLE: 'Proceq.ExportFieldProbeType',
				VALUE: 'name' in thisMeasurementObject.properties ? thisMeasurementObject.properties.probe.name : null,
			},
			PROBESERIALNUMBER: {
				TITLE: 'Proceq.ExportFieldProbeSerialNumber',
				VALUE:
					'serialNumber' in thisMeasurementObject.properties
						? thisMeasurementObject.properties.probe.serialNumber
						: null,
			},
			PROBEBTSERIALNUMBER: {
				TITLE: 'Proceq.ExportFieldProbeBTSerialNumber',
				VALUE:
					'btSerialNumber' in thisMeasurementObject.properties
						? thisMeasurementObject.properties.probe.btSerialNumber
						: null,
			},
			PROBELASTVERIFICATIONDATE: {
				TITLE: 'Proceq.ExportFieldProbeLastVerificationDate',
				VALUE:
					'lastVerificationDate' in thisMeasurementObject.properties
						? thisMeasurementObject.properties.probe.lastVerificationDate
						: null,
			},
		};
	}

	let settings;
	if ('settings' in thisMeasurementObject) {
		/* CHANGED FOR SCHMIDT */
		const SETTINGS: Object = {};
		SETTINGS.STANDARD = {
			TITLE: 'Proceq.ExportFieldStandard',
			VALUE:
				'standardId' in thisMeasurementObject.settings
					? getStandardNameOfStandardId(
							null,
							formatMessage,
							thisMeasurementObject.settings.standardId,
							productType
					  )
					: getStandardNameOfStandardId(null, formatMessage, 1, productType),
		};

		switch (productType) {
			case 'EQUOTIP':
				SETTINGS.MATERIAL = {
					TITLE: 'Proceq.ExportFieldMaterial',
					VALUE:
						'materialId' in thisMeasurementObject.settings
							? getMaterialNameOfMaterialId(
									null,
									formatMessage,
									thisMeasurementObject.settings.materialId
							  )
							: getMaterialNameOfMaterialId(null, formatMessage, 2),
				};

				if (
					thisMeasurementObject.settings.customMaterial != null &&
					thisMeasurementObject.settings.customMaterial.customMaterialName != null
				)
					SETTINGS.MATERIAL.VALUE = thisMeasurementObject.settings.customMaterial.customMaterialName;

				break;
			case 'SSL':
			case 'SCHMIDT': {
				const surfaceStrenAndCorrEnum = getSchmidtCompositeSurfaceId(
					thisMeasurementObject.settings.secondary.strengthId,
					thisMeasurementObject.settings.secondary.surfaceCorrectionId
				);

				SETTINGS.CONVERSIONCURVE = {
					TITLE: 'Proceq.ExportFieldConversionCurve',
					VALUE: null,
				};
				if (
					thisMeasurementObject.settings.customMaterial != null &&
					thisMeasurementObject.settings.customMaterial.customMaterialName != null
				) {
					SETTINGS.CONVERSIONCURVE.VALUE = thisMeasurementObject.settings.customMaterial.customMaterialName;
				} else if (typeof thisMeasurementObject.settings.secondary.conversionCurveId !== 'undefined') {
					//  <FormattedMessage id="Proceq.ExportFieldLowerLimit" />,
					SETTINGS.CONVERSIONCURVE.VALUE = (
						<FormattedMessage
							id={getLabelFromGlobalDataKey(
								'convcurveschmidt',
								thisMeasurementObject.settings.secondary.conversionCurveId
							)}
						/>
					);
				} else if (surfaceStrenAndCorrEnum != null) {
					SETTINGS.CONVERSIONCURVE.VALUE = (
						<FormattedMessage
							id={getLabelFromGlobalDataKey('surfacestrengthandcorrschmidt', surfaceStrenAndCorrEnum)}
						/>
					);
				} else if (thisMeasurementObject.settings.secondary.surfaceConditionId != null) {
					SETTINGS.CONVERSIONCURVE.VALUE = (
						<FormattedMessage
							id={getLabelFromGlobalDataKey(
								'surfaceconditionschmidt',
								thisMeasurementObject.settings.secondary.surfaceConditionId
							)}
						/>
					);
				}

				SETTINGS.CARBODEPTH = {
					TITLE: 'Proceq.ExportFieldCarbonationDepth',
					VALUE:
						thisMeasurementObject.settings.secondary.carbonationDepth != null
							? handleFormatMessage('ValueCarbonationDepthMms', formatMessage, [
									thisMeasurementObject.settings.secondary.carbonationDepth,
							  ])
							: null,
				};
				SETTINGS.CONCRETEAGE = {
					TITLE: 'Proceq.ExportFieldConcreteAge',
					VALUE:
						thisMeasurementObject.settings.secondary.age != null
							? handleFormatMessage('ValueConcreteAgeDays', formatMessage, [
									thisMeasurementObject.settings.secondary.age,
							  ])
							: null,
				};
				break;
			}
		}

		if (typeof thisMeasurementObject.settings.triggerLoadId !== 'undefined') {
			SETTINGS.TESTLOADINFO = {
				TITLE: 'Proceq.ExportColumnTestLoadInfo',
				VALUE: `UCI HV${thisMeasurementObject.settings.triggerLoadId}`,
			};
		}

		if (
			typeof thisMeasurementObject.properties.seriesType !== 'undefined' &&
			thisMeasurementObject.properties.seriesType === 'verificationSeries'
		) {
			/* Verification serie */
			let enumToCheckVerification = null;
			switch (productType) {
				case 'EQUOTIP':
					enumToCheckVerification = 'verificationcriteriaequotip';
					break;
				case 'SSL':
				case 'SCHMIDT':
					enumToCheckVerification = 'verificationcriteriaschmidt';
					break;
			}
			if (enumToCheckVerification) {
				const valueEnumStandardCriteria = getLabelFromGlobalDataKey(
					enumToCheckVerification,
					thisMeasurementObject.settings.standardId
				);
				if (valueEnumStandardCriteria !== null) {
					infoForDataMeasurements.PROPERTIES.VERIFICATIONCRITERIA = {
						TITLE: 'Proceq.ExportColumnVerificationCriteria',
						VALUE: <FormattedMessage id="valueEnumStandardCriteria" />,
					};
				}
			}

			SETTINGS.VERIFICATIONSTD = {
				TITLE: 'Proceq.ExportColumnVerificationStd',
				VALUE: thisMeasurementObject.settings.standard,
			};
			if (typeof thisMeasurementObject.settings.testBlock !== 'undefined') {
				SETTINGS.TESTBLOCKNAME = {
					TITLE:
						productType !== 'SCHMIDT'
							? 'Proceq.ExportColumnTestBlockName'
							: 'Proceq.ExportColumnCalibrationAnvilName',
					VALUE:
						thisMeasurementObject.settings.testBlock.testBlockName != null
							? thisMeasurementObject.settings.testBlock.testBlockName
							: null,
				};

				SETTINGS.TESTBLOCKSN = {
					TITLE:
						productType !== 'SCHMIDT'
							? 'Proceq.ExportColumnTestBlockSN'
							: 'Proceq.ExportColumnCalibrationAnvilSN',
					VALUE:
						thisMeasurementObject.settings.testBlock.testBlockSerialNumber != null
							? thisMeasurementObject.settings.testBlock.testBlockSerialNumber
							: null,
				};

				SETTINGS.TESTBLOCKNOMINALHARDNESS = {
					TITLE:
						productType !== 'SCHMIDT'
							? 'Proceq.ExportColumnTestBlockNominalHardness'
							: 'Proceq.ExportColumnCalibrationAnvilReboundValue',
					VALUE: `${thisMeasurementObject.settings.testBlock.testBlockValue} ${infoForDataMeasurements.SCALES.PRIMARY.UNIT.VALUE}`,
				};
				SETTINGS.TESTBLOCKTOLERANCE = {
					TITLE:
						productType !== 'SCHMIDT'
							? 'Proceq.ExportColumnTestBlockTolerance'
							: 'Proceq.ExportColumnCalibrationAnvilTolerance',
					VALUE: `${thisMeasurementObject.settings.testBlock.testBlockMargin} ${infoForDataMeasurements.SCALES.PRIMARY.UNIT.VALUE}`,
				};
			}

			let valueToDisplay = null;
			if (typeof thisMeasurementObject.properties.verificationResult !== 'undefined') {
				valueToDisplay = thisMeasurementObject.properties.verificationResult;
				if (getLabelFromGlobalDataKey('verificationstatuses', valueToDisplay) !== null) {
					valueToDisplay = handleFormatMessage(
						getLabelFromGlobalDataKey('verificationstatuses', valueToDisplay),
						formatMessage
					);
				}
			}

			infoForDataMeasurements.PROPERTIES.VERIFICATIONRESULT = {
				TITLE: 'Proceq.ExportColumnTestVerificationResult',
				VALUE: valueToDisplay,
			};
		}
		settings = SETTINGS;
	} else {
		settings = infoForDataMeasurements.SETTINGS;
	}

	let mainScale = 'PRIMARY';
	if ('SECONDARY' in infoForDataMeasurements.SCALES && !isVerificationSerie) {
		mainScale = 'SECONDARY';
	}

	let mainValueIndex = primaryValueIndex;
	let mainTableIndex = 'primary';
	if (secondaryValueIndex >= 0 && !isVerificationSerie) {
		mainValueIndex = secondaryValueIndex;
		mainTableIndex = 'secondary';
	}

	let thresholdLoValue = null;
	let thresholdHiValue = null;
	if (infoForDataMeasurements.SCALES[mainScale.toUpperCase()].TABLE.THRESHOLDS.length === 2) {
		thresholdLoValue = infoForDataMeasurements.SCALES[mainScale.toUpperCase()].TABLE.THRESHOLDS[0].VALUE;
		thresholdHiValue = infoForDataMeasurements.SCALES[mainScale.toUpperCase()].TABLE.THRESHOLDS[1].VALUE;
	}
	const infoIndexes = {
		mainValueIndex,
		mainTableIndex,
		removedValueIndex,
		pointLabelIndex,
		outlierTypeValueIndex /* CHANGED FOR SCHMIDT */,
	};

	const toReturn = {
		infoIndexes,
		infoForDataMeasurements: {
			...infoForDataMeasurements,
			PROPERTIES: properties,
			MISCELLANEOUS,
			SETTINGS: settings,
		},
		thisMeasurementObject,
		thresholdLoValue,
		thresholdHiValue,
		units,
		mainScale,
	};

	return toReturn;
};

export const doMeasurementsDataColumn = (dataForGraph: Object, units: string) => (
	<Col xs={8} lg={6}>
		<div className={classNames(styles.container_measurements_info, styles.extrainfo_container)}>
			<div className={styles.category_properties}>
				<FormattedMessage id="Proceq.ExportFieldMeasurements" />
			</div>
			<div className={styles.container_allmeasurements}>
				{dataForGraph.data.map(
					// eslint-disable-next-line react/no-array-index-key
					(thisDataEntry, indexData) => (
						<div key={indexData}>
							<div className={styles.container_measurement}>
								<div className={styles.index_measurement}> {dataForGraph.xAxis[indexData]} </div>
								{
									<div
										className={styles.value_measurement}
										style={{ color: dataForGraph.colors[indexData] }}
									>
										{' '}
										{thisDataEntry === null ? (
											<FormattedMessage id="Proceq.ExportFieldNoConversion" />
										) : (
											`${getResolutionForScale(units, thisDataEntry)} ${units}`
										)}
									</div>
								}
							</div>
						</div>
					),
					this
				)}
			</div>
		</div>
	</Col>
);

export const getStatisticsParametersForProduct = (mainScale: string, productType: ProductCode) => {
	let parameters = [];
	switch (productType) {
		case 'EQUOTIP':
			parameters = [
				`SCALES.${mainScale}.STATISTICS.AVG`,
				`SCALES.${mainScale}.STATISTICS.COUNT`,
				`SCALES.${mainScale}.STATISTICS.STDEV`,
				`SCALES.${mainScale}.STATISTICS.MIN`,
				`SCALES.${mainScale}.STATISTICS.MAX`,
				`SCALES.${mainScale}.STATISTICS.RANGE`,
				`SCALES.${mainScale}.STATISTICS.RELSPAN`,
			];
			break;

		case 'SSL':
		case 'SCHMIDT':
			parameters = [
				`SCALES.${mainScale}.STATISTICS.AVG`,
				`SCALES.${mainScale}.STATISTICS.COUNT`,
				`SCALES.${mainScale}.STATISTICS.MIN`,
				`SCALES.${mainScale}.STATISTICS.MAX`,
			];
			break;
	}
	return parameters;
};

export const getSettingsParametersForProduct = (
	mainScale: string,
	productType: ProductCode,
	isVerificationMode: boolean
) => {
	let parameters = [];
	if (isVerificationMode) {
		parameters = [
			'MISCELLANEOUS.UPPERLIMITWITHUNITS',
			'MISCELLANEOUS.LOWERLIMITWITHUNITS',
			'PROPERTIES.PROBENAME',
			'PROPERTIES.VERIFICATIONCRITERIA',
			'SETTINGS.VERIFICATIONSTD',
			'SETTINGS.TESTBLOCKNAME',
			'SETTINGS.TESTBLOCKSN',
			'SETTINGS.TESTLOADINFO',
			'SETTINGS.TESTBLOCKNOMINALHARDNESS',
			'SETTINGS.TESTBLOCKTOLERANCE',
			'PROPERTIES.VERIFICATIONRESULT',
		];
	} else {
		switch (productType) {
			case 'EQUOTIP':
				parameters = [
					'MISCELLANEOUS.UPPERLIMITWITHUNITS',
					'MISCELLANEOUS.LOWERLIMITWITHUNITS',
					'SETTINGS.MATERIAL',
					`SCALES.${mainScale}.NAME`,
					`SCALES.${mainScale}.UNIT`,
					'PROPERTIES.PROBENAME',
					'PROPERTIES.PROBESERIALNUMBER',
					'PROPERTIES.PROBEBTSERIALNUMBER',
					'PROPERTIES.PROBELASTVERIFICATIONDATE',
					'SETTINGS.TESTLOADINFO',
				];
				break;
			case 'SSL':
			case 'SCHMIDT':
				parameters = [
					'MISCELLANEOUS.UPPERLIMITWITHUNITS',
					'MISCELLANEOUS.LOWERLIMITWITHUNITS',
					'SETTINGS.STANDARD',
					'SETTINGS.CONVERSIONCURVE',
					'SETTINGS.CARBODEPTH',
					'SETTINGS.CONCRETEAGE',
					`SCALES.${mainScale}.NAME`,
					`SCALES.${mainScale}.UNIT`,
					'PROPERTIES.PROBENAME',
				];
				break;
		}
	}

	return parameters;
};
