// @flow
import { withHighcharts } from 'react-jsx-highcharts';
import Highcharts from 'highcharts';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import type { InjectIntlProvidedProps } from 'react-intl';
import { Col, Row, Spin } from 'antd';
// import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// import './MainData.css';

import { checkStatusJson } from 'apis/restUtils';
import { httpRequest } from 'apis/httpClient';
import errorHandler from 'actionUtils/errorHandler';
import URL from 'apis/urls';
import { getProceqUrl } from 'utils/sessionHelper';

import { morrisToHighcharts, extractLabelsFromSeries, morrisToHighchartsReg } from 'utils/conversionsHelper';
import handleFormatMessage from 'utils/handleFormatMessage';
import HighChartsDashboard from './HighChartsDashboard';
import './DashboardCustomerData.css';

type Props = {
	session: Session,
	locale: Locale,
} & InjectIntlProvidedProps;

export class DashboardCustomerData extends Component<Props, any> {
	_ismounted = false;

	state = {
		hasNoData: false,
		hideAll: false,
		dataLoaded: false,
		timeSpan: {
			from: '',
			to: '',
		},
		barCharts: [],
		donuts: this.getInitDonutsValues(),
		statistics: {},
	};

	componentWillMount() {
		this.loadDashboardData();
	}

	componentDidMount() {
		this._ismounted = true;
	}

	componentWillUnmount() {
		this._ismounted = false;
	}

	componentWillReceiveProps() {
		/* this.setState({
			hideAll:true
		}, () => {
			setTimeout(() => {
				if (this._ismounted) {
*/
		this.loadDashboardData();

		/* this.setState({
						hideAll:false,
						donuts: this.getInitDonutsValues()
					}, () => { this.loadDashboardData(); }); */
		/*	}
			}, 10);
		}); */
	}

	getInitDonutsValues() {
		const productType = this.props.session.productType;
		let toReturn = {};
		switch (productType) {
			case 'EQUOTIP':
				toReturn = {
					materials: { title: 'GraphMaterial', data: [], labels: [] },
					scales: { title: 'GraphSecondaryScale', data: [], labels: [] },
				};
				break;
			case 'SSL':
			case 'SCHMIDT':
				toReturn = {
					standardsschmidt: { title: 'GraphStandard', data: [], labels: [] },
					scales: { title: 'GraphSecondaryScale', data: [], labels: [] },
				};
				break;
		}
		return toReturn;
	}

	loadDashboardData() {
		const { intl } = this.props,
			{ formatMessage } = intl;
		const productType = this.props.session.productType;
		this.setState(
			{
				hasNoData: false,
				dataLoaded: false,
			},
			() => {
				return httpRequest(
					this.props.session,
					this.props.locale,
					getProceqUrl(this.props.session, URL.PROCEQ_DASHBOARD_GET_DATA),
					'post',
					true,
					{},
					{},
					{}
				)
					.then(checkStatusJson)
					.then(json => {
						// Add the All Measurements, Flagged and Trash folder always at the end
						const data = json.data;
						if (data.hasData) {
							const newDonuts = this.getInitDonutsValues();
							if (
								newDonuts.materials &&
								typeof data.materialDonut !== 'undefined' &&
								data.materialDonut !== null
							) {
								newDonuts.materials.data = morrisToHighchartsReg(
									productType,
									formatMessage,
									data.materialDonut,
									'materials'
								);
								newDonuts.materials.labels = extractLabelsFromSeries(newDonuts.materials.data);
							}
							if (
								newDonuts.scales &&
								typeof data.scaleDonut !== 'undefined' &&
								data.scaleDonut !== null
							) {
								newDonuts.scales.data = morrisToHighchartsReg(
									productType,
									formatMessage,
									data.scaleDonut,
									'scales'
								);
								newDonuts.scales.labels = extractLabelsFromSeries(newDonuts.scales.data);
							}

							if (
								newDonuts.standardsschmidt &&
								typeof data.standardDonut !== 'undefined' &&
								data.standardDonut !== null
							) {
								newDonuts.standardsschmidt.data = morrisToHighchartsReg(
									productType,
									formatMessage,
									data.standardDonut,
									'standardsschmidt'
								);
								newDonuts.standardsschmidt.labels = extractLabelsFromSeries(
									newDonuts.standardsschmidt.data
								);
							}

							const barChart0Data = morrisToHighcharts(formatMessage, data.impactCount);
							const barChart1Data = morrisToHighcharts(formatMessage, data.histogram);
							const labelsBarChart0 = extractLabelsFromSeries(barChart0Data);
							const labelsBarChart1 = extractLabelsFromSeries(barChart1Data);

							this.setState({
								dataLoaded: true,
								timeSpan: {
									from: data.firstDate,
									to: data.lastDate,
								},
								barCharts: [
									{
										name: 'GraphMeasurementCount',
										labels: labelsBarChart0,
										ykeys: ['value'],
										data: barChart0Data,
										seriesNames: ['LabelImpacts'],
									},
									{
										name: 'GraphHistogram',
										labels: labelsBarChart1,
										data: barChart1Data,
										seriesNames: ['LabelImpacts'],
									},
								],
								donuts: newDonuts,
								statistics: data.statistics,
							});
						} else {
							this.setState({
								dataLoaded: true,
								hasNoData: true,
							});
						}
					})
					.catch(err => {
						errorHandler(err);
					});
			}
		);
	}

	render() {
		const { intl } = this.props,
			{ formatMessage } = intl;

		let loadingPart = null;
		if (this.state.hideAll || !this.state.dataLoaded)
			loadingPart = (
				<Spin
					style={{ marginTop: '50px', width: '100%' }}
					tip={handleFormatMessage('Proceq.JQGridTableLoadText', formatMessage)}
				/>
			);

		if (this.state.hasNoData) {
			return (
				<div>
					<div className="container">
						<Col xs={24}>
							<div className="white">
								<Row>
									<Col xs={24}>
										<h2>{handleFormatMessage('GraphNoDataAvailable', formatMessage)}</h2>
									</Col>
								</Row>
							</div>
						</Col>
					</div>
				</div>
			);
		}
		if (loadingPart !== null) {
			return (
				<div>
					<div className="container">
						<Col xs={24}>
							<div className="white">
								<Row>
									<Col xs={24}>{loadingPart}</Col>
								</Row>
							</div>
						</Col>
					</div>
				</div>
			);
		}

		return (
			<div>
				<div className="container">
					{/* <div className="row">
							<div className="col-xs-12">
								<div className="title-section title-dashboard">
									{ handleFormatMessage('Dashboard', formatMessage) }
								</div>
							</div>
						</div> */}
					<Row className="no-background">
						<Col xs={24}>
							<div className="white">
								<Row>
									<Col xs={24}>
										<h2 className="label-statistics">
											{handleFormatMessage('ExportFieldStatistics', formatMessage)}
										</h2>
										<div>
											{this.state.timeSpan.from}
											{' - '}
											{this.state.timeSpan.to}
										</div>
									</Col>
									<Col xs={24}>
										<Row className="stat-item-container">
											<Col xs={12} md={6} className="stat-item">
												<div>
													<span>
														{handleFormatMessage('StatisticsTotalImpacts', formatMessage)}
													</span>
													<span className="number">{this.state.statistics.totalimpacts}</span>
												</div>
											</Col>
											<Col xs={12} md={6} className="stat-item">
												<div>
													<span>
														{handleFormatMessage('StatisticsTotalSeries', formatMessage)}
													</span>
													<span className="number">{this.state.statistics.totalseries}</span>
												</div>
											</Col>
											<Col xs={12} md={6} className="stat-item">
												<div>
													<span>
														{handleFormatMessage('StatisticsTotalExcluded', formatMessage)}
													</span>
													<span className="number">
														{this.state.statistics.totalexcluded}
													</span>
												</div>
											</Col>
											<Col xs={12} md={6} className="stat-item">
												<div>
													<span>
														{handleFormatMessage(
															'StatisticsValuesOutOfLimits',
															formatMessage
														)}
													</span>
													<span className="number"> {this.state.statistics.totaloob}</span>
												</div>
											</Col>
										</Row>
									</Col>
								</Row>
							</div>
						</Col>
					</Row>
					<Row>
						<Col xs={24}>
							<div className="white">
								<h2 className="label-diagramm">
									{handleFormatMessage(this.state.barCharts[0].name, formatMessage)}
								</h2>
								<HighChartsDashboard
									key={`${this.props.session.productType}-dashboard-0-`}
									baseId={`${this.props.session.productType}-dashboard-0-`}
									type={'areaspline'}
									series={this.state.barCharts[0].data}
									serieNames={this.state.barCharts[0].seriesNames}
									showLegend={false}
									xAxisTitle={''}
									yAxisTitle={handleFormatMessage('LabelImpacts', formatMessage)}
									labels={this.state.barCharts[0].labels}
								/>
							</div>
						</Col>
						<Col xs={24}>
							<div className="white">
								<h2 className="label-diagramm">
									{handleFormatMessage(this.state.barCharts[1].name, formatMessage)}
								</h2>
								<HighChartsDashboard
									key={`${this.props.session.productType}-dashboard-1-`}
									baseId={`${this.props.session.productType}-dashboard-1-`}
									type={'column'}
									series={this.state.barCharts[1].data}
									serieNames={this.state.barCharts[1].seriesNames}
									showLegend={false}
									xAxisTitle={this.props.session.productType === 'EQUOTIP' ? 'HLD' : 'R'}
									yAxisTitle={handleFormatMessage('LabelImpacts', formatMessage)}
									labels={this.state.barCharts[1].labels}
								/>
							</div>
						</Col>
					</Row>
					<Row>
						{this.getDonutsKey().map(keyDonut => {
							// $FlowFixMe
							const thisDonutInfo = this.state.donuts[keyDonut];
							return (
								<Col xs={24} md={12} key={`donutt-dasbhoard-col-${keyDonut}`}>
									<div className="white">
										<h2 className="label-diagramm">
											{handleFormatMessage(thisDonutInfo.title, formatMessage)}
										</h2>
										<HighChartsDashboard
											key={`${this.props.session.productType}-dashboard-donut-${keyDonut}`}
											baseId={`${this.props.session.productType}-dashboard-0-${keyDonut}`}
											type={'pie'}
											series={thisDonutInfo.data}
											unitForLabel={'%'}
											labels={thisDonutInfo.labels}
											showLegend
										/>
									</div>
								</Col>
							);
						})}
					</Row>
				</div>
			</div>
		);
	}

	getDonutsKey() {
		const toReturn = [];
		Object.keys(this.state.donuts).forEach(key => {
			toReturn.push(key);
		});
		return toReturn;
	}
}

const mapStateToProps = state => ({
	session: state.session,
	locale: state.locale,
	globalregister: state.globalregister,
});

export default withHighcharts(connect(mapStateToProps)(injectIntl(DashboardCustomerData)), Highcharts);
