// @flow
import React from 'react';
import { FormattedMessage } from 'react-intl';

export const UpdateStatus = ({ log }: GPRLogbookEntryProps) => (
	<FormattedMessage
		id="App.LogbookEntryUpdateStatus"
		values={{
			item: log.content.item,
			status: log.content.status,
		}}
	/>
);

export default UpdateStatus;
