// @flow
import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { MeasurementScanType } from 'constants/exportConstants';
import { ProductConstants } from 'components/contracts/contractConsts';
import StaticMap from '../StaticMap';
import ConvertedUnits from '../../../shared/ConvertedUnits';

export const Creation = ({ log, productModel, isMetric, scanType, hasMA8000 }: GPRLogbookEntryProps) => {
	const { latitide, longitude, measurementInfo } = log.content;
	const coordinates = { lat: +latitide, lng: +longitude };
	const { fileFolder, mode, resolution, repetitionRate, dielectricConstant, coordinateSystem } = measurementInfo;
	return (
		<div>
			<b>
				<FormattedMessage
					id="Created {fileName} in folder {folderName}"
					values={{
						fileName: fileFolder.fileName,
						folderName: fileFolder.folderName,
					}}
				/>
			</b>
			<br />
			<FormattedMessage id="Mode: {value}" values={{ value: mode }} />
			<br />
			{productModel.toUpperCase() === ProductConstants.GPR_SOIL && scanType === MeasurementScanType.AreaScan && (
				<>
					<ConvertedUnits
						id="Proceq.LogbookEntryGridSize"
						unitId={'GPR_SOIL.CSV.Grid Size - X'}
						scanType={scanType}
						isMetric={isMetric}
						values={[measurementInfo.grid.gridSizeWidth, measurementInfo.grid.gridSizeHeight]}
					/>
					<br />
					<ConvertedUnits
						id="Proceq.LogbookEntryGridSpacing"
						unitId={'GPR_SOIL.CSV.Grid Spacing'}
						scanType={scanType}
						isMetric={isMetric}
						values={[measurementInfo.grid.gridSpacing]}
					/>
					<br />
				</>
			)}
			{productModel !== 'gpr_palm' && (
				<Fragment>
					<FormattedMessage id="App.LogbookEntryResolution" values={{ value: resolution }} />
					<br />
				</Fragment>
			)}
			<ConvertedUnits
				id="App.LogbookEntryRepetitionRate"
				unitId="GPR.Settings Panel.Repetition rate"
				scanType={scanType}
				isMetric={isMetric}
				values={[repetitionRate]}
			/>
			<br />
			<ConvertedUnits
				id="App.LogbookEntryDielectricConstant"
				unitId="GPR.Logbook Panel.Dielectric Constant"
				scanType={scanType}
				isMetric={isMetric}
				values={[dielectricConstant]}
			/>
			<br />
			{productModel.toUpperCase() === ProductConstants.GPR_SOIL && hasMA8000 && (
				<>
					<FormattedMessage id="Proceq.LogbookEntryCoordinateSystem" values={{ value: coordinateSystem }} />
					<br />
				</>
			)}
			<StaticMap width={400} height={200} zoom={16} coordinates={coordinates} />
			{/* <InteractiveMap height={ 200 } zoom={ 16 } coordinates={ coordinates } /> */}
		</div>
	);
};

export const color = () => 'green';

export default Creation;
