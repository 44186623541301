module.exports = {
	shortlang: 'it',
	lang: 'it',
	messages: {
		'Proceq.ErrorWrongUsername': 'E-mail non valida',
		'Proceq.ErrorCreationNotAllowed': 'Creazione di nuovi account disabilitata',
		'Proceq.ErrorWrongPassword': 'Password non valida',
		'Proceq.ErrorWrongFirstName': 'Nome non valido',
		'Proceq.ErrorWrongLastName': 'Cognome non valido',
		'Proceq.ErrorUsernameAlreadyExists': 'Esiste già un account con la stessa e-mail',
		'Proceq.ErrorDB': "Errore durante l'esecuzione di un'operazione sul database",
		'Proceq.InvalidUsername': 'Account utente non trovato',
		'Proceq.AlreadyMultipleForgot': 'Sono già state richieste più reimpostazioni della password',
		'Proceq.InvalidNewPassword': 'Nuova password non valida',
		'Proceq.InvalidResetCode': 'Codice di reimpostazione password non valido o già scaduto',
		'Proceq.InvalidOldPassword': 'Vecchia password non valida',
		'Proceq.InvalidAccessRights': 'Sessione scaduta o utente non autorizzato a eseguire questa azione',
		'Proceq.ErrorNoAccountType': 'Tipo di account non specificato',
		'Proceq.ErrorNoUsername': 'E-mail non specificata',
		'Proceq.InvalidCountry': 'Paese non valido',
		'Proceq.ErrorCreatingUser': "Errore durante la creazione dell'utente",
		'Proceq.InvalidID': 'ID non valido',
		'Proceq.InvalidTransactionID': 'ID transazione non valido',
		'Proceq.InvalidFileContent': 'Errore durante la lettura del contenuto del file',
		'Proceq.InvalidTypeEntry': 'Tipo di voce non valido',
		'Proceq.InvalidContractID': 'Contratto non valido o scaduto',
		'Proceq.InvalidExistingID': "Impossibile creare l'ID perché esiste già",
		'Proceq.InvalidDataSeriesType': 'Tipo di serie non valido',
		'Proceq.InvalidMetaDataContent': 'Contenuto metadati non valido',
		'Proceq.ErrorFileIsLocked': 'Uno o più file sono bloccati. Riprova più tardi',
		'Proceq.MissingAttachmentFiles': 'Impossibile trovare i file allegati sul server',
		'Proceq.InvalidFlagType': 'Tipo flag non valido o mancante',
		'Proceq.MissingDateTypeFields': 'Campi obbligatori mancanti per la serie',
		'Proceq.InvalidFolderID': 'ID cartella non valido',
		'Proceq.InvalidDataSeriesName': 'Nome serie non valido',
		'Proceq.ErrorDateFormat': 'Formato data non valido',
		'Proceq.ErrorDataFormat': 'Impossibile analizzare i dati dalla serie',
		'Proceq.InvalidDownloadID': 'Token di download non valido o già scaduto',
		'Proceq.InvalidVersionNr': 'Numero di versione non valido',
		'Proceq.ErrorDataMissing': 'Impossibile recuperare i dati dal sistema di memorizzazione',
		'Proceq.ErrorSavingFile': 'Salvataggio del file sul server non riuscito',
		'Proceq.ErrorInsertDB': "Errore durante l'inserimento nel DB",
		'Proceq.InvalidFolderName': 'Nome cartella non valido',
		'Proceq.InvalidActionID': 'Link URL non valido o scaduto',
		'Proceq.AlreadyConfirmed': "L'account è già stato verificato",
		'Proceq.InvalidActionType': "L'azione richiesta non può essere eseguita",
		'Proceq.ErrorGeneratingChart': 'Errore durante la generazione dei grafici',
		'Proceq.InvalidSerialID': 'ID seriale non valido',
		'Proceq.InvalidHardwareID': 'ID hardware non valido',
		'Proceq.InvalidEntityID': 'ID oggetto non valido o eliminato',
		'Proceq.InvalidEntityType': 'Tipo di oggetto non valido',
		'Proceq.InvalidEntityVerNr': 'Numero di versione oggetto non valido',
		'Proceq.ErrorTransactionLocked': "L'oggetto è bloccato e non può essere modificato",
		'Proceq.InvalidDateValue': 'Impossibile analizzare la data',
		'Proceq.MissingFields': 'Uno o più campi obbligatori mancanti',
		'Proceq.ErrorColumnValue': 'Valore errato in uno o più campi',
		'Proceq.MissingUidDBValue': 'ID voce mancante',
		'Proceq.ErrorFileID': 'ID file non valido',
		'Proceq.ErrorInsertDownload': 'Errore durante la creazione del download',
		'Proceq.InvalidAppVersion': 'Versione app corrente non più supportata. Si prega di aggiornarla',
		'Proceq.MissingUserInfo': 'Informazioni utente mancanti',
		'Proceq.InvalidAccessToken': 'Token sessione non valido o scaduto',
		'Proceq.ErrorGeneratingCSV': "Errore durante la generazione dell'esportazione in CSV",
		'Proceq.ErrorGeneratingExport': "Errore durante la generazione dell'esportazione",
		'Proceq.MissingParams': 'Uno o più parametri obbligatori mancanti',
		'Proceq.SerialNotFound': 'Nessun contratto trovato per questo strumento',
		'Proceq.StandardEnumJapan': 'Giappone',
		'Proceq.StandardEnumDefault': 'Predefinito',
		'Proceq.MaterialEnumIndependent': 'Indipendente dal materiale',
		'Proceq.MaterialEnumSteelAndCastSteel': 'Acciaio e acciaio colato',
		'Proceq.MaterialEnumWorkToolSteel': 'Acciaio da utensili',
		'Proceq.MaterialEnumStainlessSteel': 'Acciaio inossidabile',
		'Proceq.MaterialEnumGrayCastIron': 'Ghisa grigia (GG)',
		'Proceq.MaterialEnumNodularCastIron': 'Ghisa nodulare (GGG)',
		'Proceq.MaterialEnumCastAlluminiumAlloys': 'Leghe di alluminio pressofuso',
		'Proceq.MaterialEnumBrassCopperZincAlloys': 'Ottone - Leghe di rame/zinco',
		'Proceq.MaterialEnumBronze': 'Bronzo',
		'Proceq.MaterialEnumWroughtCopperAlloys': 'Leghe in rame per lavoraz. plastica',
		'Proceq.MaterialEnumTitanium': 'Titanio',
		'Proceq.ScaleEnumCustom': 'Personalizz.',
		'Proceq.ScaleEnumPenetrationDepth': 'Profondità di penetrazione',
		'Proceq.CompanySizeMicro': '1-9 dipendenti',
		'Proceq.CompanySizeSmall': '10-49 dipendenti',
		'Proceq.CompanySizeMedium': '50-249 dipendenti',
		'Proceq.CompanySizeLarge': '>249 dipendenti',
		'Proceq.SalutationMr': 'Sig.',
		'Proceq.SalutationMs': 'Sig.ra',
		'Proceq.SalutationDr': 'Dr.',
		'Proceq.SalutationProf': 'Prof.',
		'Proceq.CategoryAerospace': 'Aerospaziale',
		'Proceq.CategoryAutomotive': 'Automobilistico',
		'Proceq.CategoryChemicalProcessing': 'Chimica industriale',
		'Proceq.CategoryConstructionBuilding': 'Costruzioni/edilizia',
		'Proceq.CategoryConsultantTestLabsEngineer': 'Consulente/Lab. prove/Ingegnere',
		'Proceq.CategoryEducationInstituteRD': 'Istruzione/Istituto/R&D',
		'Proceq.CategoryFilmProducerConverter': 'Produttore pellicola/Converter',
		'Proceq.CategoryGeologyRocktesters': 'Geologia/Analizzatore di rocce',
		'Proceq.CategoryMachineryToolingEquipment': 'Macchine/Strumentazione/Attrezzature',
		'Proceq.CategoryMassTransportation': 'Trasporto di massa',
		'Proceq.CategoryMetalWorks': 'Metallurgia',
		'Proceq.CategoryOilAndGas': 'Petrolio e gas',
		'Proceq.CategoryOthers': 'Altro',
		'Proceq.CategoryPaperProducerConverter': 'Produttore carta/Converter',
		'Proceq.CategorySalesChannel': 'Canale di vendita',
		'Proceq.ErrorWrongLoginCredentials': 'Nome utente o password non corretto',
		'Proceq.ErrorEmailNotVerified': "L'e-mail non è ancora stata verificata",
		'Proceq.ServerError': 'Errore server',
		'Proceq.TooManyActionEmailRequests':
			'Troppe richieste. Attendere che le e-mail arrivino alla propria casella postale',
		'Proceq.ErrorServerUnderMaintenance': 'Server in manutenzione',
		'Proceq.TableDataSeriesName': 'Nome',
		'Proceq.TableCreatedOn': 'Creato il',
		'Proceq.TableModifiedOn': 'Modificato il',
		'Proceq.TableCreatedBy': 'Creato da',
		'Proceq.TableNumMean': 'Media',
		'Proceq.TableNumTotal': 'Totale',
		'Proceq.TableNumStandardDeviation': 'Dev. std.',
		'Proceq.TableImpactDevice': 'Sonda',
		'Proceq.TableImpactDeviceHammer': 'Sclerometro',
		'Proceq.TableMaterial': 'Materiale',
		'Proceq.TableScaleUnit': 'Unità scala',
		'Proceq.TableActionRefresh': 'Aggiorna',
		'Proceq.TableActionExpand': 'Espandi',
		'Proceq.TableActionCollapse': 'Comprimi',
		'Proceq.TableActionExportAsPDF': 'Esporta come PDF',
		'Proceq.TableActionExportAsCSV': 'Esporta come CSV',
		'Proceq.TableActionExportAsJSON': 'Esporta come JSON',
		'Proceq.SearchOperationEquals': 'È uguale a',
		'Proceq.SearchOperationContains': 'Contiene',
		'Proceq.SearchOperationDifferent': 'Diverso da',
		'Proceq.SearchOperationGreaterOrEqual': 'Maggiore o uguale a',
		'Proceq.SearchOperationGreater': 'Maggiore di',
		'Proceq.SearchOperationLesserOrEqual': 'Minore o uguale a',
		'Proceq.SearchOperationLesser': 'Minore di',
		'Proceq.ExportFieldExportDate': 'Data esportazione',
		'Proceq.ExportFieldLimits': 'Limiti',
		'Proceq.ExportFieldHigh': 'Sup.',
		'Proceq.ExportFieldLow': 'Inf.',
		'Proceq.ExportFieldOff': 'Off',
		'Proceq.ExportFieldLowerLimit': 'Limite inferiore',
		'Proceq.ExportFieldUpperLimit': 'Limite superiore',
		'Proceq.ExportFieldReadings': 'Letture',
		'Proceq.ExportFieldStandardDeviation': 'Deviazione standard',
		'Proceq.ExportFieldRange': 'Range',
		'Proceq.ExportFieldRelativeSpan': 'Intervallo relativo',
		'Proceq.ExportFieldMaximum': 'Massimo',
		'Proceq.ExportFieldMinimum': 'Minimo',
		'Proceq.ExportFieldAverage': 'Media',
		'Proceq.ExportFieldUnit': 'Unità',
		'Proceq.ExportFieldName': 'Nome',
		'Proceq.ExportFieldMeasurementDate': 'Data di misurazione',
		'Proceq.ExportFieldLocationLatitude': 'Latitudine sito',
		'Proceq.ExportFieldLocationLongitude': 'Longitudine sito',
		'Proceq.ExportFieldProbeType': 'Tipo di sonda',
		'Proceq.ExportFieldProbeSerialNumber': 'Numero di serie sonda',
		'Proceq.ExportFieldProbeBTSerialNumber': 'Numero di serie bluetooth sonda',
		'Proceq.ExportFieldProbeLastVerificationDate': 'Ultima data di verifica sonda',
		'Proceq.ExportFieldPrimaryScale': 'Scala primaria',
		'Proceq.ExportFieldSecondaryScale': 'Scala secondaria',
		'Proceq.ExportFieldMaterial': 'Materiale',
		'Proceq.ExportFieldStandard': 'Standard',
		'Proceq.ExportFieldNoConversion': 'Nessuna conversione',
		'Proceq.ExportFieldMeasurements': 'Misurazioni',
		'Proceq.ExportFieldMore': 'Più',
		'Proceq.ExportFieldLess': 'Meno',
		'Proceq.ExportFieldStatistics': 'Statistiche',
		'Proceq.ExportFieldSettings': 'Impostazioni',
		'Proceq.DataSeriesLogbook': 'Logbook',
		'Proceq.DataSeriesAllMeasurements': 'Tutte le misurazioni',
		'Proceq.DataSeriesFlagged': 'Contrassegnata',
		'Proceq.DataSeriesTrash': 'Cestino',
		'Proceq.BrowserNoSupportAudio': 'Il browser in uso non supporta file audio.',
		'Proceq.EmailPasswordRecoverySubject': 'Richiesta di modifica password per **?0**',
		'Proceq.EmailPasswordRecoveryBody':
			'Gentile **?0** **?1**<br/><br/>Hai richiesto una nuova password. Per reimpostare la password, fare clic sul link sotto.<br/><br/><a href="**?2**">Reimposta password</a><br/><br/>Se non hai richiesto di modificare la password, ignora questo messaggio. Scadrà tra 12 ore.<br/><br/>Il Team Proceq<br/><br/>**?3**',
		'Proceq.EmailRegistrationSubject': 'Completamento registrazione per **?0**',
		'Proceq.EmailRegistrationBody':
			'Gentile **?0** **?1**<br/><br/>Grazie per la registrazione su **?5**. Per completare la registrazione, fare clic sul link sotto:<br/><br/><a href="**?2**">**?2**</a><br/><br/>Questa e-mail è stata creata automaticamente in seguito alla compilazione del modulo di registrazione. Se non è stata effettuata alcuna registrazione, ignorare questo messaggio.<br/><br/> Completando la registrazione si acconsente alla nostra <a href="**?3**">politica sulla privacy</a>.<br/><br/>Il Team Proceq<br/><br/>**?4**',
		'Proceq.LogbookActionNewMeasurement': 'Nuova misurazione',
		'Proceq.LogbookPropertyCreatedSeriesInFolder': 'Creata serie "**?0**" nella cartella "**?1**"',
		'Proceq.LogbookPropertyRenamedSeriesFromTo': 'La serie è stata rinominata da "**?0**" a "**?1**"',
		'Proceq.LogbookPropertyMovedSeriesFromToTrash': 'La serie è stata spostata dalla cartella "**?0**" al Cestino',
		'Proceq.LogbookPropertyMovedSeriesFromTrashTo': 'La serie è stata spostata dal Cestino alla cartella "**?0**"',
		'Proceq.LogbookPropertyMovedSeriesFromTo':
			'La serie è stata spostata dalla cartella "**?0**" alla cartella "**?1**"',
		'Proceq.LogbookPropertySecondaryScaleChangedFromTo': 'Scala secondaria modificata da **?0** a **?1**',
		'Proceq.LogbookPropertyMaterialChangedFromTo': 'Materiale modificato da **?0** a **?1**',
		'Proceq.LogbookPropertyMaxLimitChangedFromTo': 'Limite superiore modificato da **?0** a **?1**',
		'Proceq.LogbookPropertyMinLimitChangedFromTo': 'Limite inferiore modificato da **?0** a **?1**',
		'Proceq.LogbookPropertyStandardChangedFromTo': 'Standard modificato da **?0** a **?1**',
		'Proceq.LogbookPropertyTextCommentAdded': 'Commento di testo aggiunto',
		'Proceq.LogbookPropertyTextCommentDeletedOn': 'Eliminato il **?0**',
		'Proceq.LogbookPropertyMeasurementAdded': '**?0** aggiunto',
		'Proceq.LogbookPropertyMeasurementExcluded': '**?0** escluso',
		'Proceq.LogbookPropertyMeasurementIncluded': '**?0** incluso',
		'Proceq.LogbookPropertyMeasurementDeleted': '**?0** eliminato',
		'Proceq.LogbookPropertyAttachmentDeletedOn': 'Eliminato il **?0**',
		'Proceq.LogbookPropertyPhotoAdded': 'Foto aggiunta',
		'Proceq.LogbookPropertyAudioAdded': 'Commento audio aggiunto',
		'Proceq.LogbookPropertySeriesFlagged': 'Serie contrassegnata',
		'Proceq.LogbookPropertySeriesUnflagged': 'Serie non contrassegnata',
		'Proceq.LogbookPropertySerialNumberShort': 'N. serie',
		'Proceq.LogbookPropertyBluetoothModuleShort': 'BTM S/N',
		'Proceq.LogbookPropertyVerified': 'Verificato',
		'Proceq.LogbookPropertyContractValidUntil': 'Contratto valido fino al',
		'Proceq.LogbookPropertyOperator': 'Operatore',
		'Proceq.LogbookTitle': 'Logbook',
		'Proceq.LogbookDownload': 'Download',
		'Proceq.LogbookLoadingData': 'Caricamento dati....',
		'Proceq.LogbookClose': 'Chiudi',
		'Proceq.FormCompany': 'Società',
		'Proceq.FormScreeningEagleID': 'Screening Eagle ID (Email)',
		'Proceq.FormEmailGroupID': 'E-mail',
		'Proceq.FormCustomerEmail': 'E-mail cliente',
		'Proceq.FormProbeSN': 'N. serie sonda',
		'Proceq.FormBluetoothSN': 'N. serie',
		'Proceq.FormBluetoothHWID': 'ID HW',
		'Proceq.FormExpirationDate': 'Data di scadenza',
		'Proceq.TitleRegisterProbeSNs': 'Registra contratto di noleggio',
		'Proceq.ButtonSubmit': 'Invia',
		'Proceq.ButtonReset': 'Reimposta',
		'Proceq.ButtonResetPassword': 'Reimposta password',
		'Proceq.MessageSuccesfullyInserted': 'Inserimento completato',
		'Proceq.MessageSuccesfullyEmailConfirmed': "L'e-mail è stata confermata.",
		'Proceq.MessagePasswordSuccesfullyChanged': 'Modifica password completata',
		'Proceq.MessageSuccesfullyUpdated': 'Aggiornamento completato',
		'Proceq.MessageAccountCreated':
			"È stata inviata una e-mail di verifica registrazione. Per completare il processo di registrazione, fare clic sul link nell'e-mail.",
		'Proceq.MessageEmailForgotPassword': 'È stata inviata una e-mail con ulteriori istruzioni',
		'Proceq.MessageEmailResendConfirm': 'È stata inviata una e-mail con ulteriori istruzioni',
		'Proceq.MessageLoggingIn': 'Login in corso...',
		'Proceq.LoginNeedAccount': 'Serve un account?',
		'Proceq.LoginCreateAccount': 'Crea account',
		'Proceq.TitleForgotPassword': 'Password dimenticata',
		'Proceq.TitleResendConfirmation': 'Reinvia e-mail di conferma',
		'Proceq.FormGroup': 'Gruppo',
		'Proceq.FormFirstName': 'Nome',
		'Proceq.FormLastName': 'Cognome',
		'Proceq.FormEmail': 'Indirizzo e-mail',
		'Proceq.FormCity': 'Città',
		'Proceq.FormPassword': 'Password',
		'Proceq.FormPasswordNew': 'Nuova password',
		'Proceq.FormPasswordOld': 'Vecchia password',
		'Proceq.FormConfirmPassword': 'Conferma password',
		'Proceq.FormConfirmPasswordNew': 'Conferma nuova password',
		'Proceq.ButtonRegister': 'Registrazione',
		'Proceq.ButtonSignIn': 'Accedi',
		'Proceq.TitleSignIn': 'Accedi',
		'Proceq.LoginAlreadyRegistered': 'Già registrato?',
		'Proceq.TextOrSignInUsing': 'Oppure accedi utilizzando',
		'Proceq.NavSearchSerials': 'Cerca contratti',
		'Proceq.NavRegisterProbeSNs': 'Registra contratto',
		'Proceq.NavCreateStaffAccounts': 'Crea account staff',
		'Proceq.NavHistoryLog': 'Log della cronologia',
		'Proceq.NavMeasurementsData': 'Dati di misurazione',
		'Proceq.NavChangePassword': 'Cambia password',
		'Proceq.NavLogout': 'Logout',
		'Proceq.NavUpdateProfile': 'Aggiorna profilo',
		'Proceq.TitleUpdateProfile': 'Aggiorna profilo',
		'Proceq.NavCreateCustomersAccounts': 'Crea account cliente',
		'Proceq.ButtonSearch': 'Cerca',
		'Proceq.ButtonUpdate': 'Aggiorna',
		'Proceq.ButtonCreate': 'Crea',
		'Proceq.ButtonChange': 'Cambia',
		'Proceq.FormSearchBy': 'Cerca per',
		'Proceq.FormValue': 'Valore',
		'Proceq.FormAccountType': 'Tipo account',
		'Proceq.FormAccountTypeAdmin': 'Amministratore',
		'Proceq.FormAccountTypeBU': 'Business Unit',
		'Proceq.FormCompanyName': 'Nome società',
		'Proceq.FormAddress': 'Indirizzo',
		'Proceq.FormZipCode': 'CAP',
		'Proceq.FormCountry': 'Paese',
		'Proceq.FormPhoneNumber': 'Telefono',
		'Proceq.FormCompanyWebsite': 'Sito web società',
		'Proceq.FormJobTitle': 'Posizione',
		'Proceq.FormIndustry': 'Settore',
		'Proceq.FormSalutation': 'Formula di apertura',
		'Proceq.FormCompanySize': 'Dimensioni società',
		'Proceq.FormLanguage': 'Lingua',
		'Proceq.FormContactByProceq': 'Contatto da Proceq',
		'Proceq.LanguageEnglish': 'Italiano',
		'Proceq.LanguageJapanese': 'Giapponese',
		'Proceq.LanguageChinese': 'Cinese',
		'Proceq.LanguageGerman': 'Tedesco',
		'Proceq.TextYes': 'Sì',
		'Proceq.TextNo': 'No',
		'Proceq.FormNewsletter': 'Newsletter',
		'Proceq.TitleSearchSerials': 'Cerca contratti',
		'Proceq.TitleChangePassword': 'Cambia password',
		'Proceq.TabLoginCustomers': 'Clienti',
		'Proceq.TabLoginSingleUser': 'Singolo utente',
		'Proceq.TabLoginGroup': 'Gruppo',
		'Proceq.TextRemainingXDays': 'Rimangono ancora **?0** giorni',
		'Proceq.TextInfoShort': 'Info',
		'Proceq.TextIAgreeTerms': 'Accetto i <a>termini e le condizioni</a>',
		'Proceq.ErrorDisplayTitleError': 'Errore',
		'Proceq.ErrorDisplayTitleWarning': 'Avvertenza',
		'Proceq.LoginBackLogin': 'Torna al login',
		'Proceq.LoginForgotPasswordQuestion': 'Password dimenticata?',
		'Proceq.LoginResendConfirmationEmail': 'Reinvia e-mail di conferma',
		'Proceq.LoginRegisterAccountTextPart': 'Se non possiedi un account,',
		'Proceq.LoginRegisterAccountLinkPart': 'registrati qui',
		'Proceq.LoginProceqUseOnly': 'Questo è solo per uso interno Proceq',
		'Proceq.ProfileEnterInfo': 'Prima di continuare, inserire le informazioni sul proprio profilo',
		'Proceq.TextInfoExclamation': 'Info!',
		'Proceq.FooterLegalPrivacy': 'Legale & Privacy',
		'Proceq.JQGridTableRecordText': 'Visualizzazione {0} - {1} di {2}',
		'Proceq.JQGridTableEmptyRecords': 'Nessun record da visualizzare',
		'Proceq.JQGridTableLoadText': 'Caricamento...',
		'Proceq.JQGridTablePaginationText': 'Pagina {0} di {1}',
		'Proceq.JQGridTableSavingText': 'Salvataggio...',
		'Proceq.JQGridTableFirstPage': 'Prima pagina',
		'Proceq.JQGridTableLastPage': 'Ultima pagina',
		'Proceq.JQGridTableNextPage': 'Pagina successiva',
		'Proceq.JQGridTablePreviousPage': 'Pagina precedente',
		'Proceq.JQGridTableRecordsPerPage': 'Record per pagina',
		'Proceq.JQGridTablePageText': 'Pagina:',
		'Proceq.JQGridTableNoMoreRecs': 'Nessun altro record...',
		'Proceq.ExportColumnCSVNo': 'N.',
		'Proceq.ExportColumnCSVSingleReadings': 'Letture singole',
		'Proceq.ExportColumnCSVMeasurementValue': 'Valore di misura',
		'Proceq.ExportColumnCSVAngleDegrees': 'Angolo (°)',
		'Proceq.ExportColumnCSVExcluded': 'Escluso',
		'Proceq.ExportColumnCSVSeriesName': 'Nome serie',
		'Proceq.ExportColumnCSVImpactDevice': 'Percussore',
		'Proceq.ExportColumnCSVImpactDeviceSN': 'N. serie percussore',
		'Proceq.ExportColumnCSVImpactDeviceVerification': 'Verifica percussore',
		'Proceq.ExportColumnCSVImpactDeviceHammerVerification': 'Verifica sclerometro',
		'Proceq.ExportColumnCSVImpactDeviceHammer': 'Sclerometro',
		'Proceq.ExportColumnCSVImpactDeviceHammerSN': 'N. serie sclerometro',
		'Proceq.ExportColumnCSVBluetoothModuleSN': 'N. serie modulo bluetooth',
		'Proceq.ExportColumnCSVConversionStandard': 'Std. conversione',
		'Proceq.ExportColumnCSVScale': 'Scala',
		'Proceq.ExportColumnCSVUnit': 'Unità',
		'Proceq.ExportColumnCSVReadings': 'Letture',
		'Proceq.ExportColumnCSVStandardDeviation': 'Dev. std.',
		'Proceq.ExportColumnCSVRelativeSpan': 'Rel. Intervallo (%)',
		'Proceq.ExportColumnCSVDebugDataSeriesName': 'Nome serie',
		'Proceq.ExportColumnExportDate': 'Data esportazione',
		'Proceq.ExportColumnUpperLimit': 'Limite superiore',
		'Proceq.ExportColumnLowerLimit': 'Limite inferiore',
		'Proceq.ExportColumnLimits': 'Limiti',
		'Proceq.ExportColumnHigh': 'Sup.',
		'Proceq.ExportColumnLow': 'Inf.',
		'Proceq.ExportColumnValueOff': 'Off',
		'Proceq.ExportColumnValueNoConversion': 'Nessuna conversione',
		'Proceq.ExportColumnValueNoConversionShort': 'NoConv',
		'Proceq.ExportColumnReadings': 'Letture',
		'Proceq.ExportColumnStandardDeviation': 'Deviazione standard',
		'Proceq.ExportColumnRange': 'Range',
		'Proceq.ExportColumnRelativeSpan': 'Intervallo relativo',
		'Proceq.ExportColumnMaximum': 'Massimo',
		'Proceq.ExportColumnMinimum': 'Minimo',
		'Proceq.ExportColumnAverage': 'Media',
		'Proceq.ExportColumnUnit': 'Unità',
		'Proceq.ExportColumnName': 'Nome',
		'Proceq.ExportColumnDataSeriesID': 'ID serie',
		'Proceq.ExportColumnFolderName': 'Nome cartella',
		'Proceq.ExportColumnCreatedDate': 'Data di creazione',
		'Proceq.ExportColumnCreatedDateOnly': 'Data di creazione',
		'Proceq.ExportColumnCreatedTime': 'Ora di creazione',
		'Proceq.ExportColumnLastModifiedDate': 'Data ultima modifica',
		'Proceq.ExportColumnLastModifiedDateOnly': 'Data ultima modifica',
		'Proceq.ExportColumnLastModifiedTime': 'Ora ultima modifica',
		'Proceq.ExportColumnMeasurementAvgHardness': 'Durezza media mis.',
		'Proceq.ExportColumnLocationLatitude': 'Latitudine sito',
		'Proceq.ExportColumnLocationLongitude': 'Longitudine sito',
		'Proceq.ExportColumnProbeType': 'Tipo',
		'Proceq.ExportColumnProbeSN': 'Numero di serie',
		'Proceq.ExportColumnBTMSN': 'Numero di serie',
		'Proceq.ExportColumnProbeLastModifiedDate': 'Data ultima verifica',
		'Proceq.ExportColumnProbeHardwareRevision': 'Revisione hardware',
		'Proceq.ExportColumnProbeFirmwareRevision': 'Revisione firmware',
		'Proceq.ExportColumnBTMHardwareRevision': 'Revisione hardware',
		'Proceq.ExportColumnBTMFirmwareRevision': 'Revisione firmware',
		'Proceq.ExportColumnAppPlatform': 'Piattaforma',
		'Proceq.ExportColumnVersion': 'Versione',
		'Proceq.ExportColumnOperator': 'Operatore',
		'Proceq.ExportColumnPrimaryScale': 'Scala primaria',
		'Proceq.ExportColumnSecondaryScale': 'Scala secondaria',
		'Proceq.ExportColumnMaterial': 'Materiale',
		'Proceq.ExportColumnConversionStandard': 'Standard di conversione',
		'Proceq.ExportColumnAngleCorrection': 'Correzione angolo',
		'Proceq.ExportColumnValueAuto': 'Auto',
		'Proceq.ExportColumnTableAngleGrades': 'Angolo (°)',
		'Proceq.ExportColumnTableLegendExcluded': '* Escluso',
		'Proceq.ExportColumnMeasurementBarGraph': 'Grafico a barre misurazioni',
		'Proceq.ExportColumnTableView': 'Vista Tabella',
		'Proceq.ExportColumnStatistics': 'Statistiche',
		'Proceq.ExportColumnSettings': 'Impostazioni',
		'Proceq.ExportColumnImpactDeviceInfo': 'Info su percussore',
		'Proceq.ExportColumnImpactDeviceHammerInfo': 'Info su sclerometro',
		'Proceq.ExportColumnBluetoothModuleInfo': 'Info su modulo bluetooth',
		'Proceq.ExportColumnApplicationInfo': 'Info su applicazione',
		'Proceq.ExportColumnAttachedPhotos': 'Foto allegate',
		'Proceq.ButtonActions': 'Azioni',
		'Proceq.SerialNotInServiceFTP': 'N. serie non in Service FTP',
		'Proceq.ActionResultsToCSV': 'Esporta risultati come CSV',
		'Proceq.ErrorDisplayTitleSuccess': 'Azione riuscita',
		'Proceq.ErrorNoMultipleTargetsAllowed': "L'azione non può essere eseguita su più righe",
		'Proceq.MessageActionSuccesfullyPerformed': 'Azione completata con successo',
		'Proceq.EmailReminderContractUserSubject': 'Promemoria scadenza contratto',
		'Proceq.EmailReminderContractUserBody':
			'Gentile utente<br/><br/>Il periodo di noleggio per **?0** scadrà il **?1**.<br/><br/>Il contratto verrà automaticamente rinnovato per altri 12 mesi, in base ai <a href="http://www.proceq.com/fileadmin/user_upload/Proceq_General_Terms___Conditions_of_Rental_10112016.pdf">nostri ‘Termini e condizioni generali di noleggio\'</a>. La fattura verrà inviata separatamente. Si raccomanda di provvedere per tempo al pagamento, in modo da garantire la continuità di utilizzo del proprio prodotto Equotip Live.<br/><br/>Per qualsiasi domanda al riguardo, contattare il proprio <a href="http://www.proceq.com/contact/proceq-network/">rappresentante locale</a>.<br/><br/>Il Team Proceq<br/><br/>**?2**',
		'Proceq.EmailReminderContractAdminSubject': 'Promemoria scadenza contratto',
		'Proceq.ErrorNoTargetSelected': "Nessun target selezionato per l'azione",
		'Proceq.ErrorDisplayTitleInfo': 'Info',
		'Proceq.TextSharingSeriesUrlLinkCreated':
			'Le serie sono state condivise al seguente link:<br/>**?0**<br/><a class="link-copy-clipboard" href="javascript:void(0);" onclick="**?1**">Copia negli Appunti</a><br/>',
		'App.ShareMeasurementsSuccess': 'Le serie sono state condivise al seguente link',
		'App.ShareMeasurementsCopyAction': 'Copia negli Appunti',
		'Proceq.TextPleaseWait': 'Attendere...',
		'Proceq.TextPerformingServerRequest': 'Esecuzione richiesta server in corso',
		'Proceq.TextPerformingAction': 'Esecuzione azione in corso',
		'Proceq.ButtonCreateAccountChinaBack': 'Rimuovi',
		'Proceq.TitleCreateAccountChina': 'Cliente cinese',
		'Proceq.BodyCreateAccountChina':
			'Per clienti in Cina, passare a <a href="**?0**">**?1**</a> per la registrazione o il login',
		'Proceq.LoginCompatibiltyInfo': 'Informazioni di compatibilità <a href="**?0**">App Proceq</a>',
		'Proceq.TextRedirectingToRegion': 'Reindirizzamento alla tua regione...',
		'Proceq.EmailReminderContractExpiredTodayUserSubject': 'Scadenza contratto',
		'Proceq.EmailReminderContractExpiredTodayUserBody':
			'Gentile utente<br/><br/>Il periodo di noleggio per **?0** è scaduto oggi.<br/><br/>Ci dispiace che non utilizzerai più Proceq Live, ma saremo lieti di averti di nuovo tra i nostri utenti.<br/><br/>Per rinnovare il contratto, contatta subito il tuo <a href="http://www.proceq.com/contact/proceq-network/">rappresentante locale</a>.<br/><br/>Il Team Proceq<br/><br/>**?1**',
		'Proceq.EmailRentalPeriodChangedSubject': 'Periodo di noleggio modificato',
		'Proceq.EmailRentalPeriodChangedBody':
			'Gentile utente<br/><br/>La data di scadenza del tuo contratto di noleggio per **?0** è stata modificata. Il tuo contratto di noleggio scadrà il **?1**.<br/><br/>Per qualsiasi domanda, contatta il tuo <a href="http://www.proceq.com/contact/proceq-network/">rappresentante locale</a>.<br/><br/>Il Team Proceq<br/><br/>**?2**',
		'Proceq.FormExpiresIn': 'Scade tra (giorni)',
		'Proceq.SelectTimeWeek': 'settimana',
		'Proceq.SelectTimeDay': 'giorno',
		'Proceq.SelectTimeMonth': 'mese',
		'Proceq.GraphNoDataAvailable': 'nessun dato disponibile',
		'Proceq.GraphMaterial': 'Gruppo materiali',
		'Proceq.GraphSecondaryScale': 'Scala di conversione',
		'Proceq.GraphStandard': 'Standard di conversione',
		'Proceq.GraphMeasurementCount': 'Numero di impatti',
		'Proceq.GraphMeasurementSeriesCount': 'Numero di serie di misura',
		'Proceq.StatisticsTitle': 'Statistiche',
		'Proceq.StatisticsCreatedSeries': 'serie di misura create',
		'Proceq.StatisticsModifiedSeries': 'serie di misura modificate',
		'Proceq.StatisticsPhotos': 'foto',
		'Proceq.StatisticsAudioFiles': 'file audio',
		'Proceq.StatisticsUsedStorage': 'memoria utilizzata',
		'Proceq.StatisticsFolder': 'Cartella',
		'Proceq.StatisticsModified': 'Modificato',
		'Proceq.StatisticsCreated': 'Creato',
		'Proceq.StatisticsAll': 'Tutte',
		'Proceq.StatisticsCountry': 'Paese',
		'Proceq.StatisticsCompanySize': 'Dimensioni società',
		'Proceq.StatisticsIndustry': 'Settore',
		'Proceq.DateRangeFrom': 'Da',
		'Proceq.DateRangeTo': 'A',
		'Proceq.GraphHistogram': 'Istogramma durezza',
		'Proceq.GraphSeriesHistogram': 'Serie istogramma durezza',
		'Proceq.StatisticsImpactDevice': 'Percussore',
		'Proceq.StatisticsImpactDeviceHammer': 'Sclerometro',
		'Proceq.GraphExcluded': 'Escluso',
		'Proceq.GraphIncluded': 'Inclusi',
		'Proceq.GraphWithinTolerance': 'In tolleranza',
		'Proceq.GraphOutOfTolerance': 'Fuori tolleranza',
		'Proceq.StatisticsCreatedImpacts': 'Impatti creati (inclusi/esclusi)',
		'Proceq.StatisticsModifiedImpacts': 'Impatti modificati (inclusi/esclusi)',
		'Proceq.GraphNoConversion': 'Nessuna conversione',
		'Proceq.StatisticsTotalImpacts': 'Impatti totali',
		'Proceq.StatisticsTotalSeries': 'Serie totali',
		'Proceq.StatisticsTotalExcluded': 'Totale esclusi',
		'Proceq.StatisticsValuesOutOfLimits': 'Valori fuori limiti',
		'Proceq.StatisticsPeriod': 'Periodo',
		'Proceq.LabelFrom': 'Da',
		'Proceq.LabelTo': 'A',
		'Proceq.LabelImpacts': 'Impatti',
		'Proceq.AbbrevCalendarWeek': 'Sett.',
		'Proceq.eRebNStd_Mean': 'Media',
		'Proceq.ExportFieldTargetScale': 'Scala target',
		'Proceq.ExportFieldConversionCurve': 'Curva di conversione',
		'Proceq.eCC_None': 'Nessuno',
		'Proceq.eCC_Portland_Cement_J_N': 'Cemento Portland J',
		'Proceq.eCC_Early_Strength_J_N': 'Resistenza iniziale J',
		'Proceq.eCC_Blast_Furnace_J_N': 'Altoforno J',
		'Proceq.eCC_Average_Curve_J_N': 'Curva media J',
		'Proceq.eSC_R1_dry': 'JSCE-G504 R1 = 0 (a secco)',
		'Proceq.eSC_R1_black_mark': 'JSCE-G504 R1 = +3 (tacca nera)',
		'Proceq.eSC_R1_wet_surface': 'JSCE-G504 R1 = +5 (superficie bagnata)',
		'Proceq.eCSC_None': 'Nessuno',
		'Proceq.eCSC_Upper': 'Superiore',
		'Proceq.eCSC_Under': 'Inferiore',
		'Proceq.eSCC_US_eCSC_None': 'Resistenza complessiva',
		'Proceq.eSCC_US_eCSC_Upper': 'Resistenza complessiva - superficie di colata superiore',
		'Proceq.eSCC_US_eCSC_Under': 'Resistenza complessiva - superficie di colata inferiore',
		'Proceq.eSCC_PC_eCSC_None': 'Calcestruzzo pompato',
		'Proceq.eSCC_PC_eCSC_Upper': 'Calcestruzzo pompato - superficie di colata superiore',
		'Proceq.eSCC_PC_eCSC_Under': 'Calcestruzzo pompato - superficie di colata inferiore',
		'Proceq.eSCC_US': 'Resistenza complessiva',
		'Proceq.eSCC_PC': 'Calcestruzzo pompato',
		'Proceq.eNoOutlier': 'Nessun outlier',
		'Proceq.eOutlierLow': 'Outlier basso',
		'Proceq.eOutlierHigh': 'Outlier alto',
		'Proceq.eOutlierRedundant': 'Outlier ridondante',
		'Proceq.LogbookPropertyConversionCurveChangedFromTo': 'Curva di conversione modificata da **?0** a **?1**',
		'Proceq.LogbookPropertyConversionCurveChangedTo': 'Curva di conversione modificata a **?0**',
		'Proceq.LogbookPropertyTargetScaleChangedFromTo': 'Scala target modificata da **?0** a **?1**',
		'Proceq.LogbookPropertySurfaceCorrectionChangedFromTo': 'Correzione superficie modificata da **?0** a **?1**',
		'Proceq.LogbookPropertySurfaceCorrectionChangedTo': 'Correzione superficie modificata a **?0**',
		'Proceq.LogbookPropertyCarbonationDepthChangedFromTo':
			'Profondità carbonatazione modificata da **?0** mm a **?1** mm',
		'Proceq.LogbookPropertySurfaceConditionChangedFromTo': 'Condizione superficie modificata da **?0** a **?1**',
		'Proceq.LogbookPropertySurfaceConditionChangedTo': 'Condizione superficie modificata a **?0**',
		'Proceq.LogbookPropertyConcreteAgeChangedFromTo': 'Età calcestruzzo modificata da **?0** gg a **?1** gg',
		'Proceq.LogbookPropertyConcreteAgeChangedTo': 'Età calcestruzzo modificata a **?0** gg',
		'Proceq.ExportColumnCSVOutlierType': 'Outlier',
		'Proceq.ExportColumnCarbonationDepth': 'Profondità carbonatazione',
		'Proceq.ExportColumnConcreteAge': 'Età calcestruzzo',
		'Proceq.ExportFieldCarbonationDepth': 'Profondità carbonatazione',
		'Proceq.ExportFieldConcreteAge': 'Età calcestruzzo',
		'Proceq.ValueConcreteAgeDays': '**?0** gg',
		'Proceq.ValueCarbonationDepthMms': '**?0** mm',
		'Proceq.ExportColumnConversionCurve': 'Curva di conversione',
		'Proceq.ExportColumnTableLegendOutlier': '* Outlier',
		'Proceq.ExportColumnTargetScale': 'Scala target',
		'Proceq.ExportColumnMeasurementAvgCompressiveStrength': 'Misurazione resistenza media a compressione',
		'Proceq.ExportColumnEn13791Screening': 'Screening secondo EN 13791',
		'Proceq.ExportColumnLowestRebound': 'Valore di rimbalzo inferiore',
		'Proceq.ExportColumnMedianRebound': 'Valore di rimbalzo mediano',
		'Proceq.ExportColumnCompressiveStrengthClass': 'Classe di resistenza a compressione secondo EN 206',
		'Proceq.EmailIntroDearContractOwner': 'Gentile titolare del contratto',
		'Proceq.ColumnTableSeriesRegionExport': 'Serie',
		'Proceq.ExportColumSeriesRegionExport': 'Serie',
		'Proceq.ExportTitleTestRegionExport': 'Esportazione regione di prova',
		'Proceq.ErrorSchmidtExportInvalidStandard': 'Tutte le serie devono avere lo stesso standard impostato',
		'Proceq.ErrorSchmidtExportTooFewSeries': 'Per lo screening secondo EN 13791 sono necessarie almeno 9 serie',
		'Proceq.ErrorSchmidtExportTooManySeries':
			'Per una singola esportazione è possibile selezionare massimo 99 serie',
		'Proceq.ErrorSchmidtExportDifferentConfiguration': 'Trovate configurazioni diverse nelle serie',
		'Proceq.ErrorSchmidtExportWrongStandard': "Trovato standard errato per l'esportazione selezionata",
		'Proceq.ErrorSchmidtExportInvalidSeries':
			'Trovata almeno una serie non valida in base allo standard selezionato',
		'Proceq.ErrorSchmidtExportSoftwareError': "Errore durante la generazione dell'esportazione",
		'Proceq.ErrorSchmidtExportOutOfRange': 'La classe di resistenza calcolata è fuori range',
		'Proceq.ButtonExportTestRegionEU': 'Esporta prova screening secondo EN 13791 come PDF',
		'Proceq.ButtonExportTestRegionUniformity': 'Esporta uniformità come PDF',
		'Proceq.ButtonExportTestRegionChina': 'Esporta regione di prova JGJ T23 come PDF',
		'Proceq.ErrorNotYetImplemented': 'Non ancora implementato',
		'Proceq.ErrorSchmidtExportWrongParam':
			'Trovato parametro errato nella serie. Ad esempio: Unità errata per esportazione selezionata',
		'Proceq.ErrorSchmidtExportUnitNotMpa': "Per regione JGJ è obbligatorio impostare l'unità su MPa",
		'Proceq.ErrorSchmidtExportWrongFormFactor': 'Per regione JGJ il form factor non deve essere cilindro 2:1',
		'Proceq.NavSelectView': 'Seleziona vista',
		'Proceq.TextConfirmationAreYouSure': 'Si è sicuri?',
		'Proceq.ErrorContractLinkedDifferentImpactDevice':
			'Impossibile usare il contratto con il numero di serie corrente del percussore',
		'Proceq.TitleShareByUrl': 'Condividi per URL',
		'Proceq.ExportTitleVerification': 'Rapporto di verifica:',
		'Proceq.ExportVerificationStatusFailed': 'Non superata',
		'Proceq.ExportVerificationStatusPassed': 'Superata',
		'Proceq.ExportColumnTestVerificationResult': 'Risultato verifica',
		'Proceq.ExportColumnTestBlockTolerance': 'Incertezza blocco di prova (Ucrm) k=2',
		'Proceq.ExportColumnTestBlockNominalHardness': 'Durezza nominale blocco di prova',
		'Proceq.ExportColumnTestBlockSN': 'Numero di serie blocco di prova',
		'Proceq.ExportColumnTestBlockName': 'Nome blocco di prova',
		'Proceq.ExportColumnVerificationStd': 'Standard di verifica',
		'Proceq.ExportColumnVerificationCriteria': 'Criteri di verifica',
		'Proceq.VerificationCriteriaAverage5Span5Perc': 'media +/- 5%, intervallo +/- 5%',
		'Proceq.VerificationCriteriaReadings6HL': 'letture +/- 6 HL',
		'Proceq.VerificationCriteriaReadings3Perc': 'letture +/- 3%',
		'Proceq.VerificationCriteriaAverage5Perc': 'media +/- 5%',
		'Proceq.VerificationCriteriaAverage2R': 'media +/- 2 R',
		'Proceq.VerificationCriteriaReadings3R': 'letture +/- 3 R',
		'Proceq.StandardEnumCustom': 'Personalizz.',
		'Proceq.MaterialEnumCustom': 'Personalizz.',
		'Proceq.ExportColumnCalibrationAnvilName': 'Nome incudine di taratura',
		'Proceq.ExportColumnCalibrationAnvilSN': 'Numero di serie incudine di taratura',
		'Proceq.ExportColumnCalibrationAnvilReboundValue': 'Valore di rimbalzo incudine di taratura',
		'Proceq.ExportColumnCalibrationAnvilTolerance': 'Tolleranza incudine di taratura',
		'Proceq.ExportColumnTestLoadInfo': 'Info carico di prova',
		'Proceq.NavVerificationData': 'Dati verifica',
		'Proceq.NavCustomData': 'Dati personalizz.',
		'Proceq.TableColumnVerificationResult': 'Risultato',
		'Proceq.TitleVerifications': 'Verifiche',
		'Proceq.LogBookTestBlock': 'Blocco di prova',
		'Proceq.LogBookCalibrationAnvil': 'Incudine di taratura',
		'Proceq.LogbookPropertyTestBlockChangedFromTo': 'Il blocco di prova è stato modificato da "**?0**" a "**?1**"',
		'Proceq.LogbookPropertyTestBlockChangedTo': 'Il blocco di prova è stato modificato a "**?0**"',
		'Proceq.LogbookPropertyCalibrationAnvilChangedFromTo':
			"L'incudine di taratura è stata modificata da **?0** a **?1**",
		'Proceq.LogbookPropertyCalibrationAnvilChangedTo': "L'incudine di taratura è stata modificata a **?0**",
		'Proceq.LogbookPropertyCreatedVerification': 'Creata verifica "**?0**"',
		'Proceq.LogbookPropertyRenamedVerificationFromTo': 'La verifica è stata rinominata da "**?0**" a "**?1**"',
		'Proceq.LogbookResultVerificationFailed': 'Verifica non superata',
		'Proceq.LogbookResultVerificationPassed': 'Verifica superata',
		'Proceq.LogbookPropertyTestLoadChangedFromTo': 'Carico di prova modificato da **?0** a **?1**',
		'Proceq.LogbookPropertyTestLoadChangedTo': 'Carico di prova modificato a **?0**',
		'Proceq.LogBookTestLoad': 'Carico di prova',
		'Proceq.FieldWebOriginalCurve': 'Originale',
		'Proceq.FieldWebCustomCurve': 'Personalizz.',
		'Proceq.ExportFieldValuePair': 'Coppia di valori',
		'Proceq.TableBaseMaterial': 'Materiale base',
		'Proceq.TableDescription': 'Descrizione',
		'Proceq.NavCustomMaterial': 'Materiale personalizzato',
		'Proceq.FolderCustomMaterials': 'Materiali personalizzati',
		'Proceq.SchmidtCurveTypePolynomial': 'fck = a + b*R + c*R² (polinomiale)',
		'Proceq.SchmidtCurveTypeExponencial': 'fck = a.e^(b*R) (esponenziale)',
		'Proceq.SchmidtCurveTypePolynomialShort': 'Polinomiale',
		'Proceq.SchmidtCurveTypeExponencialShort': 'Esponenziale',
		'Proceq.ExportFieldCurveType': 'Tipo di curva',
		'Proceq.TableActionEdit': 'Modifica',
		'Proceq.TableActionAdd': 'Aggiungi',
		'Proceq.WebAccountActivatedTitle': 'Benvenuto in Proceq Live,',
		'Proceq.WebAccountActivatedBodyLine1':
			"Il tuo account è stato correttamente attivato. È ora pronto per l'utilizzo!",
		'Proceq.WebAccountActivatedProceqTeam': 'Team Proceq',
		'Proceq.NavDashboard': 'Cruscotto',
		'App.SchmidtFormFactorRAngle': 'Angolo R corretto',
		'App.SchmidtFormFactorQAngle': 'Valore Q',
		'App.ShareMeasurementsCopySuccess': 'Copiato!',
		'App.ShareMeasurementsCopyFailed': 'Il browser in uso non supporta la copia. Eseguire la copia manualmente.',
		'App.SystemFolders': 'Cartelle di sistema',
		'Proceq.DataSeriesDemo': 'Demo',
		'Proceq.DataSeriesImported': 'Importato',
		'Antenna Spacing [{unit}]': 'Spaziatura antenna [{unit}]',
		'App Version': 'Versione dell&#39;app',
		'Base Unit S/N': 'Unità base S / N',
		'Calculated Depth': 'Profondità calcolata',
		Comment: 'Commento',
		'Contract ID': 'ID contratto',
		'Distance X [{unit}]': 'Distanza X [{unit}]',
		'Distance Y [{unit}]': 'Distanza Y [{unit}]',
		'Export HTML Format Version': 'Esporta versione in formato HTML',
		'File ID': 'ID file',
		'File Name': 'Nome del file',
		'Firmware Version': 'Versione del firmware',
		'Grid Size X [{unit}]': 'Grid Size X [{unit}]',
		'Grid Size Y [{unit}]': 'Dimensione griglia Y [{unit}]',
		'Grid Spacing X [{unit}]': 'Spaziatura griglia X [{unit}]',
		'Grid Spacing Y [{unit}]': 'Spaziatura griglia Y [{unit}]',
		'Hardware Version': 'Versione hardware',
		Line: 'Linea',
		'MAC Address': 'Indirizzo MAC',
		'Marker Number': 'Numero marcatore',
		'Measurement Presets': 'Preimpostazioni di misurazione',
		Mode: 'Modalità',
		Name: 'Nome',
		Objects: 'Oggetti',
		'Probe S/N': 'Sonda S / N',
		Product: 'Prodotto',
		'Repetition Rate [{unit}]': 'Tasso di ripetizione [{unit}]',
		Resolution: 'Risoluzione',
		'Sampling Rate [{unit}]': 'Frequenza di campionamento [{unit}]',
		'Scan Distance [{unit}]': 'Distanza di scansione [{unit}]',
		'Scan Length [{unit}]': 'Lunghezza scansione [{unit}]',
		'Set Depth': 'Imposta profondità',
		'Set Size': 'Imposta dimensione',
		Tag: 'Etichetta',
		'Tag Type': 'Tipo di tag',
		'Time Window [{unit}]': 'Time Window [{unit}]',
		Units: 'unità',
		'User / Probe Info': 'Informazioni utente / sonda',
		Markers: 'marcatori',
		'Created {fileName} in folder {folderName}': 'Creato {fileName} nella cartella {folderName}',
		'Mode: {value}': 'Modalità: {value}',
		'App.HTML.FDL.MeasurementPresets.DgsCalibration': 'Calibrazione DGS',
		'App.HTML.FDL.MeasurementPresets.DgsSnapshot': 'Immagine DGS',
		'App.HTML.FDL.IndicationInfo.Backwall': 'Superficie di fondo',
		'App.HTML.FDL.IndicationInfo.ReferenceType': 'Sistema di referenza',
		'App.HTML.FDL.IndicationInfo.ProbeType': 'Tipo Sonda',
		'App.HTML.FDL.IndicationInfo.calibratedDistance': 'Distanza Calibrazione ({unit})',
		'App.HTML.FDL.IndicationInfo.CalibratedAmplitude': 'Ampiezza di calibrazione ({unit})',
		'App.HTML.FDL.IndicationInfo.Attenuation': 'Attenuazione ({unit})',
		'App.HTML.FDL.IndicationInfo.DeltaVk': 'Delta Vk ({unit})',
		'App.HTML.FDL.IndicationInfo.ErsMainCurve': 'ERS Curva Principale ({unit})',
		'App.HTML.FDL.DgsSnapshot.G1DistanceToDefect': 'G1 Distanza dal difetto ({unit})',
		'App.HTML.FDL.DgsSnapshot.G1AmplitudeOfDefect': 'G1 Ampiezza del difetto ({unit})',
		'App.HTML.FDL.DgsSnapshot.G1.Ers': 'G1 ERS ({unit})',
		'App.HTML.FDL.DgsSnapshot.G1DbToCurve': 'G1 Misure dell ampiezza dB ({unit})',
		'App.HTML.FDL.DgsSnapshot.G2DistanceToDefect': 'G2 Distanza dal difetto ({unit})',
		'App.HTML.FDL.DgsSnapshot.G2AmplitudeOfDefect': 'G2 Ampiezza del difetto ({unit})',
		'App.HTML.FDL.DgsSnapshot.G2.Ers': 'G2 ERS ({unit})',
		'App.HTML.FDL.DgsSnapshot.G2DbToCurve': 'G2 Misure dell ampiezza dB ({unit})',
		'Auth.LoggingIn': 'Login in corso...',
		'Auth.LoggingOut': 'Logout in corso...',
	},
};
