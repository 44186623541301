// @flow
import React, { useEffect } from 'react';
import qs from 'qs';
import { noop, trim } from 'lodash';
import { compose, withState, lifecycle } from 'recompose';
import { LocaleProvider, Spin } from 'antd';
import { connect } from 'react-redux';
import { IntlProvider } from 'react-intl';
import { initGlobalRegister } from 'actions/globalregister';
import { initRecoverLastSesssion, initSharedTokenSession } from 'actions/session';
import { getMeasurementSummaries } from 'actions/login';
import { initLanguage } from 'actions/locale';
import styles from './App.styl';
import { trackUserInSentry } from '../utils/sessionHelper';

const App = ({ loading, locale: { antdLocaleProvider, lang, messages }, session, children }) => {
	const { userName, userID } = session;

	useEffect(() => {
		if (userName && userID) {
			trackUserInSentry(userID, userName);
		}
	}, [userName, userID]);

	return loading ? (
		<Spin className={styles.loading} />
	) : (
		<LocaleProvider locale={antdLocaleProvider}>
			<IntlProvider locale={lang} messages={messages} key={lang} onError={noop}>
				{children}
			</IntlProvider>
		</LocaleProvider>
	);
};

export default compose(
	connect(
		state => ({
			locale: state.locale,
			session: state.session,
		}),
		{
			initGlobalRegister,
			initRecoverLastSesssion,
			initLanguage,
			initSharedTokenSession,
			getMeasurementSummaries,
		}
	),
	withState('loading', 'setLoading', true),
	lifecycle({
		componentDidMount() {
			const { sh, k, d } = qs.parse(trim(window.location.search, '?'));
			this.props.initGlobalRegister();
			this.props.initLanguage();
			if (sh) {
				this.props.initSharedTokenSession(sh);
				this.props.setLoading(false);
			} else if (k && d) {
				this.props.setLoading(false);
			} else {
				this.props.initRecoverLastSesssion();
				setTimeout(() => {
					/* if (this.props.session.sessionType === 'TDS') {
						this.props.getMeasurementSummaries().finally(() => this.props.setLoading(false));
					} else {
						this.props.setLoading(false);
					} */
					this.props.setLoading(false);
				});
			}
		},
	})
)(App);
