// @flow
import React from 'react';
import { connect } from 'react-redux';
import { compose, withState } from 'recompose';
import { FormattedMessage } from 'react-intl';
import { Button } from 'antd';
import URL from 'apis/urls';
import { request } from 'actions/ajax';
import styles from '../shared/SmarterTable.styl';

export const ExportFeatures = ({ loading, exportFeatures }: { loading: boolean, exportFeatures: Function }) => (
	<Button className={styles.button} type="primary" onClick={exportFeatures} loading={loading}>
		<FormattedMessage id="Export Features" />
	</Button>
);

export default compose(
	withState('loading', 'setLoading', false),
	connect(null, (dispatch: Function, props) => ({
		exportFeatures: () => {
			dispatch(
				request({
					url: URL.EXPORT_FEATURES,
					setLoading: props.setLoading,
					params: {},
					returnResponseHeaders: true,
				})
			).then(response =>
				window.fileDownload(
					response.data,
					(response.headers['content-disposition'] || '').split('"')[1],
					response.headers['content-type']
				)
			);
		},
	}))
)(ExportFeatures);
