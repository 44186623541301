// @flow
import React from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { compose, lifecycle, withStateHandlers } from 'recompose';
import { Form, Row, Button, message, Radio } from 'antd';
import axios from 'axios';
import type { FormProps } from 'antd/lib/form';
import { FormattedMessage, injectIntl } from 'react-intl';
import URL from 'apis/urls';
import { request } from 'actions/ajax';
import { getInitialRange } from 'constants/dateRange';
import { getOnlyDataFromGlobalDataKey } from 'utils/registryHelper';
import { ROLES, hasRoleLevel } from 'utils/rolesHelper';
import SmarterSelect from '../shared/SmarterFormItems/Select';
import styles from './RegionalAnalytics.styl';
import layoutStyles from '../layout/Layout.styl';
import selectStyles from './UserAnalytics.styl';
import DateRange from '../shared/DateRange';
import MapView from './MapView';
import ExportStats from './ExportStats';

const normalize = formValues => {
	const regionGlobalData = getOnlyDataFromGlobalDataKey('regionsnamesregionalanalytics').filter(v => v !== 'All');
	const productGlobalData = getOnlyDataFromGlobalDataKey('availproductsappfullregional').filter(v => v !== 'All');
	const productModelGlobalData = getOnlyDataFromGlobalDataKey('productModelsRegional').filter(v => v !== 'All');

	let productFormValue = formValues.product;
	let productModelFormValue = formValues.productModel;
	let regionFormValue = formValues.region;

	if (regionFormValue !== undefined && regionFormValue.includes('All')) {
		regionFormValue = regionGlobalData;
	}

	if (productFormValue !== undefined && productFormValue.includes('All')) {
		productFormValue = productGlobalData;
	}

	if (productModelFormValue !== undefined && productModelFormValue.includes('All')) {
		productModelFormValue = productModelGlobalData;
	}

	const params: Object = {
		product: productFormValue,
		region: regionFormValue,
		productModel: productModelFormValue,
	};

	if (formValues.dates && formValues.dates.from && formValues.dates.to) {
		params.from = formValues.dates.from.unix();
		params.to = formValues.dates.to.unix();
	}
	return params;
};

export const RegionalAnalyticsPage = ({
	isAdmin,
	loading,
	users,
	data,
	excludedCount,
	loadAnalytics,
	form,
	cancel,
}: {
	isAdmin: boolean,
	loading: boolean,
	users: User[],
	data: RegionalAnalytics[],
	excludedCount: number,
	loadAnalytics: Function,
	form: FormProps,
	cancel: Function,
}) => (
	<div className={styles.filter_container}>
		<div className={classNames(styles.header_container, layoutStyles.container)}>
			<h2 className="label-statistics">
				<FormattedMessage id="App.DashboardFilters" />
			</h2>
			<Form onSubmit={loadAnalytics(isAdmin)}>
				<div className={styles.left_filter_section}>
					<Form.Item label={<FormattedMessage id="App.AnalyticsRegion" />}>
						{form.getFieldDecorator('region', {
							rules: [{ required: true }],
							initialValue: ['All'],
						})(
							<SmarterSelect
								mode="multiple"
								className={styles.select}
								globalRegister="regionsnamesregionalanalytics"
								disabled={loading}
							/>
						)}
					</Form.Item>
					<Form.Item label={<FormattedMessage id="App.AnalyticsFilter" />}>
						{form.getFieldDecorator('filter', {
							initialValue: 'family',
						})(
							<Radio.Group>
								<Radio value={'family'}>{'App'}</Radio>
								<Radio value={'productModel'}>{'Product'}</Radio>
							</Radio.Group>
						)}
					</Form.Item>
					{form.getFieldValue('filter') === 'family' && (
						<Form.Item label={<FormattedMessage id="App.Product.Family" />}>
							{form.getFieldDecorator('product', {
								initialValue: [],
							})(
								<SmarterSelect
									mode="multiple"
									className={selectStyles.select}
									globalRegister="availproductsappfullregional"
									disabled={loading}
								/>
							)}
						</Form.Item>
					)}
					{form.getFieldValue('filter') === 'productModel' && (
						<Form.Item label={<FormattedMessage id="App.ProductModel" />}>
							{form.getFieldDecorator('productModel', {
								initialValue: [],
							})(
								<SmarterSelect
									mode="multiple"
									className={selectStyles.select}
									globalRegister="productModelsRegional"
									disabled={loading}
								/>
							)}
						</Form.Item>
					)}
				</div>
				<div className={styles.right_filter_section}>
					<Form.Item label={<FormattedMessage id="App.DashboardDateRange" />}>
						{form.getFieldDecorator('dates', {
							initialValue: getInitialRange(),
							disabled: loading,
						})(<DateRange disabled={loading} />)}
					</Form.Item>
				</div>
				<Row className={styles.buttons}>
					<Button type="primary" htmlType="submit" loading={loading}>
						<FormattedMessage id="Proceq.TableActionRefresh" />
					</Button>
					&nbsp;
					{loading && (
						<Button type="default" onClick={cancel}>
							<FormattedMessage id="App.AnalyticsRegionExportCancel" />
						</Button>
					)}
					<ExportStats params={normalize(form.getFieldsValue())} disabled={loading} />
				</Row>
			</Form>
		</div>
		<MapView
			loading={loading}
			data={data}
			measurementCount={data.length}
			excludedCount={excludedCount}
			users={users}
		/>
	</div>
);

export default compose(
	injectIntl,
	Form.create({}),
	withStateHandlers(
		{
			loading: false,
			formValues: {},
			users: [],
			data: [],
			excludedCount: 0,
			cancelSource: axios.CancelToken.source(),
		},
		{
			startLoading: state => () => ({
				...state,
				loading: true,
			}),
			finishLoading: state => (users, data, excludedCount, formValues) => ({
				...state,
				users,
				data,
				excludedCount,
				formValues,
				loading: false,
			}),
			setUsers: state => users => ({
				...state,
				users,
			}),
			cancel: ({ cancelSource, ...state }, { intl }) => () => {
				cancelSource.cancel();
				message.error(intl.formatMessage({ id: 'App.AnalyticsOperationCancelled' }));
				return { ...state, cancelSource: axios.CancelToken.source(), loading: false };
			},
		}
	),
	connect(
		state => ({
			product: state.session.productType,
			region: state.session.serverRegion,
			isAdmin: hasRoleLevel(state.session.scopes, ROLES.ADMIN),
		}),
		(dispatch: Function, props) => ({
			loadAnalytics: (isAdmin: boolean) => e => {
				if (e) e.preventDefault();
				props.form.validateFields((err, formValues) => {
					if (err) {
						return;
					}

					const params = normalize(formValues);
					// This operation is expensive
					// props.setData([]);
					props.startLoading();

					const promises = [];
					const productRegionIndex = [];

					if (params.region !== undefined && params.region.length > 0) {
						if (params.product !== undefined && params.product.length > 0) {
							params.product.forEach(product => {
								params.region.forEach(region => {
									productRegionIndex.push({ product, region });
									promises.push(
										dispatch(
											request({
												method: 'POST',
												url: URL.LOCATION_ANALYTICS,
												params: {
													...params,
													product,
													region,
												},
												options: {
													cancelToken: props.cancelSource.token,
												},
											})
										)
									);
								});
							});
						} else if (params.productModel !== undefined && params.productModel.length > 0) {
							params.productModel.forEach(productModel => {
								params.region.forEach(region => {
									productRegionIndex.push({ product: productModel, region });
									promises.push(
										dispatch(
											request({
												method: 'POST',
												url: URL.LOCATION_ANALYTICS,
												params: {
													...params,
													productModel,
													region,
												},
												options: {
													cancelToken: props.cancelSource.token,
												},
											})
										)
									);
								});
							});
						}
					}

					Promise.all(promises).then(async data => {
						const aggregated = [];
						let excluded = 0;
						for (let i = 0; i < data.length; i++) {
							const payload = data[i];

							for (let j = 0; j < payload.length; j++) {
								const measurement = payload[j];

								const { lat, lon } = measurement;
								if (lat <= 90 && lat >= -90 && lon <= 180 && lon >= -180 && lat !== 0 && lon !== 0) {
									aggregated.push({
										...measurement,
										...productRegionIndex[i],
									});
								} else {
									excluded++;
								}
							}
						}
						// Load user data based on measurement data
						const uIDs = [];
						aggregated.forEach(measurement => {
							if (measurement.uid) uIDs.push(measurement.uid);
							if (measurement.ownerId) uIDs.push(measurement.ownerId);
						});
						const uniqueIds = [...new Set(uIDs)];
						let users = [];
						if (uniqueIds.length > 0) {
							await dispatch(
								request({
									url: isAdmin ? URL.GET_ACCOUNTS : URL.GET_USERS,
									params: {
										ids: uniqueIds,
									},
								})
							).then(user => {
								users = user.data;
							});
						}

						props.finishLoading(users, aggregated, excluded, formValues);
					});
				});
			},
		})
	),
	lifecycle({
		componentDidMount() {
			this.props.loadAnalytics(this.props.isAdmin)();
		},
	})
)(RegionalAnalyticsPage);
