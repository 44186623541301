// @flow
import React from 'react';
import { get, isFinite } from 'lodash';
import { Table } from 'antd';
import { FormattedMessage } from 'react-intl';
import { convertAndDisplay } from 'constants/conversionRates';
import { TagObjectType } from 'constants/exportConstants';
import { ProductConstants } from 'components/contracts/contractConsts';
import ConvertedUnits from '../../shared/ConvertedUnits';

import Backwall from './icoBackwall@2x.png';
import NonMetallic from './icoNonMetallic@2x.png';
import Rebar from './icoRebar@2x.png';
import Void from './icoVoid@2x.png';
import LiveWire from './icoLiveWire@2x.png';
import UserDefined from './icoUserDefined@2x.png';
import PulseVelocity from './iconLogbookPulseVelocity.svg';

export const LogEventInfo = ({ log, product, isMetric, scanType }: GPRLogbookEntryProps) => {
	const convertedUnitProps = {
		isMetric,
		scanType,
		values: [],
	};
	const commonSet =
		log.content.type !== TagObjectType.Velocity
			? [
					{
						id: 'App.LogbookSettingsCalculatedDepth',
						unitId: `${product}.Logbook Panel.Calculated Depth`,
						dataIndex: get(log.content, 'locationY'),
					},
					{
						id: 'App.LogbookSettingsSetDepth',
						unitId: `${product}.Logbook Panel.Set Depth`,
						dataIndex: get(log.content, 'calibratedDepthString'),
					},
					{
						id: 'App.LogbookSettingsSetSize',
						unitId: `${product}.Logbook Panel.Set Size`,
						dataIndex: get(log.content, 'sizeCentimeter'),
					},
			  ]
			: [];

	const punditVelocitySet =
		product === ProductConstants.PUNDIT && log.content.type === TagObjectType.Velocity
			? [
					{
						id: 'App.HTML.PUNDIT.TransmissionTime',
						unitId: 'PUNDIT.GridScan.TransmissionTime',
						dataIndex: log.content.transmissionTime,
					},
					{
						id: 'App.HTML.PUNDIT.Display.LocalDepth',
						unitId: 'PUNDIT.Logbook Panel.Set Depth',
						dataIndex: log.content.settings['Local Depth'],
					},
			  ]
			: [];

	const rows = [
		{
			id: 'App.LogbookSettingsType',
			icon: get(log.content, 'type'),
		},
		{
			id: 'App.LogbookSettingsDistance',
			unitId: `${product}.Logbook Panel.Tag Object Distance`,
			dataIndex: get(log.content, 'locationX'),
		},
		...(product === 'GPR'
			? [
					{
						id: 'App.LogbookSettingsDielectricConstant',
						unitId: `GPR.Logbook Panel.Dielectric Constant`,
						dataIndex: get(log.content, 'settings.Dielectric Constant (ℇᵣ)'),
					},
					{
						id: 'App.LogbookSettingsLinearGain',
						unitId: `GPR.Logbook Panel.Linear Gain`,
						dataIndex: get(log.content, 'settings.Linear Gain (dB)'),
					},
			  ]
			: []),
		...(product === 'PUNDIT'
			? [
					{
						id: 'App.LogbookSettingsPulseVelocity',
						unitId: `PUNDIT.Settings Panel.Pulse Velocity`,
						dataIndex: get(log.content, 'settings.Pulse Velocity'),
					},
					{
						id: 'App.LogbookSettingsAnalogGain',
						unitId: `PUNDIT.Settings Panel.Analogue Gain`,
						dataIndex: get(log.content, 'settings.Analogue Gain'),
					},
			  ]
			: []),
		{
			id: 'App.LogbookSettingsTimeGainCompensation',
			unitId: `${product}.Logbook Panel.Time Gain Compensation`,
			dataIndex: get(log.content, 'settings.Time Gain Compensation'),
		},
		...commonSet,
		...punditVelocitySet,
		{
			id: 'App.LogbookSettingsComment',
			dataIndex: get(log.content, 'comment'),
		},
		{
			id: 'App.LogbookSettingsName',
			dataIndex: get(log.content, 'settings.name'),
		},
	];

	return (
		<Table rowKey="id" dataSource={rows} pagination={false} showHeader={false} size="small" className="to_toggle">
			<Table.Column
				key="label"
				render={(text, record) =>
					record.unitId ? (
						<ConvertedUnits id={record.id} unitId={record.unitId} {...convertedUnitProps} />
					) : (
						<FormattedMessage id={record.id} />
					)
				}
				width={230}
			/>
			<Table.Column
				key="value"
				render={(text, record) => {
					if (record.icon) {
						let icon;
						switch (record.icon) {
							case 'Backwall':
								icon = Backwall;
								break;
							case 'Non Metallic':
								icon = NonMetallic;
								break;
							case 'Rebar':
								icon = Rebar;
								break;
							case 'Void':
								icon = Void;
								break;
							case 'Live Wire':
								icon = LiveWire;
								break;
							case 'User Defined':
								icon = UserDefined;
								break;
							case TagObjectType.Velocity:
								icon = PulseVelocity;
								break;
							case TagObjectType.PulseVelocity:
								icon = PulseVelocity;
								break;
							default:
								break;
						}
						const iconStr =
							record.icon === TagObjectType.Velocity ? TagObjectType.PulseVelocity : record.icon;
						return (
							<span>
								<img src={icon} alt="type" style={{ height: 34 }} />
								&nbsp;
								{iconStr}
							</span>
						);
					}
					if (record.unitId) {
						return isFinite(+record.dataIndex)
							? convertAndDisplay(record.unitId, scanType, isMetric).convertFunction(+record.dataIndex)
							: '-';
					}
					return record.dataIndex;
				}}
			/>
		</Table>
	);
};

export default LogEventInfo;
