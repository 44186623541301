// @flow
import React, { Component } from 'react';
import { Select as SelectComponent } from 'antd';
import { FormattedMessage, injectIntl } from 'react-intl';
import type { InjectIntlProvidedProps } from 'react-intl';
import { getTextValue } from 'utils/registryHelper';
import { compose, withState } from 'recompose';
import { getCookie, setCookie } from '../../../utils/sessionHelper';

// eslint-disable-next-line react/prefer-stateless-function
export class Select extends Component<
	{
		mode: string,
		globalRegister: string,
		emptyOption?: string,
		open: boolean,
		saveSelects?: string,
		selectOpt: SelectOpts[],
		setOpen: Function,
		onFocus: Function,
		onBlur: Function,
		onDeselect: Function,
	} & InjectIntlProvidedProps
> {
	render() {
		const {
			mode,
			globalRegister,
			selectOpt,
			emptyOption,
			open,
			setOpen,
			intl,
			saveSelects = '',
			...props
		} = this.props;
		const onSelectChange = selection => {
			if (saveSelects) {
				const selections = getCookie(saveSelects);
				if (selections !== '') {
					const newSelections = `${selections},${selection}`;
					setCookie(saveSelects, newSelections, 100000 * 60 * 60 * 1000);
				} else {
					setCookie(saveSelects, selection, 100000 * 60 * 60 * 1000);
				}
			}
		};
		const onDeselectChange = selection => {
			if (saveSelects) {
				const newSelections = getCookie(saveSelects)
					.split(',')
					.filter(currentSelection => currentSelection !== selection)
					.toString();
				setCookie(saveSelects, newSelections, 100000 * 60 * 60 * 1000);
			}
		};

		return (
			<SelectComponent
				showSearch
				filterOption={(value, option) => {
					const label = intl.formatMessage({ id: option.props.id });
					return label.toLowerCase().includes(value.toLowerCase());
				}}
				mode={mode === undefined || mode === '' ? 'default' : mode}
				getPopupContainer={node => node || document.body}
				onSelect={onSelectChange}
				onDeselect={onDeselectChange}
				{...props}
			>
				{emptyOption && (
					<SelectComponent.Option key="" value="">
						<FormattedMessage id={emptyOption} />
					</SelectComponent.Option>
				)}
				{selectOpt !== undefined &&
					selectOpt.map(opt => (
						<SelectComponent.Option
							key={opt.value}
							id={opt.name}
							title={opt.disable ? opt.disableText ?? 'disable' : undefined}
							disabled={opt.disable}
						>
							{opt !== undefined && intl.formatMessage({ id: opt.name })}
						</SelectComponent.Option>
					))}
				{selectOpt === undefined &&
					getTextValue([globalRegister]).map(({ text, value }) => (
						<SelectComponent.Option key={value} id={text}>
							{intl.formatMessage({ id: text })}
						</SelectComponent.Option>
					))}
			</SelectComponent>
		);
	}
}

export default compose(withState('open', 'setOpen', false), injectIntl)(Select);
