// @flow
import React from 'react';
import { compose, lifecycle, withState } from 'recompose';
import { connect } from 'react-redux';
import { request } from 'actions/ajax';
import { Spin } from 'antd';
import URL from 'apis/urls';
import { injectIntl } from 'react-intl';
import routeURLs from '../../routes/urls';
import { getProductFamily } from '../contracts/contractConsts';

export const ContractsRedirectRoute = () => {
	return <Spin style={{ marginTop: '50px', width: '100%' }} />;
};

export default compose(
	injectIntl,
	withState('error', 'setError', null),
	withState('loading', 'setLoading', true),
	withState('contract', 'setContract', null),
	connect(null, (dispatch: Function, props) => ({
		loadContract: () => {
			const href = window.location.href;
			let contractId = '';
			if (href.includes('contractId=')) {
				const contractInfo = href.split('=');
				contractId = contractInfo[1];
			}
			dispatch(
				request({
					method: 'GET',
					url: `${URL.GET_CONTRACTS}${contractId}`,
					setLoading: props.setLoading,
					params: {
						product: undefined,
					},
				})
			)
				.then(contract => {
					props.setLoading(false);
					props.setContract(contract);
					const productFamily = getProductFamily(contract.product, contract.type);
					if (productFamily !== null) {
						window.location.href = `/contracts/${productFamily}/contracts?contractId=${contract.id}`;
					} else {
						window.location.href = routeURLs.SETTINGS;
					}
				})
				.catch(() => {
					props.setLoading(false);
					window.location.href = routeURLs.SETTINGS;
				});
		},
	})),
	lifecycle({
		componentDidMount() {
			this.props.loadContract();
		},
	})
)(ContractsRedirectRoute);
