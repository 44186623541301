// @flow
import { isLive } from 'apis/urls';
import React from 'react';
import { getEagleCareText } from './contractConsts';

export type ColumnConfigProps = {
	dataIndex: string,
	title: string,
	globalRegister?: string | string[],
	render?: Function,
	sorter?: boolean,
	filter?: boolean | string,
	multiSelect?: boolean,
	filterSearch?: any,
};

const commonColumnsBefore: ColumnConfigProps[] = [
	{
		title: 'Proceq.FormExpirationDate',
		dataIndex: 'expirationDate',
		sorter: true,
		typeField: 'date',
		renderIfEmpty: 'Proceq.TextLifetime',
	},
	{
		title: 'BP',
		dataIndex: 'bp',
		filter: true,
	},
	{
		title: 'Remarks',
		dataIndex: 'remarks',
		filter: true,
		render: text => <div style={{ maxWidth: '300px', overflow: 'hidden', textOverflow: 'ellipsis' }}>{text}</div>,
	},
];

const invoiceColumns: ColumnConfigProps[] = [
	{
		title: 'Invoice No 1',
		dataIndex: 'invoiceNo',
		filter: true,
	},
	{
		title: 'Invoice No 2',
		dataIndex: 'invoiceNo2',
		filter: true,
	},
	{
		title: 'Invoice No 3',
		dataIndex: 'invoiceNo3',
		filter: true,
	},
	{
		title: 'Invoice No 4',
		dataIndex: 'invoiceNo4',
		filter: true,
	},
	{
		title: 'Invoice No 5',
		dataIndex: 'invoiceNo5',
		filter: true,
	},
	{
		title: 'Invoice No 6',
		dataIndex: 'invoiceNo6',
		filter: true,
	},
];

const commonColumnsAfter: ColumnConfigProps[] = [
	{
		title: 'Created',
		dataIndex: 'created',
		sorter: true,
		typeField: 'date',
	},
	{
		title: 'Updated',
		dataIndex: 'updated',
		sorter: true,
		typeField: 'date',
	},
	{
		title: 'Proceq.FormLanguage',
		dataIndex: 'language',
		globalRegister: 'languagescontacttds',
		filter: true,
		multiSelect: true,
	},
];

const columnConfig: {
	[key: string]: ColumnConfigProps[],
} = {
	GPR: [
		{
			title: 'App.RoleBU',
			dataIndex: 'businessUnit',
			globalRegister: 'buunits',
			filter: true,
			multiSelect: true,
		},
		{
			title: 'App.Product',
			dataIndex: 'product',
			globalRegister: isLive ? 'wifiproductslive' : 'wifiproductsfull',
			filter: true,
			multiSelect: true,
		},
		{
			title: 'App.ProductType',
			dataIndex: 'tier',
			globalRegister: ['tiersgpr', 'tierspundit', 'tiersfdl', 'tiersgpr_palm'],
			filter: 'tier',
			multiSelect: true,
		},
		{
			title: 'App.DeviceSN',
			dataIndex: 'probeSN',
			sorter: true,
			filter: 'probeSN',
		},
		{
			title: 'App.MASN',
			dataIndex: 'mASN',
			filter: 'mASN',
		},
		{
			title: 'App.ContractsWifiMAC',
			dataIndex: 'hardwareID',
			sorter: true,
			filter: 'hardwareID',
		},
		...commonColumnsBefore,
		{
			title: 'App.IsRedeemed',
			dataIndex: 'isCareRedeemed',
			globalRegister: 'yesorno',
			sorter: true,
			filter: 'isCareRedeemed',
			render: (text: number) => getEagleCareText(text),
		},
		...commonColumnsAfter,
		...invoiceColumns,
	],
	MAINTENANCE_SERVICES: [
		{
			title: 'App.RoleBU',
			dataIndex: 'businessUnit',
			globalRegister: 'buunits',
			filter: true,
			multiSelect: true,
		},
		{
			title: 'App.Product',
			dataIndex: 'product',
			globalRegister: 'latestavailproductsfull',
			filter: true,
			multiSelect: true,
		},
		{
			title: 'App.ProductType',
			dataIndex: 'tier',
			globalRegister: ['tiersgpr', 'tierspundit', 'tiersfdl', 'tiersgpr_palm'],
			filter: 'tier',
			multiSelect: true,
		},
		{
			title: 'App.DeviceSN',
			dataIndex: 'probeSN',
			sorter: true,
			filter: 'probeSN',
		},
		...commonColumnsBefore,
		...commonColumnsAfter,
		...invoiceColumns,
	],
	SAAS: [
		{
			title: 'Contract ID',
			dataIndex: 'id',
			filter: 'id',
		},
		{
			title: 'Primary Contact Email',
			dataIndex: 'email',
			filter: 'email',
		},
		{
			title: 'App.RoleBU',
			dataIndex: 'businessUnit',
			globalRegister: 'buunits',
			filter: true,
			multiSelect: true,
		},
		{
			title: 'BP',
			dataIndex: 'bp',
			filter: true,
		},
		{
			title: 'First Expiration Date',
			dataIndex: 'firstExpirationDate',
			sorter: true,
		},
		{
			title: 'License Count',
			dataIndex: 'licenseCount',
			filter: true,
		},
		{
			title: 'Remarks',
			dataIndex: 'remarks',
			filter: true,
			render: text => (
				<div style={{ maxWidth: '300px', overflow: 'hidden', textOverflow: 'ellipsis' }}>{text}</div>
			),
		},
		{
			title: 'Proceq.FormLanguage',
			dataIndex: 'language',
			globalRegister: 'languagescontacttds',
			filter: true,
			multiSelect: true,
		},
		...invoiceColumns,
	],
	SAAS_LICENSES: [
		{
			title: 'Contract',
			dataIndex: 'contractLink',
		},
		{
			title: 'History',
			dataIndex: 'history',
		},
		{
			title: 'Activation Date',
			dataIndex: 'activationDate',
			filter: true,
		},
		{
			title: 'Expiration Date',
			dataIndex: 'expirationDate',
			sorter: true,
			filter: true,
		},
		{
			title: 'Active User',
			dataIndex: 'activeUID',
			filter: true,
		},
		{
			title: 'App.Product',
			dataIndex: 'product',
			globalRegister: 'saasProducts',
			filter: true,
			multiSelect: true,
		},
		{
			title: 'Device ID',
			dataIndex: 'deviceID',
			filter: true,
			sorter: true,
		},
		{
			title: 'Generated By',
			dataIndex: 'uID',
			filter: 'uID',
		},
		{
			title: 'Business Unit',
			dataIndex: 'businessUnit',
			globalRegister: 'buunits',
			filter: true,
			multiSelect: true,
		},
		{
			title: 'Generated At',
			dataIndex: 'created',
			sorter: true,
			typeField: 'date',
			filter: true,
		},
		{
			title: 'type',
			dataIndex: 'tier',
			filter: 'tier',
			multiSelect: true,
		},
	],
	EQUOTIP: [
		{
			title: 'App.RoleBU',
			dataIndex: 'businessUnit',
			globalRegister: 'buunits',
			filter: true,
			multiSelect: true,
		},
		{
			title: 'App.Product',
			dataIndex: 'product',
			globalRegister: isLive ? 'bluetoothproductslive' : 'bluetoothproductsfull',
			filter: true,
			multiSelect: true,
		},
		{
			title: 'App.ProductType',
			dataIndex: 'tier',
			globalRegister: ['tiersleebd', 'tiersuci', 'tiersschmidt', 'tiersglm', 'tiersssl'],
			filter: 'tier',
			multiSelect: true,
		},
		{
			title: 'App.DeviceSN',
			dataIndex: 'probeSN',
			sorter: true,
			filter: 'probeSN',
		},
		{
			title: 'App.BluetoothSN',
			dataIndex: 'dongleSN',
			sorter: true,
			filter: 'dongleSN',
		},
		{
			title: 'App.HardwareId',
			dataIndex: 'hardwareID',
			sorter: true,
			filter: 'hardwareID',
		},
		...commonColumnsBefore,
		{
			title: 'App.IsRedeemed',
			dataIndex: 'isCareRedeemed',
			globalRegister: 'yesorno',
			sorter: true,
			filter: 'isCareRedeemed',
			render: (text: number) => getEagleCareText(text),
		},
		...commonColumnsAfter,
		...invoiceColumns,
	],
	SOFTWARE: [
		{
			title: 'App.RoleBU',
			dataIndex: 'businessUnit',
			globalRegister: 'buunits',
			filter: true,
			multiSelect: true,
		},
		{
			title: 'App.Product',
			dataIndex: 'product',
			globalRegister: 'softwareproducts',
			filter: true,
			multiSelect: true,
		},
		{
			title: 'App.ProductType',
			dataIndex: 'tier',
			filter: 'tier',
			multiSelect: true,
		},
		{
			title: 'App.SerialLicenseNo',
			dataIndex: 'probeSN',
			sorter: true,
			filter: 'probeSN',
		},
		...commonColumnsBefore,
		...commonColumnsAfter,
		...invoiceColumns,
	],
};

export default columnConfig;
