import { checkStatusJson } from 'apis/restUtils';
import URL from 'apis/urls';
import { httpRequest } from 'apis/httpClient';
import actionTypes from './actionTypes';

const globalRegiterMetadataToFetch = [
	{ key: 'companysize', path: 'companysizes' },
	{ key: 'industrytype', path: 'industries' },
	{ key: 'languagescontacttds', path: 'languages' },
];

export function setKeyValue(value) {
	return dispatch => {
		dispatch({
			type: actionTypes.SET_KEY_VALUE,
			value,
		});
	};
}

export function initMetadataRegister(keyRegister, pathUrl) {
	return (dispatch, getState) => {
		const apiUrlFull = URL.METADATA_FIELDS + pathUrl;
		return httpRequest(null, getState().locale, apiUrlFull, 'get', false)
			.then(checkStatusJson)
			.then(json => {
				const valueForRegister = [];
				json.forEach(entry => {
					valueForRegister.push({
						label: entry.value,
						data: entry.key,
					});
				});
				dispatch({
					type: actionTypes.SET_KEY_VALUE,
					value: [keyRegister, valueForRegister],
				});
			});
	};
}
export function initMetadataRegisters() {
	return dispatch => {
		globalRegiterMetadataToFetch.forEach(metaInfo => {
			dispatch(initMetadataRegister(metaInfo.key, metaInfo.path));
		});
	};
}
export function initGlobalRegister() {
	return dispatch => {
		dispatch({
			type: actionTypes.INIT_GLOBAL_REGISTER,
		});
	};
}
