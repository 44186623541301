// @flow
import React from 'react';
import { get } from 'lodash';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Layout, Button } from 'antd';
import { compose, withState, lifecycle, withStateHandlers } from 'recompose';
import URL, { isProduction } from 'apis/urls';
import { FormattedMessage } from 'react-intl';
import { request } from 'actions/ajax';
import { hasRoleLevel, ROLES } from 'utils/rolesHelper';
import columnConfig, { defaultColumnConfig } from './columnConfig';
import Folders from './Folders';
import MeasurementTable from './MeasurementTable';
import styles from './MeasurementTable.styl';
import EquotipReadings from './EquotipReadings';
import GLMReadings from './Readings/GLMReadings';
import Logbook from './Logbook';
import JSONTreeView from './Debug/JSONTreeView';
import Attachments from './Debug/Attachments';
import Flagged from './icoFlagged@2x.png';
import GPRReadings from './GPRReadings';

export const Measurements = ({
	loading,
	product,
	fileType,
	isDev,
	folders,
	measurements,
	totalRecords,
	params,
	loadMeasurements,
	folderID,
	setFolder,
	refreshCounter,
}: {
	loading: boolean,
	product: ProductCode,
	fileType: MeasurementFileType,
	isDev: boolean,
	folders: Folder[],
	measurements: Measurement[],
	totalRecords: number,
	params: Object,
	loadMeasurements: Function,
	folderID: string,
	setFolder: Function,
	refreshCounter: number,
}) => (
	<Layout>
		<Layout.Sider className="folders_div">
			<Folders folders={folders} activeFolder={folderID} setFolder={setFolder} />
		</Layout.Sider>

		<Layout.Content>
			<MeasurementTable
				key={refreshCounter}
				loading={loading}
				fileType={fileType}
				params={params}
				measurements={measurements}
				loadMeasurements={loadMeasurements}
				totalRecords={totalRecords}
				columnConfig={[
					{
						title: 'Proceq.TableDataSeriesName',
						dataIndex: 'name',
						sorter: true,
						filter: 'name',
						render: (text, record) => {
							const version = get(record, 'content.info.version');
							return (
								<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
									{record.flagged && (
										<img src={Flagged} alt="flagged" style={{ height: 14, margin: 4 }} />
									)}
									<span style={{ alignSelf: 'flex-start', flex: 1 }}>{text}</span>
									&nbsp; &nbsp;
									<Logbook
										mID={record.id}
										product={product}
										version={version}
										withunsynced={params.withunsynced}
									/>
									{isDev && <JSONTreeView mID={record.id} withunsynced={params.withunsynced} />}
									{isDev && <Attachments mID={record.id} />}
									{['GPR', 'GPR_SOIL', 'PUNDIT', 'FDL', 'PIT_IE'].includes(product) && (
										<Link to={`/data/${record.id}`} rel="noopener noreferrer">
											<Button type="primary" className={styles.button}>
												<FormattedMessage id="App.HTML.HTMLView" />
											</Button>
										</Link>
									)}
								</div>
							);
						},
					},
					...get(columnConfig, product, defaultColumnConfig),
				]}
				expandedRowRender={(() => {
					switch (product) {
						case 'EQUOTIP':
						case 'SSL':
						case 'SCHMIDT':
							return record => <EquotipReadings mID={record.id} product={product} />;
						case 'GLM':
							return record => <GLMReadings mID={record.id} product={product} params={params} />;
						case 'GPR':
							return record => <GPRReadings mID={record.id} product={product} />;
						default:
							return null;
					}
				})()}
			/>
		</Layout.Content>
	</Layout>
);

export default compose(
	withState('loading', 'setLoading', true),
	withState('folders', 'setFolders', []),
	withState('measurements', 'setMeasurements', []),
	withState('totalRecords', 'setTotalRecords', 0),
	withState('params', 'setParams', {}),
	connect(state => ({
		product: state.session.productType,
		fileType: ['EQUOTIP', 'SCHMIDT', 'GLM'].includes(state.session.productType) ? 'measurement' : 'all',
		isDev: hasRoleLevel(state.session.scopes, ROLES.DEV),
	})),
	connect(null, (dispatch: Function, props) => ({
		loadFolders: withunsynced => {
			dispatch(
				request({
					url: URL.GET_FOLDERS,
					params: {
						withunsynced,
					},
				})
			).then(data => {
				let folders = data.data;
				if (props.product === 'SCHMIDT' && isProduction) {
					folders = folders.filter(folder => !['import', 'demo'].includes(folder.type));
				}
				props.setFolders(folders);
			});
		},
	})),
	connect(null, (dispatch: Function, props) => ({
		loadMeasurements: newParams => {
			const params = {
				folderID: 'all',
				offset: 0,
				limit: 20,
				...props.params,
				fileType: props.fileType,
				...newParams,
				productFamily: props.product,
			};
			props.setParams(params);
			props.loadFolders(params.withUnSynced);
			dispatch(
				request({
					method: 'POST',
					url: URL.GET_MEASUREMENTS,
					setLoading: props.setLoading,
					params,
				})
			).then(data => {
				// this is needed to close opened measurements so they can be refreshed
				props.setMeasurements([]);
				props.setMeasurements(data.data);
				props.setTotalRecords(data.totalRecords);
			});
		},
	})),
	withStateHandlers(
		{
			refreshCounter: 0, // by reloading the key parameter, we can essentially reset pagination/sort/sort state in MeasurementBaseTable
		},
		{
			refresh: state => () => ({
				refreshCounter: state.refreshCounter + 1,
			}),
		}
	),
	withState('folderID', 'setFolder', 'all'),
	lifecycle({
		componentDidMount() {
			this.props.loadMeasurements();
		},
		componentDidUpdate(prevProps) {
			if (this.props.product !== prevProps.product) {
				if (this.props.folderID !== 'all') {
					this.props.setFolder('all');
				} else {
					this.props.refresh();
					// setTimeout is required to wait for the changes to take place before loadMeasurements reads it
					this.props.setParams({}, () => setTimeout(() => this.props.loadMeasurements({ folderID: 'all' })));
				}
			}

			if (this.props.folderID !== prevProps.folderID) {
				this.props.refresh();
				// setTimeout is required to wait for the changes to take place before loadMeasurements reads it
				this.props.setParams({}, () =>
					setTimeout(() => this.props.loadMeasurements({ folderID: this.props.folderID }))
				);
			}
		},
	})
)(Measurements);
