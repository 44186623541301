// @flow
import React, { Fragment } from 'react';
import { get } from 'lodash';
import { Table } from 'antd';
import { FormattedMessage } from 'react-intl';
import ConvertedUnits from 'components/shared/ConvertedUnits';
import { renderFolder } from 'utils/fileUtils';
import type { DerivedProps } from '../../../Measurement';
import styles from '../../../HTMLExport.styl';
import { formatWithIdentation } from '../../../../../../utils/handleFormatMessage';

export const title = (
	<Fragment>
		<FormattedMessage id="Proceq.ExportFieldSettings" />
		{' ­­» '}
		<FormattedMessage id="Measurement Presets" />
	</Fragment>
);

export const MeasurementPresets = ({
	data,
	scanType,
	isMetric,
	convert,
	showTitle,
}: { data: MeasurementFullData } & DerivedProps) => {
	const creationLog = data.logs.find(l => l.type === 'creation');
	const quality = get(data, 'settings.0.content.settings.preset.quality');
	const version = get(data, 'settings.0.content.settings.version');

	const isLfOnly = quality.toUpperCase() === 'MAX DEPTH' && version > 2;

	const isLineScan = get(data, 'measurement.type') === 'Line Scan';
	const isAreaScan = get(data, 'measurement.type') === 'Area Scan';
	const productModel = get(data, 'measurement.productModel');

	const presets = [
		{
			label: 'File Name',
			value: get(data, 'measurement.name'),
		},
		{
			label: 'Proceq.StatisticsFolder',
			value: <FormattedMessage id={renderFolder(data)} />,
		},
		{
			label: 'Mode',
			value: get(data, 'measurement.type'),
		},
		{
			label: 'App.HTML.GPR.MeasurementPresets.ScanDirection',
			value: get(data, 'settings.0.content.settings.preset.scanModeParameters.lineScanDirection'),
			enable: isLineScan,
		},
		{
			label: 'Grid Size X [{unit}]',
			unitId: 'GPR_SOIL.CSV.Grid Size - X',
			value: get(creationLog, 'content.measurementInfo.grid.gridSizeWidth'),
			enable: isAreaScan,
		},
		{
			label: 'Grid Size Y [{unit}]',
			unitId: 'GPR_SOIL.CSV.Grid Size - Y',
			value: get(creationLog, 'content.measurementInfo.grid.gridSizeHeight'),
			enable: isAreaScan,
		},
		{
			label: 'Grid Spacing X [{unit}]',
			unitId: 'GPR_SOIL.CSV.Grid Spacing',
			value:
				get(creationLog, 'content.measurementInfo.grid._gridSpacingX') ||
				get(creationLog, 'content.measurementInfo.grid.gridSpacingX') ||
				get(creationLog, 'content.measurementInfo.grid.gridSpacing'),
			enable: isAreaScan,
		},
		{
			label: 'Grid Spacing Y [{unit}]',
			unitId: 'GPR_SOIL.CSV.Grid Spacing',
			value:
				get(creationLog, 'content.measurementInfo.grid._gridSpacingY') ||
				get(creationLog, 'content.measurementInfo.grid.gridSpacingY') ||
				get(creationLog, 'content.measurementInfo.grid.gridSpacing'),
			enable: isAreaScan,
		},
		{
			label: 'Units',
			value: get(data, 'settings.0.content.settings.display.unit'),
		},
		{
			label: 'Resolution',
			value: quality,
			enable: productModel === 'gpr_soil',
		},
		{
			label: 'Repetition Rate [{unit}]',
			unitId: 'GPR.CSV.Repetition Rate',
			value: get(creationLog, 'content.measurementInfo.repetitionRate'),
		},
	]
		.filter(row => row.enable === undefined || row.enable === true)
		.map((row, key) => ({
			key,
			label: row.unitId ? (
				<ConvertedUnits id={row.label} unitId={row.unitId} scanType={scanType} isMetric={isMetric} />
			) : (
				<FormattedMessage id={row.label} />
			),
			value: row.multiple
				? row.unitId
					? formatWithIdentation(
							convert(row.value[0], row.unitId),
							isLfOnly ? '' : convert(row.value[1], row.unitId)
					  )
					: formatWithIdentation(row.value[0], row.value[1])
				: row.unitId
				? convert(row.value, row.unitId)
				: row.value,
		}));

	return (
		<Table
			title={showTitle ? () => <span className={styles.sub_header}>{title}</span> : null}
			className={[styles.table, 'table-measurement-presets'].join(' ')}
			dataSource={presets}
			pagination={false}
			showHeader={false}
			size="small"
		>
			<Table.Column dataIndex="label" className={styles.column} />
			<Table.Column dataIndex="value" />
		</Table>
	);
};

export default MeasurementPresets;
