import React from 'react';
import { shallowWithIntl } from 'utils/testHelper';
import StaticMap from './StaticMap';

let wrapper;

describe('StaticMap Component', () => {
	beforeEach(() => {
		wrapper = shallowWithIntl(<StaticMap coordinates={{ lat: 10, lng: 10 }} />);
	});

	afterEach(() => {
		wrapper.unmount();
	});

	it('load StaticMap', () => {
		expect(wrapper.exists()).toBe(true);
	});

	it('renders nothing if 0,0 passed in', () => {
		wrapper.setProps({
			coordinates: {
				lat: 0,
				lng: 0,
			},
		});
		expect(wrapper.isEmptyRender()).toBe(true);
	});
});
