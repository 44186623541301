// @flow
import React from 'react';
import { has, get } from 'lodash';
import { Card, Table } from 'antd';
import { FormattedMessage } from 'react-intl';
import Photo from 'components/measurements/LogEvent/Photo';
import ConvertedUnits from 'components/shared/ConvertedUnits';
import styles from '../../HTMLExport.styl';
import type { DerivedProps } from '../../Measurement';
import { formatWithIdentation } from '../../../../../utils/handleFormatMessage';

const title = (
	<span className={styles.sub_header}>
		<FormattedMessage id="Proceq.ExportFieldSettings" />
		{' ­­» '}
		<FormattedMessage id="App.DashboardSnapshots" />
	</span>
);

export const SavedSnapshots = ({
	data,
	logs,
	scanType,
	product,
	isMetric,
	convert,
}: { data: MeasurementFullData, logs: Log[] } & DerivedProps) => {
	const backgroundRemovalDepth = get(data, 'settings.0.content.settings.process.surfaceWaveRemoval');
	const maxScanDepth = get(data, 'settings.0.content.settings.preset.maxScanDepth');
	const separationSliderDepthPercentage = get(data, 'settings.0.content.settings.process.shallowSeparatorFactor');
	const separationSliderDepth = separationSliderDepthPercentage * maxScanDepth;

	return (
		<Card title={title} className="saved-snapshots">
			{logs.map(log => (
				<div key={log.id} className="snapshot">
					<Photo mID={log.mID} aID={log.content.attachmentId} attachmentName={log.content.attachmentName} />
					{log.content.metadata && (
						<Table
							title={null}
							dataSource={[
								{
									label: 'App.HTML.GPR.MeasurementPresets.FileName',
									value: log.content.attachmentName,
									enable: true,
								},
								{
									label: 'App.HTML.GPR.Objects.Line',
									value: log.content.metadata.lineId,
									enable: has(log, 'content.metadata.lineId'),
								},
								{
									label: 'App.LogbookSettingsDielectricConstant',
									unitId: 'GPR.Logbook Panel.Dielectric Constant',
									value: log.content.metadata.dielectricConstant,
									enable: product === 'GPR',
								},
								{
									label: 'App.HTML.GPR.Settings.ImageProcessing.LinearGain',
									unitId: `${product}.CSV.Linear Gain`,
									value: log.content.metadata.linearGain,
									enable: product === 'GPR',
								},
								{
									label: 'App.HTML.GPR.Settings.ImageProcessing.TimeGain',
									unitId: `${product}.CSV.Time Gain Compensation`,
									value: log.content.metadata.timeGainCompensation,
									enable: product === 'GPR',
								},
								{
									label: 'App.LogbookSettingsDielectricConstant',
									unitId: 'GPR.Logbook Panel.Dielectric Constant',
									value: [
										log.content.metadata.dielectricConstant,
										log.content.metadata.dielectricConstant,
									],
									enable: product === 'GPR_SOIL',
									multiple: true,
								},
								{
									label: 'App.HTML.GPR.Settings.ImageProcessing.LinearGain',
									unitId: `${product}.CSV.Linear Gain`,
									value: [log.content.metadata.linearGainLF, log.content.metadata.linearGain],
									enable: product === 'GPR_SOIL',
									multiple: true,
								},
								{
									label: 'App.HTML.GPR.Settings.ImageProcessing.TimeGain',
									unitId: `${product}.CSV.Time Gain Compensation`,
									value: [
										log.content.metadata.timeGainCompensationLF,
										log.content.metadata.timeGainCompensation,
									],
									enable: product === 'GPR_SOIL',
									multiple: true,
								},
								{
									label: 'App.HTML.GPR_SOIL.Setting.Background.Removal.Depth',
									unitId: `GPR_SOIL.Snapshot.Depth`,
									value: backgroundRemovalDepth,
									enable: product === 'GPR_SOIL',
								},
								{
									label: 'App.HTML.GPR_SOIL.Setting.Sepration.Slider.Depth',
									unitId: `GPR_SOIL.Snapshot.Depth`,
									value: separationSliderDepth,
									enable: product === 'GPR_SOIL',
								},
								{
									label: 'App.HTML.PUNDIT.ImageProcessing.DigitalGain',
									unitId: 'PUNDIT.Settings Panel.Digital Gain',
									value: log.content.metadata.digitalGain,
									enable: product === 'PUNDIT',
								},
								{
									label: 'App.HTML.PUNDIT.ImageProcessing.PulseVelocity',
									unitId: 'PUNDIT.Settings Panel.Pulse Velocity',
									value: log.content.metadata.pulseVelocity,
									enable: product === 'PUNDIT',
								},
								{
									label: 'App.HTML.PUNDIT.Snapshot.CenterFrequency',
									unitId: 'PUNDIT.Snapshot.Center Frequency',
									value: log.content.metadata.centerFrequency,
									enable: product === 'PUNDIT',
								},
							]
								.filter(({ enable }) => enable)
								.map(row => ({
									label: row.unitId ? (
										<ConvertedUnits
											id={row.label}
											unitId={row.unitId}
											scanType={scanType}
											isMetric={isMetric}
										/>
									) : (
										<FormattedMessage id={row.label} />
									),
									value: row.multiple
										? formatWithIdentation(
												convert(row.value[0], row.unitId),
												convert(row.value[1], row.unitId)
										  )
										: row.unitId
										? convert(row.value, row.unitId)
										: row.value,
									key: row.label,
								}))}
							pagination={false}
							showHeader={false}
							size="small"
						>
							<Table.Column dataIndex="label" className={styles.column} />
							<Table.Column dataIndex="value" />
						</Table>
					)}
				</div>
			))}
		</Card>
	);
};

export default SavedSnapshots;
