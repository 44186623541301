// @flow
export type Data = mixed;
export type Label = string;
export type Key = string;

export type DataLabel = {
	data: Data,
	label: Label,
};

export type KeyData = {
	key: Key,
	data: DataLabel[],
};

export const GLOBAL_REGISTERED_INFO: KeyData[] = [
	{
		key: 'reportids',
		data: [
			{
				data: 'users_report_detailed',
				label: "Users' Report Detailed",
			},
			{
				data: 'contracts_report',
				label: 'Contracts Report',
			},
			{
				data: 'contracts_report_detailed',
				label: 'Contracts Report Detailed',
			},
			{
				data: 'mapping_countries',
				label: 'Countries Mapping',
			},
			{
				data: 'weekly_bu',
				label: 'Weekly BUs',
			},
		],
	},
	{
		key: 'regionsservers',
		data: [
			{ label: '', data: 'EU' },
			{ label: 'srv2', data: 'SG' },
			{ label: 'srv3', data: 'US' },
			{ label: 'srv4', data: 'JP' },
		],
	},
	{
		key: 'regionsnames',
		data: [
			{ label: 'EU', data: 'EU' },
			{ label: 'Singapore', data: 'SG' },
			{ label: 'USA', data: 'US' },
			{ label: 'Japan', data: 'JP' },
			{ label: 'China', data: 'CN' },
			{ label: 'UK', data: 'UK' },
			{ label: 'UAE', data: 'AE' },
		],
	},
	{
		key: 'regionsnamesregionalanalytics',
		data: [
			{ label: 'All', data: 'All' },
			{ label: 'EU', data: 'EU' },
			{ label: 'Singapore', data: 'SG' },
			{ label: 'USA', data: 'US' },
			{ label: 'Japan', data: 'JP' },
			{ label: 'China', data: 'CN' },
			{ label: 'UK', data: 'UK' },
			{ label: 'UAE', data: 'AE' },
		],
	},
	{
		key: 'usertypeFilter',
		data: [
			{ label: 'Internal', data: '1' },
			{ label: 'End User', data: '0' },
			{ label: 'Reseller', data: '2' },
		],
	},
	{
		key: 'availlanguages',
		data: [
			{ label: 'Proceq.LanguageChinese', data: 'zh' },
			{ label: 'Proceq.LanguageEnglish', data: 'en' },
			{ label: 'Proceq.LanguageFrench', data: 'fr' },
			{ label: 'Proceq.LanguageGerman', data: 'de' },
			{ label: 'Proceq.LanguageItalian', data: 'it' },
			{ label: 'Proceq.LanguageJapanese', data: 'ja' },
			{ label: 'Proceq.LanguageKorean', data: 'ko' },
			{ label: 'Proceq.LanguagePortuguese', data: 'pt' },
			{ label: 'Proceq.LanguageRussian', data: 'ru' },
			{ label: 'Proceq.LanguageSpanish', data: 'es' },
			{ label: 'Proceq.LanguageTurkish', data: 'tr' },
		],
	},
	{
		key: 'loginproducts',
		data: [
			{ label: 'Equotip', data: 'EQUOTIP' },
			{ label: 'Schmidt', data: 'SCHMIDT' },
			{ label: 'GPR', data: 'GPR' },
			{ label: 'Pundit', data: 'PUNDIT' },
		],
	},
	{
		key: 'availproductsfull',
		data: [
			{ label: 'Equotip Live', data: 'EQUOTIP' },
			{ label: 'Flaw Detector Live', data: 'FDL' },
			{ label: 'Glossmeter Live', data: 'GLM' },
			{ label: 'PaperSchmidt', data: 'PaperSchmidt' },
			{ label: 'Proceq GPR Live', data: 'GPR' },
			{ label: 'Profometer Live', data: 'PROFOMETER' },
			{ label: 'Pundit Live Array', data: 'PUNDIT' },
			{ label: 'Pundit PI8000', data: 'PIT_IE' },
			{ label: 'Schmidt Live', data: 'SCHMIDT' },
			{ label: 'Subsurface Live', data: 'GPR_SOIL' },
		],
	},
	{
		key: 'availproductsappfull',
		data: [
			{ label: 'EQ', data: 'EQUOTIP' },
			{ label: 'GP', data: 'GPR' },
			{ label: 'GPSolo', data: 'GPR_SOLO' },
			{ label: 'GS', data: 'GPR_SOIL' },
			{ label: 'OS', data: 'SCHMIDT' },
			{ label: 'PD', data: 'PUNDIT' },
			{ label: 'PI', data: 'PIT_IE' },
			{ label: 'PM', data: 'PROFOMETER' },
			{ label: 'UT', data: 'FDL' },
			{ label: 'ZG', data: 'GLM' },
		],
	},
	{
		key: 'availappnamesfull',
		data: [
			{ label: 'All', data: 'All' },
			{ label: 'EQ', data: 'app_eq' },
			{ label: 'OS', data: 'app_os' },
			{ label: 'GS', data: 'app_gs' },
			{ label: 'GP', data: 'app_gp' },
			{ label: 'GPSolo', data: 'app_gp_solo' },
			{ label: 'GM', data: 'app_gm' },
			{ label: 'PS', data: 'app_ps' },
			{ label: 'PD', data: 'app_pd' },
			{ label: 'PI', data: 'app_pi' },
			{ label: 'PM', data: 'app_pm' },
			{ label: 'UT', data: 'app_ut' },
			{ label: 'ZG', data: 'app_zg' },
			{ label: 'Eagle Workspace', data: 'app_ew' },
		],
	},
	{
		key: 'productModels',
		data: [
			{ label: 'Equotip Live', data: 'LEEBD' },
			{ label: 'GM8000', data: 'GM8000' },
			{ label: 'GP8000', data: 'GPR' },
			{ label: 'GP8100', data: 'GPR_GP8100' },
			{ label: 'GP8800', data: 'GPR_PALM' },
			{ label: 'GS8000', data: 'GPR_SOIL' },
			{ label: 'GS9000', data: 'GS9000' },
			{ label: 'MA8000', data: 'MAP' },
			{ label: 'OS8000', data: 'SCHMIDT' },
			{ label: 'OS8200', data: 'SSL' },
			{ label: 'PS8000', data: 'PS8000' },
			{ label: 'RS8000', data: 'RS8000' },
			{ label: 'PD8000', data: 'PUNDIT' },
			{ label: 'PD8050', data: 'PUNDIT_PD8050' },
			{ label: 'PI8000', data: 'PI8000' },
			{ label: 'PM8000', data: 'PM8000' },
			{ label: 'PM8500', data: 'PM8500' },
			{ label: 'UCI Live', data: 'UCI' },
			{ label: 'UT8000', data: 'FDL' },
			{ label: 'ZG8000', data: 'GLM' },
		],
	},
	{
		key: 'availproductsappfullregional',
		data: [
			{ label: 'All', data: 'All' },
			{ label: 'EQ', data: 'EQUOTIP' },
			{ label: 'GP', data: 'GPR' },
			{ label: 'GPSolo', data: 'GPR_SOLO' },
			{ label: 'GS', data: 'GPR_SOIL' },
			{ label: 'OS', data: 'SCHMIDT' },
			{ label: 'PD', data: 'PUNDIT' },
			{ label: 'PI', data: 'PIT_IE' },
			{ label: 'PM', data: 'PROFOMETER' },
			{ label: 'UT', data: 'FDL' },
			{ label: 'ZG', data: 'GLM' },
		],
	},
	{
		key: 'productModelsRegional',
		data: [
			{ label: 'All', data: 'All' },
			{ label: 'Equotip Live', data: 'LEEBD' },
			{ label: 'GM8000', data: 'GM8000' },
			{ label: 'GP8000', data: 'GPR' },
			{ label: 'GP8100', data: 'GPR_GP8100' },
			{ label: 'GP8800', data: 'GPR_PALM' },
			{ label: 'GPR Insights', data: 'GPR_Insights' },
			{ label: 'GS8000', data: 'GPR_SOIL' },
			{ label: 'GS9000', data: 'GS9000' },
			{ label: 'MA8000', data: 'MAP' },
			{ label: 'OS8000', data: 'SCHMIDT' },
			{ label: 'OS8200', data: 'SSL' },
			{ label: 'PS8000', data: 'PS8000' },
			{ label: 'RS8000', data: 'RS8000' },
			{ label: 'PD8000', data: 'PUNDIT' },
			{ label: 'PD8050', data: 'PUNDIT_PD8050' },
			{ label: 'PI8000', data: 'PI8000' },
			{ label: 'PM8000', data: 'PM8000' },
			{ label: 'PM8500', data: 'PM8500' },
			{ label: 'UCI Live', data: 'UCI' },
			{ label: 'UT8000', data: 'FDL' },
			{ label: 'ZG8000', data: 'GLM' },
			{ label: 'Workspace', data: 'WORKSPACE' },
			{ label: 'PqUpgrade', data: 'PQUPGRADE' },
			{ label: 'FDLiveService', data: 'FDLiveService' },
			{ label: 'LiveService', data: 'LiveService' },
		],
	},
	{
		key: 'latestavailproductsfull',
		data: [
			{ label: 'Equotip Live Leeb D', data: 'LEEBD' },
			{ label: 'Equotip Live UCI', data: 'UCI' },
			{ label: 'Proceq GM8000', data: 'GM8000' },
			{ label: 'Proceq CS8000', data: 'PROCEQ_CS8000' },
			{ label: 'Proceq GP8000', data: 'GPR' },
			{ label: 'Proceq GP8100', data: 'GPR_GP8100' },
			{ label: 'Proceq GP8800', data: 'GPR_PALM' },
			{ label: 'Proceq UT8000', data: 'FDL' },
			{ label: 'Profometer PM8000', data: 'PM8000' },
			{ label: 'Profometer PM8500', data: 'PM8500' },
			{ label: 'Pundit PD8000', data: 'PUNDIT' },
			{ label: 'Pundit PD8050', data: 'PUNDIT_PD8050' },
			{ label: 'Pundit PI8000', data: 'PI8000' },
			{ label: 'Schmidt OS8000', data: 'SCHMIDT' },
			{ label: 'Schmidt OS8200', data: 'SSL' },
			{ label: 'Schmidt PS8000', data: 'PS8000' },
			{ label: 'Schmidt RS8000', data: 'RS8000' },
			{ label: 'Zehntner ZG8000', data: 'GLM' },
		],
	},
	{
		key: 'contracttypes',
		data: [
			{ label: 'Device', data: '0' },
			{ label: 'Maintenance Services', data: '1' },
			{ label: 'Software', data: '2' },
		],
	},
	{
		key: 'availproductslivedev',
		data: [
			{ label: 'Equotip Live', data: 'EQUOTIP' },
			{ label: 'Flaw Detector Live', data: 'FDL' },
			{ label: 'Glossmeter Live', data: 'GLM' },
			{ label: 'Proceq GPR Live', data: 'GPR' },
			{ label: 'Profometer Live', data: 'PROFOMETER' },
			{ label: 'Pundit Live Array', data: 'PUNDIT' },
			{ label: 'Pundit PI8000', data: 'PIT_IE' },
			{ label: 'Schmidt Live', data: 'SCHMIDT' },
			{ label: 'Subsurface Live', data: 'GPR_SOIL' },
		],
	},
	{
		key: 'availproductslive',
		data: [
			{ label: 'Equotip Live', data: 'EQUOTIP' },
			{ label: 'Flaw Detector Live', data: 'FDL' },
			{ label: 'Glossmeter Live', data: 'GLM' },
			{ label: 'Proceq GPR Live', data: 'GPR' },
			{ label: 'Profometer Live', data: 'PROFOMETER' },
			{ label: 'Pundit Live Array', data: 'PUNDIT' },
			{ label: 'Pundit PI8000', data: 'PIT_IE' },
			{ label: 'Schmidt Live', data: 'SCHMIDT' },
			{ label: 'Subsurface Live', data: 'GPR_SOIL' },
		],
	},
	{
		key: 'productversions',
		data: [
			{ label: 'Equotip Live Leeb D', data: 'EQUOTIP' },
			{ label: 'Flaw Detector Live', data: 'FDL' },
			{ label: 'Glossmeter Live', data: 'GLM' },
			{ label: 'Original Schmidt Live', data: 'SCHMIDT' },
			{ label: 'PaperSchmidt', data: 'PAPERSCHMIDT' },
			{ label: 'Proceq GPR Live', data: 'GPR' },
			{ label: 'Profometer Live', data: 'PROFOMETER' },
			{ label: 'Pundit Impact', data: 'PIT_IE' },
			{ label: 'Pundit Live Array', data: 'PUNDIT' },
			{ label: 'Subsurface Live', data: 'GPR_SOIL' },
			{ label: 'Proceq GM8000', data: 'GPR_MOUNTED' },
			{ label: 'GPR Solo', data: 'GPR_SOLO' },
		],
	},
	{
		key: 'wifiproductsfull',
		data: [
			{ label: 'Proceq GM8000', data: 'GM8000' },
			{ label: 'Proceq GP8000', data: 'GPR' },
			{ label: 'Proceq GP8100', data: 'GPR_GP8100' },
			{ label: 'Proceq GP8800', data: 'GPR_PALM' },
			{ label: 'Proceq GS8000', data: 'GPR_SOIL' },
			{ label: 'Proceq GS9000', data: 'GS9000' },
			{ label: 'Proceq UT8000', data: 'FDL' },
			{ label: 'Pundit PD8000', data: 'PUNDIT' },
			{ label: 'Pundit PD8050', data: 'PUNDIT_PD8050' },
		],
	},
	{
		key: 'latencylogsproducts',
		data: [
			{ label: 'Proceq GP8000', data: 'GPR' },
			{ label: 'Proceq GS8000', data: 'GPR_SOIL' },
			{ label: 'Proceq UT8000', data: 'FDL' },
			{ label: 'Pundit PD8000', data: 'PUNDIT' },
		],
	},
	{
		key: 'productmodel',
		data: [
			{ label: 'Proceq GM8000', data: 'GM8000' },
			{ label: 'Proceq GP8000', data: 'GPR' },
			{ label: 'Proceq GP8100', data: 'GPR_GP8100' },
			{ label: 'Proceq GP8800', data: 'GPR_PALM' },
			{ label: 'Proceq GS8000', data: 'GPR_SOIL' },
			{ label: 'Proceq UT8000', data: 'FDL' },
			{ label: 'Pundit PI8000', data: 'PI8000' },
		],
	},
	{
		key: 'bluetoothproductsfull',
		data: [
			{ label: 'Equotip Live Leeb D', data: 'LEEBD' },
			{ label: 'Equotip Live UCI', data: 'UCI' },
			{ label: 'Profometer PM8000', data: 'PM8000' },
			{ label: 'Profometer PM8500', data: 'PM8500' },
			{ label: 'Pundit PI8000', data: 'PI8000' },
			{ label: 'Schmidt OS8000', data: 'SCHMIDT' },
			{ label: 'Schmidt OS8200', data: 'SSL' },
			{ label: 'Schmidt PS8000', data: 'PS8000' },
			{ label: 'Schmidt RS8000', data: 'RS8000' },
			{ label: 'Zehntner ZG8000', data: 'GLM' },
		],
	},
	{
		key: 'bluetoothproductslive',
		data: [
			{ label: 'Equotip Live Leeb D', data: 'LEEBD' },
			{ label: 'Equotip Live UCI', data: 'UCI' },
			{ label: 'Profometer PM8000', data: 'PM8000' },
			{ label: 'Profometer PM8500', data: 'PM8500' },
			{ label: 'Pundit PI8000', data: 'PI8000' },
			{ label: 'Schmidt OS8000', data: 'SCHMIDT' },
			{ label: 'Schmidt OS8200', data: 'SSL' },
			{ label: 'Schmidt PS8000', data: 'PS8000' },
			{ label: 'Schmidt RS8000', data: 'RS8000' },
			{ label: 'Zehntner ZG8000', data: 'GLM' },
		],
	},
	{
		key: 'saasProducts',
		data: [
			{ label: 'GPR Insights', data: 'GPR_INSIGHTS' },
			{ label: 'Inspect', data: 'INSPECT' },
		],
	},
	{
		key: 'freeTrialSaasProducts',
		data: [
			{ label: 'GPR Insights', data: 'GPR_INSIGHTS' },
			{ label: 'Inspect', data: 'INSPECT' },
		],
	},
	{
		key: 'softwareproducts',
		data: [
			{ label: 'GPRSIM', data: 'GPRSIM' },
			{ label: 'GPR-SLICE', data: 'GPR_SLICE' },
			{ label: 'Pundit Vision', data: 'PUNDIT_VISION' },
		],
	},
	{
		key: 'availproductsproceq',
		data: [
			{ label: 'Equotip Live Leeb D', data: 'EQUOTIP' },
			{ label: 'Original Schmidt Live', data: 'SCHMIDT' },
		],
	},
	{
		key: 'wifiproductslive',
		data: [
			{ label: 'Proceq GM8000', data: 'GM8000' },
			{ label: 'Proceq GP8000', data: 'GPR' },
			{ label: 'Proceq GP8100', data: 'GPR_GP8100' },
			{ label: 'Proceq GP8800', data: 'GPR_PALM' },
			{ label: 'Proceq GS8000', data: 'GPR_SOIL' },
			{ label: 'Proceq GS9000', data: 'GS9000' },
			{ label: 'Proceq UT8000', data: 'FDL' },
			{ label: 'Pundit PD8000', data: 'PUNDIT' },
			{ label: 'Pundit PD8050', data: 'PUNDIT_PD8050' },
		],
	},
	{
		key: 'registerproducts',
		data: [
			{ label: 'Equotip Live Leeb D', data: 'leebd' },
			{ label: 'Equotip Live Leeb D (Rental)', data: 'leebd_rnt' },
			{ label: 'Equotip Live UCI', data: 'uci' },
			{ label: 'Original Schmidt Live', data: 'schmidt' },
		],
	},
	{
		key: 'tiersleebd',
		data: [
			{ label: 'Non Rental', data: 'leebd' },
			{ label: 'Rental', data: 'leebd_rnt' },
		],
	},
	{
		key: 'tiersuci',
		data: [{ label: 'Non Rental', data: 'uci' }],
	},
	{
		key: 'tiersschmidt',
		data: [{ label: 'Non Rental', data: 'schmidt' }],
	},
	{
		key: 'proceqregisterproducts',
		data: [
			{ label: 'Equotip Live Leeb D', data: 'EQUOTIP' },
			{ label: 'Equotip Live Leeb D (Rental)', data: 'EQUOTIPRNT' },
			{ label: 'Equotip Live UCI', data: 'UCI' },
			{ label: 'Original Schmidt Live', data: 'SCHMIDT' },
		],
	},
	{
		key: 'tiersgpr',
		data: [
			{ label: 'Basic', data: 'basic' },
			{ label: 'Pro', data: 'pro' },
			{ label: 'Pro Rental', data: 'pro_rental' },
			{ label: 'Pro Unlimited Rental', data: 'pro_unlimited' },
			{ label: 'Pro Subscription', data: 'pro_subscription' },
			{ label: 'Pro Subscription Care', data: 'pro_subscription_care' },
		],
	},
	{
		key: 'tierspundit',
		data: [
			{ label: 'Pro', data: 'pro_pundit' },
			{ label: 'Pro Rental', data: 'pro_rental_pundit' },
			{ label: 'Pro Subscription', data: 'pro_subscription' },
			{ label: 'Pro Subscription Care', data: 'pro_subscription_care' },
		],
	},
	{
		key: 'tiersfdl',
		data: [
			// { label: 'Basic', data: 'basic' },
			{ label: 'Pro Subscription', data: 'pro_subscription' },
			// { label: 'Pro Rental', data: 'pro_rental' },
			// {
			// 	label: 'Pro Unlimited Rental',
			// 	data: 'pro_unlimited',
			// },
		],
	},
	{
		key: 'tiersgpr_palm',
		data: [
			{ label: 'Pro Subscription', data: 'pro_subscription' },
			{ label: 'Pro Subscription Care', data: 'pro_subscription_care' },
		],
	},
	{
		key: 'tiersglm',
		data: [{ label: 'Pro', data: 'pro' }],
	},
	{
		key: 'tiersssl',
		data: [{ label: 'Non Rental', data: 'ssl' }],
	},
	{
		key: 'gpr_scan_type',
		data: [
			{ label: 'Line Scan', data: 'Line Scan' },
			{ label: 'Area Scan', data: 'Area Scan' },
		],
	},
	{
		key: 'pundit_scan_type',
		data: [
			{ label: 'Line Scan', data: 'Line Scan' },
			{ label: 'Stripe Scan', data: 'Stripe Scan' },
		],
	},
	{
		key: 'contractoptionsschmidt',
		data: [
			{ label: 'Live', data: 'LIVE' },
			{ label: 'Print', data: 'PRINT' },
		],
	},
	{
		key: 'rolestds',
		data: [
			{ label: 'App.RoleSuper', data: 'super' },
			{ label: 'App.RoleAdmin', data: 'admin' },
			{ label: 'App.RolePO', data: 'po' },
			{ label: 'App.RoleBU', data: 'bu' },
			{ label: 'App.RoleDev', data: 'dev' },
			{ label: 'App.RoleSalesAndFinance', data: 'sales_and_finance' },
			{ label: 'App.RoleAnalyst', data: 'analyst' },
			{ label: 'App.RoleUser', data: 'user' },
		],
	},
	{
		key: 'rolestdsshort',
		data: [
			{ label: 'BU', data: 'bu' },
			{ label: 'PO', data: 'po' },
			{ label: 'User', data: 'user' },
			{ label: 'Dev', data: 'dev' },
			{ label: 'Super', data: 'super' },
			{ label: 'Admin', data: 'admin' },
			{ label: 'Contract Viewer', data: 'sales_and_finance' },
			{ label: 'Analyst', data: 'analyst' },
		],
	},
	{
		key: 'roles',
		data: [
			{ label: 'Admin', data: 'admin_proceq' },
			{ label: 'BU', data: 'snassigner_proceq' },
			{ label: 'Dev', data: 'developer_proceq' },
			{ label: 'Debug', data: 'debug_client' },
			{ label: 'Master', data: 'master_proceq' },
		],
	},
	{
		key: 'languagescontacttds',
		data: [
			{ label: 'Proceq.LanguageEnglish', data: 'en' },
			{ label: 'Proceq.LanguageGerman', data: 'de' },
			{ label: 'Proceq.LanguageChinese', data: 'zh' },
			{ label: 'Proceq.LanguageJapanese', data: 'ja' },
			{ label: 'Proceq.LanguageKorean', data: 'ko' },
		],
	},
	{
		key: 'languagescontact',
		data: [
			{ label: 'Proceq.LanguageEnglish', data: 'en' },
			{ label: 'Proceq.LanguageGerman', data: 'de' },
			{ label: 'Proceq.LanguageChinese', data: 'cn' },
			{ label: 'Proceq.LanguageJapanese', data: 'ja' },
		],
	},
	{
		key: 'languagescombined',
		data: [
			{ label: 'Proceq.LanguageEnglish', data: 'en' },
			{ label: 'Proceq.LanguageGerman', data: 'de' },
			{ label: 'Proceq.LanguageChinese', data: 'cn' },
			{ label: 'Proceq.LanguageJapanese', data: 'jp' },
			{ label: 'Proceq.LanguageKorean', data: 'ko' },
		],
	},
	{
		key: 'buunits',
		data: [
			{ label: 'Proceq HQ', data: 'PQHQ' },
			{ label: 'Proceq Asia', data: 'PQASIA' },
			{ label: 'Proceq China', data: 'PQCHINA' },
			{ label: 'Proceq Europe', data: 'PQEUROPE' },
			{ label: 'Proceq Middle East', data: 'PQMIDDLEEAST' },
			{ label: 'Proceq Russia', data: 'PQRUSSIA' },
			{ label: 'Proceq South America', data: 'PQSOUTHAMERICA' },
			{ label: 'Proceq UK', data: 'PQUK' },
			{ label: 'Proceq USA', data: 'PQUSA' },
		],
	},
	{
		key: 'industrytype',
		data: [
			{ label: 'Proceq.CategoryAerospace', data: 'AERO' },
			{ label: 'Proceq.CategoryAutomotive', data: 'AUTO' },
			{ label: 'Proceq.CategoryChemicalProcessing', data: 'CHEM' },
			{ label: 'Proceq.CategoryConstructionBuilding', data: 'CONST' },
			{ label: 'Proceq.CategoryConsultantTestLabsEngineer', data: 'ENG' },
			{ label: 'Proceq.CategoryEducationInstituteRD', data: 'EDU' },
			{ label: 'Proceq.CategoryFilmProducerConverter', data: 'FILM' },
			{ label: 'Proceq.CategoryGeologyRocktesters', data: 'GEO' },
			{ label: 'Proceq.CategoryMachineryToolingEquipment', data: 'TOOL' },
			{ label: 'Proceq.CategoryMassTransportation', data: 'TRANSP' },
			{ label: 'Proceq.CategoryMetalWorks', data: 'METAL' },
			{ label: 'Proceq.CategoryOilAndGas', data: 'OIL' },
			{ label: 'Proceq.CategoryPaperProducerConverter', data: 'PAPER' },
			{ label: 'Proceq.CategorySalesChannel', data: 'SALES' },
			{ label: 'Proceq.CategoryOthers', data: 'OTHERS' },
		],
	},
	{
		key: 'salutation',
		data: [
			{ label: 'Proceq.SalutationMr', data: 'MR' },
			{ label: 'Proceq.SalutationMs', data: 'MS' },
			{ label: 'Proceq.SalutationDr', data: 'DR' },
			{ label: 'Proceq.SalutationProf', data: 'PROF' },
		],
	},
	{
		key: 'companysize',
		data: [
			{ label: 'Proceq.CompanySizeMicro', data: 'MICRO' },
			{ label: 'Proceq.CompanySizeSmall', data: 'SMALL' },
			{ label: 'Proceq.CompanySizeMedium', data: 'MEDIUM' },
			{ label: 'Proceq.CompanySizeLarge', data: 'LARGE' },
		],
	},
	{
		key: 'verificationstatuses',
		data: [
			{ label: 'Proceq.ExportVerificationStatusFailed', data: 'failed' },
			{ label: 'Proceq.ExportVerificationStatusPassed', data: 'passed' },
		],
	},
	{
		key: 'probetype',
		data: [
			{ label: 'Proceq.DeviceEquotipD', data: '0' },
			{ label: 'Proceq.DeviceEquotipUCI', data: '1' },
			{ label: 'Proceq.DeviceSchmidtL', data: '2' },
			{ label: 'Proceq.DeviceSchmidtN', data: '3' },
		],
	},
	{
		key: 'standards',
		data: [
			{ label: 'Proceq.StandardEnumProceq', data: '1' },
			{ label: 'Proceq.StandardEnumISO18265', data: '2' },
			{ label: 'Proceq.StandardEnumASTME140', data: '4' },
			{ label: 'Proceq.StandardEnumJapan', data: '8' },
			{ label: 'Proceq.StandardEnumDefault', data: '15' },
			{ label: 'Proceq.eVerNStd_ISO_16859', data: '50' },
			{ label: 'Proceq.eVerNStd_ASTM_A956', data: '51' },
			{ label: 'Proceq.eVerNStd_ASTM_A1038', data: '52' },
			{ label: 'Proceq.eVerNStd_DIM_50195', data: '53' },
			{ label: 'Proceq.StandardEnumCustom', data: '999' },
		],
	},
	{
		key: 'standardsschmidt',
		data: [
			{ label: 'Proceq.eRebNStd_EN_12540_2', data: '0' },
			{ label: 'Proceq.eRebNStd_ASTM_C805', data: '1' },
			{ label: 'Proceq.eRebNStd_JGJ_T23', data: '2' },
			{ label: 'Proceq.eRebNStd_JSCE_G504', data: '3' },
			{ label: 'Proceq.eRebNStd_Mean', data: '4' },
			{ label: 'Proceq.eRebNStd_JIS', data: '8' },
			{ label: 'Proceq.eRebNStd_NUM_std', data: '9' },
			{ label: 'Proceq.eVerNStd_Proceq', data: '50' },
		],
	},
	{
		key: 'unitsschmidt',
		data: [
			{ label: 'R', data: '0' },
			{ label: 'N/mm²', data: '1' },
			{ label: 'MPa', data: '2' },
			{ label: 'psi', data: '3' },
			{ label: 'kg/cm²', data: '4' },
			{ label: 'Q', data: '6' },
		],
	},
	{
		key: 'formfactorschmidt',
		data: [
			{ label: '150 mm cube', data: '0' },
			{ label: '2:1 cylinder', data: '1' },
			{ label: '1:1 core', data: '2' },
		],
	},
	{
		key: 'schmidtcurvetypes',
		data: [
			{ label: 'Proceq.SchmidtCurveTypePolynomial', data: '0' },
			{ label: 'Proceq.SchmidtCurveTypeExponencial', data: '1' },
		],
	},
	{
		key: 'schmidtcurvetypesshort',
		data: [
			{ label: 'Proceq.SchmidtCurveTypePolynomialShort', data: '0' },
			{ label: 'Proceq.SchmidtCurveTypeExponencialShort', data: '1' },
		],
	},
	{
		key: 'units',
		data: [
			{ label: 'HLD', data: '2' },
			{ label: 'HLS', data: '3' },
			{ label: 'HLE', data: '4' },
			{ label: 'HLDL', data: '5' },
			{ label: 'HLC', data: '6' },
			{ label: 'HLG', data: '7' },
			{ label: 'HLSVP', data: '8' },
			{ label: 'HL', data: '9' },
			{ label: 'HB', data: '10' },
			{ label: 'HV', data: '11' },
			{ label: 'HRA', data: '12' },
			{ label: 'HRB', data: '13' },
			{ label: 'HRBN', data: '14' },
			{ label: 'HRC', data: '15' },
			{ label: 'HRE', data: '16' },
			{ label: 'HRH', data: '17' },
			{ label: 'HR15N', data: '18' },
			{ label: 'HR15T', data: '19' },
			{ label: 'HMMRC', data: '20' },
			{ label: 'HS', data: '21' },
			{ label: 'MPa', data: '22' },
			{ label: 'Custom', data: '23' },
			{ label: 'uM', data: '24' },
			{ label: 'HV(UCI)', data: '25' },
			{ label: 'HLU', data: '26' },
		],
	},
	{
		key: 'unitsGLM',
		data: [
			{ label: 'GU', data: '0' },
			{ label: '%', data: '1' },
		],
	},
	{
		key: 'modesGLM',
		data: [
			{ label: 'Single', data: '0' },
			{ label: 'Scan', data: '2' },
			{ label: 'Continous', data: '3' },
		],
	},
	{
		key: 'convcurveschmidt',
		data: [
			{ label: 'Proceq.eCC_None', data: '0' },
			{ label: 'Proceq.eCC_PROCEQ_B_N', data: '1' },
			{ label: 'Proceq.eCC_PROCEQ_A_N', data: '2' },
			{ label: 'Proceq.eCC_Portland_Cement_J_N', data: '3' },
			{ label: 'Proceq.eCC_Early_Strength_J_N', data: '4' },
			{ label: 'Proceq.eCC_Blast_Furnace_J_N', data: '5' },
			{ label: 'Proceq.eCC_Average_Curve_J_N', data: '6' },
			{ label: 'Proceq.eCC_M_JAP_N', data: '7' },
			{ label: 'Proceq.eCC_A_JAP_N', data: '8' },
			{ label: 'Proceq.eCC_T_JAP_N', data: '9' },
			{ label: 'Proceq.eCC_P_JAP_N', data: '10' },
			{ label: 'Proceq.eCC_PROCEQ_B_L', data: '11' },
			{ label: 'Proceq.eCC_PROCEQ_A_L', data: '12' },
			{ label: 'Proceq.eCC_SS_Low10_N', data: '13' },
			{ label: 'Proceq.eCC_SS_EU_N', data: '14' },
			{ label: 'Proceq.eCC_SS_CN_N', data: '15' },
			{ label: 'Proceq.eCC_SS_RUS_N', data: '16' },
			{ label: 'Proceq.eCC_SS_XX_L', data: '17' },
		],
	},
	{
		key: 'surfaceconditionschmidt',
		data: [
			{ label: 'Proceq.eSC_R1_dry', data: '0' },
			{ label: 'Proceq.eSC_R1_black_mark', data: '3' },
			{ label: 'Proceq.eSC_R1_wet_surface', data: '5' },
		],
	},
	{
		key: 'surfacecorrschmidt',
		data: [
			{ label: 'Proceq.eCSC_None', data: '0' },
			{ label: 'Proceq.eCSC_Upper', data: '1' },
			{ label: 'Proceq.eCSC_Under', data: '2' },
		],
	},
	{
		key: 'surfacestrengthschmidt',
		data: [
			{ label: 'Proceq.eSCC_US', data: '0' },
			{ label: 'Proceq.eSCC_PC', data: '1' },
		],
	},

	{
		key: 'surfacestrengthandcorrschmidt',
		data: [
			{ label: 'Proceq.eSCC_US_eCSC_None', data: '0_0' },
			{ label: 'Proceq.eSCC_US_eCSC_Upper', data: '0_1' },
			{ label: 'Proceq.eSCC_US_eCSC_Under', data: '0_2' },
			{ label: 'Proceq.eSCC_PC_eCSC_None', data: '1_0' },
			{ label: 'Proceq.eSCC_PC_eCSC_Upper', data: '1_1' },
			{ label: 'Proceq.eSCC_PC_eCSC_Under', data: '1_2' },
		],
	},

	{
		key: 'outliertypeschmidt',
		data: [
			{ label: 'Proceq.eNoOutlier', data: '0' },
			{ label: 'Proceq.eOutlierLow', data: '1' },
			{ label: 'Proceq.eOutlierHigh', data: '2' },
			{ label: 'Proceq.eOutlierRedundant', data: '3' },
		],
	},

	{
		key: 'materials',
		data: [
			{ label: 'Proceq.MaterialEnumIndependent', data: '1' },
			{ label: 'Proceq.MaterialEnumSteelAndCastSteel', data: '2' },
			{ label: 'Proceq.MaterialEnumWorkToolSteel', data: '3' },
			{ label: 'Proceq.MaterialEnumStainlessSteel', data: '4' },
			{ label: 'Proceq.MaterialEnumGrayCastIron', data: '5' },
			{ label: 'Proceq.MaterialEnumNodularCastIron', data: '6' },
			{ label: 'Proceq.MaterialEnumCastAlluminiumAlloys', data: '7' },
			{ label: 'Proceq.MaterialEnumBrassCopperZincAlloys', data: '8' },
			{ label: 'Proceq.MaterialEnumBronze', data: '9' },
			{ label: 'Proceq.MaterialEnumWroughtCopperAlloys', data: '10' },
			{ label: 'Proceq.MaterialEnumTitanium', data: '11' },
			{ label: 'Proceq.MaterialEnumCustom', data: '999' },
		],
	},
	{
		key: 'scales',
		data: [
			{ label: 'Proceq.ScaleEnumLeebD', data: '2' },
			{ label: 'Proceq.ScaleEnumLeebS', data: '3' },
			{ label: 'Proceq.ScaleEnumLeebE', data: '4' },
			{ label: 'Proceq.ScaleEnumLeebDL', data: '5' },
			{ label: 'Proceq.ScaleEnumLeebC', data: '6' },
			{ label: 'Proceq.ScaleEnumLeebG', data: '7' },
			{ label: 'Proceq.ScaleEnumLeebSVP', data: '8' },
			{ label: 'Proceq.ScaleEnumLeeb', data: '9' },
			{ label: 'Proceq.ScaleEnumBrinell', data: '10' },
			{ label: 'Proceq.ScaleEnumVickers', data: '11' },
			{ label: 'Proceq.ScaleEnumRockwellA', data: '12' },
			{ label: 'Proceq.ScaleEnumRockwellB', data: '13' },
			{ label: 'Proceq.ScaleEnumRockwellBN', data: '14' },
			{ label: 'Proceq.ScaleEnumRockwellC', data: '15' },
			{ label: 'Proceq.ScaleEnumRockwellE', data: '16' },
			{ label: 'Proceq.ScaleEnumRockwellH', data: '17' },
			{ label: 'Proceq.ScaleEnumRockwell15N', data: '18' },
			{ label: 'Proceq.ScaleEnumRockwell15T', data: '19' },
			{ label: 'Proceq.ScaleEnumRockwell', data: '20' },
			{ label: 'Proceq.ScaleEnumShore', data: '21' },
			{ label: 'Proceq.ScaleEnumMPa', data: '22' },
			{ label: 'Proceq.ScaleEnumCustom', data: '23' },
			{ label: 'Proceq.ScaleEnumPenetrationDepth', data: '24' },
			{ label: 'Proceq.ScaleEnumVickersUCI', data: '25' },
			{ label: 'Proceq.ScaleEnumLeebU', data: '26' },
		],
	},
	{
		key: 'countries',
		data: [
			{ label: 'Afghanistan', data: 'AF' },
			{ label: 'Åland Islands', data: 'AX' },
			{ label: 'Albania', data: 'AL' },
			{ label: 'Algeria', data: 'DZ' },
			{ label: 'American Samoa', data: 'AS' },
			{ label: 'Andorra', data: 'AD' },
			{ label: 'Angola', data: 'AO' },
			{ label: 'Anguilla', data: 'AI' },
			{ label: 'Antarctica', data: 'AQ' },
			{ label: 'Antigua and Barbuda', data: 'AG' },
			{ label: 'Argentina', data: 'AR' },
			{ label: 'Armenia', data: 'AM' },
			{ label: 'Aruba', data: 'AW' },
			{ label: 'Australia', data: 'AU' },
			{ label: 'Austria', data: 'AT' },
			{ label: 'Azerbaijan', data: 'AZ' },
			{ label: 'Bahamas', data: 'BS' },
			{ label: 'Bahrain', data: 'BH' },
			{ label: 'Bangladesh', data: 'BD' },
			{ label: 'Barbados', data: 'BB' },
			{ label: 'Belarus', data: 'BY' },
			{ label: 'Belgium', data: 'BE' },
			{ label: 'Belize', data: 'BZ' },
			{ label: 'Benin', data: 'BJ' },
			{ label: 'Bermuda', data: 'BM' },
			{ label: 'Bhutan', data: 'BT' },
			{ label: 'Bolivia', data: 'BO' },
			{ label: 'Bosnia and Herzegovina', data: 'BA' },
			{ label: 'Botswana', data: 'BW' },
			{ label: 'Bouvet Island', data: 'BV' },
			{ label: 'Brazil', data: 'BR' },
			{ label: 'British Indian Ocean Territory', data: 'IO' },
			{ label: 'Brunei Darussalam', data: 'BN' },
			{ label: 'Bulgaria', data: 'BG' },
			{ label: 'Burkina Faso', data: 'BF' },
			{ label: 'Burundi', data: 'BI' },
			{ label: 'Cambodia', data: 'KH' },
			{ label: 'Cameroon', data: 'CM' },
			{ label: 'Canada', data: 'CA' },
			{ label: 'Cape Verde', data: 'CV' },
			{ label: 'Cayman Islands', data: 'KY' },
			{ label: 'Central African Republic', data: 'CF' },
			{ label: 'Chad', data: 'TD' },
			{ label: 'Chile', data: 'CL' },
			{ label: 'China', data: 'CN' },
			{ label: 'Christmas Island', data: 'CX' },
			{ label: 'Cocos (Keeling) Islands', data: 'CC' },
			{ label: 'Colombia', data: 'CO' },
			{ label: 'Comoros', data: 'KM' },
			{ label: 'Congo', data: 'CG' },
			{ label: 'Congo, The Democratic Republic of the', data: 'CD' },
			{ label: 'Cook Islands', data: 'CK' },
			{ label: 'Costa Rica', data: 'CR' },
			{ label: "Cote D'Ivoire", data: 'CI' },
			{ label: 'Croatia', data: 'HR' },
			{ label: 'Cuba', data: 'CU' },
			{ label: 'Cyprus', data: 'CY' },
			{ label: 'Czech Republic', data: 'CZ' },
			{ label: 'Denmark', data: 'DK' },
			{ label: 'Djibouti', data: 'DJ' },
			{ label: 'Dominica', data: 'DM' },
			{ label: 'Dominican Republic', data: 'DO' },
			{ label: 'Ecuador', data: 'EC' },
			{ label: 'Egypt', data: 'EG' },
			{ label: 'El Salvador', data: 'SV' },
			{ label: 'Equatorial Guinea', data: 'GQ' },
			{ label: 'Eritrea', data: 'ER' },
			{ label: 'Estonia', data: 'EE' },
			{ label: 'Ethiopia', data: 'ET' },
			{ label: 'Falkland Islands (Malvinas)', data: 'FK' },
			{ label: 'Faroe Islands', data: 'FO' },
			{ label: 'Fiji', data: 'FJ' },
			{ label: 'Finland', data: 'FI' },
			{ label: 'France', data: 'FR' },
			{ label: 'French Guiana', data: 'GF' },
			{ label: 'French Polynesia', data: 'PF' },
			{ label: 'French Southern Territories', data: 'TF' },
			{ label: 'Gabon', data: 'GA' },
			{ label: 'Gambia', data: 'GM' },
			{ label: 'Georgia', data: 'GE' },
			{ label: 'Germany', data: 'DE' },
			{ label: 'Ghana', data: 'GH' },
			{ label: 'Gibraltar', data: 'GI' },
			{ label: 'Greece', data: 'GR' },
			{ label: 'Greenland', data: 'GL' },
			{ label: 'Grenada', data: 'GD' },
			{ label: 'Guadeloupe', data: 'GP' },
			{ label: 'Guam', data: 'GU' },
			{ label: 'Guatemala', data: 'GT' },
			{ label: 'Guernsey', data: 'GG' },
			{ label: 'Guinea', data: 'GN' },
			{ label: 'Guinea-Bissau', data: 'GW' },
			{ label: 'Guyana', data: 'GY' },
			{ label: 'Haiti', data: 'HT' },
			{ label: 'Heard Island and Mcdonald Islands', data: 'HM' },
			{ label: 'Holy See (Vatican City State)', data: 'VA' },
			{ label: 'Honduras', data: 'HN' },
			{ label: 'Hong Kong', data: 'HK' },
			{ label: 'Hungary', data: 'HU' },
			{ label: 'Iceland', data: 'IS' },
			{ label: 'India', data: 'IN' },
			{ label: 'Indonesia', data: 'ID' },
			{ label: 'Iran, Islamic Republic Of', data: 'IR' },
			{ label: 'Iraq', data: 'IQ' },
			{ label: 'Ireland', data: 'IE' },
			{ label: 'Isle of Man', data: 'IM' },
			{ label: 'Israel', data: 'IL' },
			{ label: 'Italy', data: 'IT' },
			{ label: 'Jamaica', data: 'JM' },
			{ label: 'Japan', data: 'JP' },
			{ label: 'Jersey', data: 'JE' },
			{ label: 'Jordan', data: 'JO' },
			{ label: 'Kazakhstan', data: 'KZ' },
			{ label: 'Kenya', data: 'KE' },
			{ label: 'Kiribati', data: 'KI' },
			{ label: "Democratic People's Republic of Korea", data: 'KP' },
			{ label: 'Korea, Republic of', data: 'KR' },
			{ label: 'Kosovo', data: 'XK' },
			{ label: 'Kuwait', data: 'KW' },
			{ label: 'Kyrgyzstan', data: 'KG' },
			{ label: "Lao People's Democratic Republic", data: 'LA' },
			{ label: 'Latvia', data: 'LV' },
			{ label: 'Lebanon', data: 'LB' },
			{ label: 'Lesotho', data: 'LS' },
			{ label: 'Liberia', data: 'LR' },
			{ label: 'Libyan Arab Jamahiriya', data: 'LY' },
			{ label: 'Liechtenstein', data: 'LI' },
			{ label: 'Lithuania', data: 'LT' },
			{ label: 'Luxembourg', data: 'LU' },
			{ label: 'Macao', data: 'MO' },
			{ label: 'Macedonia, The Former Yugoslav Republic of', data: 'MK' },
			{ label: 'Madagascar', data: 'MG' },
			{ label: 'Malawi', data: 'MW' },
			{ label: 'Malaysia', data: 'MY' },
			{ label: 'Maldives', data: 'MV' },
			{ label: 'Mali', data: 'ML' },
			{ label: 'Malta', data: 'MT' },
			{ label: 'Marshall Islands', data: 'MH' },
			{ label: 'Martinique', data: 'MQ' },
			{ label: 'Mauritania', data: 'MR' },
			{ label: 'Mauritius', data: 'MU' },
			{ label: 'Mayotte', data: 'YT' },
			{ label: 'Mexico', data: 'MX' },
			{ label: 'Micronesia, Federated States of', data: 'FM' },
			{ label: 'Moldova, Republic of', data: 'MD' },
			{ label: 'Monaco', data: 'MC' },
			{ label: 'Mongolia', data: 'MN' },
			{ label: 'Montenegro', data: 'ME' },
			{ label: 'Montserrat', data: 'MS' },
			{ label: 'Morocco', data: 'MA' },
			{ label: 'Mozambique', data: 'MZ' },
			{ label: 'Myanmar', data: 'MM' },
			{ label: 'Namibia', data: 'NA' },
			{ label: 'Nauru', data: 'NR' },
			{ label: 'Nepal', data: 'NP' },
			{ label: 'Netherlands', data: 'NL' },
			{ label: 'Netherlands Antilles', data: 'AN' },
			{ label: 'New Caledonia', data: 'NC' },
			{ label: 'New Zealand', data: 'NZ' },
			{ label: 'Nicaragua', data: 'NI' },
			{ label: 'Niger', data: 'NE' },
			{ label: 'Nigeria', data: 'NG' },
			{ label: 'Niue', data: 'NU' },
			{ label: 'Norfolk Island', data: 'NF' },
			{ label: 'Northern Mariana Islands', data: 'MP' },
			{ label: 'Norway', data: 'NO' },
			{ label: 'Oman', data: 'OM' },
			{ label: 'Pakistan', data: 'PK' },
			{ label: 'Palau', data: 'PW' },
			{ label: 'Palestinian Territory, Occupied', data: 'PS' },
			{ label: 'Panama', data: 'PA' },
			{ label: 'Papua New Guinea', data: 'PG' },
			{ label: 'Paraguay', data: 'PY' },
			{ label: 'Peru', data: 'PE' },
			{ label: 'Philippines', data: 'PH' },
			{ label: 'Pitcairn', data: 'PN' },
			{ label: 'Poland', data: 'PL' },
			{ label: 'Portugal', data: 'PT' },
			{ label: 'Puerto Rico', data: 'PR' },
			{ label: 'Qatar', data: 'QA' },
			{ label: 'Reunion', data: 'RE' },
			{ label: 'Romania', data: 'RO' },
			{ label: 'Russian Federation', data: 'RU' },
			{ label: 'Rwanda', data: 'RW' },
			{ label: 'Saint Helena', data: 'SH' },
			{ label: 'Saint Kitts and Nevis', data: 'KN' },
			{ label: 'Saint Lucia', data: 'LC' },
			{ label: 'Saint Pierre and Miquelon', data: 'PM' },
			{ label: 'Saint Vincent and the Grenadines', data: 'VC' },
			{ label: 'Samoa', data: 'WS' },
			{ label: 'San Marino', data: 'SM' },
			{ label: 'Sao Tome and Principe', data: 'ST' },
			{ label: 'Saudi Arabia', data: 'SA' },
			{ label: 'Senegal', data: 'SN' },
			{ label: 'Serbia', data: 'RS' },
			{ label: 'Seychelles', data: 'SC' },
			{ label: 'Sierra Leone', data: 'SL' },
			{ label: 'Singapore', data: 'SG' },
			{ label: 'Slovakia', data: 'SK' },
			{ label: 'Slovenia', data: 'SI' },
			{ label: 'Solomon Islands', data: 'SB' },
			{ label: 'Somalia', data: 'SO' },
			{ label: 'South Africa', data: 'ZA' },
			{
				label: 'South Georgia and the South Sandwich Islands',
				data: 'GS',
			},
			{ label: 'Spain', data: 'ES' },
			{ label: 'Sri Lanka', data: 'LK' },
			{ label: 'Sudan', data: 'SD' },
			{ label: 'Suriname', data: 'SR' },
			{ label: 'Svalbard and Jan Mayen', data: 'SJ' },
			{ label: 'Swaziland', data: 'SZ' },
			{ label: 'Sweden', data: 'SE' },
			{ label: 'Switzerland', data: 'CH' },
			{ label: 'Syrian Arab Republic', data: 'SY' },
			{ label: 'Taiwan', data: 'TW' },
			{ label: 'Tajikistan', data: 'TJ' },
			{ label: 'Tanzania, United Republic of', data: 'TZ' },
			{ label: 'Thailand', data: 'TH' },
			{ label: 'Timor-Leste', data: 'TL' },
			{ label: 'Togo', data: 'TG' },
			{ label: 'Tokelau', data: 'TK' },
			{ label: 'Tonga', data: 'TO' },
			{ label: 'Trinidad and Tobago', data: 'TT' },
			{ label: 'Tunisia', data: 'TN' },
			{ label: 'Turkey', data: 'TR' },
			{ label: 'Turkmenistan', data: 'TM' },
			{ label: 'Turks and Caicos Islands', data: 'TC' },
			{ label: 'Tuvalu', data: 'TV' },
			{ label: 'Uganda', data: 'UG' },
			{ label: 'Ukraine', data: 'UA' },
			{ label: 'United Arab Emirates', data: 'AE' },
			{ label: 'United Kingdom', data: 'GB' },
			{ label: 'United States', data: 'US' },
			{ label: 'United States Minor Outlying Islands', data: 'UM' },
			{ label: 'Uruguay', data: 'UY' },
			{ label: 'Uzbekistan', data: 'UZ' },
			{ label: 'Vanuatu', data: 'VU' },
			{ label: 'Venezuela', data: 'VE' },
			{ label: 'Viet Nam', data: 'VN' },
			{ label: 'Virgin Islands, British', data: 'VG' },
			{ label: 'Virgin Islands, U.S.', data: 'VI' },
			{ label: 'Wallis and Futuna', data: 'WF' },
			{ label: 'Western Sahara', data: 'EH' },
			{ label: 'Yemen', data: 'YE' },
			{ label: 'Zambia', data: 'ZM' },
			{ label: 'Zimbabwe', data: 'ZW' },
		],
	},
	{
		key: 'phonecodes',
		data: [
			{ label: '+1', data: '+1' },
			{ label: '+1-242', data: '+1-242' },
			{ label: '+1-246', data: '+1-246' },
			{ label: '+1-264', data: '+1-264' },
			{ label: '+1-268', data: '+1-268' },
			{ label: '+1-284', data: '+1-284' },
			{ label: '+1-340', data: '+1-340' },
			{ label: '+1-345', data: '+1-345' },
			{ label: '+1-441', data: '+1-441' },
			{ label: '+1-473', data: '+1-473' },
			{ label: '+1-649', data: '+1-649' },
			{ label: '+1-664', data: '+1-664' },
			{ label: '+1-671', data: '+1-671' },
			{ label: '+1-758', data: '+1-758' },
			{ label: '+1-767', data: '+1-767' },
			{ label: '+1-784', data: '+1-784' },
			{ label: '+1-787', data: '+1-787' },
			{ label: '+1-868', data: '+1-868' },
			{ label: '+1-869', data: '+1-869' },
			{ label: '+1-876', data: '+1-876' },
			{ label: '+7', data: '+7' },
			{ label: '+20', data: '+20' },
			{ label: '+27', data: '+27' },
			{ label: '+30', data: '+30' },
			{ label: '+31', data: '+31' },
			{ label: '+32', data: '+32' },
			{ label: '+33', data: '+33' },
			{ label: '+34', data: '+34' },
			{ label: '+36', data: '+36' },
			{ label: '+39', data: '+39' },
			{ label: '+39', data: '+39' },
			{ label: '+40', data: '+40' },
			{ label: '+41', data: '+41' },
			{ label: '+43', data: '+43' },
			{ label: '+44', data: '+44' },
			{ label: '+44', data: '+44' },
			{ label: '+45', data: '+45' },
			{ label: '+46', data: '+46' },
			{ label: '+47', data: '+47' },
			{ label: '+48', data: '+48' },
			{ label: '+49', data: '+49' },
			{ label: '+51', data: '+51' },
			{ label: '+52', data: '+52' },
			{ label: '+53', data: '+53' },
			{ label: '+54', data: '+54' },
			{ label: '+55', data: '+55' },
			{ label: '+56', data: '+56' },
			{ label: '+57', data: '+57' },
			{ label: '+58', data: '+58' },
			{ label: '+60', data: '+60' },
			{ label: '+61', data: '+61' },
			{ label: '+62', data: '+62' },
			{ label: '+63', data: '+63' },
			{ label: '+64', data: '+64' },
			{ label: '+65', data: '+65' },
			{ label: '+66', data: '+66' },
			{ label: '+81', data: '+81' },
			{ label: '+82', data: '+82' },
			{ label: '+84', data: '+84' },
			{ label: '+86', data: '+86' },
			{ label: '+90', data: '+90' },
			{ label: '+91', data: '+91' },
			{ label: '+92', data: '+92' },
			{ label: '+93', data: '+93' },
			{ label: '+94', data: '+94' },
			{ label: '+95', data: '+95' },
			{ label: '+98', data: '+98' },
			{ label: '+212', data: '+212' },
			{ label: '+213', data: '+213' },
			{ label: '+216', data: '+216' },
			{ label: '+218', data: '+218' },
			{ label: '+220', data: '+220' },
			{ label: '+221', data: '+221' },
			{ label: '+222', data: '+222' },
			{ label: '+223', data: '+223' },
			{ label: '+224', data: '+224' },
			{ label: '+225', data: '+225' },
			{ label: '+226', data: '+226' },
			{ label: '+227', data: '+227' },
			{ label: '+228', data: '+228' },
			{ label: '+229', data: '+229' },
			{ label: '+230', data: '+230' },
			{ label: '+231', data: '+231' },
			{ label: '+232', data: '+232' },
			{ label: '+233', data: '+233' },
			{ label: '+234', data: '+234' },
			{ label: '+235', data: '+235' },
			{ label: '+236', data: '+236' },
			{ label: '+237', data: '+237' },
			{ label: '+238', data: '+238' },
			{ label: '+239', data: '+239' },
			{ label: '+240', data: '+240' },
			{ label: '+241', data: '+241' },
			{ label: '+242', data: '+242' },
			{ label: '+243', data: '+243' },
			{ label: '+244', data: '+244' },
			{ label: '+245', data: '+245' },
			{ label: '+248', data: '+248' },
			{ label: '+249', data: '+249' },
			{ label: '+250', data: '+250' },
			{ label: '+251', data: '+251' },
			{ label: '+252', data: '+252' },
			{ label: '+253', data: '+253' },
			{ label: '+254', data: '+254' },
			{ label: '+255', data: '+255' },
			{ label: '+256', data: '+256' },
			{ label: '+257', data: '+257' },
			{ label: '+258', data: '+258' },
			{ label: '+260', data: '+260' },
			{ label: '+261', data: '+261' },
			{ label: '+262', data: '+262' },
			{ label: '+263', data: '+263' },
			{ label: '+264', data: '+264' },
			{ label: '+265', data: '+265' },
			{ label: '+266', data: '+266' },
			{ label: '+267', data: '+267' },
			{ label: '+268', data: '+268' },
			{ label: '+269', data: '+269' },
			{ label: '+269', data: '+269' },
			{ label: '+290', data: '+290' },
			{ label: '+291', data: '+291' },
			{ label: '+297', data: '+297' },
			{ label: '+298', data: '+298' },
			{ label: '+299', data: '+299' },
			{ label: '+350', data: '+350' },
			{ label: '+351', data: '+351' },
			{ label: '+352', data: '+352' },
			{ label: '+353', data: '+353' },
			{ label: '+354', data: '+354' },
			{ label: '+355', data: '+355' },
			{ label: '+356', data: '+356' },
			{ label: '+357', data: '+357' },
			{ label: '+358', data: '+358' },
			{ label: '+359', data: '+359' },
			{ label: '+370', data: '+370' },
			{ label: '+371', data: '+371' },
			{ label: '+372', data: '+372' },
			{ label: '+373', data: '+373' },
			{ label: '+374', data: '+374' },
			{ label: '+375', data: '+375' },
			{ label: '+376', data: '+376' },
			{ label: '+377', data: '+377' },
			{ label: '+378', data: '+378' },
			{ label: '+380', data: '+380' },
			{ label: '+381', data: '+381' },
			{ label: '+382', data: '+382' },
			{ label: '+385', data: '+385' },
			{ label: '+386', data: '+386' },
			{ label: '+387', data: '+387' },
			{ label: '+389', data: '+389' },
			{ label: '+420', data: '+420' },
			{ label: '+421', data: '+421' },
			{ label: '+423', data: '+423' },
			{ label: '+500', data: '+500' },
			{ label: '+501', data: '+501' },
			{ label: '+502', data: '+502' },
			{ label: '+503', data: '+503' },
			{ label: '+504', data: '+504' },
			{ label: '+505', data: '+505' },
			{ label: '+506', data: '+506' },
			{ label: '+507', data: '+507' },
			{ label: '+508', data: '+508' },
			{ label: '+509', data: '+509' },
			{ label: '+590', data: '+590' },
			{ label: '+591', data: '+591' },
			{ label: '+592', data: '+592' },
			{ label: '+593', data: '+593' },
			{ label: '+594', data: '+594' },
			{ label: '+595', data: '+595' },
			{ label: '+596', data: '+596' },
			{ label: '+597', data: '+597' },
			{ label: '+598', data: '+598' },
			{ label: '+599', data: '+599' },
			{ label: '+670', data: '+670' },
			{ label: '+672', data: '+672' },
			{ label: '+672', data: '+672' },
			{ label: '+673', data: '+673' },
			{ label: '+674', data: '+674' },
			{ label: '+675', data: '+675' },
			{ label: '+676', data: '+676' },
			{ label: '+677', data: '+677' },
			{ label: '+678', data: '+678' },
			{ label: '+679', data: '+679' },
			{ label: '+680', data: '+680' },
			{ label: '+681', data: '+681' },
			{ label: '+682', data: '+682' },
			{ label: '+683', data: '+683' },
			{ label: '+684', data: '+684' },
			{ label: '+686', data: '+686' },
			{ label: '+687', data: '+687' },
			{ label: '+688', data: '+688' },
			{ label: '+689', data: '+689' },
			{ label: '+690', data: '+690' },
			{ label: '+691', data: '+691' },
			{ label: '+692', data: '+692' },
			{ label: '+809', data: '+809' },
			{ label: '+850', data: '+850' },
			{ label: '+852', data: '+852' },
			{ label: '+853', data: '+853' },
			{ label: '+855', data: '+855' },
			{ label: '+856', data: '+856' },
			{ label: '+880', data: '+880' },
			{ label: '+886', data: '+886' },
			{ label: '+960', data: '+960' },
			{ label: '+961', data: '+961' },
			{ label: '+962', data: '+962' },
			{ label: '+963', data: '+963' },
			{ label: '+964', data: '+964' },
			{ label: '+965', data: '+965' },
			{ label: '+966', data: '+966' },
			{ label: '+967', data: '+967' },
			{ label: '+968', data: '+968' },
			{ label: '+971', data: '+971' },
			{ label: '+972', data: '+972' },
			{ label: '+973', data: '+973' },
			{ label: '+974', data: '+974' },
			{ label: '+975', data: '+975' },
			{ label: '+976', data: '+976' },
			{ label: '+977', data: '+977' },
			{ label: '+992', data: '+992' },
			{ label: '+993', data: '+993' },
			{ label: '+994', data: '+994' },
			{ label: '+995', data: '+995' },
			{ label: '+996', data: '+996' },
			{ label: '+998', data: '+998' },
		],
	},
	{
		key: 'firmwaretypes',
		data: [
			{ label: 'Probe', data: 'probe' },
			{ label: 'Dongle', data: 'dongle' },
			{ label: 'Ublox', data: 'ublox' },
		],
	},
	{
		key: 'dashboarddate',
		data: [
			// { label: 'Proceq.StatisticsAll', data: '' },
			{ label: 'Proceq.StatisticsCreated', data: 'created' },
			{ label: 'Proceq.StatisticsModified', data: 'last_modified' },
		],
	},
	{
		key: 'analyticsflags',
		data: [
			{ label: 'App.AnalyticsFlagAll', data: 'all' },
			{ label: 'App.AnalyticsFlagAlive', data: 'alive' },
			{ label: 'App.AnalyticsFlagTrashed', data: 'trashed' },
			{ label: 'App.AnalyticsFlagDeleted', data: 'deleted' },
		],
	},
	{
		key: 'platforms',
		data: [
			{ label: 'Android', data: 'android' },
			{ label: 'iOS', data: 'ios' },
		],
	},
	{
		key: 'yesorno',
		data: [
			{ label: 'Proceq.TextYes', data: '1' },
			{ label: 'Proceq.TextNo', data: '0' },
		],
	},
	{
		key: 'enabled',
		data: [
			{
				label: 'Proceq.Enabled',
				data: 1,
			},
			{
				label: 'Proceq.NotEnabled',
				data: 0,
			},
		],
	},
];
