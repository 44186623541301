// @flow
import React from 'react';
import { Table } from 'antd';
import { has } from 'lodash';
import ConvertedUnits from 'components/shared/ConvertedUnits';
import { FormattedMessage } from 'react-intl';
import type { DerivedProps } from 'components/measurements/HTMLExport/Measurement';
import styles from 'components/measurements/HTMLExport/HTMLExport.styl';

export type MeasurementObjectWithInfo = MeasurementObject & {
	readingContent: $PropertyType<MeasurementReading, 'content'>,
	logContent: $PropertyType<Log, 'content'>,
	sequenceNo: number,
};

export const title = <FormattedMessage id="UtilitiesLine" />;

export const UtilitiesLine = ({
	data,
	isMetric,
	convert,
	showTitle,
	scanType,
}: { data: MeasurementFullData } & DerivedProps) => {
	let utilitiesData = [];
	const groupCount = {};
	data.objects.forEach(e => {
		if (e.type === 'utility') {
			let color = '';
			let group = '';
			if (has(e, 'content.category.type.utilities')) {
				color = e.content.category.type.utilities;
			}
			if (has(e, 'content.category.name')) {
				group = e.content.category.name;
			}
			let number = groupCount[group] + 1;
			if (groupCount[group] === undefined) {
				number = 1;
			}
			groupCount[group] = number;
			const setSize = e.content.sizeMeter ?? '-';
			const comment = e.content.comment ?? '-';

			utilitiesData = utilitiesData.concat({
				group,
				number,
				color,
				setSize,
				comment,
				textColor: false,
				convert: false,
				// children,
			});
		}
	});

	return (
		<div className="table-objects">
			<Table
				title={showTitle ? () => <span className={styles.main_header}>{title}</span> : null}
				className={styles.table}
				columns={[
					{
						title: 'Group',
						dataIndex: 'group',
						width: 60,
					},
					{
						title: 'Number',
						dataIndex: 'number',
						width: 40,
					},
					{
						title: 'Colour',
						dataIndex: 'color',
						convert: false,
						render: (text, record: any) => {
							return record.convert ? convert(text, 'GPR_SOIL.Utilities.Line') : text;
						},
						width: 40,
					},
					{
						title: (
							<ConvertedUnits
								id="Set Size [{unit}]"
								unitId="GPR_SOIL.Logbook Panel.Set Size"
								scanType={scanType}
								isMetric={isMetric}
							/>
						),
						dataIndex: 'setSize',
						unitId: `GPR_SOIL.Utilities.Line`,
						render: text => {
							return convert(text, 'GPR_SOIL.Logbook Panel.Set Size');
						},
						width: 40,
					},
					{
						title: 'Comment',
						dataIndex: 'comment',
						render: (text, record: any) => {
							return record.convert ? convert(text, 'GPR_SOIL.Utilities.Line') : text;
						},
						width: 80,
					},
				]}
				rowClassName={record => (record.textColor ? styles.utilities_line : '')}
				dataSource={utilitiesData}
				pagination={false}
				size="small"
				bordered
			/>
		</div>
	);
};

export default UtilitiesLine;
