// reference: https://confluence.screeningeagle.com/display/PQBAC/Events+API
export default [
	{
		internal: 'EQUOTIP',
		official: 'Equotip Live',
	},
	{
		internal: 'UCI',
		official: 'UCI Live',
	},
	{
		internal: 'SCHMIDT',
		official: 'OS8000',
	},
	{
		internal: 'SSL',
		official: 'OS8200',
	},
	{
		internal: 'GLM',
		official: 'ZG8000',
	},
	{
		internal: 'PIT_IE',
		official: 'PI8000',
	},
	{
		internal: 'GPR',
		official: 'GP8000',
	},
	{
		internal: 'GPR_GP8100',
		official: 'GP8100',
	},
	{
		internal: 'GPR_PALM',
		official: 'GP8800',
	},
	{
		internal: 'PUNDIT',
		official: 'PD8000',
	},
	{
		internal: 'PUNDIT_8050',
		official: 'PD8050',
	},
	{
		internal: 'GPR_SOIL',
		official: 'GS8000',
	},
	{
		internal: 'MAP',
		official: 'MA8000',
	},
	{
		internal: 'FDL',
		official: 'UT8000',
	},
];
