// @flow
import { injectIntl } from 'react-intl';
import type { InjectIntlProvidedProps } from 'react-intl';
import handleFormatMessage from 'utils/handleFormatMessage';

export const RenameFile = ({ log, intl }: GPRLogbookEntryProps & InjectIntlProvidedProps) =>
	handleFormatMessage('Proceq.LogbookPropertyRenamedSeriesFromTo', intl.formatMessage, [
		log.content.old,
		log.content.new,
	]);

// export default RenameFile;
export default injectIntl(RenameFile);
