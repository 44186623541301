// @flow
import React, { Fragment } from 'react';
import { Table } from 'antd';
import { has, get } from 'lodash';
import { FormattedMessage } from 'react-intl';
import { convertAndDisplay } from 'constants/conversionRates';
import ConvertedUnits from 'components/shared/ConvertedUnits';
import type { FDLMeasurementPresetsProps } from './MeasurementPresets';
import styles from '../../../HTMLExport.styl';

export const DGSCalibration = ({
	setupFile,
	indicationInfo,
	number,
	showTitle,
	unit,
}: { setupFile: FDLSetupFile, indicationInfo: Object } & FDLMeasurementPresetsProps) => {
	const title = (
		<Fragment>
			<FormattedMessage id="Proceq.ExportFieldSettings" />
			{' ­­» '}
			<FormattedMessage id="App.HTML.FDL.MeasurementPresets.DgsCalibration" />
			{' ­­» '}
			{number}
		</Fragment>
	);

	// For this section, the values are not saved in SI units in the measurement json.
	// However, the conversionRates expect it to be in SI. Thus, convert accordingly.
	// To check the unit of the saved parameter, refer : https://jira.screeningeagle.com/browse/FDL-958
	const dataSource = [
		{
			label: 'App.HTML.FDL.IndicationInfo.ProbeType',
			value: setupFile.probeType,
		},
		{
			label: 'App.HTML.FDL.IndicationInfo.ReferenceType',
			value: <FormattedMessage id={'App.HTML.FDL.IndicationInfo.Backwall'} />,
		},
		{
			label: 'App.HTML.FDL.IndicationInfo.calibratedDistance',
			value: indicationInfo.calibratedDistance / 1000,
			unitId: 'FDL.indicationInfo.CalibratedDistance',
		},
		{
			label: 'App.HTML.FDL.IndicationInfo.CalibratedAmplitude',
			value: indicationInfo.calibratedAmplitude,
			unitId: 'FDL.indicationInfo.CalibratedAmplitude',
		},
		{
			label: 'App.HTML.FDL.IndicationInfo.Attenuation',
			value: unit === 'metric' ? setupFile.dgsAttenuationCoeffCalib : setupFile.dgsAttenuationCoeffCalib * 25.4,
			unitId: 'FDL.setupFile.Attenuation',
			skipConversion: true,
		},
		{
			label: 'App.HTML.FDL.IndicationInfo.DeltaVk',
			value: setupFile.dgsDeltaVk,
			unitId: 'FDL.indicationInfo.CalibratedAmplitude',
		},
		{
			label: 'App.HTML.FDL.IndicationInfo.ErsMainCurve',
			value: setupFile.dgsErs / 1000,
			unitId: 'FDL.setupFile.DgsErs',
		},
	].map((row, key) => {
		let label = <FormattedMessage id={row.label} />;
		let value = row.value;
		const unitToUse = has(setupFile, 'unit') ? get(setupFile, 'unit') : unit;
		if (row.unitId) {
			const { convertFunction } = convertAndDisplay(row.unitId, 'Indication', unitToUse);
			label = <ConvertedUnits id={row.label} unitId={row.unitId} scanType="Indication" isMetric={unitToUse} />;
			if (!row.skipConversion) {
				value = convertFunction(row.value);
			}
		}

		return {
			key,
			label,
			value,
		};
	});

	return (
		<Table
			title={showTitle ? () => <span className={styles.sub_header}>{title}</span> : null}
			className={styles.table}
			dataSource={dataSource}
			pagination={false}
			showHeader={false}
			size="small"
		>
			<Table.Column dataIndex="label" className={styles.column} />
			<Table.Column dataIndex="value" />
		</Table>
	);
};

export default DGSCalibration;
