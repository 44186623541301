export default {
	LOGIN_REQUEST: 'LOGIN_REQUEST',
	LOGIN_FAILED: 'LOGIN_FAILED',
	LOGIN_SUCCESS: 'LOGIN_SUCCESS',
	LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
	PASSWORD_UPDATE_SUCCESS: 'PASSWORD_UPDATE_SUCCESS',
	PASSWORD_UPDATE_FAILED: 'PASSWORD_UPDATE_FAILED',
	RESET_PASSWORD_SENDING: 'RESET_PASSWORD_SENDING',
	RESET_PASSWORD_EMAIL_SENT: 'RESET_PASSWORD_EMAIL_SENT',
	RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',

	INITIALIZE: 'INITIALIZE',
	REINITIALIZE_APP: 'REINITIALIZE_APP',
	PROFILE_LOADED: 'PROFILE_LOADED',
	PORTRAIT_LOADED: 'PORTRAIT_LOADED',
	NAV_ITEM_TOGGLED: 'NAV_ITEM_TOGGLED',
	SIDE_BAR_MODE_TOGGLED: 'SIDE_BAR_MODE_TOGGLED',

	PENDING_AUTH_USERS_RECEIVED: 'PENDING_AUTH_USERS_RECEIVED',
	REAUTH_USER_SUCCESS: 'REAUTH_USER_SUCCESS',

	SET_KEY_VALUE: 'SET_KEY_VALUE',
	INIT_GLOBAL_REGISTER: 'INIT_GLOBAL_REGISTER',
	START_USER_SESSION: 'START_USER_SESSION',
	FINISH_USER_SESSION: 'FINISH_USER_SESSION',
	INIT_USER_SESSION: 'INIT_USER_SESSION',

	PROCEQ_LOGIN_REQUEST: 'PROCEQ_LOGIN_REQUEST',
	PROCEQ_PROFILE_LOADED: 'PROCEQ_PROFILE_LOADED',
	CHANGE_CURRENT_PRODUCT: 'CHANGE_CURRENT_PRODUCT',
	CHANGE_CURRENT_GROUP: 'CHANGE_CURRENT_GROUP',
	CHANGING_CURRENT_PRODUCT: 'CHANGING_CURRENT_PRODUCT',
	INIT_RECOVER_LAST_SESSION: 'INIT_RECOVER_LAST_SESSION',
	INIT_SHARED_PROCEQ_TOKEN_SESSION: 'INIT_SHARED_PROCEQ_TOKEN_SESSION',
	INIT_ONETIME_PROCEQ_TOKEN_SESSION: 'INIT_ONETIME_PROCEQ_TOKEN_SESSION',
	CHANGE_LANGUAGE: 'CHANGE_LANGUAGE',
	INIT_LANGUAGE: 'INIT_LANGUAGE',

	SET_SHARED_MEASUREMENTS_SESSION: 'SET_SHARED_MEASUREMENTS_SESSION',
	SET_AJAX_PARAMS: 'SET_AJAX_PARAMS',
	SET_MEASUREMENT_SUMMARY: 'SET_MEASUREMENT_SUMMARY',
};
