// @flow
import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withState } from 'recompose';
import { Form, Input, Button, message, Select } from 'antd';
import { FormattedMessage, injectIntl } from 'react-intl';
import type { InjectIntlProvidedProps } from 'react-intl';
import type { FormProps } from 'antd/lib/form';
import { HOSTS, APIS } from 'apis/proceq';
import { request } from 'actions/ajax';

const { Option } = Select;

export const TouchMeasurement = ({
	touchMeasurementHandler,
	form,
	intl,
	loading,
}: {
	touchMeasurementHandler: Function,
	form: FormProps,
	loading: boolean,
} & InjectIntlProvidedProps) => (
	<div>
		<Form layout="inline" onSubmit={touchMeasurementHandler(form)}>
			<Form.Item
				label={intl.formatMessage({
					id: 'App.DevTools.TouchMeasurement.RegionLabel',
				})}
			>
				{form.getFieldDecorator('region', {
					rules: [{ required: true, message: 'Please select region!' }],
				})(
					<Select style={{ width: 130 }}>
						{Object.keys(HOSTS)
							.filter(h => h !== 'MGT')
							.map(regionCode => (
								<Option key={regionCode} value={regionCode}>
									{regionCode}
								</Option>
							))}
					</Select>
				)}
			</Form.Item>
			<Form.Item
				label={intl.formatMessage({
					id: 'App.DevTools.TouchMeasurement.MeasurementIdLabel',
				})}
			>
				{form.getFieldDecorator('measurementId', {
					rules: [{ required: true }],
				})(<Input />)}
			</Form.Item>
			<Button type="primary" htmlType="submit" loading={loading}>
				<FormattedMessage id="App.DevTools.TouchMeasurement.Button" />
			</Button>
		</Form>
	</div>
);

export default compose(
	withState('loading', 'setLoading', false),
	injectIntl,
	connect(null, (dispatch: Function, props) => ({
		touchMeasurementHandler: form => e => {
			e.preventDefault();
			form.validateFields((err, values) => {
				if (err) {
					return;
				}

				const region = values.region;
				const payload = {
					measurementId: values.measurementId,
				};

				dispatch(
					request({
						method: 'PUT',
						url: `${HOSTS[region]}${APIS.REGIONAL_TOUCH_MEASUREMENT}`,
						params: payload,
						setLoading: props.setLoading,
					})
				)
					.then(() => {
						message.success(
							props.intl.formatMessage({
								id: 'App.DevTools.TouchMeasurement.Success',
							})
						);
					})
					.catch(error => {
						message.error(error);
					});
			});
		},
	})),
	Form.create({})
)(TouchMeasurement);
